import store from '@/mobile/src/store'
import { Dialog } from '@capacitor/dialog'
import { AppLauncher } from '@capacitor/app-launcher'
import { getPlatform, getAskNicelyAppInfo } from '@/mobile/src/utils/capacitor'

const showUpdateDialog = async function (updateLink) {
    const { value } = await Dialog.confirm({
        title: 'There is a new app version available',
        message: 'Press OK to update',
    })

    if (value) {
        await AppLauncher.openUrl({ url: updateLink })
    }
}

export async function deviceCheck() {
    const deviceInfo = await getAskNicelyAppInfo()
    const deviceRequirement = store.getters.deviceRequirement

    let updateLink = ''
    let requiredBuild = 0
    const deviceBuild = deviceInfo.build

    const platform = await getPlatform()
    if (platform === 'ios') {
        updateLink = 'https://apps.apple.com/us/app/asknicely/id1246475849'
        requiredBuild = deviceRequirement['ios'].build
    } else if (platform === 'android') {
        updateLink =
            'https://play.google.com/store/apps/details?id=com.asknicelymobile'
        requiredBuild = deviceRequirement['android'].build
    } else {
        return // unknown platform or web
    }

    if (deviceBuild && parseInt(deviceBuild) < requiredBuild) {
        await showUpdateDialog(updateLink)
    }
}
