<template>
    <div class="dialog-card-body">
        <div class="dialog-card-title">
            <h4 @click="$emit('openDetails')">{{ title }}</h4>
            <button
                v-if="canDelete"
                type="button"
                class="image-btn delete-btn"
                @click="onDelete"
            >
                <img
                    src="../../components/WebView/icons/Trashcan.svg"
                    alt="Delete this suggestion"
                />
            </button>
        </div>
        <div @click="$emit('openDetails')">
            <h5 v-if="subtitle" class="subtitle">{{ subtitle }}</h5>
            <ActionLabel v-if="action && type === 'comment'" :action="action" />
            <TextWithHighlight
                class="body"
                :text="body"
                :highlights="highlights"
            />
            <div class="created">{{ formatTime(created) }}</div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-facing-decorator'
import { Getter } from 'vuex-facing-decorator'
import ActionLabel from './ActionLabel.vue'
import { IRecipient } from '@/entities/notices'
import { SuggestionAction } from '@/entities/suggestions'
import TextWithHighlight from '@/components/TextWithHighlight.vue'
import { relativeDate } from '@/utils/time'

@Component({
    components: {
        ActionLabel,
        TextWithHighlight,
    },
    emits: ['delete', 'openDetails'],
})
export default class DialogBody extends Vue {
    @Prop({ type: String, default: '' }) public title!: string
    @Prop({ type: String, default: '' }) public subtitle!: string
    @Prop({ type: String, default: '' }) public body!: string
    @Prop({ type: Number, default: 0 }) public created!: number
    @Prop({ type: String, default: '' }) public action!: SuggestionAction
    @Prop({ type: String, default: '' }) public type!: string
    @Prop({ type: Boolean, default: true }) public canDelete!: boolean

    onDelete() {
        this.$emit('delete')
    }

    @Getter public suggestionMentionUsers!: IRecipient[]

    public formatTime(time: number) {
        return relativeDate(time)
    }

    public get highlights() {
        return this.suggestionMentionUsers.map((user) => `@${user.name}`)
    }
}
</script>

<style scoped lang="less">
@import '~@/styles/button.less';
@import '~@/styles/palette.less';
@import '~@/styles/typography.less';

.label {
    margin-bottom: 10px;
}

.dialog-card-title {
    display: flex;
    justify-content: space-between;
    padding: 4px 0px 8px 0;
}

h4 {
    font-weight: 500;
    color: #2f384c;
    font-family: 'Roboto Black', sans-serif;
    margin: 0; // remove the bottom margin in desktop page
}

.delete-btn {
    background-color: transparent;
    display: flex;
    min-width: 40px;
}

.subtitle {
    color: @grey40;
    font-family: @fontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    letter-spacing: 1px;
    margin-top: 0;
    text-transform: uppercase;
}

.body {
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 15px;
}

.created {
    color: #93a1be;
    font-size: 12px;
    line-height: 20px;
}
</style>
