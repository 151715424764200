import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EngagementPositiveFeedback = _resolveComponent("EngagementPositiveFeedback")!
  const _component_EngagementFiveStarQuestion = _resolveComponent("EngagementFiveStarQuestion")!
  const _component_EngagementNPSQuestion = _resolveComponent("EngagementNPSQuestion")!
  const _component_EngagementShoutoutQuestion = _resolveComponent("EngagementShoutoutQuestion")!
  const _component_EngagementScorecardQuestion = _resolveComponent("EngagementScorecardQuestion")!
  const _component_FullScreenPopup = _resolveComponent("FullScreenPopup")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.shouldShowEngagementModal)
      ? (_openBlock(), _createBlock(_component_FullScreenPopup, { key: 0 }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(["close", { desktop: !_ctx.isMobile }]),
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
            }, " × ", 2),
            _createElementVNode("div", {
              class: _normalizeClass(["engagement-popup", { 'desktop-engagement-popup': !_ctx.isMobile }])
            }, [
              (
                        _ctx.dailyEngagementQuestionToShow.question_type ===
                        _ctx.engagementQuestionType.PositiveFeedback
                    )
                ? (_openBlock(), _createBlock(_component_EngagementPositiveFeedback, {
                    key: 0,
                    response: _ctx.dailyEngagementQuestionToShow.response,
                    "see-more-responses": _ctx.isLastQuestion,
                    onDone: _cache[1] || (_cache[1] = ($event: any) => (_ctx.submitAnswer(_ctx.dailyEngagementQuestionToShow)))
                  }, null, 8, ["response", "see-more-responses"]))
                : (
                        _ctx.dailyEngagementQuestionToShow.question_type ===
                        _ctx.engagementQuestionType.FiveStar
                    )
                  ? (_openBlock(), _createBlock(_component_EngagementFiveStarQuestion, {
                      key: _ctx.dailyEngagementQuestionToShow.question_id,
                      question: _ctx.dailyEngagementQuestionToShow,
                      onUpdate: _cache[2] || (_cache[2] = ($event: any) => (
                        _ctx.updateQuestion(_ctx.dailyEngagementQuestionToShow, $event)
                    )),
                      onDone: _ctx.submitAnswer
                    }, null, 8, ["question", "onDone"]))
                  : (
                        _ctx.dailyEngagementQuestionToShow.question_type ===
                        _ctx.engagementQuestionType.NPS
                    )
                    ? (_openBlock(), _createBlock(_component_EngagementNPSQuestion, {
                        key: 2,
                        question: _ctx.dailyEngagementQuestionToShow,
                        onUpdate: _cache[3] || (_cache[3] = ($event: any) => (
                        _ctx.updateQuestion(_ctx.dailyEngagementQuestionToShow, $event)
                    )),
                        onDone: _ctx.submitAnswer
                      }, null, 8, ["question", "onDone"]))
                    : (
                        _ctx.dailyEngagementQuestionToShow.question_type ===
                        _ctx.engagementQuestionType.Shoutout
                    )
                      ? (_openBlock(), _createBlock(_component_EngagementShoutoutQuestion, {
                          key: 3,
                          question: _ctx.dailyEngagementQuestionToShow,
                          onUpdate: _cache[4] || (_cache[4] = ($event: any) => (
                        _ctx.updateQuestion(_ctx.dailyEngagementQuestionToShow, $event)
                    )),
                          onDone: _ctx.submitAnswer
                        }, null, 8, ["question", "onDone"]))
                      : (
                        _ctx.dailyEngagementQuestionToShow.question_type ===
                        _ctx.engagementQuestionType.Scorecard
                    )
                        ? (_openBlock(), _createBlock(_component_EngagementScorecardQuestion, {
                            key: 4,
                            question: _ctx.dailyEngagementQuestionToShow,
                            onUpdate: _cache[5] || (_cache[5] = ($event: any) => (
                        _ctx.updateQuestion(_ctx.dailyEngagementQuestionToShow, $event)
                    )),
                            onDone: _ctx.submitAnswer
                          }, null, 8, ["question", "onDone"]))
                        : _createCommentVNode("", true)
            ], 2)
          ], undefined, true),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}