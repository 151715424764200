import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7883ec68"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["textContent"]
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["value", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtTa = _resolveComponent("AtTa")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ 'at-mentions-below': _ctx.atMentionsBelow })
  }, [
    _createVNode(_component_AtTa, {
      members: _ctx.members,
      "filter-match": _ctx.memberSort,
      class: _normalizeClass([
                'atwho-wrap',
                _ctx.compacted && 'compacted',
                _ctx.inModal && 'in-modal',
            ]),
      "name-key": "name",
      onInsert: _ctx.onInsertMention
    }, {
      item: _withCtx((scope) => [
        _createElementVNode("span", {
          class: "name",
          textContent: _toDisplayString(scope.item.name)
        }, null, 8, _hoisted_1),
        _createElementVNode("span", {
          class: "description",
          textContent: _toDisplayString(scope.item.description)
        }, null, 8, _hoisted_2)
      ]),
      default: _withCtx(() => [
        _createElementVNode("textarea", {
          value: _ctx.text,
          placeholder: _ctx.placeholder,
          onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onMessageUpdate && _ctx.onMessageUpdate(...args))),
          onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onMessageUpdate && _ctx.onMessageUpdate(...args))),
          onKeydown: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onInputKeydown && _ctx.onInputKeydown(...args)))
        }, null, 40, _hoisted_3)
      ], undefined, true),
      _: 1
    }, 8, ["members", "filter-match", "class", "onInsert"])
  ], 2))
}