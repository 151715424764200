import moment from 'moment'

/**
 * Returns the score, with the correct decimal point format.
 */
export function getFormattedScore(
    score: number,
    scoreType = 'nps',
    hasDisplayCsatAsPercentage = 0
): string {
    return showDecimalPoint(scoreType, hasDisplayCsatAsPercentage)
        ? Number(score).toFixed(1)
        : Number(score).toFixed(0)
}

/**
 * Returns true if the score should display a decimal point.
 */
export function showDecimalPoint(
    scoreType: string,
    hasDisplayCsatAsPercentage: number
): boolean {
    // 0 DP: NPS, CSAT%
    // 1 DP: CSAT, Fivestar
    return (
        scoreType === 'fivestar' ||
        (scoreType === 'csat' && hasDisplayCsatAsPercentage !== 1)
    )
}

/**
 * @param timeSelected - day number, e.g. 1, 7, 30 days
 */
export function getDateRange(timeSelected: number): string {
    // format: 'custom/dd-mm-yyyy_dd-mm-yyyy'
    const end = moment().format('DD-MM-YYYY')
    const start = moment().subtract(timeSelected, 'days').format('DD-MM-YYYY')
    return `custom/${start}_${end}`
}
