import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cube-scene" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(['cube', _ctx.face, { resizing: _ctx.resizing }])
    }, [
      _renderSlot(_ctx.$slots, "front", { class: "cube__face cube__face--front" }),
      _renderSlot(_ctx.$slots, "back", { class: "cube__face cube__face--back" }),
      _renderSlot(_ctx.$slots, "right", { class: "cube__face cube__face--right" }),
      _renderSlot(_ctx.$slots, "left", { class: "cube__face cube__face--left" }),
      _renderSlot(_ctx.$slots, "top", { class: "cube__face cube__face--top" }),
      _renderSlot(_ctx.$slots, "bottom", { class: "cube__face cube__face--bottom" })
    ], 2)
  ]))
}