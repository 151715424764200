import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/img/Icons/chevron-up-solid.svg'
import _imports_1 from '@/assets/img/Icons/chevron-down-solid.svg'


const _withScopeId = n => (_pushScopeId("data-v-2c0515e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "" }
const _hoisted_2 = {
  key: 1,
  class: "teams-top-performer"
}
const _hoisted_3 = {
  key: 0,
  class: "content"
}
const _hoisted_4 = {
  key: 0,
  src: _imports_0
}
const _hoisted_5 = {
  key: 1,
  src: _imports_1
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingCard = _resolveComponent("LoadingCard")!
  const _component_Typography = _resolveComponent("Typography")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.hierarchyData || _ctx.loading)
      ? (_openBlock(), _createBlock(_component_LoadingCard, {
          key: 0,
          borderless: true,
          variant: "single"
        }))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.pickedItem)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", null, [
                  _createVNode(_component_Typography, {
                    variant: "overline",
                    class: "hierarchy-title"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.title), 1)
                    ], undefined, true),
                    _: 1
                  }),
                  _createVNode(_component_Typography, {
                    variant: "h4",
                    component: "h4",
                    class: "hierarchy-name"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.pickedItem.name), 1)
                    ], undefined, true),
                    _: 1
                  })
                ]),
                _createElementVNode("div", null, [
                  _createVNode(_component_Typography, {
                    variant: "subtitle2",
                    class: "hierarchy-score"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", null, _toDisplayString(_ctx.scoreType) + " " + _toDisplayString(_ctx.displayedScoreDisplay), 1),
                      _createElementVNode("div", null, [
                        (
                                    Number(
                                        _ctx.pickedItemScore
                                            ? _ctx.pickedItemScore.change
                                            : 0
                                    ) > 0
                                )
                          ? (_openBlock(), _createElementBlock("img", _hoisted_4))
                          : (_openBlock(), _createElementBlock("img", _hoisted_5)),
                        _createTextVNode(" " + _toDisplayString(_ctx.displayedScoreChange), 1)
                      ])
                    ], undefined, true),
                    _: 1
                  })
                ])
              ]))
            : _createCommentVNode("", true)
        ]))
  ]))
}