import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-284fb84c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "strip-input" }
const _hoisted_2 = { class: "options" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", null, _toDisplayString(_ctx.label), 1),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
        return (_openBlock(), _createElementBlock("span", {
          key: index,
          class: _normalizeClass(['item', { active: option.selected }]),
          onClick: ($event: any) => (!_ctx.disabled && _ctx.clickHandler(option, index))
        }, _toDisplayString(option.key), 11, _hoisted_3))
      }), 128))
    ])
  ]))
}