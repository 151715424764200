import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextClamp = _resolveComponent("TextClamp")!
  const _component_BasePillButton = _resolveComponent("BasePillButton")!

  return (_openBlock(), _createBlock(_component_BasePillButton, {
    "is-active": _ctx.isActive,
    disabled: _ctx.disabled,
    onClick: _ctx.onClick
  }, {
    default: _withCtx(() => [
      _createVNode(_component_TextClamp, {
        text: `${_ctx.formattedLabel}`,
        "max-lines": _ctx.maxLines
      }, null, 8, ["text", "max-lines"])
    ], undefined, true),
    _: 1
  }, 8, ["is-active", "disabled", "onClick"]))
}