<template>
    <div class="token-container">
        <img class="bg" alt="" :src="bgSrc" />
        <div class="content">
            <div class="title">{{ title }}</div>
            <div class="description">{{ description }}</div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import Typography from '@/components/Rain/Typography/Typography.vue'

@Component({
    components: { Typography },
})
export default class AchievementToken extends Vue {
    @Prop({ type: String })
    type!: string

    @Prop({ type: String })
    title!: string

    @Prop({ type: String })
    description!: string

    get bgSrc() {
        return require(
            `@/assets/img/scorecard/achievement_tokens/${this.type}.svg`
        )
    }
}
</script>

<style scoped lang="less">
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';

.token-container {
    position: relative;
    color: white;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 96px;
    height: 96px;
    margin: @gutterSpacing-md;

    .bg {
        position: absolute;
    }

    .content {
        z-index: 1;
    }

    .title {
        font-size: @fontSize-md;
        font-weight: @fontWeight-bold;
    }

    .description {
        font-size: @fontSize-xs;
    }
}
</style>
