import {
    getCoachingInsights,
    getGroupBadges,
    getInsightsStatuses,
    getTeamUpdatesInsights,
    getTeamUserInsights,
    getTsGroupRepeatVisitsSummary,
    getTsGroupSummary,
    getTsSummary,
    getTsTeamGroups,
} from '@/api/teamscoreboard'
import { CompanyFeatures, IFilterRule } from '@/entities'
import {
    CoachingMetricType,
    EngagementMetricType,
    IHierarchyPath,
    ITimeRange,
    TeamUpdatesUserData,
} from '@/entities/insights'
import { Badges } from '@/entities/scorecard'
import { TsGroupEntity, TsTeamGroupEntity } from '@/entities/teamscoreboard'

import {
    GroupStatusMap,
    InsightsMetric,
    InsightsStatus,
    SelectedStatuses,
    TimeOptions,
} from '@/mobile/src/types/insights'
import { IScorecardSetting } from '@/pages/appstore/components/Scorecard/scorecard-settings-entity'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { getEngagementInsights } from '@/api/engagement'
import {
    saveToSessionStorage,
    getFromSessionStorage,
    getSessionStorageExpiry,
} from '@/utils/sessionstorage'
import {
    addTeamUpdatesCardAction,
    getTeamUpdatesCardNote,
} from '@/api/teamUpdates'
import { sleep } from '@/utils/async'

// eslint-disable-next-line @typescript-eslint/no-empty-function
let getIsRepeatVisitTrackingConfigured = async () => {}
// TODO: This is here because of some funkiness that happens when the module tries to load something from @/mobile
//The simplest solution would be to move the call out of the mobile api path, but for now this can give an example of
//how to get around import problems
if (process.env.CONFIG_KEY === 'mobile') {
    getIsRepeatVisitTrackingConfigured =
        require('@/mobile/src/api/auth').getIsRepeatVisitTrackingConfigured
}

function convertToArray(
    selectedGroups: IHierarchyPath
): Array<{ level: number; groupName: string }> {
    const entries = Object.entries(selectedGroups).map(([level, groupName]) => {
        return {
            level: Number(level),
            groupName,
        }
    })
    entries.sort((a, b) => {
        return a.level - b.level
    })
    return entries
}

export interface InsightsState {
    // home page selected insight metric tile
    selectedMetric: InsightsMetric

    // different from mobile question type, used only for insights
    selectedQuestionType: string

    selectedCoachingMetric: CoachingMetricType

    selectedEngagementMetric: EngagementMetricType

    selectedStatuses: SelectedStatuses

    // an index of what is the hierarchy level of groups we are looking at in the table
    viewByHierarchyLevel: number

    // team hierarchy levels from high to low
    teamHierarchy?: TsTeamGroupEntity[]

    // table rows data for nps metric
    cache: {
        [cacheKey: string]: any
    }

    // Card notes data for team updates
    teamUpdatesCardNotes: {
        [cardKey: string]: string
    }

    insightsTeamUserData: TeamUpdatesUserData

    // what are the selected values on each layer
    selectedGroups: IHierarchyPath

    isLoading: boolean

    showFilters: boolean

    // time range for getting the data
    timeRange: ITimeRange

    // selected scorecard for loading insight data for a specific scorecard
    scorecard: IScorecardSetting

    badges?: {
        negative: Badges
        positive: Badges
    }
    // at lowest level, when click on row, mark as highlighted
    highlightedGroup: TsGroupEntity | undefined

    isRepeatVisitTrackingConfigured: boolean
    // those are used to tmp store whether user has csat data or fivestar data
    // so that when they drill down a level and while loading data, we won't hide the tile.
    currentlyLoading: string[]
    selectedTeamMemberId: null | number
    desktopEngagementThemeSelected: string | null
    desktopEngagementUserIdSelected: string | null

    showNoticesComposer: boolean
    loadingNoticesComposer: boolean
    preselectedNoticesUserId: null | number

    insightErrorCode: null | string
    insightNicelyError: null | string
    insightUserError: null | string
    teamUpdateCardAction: null | string
    insightTeamUserDataCacheKey: null | string
}

export const state: InsightsState = {
    selectedMetric: InsightsMetric.NPS,
    selectedQuestionType: 'nps',
    selectedCoachingMetric: 'trending_topic',
    selectedEngagementMetric: 'trending_themes',
    selectedStatuses: {
        [InsightsStatus.GOLD]: false,
        [InsightsStatus.SILVER]: false,
        [InsightsStatus.BRONZE]: false,
    },
    viewByHierarchyLevel: 0, // by default top level
    teamHierarchy: undefined,
    cache: {},
    teamUpdatesCardNotes: {},
    insightsTeamUserData: {
        checkin_answered: 0,
        checkin_total: 0,
        success: true,
        total_previous_response: 0,
        total_response: 0,
        userdata: [],
    },
    selectedGroups: {},
    highlightedGroup: undefined,
    isLoading: false,
    showFilters: false,
    timeRange: {
        timeUnit: 'month',
        timeValue: 1,
    },
    scorecard: {
        id: -1,
        name: '',
        field: '',
        deleted_at: -1,
        userRoleIds: [],
        scorecardTopics: [],
    },
    badges: undefined,
    isRepeatVisitTrackingConfigured: false,
    currentlyLoading: [],
    selectedTeamMemberId: null,
    desktopEngagementThemeSelected: null,
    desktopEngagementUserIdSelected: null,
    showNoticesComposer: false,
    loadingNoticesComposer: false,
    preselectedNoticesUserId: null,
    insightErrorCode: null,
    insightNicelyError: null,
    insightUserError: null,
    teamUpdateCardAction: null,
    insightTeamUserDataCacheKey: null,
}

const getters: GetterTree<InsightsState, any> = {
    timeRange: ({ timeRange }) => timeRange,

    // NOTE:
    // There might be data at top level, but might not have data at the level one is at.
    // But we still need to show the tile if there is tile at top level
    hasCsatData: ({ cache }) => {
        const key = Object.keys(cache).find((key) =>
            key.startsWith('csat_summary_')
        )
        return !!key && cache[key].total > 0
    },
    hasFivestarData: ({ cache }) => {
        const key = Object.keys(cache).find((key) =>
            key.startsWith('fivestar_summary_')
        )
        return !!key && cache[key].total > 0
    },

    // TOOD: apply cache later
    positiveBadges: ({ badges }) => badges?.positive,
    negativeBadges: ({ badges }) => badges?.negative,

    friendlyTimeRange: ({ timeRange }) => {
        const selectedTimeRange = TimeOptions.find(
            (option) =>
                option.timeRange.timeValue == timeRange.timeValue &&
                option.timeRange.timeUnit == timeRange.timeUnit
        )
        return selectedTimeRange?.label || 'last 30 days'
    },

    friendlyShortTimeRange: ({ timeRange }) => {
        const selectedTimeRange = TimeOptions.find(
            (option) =>
                option.timeRange.timeValue == timeRange.timeValue &&
                option.timeRange.timeUnit == timeRange.timeUnit
        )
        return selectedTimeRange?.shortLabel || '30 days'
    },

    // current selected metric: nps, repeat visit,
    selectedMetric: ({ selectedMetric }) => selectedMetric,
    selectedQuestionType: ({ selectedQuestionType }) => selectedQuestionType,
    selectedCoachingMetric: ({ selectedCoachingMetric }) =>
        selectedCoachingMetric,
    selectedEngagementMetric: ({ selectedEngagementMetric }) =>
        selectedEngagementMetric,
    highlightedGroup: ({ highlightedGroup }) => highlightedGroup,
    selectedStatuses: ({ selectedStatuses }) => selectedStatuses,
    // current selected level in the hierarchy
    viewByGroupType: ({
        teamHierarchy,
        viewByHierarchyLevel,
    }): TsTeamGroupEntity | undefined => {
        return teamHierarchy ? teamHierarchy[viewByHierarchyLevel] : undefined
    },
    previousViewByGroupType: ({
        teamHierarchy,
        viewByHierarchyLevel,
    }): TsTeamGroupEntity | undefined => {
        return viewByHierarchyLevel > 0 && teamHierarchy
            ? teamHierarchy[viewByHierarchyLevel - 1]
            : undefined
    },

    // current status map, e.g {country_c: {canada: bronze}}
    currentStatusesMap: (
        state,
        getters
    ): { [field: string]: GroupStatusMap } => {
        return state.cache[getters.insightsStatusesCacheKey]
    },

    previousStatusesMap: (
        state,
        getters
    ): { [field: string]: GroupStatusMap } => {
        return state.cache[getters.previousInsightsStatusesCacheKey]
    },

    // statuses for each layer of hierarchy, e.g
    // { region : { Canada: Bronze, US: Silver}}
    currentStatuses: (state, getters) => {
        return getters.currentStatusesMap?.[
            getters.viewByGroupType?.fieldMapping
        ]
    },

    previousStatuses: (state, getters) => {
        return getters.previousStatusesMap?.[
            getters.previousViewByGroupType?.fieldMapping
        ]
    },

    currentGroupLevel: (state): number | undefined => {
        const entries = convertToArray(state.selectedGroups)
        const levels = entries.map((entry) => entry.level)
        const highestLevel = Math.max(...levels)
        return highestLevel
    },
    // group name for current level
    currentGroupName: (state, getters): string | undefined => {
        return state.selectedGroups[getters.currentGroupLevel]
    },

    // the avatar to show in detail modal
    currentGroupAvatar: (state, getters): string | undefined => {
        return getters.insightsDetails?.tsgroups.find(
            (group) => group.group === state.highlightedGroup
        )?.avatar
    },

    scorecardField: (state, getters): string | undefined => {
        return getters.insightsDetails?.scorecardField
    },

    previousGroupName: (state): string | undefined => {
        const entries = convertToArray(state.selectedGroups)
        entries.pop()
        const levels = entries.map((entry) => entry.level)
        const highestLevel = Math.max(...levels)
        return state.selectedGroups[highestLevel]
    },
    isCurrentRootLevel: (state) =>
        Object.entries(state.selectedGroups).length === 0,
    viewByGroupTypeIsLeafLevel: ({ viewByHierarchyLevel, teamHierarchy }) =>
        viewByHierarchyLevel == (teamHierarchy?.length ?? 0) - 1,

    previousHierarchyFilters: ({ selectedGroups, teamHierarchy }) => {
        if (!teamHierarchy) {
            return
        }
        const entries = convertToArray(selectedGroups)
        entries.pop()
        return entries.map<IFilterRule>((entry) => ({
            column: teamHierarchy[entry.level].fieldMapping,
            operator: 'in',
            value: [entry.groupName],
        }))
    },
    hierarchyFilters: ({ selectedGroups, teamHierarchy }) => {
        if (!teamHierarchy) {
            return
        }
        const entries = convertToArray(selectedGroups)
        return entries.map<IFilterRule>((entry) => ({
            column: teamHierarchy[entry.level]?.fieldMapping,
            operator: 'in',
            value: [entry.groupName],
        }))
    },
    statusFilters: (
        { selectedStatuses },
        { currentStatuses, insightsDetails, viewByGroupType }
    ) => {
        const statusValues = Object.values(selectedStatuses)
        if (
            statusValues.every((status) => status) ||
            !statusValues.some((status) => status) ||
            !insightsDetails?.tsgroups
        ) {
            return []
        }

        const groups = insightsDetails.tsgroups
            .filter(
                (group: TsGroupEntity) =>
                    selectedStatuses[currentStatuses?.[group.group]]
            )
            .map((group: TsGroupEntity) => group.group)

        if (groups.length === 0) {
            // when filtering by status results in no groups
            // we want to add a value that won't match any groups so that the
            // summary comes back empty
            // here we use an empty string assuming that empty string values
            // will not have been stored
            groups.push('')
        }

        return [
            {
                column: viewByGroupType.fieldMapping,
                operator: 'in',
                value: groups,
            },
        ]
    },
    teamHierarchy: ({ teamHierarchy }) => teamHierarchy,

    // common part of cache key
    cacheKey: (state, { hierarchyFilters }) => {
        const scorecardId = state.scorecard?.id ?? '-1'

        return `${scorecardId}_${state.timeRange.timeValue}_${
            state.timeRange.timeUnit
        }_${JSON.stringify(hierarchyFilters)}`
    },
    previousCacheKey: (state, { previousHierarchyFilters }) =>
        `${state.timeRange.timeValue}_${
            state.timeRange.timeUnit
        }_${JSON.stringify(previousHierarchyFilters)}`,

    getInsightsSummaryCacheKey:
        (state, { cacheKey, statusFilters }) =>
        (questionType) =>
            `${questionType}_summary_${cacheKey}_${JSON.stringify(
                statusFilters
            )}`,
    getInsightsDetailsCacheKey:
        (state, { cacheKey }) =>
        (questionType) =>
            `${questionType}_insights_${cacheKey}_${state.viewByHierarchyLevel}`,
    insightsStatusesCacheKey: (state, { cacheKey }) =>
        `status_${cacheKey}_${state.viewByHierarchyLevel}`,
    getInsightsRepeatVisitsCacheKey:
        (state, { cacheKey }) =>
        (questionType) =>
            `${questionType}_insights_repeatvisits_${cacheKey}_${state.viewByHierarchyLevel}`,
    getInsightsCoaching: (state, { cacheKey }) =>
        `insights_coaching_${cacheKey}_${state.viewByHierarchyLevel}`,
    getInsightsEngagement: (state, { cacheKey }) =>
        `insights_engagement_${cacheKey}_${state.viewByHierarchyLevel}`,
    // cache key for scorecard topics
    scorecardGroupsCacheKey: (state, { cacheKey }) =>
        `nps_scorecard_${cacheKey}_${state.viewByHierarchyLevel}`,
    previousInsightsStatusesCacheKey: (state, { previousCacheKey }) =>
        `status_${previousCacheKey}`,

    npsInsightsSummary: ({ cache }, { getInsightsSummaryCacheKey }) =>
        cache[getInsightsSummaryCacheKey('nps')],
    csatInsightsSummary: ({ cache }, { getInsightsSummaryCacheKey }) =>
        cache[getInsightsSummaryCacheKey('csat')],
    fivestarInsightsSummary: ({ cache }, { getInsightsSummaryCacheKey }) =>
        cache[getInsightsSummaryCacheKey('fivestar')],
    insightsDetails: (
        { cache },
        { getInsightsDetailsCacheKey, selectedQuestionType }
    ) => cache[getInsightsDetailsCacheKey(selectedQuestionType)],
    insightsCoaching: ({ cache }, { getInsightsCoaching }) =>
        cache[getInsightsCoaching],
    insightsEngagement: ({ cache }, { getInsightsEngagement }) =>
        cache[getInsightsEngagement],
    insightsRepeatVisits: (
        { cache },
        { getInsightsRepeatVisitsCacheKey, selectedQuestionType }
    ) => cache[getInsightsRepeatVisitsCacheKey(selectedQuestionType)],
    scorecardGroups: ({ cache }, { scorecardGroupsCacheKey }) =>
        cache[scorecardGroupsCacheKey],
    isLoadingInsightsData: (state) => state.isLoading,
    showFilters: (state) => state.showFilters,

    isRepeatVisitTrackingConfigured: ({ isRepeatVisitTrackingConfigured }) =>
        isRepeatVisitTrackingConfigured,

    currentlyLoading: ({ currentlyLoading }) => currentlyLoading,

    selectedTeamMemberId: ({ selectedTeamMemberId }) => selectedTeamMemberId,
    desktopEngagementThemeSelected: (state) =>
        state.desktopEngagementThemeSelected,
    desktopEngagementUserIdSelected: (state) =>
        state.desktopEngagementUserIdSelected,
    showNoticesComposer: (state) => state.showNoticesComposer,
    loadingNoticesComposer: (state) => state.loadingNoticesComposer,
    preselectedNoticesUserId: (state) => state.preselectedNoticesUserId,

    getInsightsTeamUpdatesCacheKey: (state, { cacheKey }) =>
        `insights_team_updates_${cacheKey}`,
    insightsTeamUpdatesData: ({ cache }, { getInsightsTeamUpdatesCacheKey }) =>
        cache[getInsightsTeamUpdatesCacheKey],
    insightsTeamUpdatesDataCacheKey: (state) =>
        ['t', state.timeRange.timeUnit, state.timeRange.timeValue].join('_'),

    insightsTeamUserData: (state) => state.insightsTeamUserData,
    teamUpdatesCardNotes: (state) => state.teamUpdatesCardNotes,

    insightErrorCode: (state) => state.insightErrorCode,
    insightNicelyError: (state) => state.insightNicelyError,
    insightUserError: (state) => state.insightUserError,
    insightTeamUserDataCacheKey: (state) => state.insightTeamUserDataCacheKey,
}

const actions: ActionTree<InsightsState, any> = {
    async loadTeamHierarchy({ commit }) {
        commit('beginLoading')
        const { data } = await getTsTeamGroups()
        commit('setTeamHierarchy', data)
        commit('endLoading')
    },

    async loadIsRepeatVisitTrackingConfigured({ commit }) {
        commit('beginLoading')

        try {
            if (process.env.CONFIG_KEY === 'mobile') {
                // Do we need this for web?
                const response: any = await getIsRepeatVisitTrackingConfigured()

                if (response.success) {
                    const {
                        data: { isRepeatVisitTrackingConfigured },
                    } = response
                    commit(
                        'setIsRepeatVisitTrackingConfigured',
                        isRepeatVisitTrackingConfigured
                    )
                }
            }
        } finally {
            commit('endLoading')
        }
    },
    async loadInsightsSummary({ commit, getters, state }, { questionType }) {
        commit('beginLoadingMetric', questionType)
        const { data } = await getTsSummary(
            '',
            [],
            getters.hierarchyFilters
                ? getters.hierarchyFilters.concat(getters.statusFilters)
                : [],
            '',
            `${state.timeRange.timeUnit}/${state.timeRange.timeValue}`,
            questionType,
            1
        )
        commit('setCache', {
            key: getters.getInsightsSummaryCacheKey(questionType),
            value: data,
        })
        commit('endLoadingMetric', questionType)
    },
    async loadInsightsRepeatVisits(
        { commit, getters, dispatch, state },
        { questionType }
    ) {
        commit('beginLoadingMetric', 'repeatVisits')
        const { data } = await getTsGroupRepeatVisitsSummary(
            getters.viewByGroupType ? getters.viewByGroupType.fieldMapping : '',
            '',
            getters.hierarchyFilters, // todo add other filters
            `${state.timeRange.timeUnit}/${state.timeRange.timeValue}`,
            questionType
        )

        commit('setCache', {
            key: getters.getInsightsRepeatVisitsCacheKey(questionType),
            value: data,
        })
        commit('endLoadingMetric', 'repeatVisits')
    },
    async loadInsightsDetails({ commit, getters, state }, { questionType }) {
        commit('beginLoading')
        const { data } = await getTsGroupSummary(
            getters.viewByGroupType ? getters.viewByGroupType.fieldMapping : '',
            '',
            getters.hierarchyFilters, // todo add other filters
            '', // todo unsure yet what this is for
            '',
            `${state.timeRange.timeUnit}/${state.timeRange.timeValue}`,
            questionType,
            0
        )
        commit('setCache', {
            key: getters.getInsightsDetailsCacheKey(questionType),
            value: data,
        })
        commit('endLoading')
    },
    async loadInsightsStatuses({ commit, getters, state }) {
        const { cache, timeRange } = state
        commit('beginLoading')
        const { data } = await getInsightsStatuses(
            getters.viewByGroupType ? getters.viewByGroupType.fieldMapping : '',
            timeRange.timeUnit,
            timeRange.timeValue
        )

        commit('setCache', {
            key: getters.insightsStatusesCacheKey,
            value: {
                ...cache[getters.insightsStatusesCacheKey],
                [getters.viewByGroupType
                    ? getters.viewByGroupType.fieldMapping
                    : '']: data,
            },
        })
        commit('endLoading')
    },

    async loadInsightsCoaching(
        { commit, getters, state },
        scorecardField = null
    ) {
        commit('beginLoadingMetric', 'insightsCoaching')

        let _insightCoaching
        try {
            const insightsCoachingCacheKey = [
                'c',
                scorecardField,
                state.timeRange.timeUnit,
                state.timeRange.timeValue,
            ].join('_')
            const insightsCoachingCache = getFromSessionStorage(
                insightsCoachingCacheKey
            )

            if (!insightsCoachingCache) {
                const { data } = await getCoachingInsights(
                    scorecardField,
                    state.timeRange.timeUnit,
                    state.timeRange.timeValue
                )

                let doCaching = true
                if (data !== null) {
                    if (
                        data.data === null ||
                        (Array.isArray(data.data) && data.data.length < 1)
                    ) {
                        doCaching = false
                    }
                }

                _insightCoaching = data

                if (doCaching) {
                    saveToSessionStorage(
                        insightsCoachingCacheKey,
                        JSON.stringify(_insightCoaching),
                        24
                    )
                }
            } else {
                _insightCoaching = JSON.parse(insightsCoachingCache)
            }
        } catch (e) {
            _insightCoaching = {
                success: false,
            }
        }

        commit('setCache', {
            key: getters.getInsightsCoaching,
            value: _insightCoaching,
        })

        commit('endLoadingMetric', 'insightsCoaching')
    },

    async loadInsightsEngagement({ commit, getters, state }) {
        commit('beginLoadingMetric', 'insightsEngagement')

        // ping every few seconds to server to check if data is ready
        let engagementData
        do {
            const { data } = await getEngagementInsights(
                state.timeRange.timeUnit,
                state.timeRange.timeValue
            )
            engagementData = data?.data ? data : undefined
            await sleep(3000)
        } while (!engagementData)

        commit('setCache', {
            key: getters.getInsightsEngagement,
            value: engagementData,
        })

        commit('endLoadingMetric', 'insightsEngagement')
    },

    async loadScorecardGroups({ commit, getters, state }) {
        // commit('beginLoading')
        const { data } = await getTsGroupSummary(
            getters.scorecardField,
            '',
            getters.hierarchyFilters,
            '',
            '',
            `${state.timeRange.timeUnit}/${state.timeRange.timeValue}`,
            '',
            0
        )
        data.tsgroups.sort((row1, row2) => {
            return row2.responded - row1.responded
        })
        commit('setCache', {
            key: getters.scorecardGroupsCacheKey,
            value: data,
        })
        // commit('endLoading')
    },

    // when filter/time range/hierarchy is changed, let's refresh data
    // if data is already there, it won't re-send ajax call, so safe to call multiple times
    async refreshData(
        { dispatch, getters, state, rootGetters },
        ignoreCache = false
    ) {
        // avoid duplicate calling
        if (state.isLoading) {
            return
        }
        // load coaching insights if not cached
        if (ignoreCache || !getters.insightsCoaching) {
            dispatch('loadInsightsCoaching')
        }
        // load engagement insights if not cached
        if (ignoreCache || !getters.insightsEngagement) {
            dispatch('loadInsightsEngagement')
        }
        // load nps insights if not cached
        if (ignoreCache || !getters.insightsDetails) {
            await dispatch('loadInsightsDetails', {
                questionType: getters.selectedQuestionType,
            })
        }
        // if statuses not loaded, let load that
        if (ignoreCache || !getters.currentStatuses) {
            await dispatch('loadInsightsStatuses')
        }

        let activeMetrics = []
        let needRepeatVisits = false
        if (process.env.CONFIG_KEY === 'mobile') {
            activeMetrics = rootGetters.activeMetrics

            needRepeatVisits =
                getters.getFeatureValue(
                    CompanyFeatures.enable_insights_tenure
                ) ||
                getters.getFeatureValue(
                    CompanyFeatures.enable_insights_repeat_customers
                ) ||
                getters.getFeatureValue(
                    CompanyFeatures.enable_insights_retention
                )
        } else {
            // TODO: Fix this.  I'm not sure why, but this getter is returning as a object rather than array.
            activeMetrics = Object.values(rootGetters['auth/getActiveMetrics'])

            needRepeatVisits =
                getters.$companyVars.enable_insights_tenure ||
                getters.$companyVars.enable_insights_repeat_customers ||
                getters.$companyVars.enable_insights_retention
        }
        activeMetrics.forEach((metric) => {
            if (ignoreCache || !getters[metric + 'InsightsSummary']) {
                dispatch('loadInsightsSummary', { questionType: metric })
            }
        })

        if (
            (ignoreCache || !getters.insightsRepeatVisits) &&
            needRepeatVisits
        ) {
            // load asynchronously
            dispatch('loadInsightsRepeatVisits', {
                questionType: getters.selectedQuestionType,
            })
        }
    },

    async loadGroupBadges({ commit, getters }) {
        const { data } = await getGroupBadges(getters.hierarchyFilters)
        commit('setBadges', data)
    },

    // user select a different metric (nps, repeat visit, etc)
    async selectMetric(
        { commit, dispatch, rootGetters },
        { selected, questionType }
    ) {
        commit('setSelectedMetric', selected)
        if (questionType) {
            commit('setSelectedQuestionType', questionType)
        }
        dispatch('refreshData')
    },

    setSelectedCoachingMetric({ commit }, selected) {
        commit('setSelectedCoachingMetric', selected)
    },

    setSelectedEngagementMetric({ commit }, selected) {
        commit('setSelectedEngagementMetric', selected)
    },

    setSelectedStatus({ commit, dispatch }, payload) {
        commit('setSelectedStatus', payload)
        dispatch('refreshData')
    },

    // user select a different time range
    updateTimeRange({ commit, dispatch }, { timeUnit, timeValue, path }) {
        commit('setTimeRange', { timeUnit, timeValue })

        // Insights page, Time Filter change, load different data based on the page path
        if (path && path.indexOf('engagement') !== -1) {
            dispatch('loadInsightsEngagement')
        } else if (path && path.indexOf('coaching') !== -1) {
            dispatch('loadInsightsCoaching')
        } else if (path && path.indexOf('teamupdates/detail') !== -1) {
            // Get the team member user ID, last param
            const userId = path.split('/').pop()
            dispatch('loadInsightsTeamUserData', { userId })
            // Need to load this for team member stats
            dispatch('loadInsightsTeamUpdatesData')
        } else if (path && path.indexOf('teamupdates') !== -1) {
            dispatch('loadInsightsTeamUpdatesData')
        } else {
            dispatch('refreshData')
        }
    },

    goBack({ commit, getters, dispatch, state }) {
        // update the type of data we're viewing in the table to the level of the group that we're on
        commit('setViewByHierarchyLevel', getters.currentGroupLevel)

        // remove the latest selected group (also should be the group with the highest index also known as the lowest in the hierarchy)
        commit('removeSelectedGroup', getters.currentGroupLevel)

        dispatch('refreshData')
    },

    drillDownOneLevel({ commit, getters, dispatch, state }, selectedValue) {
        // record the selected group
        commit('addSelectedGroup', selectedValue)
        // update the type of data we're viewing in the table to the next level in the hierarchy
        commit('setViewByHierarchyLevel', state.viewByHierarchyLevel + 1)

        dispatch('refreshData')

        // !!! before drill down to next level, load current level badges
        dispatch('loadGroupBadges')
    },

    selectViewByHierarchyLevel({ commit, dispatch }, level) {
        commit('setViewByHierarchyLevel', level)
        dispatch('refreshData')
    },

    resetInsights({ commit, dispatch }) {
        commit('clearSelectedGroups')
        commit('setViewByHierarchyLevel', 0)
        commit('clearSelectedStatuses')
        dispatch('refreshData')
    },

    setShowFilters({ commit }, value) {
        commit('setShowFilters', value)
    },

    setHighlightedGroup({ commit }, group: TsGroupEntity) {
        commit('setHighlightedGroup', group)
    },

    setSelectedTeamMemberId({ commit }, value) {
        commit('setSelectedTeamMemberId', value)
    },

    setDesktopEngagementThemeSelected({ commit }, theme) {
        commit('setDesktopEngagementThemeSelected', theme)
    },

    setDesktopEngagementUserIdSelected({ commit }, userId) {
        commit('setDesktopEngagementUserIdSelected', userId)
    },

    async setShowNoticesComposer(
        { commit, getters, dispatch },
        { show, userId }
    ) {
        if (!userId) {
            userId = getters.selectedTeamMemberId
        }
        if (show) {
            await dispatch('loadRecipients', parseInt(userId))
        }
        commit('setLoadingNoticesComposer', true)
        commit('setShowNoticesComposer', show)
        commit('setPreselectedNoticesUserId', userId)
        commit('setLoadingNoticesComposer', false)
    },

    setInsightErrorCode({ commit }, errorCode) {
        commit('setInsightErrorCode', errorCode)
    },

    setInsightNicelyError({ commit }, errorMessage) {
        commit('setInsightNicelyError', errorMessage)
    },

    setInsightUserError({ commit }, errorUserMessage) {
        commit('setInsightUserError', errorUserMessage)
    },

    async loadInsightsTeamUpdatesData({ commit, getters, state }) {
        let _insightTeamUpdates

        try {
            //const insightsTeamUpdatesCache = getFromSessionStorage(getters.insightsTeamUpdatesDataCacheKey)

            //todo:: this is for testing only since it needs to be getting the validating the data from backend
            const insightsTeamUpdatesCache = false

            if (!insightsTeamUpdatesCache) {
                const { data } = await getTeamUpdatesInsights(
                    state.timeRange.timeUnit,
                    state.timeRange.timeValue
                )

                let doCaching = true
                if (data !== null) {
                    if (
                        data.data === null ||
                        (Array.isArray(data.data) && data.data.length < 1)
                    ) {
                        doCaching = false
                    }
                }

                _insightTeamUpdates = data

                if (doCaching) {
                    saveToSessionStorage(
                        getters.insightsTeamUpdatesDataCacheKey,
                        JSON.stringify(_insightTeamUpdates),
                        24
                    )
                }
            } else {
                _insightTeamUpdates = JSON.parse(insightsTeamUpdatesCache)
            }
        } catch (e) {
            _insightTeamUpdates = {
                success: false,
            }
        }

        commit('setCache', {
            key: getters.getInsightsTeamUpdatesCacheKey,
            value: _insightTeamUpdates,
        })
    },

    async loadInsightsTeamUserData({ commit, getters, state }, { userId }) {
        let teamUserData

        try {
            const { data } = await getTeamUserInsights(
                userId,
                state.timeRange.timeUnit,
                state.timeRange.timeValue
            )

            teamUserData = data
        } catch (e) {
            teamUserData = {
                success: false,
            }
        }

        commit('setInsightsTeamUserData', teamUserData)
    },

    async setInsightsTeamCardNote(
        { commit, dispatch, getters, state },
        { cardId, cardType, note }
    ) {
        await addTeamUpdatesCardAction(cardId, cardType, 'note', note)

        commit('setTeamUpdatesCardNotes', {
            key: cardType + cardId,
            value: note,
        })

        let hasNote = '1'
        if (note === '') {
            hasNote = '0'
        }

        dispatch('setInsightsTeamCardActionData', {
            cardId,
            cardType,
            field: 'hasNote',
            value: hasNote,
        })
    },

    async setInsightsTeamCardActionData(
        { commit, getters, state },
        { cardId, cardType, field, value }
    ) {
        const teamUserData = getters.insightsTeamUserData
        const cardIndex = teamUserData.userdata.findIndex(
            (card) => card.id == cardId && card.type == cardType
        )

        if (typeof teamUserData.userdata[cardIndex] !== 'undefined') {
            teamUserData.userdata[cardIndex][field] = value
        }

        commit('setInsightsTeamUserData', teamUserData)
    },

    async loadInsightsTeamCardNote(
        { commit, getters, state },
        { cardId, cardType }
    ) {
        const { data } = await getTeamUpdatesCardNote(cardId, cardType)

        if (data.success) {
            commit('setTeamUpdatesCardNotes', {
                key: cardType + cardId,
                value: data.note,
            })
        }
    },

    async setTeamUpdateCardAction(
        { commit, getters, dispatch },
        { cardId, cardType, action, userId }
    ) {
        if (!userId) {
            userId = getters.selectedTeamMemberId
        }

        const { data } = await addTeamUpdatesCardAction(
            cardId,
            cardType,
            action
        )
    },

    /**
     * Mark a card as seen and also updates the 'Updates' count on the team page
     */
    async setTeamUpdateCardSeen(
        { commit, getters, dispatch },
        { cardId, cardType, userId }
    ) {
        // Update 'Updates' count on team page
        const _insightTeamUpdates = getters.insightsTeamUpdatesData

        // Get the current cached data and make the update
        const userStats = _insightTeamUpdates.teamdata.find(
            (a) => a.id == userId
        )
        if (userStats.updates !== 0) {
            userStats.updates--
        }

        // Update the session storage
        const expiry = getSessionStorageExpiry(
            getters.insightsTeamUpdatesDataCacheKey
        )
        saveToSessionStorage(
            getters.insightsTeamUpdatesDataCacheKey,
            JSON.stringify(_insightTeamUpdates),
            24,
            expiry
        )

        // Update the Vuex cache
        commit('setCache', {
            key: getters.getInsightsTeamUpdatesCacheKey,
            value: _insightTeamUpdates,
        })

        // Make the API call
        dispatch('setTeamUpdateCardAction', {
            cardId: cardId,
            cardType: cardType,
            action: 'seen',
            userId: userId,
        })
    },
}

const mutations: MutationTree<InsightsState> = {
    setInsightsTeamUserData(state, userData) {
        state.insightsTeamUserData = userData
    },

    setTeamUpdatesCardNotes(state, { key, value }) {
        state.teamUpdatesCardNotes = {
            ...state.teamUpdatesCardNotes,
            [key]: value,
        }
    },

    setTeamHierarchy(state, teamHierarchy: TsTeamGroupEntity[]) {
        state.teamHierarchy = teamHierarchy
    },

    setIsRepeatVisitTrackingConfigured(
        state,
        isRepeatVisitTrackingConfigured: boolean
    ) {
        state.isRepeatVisitTrackingConfigured = isRepeatVisitTrackingConfigured
    },

    setSelectedMetric(state, selected: InsightsMetric) {
        state.selectedMetric = selected
    },

    setSelectedQuestionType(state, questionType) {
        state.selectedQuestionType = questionType
    },

    setSelectedCoachingMetric(state, selected: CoachingMetricType) {
        state.selectedCoachingMetric = selected
    },

    setSelectedEngagementMetric(state, selected: EngagementMetricType) {
        state.selectedEngagementMetric = selected
    },

    setSelectedStatus(state, payload) {
        state.selectedStatuses = {
            ...state.selectedStatuses,
            [payload.status]: payload.value,
        }
    },

    clearSelectedStatuses(state) {
        for (const [status, value] of Object.entries(state.selectedStatuses)) {
            state.selectedStatuses = {
                ...state.selectedStatuses,
                [status]: false,
            }
        }
    },

    setViewByHierarchyLevel(state, lvl) {
        state.viewByHierarchyLevel = lvl
    },

    addSelectedGroup(state, value) {
        state.selectedGroups = {
            ...state.selectedGroups,
            [state.viewByHierarchyLevel]: value,
        }
    },

    clearSelectedGroups(state) {
        state.selectedGroups = {}
    },

    removeSelectedGroup(state, level) {
        delete state.selectedGroups[level]
        state.selectedGroups = { ...state.selectedGroups }
    },

    setCache(state, { key, value }) {
        state.cache = { ...state.cache, [key]: value }
    },

    setTimeRange(state, { timeUnit, timeValue }) {
        state.timeRange = { timeUnit, timeValue }
    },

    setScorecard(state, scorecard) {
        state.scorecard = scorecard
    },

    setBadges(state, badges) {
        state.badges = badges
    },

    beginLoading(state) {
        state.isLoading = true
    },

    endLoading(state) {
        state.isLoading = false
    },

    beginLoadingMetric(state, questionType) {
        state.currentlyLoading.push(questionType)
    },

    endLoadingMetric(state, questionType) {
        state.currentlyLoading = state.currentlyLoading.filter(
            (metric) => metric !== questionType
        )
    },

    setShowFilters(state, value) {
        state.showFilters = value
    },

    setHighlightedGroup(state, group: TsGroupEntity) {
        state.highlightedGroup = group
    },

    setSelectedTeamMemberId(state, value) {
        state.selectedTeamMemberId = value
    },

    setDesktopEngagementThemeSelected(state, theme) {
        state.desktopEngagementThemeSelected = theme
    },

    setDesktopEngagementUserIdSelected(state, userId) {
        state.desktopEngagementUserIdSelected = userId
    },

    setShowNoticesComposer(state, show) {
        state.showNoticesComposer = show
    },

    setLoadingNoticesComposer(state, loading) {
        state.loadingNoticesComposer = loading
    },

    setPreselectedNoticesUserId(state, userId) {
        state.preselectedNoticesUserId = userId
    },

    setInsightErrorCode(state, code) {
        state.insightErrorCode = code
    },

    setInsightNicelyError(state, extra) {
        state.insightNicelyError = extra
    },

    setInsightUserError(state, message) {
        state.insightUserError = message
    },

    //add insightTeamUserDataCacheKey to state
    setInsightTeamUserDataCacheKey(state, key) {
        state.insightTeamUserDataCacheKey = key
    },
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations,
}
