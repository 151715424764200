<template>
    <div class="feedback-container">
        <PullToRefreshWrapper
            :disable="
                haxIosDisableContentScrolling ||
                !showTopOverview ||
                overviewScrolling
            "
            :ptr-refreshing="refreshing"
            :refresh-container-classes="true ? 'full-height' : ''"
            @refresh-action="forceRefresh"
        >
            <TransitionGroup appear>
                <div key="content" class="content">
                    <TopOverview
                        v-if="showTopOverview"
                        key="overview"
                        :force-show-loading="forceShowLoading"
                        :should-refresh="refreshing"
                        @refresh-finished="onRefreshDone"
                        @carousel-touch-start="onOverviewTouchStart"
                        @carousel-touch-end="onOverviewTouchEnd"
                    />
                    <FeedbackResponses
                        :force-show-loading="forceShowLoading"
                        :should-refresh="refreshing"
                        @refresh-finished="onRefreshDone"
                    />
                </div>
            </TransitionGroup>
        </PullToRefreshWrapper>
    </div>
</template>

<script lang="ts">
import { Action, Getter, namespace } from 'vuex-facing-decorator'
import { useRoute } from 'vue-router'
import { Component, Watch, Vue } from 'vue-facing-decorator'
import PullToRefreshWrapper from '@/mobile/src/components/appV4/PullToRefreshWrapper.vue'
import TopOverview from '@/mobile/src/components/appV4/feedback/TopOverview.vue'
import FeedbackResponses from '@/mobile/src/components/appV4/feedback/FeedbackResponses.vue'

const MobileNavigationModule = namespace('mobilenavigation')

@Component({
    components: {
        FeedbackResponses,
        PullToRefreshWrapper,
        TopOverview,
    },
})
export default class Feedback extends Vue {
    @MobileNavigationModule.Getter haxIosDisableContentScrolling
    @Getter public mobileFilterActive
    @Getter public feedbackFilter

    @Action public setFeedbackFilter
    @Action public getFilters

    public showTopOverview = true
    public overviewScrolling = false

    // number of components need to be refreshed
    public numberOfComponentsToRefresh = 2
    public refreshingCount = this.numberOfComponentsToRefresh
    public refreshing = false
    // Reset Filter process will update mobileFilterActive several times.
    // This is used to make data reload once all Reset Filter processes are finished.
    public filterResetting = false
    private forceShowLoading = false

    private route = useRoute()

    public mounted() {
        if (!this.mobileFilterActive) {
            this.getFilters()
        }

        if (this.route.name === 'topicfeedback') {
            this.showTopOverview = false
        }
    }

    public onOverviewTouchStart() {
        this.overviewScrolling = true
    }

    public onOverviewTouchEnd() {
        this.overviewScrolling = false
    }

    public async forceRefresh() {
        // start force refresh
        this.refreshing = true
    }

    public async resettingFilters(status = true) {
        // start force refresh
        this.filterResetting = status

        if (status) {
            this.forceShowLoading = true
        }
    }

    public onRefreshDone() {
        this.refreshingCount--
        // once refreshingCount === 0, means all components are reloaded completely.
        if (this.refreshingCount <= 0) {
            this.refreshing = false

            if (this.forceShowLoading) {
                this.forceShowLoading = false
            }

            // reset the count for the next forceRefresh process.
            this.refreshingCount = this.numberOfComponentsToRefresh
        }
    }

    @Watch('mobileFilterActive')
    public onFilterChange() {
        const newFilterRules = JSON.stringify(this.mobileFilterActive)
        const filterRulesHaveChanged = newFilterRules !== this.feedbackFilter
        if (
            !this.refreshing &&
            !this.filterResetting &&
            filterRulesHaveChanged
        ) {
            this.setFeedbackFilter(newFilterRules)
            this.forceRefresh()
        } else if (
            this.forceShowLoading &&
            this.refreshingCount === this.numberOfComponentsToRefresh &&
            !filterRulesHaveChanged
        ) {
            this.forceShowLoading = false
        }
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';
</style>
