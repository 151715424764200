import { ActionTree, GetterTree, MutationTree } from 'vuex'

export interface State {
    isConnected: boolean
}

const INITIAL_STATE: State = {
    isConnected: false,
}

const actions: ActionTree<State, any> = {
    setIsConnected({ commit }, isConnected: boolean) {
        commit('setIsConnected', isConnected)
    },
}

const mutations: MutationTree<State> = {
    setIsConnected(state, isConnected: boolean) {
        state.isConnected = isConnected
    },
}

const getters: GetterTree<State, any> = {
    isConnected: ({ isConnected }) => isConnected,
}

export default {
    namespaced: true,
    state: INITIAL_STATE,
    getters,
    mutations,
    actions,
}
