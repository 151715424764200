import { registerPushDevice } from '@/mobile/src/api/auth'
import router from '@/mobile/src/router'
import store from '@/mobile/src/store'
import {
    getPlatform,
    updatePushNotificationPermissionsInStore,
} from '@/mobile/src/utils/capacitor'
import { isLocal } from '@/mobile/src/utils/misc'
import { Capacitor } from '@capacitor/core'
import {
    PushNotifications,
    PushNotificationSchema,
    Token,
    ActionPerformed,
} from '@capacitor/push-notifications'
import { pendoTrackEvent } from '@/utils/pendo'

export async function registerPushNotificationListener() {
    if (!Capacitor.isPluginAvailable('PushNotifications')) {
        return // early return to avoid errors when debugging in browser
    }

    // On success, we should be able to receive notifications
    PushNotifications.addListener(
        'registration',
        onPushNotificationRegistration
    )

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError', (error: any) => {
        alert('Error on registration: ' + JSON.stringify(error))
    })

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener(
        'pushNotificationReceived',
        onPushNotificationReceive
    )

    // Method called when tapping on a notification
    PushNotifications.addListener(
        'pushNotificationActionPerformed',
        onPushNotificationActionPerformed
    )
}

export default async function requestPushNotificationPermission() {
    if (
        store.getters.isImpersonating ||
        !Capacitor.isPluginAvailable('PushNotifications')
    ) {
        return // early return to avoid errors when debugging in browser or impersonating
    }

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then(async (result) => {
        // update permissions in store
        await updatePushNotificationPermissionsInStore()
        if (result.receive === 'granted') {
            // Register with Apple / Google to receive push via APNS/FCM
            await PushNotifications.register()
        } else {
            alert('You can enable push notification in settings later')
        }
    })
}

function onPushNotificationReceive(notification: PushNotificationSchema) {
    handlePushNotification(notification.data)
}

function onPushNotificationActionPerformed(notification: ActionPerformed) {
    handlePushNotification(notification.notification.data)
}

function handlePushNotification(data) {
    pendoTrackEvent('Mobile App - Push Notification Received (Mobile App)')

    const has_mlp = store.getters.userHasMlp // User with MLP flag enabled should be directed to App v4 screen
    switch (data?.type) {
        case 'notice':
            if (has_mlp) {
                router.push({
                    name: 'inbox',
                })
                return
            }
            router.push({
                name: 'notices',
                params: {
                    mailbox: 'received',
                    messageType: 'message',
                    messageId: data.message_id.toString(),
                },
            })
            break
        case 'report-card-comment':
            router.push({
                name: 'teams',
                query: { teamprofile: 'true' },
            })
            break
        case 'report-card':
        case 'scorecard-rank':
            if (has_mlp) {
                router.push({
                    name: 'overview',
                    query: { reportcard: 'true' },
                })
                return
            }
            router
                .push({
                    name: 'scorecardRank',
                })
                .catch((error) => {
                    if (error?.name !== 'NavigationDuplicated') {
                        throw error
                    }
                })
            break
        case 'suggestion':
            if (has_mlp) {
                router.push({
                    name: 'inbox',
                })
                return
            }
            router.push({
                name: 'suggestionsDetail',
                params: { id: data.suggestion_id.toString() },
            })
            break
        case 'urgent-task':
        case 'scorecard-response':
        case 'chat':
            if (has_mlp) {
                router.push({
                    name: 'feedbackdetail',
                    params: { feedbackid: data.questionid.toString() },
                    query: { fromPushNotification: Math.random() },
                })
                return
            }
            router.push({
                name: 'chatResponsesRoot',
                params: { id: data.questionid.toString() },
            })
            break
        case 'teamfeed-task':
            if (has_mlp) {
                router.push({
                    name: 'myfeedback',
                })
                return
            }
            router.push({
                name: 'mlpAct',
            })
            break
        case 'reels':
            router.push({
                name: 'reels',
            })
            break
    }
}

async function onPushNotificationRegistration(token: Token) {
    const platform = await getPlatform()
    await registerPushDevice(token.value, platform)
    await store.dispatch('dispatchStoreLogin')
    store.commit('setPushNotificationToken', token.value)
}

if (isLocal) {
    // expose handlePushNotification for cypress tests
    window.cypressTest = { ...window.cypressTest, handlePushNotification }
}
