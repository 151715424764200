<template>
    <Portal :to="to" :disabled="portalDisabled || disabled">
        <slot />
    </Portal>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { Portal } from 'portal-vue'
import Modal from '@/components/Modal.vue'

// this portal container:
// 1. mount the modal at root level using Vue-Portal component
// 2. captures Android back button and emit event
@Component({
    components: {
        Modal,
        Portal,
    },
    emits: ['back'],
})
export default class PortalContainer extends Vue {
    // mounting ponit for portal
    @Prop({ type: String, default: 'portal-destination' })
    public to!: string

    @Prop({ type: Boolean, default: false })
    public disabled!: boolean

    // don't move it if dest element not exist
    private portalDisabled = true
    private portalEl: HTMLElement | null = null

    private cbBackup

    onBack() {
        // on (Android) back pressed
        this.$emit('back')
    }

    beforeMount() {
        // take over global Android back button handler
        this.cbBackup = window.globalAndroidBackButtonHandler
        window.globalAndroidBackButtonHandler = this.onBack
    }

    beforeDestroy() {
        // restore global Android back button handler
        window.globalAndroidBackButtonHandler = this.cbBackup
    }

    private mounted() {
        this.portalEl = document.getElementById(this.to)
        this.portalDisabled = !this.portalEl
    }
}
</script>
