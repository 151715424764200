<template>
    <WebViewScreen title="New Suggestion" no-pad>
        <template #left-button>
            <BackButton />
        </template>
        <template #right-button>
            <div class="submit" @click="submitSuggestion">
                <button :disabled="!submittable || isSubmitting">
                    {{ !isSubmitting ? 'Post' : 'Posting' }}
                </button>
            </div>
        </template>

        <WebViewForm class="suggestion-composer" @submit="submitSuggestion">
            <fieldset>
                <label>Title</label>
                <div class="input-container">
                    <input
                        v-model="title"
                        class="editor-title"
                        placeholder="Suggestion title"
                    />
                </div>
            </fieldset>
            <fieldset class="expand editor-body">
                <label>Description</label>
                <MentionableEditor
                    :members="suggestionMentionUsers"
                    placeholder="Write suggestion description"
                    :text="body"
                    @input="onInputChange"
                    @mention="onMention"
                />
            </fieldset>
            <input :text="mentions" class="mentions" />
        </WebViewForm>
    </WebViewScreen>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { useRouter } from 'vue-router'
import { Action, Getter } from 'vuex-facing-decorator'
import WebViewScreen from '@/components/WebView/WebViewScreen.vue'
import WebViewForm from '@/components/WebView/WebViewForm.vue'
import MentionableEditor from '@/components/MentionableEditor.vue'
import BackButton from '@/mobile/src/components/BackButton.vue'
import { IRecipient } from '@/entities/notices'
import { Suggestion, SuggestionNew } from '@/entities/suggestions'

@Component({
    components: {
        BackButton,
        WebViewScreen,
        WebViewForm,
        MentionableEditor,
    },
})
export default class SuggestionCreateScreen extends Vue {
    @Getter public suggestionMentionUsers!: IRecipient[]

    @Action public loadSuggestionMentionUsers
    @Action public createSuggestion!: (
        suggestion: SuggestionNew
    ) => Promise<Suggestion>

    private router = useRouter()

    public title = ''
    public body = ''
    public isSubmitting = false

    protected mentions: IRecipient[] = []

    public goBack() {
        this.router.back()
    }

    public get submittable() {
        return this.title && this.body
    }

    public async submitSuggestionBase(event: Event) {
        this.isSubmitting = true
        event.preventDefault()
        const { title, body, mentions } = this
        if (this.submittable) {
            return await this.createSuggestion({ title, body, mentions })
        }
        this.isSubmitting = false
    }

    public async submitSuggestion(event: Event) {
        const res = await this.submitSuggestionBase(event)

        if (res) {
            await this.router.replace({
                name: 'suggestionsDetail',
                params: { id: res.id.toString() },
            })
        }
    }

    public onMention(mentions: IRecipient[]) {
        this.mentions = mentions
    }

    public onInputChange(text: string) {
        this.body = text
    }
}
</script>

<style scoped lang="less">
@import '../../styles/layout.less';
@import '../../styles/palette.less';
.suggestion-composer {
    .input-container {
        display: flex;
        width: inherit;

        .editor-title {
            width: initial;
            flex: 1;
        }
    }

    input::placeholder,
    textarea::placeholder {
        color: #a9a9a9;
        font-weight: normal !important;
        font-size: 16px;
        /*font-style: italic;*/
    }
    .editor-body {
        flex: 1;
        background-color: white;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .mentions {
        display: none;
    }

    fieldset {
        padding: 0;
        border: none;
    }
}

.submit {
    text-align: right;
    button {
        display: inline-block;
        color: #2e5bff;
        font-size: 18px;
        font-weight: bold;
        background: #fff;
        border: none;
        &:disabled {
            opacity: 0.4;
        }
    }
}
</style>
