/*
 * The input field isn't strictly equal to the inputType defined at back end,
 * Current input field listed the whole fields that front end can access to,
 * Might be modified later based on the latest user story.
 */

export const appstoreInput = `
$id: ID!,
$name: String,
$title: String,
$description: String,
$body: String,
$app_link: String,
$help_link: String,
$search_terms: String,
$only_show_for_logged_in: Boolean,
$image: ImageUpload,
$video_url: String,
$image_hero_url: ImageHeroUrlUpload,
$display: Boolean,
$type: String,
$status: String,
$linked_appstore_ids: [String],
$linked_learning_ids: [String]
`
export const appstoreInputData = `
id: $id,
name: $name,
title: $title,
description: $description,
body: $body,
app_link: $app_link,
help_link: $help_link,
search_terms: $search_terms,
only_show_for_logged_in: $only_show_for_logged_in,
image: $image,
video_url: $video_url,
image_hero_url: $image_hero_url,
display: $display,
type: $type,
status: $status,
linked_appstore_ids: $linked_appstore_ids,
linked_learning_ids: $linked_learning_ids
`

export const learningInput = `
$id: ID,
$title: String!,
$content: String,
$feature_on_appstore_homepage: Boolean,
$url: String,
$image_hero_url: LearningImageHeroUrlUpload,
$image: LearningImageUpload,
$published_at: Int,
$status: String,
$type: String,
$display: Boolean,
$only_show_for_logged_in: Boolean,
$search_terms: String,
$linked_appstore_ids: [String],
$unlinked_appstore_ids: [String]
`
export const learningInputData = `
id: $id,
title: $title,
content: $content,
feature_on_appstore_homepage: $feature_on_appstore_homepage,
url: $url,
image_hero_url: $image_hero_url,
image: $image,
published_at: $published_at,
status: $status,
type: $type,
display: $display,
only_show_for_logged_in: $only_show_for_logged_in,
search_terms: $search_terms,
linked_appstore_ids: $linked_appstore_ids,
unlinked_appstore_ids: $unlinked_appstore_ids
`
