<template>
    <div
        v-if="!showTeamProfileSelect"
        class="teams-page"
        @click="filterLostFocus(true)"
    >
        <TeamsFilter
            :key="refreshFilter"
            :title="title"
            :tenant="tenant"
            :back-label="backLabel"
            :has-network-connection="hasNetworkConnection"
            @toggleFilter="toggleTeamFilterSelected"
            @backAction="backAction"
            @resetFilters="resetFilters"
            @filterLostFocus="filterLostFocus"
        />
        <PullToRefreshWrapper
            :ptr-refreshing="refreshing"
            :refresh-container-classes="false ? 'full-height' : ''"
            @refresh-action="forceRefresh"
        >
            <div class="content">
                <OfflinePage v-if="!hasNetworkConnection" />
                <div v-else class="guttering">
                    <CubeWrapper :face="!showGoalTracking ? 'front' : 'right'">
                        <template #front>
                            <CXScorecard
                                class="cube__face cube__face--front"
                                :score-days="scoreDays"
                                @goal-clicked="
                                    () => toggleShowGoalTracking(true)
                                "
                            />
                        </template>
                        <template #right>
                            <GoalTracking
                                class="cube__face cube__face--right"
                                :on-back="() => toggleShowGoalTracking(false)"
                            />
                        </template>
                    </CubeWrapper>
                    <LocationGoalTimeline
                        v-if="loadingTopics"
                        :should-refresh="refreshing"
                        :filter-key="filterKey"
                        @drillDown="toggleTeamFilterSelected"
                        @refresh-finished="onRefreshDone"
                    />
                    <Topics
                        v-if="loadingTopics"
                        :should-refresh="refreshing"
                        :filter-key="filterKey"
                        @refresh-finished="onRefreshDone"
                    />
                    <TeamTopPerformer
                        :loading="refreshing"
                        title="TOP PERFORMER"
                        type="top-performer"
                    />
                    <TeamTopPerformer
                        :loading="refreshing"
                        title="MOST IMPROVED"
                    />
                </div>
            </div>
        </PullToRefreshWrapper>
    </div>
</template>

<script lang="ts">
import { Component, Watch, Vue } from 'vue-facing-decorator'
import { useRoute, useRouter } from 'vue-router'
import TeamsFilter from '@/mobile/src/components/appV4/TeamsFilter.vue'
import PillButton from '@/components/PillButton.vue'
import LocationGoalTimeline from '@/mobile/src/views/reportcard/LocationGoalTimeline.vue'
import CubeWrapper from '@/mobile/src/components/appV4/CubeWrapper.vue'
import CXScorecard from '@/mobile/src/widgets/CXScorecard.vue'
import NPSDisplay from '@/mobile/src/components/NPSDisplay.vue'
import GoalTracking from '@/mobile/src/components/appV4/GoalTracking.vue'
import TeamTopPerformer from '@/mobile/src/components/appV4/teams/TeamTopPerformer.vue'
import Topics from '@/mobile/src/components/appV4/teams/Topics.vue'
import { Getter, namespace } from 'vuex-facing-decorator'
import { TsTeamGroupEntity } from '@/entities/teamscoreboard'
import { truncateString } from '@/mobile/src/utils/misc'
import PullToRefreshWrapper from '@/mobile/src/components/appV4/PullToRefreshWrapper.vue'
import { UserRoleType } from '@/mobile/src/types/auth'
import { deepClone } from '@/utils/object'
import { getFilterKey } from '@/mobile/src/utils/topics'
import OfflinePage from '@/mobile/src/components/appV4/OfflinePage.vue'

const ScoredataModule = namespace('scoredata')
const TeamFilterModule = namespace('teamFilter')
const TimeFilterModule = namespace('timeFilter')
const ReportCardModule = namespace('reportcard')

@Component({
    components: {
        OfflinePage,
        PullToRefreshWrapper,
        PillButton,
        TeamsFilter,
        CubeWrapper,
        CXScorecard,
        NPSDisplay,
        LocationGoalTimeline,
        GoalTracking,
        TeamTopPerformer,
        Topics,
    },
})
export default class Teams extends Vue {
    private showGoalTracking = false
    private scoreDays = '30' // days
    private backLabel = ''
    private title = ''
    private tenant = ''
    private maxTitleLength = 18
    private numberOfComponentsToRefresh = 3
    private refreshingCount = this.numberOfComponentsToRefresh
    private refreshing = false
    private filterKey = ''
    private loadingTopics = false
    private refreshFilter = Math.random()

    private route = useRoute()
    private router = useRouter()

    @Getter public $companyVars

    @ScoredataModule.Action loadScoreData
    @TimeFilterModule.Getter timeFilterSelected

    @TeamFilterModule.Getter teamFilterSelected
    @TeamFilterModule.Getter teamParentFilterSelected
    @TeamFilterModule.Getter backToPrevious
    @TeamFilterModule.Getter filterOptions
    @TeamFilterModule.Getter teamFilterLoading
    @TeamFilterModule.Action toggleSelected
    @TeamFilterModule.Action addHistory
    @TeamFilterModule.Action removeHistory
    @TeamFilterModule.Action loadFilterOptions
    // Only for the frontline staff log in.
    @TeamFilterModule.Action loadTeamParent
    @TeamFilterModule.Action setSelectedParentFilter

    @ReportCardModule.Getter showTeamProfileSelect!: boolean
    @ReportCardModule.Action setShowTeamProfileSelect!: (show: boolean) => void
    @Getter userRole
    @Getter userName
    @Getter userRoleType
    @Getter teamFilterAdminRight

    @Getter hasNetworkConnection!: boolean

    @Watch('timeFilterSelected')
    @Watch('teamFilterSelected')
    public filterChanged() {
        const newFilterKey = getFilterKey(
            this.teamFilterSelected,
            this.timeFilterSelected
        )
        const filterRulesHaveChanged = newFilterKey !== this.filterKey

        if (filterRulesHaveChanged && !this.teamFilterLoading) {
            this.filterKey = newFilterKey
            this.loadData()
        }
    }

    public filterLostFocus(clicked: boolean) {
        if (clicked) {
            // hide Filter component.
            this.refreshFilter = Math.random()
        }
    }

    public onRefreshDone() {
        this.refreshingCount--
        // once refreshingCount === 0, means all components are reloaded completely.
        if (this.refreshingCount === 0) {
            this.refreshing = false
            // reset the count for the next forceRefresh process.
            this.refreshingCount = this.numberOfComponentsToRefresh
        }
    }

    public async forceRefresh() {
        this.refreshing = true
        await this.loadData()
        this.onRefreshDone()
    }

    @Watch('title')
    public truncateTitle() {
        // allow maximum X number of characters in title.
        this.title = truncateString(this.title, this.maxTitleLength)
    }

    public async mounted() {
        this.title = this.tenant = this.$companyVars.name
        await this.loadData()
        this.triggerTeamProfileSelect()
    }

    private triggerTeamProfileSelect() {
        if (this.route.query.teamprofile) {
            // show team profile popup from PushNotification
            this.setShowTeamProfileSelect(true)
            this.router.replace({ query: {} })
        }
    }

    public async loadData() {
        if (this.teamFilterLoading) {
            return
        }

        // Set ParentFilter only if the current user manages some team members
        // So after login, on Teams, user only sees the team members he/she managed.
        if (
            this.filterOptions.length > 1 &&
            this.userRole?.includes(this.filterOptions[0]?.groupName) &&
            this.userRoleType === UserRoleType.FrontlineManager &&
            !this.teamFilterAdminRight
        ) {
            // Assuming the frontline manager filter button should be the first one of team filters
            this.setSelectedParentFilter({
                ...this.filterOptions[0],
                rankWithinName: this.userName,
            })
            // Show the title bar as current login username.
            this.title = this.userName
        }

        // This is necessary to force <Topics> loaded after above this.setSelectedParentFilter() is called.
        this.loadingTopics = true

        if (this.userRoleType === UserRoleType.FrontlineStaff) {
            // If current login user is frontline staff, load only its own team members
            await this.loadTeamParent()
        }

        await this.loadScoreDataByFilter()
        if (!this.backLabel) {
            // reload the TITLE bar name and BACK button to current state when back from the Overview page
            await this.toggleTeamFilterSelected(this.teamFilterSelected, true)
        }
    }

    /**
     * Load data for NPS summary component by drilling down the Hierarchy data,
     * i.e. Country >> State >> City >> Gym Manager ....
     */
    public async loadScoreDataByFilter() {
        // Apply hierarchy data as team filter to NPS graph/score
        let extraFilter
        let overwriteFilter = false
        if (this.userRoleType === UserRoleType.FrontlineStaff) {
            // Login as frontline staff, only see the data for the team.
            extraFilter = this.teamParentFilterSelected?.rankWithinName
                ? {
                      column: this.teamParentFilterSelected?.fieldMapping,
                      operator: 'in',
                      value: [this.teamParentFilterSelected?.rankWithinName],
                  }
                : {}
            overwriteFilter = true
        } else {
            extraFilter = this.teamFilterSelected?.customSelectionName
                ? {
                      column: this.teamFilterSelected?.fieldMapping,
                      operator: 'in',
                      value: [this.teamFilterSelected?.customSelectionName],
                  }
                : {}
        }

        // Load the NPS Summary data by applying the parent scope,
        // so the NPS summary data will match the data in the Hierarchy data list.
        const extraFilterParent = this.teamFilterSelected?.rankWithinName
            ? {
                  column: this.teamFilterSelected?.rankWithin,
                  operator: 'in',
                  value: [this.teamFilterSelected?.rankWithinName],
              }
            : {}

        this.scoreDays = this.timeFilterSelected.value
        await this.loadScoreData({
            days: this.timeFilterSelected.value,
            extraFilter: [extraFilter, extraFilterParent],
            forceRefresh: this.refreshing,
            overwriteFilter: overwriteFilter,
        })
    }

    public toggleShowGoalTracking(show: boolean) {
        this.showGoalTracking = show
    }

    resetFilters() {
        this.backLabel = ''
        this.title = this.$companyVars.name
    }

    // Toggle team filter selected and get the Back label
    public toggleTeamFilterSelected(
        option: TsTeamGroupEntity,
        fromBackButton = false,
        parentOption: TsTeamGroupEntity | null = null
    ) {
        this.toggleSelected(option)

        // get the history first, then add new history after
        if (fromBackButton) {
            this.backLabel =
                this.backToPrevious?.rankWithinName !== ''
                    ? this.backToPrevious?.rankWithinName
                    : this.backToPrevious?.groupName
        } else {
            this.backLabel =
                this.backToPrevious?.customSelectionName ??
                this.backToPrevious?.groupName
        }

        this.title = option
            ? option.customSelectionName ?? this.$companyVars.name
            : this.$companyVars.name

        // Get first Team filter item
        const firstTeamFilter = this.filterOptions ? this.filterOptions[0] : {}
        if (firstTeamFilter && !option?.customSelectionName) {
            // reach the root item (first Team filter), remove back button display
            this.backLabel = ''
            if (
                this.userRoleType === UserRoleType.FrontlineManager &&
                this.userRole?.includes(this.teamFilterSelected?.groupName)
            ) {
                this.title = this.userName
            } else {
                this.title = this.$companyVars.name
            }
        } else if (option && this.backLabel === option.groupName) {
            // If click back from Overview page, backLabel === current Title
            // remove the last Back history item, to make Back label one level higher than current Title selection.
            this.removeHistory()
            this.backLabel =
                this.backToPrevious?.customSelectionName ??
                this.backToPrevious?.groupName
        }

        // If the backLabel is the same as current user role, hide it.
        if (
            this.userRoleType === UserRoleType.FrontlineManager &&
            this.userRole?.includes(this.backToPrevious?.groupName)
        ) {
            this.backLabel = ''
        }

        // If not call from Back button click, add to navigation history
        if (!fromBackButton) {
            // Allow to add back Parent filter to the Back history.
            let historyOption = { ...option }
            // If login user is Frontline Manager, and only view its team list: TRUE
            // If login user is Admin with Frontline Manager type, no (locked/scorecard) filter setup: FALSE.
            if (
                this.userRoleType === UserRoleType.FrontlineManager &&
                this.filterOptions.length > 1 &&
                this.userRole?.includes(this.filterOptions[0]?.groupName)
            ) {
                historyOption = deepClone(option)
                historyOption.customSelectionName = this.userName
            }
            this.addHistory({
                filterOption: historyOption,
                parentFilterOption: parentOption,
            })

            if (parentOption) {
                // Set backLabel to parentOption
                this.backLabel =
                    parentOption.customSelectionName ?? parentOption.groupName
            }
        }
    }

    // back to previous level in the Team hierarchy
    public backAction(): void {
        // then remove the last history item
        this.removeHistory()
        // get last history item, to select the Filter button
        let history = this.backToPrevious

        // highlight the team filter button.
        this.toggleTeamFilterSelected(history, true)
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/variables.less';
@import '~@/styles/rain/variables.less';
@import '~@/styles/palette.less';

.teams-page {
    .content {
        margin-top: @filterHeaderHeight;
        .guttering {
            padding-top: @featureGutterThick;
        }

        div {
            margin-bottom: @featureGutter;
        }
    }
}
</style>
