<template>
    <HorizontalScrollContainer
        :class="{ 'goal-list-centered': centered }"
        class="goal-list"
    >
        <template v-if="goalHistory.length > 0">
            <div
                v-for="(item, itemIdx) in goalHistory"
                :key="itemIdx"
                class="goal-item"
                :class="{ reverse: textAboveDot }"
            >
                <span class="month-label"><Typography variant="subtitle2" class="month-text">{{
                        item.title
                    }}</Typography></span>
                <div class="circle" :class="item.achieved ? 'filled' : ''" />
            </div>
        </template>
        <div
            v-show="!goalHistory || goalHistory.length === 0"
            class="goal-placeholder"
        ></div>
    </HorizontalScrollContainer>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import HorizontalScrollContainer from '@/components/HorizontalScrollContainer.vue'
import { IYearInGoalItem } from '@/entities/reportcard'
import Typography from '@/components/Rain/Typography/Typography.vue'

@Component({
    components: {
        Typography,
        HorizontalScrollContainer,
    },
})
export default class GoalHistory extends Vue {
    @Prop({ type: Array, default: [] }) public goalHistory!: IYearInGoalItem[]
    @Prop({ type: Boolean, default: false }) public centered!: boolean
    @Prop({ type: Boolean, default: false }) public textAboveDot!: boolean
}
</script>

<style scoped lang="less">
@import '~@/styles/variables';
@import '~@/styles/rain/variables.less';

.goal-list {
    display: flex;
    flex-direction: row-reverse;
    gap: @gutterSpacing;
    padding: @gutterSpacing;

    scroll-snap-type: x mandatory;
    scroll-padding: 0 @gutterSpacing;

    > .goal-item {
        scroll-snap-align: start;
        display: flex;
        flex-direction: column;
        margin-right: 10px;
        align-items: center;
    }

    > .reverse {
        flex-direction: column-reverse;
        margin: 0 @featureGutter;
        .month-label {
            padding-top: @gutterSpacing-base;
            .month-text {
                text-transform: none;
            }
        }
    }

    .goal-placeholder {
        height: 41px;
    }
}

.goal-list-centered {
    flex-direction: row-reverse;
    align-content: center;
    justify-content: center;
    > .goal-item {
        margin: 0 @featureGutter;
    }
}

.month-label {
    padding-bottom: 5px;
    .month-text {
        color: white;
    }
}

.circle {
    border-radius: 50%;
    height: 15px;
    width: 15px;
    border: 1px solid white;
}

.filled {
    background-color: white;
}
</style>
