<template>
    <ItemDetail
        :feedbackid="feedbackid"
        :momentid="momentid"
        @message-sent="onMessageSent"
    >
        <template #detailContent>
            <div v-if="errorMomentDetails" class="error-message">
                <ErrorPage />
            </div>
            <div v-else-if="!loadingMomentDetails">
                <MomentCard
                    :key="momentDetails.id"
                    :moment="momentDetails"
                    :has-count="false"
                    :emoji-view="showEmoji"
                    detailed-view
                    @onReact="onReact"
                />
                <MomentCard
                    v-for="moment in momentDetails.replies"
                    :key="moment.id"
                    :ref="momentSeenRef(moment)"
                    :moment="moment"
                    :has-count="false"
                    disable-highlight
                    detailed-view
                />
            </div>
            <LoadingCard
                v-for="cards in loadingCardCount"
                v-else
                :key="cards"
                :borderless="true"
                variant="single"
                class="loading-single"
            />
        </template>
    </ItemDetail>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import useEmitter from '@/composables/useEmitter'
import { namespace } from 'vuex-facing-decorator'
import { useRouter } from 'vue-router'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { MomentMessageRecipient } from '@/entities/moment'
import ItemDetail from '@/mobile/src/views/appV4/ItemDetail.vue'
import MomentCard from '@/mobile/src/components/appV4/MomentCard.vue'
import LoadingCard from '@/mobile/src/components/appV4/LoadingCard.vue'
import ErrorPage from '@/mobile/src/components/appV4/ErrorPage.vue'
import { scrollToBottom } from '@/mobile/src/utils/scrollbus'
import { IEmojiReactionUpdateData, IOnReact } from '@/entities'
import { updateNoticeEmoji } from '@/api/notices'
import { updateEmojiReactionDetails } from '@/mobile/src/utils/emojireactions'

const MomentsModule = namespace('moments')

@Component({
    components: {
        ErrorPage,
        LoadingCard,
        MomentCard,
        ItemDetail,
        FontAwesomeIcon,
    },
})
export default class MomentDetail extends Vue {
    @Prop({ type: String, default: null }) feedbackid?: string
    @Prop({ type: String, default: null }) momentid?: string

    @MomentsModule.Action setSelectedRecipients
    @MomentsModule.Action loadMomentDetails
    @MomentsModule.Getter momentDetails
    @MomentsModule.Getter loadingMomentDetails
    @MomentsModule.Getter errorMomentDetails

    public loadingCardCount = 4

    public scrollTimer!: NodeJS.Timeout
    public loadingCancelled = false

    private router = useRouter()
    private emitter = useEmitter()

    public get showEmoji(): boolean {
        return (
            this.momentDetails.source_id &&
            this.momentDetails.source_type === 'notices'
        )
    }

    public async mounted() {
        await this.loadMomentDetails(this.momentid)
        if (
            !this.loadingCancelled &&
            Object.keys(this.momentDetails.replies).length
        ) {
            this.scrollTimer = setTimeout(this.scrollToUnread, 200)
        }
    }

    async created() {
        let customerObject = false

        if (customerObject) {
            // load data from API and check for customer name or email
            let defaultRecipient: MomentMessageRecipient = {
                type: 'user',
                name: 'Default Customer name or email',
                description: 'Prefill customer into the recipient list',
                user_id: 9999,
                field: '',
                value: '',
                role: '',
                sender: false,
                avatar: '',
                usernames: ['defaultUser'],
                selectableForSending: true,
            }
            this.setSelectedRecipients([defaultRecipient])
        } else {
            // clear recipients on load
            this.setSelectedRecipients([])
        }
    }

    beforeDestroy() {
        this.loadingCancelled = true
        clearTimeout(this.scrollTimer)
    }

    public goBack() {
        this.router.go(-1)
    }

    public momentSeenRef(moment) {
        return moment.firstNotSeen ? 'firstNotSeen' : 'chat'
    }

    public scrollToUnread() {
        const lastUnreadReply = this.$refs.firstNotSeen
        if (
            lastUnreadReply &&
            Array.isArray(lastUnreadReply) &&
            lastUnreadReply.length
        ) {
            // Avoiding multitype complaints
            (lastUnreadReply[0] as any).$el.scrollIntoView({
                behavior: 'smooth',
            })
        } else {
            scrollToBottom(this.emitter, 'smooth')
        }
    }

    public onMessageSent() {
        this.loadMomentDetails(this.momentid).then(() => {
            const messageCards =
                document.getElementsByClassName('moment-container')
            if (messageCards) {
                const lastCard = messageCards.item(
                    messageCards?.length - 1 ?? 0
                ) as HTMLDivElement
                lastCard.scrollIntoView({ behavior: 'smooth' })
            }
        })
    }

    private async onReact(data: IOnReact) {
        try {
            const updateData: IEmojiReactionUpdateData = {
                emoji: data.emoji,
                emojis: this.momentDetails.emojis,
            }

            updateEmojiReactionDetails(updateData)
            this.momentDetails.emojis = updateData.emojis
            await updateNoticeEmoji(this.momentDetails.source_id, data.emoji)
        } catch (e) {
            // Undo the count and self flag change.
            // For some reason, anything that isn't status 200 is thrown.
            updateEmojiReactionDetails(this.momentDetails)
        }
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/variables.less';
@import '~@/styles/variables.less';

.loading-card {
    margin-top: @featureGutter;
}

.error-message {
    margin-top: 105px;
    height: 100%;
}
</style>
