import { AxiosInstance, AxiosResponse } from 'axios'
import AsyncStorage from '@/mobile/src/store/asyncstorage'

const offlineCacheStorage = new AsyncStorage()

export class OfflineCache {
    private module = ''
    private expiry = 0

    private cachedKeys: { [key: string]: boolean } = {}

    private get storage() {
        // can be replaced to other storages like SQLLite
        return offlineCacheStorage
    }

    constructor(module: string, expiry?: number) {
        this.module = module
        if (expiry) {
            this.expiry = expiry
        }

        if (process.env.CONFIG_KEY === 'mobile') {
            this.rehydrate()
        }
    }

    private async rehydrate() {
        // try to restore from local storage
        const restored = await this.get<{ [key: string]: boolean }>(
            'cachedKeys'
        )
        if (restored) {
            this.cachedKeys = restored
        }
    }

    private getFullKey(key: string): string {
        return `${this.module}_${key}`
    }

    public async length() {
        await this.rehydrate()
        let valueTotalLength = 0
        for (const key of Object.keys(this.listKeys())) {
            const cachedValue = await this.get(key)
            valueTotalLength += JSON.stringify(cachedValue).length
        }
        return valueTotalLength
    }

    public listKeys() {
        return this.cachedKeys
    }

    public clear() {
        Object.keys(this.cachedKeys).forEach((k) => {
            this.remove(k)
        })
    }

    public async get<T>(key: string) {
        const v = await this.storage.getItem<T>(this.getFullKey(key))
        if (!v) {
            return null
        }
        return v
    }

    public async remove(key: string) {
        await this.storage.removeItem(key)

        // maintain key list
        delete this.cachedKeys[key]
        this.storage.setItem(this.getFullKey('cachedKeys'), this.cachedKeys)
    }

    public async set(key: string, value: any, expiry?: number) {
        const exp = expiry ? expiry : this.expiry
        // TODO Handle expiry
        this.storage.setItem(this.getFullKey(key), value)
        // maintein key list
        this.cachedKeys[key] = true
        this.storage.setItem(this.getFullKey('cachedKeys'), this.cachedKeys)
    }

    // @todo remove expiry parameter which is not used.
    public async cachedGet<T>(
        client: AxiosInstance,
        url: string,
        expiry?: number,
        options?: any
    ): Promise<AxiosResponse<T>> {
        const isMobile = process.env.CONFIG_KEY === 'mobile'
        const hasNetworkConnection =
            process.env.CONFIG_KEY === 'mobile'
                ? require('@/mobile/src/store').default.getters
                      .hasNetworkConnection
                : true

        if (isMobile && !hasNetworkConnection) {
            const cachedResp = await this.get<AxiosResponse<T>>(url)
            if (cachedResp) {
                return cachedResp
            }
        }

        let resp
        if (options && typeof options.params != undefined) {
            resp = await client.get<T>(url, options)
        } else {
            resp = await client.get<T>(url)
        }
        // TODO: do we need save to cache if not in offline mode?
        if (isMobile && resp) {
            this.set(url, resp)
        }

        return resp
    }
}
