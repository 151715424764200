<template>
    <FeatureContainer class="goal-tracking">
        <TextBackButton :on-click="onBack" />
        <div class="goal-gift-box">
            <img src="@/assets/img/golden-giftbox.svg" alt="Goal!" />
        </div>
        <div class="goal-text-box">
            <Typography variant="subtitle1" class="goal-text">Achieve the goal {{ month }} to unlock the following reward:
                {{ goalTrackingData.reward }}</Typography>
        </div>
        <GoalHistory
            :goal-history="goalTrackingData.goalTrackingByMonth"
            :centered="goalTrackingData.period < 5"
            :text-above-dot="true"
        />
    </FeatureContainer>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import GoalHistory from '@/mobile/src/components/reportcard/GoalHistory.vue'
import { Getter, namespace } from 'vuex-facing-decorator'
import FeatureContainer from '@/mobile/src/components/appV4/FeatureContainer.vue'
import Typography from '@/components/Rain/Typography/Typography.vue'
import TextBackButton from '@/mobile/src/components/appV4/TextBackButton.vue'

const GoalTrackingModule = namespace('goals')

@Component({
    components: {
        TextBackButton,
        Typography,
        FeatureContainer,
        GoalHistory,
    },
})
export default class GoalTracking extends Vue {
    @Prop({ type: Function, required: true }) public onBack!: () => void

    @Getter userRoleType
    @GoalTrackingModule.Getter goalTrackingData

    get month() {
        if (this.goalTrackingData.period > 1) {
            return 'for ' + this.goalTrackingData.period + ' consecutive months'
        }
        return 'this month'
    }
}
</script>

<style scoped lang="less">
@import '~@/styles/rain/variables.less';

.goal-tracking {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    min-height: 215px;

    .goal-text-box {
        padding: 0 @gutterSpacing-md;
        padding-bottom: @gutterSpacing-base;
        .goal-text {
            text-align: center;
            color: white;
            opacity: 0.9;
            text-transform: none;
        }
    }

    .goal-gift-box {
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 55px;
        img {
            position: relative;
            height: 158px;
            top: -83px;
        }
    }
}
</style>
