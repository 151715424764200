import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d783a488"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pill-search-input-content" }
const _hoisted_2 = { class: "search-icon" }
const _hoisted_3 = {
  key: 0,
  class: "search"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_BasePillButton = _resolveComponent("BasePillButton")!

  return (_openBlock(), _createBlock(_component_BasePillButton, {
    class: "pill-search-input",
    onClick: _ctx.showSearchInput
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_FontAwesomeIcon, {
            icon: _ctx.faSearch,
            size: "lg"
          }, null, 8, ["icon"])
        ]),
        _createVNode(_Transition, { name: "slide-right" }, {
          default: _withCtx(() => [
            (_ctx.searchInputActive)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("div", {
                    ref: "searchinput",
                    contenteditable: "true",
                    class: "search-input",
                    onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args)))
                  }, null, 544),
                  _createElementVNode("div", {
                    class: "close-icon",
                    onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.closeSearchInput && _ctx.closeSearchInput(...args)), ["stop"]))
                  }, [
                    _createVNode(_component_FontAwesomeIcon, {
                      icon: _ctx.faClose,
                      size: "lg"
                    }, null, 8, ["icon"])
                  ])
                ]))
              : _createCommentVNode("", true)
          ], undefined, true),
          _: 1
        })
      ])
    ], undefined, true),
    _: 1
  }, 8, ["onClick"]))
}