import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { ref: "selected-recipient-pills" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tab = _resolveComponent("Tab")!
  const _component_Tabs = _resolveComponent("Tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Tabs, {
      class: _normalizeClass(['selected-recipient-pills', { mobile: _ctx.$isMobile }]),
      vsmall: !_ctx.$isMobile,
      pills: "",
      "can-remove-tabs": _ctx.canRemoveTabs,
      "can-remove-single-tabs": _ctx.canRemoveSingleTabs,
      onRemove: _ctx.removeRecipient,
      onChange: _ctx.removeRecipient
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedRecipients, (recipient) => {
          return (_openBlock(), _createBlock(_component_Tab, {
            key: recipient.name,
            name: recipient.name,
            "is-active": ""
          }, null, 8, ["name"]))
        }), 128))
      ], undefined, true),
      _: 1
    }, 8, ["class", "vsmall", "can-remove-tabs", "can-remove-single-tabs", "onRemove", "onChange"])
  ], 512))
}