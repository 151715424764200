<template>
    <div class="gradient-highlighted-card">
        <div class="background">
            <div class="inner">
                <slot />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import BaseCard from '@/components/BaseCard.vue'
import { Component, Vue } from 'vue-facing-decorator'

@Component({
    components: {
        BaseCard,
    },
})
export default class GradientHighlightedCard extends Vue {}
</script>

<style scoped lang="less">
@import '~@/styles/palette';
@import '~@/styles/variables';

@gradientBorderWidth: 2px;
@gradientBorderRadius: @borderRadius * 2;

.background {
    padding: @gradientBorderWidth;
    background: linear-gradient(@blue 0%, rgba(224, 231, 255, 0) 20vh);
    border-radius: @gradientBorderRadius;

    // Apply flex to prevent margins collapsing to the parent of this component
    display: flex;
    flex-direction: column;
}

.inner {
    background-color: @white;

    // gives a nice inner radius that is slightly tighter than the outer radius
    border-radius: @gradientBorderRadius - @gradientBorderWidth;
}
</style>
