<template>
    <EmptyPageState colour="white" :class="['offline-container', bordered]">
        <template #icon-img>
            <img
                class="wifi-icon"
                src="@/assets/img/Icons/offline-white.svg"
                alt="wifi icon"
            />
        </template>
        <template #header-text>You're Currently Offline</template>
        <template #body-text>
            You can access this when you're back online.
        </template>
    </EmptyPageState>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import EmptyPageState from '@/mobile/src/components/EmptyPageState.vue'
import MultiMetricSelect from '@/mobile/src/components/appV4/MultiMetricSelect.vue'

@Component({
    components: {
        MultiMetricSelect,
        EmptyPageState,
    },
})
export default class OfflinePage extends Vue {
    @Prop({ type: Boolean, default: false })
    public bordered?: boolean
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';
@import '~@/styles/variables.less';

.bordered {
    border: 1px dashed @white50;
    padding: @featureGutter;
    border-radius: @borderRadius-md;
}
.wifi-icon {
    height: 48px;
}
.offline-container {
    height: calc(100vh - @mobileNavHeightPadded - @filterHeaderHeight);
}
</style>
