import { ActionTree, MutationTree, GetterTree } from 'vuex'
import { EngagementQuestion } from '@/entities/engagement'
import {
    answerEngagementQuestion,
    getDailyEngagementQuestions,
    markEngagementQuestionsAsOpen,
} from '@/api/engagement'

export interface EngagementState {
    dailyEngagementQuestions: EngagementQuestion[]
    engagementModalOpened: boolean
}

export const state: EngagementState = {
    dailyEngagementQuestions: [],
    engagementModalOpened: false, // open the modal by default
}

const getters: GetterTree<EngagementState, any> = {
    dailyEngagementQuestions({ dailyEngagementQuestions }) {
        return dailyEngagementQuestions
    },
    shouldShowEngagementModal({ engagementModalOpened }, getters) {
        // If the modal hasn't been closed manually and has question to show, show the modal
        if (engagementModalOpened && getters.dailyEngagementQuestionToShow) {
            return true
        }
        return false
    },
    hasUnopenedEngagementQuestions({ dailyEngagementQuestions }) {
        return !!dailyEngagementQuestions.find((q) => !q.opened_at)
    },
    hasUnansweredQuestions({ dailyEngagementQuestions }) {
        return !!dailyEngagementQuestions.find((q) => !q.answered_at)
    },
    dailyEngagementQuestionToShow({ dailyEngagementQuestions }) {
        for (const question of dailyEngagementQuestions) {
            if (!question.answered_at) {
                return question
            }
        }
        return null
    },
}

const actions: ActionTree<EngagementState, any> = {
    setEngagementModalOpened({ commit }, value: boolean) {
        commit('setEngagementModalOpened', value)
    },

    async loadDailyEngagementQuestions({ commit, rootGetters, getters }) {
        if (
            // Don't load questions if on mobile and V4 is enabled for both company and logged user
            rootGetters.isUsingAppV4 ||
            rootGetters['auth/isFrontlineEngagementUser'] ||
            // Don't load questions if it's not a frontline engagement user
            !(
                (process.env.CONFIG_KEY === 'mobile' &&
                    rootGetters.isFrontlineEngagementUser) ||
                (process.env.CONFIG_KEY !== 'mobile' &&
                    rootGetters['auth/isFrontlineEngagementUser'])
            )
        ) {
            return
        }

        try {
            const { data } = await getDailyEngagementQuestions()
            await commit('setDailyEngagementQuestions', data.questions)
        } catch (e) {
            await commit('setDailyEngagementQuestions', [])
        }

        // Always show modal where there are unopened questions
        if (getters.hasUnopenedEngagementQuestions) {
            commit('setEngagementModalOpened', true)
        }
    },

    async markEngagementQuestionsAsOpen({ state, dispatch }) {
        const engagementAnswerIds = state.dailyEngagementQuestions.map(
            (row) => row.answer_id
        )
        await markEngagementQuestionsAsOpen(engagementAnswerIds)
    },

    async answerEngagementQuestion({ commit }, question: EngagementQuestion) {
        try {
            const { data } = await answerEngagementQuestion(question)
            commit('updateEngagementQuestion', data)
        } catch (e) {
            // @todo insights_engagement:  handle error e.response.data.error
        }
    },

    async updateEngagementQuestionField(
        { commit, state },
        { answer_id, key, value }
    ) {
        const engagementQuestion = state.dailyEngagementQuestions.find(
            (q) => q.answer_id === answer_id
        )
        if (engagementQuestion) {
            commit('updateEngagementQuestion', {
                ...engagementQuestion,
                [key]: value,
            })
        }
    },
}

const mutations: MutationTree<EngagementState> = {
    setDailyEngagementQuestions(state, data) {
        state.dailyEngagementQuestions = data
    },

    setEngagementModalOpened(state, data) {
        state.engagementModalOpened = data
    },

    updateEngagementQuestion(state, data: EngagementQuestion) {
        const engagementQuestion = state.dailyEngagementQuestions.find(
            (q) => q.answer_id === data.answer_id
        )
        if (!engagementQuestion) {
            return
        }
        state.dailyEngagementQuestions = state.dailyEngagementQuestions.map(
            (q) => {
                return q.answer_id === data.answer_id ? data : q
            }
        )
    },
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations,
}
