import { ajaxClient as client } from '@/api/client'
import cachedClient from '@/api/clientCached'
import { IComposer, IMessage, IRecipient } from '@/entities/notices'
import { NoticeType } from '@/store/modules/notices'

export async function loadRecipients() {
    return await cachedClient.get<IRecipient[]>(`/notices/recipients`)
}
export async function loadVirtualUsers() {
    return await cachedClient.get<IRecipient[]>(`/virtualusers/list`)
}

export async function sendMessage(composer: IComposer) {
    const formData = new FormData()
    formData.append('composer', JSON.stringify(composer))
    return await client.post(`/notices/send`, formData)
}

export async function sendMoment(request: IComposer) {
    return await client.post(`/moments/send`, request)
}

export async function loadMessagesSent() {
    return await client.get<IMessage[]>(`/notices/message/sent`)
}

export async function loadMessagesScheduled() {
    return await client.get<IMessage[]>(`/notices/message/scheduled`)
}

export async function loadMessagesReceived(noticeTypes: NoticeType[] | null) {
    // the method for AJAX call
    const config = {}
    if (noticeTypes) {
        config['params'] = {
            notice_types: noticeTypes,
        }
    }

    return await client.get<IMessage[]>(`/notices/message/received`, config)
}

export async function markMessageRead(id: number) {
    const formData = new FormData()
    formData.append('id', id.toString())
    return await client.post(`/notices/message/mark-read`, formData)
}

export async function respondToNotice(
    id: number,
    action: string,
    feedback: string
) {
    const formData = new FormData()
    formData.append('id', id.toString())
    formData.append('action', action.toString())
    formData.append('feedback', feedback)
    return client.post(`notices/message/respond`, formData)
}

export async function loadMessage(id: number) {
    return await cachedClient.get<IMessage>(`/notices/message/${id}`)
}

export async function deleteMessage(id: number) {
    const formData = new FormData()
    formData.append('id', id.toString())
    return await client.post(`/notices/message/delete`, formData)
}

export async function loadTimezone() {
    return await client.get(`/company/`)
}

// Getting messages sent by all tenant users for Frontline report
export async function loadCompanyMessagesSent(filters, page = 1) {
    return await client.get<IMessage[]>(`/notices/company/sent/${page}`, {
        params: {
            filters: filters,
        },
    })
}

// getting the type of all messages sent by users on the tennant
export async function getCompanyMessageTypes() {
    return await client.get<String[]>(`/notices/types`)
}

export async function updateNoticeEmoji(id, emoji) {
    const formData = new FormData()
    formData.append('emoji', emoji)
    return await client.post(`/ajax/notices/emojireact/${id}`, formData, {
        baseURL: '/',
    })
}
