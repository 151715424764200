import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { getAchievedGoal } from '@/api/reportcard'
import { IAchievedGoal } from '@/entities/reportcard'

export interface AchievedGoalState {
    cacheKey: string
    achievedGoalByUserMonth: Record<number, IAchievedGoal | null>
    month: number
    userName: string
}

interface AchievedGoalCacheKey {
    month: number
    userName: string
}

export const state: AchievedGoalState = {
    cacheKey: '',
    achievedGoalByUserMonth: {},
    month: 0,
    userName: '',
}

const getters: GetterTree<AchievedGoalState, any> = {
    getAchievedGoalByUserMonth: (state) => (month: number, userName: string) =>
        state.achievedGoalByUserMonth[btoa(`${month}-${userName ?? ''}`)] ||
        null,
}

const actions: ActionTree<AchievedGoalState, any> = {
    async loadAchievedGoalData(
        { commit, state, getters },
        { month, userName }: AchievedGoalCacheKey
    ) {
        commit('setAchievedGoalMonth', month)
        commit('setAchievedGoalsUserName', userName)
        commit('setCacheKey', btoa(`${state.month}-${state.userName ?? ''}`))

        if (
            typeof state.achievedGoalByUserMonth[state.cacheKey] !== 'undefined'
        ) {
            return
        }

        try {
            const { data } = await getAchievedGoal(month, userName)
            commit('setAchievedGoalData', { data: data.achievedGoal })
        } catch (error) {
            // handle error
        }
    },
}

const mutations: MutationTree<AchievedGoalState> = {
    setAchievedGoalData(state, payload: { data: IAchievedGoal }) {
        state.achievedGoalByUserMonth[state.cacheKey] = payload.data
    },
    setAchievedGoalMonth(state, month: number) {
        state.month = month
    },
    setAchievedGoalsUserName(state, userName: string) {
        state.userName = userName
    },
    setCacheKey(state, cacheKey: string) {
        state.cacheKey = cacheKey
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
