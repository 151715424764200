import { AsyncStorage as IAsyncStorage } from 'vuex-persist'
import { parse, stringify } from 'flatted'

// split storage for mobile and web, so bundle size doesn't blow up
const Storage =
    process.env.CONFIG_KEY === 'mobile'
        ? require('@capacitor/storage').Storage
        : null

export default class AsyncStorage implements IAsyncStorage {
    _config?: { name: string } | undefined

    async length(): Promise<number> {
        const { keys } = await Storage.keys()
        return keys.length
    }

    async key(keyIndex: number): Promise<string> {
        const { keys } = await Storage.keys()
        return keys[keyIndex]
    }

    async setItem<T>(key: string, data: T): Promise<T> {
        await Storage.set({
            key,
            value: stringify(data),
        })
        return data
    }

    async getItem<T>(key: string): Promise<T> {
        const { value } = await Storage.get({ key })
        try {
            return parse(value || '{}')
        } catch (e) {
            return {} as T
        }
    }

    async removeItem(key: string) {
        await Storage.remove({ key })
    }

    async clear() {
        await Storage.clear()
    }
}
