import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterBar = _resolveComponent("FilterBar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
            'filter-bar-container',
            {
                'no-x-pad': _ctx.noXPad,
                'no-pad': _ctx.noPad,
                'non-floating-filter-bar': !_ctx.isFloating,
            },
        ]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickWhenOffline && _ctx.onClickWhenOffline(...args)))
  }, [
    (_ctx.tsFilterBarVisible)
      ? (_openBlock(), _createBlock(_component_FilterBar, {
          key: 0,
          "should-reload-on-change": false,
          "enabled-executive-reporting": false,
          "is-in-mobile": _ctx.tsMobileView,
          class: _normalizeClass({ 'float-shift-up': !_ctx.isFloating }),
          permissions: _ctx.permissions
        }, null, 8, ["is-in-mobile", "class", "permissions"]))
      : _createCommentVNode("", true)
  ], 2))
}