import { Schedulerrule } from '@/pages/simple-survey/entities/schedule'
import { isNumeric } from '@/utils/string'
import { ErrorMessage } from '@/entities/survey'
import { Router } from 'vue-router'

export function finishRuleSetting(
    schedulerrules: Schedulerrule[],
    newRule: Schedulerrule
) {
    let isOldRule = false
    schedulerrules.forEach((rule) => {
        if (newRule.field === rule.field) {
            rule.operator = newRule.operator
            rule.value = newRule.value
            rule.repeat = newRule.repeat
            rule.agoOrAway = newRule.agoOrAway
            isOldRule = true
        }
    })
    if (!isOldRule) {
        schedulerrules.push(newRule)
    }
}

export function displayField(field: string) {
    return field
        .split('_')
        .filter((e) => e !== 'c')
        .join(' ')
}

export function rollbackField(field: string) {
    if (['segment', 'theme'].includes(field)) {
        return field
    }
    return field.split(' ').join('_') + '_c'
}

export function getAnchorAncestor(element): HTMLAnchorElement | null {
    if (element.tagName === 'A' && element.href) {
        return element
    }
    if (element.parentNode && element.parentNode !== document.body) {
        return getAnchorAncestor(element.parentNode)
    }
    return null
}

export function checkIntInvalid(newValue: string): boolean {
    return (
        !isNumeric(newValue) ||
        Number(newValue) < 0 ||
        !Number.isInteger(+newValue)
    )
}

export function pushBackToError(
    $router: Router,
    errors: ErrorMessage,
    sectionName = 'surveyAudienceAndTiming'
) {
    const errorSectionId = Object.keys(errors)[0]
    $router.replace({
        name: sectionName,
        hash: '#' + errorSectionId,
    })
}
