import { toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: "type-badge",
    style: _normalizeStyle({ background: _ctx.backgroundColour, color: _ctx.textColour })
  }, [
    _createTextVNode(_toDisplayString(_ctx.text) + " ", 1),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 4))
}