<template>
    <div class="engagement-shoutout-question">
        <div class="top">
            <div class="icon">
                <img :src="icon" alt="" />
            </div>
            <div class="question">
                {{ question.question }}
            </div>
        </div>
        <div class="middle">
            <MultipleOptionPicker
                v-if="selectingRecipients"
                with-icon
                with-search
                :options="question.options"
                :selected="selectedOptions"
                @select="onSelect"
            />

            <div v-if="!selectingRecipients" class="compose-shoutout">
                <div
                    class="recipient-names"
                    @click="selectingRecipients = true"
                >
                    <InputSingleLine
                        :value="recipientNames"
                        label="Recipients"
                    />
                    <div class="mask">
                        <FontAwesomeIcon
                            class="plus"
                            :icon="faPlus"
                            @click="selectingRecipients = true"
                        />
                    </div>
                </div>
                <div class="message-container">
                    <FlexiMultilineTextInput
                        :value="question.comment ? question.comment : ''"
                        :blur-on-new-line="true"
                        placeholder="Want to share more…"
                        @input="onInputComment"
                    />
                </div>
            </div>
        </div>
        <div class="button-container">
            <BaseButton
                v-if="selectingRecipients"
                full
                :disabled="!selectedOptions.length"
                @click="selectingRecipients = false"
            >
                Done
            </BaseButton>
            <BaseButton
                v-else
                full
                :disabled="!question.comment"
                @click="onSendShoutout"
            >
                Send Shoutout
            </BaseButton>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { Action, Getter } from 'vuex-facing-decorator'
import { EngagementQuestion } from '@/entities/engagement'
import FlexiMultilineTextInput from '@/components/BaseMultilineTextInput.vue'
import BaseButton from '@/components/Rain/Buttons/BaseButton.vue'
import InputSingleLine from '@/components/Rain/Inputs/InputSingleLine.vue'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faPlus } from 'fontawesome/free-solid-svg-icons'
import MultipleOptionPicker from '@/mobile/src/components/engagement/MultipleOptionPicker.vue'
import { EngagementQuestionIcon } from '@/mobile/src/components/engagement/utils'

@Component({
    components: {
        MultipleOptionPicker,
        InputSingleLine,
        BaseButton,
        FlexiMultilineTextInput,
        FontAwesomeIcon,
    },
    emits: ['update', 'done'],
})
export default class EngagementShoutoutQuestion extends Vue {
    @Prop({ type: Object, required: true })
    question!: EngagementQuestion

    @Getter
    userName!: string

    selectingRecipients = true

    faPlus = faPlus

    get recipientNames() {
        return this.selectedOptions
            .map(
                (userId) =>
                    this.question.options?.find((opt) => opt.value === userId)
                        ?.label
            )
            .join(', ')
    }

    get selectedOptions() {
        if (!this.question.answer || !this.question.answer.length) {
            return []
        }
        return JSON.parse(this.question.answer)
    }

    get icon() {
        return EngagementQuestionIcon[this.question.theme]
    }

    onSelect(selectedOptValues) {
        this.$emit('update', {
            key: 'answer',
            value: JSON.stringify(selectedOptValues),
        })
    }

    onInputComment(comment) {
        this.$emit('update', { key: 'comment', value: comment })
    }

    async onSendShoutout() {
        this.$emit('done', this.question)
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/typography';
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';

.engagement-shoutout-question {
    text-align: center;
    flex-direction: column;
    flex: 1;
    display: flex;
    overflow: auto;
    padding: @gutterSpacing-md;

    .top {
        padding-top: 30px;
    }
    .middle {
        flex-grow: 1;
        overflow: scroll;
    }

    .icon img {
        height: 80px;
        margin-bottom: 20px;
    }

    .question {
        .heading();
        padding: 0 16px;
    }

    .compose-shoutout {
        padding: 16px;
    }

    .message-container {
        margin-top: 16px;
    }

    .button-container {
        padding: 16px;
    }

    .recipient-names {
        position: relative;

        .mask {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            flex-direction: column;
            z-index: 1;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            padding-right: 16px;
            box-sizing: border-box;
            color: @primaryBlue;
        }
    }

    .comment {
        margin-top: 10px;
        padding: 0 16px;
        height: 200px;
    }
}
</style>
