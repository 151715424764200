<template>
    <div class="feedback-custom-data-container">
        <div class="feedback-custom-data-content">
            <div v-if="chatCustomFields.length === 0">
                No custom data available.
            </div>
            <table v-else>
                <tr v-for="(row, rowIdx) in chatCustomFields" :key="rowIdx">
                    <td class="row-label">{{ row.k }}</td>
                    <td class="row-value">{{ row.v }}</td>
                </tr>
            </table>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { Getter } from 'vuex-facing-decorator'

@Component({
    components: {},
})
export default class FeedbackCustomData extends Vue {
    @Getter public chatCustomFields
}
</script>
<style lang="less" scoped>
@import '~@/styles/palette.less';
@import '~@/styles/rain/variables.less';

.feedback-custom-data-container {
    .back {
        color: white;
    }

    .feedback-custom-data-content {
        color: #fff;
        padding-top: 30px;

        table {
            table-layout: fixed;
            overflow-wrap: break-word;
            width: 100%;

            tr {
                td {
                    padding: 7px 5px 8px 5px;
                    &.row-label {
                        color: rgba(255, 255, 255, 0.6);
                        font-weight: @fontWeight-medium;
                        font-size: @fontSize-base;
                        letter-spacing: @letterSpacing-2xl;
                        line-height: @lineHeight-base;
                        padding-left: -20px;
                        text-align: right;
                        text-transform: uppercase;
                        width: 50%;
                    }
                    &.row-value {
                        color: #ffffff;
                        font-weight: @fontWeight-regular;
                        font-size: @fontSize-md;
                        letter-spacing: @letterSpacing-xl;
                        line-height: @lineHeight-md;
                        text-align: left;
                    }
                }
            }
        }
    }
}
</style>
