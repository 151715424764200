import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-01b80b22"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BackButton = _resolveComponent("BackButton")!
  const _component_MenuRow = _resolveComponent("MenuRow")!
  const _component_WebViewScreen = _resolveComponent("WebViewScreen")!

  return (_openBlock(), _createBlock(_component_WebViewScreen, {
    class: "webview-container",
    "no-pad": "",
    title: "Settings"
  }, {
    "left-button": _withCtx(() => [
      _createVNode(_component_BackButton)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (menuItem) => {
          return (_openBlock(), _createBlock(_component_MenuRow, {
            key: menuItem.id,
            "menu-item": menuItem,
            class: "item",
            onClick: _ctx.onClickItem
          }, null, 8, ["menu-item", "onClick"]))
        }), 128))
      ])
    ], undefined, true),
    _: 1
  }))
}