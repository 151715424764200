<template>
    <div class="over-card-container">
        <div class="over-card-header">
            <div class="left">
                <slot name="header-left"></slot>
            </div>
            <div class="center">
                <slot name="header-center"></slot>
            </div>
            <div class="right">
                <div>
                    <button class="x-close-button" @click="closePopup">
                        <FontAwesomeIcon class="xmark" :icon="faXmark" />
                    </button>
                </div>
            </div>
        </div>
        <div class="over-card-content">
            <slot />
        </div>
        <div class="over-card-footer">
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script lang="ts">
import { faXmark } from 'fontawesome/free-solid-svg-icons'
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'

@Component({
    components: {
        FontAwesomeIcon,
    },
})
export default class OverCardModal extends Vue {
    @Prop({ type: Function, required: false }) private closePopup!: Function

    readonly faXmark = faXmark
}
</script>

<style scoped lang="less">
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';

.over-card-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background-color: @deepFog;
    backdrop-filter: blur(25px);
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
    overflow: hidden;
}

.over-card-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: @featureGutter 0;
    border-bottom: @borderWidth solid @thinFog;

    .left {
        width: 10vw;
    }

    .center {
        color: @white;
        display: inline-block;
    }

    .right {
        width: 10vw;
        .x-close-button {
            border: none;
            background: none;
            color: white;
            padding-right: 10px;
        }
        .xmark {
            width: 18px;
            height: 18px;
        }
    }
}

.over-card-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-height: 100%;
    overflow: hidden;
}
</style>
