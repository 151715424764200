import client from './client'
import { headerTokenClient } from './client'
import { ICompanyConfig, CompanyAttributes } from '@/entities/company'

export async function setCompanyConfig(config: ICompanyConfig) {
    try {
        return await client.post(`company/config`, config)
    } catch (e) {
        return {
            data: {
                success: false,
                msg: 'Something went wrong, please try again later',
            },
        }
    }
}

export async function getCompanyFields(fields?: CompanyAttributes[]) {
    let query = 'company/'
    if (fields) {
        query += '?fields=' + JSON.stringify(fields)
    }
    return client.get(query)
}

export async function getHierarchyFields() {
    return client.get(`company/hierarchy-fields`)
}

export async function getCompany(fields?: CompanyAttributes[]) {
    let query = '/ajax/company/'
    if (fields) {
        query += '?fields=' + JSON.stringify(fields)
    }
    return headerTokenClient.get(query)
}

export async function cancelCalculation() {
    return await headerTokenClient.post(
        '/ajax/setting/calculated_fields/cancel-changes'
    )
}
