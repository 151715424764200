import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-451aab06"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header-spacer" }
const _hoisted_2 = { class: "header-container" }
const _hoisted_3 = { class: "header-top" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Typography = _resolveComponent("Typography")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.filterLostFocus()), ["stop"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Typography, {
        component: "span",
        class: "title-text"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.title), 1)
        ], undefined, true),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Typography, {
          component: "span",
          class: "back flex-1",
          onClick: _ctx.goBack
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "back", {}, undefined, true)
          ], undefined, true),
          _: 3
        }, 8, ["onClick"]),
        _createVNode(_Transition, {
          name: "title",
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_component_Typography, {
              key: _ctx.title,
              component: "span",
              class: "title-text flex-2"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.title), 1)
              ], undefined, true),
              _: 1
            }))
          ], undefined, true),
          _: 1
        }),
        _createVNode(_component_Typography, {
          component: "span",
          class: "action flex-1",
          onClick: _ctx.goAction
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "action", {}, undefined, true)
          ], undefined, true),
          _: 3
        }, 8, ["onClick"])
      ]),
      _createVNode(_Transition, { name: "slide-down" }, {
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("div", null, [
            _renderSlot(_ctx.$slots, "dropdown", {}, undefined, true)
          ], 512), [
            [_vShow, _ctx.showDropdown]
          ])
        ], undefined, true),
        _: 3
      })
    ])
  ]))
}