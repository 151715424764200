import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/img/Icons/leaderboard-up.svg'
import _imports_1 from '@/assets/img/Icons/leaderboard-down.svg'


const _withScopeId = n => (_pushScopeId("data-v-83bd63a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "rank-row" }
const _hoisted_2 = { class: "rank" }
const _hoisted_3 = {
  key: 0,
  class: "avatar-container"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 0,
  class: "body"
}
const _hoisted_6 = { class: "value" }
const _hoisted_7 = {
  key: 1,
  class: "no-rank-body"
}
const _hoisted_8 = { class: "value" }
const _hoisted_9 = { class: "nps-info" }
const _hoisted_10 = { class: "nps" }
const _hoisted_11 = { class: "change" }
const _hoisted_12 = {
  key: 0,
  alt: "",
  src: _imports_0
}
const _hoisted_13 = {
  key: 1,
  alt: "",
  src: _imports_1
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ComparisonBar = _resolveComponent("ComparisonBar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.avatar)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("img", {
              src: _ctx.getCDNUrl(_ctx.avatar),
              class: "avatar"
            }, null, 8, _hoisted_4),
            (_ctx.showRank)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: _normalizeClass(["rank-number", { active: _ctx.active }])
                }, _toDisplayString(_ctx.rankNum), 3))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["rank-number", { active: _ctx.active }])
          }, [
            (_ctx.showRank)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(_toDisplayString(_ctx.rankNum), 1)
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(" — ")
                ], 64))
          ], 2))
    ]),
    (_ctx.showRank)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.rank.name), 1),
          _createVNode(_component_ComparisonBar, {
            value: Number(_ctx.rank.nps),
            average: Number(_ctx.companyNps),
            "average-text": `${_ctx.ratingLabel} ${_ctx.companyNps}`,
            min: _ctx.minValue,
            max: _ctx.maxValue,
            showlabel: _ctx.rankNum === 1,
            last: _ctx.isLast,
            "positive-color": "linear-gradient(90deg, rgba(46, 91, 255, 0.25) 8.85%, #0037FF 100%)",
            "negative-color": "linear-gradient(90deg, #F7C09C 0.52%, rgba(247, 192, 156, 0.05) 100%)",
            class: "percent"
          }, null, 8, ["value", "average", "average-text", "min", "max", "showlabel", "last"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.rank.name), 1)
        ])),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.nps), 1),
      _createElementVNode("div", _hoisted_11, [
        (_ctx.rank.change >= 0)
          ? (_openBlock(), _createElementBlock("img", _hoisted_12))
          : (_openBlock(), _createElementBlock("img", _hoisted_13)),
        _createTextVNode(" " + _toDisplayString(_ctx.change), 1)
      ])
    ])
  ]))
}