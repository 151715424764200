<template>
    <div ref="container" class="dialog">
        <div class="avatar">
            <AvatarOrInitials :user="user" :size="32" />
            <div v-if="alert" class="alert" />
            <div :class="['vertical-line', getVerticalLineClass]" />
        </div>

        <div class="body">
            <div v-if="suggestionLink">
                <div class="suggestion-link">
                    <DialogBody
                        :title="title"
                        :subtitle="subtitle"
                        :body="body"
                        :action="action"
                        :type="type"
                        :created="created"
                        :can-delete="canDelete"
                        @delete="askDelete"
                        @openDetails="router.push(suggestionLink)"
                    />
                </div>
            </div>
            <template v-else>
                <DialogBody
                    :title="title"
                    :subtitle="subtitle"
                    :body="body"
                    :action="action"
                    :type="type"
                    :created="created"
                    :can-delete="canDelete"
                    @delete="askDelete"
                />
            </template>

            <slot name="footer" />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { Action, Getter } from 'vuex-facing-decorator'
import { useRouter } from 'vue-router'
import {
    SuggestionAction,
    SuggestionLinkMode,
    SuggestionView,
} from '@/entities/suggestions'
import DialogBody from './DialogBody.vue'
import { UserDetail } from '@/entities/user'
import AvatarOrInitials from '@/components/WebView/AvatarOrInitials.vue'

@Component({
    components: { DialogBody, AvatarOrInitials },
})
export default class Dialog extends Vue {
    @Prop({ type: Object }) public suggestion!: SuggestionView
    @Prop({ type: String, default: '' }) public title!: string
    @Prop({ type: String, default: '' }) public subtitle!: string
    @Prop({ type: String, default: '' }) public body!: string
    @Prop({
        type: Object,
        default() {
            return { id: 0, name: '', avatar: '' }
        },
    })
    public user!: UserDetail
    @Prop({ type: Number, default: SuggestionLinkMode.None })
    public linkMode!: SuggestionLinkMode
    @Prop({ type: Number, default: 0 }) public created!: number
    @Prop({ type: String, default: '' }) public action!: SuggestionAction
    @Prop({ type: String, default: '' }) public type!: string
    @Prop({ type: Boolean, default: false }) public alert!: boolean
    @Prop({ type: Boolean, default: false }) public withVerticalLine!: boolean
    @Prop({ type: Boolean, default: false }) public showDelete!: boolean

    @Getter public activeSuggestionId
    @Getter public getUserId!: number
    @Getter public getUserIsAdmin!: boolean

    @Action public removeSuggestion
    @Action public cleanActiveSuggestion
    @Action public setActiveSuggestionId

    public router = useRouter()

    public get canDelete() {
        return (
            this.showDelete &&
            this.suggestion &&
            (this.suggestion.user_id === this.getUserId || this.getUserIsAdmin)
        )
    }

    public get suggestionLink() {
        if (this.linkMode === SuggestionLinkMode.None) {
            return undefined
        }

        if (this.linkMode === SuggestionLinkMode.Path) {
            return {
                name: 'suggestionsDetail',
                params: { id: this.suggestion.id.toString() },
            }
        }

        return { query: { suggestion: this.suggestion.id } }
    }

    // where recent is 'in the last minute'
    public get recentlyCreated() {
        const now = new Date().valueOf()
        const then = this.created * 1000
        return now - then < 1000 * 60
    }

    public get getVerticalLineClass() {
        if (!this.withVerticalLine) {
            return 'none'
        }
        if (!this.action) {
            return '' // non-action display as gray by default
        }
        return this.action === 'not-now' ? 'negative' : 'positive'
    }

    public askDelete() {
        const confirmToDelete = window.confirm(
            'Are You Sure? You are about to delete this suggestion'
        )

        if (confirmToDelete) {
            if (this.removeSuggestion(this.suggestion)) {
                if (this.suggestion.id === this.activeSuggestionId) {
                    this.setActiveSuggestionId(0)
                    this.cleanActiveSuggestion()
                }
            }
        }
    }
}
</script>

<style scoped lang="less">
a {
    text-decoration: none;
}
.dialog {
    display: flex;
}

.body {
    flex: 1;
    position: relative;

    .suggestion-link {
        cursor: pointer;
    }
}

.avatar {
    overflow: hidden;
    margin-bottom: 6px;
    width: 46px;
    img {
        border-radius: 17px;
        width: 34px;
        height: 34px;
        display: block;
    }
}

.alert {
    width: 8px;
    height: 8px;
    background: #b43dc6;
    border: 2px solid #fff;
    border-radius: 10px;
    position: absolute;
    top: 10px;
    left: 37px;
}

.vertical-line {
    width: 3px;
    height: 100%;
    background: #f4f6fc;
    margin-top: -0px;
    margin-left: 15px;

    &.none {
        display: none;
    }

    &.positive {
        background: #2e5bff;
    }

    &.negative {
        background: #fb7e42;
    }
}
</style>
