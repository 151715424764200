<template>
    <div v-if="show">
        <Modal
            class="composer-modal"
            :show="show"
            vanilla
            flex
            flex-direction="column"
            :close="onClose"
            :height="modalHeight"
            :max-width="maxModalWidth"
        >
            <div v-if="composingStep === 'type'" class="type-container">
                <div class="composer-option header">
                    <Typography variant="subtitle1">Select Notice Type</Typography>
                </div>
                <NoticeTypePicker
                    title="Select Notice Type"
                    class="notice-type-picker"
                    :selected="composer.type"
                    :has-last-divider="false"
                    @select="onSelectNoticeType"
                />
            </div>

            <div
                v-else-if="composingStep === 'message'"
                class="composer-container"
            >
                <div class="composer-option header contents">
                    <Typography variant="subtitle1">{{ typeTitle }}</Typography>
                </div>
                <div
                    v-if="canPickRecipients"
                    class="composer-option contents"
                    @click="showRecipientPicker()"
                >
                    <div class="names">
                        <div v-if="!hasRecipients" class="placeholder">
                            Add Recipients
                        </div>
                        <div v-else ref="recipients" class="recipients">
                            <SelectedRecipientPills
                                :recipients="recipients"
                                :selected-recipients="selectedRecipients"
                                :can-remove-tabs="false"
                            />
                        </div>
                    </div>
                </div>
                <div ref="notice-composer-editor" class="editor">
                    <Quote
                        v-if="composer.question"
                        :text="composer.question.comment || 'No Comment'"
                        @quoteClampChange="scrollToEditor"
                    />
                    <ChatInput
                        html-mode
                        custom-class="notice-editor"
                        class="notice-editor-wrapper"
                        :content="editingMessage"
                        placeholder="Write Notice"
                        :on-update="onMessageUpdate"
                        :mentions="recipients"
                        :max-text-length="301"
                    />
                </div>
                <div class="footer-container">
                    <div class="notice-scheduler">
                        <template v-if="canPickSchedule">
                            <Typography variant="subtitle2">
                                <span class="notice-scheduler-label">Publish Schedule:</span>
                                <span
                                    class="notice-scheduler-value"
                                    @click="showSchedule = true"
                                >
                                    {{ scheduledTimeLabel }}
                                    <FontAwesomeIcon
                                        class="chevron"
                                        :icon="faChevronDown"
                                    />
                                </span>
                            </Typography>
                        </template>
                    </div>
                    <div class="footer-button-container">
                        <template v-if="$companyVars.has_huddle">
                            <label for="pin-notice-checkbox">Pin notice</label>
                            <input
                                id="pin-notice-checkbox"
                                v-model="composer.isPinned"
                                type="checkbox"
                            />
                        </template>
                        <BaseButton
                            v-if="!composer.question"
                            class="btn-back"
                            variant="ghost"
                            @click="showNoticeTypePicker"
                        >
                            Back
                        </BaseButton>
                        <BaseButton
                            :disabled="!canSend"
                            variant="secondary"
                            @click="sendNoticeMessage"
                            >{{ sendButtonLabel(sendButtonTextOverride) }}
                        </BaseButton>
                    </div>
                </div>
            </div>

            <DesktopRecipientPicker
                v-else-if="composingStep === 'recipient'"
                :single="false"
                :selected="selectedRecipients"
                :recipients="recipients"
                :allow-empty="false"
                title="Add Recipients"
                @close="onCancelRecipients"
                @confirm="onConfirmRecipients"
            />
        </Modal>

        <NoticeSchedulerModal
            :timezone="timezone"
            :show="showSchedule"
            @close="showSchedule = false"
            @schedule="onScheduleTime"
        />
    </div>
</template>

<script lang="ts">
import 'driver.js/dist/driver.min.css'
import { Component, Prop, Watch } from 'vue-facing-decorator'
import Modal from '@/components/Modal.vue'
import { sendMessage, sendMoment } from '@/api/notices'
import { isEmptyString } from '@/utils/string'
import {
    convertNoticeComposerToMomentRequest,
    noticeTypeTitle,
} from '@/utils/notices'
import { Mutation, namespace } from 'vuex-facing-decorator'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { IRecipient } from '@/entities/notices'
import NoticeRow from '@/components/Notices/NoticeRow.vue'
import NoticeMessage from '@/components/Notices/NoticeMessage.vue'
import NoticeSchedulerModal from '@/components/Notices/NoticeSchedulerModal.vue'
import RecipientPicker from '@/components/Notices/RecipientPicker.vue'
import NoticeTypePicker from '@/components/Notices/NoticeTypePicker.vue'
import Quote from '@/components/Notices/Quote.vue'
import DesktopRecipientPicker from '@/components/Notices/DesktopRecipientPicker.vue'
import RecipientPickerModal from '@/components/Notices/RecipientPickerModal.vue'
import SelectedRecipientPills from '@/components/Notices/SelectedRecipientPills.vue'
import NoticesMixin from '@/components/Notices/notices-mixin'
import ChatInput from '@/components/Chat/ChatInput.vue'
import Typography from '@/components/Rain/Typography/Typography.vue'
import BaseButton from '@/components/Rain/Buttons/BaseButton.vue'
import { UserDetails } from '@/mobile/src/types/auth'
import { Getter } from 'vuex-facing-decorator'

const AuthModule = namespace('auth')

@Component({
    components: {
        SelectedRecipientPills,
        RecipientPickerModal,
        BaseButton,
        Typography,
        DesktopRecipientPicker,
        Quote,
        ChatInput,
        Modal,
        NoticeMessage,
        NoticeRow,
        NoticeSchedulerModal,
        NoticeTypePicker,
        RecipientPicker,
        FontAwesomeIcon,
    },
    emits: ['notice-sent', 'close'],
})
export default class NoticesComposer extends NoticesMixin {
    @Prop({ type: Boolean }) public show!: boolean
    @Prop({ type: String, default: '' }) public sendButtonTextOverride!: string
    @Prop({ type: Boolean, default: true }) public canPickRecipients!: boolean
    @Prop({ type: Boolean, default: true }) public canPickSchedule!: boolean
    @Prop({ type: Boolean, default: false }) public resetOnShow!: boolean
    @Prop({ type: String, default: 'type' }) public startStep!: string
    @Prop({ type: Object, default: () => ({}) }) public notice!: {
        type: string
        message: string
        recipients: IRecipient[]
        question: null | object
    }
    @Prop({ type: Function }) public saveOverride!: (IComposer) => any

    @AuthModule.Getter private details!: UserDetails

    @Getter $companyVars

    @Mutation private setMessage
    private saving = false
    private composingStep = '' // ['message', 'type', 'recipient']
    private modalHeight = 480
    private maxModalWidth = 600

    @Watch('show')
    onShowChange(show) {
        if (this.resetOnShow) {
            if (show) {
                //Reset recipients & question on show
                this.selectedRecipients = [...this.composer.recipients]

                if (this.notice?.question) {
                    this.setComposerQuestion(this.notice.question)
                }
            } else {
                this.showNoticeTypePicker()
            }
        }
    }

    public beforeMount() {
        this.composingStep = this.startStep
        if (this.notice) {
            // set notice type
            this.setComposerNoticeType(this.notice.type)
            this.setComposerMessage(this.notice.message)
            this.setComposerRecipients(this.notice.recipients ?? [])
            if (this.notice?.question) {
                this.setComposerQuestion(this.notice.question)
            }
        }
    }

    public async mounted() {
        this.selectedRecipients = [...this.composer.recipients]
        if (!this.timezone) {
            this.loadTimezone()
        }
    }

    private onSelectNoticeType(noticeType) {
        this.setComposerNoticeType(noticeType)
        this.composingStep = 'message'
    }

    private showRecipientPicker() {
        this.composingStep = 'recipient'
        if (!this.recipients || !this.recipients.length) {
            this.loadRecipients()
        }
    }

    private showNoticeTypePicker() {
        this.composingStep = this.startStep
    }

    private async sendNoticeMessage() {
        if (!this.canSend) {
            return
        }
        if (this.saveOverride) {
            this.saving = true
            const success = await this.saveOverride(this.composer)
            this.saving = false
            if (success) {
                // reset when success
                this.composingStep = this.startStep
                this.scheduledTimeLabel = 'Now'
                this.selectedRecipients = []
                this.resetComposer()
            }
            return
        }

        // Notices without scheduled_at will trigger moment creation.
        if (!this.composer.scheduled_at) {
            await this.sendMomentNoticeMessage()
            return
        }

        this.saving = true
        const { data } = await sendMessage(this.composer)
        this.saving = false
        this.setErrorMessage(data.msg)
        if (data.success) {
            data.scheduled_at = this.composer.scheduled_at
            this.composingStep = 'type' //reset display
            this.scheduledTimeLabel = 'Now'
            this.selectedRecipients = []

            this.notifySuccess(data)
            this.resetComposer()
            this.$emit('notice-sent', data)
        } else {
            this.notifyError(data)
        }
    }

    private async sendMomentNoticeMessage() {
        this.saving = true
        const { data } = await sendMoment(
            convertNoticeComposerToMomentRequest(this.composer)
        )
        this.saving = false

        if (data.success) {
            data.msg = 'Notice sent' // Replace moment create message with notice sent.
            this.composingStep = 'type' //reset display
            this.scheduledTimeLabel = 'Now'
            this.selectedRecipients = []
            this.notifySuccess(data)
            this.resetComposer()
            this.$emit('notice-sent', data)
        } else {
            this.setErrorMessage(data.error)
            this.notifyError(data)
        }
    }

    private get editingMessage() {
        return this.composer.message
    }

    private get canSend(): boolean {
        return !!(
            this.editingMessage &&
            !isEmptyString(this.editingMessage) &&
            this.contentLength <= this.contentLengthLimit &&
            (!this.canPickRecipients ||
                (this.canPickRecipients && this.hasRecipients)) &&
            !this.saving
        )
    }

    private get typeTitle() {
        return noticeTypeTitle(this.composer.type)
    }

    private async onClose() {
        this.$emit('close')
    }

    private onCancelRecipients() {
        this.composingStep = 'message'
        this.selectedRecipients = this.composer.recipients
    }

    private onConfirmRecipients(confirmedRecipients: IRecipient[]) {
        this.composingStep = 'message'
        this.setComposerRecipients(confirmedRecipients)
        this.selectedRecipients = confirmedRecipients
    }
}
</script>

<style lang="less">
.type-list {
    margin: -30px -15px 0 -15px;

    .row:last-of-type {
        border-bottom: none;
    }
}
</style>

<!-- overwrite any feature tour css -->
<style lang="less" scoped>
@import '~@/styles/layout.less';
@import '~@/styles/palette.less';
@import '~@/styles/notices.less';
@import '~@/styles/rain/variables.less';
@import '~@/styles/rain/colour.less';

@padding: 20px;

.notices-desktop {
    max-width: 600px;
    margin: 0 auto;
}

.composer-option {
    flex-shrink: 0;
    border-bottom: 1px solid @blueLight;
    display: flex;
    align-items: center;
    cursor: pointer;

    .notice-type-badge {
        font-size: 12px;
    }

    &.contents {
        margin: 0 @gutterSpacing-lg;
    }

    &.header {
        border-bottom: unset;
        text-transform: capitalize;
        font-weight: 500;
        height: 50px;
    }

    .names {
        height: fit-content;
        flex: 1;

        .recipients {
            padding: 8px 0 12px 0;
        }

        .placeholder {
            padding: 12px 0 16px 0;
            font-size: 14px;
            line-height: 20px;
            color: @neutral;
        }
    }
}

.editor {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: @gutterSpacing-md @gutterSpacing-lg;
    scroll-behavior: smooth;
    overflow: auto;
}

.footer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    border-top: 1px solid @blueLight;
    text-align: center;

    label {
        color: @darkGrey;
        font-weight: 500;
    }
    input[type='checkbox'] {
        vertical-align: middle;
    }

    .notice-scheduler {
        .notice-scheduler();
        padding-right: 12px;
    }

    .footer-button-container .btn-back {
        margin-right: 16px;
    }
}

.notice-title {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
}

.heading {
    padding: 20px 20px 0 20px;
    background: white;
    margin-bottom: 12px;
    border-radius: 8px;
}

.uk-text-uppercase {
    text-transform: uppercase;
}

.uk-button-primary,
.uk-button-primary:hover {
    background: @blue;
}

.tab-options {
    display: flex;
}

.tab-options div {
    padding: 12px 0;
    margin-right: 20px;

    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;

    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    cursor: pointer;
    color: #93a1be;
}

.tab-options .active {
    color: #2e5bff;
    border-bottom: 2px solid blue;
}

.empty-msg {
    height: 400px;
    text-align: center;
    color: #93a1be;
    margin-top: 150px;
}

.modal-pad {
    padding: 16px 24px;
}

.notice-type-picker {
    margin-top: -14px;
}

.type-container {
    padding: @gutterSpacing-md @gutterSpacing-lg;
}

.composer-container {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    position: relative;
    padding-top: @gutterSpacing-md;
    box-sizing: border-box;
    overflow: auto;
}

.recipient-title {
    height: 22px;
    line-height: 24px;
    border-bottom: 1px solid #e0e7ff;
}

.recipient-picker-modal-container {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.recipient-picker-content-container {
    clear: both;
    flex: 1;
    display: flex;
    flex-direction: column;
    height: calc(100% - 140px);
}

.recipient-action {
    z-index: 1;
    box-shadow: 0 0 10px #d3d3d3;
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
    background: @white;
}

.back {
    height: 12px;
    cursor: pointer;
}

.uk-button:disabled {
    opacity: 0.3;
    cursor: not-allowed;
}

.chevron {
    color: @grey90;
    height: 12px;
    margin-left: 2px;
}

.chat-input-container {
    position: static !important; // reset positioning
    min-height: 180px; // avoid the input to squish too small when the shout-out quote is displayed
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .tools-container {
        position: unset;
    }
}

.composer-modal .modal-container .close {
    padding: @gutterSpacing-lg !important;
}
</style>
