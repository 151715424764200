
import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'
import PortalContainer from '@/mobile/src/components/containers/PortalContainer.vue'
import BaseButton from '@/components/Rain/Buttons/BaseButton.vue'
import Typography from '@/components/Rain/Typography/Typography.vue'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faTimes } from 'fontawesome/free-solid-svg-icons'

@Component({
    components: {
        BaseButton,
        Typography,
        PortalContainer,
        FontAwesomeIcon,
    },
    emits: ['close', 'click-left', 'click-right'],
})
class Modal extends Vue {
    @Prop({ type: Boolean, default: false }) public readonly show!: boolean
    @Prop({ type: String, default: '' }) public readonly title!: string
    @Prop({ type: String, default: 'ghost' })
    public readonly btnLeftType!: string
    @Prop({ type: String, default: '' })
    public readonly btnLeft!: string
    @Prop({ type: String, default: 'confirm' })
    public readonly btnRight!: string
    @Prop({ type: String, default: '' })
    public readonly btnRightType!: string
    @Prop({ type: Boolean, default: true })
    public readonly btnFull!: boolean
    @Prop({ type: Boolean, default: false })
    public readonly allowClickOut!: boolean
    @Prop({ type: Boolean, default: true })
    public readonly footerTopPadding!: boolean
    @Prop({ type: Boolean, default: false })
    public readonly isDisabled!: boolean
    @Prop({ type: String, default: '' })
    public readonly width!: string

    @Prop({ type: Number, default: 600 })
    public readonly containerHeight!: number
    @Prop({ type: Number, default: 400 })
    public readonly containerWidth!: number
    public readonly containerContentHeight: number = this.containerHeight - 224

    public faTimes = faTimes

    public clickOut() {
        if (!this.allowClickOut) {
            return
        }

        this.$emit('close')
    }

    containerWidthPx = `${this.containerWidth}px`
    containerHeightPx = `${this.containerHeight}px`
    containerContentHeightPx = `${this.containerContentHeight}px`
}

const __default__ = toNative(Modal)

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "45d1cb82": (_ctx.containerWidthPx),
  "db22ba0a": (_ctx.containerHeightPx),
  "6ad2963c": (_ctx.containerContentHeightPx)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__