import {
    getFilters,
    getRuleOptions,
    getRuleOptionsWithSuboptions,
    getRuleSubOptions,
    getTimeOptions,
    patchFilter,
    createFilter,
    deleteFilter,
    getBenchmarkIndustriesList,
} from '@/api/filter'
import {
    IFilter,
    IFilterOption,
    IFilterTimeLabel,
    IFilterRule,
    IIndustry,
    TimeOption,
} from '@/entities'
import { TimeOptionLabel } from '@/utils/time'
import { MutationTree, GetterTree } from 'vuex'
import { dedupBy } from '@/utils/array'
import { format, subMonths } from 'date-fns'
import { getCompany } from '@/api/company'
import { IScorecardTopic } from '@/pages/appstore/components/Scorecard/scorecard-settings-entity'
import { getScorecardTopics } from '@/api/scorecard'
import { getReportLeaderboardList } from '@/api/teamscoreboard'

export interface FilterState {
    filters: IFilter[]
    scorecardTopics: IScorecardTopic[]
    timeOptions: TimeOptionLabel[][]
    ruleOptions: IFilterOption[]
    activeRuleSubOption: string
    loadingRuleSubOption: string
    searchTerm: string
    editing: number // filter id
    editMode: 'create' | 'edit' | 'closed'
    patching: boolean
    patchError?: string
    lastPatch: number
    lastAdd: number
    industries: IIndustry[]
    leaderboards: []
    appBaseUrl: string
    company?: any
    topicFeedbackFilters?: IFilterRule[] | undefined
    topicFeedbackTimeOption?: TimeOptionLabel | undefined // for feedback page,
    usePagination: boolean
}

export const state: FilterState = {
    filters: [],
    scorecardTopics: [],
    ruleOptions: [],
    timeOptions: [],
    activeRuleSubOption: '',
    loadingRuleSubOption: '',
    searchTerm: '',
    editing: -1,
    editMode: 'closed',
    patching: false,
    lastPatch: 0,
    lastAdd: 0,
    industries: [],
    leaderboards: [],
    appBaseUrl: '',
    company: null,
    topicFeedbackFilters: undefined,
    topicFeedbackTimeOption: undefined,
    usePagination: false,
}

interface OptionPayload {
    option: string
    options: IFilterOption[]
}

const DATE_FORMAT = 'DD-MM-YYYY'

export function getFilterTimeLabel({
    time_unit,
    time_value,
}: IFilter): IFilterTimeLabel {
    const val =
        typeof time_value === 'string' ? parseInt(time_value, 10) : time_value
    if (
        time_unit === 'today' ||
        (time_unit === 'day' && Number(time_value) === 0)
    ) {
        return { label: 'today' }
    } else if (time_unit === 'custom') {
        const [from, to] = String(time_value).split('_')
        // from/to are potentially undefined if the url is bad. Fall back to today/a month ago
        return {
            label: time_unit,
            from: from || format(subMonths(new Date(), 1), DATE_FORMAT),
            to: to || format(new Date(), DATE_FORMAT),
        }
    } else if (time_unit === 'ytd') {
        return { label: `year to date` }
    } else if (time_unit === 'day') {
        const daysLabel = {
            7: { label: 'Week' },
            14: { label: '2 Weeks' },
            30: { label: 'Last Month' },
        }
        return daysLabel[val]
    } else if (['year', 'month', 'week'].indexOf(time_unit) > -1) {
        if (val === 0) {
            return { label: `this ${time_unit}` }
        } else {
            if (time_unit === 'year' && val === 1) {
                return { label: 'Previous Calendar Year' }
            }
            return val !== 1
                ? { label: `last ${val} ${time_unit}s` }
                : { label: `last ${time_unit}` }
        }
    } else if (time_unit !== format(new Date(), 'MMM').toLowerCase()) {
        return { label: `last ${time_unit}` }
    } else {
        return { label: `${time_unit}` }
    }
}

const byColumn = (a: IFilterRule, b: IFilterRule): number => {
    return a.column > b.column ? 1 : a.column < b.column ? -1 : 0
}

const getters: GetterTree<FilterState, any> = {
    filterActive({ filters }: FilterState) {
        const active = filters.find((f) => !!f.is_default)
        if (active) {
            return {
                ...active,
                filter_rules: (active.filter_rules || []).sort(byColumn),
            } as IFilter
        }
    },
    filterQuestionType({ filters }: FilterState, { filterActive }) {
        if (!filterActive) {
            return 'nps'
        }
        const questionTypeRule = filterActive.filter_rules.find(
            (r) => r.column === 'question_type'
        )
        return questionTypeRule ? questionTypeRule.value[0] : 'nps'
    },
    filterTimeLabel(
        { filters }: FilterState,
        { filterActive, hasNetworkConnection }
    ) {
        if (!hasNetworkConnection) {
            return { label: 'Last 30 days' }
        }
        return filterActive ? getFilterTimeLabel(filterActive) : { label: '-' }
    },
    filterTimeOptions({ timeOptions }: FilterState) {
        return timeOptions
    },
    filterRuleOptions({ ruleOptions }: FilterState) {
        return {
            general: ruleOptions.filter(({ common }) => common),
            custom: ruleOptions
                .filter(({ common }) => !common)
                .sort((a, b) =>
                    a.label > b.label ? 1 : a.label < b.label ? -1 : 0
                ),
        }
    },
    filterLoadingRuleSubOption({ loadingRuleSubOption }: FilterState) {
        return loadingRuleSubOption
    },
    filterActiveRuleSubOption({ activeRuleSubOption }: FilterState) {
        return activeRuleSubOption
    },
    filterActiveRuleSubOptions({
        activeRuleSubOption,
        ruleOptions,
    }: FilterState) {
        if (activeRuleSubOption) {
            const option = ruleOptions.find(
                (opt) => opt.value === activeRuleSubOption
            )
            const data = (option && option.options) || []

            data.sort((a, b) => {
                if (a.value === '') {
                    return -1
                }
                if (b.value === '') {
                    return 1
                }
                if (isNaN(a.value as any)) {
                    return a.value.localeCompare(b.value)
                }
                return Number(a.value) - Number(b.value)
            })

            return data
        }
        return []
    },
    filterSets({ filters }: FilterState) {
        return {
            Saved: filters.filter((f) => !f.locked && !f.is_default), // the user's own saved filters
            // We used to have a second type, 'User Role' filters
        }
    },
    filterEditing({ editing, filters }: FilterState) {
        return filters.find((f) => f.id === editing)
    },
    filterEditMode({ editMode }: FilterState) {
        return editMode
    },
    filterSearchTerm({ searchTerm }: FilterState) {
        return searchTerm
    },
    filterLastPatch({ lastPatch }: FilterState) {
        return lastPatch
    },
    filterLastAdd({ lastAdd }: FilterState) {
        return lastAdd
    },
    filterPatching({ patching }: FilterState) {
        return patching
    },
    filterPatchError({ patchError }: FilterState) {
        return patchError
    },
    industries({ industries }: FilterState) {
        return industries
    },
    reportLeaderboardList({ leaderboards }: FilterState) {
        return leaderboards
    },
    appBaseUrl({ appBaseUrl }: FilterState) {
        return appBaseUrl
    },
    company({ company }: FilterState) {
        return company
    },
    scorecardTopics({ scorecardTopics }: FilterState) {
        return scorecardTopics
    },
    topicFeedbackFilters({ topicFeedbackFilters }): IFilterRule[] | undefined {
        return topicFeedbackFilters
    },
    topicFeedbackTimeOption({
        topicFeedbackTimeOption,
    }): TimeOptionLabel | undefined {
        return topicFeedbackTimeOption
    },
}

const actions = {
    // @todo error handling

    async getFilters({ commit }) {
        const { data } = await getFilters()
        commit('setFilters', data)
    },

    async getScorecardTopics({ commit }) {
        const { data } = await getScorecardTopics()
        commit('setScorecardTopics', data)
    },

    setIndustry({ commit }, industry: string) {
        commit('setIndustry', industry)
    },

    setFilterEditing({ commit }, { id, mode }) {
        commit('setFilterEditing', { id, mode })
    },

    setAppBaseUrl({ commit }, url: string) {
        commit('setAppBaseUrl', url)
    },

    populateTimeOptions({ commit }) {
        commit('setTimeOptions', getTimeOptions())
    },

    async getRuleOptions({ commit }) {
        const { data } = await getRuleOptions()
        commit('setRuleOptions', data)
    },

    async getRuleOptionsWithSubOptions({ commit }) {
        const { data } = await getRuleOptionsWithSuboptions()
        commit('setRuleOptionsWithSubOptions', data)
    },

    async loadRuleSubOptions({ commit, state }, option: string) {
        commit('loadRuleSubOptions', option)
        const { data } = await getRuleSubOptions(
            option,
            state.searchTerm,
            state.usePagination
        )
        if (option !== state.loadingRuleSubOption) {
            // Somehow we got data for an option we were not trying to load.  Maybe
            // a laggy XHR response?  Ignore it.
            return
        }

        commit('setRuleSubOptions', {
            option,
            options: data || [],
        } as OptionPayload)
    },

    async reloadRuleSubOptions({ commit, state }, option: string) {
        const { data } = await getRuleSubOptions(option, state.searchTerm)
        if (data && data.length) {
            commit('setRuleSubOptions', {
                option,
                options: data || [],
            } as OptionPayload)
        }
    },

    clearRuleSubOption({ commit }) {
        commit('clearRuleSubOption')
    },

    async setFilterTime(context, { id, time_unit, time_value }) {
        const patch = { id, time_unit, time_value }
        await actions.patchFilter(context, patch)
    },

    async setFilterRule(context, { id, rule }) {
        const filter = context.state.filters.find((f) => f.id === id)
        if (!filter) {
            return
        }
        // replaced the previous applied filter with new same filter
        const filter_rules = dedupBy(
            (filter.filter_rules || []).concat([rule]),
            (r: IFilterRule) => r.column
        )
        const patch = { filter_rules, id }
        await actions.patchFilter(context, patch)
    },

    async removeFilterRule(context, { id, column, operator }) {
        const filter = context.state.filters.find((f) => f.id === id)
        if (!filter) {
            return
        }
        const patch = {
            filter_rules: (filter.filter_rules || []).filter(
                (r) => r.column + '/' + r.operator !== column + '/' + operator
            ),
            id,
        }
        await actions.patchFilter(context, patch)
    },

    async removeFilterValue(context, { ruleValue, id, column }) {
        const filter = context.state.filters.find((f) => f.id === id)
        filter.filter_rules.filter((f, idx) => {
            if (f.column === column) {
                const removeIdx = f.value.indexOf(ruleValue)
                if (removeIdx >= 0) {
                    f.value.splice(removeIdx, 1)
                }
                if (f.value.length < 1) {
                    filter.filter_rules.splice(idx, 1)
                }
            }
        })
        await actions.patchFilter(context, filter)
    },

    async setFilterQuestionType(context, { id, question_type }) {
        await actions.setFilterRule(context, {
            id,
            rule: {
                column: 'question_type',
                operator: 'in',
                value: [question_type],
            },
        })
    },

    async removeAllFilters(context, { id }) {
        const filter = context.state.filters.find((f) => f.id === id)
        filter.filter_rules = []

        await actions.patchFilter(context, filter)
    },

    async removeAllFiltersAndResetTime(context, { id, time_unit, time_value }) {
        const patch = { id, filter_rules: [], time_unit, time_value }
        await actions.patchFilter(context, patch)
    },

    async copyFilter(context, { targetFilterId, sourceFilter }) {
        const patch = {
            ...sourceFilter,
            id: targetFilterId,
        }
        await actions.patchFilter(context, patch)
    },

    async createFilter({ commit }, filter: IFilter): Promise<IFilter> {
        const { data } = await createFilter(filter)
        const {
            is_shared,
            user_id,
            name,
            filter_rules,
            time_unit,
            time_value,
            question_type,
        } = filter
        const newFilter = {
            id: data.id,
            user_id,
            is_shared,
            name,
            filter_rules,
            time_unit,
            time_value,
            question_type,
        }
        commit('addFilter', newFilter)
        return newFilter
    },

    async patchFilter({ commit, dispatch }, patch: Partial<IFilter>) {
        try {
            commit('patchingFilter')
            await patchFilter(patch)
            commit('patchFilter', patch)
        } catch (err) {
            const message = 'failed to update filter'
            commit('patchFilterError', message)
            dispatch('statusAddToast', message)
        }
    },

    async deleteFilter({ commit }, id: number) {
        await deleteFilter(id)
        commit('removeFilter', { id })
    },

    setSearchTerm({ commit }, term) {
        commit(
            'setSearchTerm',
            typeof term === 'string' ? term.trim().toLowerCase() : ''
        )
    },

    setUsePagination({ commit }, usePagination: boolean) {
        commit('setUsePagination', usePagination)
    },

    async loadBenchmarkIndustriesList({ commit, state }) {
        const { data } = await getBenchmarkIndustriesList(state.appBaseUrl)
        commit('setIndustries', data)
    },

    async loadLeaderboardList({ commit, state }) {
        const { data } = await getReportLeaderboardList()
        commit('setLeaderboardList', data)
    },

    async loadCompany({ commit }) {
        const { data } = await getCompany()
        commit('setCompany', data)
    },

    async setTopicFeedbackFilters({ commit }, feedbackFilters: IFilterRule[]) {
        commit('topicFeedbackFilters', feedbackFilters)
    },
    async resetTopicFeedbackFilters({ commit }) {
        commit('topicFeedbackFilters', undefined)
    },
    setTopicFeedbackTimeOption({ commit }, timeOption: TimeOptionLabel) {
        commit('topicFeedbackTimeOption', timeOption)
    },
    resetTopicFeedbackTimeOption({ commit }, timeOption: TimeOptionLabel) {
        commit('topicFeedbackTimeOption', undefined)
    },
}

const mutations: MutationTree<FilterState> = {
    setFilters(state, filters: IFilter[]) {
        state.filters = filters
        state.patchError = ''
    },

    setScorecardTopics(state, scorecardTopics: IScorecardTopic[]) {
        state.scorecardTopics = scorecardTopics
    },

    setIndustry(state, industry: string) {
        state.company.industry = industry
    },

    setFilterEditing(state, { id, mode }) {
        state.editing = id
        state.editMode = mode
    },

    setTimeOptions(state, timeOptions: TimeOptionLabel[][]) {
        state.timeOptions = timeOptions
    },

    setRuleOptions(state, options: IFilterOption[]) {
        state.ruleOptions = options
    },

    setRuleOptionsWithSubOptions(state, options: IFilterOption[]) {
        state.ruleOptions = options
    },

    loadRuleSubOptions(state, option: string) {
        state.loadingRuleSubOption = option
        state.searchTerm = '' // reset it because the options are changing
    },

    setRuleSubOptions(state, { option, options }: OptionPayload) {
        state.activeRuleSubOption = option
        state.loadingRuleSubOption = ''
        state.ruleOptions = state.ruleOptions.map((opt) =>
            opt.value === option ? { ...opt, options } : opt
        )
    },

    clearRuleSubOption(state) {
        state.activeRuleSubOption = ''
    },

    patchingFilter(state) {
        state.patching = true
        state.patchError = ''
    },

    patchFilter(state, patch: Partial<IFilter> & { id: number }) {
        state.lastPatch = new Date().valueOf()
        state.patching = false
        state.filters = state.filters.map((filter: IFilter) =>
            filter.id === patch.id
                ? {
                      ...filter,
                      ...patch,
                      question_type:
                          patch.filter_rules?.find(
                              (rule) => rule.column === 'question_type'
                          )?.value?.[0] ?? filter.question_type,
                  }
                : filter
        )
    },

    patchFilterError(state, message: string) {
        state.patchError = message
        state.patching = false
    },

    addFilter(state, filter: IFilter) {
        state.filters = dedupBy(state.filters.concat(filter), (f) =>
            String(f.id)
        )
        state.lastAdd = new Date().valueOf()
    },

    removeFilter(state, { id }: { id: number }) {
        state.filters = state.filters.filter((f) => f.id !== id)
    },

    setSearchTerm(state, term: string) {
        state.searchTerm = term
    },

    setUsePagination(state, usePagination: boolean) {
        state.usePagination = usePagination
    },

    setIndustries(state, industries: IIndustry[]) {
        state.industries = industries
    },

    setLeaderboardList(state, leaderboards: []) {
        state.leaderboards = leaderboards
    },

    setAppBaseUrl(state, url: string) {
        state.appBaseUrl = url
    },

    setCompany(state, company: any) {
        state.company = company
    },

    // For passing filter from Topics component to Feedback component
    topicFeedbackFilters(state, feedbackFilters: IFilterRule[] | undefined) {
        state.topicFeedbackFilters = feedbackFilters
    },

    // For passing timeOption from Topics component to Feedback component
    topicFeedbackTimeOption(state, timeOption: TimeOptionLabel | undefined) {
        state.topicFeedbackTimeOption = timeOption
    },
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations,
}
