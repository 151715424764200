<template>
    <div
        v-if="isHtml"
        :class="['clamp', { 'italic-text': textItalic }]"
        :style="clampStyling"
        v-html="htmlText"
    />
    <div
        v-else
        :class="['clamp', { 'italic-text': textItalic }]"
        :style="clampStyling"
    >
        {{ text }}
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import DOMPurify from 'dompurify'

/**
 * TextClamp - Truncates text to a specific number of lines
 * You can use plain text or provide sanitised HTML to render
 */
@Component({})
export default class TextClamp extends Vue {
    @Prop({ type: String, required: true }) public readonly text!: string
    @Prop({ type: Boolean, default: false })
    public readonly textItalic!: boolean
    @Prop({ type: Boolean, default: false }) public readonly isHtml?: boolean
    @Prop({ type: Number, default: 3 }) public readonly maxLines!: number // 0 = no clamping
    @Prop({ type: String, default: '21px' }) public readonly lineHeight!: string // We need to know the line height of contained text to clamp text in iOS app

    public get clampStyling() {
        if (this.maxLines > 0) {
            return {
                '-webkit-line-clamp': this.maxLines,
                'line-clamp': this.maxLines,
                'max-height': `calc(${this.lineHeight} * ${this.maxLines})`,
            }
        }
        return {}
    }

    public get htmlText() {
        return DOMPurify.sanitize(this.text)
    }
}
</script>

<style lang="less" scoped>
.clamp {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    &.italic-text {
        font-style: italic;
    }
}

// :deep(), used to target out-of-scope v-html rendered <p>
.clamp :deep(p) {
    margin: 0;
}
</style>
