import { ActionTree, MutationTree, GetterTree } from 'vuex'
import Bugsnag from '@bugsnag/js'
import {
    getCsrfToken,
    me,
    saveSelectedScorecard,
    getScorecards,
} from '@/mobile/src/api/auth'
import {
    MultiLoginTokens,
    UserDetails,
    UserRoleType,
} from '@/mobile/src/types/auth'
import { LooseObject } from '@/pages/appstore/entities/app'
import { TabData } from '@/mobile/src/types/tab'
import IconLightBulb from '@/assets/img/Icons/light-bulb-grey.svg'
import IconLightBulbActive from '@/assets/img/Icons/light-bulb-purple.svg'
import IconTabScorecard from '@/assets/img/Icons/tab-scorecard.svg'
import IconTabScorecardActive from '@/assets/img/Icons/tab-scorecard-active.svg'
import IconTabInsights from '@/assets/img/Icons/tab-insights.svg'
import IconTabInsightsActive from '@/assets/img/Icons/tab-insights-active.svg'
import IconTabInbox from '@/assets/img/Icons/tab-inbox.svg'
import IconTabNotices from '@/assets/img/Icons/tab-notices.svg'
import IconTabNoticesActive from '@/assets/img/Icons/tab-notices-active.svg'
import IconTabFeedback from '@/assets/img/Icons/tab-feedback.svg'
import IconTabFeedbackActive from '@/assets/img/Icons/tab-feedback-active.svg'
import IconTabFeedbackWhite from '@/assets/img/Icons/tab-feedback-white.svg'
import IconTabMore from '@/assets/img/Icons/tab-more.svg'
import IconTabMoreActive from '@/assets/img/Icons/tab-more-active.svg'
import IconTabHomeWhite from '@/assets/img/Icons/tab-home-white.svg'
import IconTabTeams from '@/assets/img/Icons/tab-teams.svg'
import IconTabEvenMore from '@/assets/img/Icons/tab-even-more.svg'
import IconTabHuddles from '@/assets/img/Icons/tab-flower.svg'
import { Capacitor } from '@capacitor/core'
import { Storage } from '@capacitor/storage'
import { Dialog } from '@capacitor/dialog'
import { StatusBar, Style } from '@capacitor/status-bar'
import { time } from '@/utils/time'
import { rootDomain } from '@/mobile/src/utils/misc'
import { CompanyFeatures, QuestionType } from '@/entities'
import { saveUserConfig } from '@/api/settings/account/users'
import { clone } from '@/utils/object'
import { sleep } from '@/utils/async'
import { clearAllFromSessionStorage } from '@/utils/sessionstorage'
import { pendoTrackEvent } from '@/utils/pendo'
import { InsightsMetric } from '@/mobile/src/types/insights'
import { OfflineCache } from '@/mobile/src/utils/offlinecache'
import { Scorecard } from '@/mobile/src/types/scorecard'

export interface UserState {
    domain: string
    id: string
    email: string
    phone: string
    name: string
    avatar: string
    firstMobileAuthcheck: number // timestamp when user first time login to mobile app
    accesstoken: string
    csrftoken: string
    details: UserDetails
    unread: object
    savedLeaderboardsNames: string[]
    hidePushNotificationModal: boolean
    mobileQuestionType: QuestionType
    switchMetricAlertShown: boolean
    disablePendo: boolean
    currentlyCaching: boolean
    cacheStage: string
    lastDataCached: number
    lastTimeSpent: number
    lastDataSize: number
    activeMetrics: string[]
    unseenAchievements: []
    feedbackFilter: string
    showSeasonalBanner: boolean
    availableScorecards: Scorecard[]
    isImpersonating: boolean
    loginTokens: MultiLoginTokens
    adminToken: string
}

export const state: UserState = {
    domain: '',
    id: '',
    email: '',
    phone: '',
    name: '',
    avatar: '',
    firstMobileAuthcheck: 0,
    accesstoken: '',
    csrftoken: '',
    details: {
        permissions: [],
        company: {},
        userRole: '',
        userRoleType: UserRoleType.FrontlineStaff, // default to `frontline_staff`
        hasPushDevice: 0,
        userHasMlp: false,
        userHasMfa: null,
        selectedScorecard: null,
    },
    unread: {},
    savedLeaderboardsNames: [],
    hidePushNotificationModal: false,
    // which question type is selected to show on mobile app
    mobileQuestionType: 'nps',
    switchMetricAlertShown: true, // whether the user has seen the switch metric alert modal
    disablePendo: false,
    currentlyCaching: false,
    cacheStage: 'boot',
    lastDataCached: 0,
    lastTimeSpent: 0,
    lastDataSize: 0,
    activeMetrics: [],
    unseenAchievements: [],
    feedbackFilter: '',
    showSeasonalBanner: false,
    availableScorecards: [],
    isImpersonating: false,
    loginTokens: {},
    adminToken: '',
}

const initialState = { ...state }

const getters: GetterTree<UserState, any> = {
    userId({ id }) {
        return id
    },

    userName({ name }) {
        return name
    },

    userAvatar({ avatar }) {
        return avatar
    },

    // tenant domain name like jarvis
    domain({ domain }) {
        return domain
    },

    isImpersonating({ isImpersonating }) {
        return isImpersonating
    },

    // full domain name like https://jarvis.asknice.ly for mobile or empty string '' for web
    // because for mobile we need the full url otherwise it's using 'localhost'
    fullDomainUrl({ domain }) {
        return `https://${domain}.${rootDomain}`
    },

    email({ email }) {
        return email
    },

    phone({ phone }) {
        return phone
    },

    accesstoken({ accesstoken }) {
        return accesstoken
    },

    csrftoken({ csrftoken }) {
        return csrftoken
    },

    disablePendo({ disablePendo }) {
        return disablePendo
    },

    showStaffPushNotificationPanel({ details }) {
        // if is frontline staff && no push device
        const { userRoleType, company } = details
        return (
            userRoleType === UserRoleType.FrontlineStaff &&
            company.has_smart_notifications
        )
    },

    // show push notifi request after 32hours of first login to mobile
    showStaffPushNotificationModal({
        firstMobileAuthcheck,
        details,
        hidePushNotificationModal,
    }) {
        const { userRoleType, company } = details
        return (
            userRoleType === UserRoleType.FrontlineStaff &&
            !hidePushNotificationModal &&
            time() - firstMobileAuthcheck > 3600 * 32 &&
            company.has_smart_notifications
        )
    },

    showAdminPushNotificationNative({ details }) {
        const { userRoleType, company } = details
        return (
            userRoleType !== UserRoleType.FrontlineStaff ||
            !company.has_smart_notifications
        )
    },

    details({ details }) {
        return details
    },

    permissions({ details }) {
        return details.permissions || []
    },

    companyProps({ details }) {
        return details.company || []
    },

    getFeatureValue({ details }) {
        return (featureName: CompanyFeatures) => {
            return details.company[featureName]
        }
    },

    hasNoticeTab({ details }) {
        const { permissions, company }: UserDetails = details
        return company.has_notices && permissions.includes('ROLE_VIEW_NOTICES')
    },

    hasInsightsTab({ details }) {
        const { permissions, company, userRoleType }: UserDetails = details

        return (
            company.enable_insights_v1 &&
            (userRoleType === UserRoleType.FrontlineManager ||
                userRoleType === UserRoleType.HeadOffice)
        )
    },

    hasTeamsTab({ details }) {
        const { company, userRoleType }: UserDetails = details

        return (
            getters.userHasMlp &&
            (userRoleType === UserRoleType.FrontlineManager ||
                userRoleType === UserRoleType.FrontlineStaff ||
                userRoleType === UserRoleType.HeadOffice)
        )
    },

    hasTeamScoreboardTab({ details }) {
        const { permissions, company }: UserDetails = details

        return (
            company.has_scorecard &&
            permissions.includes('ROLE_VIEW_TEAM_SCOREBOARD')
        )
    },

    userRole({ details }) {
        return details.userRole
    },

    userRoleType({ details }) {
        return details.userRoleType
    },

    userMasterRole({ details }) {
        return details.userMasterRole
    },

    userMasterRoleType({ details }) {
        return details.userMasterRoleType
    },

    selectedScorecard({ details }) {
        return details.selectedScorecard
    },

    availableScorecards({ availableScorecards }) {
        return availableScorecards
    },

    userHasMlp({ details }) {
        return details.userHasMlp
    },

    userHasMfa({ details }) {
        return details.userHasMfa
    },

    savedLeaderboardsNames({ savedLeaderboardsNames }) {
        return savedLeaderboardsNames || []
    },

    // visible tabs based on permission and feature
    mainNavTabs({ details, unread }, { hasInsightsTab, hasTeamsTab }) {
        const { permissions, company, userRoleType, userHasMlp }: UserDetails =
            details
        const tabs: TabData[] = []
        const tabUnreadCount = (tabName) => {
            return unread[tabName] ? unread[tabName] : 0
        }

        // Temporary tabs for App V4 while it's behind the toggle
        if (userHasMlp) {
            tabs.push({
                text: 'home',
                icon: IconTabHomeWhite,
                activeIcon: IconTabHomeWhite,
                name: 'overview',
                count: tabUnreadCount('overview'),
            })

            if (hasTeamsTab) {
                tabs.push({
                    text: 'teams',
                    icon: IconTabTeams,
                    activeIcon: IconTabTeams,
                    name: 'teams',
                    count: tabUnreadCount('teams'),
                })
            }

            if (
                company.has_notices &&
                permissions.includes('ROLE_VIEW_NOTICES')
            ) {
                tabs.push({
                    text: 'inbox',
                    icon: IconTabInbox,
                    activeIcon: IconTabInbox,
                    name: 'inbox',
                    count: tabUnreadCount('moments'),
                })
            }

            tabs.push({
                text: 'feedback',
                icon: IconTabFeedbackWhite,
                activeIcon: IconTabFeedbackWhite,
                name: 'myfeedback',
                count: tabUnreadCount('myfeedback'),
            })

            if (company.has_huddle) {
                tabs.push({
                    text: 'huddle',
                    icon: IconTabHuddles,
                    activeIcon: IconTabHuddles,
                    name: 'huddles',
                })
            }

            tabs.push({
                text: 'more',
                icon: IconTabEvenMore,
                activeIcon: IconTabEvenMore,
                name: 'evenmore',
            })

            return [...tabs]
        }

        if (
            company.has_scorecard &&
            permissions.includes('ROLE_VIEW_PERSONAL_SCORECARD')
        ) {
            tabs.push({
                text: 'overview',
                icon: IconTabScorecard,
                activeIcon: IconTabScorecardActive,
                name: 'scorecardHome',
                count: tabUnreadCount('scorecard'),
            })
        }

        if (hasInsightsTab) {
            tabs.push({
                text: 'insights',
                icon: IconTabInsights,
                activeIcon: IconTabInsightsActive,
                name: 'insightsHome',
                count: tabUnreadCount('insights'),
            })
        }

        if (company.has_notices && permissions.includes('ROLE_VIEW_NOTICES')) {
            tabs.push({
                text: 'notices',
                icon: IconTabNotices,
                activeIcon: IconTabNoticesActive,
                name: 'notices',
                count: tabUnreadCount('notices'),
            })
        }

        if (permissions.includes('ROLE_VIEW_CREATE_SUGGESTIONS')) {
            tabs.push({
                text: 'suggestions',
                icon: IconLightBulb,
                activeIcon: IconLightBulbActive,
                name: 'suggestions',
                count: tabUnreadCount('suggestions'),
            })
        }

        if (
            !company.has_scorecard ||
            !permissions.includes('ROLE_VIEW_PERSONAL_SCORECARD')
        ) {
            tabs.push({
                text: 'feedback',
                icon: IconTabFeedback,
                activeIcon: IconTabFeedbackActive,
                name: 'feedback',
                count: tabUnreadCount('feedback'),
            })
        }

        return [
            ...tabs,
            {
                text: 'more',
                icon: IconTabMore,
                activeIcon: IconTabMoreActive,
                name: 'more',
                count: tabUnreadCount('more'),
            },
        ]
    },

    mobileQuestionType({ mobileQuestionType }) {
        return mobileQuestionType
    },
    // active filter for mobile app, since user can select
    // which metric to show and not in sync with the desktop one
    mobileFilterActive(
        { mobileQuestionType },
        getters,
        rootState,
        rootGetters
    ) {
        const { filterActive, hasNetworkConnection, offlineFilter } =
            rootGetters
        if (!hasNetworkConnection) {
            return offlineFilter(mobileQuestionType)
        }
        if (!filterActive) {
            return filterActive
        }
        const mobileFilter = clone(filterActive)
        mobileFilter.question_type = mobileQuestionType
        if (mobileFilter.filter_rules) {
            mobileFilter.filter_rules = mobileFilter.filter_rules.map(
                (rule) => ({
                    ...rule,
                    value:
                        rule.column === 'question_type'
                            ? [mobileQuestionType]
                            : rule.value,
                })
            )
        }

        return mobileFilter
    },

    // filter to use when it's offline mode
    offlineFilter() {
        return (questionType = 'nps') => ({
            filter_rules: [
                {
                    column: 'question_type',
                    operator: 'in',
                    value: [questionType],
                },
            ],
            time_unit: 'month',
            time_value: '1',
            question_type: questionType,
        })
    },

    currentlyCaching({ currentlyCaching }) {
        return currentlyCaching
    },

    lastDataCached({ lastDataCached }) {
        return lastDataCached
    },

    cacheStage({ cacheStage }) {
        return cacheStage
    },

    lastTimeSpent({ lastTimeSpent }) {
        const m = Math.floor((lastTimeSpent % 3600) / 60)
        const s = Math.floor((lastTimeSpent % 3600) % 60)
        return `${m}m ${s}s`
    },

    lastDataSize({ lastDataSize }) {
        return lastDataSize
    },

    activeMetrics({ activeMetrics }) {
        return activeMetrics
    },

    unseenAchievements({ unseenAchievements }) {
        return unseenAchievements
    },

    isFrontlineEngagementUser({ details }) {
        const { company, userRoleType }: UserDetails = details
        return (
            company.has_insights_v3 &&
            userRoleType === UserRoleType.FrontlineStaff
        )
    },

    isUsingAppV4({ details }) {
        const { userHasMlp }: UserDetails = details
        return process.env.CONFIG_KEY === 'mobile' && userHasMlp
    },

    isFrontlineStaff({ details }) {
        const { userRoleType }: UserDetails = details
        return userRoleType === UserRoleType.FrontlineStaff
    },

    isFrontlineManager({ details }) {
        const { userRoleType }: UserDetails = details
        return userRoleType === UserRoleType.FrontlineManager
    },

    feedbackFilter({ feedbackFilter }) {
        return feedbackFilter
    },

    showSeasonalBanner: ({ showSeasonalBanner }) => {
        return showSeasonalBanner
    },

    teamFilterAdminRight({ details }): boolean {
        const { userRoleType, userRole }: UserDetails = details
        // AppV4, Allow user with admin right to see the whole team hierarchy menu
        return (
            userRoleType === UserRoleType.HeadOffice ||
            userRole === UserRoleType.Admin
        )
    },

    loginTokens({ loginTokens }): MultiLoginTokens {
        return loginTokens
    },

    adminToken({ adminToken }): string {
        return adminToken
    },
}

const validKeys = [
    'domain',
    'email',
    'name',
    'accesstoken',
    'csrftoken',
    'id',
    'avatar',
    'firstMobileAuthcheck',
    'mobileQuestionType',
    'switchMetricAlertShown',
    'savedLeaderboardsNames',
    'unseenAchievements',
    'availableScorecards',
    'hidePushNotificationModal',
]

const actions: ActionTree<UserState, any> = {
    async setUserProp({ commit, state }, kvs: LooseObject) {
        commit('setUserProp', kvs)
    },

    async switchMetricType(
        { commit, state, getters, dispatch },
        newQuestionType,
        showAlert = true
    ) {
        if (newQuestionType !== getters.mobileQuestionType) {
            if (showAlert && !state.switchMetricAlertShown) {
                // show confirm
                const confirmRet = await Dialog.confirm({
                    title:
                        'Changing view result to ' +
                        newQuestionType.toUpperCase(),
                    message:
                        'This will affect all results including Feedback,' +
                        (getters.hasTeamScoreboardTab
                            ? ' Team Scoreboard,'
                            : '') +
                        (getters.hasInsightsTab ? ' Insights,' : '') +
                        ' Leaderboards',
                })
                if (!confirmRet.value) {
                    return false
                }
            }
            saveUserConfig({
                mobileQuestionType: newQuestionType,
                switchMetricAlertShown: true,
            })
            commit('setUserProp', {
                mobileQuestionType: newQuestionType,
                switchMetricAlertShown: true,
            })
            if (state?.details.userHasMlp) {
                dispatch('scoredata/loadScoreData', {
                    days: '30',
                    forceRefresh: true,
                })
                dispatch('topicfeedback/getTopicFeedback', {
                    month: new Date().getMonth() + 1,
                    forceRefresh: true,
                })
            }

            pendoTrackEvent('mobile_metric_type_switched')

            return true
        }

        commit('setUserProp', {
            mobileQuestionType: newQuestionType,
            switchMetricAlertShown: true,
        })

        return true
    },

    async refreshCsrf({ commit, dispatch }) {
        const result = await getCsrfToken()
        commit('setUserProp', { csrftoken: result.data })
    },

    setSavedLeaderboardsNames({ commit, state, dispatch }, payload) {
        commit('setUserProp', { savedLeaderboardsNames: payload })
    },

    async setDetails({ commit, state, dispatch, rootGetters }) {
        if (!state.accesstoken) {
            if (Capacitor.isPluginAvailable('StatusBar')) {
                await StatusBar.setStyle({ style: Style.Dark })
            }
            return // if user not logged in, do nothing
        }

        if (!rootGetters.hasNetworkConnection) {
            return
        }

        const details = await me()
        if (!details.success) {
            // need to logout user and re-login
            dispatch('logoutUser')
            return
        }

        const {
            permissions,
            company,
            user,
            activeMetrics,
            userRole,
            userRoleType,
            userMasterRole,
            userMasterRoleType,
            hasPushDevice,
            userHasMlp,
            userHasMfa,
            unseenAchievements,
            appRequirements,
            selectedScorecard,
            impersonating,
        } = details.data

        if (Capacitor.isPluginAvailable('StatusBar')) {
            if (userHasMlp) {
                await StatusBar.setStyle({ style: Style.Dark })
            } else {
                await StatusBar.setStyle({ style: Style.Light })
            }
        }

        commit('setDetails', {
            permissions,
            company,
            userRole,
            userRoleType,
            userMasterRole,
            userMasterRoleType,
            hasPushDevice,
            userHasMlp,
            userHasMfa,
            selectedScorecard,
        })
        commit('setDeviceRequirement', appRequirements)
        commit('setCompanyName', company?.name ?? '')
        commit('setActiveMetrics', activeMetrics)
        commit('$setCompanyData', company)
        commit('$setIsMobile', true)
        commit('setUserProp', details.data.user)
        commit('setUserProp', { unseenAchievements })
        commit('setIsImpersonating', impersonating)
        // once CompanyData loaded, then re-set the timeOptions
        dispatch('populateTimeOptions')

        // update suggestions store
        dispatch('setUser', {
            userId: user.id,
            userName: user.name,
            userAvatar: user.avatar,
            userIsAdmin: permissions.includes('ROLE_ADMIN_SUGGESTIONS'),
        })

        // set insights metric if user has saved metric
        if (details.data?.user?.mobileQuestionType) {
            let metricType = InsightsMetric.NPS
            const questionType = details.data?.user?.mobileQuestionType ?? 'nps'
            switch (details.data?.user?.mobileQuestionType) {
                case 'csat':
                    metricType = InsightsMetric.CSAT
                    break
                case 'fivestar':
                    metricType = InsightsMetric.FIVESTAR
                    break
            }
            commit('setSelectedMetric', metricType)
            commit('setSelectedQuestionType', questionType)
        }

        Bugsnag.setUser(user.id, user.email, user.name)
    },

    async logoutUser({ commit }) {
        await commit('resetState')

        // have to clear storage, so it won't be able to be restored
        await Storage.clear()
        if (Capacitor.isPluginAvailable('StatusBar')) {
            await StatusBar.setStyle({ style: Style.Dark })
        }

        await clearAllFromSessionStorage()

        await Bugsnag.setUser()

        await window.location.reload()
    },

    setTabUnreadCount({ commit, state }, tab: { name: string; count: number }) {
        commit('setUnread', { ...state.unread, [tab.name]: tab.count })
    },

    updateLastDataCached({ state }, { timeUsed, dataSize }) {
        state.lastDataCached = new Date().getTime() / 1000
        state.lastTimeSpent = timeUsed
        state.lastDataSize = dataSize
    },

    async fillOfflineCache({ dispatch, getters, state, commit }) {
        if (!state.accesstoken) {
            return
        }
        const start = new Date().getTime() / 1000

        if (
            start - getters.lastDataCached < 60 * 60 ||
            !getters.$companyVars.has_mobile_offline_cache
        ) {
            return // already cached within 1 hour, not doing it again
        }
        dispatch('updateLastDataCached', { timeUsed: 0, dataSize: 0 })

        pendoTrackEvent('mobile-offline-cache-started')
        commit('setCurrentlyCaching', true)
        const { permissions, company }: UserDetails = getters.details
        try {
            commit('setCacheStage', 'scoreData')
            await dispatch('scoredata/loadOfflineDataForScoreData')
            commit('setCacheStage', 'topicFeedback')
            await dispatch('topicfeedback/loadOfflineDataForTopicFeedback')
            commit('setCacheStage', 'topicScoreboard')
            await dispatch('loadOfflineDataForTopicScoreboard')
            // Only user with view personal scorecard permission can access /ajax/scorecard
            if (
                company.has_scorecard &&
                permissions.includes('ROLE_VIEW_PERSONAL_SCORECARD')
            ) {
                commit('setCacheStage', 'scoreboard')
                await dispatch('loadOfflineDataForScorecard')
            }
            commit('setCacheStage', 'feedback')
            await dispatch('loadOfflineDataForFeedback')
            commit('setCacheStage', 'complete')
        } catch (e) {
            pendoTrackEvent('mobile-offline-cache-error', {
                stage: getters.cacheStage,
            })
            throw e
        } finally {
            const timeUsed = Math.round(new Date().getTime() / 1000 - start)
            const dataSize =
                (await new OfflineCache('scorecard').length()) +
                (await new OfflineCache('responses').length())
            dispatch('updateLastDataCached', { timeUsed, dataSize })

            pendoTrackEvent('mobile-offline-cache-finished', {
                duration: timeUsed,
            })
            commit('setCurrentlyCaching', false)
        }
    },

    async setFeedbackFilter({ commit }, filters) {
        commit('setFeedbackFilter', filters)
    },

    toggleShowSeasonalBanner: ({ commit }) => {
        commit('toggleShowSeasonalBanner')
    },

    closeSeasonalBanner: ({ commit }) => {
        commit('closeSeasonalBanner')
    },

    async loadScorecards({ commit }) {
        await getScorecards().then((response) => {
            commit('setUserProp', { availableScorecards: response.data })
        })
    },

    async selectNewScorecard({ commit, state }, scorecard) {
        const currentSelectedScorecard = state.details.selectedScorecard
        commit('setSelectedScorecard', scorecard?.id)

        await saveSelectedScorecard(scorecard).catch(() => {
            // Switch back to previously selected scorecard if the request fails
            commit('setSelectedScorecard', currentSelectedScorecard)
        })
    },

    saveLoginTokens({ commit }, tokens) {
        commit('setLoginTokens', tokens)
    },

    saveHasMFA({ commit }, payload) {
        commit('setHasMFA', payload)
    },

    saveAdminToken({ commit }, payload) {
        commit('setAdminToken', payload)
    },

    clearLoginTokens({ commit }) {
        commit('clearLoginTokens')
    },
}

const mutations: MutationTree<UserState> = {
    setUserProp(state, kvs: LooseObject) {
        Object.keys(kvs).forEach((key) => {
            if (
                validKeys.indexOf(key) > -1 &&
                typeof kvs[key] !== 'undefined'
            ) {
                state[key] = kvs[key]
            }
        })
    },

    setDetails(state, details: UserDetails) {
        state.details = details
    },

    setActiveMetrics(state, activeMetrics: []) {
        state.activeMetrics = activeMetrics
    },

    setUnread(state, unread) {
        state.unread = unread
    },

    setSelectedScorecard(state, scorecardId: number) {
        state.details.selectedScorecard = scorecardId
    },

    setFeedbackFilter(state, filters) {
        state.feedbackFilter = filters
    },

    setIsImpersonating(state, isImpersonating) {
        state.isImpersonating = isImpersonating
    },

    toggleShowSeasonalBanner: (state) => {
        state.showSeasonalBanner = !state.showSeasonalBanner
        if (state.showSeasonalBanner) {
            pendoTrackEvent('seasonal-banner-revealed')
        }
    },

    closeSeasonalBanner: (state) => {
        state.showSeasonalBanner = false
    },

    setLoginTokens(state, tokens) {
        state.loginTokens = tokens
    },

    setHasMFA(state, userHasMfa: boolean) {
        state.details.userHasMfa = userHasMfa
    },

    setAdminToken(state, { token, email }) {
        state.adminToken = token
        state.email = email
    },

    clearLoginTokens(state) {
        state.loginTokens = {}
        state.adminToken = ''
    },

    resetState(state) {
        state = { ...initialState }
    },

    setCurrentlyCaching(state, isCaching) {
        state.currentlyCaching = isCaching
    },

    setCacheStage(state, stage) {
        state.cacheStage = stage
    },
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations,
}
