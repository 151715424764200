import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f64a9abc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "infinite-scroll-content" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: "infinite-scroll-container",
    class: _normalizeClass(_ctx.scrollContainerClasses)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    (_ctx.isLoadingMore)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "loading", {}, undefined, true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      id: "infinite-scroll-end",
      ref: "infiniteScrollEnd",
      class: _normalizeClass(_ctx.scrollEndClass)
    }, null, 2)
  ], 2))
}