<template>
    <EmptyPageState colour="white" :class="[{ bordered }]">
        <template #icon-img>
            <FontAwesomeIcon :icon="warningIcon" class="warning-icon" />
        </template>
        <template #header-text>Houston, we have a problem!</template>
        <template #body-text>
            Sorry, it looks like something went wrong with our app! Please check
            back later and contact our support team if the problem isn't
            resolved.
        </template>
        <template #action-button>
            <div @click="contactSupport">Contact support team</div>
        </template>
    </EmptyPageState>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faWarning } from 'fontawesome/free-solid-svg-icons'
import { AppLauncher } from '@capacitor/app-launcher'
import { pendoTrackEvent } from '@/utils/pendo'
import EmptyPageState from '@/mobile/src/components/EmptyPageState.vue'

@Component({
    components: {
        EmptyPageState,
        FontAwesomeIcon,
    },
})
export default class ErrorPage extends Vue {
    @Prop({ type: Boolean, default: false })
    public bordered?: boolean

    private warningIcon = faWarning

    public contactSupport() {
        pendoTrackEvent('mobile_error_contact_support_clicked')
        AppLauncher.openUrl({ url: 'mailto:support@asknice.ly' })
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';

.bordered {
    border: 1px dashed @white50;
    padding: @featureGutter;
    border-radius: @borderRadius-md;
}

.warning-icon {
    color: white;
    font-size: 48px;
    padding-bottom: 18px;
}
</style>
