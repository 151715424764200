<template>
    <div class="tooltip uk-icon-info-circle" :style="iconStyle">
        <div class="uk-tooltip uk-tooltip-bottom" :style="style">
            <div class="uk-tooltip-inner">
                <slot />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component({})
export default class NPSTooltip extends Vue {
    @Prop({ type: Number, default: 0 }) public width!: number
    @Prop({ type: String }) public color!: string

    get iconStyle() {
        let style = ''
        if (this.color) {
            style += `color: ${this.color}`
        }
        return style
    }
    get style() {
        let style = ''
        if (this.width) {
            style += `width: ${this.width}px;`
            style += `max-width: ${this.width}px;`
        }

        return style
    }
}
</script>

<style scoped>
.tooltip {
    position: relative;
    display: inline-block;
    margin-left: 6px;
}

.uk-tooltip {
    display: block;
    visibility: hidden;
    width: 116px;
    left: -53px;
    top: 12px;

    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: subpixel-antialiased;
    font-weight: 400;
    text-transform: none;
}

.uk-tooltip a {
    color: #3a4b59;
}

.tooltip:hover .uk-tooltip {
    visibility: visible;
}
</style>
