<template>
    <div v-if="previousTeamScoreboard" class="title-container">
        <div class="left">
            <a class="back" @click="onClickBackTo()">
                <img
                    src="@/assets/img/Icons/corner-right-up.svg"
                    alt="Up one level"
                    width="16"
                    height="16"
                />
                Back to {{ backToText }}
            </a>
        </div>
        <div class="right">
            <img
                :title="siblingGroups.prev ? siblingGroups.prev.group : ''"
                class="prev"
                src="@/assets/img/Icons/left-arrow-blue.svg"
                alt="prev"
                @click="switchTeamScoreboard(siblingGroups.prev)"
            />
            <img
                :title="siblingGroups.next ? siblingGroups.next.group : ''"
                class="next"
                src="@/assets/img/Icons/right-arrow-blue.svg"
                alt="next"
                @click="switchTeamScoreboard(siblingGroups.next)"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { Action, Getter } from 'vuex-facing-decorator'
import { TeamScoreboardEntity } from '@/entities/teamscoreboard'

@Component({})
export default class TsNpsCurrentGroupV2 extends Vue {
    @Getter public companyName
    @Getter public teamScoreboard
    @Getter public previousTeamScoreboard
    @Getter public siblingGroups

    @Action public popTsHierarchy
    @Action public switchTeamScoreboard

    public get backToText() {
        if (this.teamScoreboard?.parent?.field === this.teamScoreboard?.field) {
            return this.previousTeamScoreboard.fieldLabel
        }

        return this.previousTeamScoreboard.parentFieldLabel || this.companyName
    }

    public onClickBackTo() {
        let level = 1
        if (this.teamScoreboard?.parent?.field === this.teamScoreboard?.field) {
            level = 0
        }
        this.popTsHierarchy(level)
    }
}
</script>

<style scoped lang="less">
@import '../../styles/palette';

.title-container {
    background-color: @greyLight;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    padding: 10px 5px 10px 10px;

    .back {
        color: @blue;
        display: flex;
        align-items: center;
    }

    .right {
        img {
            padding: 2px 10px;
        }
    }
}
</style>
