<template>
    <div class="moment-container" @click="viewDetail">
        <MomentFeedbackCard
            v-if="moment.question"
            :moment="moment"
            :moment-recipients="recipients"
        />
        <FeedbackBaseCard
            v-else
            :timestamp="timestamp"
            :author="moment.user.name"
            :text="message"
            :highlight="!disableHighlight && !moment.seen"
            :detailed-view="detailedView"
            :emoji-view="emojiView"
            :emoji-reactions="emojiReactions"
            :show-undo="showUndo"
            :undo-time-left="secondsToDelete"
            card-type="moment"
            @onReact="onReact"
            @undoChat="onUndoChat"
        >
            <template v-if="moment.image" #image>
                <FlexibleImageBox
                    :src="getMessageImageURL(moment.image)"
                    :alt="`Posted by ${moment.user.name}`"
                    extra-class="moment-image"
                />
            </template>

            <template #icon>
                <AvatarOrInitials
                    :size="40"
                    :user="momentUser"
                    class="profile-image"
                />
            </template>
            <template #title>
                <MomentTypeTitle
                    :seen="Boolean(moment.seen)"
                    :title="moment.type"
                    :type-class="typeClass"
                />
            </template>
            <template #rightTitle>
                <DeleteConfirmButton
                    v-if="secondsToDelete > 0 && moment.type === 'Workflow'"
                    delete-confirmation-message="Are you sure you want to cancel this workflow run?"
                    @delete="onConfirmDelete"
                />
                <TextClamp
                    v-if="recipients"
                    autoresize
                    :max-lines="2"
                    :text="`To ${recipients}`"
                />
            </template>
            <template v-if="hasCount && moment.repliesCount > 0" #extraIcon>
                <CountCircle
                    :count="moment.repliesCount"
                    :variation="typeClass"
                    class="replies-count"
                />
            </template>
        </FeedbackBaseCard>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { Getter } from 'vuex-facing-decorator'
import useEmitter from '@/composables/useEmitter'

import { Moment } from '@/entities/moment'
import AvatarOrInitials from '@/components/WebView/AvatarOrInitials.vue'
import CountCircle from '@/mobile/src/components/appV4/CountCircle.vue'
import MomentFeedbackCard from '@/mobile/src/components/appV4/MomentFeedbackCard.vue'
import FeedbackBaseCard from '@/mobile/src/components/appV4/feedback/FeedbackBaseCard.vue'
import MomentTypeTitle from '@/mobile/src/components/appV4/moments/MomentTypeTitle.vue'
import TextClamp from '@/components/TextClamp.vue'
import FlexibleImageBox from '@/mobile/src/components/appV4/FlexibleImageBox.vue'
import { getCDNUrl } from '@/utils/cdn'
import { parseMentionsAndTags } from '@/utils/string'
import { EmojiReactionDetails, IOnReact } from '@/entities'
import { MessageTypeLabel } from '@/mobile/src/types/messageTypes'
import { deleteReply } from '@/api/chat'
import { hideToast, showToast } from '@/mobile/src/utils/toastbus'
import { ToastColor, ToastIcon } from '@/mobile/src/types/toasts'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faClose } from 'fontawesome/free-solid-svg-icons'
import DeleteConfirmButton from '@/mobile/src/components/appV4/DeleteConfirmButton.vue'

@Component({
    components: {
        DeleteConfirmButton,
        FlexibleImageBox,
        MomentTypeTitle,
        MomentFeedbackCard,
        CountCircle,
        AvatarOrInitials,
        FeedbackBaseCard,
        TextClamp,
        FontAwesomeIcon,
    },
    emits: [
        'viewDetail',
        'onReact',
        'undoChat',
        'reloadChat',
        'hardReloadChat',
    ],
})
export default class MomentCard extends Vue {
    @Prop({ type: Object, required: true }) moment!: Moment
    @Prop({ type: Boolean, default: true }) hasCount!: boolean
    @Prop({ type: Boolean, default: false }) disableHighlight!: boolean
    @Prop({ type: Boolean, default: false }) detailedView!: boolean
    @Prop({ type: Boolean, default: false }) emojiView!: boolean

    @Getter readonly hasNetworkConnection

    private currentTime = 0
    private currentTimer

    private undoing = false
    private faClose = faClose

    private emitter = useEmitter()

    public mounted() {
        this.currentTime = new Date().getTime()
        //If this is a customer reply/email, set up the 'undo' timer
        if (this.moment.type === 'Customer Reply' && this.secondsToDelete > 0) {
            this.currentTimer = setInterval(() => {
                this.currentTime = new Date().getTime()
                if (this.currentTimer && this.secondsToDelete <= 0) {
                    clearTimeout(this.currentTimer)
                    this.currentTimer = null
                }
            }, 100)
        }
    }

    public async beforeDestroy() {
        if (this.currentTimer) {
            clearTimeout(this.currentTimer)
        }
    }

    public get typeClass() {
        return this.moment.type.toLowerCase().replace(' ', '-')
    }

    public get recipients() {
        if (this.moment.recipientName.length === 0) {
            return ''
        }
        return this.moment.recipientName.join(', ')
    }

    public get message() {
        if (this.moment.type === 'Internal Note') {
            return parseMentionsAndTags(this.moment.message)
        }

        return this.moment.message
    }

    public onConfirmDelete() {
        this.onCancelWorkflow()
    }

    public get secondsToDelete() {
        return Number(this.moment.created) - this.currentTime / 1000
    }

    public get showUndo() {
        return this.secondsToDelete > 0 && this.moment.type === 'Customer Reply'
    }

    public viewDetail() {
        this.$emit('viewDetail')
    }

    public get timestamp() {
        return Number(this.moment.updated ?? this.moment.created)
    }

    private get emojiReactions(): EmojiReactionDetails[] {
        if (this.emojiView && this.moment.emojis) {
            return this.moment.emojis
        }

        return []
    }

    public getMessageImageURL(imageSrc: string): string {
        return getCDNUrl(imageSrc)
    }

    private onReact(data: IOnReact): void {
        data.sourceId = Number(this.moment.id)
        if (
            [
                MessageTypeLabel.emailReply as string,
                MessageTypeLabel.workflow as string,
            ].includes(this.moment.type)
        ) {
            data.source = 'chat'
        } else if (this.moment.type === MessageTypeLabel.internalNote) {
            data.source = 'note'
        }

        data.emojis = this.moment.emojis
        this.$emit('onReact', data as IOnReact)
    }

    public async onUndoChat() {
        if (this.undoing) {
            return
        }
        this.undoing = true
        let undoToastTimeId = showToast(
            this.emitter,
            ToastIcon.Waiting,
            ToastColor.Warning,
            `Undoing...`,
            0
        )
        const response = await deleteReply(Number(this.moment.id))
        hideToast(this.emitter, undoToastTimeId)
        this.undoing = false

        if (response.data.msg) {
            alert(response.data.msg)
        } else {
            this.$emit('undoChat', this.moment)
        }

        this.$emit('reloadChat')
    }

    public async onCancelWorkflow() {
        if (this.undoing) {
            return
        }
        this.undoing = true
        let undoToastTimeId = showToast(
            this.emitter,
            ToastIcon.Waiting,
            ToastColor.Warning,
            `Cancelling Workflow Run`,
            0
        )
        const response = await deleteReply(Number(this.moment.id))

        if (response.data.msg) {
            alert(response.data.msg)
        }
        this.undoing = false
        this.$emit('hardReloadChat')
        hideToast(this.emitter, undoToastTimeId)
    }

    public get momentUser() {
        return {
            ...this.moment.user,
            avatar: this.hasNetworkConnection ? this.moment.user.avatar : null,
        }
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/appV4/momentTypes.less';
@import '~@/styles/rain/variables.less';

.icons-container {
    .replies-count {
        color: var(--type-colour);
        border-color: var(--type-colour);
    }
}
</style>
