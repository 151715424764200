import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { getTopPerformers } from '@/api/reportcard'
import { IHierarchyGoal } from '@/entities/reportcard'

export interface TopPerformersState {
    cacheKey: string
    topPerformersByUserMonth: Record<number, IHierarchyGoal[]>
    month: number
    userName: string
}

interface TopPerformersCacheKey {
    month: number
    userName: string
}

export const state: TopPerformersState = {
    cacheKey: '',
    topPerformersByUserMonth: {},
    month: 0,
    userName: '',
}

const getters: GetterTree<TopPerformersState, any> = {
    getTopPerformersByUserMonth: (state) => (month: number, userName: string) =>
        state.topPerformersByUserMonth[btoa(`${month}-${userName ?? ''}`)] ||
        null,
    topPerformers: (state) => state.topPerformersByUserMonth[state.cacheKey],
}

const actions: ActionTree<TopPerformersState, any> = {
    async loadTopPerformersData(
        { commit, state, getters },
        { month, userName }: TopPerformersCacheKey
    ) {
        commit('setTopPerformersMonth', month)
        commit('setTopPerformersUserName', userName)
        commit('setCacheKey', btoa(`${state.month}-${state.userName ?? ''}`))

        if (state.topPerformersByUserMonth[state.cacheKey]) {
            return
        }

        try {
            const { data } = await getTopPerformers(month, userName)
            commit('setTopPerformersData', { data: data.topPerformers })
        } catch (error) {
            // handle error
        }
    },
}

const mutations: MutationTree<TopPerformersState> = {
    setTopPerformersData(state, payload: { data: IHierarchyGoal[] }) {
        state.topPerformersByUserMonth[state.cacheKey] = payload.data
    },
    setTopPerformersMonth(state, month: number) {
        state.month = month
    },
    setTopPerformersUserName(state, userName: string) {
        state.userName = userName
    },
    setCacheKey(state, cacheKey: string) {
        state.cacheKey = cacheKey
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
