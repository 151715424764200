<template>
    <WebViewScreen
        v-if="insightsEngagement"
        no-pad
        class="insights"
        show-go-back
        disable-when-offline
    >
        <template #custom-title>
            <TwoLineHeader title="Engagement" :subtitle="friendlyTimeRange" />
        </template>
        <template #right-button>
            <InsightsTimePickerMobile
                :time-range="timeRange"
                @selectTime="updateTimeRange"
            />
        </template>
        <Scrollable
            class="coaching-metrics-container"
            :loading="loading"
            :pull-down="onPullDown"
        >
            <InsightsEngagementTiles />
            <InsightsEngagementTrendingList
                v-if="
                    selectedEngagementMetric === 'trending_themes' && !loading
                "
                class="insights-engagement-list"
            />
            <GradientHighlightedCard
                v-else-if="
                    selectedEngagementMetric === 'team_spotlight' && !loading
                "
                class="insights-engagement-list"
            >
                <InsightsEngagementSpotlightList
                    :spotlight-team="insightsEngagement.data.team"
                />
            </GradientHighlightedCard>
        </Scrollable>
    </WebViewScreen>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { Action, Getter } from 'vuex-facing-decorator'
import InsightsTimePickerMobile from '@/mobile/src/components/Insights/InsightsTimePickerMobile.vue'
import Scrollable from '@/components/Scrollable.vue'
import WebViewScreen from '@/components/WebView/WebViewScreen.vue'
import TwoLineHeader from '@/mobile/src/components/TwoLineHeader.vue'
import {
    EngagementInsights,
    EngagementMetricType,
    ITimeRange,
} from '@/entities/insights'
import GradientHighlightedCard from '@/components/GradientHighlightedCard.vue'
import InsightsEngagementTiles from '@/mobile/src/components/Insights/InsightsEngagementTiles.vue'
import InsightsEngagementTrendingList from '@/mobile/src/components/Insights/InsightsEngagementTrendingList.vue'
import InsightsEngagementSpotlightList from '@/mobile/src/components/Insights/InsightsEngagementSpotlightList.vue'

@Component({
    components: {
        GradientHighlightedCard,
        InsightsEngagementSpotlightList,
        InsightsEngagementTrendingList,
        InsightsEngagementTiles,
        InsightsTimePickerMobile,
        Scrollable,
        WebViewScreen,
        TwoLineHeader,
    },
})
export default class EngagementMetrics extends Vue {
    @Getter
    readonly friendlyTimeRange!: string

    @Getter
    readonly timeRange!: ITimeRange

    @Getter
    readonly selectedEngagementMetric!: EngagementMetricType

    @Getter
    readonly insightsEngagement!: EngagementInsights

    @Getter
    readonly currentlyLoading!: string[]

    @Action
    updateTimeRange

    @Action
    loadInsightsEngagement

    public async mounted() {
        if (!this.insightsEngagement && !this.loading) {
            await this.loadInsightsEngagement()
        }
    }

    get loading() {
        return this.currentlyLoading.includes('insightsEngagement')
    }

    async onPullDown() {
        if (!this.loading) {
            await this.loadInsightsEngagement()
        }
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/typography';
@import '~@/styles/variables';
@import '~@/styles/button';

.insights {
    background-color: @lightGrey;
}

.time-range {
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: @grey40;
}

.coaching-metrics-container {
    background: @neutralBg;

    .insights-no-data {
        padding: 12px;
    }
}

.insights-engagement-list {
    margin: 12px;
}
</style>
