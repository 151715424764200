<template>
    <div class="nps-bar-label">
        <div class="nbl-label-container" :style="{ left: middlePercent + '%' }">
            <div class="nbl-label"><slot></slot></div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component({})
export default class NPSBarLabel extends Vue {
    @Prop({ type: Number, required: true }) public min!: number
    @Prop({ type: Number, required: true }) public max!: number
    @Prop({ type: Number, required: true }) public average!: number

    public get range(): number {
        return this.max - this.min
    }

    public get middleRelative() {
        return this.average - this.min
    }

    public get middlePercent(): number {
        const value =
            this.range > 0 ? (this.middleRelative / this.range) * 100 : 50
        // min value limited to 5, because when value is 0, we don't need to center the label
        // instead left align it
        return Math.max(5, Math.min(100, value))
    }
}
</script>

<style lang="less" scoped>
@import '../styles/typography';
@import '../styles/palette';

.nps-bar-label {
    .nbl-label-container {
        position: relative; // Allows positioning of the label over the bar
        float: left; // The element gets to full width otherwise and pushes the right side

        .nbl-label {
            position: relative;
            transform: translateX(
                -50%
            ); // Horizontally center the label over the bar
            display: inline-block; // Wrap width to content

            font-family: @fontFamily;
            font-size: 11px;

            color: @grey90;
            font-weight: 500;
        }
    }
}
</style>
