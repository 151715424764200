<template>
    <component :is="$isMobile ? 'GradientHighlightedCard' : 'BaseCard'">
        <div
            class="insight-engagement-trending-list-card"
            :class="{
                'desktop-insight-engagement-trending-list-card': !$isMobile,
            }"
        >
            <div class="header">
                <div class="title">Themes</div>
                <div id="engagement-trending-info" class="title">
                    Engagement
                    <img src="@/assets/img/Icons/info.svg" alt="Info icon" />
                </div>
            </div>
            <div v-if="!loading">
                <InsightsEngagementThemeItem
                    v-for="(theme, i) in themes"
                    :key="theme.id"
                    :theme-id="theme.id"
                    :title="theme.name"
                    :history="theme.history"
                    :points="theme.change_in_term_data.score"
                    :overall-score="theme.current_term_data.score"
                    :previous-score="theme.previous_term_data.score"
                    :delta-positive="theme.change_in_term_data.score > 0"
                    :class="[
                        i + 1 < themes.length &&
                        theme.id !== desktopEngagementThemeSelected &&
                        currentPosition !== i + 1
                            ? 'bottom-border'
                            : 'no-border',
                    ]"
                    @click="onClickTheme(theme, i)"
                />
            </div>
            <InsightsListLoading v-else />
        </div>
    </component>
</template>

<script lang="ts">
import { Getter } from 'vuex-facing-decorator'
import { Component, Vue } from 'vue-facing-decorator'
import { useRouter } from 'vue-router'
import BaseCard from '@/components/BaseCard.vue'
import { EngagementInsights } from '@/entities/insights'
import GradientHighlightedCard from '@/components/GradientHighlightedCard.vue'
import {
    tilePercentage,
    engagementIcon,
} from '@/mobile/src/components/engagement/utils'
import InsightsEngagementThemeItem from '@/mobile/src/components/Insights/InsightsEngagementThemeItem.vue'
import InsightsListLoading from '@/mobile/src/components/Insights/InsightsListLoading.vue'

@Component({
    components: {
        InsightsListLoading,
        BaseCard,
        InsightsEngagementThemeItem,
        GradientHighlightedCard,
    },
    methods: {
        engagementIcon,
        tilePercentage,
    },
})
export default class InsightsEngagementTrendingList extends Vue {
    @Getter
    readonly insightsEngagement!: EngagementInsights

    @Getter
    readonly currentlyLoading!: string[]

    @Getter
    readonly desktopEngagementThemeSelected!: string
    public currentPosition = 0

    private router = useRouter()

    public get themes() {
        return (
            this.insightsEngagement?.data.themes.sort(
                (a, b) =>
                    Math.abs(b.change_in_term_data.score) -
                    Math.abs(a.change_in_term_data.score)
            ) ?? []
        )
    }

    onClickTheme(theme, position = 0) {
        //assigns the currentPosition of the selected theme
        this.currentPosition = position
        this.router.push({
            name: 'InsightsEngagementTeamThemeDetail',
            params: { themeId: theme.id },
        })
    }

    get loading() {
        return this.currentlyLoading.includes('insightsEngagement')
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/variables.less';
@import '~@/styles/rain/typography.less';
@import '~@/styles/insights.less';

.insight-engagement-trending-list-card {
    padding: @gutterSpacing-md;

    .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 14px;

        .title {
            .title();
            line-height: 16px;
            display: flex;
            align-items: center;
            img {
                margin-left: 4px;
            }
        }
    }

    .bottom-border {
        border-bottom: 1px solid @outline;
    }
}

.desktop-insight-engagement-trending-list-card {
    padding: 12px;
    cursor: pointer;

    .header {
        padding: @gutterSpacing-md;
        justify-content: flex-start;
        .title {
            width: @desktopInsightsEngagementThemesWidth;
        }
    }
    .bottom-border {
        border-bottom: 1px solid @outline;
    }
    .no-border {
        border-bottom: 0px solid @white !important;
    }
}
</style>
