import axios, { AxiosInstance, Method } from 'axios'
import { isProduction, rootDomain } from '@/mobile/src/utils/misc'
import { LooseObject } from '@/pages/appstore/entities/app'
import store from '@/mobile/src/store'

// tap on before sending the request and set domain, accesstoken, csrftoken, phpstorm debug header etc
const requestConfigInterceptor = async (config) => {
    const { domain, accesstoken, csrftoken } = store.getters
    // when request want to use custom subdomain like login page
    if (config.headers['X-tenant']) {
        const subDomain = config.headers['X-tenant']
        delete config.headers['X-tenant'] // delete tmp key

        config.url = `https://${subDomain}.${rootDomain}${config.url}`
    } else if (domain && !/^http/.test(config.url)) {
        // add domain part if not set
        if (config.baseURL) {
            config.url = `/${config.baseURL}/${config.url}`
            while (/\/\//.test(config.url)) {
                config.url = config.url.replace('//', '/')
            }
        }
        config.url = `https://${domain}.${rootDomain}${config.url}`
    }
    if (accesstoken) {
        config.headers['X-accesstoken'] = accesstoken
    }

    if (csrftoken) {
        // set both header and query for token, since some check query, some check header
        config.headers['X-Csrf-Token'] = csrftoken
        if (!config.params) {
            config.params = {}
        }
        config.params._token = csrftoken
    }
    if (!isProduction) {
        if (!config.params) {
            config.params = {}
        }
        config.params.XDEBUG_SESSION_START = 'PHPSTORM'
    }

    return config
}

const responseErrorInterceptor = (error) => {
    //Place custom information here; note that the error object contains the full request as well, even if you don't
    //see that when you log it.  Add a breakpoint to investigate fully.
    error.askNicelyMetadata = {
        hasNetworkConnection: store.getters.hasNetworkConnection,
    }
    return Promise.reject(error)
}

/**
 * We have clients used in two different scenarios:
 * 1. one used by our new mobile app ONLY, which we can unify errors output and all that,
 *    with this, we can provide a unified client
 * 2. one used by shared API modules (shared between web and mobiel), then we need to
 *    keep the api consistent. So we need to keep the primitive jsonClient/ajaxClient/formClient.
 */

// primitive jsonClient/ajaxClient/formClient to be compatible with web app requests
function createClient(type: string): AxiosInstance {
    const client = axios.create()
    switch (type) {
        case 'json':
            client.defaults.headers = {
                'Content-Type': 'application/json',
            }
            break
        case 'ajax':
            client.defaults.headers = {
                'Content-Type': 'application/json',
            }
            client.defaults.baseURL = '/ajax'
            break
        case 'form':
            client.defaults.headers = {
                'Content-Type': 'multipart/form-data',
            }
            break
    }
    client.interceptors.request.use(requestConfigInterceptor, (error) =>
        Promise.reject(error)
    )
    client.interceptors.response.use(
        (response) => response,
        responseErrorInterceptor
    )
    return client
}

const clients = {
    json: createClient('json'),
    ajax: createClient('ajax'),
    form: createClient('form'),
    other: createClient('form'),
}

const jsonClient = clients.json
const ajaxClient = clients.ajax
const formClient = clients.form

export interface ApiResult<T> {
    success: boolean
    data: T
    raw: any
    msgs: string[] // raw msgs
    msg: string // joined msg with \n
}

// an unified function to do requests
export const doRequest = async <T = LooseObject>(
    url: string,
    data: LooseObject = {},
    method: Method = 'get',
    clientType = 'json',
    subDomain = '', // if empty, will use the one from vuex store
    includeCookies = false, // useful when we want to send the cookies (for session etc)
    acceptContentTypes = 'application/json, text/plain, */*'
): Promise<ApiResult<T>> => {
    const client = (clients[clientType] || clients.other) as AxiosInstance
    if (clientType === 'form' && method === 'post') {
        const formData = new FormData()
        Object.keys(data).forEach((key: string) =>
            formData.append(key, data[key])
        )
        data = formData
    }

    const headers = {
        Accept: acceptContentTypes,
    }
    if (subDomain) {
        headers['X-tenant'] = subDomain // consumed by Interceptor
    }
    let params = {}
    if (method === 'get') {
        params = data
        data = {}
    }
    let ret: ApiResult<T>
    try {
        const result = await client.request({
            url,
            method,
            data,
            params,
            headers,
            withCredentials: includeCookies,
        })
        const status = result.status
        const responseData = result.data
        if (
            status < 300 &&
            (typeof responseData.success === 'undefined' ||
                (responseData.success !== 'false' &&
                    responseData.success !== 0 &&
                    responseData.success !== false))
        ) {
            ret = {
                success: true,
                data: responseData || {},
                raw: result,
                msgs: [],
                msg: '',
            }
        } else {
            const msgs = consolidateErrors(responseData)
            ret = {
                success: false,
                data: {} as T,
                raw: result,
                msgs,
                msg: msgs.join('\n'),
            }
        }
    } catch (e: any) {
        let msgs: string[] = []
        if (e && e.response && e.response.data) {
            msgs = consolidateErrors(e.response.data)
        }
        ret = {
            success: false,
            data: {} as T,
            msgs,
            raw: e.response,
            msg: msgs.join('\n'),
        }
    }

    return ret
}

function consolidateErrors(data) {
    let msgs: any[] = []
    if (!data) {
        return msgs
    }
    ['message', 'messages', 'msg', 'msgs', 'errors'].forEach((key) => {
        if (typeof data[key] === 'string') {
            msgs = [...msgs, data[key]]
        } else if (Array.isArray(data[key])) {
            msgs = [...msgs, ...data[key]]
        }
    })

    return msgs
}

export { jsonClient, ajaxClient, formClient }
export default jsonClient
