import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4cbda0cf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "footer-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MessagePillButton = _resolveComponent("MessagePillButton")!
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MessagePillButton, {
      "is-active": true,
      disabled: _ctx.canSendMessage,
      "composer-type": _ctx.composerType,
      label: "Send",
      onClick: _ctx.send
    }, null, 8, ["disabled", "composer-type", "onClick"]),
    _createElementVNode("div", null, [
      (_ctx.canAddImage)
        ? (_openBlock(), _createBlock(_component_FontAwesomeIcon, {
            key: 0,
            icon: _ctx.imageIcon,
            class: "image-icon",
            size: "lg",
            onClick: _ctx.onImageButtonClicked
          }, null, 8, ["icon", "onClick"]))
        : _createCommentVNode("", true)
    ])
  ]))
}