/**
 * 'Toast' is a transient message that pops up at the top of the screen
 * to give feedback about some user interactions (usually crud)
 */

import { ActionTree, MutationTree, GetterTree } from 'vuex'

export interface Toast {
    id: number
    message: string
}

export interface StatusState {
    toasts: Toast[]
}

export const state: StatusState = {
    toasts: [],
}

const getters: GetterTree<StatusState, any> = {
    statusToasts({ toasts }) {
        return toasts
    },
}

const actions: ActionTree<StatusState, any> = {
    statusAddToast({ commit, dispatch }, message: string): number {
        const id = Math.round(new Date().valueOf() * Math.random())
        commit('statusAddToast', { id, message })

        setTimeout(() => {
            // automatically remove it after a few seconds
            dispatch('statusRemoveToast', id)
        }, 3000)

        return id
    },
    statusRemoveToast({ commit }, id: number) {
        commit('statusRemoveToast', id)
    },
    statusClearToasts({ commit }) {
        commit('statusClearToasts')
    },
}

const mutations: MutationTree<StatusState> = {
    statusAddToast(state, toast: Toast) {
        state.toasts.push(toast)
    },
    statusRemoveToast(state, id: number) {
        state.toasts = state.toasts.filter((t) => t.id !== id)
    },
    statusClearToasts(state) {
        state.toasts = []
    },
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations,
}
