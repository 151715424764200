import client from '@/api/client'
import { ScorecardEntity } from '@/entities/scorecard'
import { ToggleStarred } from '@/entities/topicfeedback'
import { OfflineCache } from '@/mobile/src/utils/offlinecache'
import { IScorecardTopic } from '@/pages/appstore/components/Scorecard/scorecard-settings-entity'
import { IFilterRule } from '@/entities'

const offlineCache = new OfflineCache('scorecard')

export async function getScorecard(userId?: string) {
    return offlineCache.cachedGet<ScorecardEntity>(
        client,
        `/scorecard` + (userId ? `/user/${userId}` : ``)
    )
}

export async function getScorecardTopics() {
    return await client.get<IScorecardTopic>(`/scorecard-settings/topics`)
}

export async function getMyTeam() {
    return offlineCache.cachedGet(client, `/scorecard/my-team`)
}

export async function getBadgeTotalCount(
    badge: string,
    type: string,
    userId?: string
) {
    badge = encodeURIComponent(badge)
    const t = type === 'positive' ? 1 : -1
    return offlineCache.cachedGet(
        client,
        `/scorecard/badge/` +
            (userId ? `user/${userId}/` : ``) +
            `${t}/${badge}/total`
    )
}

export async function seeBadge(badge: string, type: string) {
    badge = encodeURIComponent(badge)
    const t = type === 'positive' ? 1 : -1
    return client.post(`/scorecard/badge/${t}/${badge}/see`, {})
}

export async function loadScorecardStats(weekNumberBefore: number) {
    return client.get(`/scorecard/stats/${weekNumberBefore}`)
}

export async function loadScorecardResponseRates(months: number) {
    return client.get(`/scorecard/response-rates/${months}`)
}

export async function deleteScorecard(id: number) {
    const response = await client.post(`/scorecard-settings/delete/${id}`)
    const success = response.status === 200
    if (!success) {
        throw new Error('Issue deleting scorecard :' + id)
    }
}

export async function getTopicFeedback(
    month: number | null,
    metric: string,
    filters: IFilterRule[],
    sortFavouritesFirst = false
) {
    const filterPayload = {
        question_type: metric,
        filter_rules: filters,
    }
    if (month) {
        filterPayload['month'] = month
    }
    // expiry parameter is not used in this function, so we are passing -1
    return offlineCache.cachedGet<{ data: any; message?: string }>(
        client,
        `/topicfeedback`,
        -1,
        {
            params: {
                filters: filterPayload,
                sortFavouritesFirst: sortFavouritesFirst,
            },
        }
    )
}

export async function toggleResponseStarred(
    responseId: number
): Promise<ToggleStarred | void> {
    const response = await client.post(
        `/reportcard/favourites/toggle/${responseId}`
    )
    const success = response.status === 200
    if (success) {
        const status = response.data?.status === 'created'
        const data: ToggleStarred = { questionId: Number(responseId) }
        return data
    }

    throw new Error('Issue toggling favourite for :' + responseId)
}
