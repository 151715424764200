<template>
    <div>
        <div class="vue-simple-spinner" :style="spinnerStyle"></div>
        <div
            v-if="message.length > 0"
            class="vue-simple-spinner-text"
            :style="text_style"
        >
            {{ message }}
        </div>
    </div>
</template>

<script lang="ts" setup>
// Replacement for 'vue-simple-spinner' NPM package component
// https://github.com/dzwillia/vue-simple-spinner/blob/master/src/components/Spinner.vue

import { computed, ComputedRef } from 'vue'

interface Props {
    // either a number (pixel width/height) or 'tiny', 'small',
    // 'medium', 'large', 'huge', 'massive' for common sizes
    size?: string | number
    lineSize?: number
    lineBgColor?: string
    lineFgColor?: string
    speed?: number
    spacing?: number
    message?: string
    fontSize?: number
    textFgColor?: string
}
const props = withDefaults(defineProps<Props>(), {
    size: 32,
    lineSize: 3,
    lineBgColor: '#eee',
    lineFgColor: '#2196f3',
    speed: 0.8,
    spacing: 4,
    message: '',
    fontSize: 13,
    textFgColor: '#555',
})

const isNumber = function (n) {
    return !isNaN(parseFloat(n)) && isFinite(n)
}

const sizePx: ComputedRef<number> = computed(() => {
    switch (props.size) {
        case 'tiny':
            return 12
        case 'small':
            return 16
        case 'medium':
            return 32
        case 'large':
            return 48
        case 'big':
            return 64
        case 'huge':
            return 96
        case 'massive':
            return 128
    }

    return isNumber(props.size) ? Number(props.size) : 32
})

const lineSizePx: ComputedRef<number> = computed(() => {
    switch (props.size) {
        case 'tiny':
            return 1
        case 'small':
            return 2
        case 'medium':
            return 3
        case 'large':
            return 3
        case 'big':
            return 4
        case 'huge':
            return 4
        case 'massive':
            return 5
    }

    return isNumber(props.lineSize) ? props.lineSize : 4
})

const textMarginTop: ComputedRef<number> = computed(() => {
    switch (props.size) {
        case 'tiny':
        case 'small':
        case 'medium':
        case 'large':
        case 'big':
        case 'huge':
        case 'massive':
            return Math.min(Math.max(Math.ceil(sizePx.value / 8), 3), 12)
    }

    return isNumber(props.spacing) ? props.spacing : 4
})

const textFontSize = computed(() => {
    switch (props.size) {
        case 'tiny':
        case 'small':
        case 'medium':
        case 'large':
        case 'big':
        case 'huge':
        case 'massive':
            return Math.min(Math.max(Math.ceil(sizePx.value * 0.4), 11), 32)
    }

    return isNumber(props.fontSize) ? props.fontSize : 13
})

const spinnerStyle = computed(() => {
    return {
        margin: '0 auto',
        'border-radius': '100%',
        border: lineSizePx.value + 'px solid ' + props.lineBgColor,
        'border-top': lineSizePx.value + 'px solid ' + props.lineFgColor,
        width: sizePx.value + 'px',
        height: sizePx.value + 'px',
        animation:
            'vue-simple-spinner-spin ' + props.speed + 's linear infinite',
    }
})
const text_style = computed(() => {
    return {
        'margin-top': textMarginTop.value + 'px',
        color: props.textFgColor,
        'font-size': textFontSize.value + 'px',
        'text-align': 'center',
    }
})
</script>

<style>
.vue-simple-spinner {
    transition: all 0.3s linear;
}

@keyframes vue-simple-spinner-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
