<template>
    <RouterView v-slot="{ Component }">
        <PushPopTransitionContainer v-if="storeRestored">
            <component :is="Component" />
        </PushPopTransitionContainer>

        <!-- when store not restored, show a spinner -->
        <LoadingWithGradientBg v-else />
    </RouterView>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { RouterView } from 'vue-router'
import store from '@/mobile/src/store'
import { SplashScreen } from '@capacitor/splash-screen'
import { initializeApp } from '@/mobile/src/utils/capacitor'
import '@teamhive/capacitor-single-signon'
import LoadingWithGradientBg from '@/mobile/src/components/LoadingWithGradientBg.vue'
import PushPopTransitionContainer from '@/mobile/src/components/containers/PushPopTransitionContainer.vue'

const storeRestored = ref(false) // whether data is restored when app starts, should not show anything before data restored

onMounted(() => {
    configure()
})

const configure = async () => {
    await initializeApp()
    // wait store to be restored
    await (store as any).restored
    storeRestored.value = true
    // hide splash screen
    await SplashScreen.hide()
}
</script>
