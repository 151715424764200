<template>
    <div ref="container" class="suggestion-agreement">
        <div
            v-if="usersAgreed.length === 0 && !action"
            class="user-first-agree"
        >
            Be the first one to agree!
        </div>
        <div v-else class="user-bubbles">
            <div
                v-for="(user, index) in firstAgreed"
                :key="user.id"
                class="user-bubble"
                :style="{
                    background: colors[suggestion.user_id % colors.length],
                    left: index * 15 + 'px',
                }"
                @click="showAgreedUsers = true"
            >
                <AvatarOrInitials :user="user" :size="21" />
            </div>
            <div
                v-if="othersAgreed > 0"
                class="user-bubble"
                style="left: 45px"
                @click="showAgreedUsers = false"
            >
                +{{ othersAgreed }}
            </div>
        </div>
        <ActionLabel v-if="action" :action="action" />
        <Button v-else-if="hasAgreed" variant="primary" @click="removeAgreed">Agreed</Button>
        <Button v-else variant="secondary" @click="agree">Agree</Button>

        <SuggestionAgreedUsersModal
            v-show="showAgreedUsers"
            :users-agreed="usersAgreed"
            @close="showAgreedUsers = false"
        />
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { Action, Getter } from 'vuex-facing-decorator'
import { SuggestionAction, SuggestionView } from '@/entities/suggestions'
import Button from '@/components/WebView/Button.vue'
import AvatarOrInitials from '@/components/WebView/AvatarOrInitials.vue'
import ActionLabel from './ActionLabel.vue'
import SuggestionAgreedUsersModal from './SuggestionAgreedUsersModal.vue'
import { UserColors } from '@/utils/userColors'
import useEmitter from '@/composables/useEmitter'

@Component({
    components: {
        Button,
        ActionLabel,
        AvatarOrInitials,
        SuggestionAgreedUsersModal,
    },
})
export default class SuggestionAgreement extends Vue {
    @Prop({ type: Object }) public suggestion!: SuggestionView
    @Prop({ type: String, default: '' }) public action!: SuggestionAction

    @Getter private getUserId
    @Action public createSuggestionVote!: (suggestionId: number) => void
    @Action public removeSuggestionVote!: (suggestionId: number) => void

    public emitter = useEmitter()

    public showAgreedUsers = false
    public readonly displayUsersMax = 3

    public get colors() {
        return UserColors
    }

    public get usersAgreed() {
        return this.suggestion.users_agreed
    }

    public get hasAgreed(): boolean {
        return !!this.usersAgreed.find(
            (u) => u.id.toString() === this.getUserId.toString()
        )
    }

    public get firstAgreed() {
        return this.usersAgreed.slice(0, this.displayUsersMax)
    }

    public get othersAgreed(): number {
        return this.usersAgreed.length - this.displayUsersMax
    }

    public async agree() {
        if (this.hasAgreed) {
            return // agreed already
        }

        await this.createSuggestionVote(this.suggestion.id)
    }

    public async removeAgreed() {
        if (!this.hasAgreed) {
            return // disagreed already
        }

        await this.removeSuggestionVote(this.suggestion.id)
    }
}
</script>

<style scoped lang="less">
.suggestion-agreement {
    padding: 10px 0 5px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.user-first-agree {
    color: #6484ff;
    font-size: 12px;
    line-height: 20px;
}

.user-bubbles {
    position: relative;
    height: 21px;
}

.user-bubble {
    box-sizing: border-box;
    border: 1px solid #fff;
    border-radius: 11px;
    overflow: hidden;
    height: 21px;
    width: 21px;
    position: absolute;
    top: 0;
    background: #8897b6;
    color: #fff;
    font-size: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
</style>
