import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SuggestionAgreement = _resolveComponent("SuggestionAgreement")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    suggestion: _ctx.suggestion,
    user: _ctx.suggestion.user,
    title: _ctx.title || _ctx.suggestion.title,
    subtitle: _ctx.suggestion.user.name,
    body: _ctx.body,
    created: _ctx.suggestion.created,
    "link-mode": _ctx.linkMode(_ctx.suggestion),
    alert: !_ctx.suggestion.seen,
    "show-delete": _ctx.inDashboard || _ctx.inModal
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_SuggestionAgreement, {
        suggestion: _ctx.suggestion,
        action: _ctx.suggestion.action
      }, null, 8, ["suggestion", "action"])
    ]),
    _: 1
  }, 8, ["suggestion", "user", "title", "subtitle", "body", "created", "link-mode", "alert", "show-delete"]))
}