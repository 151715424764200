<template>
    <img v-if="positive" src="@/assets/img/Icons/leaderboard-up.svg" alt="" />
    <img v-else src="@/assets/img/Icons/leaderboard-down.svg" alt="" />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component({})
export default class UpDownArrow extends Vue {
    @Prop({ type: Boolean, default: true })
    readonly positive!: boolean
}
</script>
