// TODO - map colours to variables when doing context awareness ticket

export interface MessageType {
    type: 'message' | 'shoutout' | 'coaching' | 'emailReply' | 'internalNote'
    label: string // Type label e.g. "Internal Note'
    colour: string
    description: string
}

export enum ModalSource {
    FeedbackDetailScreen = 'FeedbackDetailScreen',
    MomentsDetailScreen = 'MomentsDetailScreen',
    StaffProfileScreen = 'StaffProfileScreen',
    MomentsScreen = 'MomentsScreen',
}

export enum MessageTypeLabel {
    message = 'Message',
    shoutout = 'Shoutout',
    coaching = 'Coaching',
    emailReply = 'Customer Reply',
    internalNote = 'Internal Note',
    workflow = 'Workflow',
}

export enum MessageTypeValues {
    message = 'message',
    shoutout = 'shoutout',
    coaching = 'coaching',
    emailReply = 'emailReply',
    internalNote = 'internalNote',
}

export const messageTypes: MessageType[] = [
    {
        type: MessageTypeValues.message,
        label: MessageTypeLabel.message,
        colour: '#DEE6FF',
        description: 'Send a general message to other members of the company',
    },
    {
        type: MessageTypeValues.shoutout,
        label: MessageTypeLabel.shoutout,
        colour: '#00D68F',
        description: 'Recognise a frontline team member’s outstanding work',
    },
    {
        type: MessageTypeValues.coaching,
        label: MessageTypeLabel.coaching,
        colour: '#FFAA00',
        description: 'Help frontline staff to achieve higher standards',
    },
    {
        type: MessageTypeValues.emailReply,
        label: MessageTypeLabel.emailReply,
        colour: '#A1DADB',
        description: 'Reply to a customer with an email or SMS',
    },
    {
        type: MessageTypeValues.internalNote,
        label: MessageTypeLabel.internalNote,
        colour: '#FBE083',
        description: 'Only visible internally to other members of the company',
    },
]
