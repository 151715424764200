import {
    IComposer,
    IMessageRecipient,
    INoticeType,
    NoticeRespondAction,
} from '@/entities/notices'
import { palette } from '@/utils/palette'
import UrgentNoticeIcon from '@/assets/img/notices/urgent-notice.svg'
import LeaderNoticeIcon from '@/assets/img/notices/leader-notice.svg'
import GeneralNoticeIcon from '@/assets/img/notices/general-notice.svg'
import ShoutoutIcon from '@/assets/img/notices/shoutout.svg'
import CoachingIcon from '@/assets/img/notices/coaching.svg'
import { MessageTypeLabel, ModalSource } from '@/mobile/src/types/messageTypes'

declare const UIkit

export const PredefinedNoticeTypes: INoticeType[] = [
    {
        name: 'urgent notice',
        description:
            'Quickly communicate urgent information that impacts customers and staff. This will send emails and mobile notifications.',
        emergencyLevel: 5,
        eg: 'Eg: Global Pandemic Covid-19, Measles Outbreak,...',
        color: palette.negative,
        backgroundColor: palette.red10,
        confetti: 0,
        allowedRespondActions: NoticeRespondAction.All,
        icon: UrgentNoticeIcon,
        isComposing: false,
    },
    {
        name: 'leader notice',
        description:
            'Keep your customer-facing staff updated with relevant information from the leaders in your business. This will send emails and mobile notifications.',
        emergencyLevel: 4,
        eg: 'Eg: CEO Update, News from the Boardroom',
        color: palette.purpleDark,
        backgroundColor: palette.purpleLight,
        confetti: 0,
        allowedRespondActions: NoticeRespondAction.All,
        icon: LeaderNoticeIcon,
        isComposing: false,
    },
    {
        name: 'general notice',
        description:
            'Send a Notice to your customer-facing staff to keep them across key information.',
        emergencyLevel: 3,
        eg: 'Eg: Reminders, General Business...',
        color: palette.blue,
        backgroundColor: palette.blueLight1,
        confetti: 0,
        allowedRespondActions: NoticeRespondAction.All,
        icon: GeneralNoticeIcon,
        isComposing: true,
    },
    {
        name: 'shoutout',
        description:
            'Motivate your teams by sending them a Shoutout and celebrating their great work.',
        emergencyLevel: 0,
        eg: 'Eg: XYZ Branch received awesome feedback all week... ',
        color: palette.greenDark1,
        backgroundColor: palette.greenLightest,
        confetti: 1,
        allowedRespondActions:
            NoticeRespondAction.Up |
            NoticeRespondAction.RaiseHands |
            NoticeRespondAction.Celebrate,
        icon: ShoutoutIcon,
        isComposing: true,
    },
    {
        name: 'coaching',
        description:
            'Nudge your team closer to CX mastery with short, snackable learning content when they need it most. Eg: When discussing XZY with a client, make sure you…',
        emergencyLevel: 0,
        eg: 'Eg: When discussing XYZ with a client... ',
        color: palette.gold,
        backgroundColor: palette.yellowLight,
        confetti: 0,
        allowedRespondActions: NoticeRespondAction.All,
        icon: CoachingIcon,
        isComposing: true,
    },
    {
        name: 'report',
        description: '',
        emergencyLevel: 0,
        eg: '',
        color: palette.white,
        backgroundColor: palette.purple,
        confetti: 0,
        allowedRespondActions: NoticeRespondAction.All,
        icon: CoachingIcon,
        isComposing: false,
    },
]

export function getRecipientName(recipient) {
    if (recipient.type !== 'group') {
        return recipient.name
    }
    // handle group
    if (recipient.field === 'everyone') {
        return 'Everyone'
    }
    let groupName = recipient.value
    if (recipient.role) {
        groupName += `(${recipient.role})`
    }
    return groupName
}

export function getJoinedRecipientNames(recipients: IMessageRecipient[]) {
    return recipients.map(getRecipientName).join(', ')
}

interface NotifierConfig {
    timeout: number
    pos: string
}

const notifierDefaults = {
    pos: 'top-right',
    timeout: 5000,
}

export function notifySuccess(
    message: string,
    config: NotifierConfig = notifierDefaults
) {
    UIkit.notify(message, {
        status: 'success',
        pos: config.pos,
        timeout: config.timeout,
    })
}

export function notifyError(
    message: string,
    config: NotifierConfig = notifierDefaults
) {
    UIkit.notify(message, {
        status: 'error',
        pos: config.pos,
        timeout: config.timeout,
    })
}

export function noticeTypeTitle(type: String) {
    if (!type) {
        return 'Notice'
    }

    if (type.toLowerCase().includes('notice')) {
        return type
    }
    return `${type} Notice`
}

export function getResponseShoutoutRecipients(recipients) {
    return [recipients.find((recipient) => recipient.field === 'everyone')]
}

export function convertNoticeComposerToMomentRequest(composer: IComposer) {
    const requestType = composer.type
    composer.source = ModalSource.MomentsScreen
    switch (requestType) {
        case 'general notice':
            composer.type = MessageTypeLabel.message
            break
        case 'shoutout':
            composer.type = MessageTypeLabel.shoutout
            break
        case 'coaching':
            composer.type = MessageTypeLabel.coaching
            break
    }
    return composer
}

export function extractNoteMentions(existingUsers: any[], text) {
    const mentions: any[] = []
    for (const user of existingUsers) {
        if (user.name === null) {
            if (user.email === null) {
                continue
            }
            user.name = user.email
        }
        if (user.name.trim() !== '') {
            // Check if the user is mentioned in note, mention would be in format of [@<name>](user:<email>)
            if (
                text.includes(
                    `[@${user.name.trim()}](user:${user.email.trim()})`
                )
            ) {
                mentions.push({
                    description: user.email,
                    field: '',
                    name: user.name.trim(),
                    role: '',
                    sender: null,
                    type: 'user',
                    user_id: user.id,
                    value: user.name.trim(),
                })
            }
        }
    }
    return mentions
}
