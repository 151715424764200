<template>
    <WebViewScreen
        title="Suggestions"
        class="list-container"
        disable-when-offline
    >
        <template #left-button>
            <div v-if="embedded" @click="openDrawer">
                <img
                    src="@/components/WebView/icons/AskNicelyHome.svg"
                    alt="AskNicely"
                />
            </div>
            <div v-else @click="router.back()">
                <img
                    src="@/components/WebView/icons/BackLeftAction.svg"
                    alt="back"
                />
            </div>
        </template>

        <div>
            <SuggestionsSubNav :in-dashboard="false" />
            <div class="scroll-container">
                <SuggestionsList
                    :in-dashboard="false"
                    :suggestion-clip-length="120"
                />
            </div>
        </div>
        <template #right-button>
            <div v-if="embedded && !offline">
                <RouterLink :to="{ name: 'suggestionsCreate' }">
                    <img
                        src="@/components/WebView/icons/AddNew.svg"
                        alt="Create a new suggestion"
                    />
                </RouterLink>
            </div>
        </template>
        <ScreenBottomButton
            v-if="!embedded && !offline"
            :icon="bulbIcon"
            title="Make Suggestion"
            @click="router.push('/suggestions/suggestion/create')"
        />
    </WebViewScreen>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { useRouter } from 'vue-router'
import WebViewScreen from '@/components/WebView/WebViewScreen.vue'
import SuggestionsList from './SuggestionsList.vue'
import SuggestionsSubNav from './SuggestionsSubNav.vue'
import { embedded, postMessageToRNWebView } from '@/utils/react-native-webview'
import { Action } from 'vuex-facing-decorator'
import ScreenBottomButton from '@/components/Notices/ScreenBottomButton.vue'
import BulbIcon from '@/assets/img/Icons/White/bulb.svg'

@Component({
    components: {
        ScreenBottomButton,
        WebViewScreen,
        SuggestionsList,
        SuggestionsSubNav,
    },
})
export default class SuggestionsListScreen extends Vue {
    @Prop({ type: Boolean }) private offline!: boolean
    @Action public loadSuggestionMentionUsers

    private router = useRouter()

    public bulbIcon = BulbIcon
    private get embedded() {
        // a key set by the RN app on Window to tell us if we are embedded in RN webview
        return embedded
    }

    public mounted() {
        if (!this.embedded) {
            // do initial loading for capacitor app here
            this.loadSuggestionMentionUsers()
        }
    }

    public openDrawer() {
        if (embedded && postMessageToRNWebView) {
            postMessageToRNWebView({
                action: 'openDrawer',
                // opens the main drawer menu in the native app.
                // see /utils/navigation.jd doAction in asknicely_mobile for the handler
            })
        }
    }
}
</script>

<style lang="less" scoped>
@import '../../styles/palette';

.list-container {
    background: @greyLight;
}

h4 {
    margin: 0;
}

.scroll-container {
    flex: 1;
    overflow-y: auto;
}
</style>
