import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1d82fc94"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "circle-container" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = {
  key: 1,
  class: "mention-dot"
}
const _hoisted_4 = { class: "mention-count-total-container" }
const _hoisted_5 = { class: "mention-count-total" }
const _hoisted_6 = { class: "badge-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.badgeId && _ctx.badgeSchema !== undefined)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["scorecard-badge", {
            empty: _ctx.badgeType !== 'achievement' && _ctx.count === '0',
            'no-space': _ctx.noSpace,
        }])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("img", {
            src: _ctx.badgeImage,
            width: "75",
            alt: _ctx.label,
            class: "badge-image"
          }, null, 8, _hoisted_2),
          (_ctx.showCount && _ctx.countUnseen !== '0')
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "mention-count-new",
                style: _normalizeStyle(_ctx.countStyle)
              }, " +" + _toDisplayString(_ctx.countUnseen), 5))
            : (!_ctx.showCount && _ctx.countUnseen !== '0')
              ? (_openBlock(), _createElementBlock("div", _hoisted_3))
              : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.count), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.label || _ctx.badgeSchema.label), 1)
      ], 2))
    : _createCommentVNode("", true)
}