import { CoachingInsightItem } from '@/entities/insights'
import { useRouter } from 'vue-router'
import { Component, Prop, Vue } from 'vue-facing-decorator'
import InsightsMetric from '@/mobile/src/components/Insights/InsightsMetric.vue'
import BaseCard from '@/components/BaseCard.vue'
import UpDownArrow from '@/components/UpDownArrow.vue'
import AvatarOrInitials from '@/components/WebView/AvatarOrInitials.vue'
import TopicBadge from '@/mobile/src/components/TopicBadge.vue'

@Component({
    components: {
        TopicBadge,
        AvatarOrInitials,
        UpDownArrow,
        BaseCard,
        InsightsMetric,
    },
})
export default class TeamMemberTile extends Vue {
    public router = useRouter()

    @Prop({ type: Object, default: null })
    protected data!: CoachingInsightItem

    @Prop({ type: String, default: null })
    private topic!: string

    @Prop({ type: String, default: null })
    private name!: string

    @Prop({ type: String, default: null })
    private badge!: string

    public onCardClick() {
        this.router
            .push({
                name: 'scorecardHomeUser',
                params: { teamMemberUserId: this.data.user.id.toString() },
            })
            .catch((e) => e)
    }
}
