import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54b96382"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "scorecard-nps"
}
const _hoisted_2 = { class: "nps" }
const _hoisted_3 = { class: "change-indicator" }
const _hoisted_4 = { class: "number" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UpDownArrow = _resolveComponent("UpDownArrow")!

  return (_ctx.scorecard)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.scorecard.nps), 1),
        _createElementVNode("span", _hoisted_3, [
          _createVNode(_component_UpDownArrow, {
            positive: _ctx.scorecard.change >= 0
          }, null, 8, ["positive"])
        ]),
        _createElementVNode("span", _hoisted_4, _toDisplayString(Number(_ctx.scorecard.change).toFixed(1)), 1)
      ]))
    : _createCommentVNode("", true)
}