import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!

  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(['moment-type', _ctx.typeClass])
  }, [
    (!_ctx.seen)
      ? (_openBlock(), _createBlock(_component_FontAwesomeIcon, {
          key: 0,
          icon: _ctx.circleIcon,
          class: "moment-highlight-dot"
        }, null, 8, ["icon"]))
      : _createCommentVNode("", true),
    _createTextVNode(" " + _toDisplayString(_ctx.title), 1)
  ], 2))
}