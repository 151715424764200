import { UserDetail } from '@/entities/user'

export interface NoticesEntity {
    received: IMessage[]
    sent: IMessage[]
    composer: IComposer
    recipients: IRecipient[] // all recipients
}

export interface IMessage {
    id: number
    message: string
    type: string
    created: number
    scheduled_at: number
    friendly_scheduled_at?: string
    user_id: number
    recipients: IMessageRecipient[] // who this message is sent to
    seen: number // for received msg
    noticesrecipient_id: number // for recived msg recipient id
    sender: UserDetail
    actions: {
        up: number
        raise_hands: number
        celebrate: number
        feedback: number
    }
    virtual_from?: number
    recipient_count?: number
    feedback?: []
}

export interface IMessageRecipient {
    user_id: number
    seen: number
    sent: number
    type: 'group' | 'user'
    field: string
    value: string
    role: string
    name: string // the name of user who this msg is sent to if type is user
}

export interface IComposer {
    recipients: IRecipient[]
    message: string
    mentions: IRecipient[]
    scheduled_at: string
    type: string
    question: {
        id: number
        comment: string
    } | null // optional question the notice is related to
    source?: string
    isPinned?: boolean
}

export interface IRecipient {
    type: 'user' | 'group' | 'virtual'
    name: string // name to display
    description: string
    user_id: number // id of user or 0 for group
    field: string // field for group type or empty for user type
    value: string // value for group type or empty for user type
    role: string // role for group+role or empty for user type
    sender?: boolean // if the user is a playbook sender only, not a recipient
    avatar: string
    usernames: string[] // value for group type, usernames under the group
    selectableForSending?: boolean // value if this user can be selected as a sender
}

export interface INoticeType {
    name: string
    emergencyLevel: number
    description: string
    eg: string
    color: string
    backgroundColor: string
    confetti: number
    allowedRespondActions: NoticeRespondAction
    icon: string
    isComposing: boolean
}

// Notice message format to display in the frontline report
export interface IReportMessage {
    id: number
    message: string
    playbook: string
    type: string
    created: string
    sender: string // Only requires sender name
    user_id: number
    feedback: number
    recipient_count: number
    actions: number
}

export enum NoticeRespondAction {
    Down = 1 << 0,
    Think = 1 << 1,
    Up = 1 << 2,
    RaiseHands = 1 << 3,
    Celebrate = 1 << 4,
    Feedback = 1 << 5,
    Heart = 1 << 6,
    Clap = 1 << 7,
    All = Down | Think | Up | RaiseHands | Celebrate | Feedback | Heart | Clap,
}

// Emojis that can be used for notice reactions
export const AVAILABLE_NOTICE_REACTION_TYPES: { [key: string]: string } = {
    heart: '💜',
    clap: '👏',
    up: '👍',
    raise_hands: '🙌',
    celebrate: '🎉',
}

// Contains historically used reactions
export const ALL_NOTICE_REACTION_TYPES: { [key: string]: string } = {
    ...AVAILABLE_NOTICE_REACTION_TYPES,
    down: '🤷‍♀',
    think: '🤔',
}

export const unknownUser: IRecipient = {
    type: 'user',
    name: 'Unknown User',
    description: '',
    user_id: 0,
    field: '',
    value: '',
    role: '',
    avatar: '',
    usernames: [],
}

export interface NoticeType {
    label: string
    value: string
    disabled: boolean
}

export interface NoticeDate {
    label: string
    value: number
    disabled: boolean
}

export interface FrontlineNoticeRow {
    id: number // userId
    actions: number
    created: string
    feedback: number
    message: string
    recipient_count: number
    sender: string
    type: string
}
