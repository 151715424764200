<template>
    <div
        ref="msgs"
        class="chat-msgs"
        :class="`${noPad ? 'no-pad' : ''}`"
        :style="{ 'padding-bottom': bottomPadding + 'px' }"
    >
        <ChatMessage
            v-for="message in messages"
            v-bind="$attrs"
            :key="generateMessageKey(message)"
            class="chat-message"
            :question-text="messages[0].comment_raw"
            :on-change-reply-review="onChangeReplyReview"
            :chat-status="chatStatus"
            :custom-data="customData"
            :can-delete-note="canDeleteNote"
            :is-user-basic="isUserBasic"
            :person="person"
            :conv-questions="convQuestions || {}"
            :message="message"
            :has-network-connection="hasNetworkConnection"
        />
    </div>
</template>

<script lang="ts">
import { nextTick } from 'vue'
import { Component, Prop, Watch, Vue } from 'vue-facing-decorator'
import ChatMessage from '@/components/Chat/ChatMessage.vue'
import { ChatStatus } from '@/entities/chat'
import { ConvUIQuestions } from '@/pages/dashboard/entities/dash'
import { UserDetail } from '@/entities/user'

@Component({
    components: { ChatMessage },
})
export default class ChatMessages extends Vue {
    @Prop({ type: Array, default: [] }) public messages!: ChatMessage[]
    @Prop({ type: Function }) public onChangeReplyReview
    @Prop({ type: Object }) public chatStatus!: ChatStatus
    @Prop({ type: Object }) public customData!: {}
    @Prop({ type: Object }) public person!: UserDetail
    @Prop({ type: Boolean }) public canDeleteNote!: boolean
    @Prop({ type: Boolean }) public isUserBasic!: boolean
    @Prop({ type: Boolean, default: false }) public noPad!: boolean
    @Prop() public convQuestions!: ConvUIQuestions
    @Prop({ type: Boolean })
    hasNetworkConnection!: boolean
    @Prop({ type: Number, default: 0 }) public bottomPadding?: number

    @Watch('messages')
    onMessagesChanged() {
        this.scrollToBottom()
    }

    public generateMessageKey(message) {
        return message.type + (message.id ?? message.notice_id)
    }

    public scrollToBottom() {
        nextTick(() => {
            const chatMessages: HTMLCollectionOf<Element> =
                document.getElementsByClassName('chat-message')
            const lastChatMessage: Element | null = chatMessages.item(
                chatMessages.length - 1
            )
            if (lastChatMessage) {
                lastChatMessage.scrollIntoView()
            }
        })
    }
}
</script>

<style scoped lang="less">
.chat-msgs {
    padding-right: 25px;
    overflow: auto;
    min-height: 320px; // a safe value to make sure emoji action popup won't be overlapped
}
.no-pad {
    padding-right: 0;
}
</style>
