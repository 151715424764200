<template>
    <div class="engagement-five-star-question">
        <div class="top">
            <div class="icon">
                <img :src="icon" />
            </div>
            <div class="question">
                {{ question.question }}
            </div>
            <div class="stars">
                <div v-for="(i, idx) in starCount" :key="idx" class="star">
                    <FontAwesomeIcon
                        :icon="getFontAwesomeIcon(i)"
                        class="font-awesome"
                        :class="{
                            'faces-unselected':
                                question.variation === 'faces' &&
                                question.answer !== i,
                        }"
                        @click="$emit('update', { key: 'answer', value: i })"
                    />
                </div>
            </div>
            <div v-if="!wellbeingQuestionType" class="star-labels">
                <div>Not Really</div>
                <div>Definitely</div>
            </div>
            <div v-if="question.answer !== null" class="comment">
                <FlexiMultilineTextInput
                    :value="question.comment ? question.comment : ''"
                    :blur-on-new-line="true"
                    placeholder="Could you share more…"
                    @input="$emit('update', { key: 'comment', value: $event })"
                />
            </div>
        </div>
        <div class="bottom">
            <BaseButton full :disabled="!question.answer" @click="submitAnswer">
                Done
            </BaseButton>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import {
    EngagementQuestion,
    EngagementQuestionTheme,
} from '@/entities/engagement'
import {
    faStar,
    faTired,
    faFrown,
    faMeh,
    faSmile,
    faGrinBeam,
} from 'fontawesome/free-solid-svg-icons'
import { faStar as farStar } from 'fontawesome/free-regular-svg-icons'
import BaseButton from '@/components/Rain/Buttons/BaseButton.vue'
import { EngagementQuestionIcon } from '@/mobile/src/components/engagement/utils'
import { getFaceIconForValue } from '@/mobile/src/utils/engagement'
import FlexiMultilineTextInput from '@/components/FlexiMultilineTextInput.vue'

const fontAwesomeSources = {
    faStar,
    farStar,
    faTired,
    faFrown,
    faMeh,
    faSmile,
    faGrinBeam,
}

@Component({
    components: {
        FlexiMultilineTextInput,
        BaseButton,
        FontAwesomeIcon,
    },
    emits: ['update', 'done'],
})
export default class EngagementFiveStarQuestion extends Vue {
    @Prop({ type: Object, required: true })
    public question!: EngagementQuestion

    public fontAwesomeSources = fontAwesomeSources
    public starCount = 5

    public submitAnswer() {
        this.$emit('done', this.question)
    }

    public getFontAwesomeIcon(value) {
        if ((this.question.variation ?? 'stars') === 'faces') {
            return this.fontAwesomeSources[getFaceIconForValue(value)]
        }
        return this.fontAwesomeSources[
            (this.question.answer ?? 0) >= value ? 'faStar' : 'farStar'
        ]
    }

    get icon() {
        return EngagementQuestionIcon[this.question.theme]
    }

    get wellbeingQuestionType() {
        return this.question.theme === EngagementQuestionTheme['Wellbeing']
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/variables';
@import '~@/styles/typography';
@import '~@/styles/rain/colour';

.engagement-five-star-question {
    text-align: center;
    flex-direction: column;
    flex: 1;
    display: flex;

    .top {
        flex: 1;
        margin-top: 30px;
    }

    .bottom {
        flex: 0;
        padding: 16px 16px 32px 16px;
    }

    .icon img {
        height: 80px;
        margin-bottom: 20px;
    }

    .question {
        .heading();
        margin: 12px;
    }

    .stars {
        margin-top: 22px;
        font-size: 40px;
        display: flex;
        justify-content: space-evenly;
        color: @brandPurple;

        .star {
            text-align: center;
        }

        .faces-unselected {
            color: @disabledPrimaryGrey;
        }
    }

    .star-labels {
        display: flex;
        justify-content: space-between;
        margin-top: 14px;
        .label();
        color: @grey40;
        font-weight: 400;

        div {
            min-width: 100px;
            padding: 0;
        }
    }

    .comment {
        margin-top: 10px;
        padding: 0 16px;
        height: 20vh;
        max-height: 200px;
    }

    @media (min-height: 668px) {
        .comment {
            margin: 40px 0;
        }
    }
}
</style>
