export function processNoteMentions(text, recipients) {
    const users = new Map<string, string>()
    for (const user of recipients.filter(
        (recipient) => recipient.type !== 'group'
    )) {
        if (user.name === '') {
            if (user.description === '') {
                continue
            }
            user.name = user.description
        }
        if (user.name.trim() !== '') {
            users.set(user.name, user.description)
        }
    }
    users.forEach((email, name) => {
        const replace = `@${name}`
        const re = new RegExp(replace, 'g')
        text = text.replace(re, `[@${name}](user:${email})`)
    })

    return text
}

export function processNoteHashtags(text: string) {
    const re = new RegExp(`#(\\w+)`, 'g')
    text = text.replace(re, (fullHashtag) => {
        const tagWithoutHash = fullHashtag.replace('#', '')
        return `[${fullHashtag}](tag:${tagWithoutHash})`
    })

    return text
}

export function cleanMomentText(text: string) {
    return text.replace(/<\/?[^>]+(>|$)/g, '')
}
