<template>
    <div v-if="coachingMetricData">
        <div :class="['insight-coaching-list-card', { desktop: $isMobile }]">
            <div v-if="$isMobile" class="header">
                <div class="title">{{ leftHeader }}</div>
                <div class="title">{{ rightHeader }}</div>
            </div>
            <div v-else class="header desktop">
                <div class="title left">{{ leftHeader }}</div>
                <div class="title middle">{{ middleHeader }}</div>
                <div class="title right">{{ rightHeader }}</div>
                <div
                    v-if="coachingMetricType === 'trending_topic'"
                    class="title chevron"
                ></div>
            </div>
            <div>
                <div
                    v-for="(
                        coachingMetricDataItem, title, i
                    ) in sortedMetricData"
                    :key="title"
                >
                    <InsightsCoachingListItem
                        v-if="$isMobile"
                        :key="'mobile' + title"
                        :title="title"
                        :data="coachingMetricDataItem"
                        :coaching-metric-type="coachingMetricType"
                        :total-count="totalCount"
                        :question-type="questionType"
                        :class="[
                            i + 1 < Object.keys(coachingMetricData).length
                                ? 'bottom-border'
                                : 'no-border',
                        ]"
                    />
                    <InsightsCoachingListItemWeb
                        v-else
                        :key="title"
                        :title="title"
                        :data="coachingMetricDataItem"
                        :coaching-metric-type="coachingMetricType"
                        :total-count="totalCount"
                        :question-type="questionType"
                        :active-detail="activeDetail"
                        :class="[
                            i + 1 < Object.keys(coachingMetricData).length
                                ? 'bottom-border'
                                : 'no-border',
                        ]"
                        @change-detail="onSwitchActiveDetail"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { CoachingInsights, CoachingMetricType } from '@/entities/insights'
import { Component, Prop, Vue } from 'vue-facing-decorator'
import InsightsMetric from '@/mobile/src/components/Insights/InsightsMetric.vue'
import InsightsCoachingListItem from '@/mobile/src/components/Insights/InsightsCoachingListItem.vue'
import { QuestionType } from '@/entities'
import InsightsCoachingListItemWeb from '@/mobile/src/components/Insights/InsightsCoachingListItemWeb.vue'
import { Getter } from 'vuex-facing-decorator'

@Component({
    components: {
        InsightsCoachingListItemWeb,
        InsightsCoachingListItem,
        InsightsMetric,
    },
})
export default class InsightsCoachingList extends Vue {
    @Prop({ type: String, default: null })
    private coachingMetricType!: CoachingMetricType

    @Prop({ type: String, default: '' })
    private questionType!: QuestionType

    @Getter
    readonly insightsCoaching!: CoachingInsights

    private activeDetail = ''

    get leftHeader() {
        if (this.coachingMetricType === 'trending_topic') {
            return 'Scorecard Topics'
        }

        return 'Team Members'
    }

    get middleHeader() {
        if (this.coachingMetricType === 'trending_topic') {
            return 'Responses'
        }

        return 'Feedback'
    }

    get rightHeader() {
        if (this.coachingMetricType === 'top_performer') {
            return this.questionType
        }

        return this.questionType + ' Change'
    }

    get coachingMetricData() {
        // split the scorecard and frontline data then return the relevant one based on metric type
        const insightsCoaching = this.insightsCoaching?.data ?? {}

        let frontlineData = {}
        let scorecardData = {}
        Object.keys(insightsCoaching).forEach(function (key) {
            if (key === 'scorecard') {
                scorecardData = insightsCoaching[key]
            } else {
                frontlineData = { ...frontlineData, ...insightsCoaching[key] }
            }
        })

        if (this.coachingMetricType === 'trending_topic') {
            return scorecardData
        } else {
            return frontlineData
        }
    }

    get sortedMetricData() {
        const coachingMetricData = this.coachingMetricData
        const coachingMetricType = this.coachingMetricType
        const coachingMetricDataSortedKeys = Object.keys(
            coachingMetricData
        ).sort(function (a, b) {
            if (coachingMetricType === 'top_performer') {
                return (
                    coachingMetricData[b]['score_current'] -
                    coachingMetricData[a]['score_current']
                )
            } else {
                return (
                    Math.abs(coachingMetricData[b]['score_change']) -
                    Math.abs(coachingMetricData[a]['score_change'])
                )
            }
        })

        const sortedMetricData = {}
        coachingMetricDataSortedKeys.forEach((field) => {
            sortedMetricData[field] = this.coachingMetricData[field]
        })

        return sortedMetricData
    }

    get totalCount() {
        let count = 0
        for (const coachingMetricDataKey in this.coachingMetricData) {
            count += this.coachingMetricData[coachingMetricDataKey].total_count
        }
        return count
    }

    public onSwitchActiveDetail(detail) {
        this.activeDetail = detail
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/variables';
@import '~@/styles/typography';
@import '~@/styles/variables';
@import '~@/styles/typography';

.insight-coaching-list-card {
    padding: 12px;

    &.desktop {
        padding: 16px 24px;
    }

    .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 14px;

        .title {
            .title();
        }

        &.desktop {
            .title {
                flex: 1;

                &.left {
                    flex: 3;
                    padding-left: 20px;
                }

                &.middle {
                    flex: 2;
                    padding-left: 20px;
                }

                &.right {
                    padding-right: 40px;
                }

                &.chevron {
                    flex: 0.25;
                }
            }
        }
    }

    .coaching-list-item-container {
        padding: 12px 0;
    }

    hr {
        border: none;
        border-top: 1px solid @blueLight;
    }

    .bottom-border {
        border-bottom: 1px solid @blueLight;
    }
    .no-border {
        border-bottom: 0px solid @white !important;
    }
}
</style>
