<template>
    <ChatResponseScreen>
        <RouterView v-slot="{ Component }">
            <transition :name="transitionName">
                <component :is="Component" class="child-view" />
            </transition>
        </RouterView>
    </ChatResponseScreen>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { useRoute } from 'vue-router'
import ChatResponseScreen from '@/pages/chat/responses/ChatResponseScreen.vue'
import { Action, Getter } from 'vuex-facing-decorator'

@Component({
    components: {
        ChatResponseScreen,
    },
})
export default class Chat extends Vue {
    @Action protected reloadChatCustomData!: () => void
    @Action protected loadChat!: () => void
    @Action protected getChatQuestion!: (id: number) => void
    public transitionName = 'slide-left'

    private route = useRoute()

    async mounted() {
        const routeId = Number(this.route.params.id)
        await this.getChatQuestion(routeId)
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/palette';

.chat {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.container {
    background-color: @backgroundColor;
    flex: 1 0 auto;
}

.content {
    height: 100%;
    overflow-y: auto;
}

.next {
    height: 4000px;
}
</style>
