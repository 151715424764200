<template>
    <TransitionGroup name="loading" tag="div">
        <EmptyPageHome v-if="emptyScoreData" key="empty-page" bordered />
        <ErrorPage v-else-if="scoredataError" key="error-page" bordered />

        <FeatureContainer
            v-else-if="!scoredataLoading"
            key="nps-features"
            :class="['nps-features', { 'no-report': !showReportCardButton }]"
        >
            <div v-if="!showReportCardButton" class="expand-graph-icons">
                <FontAwesomeIcon
                    v-if="showScoreGraph"
                    class="chevron"
                    :icon="faChevronUp"
                    @click="expandGraph"
                />
                <FontAwesomeIcon
                    v-else
                    class="chevron"
                    :icon="faChevronDown"
                    @click="expandGraph"
                />
            </div>
            <NPSDisplay
                :score="scoreValue"
                :score-type="scoreType"
                :score-days="scoreDays"
            />
            <Transition name="graph">
                <NPSTargetGraph
                    v-if="showScoreGraph || showReportCardButton"
                    class="home-target-graph"
                    :target="scoreTarget"
                    :score-data="scoreGraph"
                    :score-type="scoreType"
                    :tall="!showReportCardButton"
                    @goal-clicked="onGoalClick"
                />
            </Transition>
            <PillButton
                v-if="showReportCardButton && hasNetworkConnection"
                :disabled="!hasLastMonthsReportCard"
                label="Report Card"
                class="is-v4"
                @click="onReportCardClick()"
            />
        </FeatureContainer>

        <LoadingCard v-else key="graph" variant="graph" />
    </TransitionGroup>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { useRoute } from 'vue-router'
import { Getter, namespace } from 'vuex-facing-decorator'
import FeatureContainer from '@/mobile/src/components/appV4/FeatureContainer.vue'
import NPSDisplay from '@/mobile/src/components/NPSDisplay.vue'
import NPSTargetGraph from '@/mobile/src/components/appV4/NPSTargetGraph.vue'
import PillButton from '@/components/PillButton.vue'
import LoadingCard from '@/mobile/src/components/appV4/LoadingCard.vue'
import EmptyPageHome from '@/mobile/src/components/appV4/EmptyPageHome.vue'
import ErrorPage from '@/mobile/src/components/appV4/ErrorPage.vue'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faChevronUp } from 'fontawesome/free-solid-svg-icons/faChevronUp'
import { faChevronDown } from 'fontawesome/free-solid-svg-icons/faChevronDown'

const ScoredataModule = namespace('scoredata')
const ReportCardModule = namespace('reportcard')

@Component({
    components: {
        EmptyPageHome,
        ErrorPage,
        LoadingCard,
        PillButton,
        NPSTargetGraph,
        NPSDisplay,
        FeatureContainer,
        FontAwesomeIcon,
    },
    emits: ['goal-clicked'],
})
export default class CXScorecard extends Vue {
    @Prop({ type: Function, default: () => 0 })
    public readonly loadReportCard?: () => {}
    @Prop({ type: String, default: '30' }) public readonly scoreDays?: string

    @ScoredataModule.Getter scoredataLoading!: boolean
    @ScoredataModule.Getter scoreGraph
    @ScoredataModule.Getter scoreType
    @ScoredataModule.Getter scoreValue
    @ScoredataModule.Getter scoreTarget
    @ScoredataModule.Getter scoredataError!: boolean
    @ScoredataModule.Getter emptyScoreData!: boolean
    @ScoredataModule.Getter showScoreGraph!: boolean
    @ScoredataModule.Action setShowScoreGraph

    @ReportCardModule.Getter hasLastMonthsReportCard

    @Getter hasNetworkConnection!: boolean

    private route = useRoute()

    private showReportCardButton = true
    private readonly faChevronUp = faChevronUp
    private readonly faChevronDown = faChevronDown
    private teamPageName = 'teams'

    public mounted() {
        // Don't show the ReportCard button if under /teams page.
        this.showReportCardButton = this.route.name !== this.teamPageName
        // By default, hide graph when first load teams page.
        if (
            this.showScoreGraph === null &&
            this.route.name === this.teamPageName
        ) {
            this.setShowScoreGraph(this.route.name !== this.teamPageName)
        }
    }

    public onReportCardClick() {
        if (!this.hasNetworkConnection) {
            return
        }

        if (this.hasLastMonthsReportCard && this.loadReportCard) {
            this.loadReportCard()
        }
    }
    public onGoalClick() {
        this.$emit('goal-clicked')
    }

    public expandGraph() {
        this.setShowScoreGraph(!this.showScoreGraph)
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/variables.less';

.nps-features {
    .home-target-graph {
        margin-left: -@featureGutter; //Make the margin overflow so the graph reaches the edge
        margin-top: @gutterSpacing-base;
        margin-bottom: @gutterSpacing-base;
    }

    .chevron {
        color: white;
    }

    .expand-graph-icons {
        float: right;
        margin-top: -10px;
    }

    .graph-enter-active {
        transition:
            margin-top 0.25s ease-in,
            opacity 0.25s cubic-bezier(0, 0, 0.9, 0);
    }
    .graph-leave-active {
        transition:
            margin-top 0.25s ease-in,
            opacity 0.25s cubic-bezier(0, 0, 0.1, 1);
    }
    .graph-enter-from,
    .graph-leave-to {
        margin-top: -150px;
        opacity: 0;
    }
    .graph-enter-to,
    .graph-leave-from {
        margin-top: 8px;
        opacity: 1;
    }

    &.no-report {
        padding-bottom: 20px;
    }
}
</style>
