import { IResponse } from '@/entities'

/**
 * Returns the time text for the feedback over, eg. "NPS over the last 30 days"
 * Assumes caller doesn't care about case sensitivity.
 */
export function getOverviewText(
    questionType: string,
    timeUnit: string,
    timeValue: string,
    includeQuestionType = true
) {
    let suffix = ''

    if (timeUnit === 'day') {
        suffix = `over the last ${timeValue} days`
    } else if (timeUnit === 'week') {
        if (parseInt(timeValue) > 1) {
            suffix = 'over the last 2 weeks'
        } else {
            suffix = `over the last week`
        }
    } else if (timeUnit === 'month' && timeValue === '1') {
        suffix = `over the last 30 days`
    } else if (timeUnit === 'month' || timeUnit === 'year') {
        suffix = 'over the last year'
    } else if (timeUnit === 'ytd') {
        suffix = 'year to date'
    } else if (timeUnit === 'today') {
        suffix = 'today'
    } else if (timeUnit === 'custom') {
        suffix = 'over the custom time period'
    } else if (
        [
            'jan',
            'feb',
            'mar',
            'apr',
            'may',
            'jun',
            'jul',
            'aug',
            'sep',
            'oct',
            'nov',
            'dec',
        ].includes(timeUnit)
    ) {
        suffix = `of last ${timeUnit}`
    }

    if (includeQuestionType) {
        return questionType + ' ' + suffix
    }

    return suffix
}

export function getCommentCount(response: IResponse): number {
    return (
        Number(response.automation_count ?? 0) + // Workflow
        Number(response.note_count ?? 0) + // Internal notes
        Number(response.chat_count ?? 0) + // Email + reply
        Number(response.notices_count ?? 0) // Shoutout + Coaching + Messages
    )
}
