<template>
    <div class="huddles-card huddles-error-card" @click="onErrorClick">
        <div class="error-header">
            <FontAwesomeIcon :icon="faCircleExclamation" /> Failed to load this
            {{ type }}
        </div>
        <div class="error-text">Tap to refresh.</div>
    </div>
</template>

<script lang="ts" setup>
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faCircleExclamation } from 'fontawesome/pro-regular-svg-icons'

interface Props {
    type: 'page' | 'section'
}
const props = defineProps<Props>()
const emit = defineEmits(['onErrorClick'])

const onErrorClick = () => {
    emit('onErrorClick')
}
</script>

<style lang="less" scoped>
@import '@/styles/prime-vue/variables.less';
@import '@/styles/rain/variables.less';
@import '@/styles/huddles.less';

.huddles-error-card {
    display: flex;
    flex-direction: column;
    height: fit-content;
    gap: 15px;
    width: 100%;
    padding: @spacing-2xl;
}

.error-header {
    color: var(--p-gray-600);
    font-size: @fontSize-md;
    font-weight: @fontWeight-medium;
}

.error-text {
    color: var(--p-gray-900);
    font-size: @fontSize-sm;
    font-weight: @fontWeight-regular;
}
</style>
