import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3b0bfc4a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "recipient-picker-modal-container" }
const _hoisted_2 = { class: "modal-pad recipient-title" }
const _hoisted_3 = { class: "recipient-action" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Typography = _resolveComponent("Typography")!
  const _component_RecipientPicker = _resolveComponent("RecipientPicker")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Typography, { variant: "subtitle1" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.title ? _ctx.title : 'Select one or more Recipient(s)'), 1)
        ], undefined, true),
        _: 1
      })
    ]),
    _createVNode(_component_RecipientPicker, {
      class: "recipient-picker-content-container",
      recipients: _ctx.recipients,
      "selected-recipients": _ctx.selectedRecipients,
      "can-pick-virtual-users": _ctx.canPickVirtualUsers,
      "max-list-length": 20,
      onToggleRecipient: _ctx.onToggleRecipient,
      onClearSelectedRecipients: _ctx.clearSelectedRecipients
    }, null, 8, ["recipients", "selected-recipients", "can-pick-virtual-users", "onToggleRecipient", "onClearSelectedRecipients"]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_BaseButton, {
        class: "btn-back",
        variant: "ghost",
        onClick: _ctx.onCancelRecipients
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Back ")
        ], undefined, true),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_BaseButton, {
        disabled: _ctx.disableSave,
        variant: "secondary",
        onClick: _ctx.onConfirmRecipients
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Confirm ")
        ], undefined, true),
        _: 1
      }, 8, ["disabled", "onClick"])
    ])
  ]))
}