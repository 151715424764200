<template>
    <div
        :class="[
            'base-card',
            {
                'is-highlighted': isHighlighted,
                'no-pad': noPad,
                'no-overflow': noOverflow,
            },
        ]"
        @click="onClick"
    >
        <slot></slot>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component({
    emits: ['click'],
})
export default class BaseCard extends Vue {
    @Prop({ type: Boolean, required: false, default: false })
    readonly isHighlighted!: boolean

    @Prop({ type: Boolean, required: false, default: false })
    readonly noPad!: boolean

    @Prop({ type: Boolean, required: false, default: false })
    readonly noOverflow!: boolean

    onClick() {
        this.$emit('click')
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/palette';
@import '~@/styles/variables';

.base-card {
    background-color: @white;
    border-radius: @borderRadius * 2;
    border: 1px solid @blueLight;
    padding: 1px;
    transition: border-color 200ms ease-in-out;

    &.is-highlighted {
        border: 2px solid @blue;
        padding: 0;
    }

    &.no-pad {
        padding: 0;
    }

    &.no-overflow {
        overflow: hidden;
    }
}
</style>
