<template>
    <PortalContainer v-if="show" @back="onCancel">
        <transition name="modal">
            <div class="modal">
                <div class="wrapper">
                    <div class="content">
                        <div v-click-outside="clickOut" class="container">
                            <div class="header">
                                <p class="title">{{ title }}</p>
                            </div>
                            <div class="body">
                                <p class="message">{{ message }}</p>
                            </div>
                            <div class="footer">
                                <Button
                                    variant="cancel"
                                    no-border
                                    @click.stop="onCancel"
                                    >{{ cancelTitle }}</Button>
                                <Button variant="primary" @click.stop="onOk">{{
                                    okTitle
                                }}</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </PortalContainer>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import Button from '@/components/WebView/Button.vue'
import vClickOutside from 'v-click-outside'
import PortalContainer from '@/mobile/src/components/containers/PortalContainer.vue'

@Component({
    components: { PortalContainer, Button },
    directives: {
        clickOutside: vClickOutside.directive,
    },
})
export default class AlertDialog extends Vue {
    @Prop({ type: Boolean, default: false }) public show!: boolean
    @Prop({ type: Boolean, default: false }) public allowClickOut!: boolean
    @Prop({ type: String, default: 'Are you sure?' }) public title!: string
    @Prop({ type: String, default: '' }) public message!: string
    @Prop({ type: String, default: 'OK' }) public okTitle!: string
    @Prop({ type: String, default: 'Cancel' }) public cancelTitle!: string
    @Prop({ type: Function, default: () => 0 }) public onCancel!: () => void
    @Prop({ type: Function, default: () => 0 }) public onOk!: () => void

    public clickOut(e) {
        if (!this.allowClickOut) {
            return
        }
        this.onCancel()
    }
}
</script>

<style scoped lang="less">
@import '../styles/palette.less';

.modal {
    position: fixed;
    z-index: 998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    display: table;
    transition: opacity 0.3s ease;
    background-color: rgba(0, 0, 0, 0.5);
}

.wrapper {
    display: table-cell;
    vertical-align: top;
}

.container {
    position: relative;
    margin: 60% auto 0px;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    overflow-y: auto;
    width: 76%;
    padding: 25px;
    display: flex;
    flex-direction: column;

    .header {
        height: 32px;

        .title {
            font-weight: 500;
            font-size: 18px;
            color: #2f384c;
        }
    }

    .body {
        flex: 1;
        .message {
            font-size: 14px;
        }
    }
    .footer {
        display: flex;
        padding-top: 30px;
        .button {
            flex: 1;
            display: block;
            border-radius: 4px;
            font-size: 12px;
            line-height: 14px;
            padding: 12px;
            margin: 0px 5px;
            text-align: center;
        }
    }
}

.modal-enter-from {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter-from .container,
.modal-leave-active .container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
@media (min-width: 768px) {
    .container {
        margin-top: 10%;
        width: 40%;
    }
}
</style>
