import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a6ed186"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = ["type", "autocomplete", "value", "disabled", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      class: _normalizeClass([{ value: _ctx.value, error: _ctx.error }]),
      type: _ctx.type,
      autocomplete: _ctx.autocomplete,
      value: _ctx.value,
      disabled: !!_ctx.disabled,
      placeholder: _ctx.label,
      onFocus: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('focus', $event))),
      onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('blur', $event))),
      onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('input', $event.target.value)))
    }, null, 42, _hoisted_2),
    _createVNode(_component_FontAwesomeIcon, {
      icon: _ctx.faSearch,
      class: "search-icon"
    }, null, 8, ["icon"])
  ]))
}