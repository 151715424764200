<template>
    <div class="huddle-sales">
        <div class="top-selling-items-header">
            <FontAwesomeIcon :icon="faCircleDollar" size="xl" />
            <Typography variant="subtitle1">Top Selling Items</Typography>
        </div>

        <div v-if="summaryView" class="view--summary">
            <div
                v-for="(salesItem, index) in sortedTopSellers.slice(0, 3)"
                :key="index"
                class="sales-item"
            >
                <Typography variant="body2" class="item-name">
                    {{ salesItem.item }}
                </Typography>
                <div class="count">{{ salesItem.count }}</div>
            </div>
        </div>
        <div v-else class="view--full">
            {{ sortedTopSellers }}
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, type ComputedRef } from 'vue'
import { SalesItem } from '@/entities/huddles'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faCircleDollar } from 'fontawesome/pro-regular-svg-icons'
import Typography from '@/components/Rain/Typography/Typography.vue'

interface Props {
    topSellingItems: SalesItem[]
    summaryView: boolean
}
const props = withDefaults(defineProps<Props>(), {
    summaryView: false,
})

const sortedTopSellers: ComputedRef<SalesItem[]> = computed(() => {
    return [...props.topSellingItems].sort((a, b) => {
        return a.count > b.count ? -1 : 1
    })
})
</script>

<style lang="less" scoped>
@import '@/styles/prime-vue/variables.less';
@import '@/styles/rain/variables.less';

.huddle-sales {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.top-selling-items-header {
    display: flex;
    gap: @spacing-sm;
    color: var(--p-gray-600);
    align-items: center;
    margin-bottom: @spacing-md;
}

.sales-item {
    color: var(--p-gray-700);
    display: flex;
    justify-content: space-between;
    padding: @spacing-xxs 9px 3px;
    flex: 2;
    white-space: nowrap;

    .count {
        padding: 0 5px;
        font-size: @fontSize-sm;
        font-weight: @fontWeight-medium;
        line-height: 22px;
        color: white;
        background: var(--p-gray-700);
        border-radius: 3px;
        margin-left: 8px;
    }
}

.view {
    &--summary {
        display: flex;
        flex-grow: 2;
        align-items: center;

        .sales-item {
            height: fit-content;

            .item-name {
                max-width: 80px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}
</style>
