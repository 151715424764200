import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bad8da58"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "feedback-container" }
const _hoisted_2 = {
  key: "content",
  class: "content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopOverview = _resolveComponent("TopOverview")!
  const _component_FeedbackResponses = _resolveComponent("FeedbackResponses")!
  const _component_PullToRefreshWrapper = _resolveComponent("PullToRefreshWrapper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PullToRefreshWrapper, {
      disable: 
                _ctx.haxIosDisableContentScrolling ||
                !_ctx.showTopOverview ||
                _ctx.overviewScrolling
            ,
      "ptr-refreshing": _ctx.refreshing,
      "refresh-container-classes": true ? 'full-height' : '',
      onRefreshAction: _ctx.forceRefresh
    }, {
      default: _withCtx(() => [
        _createVNode(_TransitionGroup, { appear: "" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              (_ctx.showTopOverview)
                ? (_openBlock(), _createBlock(_component_TopOverview, {
                    key: "overview",
                    "force-show-loading": _ctx.forceShowLoading,
                    "should-refresh": _ctx.refreshing,
                    onRefreshFinished: _ctx.onRefreshDone,
                    onCarouselTouchStart: _ctx.onOverviewTouchStart,
                    onCarouselTouchEnd: _ctx.onOverviewTouchEnd
                  }, null, 8, ["force-show-loading", "should-refresh", "onRefreshFinished", "onCarouselTouchStart", "onCarouselTouchEnd"]))
                : _createCommentVNode("", true),
              _createVNode(_component_FeedbackResponses, {
                "force-show-loading": _ctx.forceShowLoading,
                "should-refresh": _ctx.refreshing,
                onRefreshFinished: _ctx.onRefreshDone
              }, null, 8, ["force-show-loading", "should-refresh", "onRefreshFinished"])
            ])
          ], undefined, true),
          _: 1
        })
      ], undefined, true),
      _: 1
    }, 8, ["disable", "ptr-refreshing", "onRefreshAction"])
  ]))
}