import client, { formClient } from '../../client'
import { IUserConfig } from '@/entities/user'

declare let ANAPI_TOKEN: string

export async function saveUser(params) {
    try {
        return await client.post(`user`, params)
    } catch (e) {
        return {
            data: {
                success: false,
                msg: 'Something went wrong, please try again later',
            },
        }
    }
}

export async function getPaginated(params) {
    try {
        return await client.post(`/user/paginated`, params)
    } catch (error) {
        return {
            data: {
                success: false,
                msg: 'Something went wrong, please try again later',
            },
        }
    }
}

export async function getAllSelectedUsers(params) {
    try {
        return await client.post(`/user/getselected`, params)
    } catch (error) {
        return {
            data: {
                success: false,
                msg: 'Something went wrong, please try again later',
            },
        }
    }
}

export async function sendInvitation(params) {
    params.append('job', 'invitation')
    try {
        return await client.post(`sendinvitation`, params)
    } catch (e) {
        return {
            data: {
                success: false,
                msg: 'Something went wrong, please try again later',
            },
        }
    }
}

export async function verifyUsers(params) {
    try {
        return await client.post(`verifyusers`, params)
    } catch (e) {
        return {
            data: {
                success: false,
                msg: 'Something went wrong, please try again later',
            },
        }
    }
}

export async function saveUserConfig(config: IUserConfig) {
    try {
        return await client.post(`user/config`, config)
    } catch (e) {
        return {
            data: {
                success: false,
                msg: 'Something went wrong, please try again later',
            },
        }
    }
}

export async function deleteUser(id) {
    try {
        return await client.post(`user/${id}/delete`)
    } catch (e) {
        return {
            data: {
                success: false,
                msg: 'Something went wrong, please try again later',
            },
        }
    }
}

export async function sendResetPasswordEmail(params) {
    try {
        return await client.post(`/ajax/password/admin-reset-user`, params, {
            baseURL: '/',
        })
    } catch (e) {
        return {
            data: {
                success: false,
                msg: 'Something went wrong, please try again later',
            },
        }
    }
}

export async function bulkUpdateUser(params) {
    try {
        return await client.post(`user/bulk/update`, params)
    } catch (e) {
        return {
            data: {
                success: false,
                msg: 'Something went wrong, please try again later',
            },
        }
    }
}

export async function bulkDeleteUser(params) {
    try {
        return await client.post(`user/bulk/delete`, params)
    } catch (e) {
        return {
            data: {
                success: false,
                msg: 'Something went wrong, please try again later',
            },
        }
    }
}

// invite to mobile app
export async function inviteUserToApp(id) {
    try {
        return await client.post(`/user/invite/${id}`, {}, { baseURL: '/' })
    } catch (e) {
        return {
            data: {
                success: false,
                msg: 'Something went wrong, please try again later',
            },
        }
    }
}

export async function uploadUserAvatar(file: File) {
    const formData = new FormData()
    formData.append('avatar', file)
    formData.append('_token', ANAPI_TOKEN)

    return formClient.post<{ url: string }>(`/ajax/user/avatar`, formData)
}

export async function getApiKey() {
    try {
        return await client.get(`/user/apikey`)
    } catch (e) {
        return {
            data: {
                success: false,
                msg: 'Something went wrong, please try again later',
            },
        }
    }
}

export async function sendMagicLinks(params) {
    params.append('job', 'magic-links')
    try {
        return await client.post(`sendinvitation`, params)
    } catch (e) {
        return {
            data: {
                success: false,
                msg: 'Something went wrong, please try again later',
            },
        }
    }
}

export async function setBetaAppAccess(accessBeta = false) {
    await client.post(`/user/access-beta-app/${accessBeta}`)
}
