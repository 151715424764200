<template>
    <div
        class="recipient-container"
        :class="{ compact }"
        @click="$emit('pick', recipient)"
    >
        <AvatarOrInitials :user="userForAvatar" :size="40" />
        <Typography class="name" variant="body1">{{
            recipientName
        }}</Typography>

        <div :class="{ checkbox: true, selected }">
            <svg
                v-if="selected"
                width="14"
                height="10"
                viewBox="0 0 14 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M1 5.49943L4.65259 9L13 1"
                    stroke="#2E5BFF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { IRecipient } from '@/entities/notices'
import AvatarOrInitials from '@/components/WebView/AvatarOrInitials.vue'
import { UserDetail } from '@/entities'
import { decode as entityDecode } from 'he'
import Typography from '@/components/Rain/Typography/Typography.vue'

@Component({
    components: {
        Typography,
        AvatarOrInitials,
    },
    emits: ['pick'],
})
export default class Recipient extends Vue {
    @Prop() private recipient!: IRecipient
    @Prop({ type: Boolean, default: false }) private selected!: boolean
    // if compact, no border bottom, padding also smaller
    @Prop({ type: Boolean, default: false }) private compact!: boolean

    private get userForAvatar(): UserDetail {
        return {
            name: this.recipient.name,
            id: this.recipient.user_id,
            avatar: this.recipient.avatar,
        }
    }

    private get recipientName() {
        return entityDecode(this.recipient.name)
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/layout.less';
@import '~@/styles/palette.less';
@import '~@/styles/rain/variables.less';

.recipient-container:hover {
    transition: background-color 0.3s ease-in-out;
    background-color: @greyLight;
}
.recipient-container {
    padding: @gutterSpacing-base @gutterSpacing-lg;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    margin-bottom: @gutterSpacing-md;

    &.compact {
        border-bottom: none;
        padding: 10px 20px;
    }
    .avatar {
        width: 40px;
        height: 40px;
        color: white;
        font-size: 20px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        overflow: hidden;

        &.text {
            background-color: @purple;
        }

        &.image {
            background-color: white;
            border: 0.5px solid #93a1be;
        }

        span {
            margin: 0 auto;
        }
    }

    .name {
        font-size: 16px;
        margin-left: 12px;
        flex: 1;
        text-align: left;
    }

    .checkbox {
        margin-left: 10px;
        text-align: center;
        width: 24px;
    }
}
</style>
