import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-01ef53b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "avatar" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "details" }
const _hoisted_4 = {
  key: 0,
  class: "header"
}
const _hoisted_5 = {
  key: 0,
  class: "sub-title"
}
const _hoisted_6 = { class: "score-container" }
const _hoisted_7 = { class: "score" }
const _hoisted_8 = {
  key: 1,
  class: "sub-header"
}
const _hoisted_9 = { class: "trending-topic-top" }
const _hoisted_10 = {
  key: 2,
  class: "sub-header coaching-scorecard-container"
}
const _hoisted_11 = {
  key: 0,
  class: "coaching-scorecard-info"
}
const _hoisted_12 = { class: "coaching-scorecard-score" }
const _hoisted_13 = {
  key: 1,
  class: "coaching-scorecard-info"
}
const _hoisted_14 = { class: "coaching-scorecard-score" }
const _hoisted_15 = {
  key: 3,
  class: "sub-header coaching-scorecard-container"
}
const _hoisted_16 = {
  key: 0,
  class: "coaching-scorecard-info"
}
const _hoisted_17 = { class: "coaching-scorecard-score" }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AvatarOrInitials = _resolveComponent("AvatarOrInitials")!
  const _component_UpDownArrow = _resolveComponent("UpDownArrow")!
  const _component_BestWorstTopicTag = _resolveComponent("BestWorstTopicTag")!

  return (_openBlock(), _createElementBlock("div", {
    class: "coaching-list-item",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToCoachingDetailedPage && _ctx.goToCoachingDetailedPage(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.coachingMetricType === 'trending_topic')
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            width: 44,
            height: 44,
            src: _ctx.getBadgeSchema(_ctx.data.badge).img,
            alt: _ctx.getBadgeSchema(_ctx.data.badge).label
          }, null, 8, _hoisted_2))
        : (_openBlock(), _createBlock(_component_AvatarOrInitials, {
            key: 1,
            user: _ctx.data.user,
            size: 44
          }, null, 8, ["user"]))
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.title)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", null, [
              _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
              (_ctx.coachingMetricType === 'biggest_change')
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.data.score_current.toFixed(1)) + " " + _toDisplayString(_ctx.scoreLabel), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createTextVNode(_toDisplayString(_ctx.score) + " ", 1),
                (_ctx.data.score_change !== 0)
                  ? (_openBlock(), _createBlock(_component_UpDownArrow, {
                      key: 0,
                      positive: _ctx.data.score_change > 0,
                      class: "change"
                    }, null, 8, ["positive"]))
                  : _createCommentVNode("", true)
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.coachingMetricType === 'trending_topic')
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", null, _toDisplayString(_ctx.mentions) + " mentions", 1),
              _createElementVNode("div", null, _toDisplayString(_ctx.data.score_current.toFixed(1)) + " " + _toDisplayString(_ctx.scoreLabel), 1)
            ]),
            _createElementVNode("div", null, _toDisplayString(_ctx.responseRate) + "% of all responses", 1)
          ]))
        : (_ctx.coachingMetricType === 'top_performer')
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              (_ctx.highestScorecardScore.title)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createVNode(_component_BestWorstTopicTag, {
                      positive: true,
                      title: _ctx.highestScorecardScore.title
                    }, null, 8, ["title"]),
                    _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.highestScorecardScore.score_current.toFixed(1)) + " " + _toDisplayString(_ctx.scoreLabel), 1)
                  ]))
                : _createCommentVNode("", true),
              (
                        _ctx.lowestScorecardScore.title &&
                        _ctx.highestScorecardScore !== _ctx.lowestScorecardScore
                    )
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createVNode(_component_BestWorstTopicTag, {
                      positive: false,
                      title: _ctx.lowestScorecardScore.title
                    }, null, 8, ["title"]),
                    _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.lowestScorecardScore.score_current.toFixed(1)) + " " + _toDisplayString(_ctx.scoreLabel), 1)
                  ]))
                : _createCommentVNode("", true)
            ]))
          : (_ctx.coachingMetricType === 'biggest_change')
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                (_ctx.biggestScorecardChange.title)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                      _createVNode(_component_BestWorstTopicTag, {
                        positive: _ctx.biggestScorecardChange.score_change > 0,
                        title: _ctx.biggestScorecardChange.title
                      }, null, 8, ["positive", "title"]),
                      _createElementVNode("div", _hoisted_17, [
                        (_ctx.biggestScorecardChange.score_change > 0)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_18, "+"))
                          : (_ctx.biggestScorecardChange.score_change < 0)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_19, "-"))
                            : _createCommentVNode("", true),
                        _createTextVNode(" " + _toDisplayString(Math.abs(
                                _ctx.biggestScorecardChange.score_change
                            ).toFixed(1)) + " " + _toDisplayString(_ctx.scoreLabel), 1)
                      ]),
                      _createVNode(_component_UpDownArrow, {
                        positive: _ctx.biggestScorecardChange.score_change > 0,
                        class: "change-small"
                      }, null, 8, ["positive"])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
    ])
  ]))
}