import { parse } from 'flatted'

// This function is exactly the same as the default one provided by vuex-persist,
// except I have this try/catch block so it doesn't stop the whole page load.
// Failing to restore state is acceptable for us.
export default function restoreState(key, storage) {
    if (!storage) {
        return {}
    }
    const value = storage.getItem(key)
    if (typeof value === 'string') {
        // If string, parse, or else, just return
        try {
            return parse(value || '{}')
        } catch (e) {
            return {}
        }
    } else {
        return value || {}
    }
}
