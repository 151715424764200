import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['dropdown', { opened: _ctx.opened }])
  }, [
    (_ctx.mode === 'click')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "trigger",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
        }, [
          _renderSlot(_ctx.$slots, "trigger", {}, undefined, true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.mode === 'mouseover')
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "trigger",
          onMouseover: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.open && _ctx.open(...args)))
        }, [
          _renderSlot(_ctx.$slots, "trigger", {}, undefined, true)
        ], 32))
      : _createCommentVNode("", true),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.opened)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              ref: "content",
              class: _normalizeClass([
                    'content',
                    { left: _ctx.left, right: _ctx.rightOverride || _ctx.right },
                    _ctx.modal ? 'modal-dd' : '',
                ])
            }, [
              _renderSlot(_ctx.$slots, "content", {}, undefined, true)
            ], 2))
          : _createCommentVNode("", true)
      ], undefined, true),
      _: 3
    })
  ], 2)), [
    [_directive_click_outside, _ctx.clickOutside]
  ])
}