import client from '@/api/client'

export async function getNotifications(
    last_polled: string,
    first_notification_id = null
) {
    return client.post(`/msgserver/notifications/${last_polled}`, {
        first_notification_id,
    })
}
