import { ActionTree, GetterTree, MutationTree } from 'vuex'
import {
    getApolloQueryClient,
    getApolloMutationClient,
} from '@/api/apollo-client'
import client, { formClient, baseClient } from '@/api/client'
import {
    Learning,
    InitLearning,
} from './../../pages/appstore/entities/learning'
import { App, InitApp, LooseObject } from './../../pages/appstore/entities/app'
import {
    AppstoreInitialData,
    RecommendedAppsInitialData,
    LaunchPadInitialData,
} from '@/pages/appstore/entities/appstore'
import { TenantConfig } from '@/pages/appstore/entities/tenantConfig'
import { StepTip, StepAPIPayload } from '@/pages/appstore/entities/step-tip'
import { notifySuccess, notifyError, setFormData } from '@/utils/appstore'
import {
    getAppstore,
    getAppstores,
    getLearnings,
    getLearning,
    updateAppstore,
    reorderAppstoreData,
    createUpdateLearning,
} from '@/graphql/appstore/appstore'

export interface AppstoreState {
    baseURL: string
    adminBaseURL: string
    graphBaseURL: string
    graphAdminBaseURL: string
    isAdmin: boolean
    tenantConfig: TenantConfig[]
    recommendedAppsNames: string[]
    launchPadAppsNames: string[]
    isEmbed: boolean
    enabledBetaAppsString: string
    allApps: App[]
    installedAppsNames: string[]
    curApp: App
    curAppLearnings: Learning[]
    curAppApps: App[]
    curAppSteps: StepTip[]
    allLearnings: Learning[]
    curLearning: Learning
    curLearningLearnings: Learning[]
    curLearningApps: App[]
    hasSimpleSurvey: boolean
    csvImporterEmailDomain: string
    searchTerm: string
}

export const state: AppstoreState = {
    baseURL: '',
    adminBaseURL: '',
    graphBaseURL: '',
    graphAdminBaseURL: '',
    isAdmin: false,
    tenantConfig: [],
    recommendedAppsNames: [],
    launchPadAppsNames: [],
    isEmbed: false,
    enabledBetaAppsString: '',
    allApps: [],
    installedAppsNames: [],
    curApp: { ...InitApp },
    curAppLearnings: [],
    curAppApps: [],
    curAppSteps: [],
    allLearnings: [],
    curLearning: { ...InitLearning },
    curLearningLearnings: [],
    curLearningApps: [],
    hasSimpleSurvey: false,
    csvImporterEmailDomain: '',
    searchTerm: '',
}

// Create the apollo query client
let apolloClient: LooseObject
// Create the apollo mutate client
let apolloMutateClient: LooseObject
const actions: ActionTree<AppstoreState, any> = {
    async setAjaxClients({ commit, state }) {
        client.defaults.params.enabledBetaApps = state.enabledBetaAppsString
        apolloClient = getApolloQueryClient(state.graphBaseURL)
        if (state.isAdmin) {
            try {
                const result = await baseClient.get(
                    state.adminBaseURL + '/get-csrf-token',
                    { withCredentials: true }
                )
                const token = result.data['csrf-token']
                const mutateURL = `${state.graphAdminBaseURL}?_token=${token}`
                apolloMutateClient = getApolloMutationClient(mutateURL)
                formClient.defaults.params = {
                    _token: token,
                }
            } catch (error) {
                state.isAdmin = false
                console.error(error)
            }
        }
    },
    async loadAllApps({ commit, state }) {
        const options = {
            query: getAppstores(),
            variables: { enabledBetaApps: state.enabledBetaAppsString },
        }
        const { data } = await apolloClient.query(options)

        commit('setAllApps', data.appstores)
    },
    async loadInstalledAppsNames({ commit, state }) {
        const { data } = await client.get('/appstore/installedAppsNames')

        commit('setInstalledAppsNames', data.installedAppsNames)
    },
    async loadCurApp({ commit, state }, app) {
        const options = { query: getAppstore(app.id), fetchPolicy: 'no-cache' }
        const { data } = await apolloClient.query(options)

        commit('setCurApp', data.appstore)
        commit('setCurAppLearnings', data.appstore.linked_learning)
        commit('setCurAppApps', data.appstore.linked_appstore)
        commit(
            'setCurAppSteps',
            data.appstore.linked_steps.map(
                ({ learning_id, appstore_id, ...rest }: StepAPIPayload) => ({
                    ...rest,
                    learningId: learning_id,
                    appstoreId: appstore_id,
                })
            )
        )
    },
    async addApp({ commit, state }, app) {
        // TODO: add new app
    },
    async updateCurApp({ commit, state }, app) {
        try {
            const options = { mutation: updateAppstore(), variables: app }
            const { data } = await apolloMutateClient.mutate(options)

            commit('setCurApp', data.updateAppstore)
            commit('updateAllApps', data.updateAppstore)
            commit('setCurAppLearnings', data.updateAppstore.linked_learning)
            commit('setCurAppApps', data.updateAppstore.linked_appstore)
            commit(
                'setCurAppSteps',
                data.updateAppstore.linked_steps.map(
                    ({
                        learning_id,
                        appstore_id,
                        ...rest
                    }: StepAPIPayload) => ({
                        ...rest,
                        learningId: learning_id,
                        appstoreId: appstore_id,
                    })
                )
            )
            notifySuccess('Updated successfully')
        } catch (error: any) {
            notifyError(`Something went wrong: ${error.message}`)
        }
    },
    async unlinkCurAppLearning({ commit, state }, app) {
        try {
            const options = { mutation: updateAppstore(), variables: app }
            const { data } = await apolloMutateClient.mutate(options)

            commit('setCurAppLearnings', data.updateAppstore.linked_learning)
            notifySuccess('Unlinked successfully')
        } catch (error: any) {
            notifyError(`Something went wrong: ${error.message}`)
        }
    },
    async reorderCurAppLearnings({ commit, state }, app) {
        try {
            const options = {
                mutation: reorderAppstoreData(),
                variables: {
                    appstore_id: app.appstore_id,
                    linked_learning_ids: app.linked_learnings,
                },
            }
            const { data } = await apolloMutateClient.mutate(options)

            commit(
                'setCurAppLearnings',
                data.reorderAppstoreData.linked_learning
            )
            notifySuccess('Order changed successfully')
        } catch (error: any) {
            notifyError(`Something went wrong: ${error.message}`)
        }
    },
    async reorderCurAppApps({ commit, state }, app) {
        try {
            const options = {
                mutation: reorderAppstoreData(),
                variables: {
                    appstore_id: app.appstore_id,
                    linked_appstore_ids: app.linked_appstores,
                },
            }
            const { data } = await apolloMutateClient.mutate(options)

            commit('setCurAppApps', data.reorderAppstoreData.linked_appstore)
            notifySuccess('Order changed successfully')
        } catch (error: any) {
            notifyError(`Something went wrong: ${error.message}`)
        }
    },
    async deleteCurApp({ commit, state }, app) {
        // TODO: delete the current app
    },
    // relationship between app & learning can only be editted in learning editor
    async loadAllLearnings({ commit, state }) {
        const options = { query: getLearnings() }
        const { data } = await apolloClient.query(options)

        commit('setAllLearnings', data.learnings)
    },
    async loadCurLearning({ commit, state }, learningId: string) {
        const options = { query: getLearning(learningId) }
        const { data } = await apolloClient.query(options)

        commit('setCurLearning', data.learning)
        // commit('setCurLearningLearnings', data.linkedLearnings) not in current scope
        commit('setCurLearningApps', data.learning.linked_appstore)
    },
    async addLearning({ commit, state }, learning) {
        try {
            const options = {
                mutation: createUpdateLearning(),
                variables: learning,
            }
            const { data } = await apolloMutateClient.mutate(options)

            const canAdd = data.createUpdateLearning.linked_appstore
                .map((e: App) => e.id)
                .includes(state.curApp.id)
            if (canAdd) {
                commit('addCurAppLearnings', data.createUpdateLearning)
            }
            commit('setCurLearning', data.createUpdateLearning)
            commit(
                'setCurLearningApps',
                data.createUpdateLearning.linked_appstore
            )
            commit('setAllLearnings', data.createUpdateLearning)
            notifySuccess('Added successfully')
        } catch (error: any) {
            notifyError(`Something went wrong: ${error.message}`)
        }
    },
    async updateCurLearning({ commit, state }, learning) {
        try {
            const options = {
                mutation: createUpdateLearning(),
                variables: learning,
            }
            const { data } = await apolloMutateClient.mutate(options)

            const canAdd = data.createUpdateLearning.linked_appstore
                .map((e: App) => e.id)
                .includes(state.curApp.id)
            if (canAdd) {
                commit('updateCurAppLearnings', data.createUpdateLearning)
            } else {
                commit('deleteCurAppLearning', data.createUpdateLearning.id)
            }
            commit('setCurLearning', data.createUpdateLearning)
            commit(
                'setCurLearningApps',
                data.createUpdateLearning.linked_appstore
            )
            commit('updateAllLearnings', data.createUpdateLearning)
            notifySuccess('Updated successfully')
        } catch (error: any) {
            notifyError(`Something went wrong: ${error.message}`)
        }
    },
    // async deleteCurLearning({commit, state}, learningId: string|number) {
    //     try {
    //         const {data} = await formClient.post(
    //             state.adminBaseURL + `/learning/delete`, setFormData({id: learningId})
    //         )
    //         commit('deleteCurAppLearning', data.id)
    //         commit('decreaseAllLearnings', data.id)
    //         notifySuccess('Deleted successfully')
    //     } catch (error: any) {
    //         notifyError(`Something went wrong: ${error.message}`)
    //     }
    // }
    async addStep({ commit, state }, step: StepTip) {
        try {
            const { key, title, description, learningId, appstoreId } = step
            const requestData: StepAPIPayload = {
                key,
                appstore_id: appstoreId,
                title,
                description,
            }

            if (learningId) {
                requestData.learning_id = learningId
            }

            const { data } = await formClient.post(
                state.adminBaseURL + `/step/save`,
                setFormData(requestData)
            )

            commit('addStep', {
                ...step,
                id: data.id,
            })
            notifySuccess('Added successfully')
        } catch (error: any) {
            notifyError(`Something went wrong: ${error.message}`)
        }
    },
    async updateStep({ commit, state }, step) {
        try {
            const { id, key, title, description, learningId, appstoreId } = step
            const requestData: StepAPIPayload = {
                id,
                key,
                appstore_id: appstoreId,
                title,
                description,
            }

            if (learningId) {
                requestData.learning_id = learningId
            }

            const { data } = await formClient.post(
                state.adminBaseURL + `/step/save`,
                setFormData(requestData)
            )

            commit('updateStep', step)
            notifySuccess('Updated successfully')
        } catch (error: any) {
            notifyError(`Something went wrong: ${error.message}`)
        }
    },
    async removeStep({ commit, state: { adminBaseURL } }, stepId: string) {
        try {
            const { data } = await formClient.post(
                `/step/delete`,
                setFormData({ id: stepId }),
                { baseURL: adminBaseURL }
            )

            commit('deleteStep', data.id)
            notifySuccess('Deleted successfully')
        } catch (error: any) {
            notifyError(`Something went wrong: ${error.message}`)
        }
    },
}

const mutations: MutationTree<AppstoreState> = {
    setBasics(state, data: AppstoreInitialData) {
        state.baseURL = data.baseURL
        state.adminBaseURL = data.adminBaseURL
        state.graphBaseURL = data.graphBaseURL
        state.graphAdminBaseURL = data.graphAdminBaseURL
        state.enabledBetaAppsString = data.enabledBetaApps
        state.isAdmin = data.isAppstoreAdmin
        state.tenantConfig = data.tenantConfig
        state.isEmbed = data.isEmbed
        state.hasSimpleSurvey = data.hasSimpleSurvey
        state.csvImporterEmailDomain = data.csvImporterEmailDomain
    },
    setDashRecommendedAppsBasics(state, data: RecommendedAppsInitialData) {
        state.graphBaseURL = data.graphBaseURL
        state.recommendedAppsNames = data.recommendedAppsNames
        state.enabledBetaAppsString = data.enabledBetaApps
    },
    setLaunchPadBasics(state, data: LaunchPadInitialData) {
        state.graphBaseURL = data.graphBaseURL
        state.launchPadAppsNames = data.launchPadAppsNames
    },
    setAllApps(state, apps) {
        state.allApps = apps
    },
    updateAllApps(state, app: App) {
        state.allApps = state.allApps.map((e: any) =>
            e.id === app.id ? app : e
        )
    },
    setInstalledAppsNames(state, installedAppsNames: string[]) {
        state.installedAppsNames = installedAppsNames
    },
    setAllLearnings(state, learnings) {
        state.allLearnings = state.allLearnings.concat(learnings)
    },
    updateAllLearnings(state, learning: Learning) {
        state.allLearnings = state.allLearnings.map((e: any) =>
            e.id === learning.id ? learning : e
        )
    },
    decreaseAllLearnings(state, learningId: number | string) {
        state.allLearnings = state.allLearnings.filter(
            (e: Learning) => Number(e.id) !== learningId
        )
    },
    setCurApp(state, app: App) {
        state.curApp = app
    },
    setCurLearning(state, learning: Learning) {
        state.curLearning = { ...learning }
    },

    setCurLearningLearnings(state, learnings: Learning[]) {
        state.curLearningLearnings = learnings
    },
    setCurLearningApps(state, apps: App[]) {
        state.curLearningApps = apps
    },
    setCurAppLearnings(state, learnings: Learning[]) {
        state.curAppLearnings = learnings
    },
    addCurAppLearnings(state, learning: Learning) {
        state.curAppLearnings.unshift(learning)
    },
    updateCurAppLearnings(state, learning: Learning) {
        state.curAppLearnings = state.curAppLearnings.map((e: Learning) =>
            e.id === learning.id ? learning : e
        )
    },
    deleteCurAppLearning(state, learningId: string | number) {
        state.curAppLearnings = state.curAppLearnings.filter(
            (e: Learning) => Number(e.id) !== Number(learningId)
        )
    },
    setCurAppApps(state, apps: App[]) {
        state.curAppApps = apps
    },
    addCurAppApps(state, app: App) {
        state.curAppApps.push(app)
    },
    deleteCurAppApps(state, app: App) {
        state.curAppApps = state.curAppApps.filter((e: App) => e.id !== app.id)
    },
    setCurAppSteps(state, steps: StepTip[]) {
        state.curAppSteps = steps
    },
    addStep(state, step: StepTip) {
        state.curAppSteps.push(step)
    },
    updateStep(state, step: StepTip) {
        state.curAppSteps.splice(
            state.curAppSteps.findIndex(({ id }) => id === step.id),
            1,
            step
        )
    },
    deleteStep(state, stepId: string) {
        state.curAppSteps.splice(
            state.curAppSteps.findIndex(({ id }) => id === stepId),
            1
        )
    },
    setSearchTerm(state, searchTerm: string) {
        state.searchTerm = searchTerm
    },
}

const getters: GetterTree<AppstoreState, any> = {
    getIsAdmin: ({ isAdmin }) => isAdmin,
    getTenantConfig: ({ tenantConfig }) => tenantConfig,
    getIsEmbed: ({ isEmbed }) => isEmbed,
    getAllApps: ({ allApps }) => allApps,
    getInstalledApps: ({ allApps, installedAppsNames }) =>
        allApps.filter((e: App) => installedAppsNames.includes(e.name)),
    getInstalledAppsNames: ({ installedAppsNames }) => installedAppsNames,
    getCurApp: ({ curApp }) => curApp,
    getCurAppLearnings: ({ curAppLearnings }) => curAppLearnings,
    getCurAppLearningsIds: ({ curAppLearnings }) =>
        curAppLearnings.map((e: Learning) => e.id),
    getCurAppApps: ({ curAppApps }) => curAppApps,
    getCurAppAppsIds: ({ curAppApps }) => curAppApps.map((e: App) => e.id),
    getAllLearnings: ({ allLearnings }) => allLearnings,
    getCurLearning: ({ curLearning }) => curLearning,
    getCurLearningLearnings: ({ curLearningLearnings }) => curLearningLearnings,
    getCurLearningApps: ({ curLearningApps }) => curLearningApps,
    getCurLearningAppsIds: ({ curLearningApps }) =>
        curLearningApps.map((e: App) => e.id),
    getCurAppSteps: ({ curAppSteps }) => curAppSteps,
    baseURL: ({ baseURL }) => baseURL,
    hasSimpleSurvey: ({ hasSimpleSurvey }) => hasSimpleSurvey,
    csvImporterEmailDomain: ({ csvImporterEmailDomain }) =>
        csvImporterEmailDomain,
    getRecommendedApps({
        allApps,
        recommendedAppsNames,
        installedAppsNames,
    }): App[] {
        return allApps
            .filter((e: App) => recommendedAppsNames.includes(e.name))
            .filter((e: App) => !installedAppsNames.includes(e.name))
    },
    getLaunchPadApps({
        allApps,
        launchPadAppsNames,
        installedAppsNames,
    }): App[] {
        let rawApps = allApps.filter((e: App) =>
            launchPadAppsNames.includes(e.name)
        )
        if (rawApps.length === 0) {
            rawApps = allApps.filter((e: App) => e.name === 'csv-importer')
        }
        return rawApps.filter((e: App) => !installedAppsNames.includes(e.name))
    },
    getSearchTerm: ({ searchTerm }) => searchTerm,
}

export default {
    namespaced: false,
    state,
    actions,
    mutations,
    getters,
}
