<template>
    <form class="web-view-form" @submit="(event) => $emit('submit', event)">
        <slot></slot>
    </form>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { Action, Getter } from 'vuex-facing-decorator'

/**
 * This is just for consistent styling of form elements in a webview,
 * these are passed into the child slot
 */
@Component({
    components: {},
    emits: ['submit'],
})
export default class WebViewForm extends Vue {}
</script>

<style lang="less">
@import '~@/styles/palette';

form.web-view-form {
    display: flex;
    flex-direction: column;
    background: @white;
    flex: 1;

    fieldset {
        display: block;

        &:not(:first-of-type) {
            border-top: 1px solid @blueLight;
        }
    }

    fieldset.expand {
        flex: 1;
        textarea {
            height: 200px;
        }
    }

    label {
        font-size: 14px;
        line-height: 20px;
        padding: 20px 20px 0px;
        height: 20px;
    }

    label,
    input,
    textarea {
        display: block;
        font-family: 'Roboto', sans-serif;
    }

    input,
    textarea {
        font-size: 16px;
        line-height: 19px;
        color: #293143;
        width: 100%;
        padding: 10px 20px 30px;
        border: none;
        outline: none;

        &::placeholder {
            font-family: 'Roboto Light', sans-serif;
            color: #adb1b8;
            font-weight: lighter;
        }
    }
}
</style>
