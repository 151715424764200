import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MomentTypeTitle = _resolveComponent("MomentTypeTitle")!
  const _component_ResponseCircle = _resolveComponent("ResponseCircle")!
  const _component_CountCircle = _resolveComponent("CountCircle")!
  const _component_TextClamp = _resolveComponent("TextClamp")!
  const _component_AvatarOrInitials = _resolveComponent("AvatarOrInitials")!
  const _component_FeedbackBaseCard = _resolveComponent("FeedbackBaseCard")!

  return (_openBlock(), _createBlock(_component_FeedbackBaseCard, {
    timestamp: Number(_ctx.question.responded),
    author: _ctx.question.name,
    text: _ctx.question.comment,
    highlight: !_ctx.moment.seen,
    "card-type": "moment",
    "text-italic": _ctx.isNoCommentQuestion,
    "double-card": "",
    "is-starred": _ctx.isStarred,
    onToggleStarred: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onToggleStarred()))
  }, _createSlots({
    title: _withCtx(() => [
      _createVNode(_component_MomentTypeTitle, {
        seen: Boolean(_ctx.moment.seen),
        title: _ctx.questionTopic
      }, null, 8, ["seen", "title"])
    ]),
    icon: _withCtx(() => [
      _createVNode(_component_ResponseCircle, {
        score: Number(_ctx.question.score),
        variation: _ctx.question.type
      }, null, 8, ["score", "variation"])
    ]),
    secondTitle: _withCtx(() => [
      _createVNode(_component_MomentTypeTitle, {
        title: _ctx.moment.type,
        "type-class": _ctx.momentTypeClass
      }, null, 8, ["title", "type-class"])
    ]),
    secondRightTitle: _withCtx(() => [
      _createVNode(_component_TextClamp, {
        text: `To ${_ctx.momentRecipients}`,
        "max-lines": 2
      }, null, 8, ["text"])
    ]),
    secondIcon: _withCtx(() => [
      _createVNode(_component_AvatarOrInitials, {
        size: 40,
        user: _ctx.moment.user
      }, null, 8, ["user"])
    ]),
    secondText: _withCtx(() => [
      _createVNode(_component_TextClamp, {
        "is-html": "",
        text: _ctx.message,
        "max-lines": 2
      }, null, 8, ["text"])
    ]),
    secondAuthor: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.moment.user.name), 1)
    ]),
    secondTimestamp: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.getRelativeDate(_ctx.moment.updated)), 1)
    ]),
    _: 2
  }, [
    (_ctx.moment.repliesCount > 0)
      ? {
          name: "extraIcon",
          fn: _withCtx(() => [
            _createVNode(_component_CountCircle, {
              count: _ctx.moment.repliesCount,
              variation: _ctx.momentTypeClass,
              class: "replies-count"
            }, null, 8, ["count", "variation"])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["timestamp", "author", "text", "highlight", "text-italic", "is-starred"]))
}