<template>
    <WebViewScreen
        class="notification-container"
        no-pad
        title="Notifications"
        disable-when-offline
    >
        <template #left-button><BackButton /></template>
        <template #right-button>
            <div class="btn-plus" @click="onShowCreateNotificationSetting">
                <img
                    src="@/components/WebView/icons/AddNew.svg"
                    alt="Create a new notification"
                />
            </div>
        </template>

        <Scrollable
            v-if="settings && settings.length"
            :loading="!notificationSettingsLastRefreshed"
            class="content-container"
            :pull-down="reloadNotificationSettings"
        >
            <div
                v-for="setting in settings"
                :key="setting.id"
                ref="content"
                class="card-content"
            >
                <div class="text">{{ setting.name }}</div>
                <div class="icons">
                    <FontAwesomeIcon
                        :icon="faTrashCan"
                        size="lg"
                        class="trash-icon"
                        @click="confirmDeleteSetting(setting)"
                    />
                    <ToggleInput
                        :checked="setting.enabled"
                        checked-text=""
                        un-checked-text=""
                        size="mobile"
                        @change="toggleSetting(setting)"
                    />
                </div>
            </div>
        </Scrollable>
    </WebViewScreen>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { Capacitor } from '@capacitor/core'
import { Dialog } from '@capacitor/dialog'
import { useRouter } from 'vue-router'
import BaseButton from '@/components/BaseButton.vue'
import WebViewScreen from '@/components/WebView/WebViewScreen.vue'
import MenuRow from '@/mobile/src/components/MenuRow.vue'
import Scrollable from '@/components/Scrollable.vue'
import { Action, Getter, Mutation } from 'vuex-facing-decorator'
import { NotificationSetting } from '@/mobile/src/store/modules/notificationSettings'
import ToggleInput from '@/components/ToggleInput.vue'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faTrashCan } from 'fontawesome/free-regular-svg-icons'
import {
    deleteNotificationSetting,
    toggleNotificationSettings,
    toggleUserNotificationSettings,
} from '@/mobile/src/api/notificationSettings'
import BackButton from '@/mobile/src/components/BackButton.vue'
import requestPushNotificationPermission from '@/mobile/src/utils/pushnotification'
import { PushNotifications } from '@capacitor/push-notifications'

@Component({
    components: {
        BackButton,
        ToggleInput,
        Scrollable,
        MenuRow,
        WebViewScreen,
        BaseButton,
        FontAwesomeIcon,
    },
})
export default class NotificationSettings extends Vue {
    @Getter
    private readonly notificationSettingsLastRefreshed!: number

    @Getter
    private readonly showAdminPushNotificationNative

    @Getter
    private readonly settings!: NotificationSetting[]

    @Action loadNotificationSettings
    @Mutation refreshFeature

    private router = useRouter()
    public faTrashCan = faTrashCan

    async mounted() {
        this.loadNotificationSettings()

        if (Capacitor.isPluginAvailable('PushNotifications')) {
            const result = await PushNotifications.checkPermissions()

            // request push notification permission
            if (
                result.receive === 'prompt' &&
                this.showAdminPushNotificationNative
            ) {
                await requestPushNotificationPermission()
            }
        }
    }

    private async reloadNotificationSettings() {
        await this.loadNotificationSettings()
        this.refreshFeature('notificationSettings')
    }

    private async onShowCreateNotificationSetting() {
        await this.router.push({ name: 'addNotificationSettings' })
    }

    private async confirmDeleteSetting(item: NotificationSetting) {
        const confirmDelete = await Dialog.confirm({
            title: 'Delete notification',
            message: `Are you sure you want to delete ${item.name}?`,
        })

        if (confirmDelete.value) {
            await this.deleteSetting(item)
        }
    }

    private async deleteSetting(item: NotificationSetting) {
        await deleteNotificationSetting(item.id)
        await this.loadNotificationSettings()
    }

    private async toggleSetting(item: NotificationSetting) {
        if (!item.id) {
            // other customize user settings.
            await toggleUserNotificationSettings(item.subject, !item.enabled)
        } else {
            // Automation notification settings.
            await toggleNotificationSettings(item.id, !item.enabled)
        }
        await this.loadNotificationSettings()
    }
}
</script>

<style lang="less" scoped>
@import '@/styles/palette.less';

.content-container {
    background: @greyLight;
    height: 100%;

    .card-content:first-of-type {
        margin-top: 15px;
    }

    .card-content {
        background-color: white;
        border-bottom: 1px solid @blueLight;
        padding: 10px 15px;
        display: flex;
        align-items: center;

        .text {
            color: @grey90;
            flex: 1;
        }

        .icons {
            display: flex;
            align-items: center;
            gap: 16px;

            .trash-icon {
                color: @red;
            }
        }
    }
}
</style>
