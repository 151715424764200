import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-009f609d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "error-message"
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorPage = _resolveComponent("ErrorPage")!
  const _component_MomentCard = _resolveComponent("MomentCard")!
  const _component_LoadingCard = _resolveComponent("LoadingCard")!
  const _component_ItemDetail = _resolveComponent("ItemDetail")!

  return (_openBlock(), _createBlock(_component_ItemDetail, {
    feedbackid: _ctx.feedbackid,
    momentid: _ctx.momentid,
    onMessageSent: _ctx.onMessageSent
  }, {
    detailContent: _withCtx(() => [
      (_ctx.errorMomentDetails)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_ErrorPage)
          ]))
        : (!_ctx.loadingMomentDetails)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(), _createBlock(_component_MomentCard, {
                key: _ctx.momentDetails.id,
                moment: _ctx.momentDetails,
                "has-count": false,
                "emoji-view": _ctx.showEmoji,
                "detailed-view": "",
                onOnReact: _ctx.onReact
              }, null, 8, ["moment", "emoji-view", "onOnReact"])),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.momentDetails.replies, (moment) => {
                return (_openBlock(), _createBlock(_component_MomentCard, {
                  key: moment.id,
                  ref_for: true,
                  ref: _ctx.momentSeenRef(moment),
                  moment: moment,
                  "has-count": false,
                  "disable-highlight": "",
                  "detailed-view": ""
                }, null, 8, ["moment"]))
              }), 128))
            ]))
          : (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.loadingCardCount, (cards) => {
              return (_openBlock(), _createBlock(_component_LoadingCard, {
                key: cards,
                borderless: true,
                variant: "single",
                class: "loading-single"
              }))
            }), 128))
    ]),
    _: 1
  }, 8, ["feedbackid", "momentid", "onMessageSent"]))
}