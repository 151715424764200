export interface INPSTrendGraph {
    labels: string[]
    hoverDate: string[]
    nps: string[]
    totalResponded: string[]
    validMonths: number
    trendGraphLabel: string
    period: string
    isNoData: boolean
    grouping?: string
    targetGoal?: string
}

export interface NPSTrendGraphNums {
    labels: string[]
    hoverDate: string[]
    nps: number[]
    totalResponded: number[]
    validMonths: number
    trendGraphLabel: string
    period: string
    isNoData: boolean
    grouping?: string
}

export enum GraphDataGrouping {
    Day = 'day',
    Month = 'month',
    Quarter = 'quarter',
}

export interface SentimentDataset {
    negative: number[]
    neutral: number[]
    positive: number[]
}

export interface SentimentTrendGraph {
    labels: string[]
    datasets: SentimentDataset
}

export interface SentimentTrendGraphCollection {
    [key: string]: SentimentTrendGraph
}

export interface INPSSummary {
    // using same name for five star, but number is between 0 - 5
    nps: number
    change: number
    total: number
    startTime?: number
    endTime?: number
    percentageSign?: string
}

export interface INPSStatsSummary {
    total: number
    promoter: number
    passive: number
    detractor: number
    sent: number
    opened: number
    responded: number
    responseRate: number
    notsent?: number
}

export interface CaseDataset {
    negative: number[]
    positive: number[]
}

export interface CaseLabels {
    negative: string
    positive: string
}

export interface CaseInfo {
    timeToClose: string
    dateFirstPart: string
    dateSecondPart: string
}

export interface CaseGraph {
    info: CaseInfo[]
    datasetLabels: CaseLabels
    datasets: CaseDataset
}

export enum MetricType {
    NPS = 'nps',
    CSAT = 'csat',
    FIVESTAR = 'fivestar',
}
