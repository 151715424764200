import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Transition as _Transition, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-51f1a22f"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "template-selector-modal"
}
const _hoisted_2 = {
  key: 0,
  class: "loading-wave"
}
const _hoisted_3 = {
  key: 1,
  class: "rows"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_Typography = _resolveComponent("Typography")!

  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      (_ctx.modalOpen)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_FontAwesomeIcon, {
              class: "close-icon",
              icon: _ctx.closeIcon,
              onClick: _ctx.closeModal
            }, null, 8, ["icon", "onClick"]),
            (_ctx.loadingTemplates)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2))
              : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.templateRows, (template) => {
                    return (_openBlock(), _createElementBlock("button", {
                      key: template.id,
                      class: "template-row",
                      onClick: () => template.onClick()
                    }, [
                      (template.icon)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            class: "icon",
                            src: template.icon,
                            alt: "Action Icon"
                          }, null, 8, _hoisted_5))
                        : _createCommentVNode("", true),
                      _createVNode(_component_Typography, {
                        variant: "overline",
                        class: "template-text"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(template.title), 1)
                        ], undefined, true),
                        _: 2
                      }, 1024)
                    ], 8, _hoisted_4))
                  }), 128))
                ]))
          ]))
        : _createCommentVNode("", true)
    ], undefined, true),
    _: 1
  }))
}