<template>
    <BaseCard class="team-member-tile" :style="tileSize" @click="onCardClick">
        <div class="team-member-tile-avatar">
            <AvatarOrInitials :user="data.user" :size="width / 2" />
        </div>
        <div class="team-member-tile-topic">
            <TopicBadge :topic="topic" :badge="badge" :font-size="10" />
        </div>
        <div class="team-member-tile-name">{{ name }}</div>
        <div class="team-member-tile-score">
            {{ data.score_current.toFixed(1) }}
            <UpDownArrow
                v-if="data.score_change !== 0"
                :positive="data.score_change > 0"
                class="change"
            />
        </div>
    </BaseCard>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import TeamMemberTile from '@/pages/insights/TeamMemberTile'

@Component({})
export default class TeamMemberTileMobile extends TeamMemberTile {
    @Prop({ type: Number, default: 150 })
    private width!: number

    @Prop({ type: Number, default: 186 })
    private height!: number

    constructor(opts) {
        super(opts, Vue)
    }

    get tileSize() {
        return 'width: ' + this.width + 'px; height: ' + this.height + 'px;'
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/variables';
@import '~@/styles/typography';
@import '~@/styles/fade';

.team-member-tile {
    text-align: center;

    .team-member-tile-avatar {
        position: relative;
        top: 6%;
    }
    .team-member-tile-topic {
        position: relative;
        top: 10%;

        div {
            margin: 0 auto;
        }
    }

    .team-member-tile-name {
        position: relative;
        top: 16%;
        font-weight: 500;
        font-size: 14px;
        color: @grey90;
        padding: 0 8px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .team-member-tile-score {
        position: relative;
        top: 16%;

        .heading();
        margin-top: 2%;

        img {
            margin-bottom: 4px;
        }
    }
}
</style>
