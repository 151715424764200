import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { getGoalTrackingStatus } from '@/api/goals'
import { IYearInGoalItem } from '@/entities/reportcard'
import Vue from 'vue'

export interface GoalTrackingState {
    goalTrackingByMonth: Record<number, IYearInGoalItem[]>
    period: number
    reward: string
    giftboxLoaded: boolean
}

export const state: GoalTrackingState = {
    goalTrackingByMonth: [],
    period: 3,
    reward: 'none',
    giftboxLoaded: false,
}

const getters: GetterTree<GoalTrackingState, any> = {
    goalTrackingData: (state) => state,
    rewardEnabled(state, getters, rootState, rootGetters) {
        if (rootGetters.mobileQuestionType === 'csat') {
            return rootGetters.$companyVars.reward_csat_enabled === 1
        }

        if (rootGetters.mobileQuestionType === 'fivestar') {
            return rootGetters.$companyVars.reward_fivestar_enabled === 1
        }

        return rootGetters.$companyVars.reward_enabled === 1
    },
    goalsEnabled(state, getters, rootState, rootGetters) {
        if (rootGetters.mobileQuestionType === 'csat') {
            return rootGetters.$companyVars.target_csat_enabled === 1
        }

        if (rootGetters.mobileQuestionType === 'fivestar') {
            return rootGetters.$companyVars.target_fivestar_enabled === 1
        }

        return rootGetters.$companyVars.target_enabled === 1
    },
    giftboxLoaded: ({ giftboxLoaded }) => giftboxLoaded,
}

const actions: ActionTree<GoalTrackingState, any> = {
    async loadGoalTrackingData({ commit, state, getters }, questionType) {
        if (!questionType) {
            return
        }

        try {
            const { data } = await getGoalTrackingStatus(questionType)
            commit('setGoalTrackingStatus', { data: data })
        } catch (error) {
            // handle error
        }
    },
}

const mutations: MutationTree<GoalTrackingState> = {
    setGoalTrackingStatus(state, { data }) {
        state.goalTrackingByMonth = data.goalTrackingMonths
        state.period = data.period
        state.reward = data.reward
    },
    updateGiftboxLoaded(state) {
        state.giftboxLoaded = true
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
