<template>
    <div class="coaching-list-item" @click="goToCoachingDetailedPage">
        <div class="avatar">
            <img
                v-if="coachingMetricType === 'trending_topic'"
                :width="44"
                :height="44"
                :src="getBadgeSchema(data.badge).img"
                :alt="getBadgeSchema(data.badge).label"
            />
            <AvatarOrInitials v-else :user="data.user" :size="44" />
        </div>
        <div class="details">
            <div v-if="title" class="header">
                <div>
                    {{ title }}
                    <span
                        v-if="coachingMetricType === 'biggest_change'"
                        class="sub-title"
                        >{{ data.score_current.toFixed(1) }}
                        {{ scoreLabel }}</span>
                </div>
                <div class="score-container">
                    <div class="score">
                        {{ score }}
                        <UpDownArrow
                            v-if="data.score_change !== 0"
                            :positive="data.score_change > 0"
                            class="change"
                        />
                    </div>
                </div>
            </div>

            <div
                v-if="coachingMetricType === 'trending_topic'"
                class="sub-header"
            >
                <div class="trending-topic-top">
                    <div>{{ mentions }} mentions</div>
                    <div>
                        {{ data.score_current.toFixed(1) }} {{ scoreLabel }}
                    </div>
                </div>
                <div>{{ responseRate }}% of all responses</div>
            </div>
            <div
                v-else-if="coachingMetricType === 'top_performer'"
                class="sub-header coaching-scorecard-container"
            >
                <div
                    v-if="highestScorecardScore.title"
                    class="coaching-scorecard-info"
                >
                    <BestWorstTopicTag
                        :positive="true"
                        :title="highestScorecardScore.title"
                    />
                    <div class="coaching-scorecard-score">
                        {{ highestScorecardScore.score_current.toFixed(1) }}
                        {{ scoreLabel }}
                    </div>
                </div>
                <div
                    v-if="
                        lowestScorecardScore.title &&
                        highestScorecardScore !== lowestScorecardScore
                    "
                    class="coaching-scorecard-info"
                >
                    <BestWorstTopicTag
                        :positive="false"
                        :title="lowestScorecardScore.title"
                    />
                    <div class="coaching-scorecard-score">
                        {{ lowestScorecardScore.score_current.toFixed(1) }}
                        {{ scoreLabel }}
                    </div>
                </div>
            </div>
            <div
                v-else-if="coachingMetricType === 'biggest_change'"
                class="sub-header coaching-scorecard-container"
            >
                <div
                    v-if="biggestScorecardChange.title"
                    class="coaching-scorecard-info"
                >
                    <BestWorstTopicTag
                        :positive="biggestScorecardChange.score_change > 0"
                        :title="biggestScorecardChange.title"
                    />
                    <div class="coaching-scorecard-score">
                        <span v-if="biggestScorecardChange.score_change > 0">+</span>
                        <span
                            v-else-if="biggestScorecardChange.score_change < 0"
                            >-</span>
                        {{
                            Math.abs(
                                biggestScorecardChange.score_change
                            ).toFixed(1)
                        }}
                        {{ scoreLabel }}
                    </div>
                    <UpDownArrow
                        :positive="biggestScorecardChange.score_change > 0"
                        class="change-small"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { CoachingInsightItem, CoachingMetricType } from '@/entities/insights'
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { useRouter } from 'vue-router'
import InsightsMetric from '@/mobile/src/components/Insights/InsightsMetric.vue'
import UpDownArrow from '@/components/UpDownArrow.vue'
import BestWorstTopicTag from '@/mobile/src/components/BestWorstTopicTag.vue'
import { badgeSchemas } from '@/entities/scorecard'
import AvatarOrInitials from '@/components/WebView/AvatarOrInitials.vue'
import { QuestionType } from '@/entities'

@Component({
    components: {
        AvatarOrInitials,
        BestWorstTopicTag,
        UpDownArrow,
        InsightsMetric,
    },
})
export default class InsightsCoachingListItem extends Vue {
    @Prop({ type: String, default: null })
    protected coachingMetricType!: CoachingMetricType

    @Prop({ type: String, default: null })
    protected title!: string

    @Prop({ type: Object, default: null })
    protected data!: CoachingInsightItem

    @Prop({ type: Number, default: null })
    private totalCount!: number

    @Prop({ type: String, default: {} })
    private questionType!: QuestionType

    private router = useRouter()

    get score() {
        if (this.coachingMetricType === 'top_performer') {
            return this.data.score_current.toFixed(1)
        } else {
            return this.data.score_change.toFixed(1)
        }
    }

    get mentions() {
        return this.data.total_count
    }

    get responseRate() {
        return ((this.data.total_count / this.totalCount) * 100).toFixed(0)
    }

    get scoreLabel() {
        if (this.questionType === 'nps') {
            return 'NPS'
        }
        return ''
    }

    get biggestScorecardChange() {
        let biggestChange!: CoachingInsightItem
        if (this.data.scorecard) {
            for (const title in this.data.scorecard) {
                if (
                    !biggestChange ||
                    Math.abs(this.data.scorecard[title].score_change) >
                        Math.abs(biggestChange.score_change)
                ) {
                    biggestChange = this.data.scorecard[title]
                    biggestChange.title = title
                }
            }
        }
        return (
            biggestChange ?? {
                title: '',
                score_change: 0,
            }
        )
    }

    get highestScorecardScore() {
        let highestScore!: CoachingInsightItem
        if (this.data.scorecard) {
            for (const title in this.data.scorecard) {
                if (
                    !highestScore ||
                    this.data.scorecard[title].score_current >
                        highestScore.score_current
                ) {
                    highestScore = this.data.scorecard[title]
                    highestScore.title = title
                }
            }
        }
        return (
            highestScore ?? {
                title: '',
                score_current: 0,
            }
        )
    }

    get lowestScorecardScore() {
        let lowestScore!: CoachingInsightItem
        if (this.data.scorecard) {
            for (const title in this.data.scorecard) {
                if (
                    !lowestScore ||
                    this.data.scorecard[title].score_current <
                        lowestScore.score_current
                ) {
                    lowestScore = this.data.scorecard[title]
                    lowestScore.title = title
                }
            }
        }
        return (
            lowestScore ?? {
                title: '',
                score_current: 0,
            }
        )
    }

    public getBadgeSchema(badgeId) {
        return badgeSchemas[badgeId] ?? null
    }

    public goToCoachingDetailedPage() {
        if (this.coachingMetricType === 'trending_topic') {
            this.router
                .push({
                    name: 'InsightsCoachingTopic',
                    params: { topic: this.title },
                })
                .catch((e) => e)
        } else {
            // we can set user.id to 0 if we can't find an actual user mapped
            // possibly if frontline_staff definition mapping in data management was done incorrectly
            if (this.data.user.id) {
                this.router
                    .push({
                        name: 'scorecardHomeUser',
                        params: {
                            teamMemberUserId: this.data.user.id.toString(),
                        },
                    })
                    .catch((e) => e)
            }
            //For Coaching Top Performer and Most Improved list, when the list is too long,
            //we scroll back up to show selected the member information
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/typography';
@import '~@/styles/rain/variables.less';
@import '~@/styles/rain/colour.less';

.coaching-list-item {
    padding: @gutterSpacing-md 0;
    display: flex;

    .avatar {
        width: 44px;
    }

    .header {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 8px;

        .sub-title {
            font-size: 12px;
            color: @grey;
            font-weight: normal;
            padding-left: 4px;
        }
    }

    .sub-header {
        font-size: 12px;
        font-weight: normal;
        color: @grey;
    }

    .details {
        margin-left: 12px;
        flex-grow: 1;

        .trending-topic-top {
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;
        }

        .coaching-scorecard-container {
            margin-top: 12px;
        }

        .coaching-scorecard-info {
            display: flex;

            .change-small {
                height: 6px;
                width: 6px;
                margin: auto auto auto 6px;
            }

            .coaching-scorecard-score {
                margin: auto 0 auto 4px;
            }
        }
    }

    .score-container {
        .score {
            display: flex;
            text-align: right;

            .change {
                height: 10px;
                width: 10px;
                margin: auto auto auto 6px;
            }
        }
    }
}
</style>
