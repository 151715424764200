<template>
    <div class="filter-bar-download">
        <a
            :class="[{ trigger: showDownloadButton }, 'dl-presentation']"
            href
            @click.prevent="toggle"
        >
            <BaseButton
                v-if="showDownloadButton"
                id="download-presentation-button"
                variant="secondary"
                >Download Presentation</BaseButton>
            <span v-else>Download Presentation</span>
        </a>

        <RainModal
            id="download-presentation-modal"
            title="Download Presentation"
            class="download-modal"
            btn-right="Download"
            :show="show"
            :allow-click-out="true"
            :footer-top-padding="false"
            @click-right="showDownloading"
            @close="close"
        >
            <slot></slot>
        </RainModal>

        <Modal :show="downloading" class="visible">
            <div class="uk-modal-header downloading-header">
                <h2>Downloading Presentation</h2>
            </div>
            <div class="downloading-message">
                Please wait for the download to complete.
            </div>
            <div class="center">
                <i class="asknicely-icon-loader_static"></i>
            </div>
        </Modal>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import Modal from '@/components/Modal.vue'
import RainModal from '@/components/Rain/Modals/Modal.vue'
import BaseButton from '@/components/Rain/Buttons/BaseButton.vue'
import { Getter } from 'vuex-facing-decorator'
import client from '@/api/client'
import { sleep } from '@/utils/async'

declare const UIkit

@Component({
    components: {
        Modal,
        RainModal,
        BaseButton,
    },
    emits: ['toggle'],
})
export default class FilterBarDownload extends Vue {
    @Getter public $companyVars
    @Prop({ type: Boolean, default: true }) public showDownloadButton!: boolean
    @Prop({ type: Function, default: () => 0 })
    public downloadFileAsync!: () => string
    @Prop({ type: String, default: 'report.pptx' }) public fileName!: string
    @Prop({ type: Boolean, default: false }) public disableDownload!: boolean
    public show = false
    public downloading = false

    public toggle() {
        this.$emit('toggle')
        this.show = !this.show
    }

    public close() {
        this.show = false
    }

    public async showDownloading() {
        if (this.disableDownload) {
            return
        }
        this.show = false
        this.downloading = true

        try {
            // initial presentation download
            const statusUrl = await this.downloadFileAsync()
            let response
            do {
                await sleep(1000)
                const statusUrlResponse = await client.get(statusUrl, {
                    baseURL: '/',
                })
                response = statusUrlResponse.data
            } while (!response)
            window.location = response
            this.downloading = false
            UIkit.notify('Your download has started!', {
                status: 'success',
                timeout: 2000,
                pos: 'top-right',
            })
            return
        } catch (error) {
            UIkit.notify(
                'Something went wrong. Try changing your filters and try again. If it still does not help, please contact the customer support team.',
                {
                    status: 'danger',
                }
            )
        }

        this.downloading = false
    }
}
</script>

<style scoped lang="less">
@import '../styles/palette';

.trigger {
    padding: 1px 0;

    img {
        position: relative;
        height: 22px;
        width: 28px;
    }
}

.dl-presentation {
    color: @blue;
}

@media (max-width: 1280px) {
    .filter-bar-download {
        display: inline-block;
    }

    .trigger {
        img {
            margin-top: 6px;
        }
    }
}

.download-modal {
    text-transform: none;
}

.modal {
    .container {
        width: 376px;
    }
    h1 {
        color: @grey90;
        font-size: 18pt;
        font-weight: 500;
        line-height: 28pt;
    }
    a.uk-button {
        background-color: @blue;
    }

    .center {
        margin: auto;
        text-align: center;
    }

    i.asknicely-icon-loader_static {
        font-size: 30px;
    }

    .modal-header {
        background-color: #f9f9f9;
        border-bottom: 1px solid #d8d8d8;
    }

    .downloading-header {
        margin: -30px -30px 0px;
    }

    .downloading-message {
        margin: 10px 10px 10px -10px;
        text-transform: none;
    }

    .visible {
        visibility: visible;
    }

    .team-scoreboard-app .breakdown .trigger img {
        position: initial;
    }

    .uk-button.disabled {
        background: #d6defc;
        border-color: #d6defc;
        cursor: default;
    }
}
</style>
