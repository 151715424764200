<template>
    <div
        :class="{
            'nps-number': true,
            'has-rank': showRank,
        }"
    >
        <div class="nps-block">
            <div class="nps">
                {{ getNpsRounded(tsNpsSummary.nps) }}
            </div>
            <div v-if="!tsNpsSummary.isFiveScore" class="change">
                <img
                    v-if="tsNpsSummary.change >= 0"
                    src="@/assets/img/Icons/leaderboard-up.svg"
                    alt=""
                />
                <img
                    v-else
                    src="@/assets/img/Icons/leaderboard-down.svg"
                    alt=""
                />
                <span class="number">{{
                    getNpsRounded(tsNpsSummary.change)
                }}</span>
            </div>
        </div>
        <div v-if="showRank" class="rank-block">
            <div class="rank-number">
                <img src="@/assets/img/Icons/trophy.svg" alt="trophy" />
                {{ tsNpsSummary.rank }}{{ getOrdinalRank(tsNpsSummary.rank) }}
            </div>
            <div class="rank-label">in {{ tsNpsSummary.rankFieldLabel }}</div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Watch, Vue } from 'vue-facing-decorator'
import { Getter } from 'vuex-facing-decorator'
import { getOrdinal } from '@/utils/number'

import Tweezer from 'tweezer.js'
import { TsNpsSummaryEntity } from '@/entities/teamscoreboard'

@Component({})
export default class TsNpsNumber extends Vue {
    @Getter public tsNpsSummary?: TsNpsSummaryEntity
    @Getter public tsFiltersHasGroup

    public lastValue = 0

    public getOrdinalRank(rank: string | number) {
        return getOrdinal(rank)
    }

    public getNpsRounded(val: number) {
        if (this.tsNpsSummary && this.tsNpsSummary.isFiveScore) {
            return val
        }

        // Round 100.0 to 100 and -100.0 to -100
        if (val === 100 || val === -100) {
            return val.toFixed(0)
        }
        return Number(val).toFixed(1)
    }

    @Watch('tsNpsSummary')
    public async onTsNpsSummaryChanged() {
        new Tweezer({
            start: this.lastValue,
            end: this.tsNpsSummary ? this.tsNpsSummary.nps : 0,
            duration: 1000,
        })
            .on('tick', (value) => {
                if (this.tsNpsSummary) {
                    this.tsNpsSummary.nps = value
                } else {
                    this.tsNpsSummary = {
                        nps: value,
                        change: 0,
                        total: 0,
                        promoter: 0,
                        passive: 0,
                        detractor: 0,
                        scorecardQuestionTotal: 0,
                    }
                }
            })
            .on('done', () => {
                if (this.tsNpsSummary) {
                    this.lastValue = this.tsNpsSummary.nps
                }
            })
            .begin()
    }

    public get showRank(): boolean {
        // If we have more than one sidebarFilter
        if (this.tsFiltersHasGroup) {
            return false
        }

        if (!this.tsNpsSummary || !this.tsNpsSummary.rank) {
            return false
        }

        return true
    }
}
</script>

<style scoped lang="less">
@import '../../styles/palette.less';
@import '../../styles/typography.less';

.nps-number {
    // I don't like this, but it prevents the change icon + number wrapping
    // at weird page widths
    white-space: nowrap;
}

.nps-number.has-rank {
    display: flex;
    flex-direction: column;

    .nps-block {
        display: flex;
        align-items: baseline;
    }

    .rank-block {
        .rank-number {
            font-weight: 500;
            font-size: 18px;

            color: @darkGreyBlue;

            img {
                // For some reason the image is a bit lower, this pushes it up
                padding-bottom: 4px;
            }
        }

        .rank-label {
            font-size: 12px;

            color: @grey40;
        }
    }
}

.nps {
    font-weight: 500;
    font-size: 42px;
    line-height: 42px;
    color: @blue;

    margin-right: 8px;
    margin-bottom: 8px;
}

.change {
    color: @fontColor;
}

.number {
    font-size: 16px;
    color: @fontColor;
    margin-left: 8px;
}
</style>
