<template>
    <transition name="modal">
        <div v-if="show" :class="['modal', { bg }]">
            <div
                class="modal-wrapper"
                :class="{ shake: shake }"
                :style="`top: ${top}%`"
                @click.self="clickOut"
            >
                <div
                    :class="[
                        size,
                        {
                            'overflow-visible': visibleIfOverflow,
                            'modal-container': !isFull,
                            flex,
                        },
                    ]"
                    :style="styles"
                >
                    <template v-if="vanilla">
                        <div class="vanilla-content">
                            <slot></slot>
                        </div>
                        <div v-if="hasClose" class="close" @click="close">
                            &times;
                        </div>
                    </template>
                    <template v-else>
                        <div
                            v-if="hasHeader && title.length > 0"
                            class="header"
                            :class="{ 'small-header': smallHeader }"
                        >
                            <div v-if="hasClose" class="close" @click="close">
                                &times;
                            </div>
                            <h2>{{ title }}</h2>
                        </div>

                        <div class="content" :style="getContentStyle">
                            <slot></slot>
                            <div
                                v-if="isLarge && !hasClose"
                                class="close"
                                @click="close"
                            >
                                &times;
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </transition>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { ModalSize } from './modal-size'
import vClickOutside from 'v-click-outside'

@Component({
    directives: {
        clickOutside: vClickOutside.directive,
    },
})
export default class Modal extends Vue {
    @Prop({ type: Boolean, default: false }) public show!: boolean
    // whether show a raw modal with no header/padding/button
    @Prop({ type: Boolean, default: false }) public vanilla!: boolean
    @Prop({ type: Number, default: 30 }) public padding!: number
    @Prop({ type: Number, default: 0 }) public borderRadius!: number
    @Prop({ type: Boolean, default: true }) public hasHeader!: boolean
    @Prop({ type: Boolean, default: false }) public flex!: boolean
    @Prop({ type: Boolean, default: false }) public centered!: boolean
    @Prop({ type: String, required: false }) public flexDirection!: boolean
    @Prop({ type: Boolean, default: true }) public hasClose!: boolean
    // when click out, close modal
    @Prop({ type: Boolean, default: true }) public allowClickOut!: boolean
    // when press escape, close modal
    @Prop({ type: Boolean, default: true }) public allowEscape!: boolean
    @Prop({ type: String, default: '' }) public title!: string
    @Prop({ type: Function, default: () => 0 }) public close!: () => void
    @Prop({ type: String, default: ModalSize.Medium }) public size!: ModalSize
    @Prop({ type: Boolean, default: true }) public bg!: boolean // if to add the bg color
    @Prop({ type: Boolean, default: false }) public smallHeader!: boolean
    @Prop({ type: Boolean, default: false }) public visibleIfOverflow!: boolean // if visible when the content overflow
    @Prop({ type: Number, default: 0 }) public top!: number
    @Prop({ type: Number, default: 0 }) public height!: number
    @Prop({ type: String, default: '' }) public width!: string
    @Prop({ type: Number, default: 0 }) public maxWidth!: number
    @Prop({ type: Boolean, default: false }) public shake!: boolean

    public mounted() {
        if (this.allowEscape) {
            document.addEventListener('keydown', (event: KeyboardEvent) => {
                if (this.show && event.key === 'Escape') {
                    this.close()
                }
            })
        }
        // TODO:
        // $on is not supported in Vue 3
        // temporarily disabling to check behaviour later
        // this.$on('close', this.close)
    }

    public get isLarge() {
        return this.size === ModalSize.Large
    }

    public get isFull() {
        return this.size === ModalSize.Full
    }

    public get styles() {
        let style = ''

        if (this.borderRadius) {
            style += `border-radius: ${this.borderRadius}px;`
        }

        if (this.height) {
            style += `height: ${this.height}px;`
        }

        if (this.width) {
            style += `width: ${this.width};`
        }

        if (this.maxWidth) {
            style += `max-width: ${this.maxWidth}px;`
        }

        if (this.flex) {
            style += `display: flex;`
        }

        if (this.flexDirection) {
            style += `flex-direction: ${this.flexDirection};`
        }

        if (this.centered) {
            style += 'transform: translateY(50%);'
        }

        return style
    }

    public get getContentStyle() {
        let style = ''
        if (this.padding) {
            style += `padding: ${this.padding}px;`
        }
        return style
    }

    public clickOut() {
        if (!this.allowClickOut) {
            return
        }

        this.close()
    }
}
</script>

<style scoped lang="less">
@import '../styles/palette.less';
@import '../styles/rain/variables.less';

.modal {
    position: fixed;
    z-index: 998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    display: table;
    transition: opacity 0.3s ease;

    &.bg {
        background-color: rgba(0, 0, 0, 0.5);
    }
}

.modal-wrapper {
    display: table-cell;
    vertical-align: top;
    position: relative;
}

.modal-container {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0 auto;
    background-color: #fff;
    border-radius: @borderRadius-md;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    overflow: hidden;

    .close {
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 16px;
        font-size: 20px;
        line-height: 10px;
        // causes issues where we have a modal within a modal in the case of chat reply template
        // z-index: 10;
        color: @greyMid;
        cursor: pointer;
    }

    .header {
        margin-top: 0;
        padding: 30px 30px 0px 30px;
        border-radius: 3px 3px 0px 0px;
        position: relative;

        h2 {
            font-weight: normal;
            padding: 0px;
            font-size: 24px;
            margin: 0px;
        }

        &.small-header {
            padding-left: 24px;
            padding-top: 24px;

            h2 {
                font-size: 16px;
                font-weight: 500;
            }

            .close {
                color: @grey90;
                font-size: 26px;
                padding-top: 18px;
            }
        }
    }

    .content,
    .vanilla-content {
        position: relative;
        height: 100%;
        overflow-y: auto;
    }

    .footer {
        padding: 30px;
    }

    // mobile modal sizes
    &.small {
        margin-top: 50px;
        max-height: calc(100vh - 100px);
        width: 74%;

        .modal-container {
            margin-top: 100px; // for nested modals
        }
    }

    &.medium {
        margin-top: 50px;
        max-height: calc(100vh - 100px);
        width: 98%;

        .modal-container {
            margin-top: 100px; // for nested modals
        }
    }

    &.large {
        margin-top: 20px;
        max-height: calc(100vh - 40px);
        width: 98%;

        .modal-container {
            margin-top: 40px; // for nested modals
        }

        .content {
            padding: 0px;
        }

        .close {
            background: #fff;
            font-size: 30px;
        }
    }

    &.full {
        border-radius: 0;
        margin: 0;
        height: 100%;
        width: 100%;

        .content {
            margin: 0;
            padding: 0;
            height: 100%;
            width: 100%;
        }
    }

    &.chat {
        margin-top: 50px;
        max-height: calc(100vh - 100px);
        width: 98%;
    }

    .flex {
        display: flex;
    }
}

.modal-enter-from {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter-from .container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.overflow-visible {
    overflow: visible;
}

// tablet modal sizes
@media (min-width: 768px) {
    .modal-container {
        &.small {
            width: 50%;
        }

        &.medium {
            width: 74%;
        }

        &.large {
            width: 86%;
        }

        &.chat {
            width: 92%;
        }
    }
}

// desktop modal sizes
@media (min-width: 992px) {
    .modal-container {
        &.small {
            width: 25%;
        }

        &.medium {
            width: 50%;
        }

        &.large {
            width: 75%;
        }

        &.chat {
            width: 92%;
        }
    }
}

// Shake animation
.shake {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}
</style>
