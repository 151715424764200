<template>
    <PortalContainer @back="$emit('close', type)">
        <div
            id="achievement-splash-container"
            class="achievement-splash-container"
        >
            <div class="card">
                <div class="close">
                    <img
                        src="@/assets/img/Icons/Grey/close_grey_bold.svg"
                        class="close-button"
                        alt="close"
                        @click="$emit('close', type)"
                    />
                </div>
                <div class="card-content">
                    <div v-if="!title" class="title-congrats">
                        <Typography variant="h3">Congratulations!</Typography>
                    </div>
                    <div class="badge">
                        <img
                            :src="imageUrl"
                            width="120"
                            :alt="type"
                            class="badge-image"
                        />
                    </div>
                    <div v-if="title" class="title-custom">
                        <Typography variant="h3">{{ title }}</Typography>
                    </div>
                    <Typography variant="body1" class="body">
                        {{ textBody }}
                    </Typography>
                </div>
            </div>
        </div>
    </PortalContainer>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import confetti from '@/utils/fancyConfetti'
import { sleep } from '@/utils/async'
import PortalContainer from '@/mobile/src/components/containers/PortalContainer.vue'
import Modal from '@/components/Modal.vue'
import Typography from '@/components/Rain/Typography/Typography.vue'
import { achievementSchemas } from '@/entities/scorecard'

@Component({
    components: { Typography, Modal, PortalContainer },
})
export default class AchievementSplash extends Vue {
    @Prop({ type: String })
    type!: string

    beforeMount() {
        if (!this.type) {
            this.$emit('close', this.type)
        }
    }

    mounted() {
        this.showConfetti()
    }

    async showConfetti() {
        await confetti.init('achievement-splash-container', 1000)
        await confetti.start(1000, 1000, 1000)
        await sleep(3000)
    }

    get title() {
        return achievementSchemas[this.type]?.title
    }

    get imageUrl() {
        return achievementSchemas[this.type]?.img
    }

    get textBody() {
        return achievementSchemas[this.type]?.description
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/variables.less';
@import '~@/styles/rain/colour.less';
@import '~@/styles/fade.less';

.achievement-splash-container {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;

    .card {
        margin: 0 @gutterSpacing-md;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
        border-radius: @borderRadius-md;
        position: relative;
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1001;
        background: white;

        .close {
            position: absolute;
            right: @gutterSpacing-lg;
            top: @gutterSpacing-lg;
        }

        .card-content {
            text-align: center;
            padding: 93px @gutterSpacing-lg 106px;

            .badge {
                margin: @gutterSpacing-lg;
            }

            .body {
                text-align: center;
                letter-spacing: @letterSpacing-xl;
                line-height: 28px;
                max-width: 295px;
            }

            .title-custom {
                margin-bottom: 16px;
            }
        }
    }
}
</style>
