import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-edacc6cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "engagement-positive-feedback" }
const _hoisted_2 = { class: "top" }
const _hoisted_3 = { class: "engagement-positive-feedback-details" }
const _hoisted_4 = { class: "header" }
const _hoisted_5 = { class: "feedback" }
const _hoisted_6 = { class: "bottom" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Lottie = _resolveComponent("Lottie")!
  const _component_ScorecardResponseTile = _resolveComponent("ScorecardResponseTile")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        (_ctx.animationData)
          ? (_openBlock(), _createBlock(_component_Lottie, {
              key: 0,
              class: "bg-img-container",
              "animation-data": _ctx.animationData
            }, null, 8, ["animation-data"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.response.badges[0].label) + "!", 1),
        _createElementVNode("div", _hoisted_5, " You’ve recently received a positive response in " + _toDisplayString(_ctx.response.badges[0].topic_name) + ". Well done! Here’s what the customer said: ", 1),
        _createElementVNode("div", null, [
          _createVNode(_component_ScorecardResponseTile, {
            response: _ctx.response,
            scorecard: 'communication',
            class: "scorecard-response"
          }, null, 8, ["response"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_ctx.seeMoreResponses)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_BaseButton, {
              primary: "",
              full: "",
              large: "",
              centred: true,
              onClick: _ctx.goToMoreResponses
            }, {
              default: _withCtx(() => [
                _createTextVNode(" See More Responses ")
              ], undefined, true),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_BaseButton, {
              full: "",
              large: "",
              variant: "ghost",
              centred: true,
              onClick: _ctx.submitAnswer
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Dismiss ")
              ], undefined, true),
              _: 1
            }, 8, ["onClick"])
          ], 64))
        : (_openBlock(), _createBlock(_component_BaseButton, {
            key: 1,
            full: "",
            onClick: _ctx.submitAnswer
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Continue ")
            ], undefined, true),
            _: 1
          }, 8, ["onClick"]))
    ])
  ]))
}