import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f0a6ada2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isHtml)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(['clamp', { 'italic-text': _ctx.textItalic }]),
        style: _normalizeStyle(_ctx.clampStyling),
        innerHTML: _ctx.htmlText
      }, null, 14, _hoisted_1))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(['clamp', { 'italic-text': _ctx.textItalic }]),
        style: _normalizeStyle(_ctx.clampStyling)
      }, _toDisplayString(_ctx.text), 7))
}