<template>
    <HierarchyGoalTimeline
        :title="title"
        :loading="loading"
        :hierarchy-items="getHierarchyGoalByLocationLevel"
        :number-per-page="7"
        :pagination="true"
        :allow-drill-down="true"
        @drillDown="drillDown"
    />
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import { Getter, namespace } from 'vuex-facing-decorator'
import HierarchyGoalTimeline from '@/mobile/src/views/reportcard/HierarchyGoalTimeline.vue'
import { UserRoleType } from '@/mobile/src/types/auth'
import { IHierarchyGoal } from '@/entities/reportcard'
import { deepClone } from '@/utils/object'
import { useRouter } from 'vue-router'

const HierarchyLevelGoalsModule = namespace('hierarchylevelgoals')
const ScoredataModule = namespace('scoredata')
const TeamFilterModule = namespace('teamFilter')
const TimeFilterModule = namespace('timeFilter')

@Component({
    components: {
        HierarchyGoalTimeline,
    },
    emits: ['refresh-finished', 'drillDown'],
})
export default class LocationGoalTimeline extends Vue {
    @Prop({ type: Boolean, default: false }) public shouldRefresh!: boolean
    @Prop({ type: String, default: '' }) public filterKey!: string

    private loading = true
    private loadingHierarchy = false
    private groupName = ''

    @HierarchyLevelGoalsModule.Getter getHierarchyGoalByLocationLevel!: Function
    @HierarchyLevelGoalsModule.Action loadHierarchyGoalByLocationLevel

    @TeamFilterModule.Getter teamFilterSelected
    @TeamFilterModule.Getter teamParentFilterSelected
    @TeamFilterModule.Getter filterOptions
    @TeamFilterModule.Getter teamFilterLoading
    @TeamFilterModule.Getter teamParentLoading
    @TeamFilterModule.Action loadFilterOptions
    @TeamFilterModule.Action addHistory

    @TimeFilterModule.Getter timeFilterSelected

    @ScoredataModule.Getter scoreType

    @Getter userRole
    @Getter userName
    @Getter userRoleType
    @Getter teamFilterAdminRight
    @Getter isFrontlineStaff
    @Getter fieldLabel

    private router = useRouter()

    private get title(): string {
        return this.fieldLabel ?? this.groupName
    }

    public async mounted() {
        if (!this.teamParentLoading) {
            await this.loadData()
        }
    }

    @Watch('shouldRefresh')
    public async forceRefresh() {
        if (this.shouldRefresh) {
            await this.loadData()
            this.$emit('refresh-finished')
        }
    }

    @Watch('filterKey')
    @Watch('teamParentLoading')
    public async filterChanged() {
        if (!this.teamParentLoading) {
            await this.loadData()
        }
    }

    public async loadData(forceRefresh = false) {
        /**
         * Refer to PR: https://github.com/asknicely/asknicelydo/pull/17750
         * for why to not pass this.scoreType to API call.
         * Assuming @Watch('scoreType') will be triggered always.
         */
        if (this.teamFilterLoading || !this.scoreType) {
            return
        }

        // loadData() can be called multiple times with a drillDown hierarchy action.
        // This is used to prevent multiple loadHierarchyGoalByLocationLevel() call fired in one click.
        if (this.loadingHierarchy) {
            return
        }

        this.loading = true
        this.loadingHierarchy = true

        // User of Head Office role type can see the whole hierarchy
        await this.loadFilterOptions(!this.teamFilterAdminRight)
        this.groupName = this.teamFilterSelected?.groupName

        if (this.teamFilterSelected?.customSelectionName) {
            await this.loadHierarchyGoalByLocationLevel({
                selectionLocationLevel: this.teamFilterSelected.fieldMapping,
                selection: this.teamFilterSelected.customSelectionName,
                dayRange: this.timeFilterSelected?.value,
                questionType: this.scoreType,
                forceRefresh: this.shouldRefresh || forceRefresh,
            })
        } else {
            let selectedLocationLevel = this.teamFilterSelected?.fieldMapping
            let selection = null
            // TeamHierarchy groupName needs to be the same as "userRole", otherwise, below won't work!
            if (
                this.teamParentFilterSelected &&
                this.userRole?.includes(this.teamFilterSelected?.groupName)
            ) {
                // If current login user is a frontline manager/staff, load only its own team members
                selectedLocationLevel =
                    this.teamParentFilterSelected?.fieldMapping
                selection = this.teamParentFilterSelected?.rankWithinName
            }

            await this.loadHierarchyGoalByLocationLevel({
                selectionLocationLevel: selectedLocationLevel,
                selection: selection,
                dayRange: this.timeFilterSelected?.value,
                questionType: this.scoreType,
                forceRefresh: this.shouldRefresh || forceRefresh,
            })
        }

        this.loading = false
        this.loadingHierarchy = false
    }

    // find the child of the current hierarchy level ie: country_c -> state_c
    findChildInHierarchy(currentHierarchyLevel) {
        const currentIndex = this.filterOptions.findIndex(
            (item) => item.fieldMapping == currentHierarchyLevel
        )
        const childFilter = this.filterOptions[currentIndex + 1]
        // if we are at the second to last level, we don't want to drill down any further
        if (currentIndex + 1 === this.filterOptions.length) {
            return null
        }
        return childFilter
    }

    async drillDown(hierarchyItem: IHierarchyGoal): Promise<void> {
        if (!hierarchyItem.allowDrilldown) {
            return
        }

        this.loading = true

        const activeFilter = this.filterOptions.find(
            (item) => item.fieldMapping == this.teamFilterSelected.fieldMapping
        )

        // Get the parent item of the current selected, drilldown item for Back button histories.
        const parentFilter = this.teamFilterSelected
            ? JSON.parse(JSON.stringify(this.teamFilterSelected))
            : {}

        const newFilter = activeFilter
            ? JSON.parse(JSON.stringify(activeFilter))
            : {}
        // This is used to filter the next score-data within parent scope
        newFilter.rankWithinName =
            this.teamFilterSelected?.customSelectionName ?? ''
        newFilter.fieldMapping = hierarchyItem.childLevel
        newFilter.customSelectionName = hierarchyItem.name

        // View bottom line user's overview/report card.
        if (!this.findChildInHierarchy(hierarchyItem.childLevel)) {
            // Not allow frontline staff to view team member's overview/report card page.
            if (this.isFrontlineStaff) {
                this.loading = false
                return
            }

            let filter = {
                column: hierarchyItem.childLevel,
                operator: 'in',
                value: [hierarchyItem.name],
                fromDrillDown: true,
            }

            const filterJSON = encodeURIComponent(JSON.stringify(filter))

            let historyOption = { ...newFilter }
            // If login user is Frontline Manager, and only view its team list: TRUE
            // If login user is Admin with Frontline Manager type, no (locked/scorecard) filter setup: FALSE.
            if (
                this.userRoleType === UserRoleType.FrontlineManager &&
                this.filterOptions.length > 1 &&
                this.userRole?.includes(this.filterOptions[0]?.groupName)
            ) {
                historyOption = deepClone(parentFilter)
                historyOption.customSelectionName = this.userName
            }
            this.addHistory({
                filterOption: historyOption,
            })

            await this.router.replace('/overview-user/' + filterJSON)
            this.loading = false
            return
        }

        // continue drill down on the hierarchy
        this.$emit('drillDown', newFilter, false, parentFilter)

        await this.loadData()
    }

    @Watch('scoreType')
    async onMetricChange() {
        await this.loadData(true)
    }
}
</script>

<style lang="less"></style>
