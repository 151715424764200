import { ajaxClient as client } from '@/api/client'

export async function getWorkflowsList() {
    return await client.get('/workflows')
}

export async function getWorkflowsListByType(type: string) {
    const {
        data: { data },
    } = await client.get('/workflows', {
        params: {
            type,
        },
    })

    return data
}

export async function getActionsList() {
    return await client.get('/workflows/actions')
}

export async function enableWorkflow(workflowId: number, enabled: boolean) {
    return await client.post(`/workflows/${workflowId}/enable`, null, {
        params: {
            enabled,
        },
    })
}

export async function deleteWorkflow(workflowId: number) {
    return await client.post(`/workflows/${workflowId}/delete`)
}

export async function duplicateWorkflow(workflowId: number) {
    return await client.post(`/workflows/${workflowId}/duplicate`)
}

export async function addTagsToWorkflow(workflowId: number, tags: string[]) {
    return await client.post(`/workflows/${workflowId}/tags`, { tags: tags })
}

export async function updateWorkflowListOrder(workflowSequence: number[]) {
    const formData = new FormData()
    formData.append('items', JSON.stringify(workflowSequence))
    return await client.post('/workflows/order', formData)
}

export async function removeTagsToWorkflow(workflowId: number, tags: string[]) {
    return await client.delete(`/workflows/${workflowId}/tags`, {
        data: { tags: tags },
    })
}
