import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "intersectionalContainer" }
const _hoisted_2 = {
  ref: "uptrigger",
  class: "trigger"
}
const _hoisted_3 = {
  ref: "midtrigger",
  class: "trigger"
}
const _hoisted_4 = {
  ref: "lowtrigger",
  class: "trigger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, null, 512),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default")
    ], 512),
    _createElementVNode("div", _hoisted_4, null, 512)
  ]))
}