import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bd334a78"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AvatarOrInitials = _resolveComponent("AvatarOrInitials")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    "small-header": "",
    padding: 20,
    size: _ctx.modalSize.Small,
    show: true,
    title: _ctx.agreedUsersModalTitle,
    close: _ctx.closeModal,
    "allow-click-out": true
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.usersAgreed, (user, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "agreed-user-row",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
        }, [
          _createVNode(_component_AvatarOrInitials, {
            user: user,
            size: 32
          }, null, 8, ["user"]),
          _createElementVNode("p", _hoisted_1, _toDisplayString(user.name), 1)
        ]))
      }), 128))
    ], undefined, true),
    _: 1
  }, 8, ["size", "title", "close"]))
}