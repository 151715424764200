<template>
    <div class="controls">
        <Dropdown right>
            <template #trigger>
                <BaseButton secondary small muted>
                    <b>{{ active ? active.label : placeholder }}</b>
                </BaseButton>
            </template>

            <template #content>
                <div class="options">
                    <div
                        v-for="(option, index) in options"
                        :key="index"
                        class="option"
                        @click="() => onChange(option.value)"
                    >
                        {{ option.label }}
                    </div>
                </div>
            </template>
        </Dropdown>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import Dropdown from './Dropdown.vue'
import BaseButton from './BaseButton.vue'
import { Option } from '@/entities'

type Value = string | number | object

@Component({
    components: { Dropdown, BaseButton },
})
export default class Controls extends Vue {
    @Prop({ type: String, default: '' }) public placeholder!: string
    @Prop({ type: [String, Number, Object], default: '' }) public value!: Value
    @Prop({ type: Array }) public options!: Value[]
    @Prop({ type: Function }) public onChange!: (value: Value) => any

    get active(): Option<string> | false {
        if (this.options) {
            const options = this.options as Array<Option<string>>
            for (const opt of options) {
                if (opt.value === this.value) {
                    return opt
                }
            }
        }

        return false
    }
}
</script>

<style scoped lang="less">
@import '../styles/layout';
@import '../styles/palette';

.trigger {
    b {
        padding: 0px;
        margin: 0px;
        font-size: 14px;
    }
}

.options {
    padding: 10px 0px;
    background: #fff;
    border-radius: 3px;
    font-size: 14px;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    position: relative;
}
.option {
    padding: 5px 15px;
    cursor: pointer;
    b {
        float: right;
    }
}
.option:hover {
    background: @backgroundColor;
    color: @ctaColor;
}
</style>
