<template>
    <transition :name="$isMobile ? slideName : 'no-transit'">
        <div :class="['scorecard-subpage', { 'for-web': !$isMobile }]">
            <div class="container">
                <div
                    v-if="showHeader"
                    class="header"
                    :class="{
                        bgWhite: title,
                        hasBgImg: !!animationData,
                        borderBottom: title,
                        sticky,
                    }"
                >
                    <div v-if="showBack" class="back" @click="goBack">
                        <svg
                            width="8"
                            height="14"
                            viewBox="0 0 8 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M2.39966 6.99636L7.66805 1.70469C8.05769 1.31302 8.05769 0.67969 7.66805 0.29219C7.27842 -0.0994771 6.64836 -0.0953104 6.25873 0.29219L0.289815 6.28802C-0.0873869 6.66719 -0.0956771 7.27552 0.2608 7.66719L6.25458 13.7047C6.4494 13.9005 6.7064 13.9964 6.95925 13.9964C7.21209 13.9964 7.46909 13.9005 7.66391 13.7047C8.05355 13.313 8.05355 12.6797 7.66391 12.2922L2.39966 6.99636Z"
                                :fill="isBlackArrow ? `black` : `white`"
                            />
                        </svg>
                    </div>
                    <div v-else class="back" />
                    <div v-if="title" class="title" v-html="title" />
                    <div v-if="buttonRight" class="done" @click="goBack">
                        {{ buttonRight }}
                    </div>
                    <div v-else class="done" />
                </div>

                <div class="body" :style="`background: ${bgColor}`">
                    <Lottie
                        v-if="animationData"
                        class="bg-img-container"
                        :animation-data="animationData"
                    />
                    <slot />
                </div>
            </div>
        </div>
    </transition>
</template>

<script lang="ts">
import { Vue3Lottie as Lottie } from 'vue3-lottie'
import { useRoute, useRouter } from 'vue-router'
import { Component, Prop, Watch, Vue } from 'vue-facing-decorator'
import { Action, Getter } from 'vuex-facing-decorator'
import { sleep } from '@/utils/async'

@Component({
    components: { Lottie },
})
export default class ScorecardSubpage extends Vue {
    public animationData?: any = null

    @Prop({ type: String, default: '' }) public badgeId!: string
    @Prop({ type: Boolean, default: false }) public sticky!: string
    @Prop({ type: Boolean, default: true }) public showHeader!: boolean
    @Prop({ type: Boolean, default: true }) public showBack!: boolean
    @Prop({ type: String, default: '' }) public buttonRight!: string
    @Prop({ type: String, default: '' }) public title!: string
    @Prop({ type: String, default: '#F4F6FC' }) public bgColor!: string
    @Prop({ type: String, default: 'left' }) public slide!: string

    @Action public setSlide

    @Getter
    $isMobile

    private routerStack: Array<any> = []

    public slideName = `slide-${this.slide}`

    private route = useRoute()
    private router = useRouter()

    public async mounted() {
        if (this.badgeId) {
            this.animationData = (
                await import(
                    `@/assets/img/scorecard/animated/${this.badgeId}.json`
                )
            ).default
        }
    }

    @Watch('slide')
    public updateSlide() {
        this.slideName = `slide-${this.slide}`
    }

    get historyStack() {
        //Router will always have history, but it's set as private for some reason.
        //Retyping as any gets around the linting error.
        //Slice up to the current page.
        const currentIndex = this.routerStack.findIndex(
            (r) => r.path === this.route.path
        )
        return this.routerStack.slice(0, currentIndex).reverse()
    }

    public async goBack() {
        // set back page slide right in
        this.setSlide('right') // new page slide right in
        await sleep(10)
        this.slideName = 'slide-left' // we slide left out

        if (this.$isMobile) {
            this.router.back()
        } else {
            const lastRoute = this.historyStack.shift()
            await this.router.push({
                name: lastRoute.name,
                params: lastRoute.params,
            })
        }
    }

    public get isBlackArrow() {
        if (this.badgeId === 'unknown-badge') {
            return true
        }
        return !this.animationData
    }
}
</script>

<style scoped lang="less">
@import '~@/styles/layout';
@import '~@/styles/palette';
@import '~@/pages/scorecard/slide.less';

.scorecard-subpage {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    &.for-web {
        position: relative;
        width: auto;
        height: auto;
    }

    .container {
        position: relative;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        // To avoid x-scrollbar Notice Composer page. When the @ popup displays, it will go out of the container.
        overflow-x: hidden;

        .header {
            display: flex;
            align-items: center;

            &.sticky {
                position: sticky;
                position: -webkit-sticky;
                z-index: 11;
                top: 0;
            }

            &.bgWhite {
                background: white;
            }

            &.hasBgImg {
                position: absolute;
                top: 0;
                z-index: 2;
            }

            &.borderBottom {
                border-bottom: 1px solid #e2e2e3;
            }

            .back {
                cursor: pointer;
                padding: 15px;
                width: 25px;

                svg {
                    min-width: 8px;
                }
            }

            .title {
                flex: 5;
                text-align: center; // same as app header title
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                color: #181818;
            }

            .done {
                cursor: pointer;
                color: @blue;
                font-weight: bold;
                font-size: 16px;
            }

            .back,
            .done {
                flex: 1 0 0;
            }
        }

        .body {
            flex: 1;
            display: flex;
            flex-direction: column;

            .bg-img-container {
                position: relative;
                max-height: 25vh;
                overflow: hidden;
                display: flex;
                align-items: center;
            }
        }
    }
}
</style>
