<template>
    <SimpleSpinner size="small" class="spinner" line-fg-color="transparent" />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { Capacitor } from '@capacitor/core'
import { PushNotifications } from '@capacitor/push-notifications'
import Input from '@/components/Input.vue'
import { getCsrfToken } from '@/mobile/src/api/auth'
import TransparentButton from '@/mobile/src/components/TransparentButton.vue'
import { parseDeepLinkUrl, processMagicLink } from '@/mobile/src/services/auth'
import { Action, Getter } from 'vuex-facing-decorator'
import SimpleSpinner from '@/components/SimpleSpinner.vue'
import { unRegisterPushDevice } from '@/mobile/src/api/auth'
import { useRouter } from 'vue-router'

@Component({
    components: {
        TransparentButton,
        Input,
        SimpleSpinner,
    },
})
export default class MagicLinkLogin extends Vue {
    @Prop({ type: String, default: '' }) url!: string

    @Action public setUserProp
    @Action public logoutUser
    @Action public dispatchStoreLogin

    @Getter pushNotificationToken

    private router = useRouter()

    // the url could contain some paramters (like `disablePendo=1`)
    setLoginOptions(url: string) {
        const { query } = parseDeepLinkUrl(this.url)
        if (query && query.disablePendo) {
            // Used when initialize Pendo
            this.setUserProp({ disablePendo: true })
        }
    }

    async mounted() {
        if (!this.url || !parseDeepLinkUrl(this.url)) {
            alert('Invalid link')
            await this.router.replace({ name: 'scorecardHome' })
            return
        }

        // spin and open activation
        const credentials = await processMagicLink(this.url)
        const { domain } = parseDeepLinkUrl(this.url)
        if (credentials.mfa) {
            await this.router.replace({
                name: 'mfa-login',
                query: {
                    domain: domain,
                    invitationToken: credentials.invitationToken,
                },
            })
        } else if (credentials.auth_token) {
            await unRegisterPushDevice(this.pushNotificationToken)
            await this.logoutUser()

            this.setLoginOptions(this.url)
            // update store
            this.setUserProp({
                domain,
                email: credentials.email,
                name: credentials.name,
                accesstoken: credentials.auth_token,
            })
            const result = await getCsrfToken()
            this.setUserProp({
                csrftoken: result.data,
            })
            await this.dispatchStoreLogin()
            // register device for push notifications
            if (Capacitor.isPluginAvailable('PushNotifications')) {
                PushNotifications.register()
            }
            await this.router.replace({ name: 'home' })
        } else {
            alert(`Sorry, we couldn't log you in this time`)
            await this.router.replace({ name: 'home' })
        }
    }
}
</script>
<style lang="less" scoped>
@import '@/styles/palette';

.spinner {
    margin: 0 auto;
}
</style>
