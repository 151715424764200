<template>
    <SimpleList
        class="notice-type-rows"
        :rows="rows"
        :has-left-gutter="!!$isMobile"
        :has-last-divider="hasLastDivider"
    />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { PredefinedNoticeTypes } from '@/utils/notices'
import SimpleList from '@/components/SimpleList.vue'
import NoticeTypeRow from './NoticeTypeRow.vue'

@Component({
    components: {
        SimpleList,
    },
    emits: ['select'],
})
export default class NoticeTypePicker extends Vue {
    @Prop({ type: String }) public selected?: string

    @Prop({ type: Boolean, required: false, default: true })
    public hasLastDivider?: boolean

    protected get rows() {
        return PredefinedNoticeTypes.filter((notice) => notice.isComposing).map(
            (noticeType) => this.noticeTypeRow(noticeType)
        )
    }

    private noticeTypeRow(noticeType) {
        return {
            id: noticeType.name,
            onClick: () => {
                this.$emit('select', noticeType.name)
            },
            rowComponent: {
                component: NoticeTypeRow,
                props: {
                    noticeType,
                    selected: this.selected === noticeType.name,
                },
            },
        }
    }
}
</script>
