<template>
    <div class="container">
        <div class="content">
            <slot />
        </div>
        <div
            ref="tabsContainer"
            :class="['tabs', { solid: route.name === 'huddles' }]"
        >
            <ViewAsNavBar />
            <MobileNavigationTab
                v-for="tab in tabs"
                :key="tab.text"
                class="tab-option"
                :tab="tab"
                :selected="selected === tab.name || tab.name === routeGroup"
                @click="onClickTab"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { namespace, Action, Getter } from 'vuex-facing-decorator'
import useEmitter from '@/composables/useEmitter'
import { useRoute } from 'vue-router'

import { TabData } from '@/mobile/src/types/tab'
import MobileNavigationTab from '@/mobile/src/components/appV4/MobileNavigationTab.vue'
import { scrollToTop } from '@/mobile/src/utils/scrollbus'
import { pendoTrackEvent } from '@/utils/pendo'
import Typography from '@/components/Rain/Typography/Typography.vue'
import ViewAsNavBar from '@/mobile/src/components/containers/TabbedContainer/ViewAsNavBar.vue'

const MobileNavigationModule = namespace('mobilenavigation')
const NotificationsModule = namespace('notifications')

@Component({
    components: {
        ViewAsNavBar,
        MobileNavigationTab,
        Typography,
    },
    emits: ['click'],
})
export default class MobileNavigation extends Vue {
    private route = useRoute()
    private emitter = useEmitter()

    @MobileNavigationModule.Action setTabsHeight

    @NotificationsModule.Action setNotificationPanelOpen
    @NotificationsModule.Getter notificationPanelOpen

    @Action resetScrollPositions

    @Prop({ type: Array, default: [] }) public tabs!: TabData[]
    @Prop({ type: String, default: '' }) public selected!: string

    mounted() {
        this.updateBottomTabsHeight()
    }

    updated() {
        this.updateBottomTabsHeight()
    }

    updateBottomTabsHeight() {
        const height =
            (this.$refs?.tabsContainer as HTMLElement | undefined)
                ?.clientHeight ?? 0
        this.setTabsHeight(height)
    }

    get routeGroup() {
        return this?.route?.meta?.group
    }

    private onClickTab(tab: string) {
        if (tab === this.route.name) {
            if (tab === 'overview' && this.notificationPanelOpen) {
                // Close the notifications panel if clicking on the home tab while it is open
                pendoTrackEvent(
                    'mobile-notifications-closed-with-home-tab-press'
                )
                this.setNotificationPanelOpen(false)
            }

            return // ignore when clicking on same tab twice
        }
        this.resetScrollPositions()
        scrollToTop(this.emitter, 'auto')
        this.$emit('click', tab)
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/variables.less';

.container {
    display: flex;
    flex-flow: column;
    height: 100%;
    box-sizing: border-box;

    .content {
        display: flex;
        flex-flow: column;
        flex: 1 1 auto;
        position: relative; // so the absolute positioned child won't go over us
        height: 100%; // in safari so child components know it's taking up 100%
    }

    .tabs {
        align-items: center;
        flex: 0 1;
        display: flex;
        min-height: @mobileNavHeight;
        background-color: rgba(
            @offBlack,
            0.8
        ); // Black with 0.4 opacity background
        position: absolute;
        bottom: 0;
        width: 100%;
        padding-bottom: calc(
            env(safe-area-inset-bottom) - 12px
        ); // For iPhones above X with gesture bar
        backdrop-filter: blur(4px);
        box-sizing: initial;

        .tab-option {
            flex: 1;
        }

        &.solid {
            background-color: @offBlack;
        }
    }
}
</style>
