<template>
    <div class="things-to-work-on">
        <template v-for="badge in badgesToDisplay">
            <RouterLink
                v-if="badge.count > 0"
                :key="badge.badgeId"
                :to="{
                    name: routeName,
                    params: { value: badge.badgeId, teamMemberUserId },
                }"
                class="topic-row topic-link"
            >
                <TopicRowContent
                    :label="badge.label || badge.value"
                    :total-responses="badge.count"
                    :unseen-responses="badge.countUnseen"
                />
                <FontAwesomeIcon
                    class="right-chevron-icon"
                    :icon="faChevronRight"
                />
            </RouterLink>
            <div v-else :key="badge.badgeId" class="topic-row">
                <TopicRowContent
                    :label="badge.label || badge.value"
                    :total-responses="badge.count"
                    :unseen-responses="badge.countUnseen"
                />
                <FontAwesomeIcon
                    class="right-chevron-icon"
                    :icon="faChevronRight"
                />
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import TopicRowContent from '@/components/Scorecard/TopicRowContent.vue'
import { ScorecardEntity } from '@/entities/scorecard'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faChevronRight } from 'fontawesome/free-solid-svg-icons'
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { Getter } from 'vuex-facing-decorator'

@Component({
    components: {
        FontAwesomeIcon,
        TopicRowContent,
    },
})
export default class ThingsToWorkOn extends Vue {
    @Prop({ type: String, required: false })
    readonly teamMemberUserId?: string

    @Getter
    readonly scorecard?: ScorecardEntity

    get routeName() {
        return this.teamMemberUserId
            ? 'scorecardRoomToImproveDetailUser'
            : 'scorecardRoomToImproveDetail'
    }

    get badges() {
        return this.scorecard?.badges.negative
    }

    get badgesToDisplay() {
        if (!this.badges) {
            return []
        }

        return Object.entries(this.badges).map(([key, badge]) => {
            return {
                ...badge,
                count: Number(badge.count),
            }
        })
    }

    faChevronRight = faChevronRight
}
</script>

<style lang="less" scoped>
@import '~@/styles/palette';

.topic-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
        border-bottom: 1px solid @greyLight;
        padding-bottom: 15px;
    }

    &:not(:first-child) {
        padding-top: 16px;
    }
}

.topic-link {
    text-decoration: none;
}

.right-chevron-icon {
    color: @grey40;
}
</style>
