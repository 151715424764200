/**
 * Creates a Pendo track event with properties
 * @param trackEventName Name for the track event
 */
export function pendoTrackEvent(
    trackEventName: string,
    ...optionalProperties: Record<string, any>[]
) {
    const combinedProperties = Object.assign({}, ...optionalProperties)
    if (window && window.pendo) {
        window.pendo.track(trackEventName, {
            URL: window.location.href,
            ...combinedProperties,
        })
    }
}
