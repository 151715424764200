import { ActionTree, MutationTree, GetterTree } from 'vuex'

import { emptyPlaybook, IPlaybook } from '@/entities/playbook'
import {
    savePlaybook,
    loadPlaybooks,
    savePlaybookNotice,
    deletePlaybookNotice,
    deletePlaybook,
    loadPlaybookScorecardOptions,
} from '@/api/playbook'
import { IScorecardSetting } from '@/pages/appstore/components/Scorecard/scorecard-settings-entity'

export interface PlaybookState {
    playbooks: IPlaybook[]
    scorecardOptions: IScorecardSetting[]
}

export const state: PlaybookState = {
    playbooks: [],
    scorecardOptions: [],
}

const getters: GetterTree<PlaybookState, any> = {
    playbooks({ playbooks }) {
        return playbooks
    },
    getPlaybook({ playbooks }) {
        return (id: number) => {
            const found = playbooks.find((playbook) => playbook.id === id)
            return found ? found : emptyPlaybook()
        }
    },
    scorecardOptions({ scorecardOptions }) {
        return scorecardOptions
    },
}

const actions: ActionTree<PlaybookState, any> = {
    async savePlaybook({ commit }, playbook) {
        // with playbook.id => create
        // without playbook.id => update
        try {
            const { data } = await savePlaybook(playbook)
            commit('updateOrAppendPlaybook', data)
            return data
        } catch {
            return null
        }
    },

    async deletePlaybookNotice({ commit }, { id, playbook_id }) {
        try {
            const { data } = await deletePlaybookNotice(playbook_id, id)
            commit('updateOrAppendPlaybook', data)
            return data
        } catch {
            return null
        }
    },

    async deletePlaybook({ commit }, playbookId) {
        try {
            const { data } = await deletePlaybook(playbookId)
            commit('deletePlaybook', playbookId)
            return data
        } catch {
            return null
        }
    },

    async savePlaybookNotice({ commit }, { playbookId, playbookNotice }) {
        try {
            const { data } = await savePlaybookNotice(
                playbookId,
                playbookNotice
            )
            commit('updateOrAppendPlaybook', data)
            return data
        } catch {
            return null
        }
    },

    async loadPlaybooks({ commit }) {
        const { data } = await loadPlaybooks()
        commit('setPlaybooks', data)
    },

    async loadScorecardOptions({ commit }) {
        const { data } = await loadPlaybookScorecardOptions()
        commit('setPlaybookScorecardOptions', data)
    },
}

const mutations: MutationTree<PlaybookState> = {
    setPlaybookScorecardOptions(state, scorecardOptions) {
        state.scorecardOptions = scorecardOptions
    },

    setPlaybooks(state, playbooks) {
        state.playbooks = playbooks
    },

    updateOrAppendPlaybook(state, playbook) {
        const newPlaybooks: IPlaybook[] = []
        // replace original one if have the same id
        let replace = false
        for (const p of state.playbooks) {
            if (p.id === playbook.id) {
                replace = true
                newPlaybooks.push(playbook)
            } else {
                newPlaybooks.push(p)
            }
        }
        // insert as the first one if not found
        if (!replace) {
            newPlaybooks.unshift(playbook)
        }
        // assign
        state.playbooks = newPlaybooks
    },

    deletePlaybook(state, playbookId) {
        state.playbooks = state.playbooks.filter(
            (playbook) => playbook.id !== playbookId
        )
    },
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations,
}
