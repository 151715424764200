import client from '@/api/client'
import {
    MomentDetailResponse,
    MomentMessageRecipient,
    MomentsPollResponse,
    PollUnseenMomentCountResponse,
    SendMomentRequest,
    UploadImageResponse,
} from '@/entities/moment'
import { MomentResponse } from '@/entities/moment'

export async function loadAvailableRecipients() {
    return client.get<MomentMessageRecipient[]>(`/notices/recipients`)
}

export async function getMoments(offset: number, timestamp?: number) {
    const params = {
        offset: offset,
        timestamp: timestamp,
    }
    return client.get<MomentResponse>(`/moments`, {
        params,
    })
}

export async function pollMoments(timestamp: number) {
    const params = {
        timestamp: timestamp,
    }
    return client.get<MomentsPollResponse>(`/moments/poll`, {
        params,
    })
}

export async function sendMoment(request: SendMomentRequest) {
    return client.post(`/moments/send`, request)
}

export async function pollUnseenMomentCount() {
    return client.get<PollUnseenMomentCountResponse>(
        `/moments/unseen-count/poll`
    )
}

export async function getMomentDetail(id: number) {
    return client.get<MomentDetailResponse>(`/moments/${id}`)
}

export async function uploadImage(file) {
    const form = new FormData()
    form.append('file', file)

    return await client
        .post<UploadImageResponse>(`/moments/upload-image`, form)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            return { success: false, error: error, fileName: '' }
        })
}
