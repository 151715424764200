<template>
    <div :class="['mask-container', { 'make-relative': makeRelative }]">
        <slot v-if="!!$slots.default" />
        <div
            v-else
            class="button"
            data-test-tag="open-modal"
            @click="handleClick"
        >
            <img v-if="icon" class="icon" :src="icon" width="14" height="14" />
            <span>{{ title }}</span>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component({
    emits: ['click'],
})
export default class ScreenBottomButton extends Vue {
    @Prop({ type: String }) public title!: string
    @Prop({ type: String }) public icon?: string
    @Prop({ type: Boolean, default: false })
    public makeRelative?: boolean

    public handleClick() {
        this.$emit('click')
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/layout.less';
@import '~@/styles/palette.less';

.mask-container {
    align-items: center;
    background-image: linear-gradient(
        0deg,
        #f4f6fc 0%,
        rgba(244, 246, 252, 0) 100%
    );
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    height: 144px;
    z-index: 10;

    &.make-relative {
        position: relative;
    }

    > * {
        pointer-events: initial;
    }

    .icon {
        width: 14px;
        height: 14px;
        margin-right: 6px;
    }

    .button {
        background-color: @blue;
        color: @white;
        display: inline-block;
        line-height: 1.143;
        padding: 12px 24px;
        border-radius: 4px;
        text-transform: uppercase;
        filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
        top: 32px;
        position: relative;
        font-size: 14px;
    }
}
</style>
