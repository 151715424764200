import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/img/light-gray-right-arrow.svg'


const _withScopeId = n => (_pushScopeId("data-v-014c0640"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 3,
  class: "right-arrow",
  src: _imports_0,
  alt: "Right arrow"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['row-wrapper', { gutter: _ctx.hasLeftGutter, mobile: !!_ctx.$isMobile }])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row) => {
      return (_openBlock(), _createElementBlock("div", {
        key: row.id,
        class: _normalizeClass(['row', { noDivider: !_ctx.hasLastDivider }]),
        onClick: ($event: any) => (_ctx.clickRow(row))
      }, [
        (row.icon)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "icon",
              src: row.icon,
              alt: "Row icon"
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true),
        (row.title)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "title",
              innerHTML: row.title
            }, null, 8, _hoisted_3))
          : _createCommentVNode("", true),
        (row.rowComponent)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(row.rowComponent.component), _mergeProps({
              key: 2,
              ref_for: true
            }, row.rowComponent.props, { class: "row-content" }), null, 16))
          : _createCommentVNode("", true),
        (row.displayRightArrow)
          ? (_openBlock(), _createElementBlock("img", _hoisted_4))
          : _createCommentVNode("", true)
      ], 10, _hoisted_1))
    }), 128))
  ], 2))
}