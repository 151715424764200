<template>
    <WebViewScreen no-pad>
        <template #left-button>
            <a v-if="onRoot" class="header-button" @click="close">
                <img
                    src="../../../components/WebView/icons/Close.svg"
                    alt="Close"
                />
            </a>
            <a v-else class="header-button" @click="goBack">
                <img
                    src="../../../components/WebView/icons/BackLeftAction.svg"
                    alt="Back"
                />
            </a>
        </template>

        <template #custom-title>
            <div v-if="composable.loading.value">...</div>
            <div v-else class="custom-title-header">
                <CaseStatusRow
                    v-if="onCase"
                    class="case-status-header"
                    :question-id="chatQuestionId"
                    :chat-custom-data="chatCustomData"
                    :case-status="composable.caseStatus.value"
                />

                <div v-if="onCustomData" class="custom-title-center">
                    Custom Data
                    <span class="light-text">{{
                        chatCustomFields.length
                    }}</span>
                </div>
                <div v-if="onTemplate" class="custom-title-center">
                    Reply Template
                </div>
                <div v-if="onWorkflow" class="custom-title-center">
                    Workflows
                </div>
                <div
                    v-if="
                        !onCase && !onCustomData && !onTemplate && !onWorkflow
                    "
                    class="nav-header-chat"
                >
                    <!-- left circle -->
                    <div class="circle" :class="composable.circleClass.value">
                        <div class="score">{{ chatCustomData.answer }}</div>
                    </div>

                    <!-- right name, email -->
                    <div class="info">
                        <div class="flex-container">
                            <div class="name">
                                {{ chatCustomData.person_name }}
                            </div>
                            <div class="email">
                                {{ chatCustomData.person_email }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template #right-button>
            <RouterLink
                v-if="shouldDisplayAction"
                class="header-button"
                :to="{
                    name: 'chatResponsesActions',
                    params: { id: chatQuestionId },
                }"
            >
                <img
                    src="../../../components/WebView/icons/More.svg"
                    alt="Actions"
                />
                <div
                    v-if="composable.chatForwardingLocked.value"
                    class="locked-icon"
                />
            </RouterLink>
        </template>
        <slot />
    </WebViewScreen>
</template>

<script lang="ts">
import { Component, Setup, Vue } from 'vue-facing-decorator'
import { useRoute, useRouter } from 'vue-router'
import { Getter } from 'vuex-facing-decorator'
import CaseStatusRow from './CaseStatusRow.vue'
import Loading from '@/components/Loading.vue'
import WebViewScreen from '@/components/WebView/WebViewScreen.vue'
import { embedded, postMessageToRNWebView } from '@/utils/react-native-webview'
import { chatResponseScreenComposable } from '@/pages/chat/responses/chatResponseScreenComposable'

@Component({
    components: {
        CaseStatusRow,
        Loading,
        WebViewScreen,
    },
})
export default class ChatResponseScreen extends Vue {
    public route = useRoute()
    public router = useRouter()

    @Setup(() => chatResponseScreenComposable())
    public composable

    @Getter public chatQuestionId
    @Getter public chatCustomData
    @Getter public chatCustomFields

    public get shouldDisplayAction(): boolean {
        return /\d+$/gi.test(this.route.path)
    }

    public get onCase(): boolean {
        return /\/actions\/case/gi.test(this.route.path)
    }

    public get onCustomData(): boolean {
        return /\/actions\/customdata/gi.test(this.route.path)
    }

    public get onTemplate(): boolean {
        return /\/actions\/template/gi.test(this.route.path)
    }

    public get onWorkflow(): boolean {
        return /\/actions\/workflow/gi.test(this.route.path)
    }

    public get onRoot(): boolean {
        return /^\/[\d]+$/gi.test(this.route.path)
    }

    public close() {
        if (embedded && postMessageToRNWebView) {
            postMessageToRNWebView({
                action: 'back', // tell react native to go back
            })
        }
    }

    public async goBack() {
        await this.router.back()
    }
}
</script>

<style scoped lang="less">
@import '../../../styles/palette';
@import '../../../styles/layout';

.custom-title-header {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .custom-title-center {
        text-align: center;
        margin: auto;
        color: #2f384c;
        font-size: 17px;
        font-weight: 800;
    }

    .light-text {
        font-size: 500;
        color: #93a1be;
    }

    .circle {
        height: 32px;
        width: 32px;
        border-radius: 32px;
        border: 2.67px solid @greyLight4;
        display: flex;
        align-items: center;
        text-align: center;
        min-width: 32px;

        &.success {
            border-color: @green;
            background-color: rgba(30, 226, 137, 0.08);
            color: @green;
        }

        &.warning {
            background-color: rgba(187, 187, 187, 0.08);
            border-color: @greyMid2;
            color: @greyMid2;
        }

        &.danger {
            background-color: rgba(244, 77, 61, 0.08);
            border-color: @red;
            color: @red;
        }

        .score {
            font-size: 16px;
            font-weight: bold;
            flex: 1;
        }
    }

    .nav-header-chat {
        display: flex;
        min-width: 0;

        .info {
            display: flex;
            align-items: center;
            padding: 0 8px;
            text-align: left;
            max-width: 80%;

            .name {
                color: @darkGreyBlue;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 1px;
                text-transform: uppercase;
                margin: 0;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            .email {
                color: @greyMid;
                font-size: 12px;
                line-height: 14px;
                margin: 0;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                text-transform: none;
            }
        }
    }
}

.locked-icon {
    background: @purple url('../../../components/WebView/icons/Lock.svg')
        no-repeat center;
    width: 14px;
    height: 14px;
    display: inline-block;
    border-radius: 50%;
    position: relative;
    top: -3px;
    left: -2px;
}

.header-button {
    padding: 6px;
}
</style>
