import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-756be62b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "coaching-metrics"
}
const _hoisted_2 = {
  key: 1,
  class: "insights-no-data"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TwoLineHeader = _resolveComponent("TwoLineHeader")!
  const _component_InsightsTimePickerMobile = _resolveComponent("InsightsTimePickerMobile")!
  const _component_InsightsCoachingTiles = _resolveComponent("InsightsCoachingTiles")!
  const _component_InsightsCoachingList = _resolveComponent("InsightsCoachingList")!
  const _component_GradientHighlightedCard = _resolveComponent("GradientHighlightedCard")!
  const _component_Scrollable = _resolveComponent("Scrollable")!
  const _component_WebViewScreen = _resolveComponent("WebViewScreen")!

  return (_openBlock(), _createBlock(_component_WebViewScreen, {
    "no-pad": "",
    class: "insights",
    "show-go-back": "",
    "disable-when-offline": ""
  }, {
    "custom-title": _withCtx(() => [
      _createVNode(_component_TwoLineHeader, {
        title: "Coaching",
        subtitle: _ctx.friendlyTimeRange
      }, null, 8, ["subtitle"])
    ]),
    "right-button": _withCtx(() => [
      _createVNode(_component_InsightsTimePickerMobile, {
        "time-range": _ctx.timeRange,
        onSelectTime: _ctx.updateTimeRange
      }, null, 8, ["time-range", "onSelectTime"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Scrollable, {
        class: "coaching-metrics-container",
        loading: _ctx.loading,
        "pull-down": _ctx.onPullDown
      }, {
        default: _withCtx(() => [
          (_ctx.hasCoachingData)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_InsightsCoachingTiles, {
                  "coaching-metric-type": _ctx.selectedCoachingMetric,
                  onClick: _ctx.changeCoachingMetricType
                }, null, 8, ["coaching-metric-type", "onClick"]),
                (!_ctx.loading)
                  ? (_openBlock(), _createBlock(_component_GradientHighlightedCard, {
                      key: 0,
                      class: "insights-coaching-list"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_InsightsCoachingList, {
                          "coaching-metric-type": _ctx.selectedCoachingMetric,
                          "question-type": _ctx.insightsCoaching.question_type
                        }, null, 8, ["coaching-metric-type", "question-type"])
                      ], undefined, true),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]))
            : (_ctx.insightsCoaching)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, " No data to show, please try another Time Filter period. "))
              : _createCommentVNode("", true)
        ], undefined, true),
        _: 1
      }, 8, ["loading", "pull-down"])
    ], undefined, true),
    _: 1
  }))
}