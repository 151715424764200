import { ajaxClient as client } from '@/api/client'
import {
    Suggestion,
    SuggestionView,
    SuggestionNew,
    SuggestionComment,
    SuggestionCommentNew,
    SuggestionListQuery,
    SuggestionRelations,
    SuggestionCommentRelations,
} from '@/entities/suggestions'

export async function createSuggestion(suggestion: SuggestionNew) {
    return await client.post<Suggestion>(`/suggestion`, suggestion)
}

export async function removeSuggestion(suggestionId: number) {
    return await client.delete(`/suggestion/${suggestionId}`)
}

export async function getSuggestion(id: number) {
    return await client.get<SuggestionRelations>(`/ajax/suggestion/${id}`, {
        baseURL: '/',
    })
}

export async function getSuggestionList({ mode, offset }: SuggestionListQuery) {
    return await client.get<SuggestionRelations>(`/suggestion/list/${mode}`, {
        params: { offset },
    })
}

export async function getSuggestionComments(suggestionId: number) {
    return await client.get<SuggestionCommentRelations>(
        `/suggestion/${suggestionId}/comment`
    )
}

export async function createSuggestionComment(comment: SuggestionCommentNew) {
    return await client.post<SuggestionComment>(
        `/suggestion/${comment.suggestion_id}/comment`,
        comment
    )
}

export async function createSuggestionVote(suggestionId: number) {
    return await client.post(`/suggestion/${suggestionId}/vote`)
}

export async function removeSuggestionVote(suggestionId: number) {
    return await client.delete(`/suggestion/${suggestionId}/vote`)
}

export async function getSuggestionMentionUsers() {
    return await client.get(`/suggestion/recipients`)
}
