<template>
    <EmptyPageState colour="white" :class="[{ bordered }]">
        <template #icon-img>
            <FontAwesomeIcon :icon="dataIcon" class="data-icon" />
        </template>
        <template #header-text>No <MultiMetricSelect /> {{ headerText }}</template>
        <template #body-text>
            {{ bodyText }}
        </template>
    </EmptyPageState>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faDatabase } from 'fontawesome/free-solid-svg-icons'
import EmptyPageState from '@/mobile/src/components/EmptyPageState.vue'
import MultiMetricSelect from '@/mobile/src/components/appV4/MultiMetricSelect.vue'

@Component({
    components: {
        MultiMetricSelect,
        EmptyPageState,
        FontAwesomeIcon,
    },
})
export default class EmptyPageHome extends Vue {
    @Prop({ type: Boolean, default: false }) public bordered?: boolean
    @Prop({ type: Boolean, default: false }) public topicCarousel?: boolean
    @Prop({ type: String, required: false }) public message

    private dataIcon = faDatabase

    get headerText() {
        if (this.message === 'No scorecard linked') {
            return 'scorecard linked'
        } else if (this.topicCarousel) {
            return 'responses yet'
        } else {
            return 'data available'
        }
    }
    get bodyText() {
        if (this.message === 'No scorecard linked') {
            return 'Looks like the scorecard is not configured properly for this account. Please contact the admin to set this up.'
        } else if (this.topicCarousel) {
            return "We don't have enough data attached to your current scorecard yet to show you anything here"
        } else {
            return "It looks like we haven't received any feedback data yet. Please check back later."
        }
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';

.bordered {
    border: 1px dashed @white50;
    padding: @featureGutter;
    border-radius: @borderRadius-md;
}
.data-icon {
    color: white;
    font-size: 48px;
    padding-bottom: 18px;
}
</style>
