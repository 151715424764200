<template>
    <div class="ts-counts">
        <template v-if="showBg">
            <div v-if="countPositive > 0" class="ts-nps-bar-bg-container">
                <div class="ts-nps-bar-container ts-nps-bar-bg">
                    <span
                        class="ts-nps-bar positive absolute"
                        :style="{ width: countWidthPositive }"
                    />
                </div>
                <span class="bar-count-right">{{ countPositive }}</span>
            </div>
            <div v-if="countNegative > 0" class="ts-nps-bar-bg-container">
                <div class="ts-nps-bar-container ts-nps-bar-bg">
                    <span
                        class="ts-nps-bar negative absolute"
                        :style="{ width: countWidthNegative }"
                    />
                </div>
                <span class="bar-count-right">{{ countNegative }}</span>
            </div>
        </template>
        <template v-else>
            <div
                v-if="countPositive > 0"
                :style="{ width: countWidthPositive }"
                class="ts-nps-bar-container"
            >
                <span class="ts-nps-bar positive" />
                <span class="bar-count"><FormattedNumber :number="countPositive" />
                </span>
            </div>
            <div
                v-if="countNegative > 0"
                :style="{ width: countWidthNegative }"
                class="ts-nps-bar-container"
            >
                <span class="ts-nps-bar negative" />
                <span class="bar-count"><FormattedNumber :number="countNegative" />
                </span>
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import FormattedNumber from '@/components/FormattedNumber.vue'

@Component({
    components: { FormattedNumber },
})
export default class TsNpsCounts extends Vue {
    @Prop({ type: Number, required: true }) public countPositive!: number
    @Prop({ type: Number, required: true }) public countNegative!: number
    @Prop({ type: Number, required: true }) public maxResponded!: number
    @Prop({ type: Boolean, default: false }) public showBg!: number

    public get countWidthPositive() {
        return (this.countPositive / this.maxResponded) * 90 + '%'
    }
    public get countWidthNegative() {
        return (this.countNegative / this.maxResponded) * 90 + '%'
    }
}
</script>

<style lang="less" scoped>
@import '../../styles/palette.less';

div.ts-counts {
    width: 100%;
    white-space: nowrap;
}

.ts-nps-bar {
    height: 6px;
    border-radius: 2px;
    display: inline-block;
    width: 100%;

    &.absolute {
        position: absolute;
    }
}

.positive {
    background: #d5deff;
}

.negative {
    background: #fcc3a8;
}

.bar-count {
    padding-left: 5px;
}

.bar-count-right {
    width: 50px;
    max-width: 50px;
    text-align: right;
    font-weight: bold;
    font-size: 12px;
}

.ts-nps-bar-bg-container {
    display: flex;
    align-items: center;
}

.ts-nps-bar-bg {
    background-color: @greyLight;
    border-radius: 2px;
    height: 6px;
    flex: 1;
    position: relative;
    overflow: hidden;
}
</style>
