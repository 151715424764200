<template>
    <div class="check-in-history-container">
        <BasicSelect
            :value="selectedTheme"
            :options="allThemes"
            label="CHECK INS"
            @input="selectedTheme = $event"
        />
        <div class="questions">
            <div
                v-for="engagementQuestion in filteredEngagementQuestions"
                :key="engagementQuestion.answer_id"
                class="question"
            >
                <div class="date">
                    <Typography variant="caption">{{
                        formatDate(engagementQuestion.date)
                    }}</Typography>
                </div>
                <div class="question-text">
                    <MessageBubble direction="left">
                        <Typography variant="body2">{{
                            engagementQuestion.question
                        }}</Typography>
                    </MessageBubble>
                </div>
                <div class="answer">
                    <MessageBubble
                        direction="right"
                        :bg-color="getBackgroundColor(engagementQuestion)"
                    >
                        <div
                            v-if="
                                engagementQuestion.answer &&
                                isFiveStarType(engagementQuestion.question_type)
                            "
                            class="answer-icons"
                        >
                            <div
                                v-if="engagementQuestion.variation === 'faces'"
                            >
                                <FontAwesomeIcon
                                    :icon="
                                        variationFiveStarIcon(
                                            engagementQuestion.answer
                                        )
                                    "
                                    class="font-awesome purple-icon"
                                />
                            </div>
                            <div
                                v-for="(i, idx) in getIconKeys(
                                    engagementQuestion
                                )"
                                v-else
                                :key="idx"
                            >
                                <FontAwesomeIcon
                                    :key="i"
                                    :icon="fontAwesomeSources['faStar']"
                                    class="font-awesome purple-icon"
                                />
                            </div>
                        </div>
                        <div class="answer-text">
                            <Typography variant="body2">{{
                                getAnswerText(engagementQuestion)
                            }}</Typography>
                        </div>
                    </MessageBubble>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import {
    EngagementQuestion,
    EngagementQuestionTheme,
    EngagementQuestionType,
} from '@/entities/engagement'
import Typography from '@/components/Rain/Typography/Typography.vue'
import {
    faStar,
    faTired,
    faFrown,
    faMeh,
    faSmile,
    faGrinBeam,
} from 'fontawesome/free-solid-svg-icons'
import Controls from '@/components/Controls.vue'
import MessageBubble from '@/mobile/src/components/engagement/TeamSpotlight/MessageBubble.vue'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import moment from 'moment'
import { getFaceIconForValue } from '@/mobile/src/utils/engagement'
import BasicSelect from '@/mobile/src/components/BasicSelect.vue'
import { palette } from '@/utils/palette'

const fontAwesomeSources = {
    faStar,
    faTired,
    faFrown,
    faMeh,
    faSmile,
    faGrinBeam,
}

@Component({
    components: {
        BasicSelect,
        MessageBubble,
        Controls,
        Typography,
        FontAwesomeIcon,
    },
})
export default class EngagementCheckInHistory extends Vue {
    public fontAwesomeSources = fontAwesomeSources
    selectedTheme = 'ALL'

    @Prop({ required: true, default: [] })
    engagementQuestions!: EngagementQuestion[]

    @Prop({ required: false, default: 'ALL', type: String })
    preSelectedTheme

    mounted() {
        this.selectedTheme = this.preSelectedTheme
    }

    get filteredEngagementQuestions() {
        if (this.selectedTheme !== 'ALL') {
            return this.engagementQuestions.filter(
                (question) =>
                    question.theme ===
                    EngagementQuestionTheme[this.selectedTheme]
            )
        }
        return this.engagementQuestions
    }

    get allThemes() {
        return [
            { label: 'ALL', value: 'ALL' },
            ...Object.keys(EngagementQuestionTheme).map((key) => ({
                value: key,
                label: key,
            })),
        ]
    }

    getIconKeys(engagementQuestion) {
        return [...Array(Number(engagementQuestion.answer)).keys()]
    }

    isFiveStarType(type) {
        return type === EngagementQuestionType.FiveStar
    }

    getAnswerText(engagementQuestion: EngagementQuestion) {
        const { answer, comment, question_type } = engagementQuestion

        if (!answer) {
            return 'Skipped'
        }
        switch (question_type) {
            case EngagementQuestionType.FiveStar:
            case EngagementQuestionType.Shoutout:
                return comment
            default: {
                const parts = [answer]
                if (comment) {
                    parts.push(comment)
                }

                return parts.join(', ')
            }
        }
    }

    getBackgroundColor(engagementQuestion: EngagementQuestion) {
        return engagementQuestion.answer
            ? palette.brandPurple10
            : palette.disabledPrimaryGrey
    }

    formatDate(dateString) {
        const date = moment(dateString, 'YYYY-MM-DD')

        return date.format('dddd, DD MMM YYYY')
    }

    variationFiveStarIcon(value) {
        return this.fontAwesomeSources[getFaceIconForValue(parseInt(value))]
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';

.check-in-history-container {
    padding: @gutterSpacing-md;

    .filter {
        display: flex;
        align-items: center;

        .filter-label {
            color: @grey;
            margin-right: @gutterSpacing-base;
        }

        select {
            border: none;
            background: transparent;
            color: @primaryBlue;
            -webkit-appearance: none;
            -moz-appearance: none;
            text-indent: 1px;
            text-overflow: '';
            outline: none;
            position: relative;
            top: -1px;
        }

        select::-ms-expand {
            display: none;
        }
    }

    .controls {
        display: inline-block;
    }

    .questions {
        .question {
            .date {
                text-align: center;
                margin-top: @gutterSpacing-md;
                color: @darkGrey;
            }

            .question-text {
                margin-top: @gutterSpacing-base;
            }
        }

        .answer-icons {
            display: flex;
            flex-direction: row-reverse;

            .purple-icon {
                color: @brandPurple;
            }
        }

        .answer {
            display: flex;
            flex-direction: row-reverse;
            margin-top: @gutterSpacing-base;
        }
    }
}
</style>
