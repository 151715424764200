import { App } from './app'

export interface Learning {
    id: number | string
    title: string
    content: string // only for blog
    feature_on_appstore_homepage: boolean // 0 or 1
    url: string
    image_hero_url: string
    image: string
    type: LearningType
    display: boolean
    published_at: string
    status: string
    linked_appstore?: [App]
}

export enum LearningType {
    blog = 'blog',
    book = 'book',
    video = 'video',
    link = 'link',
}

export const InitLearning: Learning = {
    id: 0,
    title: '',
    content: '',
    feature_on_appstore_homepage: false,
    url: '',
    image_hero_url: '',
    image: '',
    type: LearningType.blog,
    display: true,
    published_at: '',
    status: 'published',
}
