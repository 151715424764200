<template>
    <div @click.stop="filterLostFocus()">
        <div class="header-spacer">
            <Typography component="span" class="title-text">
                {{ title }}
            </Typography>
        </div>
        <div class="header-container">
            <div class="header-top">
                <Typography
                    component="span"
                    class="back flex-1"
                    @click="goBack"
                >
                    <slot name="back" />
                </Typography>
                <Transition name="title" mode="out-in">
                    <Typography
                        :key="title"
                        component="span"
                        class="title-text flex-2"
                    >
                        {{ title }}
                    </Typography>
                </Transition>
                <Typography
                    component="span"
                    class="action flex-1"
                    @click="goAction"
                >
                    <slot name="action" />
                </Typography>
            </div>
            <Transition name="slide-down">
                <div v-show="showDropdown">
                    <slot name="dropdown" />
                </div>
            </Transition>
        </div>
    </div>
</template>

<script lang="ts">
import { nextTick } from 'vue'
import { Component, Prop, Watch, Vue } from 'vue-facing-decorator'
import { scrollActiveFiltersIntoView } from '@/mobile/src/utils/misc'
import Typography from '@/components/Rain/Typography/Typography.vue'
import useEmitter from '@/composables/useEmitter'
import { toggleHeaderDropdownEvent } from '@/mobile/src/utils/headerbus'
import { namespace } from 'vuex-facing-decorator'

const MobileNavigationModule = namespace('mobilenavigation')

@Component({
    components: {
        Typography,
    },
})
export default class PageHeader extends Vue {
    @Prop({
        type: Function,
        default: () => {
            return
        },
    })
    public readonly goBack!: Function
    @Prop({
        type: Function,
        default: () => {
            return
        },
    })
    public readonly goAction!: Function

    public emitter = useEmitter()

    @MobileNavigationModule.Mutation setHaxIosDisableContentScrolling
    @MobileNavigationModule.Getter containerHasFocus
    @MobileNavigationModule.Action setContainerHasFocus

    public showDropdown = false

    mounted() {
        this.emitter?.on(toggleHeaderDropdownEvent, this.onToggleDropdown)
    }

    destroyed() {
        this.emitter?.off(toggleHeaderDropdownEvent, this.onToggleDropdown)
        this.setHaxIosDisableContentScrolling(false)
    }

    get title() {
        return this.$route?.meta?.headerTitle ?? ''
    }

    onToggleDropdown() {
        this.showDropdown = !this.showDropdown
        this.setHaxIosDisableContentScrolling(this.showDropdown)

        if (this.showDropdown) {
            nextTick(() => scrollActiveFiltersIntoView())
        }
    }

    @Watch('containerHasFocus')
    public collapseFilter() {
        if (this.containerHasFocus) {
            this.showDropdown = false
        }
    }

    public filterLostFocus() {
        this.setContainerHasFocus(false)
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/palette.less';
@import '~@/styles/rain/variables.less';
@import '~@/mobile/src/styles/zindex.less';

.header-spacer {
    position: relative;
    padding: 20px;
    visibility: hidden;
}

.title-text {
    align-items: center;
    color: @white;
    font-size: @fontSize-md;
    font-weight: @fontWeight-medium;
    letter-spacing: @letterSpacing-base;
    line-height: @lineHeight-md;
    text-align: center;
    text-transform: capitalize;
}

.header-container {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(15px);
    padding-top: env(safe-area-inset-top);

    display: flex;
    flex: auto;
    flex-direction: column;

    position: fixed;
    top: 0;
    left: 0;

    height: auto;
    width: 100vw;
    z-index: @filterHeaderIndex;

    .header-top {
        display: flex;
        flex-direction: row;
        flex-grow: 0;
        justify-content: space-between;
        padding: @featureGutter;

        .back,
        .action {
            color: @white;
        }

        .back {
            text-align: left;
        }

        .action {
            text-align: right;
            display: inline;
            margin-top: -1px;
        }

        .title-enter-active,
        .title-leave-active {
            transition: all 0.45s ease-out;
        }
        .title-enter-from {
            opacity: 0;
            transform: translateY(30px);
        }
        .title-leave-to {
            opacity: 0;
            transform: translateY(30px);
        }
    }

    .divider {
        margin: 0;
        width: 100%;
    }

    .flex-2 {
        flex: 2;
    }

    .flex-1 {
        flex: 1;
    }

    .slide-down-enter-active,
    .slide-down-leave-active {
        max-height: 1000px;
        overflow-y: hidden;
        transition: all 0.3s;
    }
    .slide-down-enter-from,
    .slide-down-leave-to {
        max-height: 0;
        opacity: 0;
    }
}
</style>
