<template>
    <div class="card-container" :class="{ active }">
        <PortalTarget name="over-card-destination" @change="handleUpdate" />
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { h } from 'vue'
import { PortalTarget } from 'portal-vue'

@Component({
    components: {
        PortalTarget,
    },
})
export default class OverCardPortalWrapper extends Vue {
    public active = false

    //Refer to https://v2.portal-vue.linusb.org/api/portal-target.html#transition
    public transition = {
        functional: true,
        render(context) {
            return h(
                'transition',
                { props: { name: 'slide-fade', mode: 'out-in' } },
                context.children
            )
        },
    }

    public handleUpdate({ hasContent }) {
        this.active = hasContent
    }
}
</script>

<style lang="less" scoped>
.card-container {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 1;

    &.active {
        pointer-events: all;
    }
}

.slide-fade-enter-from {
    transform: translateY(100vh);
}

.slide-fade-enter-active {
    animation: slide 0.5s backwards;
    transition: 0.5s;
}

.slide-fade-leave-active {
    animation: slide 0.5s forwards;
    transition: 0.5s;
}

.slide-fade-leave-to {
    transform: translateY(100vh);
}
</style>
