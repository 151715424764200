import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TwoLineHeader = _resolveComponent("TwoLineHeader")!
  const _component_InsightsTimePickerMobile = _resolveComponent("InsightsTimePickerMobile")!
  const _component_InsightsEngagementTiles = _resolveComponent("InsightsEngagementTiles")!
  const _component_InsightsEngagementTrendingList = _resolveComponent("InsightsEngagementTrendingList")!
  const _component_InsightsEngagementSpotlightList = _resolveComponent("InsightsEngagementSpotlightList")!
  const _component_GradientHighlightedCard = _resolveComponent("GradientHighlightedCard")!
  const _component_Scrollable = _resolveComponent("Scrollable")!
  const _component_WebViewScreen = _resolveComponent("WebViewScreen")!

  return (_ctx.insightsEngagement)
    ? (_openBlock(), _createBlock(_component_WebViewScreen, {
        key: 0,
        "no-pad": "",
        class: "insights",
        "show-go-back": "",
        "disable-when-offline": ""
      }, {
        "custom-title": _withCtx(() => [
          _createVNode(_component_TwoLineHeader, {
            title: "Engagement",
            subtitle: _ctx.friendlyTimeRange
          }, null, 8, ["subtitle"])
        ]),
        "right-button": _withCtx(() => [
          _createVNode(_component_InsightsTimePickerMobile, {
            "time-range": _ctx.timeRange,
            onSelectTime: _ctx.updateTimeRange
          }, null, 8, ["time-range", "onSelectTime"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_Scrollable, {
            class: "coaching-metrics-container",
            loading: _ctx.loading,
            "pull-down": _ctx.onPullDown
          }, {
            default: _withCtx(() => [
              _createVNode(_component_InsightsEngagementTiles),
              (
                    _ctx.selectedEngagementMetric === 'trending_themes' && !_ctx.loading
                )
                ? (_openBlock(), _createBlock(_component_InsightsEngagementTrendingList, {
                    key: 0,
                    class: "insights-engagement-list"
                  }))
                : (
                    _ctx.selectedEngagementMetric === 'team_spotlight' && !_ctx.loading
                )
                  ? (_openBlock(), _createBlock(_component_GradientHighlightedCard, {
                      key: 1,
                      class: "insights-engagement-list"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_InsightsEngagementSpotlightList, {
                          "spotlight-team": _ctx.insightsEngagement.data.team
                        }, null, 8, ["spotlight-team"])
                      ], undefined, true),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
            ], undefined, true),
            _: 1
          }, 8, ["loading", "pull-down"])
        ], undefined, true),
        _: 1
      }))
    : _createCommentVNode("", true)
}