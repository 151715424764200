import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1e21ff22"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "filter-bar" }
const _hoisted_2 = { class: "filter-mob-view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterBarTime = _resolveComponent("FilterBarTime")!
  const _component_FilterBarNPSType = _resolveComponent("FilterBarNPSType")!
  const _component_FilterBarQuestionType = _resolveComponent("FilterBarQuestionType")!
  const _component_FilterBarLoader = _resolveComponent("FilterBarLoader")!
  const _component_FilterBarActive = _resolveComponent("FilterBarActive")!
  const _component_FilterBarSecondary = _resolveComponent("FilterBarSecondary")!
  const _component_GeneralNPSReportDownload = _resolveComponent("GeneralNPSReportDownload")!
  const _component_FilterBarTVMenu = _resolveComponent("FilterBarTVMenu")!
  const _component_FilterAndDigestEditor = _resolveComponent("FilterAndDigestEditor")!
  const _component_Toasts = _resolveComponent("Toasts")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_FilterBarTime),
        _createVNode(_component_FilterBarNPSType, { permissions: _ctx.permissions }, null, 8, ["permissions"]),
        (_ctx.enabledLeadWithAnyQuestion && !_ctx.questionType)
          ? (_openBlock(), _createBlock(_component_FilterBarQuestionType, { key: 0 }))
          : _createCommentVNode("", true),
        (_ctx.showButtons && !_ctx.isInMobile)
          ? (_openBlock(), _createBlock(_component_FilterBarLoader, { key: 1 }))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "additionalFilter", {}, undefined, true)
      ]),
      _createVNode(_component_FilterBarActive),
      _createVNode(_component_FilterBarSecondary),
      (_ctx.enabledGeneralReporting)
        ? (_openBlock(), _createBlock(_component_GeneralNPSReportDownload, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.showTV && !_ctx.forceHideTv && _ctx.showButtons)
        ? (_openBlock(), _createBlock(_component_FilterBarTVMenu, { key: 1 }))
        : _createCommentVNode("", true),
      _createVNode(_component_FilterAndDigestEditor, { "user-roles": _ctx.userRoles }, null, 8, ["user-roles"]),
      _createVNode(_component_Toasts, {
        toasts: _ctx.statusToasts,
        onRemoveToast: _cache[0] || (_cache[0] = ($event: any) => (_ctx.statusRemoveToast($event)))
      }, null, 8, ["toasts"])
    ])
  ]))
}