import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.showCompactLayout)
      ? _renderSlot(_ctx.$slots, "compact", { key: 0 })
      : _createCommentVNode("", true),
    (_ctx.showExpandedLayout)
      ? _renderSlot(_ctx.$slots, "expanded", { key: 1 })
      : _createCommentVNode("", true)
  ]))
}