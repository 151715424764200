import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3aa977de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "avatar" }
const _hoisted_2 = {
  key: 0,
  class: "desktop-subtitle"
}
const _hoisted_3 = {
  key: 0,
  class: "footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ 'engagement-team-members-list': _ctx.onSidebar })
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([
                'list-item',
                {
                    selected: _ctx.selected,
                    wide: _ctx.wideLayout,
                    narrow: !_ctx.wideLayout,
                    mobile: _ctx.$isMobile,
                },
            ])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "icon", {}, undefined, true)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(['details', { desktop: _ctx.wideLayout }])
      }, [
        _renderSlot(_ctx.$slots, "title", {}, undefined, true),
        (!_ctx.wideLayout)
          ? _renderSlot(_ctx.$slots, "subtitle", { key: 0 }, undefined, true)
          : _createCommentVNode("", true)
      ], 2),
      (_ctx.wideLayout)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "subtitle", {}, undefined, true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(['number', { 'desktop-number': _ctx.wideLayout }])
      }, [
        _renderSlot(_ctx.$slots, "score", {}, undefined, true)
      ], 2),
      (_ctx.showChevron)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(['desktop-chevron', { selected: _ctx.selected, wide: _ctx.wideLayout }])
          }, [
            _createVNode(_component_FontAwesomeIcon, {
              class: "right-chevron-icon",
              icon: _ctx.faChevronRight
            }, null, 8, ["icon"])
          ], 2))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.showFooter)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}