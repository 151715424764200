<template>
    <div class="message-container" :class="{ seen: isSeen }">
        <div class="message-body">
            <div class="left-container">
                <AvatarOrInitials :user="userForAvatar" :size="68" />
            </div>
            <div class="right-container">
                <div class="heading">
                    <div class="brief-details">
                        <div class="name">
                            {{ message.sender.name }}
                        </div>
                    </div>
                </div>

                <div class="details">
                    <Quote
                        v-if="message.questioncomment"
                        class="quote-comment"
                        :text="message.questioncomment"
                    />
                    <TextWithHighlight
                        class="subject"
                        :height="56"
                        :text="messageText"
                        :highlights="highlights"
                    />
                    <div class="description">
                        <NoticeTypeBadge :notice-type="message.type" />
                        <span class="date">{{ time }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="mailbox === 'scheduled'" class="footer scheduled">
            <span class="scheduled-icon">🕒</span>
            <div class="scheduled-operations">
                <span class="scheduled">Send scheduled for
                    {{ message.friendly_scheduled_at }}</span>
                <div class="btn-cancel" @click.stop="toggleCancel">Delete</div>
            </div>
        </div>
        <div v-else-if="mailbox === 'sent'" class="footer response-data">
            <div>{{ message.recipient_count }} recipients</div>
            <div class="reactions">
                <template v-if="emojiCount">
                    <div>
                        {{ emojiCount }}
                        <span class="emojis">{{ emojiReactions }}</span>
                    </div>
                </template>
                <div v-if="feedbackCount">
                    {{ feedbackCount }}
                    <img
                        src="@/components/WebView/icons/ChatBubbleIcon.svg"
                        alt="ChatBubbleIcon"
                    />
                </div>
            </div>
        </div>
        <AlertDialog
            :message="`You are about to cancel this notice`"
            :show="showCancelConfirmation"
            ok-title="Continue"
            :on-cancel="toggleCancel"
            :on-ok="doCancel"
        />
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { timeSince } from '@/utils/time'
import { Action } from 'vuex-facing-decorator'
import { IMessage, IRecipient } from '@/entities/notices'
import AvatarOrInitials from '@/components/WebView/AvatarOrInitials.vue'
import { UserDetail } from '@/entities'
import TextWithHighlight from '@/components/TextWithHighlight.vue'
import AlertDialog from '@/components/AlertDialog.vue'
import NoticeTypeBadge from './NoticeTypeBadge.vue'
import { ALL_NOTICE_REACTION_TYPES } from '@/entities/notices'
import Quote from '@/components/Notices/Quote.vue'

@Component({
    components: {
        AlertDialog,
        AvatarOrInitials,
        TextWithHighlight,
        NoticeTypeBadge,
        Quote,
    },
})
export default class NoticeRow extends Vue {
    @Prop({ type: Object }) private message!: IMessage
    @Prop({ type: String }) private mailbox!: 'sent' | 'received' | 'scheduled'
    @Prop({ type: Array }) private recipients!: IRecipient[] // all possible users

    @Action private loadMessagesReceived
    @Action private deleteMessage

    private showCancelConfirmation = false

    private get time() {
        return timeSince(this.message.created)
    }

    public get userForAvatar(): UserDetail | null {
        return this.message.sender || null
    }

    private get messageText() {
        return this.message.message
    }

    private get isSeen() {
        if (this.mailbox !== 'received') {
            return false
        }
        // handle `unseen` status only in `received` tab
        return this.mailbox === 'received' && this.message.seen
    }

    private get shouldHighlightMention() {
        if (this.message.type === 'report') {
            return false
        }
        return true
    }

    public get highlights() {
        if (!this.shouldHighlightMention) {
            return []
        }
        return this.recipients.map((user) => `@${user.name}`)
    }

    private toggleCancel() {
        this.showCancelConfirmation = !this.showCancelConfirmation
    }

    private doCancel() {
        this.deleteMessage(this.message.id)
        this.toggleCancel()
    }

    private get emojiCount() {
        let emojiCount = 0
        for (let reactionKey in ALL_NOTICE_REACTION_TYPES) {
            emojiCount += this.message.actions[reactionKey]
        }
        return emojiCount
    }

    private get feedbackCount() {
        return this.message.actions['feedback']
    }

    private get emojiReactions() {
        let reactions = ''
        for (let actionsKey in this.message.actions) {
            if (
                this.message.actions[actionsKey] &&
                ALL_NOTICE_REACTION_TYPES[actionsKey] !== undefined
            ) {
                reactions += ALL_NOTICE_REACTION_TYPES[actionsKey] + ' '
            }
        }

        return reactions
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/layout.less';
@import '~@/styles/palette.less';
@import '~@/pages/scorecard/slide.less';

.message-container {
    border-radius: 8px;
    background: white;
    padding: 12px;
    margin-bottom: 12px;

    &.seen {
        opacity: 0.5;
    }

    .footer {
        color: @grey40;
        padding-top: 12px;
        margin-top: 16px;
        border-top: 1px solid @blueLight;

        .shrug,
        .thumb-up {
            margin-right: 15px;
            width: 40px;
            text-align: right;
            display: inline-block;
        }

        .feedback {
            width: 90px;
            text-align: right;
            display: inline-block;
        }

        &.scheduled {
            display: flex;
            align-items: baseline;

            .scheduled-icon {
                width: 32px;
                padding-top: 2px;
            }

            .scheduled-operations {
                align-items: center;
                display: flex;
            }

            .scheduled {
                margin-left: 4px;
                flex: 1;
            }

            .btn-cancel {
                font-weight: 500;
                font-size: 12px;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                color: @grey90;
                padding: 6px 8px;
                cursor: pointer;
            }
        }

        &.response-data {
            display: flex;
            justify-content: space-between;
            line-height: 20px;

            .reactions {
                display: flex;

                .emojis {
                    padding-left: 6px;
                }

                div {
                    padding-left: 20px;
                }
            }
        }
    }
}
.message-body {
    cursor: pointer;
    display: flex;

    .left-container {
        width: 68px;
    }

    .right-container {
        flex: 1;
        padding-top: 4px;
        padding-left: 12px;

        .heading {
            display: flex;
            align-items: center;
            /* override to fix desktop style */
            line-height: 12px;

            .brief-details {
                color: @grey40;
                font-size: 10px;
                margin-left: 3px;

                .name {
                    font-weight: 500;
                    text-transform: uppercase;
                    padding: 0 0 3px 0;
                }
            }
        }

        .details {
            .subject {
                font-size: 14px;
                color: @grey90;
                margin-top: 5px;
                padding: 0 4px;
                margin-bottom: 4px;
            }

            .description {
                display: flex;
                font-size: 10px;
                padding: 4px 2px;
                /* override to fix desktop style */
                line-height: 14px;

                .date {
                    flex: 1;
                    text-align: right;
                    color: @grey40;
                    font-size: 12px;
                }
            }
        }

        /* use deep selector to remove margin of <p> */

        .details :deep(p) {
            margin-block-start: 0;
            margin-block-end: 0;
            line-height: 18px;
        }
    }
}
</style>
