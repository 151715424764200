<template>
    <div class="row-container">
        <div class="left-container">
            <div :class="['radio', { selected: selected }]">
                <div class="radio-inner" />
            </div>
        </div>
        <div class="right-container">
            <NoticeTypeBadge :notice-type="noticeType.name" />
            <div class="notice-description">{{ noticeType.description }}</div>
            <div class="notice-eg">{{ noticeType.eg }}</div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { INoticeType } from '@/entities/notices'
import NoticeTypeBadge from './NoticeTypeBadge.vue'

@Component({
    components: { NoticeTypeBadge },
})
export default class NoticeTypeRow extends Vue {
    @Prop({ type: Object }) public noticeType!: INoticeType
    @Prop({ type: Boolean }) public selected!: boolean
}
</script>

<style scoped lang="less">
@import '../../styles/palette.less';
@import '../../styles/rain/variables.less';

.row-container {
    display: flex;
    padding: 24px 0;

    .radio {
        border-radius: 50%;
        width: 24px;
        height: 24px;
        border: 1px solid @grey40;
        position: relative;
        top: -1px;
        justify-content: center;
        align-items: center;
        display: flex;
        margin-right: 16px;

        .radio-inner {
            display: none;
            width: 10px;
            height: 10px;
            background: @blue;
            border-radius: 50%;
        }

        &.selected {
            border-color: @blue;

            .radio-inner {
                display: block;
            }
        }
    }

    .right-container {
        flex: 1;
        margin-top: 2px;

        .notice-description {
            margin-top: 12px;
            font-size: @fontSize-base;
            line-height: @lineHeight-base;
            color: @grey90;
        }

        .notice-eg {
            margin-top: @gutterSpacing-base;
            font-size: @fontSize-sm;
            line-height: @lineHeight-sm;
            color: @greyBlue;
        }

        .notice-emergency {
            margin-top: @gutterSpacing-base;
            display: flex;

            .notice-emergency-label {
                font-weight: 900;
                margin-right: 8px;
            }

            .notice-emergency-group {
                display: flex;
                flex: 1;
                align-items: center;
            }

            .notice-emergency-circle {
                width: 9px;
                height: 9px;
                border-radius: 50%;
                margin-left: 6px;
                background: @greyLight4;
            }

            .notice-emergency-circle-light {
                background: @greyLight;
            }
        }
    }
}
</style>
