<template>
    <div :class="['count', { 'over-limit': unreadCount > 99 }]">
        {{ displayCount }}
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component({})
export default class UnreadCounter extends Vue {
    @Prop({ type: Number, required: true })
    public readonly unreadCount!: number

    public get displayCount() {
        return this.unreadCount <= 99 ? this.unreadCount : '99+'
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';

@size: 16px;
.count {
    position: absolute;
    height: @size;
    width: @size;
    top: -4px;
    right: 2px;
    background: @errorRed;
    color: @white;
    border-radius: 50%;
    font-weight: @fontWeight-medium;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.over-limit {
        font-size: @fontSize-xs;
    }
}
</style>
