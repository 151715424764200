import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from './WebView/icons/Refresh.svg'


const _withScopeId = n => (_pushScopeId("data-v-075a07cd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "top-loading-container-inner" }
const _hoisted_2 = { class: "spin-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuePullTo = _resolveComponent("VuePullTo")!

  return (_openBlock(), _createBlock(_component_VuePullTo, {
    ref: "pullToRefresh",
    "top-load-method": _ctx.pullDown ? _ctx.topLoadMethod : null,
    "top-config": _ctx.TOP_DEFAULT_CONFIG,
    class: _normalizeClass([_ctx.topLoaderClass, { snappy: _ctx.snappy, 'bottom-padding': _ctx.bottomPadding }]),
    "scroll-container-top": _ctx.scrollContainerTop,
    onTopStateChange: _ctx.topStateChanged,
    onInfiniteScroll: _ctx.bottomLoadMethod
  }, {
    "top-block": _withCtx((scope) => [
      _createElementVNode("div", {
        style: _normalizeStyle(_ctx.pullStyle(scope)),
        class: "top-loading-container-outer"
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("img", {
            src: _imports_0,
            style: _normalizeStyle(_ctx.pullIconStyle(scope)),
            alt: "refresh"
          }, null, 4),
          _createElementVNode("span", _hoisted_2, _toDisplayString(scope.stateText), 1)
        ])
      ], 4)
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], undefined, true),
    _: 3
  }, 8, ["top-load-method", "top-config", "class", "scroll-container-top", "onTopStateChange", "onInfiniteScroll"]))
}