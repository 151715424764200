import { Datapipe } from '@/pages/simple-survey/entities/datapipe'
import {
    surveyColorAndLayoutInit,
    surveyEmailSetupInit,
    SurveyColorAndLayoutType,
    SurveyEmailSetupType,
} from './design'

export interface SurveyApp extends SurveyWhole {
    datapipes: Datapipe[]
    from_name: string
    subject: string
    status: string | null
    valid: SurveyAppStatus
}

export interface SurveyWhole
    extends SurveySettingsTab,
        SurveyColorAndLayoutType,
        SurveyEmailSetupType {
    id: string
    last_response?: string | null
    last_sent?: string | null
    schedule_days?: string | null
    schedule_time?: string | null
    schedule_send_option?: number | null
    schedule_oneOff_sendDay?: number | null
    schedule_timezone?: string | null
    status?: string | null
    valid?: SurveyAppStatus
}

export interface SurveyAppStatus {
    settings: boolean
    design: boolean
    'audience & timing': boolean
}

export interface SurveySettingsTab {
    name?: string
    description?: string
    question_type?: string
    audience_type?: string
    deliverymethod?: string
    body?: string
    csat_very_satisfied?: string
    csat_satisfied?: string
    csat_neutral?: string
    csat_unsatisfied?: string
    csat_very_unsatisfied?: string
}

export interface SurveySettingsTabResponse {
    success: boolean
    survey?: SurveyWhole
    reason?: SurveySettingsTab
}

export interface SurveyPreviewVariables {
    fromName?: string | null
    subject?: string | null
    logo?: string | null
    avatar?: string | null
    footer?: string | null
    colorButton?: string | null
    npsLayout?: string | null
    labelLeft?: string | null
    labelRight?: string | null
    unsubscribe?: string | null
    privacy?: string | null
    csatVerySatisfied?: string | null
    csatSatisfied?: string | null
    csatNeutral?: string | null
    csatUnsatisfied?: string | null
    csatVeryUnsatisfied?: string | null
    specChanged: boolean
    lastSave?: number | null
    hasScorecardFeature: boolean
    hasScorecardQuestion: boolean
}

export enum PresetSuveyIdType {
    New = '0',
    Default = '-1',
    // Normal > 0
}

export enum audienceType {
    touchbase = 'Touch Base',
    followup = 'Follow Up',
}

export const defaultSurveyApp: SurveyApp = {
    ...surveyColorAndLayoutInit,
    ...surveyEmailSetupInit,
    audience_type: 'touchbase',
    datapipes: [],
    deliverymethod: 'email',
    description: '',
    from_name: '',
    id: PresetSuveyIdType.Default,
    last_response: null,
    last_sent: null,
    name: '',
    csat_very_satisfied: 'Very Satisfied',
    csat_satisfied: 'Satisfied',
    csat_neutral: 'Neutral',
    csat_unsatisfied: 'Unsatisfied',
    csat_very_unsatisfied: 'Very Unsatisfied',
    question_type: 'nps',
    schedule_days: null,
    schedule_time: null,
    schedule_send_option: null,
    schedule_timezone: null,
    status: 'draft',
    subject: 'Hi{ firstname}, just a quick question.',
    body: 'Hi{ firstname}, just a quick question.',
    valid: {
        settings: false,
        design: false,
        'audience & timing': false,
    },
}

export const initSurvey: SurveyWhole = {
    ...surveyColorAndLayoutInit,
    ...surveyEmailSetupInit,
    id: PresetSuveyIdType.New,
    name: '',
    description: '',
    body: '',
    csat_very_satisfied: 'Very Satisfied',
    csat_satisfied: 'Satisfied',
    csat_neutral: 'Neutral',
    csat_unsatisfied: 'Unsatisfied',
    csat_very_unsatisfied: 'Very Unsatisfied',
    question_type: 'nps',
    audience_type: 'touchbase',
    deliverymethod: 'email',
    status: 'draft',
    valid: {
        settings: false,
        design: false,
        'audience & timing': false,
    },
}

export const prioritizedSurveySteps = [
    'settings',
    'design',
    'audience & timing',
]

export const SETTINGS_TAB_INDEX = 0
export const DESIGN_TAB_INDEX = 1
export const AUDIENCE_AND_TIMING_TAB_INDEX = 2
export const REVIEW_AND_ACTIVATE_TAB_INDEX = 3

export const initSurveyPreviewVariables = {
    fromName: null,
    subject: null,
    logo: null,
    avatar: null,
    footer: null,
    colorButton: null,
    npsLayout: null,
    labelLeft: null,
    labelRight: null,
    unsubscribe: null,
    privacy: null,
    csatVerySatisfied: null,
    csatSatisfied: null,
    csatNeutral: null,
    csatUnsatisfied: null,
    csatVeryUnsatisfied: null,
    specChanged: false,
    lastSave: null,
    hasScorecardFeature: false,
    hasScorecardQuestion: false,
}

export const reviewAndActivateOptions = {
    draft: {
        headline: 'almost there!',
        icon: '🎉',
        mainText:
            'Before you activate the survey and start surveying your contacts, consider sending a survey to yourself and colleagues to check the flow, the wording and see the corresponding responses on the Dashboard.',
        explanations: [
            {
                label: 'Save',
                text: 'this survey to activate at a later date, or',
            },
            {
                label: 'Save and Activate',
                text: 'to start sending surveys.',
            },
        ],
        buttons: [
            {
                label: 'Save',
                type: 'secondary',
                action: 'save',
            },
            {
                label: 'Save & Activate',
                type: 'primary',
                action: 'activate',
            },
        ],
    },
    'one-off': {
        headline: 'almost there!',
        icon: '🎉',
        mainText:
            'Before you activate the survey and start surveying your contacts, consider sending a survey to yourself and colleagues to check the flow, the wording and see the corresponding responses on the Dashboard.',
        explanations: [
            {
                label: 'Save',
                text: 'this survey to activate at a later date, or',
            },
            {
                label: 'Save and Activate',
                text: 'to start sending surveys.',
            },
        ],
        buttons: [
            {
                label: 'Save',
                type: 'secondary',
                action: 'save',
            },
            {
                label: 'Save & Activate',
                type: 'primary',
                action: 'activate',
            },
        ],
    },
    pending_send: {
        headline: 'ready for liftoff!',
        icon: '🚀',
        mainText:
            'This survey has been activated and scheduled to send surveys. If you need to make any changes you will need to stop the survey temporarily and reactivate it later.',
        explanations: [],
        buttons: [
            {
                label: 'Stop',
                type: 'secondary',
                action: 'stop',
            },
        ],
    },
    live: {
        headline: 'surveys in progress',
        icon: '👏🏻',
        mainText:
            'Congratulations, you are surveying your contacts. If you need to make any changes you will need to stop the survey temporarily and reactivate it later.',
        explanations: [],
        buttons: [
            {
                label: 'Stop',
                type: 'secondary',
                action: 'stop',
            },
        ],
    },
    stopped: {
        headline: 'hold up',
        icon: '🛑',
        mainText:
            "This survey has been stopped. If you've made any further changes consider sending a survey to yourself and colleagues to check the flow, the wording and see the corresponding responses on the Dashboard.",
        explanations: [
            {
                label: 'Save',
                text: 'this survey to activate at a later date, or',
            },
            {
                label: 'Save and Activate',
                text: 'to start sending surveys.',
            },
        ],
        buttons: [
            {
                label: 'Save',
                type: 'secondary',
                action: 'save',
            },
            {
                label: 'Save & Activate',
                type: 'primary',
                action: 'activate',
            },
        ],
    },
    archived: {
        headline: 'safely filed',
        icon: '🗃',
        mainText: 'This survey has been archived.',
        explanations: [
            {
                label: 'Duplicate',
                text: 'this survey, or',
            },
            {
                label: 'Remove',
                text: "this survey, BUT you won't be able to retrieve it any longer",
            },
        ],
        buttons: [
            {
                label: 'Remove',
                type: 'ghost',
                action: 'remove',
            },
            {
                label: 'Duplicate',
                type: 'secondary',
                action: 'duplicate',
            },
        ],
    },
}
