<!-- Shouldn't set height here, should set height in parent component so parent can decide how tall it should be
default could be a fixed pixel height or relative height like: calc(100vh - 110px) -->
<template>
    <VuePullTo
        ref="pullToRefresh"
        :top-load-method="pullDown ? topLoadMethod : null"
        :top-config="TOP_DEFAULT_CONFIG"
        :class="[topLoaderClass, { snappy, 'bottom-padding': bottomPadding }]"
        :scroll-container-top="scrollContainerTop"
        @top-state-change="topStateChanged"
        @infinite-scroll="bottomLoadMethod"
    >
        <template #top-block="scope">
            <div :style="pullStyle(scope)" class="top-loading-container-outer">
                <div class="top-loading-container-inner">
                    <img
                        src="./WebView/icons/Refresh.svg"
                        :style="pullIconStyle(scope)"
                        alt="refresh"
                    />
                    <span class="spin-text">{{ scope.stateText }}</span>
                </div>
            </div>
        </template>
        <slot />
    </VuePullTo>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from 'vue-facing-decorator'
import { TOP_DEFAULT_CONFIG } from '@/utils/vuePullTo'
import VuePullTo from '@/components/VuePullTo.vue'

@Component({ components: { VuePullTo } })
export default class Scrollable extends Vue {
    private topLoaderClass = 'pull-to-hidden' // default is hidden when not pulling down, so it's not covering other elements

    @Prop({ type: Function, required: false }) public onScrollEnd?: () => void
    @Prop({ type: Function, required: false }) public pullDown?: () => void
    @Prop({ type: Boolean, default: false }) public snappy?: boolean
    @Prop({ type: Boolean, default: false }) public bottomPadding?: boolean // Extra padding to avoid overlap with ScreenBottomButton
    @Prop({ type: Number, required: false, default: 0 })
    public scrollContainerTop?: number // Used to retain scroll position of vue-pull-to 'scroll-container' div

    // if set, will trigger an initial call of pullDown function to load the data
    @Prop({ type: Boolean, default: false })
    public readonly loading!: boolean

    public TOP_DEFAULT_CONFIG = TOP_DEFAULT_CONFIG

    public mounted() {
        this.watchLoading()
    }

    @Watch('loading')
    public watchLoading() {
        if (this.loading) {
            // hack into the pullToRefresh component to allow initial loading call
            // all component reference are done via this Scrollable, so should be acceptable
            (this.$refs.pullToRefresh as any).$data.distance = 100
            ;(this.$refs.pullToRefresh as any).$data.state = 'loading'
        }
    }

    private async topLoadMethod(loaded) {
        if (!this.pullDown) {
            return
        }
        try {
            await this.pullDown()
            loaded('done')
        } catch (e) {
            loaded('fail')
        }
    }

    private async bottomLoadMethod() {
        if (!this.onScrollEnd) {
            return
        }
        await this.onScrollEnd()
    }

    private pullStyle({ state, triggerDistance, diff }) {
        if (!this.pullDown || state !== 'pull') {
            return ''
        }
        const dist = Math.min(diff, triggerDistance)
        const opacity = dist / 100
        return `opacity: ${opacity}`
    }

    private pullIconStyle({ state, triggerDistance, diff }) {
        if (!this.pullDown) {
            return ''
        }
        // rotate when loading
        if (state === 'loading') {
            return `animation:pull-to-loading-spin 1s linear infinite; opacity: 1`
        }
        const dist = Math.min(diff, triggerDistance)
        const opacity = dist / 100
        return `transform: rotate(${Math.floor(
            dist * 5
        )}deg); opacity: ${opacity}`
    }

    private topStateChanged(state) {
        if (!state) {
            this.topLoaderClass = 'pull-to-hidden'
        } else {
            this.topLoaderClass = 'pull-to-visible'
        }
    }
}
</script>

<style lang="less" scoped>
@import '../styles/palette';

.scrollable {
    overflow-y: auto;
}

.top-loading-container-outer {
    padding-top: 10px;
    display: flex;

    .top-loading-container-inner {
        color: @grey40;
        display: flex;
        align-items: center;
        margin: 0 auto;

        .spin-text {
            display: inline-block;
            width: 130px;
            text-align: center;
        }
    }
}
</style>

<style lang="less">
@keyframes pull-to-loading-spin {
    100% {
        transform: rotate(360deg);
    }
}

.pull-to-hidden .action-block-top {
    display: none;
}

.pull-to-visible .action-block-top {
    display: initial;
}

.snappy {
    > .scroll-container {
        scroll-snap-type: y proximity;
    }
}

.bottom-padding {
    > .scroll-container {
        padding-bottom: 74px !important; // To override 'vue-pull-to' styling
    }
}
</style>
