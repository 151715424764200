<template>
    <PillButton
        :class="composerType"
        :is-active="isActive"
        :disabled="disabled"
        :label="label"
        @click="onClick"
    />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import PillButton from '@/components/PillButton.vue'
import { namespace } from 'vuex-facing-decorator'

const MomentsModule = namespace('moments')

@Component({
    components: {
        PillButton,
    },
    emits: ['click'],
})
export default class MessagePillButton extends Vue {
    @Prop({ type: String, required: true })
    public readonly label!: string

    @Prop({ type: Boolean, required: false, default: false })
    public readonly isActive!: boolean

    @Prop({ type: Boolean, required: false, default: false })
    public readonly disabled!: boolean

    @Prop({ type: String, required: true })
    public readonly composerType!: string

    onClick() {
        if (!this.disabled) {
            this.$emit('click')
        }
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/appV4/messageTypes.less';

.pill-button {
    line-height: 18px;
    cursor: pointer;
    border-radius: 50px;
    outline: none;
    font-size: 12px;
    padding: 8px 24px;
    letter-spacing: 1px;
    background-color: transparent;
    border: 1px solid;
    border-color: var(--type-colour);
    color: var(--type-colour);
    transition: all 0.3s ease-in-out;

    &.is-active {
        border-color: var(--type-colour);
        background-color: var(--type-colour);
        color: @offBlack;
        &.message-button {
            border-color: @disabledSecondaryGrey;
        }
    }
    &.disabled {
        opacity: 0.6;
    }
}
</style>
