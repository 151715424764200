<template>
    <WebViewScreen no-pad disable-when-offline show-go-back>
        <template #custom-title>
            <div class="title">{{ getFeedbackLabel }}</div>
            <div class="time-range">{{ friendlyTimeRange }}</div>
        </template>
        <template #right-button>
            <InsightsTimePickerMobile
                :time-range="timeRange"
                @selectTime="updateTimeRange"
            />
        </template>
        <Scrollable :on-scroll-end="loadMoreResponses">
            <div class="coaching-topic-feedback-responses">
                <RouterLink
                    v-for="response in responses"
                    :key="response.id"
                    :to="{
                        name: 'chatResponsesRoot',
                        params: { id: response.id },
                    }"
                >
                    <div class="feedback-item">
                        <ScorecardResponseTile
                            :response="response"
                            :scorecard="scorecard"
                            class="coaching-topic-review-response"
                        />
                    </div>
                </RouterLink>
                <div v-if="loading" class="loading">
                    <SimpleSpinner
                        size="small"
                        class="spinner"
                        line-fg-color="#B338A3"
                    ></SimpleSpinner>
                </div>
                <div
                    v-if="!loading && resultsDepleted[key]"
                    class="no-more-results"
                >
                    no more results
                </div>
            </div>
        </Scrollable>
    </WebViewScreen>
</template>

<script lang="ts">
import Scrollable from '@/components/Scrollable.vue'
import WebViewScreen from '@/components/WebView/WebViewScreen.vue'
import { CoachingInsights, ITimeRange } from '@/entities/insights'
import InsightsTimePickerMobile from '@/mobile/src/components/Insights/InsightsTimePickerMobile.vue'
import { Component, Prop, Watch, Vue } from 'vue-facing-decorator'
import { Action, Getter } from 'vuex-facing-decorator'
import { getResponses } from '@/api/responses'
import { OFFLINE_RESPONSE_COUNT } from '@/store/modules/scorecard'
import { dedupBy } from '@/utils/array'
import { isFiveScoreQuestionType } from '@/utils/globalVariables'
import { Dict, IResponse } from '@/entities'
import ScorecardResponseTile from '@/components/ScorecardResponseTile.vue'
import { ScorecardEntity } from '@/entities/scorecard'
import SimpleSpinner from '@/components/SimpleSpinner.vue'

@Component({
    components: {
        ScorecardResponseTile,
        InsightsTimePickerMobile,
        Scrollable,
        WebViewScreen,
        SimpleSpinner,
    },
})
export default class CoachingTopicFeedback extends Vue {
    private resultsDepleted = []
    private loading = false

    @Prop({ type: String, default: null })
    private topic!: string

    @Prop({ type: String, default: null })
    private feedbackType!: string

    @Getter
    readonly friendlyTimeRange!: string

    @Getter
    readonly insightsCoaching!: CoachingInsights

    @Getter
    readonly timeRange!: ITimeRange

    @Getter
    public responsesMap!: Dict<IResponse[]>

    @Getter
    hasNetworkConnection!: boolean

    @Getter
    public scorecard!: ScorecardEntity

    @Action
    updateTimeRange

    @Action
    loadInsightsCoaching

    @Action
    private setResponses

    @Watch('insightsCoaching')
    public async onInsightsCoachingChange() {
        if (this.insightsCoaching !== undefined && this.responses.length < 1) {
            await this.loadMoreResponses()
        }
    }

    async mounted() {
        if (this.insightsCoaching === undefined) {
            // we need to call this to get the correct question_type
            await this.loadInsightsCoaching()
        }

        if (!this.responsesMap[this.key]) {
            await this.loadMoreResponses()
        }
    }

    private get getFeedbackLabel() {
        if (this.insightsCoaching) {
            if (isFiveScoreQuestionType(this.insightsCoaching.question_type)) {
                if (this.feedbackType === 'Positive') {
                    return 'Satisfied'
                } else {
                    return 'Unsatisfied & Neutral'
                }
            } else {
                if (this.feedbackType === 'Positive') {
                    return 'Promoters'
                } else {
                    return 'Detractors & Passives'
                }
            }
        }
        return ''
    }

    private get key() {
        return JSON.stringify(
            'InsightsCoachingTopicFeedback' +
                '-' +
                this.topic +
                '-' +
                this.feedbackType +
                '-' +
                this.timeRange.timeUnit +
                '-' +
                (this.timeRange.timeValue !== undefined
                    ? this.timeRange.timeValue.toString()
                    : '1')
        )
    }

    private get responses() {
        const responses = this.responsesMap[this.key]
        return responses ? dedupBy(responses, ({ id }) => id.toString()) : []
    }

    private async loadMoreResponses() {
        if (this.resultsDepleted[this.key]) {
            return
        }

        this.loading = true

        const positive = this.feedbackType === 'Positive'
        let breakpoint = positive ? '8' : '9'
        if (isFiveScoreQuestionType(this.insightsCoaching.question_type)) {
            breakpoint = positive ? '3' : '4'
        }

        const { data } = await getResponses(
            {
                filter_rules: [
                    {
                        column: 'question_type',
                        operator: 'in',
                        value: [this.insightsCoaching.question_type],
                    },
                    { column: 'comment', operator: 'isnotempty', value: [''] },
                    {
                        column: 'answer',
                        operator: positive ? 'gt' : 'lt',
                        value: [breakpoint],
                    },
                    {
                        column: this.insightsCoaching.scorecard_field,
                        operator: 'in',
                        value: [this.topic],
                    },
                ],
                time_unit: this.timeRange.timeUnit,
                time_value:
                    this.timeRange.timeValue !== undefined
                        ? this.timeRange.timeValue.toString()
                        : '1',
            },
            {
                pagesize: this.hasNetworkConnection
                    ? 10
                    : OFFLINE_RESPONSE_COUNT,
                offset:
                    this.responses && this.hasNetworkConnection
                        ? this.responses.length
                        : 0,
            }
        )

        if (data.length > 0) {
            if (!this.responsesMap[this.key]) {
                this.setResponses({ key: this.key, data })
            } else {
                this.setResponses({
                    key: this.key,
                    data: dedupBy(
                        [...this.responsesMap[this.key], ...data],
                        ({ id }) => id.toString()
                    ),
                })
            }
        } else {
            this.resultsDepleted[this.key] = true
        }
        this.loading = false
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/typography';
@import '~@/styles/variables';
@import '~@/styles/button';

.time-range {
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: @grey40;
}

.coaching-topic-feedback-responses {
    padding: 8px;

    .feedback-item {
        margin-bottom: 20px;
    }

    .no-more-results {
        color: #999;
        text-align: center;
        padding: 10px;
        font-size: 12px;
        font-style: italic;
    }
}
</style>

<style lang="less">
@import '~@/styles/variables';

.scroll-container {
    background: @neutralBg;
}
</style>
