<template>
    <BaseCard :is-highlighted="active" @click="onClick">
        <div v-if="skeleton" ref="tile" class="metric-container">
            <div class="title-container">
                <div class="title"><SkeletonDiv :width="skeleWidth" /></div>
            </div>
            <div class="subtitle">
                <SkeletonDiv :width="skeleWidth" />
            </div>
        </div>
        <div v-else ref="tile" class="metric-container">
            <div class="subtitle">
                {{ subtitle }}
            </div>
            <div class="title-container">
                <div class="title" :class="{ 'title-web': !$isMobile }">
                    {{ title }}
                    <template v-if="positive !== null">
                        <UpDownArrow
                            v-if="changeIcon === 'arrow'"
                            :positive="positive"
                            class="change"
                        />
                        <UpDownDoubleChevron
                            v-else-if="changeIcon === 'chevron'"
                            :positive="positive"
                            class="change"
                        />
                    </template>
                </div>
            </div>
        </div>
    </BaseCard>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from 'vue-facing-decorator'
import BaseCard from '@/components/BaseCard.vue'
import UpDownArrow from '@/components/UpDownArrow.vue'
import UpDownDoubleChevron from '@/mobile/src/components/UpDownDoubleChevron.vue'
import SkeletonDiv from '@/components/Skeletons/SkeletonDiv.vue'
import { sleep } from '@/utils/async'
import { Getter } from 'vuex-facing-decorator'

@Component({
    components: {
        UpDownDoubleChevron,
        UpDownArrow,
        BaseCard,
        SkeletonDiv,
    },
    emits: ['click'],
})
export default class InsightsMetric extends Vue {
    @Prop({ type: String, default: '' })
    readonly title!: string

    @Prop({ type: String, default: '' })
    readonly subtitle!: string

    // whether it's up arrow or down arrow
    @Prop({ type: String, required: false })
    readonly delta?: 'positive' | 'negative'

    @Prop({ type: String, required: false, default: 'arrow' })
    readonly changeIcon?: 'arrow' | 'chevron'

    @Prop({ type: Boolean, default: false })
    readonly active!: boolean

    @Prop({ type: Boolean, default: false, required: false })
    readonly skeleton!: boolean

    @Getter
    $isMobile

    readonly skeleWidth = '176px'

    @Watch('active')
    public async onActiveChange() {
        this.focusOnTile()
    }

    async mounted() {
        this.focusOnTile()
    }

    public async focusOnTile() {
        // scroll the active tile into the view smoothly
        if (this.active) {
            const activeTile = this.$refs['tile'] as HTMLDivElement
            await sleep(100)
            activeTile.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'center',
            })
        }
    }

    get positive() {
        if (!this.delta) {
            return null
        }

        return this.delta === 'positive'
    }

    onClick() {
        this.$emit('click')
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/typography';
@import '~@/styles/variables';

.metric-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: @gutterSpacing;
    min-width: 140px;
    cursor: pointer;
}

.title-container {
    display: flex;
    align-items: center;
}

.title {
    font-weight: 500;
    font-size: 24px;
    color: @grey90;
    height: 32px;
}

.title-web {
    font-size: 20px;
    height: 28px;
}

.change {
    margin-left: 8px;
}

.subtitle {
    .label();
    text-transform: uppercase;
    text-align: center;
    height: 20px; //
    padding-bottom: 10px;
}
</style>
