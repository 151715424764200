<template>
    <transition name="fade">
        <div v-if="modalOpen" class="mention-warning-modal">
            <FontAwesomeIcon
                class="close-icon"
                :icon="closeIcon"
                @click="closeModal"
            />

            <Typography variant="body1" class="template-text">
                User @mentions can not be used when sending an external message
                to a customer.
            </Typography>
        </div>
    </transition>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faXmark } from 'fontawesome/free-solid-svg-icons'
import Typography from '@/components/Rain/Typography/Typography.vue'

@Component({
    components: {
        Typography,
        FontAwesomeIcon,
    },
    emits: ['closeModal'],
})
export default class MentionWarningModal extends Vue {
    @Prop({ type: Boolean, default: true }) modalOpen!: boolean

    public closeIcon = faXmark

    public closeModal() {
        this.$emit('closeModal')
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';
@import '~@/mobile/src/styles/animations.less';
@import '~@/mobile/src/styles/message-composer.less';

.fade-transition-animation(0.2s);

.mention-warning-modal {
    .modal-container();
    top: 200px;
    max-height: 450px;
    overflow-y: scroll;
}

.template-text {
    color: @white;
    padding-right: 10px;
}
</style>
