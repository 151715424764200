import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-491ad738"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "team-profile-select-popup" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TeamProfileSelect = _resolveComponent("TeamProfileSelect")!

  return (
            _ctx.showTeamProfileSelect &&
            _ctx.teamProfileUsers &&
            _ctx.filterOptions.length > 0
        )
    ? (_openBlock(), _createBlock(_Transition, {
        key: 0,
        name: "slide-team-profile",
        appear: ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_TeamProfileSelect)
          ])
        ], undefined, true),
        _: 1
      }))
    : _createCommentVNode("", true)
}