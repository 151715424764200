<template>
    <div class="action-button-container" @click="onClick">
        <div class="left">
            <img v-if="icon" class="icon" :src="icon" alt="Action Icon" />
            <Typography class="label" variant="caption">{{ label }}</Typography>
        </div>
        <div class="right">
            <slot name="right"></slot>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import Typography from '@/components/Rain/Typography/Typography.vue'

@Component({
    components: {
        Typography,
    },
})
export default class ActionButton extends Vue {
    @Prop({ type: String, default: '' }) label!: string
    @Prop({
        type: Function,
        default: () => {
            return
        },
    })
    onClick?: () => void
    @Prop({ type: String }) icon!: string
}
</script>

<style lang="less" scoped>
@import '~@/styles/palette.less';
@import '~@/styles/rain/variables.less';

.action-button-container {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: space-between;
    padding: 20px;
    width: calc(100vw - 40px);

    .left {
        display: flex;
        align-items: center;

        .icon {
            padding-right: 10px;
        }

        .label {
            color: white;
            font-weight: @fontWeight-bold;
            letter-spacing: @letterSpacing-2xl;
            line-height: @lineHeight-sm;
            text-transform: uppercase;
        }
    }

    .right {
        color: white;
    }
}
</style>
