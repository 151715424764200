import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SimpleList = _resolveComponent("SimpleList")!

  return (_openBlock(), _createBlock(_component_SimpleList, {
    class: "notice-type-rows",
    rows: _ctx.rows,
    "has-left-gutter": !!_ctx.$isMobile,
    "has-last-divider": _ctx.hasLastDivider
  }, null, 8, ["rows", "has-left-gutter", "has-last-divider"]))
}