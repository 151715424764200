import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f935490"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "team-profile-select-container" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AvatarOrInitials = _resolveComponent("AvatarOrInitials")!
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_HorizontalScrollContainer = _resolveComponent("HorizontalScrollContainer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HorizontalScrollContainer, { class: "team-profile-scroll-container" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.teamProfileUsers, (profile) => {
          return (_openBlock(), _createElementBlock("div", {
            key: profile.user.id,
            class: _normalizeClass(["team-member-profile", { active: _ctx.activeUserId === profile.user.id }]),
            onClick: ($event: any) => (_ctx.selectProfileUser(profile))
          }, [
            _createVNode(_component_AvatarOrInitials, {
              size: 40,
              user: {
                        id: profile.user.id,
                        name: profile.user.name,
                        avatar: profile.user.avatar,
                    }
            }, null, 8, ["user"]),
            (profile.hasComment)
              ? (_openBlock(), _createBlock(_component_FontAwesomeIcon, {
                  key: 0,
                  icon: _ctx.faCheckCircle,
                  class: "check-circle-icon"
                }, null, 8, ["icon"]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_3, _toDisplayString(profile.user.name.trim().split(' ')[0]), 1)
          ], 10, _hoisted_2))
        }), 128))
      ], undefined, true),
      _: 1
    })
  ]))
}