<template>
    <div>
        <input
            :id="id"
            type="checkbox"
            class="toggle-input"
            :checked="checked"
            @change="onChange"
        />

        <label :for="id" :class="['toggle-label', colorClass, sizeClass]">
            <span class="toggle-label-circle">
                <span class="toggle-label-circle-inner"></span>
            </span>
            <span class="toggle-label-text toggle-label-text-left">{{
                checkedText
            }}</span>
            <span class="toggle-label-text toggle-label-text-right">{{
                unCheckedText
            }}</span>
        </label>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component({
    emits: ['change'],
})
export default class ToggleInput extends Vue {
    @Prop({ type: Boolean, required: false, default: false })
    public readonly checked!: boolean

    @Prop({ type: String, required: false })
    public readonly color!: 'blue' | 'purple' | 'green' | 'grey'

    @Prop({ type: String, required: false })
    public readonly size!: 'small' | 'wide' | 'mobile'

    @Prop({ type: String, required: false, default: 'On' })
    public readonly checkedText!: string

    @Prop({ type: String, required: false, default: 'Off' })
    public readonly unCheckedText!: string

    public id = `toggle-${Math.floor(Date.now() * Math.random())}`

    public get colorClass() {
        if (!this.color) {
            return ''
        }

        return `toggle-label-${this.color}`
    }

    public get sizeClass() {
        if (!this.size) {
            return ''
        }

        return `toggle-label-${this.size}`
    }

    public onChange(event) {
        this.$emit('change', event.target.checked)
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/palette';

* {
    box-sizing: border-box;
}

.toggle-label {
    display: flex;
    align-items: center;
    background-color: #dbdde0;
    border-radius: 500px;
    cursor: pointer;
    height: 28px;
    justify-content: space-between;
    position: relative;
    user-select: none;
    width: 63px;

    &-wide {
        width: 88px;
    }
    &-mobile {
        width: 47px;
    }
    &-text {
        text-transform: uppercase;
        font-size: 12px;
        position: relative;

        &-left {
            color: @white;
            left: 11px;
        }
        &-right {
            color: #9399a2;
            right: 11px;
        }
    }

    &-circle {
        height: 28px;
        padding: 2px;
        position: absolute;
        transition: all 0.1s linear;
        width: 28px;

        &-inner {
            background-color: @white;
            border-radius: 50%;
            display: block;
            height: 100%;
            width: 100%;
        }
    }
}

.toggle-input {
    display: none !important; // to solve the priority conflict with UIkit in smarty

    &:not(:checked) ~ .toggle-label {
        background-color: #dbdde0;

        .toggle-label-text-left {
            visibility: hidden;
        }
        .toggle-label-circle {
            left: 0;
            right: calc(100% - 28px);
        }
    }

    &:checked ~ .toggle-label {
        background-color: @purple;

        .toggle-label-text-right {
            visibility: hidden;
        }
        .toggle-label-circle {
            left: calc(100% - 28px);
            right: 0;
        }
        &-blue {
            background-color: @blue;
        }
        &-green {
            background-color: @green;
        }
        &-grey {
            background-color: #bbbbbb;
        }
    }
}
</style>
