import { AvatarSetting } from '@/entities/avatar'

export const designSteps = {
    surveyQuestions: 'surveyQuestions',
    colorAndLayout: 'colorAndLayout',
    emailSetup: 'emailSetup',
    review: 'review',
}

export type SurveyQuestionsType = {
    questionCount: number
    completed: boolean
}

export type SurveyColorAndLayoutType = {
    avatar: string
    avatarSetting: AvatarSetting | null
    logo: string
    npsLayout: string
    colorButton: string
    labelLeft: string
    labelRight: string
    disableConfetti: boolean
}

export type SurveyEmailSetupType = {
    fromName: string
    fromEmail: string
    sendingDomain: string
    subject: string
    footer: string
    reminderDays: string
    reminderText: string
    surveyReminder: boolean
}
export type ReviewType = any // TODO: needs to be set

export const surveyQuestionsInit: SurveyQuestionsType = {
    questionCount: 0,
    completed: false,
}

export const surveyColorAndLayoutInit: SurveyColorAndLayoutType = {
    avatar: '/img/avatar/noavatar.png',
    avatarSetting: null,
    logo: '',
    npsLayout: 'standard',
    colorButton: '#333',
    labelLeft: '',
    labelRight: '',
    disableConfetti: false,
}

export const surveyEmailSetupInit: SurveyEmailSetupType = {
    fromName: '',
    fromEmail: '',
    sendingDomain: '',
    subject: '',
    footer: '',
    reminderDays: '',
    reminderText: '',
    surveyReminder: false,
}
export const reviewInit: ReviewType = { test: 'test4' }
