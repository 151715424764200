import { ActionTree, GetterTree, MutationTree } from 'vuex'

export interface State {
    reviewSite: string
    reviewLink: string
    reviewTemplate: string
}

const INITIAL_STATE: State = {
    reviewSite: '',
    reviewLink: '',
    reviewTemplate: '',
}

const actions: ActionTree<State, any> = {
    setReviewSite({ commit }, site: string) {
        commit('setReviewSite', site)
    },
    setReviewLink({ commit }, lang: string) {
        commit('setReviewLink', lang)
    },
    setReviewTemplate({ commit }, template: string) {
        commit('setReviewTemplate', template)
    },
    resetConfiguration({ commit }) {
        commit('resetConfiguration')
    },
}

const mutations: MutationTree<State> = {
    setReviewSite(state, reviewSite: string) {
        state.reviewSite = reviewSite
    },
    setReviewLink(state, reviewLink: string) {
        state.reviewLink = reviewLink
    },
    setReviewTemplate(state, reviewTemplate: string) {
        state.reviewTemplate = reviewTemplate
    },
    resetConfiguration(state) {
        const initial = INITIAL_STATE
        Object.keys(initial).forEach((key) => {
            state[key] = initial[key]
        })
    },
}

const getters: GetterTree<State, any> = {
    reviewSite: ({ reviewSite }) => reviewSite,
    reviewLink: ({ reviewLink }) => reviewLink,
    reviewTemplate: ({ reviewTemplate }) => reviewTemplate,
}

export default {
    namespaced: true,
    state: INITIAL_STATE,
    getters,
    mutations,
    actions,
}
