<template>
    <label :class="['container', { disabled }]">
        <span v-if="label" class="label" v-html="label"></span>
        <input
            type="checkbox"
            :disabled="disabled"
            :checked="checked"
            :value="value"
            @click="handleClick"
        />
        <span class="checkmark" :class="{ partial: partial }"></span>
        <InfoTooltip v-if="tooltip" class="tooltip">{{ tooltip }}</InfoTooltip>
    </label>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import InfoTooltip from '@/components/InfoTooltip.vue'

/*
      A custom checkbox
    */

@Component({
    components: { InfoTooltip },
})
export default class Checkbox extends Vue {
    @Prop({ type: Function }) public click?: (id) => void
    @Prop() public checked?
    @Prop({ type: Boolean }) public partial?: boolean
    @Prop({ type: String }) public label?: string
    @Prop({ type: String }) public value?: string
    @Prop({ type: String }) public id?: string
    @Prop({ type: String })
    readonly tooltip?: string

    @Prop({ type: Boolean, required: false, default: false })
    public readonly disabled!: boolean

    public handleClick() {
        if (this.click && !this.disabled) {
            this.click(this.id)
        }
    }
}
</script>

<style scoped lang="less">
@import '~@/styles/rain/colour.less';
@import '~@/styles/palette.less';

/* Customize the label (the container) */
.container {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &.disabled {
        .label {
            color: @disabledPrimaryGrey;
        }

        .checkmark {
            color: @disabledPrimaryGrey;
            background-color: @disabledPrimaryGrey;
            border: 1px solid @disabledPrimaryGrey;
        }
    }
}

.label {
    padding-left: 25px;
    position: relative;
    top: -1px;
}

/* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    left: 0;
    top: 0;
    z-index: 1;
    height: 13px;
    width: 14px;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: #eee;
    border: 1px solid @grey40;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
    background-color: #e4e8eb;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
    background-color: #007eff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 5px;
    top: 2px;
    width: 3px;
    height: 6px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.container .checkmark.partial:after {
    left: 4px;
    top: 0;
    width: 7px;
    height: 6px;
    border: solid white;
    border-width: 0 0 2px 0;
    transform: rotate(0deg);
}

.tooltip {
    margin-left: 5px;
}
</style>
