<template>
    <div :class="containerClass">
        <div v-if="type == 'dots'">
            <slot /><span :class="{ 'loader-dots': loading }" />
        </div>
        <div v-else class="sub-container">
            <i class="asknicely-icon-loader_static"></i>
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component({})
export default class Loading extends Vue {
    @Prop({ type: String, default: 'spinner' }) public type!: String
    @Prop({ type: Boolean, default: false }) public loading!: boolean
    @Prop({ type: Boolean, default: false }) public contained!: boolean
    @Prop({ type: Boolean, default: false }) public inline!: boolean
    @Prop({ type: Boolean, default: false }) public transparent!: boolean

    get containerClass() {
        switch (this.type) {
            case 'dots':
                return null
            case 'spinner':
            default:
                return {
                    loading: this.loading,
                    'not-loading': !this.loading,
                    contained: this.contained,
                    inline: this.inline,
                    transparent: this.transparent,
                }
        }
    }
}
</script>

<style scoped lang="less">
.loader-dots {
    display: inline-block;
    overflow: hidden;
    height: 1.3em;
    margin-top: -0.3em;
    line-height: 1.5em;
    vertical-align: text-bottom;
}

.loader-dots::after {
    display: inline-table;
    white-space: pre;
    text-align: left;
}

.loader-dots::after {
    content: '\A.\A..\A...';
    animation: dotframe 2s steps(4) infinite;
}
@keyframes dotframe {
    to {
        transform: translateY(-6em);
    }
}

.loading {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    text-align: center;
    background: #fff;
    opacity: 0.7;
    display: flex;
    align-items: center;
    z-index: 100;

    .sub-container {
        margin: 0 auto;
    }

    .asknicely-icon-loader_static {
        margin-right: 5px;
    }
}

.contained {
    position: relative;
    height: 70px;
}

.inline {
    display: inline-block;
    position: relative;
    height: auto;
    width: auto;
}

.not-loading {
    display: none;
}

.transparent {
    background-color: rgba(0, 0, 0, 0);
}
</style>
