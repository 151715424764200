<template>
    <div ref="modalDisplayTarget" class="display-container">
        <div ref="modalTargetPortrait" class="nps-number">
            {{
                formatScore(
                    score,
                    isFiveScoreQuestionType(scoreType),
                    $companyVars.has_display_csat_as_percentage
                )
            }}
        </div>
        <div ref="modalTargetLandscape" class="description-container">
            <span>
                <span v-if="customLabel">{{ customLabel }}</span>
                <MultiMetricSelect :disable-select="disableMetricPicker" />
                <br />
                <template v-if="month">End of {{ monthName }}</template>
                <template v-else>LAST
                    <span v-if="scoreDays === '1'">24 Hours</span>
                    <span v-else>{{ scoreDays }} Days</span>
                </template>
            </span>
            <span class="question-icon" @click="modalOpen()"><FontAwesomeIcon :icon="faQuestionCircle" /></span>
        </div>
        <Portal
            v-if="showingNPSFeedback"
            :to="'shadow-overlay-destination'"
            :target="$refs.modalDisplayTarget"
        >
            <InfoModal
                show
                :x-adjust="30"
                :title="helpTitle"
                :report-card-popup="reportCardPopupStyle"
                :portrait-target="$refs.modalDisplayTarget"
                :landscape-target="$refs.modalTargetLandscape"
                @close="modalClose"
            >
                <span v-if="helpText">{{ helpText }}</span>
                <span v-else>This is your customer rating over the past 30 days which
                    determines if you have achieved the monthly goal at the end
                    of the month.</span>
                Please see the
                <a
                    class="link"
                    target="”_blank”"
                    href="https://asknicely.zendesk.com/hc/en-us/articles/4405787070612-Choose-your-metric-NPS-vs-CSAT-vs-5-Star-"
                    >customer ratings article</a>
                for more info on how this number is calculated.
            </InfoModal>
        </Portal>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faQuestionCircle } from 'fontawesome/free-regular-svg-icons'
import InfoModal from '@/mobile/src/components/appV4/InfoModal.vue'
import { pendoTrackEvent } from '@/utils/pendo'
import { getFormattedScore } from '@/utils/scorecard'
import moment from 'moment'
import { Getter } from 'vuex-facing-decorator'
import MultiMetricSelect from '@/mobile/src/components/appV4/MultiMetricSelect.vue'
import { Portal } from 'portal-vue'
import { formatScore } from '@/utils/number'
import { isFiveScoreQuestionType } from '@/utils/globalVariables'

@Component({
    methods: { isFiveScoreQuestionType, formatScore, getFormattedScore },
    components: {
        Portal,
        MultiMetricSelect,
        InfoModal,
        FontAwesomeIcon,
    },
})
export default class NPSDisplay extends Vue {
    @Prop({ type: Number, default: 0 }) public score!: number
    @Prop({ type: String, required: false, default: 'nps' })
    public scoreType!: String
    @Prop({ type: Number }) public month!: number
    @Prop({ type: String, default: 'Your Rating' }) public helpTitle!: String
    @Prop({ type: String, default: '' }) public helpText!: String
    @Prop({ type: String, default: '' }) public customLabel!: String
    @Prop({ type: String, default: '' }) public triggerFrom!: String
    @Prop({ type: String, default: '30' }) public readonly scoreDays?: string
    @Prop({ type: Boolean, default: false })
    public readonly disableMetricPicker?: boolean

    @Getter public $companyVars

    private readonly faQuestionCircle = faQuestionCircle
    public showingNPSFeedback = false

    public get monthName() {
        return moment()
            .month(this.month - 1)
            .format('MMMM')
    }

    public modalClose() {
        this.showingNPSFeedback = false
    }

    public modalOpen() {
        let pendo_event = 'opened_rating_help_popup'
        if (this.triggerFrom === 'report-card') {
            pendo_event = `report_card_help_popup`
        }
        pendoTrackEvent(pendo_event)
        this.showingNPSFeedback = true
    }

    get reportCardPopupStyle() {
        return this.triggerFrom === 'report-card' ? 'lighterBackground' : ''
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';

.display-container {
    display: flex;
    align-items: center;
}

.nps-number {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 60px;
    line-height: 67px;
    color: white;
}

.link {
    color: @lightGrey;
}

.description-container {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding-left: 10px;
    color: white;

    .question-icon {
        padding-left: 5px;
    }
}
</style>
