import { TimeOption, Month } from '@/entities'
import moment from 'moment'

export interface TimeOptionLabel {
    time_unit: TimeOption
    time_value: number | string
    label: string
}

// @todo it may be better to get these from server instead

export const months: Month[] = [
    'jan',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec',
]

const monthOptions = (isNewDashboardFilterAndGraph = false) =>
    months.map(
        (m, index) =>
            ({
                time_unit: m,
                time_value: '1',
                label: isNewDashboardFilterAndGraph
                    ? new Date(0, index).toLocaleString('default', {
                          month: 'long',
                      })
                    : m,
            }) as TimeOptionLabel
    )

const oldTimeOptions: TimeOptionLabel[][] = [
    [
        {
            time_unit: 'custom',
            time_value: '16-02-18_18-02-18',
            label: 'custom',
        },
        { time_unit: 'ytd', time_value: '1', label: 'year to date' },
        { time_unit: 'today', time_value: '1', label: 'today' },
        { time_unit: 'week', time_value: '1', label: 'week' },
        { time_unit: 'week', time_value: '2', label: '2 weeks' },
        ...[1, 2, 3, 6, 12].map(
            (n) =>
                ({
                    time_unit: 'month',
                    time_value: String(n),
                    label: `${n} month${n > 1 ? 's' : ''}`,
                }) as TimeOptionLabel
        ),
    ],
]

const newTimeOptions: TimeOptionLabel[][] = [
    [
        {
            time_unit: 'custom',
            time_value: '16-02-18_18-02-18',
            label: 'custom',
        },
        { time_unit: 'year', time_value: '0', label: 'This Year' },
        { time_unit: 'year', time_value: '1', label: 'Previous Calendar Year' },
        { time_unit: 'month', time_value: '0', label: 'This Month' },
        ...[2, 3, 6, 12].map(
            (n) =>
                ({
                    time_unit: 'month',
                    time_value: String(n),
                    label: `Last ${n} Months`,
                }) as TimeOptionLabel
        ),
    ],
    [
        { time_unit: 'day', time_value: '0', label: 'Today' },
        { time_unit: 'day', time_value: '7', label: 'Week' },
        { time_unit: 'day', time_value: '14', label: '2 Weeks' },
        { time_unit: 'day', time_value: '30', label: 'Last Month' },
    ],
]

export const timeOptions = [
    ...oldTimeOptions,
    monthOptions(false).slice(0, 6),
    monthOptions(false).slice(6),
] as TimeOptionLabel[][]

export function isGreyOut(
    isNewDashboardFilterAndGraph: boolean,
    createdTime: number,
    indexSet: number,
    index: number
): boolean {
    if (!isNewDashboardFilterAndGraph) {
        return false
    }
    // Undefined
    const newOptions = timeOptions
    if (
        newOptions[indexSet] === undefined ||
        newOptions[indexSet][index] === undefined
    ) {
        return true
    }

    // Custom
    if (indexSet === 0 && index === 0) {
        return false
    }

    const now = new Date()
    const nowYear = now.getFullYear()
    const nowMonth = now.getMonth()
    const nowDate = now.getDate()
    const created = new Date(createdTime * 1000)
    const createdYear = created.getFullYear()
    const createdMonth = created.getMonth()

    if (indexSet < newOptions.length - 2) {
        const timeUnit = newOptions[indexSet][index].time_unit
        const timeValue = Number(newOptions[indexSet][index].time_value)
        const endDate = new Date(
            nowYear + (timeUnit === 'year' && !timeValue ? 1 : 0),
            timeUnit === 'year'
                ? 1
                : timeUnit === 'month' && !timeValue
                  ? nowMonth + 1
                  : nowMonth,
            timeUnit === 'day' ? nowDate : 1,
            0,
            0,
            0,
            -1
        )

        return created > endDate
    }

    // Fix the index of the month
    let month = index
    if (indexSet === newOptions.length - 1) {
        month += 6
    }

    if (nowYear - createdYear > 1) {
        return false
    }
    if (nowYear < createdYear) {
        return true
    }
    if (nowYear === createdYear) {
        return month > nowMonth || month < createdMonth
    }
    if (nowYear > createdYear) {
        return month > nowMonth && month < createdMonth
    }

    return true
}

export function timeSince(time: string | number): string {
    if (typeof time === 'string') {
        time = Number(time)
        if (isNaN(time)) {
            return 'unknown'
        }
    }
    const units = [
        { name: 'second', limit: 60, in_seconds: 1 },
        { name: 'minute', limit: 3600, in_seconds: 60 },
        { name: 'hour', limit: 86400, in_seconds: 3600 },
        { name: 'day', limit: 604800, in_seconds: 86400 },
        { name: 'week', limit: 2629743, in_seconds: 604800 },
        { name: 'month', limit: 31556926, in_seconds: 2629743 },
        { name: 'year', limit: null, in_seconds: 31556926 },
    ]
    let diff = (new Date().getTime() - new Date(time * 1000).getTime()) / 1000
    const ending = diff < 0 ? ' from now' : ' ago'
    diff = Math.abs(diff)
    if (diff < 5) {
        return 'now'
    }

    for (const unit of units) {
        if (unit.limit === null || diff < unit.limit) {
            diff = Math.floor(diff / unit.in_seconds)
            return diff + ' ' + unit.name + (diff > 1 ? 's' : '') + ending
        }
    }
    return 'unknown'
}

// Returns a text description of a past time relative to now, e.g: 1 minute ago, 3 hours ago, 2 years ago.

const intervals = [
    { label: 'year', seconds: 31556926 },
    { label: 'month', seconds: 2629744 },
    { label: 'week', seconds: 604800 },
    { label: 'day', seconds: 86400 },
    { label: 'hour', seconds: 3600 },
    { label: 'minute', seconds: 60 },
    { label: 'second', seconds: 1 },
]

// time is expected in seconds
export function relativeDate(time: number) {
    const diff = Math.floor(new Date().valueOf() / 1000) - time
    let n = 0

    for (const { label, seconds } of intervals) {
        if (diff > seconds) {
            n = Math.floor(diff / seconds)
            return n + ' ' + label + (n === 1 ? '' : 's') + ' ago'
        }
    }

    return 'Just now'
}

/**
 * Format a timestamp into a relative shortened date
 * E.g. now, 1 m, 2 h, 3 d, Aug 8
 * See: https://atlassian.design/content/writing-guidelines/date-and-time-guideline#past-
 * @param timestamp Unix timestamp
 * @returns Formatted shortened past relative date
 */
export function shortenedPastRelativeDate(timestamp: number): string {
    const shortenedIntervals = [
        { label: 'd', seconds: 86400 },
        { label: 'h', seconds: 3600 },
        { label: 'm', seconds: 60 },
    ]

    const diff = Math.floor(new Date().valueOf() / 1000) - timestamp

    // Timestamp is not in the past
    if (diff < 0) {
        return '-'
    }

    const overOneWeek = 604800

    if (diff >= overOneWeek) {
        return moment.unix(timestamp).format('MMM D')
    }

    for (const { label, seconds } of shortenedIntervals) {
        if (diff > seconds) {
            const intervalCount = Math.floor(diff / seconds)
            return intervalCount + ' ' + label
        }
    }

    return 'now'
}

export function time() {
    return new Date().getTime() / 1000
}

export function morning(momentTime) {
    return momentTime.set({ hour: 8, minute: 30, second: 0, millisecond: 0 })
}

export function afternoon(momentTime) {
    return momentTime.set({ hour: 13, minute: 0, second: 0, millisecond: 0 })
}

export function tomorrowMorning() {
    return morning(moment().add(1, 'days'))
}

export function tomorrowAfternoon() {
    return afternoon(moment().add(1, 'days'))
}

export function nextMondayMorning() {
    return morning(moment().endOf('week').add(2, 'days'))
}

export function timestampToDate(timestamp: number): string {
    const ts = moment.unix(timestamp)
    return ts.format('DD MMM YYYY')
}

export function timestampToDateTime(timestamp: number): string {
    const ts = moment.unix(timestamp)
    return ts.format('DD MMM YYYY, h:mm:ss A')
}

export function getLastMonthUsingTimezone(
    timezone = 'Pacific/Auckland'
): number {
    const now = new Date()
    now.toLocaleString('en-US', { timeZone: timezone })

    let lastMonth = now.getMonth()

    // calculation to solve zero-indexed return value from getMonth()
    // e.g If current month is January, above getMonth() call will return 0, however final result should be 12 for
    // December as that's the previous month
    lastMonth = ((lastMonth + 11) % 12) + 1

    return lastMonth
}

export function getFullMonthName(monthNumber: number): string {
    const date = new Date()
    // monthNumber: 0 ~ 11
    date.setMonth(monthNumber)

    return date.toLocaleString('en-US', {
        month: 'long',
    })
}

export function formatMinutesSecondsTime(currentTimeInSeconds: number): string {
    const minutes = Math.floor(currentTimeInSeconds / 60)
    const seconds = currentTimeInSeconds % 60
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
}
