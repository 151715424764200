import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/components/WebView/icons/BackLeftAction.svg'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    src: _imports_0,
    class: "back-button",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }))
}