<template>
    <div class="teamscoreboard-details-container">
        <BreakdownScreen @click-close="router.back()" />
    </div>
</template>

<script lang="ts">
import { Component, Watch, Vue } from 'vue-facing-decorator'
import { useRoute, useRouter } from 'vue-router'
import { Action, Getter } from 'vuex-facing-decorator'
import BreakdownScreen from '@/pages/team-scoreboard/BreakdownScreen.vue'

@Component({
    components: {
        BreakdownScreen,
    },
})
export default class TeamScoreboardModal extends Vue {
    @Action public setTsMobileData
    @Action public loadTsScorecard

    @Getter public teamScoreboard

    @Getter public companyProps

    private route = useRoute()
    private router = useRouter()

    @Watch('route.path')
    public onRouteChanged() {
        if (!this.route.query.chat) {
            return
        }
        this.router.replace({
            name: 'chatResponsesRoot',
            params: { id: this.route.query.chat.toString() },
        })
    }

    @Watch('teamScoreboard')
    public async onScoreboardChange() {
        if (!this.teamScoreboard) {
            return
        }
        const { field, parentField, parentGroups, scorecardField } =
            this.teamScoreboard

        this.loadTsScorecard({ field: scorecardField })
    }

    public async mounted() {
        this.setTsMobileData({
            enabledExecutiveReporting: this.companyProps.has_scorecard,
        })

        await this.onScoreboardChange()
    }
}
</script>

<style scoped lang="less">
@import '~@/styles/palette.less';

.teamscoreboard-details-container {
    z-index: 111;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: @white;
    padding: env(safe-area-inset-top) env(safe-area-inset-right)
        env(safe-area-inset-bottom) env(safe-area-inset-left);
}
</style>
