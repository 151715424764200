<template>
    <ScorecardSubpage
        :slide="slide"
        sticky
        :show-header="!embedded"
        :title="badgeLabel"
    >
        <template v-if="!loading">
            <div class="mentions">
                {{ mentionCount }} mention{{ mentionCount > 1 ? 's' : '' }}
            </div>
            <div
                v-for="response in responses"
                :key="response.id"
                class="response"
            >
                <ScorecardResponseTile
                    class="bottom-margin-between-tiles"
                    :response="response"
                    :show-badge="false"
                    :scorecard="scorecard"
                    @click="onClickResponse(response.id.toString())"
                />
            </div>
        </template>
        <Loading v-if="loading" :loading="loading" />
    </ScorecardSubpage>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { useRoute, useRouter } from 'vue-router'
import Loading from '@/components/Loading.vue'
import ScorecardSubpage from '@/pages/scorecard/components/ScorecardSubpage.vue'
import { Action, Getter } from 'vuex-facing-decorator'
import { ScorecardEntity } from '@/entities/scorecard'
import { IResponse } from '@/entities'
import { seeBadge } from '@/api/scorecard'
import { embedded } from '@/utils/react-native-webview'
import ScorecardResponseTile from '@/components/ScorecardResponseTile.vue'

@Component({
    components: { ScorecardResponseTile, ScorecardSubpage, Loading },
})
export default class ScorecardRoomToImproveDetail extends Vue {
    @Prop({ type: String })
    public value!: string // the room to improve badge

    @Prop({ type: String, default: null })
    public teamMemberUserId!: string

    @Getter public slide!: string
    @Getter public scorecard!: ScorecardEntity
    @Getter public scorecardResponsesMap!: { [value: string]: IResponse[] }
    @Getter public scorecardOutdated!: boolean
    @Getter
    hasNetworkConnection!: boolean

    @Getter
    $isMobile

    @Action public loadScorecard
    @Action public loadResponsesForBadge

    private route = useRoute()
    private router = useRouter()

    public get responses() {
        return (
            (this.scorecardResponsesMap &&
                this.scorecardResponsesMap[this.mapKey]) ||
            []
        )
    }

    public mounted() {
        this.load()
    }

    public get badgeLabel() {
        if (!this.scorecard) {
            return 'Loading...'
        }
        return this.scorecard.badges.negative[this.value]?.label || this.value
    }

    public get mapKey() {
        return `${this.value}_negative`
    }

    public async load() {
        if (!this.scorecard.badges.negative[this.value]) {
            await this.$router.push({ path: '/scorecard/room_to_improve' })
        }
        if (this.scorecardOutdated) {
            await this.loadScorecard(this.teamMemberUserId ?? null)
        }

        if (
            !this.scorecardResponsesMap ||
            !this.scorecardResponsesMap[this.mapKey]
        ) {
            await this.loadResponsesForBadge({
                type: 'negative',
                value: this.value,
            })
        }

        // update the last seen timestamp of this badge
        if (
            !this.teamMemberUserId &&
            this.scorecard.badges.negative[this.value]?.countUnseen &&
            this.hasNetworkConnection
        ) {
            await seeBadge(this.value, 'negative')
            this.loadScorecard()
        }
    }

    public get loading() {
        return !this.responses.length
    }

    public get mentionCount() {
        return this.scorecard.badges.negative[this.value].count
    }

    public get embedded() {
        return embedded
    }

    public onClickResponse(id) {
        if (this.$isMobile) {
            this.router.push({ name: 'chatResponsesRoot', params: { id: id } })
        } else {
            const query = { ...this.route.query, chat: id }
            this.router.push({ path: this.route.path, query })
        }
    }
}
</script>

<style lang="less" scoped>
@import '../../styles/layout.less';
@import '../../styles/palette.less';

.response {
    padding-left: 8px;
    padding-right: 8px;

    .bottom-margin-between-tiles {
        margin-bottom: 12px;
    }
}

.mentions {
    font-size: 13px;
    line-height: 20px;
    color: #93a1be;
    text-align: center;
    padding-top: 12px;
}
</style>
