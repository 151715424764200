<template>
    <div class="month-picker-container">
        <div class="left">&nbsp;</div>
        <div class="center">
            <div v-if="monthPicker.months.length > 0" class="month-picker">
                <select
                    ref="monthPicker"
                    :value="selectedMonth"
                    class="dropdown-button"
                    @change="selectMonth"
                >
                    <option
                        v-for="(month, idx) in monthPicker.months"
                        :key="idx"
                        :value="month"
                    >
                        {{ getMonthText(month) }}
                    </option>
                </select>
                <FontAwesomeIcon class="chevron" :icon="faChevronDown" />
            </div>
            <div
                v-show="monthPicker.months.length === 0"
                class="month-picker-placeholder"
            ></div>
        </div>
        <div class="right">
            <div>
                <button class="x-close-button" @click="closePopup">
                    <FontAwesomeIcon class="xmark" :icon="faXmark" />
                </button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { nextTick } from 'vue'
import { Component, Prop, Watch, Vue } from 'vue-facing-decorator'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faChevronDown } from 'fontawesome/free-solid-svg-icons'
import { faXmark } from 'fontawesome/free-solid-svg-icons'
import { IMonthPicker } from '@/entities/reportcard'
import moment from 'moment'
import Button from '@/components/WebView/Button.vue'
import { getPlatform } from '@/mobile/src/utils/capacitor'

@Component({
    components: {
        Button,
        FontAwesomeIcon,
    },
    emits: ['monthChanged'],
})
export default class MonthPicker extends Vue {
    @Prop({ type: Object }) public monthPicker!: IMonthPicker
    @Prop({ type: Function, required: false }) private closePopup!: Function

    readonly faChevronDown = faChevronDown
    readonly faXmark = faXmark
    private platform = 'desktop'

    public get selectedMonth() {
        if (
            !this.monthPicker.selected ||
            !this.monthPicker.months.includes(this.monthPicker.selected)
        ) {
            // Fallback to first available month if selected is not in choices.
            return this.monthPicker.months[0]
        }

        return this.monthPicker.selected
    }

    public async mounted() {
        this.platform = await getPlatform()
        this.resizeDropdown(this.$refs.monthPicker)
    }

    @Watch('monthPicker.months', { deep: true })
    public onMonthsChange() {
        nextTick(() => {
            this.resizeDropdown(this.$refs.monthPicker)
        })
    }

    /**
     * Given a month number (1-12), returns in this example format: "January 2023"
     */
    private getMonthText(month: number): string {
        let result = moment()
            .month(month - 1)
            .format('MMMM')
        const currentMonth: number = parseInt(moment().format('M'))
        const currentYear: number = parseInt(moment().format('YYYY'))

        if (month < currentMonth) {
            result += ' ' + currentYear
        } else {
            result += ' ' + (currentYear - 1)
        }

        return result
    }

    private selectMonth(event): void {
        this.resizeDropdown(event.target)
        this.$emit('monthChanged', event.target.value)
    }

    /**
     * Resizes dropdown by drawing a temporary duplicate hidden <select> with the new option,
     * then copying its width into our actual dropdown.
     */
    private resizeDropdown(dropdown): void {
        if (dropdown?.selectedOptions[0] === undefined) {
            // This happens if you load the page straight into a month that should not be selectable.
            return
        }

        let tempOption = document.createElement('option')
        tempOption.textContent = dropdown.selectedOptions[0].textContent

        let tempSelect = document.createElement('select')
        tempSelect.style.visibility = 'hidden'
        tempSelect.style.position = 'fixed'
        tempSelect.appendChild(tempOption)

        dropdown.after(tempSelect)
        dropdown.style.width = `${+tempSelect.clientWidth + 10}px`

        if (this.platform === 'ios' || this.platform === 'android') {
            dropdown.style.marginRight = '10px'
        } else {
            dropdown.style.marginRight = '4vw'
        }

        tempSelect.remove()
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/typography.less';
@import '~@/styles/rain/variables.less';

.month-picker-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    font-family: @fontFamily;
    padding-bottom: 10px;
    padding-top: 20px;

    .left {
        width: 40px;
    }

    .center {
        .month-picker {
            color: @white;
            display: inline-block;

            .dropdown-button {
                text-align: right;
                appearance: none;
                background-color: transparent;
                border: none;
                color: @white;
                cursor: inherit;
                font-family: inherit;
                line-height: inherit;
                margin: 0;

                font-size: @fontSize-sm;
                letter-spacing: @letterSpacing-2xl;
                text-transform: uppercase;
            }

            .chevron {
                width: 10px;
                position: relative;
                left: -3vw;
                pointer-events: none;
            }
        }

        .month-picker-placeholder {
            height: 19px;
        }
    }

    .right {
        .x-close-button {
            border: none;
            background: none;
            color: white;
            padding-right: 10px;
        }
        .xmark {
            width: 18px;
            height: 18px;
        }
    }
}
</style>
