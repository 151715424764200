import { ActionTree, MutationTree, GetterTree } from 'vuex'
import {
    ComponentResponse,
    StepCustomizedComponent,
    StepEntity,
} from '@/entities/steps'

export interface StepsState {
    isSubSteps: boolean
    currentStep?: StepEntity
    finishedSteps: StepEntity[]
    allSteps: StepEntity[]
    isEditing: boolean
    uploadToken: string
}

export const state: StepsState = {
    isSubSteps: false,
    currentStep: undefined,
    finishedSteps: [],
    allSteps: [],
    isEditing: false,
    uploadToken: '',
}

const getters: GetterTree<StepsState, any> = {
    isSubSteps({ isSubSteps }) {
        return isSubSteps
    },

    currentStep({ currentStep }) {
        return currentStep
    },

    nextStep({ currentStep, allSteps }) {
        if (currentStep !== undefined && currentStep.position !== undefined) {
            if (allSteps.length === currentStep.position) {
                return null
            } else {
                return allSteps[currentStep.position]
            }
        }

        return allSteps[0] !== undefined ? allSteps[0] : null
    },

    finishedSteps({ finishedSteps }) {
        return finishedSteps
    },

    previousFinishedStep({ finishedSteps }) {
        if (finishedSteps.length > 0) {
            return finishedSteps[finishedSteps.length - 1]
        } else {
            return null
        }
    },

    allSteps({ allSteps }) {
        return allSteps
    },

    isEditing({ isEditing }) {
        return isEditing
    },

    isComplete({ allSteps, finishedSteps }) {
        return allSteps.length === finishedSteps.length
    },

    isLastStep({ currentStep, allSteps }) {
        if (currentStep === undefined || currentStep.position === undefined) {
            return false
        }

        return allSteps.length === currentStep.position
    },

    stepsCount({ allSteps }) {
        return allSteps.length
    },

    finishedStepsCount({ finishedSteps }) {
        return finishedSteps.length
    },

    currentPosition({ currentStep }) {
        return currentStep !== undefined && currentStep.position !== undefined
            ? currentStep.position
            : 1
    },

    currentLabel({ currentStep }) {
        return currentStep !== undefined && currentStep.label !== undefined
            ? currentStep.label
            : ''
    },

    currentKey({ currentStep }) {
        return currentStep !== undefined ? currentStep.key : ''
    },

    currentTitle({ currentStep }) {
        return currentStep !== undefined ? currentStep.title : ''
    },

    currentFinished({ currentStep }) {
        return currentStep !== undefined ? currentStep.finished : false
    },

    currentComponent({ currentStep }) {
        return currentStep !== undefined ? currentStep.component : null
    },

    currentComponentParams({ currentStep }) {
        return currentStep !== undefined ? currentStep.component.params : null
    },

    currentResponse({ currentStep }) {
        return currentStep !== undefined &&
            currentStep.component.response !== undefined &&
            currentStep.component.response.current !== undefined
            ? currentStep.component.response.current
            : null
    },

    previousResponse({ currentStep }) {
        return currentStep !== undefined &&
            currentStep.component.response !== undefined &&
            currentStep.component.response.previous !== undefined
            ? currentStep.component.response.previous
            : null
    },
}

const actions: ActionTree<StepsState, any> = {
    setIsSubSteps({ commit }, isSubSteps: boolean) {
        commit('setIsSubSteps', isSubSteps)
    },

    setCurrentStep({ commit }, currentStep?: StepEntity) {
        commit('setCurrentStep', currentStep)
    },

    setFinishedStep({ commit }, finishedStep: StepEntity) {
        commit('setFinishedStep', finishedStep)
    },

    setAllSteps({ commit }, allSteps: StepEntity[]) {
        commit('setAllSteps', allSteps)
    },

    setIsEditing({ commit }, isEditing: boolean) {
        commit('setIsEditing', isEditing)
    },

    setCurrentPosition({ commit }, position: number) {
        commit('setCurrentPosition', position)
    },

    setCurrentLabel({ commit }, label: string) {
        commit('setCurrentLabel', label)
    },

    setCurrentKey({ commit }, key: string) {
        commit('setCurrentKey', key)
    },

    setCurrentTitle({ commit }, title: string) {
        commit('setCurrentTitle', title)
    },

    setCurrentFinished({ commit }, finished: boolean) {
        commit('setCurrentFinished', finished)
    },

    setCurrentComponent({ commit }, component: StepCustomizedComponent) {
        commit('setCurrentComponent', component)
    },

    setCurrentComponentParams({ commit }, params: any) {
        commit('setCurrentComponentParams', params)
    },

    setCurrentResponse({ commit }, response: any) {
        commit('setCurrentResponse', response)
    },

    setPreviousResponse({ commit }, response: any) {
        commit('setPreviousResponse', response)
    },

    resetStepsState({ commit }) {
        commit('resetStepsState')
    },
}

const mutations: MutationTree<StepsState> = {
    setIsSubSteps(state, isSubSteps: boolean) {
        state.isSubSteps = isSubSteps
    },

    setCurrentStep(state, currentStep?: StepEntity) {
        state.currentStep = currentStep
        if (state.currentStep !== undefined) {
            const count = state.finishedSteps.length
            if (count > 0) {
                const previousFinishedStep = state.finishedSteps[count - 1]
                if (
                    previousFinishedStep.component.response !== undefined &&
                    previousFinishedStep.component.response.current !==
                        undefined
                ) {
                    if (state.currentStep.component.response === undefined) {
                        state.currentStep.component.response =
                            {} as ComponentResponse
                    }
                    state.currentStep.component.response.previous =
                        previousFinishedStep.component.response.current
                }
            }
        }
    },

    setFinishedStep(state, finishedStep: StepEntity) {
        finishedStep.finished = true
        const newFinishedSteps = [] as StepEntity[]
        let hasNextStep = false

        for (let i = 0; i < state.allSteps.length; i++) {
            if (finishedStep.position === state.allSteps[i].position) {
                state.allSteps[i].finished = true
                newFinishedSteps.push(state.allSteps[i])
            }

            if (
                i >= 1 &&
                state.allSteps[i - 1].component?.response?.current !== undefined
            ) {
                // @ts-ignore
                state.allSteps[i].component.response.previous =
                    // @ts-ignore
                    state.allSteps[i - 1].component.response.current
            }

            // move to next unfinished step
            if (!hasNextStep && state.allSteps[i].finished === false) {
                state.currentStep = state.allSteps[i]
                hasNextStep = true
            }
        }

        if (!hasNextStep) {
            state.currentStep = state.allSteps[state.allSteps.length - 1]
        }
        state.finishedSteps = newFinishedSteps
    },

    setAllSteps(state, allSteps: StepEntity[]) {
        const finishedSteps = [] as StepEntity[]

        state.currentStep = undefined

        for (let i = 0; i < allSteps.length; i++) {
            allSteps[i].position = i + 1
            if (allSteps[i].label === undefined) {
                allSteps[i].label = String(i + 1)
            }
            if (allSteps[i].finished === undefined) {
                allSteps[i].finished = false
            }
            if (allSteps[i].component.response === undefined) {
                allSteps[i].component.response = {
                    previous: null,
                    current: null,
                } as ComponentResponse
            } else {
                // @ts-ignore
                if (allSteps[i].component.response.previous === undefined) {
                    // @ts-ignore
                    allSteps[i].component.response.previous = null
                }
                // @ts-ignore
                if (allSteps[i].component.response.current === undefined) {
                    // @ts-ignore
                    allSteps[i].component.response.current = null
                }
            }
            if (
                state.currentStep === undefined &&
                allSteps[i].finished === false
            ) {
                state.currentStep = allSteps[i]
            }
            if (allSteps[i].finished === true) {
                finishedSteps.push(allSteps[i])
            }
        }
        if (
            state.currentStep === undefined &&
            allSteps[allSteps.length - 1] !== undefined
        ) {
            state.currentStep = allSteps[allSteps.length - 1]
        }
        state.allSteps = allSteps
        state.finishedSteps = finishedSteps
    },

    setIsEditing(state, isEditing: boolean) {
        state.isEditing = isEditing
    },

    setCurrentPosition(state, position: number) {
        if (state.currentStep !== undefined) {
            state.currentStep.position = position
        }
    },

    setUploadToken(state, uploadToken: string) {
        if (state.uploadToken !== undefined) {
            state.uploadToken = uploadToken
        }
    },

    setCurrentLabel(state, label: string) {
        if (state.currentStep !== undefined) {
            state.currentStep.label = label
        }
    },

    setCurrentKey(state, key: string) {
        if (state.currentStep !== undefined) {
            state.currentStep.key = key
        }
    },

    setCurrentTitle(state, title: string) {
        if (state.currentStep !== undefined) {
            state.currentStep.title = title
        }
    },

    setCurrentFinished(state, finished: boolean) {
        if (state.currentStep !== undefined) {
            state.currentStep.finished = finished
        }
    },

    setCurrentComponent(state, component: StepCustomizedComponent) {
        if (state.currentStep !== undefined) {
            state.currentStep.component = component
        }
    },

    setCurrentComponentParams(state, params: any) {
        if (state.currentStep !== undefined) {
            state.currentStep.component.params = params
        }
    },

    setCurrentResponse(state, response: any) {
        if (
            state.currentStep !== undefined &&
            state.currentStep.component.response !== undefined
        ) {
            state.currentStep.component.response.current = response
        }
    },

    setPreviousResponse(state, response: any) {
        if (
            state.currentStep !== undefined &&
            state.currentStep.component.response !== undefined
        ) {
            state.currentStep.component.response.previous = response
        }
    },

    resetStepsState(state) {
        state.isSubSteps = false
        state.currentStep = undefined
        state.finishedSteps = []
        state.allSteps = []
        state.isEditing = false
        state.uploadToken = ''
    },
}

export default {
    namespaced: false,
    state,
    actions,
    mutations,
    getters,
}
