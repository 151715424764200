<template>
    <div
        class="message-bubble"
        :class="{ left: direction === 'left', right: direction === 'right' }"
        :style="`background: ${bgColor};`"
    >
        <slot />
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component({
    components: {},
})
export default class MessageBubble extends Vue {
    @Prop({ type: String, default: 'left' })
    direction!: 'left' | 'right'

    @Prop({ type: String, default: 'white' })
    bgColor!: string
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';

.message-bubble {
    max-width: 80%;
    padding: @gutterSpacing-base @gutterSpacing-md @gutterSpacing-base
        @gutterSpacing-md;

    &.left {
        border-radius: @borderRadius-base*3 @borderRadius-base*3
            @borderRadius-base*3 0;
    }

    &.right {
        border-radius: @borderRadius-base*3 @borderRadius-base*3 0
            @borderRadius-base*3;
    }
}
</style>
