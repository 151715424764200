<template>
    <div class="container">
        <div class="login-form">
            <div class="inputs">
                <div @keyup.enter="() => $refs.nextBtn.onClick()">
                    <Input
                        id="inputDomain"
                        v-model="domain"
                        placeholder="Domain"
                        title="Domain"
                        :required="true"
                        autocomplete="off"
                        err-msg="Domain is required"
                        :regex="domainRegex"
                    />
                </div>
            </div>

            <TransparentButton
                ref="nextBtn"
                class="btn-login"
                text="Next"
                :click="onLogin"
            />

            <p class="white">
                Back to
                <RouterLink
                    class="find-login white"
                    :to="{ name: 'findlogin' }"
                    replace
                    >request a magic link
                </RouterLink>
            </p>
            <AppVersion class="app-v" />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { useRouter } from 'vue-router'
import Input from '@/components/Input.vue'
import TransparentButton from '@/mobile/src/components/TransparentButton.vue'
import { checkDomain } from '@/mobile/src/api/auth'
import AppVersion from '@/mobile/src/components/AppVersion.vue'
import { LooseObject } from '@/pages/appstore/entities/app'

@Component({
    components: {
        AppVersion,
        TransparentButton,
        Input,
    },
})
export default class CheckDomainLogin extends Vue {
    private domain = ''
    private domainRegex = /^\w+$/

    private router = useRouter()

    private async onLogin() {
        this.domain = this.domain.trim()
        if (!this.domainRegex.test(this.domain)) {
            return alert('Domain is invalid.')
        }
        const samlLoginInfo = await checkDomain(this.domain)
        if (!samlLoginInfo.success) {
            return alert("We couldn't find this domain.")
        }

        const query: LooseObject = {
            domain: this.domain,
        }

        if (samlLoginInfo.data.samlInfo) {
            query.samlSsoName = samlLoginInfo.data.samlInfo.samlSsoName
            query.samlSsoUrl = samlLoginInfo.data.samlInfo.samlSsoUrl
        }

        query.disableEmailLogin = samlLoginInfo.data.disableEmailLogin

        await this.router.replace({ name: 'login', query })
    }
}
</script>
<style lang="less" scoped>
@import '@/styles/palette';

.login-form {
    .white {
        color: white;
    }

    .btn-login {
        margin-top: 15px;
        width: 100%;
    }

    .app-v {
        margin-top: 10px;
    }
}
</style>
