import { FilterOptionV4, IFilterTime } from '@/entities'

/**
 * Shim to translate labels and values against some time filters returned by the backend.
 */
export function getTranslatedTimeFilterOption(
    filterTime: IFilterTime
): FilterOptionV4 {
    // Bit haxy way to fit time filters into existing filter component.
    // Time filter values on the frontend will be concat strings, like 'ytd:1' or 'week:2'.
    // time_value is generally just '1' for units where it doesn't make sense, like 'today'.
    const timeFilterOption: FilterOptionV4 = {
        label: filterTime.label,
        value: filterTime.time_unit + ':' + filterTime.time_value,
    }

    switch (timeFilterOption.label) {
        case 'custom':
            // we don't need to incorporate the custom date range into the elements value
            timeFilterOption.label = 'custom'
            timeFilterOption.value = 'custom'
            break
        case 'week':
            timeFilterOption.label = 'Last Week'
            break
        case '2 weeks':
            timeFilterOption.label = 'Last 2 Weeks'
            break
        case '1 month':
            timeFilterOption.label = 'Last 30 Days'
            timeFilterOption.value = 'day:30'
            break
        case '2 months':
            timeFilterOption.label = 'Last 60 Days'
            timeFilterOption.value = 'day:60'
            break
        case '3 months':
            timeFilterOption.label = 'Last 90 Days'
            timeFilterOption.value = 'day:90'
            break
        case '6 months':
            timeFilterOption.label = 'Last 180 Days'
            timeFilterOption.value = 'day:180'
            break
        case '12 months':
            timeFilterOption.label = 'Last Year'
            break
        case 'jan':
        case 'feb':
        case 'mar':
        case 'apr':
        case 'may':
        case 'jun':
        case 'jul':
        case 'aug':
        case 'sep':
        case 'oct':
        case 'nov':
        case 'dec':
            timeFilterOption.label = 'Last ' + timeFilterOption.label
            break
    }

    return timeFilterOption
}
