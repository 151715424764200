import CompanyStore from '@/plugins/an-common-store'
import { palette } from '@/utils/palette'

//Declared in master.html to pass us the whitelisted variables.
declare global {
    interface Window {
        ASKNICELY_COMPANY_VARS
    }
}

// TODO: Update this when we hit 3.x.
//Accepts two options; store is expected, ismobile is not.
export default {
    CompanyStore,
    install: (Vue, options) => {
        options.store.registerModule('$companyStore', CompanyStore)
        // Mixin approach
        Vue.mixin({
            computed: {
                $companyVars: () => {
                    return options.store.getters.$companyVars
                },
                $isMobile: () => {
                    return options.store.getters.$isMobile
                },
                $palette: () => {
                    return palette
                },
                $isIndevTenant: () => {
                    return options.store.getters.$isIndevTenant
                },
            },
        })

        const ismobile = options && options.ismobile
        if (ismobile) {
            // Otherwise comes from getting the user; would prefer it here
        } else {
            options.store.commit(
                '$setCompanyData',
                window.ASKNICELY_COMPANY_VARS !== undefined
                    ? window.ASKNICELY_COMPANY_VARS
                    : {}
            )
        }
        options.store.commit('$setIsMobile', ismobile)

        //TODO: Remove this once we achieve two goals: reactivity in components that will support companyVars,
        // and we have companyVars being piggybacked in off of one of our other calls - notifications is a prime
        // candidate.
        /*setInterval(async () => {
            const result = await getWhitelistFiltered()
            if(result.status === 200) {
                CompanyStore.commit('$setCompanyData', result.data)
            }
        }, 30000)*/
    },
}
