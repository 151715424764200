import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{
            'best-topic-tag': _ctx.positive,
            'worst-topic-tag': !_ctx.positive,
            desktop: !_ctx.$isMobile,
        }, "topic-tag"])
  }, _toDisplayString(_ctx.title), 3))
}