import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-761d86a7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "quote-container" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextClamp = _resolveComponent("TextClamp")!
  const _component_Typography = _resolveComponent("Typography")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(['vertical-line', { hide: _ctx.noGap }])
    }, null, 2),
    _createElementVNode("div", {
      ref: "comment",
      class: _normalizeClass(['quote-content', { 'no-gap': _ctx.noGap }])
    }, [
      _createVNode(_component_Typography, { variant: _ctx.variant }, {
        default: _withCtx(() => [
          _createVNode(_component_TextClamp, {
            autoresize: "",
            "max-lines": _ctx.maxLines,
            text: _ctx.text,
            onClampchange: _ctx.quoteClampChange
          }, {
            after: _withCtx(({ toggle, expanded, clamped }) => [
              (expanded || clamped)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: "clamp-toggle",
                    onClick: toggle
                  }, _toDisplayString(clamped ? 'Read more' : 'Read less'), 9, _hoisted_2))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["max-lines", "text", "onClampchange"])
        ], undefined, true),
        _: 1
      }, 8, ["variant"])
    ], 2)
  ]))
}