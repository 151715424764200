<template>
    <div class="container">
        <div v-if="newMomentsCount > 0" class="new-moments-pill-container">
            <BasePillButton class="new-moments-pill">
                <span class="view-new-moments" @click="showNew">{{
                    newMomentsMessage
                }}</span>
                <span class="close-new-moments-pill" @click="rejectNew"><FontAwesomeIcon
                        class="close-icon"
                        size="lg"
                        :icon="closeIcon"
                /></span>
            </BasePillButton>
        </div>
        <PullToRefreshWrapper
            :ptr-refreshing="isReloading"
            :disable="!hasNetworkConnection"
            refresh-container-classes="moment-pull-to-refresh"
            @refresh-action="reloadData"
        >
            <div
                v-if="
                    emptyMomentsData ||
                    momentsDataError ||
                    !hasNetworkConnection
                "
                class="empty-data"
            >
                <OfflinePage v-if="!hasNetworkConnection" />
                <ErrorPage v-else-if="momentsDataError" />
                <EmptyPageMoments v-else-if="emptyMomentsData" />
            </div>
            <template v-else>
                <InfiniteScroll
                    scroll-container-classes="moment-infinite-scroll"
                    :load-more="loadMore"
                    :is-loading-more="isLoading"
                    :has-reached-end="hasReachedEnd"
                >
                    <div v-if="showMoments" class="moments-container">
                        <MomentCard
                            v-for="moment in getMoments"
                            :key="moment.id"
                            :moment="moment"
                            @viewDetail="navigateToDetail(moment)"
                        />
                    </div>
                    <template #loading>
                        <LoadingCard
                            v-for="i in getMoments.length > 0 ? 3 : 5"
                            :key="i"
                            :borderless="true"
                            variant="single"
                            class="loading-single"
                        />
                    </template>
                </InfiniteScroll>
            </template>
        </PullToRefreshWrapper>
        <PlusButton
            v-if="showCreateNewMomentButton"
            :on-click="() => openMessageComposer()"
        />
        <Portal v-if="showPopup" to="over-card-destination">
            <MomentModal
                :close-popup="() => closeMessageComposer()"
                :modal-source="ModalSource.MomentsScreen"
                @message-sent="onMessageSent"
            />
        </Portal>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { namespace } from 'vuex-facing-decorator'
import { useRoute, useRouter } from 'vue-router'
import useEmitter from '@/composables/useEmitter'
import { Action, Getter } from 'vuex-facing-decorator'
import { Portal } from 'portal-vue'
import MomentModal from '@/mobile/src/components/appV4/MomentModal.vue'
import PlusButton from '@/mobile/src/components/appV4/PlusButton.vue'
import MomentCard from '@/mobile/src/components/appV4/MomentCard.vue'
import BasePillButton from '@/components/BasePillButton.vue'
import { ModalSource } from '@/mobile/src/types/messageTypes'
import { Moment } from '@/entities/moment'
import InfiniteScroll from '@/mobile/src/components/appV4/InfiniteScroll.vue'
import EmptyPageMoments from '@/mobile/src/components/appV4/EmptyPageMoments.vue'
import ErrorPage from '@/mobile/src/components/appV4/ErrorPage.vue'
import { faClose } from 'fontawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import PullToRefreshWrapper from '@/mobile/src/components/appV4/PullToRefreshWrapper.vue'
import LoadingCard from '@/mobile/src/components/appV4/LoadingCard.vue'
import { pendoTrackEvent } from '@/utils/pendo'
import { scrollToTop } from '../../utils/scrollbus'
import OfflinePage from '@/mobile/src/components/appV4/OfflinePage.vue'

const MomentsModule = namespace('moments')

@Component({
    components: {
        OfflinePage,
        LoadingCard,
        PullToRefreshWrapper,
        ErrorPage,
        InfiniteScroll,
        EmptyPageMoments,
        MomentCard,
        MomentModal,
        Portal,
        PlusButton,
        BasePillButton,
        FontAwesomeIcon,
    },
})
export default class Moments extends Vue {
    ModalSource = ModalSource

    @MomentsModule.Getter getMoments!: Moment[]
    @MomentsModule.Getter getPolledMoments!: Moment[]
    @MomentsModule.Getter hasMoments!: boolean
    @MomentsModule.Getter hasReachedEnd!: boolean
    @MomentsModule.Getter isLoading!: boolean
    @MomentsModule.Getter momentsDataError!: boolean
    @MomentsModule.Getter emptyMomentsData!: boolean
    @MomentsModule.Action fetchMoments

    @MomentsModule.Action setTypePickerModalOpen
    @MomentsModule.Action pollMoments
    @MomentsModule.Action resetPolledMoments
    @MomentsModule.Action setMomentSeen

    @Action public saveScrollPosition
    @Getter hasNetworkConnection!: boolean
    @Getter permissions!: string[]

    private route = useRoute()
    private router = useRouter()
    private emitter = useEmitter()

    public showPopup = false
    private momentsPollInterval: any
    public isReloading = false
    public showMoments = true

    public closeIcon = faClose

    public async navigateToDetail(moment: Moment) {
        this.setMomentSeen(moment.id)
        // More visual smoothness if we hide the moments list before animating page transition
        this.showMoments = false

        // Save moments home page scroll position
        const pageContent = document.getElementById(
            'page-content'
        ) as HTMLElement
        await this.saveScrollPosition({
            page: this.route.name,
            scrollTop: pageContent?.scrollTop ?? 0,
        })

        if (moment?.question?.id) {
            await this.router.push({
                name: 'momentsFeedbackDetail',
                params: { feedbackid: moment.question.id.toString() },
            })
        } else {
            await this.router.push({
                name: 'momentdetails',
                params: { momentid: moment.id.toString() },
            })
        }
    }

    public openMessageComposer() {
        this.showPopup = true
        pendoTrackEvent(`message_composer_initialised`, {
            source: ModalSource.MomentsScreen,
        })
    }

    public closeMessageComposer() {
        this.showPopup = false
        this.setTypePickerModalOpen(false)
    }

    public async reloadData() {
        this.isReloading = true
        await this.loadData(true)
        this.isReloading = false
    }

    public get showCreateNewMomentButton() {
        return (
            this.hasNetworkConnection &&
            this.permissions?.includes('ROLE_SEND_NOTICES')
        )
    }

    public async loadMore() {
        await this.loadData()
    }

    async created() {
        if (!this.hasMoments) {
            await this.loadData()
        }

        if (!this.momentsDataError) {
            this.momentsPollInterval = setInterval(() => {
                this.pollMoments()
            }, 30000) // Poll for new moments every 30 seconds
        }
    }

    public showNew() {
        scrollToTop(this.emitter, 'smooth')
        this.resetPolledMoments()
    }

    public async onMessageSent() {
        await this.pollMoments()
        this.showNew()
    }

    public rejectNew() {
        this.resetPolledMoments()
    }

    public async beforeDestroy() {
        if (this.momentsPollInterval) {
            await clearInterval(this.momentsPollInterval)
        }
    }

    private async loadData(reset = false) {
        await this.fetchMoments(reset)
    }

    public get newMomentsCount() {
        return this.getPolledMoments.length
    }

    public get newMomentsMessage() {
        let message = `Load ${this.newMomentsCount} new moment`
        return this.newMomentsCount > 1 ? message + 's' : message
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/palette';
@import '~@/styles/variables.less';
@import '~@/styles/rain/variables.less';
@tabSpacing: 50px;
@momentsBottomSpacing: 20px;

.container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.empty-data {
    height: calc(100vh - @mobileNavHeightPadded - @filterHeaderHeight);
}

.moments-container {
    margin-bottom: @momentsBottomSpacing;
    padding-top: 10px;
}

.new-moments-pill-container {
    position: fixed;
    left: 0;
    top: calc(@mobileNavHeightPadded + env(safe-area-inset-top));
    margin: auto;
    text-align: center;
    width: 100%;

    .pill-button {
        color: @indigo;
        font-family: 'Roboto', Helvetica, Arial, 'Lucida Grande', sans-serif;
        line-height: 18px;
        letter-spacing: 1px;
        text-transform: uppercase;
    }
}

.new-moments-pill {
    border-radius: 50px;
    border-right: none;
    //Let internals control left/right padding
    padding: 4px 0;
}

.view-new-moments {
    //Use padding to control clickable area
    padding: 4px 4px 4px 15px;
}

.close-new-moments-pill {
    //Use padding to control clickable area
    padding: 4px 15px 4px 13px;
}

#infinite-scroll-container.moment-infinite-scroll {
    margin-bottom: @tabSpacing;
}
</style>
