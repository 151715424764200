<template>
    <transition
        v-if="
            showTeamProfileSelect &&
            teamProfileUsers &&
            filterOptions.length > 0
        "
        name="slide-team-profile"
        appear
    >
        <div class="team-profile-select-popup">
            <TeamProfileSelect />
        </div>
    </transition>
</template>

<script lang="ts">
import { Component, Watch, Vue } from 'vue-facing-decorator'
import { namespace } from 'vuex-facing-decorator'
import AvatarOrInitials from '@/components/WebView/AvatarOrInitials.vue'
import TeamProfileSelect from '@/mobile/src/components/appV4/TeamProfileSelect.vue'
import { TeamProfileUser } from '@/entities/reportcard'

const ReportCardModule = namespace('reportcard')
const TeamFilterModule = namespace('teamFilter')

@Component({
    components: {
        AvatarOrInitials,
        TeamProfileSelect,
    },
})
export default class TeamProfileSelectPopup extends Vue {
    @ReportCardModule.Getter showTeamProfileSelect!: boolean
    @ReportCardModule.Action loadTeamProfileUsers!: () => Promise<void>
    @ReportCardModule.Getter teamProfileUsers!: TeamProfileUser[]

    @TeamFilterModule.Getter filterOptions

    @Watch('showTeamProfileSelect')
    public async onShowTeamProfileSelect() {
        if (this.showTeamProfileSelect) {
            await this.loadTeamProfileUsers()
        }
    }
}
</script>

<style scoped lang="less">
.team-profile-select-popup {
    width: 100%;
    height: calc(106px + env(safe-area-inset-top));
    top: 0;
    position: fixed;
    left: 0;
    z-index: 1;
    background: none;
}

.slide-team-profile-enter-from {
    transform: translate(0, -100%);
}

.slide-team-profile-leave-to {
    transform: translate(0, -100%);
}

.slide-team-profile-enter-active {
    animation: slide 0.3s backwards;
    transition: 0.3s;
}

.slide-team-profile-leave-active {
    animation: slide 0.5s forwards;
    transition: 0.5s;
}
</style>
