<template>
    <div :class="['empty-state-container', colour]">
        <slot name="icon-img" />
        <Typography variant="h4" component="h4">
            <slot name="header-text" />
        </Typography>
        <Typography variant="body2" class="empty-page-text">
            <slot name="body-text" />
        </Typography>
        <button v-if="hasButtonSlot" class="action-button">
            <Typography variant="overline" class="action-button-text">
                <slot name="action-button" />
            </Typography>
        </button>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import Typography from '@/components/Rain/Typography/Typography.vue'

@Component({
    components: {
        Typography,
    },
})
export default class EmptyPageState extends Vue {
    @Prop({ type: String, default: 'default' })
    public readonly colour?: 'default' | 'white'

    public get hasButtonSlot() {
        return !!this.$slots['action-button']
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/variables.less';
@import '~@/styles/rain/colour.less';

.empty-state-container {
    height: 100%;
    padding: 0 32px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    flex-grow: 5;
}

img,
svg {
    width: 140px;
    padding-bottom: @featureGutter;
}

h4,
p {
    width: 100%;
    text-align: center;
}

h4 {
    padding-bottom: 10px;
}

.white {
    h4,
    p,
    svg {
        color: white;
    }
}

.action-button {
    margin-top: @featureGutter;
    padding: @gutterSpacing-base 18px;
    border-radius: 50px;
    border: 1px solid @lightGrey;
    background: @white;

    .action-button-text {
        color: @brandPurple;
    }
}
</style>

<style lang="less">
@import '~@/styles/rain/colour.less';

.empty-page-text a {
    color: @brandPurple;
}
</style>
