import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-282bd570"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 2,
  class: "homeV4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EngagementPopup = _resolveComponent("EngagementPopup")!
  const _component_PushPopTransitionContainer = _resolveComponent("PushPopTransitionContainer")!
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_TabbedContainer = _resolveComponent("TabbedContainer")!
  const _component_PortalTarget = _resolveComponent("PortalTarget")!
  const _component_AchievementSplash = _resolveComponent("AchievementSplash")!
  const _component_OfflineBanner = _resolveComponent("OfflineBanner")!
  const _component_PageContainer = _resolveComponent("PageContainer")!
  const _component_MobileNavigation = _resolveComponent("MobileNavigation")!
  const _component_TeamProfileSelectPopup = _resolveComponent("TeamProfileSelectPopup")!
  const _component_ReportCardPopup = _resolveComponent("ReportCardPopup")!
  const _component_OverCardPortalWrapper = _resolveComponent("OverCardPortalWrapper")!
  const _component_ShadowPortalWrapper = _resolveComponent("ShadowPortalWrapper")!
  const _component_ToastOverlay = _resolveComponent("ToastOverlay")!
  const _component_LoadingWithGradientBg = _resolveComponent("LoadingWithGradientBg")!

  return (_ctx.shouldShowEngagementModal)
    ? (_openBlock(), _createBlock(_component_EngagementPopup, { key: 0 }))
    : (_ctx.dataLoaded && !_ctx.userHasMlp)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(['home', { offline: !_ctx.hasNetworkConnection }])
        }, [
          _createVNode(_component_TabbedContainer, {
            tabs: _ctx.mainNavTabs,
            selected: _ctx.selectedTab,
            onClick: _ctx.onSwitchTab
          }, {
            default: _withCtx(() => [
              _createVNode(_component_RouterView, null, {
                default: _withCtx(({ Component }) => [
                  _createVNode(_component_PushPopTransitionContainer, null, {
                    default: _withCtx(() => [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                    ], undefined, true),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              })
            ], undefined, true),
            _: 1
          }, 8, ["tabs", "selected", "onClick"]),
          _createVNode(_component_PortalTarget, { name: "portal-destination" }),
          (_ctx.showAchievementSplash)
            ? (_openBlock(), _createBlock(_component_AchievementSplash, {
                key: 0,
                type: _ctx.unseenAchievementType,
                onClose: _ctx.onCloseAchievementSplash
              }, null, 8, ["type", "onClose"]))
            : _createCommentVNode("", true)
        ], 2))
      : (_ctx.dataLoaded)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_MobileNavigation, {
              tabs: _ctx.mainNavTabs,
              selected: _ctx.selectedTab,
              onClick: _ctx.onSwitchTab
            }, {
              default: _withCtx(() => [
                _createVNode(_component_PageContainer, { class: "overview" }, {
                  default: _withCtx(() => [
                    (_openBlock(), _createElementBlock("div", { key: _ctx.contentKey }, [
                      _createVNode(_component_RouterView, {
                        class: "header-router",
                        name: "header"
                      }, {
                        default: _withCtx(({ Component }) => [
                          _createVNode(_Transition, {
                            class: "transition-container",
                            name: _ctx.getRouteTransition,
                            onAfterEnter: _ctx.scrollToPagePosition
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                            ], undefined, true),
                            _: 2
                          }, 1032, ["name", "onAfterEnter"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_RouterView, {
                        class: _normalizeClass([
                            'default-router',
                            { offline: !_ctx.hasNetworkConnection },
                        ])
                      }, {
                        default: _withCtx(({ Component }) => [
                          _createVNode(_Transition, {
                            class: "transition-container",
                            name: _ctx.getRouteTransition,
                            onAfterEnter: _ctx.scrollToPagePosition
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                            ], undefined, true),
                            _: 2
                          }, 1032, ["name", "onAfterEnter"])
                        ]),
                        _: 1
                      }, 8, ["class"]),
                      _createVNode(_component_OfflineBanner, { selected: _ctx.selectedTab }, null, 8, ["selected"])
                    ]))
                  ], undefined, true),
                  _: 1
                })
              ], undefined, true),
              _: 1
            }, 8, ["tabs", "selected", "onClick"]),
            _createVNode(_component_TeamProfileSelectPopup),
            _createVNode(_component_ReportCardPopup),
            _createVNode(_component_OverCardPortalWrapper),
            _createVNode(_component_ShadowPortalWrapper),
            _createVNode(_component_ToastOverlay)
          ]))
        : (_openBlock(), _createBlock(_component_LoadingWithGradientBg, { key: 3 }))
}