<template>
    <WebViewScreen class="webview-container" no-pad show-go-back>
        <Scrollable :loading="scorecardLoading" :pull-down="load">
            <!-- smart notification -->
            <RequestNotificationPermissionPanel />

            <SwitchLayoutContainer>
                <template #compact>
                    <ScorecardPage :team-member-user-id="teamMemberUserId" />
                </template>

                <template #expanded>
                    <ScorecardPageExpanded
                        :team-member-user-id="teamMemberUserId"
                    />
                </template>
            </SwitchLayoutContainer>
        </Scrollable>

        <RouterLink
            :to="{
                name: 'notices-pick-type-user',
                params: { preselectedUserId: teamMemberUserId },
            }"
        >
            <ScreenBottomButton
                v-if="canSendNotices"
                :title="messageButtonText"
            />
        </RouterLink>
    </WebViewScreen>
</template>

<script lang="ts">
import Scrollable from '@/components/Scrollable.vue'
import WebViewScreen from '@/components/WebView/WebViewScreen.vue'
import SwitchLayoutContainer from '@/mobile/src/components/containers/SwitchLayoutContainer.vue'
import RequestNotificationPermissionPanel from '@/mobile/src/components/RequestNotificationPermissionPanel.vue'
import { Component, Vue } from 'vue-facing-decorator'
import ScorecardHomeUser from '@/pages/insights/ScorecardHomeUser'

@Component({
    components: {
        RequestNotificationPermissionPanel,
        Scrollable,
        SwitchLayoutContainer,
        WebViewScreen,
    },
})
export default class ScorecardHomeUserMobile extends ScorecardHomeUser {
    // Under certain circumstances the super properties won't load without calling it explicitly.  Basically just
    // being safe.
    constructor(opts) {
        super(opts, Vue)
    }
}
</script>

<style scoped lang="less">
@import '~@/styles/palette';

.webview-container {
    position: relative;
    background-color: @greyLight;
}
</style>
