import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-608a63da"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "team-scoreboard-screen" }
const _hoisted_2 = { class: "team-scoreboard-screen-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BackButton = _resolveComponent("BackButton")!
  const _component_TeamScoreboardKebab = _resolveComponent("TeamScoreboardKebab")!
  const _component_FilterBarMobile = _resolveComponent("FilterBarMobile")!
  const _component_TheTeamPerformanceBlock = _resolveComponent("TheTeamPerformanceBlock")!
  const _component_ScreenBottomButton = _resolveComponent("ScreenBottomButton")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_WebViewScreen = _resolveComponent("WebViewScreen")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_WebViewScreen, {
      title: "Team Scoreboard",
      "no-pad": ""
    }, {
      "left-button": _withCtx(() => [
        _createVNode(_component_BackButton)
      ]),
      "right-button": _withCtx(() => [
        _createVNode(_component_TeamScoreboardKebab)
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_FilterBarMobile, {
            "no-x-pad": "",
            class: "filter-bar"
          }),
          _createVNode(_component_TheTeamPerformanceBlock)
        ]),
        _createVNode(_component_RouterLink, { to: { name: 'teamscoreboardDetail' } }, {
          default: _withCtx(() => [
            (!_ctx.tsLoadingTeamGroupSummary)
              ? (_openBlock(), _createBlock(_component_ScreenBottomButton, {
                  key: 0,
                  title: 'View Detail: ' + _ctx.tsCurrentGroupTitle
                }, null, 8, ["title"]))
              : _createCommentVNode("", true)
          ], undefined, true),
          _: 1
        })
      ], undefined, true),
      _: 1
    })
  ]))
}