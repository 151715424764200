<template>
    <!-- main scorecard -->
    <div v-if="scorecard" class="scorecard-container">
        <div class="header">
            <ScorecardHeader
                :location-text="locationText"
                :rating-label="ratingLabel"
                :scorecard="scorecard"
                :is-team-member-view="!!teamMemberUserId"
                @trend="goTrend"
                @rank="goRank"
            />
        </div>

        <!-- My team -->
        <template v-if="teamMembers.length > 0 && !teamMemberUserId">
            <div class="custom-base-block">
                <div class="title">My Team</div>
            </div>
            <div class="slider">
                <div class="slider-container">
                    <div class="badge-slides slide">
                        <RouterLink
                            v-for="member in teamMembers"
                            :key="member.user_id"
                            :to="{
                                name: 'scorecardHomeUser',
                                params: { teamMemberUserId: member.user_id },
                            }"
                        >
                            <div class="team-member">
                                <AvatarOrInitials
                                    :size="75"
                                    :user="member"
                                    class="team-member-avatar"
                                />
                                <p class="team-member-name">
                                    {{ member.name }}
                                </p>
                            </div>
                        </RouterLink>
                    </div>
                </div>
            </div>
        </template>
        <!-- My team end -->

        <!-- Positive Feedback -->
        <div class="custom-base-block overline-pad-top">
            <Typography variant="overline">Your customers love</Typography>
            <div v-if="sortedBadges">
                <div :class="{ slider: $isMobile }">
                    <div :class="{ 'slider-container': $isMobile }">
                        <div
                            :class="{
                                'badge-slides': $isMobile,
                                slide: $isMobile,
                                'desktop-badges': !$isMobile,
                            }"
                            class="badges-container"
                        >
                            <template
                                v-for="info in sortedBadges"
                                :key="info.value"
                            >
                                <div
                                    class="slide badge"
                                    @click="
                                        info.count > 0 && goBadge(info.value)
                                    "
                                >
                                    <ScorecardBadge
                                        :label="info.label"
                                        :show-count="!scorecard.v2"
                                        :badge-id="info.badgeId"
                                        :count-unseen="
                                            info.countUnseen.toString()
                                        "
                                        :count="info.percentage + '%'"
                                    />
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="no-positive">
                Just waiting for a scorecard to be set up...
            </div>
        </div>
        <!-- Positive Feedback End -->

        <!-- Things to work on -->
        <BaseBlock class="things-to-work-on-base-block">
            <Typography variant="overline">Your customers suggest</Typography>
            <div class="room-to-improve">
                <div class="top">
                    <div class="middle">
                        <div class="improve-item">
                            <div class="search" />
                            <div
                                v-if="hasNegative"
                                class="item-content"
                                @click="goRoomToImproveDetail"
                            >
                                <div class="label">
                                    {{
                                        mostNegative.label || mostNegative.value
                                    }}
                                </div>
                                <div class="mentions">
                                    {{ mostNegative.count }} mention{{
                                        mostNegative.count > 1 ? 's' : ''
                                    }}
                                    <span
                                        v-if="mostNegative.countUnseen"
                                        class="count"
                                    >
                                        +{{ mostNegative.countUnseen }}
                                    </span>
                                    <div class="mentions-label">
                                        {{ thingsToWorkOnDescription }}
                                    </div>
                                </div>
                            </div>
                            <div v-else class="no-negative">
                                <i>Nothing! Keep up the great work!</i>
                            </div>
                        </div>
                    </div>
                    <div v-if="hasNegative" class="right-angle">
                        <svg
                            fill="none"
                            height="14"
                            viewBox="0 0 8 14"
                            width="8"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                        >
                            <rect
                                fill="url(#pattern0)"
                                height="14"
                                opacity="0.2"
                                transform="matrix(-1 0 0 1 8 0)"
                                width="8"
                            />
                            <defs>
                                <pattern
                                    id="pattern0"
                                    height="1"
                                    patternContentUnits="objectBoundingBox"
                                    width="1"
                                >
                                    <use
                                        transform="scale(0.125 0.0714286)"
                                        xlink:href="#image0"
                                    />
                                </pattern>
                                <image
                                    id="image0"
                                    height="14"
                                    width="8"
                                    xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAOCAYAAAASVl2WAAAAmElEQVQoFWNkwAJUeGxFWTg5tv9n+P+dCV0eLMnFsZ+BkcGYgYGBgxFZAVySgUGbgYHh6p9vPxzhCrBJ3vly+DVYAS5JkOmM+CRBCphArmVAshNkLLK7MHyBLAk24c/3H54gF4NMYeHi2A+yElkRYUfCVONyLDwcQAqxKUJRAFKkzGMlxsrFvQ/ss/8MZzEUwBVxcm8DRRYAiUZTfr5p+UMAAAAASUVORK5CYII="
                                />
                            </defs>
                        </svg>
                    </div>
                </div>
                <div
                    v-if="hasNegative"
                    class="see-all"
                    @click="goRoomToImproveList"
                >
                    See all
                </div>
            </div>
        </BaseBlock>
        <!-- Things to work on end -->

        <!-- Achievements -->
        <div class="custom-base-block overline-pad-top">
            <Typography variant="overline">You’re Awesome!</Typography>
            <div class="achievement-container">
                <div
                    v-for="achievement in badgeAchievements"
                    :key="achievement.type"
                    class="achievement"
                    @click="goAchievement(achievement.badgeId)"
                >
                    <ScorecardBadge
                        :label="achievement.label"
                        :data-id="'pendo-achievement-badge'"
                        :show-count="false"
                        :badge-id="achievement.badgeId"
                        :badge-type="'achievement'"
                        :count-unseen="achievement.countUnseen.toString()"
                        :count="achievement.count.toString()"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import BaseBlock from '@/components/BaseBlock.vue'
import Modal from '@/components/Modal.vue'
import NoScorecardFound from '@/components/Scorecard/NoScorecardFound.vue'
import ScorecardBadge from '@/components/Scorecard/ScorecardBadge.vue'
import ScorecardHeader from '@/components/Scorecard/ScorecardHeader.vue'
import AvatarOrInitials from '@/components/WebView/AvatarOrInitials.vue'
import WebViewScreen from '@/components/WebView/WebViewScreen.vue'
import { getTextEllipsis } from '@/domain/NPS'
import { IRecipient } from '@/entities/notices'
import { badgeSchemas, ScorecardEntity } from '@/entities/scorecard'
import ScreenBottomButton from '@/components/Notices/ScreenBottomButton.vue'
import Driver from 'driver.js'
import 'driver.js/dist/driver.min.css'
import { useRouter } from 'vue-router'
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { Action, Getter } from 'vuex-facing-decorator'
import Typography from '@/components/Rain/Typography/Typography.vue'

@Component({
    components: {
        Typography,
        AvatarOrInitials,
        BaseBlock,
        Modal,
        NoScorecardFound,
        ScorecardBadge,
        ScorecardHeader,
        ScreenBottomButton,
        WebViewScreen,
    },
})
export default class ScorecardPage extends Vue {
    @Prop({ type: String, required: false })
    readonly teamMemberUserId?: string // view another users scorecard / my teams

    @Getter
    readonly $companyVars

    @Getter
    readonly scorecard!: ScorecardEntity

    @Getter
    readonly scorecardLastRefreshed!: number

    @Getter
    readonly locationText!: string

    @Getter
    readonly myTeam!: IRecipient[]

    @Getter
    readonly userId!: string

    driver?: Driver
    initialLoading = true

    @Action
    loadScorecard

    @Action
    loadMyTeam

    private router = useRouter()

    get ratingLabel() {
        return this.scorecard.isFiveScoreQuestionType ? 'Rating' : 'NPS'
    }

    get hasScorecard() {
        return this.scorecard && Object.keys(this.scorecard).length > 0
    }

    get hasNegative() {
        if (!this.scorecard.badges) {
            return false
        }
        return Object.keys(this.scorecard.badges.negative).length > 0
    }

    get mostNegative() {
        if (!this.hasNegative) {
            return null
        }
        let most
        Object.entries(this.scorecard.badges.negative).forEach(
            ([badge, info]) => {
                if (!most) {
                    most = { ...info, badge }
                } else if (most.count < info.count) {
                    most = { ...info, badge }
                }
            }
        )
        return most
    }

    public get sortedBadges() {
        if (!this.scorecard.badges) {
            return this.scorecard.badges
        }

        return Object.values(this.scorecard.badges.positive).sort(
            (info1, info2) => info2.count - info1.count
        )
    }

    public get badgeAchievements() {
        return Object.values(this.scorecard.achievements ?? {})
    }

    public get showNPSAndRank() {
        // todo no N/A, and this.scorecard.responded >= 5
        return this.scorecard
    }

    get teamMembers() {
        return this.myTeam?.filter(
            (member) => member.user_id !== Number(this.userId)
        )
    }

    get knowledgeImgSrc() {
        return badgeSchemas.knowledge.img
    }

    get thingsToWorkOnDescription() {
        if (!this.hasNegative) {
            return null
        }
        return `${this.mostNegative.percentage}% of detractor & passive feedback`
    }

    // all unseen negative total
    get newNegtiveTotal() {
        if (!this.scorecard.badges) {
            return this.scorecard.badges
        }

        return Object.values(this.scorecard.badges.negative)
            .map((info) => info.countUnseen)
            .reduce((sum, current) => sum + current, 0)
    }

    beforeDestroy() {
        if (this.driver) {
            this.driver.reset()
        }
    }

    getComment(text) {
        return getTextEllipsis(text, 200)
    }

    goRoomToImproveDetail() {
        if (this.teamMemberUserId) {
            this.router.push({
                name: 'scorecardRoomToImproveDetailUser',
                params: {
                    value: this.mostNegative.badge,
                    teamMemberUserId: this.teamMemberUserId,
                },
            })
        } else {
            this.router.push({
                name: 'scorecardRoomToImproveDetail',
                params: { value: this.mostNegative.badge },
            })
        }
    }

    goRoomToImproveList() {
        if (this.teamMemberUserId) {
            this.router.push({
                name: 'scorecardRoomToImproveListUser',
                params: { teamMemberUserId: this.teamMemberUserId },
            })
        } else {
            this.router.push({ name: 'scorecardRoomToImproveList' })
        }
    }

    goScorecardBadge(
        badge: string,
        ownRouteName: string,
        otherRouteName: string
    ) {
        if (this.teamMemberUserId) {
            this.router
                .push({
                    name: otherRouteName,
                    params: {
                        value: badge,
                        teamMemberUserId: this.teamMemberUserId,
                    },
                })
                .catch((e) => e)
        } else {
            this.router
                .push({
                    name: ownRouteName,
                    params: { value: badge },
                })
                .catch((e) => e)
        }
    }

    goBadge(badge: string) {
        this.goScorecardBadge(badge, 'scorecardBadge', 'scorecardBadgeUser')
    }

    goAchievement(badge: string) {
        this.goScorecardBadge(badge, 'achievementBadge', 'achievementBadgeUser')
    }

    goTrend() {
        if (this.teamMemberUserId) {
            this.router
                .push({
                    name: 'scorecardTrendUser',
                    params: { teamMemberUserId: this.teamMemberUserId },
                })
                .catch((e) => e)
        } else {
            this.router.push({ name: 'scorecardTrend' }).catch((e) => e)
        }
    }

    goRank() {
        if (this.teamMemberUserId) {
            this.router
                .push({
                    name: 'scorecardRankUser',
                    params: { teamMemberUserId: this.teamMemberUserId },
                })
                .catch((e) => e)
        } else {
            this.router.push({ name: 'scorecardRank' }).catch((e) => e)
        }
    }
}
</script>

<style lang="less">
@import '~@/styles/layout';
@import '~@/styles/palette';
@import '~@/styles/scorecard/scorecard.less';
@import '~@/styles/typography';
@import '~@/styles/rain/variables.less';
@import '~@/styles/rain/colour.less';

.scorecard-container {
    overflow-y: auto;
    position: initial;
    top: initial;
    flex: 1;

    .logo {
        img {
            max-width: 40%;
        }
    }

    .header {
        text-align: center;
    }

    .feedback-block .card {
        padding: 0;
    }

    .custom-base-block {
        margin: @gutterSpacing-md 12px @gutterSpacing-md 12px;
        background: @white;
        border: 1px solid @blueLight;
        border-radius: 8px;
    }

    .overline-pad-top {
        .overline {
            text-align: center;
            margin-top: @gutterSpacing-md;
        }
    }

    .things-to-work-on-base-block {
        margin: 12px;
        border-radius: @borderRadius-md;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.12);

        .overline {
            text-align: center;
            margin-bottom: @gutterSpacing-md;
        }
    }

    .panel {
        .title {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 20px;
            color: @grey90;
        }
    }

    .badge-container {
        margin-right: 0;
        border-radius: 8px 0 0 8px;
        border-right: none;
        padding-right: 0;

        .title {
            font-weight: 500;
            font-size: 11px;
            line-height: 20px;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            color: #93a1be;
        }

        .badge:not(:first-child) {
            margin-left: 15px;
        }

        .badge:last-child {
            padding-right: 15px;
        }
    }

    .left-end-slide,
    .right-end-slide {
        background: none;
        width: 12px;
    }

    .badges-container {
        padding: 0 10px;
    }

    .desktop-badges {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        margin: 0 12px;

        .badge {
            flex: 1 0 calc(33% - 24px);
            margin-bottom: 12px;
        }
    }

    .badge-slides {
        display: flex;
        width: max-content;

        .badge {
            margin-left: 15px;
            margin-bottom: 10px;

            &:first-child {
                margin-left: 5px;
            }

            &:last-child {
                margin-right: 5px;
            }
        }

        .team-member:not(:first-child) {
            margin-left: 15px;
        }
    }

    .no-positive {
        font-style: italic;
        text-align: center;
        color: #93a1be;
        margin-top: @gutterSpacing-md;
        margin-bottom: @gutterSpacing-md;
    }

    .room-to-improve {
        cursor: pointer;
        padding: 0;

        .top {
            flex: 1;
            display: flex;
            align-items: center;

            .middle {
                flex: 1;

                .title {
                    font-weight: 500;
                    font-size: 11px;
                    line-height: 20px;
                    letter-spacing: 0.5px;
                    text-transform: uppercase;
                    color: #93a1be;
                }

                .improve-item {
                    .item-content {
                        border-bottom: 1px solid @outline;

                        .label {
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 20px;
                            color: @grey90;
                        }

                        .mentions {
                            font-size: 12px;
                            line-height: 20px;
                            color: #93a1be;
                            padding-bottom: 12px;

                            .mentions-label {
                                color: @orange;
                            }
                        }
                    }

                    .no-negative {
                        text-align: center;
                        color: #93a1be;
                    }
                }
            }
        }

        .see-all {
            border-top: 1px solid #f4f6fc;
            font-style: normal;
            font-weight: normal;
            font-size: 11px;
            text-transform: uppercase;
            color: #2e5bff;
            padding-top: 8px;
            cursor: pointer;
        }
    }

    .slider {
        /* line them up horizontally */
        display: flex;
        /* allow for scrolling */
        overflow-x: auto;
        /* make it smooth on iOS */
        -webkit-overflow-scrolling: touch;
        scroll-snap-type: x mandatory;
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none; // IE 10+
        scrollbar-width: none; // Firefox

        &::-webkit-scrollbar {
            display: none;
        }

        .slide {
            scroll-snap-align: start;
        }
    }

    .slider-container {
        display: flex;

        .slide {
            /* make sure the width is honored */
            flex-shrink: 0;
        }
    }

    .count {
        border-radius: 10px;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        background-color: #e74c3c;
        color: white;
        display: inline-block;
        min-width: 28px;
        text-align: center;
        margin-left: 5px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        padding: 2px 1px;
    }

    .driver-highlighted-element {
        pointer-events: none; // prevent clicking
    }

    .asknicely-logo {
        text-align: center;
        margin: 5px auto;
    }

    .team-member {
        padding: 10px 0;
        width: 80px;

        .team-member-avatar {
            margin: auto;
        }

        .team-member-name {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            color: @grey90;
            margin-top: 5px;
        }
    }

    .achievement-container {
        display: flex;
        justify-content: space-around;
        padding-bottom: @gutterSpacing-md;
        margin: 0 @gutterSpacing-base * 1.5;
    }
}
</style>
