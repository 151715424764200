<template>
    <div class="mfa-page">
        <FontAwesomeIcon class="key-icon" :icon="faKey" />
        <Typography variant="h4" component="h4" class="title-container">The Secret Key</Typography>
        <Typography variant="body2" class="detail-container">
            Please enter the secret key below into your preferred MFA app. The
            secret key will expire in {{ formattedTime }}</Typography>
        <div class="secret-container display-text">{{ secret }}</div>
        <BasePillButton
            class="verify-button"
            :disabled="copied"
            @click="copyToClipboard"
        >
            {{ copyText }}
        </BasePillButton>
        <input
            v-model="verificationCode"
            type="number"
            class="mfa-code-input display-text"
            placeholder="Your 6 digit code"
        />
        <BasePillButton
            class="verify-button"
            label="Verify MFA"
            :disabled="!enteredCode || isVerifying"
            @click="verifyMFA"
        >
            <SimpleSpinner
                v-if="isVerifying"
                size="tiny"
                line-bg-color="transparent"
                :line-fg-color="$palette.brandPurple"
            />
            <template v-else>Verify MFA</template>
        </BasePillButton>
    </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { faKey } from 'fontawesome/free-solid-svg-icons'
import Typography from '@/components/Rain/Typography/Typography.vue'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { sleep } from '@/utils/async'
import { Clipboard } from '@capacitor/clipboard'
import BasePillButton from '@/components/BasePillButton.vue'
import SimpleSpinner from '@/components/SimpleSpinner.vue'

interface Props {
    formattedTime: string
    secret: string
    isVerifying: boolean
}
const props = withDefaults(defineProps<Props>(), {
    formattedTime: '',
    secret: '',
    isVerifying: false,
})

const emit = defineEmits(['verifyMFA'])

const loading = ref(false)
const copied = ref(false)
const verificationCode = ref('')

const copyText = computed(() => {
    return copied.value ? 'Copied to Clipboard' : 'Copy to Clipboard'
})

const enteredCode = computed(() => {
    return !!verificationCode.value
})

async function verifyMFA() {
    emit('verifyMFA', verificationCode.value)
}

function resetMFAInput() {
    verificationCode.value = ''
}

async function copyToClipboard() {
    const secretToCopy = document.getElementsByClassName(
        'secret-container'
    )[0] as HTMLInputElement

    // copy Link
    await Clipboard.write({
        string: secretToCopy.innerText.trim(),
    })

    copied.value = true
    await sleep(2000)
    copied.value = false
}

defineExpose({
    resetMFAInput,
})
</script>

<style scoped lang="less">
@import '~@/styles/rain/variables.less';
@import '~@/styles/rain/colour.less';
@import '~@/styles/variables.less';
@import '~@/styles/palette.less';
@import '~@/mobile/src/styles/mfa.less';

@inputPadding: 10px;
@floatingTextWidth: 285px;
@iconSize: 40px;
@secretWidth: 150px;
@inputWidth: 130px;

.title-container.reset {
    margin-bottom: @featureGutter;
}
.key-icon {
    height: @iconSize;
}

.mfa-code-input {
    margin-bottom: 20px;
}

.display-text {
    font-size: @fontSize-base;
    letter-spacing: @letterSpacing-2xl;
    font-family:
        American Typewriter,
        monospace;
    background: linear-gradient(0deg, @thinFog, @thinFog);
    border: @borderWidth dashed @white;
    padding: @inputPadding;
    border-radius: @borderRadius-base;
    gap: @inputPadding;
}

.secret-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    min-width: @secretWidth;
    min-height: @fontSize-md;
    margin: @featureGutter 0;
}

.detail-container {
    margin-top: @featureGutter;
    color: @white;
    width: @floatingTextWidth;
}
</style>
