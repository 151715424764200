<template>
    <transition name="fade">
        <div v-if="modalOpen" class="template-selector-modal">
            <FontAwesomeIcon
                class="close-icon"
                :icon="closeIcon"
                @click="closeModal"
            />

            <div v-if="loadingTemplates" class="loading-wave" />
            <div v-else class="rows">
                <button
                    v-for="template in templateRows"
                    :key="template.id"
                    class="template-row"
                    @click="() => template.onClick()"
                >
                    <img
                        v-if="template.icon"
                        class="icon"
                        :src="template.icon"
                        alt="Action Icon"
                    />
                    <Typography variant="overline" class="template-text">{{
                        template.title
                    }}</Typography>
                </button>
            </div>
        </div>
    </transition>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faXmark } from 'fontawesome/free-solid-svg-icons'
import { ChatTemplate } from '@/entities'
import { Action, Getter, namespace } from 'vuex-facing-decorator'
import Typography from '@/components/Rain/Typography/Typography.vue'
import { Row } from '@/entities/simplelist'
import PublishIcon from '@/assets/img/Icons/publish.svg'
import CalendlyIcon from '@/assets/img/Icons/calendly.svg'
import LoadingCard from '@/mobile/src/components/appV4/LoadingCard.vue'
import { pendoTrackEvent } from '@/utils/pendo'

const MomentsModule = namespace('moments')

@Component({
    components: {
        LoadingCard,
        Typography,
        FontAwesomeIcon,
    },
    emits: ['closeModal'],
})
export default class ReplyTemplateSelectorModal extends Vue {
    @Prop({ type: Boolean, default: true }) modalOpen!: boolean
    @Prop({ type: Boolean, default: false }) loadingTemplates!: boolean

    @MomentsModule.Getter customerReplyTemplates!: ChatTemplate[]
    @Getter protected chatCustomData
    @Action protected setChatReply!: ({
        from,
        emailReply,
        subject,
        message,
    }) => void
    @MomentsModule.Action setMentionText
    @MomentsModule.Action setSubject
    @MomentsModule.Action setSelectedReplyTemplate

    public closeIcon = faXmark

    public closeModal() {
        this.$emit('closeModal')
    }

    protected get publishInfo(): any {
        switch (this.chatCustomData.publishapproved) {
            case '1':
                return {
                    title: 'Published',
                    tooltip:
                        'This comment has been published as a Testimonial.',
                }
            case '2':
                return {
                    title: 'Hidden',
                    tooltip: 'Currently not showing in Testimonials.',
                }
            case '3':
                return {
                    title: 'Reviewed',
                    tooltip:
                        'This response has been published as AskNicely Review.',
                }
            case '0':
                return {
                    title: 'Rejected',
                    tooltip:
                        'The customer declined to have their comment published.',
                }
            case '-1':
                return {
                    title: 'Waiting',
                    tooltip:
                        'We have already sent a publish request to this customer.',
                }
            default:
                return {
                    title: 'Publish',
                    tooltip:
                        'Request to publish this comment as a Testimonial.',
                }
        }
    }

    protected get templateRows(): Row[] {
        if (!this.customerReplyTemplates) {
            return []
        }

        return Object.keys(this.customerReplyTemplates).map((id) => {
            const template = this.customerReplyTemplates[id]

            const templateClick = () => {
                this.setChatReply({
                    from: undefined,
                    emailReply: undefined,
                    subject: template.subject,
                    message: template.text.replace(/(?:\r\n|\r|\n)/g, '<br>'),
                })

                this.setMentionText(
                    template.text.replace(/(?:\r\n|\r|\n)/g, '<br>')
                )
                this.setSubject(template.subject)
                this.setSelectedReplyTemplate(template)

                pendoTrackEvent('message_composer_reply_template_selected')

                this.closeModal()
            }

            // public row
            if (template.permanent === '1') {
                return {
                    id,
                    icon: PublishIcon,
                    title: this.publishInfo.title,
                    onClick: templateClick,
                }
            }

            // calendly row
            if (template.name === 'Calendly') {
                return {
                    id,
                    icon: CalendlyIcon,
                    title: 'Calendly',
                    onClick: templateClick,
                }
            }

            return {
                id,
                title: template.name,
                onClick: templateClick,
            }
        })
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';
@import '~@/mobile/src/styles/animations.less';
@import '~@/mobile/src/styles/zindex.less';
@import '~@/mobile/src/styles/message-composer.less';

.fade-transition-animation(0.2s);

.template-selector-modal {
    .modal-container();
    max-height: 450px;
    overflow-y: scroll;
}

.template-text {
    color: white;
}

.rows {
    display: flex;
    flex-direction: column;
    gap: 22px;
}

.template-row {
    background: unset;
    border: unset;
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 8px;
}

.loading-wave {
    width: 60%;
    height: 18px;
    border-radius: @borderRadius-md;
    .loading-wave();
}
</style>
