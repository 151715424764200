import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5dfe259a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "notice-stack-container",
  class: "notices-container"
}
const _hoisted_2 = {
  key: 0,
  class: "notice-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NoticeTemplate = _resolveComponent("NoticeTemplate")!

  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.message)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_NoticeTemplate, {
                notice: _ctx.message,
                "is-receiver": _ctx.mailbox === 'received',
                onRespond: _ctx.onClose
              }, null, 8, ["notice", "is-receiver", "onRespond"])
            ]))
          : _createCommentVNode("", true)
      ])
    ], undefined, true),
    _: 1
  }))
}