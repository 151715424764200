<template>
    <PageHeader key="header" class="header" :go-action="goAction">
        <template v-if="hasNetworkConnection" #action>
            <Typography component="span" class="navigation-text filter flex-1">
                Filters <span v-if="filterCount">({{ filterCount }})</span>
            </Typography>
        </template>
        <template #dropdown>
            <FeedbackFilter />
        </template>
    </PageHeader>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { Getter } from 'vuex-facing-decorator'
import useEmitter from '@/composables/useEmitter'
import Typography from '@/components/Rain/Typography/Typography.vue'
import PageHeader from '@/mobile/src/components/appV4/headers/PageHeader.vue'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import FeedbackFilter from '@/mobile/src/components/appV4/feedback/FeedbackFilter.vue'
import {
    toggleHeader,
    updateFilterCountEvent,
} from '@/mobile/src/utils/headerbus'

@Component({
    components: {
        FeedbackFilter,
        PageHeader,
        Typography,
        FontAwesomeIcon,
    },
})
export default class FilterHeader extends Vue {
    public filterCount = 0

    @Getter readonly hasNetworkConnection

    public emitter = useEmitter()

    mounted() {
        this.emitter?.on(updateFilterCountEvent, this.onUpdateFilterCount)
    }

    destroyed() {
        this.emitter?.off(updateFilterCountEvent, this.onUpdateFilterCount)
    }

    //TODO:  Get rid of this after refactoring FeedbackFilter to use vuex
    private onUpdateFilterCount(count) {
        this.filterCount = count
    }

    private goAction() {
        toggleHeader(this.emitter)
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';
.navigation-text {
    align-items: center;
    color: @white;
    font-size: @fontSize-sm;
    font-weight: @fontWeight-medium;
    line-height: @lineHeight-sm;
    letter-spacing: @letterSpacing-2xl;
    text-transform: uppercase;
    &.back {
        align-items: center;
        display: inline-flex;

        .chevron-left {
            margin-right: 5px;
        }
    }
}
</style>
