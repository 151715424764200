import { ActionTree, MutationTree, GetterTree } from 'vuex'

export interface OnboardingState {
    showLaunchPad: boolean
    showTestMode: boolean
}

export const state: OnboardingState = {
    showLaunchPad: false,
    showTestMode: false,
}

const getters: GetterTree<OnboardingState, any> = {
    isShowLaunchPad({ showLaunchPad }) {
        return showLaunchPad
    },
    isShowTestMode({ showTestMode }) {
        return showTestMode
    },
}

const actions: ActionTree<OnboardingState, any> = {
    setShowLaunchPad({ commit }, showLaunchPad: boolean) {
        commit('setShowLaunchPad', showLaunchPad)
    },

    setShowTestMode({ commit }, showTestMode: boolean) {
        commit('setShowTestMode', showTestMode)
    },
}

const mutations: MutationTree<OnboardingState> = {
    setShowLaunchPad(state, showLaunchPad: boolean) {
        state.showLaunchPad = showLaunchPad
    },
    setShowTestMode(state, showTestMode: boolean) {
        state.showTestMode = showTestMode
    },
}

export default {
    namespaced: false,
    state,
    actions,
    mutations,
    getters,
}
