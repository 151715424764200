import axios, { AxiosInstance } from 'axios'
import { baseConfig, headerTokenConfig } from './clientShared'

export const baseClient = axios.create(baseConfig)
export const headerTokenClient = axios.create(headerTokenConfig)

const webFormClient = axios.create({
    params: baseConfig.params,
    headers: {
        'Content-Type': 'multipart/form-data',
    },
    withCredentials: true,
})

const webJsonClient = axios.create({
    headers: {
        'Content-Type': 'application/json',
    },
})

const webAjaxClient = axios.create({
    ...baseConfig,
    baseURL: '/ajax/',
})

// NOTE: using webpack DefinePlugin (see vue.config.js for each platform) to define CONFIG_KEY
//      in COMPILE time, we can replace the code for each plat form, and no need to
//      include unecessary code/import at all. brilliant!
//      ALSO, only affect production build, not dev
export const ajaxClient =
    process.env.CONFIG_KEY !== 'mobile'
        ? webAjaxClient
        : (require('@/mobile/src/api/mobileclient').ajaxClient as AxiosInstance)
export const publicClient =
    process.env.CONFIG_KEY !== 'mobile'
        ? webJsonClient
        : (require('@/mobile/src/api/mobileclient').jsonClient as AxiosInstance)
export const formClient =
    process.env.CONFIG_KEY !== 'mobile'
        ? webFormClient
        : (require('@/mobile/src/api/mobileclient').formClient as AxiosInstance)

const client = ajaxClient
export default client
