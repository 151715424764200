<template>
    <div class="avatar-or-initials">
        <div v-if="user && user.avatar" class="avatar" :style="sizeStyle">
            <img :src="userAvatar" :alt="user.name || user.id" />
        </div>
        <div v-else class="initials" :style="sizeStyle">{{ initials }}</div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { UserDetail } from '@/entities/user'
import { UserColors } from '@/utils/userColors'
import { getCDNUrl } from '@/utils/cdn'

@Component({})
export default class AvatarOrInitials extends Vue {
    @Prop({ type: Object, default: () => ({ id: -1, name: '', avatar: '' }) })
    public user!: UserDetail
    @Prop({ type: Number, default: 32 }) public size!: number

    public get userAvatar() {
        // return cdn url if user.avatar doesn't contain https
        // otherwise just use user.avatar
        return this.user.avatar && !/https/.test(this.user.avatar)
            ? getCDNUrl(this.user.avatar)
            : this.user.avatar
    }

    public get sizeStyle() {
        const fontSize = Math.floor(this.size / 2)
        const defaultColor = '#b43dc6'
        let color =
            this.user && this.user.name
                ? UserColors[this.user.id % UserColors.length] ?? defaultColor
                : defaultColor
        if (this.user.avatar) {
            color = 'transparent'
        }

        return `width: ${this.size}px;
            height: ${this.size}px;
            line-height: ${this.size}px;
            font-size: ${fontSize}px;
            background-color: ${color};`
    }

    public get initials() {
        return this.user && this.user.name
            ? this.user.name
                  .split(' ')
                  .slice(0, 2)
                  .map((s) => s[0])
                  .join('')
                  .toUpperCase()
            : ' '
    }
}
</script>

<style scoped lang="less">
.avatar-or-initials {
    display: inline-block;

    .avatar {
        border-radius: 50%;
        overflow: hidden;
    }

    img {
        border-radius: 50%;
        display: block;
        height: 100%;
        width: 100%;
    }

    .initials {
        background-color: #b43dc6;
        border-radius: 50%;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        line-height: 100%;
        text-align: center;
    }
}
</style>
