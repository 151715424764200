export enum InsightsMetric {
    NPS = 'Net Promoter Score',
    CSAT = 'Customer Satisfaction',
    FIVESTAR = 'Five Star',
    REPEAT_VISIT = 'Repeat Customers',
    RETENTION = 'Retention',
    TENURE = 'Avg Customer Tenure',
}

export enum InsightsStatus {
    GOLD = 'gold',
    SILVER = 'silver',
    BRONZE = 'bronze',
}

export const OrderedInsightsStatuses = [
    InsightsStatus.GOLD,
    InsightsStatus.SILVER,
    InsightsStatus.BRONZE,
]

export const TimeOptions = [
    {
        label: 'Last 7 days',
        shortLabel: '7 days',
        timeRange: { timeUnit: 'week', timeValue: 1 },
    },
    {
        label: 'Last 30 days',
        shortLabel: '30 days',
        timeRange: { timeUnit: 'month', timeValue: 1 },
    },
    {
        label: 'Last 3 months',
        shortLabel: '3 months',
        timeRange: { timeUnit: 'month', timeValue: 3 },
    },
]

export interface GroupStatusMap {
    [group: string]: InsightsStatus
}

export interface SelectedStatuses {
    [InsightsStatus.GOLD]: boolean
    [InsightsStatus.SILVER]: boolean
    [InsightsStatus.BRONZE]: boolean
}

export interface ScorecardOption {
    label: string
    value: string
}
