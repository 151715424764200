<template>
    <div class="mfa-page">
        <FontAwesomeIcon class="circle-icon" :icon="faCircleCheck" />
        <Typography variant="h4" component="h4" class="title-container">Verification</Typography>
        <p>Please enter the current 6-digit authenticator code</p>
        <input
            v-model="verificationCode"
            type="number"
            class="mfa-code-input"
            placeholder="Your 6 digit code"
            @keyup.enter="() => $refs.verifyBtn.onClick()"
        />
        <Checkbox
            class="remember-device-checkbox"
            :checked="mfaRememberDevice"
            @change="onChangeRememberDevice"
        >
            <div class="text">Remember this device for 30 days</div>
        </Checkbox>
        <BasePillButton
            ref="verifyBtn"
            class="verify-button"
            :disabled="loading || !enteredCode"
            @click="verifyMFA"
        >
            <SimpleSpinner
                v-if="loading"
                size="tiny"
                line-bg-color="transparent"
                :line-fg-color="$palette.brandPurple"
            />
            <template v-else>VERIFY MFA</template>
        </BasePillButton>

        <p class="white">
            <RouterLink
                class="find-login white"
                :to="{
                    name: 'mfa-recovery-code-login',
                    params: {
                        domain: domain,
                        email: credentials.email,
                        password: credentials.password,
                    },
                }"
                replace
                >Lost access to my authenticator
            </RouterLink>
        </p>

        <p class="white">
            Back to
            <RouterLink
                class="find-login white"
                :to="{ name: 'findlogin' }"
                replace
                >request a magic link
            </RouterLink>
        </p>
    </div>
</template>

<script setup lang="ts">
import { computed, inject, ref } from 'vue'
import { useRoute } from 'vue-router'
import { faCircleCheck } from 'fontawesome/free-solid-svg-icons'
import Typography from '@/components/Rain/Typography/Typography.vue'
import BasePillButton from '@/components/BasePillButton.vue'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import {
    loginAndRedirect,
    validateMagicLinkAndRedirect,
} from '@/mobile/src/services/auth'
import Checkbox from '@/components/Rain/Inputs/Checkbox.vue'
import SimpleSpinner from '@/components/SimpleSpinner.vue'
import { LoginMainPkey, LoginMainProvided } from '@/mobile/src/types/auth'

const verificationCode = ref('')
const loading = ref(false)
const mfaRememberDevice = ref(false)

const route = useRoute()

const enteredCode = computed(() => {
    return !!verificationCode.value
})

const { credentials } = inject(LoginMainPkey) as LoginMainProvided
const domain = route.query.domain
const invitationToken = route.query.invitationToken

async function verifyMFA() {
    loading.value = true
    if (credentials.value.email) {
        await loginAndRedirect(
            domain,
            credentials.value.email,
            credentials.value.password,
            verificationCode.value,
            mfaRememberDevice.value
        )
    } else {
        await validateMagicLinkAndRedirect(
            domain,
            invitationToken,
            verificationCode.value,
            mfaRememberDevice.value
        )
    }
    loading.value = false
    verificationCode.value = ''
}

const onChangeRememberDevice = (value: boolean): void => {
    mfaRememberDevice.value = value
}
</script>

<style lang="less" scoped>
@import '~@/mobile/src/styles/mfa.less';
</style>
