import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ca9657a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "horizontal-scroll-container metrics-container" }
const _hoisted_2 = { class: "scrolling-item" }
const _hoisted_3 = { class: "scrolling-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InsightsMetric = _resolveComponent("InsightsMetric")!
  const _component_InsightsGraph = _resolveComponent("InsightsGraph")!
  const _component_SkeletonDiv = _resolveComponent("SkeletonDiv")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _createVNode(_component_InsightsMetric, {
            active: _ctx.selectedEngagementMetric === 'trending_themes',
            delta: _ctx.metricDelta(_ctx.getMetric(_ctx.themes)),
            title: _ctx.metricName(_ctx.getMetric(_ctx.themes)),
            skeleton: _ctx.loading,
            subtitle: "Trending Themes",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (
                            _ctx.changeSelectedEngagementMetric('trending_themes')
                        ))
          }, null, 8, ["active", "delta", "title", "skeleton"])
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createVNode(_component_InsightsMetric, {
            active: _ctx.selectedEngagementMetric === 'team_spotlight',
            delta: _ctx.metricDelta(_ctx.getMetric(_ctx.team)),
            title: _ctx.metricName(_ctx.getMetric(_ctx.team)),
            skeleton: _ctx.loading,
            subtitle: "Team Spotlight",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (
                            _ctx.changeSelectedEngagementMetric('team_spotlight')
                        ))
          }, null, 8, ["active", "delta", "title", "skeleton"])
        ])
      ])
    ]),
    (_ctx.hasGraph)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.chartData)
            ? (_openBlock(), _createBlock(_component_InsightsGraph, {
                key: 0,
                title: _ctx.graphTitle,
                color: _ctx.getGraphColour(_ctx.chartData, _ctx.$palette),
                data: _ctx.chartData,
                height: _ctx.graphHeight
              }, null, 8, ["title", "color", "data", "height"]))
            : (_openBlock(), _createBlock(_component_SkeletonDiv, {
                key: 1,
                height: _ctx.graphHeight.toString() + 'px'
              }, null, 8, ["height"]))
        ], 64))
      : _createCommentVNode("", true)
  ]))
}