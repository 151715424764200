import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5442375d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "left" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Typography = _resolveComponent("Typography")!

  return (_openBlock(), _createElementBlock("div", {
    class: "action-button-container",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.icon)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "icon",
            src: _ctx.icon,
            alt: "Action Icon"
          }, null, 8, _hoisted_2))
        : _createCommentVNode("", true),
      _createVNode(_component_Typography, {
        class: "label",
        variant: "caption"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.label), 1)
        ], undefined, true),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "right", {}, undefined, true)
    ])
  ]))
}