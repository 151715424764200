import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3a44030f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }
const _hoisted_2 = {
  key: 1,
  class: "guttering"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TeamsFilter = _resolveComponent("TeamsFilter")!
  const _component_OfflinePage = _resolveComponent("OfflinePage")!
  const _component_CXScorecard = _resolveComponent("CXScorecard")!
  const _component_GoalTracking = _resolveComponent("GoalTracking")!
  const _component_CubeWrapper = _resolveComponent("CubeWrapper")!
  const _component_LocationGoalTimeline = _resolveComponent("LocationGoalTimeline")!
  const _component_Topics = _resolveComponent("Topics")!
  const _component_TeamTopPerformer = _resolveComponent("TeamTopPerformer")!
  const _component_PullToRefreshWrapper = _resolveComponent("PullToRefreshWrapper")!

  return (!_ctx.showTeamProfileSelect)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "teams-page",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.filterLostFocus(true)))
      }, [
        (_openBlock(), _createBlock(_component_TeamsFilter, {
          key: _ctx.refreshFilter,
          title: _ctx.title,
          tenant: _ctx.tenant,
          "back-label": _ctx.backLabel,
          "has-network-connection": _ctx.hasNetworkConnection,
          onToggleFilter: _ctx.toggleTeamFilterSelected,
          onBackAction: _ctx.backAction,
          onResetFilters: _ctx.resetFilters,
          onFilterLostFocus: _ctx.filterLostFocus
        }, null, 8, ["title", "tenant", "back-label", "has-network-connection", "onToggleFilter", "onBackAction", "onResetFilters", "onFilterLostFocus"])),
        _createVNode(_component_PullToRefreshWrapper, {
          "ptr-refreshing": _ctx.refreshing,
          "refresh-container-classes": false ? 'full-height' : '',
          onRefreshAction: _ctx.forceRefresh
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              (!_ctx.hasNetworkConnection)
                ? (_openBlock(), _createBlock(_component_OfflinePage, { key: 0 }))
                : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_CubeWrapper, {
                      face: !_ctx.showGoalTracking ? 'front' : 'right'
                    }, {
                      front: _withCtx(() => [
                        _createVNode(_component_CXScorecard, {
                          class: "cube__face cube__face--front",
                          "score-days": _ctx.scoreDays,
                          onGoalClicked: _cache[0] || (_cache[0] = 
                                    () => _ctx.toggleShowGoalTracking(true)
                                )
                        }, null, 8, ["score-days"])
                      ]),
                      right: _withCtx(() => [
                        _createVNode(_component_GoalTracking, {
                          class: "cube__face cube__face--right",
                          "on-back": () => _ctx.toggleShowGoalTracking(false)
                        }, null, 8, ["on-back"])
                      ]),
                      _: 1
                    }, 8, ["face"]),
                    (_ctx.loadingTopics)
                      ? (_openBlock(), _createBlock(_component_LocationGoalTimeline, {
                          key: 0,
                          "should-refresh": _ctx.refreshing,
                          "filter-key": _ctx.filterKey,
                          onDrillDown: _ctx.toggleTeamFilterSelected,
                          onRefreshFinished: _ctx.onRefreshDone
                        }, null, 8, ["should-refresh", "filter-key", "onDrillDown", "onRefreshFinished"]))
                      : _createCommentVNode("", true),
                    (_ctx.loadingTopics)
                      ? (_openBlock(), _createBlock(_component_Topics, {
                          key: 1,
                          "should-refresh": _ctx.refreshing,
                          "filter-key": _ctx.filterKey,
                          onRefreshFinished: _ctx.onRefreshDone
                        }, null, 8, ["should-refresh", "filter-key", "onRefreshFinished"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_TeamTopPerformer, {
                      loading: _ctx.refreshing,
                      title: "TOP PERFORMER",
                      type: "top-performer"
                    }, null, 8, ["loading"]),
                    _createVNode(_component_TeamTopPerformer, {
                      loading: _ctx.refreshing,
                      title: "MOST IMPROVED"
                    }, null, 8, ["loading"])
                  ]))
            ])
          ], undefined, true),
          _: 1
        }, 8, ["ptr-refreshing", "onRefreshAction"])
      ]))
    : _createCommentVNode("", true)
}