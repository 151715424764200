import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4dd89936"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "engagement-shoutout-question" }
const _hoisted_2 = { class: "top" }
const _hoisted_3 = { class: "icon" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "question" }
const _hoisted_6 = { class: "middle" }
const _hoisted_7 = {
  key: 1,
  class: "compose-shoutout"
}
const _hoisted_8 = { class: "mask" }
const _hoisted_9 = { class: "message-container" }
const _hoisted_10 = { class: "button-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultipleOptionPicker = _resolveComponent("MultipleOptionPicker")!
  const _component_InputSingleLine = _resolveComponent("InputSingleLine")!
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_FlexiMultilineTextInput = _resolveComponent("FlexiMultilineTextInput")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: _ctx.icon,
          alt: ""
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.question.question), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_ctx.selectingRecipients)
        ? (_openBlock(), _createBlock(_component_MultipleOptionPicker, {
            key: 0,
            "with-icon": "",
            "with-search": "",
            options: _ctx.question.options,
            selected: _ctx.selectedOptions,
            onSelect: _ctx.onSelect
          }, null, 8, ["options", "selected", "onSelect"]))
        : _createCommentVNode("", true),
      (!_ctx.selectingRecipients)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("div", {
              class: "recipient-names",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectingRecipients = true))
            }, [
              _createVNode(_component_InputSingleLine, {
                value: _ctx.recipientNames,
                label: "Recipients"
              }, null, 8, ["value"]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_FontAwesomeIcon, {
                  class: "plus",
                  icon: _ctx.faPlus,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectingRecipients = true))
                }, null, 8, ["icon"])
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_FlexiMultilineTextInput, {
                value: _ctx.question.comment ? _ctx.question.comment : '',
                "blur-on-new-line": true,
                placeholder: "Want to share more…",
                onInput: _ctx.onInputComment
              }, null, 8, ["value", "onInput"])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_10, [
      (_ctx.selectingRecipients)
        ? (_openBlock(), _createBlock(_component_BaseButton, {
            key: 0,
            full: "",
            disabled: !_ctx.selectedOptions.length,
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectingRecipients = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Done ")
            ], undefined, true),
            _: 1
          }, 8, ["disabled"]))
        : (_openBlock(), _createBlock(_component_BaseButton, {
            key: 1,
            full: "",
            disabled: !_ctx.question.comment,
            onClick: _ctx.onSendShoutout
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Send Shoutout ")
            ], undefined, true),
            _: 1
          }, 8, ["disabled", "onClick"]))
    ])
  ]))
}