<template>
    <div class="filter-bar-secondary">
        <div
            v-if="urlParam.length > 1"
            :class="[
                'filter-bar-active',
                tsMobileView
                    ? 'filter-bar-active-mobile'
                    : 'filter-bar-active-desktop',
            ]"
        >
            <div class="filter-bar-active-container">
                <div class="filter-rule" @click="removeParamFilter()">
                    {{ formatLabel(urlParam) }} <span class="del">&times;</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { Getter } from 'vuex-facing-decorator'

@Component({
    components: {},
})
export default class FilterBarSecondary extends Vue {
    @Getter public tsMobileView

    // get URL parameter: ?returning=promoter-detractor|detractor-promoter
    readonly urlParam =
        new URLSearchParams(window.location.search).get('returning') ?? ''

    public formatLabel() {
        // WEB-8118 only two options at the moment
        return this.urlParam === 'promoter-detractor'
            ? 'Promoters turned Detractors'
            : 'Detractors turned Promoters'
    }

    public removeParamFilter() {
        // remove URL parameter will remove the filter displaying
        window.location.href = '/responses'
    }
}
</script>

<style scoped lang="less">
@import '../styles/palette';

.filter-bar-secondary {
    flex: 1;

    .filter-bar-active {
        flex: 1;
        padding-right: 10px;
        display: inline-block;
    }

    @media (max-width: 1280px) {
        .filter-bar-active-desktop {
            width: calc(100% - 38px - 44px);
        }

        .filter-bar-active-mobile {
            padding-right: 0;
            width: 100%;
        }
    }

    .filter-bar-active-container {
        background: @white;
        border-radius: 20px;
        padding: 3px 0px;
        &:after {
            content: '';
            display: table;
            clear: both;
        }
    }
    .filter-rule {
        background: #dee6ff;
        color: @blue;
        border-radius: 20px;
        padding: 6px 24px 6px 16px;
        margin: 3px 0px 3px 6px;
        font-weight: bold;
        cursor: pointer;
        max-width: 300px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        position: relative;
        float: left;
        text-transform: none;
    }
    .del {
        opacity: 0.7;
        position: absolute;
        top: 5px;
        right: 10px;
        color: @blue;
    }

    @media (min-width: 1281px) {
        div {
            display: inline-block;
        }
    }
    @media (max-width: 1280px) {
        .filter-rule {
            font-size: 11px;
            padding: 2px 24px 2px 12px;
            max-width: calc(100% - (97px));
        }

        .filter-rule-multiple {
            max-width: calc(100% - (177px));
        }

        .del {
            top: 2px;
            right: 8px;
        }
    }
}
</style>
