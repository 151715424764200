<template>
    <div class="loading-state">
        <Profile v-if="!hideProfile" />
        <div class="masonry-grid">
            <div class="masonry-column">
                <LoadingCard
                    v-if="hideProfile"
                    :borderless="true"
                    :variant="'single'"
                    class="profile-loader"
                />
                <LoadingCard class="loading-card" />
                <LoadingCard class="loading-card" variant="pill" />
                <LoadingCard class="loading-card" />
            </div>

            <div class="masonry-column">
                <LoadingCard class="loading-card" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import Profile from '@/mobile/src/components/appV4/Profile.vue'
import LoadingCard from '@/mobile/src/components/appV4/LoadingCard.vue'

@Component({
    components: {
        LoadingCard,
        Profile,
    },
})
export default class HomeLoadingState extends Vue {
    @Prop({ type: Boolean, default: false }) private hideProfile!: boolean
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/variables.less';
@import '~@/mobile/src/styles/misc.less';

.loading-state {
    display: flex;
    flex-direction: column;
}

.masonry-grid {
    .masonry-grid(350px, 36px, 20px);
}

.profile-loader {
    margin: @featureGutter 0;
}

.loading-card {
    margin-bottom: @featureGutter;
}
</style>
