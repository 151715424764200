import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-35feff00"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "team-member" }
const _hoisted_2 = { class: "team-member-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AvatarOrInitials = _resolveComponent("AvatarOrInitials")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AvatarOrInitials, {
      user: _ctx.user,
      size: 75
    }, null, 8, ["user"]),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.name), 1)
  ]))
}