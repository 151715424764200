import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PortalTarget = _resolveComponent("PortalTarget")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["overlay-capture", { active: _ctx.active }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["endarken shadow-overlay-content", { active: _ctx.active }]),
      style: _normalizeStyle(_ctx.clipPath)
    }, null, 6),
    _createVNode(_component_PortalTarget, {
      name: "shadow-overlay-destination",
      onChange: _ctx.handleUpdate
    }, null, 8, ["onChange"])
  ], 2))
}