import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, Transition as _Transition, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d6ca840"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal" }
const _hoisted_2 = { class: "wrapper" }
const _hoisted_3 = { class: "content" }
const _hoisted_4 = { class: "container" }
const _hoisted_5 = { class: "header" }
const _hoisted_6 = { class: "title" }
const _hoisted_7 = { class: "body" }
const _hoisted_8 = { class: "message" }
const _hoisted_9 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_PortalContainer = _resolveComponent("PortalContainer")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_ctx.show)
    ? (_openBlock(), _createBlock(_component_PortalContainer, {
        key: 0,
        onBack: _ctx.onCancel
      }, {
        default: _withCtx(() => [
          _createVNode(_Transition, { name: "modal" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.title), 1)
                      ]),
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.message), 1)
                      ]),
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_Button, {
                          variant: "cancel",
                          "no-border": "",
                          onClick: _withModifiers(_ctx.onCancel, ["stop"])
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.cancelTitle), 1)
                          ], undefined, true),
                          _: 1
                        }, 8, ["onClick"]),
                        _createVNode(_component_Button, {
                          variant: "primary",
                          onClick: _withModifiers(_ctx.onOk, ["stop"])
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.okTitle), 1)
                          ], undefined, true),
                          _: 1
                        }, 8, ["onClick"])
                      ])
                    ])), [
                      [_directive_click_outside, _ctx.clickOut]
                    ])
                  ])
                ])
              ])
            ], undefined, true),
            _: 1
          })
        ], undefined, true),
        _: 1
      }, 8, ["onBack"]))
    : _createCommentVNode("", true)
}