<template>
    <div ref="filterSetContainer" class="filter-set-container">
        <Typography
            ref="filterSetLabel"
            component="div"
            class="navigation-text filter-set-label"
        >
            {{ label }}
        </Typography>
        <div ref="filterOptions" class="filter-set" @scroll="focusFilter">
            <TransitionGroup
                name="filter-set-options"
                class="filter-set-options"
                tag="div"
            >
                <PillSearchInput
                    v-if="filterCategory === 'custom' || filterType === 'tag'"
                    key="search"
                    class="filter-button"
                    :search-input-active="searchInputActive"
                    @toggle-input="onToggleSearchInput"
                    @input="onSearchInput"
                />
                <PillButton
                    v-for="option in filterOptions"
                    :key="option.value"
                    :label="option.label"
                    :class="['filter-button', { selected: isSelected(option) }]"
                    @click="selectFilter(option, filterType)"
                />
            </TransitionGroup>
        </div>
        <Transition name="fade">
            <div
                v-if="searchInputActive && searchTerm"
                class="search-results-container"
            >
                <hr class="divider" />
                <Typography
                    v-if="searchTerm && searchResults.length === 0"
                    key="noresults"
                    variant="body2"
                    class="search-results-text"
                >
                    No results found
                </Typography>
                <div
                    v-if="searchResults.length > 0"
                    class="filter-set-search-options"
                >
                    <PillButton
                        v-for="option in searchResults"
                        :key="option.value"
                        :label="option.label"
                        :max-lines="1"
                        class="filter-button"
                        @click="selectFilter(option, filterType)"
                    />
                </div>
                <hr class="divider" />
            </div>
        </Transition>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from 'vue-facing-decorator'
import Typography from '@/components/Rain/Typography/Typography.vue'
import PillButton from '@/components/PillButton.vue'
import { FilterOptionV4, FilterSetV4, MultiFilterOptionV4 } from '@/entities'
import PillSearchInput from '@/mobile/src/components/appV4/PillSearchInput.vue'
import { onClickOutside } from '@vueuse/core'

@Component({
    components: {
        PillButton,
        Typography,
        PillSearchInput,
    },
    emits: ['addMultiSubOption', 'selectFilter', 'focus', 'unfocus'],
})
export default class FilterSet extends Vue {
    @Prop({ type: String, default: '' }) public label!: string
    // Rule value, e.g. rating, question_type, comments ...
    // Not applicable to time filter.
    @Prop({ type: String, default: '' }) public filterType!: string
    @Prop({ type: String, default: '' }) public filterCategory!: string
    @Prop({ type: String, default: 'single' }) public selectMode!: string
    @Prop({ type: Boolean, default: false }) public closeSearch!: boolean
    @Prop({ type: Boolean, default: false })
    public selectFilterLoading!: boolean
    @Prop({ type: Object, default: { selected: [], filterOptions: [] } })
    public filterSet!: FilterSetV4

    private searchInputActive = false
    private searchTerm = ''

    public mounted() {
        const filterSetRef = this.$refs['filterSetContainer'] as HTMLDivElement
        if (filterSetRef) {
            onClickOutside(filterSetRef, () => this.unfocusFilter())
        }
    }

    private isSelected(option): boolean {
        return this.filterSet.selected.some((obj) => obj.value === option.value)
    }

    private selectFilter(option: FilterOptionV4, filterType: string) {
        // Don't allow user to select a filter while we are already making a request to add/delete a new filter
        if (this.selectFilterLoading) {
            return
        }

        this.focusFilter()
        if (this.selectMode === 'multi') {
            this.$emit('addMultiSubOption', filterType, option)

            const combinedOptions: MultiFilterOptionV4 = {
                label: this.filterSet.selected
                    .map((obj) => obj.label)
                    .join(', '),
                value: this.filterSet.selected.map(({ value }) => value),
                filterType: filterType,
                filterCategory: this.filterCategory,
            }

            if (
                filterType === 'tag' &&
                option.value != 'has_hashtags' &&
                option.value != 'has_no_hashtags'
            ) {
                combinedOptions.value = combinedOptions.value.filter(
                    (opt) => opt !== 'has_hashtags' && opt !== 'has_no_hashtags'
                )
            }

            this.$emit('selectFilter', combinedOptions, this.selectMode)
        } else {
            const combinedOptions: MultiFilterOptionV4 = {
                label: option.label,
                value: [option.value],
                filterType: filterType,
                filterCategory: this.filterCategory,
            }

            this.$emit('selectFilter', combinedOptions, this.selectMode)
        }
    }

    private focusFilter() {
        this.$emit('focus')
    }

    private unfocusFilter() {
        this.$emit('unfocus')
        this.onToggleSearchInput(false)
    }

    private onToggleSearchInput(searchInputActive) {
        this.searchInputActive = searchInputActive

        if (searchInputActive) {
            this.focusFilter()
        } else {
            this.searchTerm = ''
            this.$emit('unfocus')
        }
    }

    private onSearchInput(input: string) {
        const previousSearchTerm = this.searchTerm
        this.searchTerm = input

        // Scroll down to the filter search results on initial input
        // Double requestAnimationFrame() is required to wait for the browser to actually render the element before scrolling
        if (previousSearchTerm === '' && input !== '') {
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    const filterSetLabel = (this.$refs.filterSetLabel as any)
                        .$el
                    filterSetLabel.scrollIntoView({ behavior: 'smooth' })
                })
            })
        }
    }

    // Search closed from parent
    @Watch('closeSearch')
    private onCloseSearch() {
        if (this.searchInputActive && this.closeSearch) {
            this.searchInputActive = false
        }
    }

    private get searchResults(): FilterOptionV4[] {
        if (!this.searchTerm) {
            return []
        }

        return this.filterSet.filterOptions.filter((option) => {
            const isSelected = this.filterSet.selected.some(
                (item) => item.value === option.value
            )

            return (
                option.label
                    .toLowerCase()
                    .startsWith(this.searchTerm.toLowerCase()) &&
                option.value !== 'any' &&
                !isSelected
            )
        })
    }

    private get filterOptions(): FilterOptionV4[] {
        // Hide 'ALL' if search active
        if (this.searchInputActive) {
            // Only show selected items if there is a search term
            if (this.searchTerm) {
                return this.filterSet.filterOptions.filter(
                    (option) =>
                        option.value !== 'any' && this.isSelected(option)
                )
            }

            return this.filterSet.filterOptions.filter(
                (option) => option.value !== 'any'
            )
        }

        return this.filterSet.filterOptions
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/palette.less';
@import '~@/styles/rain/variables.less';

.filter-set-container {
    .filter-set-label {
        align-items: center;
        color: rgba(255, 255, 255, 0.7);
        margin-bottom: 10px;
        font-size: @fontSize-sm;
        font-weight: @fontWeight-medium;
        line-height: @lineHeight-sm;
        letter-spacing: @letterSpacing-2xl;
        padding-left: 20px;
        text-transform: uppercase;
    }

    .filter-set {
        display: flex;
        flex-direction: column;
        justify-content: left;
        overflow-x: auto;
        scrollbar-width: none;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: none;
        width: 100vw;

        /* Hide the scrollbar thumb */
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .search-results-text {
        padding: @featureGutter;
        color: white;
        opacity: 0.9;
    }

    .filter-set-search-options {
        display: inline-flex;
        flex-direction: row;
        gap: 17px;
        padding: @featureGutter;
        flex-wrap: wrap;
        overflow-y: auto;
        max-height: 380px;

        .filter-button:first-of-type {
            margin-left: 0;
        }
    }

    .filter-button {
        border: 1px solid #fff;
        background-color: rgba(0, 0, 0, 0);
        color: #fff;
        font-size: @fontSize-sm;
        font-weight: @fontWeight-medium;
        height: 28px;
        letter-spacing: @letterSpacing-xl;
        line-height: 12px;
        padding-left: 16px;
        padding-right: 16px;
        text-transform: uppercase;
        text-align: left;

        &.filter-button-search {
            padding: 6px 15px 6px 15px;
        }

        &:first-of-type {
            margin-left: 20px;
        }

        &:last-of-type {
            margin-right: 20px;
        }

        &.selected {
            background-color: #fff;
            color: @indigo;
        }

        &.disabled {
            background-color: rgba(0, 0, 0, 0);
        }
    }

    .filter-set-options {
        display: inline-flex;
        flex-direction: row;
        gap: 17px;
        width: max-content;
    }

    .search-results-container {
        margin-top: @featureGutter;
    }

    // Filter search open transition
    .fade-enter-active,
    .fade-leave-active {
        overflow-y: hidden;
        transition: all 0.3s;
    }
    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    .filter-button {
        &.filter-set-options-enter-active {
            transition-delay: 0.5s;
        }
    }
    .filter-set-options-enter-active {
        transition: all 1s;
    }
    .filter-set-options-enter-from,
    .filter-set-options-leave-to {
        opacity: 0;
    }

    // Filter multi select move transition
    .filter-set-options-move {
        transition: all 1s cubic-bezier(0.5, 0.82, 0.165, 1);

        &.selected {
            z-index: 10000;
        }
    }
}
</style>
