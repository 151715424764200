import { doRequest } from '@/mobile/src/api/mobileclient'
import { getDeviceId, getPlatform } from '@/mobile/src/utils/capacitor'
import { LoginPayload, MultiLoginTokenResult } from '@/mobile/src/types/auth'
import { LooseObject } from '@/pages/appstore/entities/app'

export async function login(
    domain,
    email,
    password,
    mfa?: string,
    mfaRememberDevice?: boolean,
    mfaSetup?: string,
    recoveryCode?: string
) {
    const deviceId = await getDeviceId()

    let data: LoginPayload = {
        email,
        password,
        deviceId,
    }

    if (mfa) {
        data = {
            ...data,
            mfa,
            mfaRememberDevice,
        }
    }

    if (recoveryCode) {
        data = {
            ...data,
            recoveryCode,
        }
    }

    if (mfaSetup) {
        data = {
            ...data,
            mfaSetup,
        }
    }

    return doRequest(`/api/v2/login`, data, 'post', 'form', domain)
}

export async function me() {
    return doRequest(`/api/v2/me`, {})
}

export async function getCompanyPendoAccountProperties() {
    return doRequest(`/company/pendo-account-properties`, {}, 'get', 'ajax')
}

export async function getIsRepeatVisitTrackingConfigured() {
    return doRequest<{ isRepeatVisitTrackingConfigured: boolean }>(
        `/company/is-repeat-visit-tracking-configured`,
        {},
        'get',
        'ajax'
    )
}

export async function getCsrfToken() {
    return doRequest(`/login/csrf`, {}, 'get', 'form')
}

interface SamlIntegration {
    tenant: string
    name: string
    url: string
}

interface FindLoginResponse {
    success: boolean
    message?: string
    email?: string
    samlIntegrations?: SamlIntegration[]
}

export async function findLogin(email: string) {
    const deviceType = await getPlatform()
    return doRequest<FindLoginResponse>(
        `/api/v2/findlogin`,
        { email, deviceType },
        'get',
        'form',
        'nicely'
    )
}

export async function openMagicInvitationLink(
    domain: string,
    token: string,
    data: object = {}
) {
    return doRequest(
        `/api/v2/invitation/accept/${token}`,
        data,
        'get',
        'form',
        domain
    )
}

interface CheckDomainResponse {
    disableEmailLogin: number
    hideGoogleSignIn: number
    samlInfo?: {
        samlSsoUrl: string
        samlSsoName: string
    }
}

export async function checkDomain(domain: string) {
    return doRequest<CheckDomainResponse>(
        `/api/v2/check-domain`,
        {},
        'get',
        'form',
        domain
    )
}

export async function loginWithGoogleIdToken(
    googleIdToken: string
): Promise<MultiLoginTokenResult> {
    const { success, data } = await doRequest(
        `/api/v2/google-login`,
        {
            id_token: googleIdToken,
            platform: await getPlatform(),
        },
        'post',
        'json',
        'nicely'
    )
    return { success, ...data }
}

export async function activateLogin(domain, token, password, phone, method) {
    return doRequest(
        `/api/v2/user/activate/${token}`,
        {
            password,
            phone,
            method,
        },
        'post',
        'json',
        domain
    )
}

export async function updatePassword(domain, token, password) {
    return doRequest(
        `/api/v2/password/update/${token}`,
        {
            password,
        },
        'post',
        'json',
        domain
    )
}

export async function getPasswordPolicy(domain, token) {
    return doRequest(
        `/api/v2/password/policy/${token}`,
        {},
        'get',
        'form',
        domain
    )
}

export async function validateUser(domain, token, password, method) {
    return doRequest(
        `/api/v2/user/validate/${token}`,
        {
            password,
            method,
        },
        'post',
        'json',
        domain
    )
}

export async function registerPushDevice(pushToken, deviceType) {
    return doRequest(
        `/api/v2/pushtoken?pushtoken=${encodeURIComponent(pushToken)}` +
            `&devicetype=${encodeURIComponent(deviceType)}`,
        {},
        'post',
        'json'
    )
}

export async function unRegisterPushDevice(pushToken: string) {
    return doRequest(
        `/api/v2/pushtoken`,
        { pushtoken: pushToken },
        'delete',
        'json'
    )
}

export async function saveUserConfig(config: LooseObject) {
    return doRequest(`/ajax/user/config`, config, 'post', 'json')
}

export async function getScorecards() {
    return doRequest(`/ajax/scorecard-settings`, {}, 'get', 'form')
}

export async function saveSelectedScorecard(scorecard) {
    return doRequest(`/ajax/user/selected-scorecard`, scorecard, 'post', 'json')
}

export async function switchUser(userId: string | number = '_exit') {
    return doRequest(`/ajax/switch-user`, { user_id: userId }, 'post', 'form')
}

export async function listSwitchUsers() {
    return doRequest(`/ajax/list-switch-users`, {}, 'post', 'form')
}

export async function getGrantToken(
    email: string,
    adminUserToken: string,
    tenant: string
) {
    return doRequest(
        `/api/v2/login/support/grant`,
        { email, adminUserToken },
        'post',
        'form',
        tenant
    )
}

//Log in using a combination of admin user token and grant token instead of using a password.
//Used as part of the CMD+G for mobile auth flow.
export async function loginSupport(
    adminUserToken: string,
    grantToken: string,
    email: string,
    tenant: string
) {
    return doRequest(
        `/api/v2/login`,
        { adminUserToken, grantToken, email, tenant },
        'post',
        'form',
        tenant
    )
}
