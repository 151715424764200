<template>
    <div class="graph-container">
        <canvas ref="graph" />
    </div>
</template>

<script lang="ts">
import { Component, Watch, Vue } from 'vue-facing-decorator'
import { Action, Getter } from 'vuex-facing-decorator'
import { Chart, registerables } from 'chart.js'
import { INPSTrendGraph } from '@/entities'
import { isFiveScoreQuestionType } from '@/utils/globalVariables'

@Component({
    components: {},
})
export default class HistoricGraph extends Vue {
    @Getter public mobileQuestionType
    @Getter public filterLastPatch
    @Getter public npsTrendGraph!: INPSTrendGraph
    @Getter public $companyVars

    @Action public updateNpsTrendGraph

    public chart?: Chart

    public mounted() {
        Chart.register(...registerables)
        if (!this.npsTrendGraph.nps.length) {
            this.load()
            return
        }
        this.updateGraph(this.npsTrendGraph.nps, this.npsTrendGraph.labels)
    }

    @Watch('mobileQuestionType')
    @Watch('filterLastPatch')
    public async load() {
        await this.updateNpsTrendGraph(this.mobileQuestionType)
        this.updateGraph(this.npsTrendGraph.nps, this.npsTrendGraph.labels)
    }

    public initialGraph() {
        return {
            labels: [],
            datasets: [],
        }
    }

    public async renderGraph(npsData, labels) {
        const maxIndex = labels.length - 1
        let newLabels = labels ? labels : []
        let stepSize = 25
        let suggestedMax = 100
        if (isFiveScoreQuestionType(this.mobileQuestionType)) {
            stepSize = 1
            suggestedMax = 5
        }
        // only display 3 labels when there are more than 3 labels
        if (maxIndex > 3) {
            const displayIndexes = [0, +(maxIndex / 2).toFixed(0), maxIndex]
            newLabels = labels.map((e: string, index: number) =>
                displayIndexes.includes(index) ? e : ''
            )
        }
        const canvas = this.$refs.graph as HTMLCanvasElement
        if (!canvas) {
            return
        }
        const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
        const datasets = [
            {
                data: npsData ? npsData : [],
                fill: false,
                backgroundColor: '#FFF',
                borderColor: '#FFF',
                events: [],
                borderWidth: 1,
            },
        ]
        if (this.chart) await this.chart.destroy()
        this.chart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: newLabels,
                datasets,
            },
            options: {
                maintainAspectRatio: false,
                elements: {
                    line: {
                        tension: 0,
                    },
                },
                scales: {
                    y: {
                        grid: {
                            color: 'rgba(255, 255, 255, 0.4)',
                            drawTicks: false,
                        },
                        border: {
                            display: false,
                            dash: [2, 5],
                        },
                        ticks: {
                            stepSize,
                            font: { size: 8, weight: 'normal' },
                            color: '#FFF',
                            padding: 16,
                        },
                        beginAtZero: true,
                        suggestedMax,
                    },
                    x: {
                        grid: {
                            color: 'rgba(255, 255, 255, 0.4)',
                            display: true,
                            drawTicks: false,
                        },
                        border: {
                            display: false,
                            dash: [2, 5],
                        },
                        ticks: {
                            font: { size: 8, weight: 'normal' },
                            color: '#FFF',
                            padding: 8,
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: false, // we have to render our own to match the design
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
            },
        })
    }

    public updateGraph(npsData, labels) {
        if (this.chart) {
            this.chart.destroy()
        }
        if (labels) {
            this.renderGraph(
                npsData,
                labels.map((name) => name.toUpperCase())
            )
        }
    }
}
</script>

<style scoped lang="less">
.graph-container {
    margin: 10px 16px 0 16px;
    pointer-events: none;
}
</style>
<style lang="less">
.graph-container {
    position: relative;
}

.trend-caret {
    width: 8px;
    margin-left: 4px;
    position: relative;
    top: 1px;
}
</style>
