<template>
    <div class="choose-login-container">
        <BackHeader />
        <div class="account">
            <div v-if="adminToken" class="account-container">
                <div class="account-content tenant-search-content">
                    <div class="account-row">
                        <InputSingleLine
                            class="tenant-search"
                            autocomplete="off"
                            autocapitalize="off"
                            variant="glass"
                            type="search"
                            :value="tenant"
                            :label="error ? error : 'Enter the Tenant Name'"
                            :spellcheck="false"
                            :error="hasError"
                            :disabled="loading"
                            @change="onTenantChange"
                            @enterpressed="onSupportLogin"
                        />
                        <FontAwesomeIcon
                            class="do-search"
                            :class="{ error }"
                            :icon="
                                loading ? faHourglassHalf : faArrowCircleRight
                            "
                            @click="onSupportLogin"
                        />
                    </div>
                </div>
            </div>
            <div
                v-for="loginToken in loginTokenArray"
                :key="loginToken.token"
                class="account-container"
                @click="selectToken(loginToken)"
            >
                <div class="account-content">
                    <Typography class="account-row" variant="overline">
                        {{ loginToken.company || loginToken.domain }}
                    </Typography>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, onUnmounted } from 'vue'
import { LoginToken } from '@/mobile/src/types/auth'
import {
    buildMagicLink,
    loginSupportAndRedirectHome,
} from '@/mobile/src/services/auth'
import store from '@/mobile/src/store'
import router from '@/mobile/src/router'
import BackHeader from '@/mobile/src/components/appV4/headers/BackHeader.vue'
import Typography from '@/components/Rain/Typography/Typography.vue'
import InputSingleLine from '@/components/Rain/Inputs/InputSingleLine.vue'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import {
    faArrowCircleRight,
    faHourglassHalf,
} from 'fontawesome/free-solid-svg-icons'
import { ToastColor, ToastIcon } from '@/mobile/src/types/toasts'
import { hideToast, showToast } from '@/mobile/src/utils/toastbus'
import useEmitter from '@/composables/useEmitter'

interface LoginTokenFull extends LoginToken {
    domain: string
}

const emitter = useEmitter()
const emit = defineEmits(['choose-account'])
const loginTokens = computed(() => store.getters['loginTokens'])
const adminToken = computed(() => store.getters['adminToken'])
const adminEmail = computed(() => store.getters['email'])

const tenant = ref('')
const error = ref('')
const loading = ref(false)

// convert (domain) keyed object to array
const loginTokenArray = computed(() => {
    return Object.keys(loginTokens.value).map((domain) => {
        return { ...loginTokens.value[domain], domain }
    })
})

const hasError = computed(() => {
    return !!error.value
})

function selectToken(loginToken: LoginTokenFull) {
    const { domain, token } = loginToken
    const url = buildMagicLink({ domain, token })
    router.replace({ name: 'magiclogin', query: { url } })
}

function onTenantChange(text: string) {
    tenant.value = text.toLowerCase()
}

async function onSupportLogin() {
    if (loading.value) {
        return
    }
    loading.value = true
    const toastTime = showToast(
        emitter,
        ToastIcon.Waiting,
        ToastColor.Shoutout,
        'Searching...'
    )
    let response: any = null
    try {
        response = await loginSupportAndRedirectHome(
            tenant.value,
            adminEmail.value,
            adminToken.value
        )
    } finally {
        loading.value = false
        hideToast(emitter, toastTime)
    }

    if (response) {
        if (response.raw.status === 404) {
            error.value = 'Please enter a valid tenant name'
        } else {
            error.value = 'Authentication failed, please contact support'
        }
    }
}

onUnmounted(() => {
    store.dispatch('clearLoginTokens')
})
</script>

<style lang="less" scoped>
@import '~@/mobile/src/styles/option-lines.less';

.choose-login-container {
    padding: env(safe-area-inset-top) env(safe-area-inset-right)
        env(safe-area-inset-bottom) env(safe-area-inset-left);

    .account {
        .option-line();

        .tenant-search-content {
            background-color: @thinFog;
            padding: 0 @featureGutter 0 0;
            width: calc(100vw - @featureGutter);
        }

        .tenant-search {
            flex: auto;
        }
    }

    .do-search.error {
        color: @errorRed;
    }
}
</style>
