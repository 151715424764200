import { MutationTree, GetterTree } from 'vuex'

export interface ChartState {
    isZoomIn: boolean
    selectedCategory: string
    selectedPoint: string
}

export const state: ChartState = {
    isZoomIn: false,
    selectedCategory: '',
    selectedPoint: '',
}

const getters: GetterTree<ChartState, any> = {
    isZoomIn({ isZoomIn }) {
        return isZoomIn
    },
    selectedCategory({ selectedCategory }) {
        return selectedCategory
    },
    selectedPoint({ selectedPoint }) {
        return selectedPoint
    },
}

const mutations: MutationTree<ChartState> = {
    setZoomIn(state, isZoomIn) {
        state.isZoomIn = isZoomIn
    },
    setSelectedCategory(state, selectedCategory) {
        state.selectedCategory = selectedCategory
    },
    setSelectedPoint(state, selectedPoint) {
        state.selectedPoint = selectedPoint
    },
}

export default {
    namespaced: false,
    state,
    getters,
    mutations,
}
