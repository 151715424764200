import { ActionTree, MutationTree, GetterTree } from 'vuex'
import { LooseObject } from '@/pages/appstore/entities/app'
import { getNotificationSettings } from '@/mobile/src/api/notificationSettings'
import { getRuleSubOptions } from '@/api/filter'

export interface NotificationSetting {
    id: number
    name: string
    enabled: boolean
    automatic: boolean
    subject: string
    body: string
}

export interface NotificationSettingsState {
    settings: NotificationSetting[]
    generalFilters: SettingFilter[]
    customFieldValues: SettingFilter[]
    newFilter: {
        name: string
        filters: LooseObject
    }
}

export interface SettingFilter {
    label: string
    value: string
    content?: LooseObject[]
}

export const state: NotificationSettingsState = {
    settings: [],
    generalFilters: [
        {
            label: 'Rating',
            value: 'rating',
            content: [
                { rating: 'promoters' },
                { rating: 'passives' },
                { rating: 'detractors' },
            ],
        },
        {
            label: 'Left Comment',
            value: 'comments',
            content: [{ comments: 'yes' }, { comments: 'no' }],
        },
    ],
    customFieldValues: [],
    // temp storage for when adding new filter
    newFilter: {
        name: '',
        filters: {},
    },
}

const getters: GetterTree<NotificationSettingsState, any> = {
    settings({ settings }) {
        return settings
    },
    generalFilters({ generalFilters }) {
        return generalFilters
    },
    customFieldValues({ customFieldValues }) {
        return customFieldValues
    },
    newFilterName({ newFilter }) {
        return newFilter.name
    },
    newFilterValues({ newFilter }) {
        return newFilter.filters
    },
}

const actions: ActionTree<NotificationSettingsState, any> = {
    async loadNotificationSettings({ commit, state }, feature: string) {
        const { success, data } = await getNotificationSettings()
        if (success) {
            commit('setSettings', data.filters)
        }
    },

    async loadFieldValues({ commit, state }, field: string) {
        const { data } = await getRuleSubOptions(field)
        commit('setNotificationCustomFieldValues', { field, values: data })
    },
}

const mutations: MutationTree<NotificationSettingsState> = {
    setSettings(state: NotificationSettingsState, settings) {
        state.settings = settings
    },

    setNotificationCustomFields(
        state: NotificationSettingsState,
        customFields
    ) {
        state.customFieldValues = customFields
    },

    setNotificationCustomFieldValues(
        state: NotificationSettingsState,
        { field, values }
    ) {
        // find the array item matching field and set the content of it
        state.customFieldValues.forEach((fieldValuesDict, index) => {
            if (fieldValuesDict.value === field) {
                state.customFieldValues[index].content = values.map((v) => ({
                    [field]: v.value,
                }))
            }
        })
        state.customFieldValues = [...state.customFieldValues]
    },

    setNewFilterName(state: NotificationSettingsState, name) {
        state.newFilter = { ...state.newFilter, name }
    },

    setNewFilterValue(state: NotificationSettingsState, kv) {
        state.newFilter.filters = { ...state.newFilter.filters, ...kv }
        state.newFilter = { ...state.newFilter }
    },

    resetNewFilter(state: NotificationSettingsState) {
        state.newFilter = {
            name: '',
            filters: {},
        }
    },
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations,
}
