<template>
    <!-- TODO: Reduce repeated code found here and in TsNpsInfo, TsNpsNumber, InsightsNPSInfo, DashboardOverview and NPSNumber -->
    <div v-if="scorecard" class="scorecard-nps">
        <span class="nps">{{ scorecard.nps }}</span>
        <span class="change-indicator">
            <UpDownArrow :positive="scorecard.change >= 0" />
        </span>
        <span class="number">{{ Number(scorecard.change).toFixed(1) }}</span>
    </div>
</template>

<script lang="ts">
import { ScorecardEntity } from '@/entities/scorecard'
import UpDownArrow from '@/components/UpDownArrow.vue'
import { Component, Vue } from 'vue-facing-decorator'
import { Getter } from 'vuex-facing-decorator'

@Component({
    components: {
        UpDownArrow,
    },
})
export default class ScorecardNPS extends Vue {
    @Getter
    scorecard!: ScorecardEntity
}
</script>

<style scoped lang="less">
@import '~@/styles/palette';

.nps {
    font-weight: 500;
    font-size: 42px;
    line-height: 42px;
    letter-spacing: -1px;
    text-transform: uppercase;
    color: @blue;
}

.change-indicator {
    padding-left: 12px;
    padding-right: 3px;
}

.number {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: @grey40;
}
</style>
