import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3073d8b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_Typography = _resolveComponent("Typography")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_PortalContainer = _resolveComponent("PortalContainer")!

  return (_ctx.show)
    ? (_openBlock(), _createBlock(_component_PortalContainer, {
        key: 0,
        onBack: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('close')))
      }, {
        default: _withCtx(() => [
          _createVNode(_Transition, { name: "modal" }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: "modal",
                onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.clickOut && _ctx.clickOut(...args)), ["self"]))
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["container", 'width' + _ctx.width])
                }, [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_FontAwesomeIcon, {
                      icon: _ctx.faTimes,
                      class: "close",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
                    }, null, 8, ["icon"]),
                    _createVNode(_component_Typography, {
                      variant: "h3",
                      component: "h3"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.title), 1)
                      ], undefined, true),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_2, [
                    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                  ]),
                  _createElementVNode("div", {
                    class: _normalizeClass([
                            'footer',
                            { 'extra-top-padding': _ctx.footerTopPadding },
                        ])
                  }, [
                    (_ctx.btnLeft)
                      ? (_openBlock(), _createBlock(_component_BaseButton, {
                          key: 0,
                          full: _ctx.btnFull,
                          variant: _ctx.btnLeftType,
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('click-left')))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.btnLeft), 1)
                          ], undefined, true),
                          _: 1
                        }, 8, ["full", "variant"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_BaseButton, {
                      full: _ctx.btnFull,
                      disabled: _ctx.isDisabled,
                      type: _ctx.btnRightType,
                      variant: "primary",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('click-right')))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.btnRight), 1)
                      ], undefined, true),
                      _: 1
                    }, 8, ["full", "disabled", "type"])
                  ], 2)
                ], 2)
              ])
            ], undefined, true),
            _: 3
          })
        ], undefined, true),
        _: 3
      }))
    : _createCommentVNode("", true)
}