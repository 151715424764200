<template>
    <span>{{ formattedNumber }}</span>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { formatNumber } from '@/utils/number'

@Component({})
export default class FormattedNumber extends Vue {
    @Prop()
    number

    @Prop({ type: Number, default: 0 })
    decimals

    get formattedNumber() {
        return formatNumber(this.number, this.decimals)
    }
}
</script>
