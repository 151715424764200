import {
    ConversationSpec,
    BlockBuilderModel,
    CardSection,
    Card,
    CardCase,
    CardCases,
    BaseCard,
    BranchCard,
    CardIndex,
    isJumpCard,
    isBranchedCard,
    isOptionsCard,
    isParamCard,
    isSayCard,
    isMultiSelectCard,
    OptionsCard,
    TYPE_REVIEW_LINK_BUTTON,
    REVIEW_LINK_INPUT,
    REVIEW_LINK_CUSTOM_FIELD,
    REVIEW_LINK_TITLE,
    TYPE_QUESTION,
} from '@/entities/conversation'
import {
    Cursor,
    Scope,
    Visited,
    SpecNode,
    NodePath,
    getNode,
    initialCursor,
    getNextCursorDownstream,
    findBlockCursor,
} from './ConversationUtils'
import {
    dedupBy,
    allUnique,
    nonUniqueIndices,
    intersection,
} from '@/utils/array'
import { clone } from '@/utils/object'
import { Dict } from '@/entities'
import objectHash from 'object-hash'
import { validateIsUrl } from '@/utils/function'

/*

- openapi validation is done serverside

- top level must be blocks
- check that any variable referred to is in scope ( collected dynamically, or available at initialisation time )
- check that variables are not re-bound
- check that variables are well-formed (start with alpha, followed by _ or alphanumeric)
- check that it can't loop

*/

// Explore all branches of the spec, yielding the nodes and their path, and any variables in scope
export function* walkSpec(
    spec: ConversationSpec,
    cursor: Cursor,
    scope: Scope = initialScope(),
    visited: Visited = {}
): IterableIterator<NodePath> {
    if (!cursor) {
        return
    }
    const cursorPrint = objectHash(cursor)
    if (visited[cursorPrint]) {
        return
    }
    visited[cursorPrint] = true

    const node = getNode(spec, cursor)
    if (!node) {
        return
    }

    if (node.type === 'Block' || node.type === 'Jump') {
        if (visited[node.label]) {
            // don't proceed any further because it's an infinite loop
            const err = new Error('infinite loop detected')
            return err
        }
    }

    yield { node, cursor, scope }

    const nextScope: Scope =
        node.type === 'ReceiveText'
            ? { ...initialScope(), ...scope, [node.param]: true }
            : node.type === 'ReceiveOption'
              ? {
                    ...initialScope(),
                    ...scope,
                    [node.param]: node.options.map(({ key }) => key),
                }
              : scope

    if (node.type === 'Block' || node.type === 'Jump') {
        visited[node.label] = true
    }

    const nextCursors: Cursor[] = getPossibleNextCursors(spec, node, cursor)
    if (nextCursors.length === 0) {
        return
    }

    for (const nextCursor of nextCursors) {
        for (const next of walkSpec(spec, nextCursor, nextScope, visited)) {
            yield next
        }
    }
}

// Find the next cursors for the current state
// If it's a For, it will be many
// Note this is different to the getNextCursor function in ConversationState
// as this one gets all possible cursors, not just the matched one
export function getPossibleNextCursors(
    spec: ConversationSpec,
    node: SpecNode,
    cursor: Cursor
): Cursor[] {
    if (cursor.length === 0) {
        return [initialCursor(spec)]
    }

    let nextCursor: Cursor | undefined
    const nextCursors: Cursor[] = []

    switch (node.type) {
        case 'Say':
        case 'ReceiveOption':
        case 'ReceiveText':
        case 'For':
            nextCursor = getNextCursorDownstream(spec, cursor, node)
            if (nextCursor) {
                nextCursors.push(nextCursor)
            }
            if (node.type !== 'For') {
                return nextCursors
            }

            // get cursor for each branch
            nextCursors.push(
                ...Object.keys(node.cases).map((caseName) =>
                    cursor.concat(['cases', caseName, 0])
                )
            )
            return nextCursors

        case 'Jump':
        case 'Block':
            nextCursor = findBlockCursor(spec, node.label)
            return nextCursor ? [nextCursor] : []
    }
}

function checkVariables(
    cursor: Cursor,
    variables: string[],
    tests: Array<(variable: string) => string>
): CursorError[] {
    const errors: CursorError[] = []
    const invalidVariables: string[] = []

    variables.forEach((variable) => {
        tests.forEach((test) => {
            const error = test(variable)
            if (error !== '') {
                if (error !== variable) {
                    errors.push({
                        message: error,
                        cursor,
                    })
                } else {
                    invalidVariables.push(`{${variable}}`)
                }
            }
        })
    })

    if (invalidVariables.length) {
        const errorVariables = invalidVariables.join(', ')
        errors.push({
            message: `This card is no longer valid and won't be presented in surveys. The ${errorVariables} field you are customising this card on no longer exists.`,
            cursor,
        })
    }

    return errors
}

export interface CursorError {
    message: string
    cursor: Cursor // <- on which card/case to show the error
}

/**
 * This should take the custom fields and base variables
 * for a question as the initial scope
 */
export function validateSpec(
    spec: ConversationSpec,
    scope: Scope = initialScope()
): CursorError[] {
    let errors: CursorError[] = []

    const blockNames = spec.map((block) => block.label)

    if (!allUnique(blockNames)) {
        return [{ cursor: [], message: `top level block names must be unique` }]
        // return early to prevent infinite loop
    }

    const specWalker = walkSpec(spec, [0], scope, {})

    for (const nodePath of specWalker) {
        const { node, cursor } = nodePath

        let itemErrors: CursorError[] = []

        switch (node.type) {
            case 'Say': {
                itemErrors = checkVariables(
                    cursor,
                    extractVariables(node.value),
                    [checkWellFormed, checkInScope(nodePath.scope)]
                )
                break
            }

            case 'ReceiveText':
            case 'ReceiveOption': {
                itemErrors = checkVariables(
                    cursor,
                    [node.param],
                    [checkWellFormed, checkNotInScope(nodePath.scope)]
                )
                // needs at least 1 option
                if (node.type === 'ReceiveOption') {
                    if (node.options.length < 1) {
                        itemErrors.push({
                            message: `needs 1 or more options`,
                            cursor,
                        })
                    }
                    // option keys must be well formed
                    // and values must be strings
                    for (const { key, value } of node.options) {
                        itemErrors = itemErrors.concat(
                            checkVariables(cursor, [key], [checkWellFormedKey])
                        )
                        if (typeof value !== 'string') {
                            itemErrors.push({
                                message: `label must be a string`,
                                cursor,
                            })
                        }
                    }
                }
                break
            }

            case 'For': {
                // check match in scope
                itemErrors = checkVariables(
                    cursor,
                    [node.match],
                    [checkWellFormed, checkInScope(nodePath.scope)]
                )

                // check cases
                const caseNames = Object.keys(node.cases)
                if (caseNames.length === 0) {
                    itemErrors.push({
                        cursor,
                        message: `needs at least 1 case to match against`,
                    })
                } else {
                    for (const caseName of caseNames) {
                        // well formed case key
                        itemErrors = itemErrors.concat(
                            checkVariables(
                                cursor,
                                [caseName],
                                [checkWellFormedKey]
                            )
                        )
                    }
                    // if received during the survey (in scope, with options),
                    // case name must be one of the option keys (or 'default')
                    if (nodePath.scope[node.match] instanceof Array) {
                        const validKeys = (
                            nodePath.scope[node.match] as string[]
                        ).concat(['default'])
                        for (const caseName of caseNames) {
                            if (validKeys.indexOf(caseName) === -1) {
                                itemErrors.push({
                                    cursor,
                                    message: `case name must match any earlier received option for ${node.match}`,
                                })
                            }
                        }
                    }
                }
                break
            }

            case 'Jump': {
                // block exists
                if (blockNames.indexOf(node.label) === -1) {
                    itemErrors.push({
                        cursor,
                        message: `jump refers to an undefined block`,
                    })
                }
                break
            }

            case 'Block': {
                // has at least 1 item
                if (node.items.length === 0) {
                    itemErrors.push({
                        cursor,
                        message: `block needs at least 1 item`,
                    })
                }
                // has a well formed label
                itemErrors = itemErrors.concat(
                    checkVariables(cursor, [node.label], [checkWellFormed])
                )
                break
            }
        }

        if (itemErrors.length > 0) {
            errors = errors.concat(itemErrors)
        }
    }

    return dedupBy(errors, (err) => err.cursor.join('.') + err.message.trim())
}

export function validateLeadQuestionSpec(
    leadQuestionCardIndex: CardIndex,
    text: string,
    scope: Scope
): CardContextualErrors {
    const { sectionIndex, cardIndex } = leadQuestionCardIndex
    const errors: CardSayError[] = []

    errors.push(
        ...checkVariables([sectionIndex, cardIndex], extractVariables(text), [
            checkWellFormed,
            checkInScope(scope),
        ]).map<CardSayError>(({ message }) => ({ type: 'SayError', message }))
    )

    if (!text) {
        errors.push({ type: 'SayError', message: 'Please enter a question' })
    }

    return collateCardErrors(errors)
}

function extractVariables(text: string): string[] {
    const variables: string[] = []

    text.replace(/{\s*[^\s]+\s*(fallback\s*[^\s]+\s*)*}/gi, (match) => {
        const stripped = match
            .replace(/[{}]/gi, '')
            .split(/\s+/)
            .find((s) => s !== '')

        if (stripped) {
            variables.push(stripped)
        }
        return match
    })

    return variables
}

export const wellFormedRegex = /^[a-z_0-9]*$/g

function checkWellFormed(variable: string): string {
    return !new RegExp(wellFormedRegex).exec(variable)
        ? 'Variable must be only lowercase letters, numbers, underscores'
        : ''
}

export const wellFormedKeyRegex = /^[a-zA-Z_ 0-9\-]*$/g

function checkWellFormedKey(variable: string): string {
    return !new RegExp(wellFormedKeyRegex).exec(variable)
        ? 'Variable must be only letters, numbers, underscores, spaces, dash'
        : ''
}

function checkInScope(scope: Scope) {
    return (variable: string) => (!scope[variable] ? variable : '')
}

// Confirm the branch logic is not including multi-select
function checkNotInMultiParams(multiParams: string[]) {
    return (variable: string) =>
        multiParams.includes(variable)
            ? `The question {"${variable}"} is a multi-select question, therefore it cannot be used for survey logic. This card won't be presented in surveys if you proceed to "save" this setup.`
            : ''
}

function checkNotInScope(scope: Scope) {
    return (variable: string) =>
        scope[variable] ? `Variable "${variable}" has already been used` : ''
}

function checkNotReserveKeyword(card): (variable: string) => string {
    if (card.type != 'question' && card.param == 'comment') {
        return (variable: string) =>
            `Please use another variable name, "${card.param}" is a reserved name`
    }
    return (variable: string) => ''
}

export function initialScope(questionType = 'nps'): Scope {
    let rating = ['promoter', 'passive', 'detractor']
    if (questionType === 'csat') {
        rating = [
            'Very Satisfied',
            'Satisfied',
            'Neutral',
            'Unsatisfied',
            'Very Unsatisfied',
        ]
    }
    if (questionType === 'fivestar') {
        rating = ['5 Star', '4 Star', '3 Star', '2 Star', '1 Star']
    }

    return {
        answer: true,
        rating: rating,
        name: true,
        reviewlink: true,
    }
}

/*
---------- Block builder validation ----------

We don't have to do as many checks for the block builder because the
UI constrains the possible input. However we still need to check that:

- Variables branched on are in scope
- Branch cases are an option of their param
- Text / params / options are not empty
- No loops (master can jump to flows, flows can jump back to after where they left)

We let the user put the spec into an invalid state, and highlight the invalid parts,
but don't let them save it until it is valid. This is similar to validation in
a text editor and has the benefit of communicating validity and prevents
overly locked-down states that can't be further edited.

I'm a bit annoyed I've had to rewrite similar logic to that used on the spec,
but other approaches I tried fell down:
- map cards->spec, get spec errors, map spec->cards with error cursor mapped

*/

export type CardCursor = number[] // [section, card, case]

export type CardNode =
    | { type: 'section'; item: CardSection }
    | { type: 'card'; item: Card }
    | { type: 'case'; item: CardCase<BaseCard> }

export interface CardPath {
    node: CardNode
    cursor: CardCursor
    scope: Scope
    questionsObserved: number
    terminal?: boolean
    path: string[]
    multiParams?: string[]
}

export class InfiniteLoopError extends Error {
    public cursor?: CardCursor
    public node?: CardNode
    constructor(message) {
        super(message)
        this.name = 'InfiniteLoopError'
    }
}

/*
We need to prepare some knowledge about each section/card/case in context.
Cursors may be arrived at multiple times but multiple paths, so we need to aggregate those.
- What is guaranteed to be in scope at that point (the intersection of all possible scopes leading to it)
- What is the longest number of questions to have been observed before that point
- Validation errors
*/

export interface GlobalData {
    scope: Scope
    errors: string[]
}

export interface ContextualData {
    scope: Scope
    questionsObserved: number
    terminal?: boolean
    errors: CardContextualErrors
    multiParams?: string[]
}

interface CardContextualErrors {
    say: string[]
    param: string[]
    jump: string[]
    section: string[]
    optionIndices: number[]
    optionMessages: string[]
    matchIndices: number[]
    matchMessages: string[]
    count: number
}

// Prepare the collected errors for display on a card
function collateCardErrors(errors: CardError[]): CardContextualErrors {
    const say = new Set()
    const param = new Set()
    const jump = new Set()
    const section = new Set()
    let optionIndices = new Set()
    const optionMessages = new Set()
    let matchIndices = new Set()
    const matchMessages = new Set()

    for (const error of errors) {
        switch (error.type) {
            case 'SayError':
                say.add(error.message)
                break
            case 'ParamError':
                param.add(error.message)
                break
            case 'JumpError':
                jump.add(error.message)
                break
            case 'SectionError':
                section.add(error.message)
                break
            case 'OptionError':
                optionIndices = new Set([...optionIndices, ...error.indices])
                optionMessages.add(error.message)
                break
            case 'MatchError':
                matchIndices = new Set([...matchIndices, ...error.indices])
                matchMessages.add(error.message)
                break
        }
    }

    const display = {
        say: Array.from(say),
        param: Array.from(param),
        jump: Array.from(jump),
        section: Array.from(section),
        optionIndices: Array.from(optionIndices),
        optionMessages: Array.from(optionMessages),
        matchIndices: Array.from(matchIndices),
        matchMessages: Array.from(matchMessages),
    } as CardContextualErrors

    const count = Object.values(display).reduce(
        (acc, arr) => arr.length + acc,
        0
    )

    return { ...display, count }
}

interface CardOptionError {
    type: 'OptionError'
    indices: number[]
    message: string
}

interface CardSayError {
    type: 'SayError'
    message: string
}

interface CardParamError {
    type: 'ParamError'
    message: string
}

interface CardMatchError {
    type: 'MatchError'
    indices: number[]
    message: string
}

interface CardSectionError {
    type: 'SectionError'
    message: string
}

interface CardJumpError {
    type: 'JumpError'
    message: string
}

export type CardError =
    | CardOptionError
    | CardSayError
    | CardParamError
    | CardMatchError
    | CardSectionError
    | CardJumpError

export interface ContextualDataAtCursors {
    global: GlobalData
    contextual: Dict<ContextualData>
}

export function deriveContextualData(
    {
        sections,
        fields = {},
        customFields = {},
        requiredSections = ['start', 'finish'],
        requiredParams = ['comment'],
        maxQuestions = 9,
        maxOptions = 7,
        questionType = 'nps',
    } = {
        sections: {} as BlockBuilderModel,
        fields: {} as Scope,
        customFields: {} as Scope,
        requiredSections: ['start', 'finish'],
        requiredParams: ['comment'],
        maxQuestions: 9,
        maxOptions: 7,
        questionType: 'nps',
    }
): ContextualDataAtCursors {
    const baseScope: Scope = { ...fields, ...initialScope(questionType) }

    const global: GlobalData = {
        scope: baseScope,
        errors: [],
    }

    const contextual: Dict<ContextualData> = {}

    const sectionNames = sections.map(({ name }) => name)
    for (const sectionName of requiredSections) {
        if (sectionNames.indexOf(sectionName) === -1) {
            global.errors.push(`"${sectionName}" section is required`)
        }
    }

    const multiParams: string[] = getMultiSelectParams(sections)

    try {
        for (const path of walkBlockBuilderModel(sections, [0], baseScope)) {
            const cursorKey = path.cursor.join('/')
            const known = contextual[cursorKey]
            let scope: Scope
            let questionsObserved: number

            if (!known) {
                scope = { ...initialScope(), ...customFields, ...path.scope }
                questionsObserved = path.questionsObserved
            } else {
                scope = {
                    ...initialScope(),
                    ...customFields,
                    ...minimalScope([known.scope, path.scope]),
                }
                questionsObserved = Math.max(
                    known.questionsObserved,
                    path.questionsObserved
                )
            }

            const errors: CardError[] = validateCardPath(
                { ...path, scope, multiParams },
                sectionNames,
                requiredParams,
                maxOptions
            )

            // Remove the multi params from the scope, so its not shown on dropdown
            for (const scopeParam in scope) {
                if (multiParams.includes(scopeParam)) {
                    delete scope[scopeParam]
                }
            }

            if (questionsObserved > maxQuestions) {
                global.errors.push(
                    `Conversation is limited to ${maxQuestions} questions max by any path`
                )
            }

            if (path.terminal) {
                const requiredNotReceived = requiredParams.filter(
                    (param) => !path.scope.hasOwnProperty(param)
                )
                if (requiredNotReceived.length > 0) {
                    const msg = `Please add a question with key : ${requiredNotReceived.join(
                        ', '
                    )}`
                    if (global.errors.indexOf(msg) === -1) {
                        global.errors.push(msg)
                    }
                }
            }

            contextual[cursorKey] = {
                scope,
                questionsObserved,
                errors: collateCardErrors(errors),
                terminal: path.terminal,
            }
        }
    } catch (err: any) {
        global.errors.push(err.message)
    }

    global.errors = dedupBy(global.errors, (error) => error)

    return { global, contextual }
}

function validateCardPath(
    path: CardPath,
    sectionNames: string[],
    requiredParams: string[],
    maxOptions: number
) {
    const errors: CardError[] = []
    const { node, cursor } = path

    switch (node.type) {
        case 'section':
            errors.push(...validateCardSection(node.item, cursor))
            break

        case 'card':
            if (isBranchedCard(node.item)) {
                errors.push(
                    ...validateBranchCard(
                        node.item,
                        cursor,
                        path.scope,
                        path.multiParams
                    )
                )
            } else {
                errors.push(
                    ...validateBaseCard(
                        node.item as BaseCard,
                        cursor,
                        path.scope,
                        requiredParams,
                        maxOptions,
                        sectionNames
                    )
                )
            }
            break

        case 'case':
            // skip old scorecard in new config
            if (
                (node.item?.card as any)?.options?.find(
                    (option) => !option?.scorecardTopicName && option.badgeId
                )
            ) {
                break
            }

            errors.push(
                ...validateBaseCard(
                    node.item.card,
                    cursor,
                    path.scope,
                    requiredParams,
                    maxOptions,
                    sectionNames
                )
            )
            break
    }
    return errors
}

function validateCardSection(section: CardSection, cursor: CardCursor) {
    const errors: CardError[] = []
    const cards = section.cards
    const lastCard = cards[cards.length - 1]

    if (cards.length === 0) {
        errors.push({
            type: 'SectionError',
            message: `Please add a question to this group`,
        })
    } else if (section.flow && lastCard && lastCard.type !== 'jump') {
        errors.push({
            type: 'SectionError',
            message: `Last item must be a 'jump'`,
        })
    }
    errors.push(
        ...checkVariables(
            cursor,
            [section.name],
            [checkWellFormed]
        ).map<CardSectionError>(({ message }) => ({
            type: 'SectionError',
            message,
        }))
    )
    return errors
}

function validateBranchCard(
    card: BranchCard,
    cursor: CardCursor,
    scope: Scope,
    multiParams: string[] = []
) {
    const errors: CardError[] = []

    // check match in scope
    errors.push(
        ...checkVariables(
            cursor,
            [card.match],
            [
                checkWellFormed,
                checkInScope(scope),
                checkNotInMultiParams(multiParams),
            ]
        ).map<CardMatchError>(({ message }) => ({
            type: 'MatchError',
            message,
            indices: [],
        }))
    )

    // check cases
    const cases = card.cases as CardCases<BaseCard>

    if (cases.length === 0) {
        errors.push({
            type: 'MatchError',
            indices: [],
            message: `needs at least 1 case to match against`,
        })
    } else {
        const caseKeys = cases.map(({ key }) => key)
        for (let i = 0; i < caseKeys.length; i++) {
            const caseName = caseKeys[i]
            // well formed case key
            errors.push(
                ...checkVariables(
                    cursor,
                    [caseName],
                    [checkWellFormedKey]
                ).map<CardMatchError>(({ message }) => ({
                    type: 'MatchError',
                    message,
                    indices: [i],
                }))
            )
        }
        // if received during the survey (in scope, with options),
        // case name must be one of the option keys (or 'default')
        if (scope[card.match] instanceof Array) {
            let validKeys = (scope[card.match] as any[])
                .map((entry) => {
                    if (typeof entry === 'object') {
                        return entry.key
                    }
                    return entry
                })
                .concat(['default'])

            // WEB-13735 Do not show NPS values as errors when matching rating
            if (card.match === 'rating') {
                validKeys = validKeys.concat([
                    'promoter',
                    'passive',
                    'detractor',
                ])
            }

            for (let i = 0; i < caseKeys.length; i++) {
                const caseName = caseKeys[i]
                if (validKeys.indexOf(caseName) === -1) {
                    errors.push({
                        type: 'MatchError',
                        message: `We could no longer find these options on '${card.match}' card.`,
                        indices: [i],
                    })
                }
            }
        }
    }
    return errors
}

function validateBaseCard(
    card: BaseCard,
    cursor: CardCursor,
    scope: Scope,
    requiredParams: string[],
    maxOptions: number,
    sectionNames: string[]
) {
    const errors: CardError[] = []

    if (isJumpCard(card)) {
        if (card.jump && sectionNames.indexOf(card.jump) === -1) {
            errors.push({
                type: 'JumpError',
                message: `This jump is no longer valid`,
            })
        }
    }
    if (isSayCard(card)) {
        errors.push(
            ...checkVariables(cursor, extractVariables(card.say), [
                checkWellFormed,
                checkInScope(scope),
            ]).map<CardSayError>(({ message }) => ({
                type: 'SayError',
                message,
            }))
        )

        const isNotSay = !card.say
        const isNotReviewLinkButton = card.type !== TYPE_REVIEW_LINK_BUTTON
        const isQuestionInConversationalMode =
            card.type === TYPE_QUESTION && card.aiConversationalMode
        if (
            isNotSay &&
            isNotReviewLinkButton &&
            !isQuestionInConversationalMode
        ) {
            errors.push({
                type: 'SayError',
                message: 'Please enter a question',
            })
        }

        if (
            !card.say &&
            card.type === TYPE_REVIEW_LINK_BUTTON &&
            card.linkType === REVIEW_LINK_INPUT
        ) {
            // message for empty input
            errors.push({
                type: 'SayError',
                message: 'Please enter a review URL',
            })
        }

        if (
            !card.say &&
            card.type === TYPE_REVIEW_LINK_BUTTON &&
            card.linkType === REVIEW_LINK_CUSTOM_FIELD
        ) {
            errors.push({
                type: 'SayError',
                message: 'Please select a custom field.',
            })
        }

        if (
            card.type === TYPE_REVIEW_LINK_BUTTON &&
            card.linkType === REVIEW_LINK_INPUT &&
            !validateIsUrl(card.say)
        ) {
            // Trigger URL validation message when the textarea lost focus.
            if (card.validateIt) {
                errors.push({
                    type: 'SayError',
                    message: 'Please enter a valid URL',
                })
            }
        }
    }
    if (isParamCard(card)) {
        errors.push(
            ...checkVariables(
                cursor,
                [card.param],
                [
                    checkWellFormed,
                    checkNotInScope(scope),
                    checkNotReserveKeyword(card),
                ]
            ).map<CardParamError>(({ message }) => ({
                type: 'ParamError',
                message,
            }))
        )

        const paramIndex = requiredParams.indexOf(card.param)
        if (paramIndex > -1) {
            requiredParams.splice(paramIndex, 1)
        }
    }
    if (isOptionsCard(card)) {
        const cardKeys = card.options.map(({ key }) => key)
        const cardLabels = card.options.map(({ value }) => value)

        const nonUniqueKeys = nonUniqueIndices(cardKeys)
        const nonUniqueLabels = nonUniqueIndices(cardLabels)

        const emptyIndexReducer = (acc, key, index) =>
            key === '' ? [index].concat(acc) : acc
        const emptyKeyIndices = cardKeys.reduce<number[]>(emptyIndexReducer, [])
        const emptyLabelIndices = cardLabels.reduce<number[]>(
            emptyIndexReducer,
            []
        )

        if (card.options.length < 1) {
            errors.push({
                type: 'OptionError',
                message: `Please add an option`,
                indices: [],
            })
        } else if (
            card.type !== 'nps' &&
            card.type !== 'list' &&
            card.options.length > maxOptions
        ) {
            errors.push({
                type: 'OptionError',
                message: `${maxOptions} is the max number of options`,
                indices: [],
            })
        }
        if (nonUniqueKeys.length > 0) {
            errors.push({
                type: 'OptionError',
                message: `The option VALUES must be different`,
                indices: nonUniqueKeys,
            })
        }
        if (nonUniqueLabels.length > 0) {
            errors.push({
                type: 'OptionError',
                message: `The option LABELS must be different`,
                indices: nonUniqueLabels,
            })
        }
        if (emptyKeyIndices.length > 0) {
            errors.push({
                type: 'OptionError',
                message: `The option VALUE cannot be empty`,
                indices: emptyKeyIndices,
            })
        }
        if (emptyLabelIndices.length > 0) {
            errors.push({
                type: 'OptionError',
                message: `The option LABEL cannot be empty`,
                indices: emptyLabelIndices,
            })
        }
        card.options.forEach((option, index) => {
            if (option.jump && sectionNames.indexOf(option.jump) === -1) {
                errors.push({
                    type: 'OptionError',
                    message: `This jump is no longer valid`,
                    indices: [index],
                })
            }
        })
    }
    return errors
}

/*
    Take the intersection of:
    - params in a scope
        - options for each param left in that
*/
function minimalScope(scopes: Scope[]): Scope {
    if (scopes.length === 0) {
        return {}
    }

    const merged: Scope = clone(scopes[0])

    for (const scope of scopes.slice(1)) {
        for (const param in scope) {
            if (
                scope.hasOwnProperty(param) &&
                merged.hasOwnProperty(param) &&
                scope[param] instanceof Array &&
                merged[param] instanceof Array
            ) {
                merged[param] = intersection(
                    scope[param] as string[],
                    merged[param] as string[]
                )
            } else {
                delete merged[param]
            }
        }
    }

    return merged
}

/**
 * Simpler version of walkBlockBuilderModel that just walks through
 * the cards and their case's cards linearly, without following any fallbacks or logic.
 * yields card indices rather than cards themselves
 */

export function* walkBlockBuilderModelLinear(sections: BlockBuilderModel) {
    for (let s = 0; s < sections.length; s++) {
        const section = sections[s]

        for (let c = 0; c < section.cards.length; c++) {
            const card = section.cards[c]

            yield { sectionIndex: s, cardIndex: c, section, card }

            if (isBranchedCard(card)) {
                for (let cc = 0; cc < card.cases.length; cc++) {
                    const cardCase = card.cases[cc]
                    yield {
                        sectionIndex: s,
                        cardIndex: c,
                        caseIndex: cc,
                        section,
                        card,
                        cardCase,
                    }
                }
            }
        }
    }
}

/**
 * Generator function that iterates through the model,
 * walking down all possible paths the user might get to,
 * with fallthrough, and following jumps
 */
export function* walkBlockBuilderModel(
    sections: BlockBuilderModel,
    cursor: CardCursor,
    scope: Scope = initialScope(), // data received until this point
    visited: Visited = {}, // names of sections visited until this point
    questionsObserved = 0, // number of questions until this point
    path: string[] = [] // cursors leading to this point,
): IterableIterator<CardPath> {
    if (!cursor) {
        return
    }
    const cursorPrint = objectHash(cursor)
    if (visited[cursorPrint]) {
        return
    }
    visited[cursorPrint] = true

    const node = getCardNode(sections, cursor)
    if (!node) {
        return
    }

    if (node.type === 'section' && visited[node.item.name]) {
        // don't proceed any further because it's an infinite loop
        const err = new InfiniteLoopError(
            `Infinite loop detected, check your jumps back to master from flows`
        )
        err.cursor = cursor
        err.node = node
        throw err
    }

    let nextScope: Scope
    // If this looks like an old scorecard, skip
    if (
        ((node as any).item?.card as any)?.options?.find(
            (option) => !option?.scorecardTopicName && option?.badgeId
        )
    ) {
        nextScope = scope
    }
    // For everything else, there's this hot mess.
    else {
        nextScope =
            node.type === 'card' && !isBranchedCard(node.item)
                ? {
                      ...initialScope(),
                      ...scope,
                      ...extractCardIntroducedScope(node.item as BaseCard),
                  }
                : node.type === 'case'
                  ? {
                        ...initialScope(),
                        ...scope,
                        ...extractCardIntroducedScope(
                            node.item.card as BaseCard
                        ),
                    }
                  : scope
    }

    if (node.type === 'section') {
        visited[node.item.name] = true
    }

    const nextCursors: CardCursor[] = getPossibleNextCardCursors(
        sections,
        node,
        cursor
    )
    const terminal = nextCursors.length === 0

    const isQuestion = node.type === 'card' && isParamCard(node.item)

    const nextQuestionsObserved = questionsObserved + (isQuestion ? 1 : 0)

    yield { node, cursor, scope, questionsObserved, terminal, path }

    if (terminal) {
        return
    }

    for (const nextCursor of nextCursors) {
        for (const next of walkBlockBuilderModel(
            sections,
            nextCursor,
            nextScope,
            visited,
            nextQuestionsObserved,
            path.concat([nextCursor.join('/')])
        )) {
            yield next
        }
    }
}

function getPossibleNextCardCursors(
    sections: BlockBuilderModel,
    node: CardNode,
    cursor: CardCursor
): CardCursor[] {
    if (
        node.type === 'card' &&
        node.item.type === 'jump' &&
        !isBranchedCard(node.item)
    ) {
        const next = findCardSectionCursor(sections, node.item.jump)
        return next ? [next] : []
    }

    const next: CardCursor[] = []

    if (node.type === 'section') {
        // Get first card in section, or next section
        const firstCardCursor = cursor.concat([0])
        const firstCard = getCardNode(sections, firstCardCursor)
        if (firstCard) {
            return [firstCardCursor]
        }

        const nextSectionCursor = [cursor[0] + 1]
        const nextSection = getCardNode(sections, nextSectionCursor)
        if (nextSection) {
            return [nextSectionCursor]
        }
    } else if (node.type === 'card' && isBranchedCard(node.item)) {
        // Get all cases
        const cases = node.item.cases as CardCases<BaseCard>
        cases.forEach((c, caseIndex) => next.push(cursor.concat([caseIndex])))
        return next
    } else {
        // Get downstream - can't fall through to a flow
        const down = getNextCardCursorDownstream(sections, node, cursor)
        const downNode = down && getCardNode(sections, down)
        const fallThroughPrevented =
            downNode && downNode.type === 'section' && downNode.item.flow
        if (down && !fallThroughPrevented) {
            next.push(down)
        }

        // For list cards, get all jump points on options
        const listCard =
            node.type === 'card'
                ? node.item
                : node.type === 'case'
                  ? node.item.card
                  : null

        if (listCard && isOptionsCard(listCard)) {
            listCard.options.forEach(({ jump }) => {
                if (jump) {
                    const jumpCursor = findCardSectionCursor(sections, jump)
                    if (jumpCursor) {
                        next.push(jumpCursor)
                    }
                }
            })
        }
    }

    return next
}

export function findCardSectionCursor(
    sections: BlockBuilderModel,
    name: string
): CardCursor | undefined {
    for (let i = 0; i < sections.length; i++) {
        const section = sections[i]
        if (section.name === name) {
            return [i]
        }
    }
}

function getNextCardCursorDownstream(
    sections: BlockBuilderModel,
    node: CardNode,
    cursor: CardCursor
): CardCursor | undefined {
    // if a case cursor (3 long), lop it off, as we don't want to fall from one case to its sibling
    const indexes = cursor.slice(0, 2)

    for (let i = indexes.length - 1; i >= 0; i--) {
        // start at the end and go <-

        const key = indexes[i]
        const nextCursor = indexes.slice(0, i).concat([key + 1])
        const nextNode = getCardNode(sections, nextCursor)

        if (nextNode && nextNode !== node) {
            return nextCursor
        }
    }
}

function extractCardIntroducedScope(card: BaseCard): Scope {
    if (card.type === 'question') {
        return { [card.param]: true }
    }

    if (isOptionsCard(card)) {
        card = card as OptionsCard

        if (card.type == 'nps') {
            return {
                [card.param]: [
                    { key: 'promoter', value: 'promoter' },
                    { key: 'passive', value: 'passive' },
                    { key: 'detractor', value: 'detractor' },
                ],
            }
        }

        return {
            [card.param]: card.options.map(
                ({ key, value, scorecardTopicName }) => {
                    return {
                        key,
                        value:
                            scorecardTopicName !== undefined
                                ? scorecardTopicName
                                : value,
                    }
                }
            ),
        }
    }

    return {}
}

function getCardNode(
    sections: BlockBuilderModel,
    cursor: CardCursor
): CardNode | undefined | false {
    const [sectionIndex, cardIndex, caseIndex] = cursor

    const section = sections[sectionIndex]
    const card = section && section.cards[cardIndex]
    const cardCase = card && isBranchedCard(card) && card.cases[caseIndex]

    switch (cursor.length) {
        case 1:
            return section && { type: 'section', item: section }
        case 2:
            return card && { type: 'card', item: card }
        case 3:
            return cardCase && { type: 'case', item: cardCase }
    }
}

// Get the multi select params in the cases cards
function getMultiSelectParams(sections: BlockBuilderModel): string[] {
    const multiCaseParams: string[] = []
    for (const section of sections) {
        for (const card of section.cards) {
            if (isBranchedCard(card)) {
                // Cases card
                for (const caseCard of card.cases) {
                    if (isMultiSelectCard(caseCard.card)) {
                        // Case card is multi select
                        multiCaseParams.push(caseCard.card.param)
                        break
                    }
                }
            }
            if (isMultiSelectCard(card)) {
                multiCaseParams.push(card.param)
            }
        }
    }

    return multiCaseParams
}
