import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!

  return (_ctx.positive)
    ? (_openBlock(), _createBlock(_component_FontAwesomeIcon, {
        key: 0,
        icon: _ctx.faAngleDoubleUp,
        class: "positive"
      }, null, 8, ["icon"]))
    : (_openBlock(), _createBlock(_component_FontAwesomeIcon, {
        key: 1,
        icon: _ctx.faAngleDoubleDown,
        class: "negative"
      }, null, 8, ["icon"]))
}