import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeStyle as _normalizeStyle, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-671f7574"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onMousedown"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Typography = _resolveComponent("Typography")!
  const _component_AvatarOrInitials = _resolveComponent("AvatarOrInitials")!

  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      (_ctx.showModal)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "recipient-picker-modal",
            style: _normalizeStyle(_ctx.positionStyle)
          }, [
            (_ctx.loadingRecipients)
              ? (_openBlock(), _createBlock(_component_Loading, {
                  key: 0,
                  loading: _ctx.loadingRecipients,
                  transparent: ""
                }, null, 8, ["loading"]))
              : _createCommentVNode("", true),
            (!_ctx.loadingRecipients && !_ctx.filteredRecipients.length)
              ? (_openBlock(), _createBlock(_component_Typography, {
                  key: 1,
                  class: "no-results",
                  variant: "body2"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Could not find results for: "),
                    _createElementVNode("strong", null, _toDisplayString(_ctx.searchInput), 1)
                  ], undefined, true),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredRecipients, (recipient, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "recipient-item",
                onMousedown: ($event: any) => (_ctx.onRecipientSelect(recipient))
              }, [
                _createVNode(_component_AvatarOrInitials, {
                  class: "avatar",
                  user: _ctx.getRecipientAvatar(recipient),
                  size: 30
                }, null, 8, ["user"]),
                _createVNode(_component_Typography, {
                  class: "recipient-name",
                  variant: "body2"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", {
                      innerHTML: _ctx.highlightSearchInName(recipient.name)
                    }, null, 8, _hoisted_2)
                  ], undefined, true),
                  _: 2
                }, 1024)
              ], 40, _hoisted_1))
            }), 128))
          ], 4))
        : _createCommentVNode("", true)
    ], undefined, true),
    _: 1
  }))
}