import { ConversationSpec } from '../entities/conversation'
import { AvatarSetting } from '@/entities/avatar'
import client, {
    baseClient,
    formClient,
    headerTokenClient,
    publicClient,
} from './client'
import { sleep } from '@/utils/async'
import { headerTokenConfig } from '@/api/clientShared'

declare let ANAPI_TOKEN: string

interface SendReplyResponse {
    error?: string // If something wrong happened, there would be an error message
    redirect?: string // If we're supposed to redirect to another url after the question
    timeout?: string // If the question is no longer valid due to timeout
    newSpec?: any // Updated spec if we've just updated the answer
}

interface SendReplyReq {
    key: string
    value: string
    badgeId?: string
    inputType: string
    questionText: string
    template: string
    version: number
    answer?: number | null
    multipleValues?: boolean
    conversationSlug?: string
}

export async function sendReply(slug: string, reply: SendReplyReq) {
    const { data } = await headerTokenClient.post<SendReplyResponse>(
        `/email/conversation/${slug}`,
        reply
    )
    const { error, newSpec } = data

    if (newSpec) {
        return newSpec
    }

    if (error) {
        alert(error)
    }

    return {}
}

export async function sendReplyDiscreetlyWithRetryOnNetworkFail(
    slug: string,
    reply: SendReplyReq,
    retryCount = 0
) {
    try {
        await headerTokenClient.post<SendReplyResponse>(
            `/email/conversation/${slug}`,
            reply
        )
    } catch (e) {
        // if the user loses network connection this will fail to send so let's retry on loop until it is successful so we don't lose this data
        if (retryCount < 10) {
            await sleep(1000 * (retryCount + 1) * (retryCount + 1))
            await sendReplyDiscreetlyWithRetryOnNetworkFail(
                slug,
                reply,
                retryCount + 1
            )
        }
    }
}

export async function stillHere(slug: string, delayseconds: number) {
    // should really be a POST but can change the endpoint later
    return await publicClient.get(`/email/${slug}/stillhere`, {
        params: {
            delayseconds,
        },
    })
}

export async function getAiQuestion(slug: string) {
    return await publicClient.get(`/email/conversation/${slug}/ai-question`)
}

export async function finished(slug: string, conversationSlug: string) {
    return await publicClient.post(`/email/conversation/${slug}/finished`, {
        conversationSlug,
    })
}

export async function trackReviewLinkClicked(
    slug: string,
    link: string | undefined,
    click: boolean,
    linkSlug: string | undefined
) {
    return await publicClient.post(
        `/email/conversation/${slug}/review-link-clicked`,
        { link, click, linkSlug }
    )
}

export async function preloadYearInReviewFinishedPage(slug) {
    return await publicClient.get(
        '/email/conversation/' + slug + '/finished_year_in_review'
    )
}

export async function verifyCaptchaToken(
    slug: string,
    token: string,
    action: string,
    answer: string,
    conversationSlug: string
) {
    const formData = new FormData()
    formData.append('g-recaptcha-response', token)
    formData.append('g-recaptcha-action', action)
    formData.append('answer', answer)
    formData.append('conversationSlug', conversationSlug)

    return await baseClient.post<{ success: boolean }>(
        `/email/${slug}/verify-captcha`,
        formData
    )
}

export async function logCaptchaLaunched(
    slug: string,
    conversationSlug: string
) {
    const formData = new FormData()
    formData.append('conversationSlug', conversationSlug)

    return await baseClient.post<{ success: boolean }>(
        `/email/${slug}/captcha-launched`,
        formData
    )
}

export async function logAwsWafChallengeBlocked(
    slug: string,
    conversationSlug: string,
    message: string | null = null
) {
    if (!slug || !conversationSlug) {
        return
    }

    return await headerTokenClient.post<{ success: boolean }>(
        `/email/conversation/aws-waf/logging`,
        {
            slug: slug,
            conversation_slug: conversationSlug,
            msg: message,
        }
    )
}

export async function verifyAwsWafChallenge(
    slug: string,
    conversationSlug: string
) {
    if (!slug || !conversationSlug) {
        return
    }

    try {
        // AwsWafIntegration.fetch handle's retrieve of the challenge token
        const response = await (window as any).AwsWafIntegration.fetch(
            `/email/conversation/aws-waf/verify`,
            {
                ...headerTokenConfig,
                method: 'POST',
                body: JSON.stringify({
                    slug: slug,
                    conversation_slug: conversationSlug,
                }),
            }
        )
        if (response.status != 200) {
            throw Error(
                `Request is blocked with ${response.status} response code`
            )
        }
        const { success, msg } = await response.json()
        if (!success) {
            throw Error(`Request is failed with ${msg} response code`)
        }
    } catch (error) {
        await logAwsWafChallengeBlocked(
            slug,
            conversationSlug,
            typeof error === 'string' ? error : (error as Error).message
        )
    }
}

// ---------- Admin functions ----------

interface SpecParams {
    default?: true
    grammar?: number
    use_yaml?: true
}

export async function getSpecLatestVersion(
    template: string,
    params: SpecParams = {}
) {
    const url = `/conversation-spec/${template}`
    return await client.get<ConversationSpec>(url, { params })
}

export async function createSpecVersion(
    template: string,
    spec: ConversationSpec,
    params: SpecParams = {}
) {
    return await client.post(`/conversation-spec/${template}`, spec, { params })
}

export async function resetSpec(template: string, params: SpecParams = {}) {
    return await client.post(`/conversation-spec/reset/${template}`, { params })
}

export async function getFields() {
    return await client.get<string[]>(`/conversation-spec/field`)
}

export async function getFieldOptions(field: string) {
    return await client.get<string[]>(
        `/conversation-spec/field/${field}/option`
    )
}

export async function generateCharacters(template: string) {
    return await baseClient.get<Array<[number, number]>>(
        `/setting/email/avatar/characters/${template}`
    )
}

export async function getCharacter(top, person, color) {
    const colorParam = color.replace(/\#/, '')

    return await baseClient.get<{ success: boolean; url: string }>(
        `/setting/email/avatar/character/${top}/${person}/${colorParam}`
    )
}

/*
    Conversations Helper for pricing
 */
export async function getMrr() {
    return await baseClient.post<{ success: boolean; mrr: string }>(
        `/charge/getmrr`
    )
}

/*
    Conversations Helper - Activate
 */
export async function activateConversations() {
    return await baseClient.post<{ success: boolean }>(
        `/charge/activate_conversations`
    )
}

export async function uploadAvatar(template: string, file: File) {
    const formData = new FormData()
    formData.append('files[]', file)
    formData.append('_token', ANAPI_TOKEN)

    return await formClient.post<{ url: string }>(
        `/setting/email/avatar/upload/${template}`,
        formData
    )
}

export async function saveAvatar(
    template: string,
    avatarUrl: string,
    avatarSetting: AvatarSetting
) {
    return await baseClient.post<{ avatar: string }>(
        `/setting/email/avatar/${template}`,
        {
            avatar: avatarUrl,
            avatarSetting,
        }
    )
}

// @todo ported from legacy code - refactor as rest
export async function getPreviewSlug(
    template: string,
    personId: number,
    scorecardPersonId?: number
) {
    const formData = new FormData()
    formData.append('langId', template)
    formData.append('_token', ANAPI_TOKEN)

    if (scorecardPersonId) {
        return await formClient.post<{ success: boolean; slug: string }>(
            `/queue/preview/getslug/${personId}/${scorecardPersonId}`,
            formData
        )
    } else {
        return await formClient.post<{ success: boolean; slug: string }>(
            `/queue/preview/getslug/${personId}`,
            formData
        )
    }
}

export async function getTestMode(template: string) {
    return await client.get(`/conversation-spec/${template}/test-mode`)
}

export async function toggleTestMode(template: string, testMode: boolean) {
    return await client.post(`/conversation-spec/${template}/test-mode`, {
        enabled: testMode,
    })
}

export async function getTestSurveysSent(template: string) {
    return await client.get(
        `/conversation-spec/${template}/test-mode-sent-count`
    )
}

export async function getPreviewPerson(email = '') {
    return await client.get<string>(
        `/conversation-spec/preview-person${email === '' ? '' : '/' + email}`
    )
}

export async function getPreviewScorecardPersons() {
    return await client.get<Array<{ id: number; name: string }>>(
        `/conversation-spec/scorecard-users`
    )
}

// update conversation question settings
export async function setConversationSetting(param: string, settings: {}) {
    return await client.post(`/conversation-setting/${param}`, settings)
}

export async function revertToClassicSurvey(template: string) {
    return await client.post(`/conversation-spec/${template}/revert-classic`)
}

export async function getConversations(questionId: number) {
    return await client.get(`/conversation-logs/${questionId}`)
}
