<template>
    <div class="reset-mfa-page">
        <FontAwesomeIcon class="key-icon" :icon="faCircleCheck" />
        <Typography
            variant="h4"
            component="h4"
            class="reset reset-title-container"
        >
            MFA Successfully Setup
        </Typography>
        <PillButton
            v-if="!companyVars.has_enforce_mfa"
            label="Reset MFA"
            :disabled="loading"
            class="is-v4"
            @click="resetMFA"
        />
    </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { faCircleCheck } from 'fontawesome/free-solid-svg-icons'
import Typography from '@/components/Rain/Typography/Typography.vue'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import PillButton from '@/components/PillButton.vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { getMFACode } from '@/api/mfa'

const store = useStore()
const router = useRouter()

const loading = ref(false)
const companyVars = computed(() => store.getters['$companyVars'])

async function resetMFA() {
    loading.value = true
    let result = await getMFACode(false)
    if (result.data.success) {
        await store.dispatch('saveHasMFA', false)
        await router.replace({ name: 'mfa-setup' })
    }
    loading.value = false
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/variables.less';
@import '~@/styles/variables.less';
@import '~@/styles/palette.less';

.reset-mfa-page {
    height: calc(100vh - 2 * @filterHeaderHeight);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: white;
    .reset-title-container {
        margin: @featureGutter 0;
        color: white;
    }
    .reset-title-container {
        margin-bottom: @featureGutter;
    }
    .key-icon {
        height: 40px;
    }
}
</style>
