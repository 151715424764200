<template>
    <div class="react-wrapper">
        <div v-if="showReactions" class="emoji-reactions-container">
            <!-- Show reactions -->
            <div
                v-for="(emoji, index) in reactions"
                :key="index"
                :class="['emoji-count', { self: emoji.self }]"
                @click.stop="react(emoji.emoji)"
            >
                <span class="emoji-display">{{ emoji.emoji }}</span>
                <Typography variant="overline" class="like-count">{{
                    emoji.count
                }}</Typography>
            </div>
            <button
                class="add-reaction-button open-reacts"
                @click.stop="toggleReactButtons"
            >
                <img :src="addEmojiIcon" alt="add emoji" />
            </button>
        </div>
        <div v-else>
            <!-- Show emoji picker -->
            <div class="react-button-panel">
                <div class="react-options">
                    <button
                        v-for="(emoji, emojiIdx) in emojiList"
                        :key="emojiIdx"
                        class="react-button"
                        @click="onReact(emoji)"
                    >
                        {{ emoji }}
                    </button>
                </div>
                <div class="react-close">
                    <button
                        class="x-close-button"
                        @click.stop="toggleReactButtons"
                    >
                        <FontAwesomeIcon class="xmark" :icon="faCircleXmark" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import Typography from '@/components/Rain/Typography/Typography.vue'
import { faCircleXmark } from 'fontawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import AddEmojiIcon from '@/assets/img/Icons/add-emoji.svg'
import { EmojiReactionDetails } from '@/entities'

@Component({
    components: {
        FontAwesomeIcon,
        Typography,
    },
    emits: ['onReact'],
})
export default class EmojiReactions extends Vue {
    @Prop({ type: Array, required: true })
    public readonly reactions!: EmojiReactionDetails[]

    public showReactions = true
    public addEmojiIcon = AddEmojiIcon

    private readonly faCircleXmark = faCircleXmark
    //Make sure this matches the EmojiReactionRepository's list of valid emoji
    private readonly emojiList = ['👍', '👎', '❤️', '🥳', '😀', '😢', '🥝']

    private react(emoji: string): void {
        this.$emit('onReact', { emoji: emoji })
    }

    public toggleReactButtons() {
        this.showReactions = !this.showReactions
    }

    private onReact(emoji: string): void {
        // Increment local count
        this.react(emoji)
        this.toggleReactButtons()
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';

.react-wrapper {
    border-top: 1px solid @thinFog;
    padding: 8px @featureGutter;

    .react-button-panel {
        display: flex;
        justify-content: space-between;

        .react-options {
            display: flex;
            flex-direction: row;
            gap: 20px;
        }

        .react-close {
            display: flex;
            align-items: center;

            .x-close-button {
                border: none;
                background: none;
                color: white;
            }
            .xmark {
                width: 16px;
                height: 16px;
            }
        }
    }

    .emoji-reactions-container {
        display: flex;
        flex-direction: row;
        gap: 10px;
        height: 24px;

        .emoji-count {
            display: flex;
            align-items: center;
            height: 16px;
            padding: 3px @gutterSpacing-base;
            border: 1px solid @white30;
            border-radius: 6px;

            .emoji-display {
                //height: 22px;
            }

            &.self {
                background: rgba(255, 255, 255, 0.2);
            }
        }

        .like-count {
            padding-left: 4px;
            color: white;
        }

        .add-reaction-button {
            align-items: center;
            background: unset;
            border: unset;
            display: flex;
            height: 24px;

            img {
                padding-right: 5px;
            }

            .plus-reaction {
                color: #fff;
            }
        }
    }
}

.react-button {
    background: unset;
    border: unset;
    display: flex;
    height: 24px;
    align-items: center;
    padding: 0;
}
</style>
