import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PillButtonWithIcon = _resolveComponent("PillButtonWithIcon")!

  return (_openBlock(), _createBlock(_component_PillButtonWithIcon, {
    class: _normalizeClass(['status-pill-button', _ctx.status]),
    label: _ctx.label,
    "is-active": _ctx.isActive,
    disabled: _ctx.disabled,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
  }, null, 8, ["class", "label", "is-active", "disabled"]))
}