<template>
    <div
        v-if="engagementTheme"
        class="engagement-theme-trend-container"
        :class="{ 'desktop-no-margin': !$isMobile }"
    >
        <BaseCard v-if="$isMobile" class="engagement-theme-trend-header">
            <InsightsEngagementThemeItem
                :key="engagementTheme.id"
                :theme-id="engagementTheme.id"
                :title="engagementTheme.name"
                :points="engagementTheme.change_in_term_data.score"
                :overall-score="engagementTheme.current_term_data.score"
                :previous-score="engagementTheme.previous_term_data.score"
                :delta-positive="engagementTheme.change_in_term_data.score > 0"
                :history="engagementTheme.history"
                :show-info-icon="true"
            />
        </BaseCard>
        <div class="schedule-one">
            <BaseButton
                full
                :disabled="scheduleMeetingUser === null"
                @click="onMessageUser"
            >
                Schedule 1:1<template v-if="scheduleMeetingUser">
                    with {{ scheduleMeetingUser.name }}</template>
            </BaseButton>
        </div>
        <Component :is="$isMobile ? 'BaseCard' : 'div'">
            <Tabs :active="activeTab" vsmall full-width @change="setActiveTab">
                <Tab
                    v-for="group in userGroups"
                    :key="group.name"
                    :name="group.name"
                    :is-active="activeTab === group.name"
                >
                    <InsightsEngagementSpotlightList
                        class="insights-engagement-list"
                        :spotlight-team="group.users"
                        :theme="engagementTheme.name"
                    />
                    <EngagementEmptyTeamMembersAttentionState
                        v-if="group.users.length < 1"
                        :state="group.name"
                        class="engagement-empty-state"
                    />
                </Tab>
            </Tabs>
        </Component>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { useRouter } from 'vue-router'
import { EngagementQuestionType } from '@/entities/engagement'
import Typography from '@/components/Rain/Typography/Typography.vue'
import Controls from '@/components/Controls.vue'
import MessageBubble from '@/mobile/src/components/engagement/TeamSpotlight/MessageBubble.vue'
import GradientHighlightedCard from '@/components/GradientHighlightedCard.vue'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import {
    tileDescription,
    tilePercentage,
    engagementIcon,
} from '@/mobile/src/components/engagement/utils'
import InsightsEngagementSpotlightList from '@/mobile/src/components/Insights/InsightsEngagementSpotlightList.vue'
import BaseButton from '@/components/Rain/Buttons/BaseButton.vue'
import Tabs from '@/components/Tabs.vue'
import Tab from '@/components/Tab.vue'
import InsightsEngagementThemeItem from '@/mobile/src/components/Insights/InsightsEngagementThemeItem.vue'
import BaseCard from '@/components/BaseCard.vue'
import { sortByTeamEngagementPercentageChange } from '@/utils/insights'
import EngagementEmptyTeamMembersAttentionState from '@/mobile/src/components/engagement/TeamTheme/EngagementEmptyTeamMembersAttentionState.vue'
import { Getter, Action } from 'vuex-facing-decorator'

@Component({
    components: {
        EngagementEmptyTeamMembersAttentionState,
        BaseCard,
        InsightsEngagementThemeItem,
        Tabs,
        Tab,
        BaseButton,
        MessageBubble,
        Controls,
        Typography,
        FontAwesomeIcon,
        InsightsEngagementSpotlightList,
        GradientHighlightedCard,
    },
    methods: {
        engagementIcon,
        tilePercentage,
        tileDescription,
    },
})
export default class EngagementThemeTrend extends Vue {
    selectedType = 'ALL'
    public activeTab = ''

    @Prop({
        required: false,
        default: () => {
            return null
        },
    })
    public engagementTheme

    private router = useRouter()

    @Getter
    $isMobile

    @Action
    setShowNoticesComposer

    public mounted() {
        if (this.engagementTheme && this.engagementTheme.recursive_data) {
            this.activeTab = this.userGroups[0].name
        }
    }

    public setActiveTab(tabKey) {
        this.activeTab = tabKey
    }

    get scheduleMeetingUser() {
        if (this.userGroups[0].users.length < 1) {
            return null
        }

        return this.userGroups[0].users[0]
    }

    get userGroups() {
        let recursiveData = sortByTeamEngagementPercentageChange(
            this.engagementTheme.recursive_data
        )
        return [
            {
                name: 'Needs Attention',
                users: recursiveData.filter(
                    (user) => user.change_in_term_data.score < 0
                ),
            },
            {
                name: 'Doing Well',
                users: recursiveData.filter(
                    (user) => user.change_in_term_data.score >= 0
                ),
            },
        ]
    }

    isFiveStarType(type) {
        return type === EngagementQuestionType.FiveStar
    }

    onSelectQuestionType(type) {
        this.selectedType = type
    }

    public onMessageUser() {
        if (this.scheduleMeetingUser) {
            if (this.$isMobile) {
                this.router.push({
                    name: 'notices-pick-type-user',
                    params: { preselectedUserId: this.scheduleMeetingUser.id },
                })
            } else {
                this.setShowNoticesComposer({
                    show: true,
                    userId: this.scheduleMeetingUser.id,
                })
            }
        }
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';

.check-in-history-container {
    padding: @gutterSpacing-md;

    .controls {
        display: inline-block;
    }

    .questions {
        .question {
            .date {
                text-align: center;
            }
        }

        .answer {
            display: flex;
            flex-direction: row-reverse;
            margin-top: @gutterSpacing-base;
        }
    }
}

.engagement-theme-trend-container {
    .engagement-theme-trend-header {
        padding: @gutterSpacing-md;
    }

    margin: 12px;
}

.desktop-no-margin {
    margin: 0;
    background-color: @white;
}

.schedule-one {
    margin: 24px 16px;
    padding-top: 14px;
}

.engagement-empty-state {
    padding: @gutterSpacing-lg @gutterSpacing-xl;
}
</style>
