import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6ffa1a4b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "suggestions-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_SuggestionDialog = _resolveComponent("SuggestionDialog")!
  const _component_SuggestionsCard = _resolveComponent("SuggestionsCard")!
  const _component_Scrollable = _resolveComponent("Scrollable")!

  return (_openBlock(), _createBlock(_component_Scrollable, {
    "on-scroll-end": _ctx.loadMore,
    "pull-down": _ctx.refresh,
    class: "suggestion-rows",
    "bottom-padding": ""
  }, {
    default: _withCtx(() => [
      _withDirectives(_createVNode(_component_Loading, {
        loading: _ctx.isRefreshing,
        contained: ""
      }, null, 8, ["loading"]), [
        [_vShow, _ctx.isRefreshing]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.suggestionListView, (suggestion) => {
          return (_openBlock(), _createBlock(_component_SuggestionsCard, {
            key: suggestion.id
          }, {
            default: _withCtx(() => [
              _createVNode(_component_SuggestionDialog, {
                suggestion: suggestion,
                "in-dashboard": _ctx.inDashboard,
                "clip-length": _ctx.suggestionClipLength
              }, null, 8, ["suggestion", "in-dashboard", "clip-length"])
            ], undefined, true),
            _: 2
          }, 1024))
        }), 128))
      ], 512), [
        [_vShow, !_ctx.isRefreshing]
      ])
    ], undefined, true),
    _: 1
  }, 8, ["on-scroll-end", "pull-down"]))
}