declare let ANAPI_TOKEN: string

export const baseConfig = {
    headers: {
        'Content-Type': 'application/json',
    },
    params: {
        _token: ANAPI_TOKEN,
    },
}

export const headerTokenConfig = {
    headers: {
        'Content-Type': 'application/json',
        'X-Csrf-Token': ANAPI_TOKEN,
    },
}

export default baseConfig
