<template>
    <div class="topic-insights">
        <div class="topic-insights-header">
            <FontAwesomeIcon :icon="faLightbulbOn" size="lg" />
            <Typography variant="subtitle1">Insights</Typography>
        </div>
        <div class="smileys">
            <div
                v-for="(topic, index) in topicsWithLargestCSATDifference"
                :key="index"
                class="topic-smiley"
            >
                <FontAwesomeIcon
                    :icon="getSmileyIcon(topic.csat)"
                    :class="`smiley-icon--${topic.csat > baseCsatGoal ? 'smile' : 'frown'}`"
                    size="xl"
                />
                <Typography variant="body2" class="topic-name">
                    {{ topic.topic }}
                </Typography>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, type ComputedRef } from 'vue'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import {
    faLightbulbOn,
    faFaceFrownSlight,
} from 'fontawesome/pro-regular-svg-icons'
import { faFaceSmile } from 'fontawesome/free-regular-svg-icons'
import { Topic } from '@/entities/huddles'
import Typography from '@/components/Rain/Typography/Typography.vue'

interface Props {
    topics: Topic[]
    baseCsatGoal: number
    summaryView: boolean
}
const props = withDefaults(defineProps<Props>(), {
    summaryView: false,
})

const getCsatDifference = (csat: number, csatGoal: number) => {
    return Math.abs(Math.abs(csat) - csatGoal)
}
const topicsWithLargestCSATDifference: ComputedRef<Topic[]> = computed(() => {
    return [...props.topics]
        .sort((a, b) => {
            const aDifference = getCsatDifference(a.csat, props.baseCsatGoal)
            const bDifference = getCsatDifference(b.csat, props.baseCsatGoal)
            return aDifference > bDifference ? -1 : 1
        })
        .splice(0, 3)
})

const getSmileyIcon = (csat: number) => {
    return csat > props.baseCsatGoal ? faFaceSmile : faFaceFrownSlight
}
</script>

<style lang="less" scoped>
@import '@/styles/prime-vue/variables.less';
@import '@/styles/rain/variables.less';
@import '@/styles/rain/colour.less';

.topic-insights {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.topic-insights-header {
    display: flex;
    gap: @spacing-sm;
    color: var(--p-gray-600);
    align-items: center;
    margin-bottom: @spacing-md;
}

.smileys {
    display: flex;

    .topic-smiley {
        display: flex;
        flex-direction: column;
        flex: 1;
        max-width: 120px;
        padding: 0 4px;

        .smiley-icon {
            &--smile {
                color: @successGreen;
            }
            &--frown {
                color: var(--p-gray-500);
            }
        }

        .topic-name {
            color: var(--p-gray-600);
            text-align: center;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}
</style>
