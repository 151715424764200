import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { notifyError, notifySuccess } from '@/utils/notices'
import { Datapipe } from '@/pages/simple-survey/entities/datapipe'
import {
    initSurvey,
    SurveyApp,
    defaultSurveyApp,
    prioritizedSurveySteps,
    SurveyPreviewVariables,
    initSurveyPreviewVariables,
} from '@/pages/simple-survey/entities/survey'
import {
    Schedule,
    TouchBaseSchedule,
    SurveyExpiryType,
    SurveyLimitType,
    RuleSettings,
    initRuleSettings,
    Schedulerrule,
} from '@/pages/simple-survey/entities/schedule'
import {
    deleteSurvey,
    duplicateSurvey,
    getSurveys,
    loadDatapipes,
    loadDatasources,
    saveGlobalContactRules,
    setStatus,
    updateAudienceAndTiming,
    updateSurvey,
} from '@/api/survey'
import {
    GlobalContactRules,
    initGlobalContactRules,
    LocalContactRules,
} from '@/pages/simple-survey/entities/contactRules'
import { pushBackToError } from '@/utils/survey'
import { SubNav, SubNavItem } from '@/pages/simple-survey/entities/subNav'
import { LoadState } from '@/common/interfaces/loadstate'
import { mutateObject } from '@/utils/object'
import {
    SurveyQuestionsType,
    ReviewType,
} from '@/pages/simple-survey/entities/design'

export interface SurveyState {
    subNav: SubNav
    globalContactRules: GlobalContactRules
    datasources: string[]
    ruleSettings: RuleSettings
    surveysIds: string[]
    surveyId: string
    surveys: { [surveyId: string]: SurveyApp }
    surveyQuestionsSet: { [surveyId: string]: SurveyQuestionsType }
    surveyReviews: { [surveyId: string]: ReviewType }
    surveySchedulers: { [surveyId: string]: Schedule | TouchBaseSchedule }
    surveyContactRulesSet: { [surveyId: string]: LocalContactRules }
    surveyExpirys: { [surveyId: string]: SurveyExpiryType }
    surveyLimits: { [surveyId: string]: SurveyLimitType }
    surveyDataSourceIdsSets: { [surveyId: string]: string[] }
    surveySchedulerrulesSets: { [surveyId: string]: Schedulerrule[] }
    surveyDatapipes: { [surveyId: string]: Datapipe[] }
    surveyPreviews: { [surveyId: string]: SurveyPreviewVariables }
    unsavedChanges: boolean
    unsavedChangesAlert: boolean
    loadState: {
        globalContactRules: LoadState
        surveyDatapipes: LoadState
        datasources: LoadState
        overview: LoadState
    }
}

export const state: SurveyState = {
    subNav: { title: '', items: [] },
    globalContactRules: initGlobalContactRules,
    datasources: [],
    ruleSettings: initRuleSettings,
    surveysIds: [],
    surveyId: '',
    surveys: {},
    surveyQuestionsSet: {},
    surveyReviews: {},
    surveySchedulers: {},
    surveyContactRulesSet: {},
    surveyExpirys: {},
    surveyLimits: {},
    surveyDataSourceIdsSets: {},
    surveySchedulerrulesSets: {},
    surveyDatapipes: {},
    surveyPreviews: {},
    unsavedChanges: false,
    unsavedChangesAlert: false,
    loadState: {
        globalContactRules: LoadState.UNSET,
        surveyDatapipes: LoadState.UNSET,
        datasources: LoadState.UNSET,
        overview: LoadState.UNSET,
    },
}

const actions: ActionTree<SurveyState, any> = {
    async deleteSurvey({ commit }, id: number) {
        await deleteSurvey(id)
        commit('removeSurvey', id)
    },
    async duplicateSurvey({ commit }, id: number) {
        const newSurvey = await duplicateSurvey(id)
        commit('addSurvey', newSurvey)
        commit('addSubNavItem', {
            title: newSurvey.name,
            href: newSurvey.id,
        })
    },
    async updateAudienceAndTiming(
        { commit, state: { surveyId } },
        { config, $router }
    ) {
        try {
            const {
                data: { success, errors, survey },
            } = await updateAudienceAndTiming(config, surveyId)
            if (!success) {
                pushBackToError($router, errors)
            } else {
                await commit('updateSurvey', survey)
            }
            return success
        } catch (e: any) {
            if (!e.response?.data) {
                return
            }
            const {
                response: {
                    data: { success, survey },
                },
            } = e
            await commit('updateSurvey', survey)
        }
    },
    async setSurveyStatus({ commit }, { id, status }) {
        try {
            const { data } = await setStatus(id, status)

            if (data.success) {
                commit('updateSurvey', data.survey)
            }
        } catch (e) {
            notifyError('Something went wrong', {
                pos: 'top-right',
                timeout: 5000,
            })
        }
    },
    async updateLeadQuestionAndCsatLabels({
        commit,
        state: { surveyId, surveys },
    }) {
        try {
            const {
                body,
                csat_very_satisfied,
                csat_satisfied,
                csat_neutral,
                csat_unsatisfied,
                csat_very_unsatisfied,
            } = surveys[surveyId]
            const {
                data: { survey },
            } = await updateSurvey(surveyId, {
                body,
                csat_very_satisfied,
                csat_satisfied,
                csat_neutral,
                csat_unsatisfied,
                csat_very_unsatisfied,
            })
            commit('updateSurvey', survey)
        } catch (e: any) {
            if (e.response) {
                const {
                    response: {
                        data: { reason, survey },
                    },
                } = e
                commit('updateSurvey', survey)
            }
        }
    },
    async updateSurvey({ commit }, survey) {
        commit('updateSurvey', survey)
        commit('renameSubNavItem', { href: survey.id, title: survey.name })
    },
    async addNewSurvey({ commit }) {
        commit('addSurvey', {
            ...initSurvey,
        })
    },
    async loadSurveys(params, surveys) {
        const { commit } = params
        commit('setSurveys', surveys)
    },
    async loadSurveyDatapipes(
        { commit, state: { surveyId, loadState } },
        type
    ) {
        if (loadState.surveyDatapipes === LoadState.LOADING) {
            return
        }

        await commit('setLoadState', {
            surveyDatapipes: LoadState.LOADING,
        })

        try {
            const request = await loadDatapipes(surveyId, { type })
            const surveyDatapipes = request.data
            await commit('setSurveyDatapipes', surveyDatapipes)
            await commit('setLoadState', {
                surveyDatapipes: LoadState.LOADED,
            })
        } catch (e) {
            await commit('setLoadState', {
                surveyDatapipes: LoadState.ERROR,
            })
        }
    },
    async loadDatasources({ commit, state: { loadState } }, type) {
        if (loadState.datasources === LoadState.LOADING) {
            return
        }

        await commit('setLoadState', {
            datasources: LoadState.LOADING,
        })

        try {
            const request = await loadDatasources(type)
            const datasources = request.data
            await commit('setDatasources', datasources)
            await commit('setLoadState', {
                datasources: LoadState.LOADED,
            })
        } catch (e) {
            await commit('setLoadState', {
                datasources: LoadState.ERROR,
            })
        }
    },
    async updateGlobalContactRules({ commit, state: { loadState } }, send) {
        if (loadState.globalContactRules === LoadState.LOADING) {
            return
        }

        await commit('setLoadState', {
            globalContactRules: LoadState.LOADING,
        })

        try {
            const { data } = await saveGlobalContactRules(send)
            // todo: notifySuccess should be refactored out of Vuex
            notifySuccess(data.msg, {
                pos: 'top-right',
                timeout: 5000,
            })
            commit('setGlobalContactRules', {
                ...send,
            })
            await commit('setLoadState', {
                globalContactRules: LoadState.LOADED,
            })
        } catch (error) {
            // todo: notifyError should be refactored out of Vuex
            notifyError('Something went wrong', {
                pos: 'top-right',
                timeout: 5000,
            })
            await commit('setLoadState', {
                globalContactRules: LoadState.ERROR,
            })
        }
    },
    async fetchLatestSurveyOverviews({
        commit,
        state: { surveys, surveysIds },
    }) {
        const surveyIdsToFetch: string[] = surveysIds.filter((id) =>
            /pending_send|live/.test(surveys[id]?.status ?? 'live')
        )
        const { data } = await getSurveys(surveyIdsToFetch)

        data.forEach((survey) => commit('updateSurvey', survey))
    },
}

const mutations: MutationTree<SurveyState> = {
    addSurvey(state, survey) {
        state.surveys = mutateObject(state.surveys, survey.id, survey)
        state.surveysIds.push(survey.id)
    },
    setSurveyId(state, id) {
        state.surveyId = id
    },
    setSurveyScheduler(state, curSchedule) {
        state.surveySchedulers = mutateObject(
            state.surveySchedulers,
            state.surveyId,
            curSchedule
        )
    },
    setSurveyQuestions(state, surveyQuestions) {
        state.surveyQuestionsSet = mutateObject(
            state.surveyQuestionsSet,
            state.surveyId,
            surveyQuestions
        )
    },
    setSurveyReview(state, surveyReview) {
        state.surveyReviews = mutateObject(
            state.surveyReviews,
            state.surveyId,
            surveyReview
        )
    },
    setSurveyContactRules(state, localContactRules) {
        state.surveyContactRulesSet = mutateObject(
            state.surveyContactRulesSet,
            state.surveyId,
            localContactRules
        )
    },
    setSurveyExpiry(state, surveyExpiry) {
        state.surveyExpirys = mutateObject(
            state.surveyExpirys,
            state.surveyId,
            surveyExpiry
        )
    },
    setSurveyLimit(state, surveyLimit) {
        state.surveyLimits = mutateObject(
            state.surveyLimits,
            state.surveyId,
            surveyLimit
        )
    },
    setSurveyDataSourceIdsSet(state, selectedSourceIds) {
        state.surveyDataSourceIdsSets = mutateObject(
            state.surveyDataSourceIdsSets,
            state.surveyId,
            selectedSourceIds
        )
    },
    setSurveySchedulerrules(state, schedulerrules) {
        state.surveySchedulerrulesSets = mutateObject(
            state.surveySchedulerrulesSets,
            state.surveyId,
            schedulerrules
        )
    },
    setRuleSettings(state, ruleSettings) {
        state.ruleSettings = ruleSettings
    },
    removeSurvey(state, id: string) {
        // https://stackoverflow.com/questions/33053310/remove-value-from-object-without-mutation
        const { [id]: omit, ...keep } = state.surveys
        state.surveys = keep

        state.surveysIds = state.surveysIds.filter((curId) => curId !== id)
    },
    setGlobalContactRules(state, data) {
        state.globalContactRules = data
    },
    setSurveys(state, surveys) {
        state.surveys = surveys.reduce((all, survey) => {
            all[survey.id] = survey
            state.surveysIds.push(survey.id)
            return all
        }, {})
    },
    setSurveyDatapipes(state, surveyDatapipes) {
        state.surveyDatapipes[state.surveyId] = surveyDatapipes
    },
    setDatasources(state, data) {
        state.datasources = data
    },
    setLoadState(state, data) {
        state.loadState = {
            ...state.loadState,
            ...data,
        }
    },
    setSurveyPreviews(state, surveyPreviews) {
        state.surveyPreviews = mutateObject(
            state.surveyPreviews,
            state.surveyId,
            surveyPreviews
        )
    },
    setSurveyField(state, { field, value }) {
        const survey = state.surveys[state.surveyId]
        if (!survey) {
            return
        }
        state.surveys = {
            ...state.surveys,
            [state.surveyId]: {
                ...survey,
                [field]: value,
            },
        }
    },
    updateSurvey(state, data) {
        const survey = state.surveys[data.id]
        if (!survey) {
            return
        }

        state.surveys = {
            ...state.surveys,
            [survey.id]: {
                ...survey,
                ...data,
                id: survey.id,
            },
        }
    },
    addSubNavItem({ subNav }, data: SubNavItem) {
        subNav.items.push(data)
    },
    renameSubNavItem({ subNav }, data: SubNavItem) {
        subNav.items = subNav.items.map((item) =>
            item.href === data.href ? data : item
        )
    },
    removeSubNavItem({ subNav }, removeHref: string) {
        subNav.items = subNav.items.filter(({ href }) => href !== removeHref)
    },
    setSubNav(state, data) {
        state.subNav = data
    },
    setUnsavedChanges(state, data) {
        state.unsavedChanges = data
    },
    setUnsavedChangesAlert(state, data) {
        state.unsavedChangesAlert = data
    },
}

const getters: GetterTree<SurveyState, any> = {
    getGlobalContactRules: ({ globalContactRules }) => globalContactRules,
    getSurveys: (state) => state.surveysIds.map((id) => state.surveys[id]),
    getSurveyId: ({ surveyId }) => surveyId,
    getSurveyIsEditable: (state, { getSurvey }) =>
        !/live|pending_send/.test(getSurvey.status || 'draft'),
    getSurveyIsDraft: (state, { getSurvey }) => getSurvey.status === 'draft',
    getSurveyIsTouchBase: (state, { getSurvey }) =>
        getSurvey.audience_type === 'touchbase',
    getSurveyIsFollowUp: (state, { getSurvey }) =>
        getSurvey.audience_type === 'followup',
    getSurvey: ({ surveyId, surveys }) => surveys[surveyId] ?? defaultSurveyApp,
    getSurveyById:
        ({ surveys }) =>
        (id) =>
            surveys[id] ?? defaultSurveyApp,
    getSurveyQuestions: ({ surveyId, surveyQuestionsSet }) =>
        surveyQuestionsSet[surveyId],
    getSurveyReview: ({ surveyId, surveyReviews }) => surveyReviews[surveyId],
    getSurveyScheduler: ({ surveyId, surveySchedulers }) =>
        surveySchedulers[surveyId],
    getSurveyContactRules: ({ surveyId, surveyContactRulesSet }) =>
        surveyContactRulesSet[surveyId],
    getSurveyExpiry: ({ surveyId, surveyExpirys }) => surveyExpirys[surveyId],
    getSurveyLimit: ({ surveyId, surveyLimits }) => surveyLimits[surveyId],
    getSurveyDataSourceIds: ({ surveyId, surveyDataSourceIdsSets }) =>
        surveyDataSourceIdsSets[surveyId],
    getSurveySchedulerrules: ({ surveyId, surveySchedulerrulesSets }) =>
        surveySchedulerrulesSets[surveyId],
    getSurveyDatapipes: ({ surveyId, surveyDatapipes }) =>
        surveyDatapipes[surveyId] ?? [],
    getSurveyLoadState: ({ loadState }) => loadState,
    getSurveyPreviews: ({ surveyId, surveyPreviews }) =>
        surveyPreviews[surveyId] ?? initSurveyPreviewVariables,
    isTabAllowed:
        ({ surveyId, surveys }) =>
        (surveyStepIndex) => {
            const survey = surveys[surveyId] ?? defaultSurveyApp

            for (
                let i = 0;
                i < prioritizedSurveySteps.length && i < surveyStepIndex;
                i++
            ) {
                const nextStep = prioritizedSurveySteps[i]
                if (!survey.valid[nextStep]) {
                    return false
                }
            }
            return true
        },
    getDatasources: ({ datasources }) => datasources,
    getRuleSettings: ({ ruleSettings }) => ruleSettings,
    getSubNav: ({ subNav }) => subNav,
    getDatasourceToDatapipes:
        ({ surveyId, surveyDatapipes }) =>
        (selectedSourceIds) => {
            const sources = {}
            surveyDatapipes[surveyId].forEach(({ id, sourceName, name }) => {
                if (!sources[sourceName]) {
                    sources[sourceName] = []
                }
                sources[sourceName].push({
                    id: String(id),
                    title: name,
                    parentTitle: sourceName,
                    display: true,
                    checked: selectedSourceIds.includes(String(id)),
                })
            })
            return sources
        },
    getUnsavedChanges: ({ unsavedChanges }) => unsavedChanges,
    getUnsavedChangesAlert: ({ unsavedChangesAlert }) => unsavedChangesAlert,
}

export default {
    namespaced: false,
    state,
    actions,
    mutations,
    getters,
}
