<template>
    <div class="horizontal-scroll-container">
        <slot />
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'

@Component
export default class HorizontalScrollContainer extends Vue {}
</script>

<style scoped lang="less">
@import '~@/styles/variables';

.horizontal-scroll-container {
    overflow-x: auto;

    /* make it smooth on iOS */
    -webkit-overflow-scrolling: touch;

    /* hide the scrollbar */
    scrollbar-width: none;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none; // IE 10+

    &::-webkit-scrollbar {
        display: none;
    }
}
</style>
