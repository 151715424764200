<template>
    <WebViewScreen no-pad class="insights" show-go-back disable-when-offline>
        <template #custom-title>
            <TwoLineHeader :title="headerTitle" :subtitle="friendlyTimeRange" />
        </template>
        <template #right-button>
            <InsightsTimePickerMobile
                :time-range="timeRange"
                @selectTime="updateTimeRange"
            />
        </template>

        <RouterView v-slot="{ Component }">
            <Scrollable>
                <component :is="Component" />
            </Scrollable>
        </RouterView>
    </WebViewScreen>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { Action, Getter, namespace } from 'vuex-facing-decorator'
import InsightsTimePickerMobile from '@/mobile/src/components/Insights/InsightsTimePickerMobile.vue'
import WebViewScreen from '@/components/WebView/WebViewScreen.vue'
import TwoLineHeader from '@/mobile/src/components/TwoLineHeader.vue'
import { ITimeRange } from '@/entities/insights'
import Scrollable from '@/components/Scrollable.vue'

const ScorecardSettingsModule = namespace('scorecardSettings')

@Component({
    components: {
        InsightsTimePickerMobile,
        WebViewScreen,
        TwoLineHeader,
        Scrollable,
    },
})
export default class EngagementView extends Vue {
    @Prop({ type: String })
    public userId!: string // from the route

    @Prop({ required: false, default: 'ALL' })
    public themeId!: string // from the route

    @Getter
    readonly friendlyTimeRange!: string

    @Getter
    readonly timeRange!: ITimeRange

    @Action
    updateTimeRange

    public get headerTitle() {
        return this.themeId !== 'ALL' ? 'Trending Theme' : 'Team Spotlight'
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/typography';
@import '~@/styles/variables';
@import '~@/styles/button';
@import '~@/styles/rain/variables';

.time-range {
    font-weight: @fontWeight-medium;
    font-size: 12px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: @grey40;
}

.spotlight-details-container {
    background: @neutralBg;

    .insights-no-data {
        padding: @gutterSpacing;
    }
}
</style>
