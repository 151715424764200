import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b91737e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "uk-tooltip-inner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "tooltip uk-icon-info-circle",
    style: _normalizeStyle(_ctx.iconStyle)
  }, [
    _createElementVNode("div", {
      class: "uk-tooltip uk-tooltip-bottom",
      style: _normalizeStyle(_ctx.style)
    }, [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ], 4)
  ], 4))
}