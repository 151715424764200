<template>
    <div class="filter-bar-loader">
        <Dropdown ref="dropdown" left :modal="tsMobileView">
            <template #trigger>
                <BaseButton secondary muted icon="chevron-down">
                    My Filters
                </BaseButton>
            </template>
            <template #content>
                <div class="options" :class="{ 'ts-modal-c': tsMobileView }">
                    <FilterModalMobileHeader
                        v-if="tsMobileView"
                        title="Saved Filters"
                        @close="closeModal"
                    />
                    <div :class="hasFilters ? 'header' : 'no-filters-header'">
                        <p v-if="!hasFilters" class="no-filters-text">
                            You have no saved filters
                        </p>
                        <BaseButton primary full :click="initSaveFilter">save current filter</BaseButton>
                    </div>
                    <div
                        v-for="(filters, key) in filterSets"
                        v-show="filters.length > 0"
                        :key="key"
                        class="option-set"
                    >
                        <h5>{{ key }} filters</h5>
                        <div
                            v-for="filter in filters"
                            v-show="!filter.is_default"
                            :key="filter.id"
                            class="option"
                        >
                            <span
                                class="filter-name"
                                @click="() => loadFilter(filter)"
                            >
                                {{ getName(filter) }}
                            </span>

                            <span
                                v-if="!filter.locked"
                                class="filter-edit"
                                @click="() => initEditFilter(filter.id)"
                            >
                                <b>edit</b>
                                <span
                                    v-if="getDigestForFilter(filter.id)"
                                    class="uk-icon-envelope-o"
                                ></span>
                            </span>
                        </div>
                    </div>
                </div>
            </template>
        </Dropdown>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { Action, Getter, State } from 'vuex-facing-decorator'
import Modal from './Modal.vue'
import BaseButton from './BaseButton.vue'
import Dropdown from './Dropdown.vue'
import { IFilter, IDigest } from '@/entities'
import FilterModalMobileHeader from '@/components/FilterModalMobileHeader.vue'

@Component({
    components: { FilterModalMobileHeader, Modal, BaseButton, Dropdown },
})
export default class FilterBarLoader extends Vue {
    @Action public getDigests
    @Action public patchFilter
    @Action public setFilterEditing
    @Action public setDigestEditing

    @Getter public filterActive
    @Getter public filterSets
    @Getter public digestSets?: IDigest[]
    @Getter public tsMobileView

    public get hasFilters() {
        const filterSets = Object.values(this.filterSets) as IFilter[][]

        for (let i = 0; i < filterSets.length; i++) {
            if (filterSets[i].length > 0) {
                return true
            }
        }

        return false
    }

    public getName(filter: IFilter) {
        // If there's no name, try construct one ad-hoc
        if (filter.name) {
            return filter.name
        }
        const time = `${filter.time_value} ${filter.time_unit}`
        const rules = filter.filter_rules
        const ruleString = rules
            ? rules
                  .map(({ column, value }) => `${column}: ${value.join('|')}`)
                  .join(', ')
            : ''
        return `${time} ${ruleString}`
    }

    public getDigestForFilter(filterId: number) {
        const { digestSets } = this
        if (digestSets) {
            return digestSets.find((digest) => digest.filter_id === filterId)
        }
    }

    // 'loading' a filter means patching the default filter with the values of the source filter
    public loadFilter(filter: IFilter) {
        if (!this.filterActive) {
            return
        }

        const { id, name, is_default } = this.filterActive

        this.patchFilter({ ...filter, name, id, is_default })

        if (filter.locked === true && this.tsMobileView) {
            setTimeout(
                () => ((this.$refs.dropdown as any).$data.opened = false),
                0
            )
        }
    }

    // sets the filter and its digest to editing mode
    public initEditFilter(filterId: number) {
        this.setFilterEditing({ id: filterId, mode: 'edit' })

        const digest = this.getDigestForFilter(filterId)

        if (digest) {
            this.setDigestEditing(digest.id)
        }
    }

    public initSaveFilter() {
        const active = this.filterActive
        if (active) {
            this.setFilterEditing({ id: active.id, mode: 'create' })
        }
    }
}
</script>

<style scoped lang="less">
@import '../styles/palette.less';
@import '../styles/layout.less';

.filter-bar-loader {
    display: inline-block;
    height: 42px;
    padding: 0;
}

.header {
    padding: 15px 15px 0px;
}

.no-filters-header {
    padding: 15px;
}

.no-filters-text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: @greyBlue;
    text-transform: none;
}

.options:extend(.box) {
    width: 250px;
    text-align: left;
    margin-bottom: 0px;
}

.option-set {
    width: 100%;
    padding: 15px 0px;

    h5 {
        padding: 5px 15px;
        margin: 0px;
        font-size: 16px;
        color: @greyMid2;
    }
}
.option {
    padding: 5px 15px;
    display: flex;
    cursor: pointer;
    width: 250px;
    box-sizing: border-box;

    .filter-name {
        font-size: 16px;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
.option:hover {
    background: @backgroundColor;
    .filter-name {
        color: @ctaColor;
    }
    .filter-edit {
        b {
            color: @ctaColor;
            display: inline-block;
            padding: 0px;
        }
        .uk-icon-envelope-o {
            /* replace with svg icon when available from design */
            display: none;
        }
    }
}
.filter-name {
    display: inline-block;
    flex: 1;
}
.filter-edit {
    height: 20px;
    b {
        display: none;
        font-size: 12px;
        padding-top: 2px;
    }
}
.filter-bar-loader .trigger > .button {
    height: 42px;
    padding: 6px 17px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1.03px;
    display: flex;
    align-items: center;
}
.filter-bar-loader .button.full {
    display: block;
    padding: 6px 17px;
    text-transform: capitalize;
}

@media (max-width: 1280px) {
    .filter-bar-loader .trigger > .button {
        padding: 0 6px;
        height: 38px;
    }

    .filter-bar-loader {
        height: 38px;
    }

    .option {
        width: auto;
    }
}
</style>
