import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/img/Icons/Grey/close_grey_bold.svg'


const _withScopeId = n => (_pushScopeId("data-v-c51ff69e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "achievement-splash-container",
  class: "achievement-splash-container"
}
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "close" }
const _hoisted_4 = { class: "card-content" }
const _hoisted_5 = {
  key: 0,
  class: "title-congrats"
}
const _hoisted_6 = { class: "badge" }
const _hoisted_7 = ["src", "alt"]
const _hoisted_8 = {
  key: 1,
  class: "title-custom"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Typography = _resolveComponent("Typography")!
  const _component_PortalContainer = _resolveComponent("PortalContainer")!

  return (_openBlock(), _createBlock(_component_PortalContainer, {
    onBack: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close', _ctx.type)))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              src: _imports_0,
              class: "close-button",
              alt: "close",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close', _ctx.type)))
            })
          ]),
          _createElementVNode("div", _hoisted_4, [
            (!_ctx.title)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_Typography, { variant: "h3" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Congratulations!")
                    ], undefined, true),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("img", {
                src: _ctx.imageUrl,
                width: "120",
                alt: _ctx.type,
                class: "badge-image"
              }, null, 8, _hoisted_7)
            ]),
            (_ctx.title)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createVNode(_component_Typography, { variant: "h3" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.title), 1)
                    ], undefined, true),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_Typography, {
              variant: "body1",
              class: "body"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.textBody), 1)
              ], undefined, true),
              _: 1
            })
          ])
        ])
      ])
    ], undefined, true),
    _: 1
  }))
}