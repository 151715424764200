import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4da496e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "ranks-container"
}
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "results" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScorecardRankRow = _resolveComponent("ScorecardRankRow")!
  const _component_Loading = _resolveComponent("Loading")!
  const _component_ScorecardSubpage = _resolveComponent("ScorecardSubpage")!

  return (_ctx.isRankDisabled)
    ? (_openBlock(), _createElementBlock("p", _hoisted_1, "ranking has been disabled"))
    : (_openBlock(), _createBlock(_component_ScorecardSubpage, {
        key: 1,
        slide: "left",
        sticky: "",
        "show-header": !_ctx.embedded,
        title: _ctx.title
      }, {
        default: _withCtx(() => [
          (_ctx.scorecard)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.rankListTitle), 1),
                _createElementVNode("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visibleRankRows, (rank, index) => {
                    return (_openBlock(), _createBlock(_component_ScorecardRankRow, {
                      key: index,
                      rank: rank,
                      "rank-num": index + 1,
                      active: _ctx.scorecard.names.indexOf(rank.name) >= 0,
                      "min-value": _ctx.minValue,
                      "max-value": _ctx.maxValue,
                      "company-nps": Number(_ctx.companyNps),
                      "is-five-score-question-type": 
                        _ctx.scorecard.isFiveScoreQuestionType
                    ,
                      "is-last": index + 1 === _ctx.scorecard.ranks.length,
                      avatar: _ctx.getAvatar(rank)
                    }, null, 8, ["rank", "rank-num", "active", "min-value", "max-value", "company-nps", "is-five-score-question-type", "is-last", "avatar"]))
                  }), 128))
                ])
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_Loading, { loading: _ctx.loading }, null, 8, ["loading"])
        ], undefined, true),
        _: 1
      }, 8, ["show-header", "title"]))
}