<template>
    <InsightsLanding v-if="$companyVars.enable_insights_v1" />
    <GrowthMetrics v-else-if="$companyVars.enable_insights_v1 !== undefined" />
</template>

<script lang="ts">
import GrowthMetrics from '@/mobile/src/views/insights/GrowthMetrics.vue'
import InsightsLanding from '@/mobile/src/views/insights/InsightsLanding.vue'
import { Component, Vue } from 'vue-facing-decorator'

@Component({
    components: {
        GrowthMetrics,
        InsightsLanding,
    },
})
export default class InsightsHome extends Vue {}
</script>
