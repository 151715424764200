import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61f8f648"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-box" }
const _hoisted_2 = ["type", "value", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      class: _normalizeClass(['search-input', { rounded: _ctx.rounded }]),
      type: _ctx.type,
      value: _ctx.value,
      placeholder: _ctx.placeholder,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('input', $event.target.value)))
    }, null, 42, _hoisted_2)
  ]))
}