import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cd323e34"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  width: "8",
  height: "14",
  viewBox: "0 0 8 14",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_3 = ["fill"]
const _hoisted_4 = {
  key: 1,
  class: "back"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 4,
  class: "done"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Lottie = _resolveComponent("Lottie")!

  return (_openBlock(), _createBlock(_Transition, {
    name: _ctx.$isMobile ? _ctx.slideName : 'no-transit'
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(['scorecard-subpage', { 'for-web': !_ctx.$isMobile }])
      }, [
        _createElementVNode("div", _hoisted_1, [
          (_ctx.showHeader)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["header", {
                        bgWhite: _ctx.title,
                        hasBgImg: !!_ctx.animationData,
                        borderBottom: _ctx.title,
                        sticky: _ctx.sticky,
                    }])
              }, [
                (_ctx.showBack)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "back",
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goBack && _ctx.goBack(...args)))
                    }, [
                      (_openBlock(), _createElementBlock("svg", _hoisted_2, [
                        _createElementVNode("path", {
                          d: "M2.39966 6.99636L7.66805 1.70469C8.05769 1.31302 8.05769 0.67969 7.66805 0.29219C7.27842 -0.0994771 6.64836 -0.0953104 6.25873 0.29219L0.289815 6.28802C-0.0873869 6.66719 -0.0956771 7.27552 0.2608 7.66719L6.25458 13.7047C6.4494 13.9005 6.7064 13.9964 6.95925 13.9964C7.21209 13.9964 7.46909 13.9005 7.66391 13.7047C8.05355 13.313 8.05355 12.6797 7.66391 12.2922L2.39966 6.99636Z",
                          fill: _ctx.isBlackArrow ? `black` : `white`
                        }, null, 8, _hoisted_3)
                      ]))
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_4)),
                (_ctx.title)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 2,
                      class: "title",
                      innerHTML: _ctx.title
                    }, null, 8, _hoisted_5))
                  : _createCommentVNode("", true),
                (_ctx.buttonRight)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 3,
                      class: "done",
                      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.goBack && _ctx.goBack(...args)))
                    }, _toDisplayString(_ctx.buttonRight), 1))
                  : (_openBlock(), _createElementBlock("div", _hoisted_6))
              ], 2))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: "body",
            style: _normalizeStyle(`background: ${_ctx.bgColor}`)
          }, [
            (_ctx.animationData)
              ? (_openBlock(), _createBlock(_component_Lottie, {
                  key: 0,
                  class: "bg-img-container",
                  "animation-data": _ctx.animationData
                }, null, 8, ["animation-data"]))
              : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ], 4)
        ])
      ], 2)
    ], undefined, true),
    _: 3
  }, 8, ["name"]))
}