<template>
    <BaseCard class="topic-card">
        <div class="topic-card-header">
            <div>
                <div class="topic-card-header-top">
                    {{ score }}
                    <UpDownArrow
                        v-if="scoreChange !== 0"
                        :positive="scoreChange > 0"
                        class="change"
                    />
                </div>
                <div class="topic-card-header-bottom">{{ questionType }}</div>
            </div>
            <div>
                <div class="topic-card-header-top">
                    {{ topicResponseCount }}
                </div>
                <div class="topic-card-header-bottom">Responses</div>
            </div>
        </div>
        <hr />
        <div>
            <div>
                <div
                    class="topic-card-breakdown-header"
                    @click="showBreakdown = !showBreakdown"
                >
                    <div>Responses Breakdown</div>
                    <div>
                        <img
                            v-if="showBreakdown"
                            src="@/pages/conversation/assets/images/chevron-up.svg"
                        />
                        <img
                            v-else
                            src="@/pages/conversation/assets/images/chevron-down.svg"
                        />
                    </div>
                </div>
                <transition name="fade">
                    <div v-if="showBreakdown">
                        <div class="topic-card-all-responses">
                            {{
                                (
                                    (topicResponseCount / totalResponseCount) *
                                    100
                                ).toFixed(0)
                            }}% of all responses
                        </div>
                        <div
                            :style="getBreakdownLineBg"
                            class="topic-card-breakdown-line"
                        ></div>
                        <div class="topic-card-breakdown">
                            <div>
                                <div>
                                    <div class="topic-card-circle positive">
                                        ⬤
                                    </div>
                                    {{ positiveLabel }}
                                </div>
                                <div
                                    class="topic-card-breakdown-count-container"
                                >
                                    <div class="topic-card-breakdown-count">
                                        {{ positiveCount }}
                                    </div>
                                    <div>{{ positivePercent }}%</div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div class="topic-card-circle neutral">
                                        ⬤
                                    </div>
                                    {{ neutralLabel }}
                                </div>
                                <div
                                    class="topic-card-breakdown-count-container"
                                >
                                    <div class="topic-card-breakdown-count">
                                        {{ neutralCount }}
                                    </div>
                                    <div>{{ neutralPercent }}%</div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div class="topic-card-circle negative">
                                        ⬤
                                    </div>
                                    {{ negativeLabel }}
                                </div>
                                <div
                                    class="topic-card-breakdown-count-container"
                                >
                                    <div class="topic-card-breakdown-count">
                                        {{ negativeCount }}
                                    </div>
                                    <div>{{ negativePercent }}%</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
    </BaseCard>
</template>

<script lang="ts">
import { CoachingInsightItem } from '@/entities/insights'
import { Component, Prop, Vue } from 'vue-facing-decorator'
import InsightsMetric from '@/mobile/src/components/Insights/InsightsMetric.vue'
import { QuestionType } from '@/entities'
import BaseCard from '@/components/BaseCard.vue'
import UpDownArrow from '@/components/UpDownArrow.vue'
import { palette } from '@/utils/palette'

@Component({
    components: {
        UpDownArrow,
        BaseCard,
        InsightsMetric,
    },
})
export default class TopicCard extends Vue {
    showBreakdown = false

    @Prop({ type: String, default: null })
    private questionType!: QuestionType

    @Prop({ type: Object, default: null })
    private data!: CoachingInsightItem

    @Prop({ type: Number, default: null })
    private totalTopicResponseCount!: number

    get score() {
        return this.data.score_current.toFixed(1)
    }

    get scoreChange() {
        return this.data.score_change
    }

    get topicResponseCount() {
        return this.data.total_count
    }

    get totalResponseCount() {
        return this.totalTopicResponseCount
    }

    get positiveCount() {
        if (this.questionType === 'nps') {
            return this.data.promoter ?? 0
        }
        return this.data.satisfied ?? 0
    }

    get neutralCount() {
        if (this.questionType === 'nps') {
            return this.data.passive ?? 0
        }
        return this.data.neutral ?? 0
    }

    get negativeCount() {
        if (this.questionType === 'nps') {
            return this.data.detractor ?? 0
        }
        return this.data.unsatisfied ?? 0
    }

    get positivePercent() {
        return Math.round((this.positiveCount / this.topicResponseCount) * 100)
    }

    get neutralPercent() {
        return Math.round((this.neutralCount / this.topicResponseCount) * 100)
    }

    get negativePercent() {
        return Math.round((this.negativeCount / this.topicResponseCount) * 100)
    }

    get positiveLabel() {
        if (this.questionType === 'nps') {
            return 'Promoters'
        }
        return 'Satisfied'
    }

    get neutralLabel() {
        if (this.questionType === 'nps') {
            return 'Passives'
        }
        return 'Neutral'
    }

    get negativeLabel() {
        if (this.questionType === 'nps') {
            return 'Detractors'
        }
        return 'Unsatisfied'
    }

    get getBreakdownLineBg() {
        return (
            'background: linear-gradient(90deg, ' +
            palette.positive +
            ' 0%, ' +
            palette.positive +
            ' ' +
            this.positivePercent.toString() +
            '%, ' +
            palette.neutral +
            ' ' +
            this.positivePercent +
            '%, ' +
            palette.neutral +
            ' ' +
            (this.neutralPercent + this.positivePercent) +
            '%, ' +
            palette.negative +
            ' ' +
            (this.neutralPercent + this.positivePercent) +
            '%, ' +
            palette.negative +
            ' 100%);'
        )
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/variables';
@import '~@/styles/typography';
@import '~@/styles/fade';

.topic-card {
    background: @white;
    padding: 12px;

    hr {
        border: none;
        border-top: 1px solid @blueLight;
    }

    .topic-card-header {
        display: flex;
        justify-content: space-around;
        text-align: center;

        .topic-card-header-top {
            .heading();
            font-size: 30px;
        }

        .topic-card-header-bottom {
            .title();
        }
    }

    .topic-card-breakdown-header {
        display: flex;
        justify-content: space-between;
        .title();
    }

    .topic-card-breakdown {
        display: flex;
        justify-content: space-between;
        .title();
        letter-spacing: 1px;

        .topic-card-breakdown-count-container {
            margin-left: 12px;
            .topic-card-breakdown-count {
                font-size: 16px;
                color: @grey90;
            }
        }

        .topic-card-circle {
            font-size: 8px;
            float: left;
            line-height: 24px;
            padding-right: 2px;
        }
    }

    .topic-card-all-responses {
        font-size: 14px;
        text-transform: uppercase;
        color: @grey90;
        font-weight: 500;
        margin-top: 22px;
        letter-spacing: 1px;
        margin-bottom: 12px;
    }

    .topic-card-breakdown-line {
        height: 10px;
        border-radius: 4px;
        margin-bottom: 14px;
    }

    .positive {
        color: @positive;
    }

    .neutral {
        color: @neutral;
    }

    .negative {
        color: @negative;
    }
}
</style>
