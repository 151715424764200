import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8c3c170c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "report-card-popup" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReportCard = _resolveComponent("ReportCard")!

  return (_ctx.showReportCard)
    ? (_openBlock(), _createBlock(_Transition, {
        key: 0,
        name: "slide-fade",
        appear: ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ReportCard, {
              "report-card-user": _ctx.reportCardUser,
              month: Number(_ctx.reportMonth),
              "close-popup": _ctx.closePopup,
              onMonthChanged: _ctx.monthChanged
            }, null, 8, ["report-card-user", "month", "close-popup", "onMonthChanged"])
          ])
        ], undefined, true),
        _: 1
      }))
    : _createCommentVNode("", true)
}