<template>
    <div class="badge-heading">
        <Lottie
            v-if="animationData"
            class-name="bg-img-container"
            :animation-data="animationData"
        />
        <div v-if="scorecard" class="badge-detail-container">
            <div class="header">
                <div class="title">{{ badgeLabel }}</div>
                <div class="stats">
                    <div class="spacer">&nbsp;</div>
                    <div class="month">
                        <div class="number">{{ pastMonthCount }}</div>
                        <div class="label">Last 30 Days</div>
                    </div>
                    <div class="total">
                        <div class="number">{{ totalCount }}</div>
                        <div class="label">All Time Total</div>
                    </div>
                    <div class="spacer">&nbsp;</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue3Lottie as Lottie } from 'vue3-lottie'
import { Component, Vue } from 'vue-facing-decorator'
import { Action, Getter } from 'vuex-facing-decorator'
import { badgeSchemas, ScorecardEntity } from '@/entities/scorecard'
import { IResponse } from '@/entities'

@Component({
    components: { Lottie },
})
export default class ScorecardBadgeHeading extends Vue {
    public animationData = null
    @Getter public scorecard!: ScorecardEntity
    @Getter public scorecardResponsesMap!: { [value: string]: IResponse[] }
    @Getter public badgeTotalMap!: { [value: string]: { pastMonth; total } }
    @Getter public scorecardOutdated!: boolean
    @Getter public scorecardLoading!: boolean

    @Action public loadScorecard
    @Action public loadResponsesForBadge
    @Action public loadTotalCountForBadge

    public mounted() {
        this.load()
    }

    public async load() {
        if (this.scorecardOutdated) {
            await this.loadScorecard()
        }

        if (!this.badgeTotalMap || !this.badgeTotalMap[this.mapKey]) {
            this.loadTotalCountForBadge({ type: 'positive', value: this.value })
        }

        if (
            !this.scorecardResponsesMap ||
            !this.scorecardResponsesMap[this.mapKey]
        ) {
            await this.loadResponsesForBadge({
                type: 'positive',
                value: this.value,
            })
        }
        if (this.badgeId) {
            this.animationData = (
                await import(
                    `@/assets/img/scorecard/animated/${this.badgeId}.json`
                )
            ).default
        }
    }

    public get value() {
        return this.scorecard.selectedBadge?.value
    }

    public get badgeId() {
        return this.scorecard.selectedBadge?.badgeId
    }

    public get mapKey() {
        return `${this.value}_positive`
    }

    public get loading() {
        return this.scorecardLoading
    }

    public get badgeLabel() {
        return (
            this.scorecard.badges.positive[this.value ?? 0].label ||
            (this.badgeId && badgeSchemas[this.badgeId].label) ||
            ''
        )
    }

    public get responses() {
        return (
            (this.scorecardResponsesMap &&
                this.scorecardResponsesMap[this.mapKey]) ||
            []
        )
    }

    public get totalCount() {
        return (
            (this.badgeTotalMap &&
                this.badgeTotalMap[this.mapKey] &&
                this.badgeTotalMap[this.mapKey].total) ||
            0
        )
    }

    public get pastMonthCount() {
        return (
            (this.badgeTotalMap &&
                this.badgeTotalMap[this.mapKey] &&
                this.badgeTotalMap[this.mapKey].pastMonth) ||
            0
        )
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/layout';
@import '~@/styles/palette';

.badge-detail-container {
    background: white;

    .header {
        text-align: center;
        padding: 20px 0;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        background: white;
        border-bottom: 1px solid #e0e7ff;

        .title {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #2f384c;
            margin-top: 0px !important;
        }

        .stats {
            margin-top: 20px;
            display: flex;

            .number {
                font-weight: 500;
                font-size: 30px;
                line-height: 35px;
                text-align: center;

                color: #1d2330;
            }

            .label {
                font-size: 10px;
                line-height: 12px;
                text-align: center;

                color: #8798ad;
            }

            .month,
            .total,
            .spacer {
                flex: 1;
            }
        }
    }
}

.badge-heading {
    .bg-img-container {
        border-bottom: 1px solid #e0e7ff;
        position: relative;
    }
}
</style>
