import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-170a29be"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-input" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["placeholder", "value"]
const _hoisted_5 = { class: "text-submit" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtTa = _resolveComponent("AtTa")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      class: "text-container",
      onSubmit: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)))
    }, [
      _createVNode(_component_AtTa, {
        members: _ctx.members,
        "filter-match": _ctx.memberSort,
        class: "atwho-wrap",
        "name-key": "name",
        onInsert: _ctx.onInsertMention
      }, {
        item: _withCtx((scope) => [
          _createElementVNode("span", {
            class: "name",
            textContent: _toDisplayString(scope.item.name)
          }, null, 8, _hoisted_2),
          _createElementVNode("span", {
            class: "description",
            textContent: _toDisplayString(scope.item.email)
          }, null, 8, _hoisted_3)
        ]),
        default: _withCtx(() => [
          _createElementVNode("textarea", {
            type: "text",
            class: "text-field",
            rows: "1",
            placeholder: _ctx.placeholder,
            value: _ctx.value,
            onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setValue && _ctx.setValue(...args))),
            onKeydown: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onInputKeydown && _ctx.onInputKeydown(...args)))
          }, null, 40, _hoisted_4)
        ], undefined, true),
        _: 1
      }, 8, ["members", "filter-match", "onInsert"]),
      _createElementVNode("button", _hoisted_5, _toDisplayString(_ctx.submitLabel), 1)
    ], 32)
  ]))
}