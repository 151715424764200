<template>
    <div>
        <LoadingCard v-if="loadingCaseAssignment" variant="single" borderless />

        <div v-else class="feedback-case-assignment-container">
            <div class="account-row">
                <InputSingleLine
                    class="tenant-search"
                    autocomplete="off"
                    autocapitalize="off"
                    variant="glass"
                    type="search"
                    :value="filterText"
                    :label="'Enter the Team Member Name'"
                    :spellcheck="false"
                    @input="handleInputChanges"
                />
            </div>
            <div class="feedback-case-assignment-content">
                <ActionButton
                    v-for="userDetails in filteredChatCaseAssignment"
                    :key="userDetails.id"
                    :on-click="
                        () => setAssignTo(userDetails.id, userDetails.name)
                    "
                    :label="userDetails.name"
                />
            </div>

            <div
                v-if="filterText && filteredChatCaseAssignment.length == 0"
                class="box-no-matches"
            >
                <span>
                    <FontAwesomeIcon
                        class="icon-no-match"
                        :icon="faTriangleExclamation"
                    />
                    No matches were found</span>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import useEmitter from '@/composables/useEmitter'
import { getTeamMembers, updateCaseAssignment } from '@/api/chat'
import { showToast, hideToast } from '@/mobile/src/utils/toastbus'
import { ToastColor, ToastDuration, ToastIcon } from '@/mobile/src/types/toasts'
import { sleep } from '@/utils/async'
import ActionButton from '@/mobile/src/components/appV4/ActionButton.vue'
import LoadingCard from '@/mobile/src/components/appV4/LoadingCard.vue'
import InputSingleLine from '@/components/Rain/Inputs/InputSingleLine.vue'
import { faTriangleExclamation } from 'fontawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'

interface UserDetails {
    id: string | number
    name: string
}

const route = useRoute()
const router = useRouter()
const emitter = useEmitter()
const store = useStore()

const chatCustomData = computed(() => store.getters['chatCustomData'])
const chatCaseAssignment = ref<UserDetails[]>([])
const loadingCaseAssignment = ref(false)

const filterText = ref('')

const filteredChatCaseAssignment = computed(() => {
    if (!filterText.value) {
        return chatCaseAssignment.value
    }
    return chatCaseAssignment.value.filter((user) =>
        user.name.toLowerCase().includes(filterText.value.toLowerCase())
    )
})

const handleInputChanges = (value: string) => {
    filterText.value = value.toLowerCase()
}

const loadTeamMembers = async () => {
    loadingCaseAssignment.value = true
    try {
        const result = await getTeamMembers(
            Number(route.params.feedbackid ?? '')
        )
        chatCaseAssignment.value = [
            ...(chatCustomData.value.case_assigned_user_id !== null
                ? [{ id: 'unassigned', name: 'Unassigned' }]
                : []),
            ...result.data.users,
        ]
    } catch (error) {
        console.error('Failed to load team members:', error)
        showToast(
            emitter,
            ToastIcon.Alert,
            ToastColor.Warning,
            'Failed to load team members',
            ToastDuration.Standard
        )
    } finally {
        loadingCaseAssignment.value = false
    }
}

const setAssignTo = async (userId: string | number, userName: string) => {
    let responseSuccess = true
    const loadingToast = showToast(
        emitter,
        ToastIcon.Waiting,
        ToastColor.EmailReply,
        userId === 'unassigned' ? 'Unassigning case' : 'Assigning case'
    )

    try {
        await updateCaseAssignment(route.params.feedbackid ?? '', userId)
    } catch (error) {
        responseSuccess = false
        console.error('Failed to assign case:', error)
    } finally {
        hideToast(emitter, loadingToast)
    }

    if (responseSuccess) {
        showToast(
            emitter,
            ToastIcon.Success,
            ToastColor.Workflow,
            userId === 'unassigned' ? 'Case Unassigned' : 'Case Assigned',
            ToastDuration.Standard
        )

        await store.dispatch('reloadChatCustomData')
        await sleep(ToastDuration.Standard)
        router.back()
    } else {
        showToast(
            emitter,
            ToastIcon.Alert,
            ToastColor.Warning,
            'Failed to assign case',
            ToastDuration.Standard
        )
    }
}

onMounted(() => {
    loadTeamMembers()
})
</script>

<style lang="less" scoped>
@import '~@/styles/palette.less';
@import '~@/styles/rain/variables.less';
@import '~@/mobile/src/styles/option-lines.less';

.feedback-case-assignment-container {
    .account-row {
        background-color: rgba(255, 255, 255, 0.1);
        align-content: center;
        justify-content: center;
        align-items: center;
        margin-top: @scrollbarWidth;
        border-radius: @borderRadius-base;
        padding: 0 @gutterSpacing-base;
    }

    .back {
        color: white;
    }

    .feedback-case-assignment-content {
        width: 100vw;
        margin-left: 50%;
        transform: translateX(-50%);
    }

    .tenant-search-content {
        background-color: @thinFog;
        padding: 0 @featureGutter 0 0;
        width: calc(100vw - @featureGutter);
    }

    .tenant-search {
        flex: auto;
    }
}

.box-no-matches {
    text-align: center;
    padding: @gutterSpacing-2xl;

    span {
        border-radius: @borderRadius-base;
        padding: @gutterSpacing-md @gutterSpacing-lg;
        background-color: rgba(255, 255, 255, 0.1);
        color: #ffffff;

        .icon-no-match {
            margin-right: @gutterSpacing-base;
        }
    }
}
</style>
