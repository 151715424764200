export interface GlobalContactRules {
    hasSoftenContactRules: boolean
    days: string
    daysFirstEmail: string
    daysFirstEmailSpread: string
}

export const initGlobalContactRules = {
    hasSoftenContactRules: false,
    days: '0',
    daysFirstEmail: '0',
    daysFirstEmailSpread: '0',
}

export interface LocalContactRules {
    custom: boolean
    selectedDay: string
}

export const initLocalContactRulesFollowUp = {
    custom: true,
    selectedDay: '0',
}

export const initLocalContactRulesTouchBase = {
    custom: false,
    selectedDay: '0',
}

export const localContactRulesInit = {
    touchbase: initLocalContactRulesTouchBase,
    followup: initLocalContactRulesFollowUp,
}
