export const isProduction = process.env.NODE_ENV === 'production'
export const isLocal = process.env.APP_ENV === 'local'
export const rootDomain =
    isProduction && !isLocal ? 'asknice.ly' : 'asknicely.site'

export function truncateString(value: string, length: number): string {
    const truncatedLength = length ?? 20
    if (value.length > truncatedLength) {
        return value.substring(0, truncatedLength) + '...'
    }

    return value
}

export function scrollActiveFiltersIntoView() {
    // Chromium doesn't like having multiple scrollIntoView at the same time: https://stackoverflow.com/q/49318497
    // So we have to hax it up with scrollTo().
    const containers = document.querySelectorAll('.filter-set')
    containers.forEach((container) => {
        const selected = container.querySelector('.selected') as HTMLElement
        if (selected) {
            const selectedCenterPosition =
                selected.offsetLeft -
                (container.getBoundingClientRect().width -
                    selected.getBoundingClientRect().width) +
                20
            container.scrollTo({
                left: selectedCenterPosition,
                behavior: 'smooth',
            })
        }
    })
}
