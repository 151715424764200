<template>
    <div v-if="show" class="offline-container">
        <div class="offline-banner-box">
            <FeatureContainer class="offline-banner">
                <div>You are currently offline.</div>
                <div>Some features are unavailable.</div>
            </FeatureContainer>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import FeatureContainer from '@/mobile/src/components/appV4/FeatureContainer.vue'
import { Getter } from 'vuex-facing-decorator'

@Component({
    components: {
        FeatureContainer,
    },
})
export default class OfflineBanner extends Vue {
    @Prop({ type: String, default: '' }) public selected!: string
    @Getter public hasNetworkConnection!: boolean
    public supportedPages = ['overview', 'myfeedback', 'more']
    get show() {
        return (
            this.supportedPages.includes(this.selected) &&
            !this.hasNetworkConnection
        )
    }
}
</script>

<style lang="less" scoped>
.offline-container {
    padding: env(safe-area-inset-top) env(safe-area-inset-right) 0
        env(safe-area-inset-left);
    text-transform: uppercase;
    text-align: center;
    color: white;
    font-size: 12px;
    line-height: 18px;
}
.offline-banner {
    padding: 8px;
}
.offline-banner-box {
    padding-top: 22px;
}
</style>
