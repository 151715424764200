<template>
    <div class="positive-feedback">
        <template v-for="(badge, key) in badges">
            <RouterLink
                v-if="badge.count > 0"
                :key="'link' + key"
                :to="{
                    name: routeName,
                    params: { value: key, teamMemberUserId },
                }"
                class="badge-link"
            >
                <ScorecardBadge
                    :label="badge.label"
                    :show-count="false"
                    :badge-id="badge.badgeId"
                    :count-unseen="badge.countUnseen.toString()"
                    :count="badgeCount(badge)"
                    no-space
                />
            </RouterLink>

            <ScorecardBadge
                v-else
                :key="key"
                :label="badge.label"
                :show-count="false"
                :badge-id="badge.badgeId"
                :count-unseen="badge.countUnseen.toString()"
                :count="badgeCount(badge)"
                no-space
            />
        </template>
    </div>
</template>

<script lang="ts">
import ScorecardBadge from '@/components/Scorecard/ScorecardBadge.vue'
import { ScorecardEntity } from '@/entities/scorecard'
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { Getter } from 'vuex-facing-decorator'

@Component({
    components: {
        ScorecardBadge,
    },
})
export default class RoutableBadge extends Vue {
    @Prop({ type: String, required: false })
    readonly teamMemberUserId?: string

    @Prop({ type: Boolean, default: false })
    public readonly showAchievements?: boolean

    @Getter
    readonly scorecard?: ScorecardEntity

    get routeName() {
        if (this.showAchievements) {
            return this.teamMemberUserId
                ? 'achievementBadgeUser'
                : 'achievementBadge'
        }
        return this.teamMemberUserId ? 'scorecardBadgeUser' : 'scorecardBadge'
    }

    get badges() {
        return this.showAchievements
            ? this.scorecard?.achievements
            : this.scorecard?.badges?.positive
    }

    public badgeCount(badge) {
        return this.showAchievements
            ? badge.count.toString()
            : badge.percentage + '%'
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/variables';

.positive-feedback {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: @gutterSpacing * 2;
}

.badge-link {
    text-decoration: none;
}
</style>
