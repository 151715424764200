import { ActionTree, MutationTree, GetterTree } from 'vuex'
import { ReelsList } from '@/entities/reels'
import { fetchReels } from '@/api/reels'

export interface ReelsState {
    reelsList: ReelsList[]
    isLoading: boolean
}
export const state: ReelsState = {
    reelsList: [],
    isLoading: true,
}

const getters: GetterTree<ReelsState, any> = {
    allReels(state) {
        return state.reelsList
    },
    loadingStatus(state) {
        return state.isLoading
    },
}

const actions: ActionTree<ReelsState, any> = {
    setLoadingStatus({ commit }, isLoading: boolean) {
        commit('setLoadingStatus', isLoading)
    },
    async loadReelsList({ commit }) {
        commit('setLoadingStatus', true)
        try {
            const { data } = await fetchReels()
            commit('updateReelsList', data)
        } catch (error) {
            console.error('Failed to load reels:', error)
            commit('updateReelsList', [])
        } finally {
            commit('setLoadingStatus', false)
        }
    },
}

const mutations: MutationTree<ReelsState> = {
    updateReelsList(state, reels) {
        state.reelsList = reels.data
    },
    setLoadingStatus(state, isLoading) {
        state.isLoading = isLoading
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
