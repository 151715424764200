<template>
    <div class="container">
        <div class="content">
            <slot />
        </div>
        <div v-show="!bottomTabsHidden" class="tabs">
            <ViewAsNavBar />
            <TabOption
                v-for="tab in tabs"
                :key="tab.text"
                class="tab-option"
                :tab="tab"
                :selected="selected === tab.name"
                @click="onClickTab"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { useRoute } from 'vue-router'
import TabOption from '@/mobile/src/components/containers/TabbedContainer/TabOption.vue'
import { TabData } from '@/mobile/src/types/tab'
import { Getter } from 'vuex-facing-decorator'
import ViewAsNavBar from '@/mobile/src/components/containers/TabbedContainer/ViewAsNavBar.vue'

@Component({
    components: {
        ViewAsNavBar,
        TabOption,
    },
    emits: ['click'],
})
export default class TabbedContainer extends Vue {
    @Getter readonly bottomTabsHidden

    private route = useRoute()

    // list of tab options
    @Prop({ type: Array, default: [] }) public tabs!: TabData[]
    // the selected tab
    @Prop({ type: String, default: '' }) public selected!: string

    private onClickTab(tab: string) {
        if (tab === this.route.name) {
            return // ignore when clicking on same tab twice
        }
        this.$emit('click', tab)
    }
}
</script>
<style lang="less" scoped>
@import '~@/styles/palette';

.container {
    // flex the layout: up down
    display: flex;
    flex-flow: column;
    height: 100%;
    box-sizing: border-box;

    // content take up rest of space
    .content {
        display: flex;
        flex-flow: column;
        flex: 1 1 auto;
        overflow-y: auto;
        position: relative; // so the absolute positioned child won't go over us
        height: 100%; // in safari so child components know it's taking up 100%
    }

    // tabs in the bottom
    .tabs {
        position: relative;
        align-items: center;
        flex: 0 1;
        display: flex;
        border-top: 1px solid @grey40;
        min-height: 64px;

        .tab-option {
            flex: 1;
        }
    }
}
</style>
