import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LeaderboardChartV2 = _resolveComponent("LeaderboardChartV2")!

  return (_ctx.tsGroupSummary)
    ? (_openBlock(), _createBlock(_component_LeaderboardChartV2, {
        key: 0,
        hidden: !_ctx.tsScatterPlotVisible,
        "is-five-score": _ctx.tsGroupSummary.isFiveScore,
        "chart-data": _ctx.tsChartGroupsDataV2,
        "show-labels": "",
        "show-average": "",
        "mobile-style": "",
        onChartClick: _ctx.handleChartClick,
        onLabelHover: _ctx.setTsGridHoverGroup
      }, null, 8, ["hidden", "is-five-score", "chart-data", "onChartClick", "onLabelHover"]))
    : _createCommentVNode("", true)
}