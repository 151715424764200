<template>
    <div class="target-graph">
        <div class="target-overlay" @click="onGoalClick">
            <div class="target">
                <div
                    v-if="
                        showRewards &&
                        goalsEnabled &&
                        (rewardEnabled || isFrontlineManager)
                    "
                    class="giftbox"
                    :class="{ enabled: rewardEnabled, bounce: !giftboxLoaded }"
                >
                    <img
                        src="@/assets/img/Icons/White/giftbox.svg"
                        alt="Gift!"
                    />
                </div>
                <p class="goal-text">Goal</p>
                <p class="target-text">{{ formatTarget }}</p>
            </div>
        </div>
        <div :class="['graph-container', { tall }]">
            <canvas ref="graph" class="graph" />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from 'vue-facing-decorator'
import { Getter, namespace } from 'vuex-facing-decorator'
import { Chart, registerables } from 'chart.js'

const GoalTrackingModule = namespace('goals')

@Component({
    emits: ['goal-clicked'],
})
export default class NPSTargetGraph extends Vue {
    @Getter public $companyVars

    public chart?: Chart
    public data: Array<any> = []
    public labels: Array<string> = []

    @Prop({ type: Object, default: null }) public scoreData!: any
    @Prop({ type: Number, default: 0 }) public target!: number
    @Prop({ type: String, default: '' }) public nextWeekLabel!: number
    @Prop({ type: String, default: 'nps' }) public scoreType!: String
    @Prop({ type: Boolean, default: false }) public tall!: boolean
    @Prop({ type: Boolean, default: true }) public showRewards?: boolean

    @GoalTrackingModule.Getter rewardEnabled
    @GoalTrackingModule.Getter goalsEnabled

    @GoalTrackingModule.Getter giftboxLoaded

    @GoalTrackingModule.Mutation updateGiftboxLoaded
    @Getter isFrontlineManager

    public mounted() {
        //Add 'ShadowLine' graph type here for now
        //Adapted from https://github.com/chartjs/Chart.js/issues/4977
        // const ShadowLineElement = Chart['elements'].Line.extend({
        //     draw() {
        //         const { ctx } = this._chart
        //
        //         const originalStroke = ctx.stroke
        //
        //         ctx.stroke = function () {
        //             ctx.save()
        //             ctx.shadowColor = 'rgba(0, 0, 0, 0.5)'
        //             ctx.shadowBlur = 20
        //             ctx.shadowOffsetX = 0
        //             ctx.shadowOffsetY = 8
        //             originalStroke.apply(this, arguments)
        //             ctx.restore()
        //         }
        //
        //         Chart['elements'].Line.prototype.draw.apply(this, arguments)
        //
        //         ctx.stroke = originalStroke
        //     },
        // })
        //
        // Chart.defaults['ShadowLine'] = Chart.defaults['line']
        // Chart['controllers'].ShadowLine = Chart['controllers'].line.extend({
        //     datasetElementType: ShadowLineElement,
        // })

        Chart.register(...registerables)

        this.parseData()
        setTimeout(this.updateGiftboxLoaded, 5000)
    }

    @Watch('scoreData', { immediate: true, deep: true })
    public parseData() {
        if (this.scoreData) {
            this.data = this.scoreData.datasets.map(
                (item) => item.score - this.target
            ) //Zero out the data relative to the target; we don't allow negative targets
            this.labels = this.scoreData.datasets.map((item, index) =>
                item.label.toUpperCase()
            )
            this.labels.push(this.scoreData.nextWeekLabel.toUpperCase())
        }

        this.updateGraph()
    }

    public async renderGraph() {
        const canvas = this.$refs.graph as HTMLCanvasElement
        if (!canvas) {
            return
        }

        // pad the graph differently based on the metric type
        let padding = 5 // nps
        switch (this.scoreType) {
            case 'csat':
                padding = this.$companyVars.has_display_csat_as_percentage
                    ? 2.5
                    : 0.125
                break
            case 'fivestar':
                padding = 0.125
                break
        }

        const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
        const brightLineColor = 'rgba(255, 255, 255, 0.9)'
        const dullLineColor = 'rgba(255, 255, 255, 0.5)'
        const maxValue =
            Math.max(...this.data.map((nps) => Math.abs(nps))) + padding
        if (this.chart) await this.chart.destroy()
        this.chart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: this.labels,
                datasets: [
                    {
                        data: this.data,
                        fill: false,
                        backgroundColor: '#FFF',
                        borderColor: '#FFF',
                        borderCapStyle: 'round',
                        borderWidth: 5, // Line thickness
                        pointRadius: 0, // Hide points
                        clip: 20,
                        cubicInterpolationMode: 'monotone',
                    },
                ],
            },
            options: {
                maintainAspectRatio: false,
                scales: {
                    y: {
                        grid: {
                            color: brightLineColor,
                            drawTicks: false,
                        },
                        border: {
                            display: false,
                            dash: [2, 2],
                        },
                        ticks: {
                            autoSkip: false, // Guarantee we draw all ticks
                            stepSize: 1,
                            font: { size: 8, weight: 'normal' },
                            color: '#FFF',
                            padding: -10,
                            callback: function (value) {
                                //We only want a line along the 'zero'/target level
                                return value === 0 ? ' ' : null
                            },
                        },
                        min: -maxValue,
                        max: maxValue,
                    },
                    x: {
                        grid: {
                            color: (ctx) =>
                                ctx.tick.label?.length &&
                                ctx.tick.label.length > 0
                                    ? dullLineColor
                                    : 'transparent',
                            display: true,
                            drawTicks: false,
                        },
                        border: {
                            display: false,
                        },
                        ticks: {
                            font: {
                                size: 12,
                                weight: 'normal',
                                family: "'Roboto'",
                            },
                            color: '#FFF',
                            padding: 8,
                            callback: function (value) {
                                return this.getLabelForValue(Number(value))
                            },
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
            },
        })
    }

    public updateGraph() {
        if (this.chart) {
            this.chart.destroy()
        }
        if (this.labels) {
            this.renderGraph()
        }
    }

    public onGoalClick() {
        if (this.rewardEnabled) {
            this.$emit('goal-clicked', true)
        }
    }

    public get formatTarget() {
        if (
            this.scoreType == 'csat' &&
            this.$companyVars.has_display_csat_as_percentage
        ) {
            return this.target.toFixed(0)
        }

        if (this.scoreType !== 'nps') {
            return this.target.toFixed(1)
        }

        return this.target.toFixed(0)
    }
}
</script>

<style scoped lang="less">
@import '~@/styles/palette.less';

.target-graph {
    position: relative;
}

.graph-container {
    position: relative;
    height: 120px;
    pointer-events: none;
    overflow: hidden;

    &.tall {
        height: 150px;
    }
}

.graph {
    position: absolute;
    right: 5px;
    //Clip the top right corner 'beneath' the overlay
    clip-path: polygon(
        0 0,
        calc(100% - 32px) 0,
        calc(100% - 16px) 32px,
        100% 32px,
        100% 100%,
        0 100%
    );
}

.target-overlay {
    position: absolute;
    right: 5px; //Tiniest of adjustments to align with the far right label if visible
    bottom: 50%;
    z-index: 100; // Make sure it overlays the graph
    overflow: visible;

    .giftbox {
        position: absolute;
        top: -75%;
        margin-right: -25%;
        right: 50%;
        opacity: 0.3;
    }
    .enabled {
        opacity: 1;
    }
    .bounce {
        animation: bounce-in 2s ease 1s 1 both;
    }

    .target {
        background-color: white;
        color: @indigo;
        height: 30px;
        width: 30px;
        border-radius: 5px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .goal-text {
            font-family: Roboto, sans-serif;
            font-size: 8px;
            line-height: 8px;
            font-weight: 700;
            margin: 0;
            text-transform: uppercase;
        }

        .target-text {
            font-family: Roboto, sans-serif;
            font-size: 12px;
            line-height: 12px;
            margin: 0;
        }
    }

    @keyframes bounce-in {
        0% {
            transform: translateY(-300px) scale(1);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
            opacity: 0;
        }

        32% {
            transform: translateY(0) scale(1);
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
            opacity: 1;
        }

        46% {
            transform: translateY(calc(-300px / 6));
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
        }

        61% {
            transform: translateY(0);
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
        }

        72% {
            transform: translateY(calc(-300px / 12));
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
        }

        81% {
            transform: translateY(0);
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
        }

        87% {
            transform: translateY(calc(-300px / 40));
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
        }

        93% {
            transform: translateY(0);
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
        }
    }
}
</style>
<style lang="less">
.graph-container {
    position: relative;
}
</style>
