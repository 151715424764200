<template>
    <WebViewScreen class="webview-container" no-pad hide-header>
        <Scrollable :loading="scorecardLoading" :pull-down="load">
            <EngagementBanner />
            <!-- smart notification -->
            <RequestNotificationPermissionPanel />
            <SwitchLayoutContainer>
                <template #compact>
                    <ScorecardPage />
                </template>

                <template #expanded>
                    <ScorecardPageExpanded />
                </template>
            </SwitchLayoutContainer>
        </Scrollable>
    </WebViewScreen>
</template>

<script lang="ts">
import Scrollable from '@/components/Scrollable.vue'
import WebViewScreen from '@/components/WebView/WebViewScreen.vue'
import { IRecipient } from '@/entities/notices'
import { ScorecardEntity } from '@/entities/scorecard'
import SwitchLayoutContainer from '@/mobile/src/components/containers/SwitchLayoutContainer.vue'
import RequestNotificationPermissionPanel from '@/mobile/src/components/RequestNotificationPermissionPanel.vue'
import ScorecardPage from '@/mobile/src/views/scorecard/ScorecardPage.vue'
import ScorecardPageExpanded from '@/mobile/src/views/scorecard/ScorecardPageExpanded.vue'
import { Component, Vue } from 'vue-facing-decorator'
import { Action, Getter, Mutation } from 'vuex-facing-decorator'
import EngagementBanner from '@/mobile/src/components/engagement/EngagementBanner.vue'

@Component({
    components: {
        EngagementBanner,
        RequestNotificationPermissionPanel,
        ScorecardPage,
        ScorecardPageExpanded,
        Scrollable,
        SwitchLayoutContainer,
        WebViewScreen,
    },
})
export default class ScorecardHome extends Vue {
    @Getter
    readonly scorecardLoading!: boolean

    @Getter
    readonly scorecardOutdated!: boolean

    @Getter
    readonly scorecard?: ScorecardEntity

    @Getter
    readonly myTeam!: IRecipient[]

    @Getter
    readonly userId!: string

    @Action
    loadMyTeam

    @Action
    loadScorecard

    @Mutation
    refreshFeature

    get hasScorecard() {
        return this.scorecard && Object.keys(this.scorecard).length > 0
    }

    mounted() {
        if (!this.scorecard) {
            this.load()
            return
        }

        const scorecardUserId = this.scorecard.user?.id.toString()

        if (this.userId !== scorecardUserId) {
            this.load()
            return
        }

        if (!this.scorecardOutdated) {
            return
        }

        this.load()
    }

    async load() {
        if (!this.scorecardLoading) {
            await this.loadScorecard()
            this.refreshFeature('scorecard')
            await this.loadMyTeam()
        }
    }
}
</script>

<style scoped lang="less">
@import '~@/styles/palette';

.webview-container {
    position: relative;
    background-color: @greyLight;
}
</style>
