import { ActionTree, MutationTree, GetterTree } from 'vuex'
import {
    FrontlineNoticeRow,
    IComposer,
    IMessage,
    IRecipient,
    IReportMessage,
    unknownUser,
} from '@/entities/notices'
import {
    deleteMessage,
    loadMessage,
    loadMessagesReceived,
    loadMessagesScheduled,
    loadMessagesSent,
    loadRecipients,
    loadVirtualUsers,
    loadTimezone,
    loadCompanyMessagesSent,
    getCompanyMessageTypes,
} from '@/api/notices'
import { sleep } from '@/utils/async'

export interface NoticesState {
    timezone: string
    canSendNotices: number
    received: IMessage[]
    sent: IMessage[]
    companyWideSent: FrontlineNoticeRow[]
    companyWideSentLength: number
    companyWideSentPageCount: number
    scheduled: IMessage[]
    composer: IComposer
    recipients: IRecipient[]
    virtualUsers: IRecipient[]
    companyMessageTypes: IMessage[]
    errMsg: string // ajax error msg to display
}

// Must match the constants in NoticesService.php.
export enum NoticeType {
    Urgent = 'urgent notice',
    Leader = 'leader notice',
    General = 'general notice',
    Shoutout = 'shoutout',
    Coaching = 'coaching',
    Report = 'report',
}

export const state: NoticesState = {
    timezone: '',
    canSendNotices: 0,
    received: [],
    sent: [],
    companyWideSent: [],
    companyWideSentLength: 20,
    companyWideSentPageCount: 20,
    scheduled: [],
    composer: {
        recipients: [],
        message: '',
        mentions: [],
        scheduled_at: '',
        type: '',
        question: null,
    },
    recipients: [],
    virtualUsers: [],
    companyMessageTypes: [],
    errMsg: '',
}

const getters: GetterTree<NoticesState, any> = {
    canSendNotices({ canSendNotices }) {
        return canSendNotices
    },

    composer({ composer }) {
        return composer
    },

    recipients({ recipients }) {
        //those that are "senders" only shouldn't be shown
        return recipients.filter((recipient) => !recipient.sender)
    },

    senders({ recipients }) {
        return recipients
    },

    virtualUsers({ virtualUsers }) {
        return virtualUsers
    },

    received({ received }) {
        return received
    },

    sent({ sent }) {
        return sent
    },

    companyWideSent({ companyWideSent }) {
        return companyWideSent
    },

    companyWideSentLength({ companyWideSentLength }) {
        return companyWideSentLength
    },

    companyWideSentPageCount({ companyWideSentPageCount }) {
        return companyWideSentPageCount
    },

    companyMessageTypes({ companyMessageTypes }) {
        return companyMessageTypes
    },

    scheduled({ scheduled }) {
        return scheduled
    },

    timezone({ timezone }) {
        return timezone
    },

    errMsg({ errMsg }) {
        return errMsg
    },

    getVirtualUser({ virtualUsers }, { getRecipient }) {
        return (virtualId, fallbackRecipient): IRecipient => {
            if (!virtualId) {
                return getRecipient(fallbackRecipient)
            }

            const found = virtualUsers.find((r) => r.user_id === virtualId)

            return found ? found : unknownUser
        }
    },

    getRecipient({ recipients }) {
        return (recipient): IRecipient => {
            if (!recipient) {
                return unknownUser
            }

            const found = recipients.find(
                (r) =>
                    (r.type === 'user'
                        ? r.user_id === recipient.user_id
                        : true) && // ignore user_id comparison if it's a `group`
                    r.role === recipient.role &&
                    r.type === recipient.type &&
                    r.field === recipient.field &&
                    r.value === recipient.value
            )

            return found ? found : unknownUser
        }
    },
}

const actions: ActionTree<NoticesState, any> = {
    setCanSendNotices({ commit }, canSendNotices: number) {
        commit('setCanSendNotices', canSendNotices)
    },

    setComposerMessage({ commit }, message: string) {
        commit('setComposerMessage', message)
    },

    setComposerRecipients({ commit }, recipients: IRecipient[]) {
        commit('setComposerRecipients', recipients)
    },

    setComposerMentions({ commit }, mentions: IRecipient[]) {
        // make it distinct
        const uniqueObj = {}
        mentions.forEach(
            (mention) =>
                (uniqueObj[
                    `${mention.type}${mention.field}${mention.user_id}`
                ] = mention)
        )
        mentions = Object.values(uniqueObj)
        commit('setComposerMentions', mentions)
    },

    setRecipients({ commit }, recipients: IRecipient[]) {
        commit('setRecipients', {
            recipients,
        })
    },

    setVirtualUsers({ commit }, virtualUsers: IRecipient[]) {
        commit('setVirtualUsers', {
            virtualUsers,
        })
    },

    async loadRecipients({ commit }, populatedRecipientId?: number) {
        const recipients = await loadRecipients()
        commit('setRecipients', recipients.data)
        const virtualUsers = await loadVirtualUsers()
        commit('setVirtualUsers', virtualUsers.data)
        if (populatedRecipientId) {
            const populatedRecipient = recipients.data.find(
                (u) => u.user_id === populatedRecipientId
            )
            if (populatedRecipient) {
                commit('setComposerRecipients', [populatedRecipient])
            }
        }
    },

    resetComposerRecipients({ commit }) {
        commit('setComposerRecipients', [])
    },

    async loadMessagesSent({ commit }) {
        const { data } = await loadMessagesSent()
        commit('setMessagesSent', data)
    },

    async loadMessagesScheduled({ commit }) {
        const { data } = await loadMessagesScheduled()
        commit('setMessagesScheduled', data)
    },

    async loadMessagesReceived({ commit }, noticeTypes: NoticeType[] | null) {
        const { data } = await loadMessagesReceived(noticeTypes)
        commit('setMessagesReceived', data)
    },

    async loadMessage({ commit }, id: number) {
        const { data } = await loadMessage(id)
        commit('setMessage', data)
    },

    async deleteMessage({ commit, state }, id) {
        const { data } = await deleteMessage(id)
        commit('setErrMsg', data.msg)
        if (!data.success) {
            return
        }
        const scheduled = state.scheduled.filter((msg) => msg.id !== id)
        commit('setMessagesScheduled', scheduled)
    },

    async loadTimezone({ commit, state }) {
        const { data } = await loadTimezone()
        commit('setTimezone', data.timezone)
    },

    setErrorMessage({ commit }, errMsg: string) {
        commit('setErrMsg', errMsg)
    },

    setComposerNoticeType({ commit }, type: string) {
        commit('setComposerNoticeType', type)
    },

    setComposerQuestion({ commit }, question) {
        commit('setComposerQuestion', question)
    },

    setComposerScheduledAt({ commit }, scheduledAt: number) {
        commit('setComposerScheduledAt', scheduledAt)
    },

    setComposerPinNotice({ commit }, isPinned: boolean | null) {
        commit('setComposerPinNotice', isPinned)
    },

    resetComposer({ commit }) {
        commit('setComposerScheduledAt', '')
        commit('setComposerNoticeType', '')
        commit('setComposerQuestion', null)
        commit('setComposerRecipients', [])
        commit('setComposerMessage', '')
        commit('setComposerPinNotice', null)
    },

    async loadCompanyMessagesSent({ commit }, params) {
        const page = params.page
        delete params.page
        const { data } = await loadCompanyMessagesSent(params, page)
        commit('setCompanyMessagesSent', data)
    },

    async getCompanyMessageTypes({ commit }) {
        const { data } = await getCompanyMessageTypes()
        commit('setCompanyMessageTypes', data)
    },
}

const mutations: MutationTree<NoticesState> = {
    setCanSendNotices(state, canSendNotices) {
        state.canSendNotices = canSendNotices
    },

    setComposerMessage(state, message) {
        state.composer = { ...state.composer, message }
    },

    setComposerRecipients(state, recipients) {
        state.composer = { ...state.composer, recipients }
    },

    setComposerMentions(state, mentions) {
        state.composer = { ...state.composer, mentions }
    },

    setComposerNoticeType(state, type: string) {
        state.composer = { ...state.composer, type }
    },

    setComposerQuestion(state, question) {
        state.composer = { ...state.composer, question }
    },

    setComposerScheduledAt(state, scheduled_at: string) {
        state.composer = { ...state.composer, scheduled_at }
    },

    setComposerPinNotice(state, isPinned: null | boolean) {
        if (isPinned !== null) {
            state.composer = { ...state.composer, isPinned }
        } else {
            state.composer = { ...state.composer, isPinned: undefined }
        }
    },

    setRecipients(state, recipients: IRecipient[]) {
        state.recipients = recipients
    },

    setVirtualUsers(state, virtualUsers: IRecipient[]) {
        state.virtualUsers = virtualUsers
    },

    setMessagesSent(state, messages: IMessage[]) {
        state.sent = messages
    },

    setMessagesScheduled(state, messages: IMessage[]) {
        state.scheduled = messages
    },

    setMessagesReceived(state, messages: IMessage[]) {
        state.received = messages
    },

    setTimezone(state, timezone: string) {
        state.timezone = timezone
    },

    setCompanyMessageTypes(state, data) {
        if (data?.length) {
            state.companyMessageTypes = data
        }
    },

    async setErrMsg(state, errMsg: string) {
        if (state.errMsg !== errMsg) {
            state.errMsg = errMsg
            await sleep(3000)
            state.errMsg = ''
        }
    },

    setCompanyMessagesSent(
        state,
        messages: {
            data: FrontlineNoticeRow[]
            count: number
            page_limit: number
        }
    ) {
        state.companyWideSent = messages.data
        state.companyWideSentLength = messages.count
        state.companyWideSentPageCount = messages.page_limit
    },
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations,
}
