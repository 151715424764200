<template>
    <div class="quote-container">
        <div :class="['vertical-line', { hide: noGap }]"></div>
        <div ref="comment" :class="['quote-content', { 'no-gap': noGap }]">
            <Typography :variant="variant">
                <TextClamp
                    autoresize
                    :max-lines="maxLines"
                    :text="text"
                    @clampchange="quoteClampChange"
                >
                    <template #after="{ toggle, expanded, clamped }">
                        <span
                            v-if="expanded || clamped"
                            class="clamp-toggle"
                            @click="toggle"
                        >
                            {{ clamped ? 'Read more' : 'Read less' }}
                        </span>
                    </template>
                </TextClamp>
            </Typography>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { VariantType } from '@/types/typography'
import TextClamp from 'vue3-text-clamp'
import Typography from '@/components/Rain/Typography/Typography.vue'

@Component({
    components: {
        Typography,
        TextClamp,
    },
    emits: ['quoteClampChange'],
})
export default class Quote extends Vue {
    @Prop({ type: String, required: true })
    public text!: string
    // Gap between vertical line & comment
    @Prop({ type: Boolean, default: false })
    public noGap?: boolean
    @Prop({ type: Number, default: 5 })
    public maxLines?: number
    @Prop({ type: String, required: false, default: 'caption' })
    public readonly variant!: VariantType

    public quoteClampChange(isClamped, event: Event) {
        this.$emit('quoteClampChange', isClamped)
    }
}
</script>

<style scoped lang="less">
@import '~@/styles/quote.less';
</style>
