import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PortalTarget = _resolveComponent("PortalTarget")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card-container", { active: _ctx.active }])
  }, [
    _createVNode(_component_PortalTarget, {
      name: "over-card-destination",
      onChange: _ctx.handleUpdate
    }, null, 8, ["onChange"])
  ], 2))
}