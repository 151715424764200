<template>
    <span @click="onClickDropdown">
        <span
            ref="modalDisplayTarget"
            :class="{ 'under-dots': hasActiveMetrics }"
        >
            {{ selectedMetric }}
            <FontAwesomeIcon
                v-if="hasActiveMetrics"
                class="chevron"
                :icon="faChevronDown"
            />
        </span>
        <Portal
            v-if="showingSelect"
            to="shadow-overlay-destination"
            :target="$refs.modalDisplayTarget"
        >
            <InfoModal
                class="multi-metric-select"
                show
                vertical-only
                no-pad
                :x-adjust="-5"
                :show-header="false"
                :portrait-target="$refs.modalDisplayTarget"
                :landscape-target="$refs.modalDisplayTarget"
            >
                <div
                    v-for="metric in combinedActiveMetrics"
                    :key="metric"
                    class="metric-option"
                    @click="onClickMetric(metric)"
                >
                    {{ friendlyMetricText(metric) }}
                </div>
            </InfoModal>
        </Portal>
    </span>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faChevronDown } from 'fontawesome/free-solid-svg-icons'
import InfoModal from '@/mobile/src/components/appV4/InfoModal.vue'
import { Action, Getter, namespace } from 'vuex-facing-decorator'
import { Portal } from 'portal-vue'

const AuthModule = namespace('auth')

@Component({
    components: {
        Portal,
        InfoModal,
        FontAwesomeIcon,
    },
})
export default class MultiMetricSelect extends Vue {
    @Prop({ type: Boolean, default: false })
    public readonly disableSelect?: boolean

    readonly faChevronDown = faChevronDown
    public showingSelect = false

    @Getter
    readonly activeMetrics!: string[]

    @Getter
    readonly mobileQuestionType!: string

    @AuthModule.Getter getActiveMetrics!: any

    @Action
    switchMetricType

    get combinedActiveMetrics() {
        return this.activeMetrics || Object.values(this.getActiveMetrics)
    }

    get selectedMetric() {
        return this.friendlyMetricText(this?.mobileQuestionType || 'nps')
    }

    get hasActiveMetrics() {
        return !this.disableSelect && this.combinedActiveMetrics.length > 1
    }

    friendlyMetricText(metric) {
        return metric === 'fivestar' ? 'Five Star' : metric.toUpperCase()
    }

    onClickDropdown() {
        if (this.hasActiveMetrics) {
            this.showingSelect = true
        }
    }

    onClickMetric(metric) {
        this.switchMetricType(metric, false)
        this.showingSelect = false
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/variables.less';
@import '~@/styles/rain/colour.less';

.under-dots {
    text-decoration: underline dotted;
}

.chevron {
    width: 10px;
    height: 10px;
    font-size: 0.75em;
    vertical-align: 0;
}

.multi-metric-select {
    .metric-option {
        border-bottom: solid 1px @thinFog;
        text-align: center;
        padding: @featureGutter / 2;

        &:last-child {
            border-bottom: none;
        }
    }
}
</style>
