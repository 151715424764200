import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b1d0a02"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "refresh-indicator"
}
const _hoisted_2 = { id: "refresh-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_Typography = _resolveComponent("Typography")!

  return (_openBlock(), _createElementBlock("div", {
    id: "refresh-container",
    ref: "ptrContainer",
    style: _normalizeStyle(_ctx.refreshContainerStyles),
    class: _normalizeClass(_ctx.refreshContainerClasses)
  }, [
    (!_ctx.disable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.showRefreshingIcon)
            ? (_openBlock(), _createBlock(_component_FontAwesomeIcon, {
                key: 0,
                class: _normalizeClass(['refresh-icon', { loading: _ctx.isRefreshing }]),
                icon: _ctx.faArrowRotateRight,
                style: _normalizeStyle(_ctx.iconStyles)
              }, null, 8, ["class", "icon", "style"]))
            : _createCommentVNode("", true),
          _createVNode(_component_Typography, {
            variant: "body1",
            class: "refresh-text"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.refreshText), 1)
            ], undefined, true),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 6))
}