import client from '@/api/client'
import { notifyError } from '@/utils/appstore'
import { TwilioCredentials } from '@/entities/appstore/twilio'

export async function getConfig(): Promise<TwilioCredentials | null> {
    try {
        const { data } = await client.get('/appstore/twilio/config')
        return data.data
    } catch (e: any) {
        notifyError(`Something went wrong: ${e.message}`)
        return null
    }
}

export async function postConfig(config: TwilioCredentials | null) {
    try {
        const { data } = await client.post('/appstore/twilio/config', config)
        return data
    } catch (e: any) {
        if (e.response.status === 400) {
            notifyError(`Error: ${e.response.data.message}`)
        } else {
            notifyError(`Something went wrong: ${e.response.data.message}`)
        }
    }
}

export async function deleteConfig() {
    try {
        const { data } = await client.delete('/appstore/twilio/config')
        return data
    } catch (e: any) {
        if (e.response.status === 400) {
            notifyError(`Error: ${e.response.data.message}`)
        } else {
            notifyError(`Something went wrong: ${e.response.data.message}`)
        }
    }
}
