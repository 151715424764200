/*
 * The query field isn't strictly equal to the type defined at back end,
 * Current query field listed the whole fields that front end can access to,
 * Might be modified later based on the latest user story.
 */

export const appstoreItem = `
id
name
title
description
body
app_link
help_link
search_terms
only_show_for_logged_in
video_url
image
image_hero_url
display
type
published_at
status
is_new
`
export const learningItem = `
id
title
content
feature_on_appstore_homepage
only_show_for_logged_in
url
image_hero_url
published_at
status
type
display
search_terms
`
export const stepItem = `
id
key
appstore_id
learning_id
title
description
`
export const appstoreItemResponse = `
${appstoreItem}
linked_learning {
    ${learningItem}
}
linked_appstore {
    ${appstoreItem}
}
linked_steps {
    ${stepItem}
}
`
export const learningItemResponse = `
${learningItem}
linked_appstore {
    ${appstoreItem}
}
`
