import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { "data-test-tag": "ts-nps-overall" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TsNpsCurrentGroupV2 = _resolveComponent("TsNpsCurrentGroupV2")!
  const _component_TsNpsInfo = _resolveComponent("TsNpsInfo")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TsNpsCurrentGroupV2),
    _createVNode(_component_TsNpsInfo, { "is-mobile": _ctx.isMobile }, null, 8, ["is-mobile"])
  ]))
}