<template>
    <div :class="['feature-content', { 'no-padding': noPadding }]">
        <slot></slot>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component({})
export default class FeatureContainer extends Vue {
    @Prop({ type: Boolean, default: false })
    public noPadding!: boolean
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';

.feature-content {
    padding: @featureGutter @featureGutter @featureGutterThick;
    background-color: @thinFog;
    border-radius: @borderRadius-md;
    backdrop-filter: blur(@glassBlur);

    &.no-padding {
        padding: 0;
    }
}
</style>
