<template>
    <div
        class="circle-avatar"
        @mouseover="changeToUpload(true)"
        @mouseleave="changeToUpload(false)"
        @click="onClickUpload"
    >
        <AvatarOrInitials :size="width" :user="user" />
        <input
            ref="fileInput"
            accept="image/x-png,image/gif,image/jpeg"
            class="file-input"
            type="file"
            @change="uploadAvatar"
        />
        <div v-if="savingAvatar" class="uploading">
            <i class="uk-icon-spinner uk-icon-spin" />
        </div>
        <div
            v-if="clickToUpload"
            class="click-to-upload"
            :style="uploadSizeStyle"
        >
            <span>Update</span>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { Action } from 'vuex-facing-decorator'
import { ScorecardEntity } from '@/entities/scorecard'
import loadImage from 'blueimp-load-image'
import { uploadUserAvatar } from '@/api/settings/account/users'
import { dataURItoBlob } from '@/utils/image'
import AvatarOrInitials from '@/components/WebView/AvatarOrInitials.vue'

@Component({
    components: { AvatarOrInitials },
})
export default class ScorecardProfileImage extends Vue {
    @Prop({ type: Object }) public scorecard!: ScorecardEntity
    @Prop({ type: Number, default: 90 }) public height?: number
    @Prop({ type: Number, default: 90 }) public width?: number
    @Prop({ type: Boolean }) public canEdit!: boolean

    @Action public getPhoto

    public savingAvatar = false
    public clickToUpload = false

    private async onClickUpload() {
        if (!this.canEdit) {
            return
        }
        if (process.env.CONFIG_KEY === 'mobile') {
            const photo = await this.getPhoto()
            this.clickToUpload = false
            if (!photo || !photo.dataUrl) {
                return
            }
            const blob = dataURItoBlob(photo.dataUrl)
            const file = new File([blob as any], `avatar.${photo.format}`, {
                type: `image/${photo.format}`,
            })
            const { data } = await uploadUserAvatar(file)
            this.scorecard.user.avatar = data.url
            this.savingAvatar = false
        } else {
            // web route
            (this.$refs.fileInput as any).click()
        }
    }

    // for web upload
    public async uploadAvatar(event: Event) {
        if (this.savingAvatar || !this.scorecard) {
            return
        }
        const files = (event.target as HTMLInputElement).files
        if (files && files[0]) {
            this.savingAvatar = true
            // resize image and correct orientation with blueimp
            loadImage(
                files[0],
                async (img) => {
                    img.toBlob(async (blob) => {
                        const file = new File([blob as any], 'avatar.png', {
                            type: 'image/png',
                        })
                        const { data } = await uploadUserAvatar(file)
                        this.scorecard.user.avatar = data.url
                        this.savingAvatar = false
                    }, 'image/jpeg')
                },
                {
                    maxWidth: 110,
                    maxHeight: 110,
                    orientation: true,
                    canvas: true,
                }
            )
        }
        this.clickToUpload = false
    }

    // logical user for AvatarOrInitial component
    get user() {
        return {
            id: this.scorecard?.user?.id ?? -1,
            name: this.scorecard?.user?.name ?? '',
            avatar: this.scorecard?.user?.avatar ?? null,
        }
    }

    private changeToUpload(change) {
        if (this.canEdit) {
            this.clickToUpload = change
        }
    }

    private get uploadSizeStyle() {
        return `
                width: ${this.width}px;
                height: ${this.height}px;
            `
    }
}
</script>

<style scoped lang="less">
.circle-avatar {
    position: relative;

    input {
        display: none;
    }

    .avatar {
        cursor: pointer;
    }

    .uploading {
        position: absolute;
        text-align: center;
        top: 0;
        left: 0;
        background: white;
        width: 100%;
        height: 100%;
        opacity: 0.5;
        z-index: 1;
        padding-top: 40%;
    }

    .click-to-upload {
        position: absolute;
        text-align: center;
        top: 0;
        left: 0;
        background: black;
        color: white;
        width: 100%;
        height: 100%;
        z-index: 1;
        border-radius: 50%;
        cursor: pointer;

        span {
            cursor: pointer;
            position: absolute;
            text-align: center;
            top: 50%;
            left: 50%;

            transform: translate(-50%, -50%);
        }
    }
}
</style>
