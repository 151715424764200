import { ActionTree, GetterTree, MutationTree } from 'vuex'
import {
    TeamScoreboardEntity,
    TsGroupSummaryEntity,
    TsNpsSummaryEntity,
    TsTeamGroupEntity,
    TsFilterEntity,
    TsFilterTypes,
    TsFilterOperators,
    TsGroupEntity,
} from '@/entities/teamscoreboard'
import {
    getTeamScoreboard,
    getTsGroupSummary,
    getTsSummary,
    getTsTeamGroups,
} from '@/api/teamscoreboard'
import { MobileTeamscoreboardData } from '@/pages/team-scoreboard/entities/team-scoreboard'
import {
    IFilterRule,
    ILeaderboardChartPoint,
    LeaderboardChartCategories,
} from '@/entities'
import objectHash from 'object-hash'
import { MINIMUM_RESPONSE_THRESHOLD } from '@/utils/globalVariables'
import { getDateRange } from '@/utils/scorecard'

export interface TeamScoreboardState {
    teamScoreboard?: TeamScoreboardEntity // The current NPS Summary
    teamScoreboardCached: Record<string, TeamScoreboardEntity[]>
    tsTeamGroups: TsTeamGroupEntity[]
    tsGroup: string[] // The value we're filtering for
    tsGroupSummary?: TsGroupSummaryEntity // A group of items filtered as above
    tsGroupSummaryCached: Record<string, TsGroupSummaryEntity[]>
    tsScorecard?: TsGroupSummaryEntity // A group of items filtered as above
    tsScorecardCached: Record<string, TsGroupSummaryEntity[]>
    tsHierarchy: TeamScoreboardEntity[] // The navigation hierarchy
    tsSort: string // The sort direction of the main group list
    tsNpsAvg: number
    tsNpsSummary?: TsNpsSummaryEntity // The NPS overall summary
    tsNpsSummaryCached: Record<string, TsNpsSummaryEntity[]>
    tsFilters: TsFilterEntity[] // Client-only filters for the group list
    tsGridHoverGroup: string // The current group being hovered-over on the grid
    /*
    The current filter signature. We don't pass the activeFilter to our API
    calls, but we need to know [when the filter has changed] via this value
    so that we can prevent axios caching our requests
     */
    tsFilterSignature: string
    tsMobileView: boolean
    tsFilterBarVisible: boolean
    tsScatterPlotVisible: boolean
    tsMobileData?: MobileTeamscoreboardData
    tsIsModalVisible: boolean
    tsIsAdminBasic: boolean
    companyName: string
    tsLoadingTeamScoreboard: number
    tsLoadingTeamGroups: number
    tsLoadingTeamGroupSummary: number
    tsLoadingNpsSummary: number
    tsLoadingScorecard: number
    cacheKeyTsNpsSummary: string
    cacheKeyTsGroupSummary: string
    cacheKeyTsScorecard: string
    cacheKeyTeamScoreboard: string
    cacheEnabled: boolean
}

export const state: TeamScoreboardState = {
    teamScoreboard: undefined,
    teamScoreboardCached: {},
    cacheKeyTeamScoreboard: '',
    tsTeamGroups: [],
    tsGroup: [],
    tsGroupSummary: undefined,
    tsGroupSummaryCached: {},
    cacheKeyTsGroupSummary: '',
    tsScorecard: undefined,
    tsScorecardCached: {},
    cacheKeyTsScorecard: '',
    tsHierarchy: [],
    tsSort: 'asc',
    tsNpsAvg: 0,
    tsNpsSummary: {
        nps: 0,
        change: 0,
        total: 0,
        promoter: 0,
        passive: 0,
        detractor: 0,
        scorecardQuestionTotal: 0,
    },
    tsNpsSummaryCached: {},
    cacheKeyTsNpsSummary: '',
    tsFilters: [],
    tsGridHoverGroup: '',
    tsFilterSignature: '',
    tsMobileView: false,
    tsFilterBarVisible: true,
    tsScatterPlotVisible: true,
    tsMobileData: {
        enabledExecutiveReporting: false,
    },
    tsIsModalVisible: false,
    tsIsAdminBasic: false,
    companyName: '',
    tsLoadingTeamScoreboard: 0,
    tsLoadingTeamGroups: 0,
    tsLoadingTeamGroupSummary: 0,
    tsLoadingNpsSummary: 0,
    tsLoadingScorecard: 0,
    cacheEnabled: false,
}

const getTeamsScoreboardGroupFilter = (scorecardValues, field) => {
    if (scorecardValues && scorecardValues.length) {
        return [
            {
                column: field,
                operator: 'in',
                value: scorecardValues,
            },
        ]
    }
    return null
}

const tsGroupSorter = (summary: TsGroupSummaryEntity, tsSort: string) => {
    const groups = [...summary.tsgroups]
    groups.sort((row1, row2) => {
        if (tsSort === 'desc') {
            return row2.NPS - row1.NPS
        } else {
            return row1.NPS - row2.NPS
        }
    })

    return groups
}

const getters: GetterTree<TeamScoreboardState, any> = {
    tsLoadingTeamScoreboard: ({ tsLoadingTeamScoreboard }) =>
        tsLoadingTeamScoreboard,
    tsLoadingTeamGroups: ({ tsLoadingTeamGroups, tsLoadingTeamScoreboard }) =>
        tsLoadingTeamGroups || tsLoadingTeamScoreboard,
    tsLoadingTeamGroupSummary: ({
        tsLoadingTeamGroupSummary,
        tsLoadingTeamGroups,
        tsLoadingTeamScoreboard,
    }) =>
        tsLoadingTeamGroupSummary ||
        tsLoadingTeamGroups ||
        tsLoadingTeamScoreboard,
    tsLoadingNpsSummary: ({
        tsLoadingNpsSummary,
        tsLoadingTeamGroups,
        tsLoadingTeamScoreboard,
    }) => tsLoadingNpsSummary || tsLoadingTeamGroups || tsLoadingTeamScoreboard,
    tsLoadingScorecard: ({
        tsLoadingScorecard,
        tsLoadingTeamGroups,
        tsLoadingTeamScoreboard,
    }) => tsLoadingScorecard || tsLoadingTeamGroups || tsLoadingTeamScoreboard,
    companyName({ companyName }) {
        return companyName
    },
    fieldLabel({
        teamScoreboard,
        teamScoreboardCached,
        cacheKeyTeamScoreboard,
    }) {
        if (teamScoreboardCached[cacheKeyTeamScoreboard]) {
            return teamScoreboardCached[cacheKeyTeamScoreboard]['fieldLabel']
        } else if (teamScoreboard && teamScoreboard['fieldLabel']) {
            return teamScoreboard['fieldLabel']
        }
    },
    teamScoreboard: ({ teamScoreboard }) => teamScoreboard,
    tsTeamGroups: ({ tsTeamGroups }) => tsTeamGroups,
    tsGroup: ({ tsGroup }) => tsGroup,
    tsHierarchy: ({ tsHierarchy }) => tsHierarchy,
    sortedGroups: ({ tsGroupSummary, tsSort }) => {
        if (!tsGroupSummary) {
            return []
        }
        return tsGroupSorter(tsGroupSummary, tsSort)
    },
    sortedGroupsCached: (state) => {
        if (!state.tsGroupSummaryCached[state.cacheKeyTsGroupSummary]) {
            return []
        }
        const tsGroupSummary: unknown =
            state.tsGroupSummaryCached[state.cacheKeyTsGroupSummary]
        return tsGroupSorter(<TsGroupSummaryEntity>tsGroupSummary, state.tsSort)
    },
    sortedPreviousGroups: ({ tsSort }, { previousTeamScoreboard }) => {
        if (!previousTeamScoreboard || !previousTeamScoreboard.tsGroupSummary) {
            return []
        }
        return tsGroupSorter(previousTeamScoreboard.tsGroupSummary, tsSort)
    },
    siblingGroups: (
        { teamScoreboard },
        { sortedPreviousGroups, sortedGroups }
    ) => {
        let targetGroups = sortedPreviousGroups
        if (teamScoreboard?.parent?.field === teamScoreboard?.field) {
            targetGroups = sortedGroups
        }

        if (!targetGroups.length) {
            return { next: null, prev: null }
        }

        const parentField = teamScoreboard?.parent?.field
        const parentGroup = teamScoreboard?.parent?.group

        if (!parentField || !parentGroup) {
            return { next: null, prev: null }
        }

        const index = targetGroups.findIndex(
            (group) => group[parentField] === parentGroup
        )

        if (index === -1) {
            return { next: null, prev: null }
        }

        let nextIndex = index + 1
        let prevIndex = index - 1

        if (nextIndex === targetGroups.length) {
            nextIndex = 0
        }
        if (prevIndex === -1) {
            prevIndex = targetGroups.length - 1
        }

        const next = targetGroups[nextIndex]
        const prev = targetGroups[prevIndex]

        return {
            prev: next ? { field: prev.field, group: prev.group } : null,
            next: prev ? { field: next.field, group: next.group } : null,
        }
    },
    tsGroupSummary: ({ tsGroupSummary }) => {
        if (!tsGroupSummary) {
            return undefined
        }
        return tsGroupSummary
    },
    // This is used by both web and mobile to get data from tsGroupSummary.tsgroups
    // and calculate aggregated nps
    tsChartGroupsDataV2({ tsGroupSummary }) {
        const chartData: ILeaderboardChartPoint[] = []

        if (tsGroupSummary && tsGroupSummary.tsgroups) {
            const groupedData: TsGroupEntity[] = []
            // if all points are below threshold, show all points, rather than putting all in one dot
            const allPointsBelowThreshold = !tsGroupSummary.tsgroups.find(
                (point) => point.responded >= MINIMUM_RESPONSE_THRESHOLD
            )
            tsGroupSummary.tsgroups.forEach((point: TsGroupEntity) => {
                if (
                    allPointsBelowThreshold ||
                    point.responded >= MINIMUM_RESPONSE_THRESHOLD
                ) {
                    chartData.push(point)
                }
            })

            if (groupedData.length) {
                let aggregateScore = 0
                let aggregateTotal = 0
                if (tsGroupSummary.isFiveScore) {
                    groupedData.forEach((point: TsGroupEntity) => {
                        aggregateScore += point.answersum ? point.answersum : 0
                        aggregateTotal += Number(point.responded)
                    })
                    aggregateScore = aggregateTotal
                        ? aggregateScore / aggregateTotal
                        : 0
                } else {
                    let aggregatePromoters = 0
                    let aggregateDetractors = 0
                    groupedData.forEach((point: TsGroupEntity) => {
                        aggregatePromoters += point.respondedPromoter
                        aggregateDetractors += point.respondedDetractor
                        aggregateTotal += Number(point.responded)
                    })
                    // TODO: Haha, another NPS calculation.  Should find a utility version.
                    aggregateScore = aggregateTotal
                        ? ((aggregatePromoters - aggregateDetractors) /
                              aggregateTotal) *
                          100
                        : 0
                }

                chartData.push({
                    group: '',
                    aggregate: true,
                    NPS: aggregateScore,
                    responded: aggregateTotal,
                    subgroups: groupedData,
                })
            }
        }

        return chartData
    },
    tsScorecard: ({ tsScorecard }) => {
        if (!tsScorecard) {
            return undefined
        }

        if (!tsScorecard.tsgroups) {
            return undefined
        }

        tsScorecard.tsgroups.sort((row1, row2) => {
            return row2.responded - row1.responded
        })

        return tsScorecard
    },

    previousTeamScoreboard: ({ tsHierarchy }) => {
        if (tsHierarchy.length <= 1) {
            return null
        }
        return tsHierarchy[tsHierarchy.length - 2]
    },

    filteredHierarchy: ({ tsHierarchy }) => {
        const filters: IFilterRule[] = []

        tsHierarchy.forEach((current) => {
            if (!current.parentField || current.parentGroups.length <= 0) {
                return
            }

            filters.push({
                column: current.parentField,
                operator: 'in',
                value: current.parentGroups,
            })
        })

        return filters
    },

    filteredTsFilters: ({ teamScoreboard, tsFilters }) => {
        const filters: IFilterRule[] = []

        if (!teamScoreboard) {
            return filters
        }
        tsFilters.forEach((filter) => {
            if (
                filter.type === TsFilterTypes.grid &&
                filter.operator === TsFilterOperators.equals
            ) {
                filters.push({
                    column: teamScoreboard.field,
                    operator: 'in',
                    value: filter.filters,
                })
            }
        })

        return filters
    },

    parentFieldFilter: ({ teamScoreboard }) => {
        const filters: IFilterRule[] = []

        if (!teamScoreboard) {
            return filters
        }
        if (
            teamScoreboard.parentField &&
            // At the very last level, we don't want to alter the filter
            teamScoreboard.field !== teamScoreboard.parentField &&
            teamScoreboard.parentGroups.length > 0
        ) {
            filters.push({
                column: teamScoreboard.parentField,
                operator: 'in',
                value: teamScoreboard.parentGroups,
            })
        }

        return filters
    },

    scoreboardGroupsFilter: ({ teamScoreboard }) => {
        const filters: IFilterRule[] = []

        if (!teamScoreboard) {
            return filters
        }
        if (teamScoreboard.groups && teamScoreboard.groups.length > 0) {
            filters.push({
                column: teamScoreboard.field,
                operator: 'in',
                value: teamScoreboard.groups,
            })
        }

        return filters
    },

    // Filter for the NPS Grid, Group List
    groupListFilters: (state, { filteredHierarchy, parentFieldFilter }) => {
        const filters: IFilterRule[] = [
            ...filteredHierarchy,
            ...parentFieldFilter,
        ]

        return filters
    },

    // Filter for sidebar objects
    sideBarFilters: (
        state,
        {
            filteredHierarchy,
            parentFieldFilter,
            filteredTsFilters,
            scoreboardGroupsFilter,
        }
    ) => {
        const filters: IFilterRule[] = [
            ...filteredHierarchy,
            ...parentFieldFilter,
            ...filteredTsFilters,
            ...scoreboardGroupsFilter,
        ]

        return filters
    },

    dashboardResponsesFilters: (
        { teamScoreboard, tsGroup },
        { sideBarFilters }
    ) => {
        if (!teamScoreboard) {
            return []
        }

        const baseFilters = [
            {
                column: teamScoreboard.field,
                operator: 'isnotnull',
                value: ['unused'],
            },
            {
                column: teamScoreboard.field,
                operator: 'isnotempty',
                value: ['unused'],
            },
        ]
        if (tsGroup && tsGroup.length) {
            baseFilters.push({
                column: teamScoreboard.field,
                operator: 'in',
                value: tsGroup,
            })
        }

        return sideBarFilters[0] ? sideBarFilters : baseFilters
    },

    tsSort: ({ tsSort }) => tsSort,

    tsNpsSummary: ({ tsNpsSummary }) => tsNpsSummary,

    tsFilters: ({ tsFilters }) => tsFilters,

    tsFiltersHasGroup: ({ tsFilters }) => {
        const found = tsFilters.find((filter) => {
            return filter.type === 'grid'
        })

        return typeof found !== 'undefined'
    },

    tsGridHoverGroup: ({ tsGridHoverGroup }) => tsGridHoverGroup,

    tsFilterSignature: (state, getters, rootState, rootGetters) => {
        return rootGetters.filterActive
            ? objectHash(rootGetters.filterActive)
            : null
    },

    tsMobileView: ({ tsMobileView }) => tsMobileView,

    tsFilterBarVisible: ({ tsFilterBarVisible }) => tsFilterBarVisible,

    tsScatterPlotVisible: ({ tsScatterPlotVisible }) => tsScatterPlotVisible,

    tsMobileData: ({ tsMobileData }) => tsMobileData,

    isLeafGroup: ({ tsGroupSummary }) => {
        if (tsGroupSummary) {
            return tsGroupSummary.isLeafGroup
        } else {
            return false
        }
    },

    tsCurrentGroupTitle: (
        { teamScoreboard, tsFilters, tsGroup },
        getters,
        { chartStatus: { selectedCategory } }
    ) => {
        const gridFilter = tsFilters.find(({ type }) => type === 'grid')

        if (gridFilter !== undefined) {
            if (!selectedCategory) {
                return 'Chart Selection'
            }

            return LeaderboardChartCategories[selectedCategory]
        }

        if (
            teamScoreboard !== undefined &&
            teamScoreboard.parentGroups !== undefined &&
            teamScoreboard.parentGroups.length > 0 &&
            teamScoreboard.parentGroups.join('…') !== ''
        ) {
            return teamScoreboard.parentGroups.join('…')
        }

        if (tsGroup && tsGroup.length) {
            return tsGroup.map(String).join(', ')
        }

        if (!getters.previousTeamScoreboard && getters.companyName.length) {
            return getters.companyName
        }

        return 'All Areas'
    },

    tsCurrentGroupAvatar: (
        { tsGroup, tsGroupSummary },
        getters,
        { chartStatus: { selectedCategory } }
    ) => {
        try {
            return tsGroupSummary?.tsgroups.find(
                (group) => group.group === tsGroup[0]
            )?.avatar
        } catch (e) {
            return ''
        }
    },

    tsIsModalVisible: ({ tsIsModalVisible }) => tsIsModalVisible,

    tsIsAdminBasic: ({ tsIsAdminBasic }) => tsIsAdminBasic,
}

const actions: ActionTree<TeamScoreboardState, any> = {
    async loadTeamScoreboard(
        { commit, state, rootGetters },
        {
            parent = { field: '', group: null },
            selected = { field: '', role: '' },
            // whether push into tsHierarchy
            pushHierarchy = true,
            swapHierarchy = false,
            forceRefresh = false,
        } = {
            parent: { field: '', group: null },
            selected: { field: '', role: '' },
            pushHierarchy: true,
            swapHierarchy: false,
            forceRefresh: false,
        }
    ) {
        const questionType = rootGetters.mobileQuestionType
        // get cache by key
        state.cacheKeyTeamScoreboard = btoa(
            `${questionType}${JSON.stringify(parent)}${JSON.stringify(
                selected
            )}${pushHierarchy}${swapHierarchy}`
        )
        if (
            state.cacheEnabled &&
            state.teamScoreboardCached[state.cacheKeyTeamScoreboard] &&
            !forceRefresh
        ) {
            // load data from cache
            state.teamScoreboard = state.teamScoreboardCached[
                state.cacheKeyTeamScoreboard
            ] as unknown as TeamScoreboardEntity
            return
        }

        commit('setTsLoadingTeamScoreboard', 1)
        const { data } = await getTeamScoreboard(
            parent.field,
            parent.group ? [parent.group] : [],
            selected.field,
            [],
            selected.role,
            questionType
        )

        commit('clearTsFilterOfType', TsFilterTypes.grid)
        commit('clearTsFilterOfType', TsFilterTypes.search)
        commit('setTeamScoreboard', { ...data, parent, selected })
        if (pushHierarchy && !swapHierarchy) {
            commit('pushTsHierarchy')
        }
        if (swapHierarchy) {
            commit('swapTsHierarchy')
        }
        commit('setTsLoadingTeamScoreboard', 0)
    },

    async loadTsTeamGroups({ commit }) {
        commit('setTsLoadingTeamGroups', 1)
        const { data } = await getTsTeamGroups()
        commit('setTsTeamGroups', data)
        commit('setTsLoadingTeamGroups', 0)
    },

    async loadTsGroupSummary(
        { commit, state, getters, rootGetters },
        { field, parentField, role, daterange = '', applyActiveFilter = 1 }
    ) {
        const filters = getters.groupListFilters
        // get cache by key
        state.cacheKeyTsGroupSummary = btoa(
            `${field}${parentField}${role}${daterange}`
        )
        if (
            state.cacheEnabled &&
            state.tsGroupSummaryCached[state.cacheKeyTsGroupSummary]
        ) {
            // load data from cache
            return
        }
        commit('setTsLoadingTeamGroupSummary', 1)
        const { data } = await getTsGroupSummary(
            field ? field : parentField,
            parentField,
            filters,
            getters.tsFilterSignature,
            role,
            daterange,
            rootGetters.mobileQuestionType,
            applyActiveFilter
        )
        commit('setTsGroupSummary', data)
        commit('setTsLoadingTeamGroupSummary', 0)
    },

    async loadTsGroupSummaryFromScoreboard({ getters, dispatch }) {
        if (!getters.teamScoreboard) {
            return
        }

        const { field, parentField, role } = getters.teamScoreboard
        await dispatch('loadTsGroupSummary', {
            field: field,
            parentField: parentField,
            role: role,
        })
    },

    async loadTsScorecard(
        { commit, state, getters, rootGetters },
        {
            field,
            extraFilters,
            daterange,
            forceRefresh,
            applyActiveFilter,
            fromTeams,
        } = {
            field: '',
            extraFilters: null,
            daterange: '',
            forceRefresh: false,
            applyActiveFilter: 1,
            fromTeams: 0,
        }
    ) {
        let filters = getters.sideBarFilters
        if (extraFilters) {
            filters = [...filters, ...extraFilters]
        }

        const questionType = rootGetters.mobileQuestionType
        // get cache by key
        state.cacheKeyTsScorecard = btoa(
            `${field}${questionType}${JSON.stringify(filters)}${daterange}`
        )
        if (
            state.cacheEnabled &&
            state.tsScorecardCached[state.cacheKeyTsScorecard] &&
            !forceRefresh
        ) {
            // load data from cache
            state.tsScorecard = state.tsScorecardCached[
                state.cacheKeyTsScorecard
            ] as unknown as TsGroupSummaryEntity
            return
        }

        commit('setTsLoadingScorecard', 1)
        const { data } = await getTsGroupSummary(
            field,
            '',
            filters,
            getters.tsFilterSignature,
            '',
            daterange,
            questionType,
            applyActiveFilter,
            fromTeams
        )
        commit('setTsScorecard', data)
        commit('setTsLoadingScorecard', 0)
    },

    async loadTsScorecardFromScoreboard(
        { getters, dispatch },
        selectedScorecard = null
    ) {
        if (!getters.teamScoreboard) {
            return
        }

        const { field, scorecardField, scorecardValues } =
            getters.teamScoreboard
        const extraFilters = getTeamsScoreboardGroupFilter(
            scorecardValues,
            field
        )

        await dispatch('loadTsScorecard', {
            field: selectedScorecard ? selectedScorecard : scorecardField,
            extraFilters: extraFilters,
        })
    },

    async loadTsNpsSummary(
        { commit, state, getters, rootGetters },
        {
            field,
            groups,
            extraFilters = null,
            daterange = '',
            forceRefresh = false,
            scorecard = null,
        }
    ) {
        let filters = getters.sideBarFilters
        if (extraFilters) {
            filters = [...filters, ...extraFilters]
        }
        const questionType = rootGetters.mobileQuestionType
        // get cache by key
        state.cacheKeyTsNpsSummary = btoa(
            `${field}${groups}${questionType}{${extraFilters}${daterange}${scorecard}`
        )
        if (
            state.cacheEnabled &&
            state.tsNpsSummaryCached[state.cacheKeyTsNpsSummary] &&
            !forceRefresh
        ) {
            // load data from cache
            state.tsNpsSummary = state.tsNpsSummaryCached[
                state.cacheKeyTsNpsSummary
            ] as unknown as TsNpsSummaryEntity
            return
        }

        commit('setTsLoadingNpsSummary', 1)
        const { data } = await getTsSummary(
            field,
            groups,
            filters,
            getters.tsFilterSignature,
            daterange,
            questionType,
            0,
            scorecard
        )

        if (data.success) {
            commit('setTsNpsSummary', data)
        }
        commit('setTsLoadingNpsSummary', 0)
    },

    async loadTsNpsSummaryFromScorecard(
        { getters, dispatch },
        scorecardField = null
    ) {
        if (!getters.teamScoreboard) {
            return
        }

        const { parentGroups, parentField, field, scorecardValues } =
            getters.teamScoreboard
        const extraFilters = getTeamsScoreboardGroupFilter(
            scorecardValues,
            field
        )

        await dispatch('loadTsNpsSummary', {
            field: parentGroups.length ? parentField : field,
            groups: parentGroups,
            extraFilters: extraFilters,
            scorecard: scorecardField,
        })
    },

    popTsHierarchy({ commit }, step = 1) {
        commit('clearTsFilterOfType', TsFilterTypes.grid)
        commit('clearTsFilterOfType', TsFilterTypes.search)
        commit('popTsHierarchy', step)
    },

    clearTsHierarchy({ commit }) {
        commit('clearTsHierarchy')
    },

    setTsGroup({ commit }, group) {
        commit('setTsGroup', group)
    },

    setTsSort({ commit }, sort) {
        commit('setTsSort', sort)
    },

    setTsFilter({ commit }, filter: TsFilterEntity) {
        commit('setTsFilter', filter)
    },

    clearTsFilterOfType({ commit }, type: string) {
        commit('clearTsFilterOfType', type)
    },

    setTsGridHoverGroup({ commit }, group: string) {
        commit('setTsGridHoverGroup', group)
    },

    setTsMobileView({ commit }, value: boolean) {
        commit('setTsMobileView', value)
    },

    setTsFilterBarVisible({ commit }, value: boolean) {
        commit('setTsFilterBarVisible', value)
    },

    setTsScatterPlotVisible({ commit }, value: boolean) {
        commit('setTsScatterPlotVisible', value)
    },

    setTsMobileData({ commit }, value: MobileTeamscoreboardData) {
        commit('setTsMobileData', value)
    },

    setTsIsModalVisible({ commit }, value: boolean) {
        commit('setTsIsModalVisible', value)
    },

    setCacheEnabled({ commit }, value: boolean) {
        commit('setCacheEnabled', value)
    },

    async switchTeamScoreboard({ getters, dispatch }, group) {
        if (group) {
            await dispatch('loadTeamScoreboard', {
                parent: group,
                swapHierarchy: true,
            })
        }
    },
    async loadOfflineDataForTopicScoreboard({
        getters,
        dispatch,
        rootGetters,
    }) {
        const filters = getters.sideBarFilters
        filters.push({
            column: rootGetters['teamFilter/filterOptions'][0]?.fieldMapping,
            operator: 'in',
            value: [rootGetters.userName],
        })
        const params = {
            field: rootGetters['teamFilter/teamFilterSelected']?.fieldMapping,
            role: rootGetters['teamFilter/teamFilterSelected']?.role,
            forceRefresh: false,
        }

        await dispatch('loadTsTeamGroups')

        if (
            rootGetters['teamFilter/teamFilterSelected']?.customSelectionName ||
            rootGetters['teamFilter/teamParentFilterSelected']
        ) {
            // Topmost level, or not drilled down.
            await dispatch('loadTeamScoreboard', {
                parent: params,
            })
        } else {
            await dispatch('loadTeamScoreboard', {
                selected: params,
            })
        }

        await getTsGroupSummary(
            rootGetters.teamScoreboard?.scorecardField,
            '',
            filters,
            getters.tsFilterSignature,
            '',
            getDateRange(Number(30)), // Home screen only need 30 days of data
            rootGetters.mobileQuestionType,
            0,
            1
        )
    },
}

const mutations: MutationTree<TeamScoreboardState> = {
    setTsLoadingTeamScoreboard(state, loading: number) {
        state.tsLoadingTeamScoreboard = loading
    },
    setTsLoadingTeamGroups(state, loading: number) {
        state.tsLoadingTeamGroups = loading
    },
    setTsLoadingTeamGroupSummary(state, loading: number) {
        state.tsLoadingTeamGroupSummary = loading
    },
    setTsLoadingNpsSummary(state, loading: number) {
        state.tsLoadingNpsSummary = loading
    },
    setTsLoadingScorecard(state, loading: number) {
        state.tsLoadingScorecard = loading
    },
    setTeamScoreboard(state, teamScoreboard: TeamScoreboardEntity) {
        state.teamScoreboard = teamScoreboard
        state.tsGroup = teamScoreboard.groups
        // @ts-ignore
        state.teamScoreboardCached[state.cacheKeyTeamScoreboard] =
            teamScoreboard
    },

    setTsTeamGroups(state, tsTeamGroups: TsTeamGroupEntity[]) {
        state.tsTeamGroups = tsTeamGroups
    },

    pushTsHierarchy(state) {
        if (!state.teamScoreboard) {
            return
        }

        const current = state.tsHierarchy[state.tsHierarchy.length - 1]

        if (!current || current.field !== state.teamScoreboard.field) {
            // Only navigate if we're doing it on different levels
            state.tsHierarchy.push({ ...state.teamScoreboard })
        }
    },

    popTsHierarchy(state, step = 1) {
        for (let i = 0; i < step; i++) {
            state.tsHierarchy.pop()
        }

        const peek = state.tsHierarchy[state.tsHierarchy.length - 1]
        if (peek) {
            state.teamScoreboard = peek
            state.tsGroup = peek.groups
        }
    },

    swapTsHierarchy(state) {
        if (!state.teamScoreboard) {
            return
        }
        if (
            state.teamScoreboard?.parent?.field !== state.teamScoreboard?.field
        ) {
            state.tsHierarchy.pop()
            state.tsHierarchy.push({ ...state.teamScoreboard })
        }
    },

    clearTsHierarchy(state) {
        state.tsHierarchy = []
        state.tsGroup = []
    },

    setTsGroupSummary(state, groups: TsGroupSummaryEntity) {
        state.tsGroupSummary = groups
        if (state.tsHierarchy && state.tsHierarchy.length) {
            //This is a cheat.  Assigning a property this way will _not_ trigger reactivity.
            //Please refer to https://vuejs.org/v2/guide/reactivity.html
            state.tsHierarchy[state.tsHierarchy.length - 1].tsGroupSummary =
                groups
        }
        // cache the data.
        // @ts-ignore
        state.tsGroupSummaryCached[state.cacheKeyTsGroupSummary] = groups
    },

    setTsScorecard(state, groups: TsGroupSummaryEntity) {
        state.tsScorecard = groups
        // @ts-ignore
        state.tsScorecardCached[state.cacheKeyTsScorecard] = groups
    },

    setTsGroup(state, group: string) {
        state.tsGroup = [group]
    },

    setTsSort(state, sort: string) {
        state.tsSort = sort
    },

    setTsNpsSummary(state, tsNpsSummary: TsNpsSummaryEntity) {
        state.tsNpsSummary = tsNpsSummary
        // @ts-ignore
        state.tsNpsSummaryCached[state.cacheKeyTsNpsSummary] = tsNpsSummary
    },

    // Sets a filter item (or replaces if there's any to replace)
    setTsFilter(state, tsFilter: TsFilterEntity) {
        let set = 0
        Object.entries(state.tsFilters).forEach(([key, filter]) => {
            if (filter.type === tsFilter.type) {
                // Remove duplicates (in case there are any)
                const index = state.tsFilters.indexOf(filter)
                state.tsFilters.splice(index, 1)

                if (set <= 0) {
                    // We spread as this causes a change to the object state, which pings Vue
                    state.tsFilters = [...state.tsFilters, tsFilter]
                    set++
                }
            }
        })

        if (set <= 0) {
            state.tsFilters = [...state.tsFilters, tsFilter]
        }
    },

    clearTsFilterOfType(state, type: string) {
        state.tsFilters = state.tsFilters.filter(
            (filter) => filter.type !== type
        )
    },

    setTsGridHoverGroup(state, tsGridHoverGroup: string) {
        state.tsGridHoverGroup = tsGridHoverGroup
    },

    setTsMobileView(state, value: boolean) {
        state.tsMobileView = value
    },

    setTsFilterBarVisible(state, value: boolean) {
        state.tsFilterBarVisible = value
    },

    setTsScatterPlotVisible(state, value: boolean) {
        state.tsScatterPlotVisible = value
    },

    setTsMobileData(state, value: MobileTeamscoreboardData) {
        state.tsMobileData = value
    },

    setTsIsModalVisible(state, tsIsModalVisible) {
        state.tsIsModalVisible = tsIsModalVisible
    },

    setCompanyName(state, companyName) {
        state.companyName = companyName
    },

    setTsIsAdminBasic(state, tsIsAdminBasic) {
        state.tsIsAdminBasic = tsIsAdminBasic
    },

    /**
     * For mobile app, the daterange is sent by user selecting Time Filter on page.
     * For desktop, the daterange is decided by dashboardfilter picked up on the server side,
     * then cacheEnabled=true will show incorrect data on the front end.
     */
    setCacheEnabled(state, enabled: boolean) {
        state.cacheEnabled = enabled
    },
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations,
}
