import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InsightsLanding = _resolveComponent("InsightsLanding")!
  const _component_GrowthMetrics = _resolveComponent("GrowthMetrics")!

  return (_ctx.$companyVars.enable_insights_v1)
    ? (_openBlock(), _createBlock(_component_InsightsLanding, { key: 0 }))
    : (_ctx.$companyVars.enable_insights_v1 !== undefined)
      ? (_openBlock(), _createBlock(_component_GrowthMetrics, { key: 1 }))
      : _createCommentVNode("", true)
}