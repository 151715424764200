import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b897764a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "spotlight-details-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EngagementTeamSpotlightUser = _resolveComponent("EngagementTeamSpotlightUser")!
  const _component_EngagementCheckInHistory = _resolveComponent("EngagementCheckInHistory")!

  return (_ctx.userData)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_EngagementTeamSpotlightUser, { "user-data": _ctx.userData }, null, 8, ["user-data"]),
        _createVNode(_component_EngagementCheckInHistory, {
          "engagement-questions": _ctx.questionHistory,
          "pre-selected-theme": _ctx.selectedTheme
        }, null, 8, ["engagement-questions", "pre-selected-theme"])
      ]))
    : _createCommentVNode("", true)
}