import { ActionTree, GetterTree, MutationTree } from 'vuex'
import {
    getTeamProfileUsers,
    getYearInGoals,
    saveReportCardComment,
    getReportCardMessages,
} from '@/api/reportcard'
import {
    IYearInGoalItem,
    ReportCardComment,
    TeamProfileUser,
} from '@/entities/reportcard'
import { IMessage } from '@/entities/notices'
import { UserDetail } from '@/entities'
import { showToast } from '@/mobile/src/utils/toastbus'
import { ToastColor, ToastDuration, ToastIcon } from '@/mobile/src/types/toasts'
import { getLastMonthUsingTimezone } from '@/utils/time'
import { Emitter } from 'mitt'

export interface ReportCardState {
    reportCardByUserMonth: Record<number, IYearInGoalItem[]>
    cacheKey: string
    month: number
    userName: string
    drillDownUser: UserDetail
    show: boolean
    showTeamProfileSelect: boolean
    teamProfileUsers: TeamProfileUser[]
    reportCardMessagesByUserMonth: Record<number, IMessage[]>
    reportCardMessagesCacheKey: string
}

export const state: ReportCardState = {
    reportCardByUserMonth: {},
    cacheKey: '',
    month: 0,
    userName: '',
    drillDownUser: {
        id: 0,
        name: '',
        avatar: null,
    },
    show: false,
    showTeamProfileSelect: false,
    teamProfileUsers: [],
    reportCardMessagesByUserMonth: {},
    reportCardMessagesCacheKey: '',
}

const getters: GetterTree<ReportCardState, any> = {
    cacheKey: (state) => state.cacheKey,
    reportCardData: (state) =>
        state.reportCardByUserMonth[state.cacheKey] || [],
    teamProfileUsers: (state) => state.teamProfileUsers,
    reportCardComment: (state, getters) => {
        const monthObj: IYearInGoalItem | undefined = getters.monthObj

        if (monthObj) {
            return monthObj.comment
        }

        return ''
    },
    monthObj: (state): IYearInGoalItem | undefined => {
        return state.reportCardByUserMonth[state.cacheKey]?.find(
            (obj) => obj.month === state.month
        )
    },
    reportMonth: (state) => state.month,
    reportUserName: (state) => state.userName,
    drillDownUser: (state) => state.drillDownUser,
    reportCardUser: (state) => {
        if (!state.drillDownUser?.name || state.drillDownUser?.name === '') {
            return state.userName
        }
        return state.drillDownUser?.name
    },
    showReportCard: (state) => state.show,
    showTeamProfileSelect: (state) => state.showTeamProfileSelect,
    mainScore: (state, getters) => {
        if (!state.reportCardByUserMonth[state.cacheKey]) {
            return 0
        }
        const monthObj: IYearInGoalItem | undefined = getters.monthObj
        if (monthObj) {
            return monthObj.score
        } else {
            return 0
        }
    },
    hasLastMonthsReportCard(state, getters, rootState, rootGetters): boolean {
        const reportCardData = state.reportCardByUserMonth[state.cacheKey] || []

        const lastMonth = getLastMonthUsingTimezone(
            rootGetters.$companyVars.timezone
        )

        return (
            reportCardData.filter(
                (item) => item.hasData && item.month === lastMonth
            ).length > 0
        )
    },
    reportCardMessagesByUserMonth: (state) =>
        state.reportCardMessagesByUserMonth?.[state.reportCardMessagesCacheKey],
    reportCardMessagesCacheKey: (state) => state.reportCardMessagesCacheKey,
}

const actions: ActionTree<ReportCardState, any> = {
    async loadReportCardData({ commit, state, getters }, forceRefresh = false) {
        // allow to get ReportCard data for the drillDown user.
        const reportCardUser = getters.reportCardUser
        commit('setCacheKey', btoa(`${state.month}-${reportCardUser ?? ''}`))

        if (state.reportCardByUserMonth[state.cacheKey] && !forceRefresh) {
            return
        }

        try {
            const { data } = await getYearInGoals(
                getters.reportMonth,
                reportCardUser
            )
            commit('setReportCardData', { data })
        } catch (error) {
            // handle error
        }
    },
    setReportMonth({ commit }, month: number) {
        commit('setReportMonth', month)
    },
    setReportUserName({ commit }, userName: number) {
        commit('setReportUserName', userName)
    },
    setShowReportCard({ commit }, show: boolean) {
        commit('setShowReportCard', show)
    },
    setShowTeamProfileSelect({ commit }, show: boolean) {
        commit('setShowTeamProfileSelect', show)
    },
    async loadTeamProfileUsers({ commit, rootGetters }) {
        const { data } = await getTeamProfileUsers(
            rootGetters.mobileQuestionType
        )
        commit('setTeamProfileUsers', data)
    },
    setDrillDownUser(
        { commit },
        payload: { id: number; name: string; avatar: string | null }
    ) {
        commit('setDrillDownUser', payload)
    },
    async saveComment(
        { commit, state, getters },
        payload: {
            comment: ReportCardComment
            month: number
            isEdit: boolean
            emitter: Emitter<any> | null
        }
    ) {
        if (!payload.comment.content) {
            return
        }

        const monthObj = state.reportCardByUserMonth[state.cacheKey]?.find(
            (obj) => obj.month === payload.month
        )

        if (!monthObj) {
            return
        }
        const yearMonth = monthObj.yearMonth
        const questionType = monthObj.questionType

        try {
            const { data } = await saveReportCardComment(
                getters.reportCardUser,
                yearMonth,
                questionType,
                payload.comment.content,
                payload.isEdit
            )

            commit('setReportCardComment', {
                comment: data.comment,
                month: payload.month,
            })

            showToast(
                payload.emitter,
                ToastIcon.Success,
                ToastColor.Message,
                'Comment saved',
                ToastDuration.Standard
            )

            commit('updateTeamProfileUserHasComment', getters.reportCardUser)
        } catch (error) {
            showToast(
                payload.emitter,
                ToastIcon.Alert,
                ToastColor.Warning,
                'An error occurred, please try again',
                ToastDuration.Standard
            )
        }
    },
    async loadReportCardMessages(
        { commit, state },
        payload: { month: number; userName: string }
    ) {
        commit(
            'setReportCardMessagesCacheKey',
            btoa(`${payload.month}-${payload.userName ?? ''}`)
        )

        if (
            state?.reportCardMessagesByUserMonth?.[
                state.reportCardMessagesCacheKey
            ] !== undefined
        ) {
            return
        }

        const { data } = await getReportCardMessages(
            payload.month,
            payload.userName
        )
        commit('setReportCardMessagesByUseMonth', data)
    },
}

const mutations: MutationTree<ReportCardState> = {
    setReportCardComment(
        state,
        payload: { comment: ReportCardComment; month: number }
    ) {
        const monthIndex = state.reportCardByUserMonth[
            state.cacheKey
        ].findIndex((obj) => obj.month === payload.month)
        state.reportCardByUserMonth[state.cacheKey][monthIndex].comment =
            payload.comment
    },
    setCacheKey(state, cacheKey: string) {
        state.cacheKey = cacheKey
    },
    setReportCardData(state, payload: { data: IYearInGoalItem[] }) {
        state.reportCardByUserMonth[state.cacheKey] = payload.data
    },
    setReportMonth(state, month: number) {
        state.month = month
    },
    setReportUserName(state, userName: string) {
        state.userName = userName
    },
    setShowReportCard(state, show: boolean) {
        state.show = show
    },
    setShowTeamProfileSelect(state, show: boolean) {
        state.showTeamProfileSelect = show
    },
    setTeamProfileUsers(state, teamProfileUsers: TeamProfileUser[]) {
        state.teamProfileUsers = teamProfileUsers
    },
    setDrillDownUser(state, payload: UserDetail) {
        state.drillDownUser.id = payload?.id
        state.drillDownUser.name = payload?.name
        state.drillDownUser.avatar = payload?.avatar
    },
    updateTeamProfileUserHasComment(state, userName) {
        const userIndex = state.teamProfileUsers.findIndex((profile) => {
            return profile.userName === userName
        })

        if (state.teamProfileUsers[userIndex]) {
            state.teamProfileUsers[userIndex].hasComment = true
        }
    },
    setReportCardMessagesByUseMonth(state, reportCardMessages: IMessage[]) {
        state.reportCardMessagesByUserMonth[state.reportCardMessagesCacheKey] =
            reportCardMessages
    },
    setReportCardMessagesCacheKey(state, cacheKey: string) {
        state.reportCardMessagesCacheKey = cacheKey
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
