import {
    EngagementQuestionTheme,
    EngagementQuestionThemeDescription,
} from '@/entities/engagement'
import WellbeingIcon from '@/assets/img/Icons/Wellbeing.svg'
import CultureIcon from '@/assets/img/Icons/Culture.svg'
import RecognitionIcon from '@/assets/img/Icons/Recognition.svg'
import DevelopmentIcon from '@/assets/img/Icons/Development.svg'

export const EngagementQuestionIcon = {
    [EngagementQuestionTheme.Wellbeing]: WellbeingIcon,
    [EngagementQuestionTheme.Culture]: CultureIcon,
    [EngagementQuestionTheme.Recognition]: RecognitionIcon,
    [EngagementQuestionTheme.Development]: DevelopmentIcon,
}

export const tileDescription = (themeId) => {
    return EngagementQuestionThemeDescription[themeId]
}

export const tilePercentage = (theme) => {
    return (
        Math.round(
            (theme.current_term_data.answered_count /
                theme.current_term_data.response_count) *
                100
        ) + '%'
    )
}

export const engagementIcon = (themeId) => {
    return EngagementQuestionIcon[themeId]
}
