import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_EmptyPageState = _resolveComponent("EmptyPageState")!

  return (_openBlock(), _createBlock(_component_EmptyPageState, {
    colour: "white",
    class: _normalizeClass([_ctx.bordered])
  }, {
    "icon-img": _withCtx(() => [
      _createVNode(_component_FontAwesomeIcon, {
        icon: _ctx.inboxIcon,
        class: "inbox-icon"
      }, null, 8, ["icon"])
    ]),
    "header-text": _withCtx(() => [
      _createTextVNode("Inbox Empty")
    ]),
    "body-text": _withCtx(() => [
      _createTextVNode(" You haven't received any messages yet. Please check back later. ")
    ]),
    _: 1
  }, 8, ["class"]))
}