<template>
    <HierarchyGoalTimeline
        :title="'TOP PERFORMERS'"
        :loading="loading"
        :month="month"
        :user-name="userName"
        :number-per-page="3"
        :hierarchy-items="getTopPerformersByUserMonth"
    />
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import { namespace } from 'vuex-facing-decorator'
import HierarchyGoalTimeline from '@/mobile/src/views/reportcard/HierarchyGoalTimeline.vue'

const TopPerformersModule = namespace('topperformers')

@Component({
    components: {
        HierarchyGoalTimeline,
    },
})
export default class TopPerformer extends Vue {
    @Prop({ type: Number }) public month!: number
    @Prop({ type: String }) public userName!: string

    private loading = true

    @TopPerformersModule.Getter getTopPerformersByUserMonth!: Function
    @TopPerformersModule.Action loadTopPerformersData

    public mounted() {
        this.loadData()
    }

    public async loadData() {
        this.loading = true
        await this.loadTopPerformersData({
            month: this.month,
            userName: this.userName,
        })
        this.loading = false
    }

    @Watch('month')
    @Watch('userName')
    public async onMonthChanged() {
        await this.loadData()
    }
}
</script>

<style lang="less"></style>
