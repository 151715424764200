import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, TransitionGroup as _TransitionGroup, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-70637944"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toast-container" }
const _hoisted_2 = {
  key: 0,
  class: "toast-backdrop"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "toast-msg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.toastList.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2))
          : _createCommentVNode("", true)
      ], undefined, true),
      _: 1
    }),
    _createVNode(_TransitionGroup, { name: "slide-in-out" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.toastList, (event) => {
          return (_openBlock(), _createElementBlock("div", {
            key: event.time,
            class: _normalizeClass(["toast-display", [event.color, { dismissable: event.dismissable }]]),
            onClick: ($event: any) => (_ctx.onClickToast(event))
          }, [
            _createVNode(_component_FontAwesomeIcon, {
              class: "toast-icon",
              icon: _ctx.getIcon(event.icon)
            }, null, 8, ["icon"]),
            _createElementVNode("div", _hoisted_4, _toDisplayString(event.msg), 1)
          ], 10, _hoisted_3))
        }), 128))
      ], undefined, true),
      _: 1
    })
  ]))
}