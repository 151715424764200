export const friendlyCustomerTenure = (days) => {
    if (!days) {
        return '0.0'
    }
    // month
    if (days > 30) {
        return (days / 30).toFixed(1) + ' mths'
    }
    // week
    if (days > 7) {
        return (days / 7).toFixed(1) + ' weeks'
    }
    return days.toFixed(1) + ' days'
}
