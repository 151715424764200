<template>
    <div :class="['flexible-image-box', extraClass]" :style="divStyle">
        <img :src="src" :alt="alt" />
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component({})
export default class FlexibleImageBox extends Vue {
    @Prop({ type: String, required: true }) private src!: string
    @Prop({ type: String, default: '' }) private alt!: string
    @Prop({ type: String, default: null }) private extraClass!: string
    @Prop({ type: Number, default: null }) public width!: number
    @Prop({ type: Number, default: null }) public height!: number

    public get divStyle() {
        return {
            width: this.width ? `${this.width}px` : '100%',
        }
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/variables.less';

.flexible-image-box {
    img {
        width: 100%;
        height: 100%;
        border-radius: 0 0 @borderRadius-md @borderRadius-md;
    }
}

.moment-image {
    img {
        border-radius: 0 0 @borderRadius-md @borderRadius-md;
    }
}
</style>
