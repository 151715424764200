import Vue from 'vue'
import { ActionTree, MutationTree, GetterTree } from 'vuex'
import {
    BlockBuilderModel,
    CardSectionIndex,
    CardIndex,
    CardCaseIndex,
    CardSection,
    HasCard,
    HasSection,
    HasDirection,
    HasParam,
    CardCases,
    isBranchedCard,
    BaseCard,
    isParamCard,
    isOptionsCard,
    LinkConfig,
    isListCard,
    Card,
    OptionsCard,
} from '@/entities/conversation'
import { partition } from '@/utils/array'
import { getSpecLatestVersion, getFieldOptions } from '@/api/conversation'
import {
    specToBlockBuilderModel,
    blankSection,
    blankCard,
    firstCase,
} from '@/transforms/conversation'
import { Scope } from '@/pages/conversation/ConversationUtils'
import {
    deriveContextualData,
    walkBlockBuilderModelLinear,
} from '@/pages/conversation/ConversationValidator'
import { IScorecardSetting } from '@/pages/appstore/components/Scorecard/scorecard-settings-entity'
import { getScorecardSettingsList } from '@/api/appstore/scorecardSettings'

interface Rights {
    isStaff: boolean
    hasFlows: boolean
    isBasic: boolean
    maxListItems: number
    hasBilling: boolean
    billingSource: string
    isViewSurveyTemplates: boolean
    isEditSurveyTemplates: boolean
}

export interface ConversationSpecState {
    sections: BlockBuilderModel
    lastEdit: number
    lastSave: number
    showUpgrade: boolean
    clickCardType: string
    activeTab: string
    rights: Rights
    calendly: LinkConfig
    scorecardSettings: IScorecardSetting[]
    fields: Scope // <- data gathered during the conversation
    customFields: Scope // <- already known or imported data
    maxQuestions: number
    inSubAppDesignTab: boolean
    questionType: string
}

const now = new Date().valueOf()

const state: ConversationSpecState = {
    sections: [],
    lastEdit: now,
    lastSave: now,
    showUpgrade: false,
    clickCardType: '',
    activeTab: 'master',
    rights: {
        isStaff: false,
        hasFlows: false,
        isBasic: false,
        maxListItems: 100,
        hasBilling: true,
        billingSource: '',
        isViewSurveyTemplates: false,
        isEditSurveyTemplates: false,
    },
    calendly: {
        say: '',
        link: '',
    },
    scorecardSettings: [],
    fields: {},
    customFields: {},
    maxQuestions: 9,
    inSubAppDesignTab: false,
    questionType: 'nps',
}

const getters: GetterTree<ConversationSpecState, any> = {
    getSections({ sections }) {
        return sections
    },
    getLastEdit({ lastEdit }) {
        return lastEdit
    },
    getLastSave({ lastSave }) {
        return lastSave
    },
    getFlowNames({ sections }) {
        return sections.filter(({ flow }) => flow).map(({ name }) => name)
    },
    getMasterNames({ sections }) {
        return sections.filter(({ flow }) => !flow).map(({ name }) => name)
    },
    getDerived(
        {
            sections,
            fields,
            customFields,
            maxQuestions,
            lastEdit,
            questionType,
        },
        getters,
        rootState,
        rootGetters
    ) {
        // @note - leave 'lastEdit' bound, even though it is not used, to fire reactive updates
        return deriveContextualData({
            sections,
            fields,
            customFields,
            maxQuestions,
            questionType,
        })
    },
    getGlobal(state, getters) {
        return getters.getDerived.global
    },
    getContextual(state, getters) {
        return getters.getDerived.contextual
    },
    getCalendly({ calendly }) {
        return calendly
    },
    scorecardSettings({ scorecardSettings }) {
        return scorecardSettings
    },

    // Used to find out how many question type cards (open,csat,nps) we have (excluding comment).
    // how many list cards, some customers will be allowed 1 list card on the base plan.
    // ignore, inapp leading question cards
    getCardTypes({ sections }) {
        // in-app leading question (ignore these items)
        const allowedParams = ['answer_nps', 'answer_csat', 'answer_ces']

        const allCards = getAllParamCards(sections).filter(
            (card) =>
                isParamCard(card) && allowedParams.indexOf(card.param) === -1
        )
        const [listCards, questionCards] = partition(
            isListCard,
            allCards
        ) as HasParam[][]

        return {
            listcount: listCards.length,
            questioncount: questionCards.length,
            commentcount: questionCards.filter(
                (card) => card.param === 'comment'
            ).length,
        }
    },

    getParamsCount({ sections }) {
        let total = 0
        for (const section of sections) {
            for (const card of section.cards) {
                if (isBranchedCard(card)) {
                    const first = firstCase(card)
                    if (first && isParamCard(first.card)) {
                        total++
                    }
                } else if (isParamCard(card)) {
                    total++
                }
            }
        }
        return total
    },
    getHasFlows({ rights }) {
        return rights.hasFlows
    },
    getIsBasic({ rights }) {
        return rights.isBasic
    },
    getHasBilling({ rights }) {
        return rights.hasBilling
    },
    getBillingSource({ rights }) {
        return rights.billingSource
    },
    getMaxListItems({ rights }) {
        return rights.maxListItems
    },
    getIsStaff({ rights }) {
        return rights.isStaff
    },
    getIsViewSurveyTemplates({ rights }) {
        return rights.isViewSurveyTemplates
    },
    getIsEditSurveyTemplates({ rights }) {
        return rights.isEditSurveyTemplates
    },
    getActiveTab({ activeTab, sections }): string {
        const section = sections.find(({ name }) => name === activeTab)
        return section ? section.name : 'master'
    },
    getShowUpgrade(state): boolean {
        return state.showUpgrade
    },
    getClickCardType(state): string {
        return state.clickCardType
    },
    getInSubAppDesignTab({ inSubAppDesignTab }): boolean {
        return inSubAppDesignTab
    },
    getQuestionType(state): string {
        return state.questionType
    },
    getAiQuestionIndex({ sections }): CardIndex | null {
        let index!: CardIndex

        if (!sections || sections.length === 0) {
            return null
        }

        sections.forEach((section, sectionIndex) => {
            // allow adding AI question only once.
            section.cards.forEach((card, cardIndex) => {
                if (card.type === 'question') {
                    if ('cases' in card && card.cases) {
                        card.cases.forEach((cardCase) => {
                            if (cardCase.card.aiConversationalMode) {
                                index = {
                                    sectionIndex,
                                    cardIndex,
                                }
                            }
                        })
                    } else if (
                        'aiConversationalMode' in card &&
                        card.aiConversationalMode
                    ) {
                        index = {
                            sectionIndex,
                            cardIndex,
                        }
                    }
                }
            })
        })

        return index
    },
}

function warnUnsaved() {
    if (!window.onbeforeunload && !window.hasOwnProperty('Cypress')) {
        window.onbeforeunload = () => true
    }
}

function dontWarnUnsaved() {
    if (window.onbeforeunload) {
        window.onbeforeunload = null
    }
}

const actions: ActionTree<ConversationSpecState, any> = {
    // @todo error handling

    async loadSpecBlocks({ commit }, template: string) {
        // grammar v1 is first block builder enabled grammar
        const res = await getSpecLatestVersion(template, { grammar: 1 })
        commit('setSections', specToBlockBuilderModel(res.data))
        commit('setLastSave', new Date().valueOf())
        dontWarnUnsaved()
    },

    async loadCustomFieldOptions({ commit, state }, field: string) {
        const existing = state.customFields[field]
        if (existing && !(existing instanceof Array)) {
            const res = await getFieldOptions(field)
            commit('setCustomFieldOptions', { field, options: res.data })
        }
    },

    setSections({ commit }, sections: CardSection[]) {
        commit('setSections', sections)
        warnUnsaved()
    },

    setLastSave({ commit }) {
        commit('setLastSave', new Date().valueOf())
        dontWarnUnsaved()
    },

    setCard({ commit }, data: CardIndex & HasCard) {
        commit('setCard', data)
        warnUnsaved()
    },

    insertCard({ commit }, data: CardIndex & HasCard) {
        commit('insertCard', data)
        warnUnsaved()
    },

    removeCard({ commit }, data: CardIndex) {
        commit('removeCard', data)
        warnUnsaved()
    },

    bumpCard({ commit }, data: CardIndex & HasDirection) {
        commit('bumpCard', data)
        warnUnsaved()
    },

    setBranchedCard({ commit }, data: CardCaseIndex & HasCard) {
        commit('setBranchedCard', data)
        warnUnsaved()
    },

    syncParam({ commit }, data: CardIndex & { param: string }) {
        commit('syncParam', data)
        warnUnsaved()
    },

    insertSection({ commit }, data: CardSectionIndex) {
        commit('insertSection', data)
        warnUnsaved()
    },

    setSection({ commit }, data: CardSectionIndex & HasSection) {
        commit('setSection', data)
        warnUnsaved()
    },

    removeSection({ commit }, data: CardSectionIndex) {
        commit('removeSection', data)
        warnUnsaved()
    },

    createFlow({ commit }, name: string) {
        commit('createFlow', name)
        warnUnsaved()
    },

    activateTab({ commit }, tabName: string) {
        commit('activateTab', tabName)
    },

    setFields({ commit }, fields: Scope) {
        commit('setFields', fields)
    },

    setCustomFields({ commit }, fields: Scope) {
        commit('setCustomFields', fields)
    },

    walkCards({ commit }) {
        commit('setWalkCards')
    },

    setShowUpgrade({ state }, show: boolean) {
        state.showUpgrade = show
    },

    setClickCardType({ state }, type: string) {
        state.clickCardType = type
    },

    setUpgradeConversations({ state }, upgrade: boolean) {
        state.rights.isBasic = !upgrade
        if (upgrade) {
            state.rights.maxListItems = 100
        }
    },

    async reloadScorecardSettings({ commit }) {
        const settings = await getScorecardSettingsList()
        commit('updateScorecardSettings', settings)
    },
}

const mutations: MutationTree<ConversationSpecState> = {
    setLastSave(state, time: number) {
        state.lastSave = time
    },

    updateScorecardSettings(state, settings: IScorecardSetting[]) {
        state.scorecardSettings = settings
    },

    setSections(state, sections: BlockBuilderModel) {
        state.sections = sections
        editedNow(state)
    },

    touchEditedNow(state) {
        editedNow(state)
    },

    setCard(state, { sectionIndex, cardIndex, card }: CardIndex & HasCard) {
        state.sections[sectionIndex].cards[cardIndex] = card
        editedNow(state)
    },

    insertCard(state, { sectionIndex, cardIndex, card }: CardIndex & HasCard) {
        // if there's already a card there, shift all after it down
        state.sections[sectionIndex].cards.splice(cardIndex, 0, card)
        editedNow(state)
    },

    removeCard(state, { sectionIndex, cardIndex }: CardIndex) {
        state.sections[sectionIndex].cards.splice(cardIndex, 1)
        editedNow(state)
    },

    bumpCard(
        state,
        { sectionIndex, cardIndex, direction }: CardIndex & HasDirection
    ) {
        const sectionCards = state.sections[sectionIndex].cards
        const swapIndex = cardIndex + direction
        const mover = sectionCards[cardIndex]
        const swap = sectionCards[swapIndex]

        if (swap) {
            state.sections[sectionIndex].cards = sectionCards.map(
                (card, index) =>
                    index === swapIndex
                        ? mover
                        : index === cardIndex
                          ? swap
                          : card
            )
            editedNow(state)
        }
    },

    setBranchedCard(
        state,
        { sectionIndex, cardIndex, caseIndex, card }: CardCaseIndex & HasCard
    ) {
        const branchedCard = state.sections[sectionIndex].cards[cardIndex]
        if (branchedCard && isBranchedCard(branchedCard)) {
            (branchedCard.cases as CardCases<Card>)[caseIndex]['card'] = card
            editedNow(state)
        }
    },

    syncParam(state, { sectionIndex, cardIndex, param }: CardIndex & HasParam) {
        const branchedCard = state.sections[sectionIndex].cards[cardIndex]
        if (branchedCard && isBranchedCard(branchedCard)) {
            const casesValue = (
                branchedCard.cases as CardCases<OptionsCard>
            ).map(({ key, card }) => ({
                key,
                card: {
                    ...card,
                    param,
                },
            }))
            branchedCard['cases'] = casesValue
            editedNow(state)
        }
    },

    insertSection(state, { sectionIndex }: CardSectionIndex) {
        const name = `group${state.sections.length + 1}`
        state.sections.splice(sectionIndex, 0, blankSection(name))
        editedNow(state)
    },

    setSection(
        state,
        { sectionIndex, section }: CardSectionIndex & HasSection
    ) {
        const oldJumpName = String(state.sections[sectionIndex].name)

        state.sections[sectionIndex] = section

        if (oldJumpName !== section.name) {
            changeJumpInAllCards(state.sections, oldJumpName, section.name)
        }

        editedNow(state)
    },

    removeSection(state, { sectionIndex }: CardSectionIndex) {
        const jumpToRemove = state.sections[sectionIndex].name

        state.sections.splice(sectionIndex, 1)

        removeJumpInAllCards(state.sections, jumpToRemove)

        editedNow(state)
    },

    createFlow(state, name: string) {
        state.sections.push({
            name,
            cards: [
                blankCard('unknown'),
                // new flows are created with one jump back to master/finish at the end
                {
                    type: 'jump',
                    jump: 'finish',
                },
            ],
            flow: true,
        })
        editedNow(state)
    },

    activateTab(state, tabName: string) {
        state.activeTab = tabName
    },

    setRights(state, rights: Rights) {
        state.rights = rights
    },

    setConfigs(state, calendlyConfig: LinkConfig) {
        state.calendly = calendlyConfig
    },

    setFields(state, fields: Scope) {
        state.fields = fields
    },

    setCustomFields(state, fields: Scope) {
        state.customFields = fields
    },
    setWalkCards(state, fields: Scope) {
        const count = 0
        for (const { section, card, cardIndex } of walkBlockBuilderModelLinear(
            state.sections
        )) {
            // if(ge == 'answer_nps' || card.param == 'answer_csat' || card.param == 'answer_ces'){
            //     // ignore
            // }else{
            //     if(isOptionsCard(card))count++
            // }
        }
    },

    setCustomFieldOptions(
        state,
        { field, options }: { field: string; options: string[] }
    ) {
        if (state.customFields[field]) {
            state.customFields[field] = options
        }
    },

    setMaxQuestions(state, maxQuestions: number) {
        state.maxQuestions = maxQuestions
    },

    setInSubAppDesignTab(state, inSubAppDesignTab: boolean) {
        state.inSubAppDesignTab = inSubAppDesignTab
    },

    setQuestionType(state, questionType: string) {
        state.questionType = questionType
    },
}

// ---------- Mutation helpers----------

function editedNow(state: ConversationSpecState) {
    state.lastEdit = new Date().valueOf()
}

function changeJumpInAllCards(
    sections: BlockBuilderModel,
    oldJumpName: string,
    jumpName: string
) {
    for (const { card } of walkBlockBuilderModelLinear(sections)) {
        if (isOptionsCard(card) && card.options && card.options.length) {
            for (const option of card.options) {
                if (option.jump === oldJumpName) {
                    option.jump = jumpName
                }
            }
        } else if (
            !isBranchedCard(card) &&
            card.type === 'jump' &&
            card.jump === oldJumpName
        ) {
            card.jump = jumpName
        }
    }
}

// function walkAllCards(sections: BlockBuilderModel){
//     for (const { section, card, cardIndex } of walkBlockBuilderModelLinear(sections)) {
//         console.log(card.type)
//     }
// }

function getAllParamCards(sections: BlockBuilderModel) {
    const cardList: Card[] = []
    for (const section of sections) {
        for (const card of section.cards) {
            if (isBranchedCard(card)) {
                const first = firstCase(card)
                if (first && isParamCard(first.card)) {
                    cardList.push(first.card)
                }
            } else if (isParamCard(card)) {
                cardList.push(card)
            }
        }
    }
    return cardList
}

function removeJumpInAllCards(sections: BlockBuilderModel, jumpName: string) {
    for (const { section, card, cardIndex } of walkBlockBuilderModelLinear(
        sections
    )) {
        if (isOptionsCard(card) && 'options' in card) {
            // remove from options where found
            for (const option of card.options) {
                if (option.jump === jumpName) {
                    delete option['jump']
                }
            }
        } else if (card.type === 'jump') {
            if (isBranchedCard(card)) {
                // delete match from branches. if branches are then empty, remove whole card
                const nextCases = card.cases.filter(
                    ({ card }) => card.jump !== jumpName
                )
                if (nextCases.length === 0) {
                    section.cards.splice(cardIndex, 1)
                } else {
                    card.cases = nextCases
                }
            } else if (card.jump === jumpName) {
                // delete if matches
                section.cards.splice(cardIndex, 1)
            }
        }
    }
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations,
}
