import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = {
  key: 0,
  class: "loading-wave"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_Typography = _resolveComponent("Typography")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['feedback-actions-bar', { 'show-border': _ctx.actions.length > 0 }])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actions, (action, actionIdx) => {
      return (_openBlock(), _createElementBlock("div", {
        key: actionIdx,
        class: "action-container"
      }, [
        _createElementVNode("div", {
          class: "action",
          onClick: action.onClick
        }, [
          _createVNode(_component_FontAwesomeIcon, {
            icon: action.icon
          }, null, 8, ["icon"]),
          (action.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2))
            : (_openBlock(), _createBlock(_component_Typography, {
                key: 1,
                variant: "overline",
                class: "action-text"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(action.label), 1)
                ], undefined, true),
                _: 2
              }, 1024))
        ], 8, _hoisted_1)
      ]))
    }), 128))
  ], 2))
}