import client from '@/api/client'
import { ILeaderboard } from '@/entities'
import qs from 'qs'

export async function getLeaderboard(
    name: string,
    questionType = '',
    time_unit = '',
    time_value = 0
) {
    let paramsObj = {}
    if (questionType) {
        paramsObj = { ...paramsObj, questionType }
    }
    if (time_unit && time_value) {
        paramsObj = { ...paramsObj, time_unit, time_value }
    }
    const params = qs.stringify(paramsObj)
    return client.get<ILeaderboard>(`/leaderboard/${name}?${params}`)
}

export async function putLeaderboard(leaderboard: ILeaderboard) {
    return client.put(`/leaderboard/${leaderboard.name}`, leaderboard)
}
