<template>
    <div class="row-container" @click="onClick">
        <!-- status = default -->
        <div v-if="caseStatus === 'default'" class="inactive">🔔 Open Case</div>

        <!-- status = closed -->
        <template v-if="caseStatus === 'closed'">
            <div class="closed badge">{{ caseStatus }}</div>
            via
            <div class="bold status-detail">{{ closedVia }}</div>
        </template>

        <!-- status = open -->
        <template v-if="caseStatus === 'open'">
            <div class="open badge">{{ caseStatus }}</div>
            <div class="status-detail" v-html="timeAgo" />
        </template>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { useRouter } from 'vue-router'
import { toggleCaseClosed } from '@/api/chat'
import { timeSince } from '@/utils/time'

@Component({})
export default class CaseStatusRow extends Vue {
    @Prop({ type: Number }) public questionId!: number
    @Prop({ type: Object }) public chatCustomData!: any
    @Prop({ type: String }) public caseStatus!: string

    private router = useRouter()

    private get closedVia(): string {
        return this.caseStatus === 'closed'
            ? this.chatCustomData.case_closed_option
            : ''
    }

    private get timeAgo(): string {
        const timeSinceStr = timeSince(this.chatCustomData.case_opened_time)
        // 23 hours ago => <b class="bold">23</b> hours ago
        return '⏱' + timeSinceStr.replace(/(\d+)/, `<b class="bold">$1</b>`)
    }

    private onClick() {
        // open case when status is `default`
        if (this.caseStatus === 'default') {
            this.openCase()
            return
        }

        // show case operation list when status is `open` or `closed
        this.navToCaseOperationList()
    }

    private async openCase() {
        await toggleCaseClosed(this.questionId, false, null)
        this.$emit('caseOpened')
    }

    private navToCaseOperationList() {
        this.router.push({
            name: 'chatResponsesCase',
            params: { id: this.questionId.toString() },
        })
    }
}
</script>

<style scoped lang="less">
@import '../../../styles/palette.less';
.row-container {
    display: flex;
    font-size: 16px;
    font-family: 'Roboto Black', sans-serif;
    align-items: center;
    color: @black80;

    .badge {
        border-radius: 3px;
        box-shadow: inset 0.5px 0.5px 0 0 rgba(0, 0, 0, 0.2);
        color: white;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.5px;
        text-shadow: 0.5px 0.5px 0 rgba(0, 0, 0, 0.2);
        margin-right: 6px;
        text-transform: uppercase;
        padding: 3px 5px;
        line-height: 14px;
        position: relative;
    }

    .closed {
        background-color: @midGreyBlue;
    }

    .open {
        background-color: @green;
    }

    .status-detail {
        flex: 1;
        margin-left: 6px;
    }

    .bold {
        font-weight: 800;
    }
}
</style>
