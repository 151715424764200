<template>
    <WebViewScreen class="webview-container" no-pad title="More">
        <Loading v-if="loading" :loading="loading" />
        <Scrollable class="container">
            <EngagementBanner />
            <RequestNotificationPermissionPanel />
            <MenuRow
                v-if="showTeamscoreBoard"
                :menu-item="{
                    icon: IconTeamScoreboard,
                    name: 'Team Scoreboard',
                    id: 'teamscoreboard',
                }"
                class="item"
                @click="router.push({ name: 'teamscoreboard' })"
            />
            <MenuRow
                v-if="showCompanyGoals"
                :menu-item="{
                    icon: IconCompanyGoals,
                    name: 'Company Goals',
                    id: 'companygoals',
                }"
                class="item"
                @click="showCompanyGoalsModal = true"
            />
            <MenuRow
                v-if="showNotices"
                :menu-item="{
                    icon: IconNoticesActive,
                    name: 'Notices',
                    id: 'notices',
                }"
                class="item"
                @click="router.push({ name: 'notices' })"
            />
            <MenuRow
                v-if="showSuggestions"
                :menu-item="{
                    icon: IconLightBulb,
                    name: 'Suggestions',
                    id: 'suggestions',
                }"
                class="item"
                @click="router.push({ name: 'suggestions' })"
            />
            <MenuRow
                v-if="showFeedback"
                :menu-item="{
                    icon: IconTabFeedbackActive,
                    name: 'Feedback',
                    id: 'feedback',
                }"
                class="item"
                @click="router.push({ name: 'feedback' })"
            />
            <MenuRow
                :menu-item="{
                    icon: IconLeaderboard,
                    name: 'Leaderboards',
                    id: 'leaderboards',
                }"
                class="item"
                @click="router.push({ name: 'leaderboards' })"
            />
            <MenuRow
                v-if="showSetting"
                :menu-item="{
                    icon: IconCog,
                    name: 'Settings',
                    id: 'settings',
                }"
                class="item"
                @click="router.push({ name: 'settings' })"
            />
            <MenuRow
                :menu-item="{
                    icon: IconBetaSwitcher,
                    name: 'Switch to Beta App',
                    id: 'beta-app',
                }"
                class="item"
                @click="switchToBetaApp"
            />
            <MenuRow
                v-if="canSwitchUser"
                :menu-item="{
                    icon: IconBetaSwitcher,
                    name: 'View as Someone Else',
                    id: 'view-as-someone-else',
                }"
                class="item"
                @click="router.push({ name: 'viewAs' })"
            />
            <MenuRow
                :menu-item="{
                    icon: IconLogout,
                    name: 'Logout',
                }"
                class="item"
                @click="logout"
            />
            <div class="footer">
                <div class="version">
                    <div>{{ domain }}</div>
                    <div>{{ decodeUserName }} | {{ userId }}</div>
                    <div>v{{ version }}</div>
                    <div v-if="currentlyCaching" class="cache-info">
                        <div>
                            <small>Offline data is currently caching...</small>
                        </div>
                    </div>
                    <div v-else-if="lastDataCached > 0" class="cache-info">
                        <div>
                            <small>Offline data cached: {{ fLastDataCached }}
                            </small>
                        </div>
                        <div>
                            <small>Time used caching: {{ lastTimeSpent }}</small>
                        </div>
                        <div>
                            <small>Cached size: {{ lastDataSize }}</small>
                        </div>
                    </div>
                </div>
            </div>
        </Scrollable>
        <CompanyGoalsModal
            v-if="showCompanyGoalsModal"
            :show="showCompanyGoalsModal"
            @close="showCompanyGoalsModal = false"
        >
        </CompanyGoalsModal>
    </WebViewScreen>
</template>

<script lang="ts">
import { Component, Watch, Vue } from 'vue-facing-decorator'
import BaseButton from '@/components/BaseButton.vue'
import { Action, Getter } from 'vuex-facing-decorator'
import { useRouter } from 'vue-router'
import WebViewScreen from '@/components/WebView/WebViewScreen.vue'
import { getAppVersion, openBrowser } from '@/mobile/src/utils/capacitor'
import IconLightBulb from '@/assets/img/Icons/light-bulb-purple.svg'
import IconTabFeedbackActive from '@/assets/img/Icons/tab-feedback-active.svg'
import IconNoticesActive from '@/assets/img/Icons/tab-notices-active.svg'
import IconCog from '@/assets/img/Icons/cog-purple.svg'
import IconLeaderboard from '@/assets/img/Icons/leaderboard.svg'
import IconLogout from '@/assets/img/Icons/Purple/logout.svg'
import IconTeamScoreboard from '@/assets/img/Icons/teamscoreboard.svg'
import IconCompanyGoals from '@/assets/img/Icons/company-goals.svg'
import IconBetaSwitcher from '@/assets/img/Icons/Purple/ArrowLeftRight.svg'
import MenuRow from '@/mobile/src/components/MenuRow.vue'
import { UserRoleType } from '@/mobile/src/types/auth'
import RequestNotificationPermissionPanel from '@/mobile/src/components/RequestNotificationPermissionPanel.vue'
import Scrollable from '@/components/Scrollable.vue'
import { decode as entityDecode } from 'he'
import EngagementBanner from '@/mobile/src/components/engagement/EngagementBanner.vue'
import CompanyGoalsModal from '@/mobile/src/components/CompanyGoalsModal.vue'
import { toggleBetaAppAccess } from '@/mobile/src/utils/betaFeatures'
import Loading from '@/components/Loading.vue'
import { unRegisterPushDevice } from '@/mobile/src/api/auth'

@Component({
    components: {
        Loading,
        EngagementBanner,
        Scrollable,
        RequestNotificationPermissionPanel,
        MenuRow,
        WebViewScreen,
        BaseButton,
        CompanyGoalsModal,
    },
})
export default class More extends Vue {
    @Getter permissions
    @Getter userRoleType
    @Getter userHasMlp
    @Getter domain
    @Getter userName
    @Getter userId
    @Getter lastDataCached!: number
    @Getter lastTimeSpent!: number
    @Getter lastDataSize!: number
    @Getter currentlyCaching!: boolean
    @Getter $companyVars
    @Getter isImpersonating
    @Getter pushNotificationToken

    @Action dispatchStoreLogout

    public version = ''
    public loading = false

    private IconTabFeedbackActive = IconTabFeedbackActive
    private IconLightBulb = IconLightBulb
    private IconCog = IconCog
    private IconLeaderboard = IconLeaderboard
    private IconLogout = IconLogout
    private IconTeamScoreboard = IconTeamScoreboard
    private IconCompanyGoals = IconCompanyGoals
    private IconNoticesActive = IconNoticesActive
    private IconBetaSwitcher = IconBetaSwitcher

    private showCompanyGoalsModal = false

    private router = useRouter()

    private async mounted() {
        this.version = await getAppVersion()
    }

    @Watch('userHasMlp', { immediate: true })
    private swapToEvenMore() {
        if (this.userHasMlp) {
            this.router.push({ name: 'evenmore' })
        }
    }

    private get showTeamscoreBoard() {
        return (
            this.$companyVars.has_scorecard &&
            this.permissions.includes('ROLE_VIEW_TEAM_SCOREBOARD')
        )
    }

    private get decodeUserName() {
        return entityDecode(this.userName)
    }

    private get showCompanyGoals() {
        return this.userHasMlp && this.permissions.includes('ROLE_ADMIN_BASICS')
    }

    private get showFeedback() {
        return (
            this.userHasMlp ||
            (this.$companyVars.has_scorecard &&
                this.permissions.includes('ROLE_VIEW_PERSONAL_SCORECARD'))
        )
    }

    private get showSetting() {
        return (
            !this.$companyVars.has_smart_notifications ||
            this.userRoleType !== UserRoleType.FrontlineStaff
        )
    }

    private get showNotices() {
        return (
            this.userHasMlp &&
            this.$companyVars.has_notices &&
            this.permissions.includes('ROLE_VIEW_NOTICES')
        )
    }

    private get showSuggestions() {
        return (
            this.userHasMlp &&
            this.permissions.includes('ROLE_VIEW_CREATE_SUGGESTIONS')
        )
    }

    public get canSwitchUser() {
        return (
            this.isImpersonating ||
            this.permissions.includes('ROLE_STAFF') ||
            this.permissions.includes('ROLE_ADMIN_BASICS')
        )
    }

    public switchToBetaApp() {
        this.loading = true
        toggleBetaAppAccess(!this.userHasMlp)
    }

    async logout() {
        this.loading = true
        await unRegisterPushDevice(this.pushNotificationToken)
        await this.dispatchStoreLogout()
        this.loading = false
    }

    get fLastDataCached() {
        return new Date(this.lastDataCached * 1000).toLocaleString()
    }
}
</script>

<style lang="less" scoped>
@import '../../../../styles/palette.less';

.container {
    background: @greyLight;
    flex: 1;

    .item {
        margin: 12px;
    }

    .footer {
        text-align: center;

        .version {
            font-weight: 500;
            font-size: 12px;
            letter-spacing: 1px;
            color: @grey40;
            padding: 5px 0 15px;
        }
    }
}

.cache-info {
    padding-top: 8px;
}
</style>
