<template>
    <transition name="fade">
        <div v-if="typePickerModalOpen" class="type-picker-modal">
            <FontAwesomeIcon
                class="close-icon"
                :icon="closeIcon"
                @click="closeTypePickerModal"
            />

            <div class="message-type-list">
                <div
                    v-for="type in messageTypes"
                    :key="type.type"
                    class="type"
                    @click="switchMessageType(type)"
                >
                    <div class="type-header">
                        <FontAwesomeIcon
                            class="type-label-icon"
                            :icon="circleRightIcon"
                            :style="typeColourStyles(type)"
                        />
                        <Typography
                            variant="tabs"
                            :style="typeColourStyles(type)"
                        >
                            {{ type.label }}
                        </Typography>
                    </div>
                    <Typography variant="body2" :style="typeColourStyles(type)">
                        {{ type.description }}
                    </Typography>
                </div>
            </div>
        </div>
    </transition>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { Getter, namespace } from 'vuex-facing-decorator'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faCircleArrowRight, faXmark } from 'fontawesome/free-solid-svg-icons'
import Typography from '@/components/Rain/Typography/Typography.vue'
import { MessageType, messageTypes } from '@/mobile/src/types/messageTypes'

const MomentsModule = namespace('moments')

@Component({
    components: {
        FontAwesomeIcon,
        Typography,
    },
})
export default class MessageTypePickerModal extends Vue {
    @Getter chatMessages
    @MomentsModule.Getter typePickerModalOpen
    @MomentsModule.Getter composerType
    @MomentsModule.Getter availableComposerTypes
    @MomentsModule.Action setComposerType
    @MomentsModule.Action setTypePickerModalOpen
    @MomentsModule.Action setSelectedRecipients
    @MomentsModule.Action setShowToField
    @MomentsModule.Action prefillEmailReplyRecipient

    // Icons
    public circleRightIcon = faCircleArrowRight
    public closeIcon = faXmark

    public messageTypes = messageTypes

    public typeColourStyles(type: MessageType) {
        const styles = {}

        // Grey out the message type if unavailable
        if (!this.isTypeAvailable(type)) {
            styles['opacity'] = '0.3'
        }

        styles['color'] = type.colour

        return styles
    }

    public switchMessageType(type: MessageType) {
        if (this.isTypeAvailable(type)) {
            // Reset recipients if we switched from emailReply or internalNote to another type
            if (
                this.composerType === 'emailReply' ||
                this.composerType === 'internalNote'
            ) {
                this.setSelectedRecipients([])
                this.setShowToField(true)
            }

            // Prefill customer as recipient for email reply
            if (type.type === 'emailReply') {
                this.prefillEmailReplyRecipient()
            } else if (type.type === 'internalNote') {
                // Hide To field as there is no recipient for internal note
                this.setShowToField(false)
                this.setSelectedRecipients([])
            }

            this.setComposerType(type.type)
            this.closeTypePickerModal()
        }
    }

    public closeTypePickerModal() {
        this.setTypePickerModalOpen({ isOpen: false })
    }

    public isTypeAvailable(type: MessageType) {
        if (!this.availableComposerTypes.length) {
            return true
        }

        return this.availableComposerTypes.includes(type.type)
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';
@import '~@/mobile/src/styles/animations.less';
@import '~@/mobile/src/styles/zindex.less';
@import '~@/mobile/src/styles/message-composer.less';

.fade-transition-animation(0.2s);

.type-picker-modal {
    .modal-container();

    .message-type-list {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: @featureGutter;

        .type-header {
            display: flex;
            align-items: center;
            padding-bottom: @gutterSpacing-base;

            .type-label-icon {
                @size: 12px;
                height: @size;
                width: @size;
                margin-right: 5px;
            }
        }
    }
}
</style>
