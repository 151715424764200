<template>
    <WebViewScreen v-if="topicData" no-pad disable-when-offline show-go-back>
        <template #custom-title>
            <TwoLineHeader
                :title="`Coaching - ${topic}`"
                :subtitle="friendlyTimeRange"
            />
        </template>
        <template #right-button>
            <InsightsTimePickerMobile
                :time-range="timeRange"
                @selectTime="updateTimeRange"
            />
        </template>
        <Scrollable>
            <template v-if="insightsCoaching">
                <div class="coaching-topic-animation-container">
                    <Lottie
                        v-if="animationData"
                        class-name="bg-img-container"
                        :animation-data="animationData"
                    />
                </div>
                <div class="coaching-topic-container">
                    <TopicCard
                        :question-type="insightsCoaching.question_type"
                        :data="topicData"
                        :total-topic-response-count="totalTopicResponseCount"
                    />
                </div>
                <div class="coaching-topic-team-member-container">
                    <div
                        class="coaching-topic-team-member-sort"
                        @click="showTeamSortModal = true"
                    >
                        <span>Team</span>
                        <span
                            class="coaching-topic-team-member-selected-option"
                            >{{ selectedSortOption }}</span>
                        <FontAwesomeIcon :icon="faChevronDown" />
                    </div>
                    <div class="slider">
                        <div class="slider-container">
                            <TeamMemberTileMobile
                                v-for="(teamMemberData, name) in teamMembers"
                                :key="name"
                                :name="name"
                                :data="teamMemberData"
                                :topic="topic"
                                :badge="topicData.badge"
                                :width="150"
                                :height="186"
                                class="coaching-topic-team-member-tile"
                            />
                        </div>
                    </div>
                </div>
                <div class="coaching-topic-feedback-container">
                    <InsightsCoachingTopicReviews
                        :title="getCoachingResponseTitle(true)"
                        :responses="getCoachingResponses(true, 2)"
                        :show-more-router-link="{
                            name: 'InsightsCoachingTopicFeedback',
                            params: { topic: topic, feedbackType: 'Positive' },
                        }"
                    />
                </div>
                <div class="coaching-topic-feedback-container">
                    <InsightsCoachingTopicReviews
                        :title="getCoachingResponseTitle(false)"
                        :responses="getCoachingResponses(false, 2)"
                        :show-more-router-link="{
                            name: 'InsightsCoachingTopicFeedback',
                            params: { topic: topic, feedbackType: 'Negative' },
                        }"
                    />
                </div>
            </template>
            <template v-else>
                <div class="loading">
                    <SimpleSpinner
                        size="small"
                        class="spinner"
                        line-fg-color="#B338A3"
                    ></SimpleSpinner>
                </div>
            </template>
        </Scrollable>

        <div class="coaching-topic-sort-modal">
            <Modal
                :top="25"
                :show="showTeamSortModal"
                allow-click-out
                allow-escape
                :has-close="false"
                title="View Results By:"
                size="medium"
                :close="() => (showTeamSortModal = false)"
                visible-if-overflow
            >
                <div
                    v-for="(sortOption, sortOptionIdx) in sortOptions"
                    :key="sortOptionIdx"
                >
                    <div
                        class="coaching-topic-sort-option"
                        @click="selectSortOption(sortOption)"
                    >
                        <div
                            :class="{
                                active: sortOption === selectedSortOption,
                            }"
                        >
                            {{ sortOption }}
                        </div>
                        <div>
                            <FontAwesomeIcon
                                v-if="sortOption === selectedSortOption"
                                :icon="faCheck"
                                class="active"
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    </WebViewScreen>
</template>

<script lang="ts">
import Scrollable from '@/components/Scrollable.vue'
import WebViewScreen from '@/components/WebView/WebViewScreen.vue'
import {
    CoachingInsightItem,
    CoachingInsights,
    ITimeRange,
} from '@/entities/insights'
import InsightsTimePickerMobile from '@/mobile/src/components/Insights/InsightsTimePickerMobile.vue'
import { Component, Prop, Watch, Vue } from 'vue-facing-decorator'
import { Action, Getter } from 'vuex-facing-decorator'
import { useRouter } from 'vue-router'
import { Vue3Lottie as Lottie } from 'vue3-lottie'
import InsightsCoachingTiles from '@/mobile/src/components/Insights/InsightsCoachingTiles.vue'
import InsightsCoachingList from '@/mobile/src/components/Insights/InsightsCoachingList.vue'
import TopicCard from '@/mobile/src/components/Insights/TopicCard.vue'
import BaseCard from '@/components/BaseCard.vue'
import BackButton from '@/mobile/src/components/BackButton.vue'
import TeamMemberTileMobile from '@/mobile/src/components/Insights/TeamMemberTileMobile.vue'
import Modal from '@/components/Modal.vue'
import { getResponses } from '@/api/responses'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faCheck, faChevronDown } from 'fontawesome/free-solid-svg-icons'
import InsightsCoachingTopicReviews from '@/mobile/src/components/Insights/InsightsCoachingTopicReviews.vue'
import { isFiveScoreQuestionType } from '@/utils/globalVariables'
import { OFFLINE_RESPONSE_COUNT } from '@/store/modules/scorecard'
import { Dict, IResponse } from '@/entities'
import SimpleSpinner from '@/components/SimpleSpinner.vue'
import TwoLineHeader from '@/mobile/src/components/TwoLineHeader.vue'

@Component({
    components: {
        InsightsCoachingTopicReviews,
        Modal,
        TeamMemberTileMobile,
        BackButton,
        BaseCard,
        TopicCard,
        InsightsCoachingList,
        InsightsCoachingTiles,
        InsightsTimePickerMobile,
        Scrollable,
        WebViewScreen,
        Lottie,
        FontAwesomeIcon,
        SimpleSpinner,
        TwoLineHeader,
    },
})
export default class CoachingTopic extends Vue {
    private topicData: CoachingInsightItem | null = null
    private totalTopicResponseCount: number | null = null
    private animationData = null
    private showTeamSortModal = false
    private sortOptions = ['High Performers', 'Low Performers']
    private selectedSortOption: 'High Performers' | 'Low Performers' =
        'High Performers'
    private positiveResponses: {} | null = null
    private negativeAndNeutralResponses: {} | null = null

    readonly faCheck = faCheck
    readonly faChevronDown = faChevronDown

    @Prop({ type: String, default: null })
    private topic!: string

    @Getter
    readonly insightsCoaching!: CoachingInsights

    @Getter
    readonly friendlyTimeRange!: string

    @Getter
    readonly timeRange!: ITimeRange

    @Getter
    public responsesMap!: Dict<IResponse[]>

    @Getter
    hasNetworkConnection!: boolean

    @Action
    loadInsightsCoaching

    @Action
    updateTimeRange

    @Action
    private setResponses

    private router = useRouter()

    @Watch('insightsCoaching')
    public async onInsightsCoachingChange() {
        if (this.insightsCoaching !== undefined) {
            await this.setTopicData()
            await this.loadFeedbackResponses(true)
            await this.loadFeedbackResponses(false)
        }
    }

    async mounted() {
        if (this.insightsCoaching === undefined) {
            await this.loadInsightsCoaching()
            await this.setTopicData()
        } else {
            await this.setTopicData()
            if (!this.responsesMap[this.getResponseKey(true)]) {
                await this.loadFeedbackResponses(true)
            }
            if (!this.responsesMap[this.getResponseKey(false)]) {
                await this.loadFeedbackResponses(false)
            }
        }
    }

    public async setTopicData() {
        if (
            !this.insightsCoaching.data.scorecard ||
            this.insightsCoaching.data.scorecard[this.topic] === undefined
        ) {
            await this.router.push({
                name: 'InsightsCoachingMetrics',
                params: { coachingMetricType: 'trending_topic' },
            })
            return
        }

        this.topicData = this.insightsCoaching.data.scorecard[this.topic]
        this.animationData = (
            await import(
                `@/assets/img/scorecard/animated/${this.topicData.badge}.json`
            )
        ).default

        let count = 0
        for (const coachingMetricDataKey in this.insightsCoaching.data
            .scorecard) {
            count +=
                this.insightsCoaching.data.scorecard[coachingMetricDataKey]
                    .total_count
        }
        this.totalTopicResponseCount = count
    }

    public selectSortOption(option) {
        this.selectedSortOption = option
        this.showTeamSortModal = false
    }

    public async getAllResponseTypes() {
        this.positiveResponses = await this.getResponse(true)
        this.negativeAndNeutralResponses = await this.getResponse(false)
    }

    private getResponseKey(positive) {
        return JSON.stringify(
            'InsightsCoachingTopicFeedback' +
                '-' +
                this.topic +
                '-' +
                (positive ? 'Positive' : 'Negative') +
                '-' +
                this.timeRange.timeUnit +
                '-' +
                (this.timeRange.timeValue !== undefined
                    ? this.timeRange.timeValue.toString()
                    : '1')
        )
    }

    private async loadFeedbackResponses(positive) {
        // we already have cached these responses so no need to re query
        if (this.responsesMap[this.getResponseKey(positive)]) {
            return
        }

        let breakpoint = positive ? '8' : '9'
        if (isFiveScoreQuestionType(this.insightsCoaching.question_type)) {
            breakpoint = positive ? '3' : '4'
        }

        const { data } = await getResponses(
            {
                filter_rules: [
                    {
                        column: 'question_type',
                        operator: 'in',
                        value: [this.insightsCoaching.question_type],
                    },
                    { column: 'comment', operator: 'isnotempty', value: [''] },
                    {
                        column: 'answer',
                        operator: positive ? 'gt' : 'lt',
                        value: [breakpoint],
                    },
                    {
                        column: this.insightsCoaching.scorecard_field,
                        operator: 'in',
                        value: [this.topic],
                    },
                ],
                time_unit: this.timeRange.timeUnit,
                time_value:
                    this.timeRange.timeValue !== undefined
                        ? this.timeRange.timeValue.toString()
                        : '1',
            },
            {
                pagesize: this.hasNetworkConnection
                    ? 10
                    : OFFLINE_RESPONSE_COUNT,
                offset: 0,
            }
        )

        this.setResponses({ key: this.getResponseKey(positive), data })
    }

    public async getResponse(positive) {
        let breakpoint = positive ? '8' : '9'
        if (isFiveScoreQuestionType(this.insightsCoaching.question_type)) {
            breakpoint = positive ? '3' : '4'
        }

        const { data } = await getResponses(
            {
                filter_rules: [
                    {
                        column: 'question_type',
                        operator: 'in',
                        value: [this.insightsCoaching.question_type],
                    },
                    { column: 'comment', operator: 'isnotempty', value: [''] },
                    {
                        column: 'answer',
                        operator: positive ? 'gt' : 'lt',
                        value: [breakpoint],
                    },
                    {
                        column: this.insightsCoaching.scorecard_field,
                        operator: 'in',
                        value: [this.topic],
                    },
                ],
                time_unit: this.timeRange.timeUnit,
                time_value:
                    this.timeRange.timeValue !== undefined
                        ? this.timeRange.timeValue.toString()
                        : '1',
            },
            {
                pagesize: 2,
                offset: 0,
            }
        )
        return data
    }

    getCoachingResponses(positive, count = 2) {
        return this.responsesMap[this.getResponseKey(positive)]
            ? this.responsesMap[this.getResponseKey(positive)].slice(0, count)
            : null
    }

    getCoachingResponseTitle(positive) {
        if (positive) {
            return isFiveScoreQuestionType(this.insightsCoaching.question_type)
                ? 'Satisfied'
                : 'Promoters'
        } else {
            return isFiveScoreQuestionType(this.insightsCoaching.question_type)
                ? 'Unsatisfied & Neutral'
                : 'Detractors & Passives'
        }
    }

    get teamMembers() {
        let teamMembers = {}
        const sortedTeamMemberData = {}

        if (this.topicData) {
            for (const frontLineStaffColumnsKey in this.insightsCoaching
                .front_line_staff_columns) {
                const frontLineStaffColumn =
                    this.insightsCoaching.front_line_staff_columns[
                        frontLineStaffColumnsKey
                    ]
                teamMembers = {
                    ...teamMembers,
                    ...this.topicData[frontLineStaffColumn],
                }
            }

            // sort the data
            const teamMembersSortedKeys = Object.keys(teamMembers).sort(
                (a, b) => {
                    if (this.selectedSortOption === 'High Performers') {
                        return (
                            teamMembers[b]['score_current'] -
                            teamMembers[a]['score_current']
                        )
                    } else if (this.selectedSortOption === 'Low Performers') {
                        return (
                            teamMembers[a]['score_current'] -
                            teamMembers[b]['score_current']
                        )
                    } else {
                        return 1
                    }
                }
            )

            teamMembersSortedKeys.forEach((field) => {
                sortedTeamMemberData[field] = teamMembers[field]
            })
        }

        return sortedTeamMemberData
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/typography';
@import '~@/styles/variables';
@import '~@/styles/button';

.coaching-topic-container {
    padding: 8px;
}

.coaching-topic-team-member-container {
    .coaching-topic-team-member-sort {
        margin: 0 8px;
        .title();

        .coaching-topic-team-member-selected-option,
        svg {
            color: @blue;
            cursor: pointer;
        }

        svg {
            margin-left: 4px;
        }
    }

    .coaching-topic-team-member-tile {
        margin: 8px;
        cursor: pointer;
    }
}

.coaching-topic-feedback-container {
    margin: 8px;
}

.coaching-topic-sort-option {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    .active {
        color: @blue;
    }
}

.slider {
    /* line them up horizontally */
    display: flex;
    /* allow for scrolling */
    overflow-x: auto;
    /* make it smooth on iOS */
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none; // IE 10+
    scrollbar-width: none; // Firefox

    &::-webkit-scrollbar {
        display: none;
    }

    .slide {
        scroll-snap-align: start;
    }
    .slider-container {
        display: flex;

        .slide {
            /* make sure the width is honored */
            flex-shrink: 0;
        }
    }
}
</style>

<style lang="less">
@import '~@/styles/variables';

.scroll-container {
    background: @neutralBg;
}
</style>
