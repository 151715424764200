<template>
    <!-- container -->
    <div class="input-container" @focusin="onFocus" @focusout="onFocusOut">
        <!-- title|error msg| -->
        <div
            v-if="hasFocused && hasTitle"
            class="text-title"
            :class="titleClass"
        >
            {{ titleText }}
        </div>
        <input
            ref="input"
            class="input"
            :class="inputClass"
            :disabled="disabled"
            :type="type"
            :value="modelValue"
            :placeholder="placeholder"
            autocapitalize="off"
            :autocomplete="autocomplete"
            :inputmode="inputmode"
            @focus="$emit('focus', $event)"
            @blur="$emit('blur', $event)"
            @input="$emit('update:modelValue', $event.target.value)"
        />
        <span v-if="required" class="asterisk" :class="asteriskClass">
            <i :class="asteriskIconClass"></i>
        </span>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Input',
    props: {
        modelValue: {
            type: String,
            default: '', // value of input
        },

        title: {
            type: String,
            default: '',
        },

        hasTitle: {
            type: Boolean,
            default: true,
        },

        placeholder: {
            type: String,
            default: '',
        },

        type: {
            type: String,
            default: 'text', // type of input
        },

        errMsg: {
            type: String,
            default: '', // error msg when validate failed
        },

        required: {
            type: Boolean,
            default: false, // if field required
        },

        regex: {
            type: RegExp,
            default: () => new RegExp('(.*?)'), // value should match regex
        },

        validateOnInit: {
            type: Boolean,
            default: false,
        },

        equal: {
            type: String,
            default: '', // value should equal to, otherwise has error
        },

        disabled: {
            type: Boolean,
            default: false, // if disabled
        },

        focused: {
            type: Boolean,
            default: false, // if set to focused by default
        },

        resetBit: {
            type: Boolean,
            default: false,
        },

        autocomplete: {
            type: String,
            default: undefined,
        },

        inputmode: {
            type: String,
            default: undefined,
        },
    },
    emits: ['focus', 'blur', 'update:modelValue', 'has-error', 'focused'],
    data() {
        return {
            hasFocused: this.validateOnInit || !!this.modelValue, // if has focused once
            focusing: false, // if currently focusing
        }
    },

    computed: {
        hasError(): boolean | '' {
            if (this.modelValue) {
                let hasError = false
                if (this.regex) {
                    hasError =
                        hasError ||
                        !(
                            this.modelValue &&
                            this.modelValue.match &&
                            this.modelValue.match(this.regex)
                        )
                }
                if (this.equal) {
                    hasError =
                        hasError ||
                        !(
                            this.equal &&
                            this.modelValue &&
                            this.modelValue === this.equal
                        )
                }
                return hasError
            }
            return this.required
        },

        // input class object
        inputClass(): object {
            return {
                'position-focused': this.hasFocused && this.hasTitle,
                'border-focus': this.focusing, // when focusing, show blue border, even error
                // after focused, if error, show error border
                'border-error':
                    !this.focusing && this.hasFocused && this.hasError,
                'has-no-title': !this.hasTitle,
            }
        },

        // input title class object
        titleClass(): object {
            return {
                'text-danger':
                    !this.focusing && this.hasFocused && this.hasError,
            }
        },

        titleText(): string {
            return !this.focusing && this.hasError ? this.errMsg : this.title
        },

        asteriskClass(): object {
            return {
                'text-danger':
                    !this.focusing && this.hasFocused && this.hasError,
                'asterisk-success':
                    !this.focusing && this.hasFocused && !this.hasError,
            }
        },

        asteriskIconClass(): object {
            if (!this.focusing && this.hasFocused && !this.hasError) {
                return { 'uk-icon-check': true }
            } else if (this.required) {
                return { 'uk-icon-asterisk': true }
            } else {
                return {}
            }
        },
    },
    watch: {
        hasError: function (hasError) {
            this.$emit('has-error', hasError)
        },

        resetBit: function (resetBit) {
            this.hasFocused = false
        },
    },
    methods: {
        onFocusOut() {
            this.focusing = false
        },

        onFocus() {
            this.focusing = true
            this.hasFocused = true
            this.$emit('focused', true)
        },

        focus() {
            (this.$refs.input as any).focus()
        },
    },
})
</script>

<style scoped lang="less">
@import '../styles/palette';

.input-container {
    position: relative;
    margin-top: 15px;
    transition: all 0.2s ease-in-out;
    box-sizing: initial;

    .input {
        height: 48px;
        width: calc(100% - 32px);
        border: 1px solid @borderGrey;
        border-radius: 3px;
        padding-left: 16px;
        padding-right: 16px;
        font-size: 16px;
        outline: none;
        color: #6e7682;

        &.has-no-title {
            height: 34px;
        }
    }

    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #9399a2;
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #9399a2;
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #9399a2;
    }

    /* show title about placeholder once focused */
    .position-focused {
        height: 30px;
        padding-top: 18px;
        transition: all 0.2s ease-in-out;
    }

    .border-error {
        border: 1px solid #f44d3d;
    }

    .border-focus {
        border: 1px solid #007eff;
    }

    .text-title {
        color: #2b3a45;
        font-size: 10px;
        font-weight: 500;
        line-height: 12px;
        position: absolute;
        top: 8px;
        left: 16px;
        transition: all 0.2s ease-in-out;
    }

    .asterisk {
        position: absolute;
        top: 35%;
        right: 15px;
        font-size: 10px;
        color: @blue;
        transition: all 0.2s ease-in-out;
    }

    .asterisk-success {
        color: #20e694;
    }

    input[disabled] {
        background-color: #fafafa;
    }

    .text-danger {
        color: @red;
    }
}
</style>
