<template>
    <p v-if="isRankDisabled">ranking has been disabled</p>
    <ScorecardSubpage
        v-else
        slide="left"
        sticky
        :show-header="!embedded"
        :title="title"
    >
        <div v-if="scorecard" class="ranks-container">
            <div class="title">{{ rankListTitle }}</div>
            <div class="results">
                <ScorecardRankRow
                    v-for="(rank, index) in visibleRankRows"
                    :key="index"
                    :rank="rank"
                    :rank-num="index + 1"
                    :active="scorecard.names.indexOf(rank.name) >= 0"
                    :min-value="minValue"
                    :max-value="maxValue"
                    :company-nps="Number(companyNps)"
                    :is-five-score-question-type="
                        scorecard.isFiveScoreQuestionType
                    "
                    :is-last="index + 1 === scorecard.ranks.length"
                    :avatar="getAvatar(rank)"
                />
            </div>
        </div>
        <Loading :loading="loading" />
    </ScorecardSubpage>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import Loading from '@/components/Loading.vue'
import ScorecardSubpage from '@/pages/scorecard/components/ScorecardSubpage.vue'
import { Action, Getter } from 'vuex-facing-decorator'
import { ILeaderboardResult, INPSSummary } from '@/entities'
import { ScorecardEntity } from '@/entities/scorecard'
import ScorecardRankRow from '@/components/Scorecard/ScorecardRankRow.vue'
import { embedded } from '@/utils/react-native-webview'

@Component({
    components: { ScorecardRankRow, ScorecardSubpage, Loading },
})
export default class ScorecardRank extends Vue {
    @Getter public slide!: string
    @Getter public scorecard!: ScorecardEntity
    @Getter public companyNpsSummary!: INPSSummary
    @Getter public scorecardOutdated!: boolean
    @Getter public scorecardLoading!: boolean

    @Action public loadScorecard
    @Action public loadCompanyNpsSummary

    @Prop({ type: String, default: null })
    public teamMemberUserId!: string // view another users rank

    public get loading() {
        return this.scorecardLoading
    }

    public get isRankDisabled() {
        return this.scorecard && !this.scorecard.rankEnabled
    }

    public mounted() {
        this.load()
    }

    public async load() {
        await this.loadCompanyNpsSummary(this.teamMemberUserId ?? null)

        if (this.scorecardOutdated) {
            await this.loadScorecard(this.teamMemberUserId ?? null)
        }
    }

    public get title() {
        if (!this.scorecard) {
            return 'loading...'
        }

        const { rankValues, rankFieldLabel } = this.scorecard
        const title = this.scorecard.rankValues
            .filter((rank) => rank > 0)
            .join(', ')
        return `<div style="margin-bottom: -5px;">
                        Rank ${title} <br>
                        <span style="font-size: 12px;">in ${rankFieldLabel}</span>
                    </div>`
    }

    public get rankListTitle() {
        return this.scorecard.rankLimitNumber
            ? `Top ${this.scorecard.rankLimitNumber} Performers`
            : 'Team Rankings'
    }

    public get visibleRankRows() {
        if (this.scorecard.rankLimitNumber) {
            return this.scorecard.ranks.slice(0, this.scorecard.rankLimitNumber)
        }
        return this.scorecard.ranks
    }

    public getAvatar(row: ILeaderboardResult) {
        if (this.scorecard.avatars[row.name] === undefined) {
            return ``
        }

        return this.scorecard.avatars[row.name]
    }

    public get minValue() {
        return Math.min(
            ...this.scorecard.ranks
                .filter((rank) => rank.responded >= 5)
                .map((rank) => rank.nps)
        )
    }

    public get maxValue() {
        return Math.max(
            ...this.scorecard.ranks
                .filter((rank) => rank.responded >= 5)
                .map((rank) => rank.nps)
        )
    }

    public get companyNps() {
        return Number(this.companyNpsSummary.nps).toFixed(1)
    }

    public get embedded() {
        return embedded
    }
}
</script>

<style scoped lang="less">
@import '../../styles/layout.less';
@import '../../styles/palette.less';

.ranks-container {
    background: #ffffff;
    border: 1px solid #e0e7ff;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 16px;
    box-shadow: 0 2px 6px rgba(112, 125, 134, 0.14);
    padding: 16px;

    .title {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 11px;
        line-height: 20px;
        letter-spacing: 0.5px;
        color: #93a1be;
        margin-bottom: 6px;
    }
}
</style>
