import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b04a6fc8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "container",
  class: "suggestion-agreement"
}
const _hoisted_2 = {
  key: 0,
  class: "user-first-agree"
}
const _hoisted_3 = {
  key: 1,
  class: "user-bubbles"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AvatarOrInitials = _resolveComponent("AvatarOrInitials")!
  const _component_ActionLabel = _resolveComponent("ActionLabel")!
  const _component_Button = _resolveComponent("Button")!
  const _component_SuggestionAgreedUsersModal = _resolveComponent("SuggestionAgreedUsersModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.usersAgreed.length === 0 && !_ctx.action)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, " Be the first one to agree! "))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.firstAgreed, (user, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: user.id,
              class: "user-bubble",
              style: _normalizeStyle({
                    background: _ctx.colors[_ctx.suggestion.user_id % _ctx.colors.length],
                    left: index * 15 + 'px',
                }),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showAgreedUsers = true))
            }, [
              _createVNode(_component_AvatarOrInitials, {
                user: user,
                size: 21
              }, null, 8, ["user"])
            ], 4))
          }), 128)),
          (_ctx.othersAgreed > 0)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "user-bubble",
                style: {"left":"45px"},
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showAgreedUsers = false))
              }, " +" + _toDisplayString(_ctx.othersAgreed), 1))
            : _createCommentVNode("", true)
        ])),
    (_ctx.action)
      ? (_openBlock(), _createBlock(_component_ActionLabel, {
          key: 2,
          action: _ctx.action
        }, null, 8, ["action"]))
      : (_ctx.hasAgreed)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 3,
            variant: "primary",
            onClick: _ctx.removeAgreed
          }, {
            default: _withCtx(() => [
              _createTextVNode("Agreed")
            ], undefined, true),
            _: 1
          }, 8, ["onClick"]))
        : (_openBlock(), _createBlock(_component_Button, {
            key: 4,
            variant: "secondary",
            onClick: _ctx.agree
          }, {
            default: _withCtx(() => [
              _createTextVNode("Agree")
            ], undefined, true),
            _: 1
          }, 8, ["onClick"])),
    _withDirectives(_createVNode(_component_SuggestionAgreedUsersModal, {
      "users-agreed": _ctx.usersAgreed,
      onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showAgreedUsers = false))
    }, null, 8, ["users-agreed"]), [
      [_vShow, _ctx.showAgreedUsers]
    ])
  ], 512))
}