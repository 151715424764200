<template>
    <div
        :class="['row-wrapper', { gutter: hasLeftGutter, mobile: !!$isMobile }]"
    >
        <div
            v-for="row in rows"
            :key="row.id"
            :class="['row', { noDivider: !hasLastDivider }]"
            @click="clickRow(row)"
        >
            <img v-if="row.icon" class="icon" :src="row.icon" alt="Row icon" />
            <div v-if="row.title" class="title" v-html="row.title"></div>
            <component
                v-bind="row.rowComponent.props"
                :is="row.rowComponent.component"
                v-if="row.rowComponent"
                class="row-content"
            />
            <img
                v-if="row.displayRightArrow"
                class="right-arrow"
                src="@/assets/img/light-gray-right-arrow.svg"
                alt="Right arrow"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { Row } from '@/entities/simplelist'

@Component({})
export default class SimpleList extends Vue {
    @Prop({ type: Array })
    public rows!: Row[]

    @Prop({ type: Boolean, required: false, default: true })
    public hasLeftGutter?: boolean

    @Prop({ type: Boolean, required: false, default: true })
    public hasLastDivider?: boolean

    public clickRow(row: Row) {
        if (row && row.onClick) {
            row.onClick!()
        }
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/palette';
@import '~@/styles/breakpoints.less';

.row-wrapper {
    &.gutter {
        padding-left: 26px;
    }
    &.mobile {
        padding: 0 16px;
    }
}
.row {
    min-height: 60px;
    border-bottom: solid 1px rgba(46, 91, 255, 0.08);
    display: flex;
    align-items: center;

    .icon {
        margin-right: 18px;
    }

    .title {
        color: @black80;
        font-family: 'Roboto Black', sans-serif;
        font-size: 16px;
        flex: 1;
    }

    .row-content {
        flex: 1;
    }
    .right-arrow {
        margin-right: 26px;
    }
}

.noDivider {
    &:last-child {
        border-bottom: 0;
    }
}
</style>
