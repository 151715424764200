import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4041620c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toasts" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_TransitionGroup, { name: "toast" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.toasts, (toast) => {
          return (_openBlock(), _createElementBlock("div", {
            key: toast.id,
            class: "toast",
            onClick: ($event: any) => (_ctx.removeToast(toast.id))
          }, _toDisplayString(toast.message), 9, _hoisted_2))
        }), 128))
      ], undefined, true),
      _: 1
    })
  ]))
}