export function getFaceIconForValue(value: number): string {
    switch (value) {
        case 1:
            return 'faTired'
        case 2:
            return 'faFrown'
        case 3:
            return 'faMeh'
        case 4:
            return 'faSmile'
        default:
            return 'faGrinBeam'
    }
}
