import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/img/seasonal-banner.png'


const _withScopeId = n => (_pushScopeId("data-v-6b5ee952"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "seasonal-banner",
  src: _imports_0,
  alt: "Seasonal banner"
}
const _hoisted_2 = {
  key: 0,
  class: "info-message-box"
}
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_Typography = _resolveComponent("Typography")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['wrapper', { 'show-info': _ctx.showInfo }])
  }, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickShowInfo && _ctx.onClickShowInfo(...args)))
    }, [
      _createVNode(_Transition, { name: "slide-down-from-top" }, {
        default: _withCtx(() => [
          (_ctx.showSeasonalBanner)
            ? (_openBlock(), _createElementBlock("img", _hoisted_1))
            : _createCommentVNode("", true)
        ], undefined, true),
        _: 1
      })
    ]),
    (_ctx.showSeasonalBanner && _ctx.showInfo)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_FontAwesomeIcon, {
            class: "close-icon",
            icon: _ctx.faXmark,
            onClick: _ctx.onClickHideInfo
          }, null, 8, ["icon", "onClick"]),
          _createVNode(_component_Typography, {
            variant: "overline",
            class: "info-text title"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.title), 1)
            ], undefined, true),
            _: 1
          }),
          _createVNode(_component_Typography, {
            variant: "body2",
            class: "info-text"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", { innerHTML: _ctx.infoText }, null, 8, _hoisted_3)
            ], undefined, true),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ], 2))
}