<template>
    <div>
        <label class="dropdown-label" @click="dropdownActive = !dropdownActive">
            <input
                :class="[
                    {
                        value: selectedOptionsLabels,
                        error,
                        'show-label': showLabel,
                    },
                ]"
                :value="selectedOptionsLabels"
                :disabled="disabled"
                readonly
            />
            <span v-if="showLabel" class="label">{{ label }}</span>
            <FontAwesomeIcon
                v-show="dropdownActive"
                class="icon"
                :class="[{ 'show-label': showLabel }]"
                :icon="faChevronUp"
            />
            <FontAwesomeIcon
                v-show="!dropdownActive"
                class="icon"
                :class="[{ 'show-label': showLabel }]"
                :icon="faChevronDown"
            />
        </label>
        <div
            v-show="dropdownActive"
            :class="[
                'multi-checkbox-container',
                { 'floating-dropdown': floatingDropdown, error },
            ]"
        >
            <Checkbox
                v-for="option in options"
                :key="option.value"
                :checked="isSelected(option)"
                @change="selectOption(option, $event)"
                >{{ option.label }}</Checkbox>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { Option, OptionValue } from '@/entities/rain/dropdown'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faChevronDown, faChevronUp } from 'fontawesome/free-solid-svg-icons'
import Checkbox from '@/components/Rain/Inputs/Checkbox.vue'

interface Props {
    options?: Option[]
    values?: OptionValue
    label?: string
    disabled?: boolean
    error?: boolean
    showLabel?: boolean
    floatingDropdown?: boolean
}
const props = withDefaults(defineProps<Props>(), {
    options: () => [],
    values: () => [],
    label: '',
    disabled: false,
    error: false,
    showLabel: true,
    floatingDropdown: false,
})
const emit = defineEmits(['change'])

const dropdownActive = ref(false)

const selectedOptionsLabels = computed(() => {
    return props.values.map((obj: Option) => obj.label).join(', ')
})

const isSelected = (option: Option) => {
    return props.values.some((obj: Option) => obj.value === option.value)
}

const selectOption = (option: Option, checked: boolean) => {
    emit('change', option, checked)
}
</script>

<style scoped lang="less">
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';
@import '~@/styles/rain/inputs.less';

.dropdown-label {
    position: relative;
    display: block;
}

.label {
    .label();
}

.icon {
    position: absolute;
    top: 22px;
    right: @gutterSpacing-md;
    color: @grey;
    pointer-events: none;

    &:not(.show-label) {
        top: 11px;
    }
}

input {
    .input-field();
    appearance: none;
    padding: @gutterSpacing-md @gutterSpacing-xl @gutterSpacing-base
        @gutterSpacing-md;
    cursor: default;

    &:not(.show-label) {
        padding-top: 0;
        padding-bottom: 0;
        height: 36px;
    }

    &.value {
        .filled-label();
    }

    &:focus {
        outline: none;
        border: 1px solid @primaryBlue;
        .filled-label();
    }

    &.error {
        border: 1px solid @errorRed;

        ~ .label,
        ~ .label ~ .icon {
            color: @errorRed;
        }
    }

    &:disabled {
        background-color: @lightGrey;
        color: @grey;
        cursor: not-allowed;
    }
}

.filled-label() {
    padding-top: @gutterSpacing-lg;

    ~ .label {
        transform: translateY(-11px);
        font-size: @fontSize-sm;
        line-height: @lineHeight-sm;
        letter-spacing: @letterSpacing-lg;
    }

    &:not(.show-label) {
        padding-top: 0;
        padding-bottom: 0;
        height: 36px;
    }
}

.multi-checkbox-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-height: 124px;
    overflow-y: scroll;
    padding: 16px;
    margin-top: 9px;
    border: 1px solid @outline;
    border-radius: 4px;

    &.floating-dropdown {
        position: fixed;
        background-color: @white;
    }

    &.error {
        border: 1px solid @errorRed;
    }

    &::-webkit-scrollbar-thumb {
        height: 75px;
        background-color: #d9d9d9;
        border: 7.5px solid transparent;
        border-radius: 20px;
        background-clip: padding-box;
    }

    &::-webkit-scrollbar {
        width: 18px;
    }
}
</style>
