import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26590274"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    class: "sort-direction",
    src: _ctx.imageSource,
    alt: _ctx.altText
  }, null, 8, _hoisted_1))
}