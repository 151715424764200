import { resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-626bc71c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "login-form" }
const _hoisted_3 = { class: "inputs" }
const _hoisted_4 = { class: "white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!
  const _component_TransparentButton = _resolveComponent("TransparentButton")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_AppVersion = _resolveComponent("AppVersion")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          onKeyup: _cache[1] || (_cache[1] = _withKeys(() => _ctx.$refs.nextBtn.onClick(), ["enter"]))
        }, [
          _createVNode(_component_Input, {
            id: "inputDomain",
            modelValue: _ctx.domain,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.domain) = $event)),
            placeholder: "Domain",
            title: "Domain",
            required: true,
            autocomplete: "off",
            "err-msg": "Domain is required",
            regex: _ctx.domainRegex
          }, null, 8, ["modelValue", "regex"])
        ], 32)
      ]),
      _createVNode(_component_TransparentButton, {
        ref: "nextBtn",
        class: "btn-login",
        text: "Next",
        click: _ctx.onLogin
      }, null, 8, ["click"]),
      _createElementVNode("p", _hoisted_4, [
        _createTextVNode(" Back to "),
        _createVNode(_component_RouterLink, {
          class: "find-login white",
          to: { name: 'findlogin' },
          replace: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode("request a magic link ")
          ], undefined, true),
          _: 1
        })
      ]),
      _createVNode(_component_AppVersion, { class: "app-v" })
    ])
  ]))
}