import { Options } from '@/entities/conversation'
import { defaultCsatOptions } from '@/transforms/conversation'

export default function fname(name: string) {
    if (!name) {
        return name
    }
    return name.replace(/_c$/, '').split('_').map(ucfirst).join(' ')
}

export function rtrim(str, chr) {
    const rgxtrim = !chr ? new RegExp('\\s+$') : new RegExp(chr + '+$')
    return str.replace(rgxtrim, '')
}

export function ltrim(str, chr) {
    const rgxtrim = !chr ? new RegExp('^\\s+') : new RegExp('^' + chr + '+')
    return str.replace(rgxtrim, '')
}

export function isNumeric(value: string) {
    return !isNaN(parseFloat(value)) && isFinite(+value)
}

export function isEmptyString(value: string): boolean {
    return /^\s*$/.test(value)
}

export function isSafeKey(key: string): boolean {
    return /([A-Za-z0-9\-\:\[\]\.\_ \,])+/.test(key)
}

export function unescapeHTML(input) {
    const doc = new DOMParser().parseFromString(input, 'text/html')
    return doc?.documentElement?.textContent ?? input
}

export function ucfirst(str) {
    if (typeof str !== 'string' || str.length === 0) {
        return str
    }
    return str.charAt(0).toUpperCase() + str.slice(1)
}

export function ucwords(str) {
    return str
        .split(/\s+/)
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1).toLowerCase())
        .join(' ')
}

const PARAM_PATTERN = /{\s*[^\s]+\s*(fallback\s*[^\s]+\s*)*}/gi

export function htmlSanitizer(str) {
    if (typeof str !== 'string') {
        return ''
    }
    //Solution sourced from https://stackoverflow.com/questions/18749591/encode-html-entities-in-javascript
    return str.replace(/[\u00A0-\u9999<>\&]/g, function (i) {
        return '&#' + i.charCodeAt(0) + ';'
    })
}

export function interpolateData(
    str: string,
    values: any,
    questionType: string,
    reselectScoreCallback: Function | null = null,
    csatOptions: Options = defaultCsatOptions()
) {
    let replaced = str
    let foundAnswer = false
    const matches = replaced.match(PARAM_PATTERN) || []

    for (const match of matches) {
        const [key, fallback] = match.slice(1, -1).split(/\s+fallback\s+/)

        if (match !== '{answer}') {
            replaced = replaced.replace(match, values[key] ?? fallback ?? '')
            continue
        }
        // There should only be one answer reselect component at a time if enabled
        if (reselectScoreCallback !== null && !foundAnswer) {
            replaced = replaced.replace(
                match,
                reselectScoreCallback(values[key])
            )
            foundAnswer = true
            continue
        }

        let replacement = '0'
        if (values[key] === undefined) {
            replaced = replaced.replace(match, replacement)
            continue
        }
        switch (questionType) {
            case 'fivestar': {
                replacement = values[key] + '&#x2606;'
                break
            }
            case 'csat': {
                const option = csatOptions.find(
                    (option) => option.key == values[key]
                )
                replacement = option ? option.value : values[key]
                break
            }
            default: {
                replacement = values[key]
                break
            }
        }
        // TODO: Should refactor this into reselect-score when the feature flag is removed
        replaced = replaced.replace(match, replacement)
    }

    return replaced
}

export function parseMentionsAndTags(textToParse: string): string {
    let parsedText = textToParse.replace(
        /\[@([^\]]+)\]\(user:([^\)]+)\)/g,
        '@$1'
    )
    parsedText = parsedText.replace(/\[#([^\]]+)\]\(tag:([^\)]+)\)/g, '#$1')

    return parsedText
}

export function sanitiseHashtag(input: string): string {
    // Only allow letters and numbers from all languages, as well as hyphen and underscore
    // \p{L} & \p{N} allows letters and numbers from any alphabet (multi-language support)
    // \p{M} supports marks in languages like Hindi
    return input.replace(/[^\p{L}\p{N}\p{M}\-_]/gu, '')
}

export const OFFLINE_FEATURE_DISABLE_MESSAGE =
    'You are currently offline. This feature is disabled.'
