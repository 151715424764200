<template>
    <div :class="className" @click="$emit('click', $event)">
        <slot></slot>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

type ButtonVariant = 'primary' | 'secondary' | 'cancel' | 'transparent'

@Component({
    emits: ['click'],
})
export default class Button extends Vue {
    @Prop({ type: String, default: 'primary' }) public variant!: ButtonVariant
    @Prop({ type: Boolean, default: false }) public faded!: boolean
    @Prop({ type: Boolean, default: false }) public noBorder!: boolean

    public get className() {
        return [
            'button',
            this.variant,
            this.faded ? 'faded' : '',
            this.noBorder ? 'no-border' : '',
        ].join(' ')
    }
}
</script>

<style scoped lang="less">
.button {
    font-size: 12px;
    line-height: 14px;
    font-family: 'Roboto', sans-serif;
    border-radius: 15px;
    border: 1px solid #2e5bff;
    padding: 7px 20px;
    display: inline-block;
    cursor: pointer;
}

.button.primary {
    background: #2e5bff;
    color: #fff;

    &.faded {
        background: #6484ff;
        border: 1px solid #6484ff;
    }
}

.button.secondary {
    background: #fff;
    color: #2e5bff;

    &.faded {
        border: 1px solid #e0e7ff;
        color: #6484ff;
    }
}

.button.transparent {
    color: white;
    background: transparent;
    border: 2px solid white;
}

.button.cancel {
    background: #fff;
    color: #6e7682;
    border-color: #6e7682;
}

.no-border {
    border: none;
}
</style>
