import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'
import user, { UserState } from '@/mobile/src/store/modules/user'
import AsyncStorage from '@/mobile/src/store/asyncstorage'
import scorecard, { ScorecardState } from '@/store/modules/scorecard'
import scoredata, { ScoreDataState } from '@/store/modules/scoredata'
import notices, { NoticesState } from '@/store/modules/notices'
import chatresponse, { ChatResponseState } from '@/store/modules/chatresponse'
import teamscoreboard, {
    TeamScoreboardState,
} from '@/store/modules/teamscoreboard'
import filter, { FilterState } from '@/store/modules/filter'
import status, { StatusState } from '@/store/modules/status'
import digest, { DigestState } from '@/store/modules/digest'
import chartStatus, { ChartState } from '@/store/modules/chartStates'
import dashboard, { DashboardState } from '@/store/modules/dashboard'
import suggestions, { SuggestionsState } from '@/store/modules/suggestions'
import pageRefresh, {
    PageRefreshState,
} from '@/mobile/src/store/modules/pageRefresh'
import notificationSettings, {
    NotificationSettingsState,
} from '@/mobile/src/store/modules/notificationSettings'
import device, { DeviceState } from '@/mobile/src/store/modules/device'
import insights, { InsightsState } from '@/store/modules/insights'
import engagement, { EngagementState } from '@/store/modules/engagement'
import scorecardSettings, {
    ScorecardSettingsState,
} from '@/store/modules/appstore/scorecardSettings'
import performanceOvertime, {
    PerformanceOverTimeConfigState,
} from '@/store/modules/performanceOvertime'
import topicfeedback, {
    TopicFeedbackState,
} from '@/store/modules/topicfeedback'
import scrollPositions, {
    ScrollPositionsState,
} from '@/mobile/src/store/modules/scrollPositions'
import reportcard, { ReportCardState } from '@/store/modules/reportcard'
import moments, { MomentsState } from '@/store/modules/moments'
import teamFilter, { TeamFilterState } from '@/store/modules/teamFilter'
import timeFilter, { TimeFilterState } from '@/store/modules/timeFilter'
import achievedgoal, { AchievedGoalState } from '@/store/modules/achievedgoal'
import topperformers, {
    TopPerformersState,
} from '@/store/modules/topperformers'
import hierarchylevelgoals, {
    HierarchyGoalState,
} from '@/store/modules/hierarchylevelgoals'
import goals, { GoalTrackingState } from '@/store/modules/goals'
import mobilenavigation, {
    MobileNavigationState,
} from '@/mobile/src/store/modules/mobileNavigation'
import notifications, {
    NotificationsState,
} from '@/mobile/src/store/modules/notifications'
import reels, { ReelsState } from '@/store/modules/reels'

const vuexLocalStore = new VuexPersistence<MobileState>({
    storage: new AsyncStorage(),
    supportCircular: false,
    asyncStorage: true,
    modules: [
        'user',
        'scorecard',
        'notices',
        'engagement',
        'filter',
        'suggestions',
        'notificationSettings',
        'scrollPositions',
        'notifications',
        '$companyStore',
        'device',
    ],
})

export interface MobileState {
    user: UserState
    scorecard: ScorecardState
    scoredata: ScoreDataState
    scorecardSettings: ScorecardSettingsState
    teamscoreboard: TeamScoreboardState
    insights: InsightsState
    filter: FilterState
    chartStatus: ChartState
    status: StatusState
    digest: DigestState
    dashboard: DashboardState
    engagement: EngagementState
    notices: NoticesState
    chatresponse: ChatResponseState
    suggestions: SuggestionsState
    pageRefresh: PageRefreshState
    notificationSettings: NotificationSettingsState
    device: DeviceState
    performanceOvertime: PerformanceOverTimeConfigState
    scrollPositions: ScrollPositionsState
    reportcard: ReportCardState
    moments: MomentsState
    teamFilter: TeamFilterState
    timeFilter: TimeFilterState
    achievedgoal: AchievedGoalState
    topperformers: TopPerformersState
    hierarchylevelgoals: HierarchyGoalState
    goals: GoalTrackingState
    mobilenavigation: MobileNavigationState
    notifications: NotificationsState
    reels: ReelsState
}

const store = createStore({
    modules: {
        user,
        scorecard,
        scoredata,
        scorecardSettings,
        teamscoreboard,
        insights,
        filter,
        chartStatus,
        status,
        digest,
        dashboard,
        engagement,
        notices,
        chatresponse,
        suggestions,
        pageRefresh, // do not persist
        notificationSettings,
        device,
        performanceOvertime,
        scrollPositions,
        topicfeedback,
        reportcard,
        moments,
        teamFilter,
        timeFilter,
        achievedgoal,
        topperformers,
        hierarchylevelgoals,
        goals,
        mobilenavigation,
        notifications,
        reels,
    },
    plugins: [vuexLocalStore.plugin],
    actions: {
        async dispatchStartPolling({ dispatch }) {
            // Store action to start all polling at global level.
            if (
                store.getters.userHasMlp &&
                store.getters.permissions.includes('ROLE_VIEW_NOTICES')
            ) {
                await dispatch('moments/startUnseenCountPolling') // start polling unseen moment count
            }
        },
        dispatchStopPolling({ dispatch }) {
            // Store action to stop all polling at global level.
            dispatch('moments/stopUnseenCountPolling') // stop polling unseen moment count
        },
        async dispatchStoreLogin({ dispatch }) {
            await dispatch('setDetails')
            // Start all polling at global level
            await dispatch('dispatchStartPolling')
        },
        async dispatchStoreLogout({ dispatch }) {
            await dispatch('scoredata/resetData')
            // stop all polling at global level
            await dispatch('dispatchStopPolling')
            await dispatch('logoutUser')
        },
    },
})

export default store
