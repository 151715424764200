import client from '@/api/client'
import { IUserType } from '@/entities/usertype'

export async function getUserCountOfUserRole(id: number | string) {
    const response = await client.get(
        `/user/roles/count/` + encodeURIComponent(id)
    )

    return response.data
}

export interface GetBaseUserRolesResponse {
    success: boolean
    msg: string
    data: IUserType[]
}

export async function getBaseUserRoles() {
    try {
        const response =
            await client.get<GetBaseUserRolesResponse>(`user/roles/base`)

        return response.data
    } catch (e) {
        return { success: false, msg: 'Something went wrong.', data: [] }
    }
}
