<template>
    <Loading v-if="isReelsLoading" :loading="isReelsLoading"></Loading>
    <div v-else>
        <ReelSlide
            :key="currentSlideIndex"
            :title="slidesData[currentSlideIndex]?.title ?? ''"
            :message="slidesData[currentSlideIndex]?.message ?? ''"
            :can-share="slidesData[currentSlideIndex]?.canShare ?? ''"
            :score="slidesData[currentSlideIndex]?.score ?? ''"
            :can-add-report-card="
                slidesData[currentSlideIndex]?.canAddReportCard ?? ''
            "
            :slide-time-duration="SLIDE_TIME_DURATION"
            :close="closeReels"
        />
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue'
import router from '@/mobile/src/router'
import store from '@/mobile/src/store'
import ReelSlide from './ReelSlide.vue'
import Loading from '@/components/Loading.vue'
import { pendoTrackEvent } from '@/utils/pendo'

const SLIDE_TIME_DURATION = 5000

const closeReels = () => {
    pendoTrackEvent('reel_time_closed')
    router.push('/')
}

const slidesData = computed(() => store.getters['reels/allReels'])
const isReelsLoading = computed(() => store.getters['reels/loadingStatus'])
const currentSlideIndex = ref(0)

let slideTransitionInterval: NodeJS.Timeout

onMounted(async () => {
    await store.dispatch('reels/loadReelsList')
    if (
        !isReelsLoading.value &&
        (!slidesData.value?.length || slidesData.value?.length === 0)
    ) {
        return await router.push('/')
    }

    pendoTrackEvent('reel_time_viewed')
    slideTransitionInterval = setInterval(() => {
        const maxIndex = slidesData.value.length
        currentSlideIndex.value = (currentSlideIndex.value + 1) % maxIndex
    }, SLIDE_TIME_DURATION)
})

onUnmounted(() => {
    clearInterval(slideTransitionInterval)
})
</script>
