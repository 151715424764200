<template>
    <div class="insight-coaching-list-card">
        <div v-if="header" class="header desktop">
            <div class="title left">{{ header.titleLeft }}</div>
            <div class="title middle">{{ header.titleMiddle }}</div>
            <div class="title right">{{ header.titleRight }}</div>
        </div>
        <div>
            <div
                v-for="(currentCount, currentCountIdx) in loadingListItemCount"
                :key="currentCountIdx"
            >
                <div class="coaching-list-item desktop">
                    <div class="left-container">
                        <SkeletonCircle width="44px" />
                        <SkeletonDiv
                            class="loading-title"
                            width="150px"
                            height="24px"
                        />
                    </div>
                    <div class="middle-container">
                        <SkeletonDiv
                            class="top-skeleton-bar"
                            width="130px"
                            height="12px"
                        />
                        <SkeletonDiv width="130px" height="12px" />
                    </div>
                    <div class="right-container">
                        <SkeletonDiv
                            class="top-skeleton-bar"
                            width="80px"
                            height="12px"
                        />
                        <SkeletonDiv width="80px" height="12px" />
                    </div>
                    <img
                        src="@/assets/img/Icons/Grey/Chevron Down.svg"
                        alt="chevron"
                    />
                </div>
                <hr v-if="currentCount < loadingListItemCount" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import SkeletonCircle from '@/components/Skeletons/SkeletonCircle.vue'
import SkeletonDiv from '@/components/Skeletons/SkeletonDiv.vue'

@Component({
    components: { SkeletonDiv, SkeletonCircle },
})
export default class InsightsListLoading extends Vue {
    @Prop({ type: Object, required: false })
    public readonly header!: {
        titleLeft: String
        titleMiddle: String
        titleRight: String
    }

    private loadingListItemCount = 4
}
</script>

<style lang="less" scoped>
@import '~@/styles/variables';
@import '~@/styles/typography';
@import '~@/styles/variables';
@import '~@/styles/typography';

.insight-coaching-list-card {
    padding: 12px;

    &.desktop {
        padding: 16px 24px;
    }

    .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 14px;

        .title {
            .title();
        }

        &.desktop {
            .title {
                flex: 1;

                &.left {
                    flex: 3;
                }

                &.middle {
                    flex: 2;
                }

                &.chevron {
                    flex: 0.25;
                }
            }
        }
    }

    hr {
        border: none;
        border-top: 1px solid @blueLight;
    }

    .coaching-list-item {
        display: flex;
        align-items: center;
        border: 3px solid transparent;

        .top-skeleton-bar {
            margin-bottom: 12px;
        }

        .left-container {
            display: flex;
            flex: 3;
            align-items: center;

            .loading-title {
                margin-left: 16px;
            }
        }
        .middle-container {
            flex: 2;
        }
        .right-container {
            flex: 1;
        }
        .chevron {
            flex: 0.25;
        }
    }
}
</style>
