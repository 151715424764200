<template>
    <div class="web-view-header" :style="style">
        <div class="left-button">
            <slot name="left-button"></slot>
        </div>
        <div v-if="title" class="simple-title" v-html="title"></div>
        <div v-else class="custom-title-container">
            <slot name="custom-title"></slot>
        </div>
        <div class="right-button">
            <slot name="right-button"></slot>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { embedded } from '@/utils/react-native-webview'

@Component({})
export default class WebViewHeader extends Vue {
    @Prop({ type: String, required: false }) public title?: string
    @Prop({ type: Number, default: 0 }) public readonly statusBarHeight?: number

    private embedded: boolean = embedded

    public get style() {
        if (!embedded) {
            return null
        }

        return {
            paddingTop: `${this.statusBarHeight}px`,
        }
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/palette';
@import '~@/styles/typography';

.web-view-header {
    background-color: @white;
    border-bottom: 1px solid @blueLight;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 54px;
    text-transform: capitalize;
    padding: 0 16px;

    > .left-button,
    > .right-button {
        align-self: flex-start;
    }

    > .simple-title,
    > .custom-title-container {
        flex: 1 0 auto;
    }
}

.left-button,
.right-button {
    min-width: 18px;
    height: 54px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.simple-title {
    text-align: center;
    color: @grey90;
    font-family: @fontFamily;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
