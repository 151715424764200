<template>
    <div v-if="userHasMfa" class="mfa-page">
        <FontAwesomeIcon class="key-icon" :icon="faCircleCheck" />

        <Typography variant="h4" component="h4" class="reset title-container">
            <template v-if="fromMfaSetup">MFA Successfully Setup</template>
            <template v-else>Your Recovery Codes</template>
        </Typography>

        <Typography variant="body2" class="detail-container">If you lose access to your authenticator and need to log back in,
            below are your <strong>recovery codes</strong>. Please note that
            each code is one-time use. Copy the codes and keep it in a safe
            place.</Typography>

        <div v-show="loading" class="secret-container">Loading codes...</div>
        <div v-show="codes && !loading" class="secret-container">
            <code v-for="code in codes" :key="code">{{ code }}</code>
        </div>
        <PillButton
            :label="copyText"
            :disabled="loading || copied"
            class="is-v4 btn-space"
            @click="copyToClipboard"
        />

        <PillButton
            label="done"
            :disabled="loading"
            class="is-v4"
            @click="redirectMFA"
        />
    </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref, onMounted } from 'vue'
import { getRecoveryCodes } from '@/api/mfa'
import { faCircleCheck } from 'fontawesome/free-solid-svg-icons'
import store from '@/mobile/src/store'
import Typography from '@/components/Rain/Typography/Typography.vue'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { showToast } from '@/mobile/src/utils/toastbus'
import useEmitter from '@/composables/useEmitter'
import { ToastColor, ToastIcon } from '@/mobile/src/types/toasts'
import { sleep } from '@/utils/async'
import PillButton from '@/components/PillButton.vue'
import { useRoute, useRouter } from 'vue-router'
import { Clipboard } from '@capacitor/clipboard'

const loading = ref(false)
const copied = ref(false)
const codes = ref('')
const forcedMFA = ref(false)
const fromMfaSetup = ref(false)
const userHasMfa = computed(() => store.getters['userHasMfa'])

const copyText = computed(() => {
    return copied.value ? 'Copied to Clipboard' : 'Copy to Clipboard'
})

const route = useRoute()
const router = useRouter()
const emitter = useEmitter()

onBeforeMount(async () => {
    forcedMFA.value = route.params.forcedMFA === 'true'
    fromMfaSetup.value = route.params.fromMfaSetup === 'true'
})

onMounted(async () => {
    await getRecoveryCodesResponse()
})

function showFailureToast() {
    showToast(
        emitter,
        ToastIcon.Success,
        ToastColor.InternalNote,
        `All previously generated recovery codes remain valid`,
        4000
    )
}

async function copyToClipboard() {
    const secretToCopy = document.getElementsByClassName(
        'secret-container'
    )[1] as HTMLInputElement

    // copy Link
    await Clipboard.write({
        string: secretToCopy.innerText.trim(),
    })

    copied.value = true
    await sleep(2000)
    copied.value = false
}

async function getRecoveryCodesResponse() {
    loading.value = true
    let recoveryCodes = await getRecoveryCodes()
    if (recoveryCodes) {
        codes.value = recoveryCodes
    } else {
        showFailureToast()
        await sleep(4000)
        await redirectMFA()
    }
    loading.value = false
}

async function redirectMFA() {
    const routerName =
        forcedMFA.value || !fromMfaSetup.value
            ? store.getters.mainNavTabs[0].name
            : 'mfa-reset'
    await router.replace({
        name: routerName,
        replace: true,
    })
    return
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/variables.less';
@import '~@/styles/variables.less';
@import '~@/styles/palette.less';

.mfa-page {
    top: 0;
    height: calc(
        100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom)
    );
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: white;
    .title-container {
        margin-top: @featureGutter;
        color: white;
    }

    .key-icon {
        height: 40px;
    }
    .secret-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: fit-content;
        min-width: 150px;
        min-height: 19px;
        font-size: 14px;
        letter-spacing: 1px;
        font-family: monospace;
        border: 1px dashed white;
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.1),
            rgba(255, 255, 255, 0.1)
        );
        padding: 10px;
        margin: @featureGutter 0;
        border-radius: 4px;
        gap: 10px;
    }
    .detail-container {
        margin-top: @featureGutter;
        color: white;
        width: 285px;
    }

    .btn-space {
        margin-bottom: @featureGutter;
    }
}
</style>
