<template>
    <div class="content">
        <div class="filter-title" />
        <div class="filter-row-box">
            <div class="filter-item small" />
            <div class="filter-item" />
            <div class="filter-item large" />
        </div>
        <div class="filter-title" />
        <div class="filter-row-box">
            <div class="filter-item large" />
            <div class="filter-item large" />
            <div class="filter-item small" />
        </div>
        <div class="filter-title" />
        <div class="filter-row-box">
            <div class="filter-item" />
            <div class="filter-item large" />
            <div class="filter-item large" />
        </div>
        <div class="filter-title" />
        <div class="filter-row-box">
            <div class="filter-item large" />
            <div class="filter-item" />
            <div class="filter-item large" />
        </div>
        <div class="filter-title" />
        <div class="filter-row-box">
            <div class="filter-item large" />
            <div class="filter-item large" />
            <div class="filter-item small" />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'

@Component({})
export default class LoadingFilters extends Vue {}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';

.content {
    padding: @featureGutter;

    .filter-title,
    .filter-item {
        width: 100%;
        border-radius: @borderRadius-base;
        background: linear-gradient(
            to right,
            @thinFog 30%,
            @white30 50%,
            @thinFog 70%
        );
        background-size: 2000%;
        animation-duration: 2.5s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-name: loading;
    }

    .filter-title {
        height: @fontSize-md;
        width: 30vw;
        margin-bottom: @gutterSpacing-base;
    }

    .filter-row-box {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

    .filter-item {
        height: 32px;
        width: 80px;
        border-radius: 9999px;
        margin-bottom: @gutterSpacing-lg;

        &.small {
            width: 50px;
        }

        &.large {
            width: 120px;
        }
    }
}

@keyframes loading {
    from {
        background-position: 100% 0;
    }
    to {
        background-position: 0 0;
    }
}
</style>
