<template>
    <FeedbackBaseCard
        :timestamp="Number(question.responded)"
        :author="question.name"
        :text="question.comment"
        :highlight="!moment.seen"
        card-type="moment"
        :text-italic="isNoCommentQuestion"
        double-card
        :is-starred="isStarred"
        @toggleStarred="onToggleStarred()"
    >
        <template #title>
            <MomentTypeTitle
                :seen="Boolean(moment.seen)"
                :title="questionTopic"
            />
        </template>

        <template #icon>
            <ResponseCircle
                :score="Number(question.score)"
                :variation="question.type"
            />
        </template>
        <template v-if="moment.repliesCount > 0" #extraIcon>
            <CountCircle
                :count="moment.repliesCount"
                :variation="momentTypeClass"
                class="replies-count"
            />
        </template>

        <template #secondTitle><MomentTypeTitle
                :title="moment.type"
                :type-class="momentTypeClass"
        /></template>
        <template #secondRightTitle>
            <TextClamp :text="`To ${momentRecipients}`" :max-lines="2" />
        </template>

        <template #secondIcon>
            <AvatarOrInitials :size="40" :user="moment.user" />
        </template>
        <template #secondText>
            <TextClamp is-html :text="message" :max-lines="2" />
        </template>

        <template #secondAuthor>{{ moment.user.name }}</template>
        <template #secondTimestamp>{{
            getRelativeDate(moment.updated)
        }}</template>
    </FeedbackBaseCard>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

import { relativeDate } from '@/utils/time'
import { toggleResponseStarred } from '@/api/scorecard'
import { Moment } from '@/entities/moment'
import FeedbackBaseCard from '@/mobile/src/components/appV4/feedback/FeedbackBaseCard.vue'
import ResponseCircle from '@/components/ResponseCircle.vue'
import MomentTypeTitle from '@/mobile/src/components/appV4/moments/MomentTypeTitle.vue'
import AvatarOrInitials from '@/components/WebView/AvatarOrInitials.vue'
import CountCircle from '@/mobile/src/components/appV4/CountCircle.vue'
import TextClamp from '@/components/TextClamp.vue'
import { noCommentResponse } from '@/entities'

@Component({
    components: {
        TextClamp,
        CountCircle,
        AvatarOrInitials,
        MomentTypeTitle,
        ResponseCircle,
        FeedbackBaseCard,
    },
})
export default class MomentFeedbackCard extends Vue {
    @Prop({ type: Object, required: true }) moment!: Moment
    @Prop({ type: String, required: true }) momentRecipients!: string

    private isStarred = this.question?.isStarred

    public get momentTypeClass() {
        return this.moment.type.toLowerCase().replace(' ', '-')
    }

    public get question() {
        return this.moment.question
    }

    public get isNoCommentQuestion() {
        return this.question?.comment === noCommentResponse
    }

    public get questionTopic() {
        return this.question?.topic?.length
            ? this.question.topic
            : 'No Topic Provided'
    }

    public getRelativeDate(timestamp) {
        return relativeDate(timestamp)
    }

    public async onToggleStarred() {
        if (this.question) {
            this.isStarred = !this.isStarred
            await toggleResponseStarred(this.question.id)
        }
    }

    public get message() {
        if (this.moment.type === 'Internal Note') {
            return this.parseMentionsAndTags(this.moment.message)
        }

        return this.moment.message
    }

    public parseMentionsAndTags(textToParse) {
        let parsedText = textToParse.replace(
            /\[@([^\]]+)\]\(user:([^\)]+)\)/g,
            '@$1'
        )
        parsedText = parsedText.replace(/\[#([^\]]+)\]\(tag:([^\)]+)\)/g, '#$1')

        return parsedText
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/appV4/momentTypes.less';

.icons-container {
    .replies-count {
        color: var(--type-colour);
        border-color: var(--type-colour);
        margin: 0 0 4px 14px;
    }
}
</style>
