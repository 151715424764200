<template>
    <div class="intersectionalContainer">
        <div ref="uptrigger" class="trigger"></div>
        <div ref="midtrigger" class="trigger">
            <slot />
        </div>
        <div ref="lowtrigger" class="trigger"></div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component({})
export default class Intersectional extends Vue {
    @Prop({ type: Function }) public onUpperScrollEnd?: () => void
    @Prop({ type: Function }) public onMiddleEnter?: () => void
    @Prop({ type: Function }) public onMiddleExit?: () => void
    @Prop({ type: Function }) public onLowerScrollEnd?: () => void

    private upperObserver
    private middleObserver
    private lowerObserver

    public mounted() {
        this.upperObserver = new IntersectionObserver((entries) => {
            this.handleUpperIntersection(entries[0])
        })

        this.upperObserver.observe(this.$refs.uptrigger)

        this.middleObserver = new IntersectionObserver((entries) => {
            this.handleMiddleIntersection(entries[0])
        })

        this.middleObserver.observe(this.$refs.midtrigger)

        this.lowerObserver = new IntersectionObserver((entries) => {
            this.handleLowerIntersection(entries[0])
        })

        this.lowerObserver.observe(this.$refs.lowtrigger)
    }

    public beforeDestroy() {
        if (this.upperObserver) {
            this.upperObserver.disconnect()
        }
        if (this.middleObserver) {
            this.middleObserver.disconnect()
        }
        if (this.lowerObserver) {
            this.lowerObserver.disconnect()
        }
    }

    private handleUpperIntersection(entry) {
        if (entry.isIntersecting && this.onUpperScrollEnd) {
            this.onUpperScrollEnd()
        }
    }

    private handleMiddleIntersection(entry) {
        if (entry.isIntersecting && this.onMiddleEnter) {
            this.onMiddleEnter()
        }
        if (!entry.isIntersecting && this.onMiddleExit) {
            this.onMiddleExit()
        }
    }

    private handleLowerIntersection(entry) {
        if (entry.isIntersecting && this.onLowerScrollEnd) {
            this.onLowerScrollEnd()
        }
    }
}
</script>

<style lang="less" scoped></style>
