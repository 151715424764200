import client from '@/api/client'
import { baseConfig } from '@/api/clientShared'

export async function loadAuthState() {
    const { data } = await client.get(`/api/v2/me`, {
        ...baseConfig,
        baseURL: '',
    })

    return data
}
