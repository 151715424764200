<template>
    <div
        v-if="tsGroupSummary && tsGroupSummary.tsgroups"
        class="nps-group-list"
    >
        <div class="gl-navbar">
            <div class="gl-back">
                <a v-if="previousTeamScoreboard" @click="goUpOneLevel">
                    <img
                        src="@/assets/img/Icons/corner-right-up.svg"
                        alt="Up one level"
                        width="16"
                        height="16"
                    />
                    <div v-if="previousTeamScoreboard" class="truncate">
                        Back to
                        {{
                            previousTeamScoreboard.parentFieldLabel
                                ? previousTeamScoreboard.parentFieldLabel + ': '
                                : ''
                        }}
                        {{
                            previousTeamScoreboard.parentGroups.length > 0 &&
                            previousTeamScoreboard.parentGroups.join('...') !==
                                ''
                                ? previousTeamScoreboard.parentGroups.join(
                                      '...'
                                  )
                                : 'All'
                        }}
                    </div>
                </a>
            </div>
            <div class="gl-sort">
                <div class="gl-sort-container">
                    <label for="gl-select" class="gl-select-text">Sorted by:</label>
                    <select
                        id="gl-select"
                        class="gl-select-text"
                        :value="tsSort"
                        @input="(event) => setTsSort(event.target.value)"
                    >
                        <option value="asc">Low to High</option>
                        <option value="desc">High to Low</option>
                    </select>
                    <div class="gl-select-caret" />
                </div>
            </div>
        </div>

        <table class="gl-table">
            <tr v-if="!emptyList" class="gl-label-row">
                <td>&nbsp;</td>
                <td>
                    <NPSBarLabel
                        :min="Number(tsGroupSummary.minNPS)"
                        :max="Number(tsGroupSummary.maxNPS)"
                        :average="Number(tsGroupSummary.averageNPS)"
                    >
                        AVG
                        {{
                            (
                                Math.round(tsGroupSummary.averageNPS * 10) / 10
                            ).toFixed(1)
                        }}
                    </NPSBarLabel>
                </td>
                <td>&nbsp;</td>
            </tr>
            <template v-if="fields.length <= 1">
                <tr
                    v-for="currentGroup in sortedGroups"
                    v-show="isVisibleItem(currentGroup)"
                    :key="currentGroup.group"
                    :class="{
                        'gl-group': true,
                        'gl-grid-hover':
                            currentGroup.group === tsGridHoverGroup,
                        'selected-leaf': isSelectedLeaf(currentGroup),
                    }"
                    @click="selectGroup(currentGroup)"
                >
                    <td class="gl-group-name">{{ currentGroup.group }}</td>

                    <td class="gl-group-comparison">
                        <NPSBar
                            :nps="Number(currentGroup.NPS)"
                            :min="Number(tsGroupSummary.minNPS)"
                            :max="Number(tsGroupSummary.maxNPS)"
                            :average="Number(tsGroupSummary.averageNPS)"
                        />
                    </td>

                    <td class="gl-group-nps">
                        <div
                            :class="[
                                'nps',
                                !tsNpsSummary.isFiveScore
                                    ? {
                                          up: tsNpsSummary.change >= 0,
                                          down: tsNpsSummary.change < 0,
                                      }
                                    : null,
                            ]"
                        >
                            {{ currentGroup.NPS }}
                        </div>
                        <div class="responded">
                            {{ currentGroup.responded }} resp
                        </div>
                    </td>
                </tr>
            </template>
            <template v-for="field in fields" v-else :key="field">
                <tr>
                    <td class="field-title">
                        {{
                            tsTeamGroups.filter(
                                (i) => i.fieldMapping === field
                            )[0].groupName
                        }}
                    </td>
                </tr>
                <template v-for="currentGroup in sortedGroups">
                    <tr
                        v-if="currentGroup.field === field"
                        v-show="isVisibleItem(currentGroup)"
                        :key="field + currentGroup.group"
                        :class="{
                            'gl-group': true,
                            'gl-grid-hover':
                                currentGroup.group === tsGridHoverGroup,
                            'selected-leaf': isSelectedLeaf(currentGroup),
                        }"
                        @click="selectGroup(currentGroup)"
                    >
                        <td class="gl-group-name">{{ currentGroup.group }}</td>

                        <td class="gl-group-comparison">
                            <NPSBar
                                :nps="Number(currentGroup.NPS)"
                                :min="Number(tsGroupSummary.minNPS)"
                                :max="Number(tsGroupSummary.maxNPS)"
                                :average="Number(tsGroupSummary.averageNPS)"
                            />
                        </td>

                        <td class="gl-group-nps">
                            <div
                                :class="[
                                    'nps',
                                    !tsNpsSummary.isFiveScore
                                        ? {
                                              up: tsNpsSummary.change >= 0,
                                              down: tsNpsSummary.change < 0,
                                          }
                                        : null,
                                ]"
                            >
                                {{ currentGroup.NPS }}
                            </div>
                            <div class="responded">
                                {{ currentGroup.responded }} resp
                            </div>
                        </td>
                    </tr>
                </template>
            </template>

            <tr v-if="emptyList" class="gl-no-results">
                <td colspan="99">No results found</td>
            </tr>
        </table>
        <Loading v-if="!!tsLoadingTeamGroupSummary" loading />
    </div>
</template>

<script lang="ts">
import { Action, Getter, Mutation } from 'vuex-facing-decorator'
import { Component, Prop, Vue } from 'vue-facing-decorator'
import NPSBar from '@/components/NPSBar.vue'
import NPSBarLabel from '@/components/NPSBarLabel.vue'
import {
    TeamScoreboardEntity,
    TsGroupEntity,
    TsGroupSummaryEntity,
    TsNpsSummaryEntity,
} from '@/entities/teamscoreboard'
import Loading from '@/components/Loading.vue'

@Component({
    components: {
        Loading,
        NPSBar,
        NPSBarLabel,
    },
})
export default class NPSPlotValueList extends Vue {
    @Prop({ type: Object, required: false })
    public tsGroupSummary?: TsGroupSummaryEntity
    @Prop({ type: Array, required: false })
    public visibleItems?: TsGroupEntity[]
    @Prop({ type: Array, required: false }) public fields?: string[]

    @Getter public teamScoreboard?: TeamScoreboardEntity
    @Getter public tsNpsSummary?: TsNpsSummaryEntity
    @Getter public previousTeamScoreboard?: TeamScoreboardEntity
    @Getter public tsSort?: string
    @Getter public tsGridHoverGroup?: string
    @Getter public tsTeamGroups
    @Getter public isLeafGroup
    @Getter public selectedPoint
    @Getter public sortedGroups
    @Getter public tsLoadingTeamGroupSummary

    @Action public loadTeamScoreboard
    @Action public popTsHierarchy

    @Mutation public setTsSort
    @Mutation public setSelectedPoint

    public get emptyList() {
        return (
            !this.tsGroupSummary ||
            this.tsGroupSummary.tsgroups.length <= 0 ||
            !this.visibleItems ||
            this.visibleItems.length <= 0
        )
    }

    public get isVisibleItem() {
        return (group: TsGroupEntity) => {
            if (!this.visibleItems || this.visibleItems.length <= 0) {
                return false
            }

            return (
                typeof this.visibleItems.find((current: TsGroupEntity) => {
                    return current.group === group.group
                }) !== 'undefined'
            )
        }
    }

    public get isSelectedLeaf() {
        return (group: TsGroupEntity) => {
            return this.isLeafGroup && group.group === this.selectedPoint
        }
    }

    public selectGroup(currentGroup) {
        this.loadTeamScoreboard({
            parent: { field: currentGroup.field, group: currentGroup.group },
        })

        if (this.isLeafGroup) {
            this.setSelectedPoint(currentGroup.field)
            // when clicking on leaf, show modal automatically
            this.$emit('showDetail')
        }
    }

    public goUpOneLevel() {
        this.popTsHierarchy()

        if (this.isLeafGroup) {
            this.setSelectedPoint('')
        }
    }
}
</script>

<style lang="less" scoped>
@import '../../../styles/palette.less';
@import '../../../styles/typography.less';

.nps-group-list {
    position: relative;
}

.gl-navbar {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    flex-wrap: wrap;

    .gl-back a {
        display: flex;
        flex: 1;
        font-weight: 500;
        font-size: 12px;

        color: @blue !important; // Uikit overrides this colour

        .truncate {
            flex: 1;
            float: right;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .gl-sort {
        flex: 1;
        display: flex;
        justify-content: flex-end;
    }

    .gl-sort-container {
        position: relative;

        label {
            position: absolute;
            line-height: 19px;
            padding-left: 10px;
            pointer-events: none;

            cursor: pointer;
        }

        #gl-select {
            border: 1px solid @blueLight;
            border-radius: 10px;

            appearance: none;
            outline: none;
            cursor: pointer;
            line-height: 20px;

            padding: 0 20px 0 62px;
        }

        .gl-select-text {
            // This border is used to properly align the text
            // For some reason this requires 1 more px of border though...
            border: 2px solid transparent;

            font-family: @fontFamily;
            font-weight: 500;
            font-size: 10px;
            color: @greyBlue;
            letter-spacing: 0.2px;
            padding-left: 5px;
        }

        .gl-select-caret {
            pointer-events: none;
            position: absolute;
            top: 10px;
            right: 7px;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid @greyBlue;
        }
    }
}

.gl-table {
    width: 100%;
    text-align: left;

    font-size: 13px;
    line-height: 18px;

    border-spacing: 0 0;
    border-collapse: separate;

    @border-size: 1px;
    @row-height: 44px - @border-size;

    .field-title {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 1px;
        color: @grey40;
        text-transform: uppercase;
        font-weight: bold;
    }

    .gl-group {
        height: @row-height;
    }

    .gl-group-name {
        font-weight: 500;
        min-width: 80px;
    }

    .gl-group-comparison {
        width: 66%;
    }

    .gl-group-nps {
        text-align: right;
    }

    .nps {
        font-weight: 500;

        &:after {
            content: '';
            display: inline-block;
            height: 5px;
            width: 6px;
            margin-left: 2px;
            margin-right: 2px;
            background-size: contain;
        }

        &.up:after {
            background-image: url('~@/assets/img/Icons/leaderboard-up.svg');
        }

        &.down:after {
            background-image: url('~@/assets/img/Icons/leaderboard-down.svg');
        }
    }

    .responded {
        color: @grey40;
        margin-right: 10px;
        white-space: nowrap;
    }

    .gl-group {
        cursor: pointer;
    }

    .gl-no-results {
        color: @grey;
        font-style: italic;
        font-size: 14px;
        text-align: center;
    }

    tr {
        td {
            border-bottom: @border-size solid transparent;
            border-top: @border-size solid transparent;
            transition: border-color 200ms ease-in-out;
            padding-left: 7px;
            padding-right: 7px;

            &:first-child {
                border-left: @border-size solid transparent;
                padding-left: 3px;
            }

            &:last-child {
                border-right: @border-size solid transparent;
                padding-right: 3px;
            }
        }

        &.selected-leaf {
            color: @blue;
            margin-left: 0px;
            margin-right: 0px;
            padding-left: 0px;
            padding-right: 0px;

            td {
                background: rgba(245, 249, 250, 0);
                border-color: @blue;
            }

            .responded {
                color: @blue;
            }
        }
    }
}
</style>
