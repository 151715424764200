<template>
    <div class="toasts">
        <transition-group name="toast">
            <div
                v-for="toast in toasts"
                :key="toast.id"
                class="toast"
                @click="removeToast(toast.id)"
            >
                {{ toast.message }}
            </div>
        </transition-group>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { Toast } from '@/store/modules/status'

@Component({
    emits: ['removeToast'],
})
export default class Toasts extends Vue {
    @Prop({ type: Array, required: true })
    public readonly toasts!: Toast[]

    public removeToast(toastId) {
        this.$emit('removeToast', toastId)
    }
}
</script>

<style scoped lang="less">
@import '../styles/layout';
@import '../styles/palette';

/* This styling may change with new design */

.toasts {
    height: 0px;
    position: fixed;
    z-index: 200;
}

.toast {
    opacity: 1;
    position: fixed;
    z-index: 9999;
    top: 20px;
    left: 50%;
    width: 300px;
    margin-left: -150px;
    padding: 15px 20px;
    background: rgb(233, 250, 242);
    color: rgb(98, 214, 138);
    border: 1px solid rgb(98, 214, 138);
    border-radius: 3px;
    cursor: pointer;
}

.toast-enter-active,
.toast-leave-active {
    transition: all 0.5s;
}
.toast-enter-from,
.toast-leave-to {
    opacity: 0;
    transform: translateY(-30px);
}
</style>
