<template>
    <div class="app-version">version: {{ version }}</div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { getAppVersion } from '@/mobile/src/utils/capacitor'

@Component({})
export default class AppVersion extends Vue {
    private version = ''

    private async mounted() {
        this.version = await getAppVersion()
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/palette.less';

.app-version {
    font-size: 10px;
    color: @greyLight;
    opacity: 0.7;
}
</style>
