<template>
    <Dialog
        :suggestion="suggestion"
        :user="suggestion.user"
        :title="title || suggestion.title"
        :subtitle="suggestion.user.name"
        :body="body"
        :created="suggestion.created"
        :link-mode="linkMode(suggestion)"
        :alert="!suggestion.seen"
        :show-delete="inDashboard || inModal"
    >
        <template #footer>
            <SuggestionAgreement
                :suggestion="suggestion"
                :action="suggestion.action"
            />
        </template>
    </Dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import SuggestionAgreement from './SuggestionAgreement.vue'
import Dialog from './Dialog.vue'
import {
    Suggestion,
    SuggestionView,
    SuggestionLinkMode,
} from '@/entities/suggestions'

@Component({
    components: {
        SuggestionAgreement,
        Dialog,
    },
})
export default class SuggestionDialog extends Vue {
    @Prop({ type: Boolean, default: false }) public inDashboard?: boolean
    @Prop({ type: Boolean, default: false }) public inModal?: boolean
    @Prop({ type: Object }) public suggestion!: SuggestionView
    @Prop({ type: String, default: '' }) public title!: string
    @Prop({ type: Number, default: 0 }) public clipLength!: number
    @Prop({ type: Boolean, default: false }) public detail!: boolean

    public linkMode(suggestion: Suggestion): SuggestionLinkMode {
        if (this.detail) {
            return SuggestionLinkMode.None
        }

        if (this.inDashboard) {
            return SuggestionLinkMode.Query
        }

        return SuggestionLinkMode.Path
    }

    public get body() {
        if (
            this.clipLength > 0 &&
            this.suggestion.body.length > this.clipLength
        ) {
            return this.suggestion.body.slice(0, this.clipLength) + '…'
        }

        return this.suggestion.body
    }
}
</script>
