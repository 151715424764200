import client from '@/api/client'
import {
    IFilterQuery,
    IResponse,
    IGetResponseOptions,
    IConvertedContact,
} from '@/entities'
import { OfflineCache } from '@/mobile/src/utils/offlinecache'
import qs from 'qs'

const offlineCache = new OfflineCache('responses')

export async function getResponses(
    filterRules: IFilterQuery,
    options: IGetResponseOptions
) {
    const params = qs.stringify({
        filter_query: JSON.stringify(filterRules),
        ...options,
        isfreetext: !!options.freetext, // indicate if is freetext question
    })

    return await offlineCache.cachedGet<IResponse[]>(
        client,
        `/response/?${params}`
    )
}

export async function getResponsesByUserId(
    id: number,
    filterRules: IFilterQuery,
    options: IGetResponseOptions
) {
    const params = qs.stringify({
        filter_query: JSON.stringify(filterRules),
        ...options,
        isfreetext: !!options.freetext, // indicate if is freetext question
    })
    return await offlineCache.cachedGet<IResponse[]>(
        client,
        `/response/by/${id}?${params}`
    )
}

export async function getHistoricalResponses(contactId) {
    return await client.get<IResponse[]>(`/response/historical/${contactId}`)
}

export async function getResponse(questionId: number) {
    return await client.get<IResponse>(`/response/get/${questionId}`)
}

export async function editResponse(params: { response: Partial<IResponse> }) {
    return await client.post(`/response/edit`, params)
}

export async function getConvertedContacts() {
    return await client.get<IConvertedContact[]>(`/response/converted-contacts`)
}

export async function getReturningContactsDownloadUrl() {
    const { data } = await client.get<{ data: string }>(
        `/returningcontacts/download-async`
    )

    return data
}
