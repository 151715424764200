import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6482c93b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "positive-feedback" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScorecardBadge = _resolveComponent("ScorecardBadge")!
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.badges, (badge, key) => {
      return (_openBlock(), _createElementBlock(_Fragment, null, [
        (badge.count > 0)
          ? (_openBlock(), _createBlock(_component_RouterLink, {
              key: 'link' + key,
              to: {
                    name: _ctx.routeName,
                    params: { value: key, teamMemberUserId: _ctx.teamMemberUserId },
                },
              class: "badge-link"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ScorecardBadge, {
                  label: badge.label,
                  "show-count": false,
                  "badge-id": badge.badgeId,
                  "count-unseen": badge.countUnseen.toString(),
                  count: _ctx.badgeCount(badge),
                  "no-space": ""
                }, null, 8, ["label", "badge-id", "count-unseen", "count"])
              ], undefined, true),
              _: 2
            }, 1032, ["to"]))
          : (_openBlock(), _createBlock(_component_ScorecardBadge, {
              key: key,
              label: badge.label,
              "show-count": false,
              "badge-id": badge.badgeId,
              "count-unseen": badge.countUnseen.toString(),
              count: _ctx.badgeCount(badge),
              "no-space": ""
            }, null, 8, ["label", "badge-id", "count-unseen", "count"]))
      ], 64))
    }), 256))
  ]))
}