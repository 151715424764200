<template>
    <div class="ts-datatype-selector">
        <button
            v-for="type in datatypes"
            :key="type"
            :class="['datatype-button', { selected: type === current }]"
            @click="$emit('change', type)"
        >
            {{ type }}
        </button>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Model, Vue } from 'vue-facing-decorator'

@Component({
    emits: ['change'],
})
export default class TsDatatypeSelector extends Vue {
    @Prop({ default: [] }) public datatypes
    @Prop({ type: String }) private current!: string
}
</script>

<style lang="less" scoped>
@import '../../styles/palette';

.ts-datatype-selector {
    background: @blueLight;
    border-radius: 15px;
    float: right;
    height: 20px;
    max-width: 100%;

    .datatype-button {
        background: transparent;
        border: none;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.5px;
        padding: 4px 9px 4px 9px;
        color: @grey40;
        transition: all 100ms ease-in;
        border-radius: 15px;
        margin: 0;
    }

    .datatype-button.selected {
        background: @blue;
        color: @white;
        padding: 4px 9px 4px 9px;
        position: relative;
        top: -1px;
    }

    /* Remove outline for non-keyboard :focus */
    *:focus:not(.focus-visible) {
        outline: none;
    }
}
</style>
