export interface ILeaderboard {
    id: number
    name: string
    title: string
    rowlimit: number
    sortcolumn: string
    sortdir: string
    hidden?: number
    is_external?: number
    rows: ILeaderboardResult[]
    locked_filters?: string[]
}

export interface ILeaderboardResult {
    name: string
    displayName: string
    responded: number
    change: number
    nps: number
    previous: number
    rank: string | number
}

export interface ILeaderboardChartPoint {
    responded: number
    NPS: number
    group: string
    aggregate?: boolean
    subgroups?: ILeaderboardChartPoint[]
}

export interface Hierarchy {
    groupName: string
    fieldMapping: string
    rankWithin: string
    children: Hierarchy[]
    isEditing: boolean
}

export const LeaderboardChartColours = {
    dot: {
        highPerf: '#20E694',
        medPerf: '#708FFF',
        lowPerf: '#FF8F5A',
    },
    background: {
        highResp: {
            highPerf: '#E4FCF2',
            medPerf: '#E0E7FF',
            lowPerf: '#FEE7DC',
        },
        medResp: {
            highPerf: '#F2FDF9',
            medPerf: '#F2F5FF',
            lowPerf: '#FEF3EE',
        },
        lowResp: {
            highPerf: '#F8FEFC',
            medPerf: '#FAFBFF',
            lowPerf: '#FFF9F6',
        },
    },
    averageNps: {
        line: '#93A1BE',
        text: '#2F384C',
        border: '#2E5BFF',
    },
    npsBar: {
        highPerf: '#20E694',
        medPerf: '#93A1BE',
        lowPerf: '#E62020',
    },
    selectedGreys: {
        text: '#D2D8E4',
        box: '#F4F6FC',
        point: '#D2D8E4',
    },
    white: '#FFFFFF',
}

export const LeaderboardChartGroups = {
    highRespHighPerf: {
        left: 0,
        top: 0,
        width: 0,
        height: 0,
        groups: [],
        selected: false,
    },
    highRespMedPerf: {
        left: 0,
        top: 0,
        width: 0,
        height: 0,
        groups: [],
        selected: false,
    },
    highRespLowPerf: {
        left: 0,
        top: 0,
        width: 0,
        height: 0,
        groups: [],
        selected: false,
    },
    medRespHighPerf: {
        left: 0,
        top: 0,
        width: 0,
        height: 0,
        groups: [],
        selected: false,
    },
    medRespMedPerf: {
        left: 0,
        top: 0,
        width: 0,
        height: 0,
        groups: [],
        selected: false,
    },
    medRespLowPerf: {
        left: 0,
        top: 0,
        width: 0,
        height: 0,
        groups: [],
        selected: false,
    },
    lowRespHighPerf: {
        left: 0,
        top: 0,
        width: 0,
        height: 0,
        groups: [],
        selected: false,
    },
    lowRespMedPerf: {
        left: 0,
        top: 0,
        width: 0,
        height: 0,
        groups: [],
        selected: false,
    },
    lowRespLowPerf: {
        left: 0,
        top: 0,
        width: 0,
        height: 0,
        groups: [],
        selected: false,
    },
}

// This is a flat object because we iterate through it, and flat = simpler
export const LeaderboardChartBoxes = {
    highRespHighPerf: {
        x: 2,
        y: 0,
        colour: LeaderboardChartColours.background.highResp.highPerf,
    },
    medRespHighPerf: {
        x: 2,
        y: 1,
        colour: LeaderboardChartColours.background.medResp.highPerf,
    },
    lowRespHighPerf: {
        x: 2,
        y: 2,
        colour: LeaderboardChartColours.background.lowResp.highPerf,
    },
    highRespMedPerf: {
        x: 1,
        y: 0,
        colour: LeaderboardChartColours.background.highResp.medPerf,
    },
    medRespMedPerf: {
        x: 1,
        y: 1,
        colour: LeaderboardChartColours.background.medResp.medPerf,
    },
    lowRespMedPerf: {
        x: 1,
        y: 2,
        colour: LeaderboardChartColours.background.lowResp.medPerf,
    },
    highRespLowPerf: {
        x: 0,
        y: 0,
        colour: LeaderboardChartColours.background.highResp.lowPerf,
    },
    medRespLowPerf: {
        x: 0,
        y: 1,
        colour: LeaderboardChartColours.background.medResp.lowPerf,
    },
    lowRespLowPerf: {
        x: 0,
        y: 2,
        colour: LeaderboardChartColours.background.lowResp.lowPerf,
    },
}

export const LeaderboardChartCategories = {
    highRespHighPerf: 'High Impact, to Recognize',
    medRespHighPerf: 'Medium Impact, to Recognize',
    lowRespHighPerf: 'Low Impact, to Recognize',
    highRespMedPerf: 'High Impact, to Develop',
    medRespMedPerf: 'Medium Impact, to Develop',
    lowRespMedPerf: 'Low Impact, to Develop',
    highRespLowPerf: 'High Impact, to Coach',
    medRespLowPerf: 'Medium Impact, to Coach',
    lowRespLowPerf: 'Low Impact, to Coach',
}

export const LeaderboardChartColoursV2 = {
    dot: {
        highPerf: '#20E694',
        medPerf: '#708FFF',
        lowPerf: '#FF8F5A',
        aggregate: '#A5A5A5',
    },
    background: {
        highPerf: '#E4FCF2',
        medPerf: '#E0E7FF',
        lowPerf: '#FEE7DC',
    },
    averageNps: {
        line: '#93A1BE',
        text: '#2F384C',
        border: '#2E5BFF',
    },
    npsBar: {
        highPerf: '#20E694',
        medPerf: '#93A1BE',
        lowPerf: '#E62020',
    },
    selectedGreys: {
        titles: '#777E8A',
        text: '#6E7682',
        box: '#F4F6FC',
        point: '#D2D8E4',
    },
    white: '#FFFFFF',
}

export const LeaderboardChartGroupsV2 = {
    highPerf: {
        left: 0,
        top: 0,
        width: 0,
        height: 0,
        groups: [],
        selected: false,
    },
    medPerf: {
        left: 0,
        top: 0,
        width: 0,
        height: 0,
        groups: [],
        selected: false,
    },
    lowPerf: {
        left: 0,
        top: 0,
        width: 0,
        height: 0,
        groups: [],
        selected: false,
    },
}

// This is a flat object because we iterate through it, and flat = simpler
export const LeaderboardChartBoxesV2 = {
    highPerf: {
        x: 2,
        colour: LeaderboardChartColoursV2.background.highPerf,
    },
    medPerf: {
        x: 1,
        colour: LeaderboardChartColoursV2.background.medPerf,
    },
    lowPerf: {
        x: 0,
        colour: LeaderboardChartColoursV2.background.lowPerf,
    },
}

export const LeaderboardChartCategoriesV2 = {
    highPerf: 'To Recognize',
    medPerf: 'To Develop',
    lowPerf: 'To Coach',
}
