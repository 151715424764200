<template>
    <div>
        <slot v-if="showCompactLayout" name="compact" />
        <slot v-if="showExpandedLayout" name="expanded" />
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'

type Layout = 'undefined' | 'compact' | 'expanded'

@Component
export default class SwitchLayoutContainer extends Vue {
    layout: Layout = 'undefined'

    get showCompactLayout() {
        return this.layout === 'compact'
    }

    get showExpandedLayout() {
        return this.layout === 'expanded'
    }

    mounted() {
        const mediaQueryList = window.matchMedia('(min-width: 768px)')

        this.layout = mediaQueryList.matches ? 'expanded' : 'compact'

        mediaQueryList.addEventListener('change', this.updateLayout)
    }

    updateLayout(mediaQueryListEvent: MediaQueryListEvent) {
        this.layout = mediaQueryListEvent.matches ? 'expanded' : 'compact'
    }
}
</script>

<style scoped></style>
