import { ITimeRange } from '@/entities/insights'
import { TimeOptions } from '@/mobile/src/types/insights'
import vClickOutside from 'v-click-outside'
import { PropType } from 'vue'
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { useRoute } from 'vue-router'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faCheck } from 'fontawesome/free-solid-svg-icons'

@Component({
    components: {
        FontAwesomeIcon,
    },
    directives: {
        clickOutside: vClickOutside.directive,
    },
})
export default class InsightsTimePicker extends Vue {
    @Prop({
        type: Object as PropType<ITimeRange>,
        default: () => ({ timeUnit: 'month', timeValue: 1 }),
    })
    timeRange!: ITimeRange

    readonly options = TimeOptions
    readonly faCheck = faCheck
    opened = false

    protected route = useRoute()

    isOptionSelected({ timeRange }) {
        return (
            this.timeRange.timeUnit == timeRange.timeUnit &&
            this.timeRange.timeValue == timeRange.timeValue
        )
    }
}
