import { ajaxClient as client } from '@/api/client'
import cachedClient from '@/api/clientCached'
import { IPlaybook } from '@/entities/playbook'

export async function savePlaybook(playbook: IPlaybook) {
    return await client.post<IPlaybook>(`/playbook`, playbook)
}

export async function savePlaybookNotice(playbookId, playbookNotice) {
    return await client.post(`/playbooknotice`, { playbookId, playbookNotice })
}

export async function deletePlaybookNotice(playbookId, playbookNoticeId) {
    return await client.delete(
        `/playbook/${playbookId}/notices/${playbookNoticeId}`
    )
}

export async function deletePlaybook(playbookId) {
    return await client.delete(`/playbook/${playbookId}`)
}

export async function loadPlaybooks() {
    return await client.get<IPlaybook>(`/playbook`)
}

export async function loadPlaybook(playbookId) {
    return await client.get(`/playbook/${playbookId}`)
}

export async function loadPlaybookScorecardOptions() {
    return await client.get(`/playbook-scorecard-options`)
}
