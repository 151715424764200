import { IScorecardSetting } from '@/pages/appstore/components/Scorecard/scorecard-settings-entity'

export function getScorecardFieldNameById(
    scorecardList: IScorecardSetting[],
    field: string,
    value: string
) {
    let translatedValue = value
    scorecardList.forEach((scorecard, idx) => {
        if (scorecard.field === field) {
            const matches = scorecard.scorecardTopics.filter(
                ({ id }) => id === parseInt(value)
            )
            if (matches.length > 0) {
                translatedValue = matches[0].topic_name
            }
        }
    })

    return translatedValue
}
