<template>
    <BaseCard
        :class="[
            'team-member-tile',
            { 'team-member-selected': isTeamMemberSelected },
        ]"
        @click="onCardClick"
    >
        <div class="team-member-tile-avatar">
            <AvatarOrInitials :user="data.user" :size="48" />
        </div>
        <div class="team-member-tile-topic">
            <div class="team-member-tile-name">{{ name }}</div>
            <TopicBadge :topic="topic" :badge="badge" :font-size="10" />
        </div>
        <div class="team-member-tile-score">
            {{ data.score_current.toFixed(1) }}
            <UpDownArrow
                v-if="data.score_change !== 0"
                :positive="data.score_change > 0"
                class="change"
            />
        </div>
    </BaseCard>
</template>

<script lang="ts">
import { Component } from 'vue-facing-decorator'
import TeamMemberTile from '@/pages/insights/TeamMemberTile'
import { Getter } from 'vuex-facing-decorator'

@Component({})
export default class TeamMemberTileWeb extends TeamMemberTile {
    @Getter
    readonly selectedTeamMemberId!: null | number

    get isTeamMemberSelected() {
        return this.data.user.id === this.selectedTeamMemberId
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour';

.team-member-tile {
    display: flex;

    .team-member-tile-avatar {
        flex: 1;
    }

    .team-member-tile-topic {
        flex: 2;
    }

    .team-member-tile-score {
        flex: 1;
    }

    &.team-member-selected {
        border-color: @primaryBlue;
    }
}
</style>
