export default {
    state: {
        $companyVars: {},
        $isMobile: false,
    },
    getters: {
        $companyVars: (state) => state.$companyVars ?? null,
        $isMobile: (state) => state.$isMobile,
        $isIndevTenant: (state) => {
            const indevDomains = ['jarvisfitnessdev']
            return indevDomains.indexOf(state.$companyVars.domain) >= 0
        },
    },
    mutations: {
        $setCompanyData(state, data) {
            state.$companyVars = data
        },
        $setIsMobile(state, isMobile) {
            state.$isMobile = isMobile
        },
    },
}
