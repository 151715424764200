import client from '@/api/client'

export async function getMFACode(setup = true) {
    //if setup is false then it wipes the existing MFA secret
    const formData = new FormData()
    formData.append('mfa', String(setup))
    return client.post(`/user/settings/mfa-code`, formData)
}

export async function verifyMFACode(mfa_code: string) {
    try {
        const formData = new FormData()
        formData.append('mfaCode', mfa_code)
        const result = await client.post(
            `/user/settings/mfa-code/verify`,
            formData
        )
        return !!result.data.success
    } catch (e: any) {
        return false
    }
}

export async function getRecoveryCodes() {
    try {
        const result = await client.get(`/user/settings/recovery-codes`)

        if (result.data.success) return result.data.recovery_codes

        return false
    } catch (e: any) {
        return false
    }
}
