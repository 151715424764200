<template>
    <FeedbackBaseCard
        :timestamp="Number(response.responded)"
        :author="responseAuthor"
        :text="responseComment"
        :text-italic="isNoCommentResponse"
        :is-starred="response.isStarred"
        :comment-count="commentCount"
        :comment-count-class="
            getMetricStyle(response.question_type, Number(response.answer))
        "
        :show-star="detailedView"
        :detailed-view="detailedView"
        :emoji-view="detailedView"
        :emoji-reactions="emojiReactions"
        @onReact="onReact"
        @toggleStarred="onToggleStarred"
        @viewDetail="viewDetail"
    >
        <template #icon>
            <ResponseCircle
                :score="Number(response.answer)"
                :variation="response.question_type"
            />
        </template>
        <template #title>
            <template v-if="responseTopic">
                {{ responseTopic }}
            </template>
            <template v-else> No Topic Provided </template>
        </template>
        <template #extraDetails>
            <div
                v-for="(customDataField, customDataIdx) in customDataSet"
                :key="customDataIdx"
                class="custom-data-container"
            >
                <div
                    v-if="isAiQuestion(customDataField.key)"
                    class="custom-data-label"
                >
                    {{ customDataField.question_text }}
                </div>
                <div v-else class="custom-data-label">
                    {{ friendlyFieldName(customDataField.key) }}
                </div>
                <div class="custom-data-value">{{ customDataField.value }}</div>
            </div>
        </template>
    </FeedbackBaseCard>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import ResponseCircle from '@/components/ResponseCircle.vue'
import FeedbackBaseCard from '@/mobile/src/components/appV4/feedback/FeedbackBaseCard.vue'
import {
    EmojiReactionDetails,
    EmojiReactionRequest,
    IEmojiReactionUpdateData,
    IOnReact,
    IResponse,
    noCommentResponse,
} from '@/entities'
import { updateEmojiReactionDetails } from '@/mobile/src/utils/emojireactions'
import { updateEmoji } from '@/api/chat'
import { getCommentCount } from '@/mobile/src/utils/feedback'
import { CustomerInfo } from '@/entities/response'
import fname from '@/utils/string'

@Component({
    components: {
        ResponseCircle,
        FeedbackBaseCard,
    },
    emits: ['toggleStarred', 'viewDetail'],
})
export default class FeedbackResponseCard extends Vue {
    @Prop({ type: Object, required: true }) response!: IResponse
    @Prop({ required: false }) customDataSet?: any
    @Prop({ type: Boolean, required: false, default: false })
    detailedView!: boolean
    @Prop({ required: false }) customData?: any

    public get responseTopic() {
        if (!this.response?.badges || this.response?.badges.length == 0) {
            return ''
        }

        return this.response?.badges[0].label
    }

    public get responseComment() {
        if (this.response.comment) {
            return this.response.comment
        }

        return noCommentResponse
    }

    public get isNoCommentResponse() {
        return this.responseComment === noCommentResponse
    }

    public get responseAuthor() {
        return this.response.person_name === CustomerInfo.anon.toString()
            ? 'Anonymous'
            : this.response.person_name
    }

    private get emojiReactions(): EmojiReactionDetails[] {
        if (this.response.emojis) {
            return this.response.emojis
        }

        return []
    }

    private get emojiRequestData(): EmojiReactionRequest {
        return {
            type: this.response.type ?? '',
            relid: this.response.id, // this is the same as question.id for question records
        }
    }

    public async onToggleStarred() {
        this.$emit('toggleStarred', { questionId: this.response.id })
    }

    public get commentCount(): number {
        return getCommentCount(this.response)
    }

    public getMetricStyle(
        questionType: string | undefined,
        score: number
    ): 'positive' | 'negative' | 'neutral' {
        // TODO: comment count colour will be determined by unread
        return 'neutral'
    }

    viewDetail() {
        this.$emit('viewDetail', this.response.id)
    }

    private async onReact(data: IOnReact) {
        const updateData: IEmojiReactionUpdateData = {
            emoji: data.emoji,
            emojis: this.response.emojis,
        }

        try {
            updateEmojiReactionDetails(updateData)
            this.response.emojis = updateData.emojis
            await updateEmoji(
                this.emojiRequestData.type,
                this.emojiRequestData.relid,
                data.emoji
            )
        } catch (e) {
            // Undo the count and self flag change.
            // For some reason, anything that isn't status 200 is thrown.
            updateEmojiReactionDetails(updateData)
        }
    }

    public friendlyFieldName(str) {
        let suffix = ''
        if (str === 'initial_score') {
            str =
                this.customData.question_type === 'nps'
                    ? 'NPS Rating'
                    : 'Rating'
        }

        const label = this.customData.custom_field_labels[str + '_c']
            ? this.customData.custom_field_labels[str + '_c']
            : str

        return fname(label) + suffix
    }

    public isAiQuestion(str) {
        return str.includes('ai_question_')
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/variables.less';
@import '~@/styles/rain/colour.less';

.custom-data-container {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding: 20px 20px 20px 70px;

    .custom-data-label {
        color: #fff;
        font-size: @fontSize-sm;
        font-weight: @fontWeight-medium;
        letter-spacing: @letterSpacing-2xl;
        line-height: @lineHeight-sm;
        padding-bottom: 6px;
        text-transform: uppercase;
    }
    .custom-data-value {
        color: rgba(255, 255, 255, 0.9);
        font-size: @fontSize-base;
        font-weight: @fontWeight-regular;
        letter-spacing: @letterSpacing-md;
        line-height: @lineHeight-base;
    }
}
</style>
