export interface MultiCheckboxItem {
    id: number | string
    title: string
    checked: boolean
    display: boolean
    parentTitle?: string
}

export interface StripInputOption {
    key: string
    value: string
    selected: boolean
    display: string
}

export interface ObjectOptions {
    [key: string]: MultiCheckboxItem[]
}

export interface ErrorMessage {
    [key: string]: string
}

export const dumbDataSources: ObjectOptions = {
    'CSV Importer': [
        {
            id: 2,
            title: 'My Event Data',
            checked: false,
            display: true,
            parentTitle: 'CSV Importer',
        },
    ],
    Salesforce: [
        {
            id: 4,
            title: 'APAC Suport',
            checked: false,
            display: true,
            parentTitle: 'Salesforce',
        },
        {
            id: 5,
            title: 'Europe Tickets',
            checked: false,
            display: true,
            parentTitle: 'Salesforce',
        },
        {
            id: 6,
            title: 'America Event',
            checked: false,
            display: true,
            parentTitle: 'Salesforce',
        },
        {
            id: 7,
            title: 'China Event',
            checked: false,
            display: true,
            parentTitle: 'Salesforce',
        },
    ],
}

export const initDayOptions: StripInputOption[] = [
    {
        key: 'M',
        value: '1',
        selected: true,
        display: 'Monday',
    },
    {
        key: 'T',
        value: '2',
        selected: false,
        display: 'Tuesday',
    },
    {
        key: 'W',
        value: '3',
        selected: false,
        display: 'Wednesday',
    },
    {
        key: 'T',
        value: '4',
        selected: false,
        display: 'Thursday',
    },
    {
        key: 'F',
        value: '5',
        selected: false,
        display: 'Friday',
    },
    {
        key: 'S',
        value: '6',
        selected: false,
        display: 'Saturday',
    },
    {
        key: 'S',
        value: '7',
        selected: false,
        display: 'Sunday',
    },
]
