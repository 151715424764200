import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8c54b15e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "engagement-focus-question" }
const _hoisted_2 = { class: "top" }
const _hoisted_3 = { class: "icon" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "question" }
const _hoisted_6 = { class: "middle" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "label" }
const _hoisted_10 = {
  key: 0,
  class: "comment"
}
const _hoisted_11 = { class: "bottom" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FlexiMultilineTextInput = _resolveComponent("FlexiMultilineTextInput")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", { src: _ctx.icon }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.question.question), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.question.options, (opt) => {
        return _withDirectives((_openBlock(), _createElementBlock("div", {
          key: opt.value,
          class: _normalizeClass(["focus-question-option", { selected: _ctx.question.answer === opt.value }]),
          onClick: ($event: any) => (_ctx.onClickOption(opt))
        }, [
          _createElementVNode("img", {
            width: "40",
            height: "40",
            src: _ctx.getBadgeImg(opt)
          }, null, 8, _hoisted_8),
          _createElementVNode("div", _hoisted_9, _toDisplayString(opt.label), 1)
        ], 10, _hoisted_7)), [
          [_vShow, !_ctx.question.answer || _ctx.question.answer === opt.value]
        ])
      }), 128)),
      (_ctx.question.answer !== null)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createVNode(_component_FlexiMultilineTextInput, {
              value: _ctx.question.comment ? _ctx.question.comment : '',
              "blur-on-new-line": true,
              placeholder: "Want to share more…",
              onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update', { key: 'comment', value: $event })))
            }, null, 8, ["value"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createVNode(_component_BaseButton, {
        full: "",
        disabled: !_ctx.question.answer,
        onClick: _ctx.submitAnswer
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Done ")
        ], undefined, true),
        _: 1
      }, 8, ["disabled", "onClick"])
    ])
  ]))
}