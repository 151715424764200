<template>
    <div :style="badgeStyle" class="topic-badge">
        {{ topic }}
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { badgeSchemas } from '@/entities/scorecard'
import { palette } from '@/utils/palette'

@Component({})
export default class TopicBadge extends Vue {
    @Prop({ type: String, default: null })
    private topic!: string

    @Prop({ type: String, default: null })
    private badge!: string

    @Prop({ type: Number, default: 12 })
    private fontSize!: number

    get badgeStyle() {
        let style = `font-size: ${this.fontSize}px;`
        if (badgeSchemas[this.badge]) {
            const { foreColor, backColor } = badgeSchemas[this.badge]
            style += `backgroundColor: ${backColor}; color: ${foreColor}`
        } else {
            style += `backgroundColor: ${palette.greyLight}; color: ${palette.greyMid4};`
        }

        return style
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/typography';

.topic-badge {
    width: fit-content;
    padding: 2px 4px;
    border-radius: 4px;
}

.topic-badge-positive {
    background: @positiveBg;
    color: @positive;
}

.topic-badge-negative {
    background: @negativeBg;
    color: @negative;
}
</style>
