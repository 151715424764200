import { ProgressType } from '@/utils/importer/const'

export const NOTIFICATION_DEFAULT_DURATION = 6000
export const TRACKER_INTERVAL = 60000

export type Notification = {
    id: string
    message: string
}

export type CsvImporterTracker = {
    id: string
    type: ProgressType
    name: string
    tracked: boolean
}

export const initCsvImporterTracker: CsvImporterTracker = {
    id: '-1',
    type: ProgressType.Upload,
    name: '',
    tracked: false,
}
