<template>
    <div class="insight-engagement-spotlight-list-card">
        <div :class="['header', { wide: wideLayout }]">
            <div class="title">Team Members</div>
            <div id="engagement-spotlight-info" class="title">
                Engagement
                <img src="@/assets/img/Icons/info.svg" />
            </div>
        </div>
        <div v-if="spotlightTeam && spotlightTeam.length">
            <InsightsEngagementListItem
                v-for="(teamUser, i) in teamUsers"
                :key="teamUser.id"
                :selected="
                    wideLayout &&
                    teamUser.id === desktopEngagementUserIdSelected
                "
                :title="teamUser.name"
                :show-chevron="showChevron"
                :wide-layout="wideLayout"
                :description="tileDescription(teamUser)"
                :class="getListBorder(i, teamUsers.length, teamUser.id)"
                :delta-positive="tilePercentageChange(teamUser) > 0"
                :previous-percentage="tilePercentagePrevious(teamUser)"
                :percentage="tilePercentage(teamUser)"
                :score="Math.abs(tilePercentageChange(teamUser))"
                @click="onClickTeamMember(teamUser, i)"
            >
                <template #icon>
                    <AvatarOrInitials
                        :size="44"
                        :user="userDetails(teamUser)"
                    />
                </template>
                <template #badge>
                    <div class="badge">
                        <BestWorstTopicTag
                            v-if="teamUser.recursive_data"
                            :positive="
                                themeWithMostChange(teamUser)
                                    .change_in_term_data.score > 0
                            "
                            :title="themeWithMostChange(teamUser).name"
                        />
                    </div>
                </template>
            </InsightsEngagementListItem>
        </div>
    </div>
</template>

<script lang="ts">
import { EngagementInsightsItem } from '@/entities/insights'
import { Component, Prop, Vue } from 'vue-facing-decorator'
import GradientHighlightedCard from '@/components/GradientHighlightedCard.vue'
import InsightsEngagementListItem from '@/mobile/src/components/Insights/InsightsEngagementListItem.vue'
import AvatarOrInitials from '@/components/WebView/AvatarOrInitials.vue'
import BestWorstTopicTag from '@/mobile/src/components/BestWorstTopicTag.vue'
import { Action, Getter } from 'vuex-facing-decorator'
import { useRouter } from 'vue-router'

@Component({
    components: {
        BestWorstTopicTag,
        AvatarOrInitials,
        InsightsEngagementListItem,
        GradientHighlightedCard,
    },
})
export default class InsightsEngagementSpotlightList extends Vue {
    @Prop({ required: true, default: [] })
    spotlightTeam

    @Prop({ required: false, default: false })
    wideLayout

    @Prop({ required: false, default: false })
    showChevron

    @Prop({ required: false, default: 'ALL' })
    theme

    @Prop({ type: Boolean, required: false, default: true })
    public readonly onSidebar!: boolean

    @Getter
    readonly desktopEngagementUserIdSelected!: string

    @Getter
    readonly $isMobile

    @Action
    setDesktopEngagementUserIdSelected

    public currentPosition = 0

    private router = useRouter()

    mounted() {
        if (!this.$isMobile && this.wideLayout) {
            this.setDesktopEngagementUserIdSelected(this.teamUsers[0]?.id)
        }
    }

    public get teamUsers() {
        return this.spotlightTeam.sort(
            (a, b) =>
                Math.abs(b.change_in_term_data.score) -
                Math.abs(a.change_in_term_data.score)
        )
    }

    public tileDescription(teamUser) {
        if (teamUser.scorecard_score.type === 'nps') {
            return teamUser.scorecard_score.value + ' NPS'
        }
        return teamUser.scorecard_score.value
    }

    public tilePercentage(teamUser) {
        return Math.round(teamUser.current_term_data.score) + '%'
    }

    public tilePercentagePrevious(teamUser) {
        return Math.round(teamUser.previous_term_data.score) + '%'
    }

    public tilePercentageChange(teamUser) {
        return (
            Math.round(teamUser.current_term_data.score) -
            Math.round(teamUser.previous_term_data.score)
        )
    }

    public themeWithMostChange(teamUser) {
        return teamUser.recursive_data.reduce((a, b) =>
            a.change_in_term_data.score > b.change_in_term_data.score ? a : b
        )
    }

    public userDetails(teamUser) {
        return {
            id: teamUser.id,
            name: teamUser.name,
            avatar: teamUser.avatar,
        }
    }

    onClickTeamMember(teamUser: EngagementInsightsItem, position = 0) {
        this.setDesktopEngagementUserIdSelected(teamUser.id)
        //assigns the currentPosition of the selected teammember
        this.currentPosition = position
        this.router
            .push({
                name: 'InsightsEngagementTeamThemeSpotlightDetail',
                params: { teamMemberUserId: teamUser.id },
            })
            .catch((e) => e)

        //For Engagement Team Spotlight list, when the list is too long,
        //we scroll back up to show selected the member information
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    public getListBorder(i, length, id) {
        if (
            i + 1 < length &&
            this.wideLayout &&
            id !== this.desktopEngagementUserIdSelected &&
            this.currentPosition !== i + 1
        ) {
            return 'bottom-border'
        } else if (i + 1 < length && !this.wideLayout && this.onSidebar) {
            return 'sidebar-border'
        } else {
            return 'no-border'
        }
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/variables';
@import '~@/styles/typography';

.insight-engagement-spotlight-list-card {
    padding: 12px;

    .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 14px;

        &.wide {
            padding: 0 @gutterSpacing-base * 1.5;
        }

        .title {
            .title();
            line-height: 16px;
            display: flex;
            align-items: center;
            img {
                margin-left: 4px;
            }
        }
    }

    .badge {
        width: fit-content;
    }

    .bottom-border {
        border-bottom: 1px solid @blueLight;
    }
    .no-border {
        border-bottom: 0px solid @white !important;
    }
    .sidebar-border {
        border-bottom: 1px solid @blueLight;
    }
}
</style>
