<template>
    <div class="engagement-positive-feedback">
        <div class="top">
            <div>
                <Lottie
                    v-if="animationData"
                    class="bg-img-container"
                    :animation-data="animationData"
                />
            </div>
            <div class="engagement-positive-feedback-details">
                <div class="header">{{ response.badges[0].label }}!</div>
                <div class="feedback">
                    You’ve recently received a positive response in
                    {{ response.badges[0].topic_name }}. Well done! Here’s what
                    the customer said:
                </div>
                <div>
                    <ScorecardResponseTile
                        :response="response"
                        :scorecard="'communication'"
                        class="scorecard-response"
                    />
                </div>
            </div>
        </div>
        <div class="bottom">
            <template v-if="seeMoreResponses">
                <BaseButton
                    primary
                    full
                    large
                    :centred="true"
                    @click="goToMoreResponses"
                >
                    See More Responses
                </BaseButton>
                <BaseButton
                    full
                    large
                    variant="ghost"
                    :centred="true"
                    @click="submitAnswer"
                >
                    Dismiss
                </BaseButton>
            </template>
            <template v-else>
                <BaseButton full @click="submitAnswer"> Continue </BaseButton>
            </template>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { useRouter, useRoute } from 'vue-router'
import { IResponse } from '@/entities'
import ScorecardResponseTile from '@/components/ScorecardResponseTile.vue'
import { Vue3Lottie as Lottie } from 'vue3-lottie'
import BaseButton from '@/components/Rain/Buttons/BaseButton.vue'

@Component({
    components: {
        BaseButton,
        ScorecardResponseTile,
        Lottie,
    },
    emits: ['done'],
})
export default class EngagementPositiveFeedback extends Vue {
    @Prop({ type: Object, required: true })
    public response!: IResponse

    @Prop({ type: Boolean, default: false })
    public seeMoreResponses!: boolean

    public animationData = null

    private router = useRouter()
    private route = useRoute()

    public async mounted() {
        if (this.response.badges && this.response.badges[0].label) {
            this.animationData = (
                await import(
                    `@/assets/img/scorecard/animated/${this.response.badges[0].badge}.json`
                )
            ).default
        }
    }

    public goToMoreResponses() {
        // don't redirect to the same page
        if (
            this.route.name !== 'scorecardBadge' &&
            this.response.badges &&
            this.route.params.value !== this.response.badges[0].badge_id
        ) {
            this.router.push({
                name: 'scorecardBadge',
                params: { value: this.response.badges[0].badge_id },
            })
        }
        this.$emit('done')
    }

    public submitAnswer() {
        this.$emit('done')
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/variables';
@import '~@/styles/typography';
@import '~@/styles/palette';

.engagement-positive-feedback {
    flex-direction: column;
    flex: 1;
    display: flex;

    .top {
        flex: 1;

        .bg-img-container {
            border-radius: 8px 8px 0 0;
        }

        .engagement-positive-feedback-details {
            margin: 12px;

            .header {
                margin-bottom: 22px;
                text-align: center;
                .heading();
            }

            .feedback {
                margin-bottom: 32px;
                text-align: center;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                color: @black80;
            }
        }
    }

    .bottom {
        flex: 0;
        padding: 16px;

        button {
            margin-top: 12px;
        }
    }
}
</style>
<style lang="less">
// force border radius for Lottie svg file on top of modal
.engagement-positive-feedback {
    .bg-img-container {
        svg {
            border-radius: 8px 8px 0 0;
        }
    }
}
</style>
