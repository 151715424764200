import { ActionTree, MutationTree, GetterTree } from 'vuex'
import {
    Notification,
    NOTIFICATION_DEFAULT_DURATION,
    CsvImporterTracker,
    initCsvImporterTracker,
} from '@/entities/rain/toast-notifications'

export interface ToastNotificationState {
    notifications: Notification[]
    csvImporterTracker: CsvImporterTracker
}

export const state: ToastNotificationState = {
    notifications: [],
    csvImporterTracker: initCsvImporterTracker,
}

const actions: ActionTree<ToastNotificationState, any> = {
    addNotification({ commit }, message: string) {
        const id = 'rain' + Date.now()
        const notification = {
            id,
            message,
        }
        commit('addNotification', notification)

        setTimeout(() => {
            commit('removeNotification', id)
        }, NOTIFICATION_DEFAULT_DURATION)
    },
    removeNotification({ commit }, notificationId: string) {
        commit('removeNotification', notificationId)
    },
}

const mutations: MutationTree<ToastNotificationState> = {
    addNotification(state, notification) {
        state.notifications = state.notifications.concat(notification)
    },
    removeNotification(state, notificationId) {
        state.notifications = state.notifications.filter(
            ({ id }) => id !== notificationId
        )
    },
    updateCsvImporterTracker(state, tracker) {
        state.csvImporterTracker = tracker
    },
}

const getters: GetterTree<ToastNotificationState, any> = {
    getNotifications: ({ notifications }) => notifications,
    getCsvImporterTracker: ({ csvImporterTracker }) => csvImporterTracker,
    getCsvImporterTracked: ({ csvImporterTracker }) =>
        csvImporterTracker.tracked,
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
}
