import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PillButton = _resolveComponent("PillButton")!

  return (_openBlock(), _createBlock(_component_PillButton, {
    class: _normalizeClass(_ctx.composerType),
    "is-active": _ctx.isActive,
    disabled: _ctx.disabled,
    label: _ctx.label,
    onClick: _ctx.onClick
  }, null, 8, ["class", "is-active", "disabled", "label", "onClick"]))
}