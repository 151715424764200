import { ActionTree, MutationTree, GetterTree } from 'vuex'
import { CrosstabDataMap } from '@/entities'
import { getCrossTabResult } from '@/api/crosstab'
import objectHash from 'object-hash'
import fname, { ucfirst } from '@/utils/string'

export interface CrosstabState {
    crosstabDataMap: CrosstabDataMap
}

export const state: CrosstabState = {
    crosstabDataMap: {},
}

const getters: GetterTree<CrosstabState, any> = {
    crosstabDataMap({ crosstabDataMap }) {
        return crosstabDataMap
    },

    fields(state, getters, rootState) {
        const normalFields = rootState.filter.ruleOptions.filter((option) =>
            /(_c|theme|segment)$/.test(option.value)
        )
        const convQuestions = rootState.dashboard.convQuestions || {}

        const grouppedNormalFields = normalFields.map((field) => {
            const label =
                (convQuestions[field.value] &&
                    convQuestions[field.value].question) ||
                field.label
            const group = label === field.label ? 'Custom Data' : 'Questions'

            return { ...field, label: ucfirst(label), group }
        })
        return ['rating', 'comments', 'messaged', 'case']
            .map((value) => ({ label: fname(value), value, group: 'General' }))
            .concat(grouppedNormalFields)
    },
}

const actions: ActionTree<CrosstabState, any> = {
    async getCrosstabData(
        { commit, state, rootState, rootGetters },
        { row, col }
    ) {
        if (!row || !col) {
            return null // no result
        }
        const key = objectHash({ col, row, filter: rootGetters.filterActive })

        // return existing cached data
        if (state.crosstabDataMap[key]) {
            return state.crosstabDataMap[key]
        }

        // ajax fetch data
        const { data } = await getCrossTabResult(row, col)
        commit('setCrosstabData', { key, data })
        return data
    },
}

const mutations: MutationTree<CrosstabState> = {
    setCrosstabData(state, { key, data }) {
        state.crosstabDataMap[key] = data
    },
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations,
}
