import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-820dce74"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['flexible-image-box', _ctx.extraClass]),
    style: _normalizeStyle(_ctx.divStyle)
  }, [
    _createElementVNode("img", {
      src: _ctx.src,
      alt: _ctx.alt
    }, null, 8, _hoisted_1)
  ], 6))
}