import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FeedbackActionsBar = _resolveComponent("FeedbackActionsBar")!
  const _component_LoadingCard = _resolveComponent("LoadingCard")!
  const _component_FeedbackResponseCard = _resolveComponent("FeedbackResponseCard")!
  const _component_MomentCard = _resolveComponent("MomentCard")!
  const _component_ItemDetail = _resolveComponent("ItemDetail")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.hasNetworkConnection)
      ? (_openBlock(), _createBlock(_component_FeedbackActionsBar, {
          key: 0,
          "feedback-id": Number(_ctx.feedbackid),
          onCaseUpdated: _ctx.onMessageSent
        }, null, 8, ["feedback-id", "onCaseUpdated"]))
      : _createCommentVNode("", true),
    _createVNode(_component_ItemDetail, {
      feedbackid: _ctx.feedbackid,
      onMessageSent: _ctx.onMessageSent
    }, {
      detailContent: _withCtx(({ undoChat: onUndoChat }) => [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_LoadingCard, {
              key: 0,
              borderless: true,
              variant: "single"
            }))
          : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.visibleChatMessages, (chat, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: _normalizeClass({ 'last-card': _ctx.isLastCard(index) })
              }, [
                (chat.type === 'question')
                  ? (_openBlock(), _createBlock(_component_FeedbackResponseCard, {
                      key: 'frc' + chat.id,
                      "detailed-view": "",
                      response: chat,
                      "custom-data-set": _ctx.customDataSet,
                      "custom-data": _ctx.chatCustomData,
                      onToggleStarred: _ctx.onToggleStarred
                    }, null, 8, ["response", "custom-data-set", "custom-data", "onToggleStarred"]))
                  : (_openBlock(), _createBlock(_component_MomentCard, {
                      key: 'mc' + chat.id,
                      ref_for: true,
                      ref: _ctx.chatSeenRef(chat),
                      "emoji-view": _ctx.showEmoji(chat),
                      "detailed-view": "",
                      "disable-highlight": "",
                      moment: chat,
                      onOnReact: _ctx.onReact,
                      onUndoChat: onUndoChat,
                      onReloadChat: _ctx.reloadChat,
                      onHardReloadChat: _ctx.hardReloadChat
                    }, null, 8, ["emoji-view", "moment", "onOnReact", "onUndoChat", "onReloadChat", "onHardReloadChat"]))
              ], 2))
            }), 128))
      ]),
      _: 1
    }, 8, ["feedbackid", "onMessageSent"])
  ]))
}