import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3af58540"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "circle-container"
}
const _hoisted_2 = { class: "right" }
const _hoisted_3 = { class: "user-info" }
const _hoisted_4 = { class: "name" }
const _hoisted_5 = { class: "time-since" }
const _hoisted_6 = {
  ref: "comment",
  class: "comment"
}
const _hoisted_7 = {
  key: 0,
  style: {"font-style":"italic"}
}
const _hoisted_8 = { class: "buttons" }
const _hoisted_9 = {
  key: 0,
  class: "button internal-note"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "response",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickResponse && _ctx.onClickResponse(...args)))
  }, [
    (_ctx.type !== 'freetext')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            class: _normalizeClass([_ctx.getQuestionClass(_ctx.response), "circle"])
          }, _toDisplayString(_ctx.response.answer), 3)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.responseName), 1),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.respondedTimeSince(_ctx.response.responded)), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createTextVNode(_toDisplayString(_ctx.response.comment) + " ", 1),
        (!_ctx.response.comment)
          ? (_openBlock(), _createElementBlock("span", _hoisted_7, " No comment "))
          : _createCommentVNode("", true)
      ], 512),
      _createElementVNode("div", _hoisted_8, [
        (_ctx.canAddNote)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, " + Internal Note "))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}