<template>
    <button
        :class="[
            'button',
            variant,
            {
                'font-awesome': fontAwesome,
                full,
                disabled,
                loading,
            },
        ]"
        :disabled="disabled"
        @click="handleClick"
    >
        <div :class="[{ 'loading-content': loading }]">
            <FontAwesomeIcon
                v-if="fontAwesomeSources[fontAwesome]"
                :icon="fontAwesomeSources[fontAwesome]"
                class="font-awesome"
            />
            <slot />
        </div>
        <SimpleSpinner
            v-if="loading"
            class="loading-spinner"
            size="small"
            line-bg-color="transparent"
            :line-fg-color="spinnerColors[variant]"
        />
    </button>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import SimpleSpinner from '@/components/SimpleSpinner.vue'
import {
    faStar,
    faCheck,
    faChevronDown,
} from 'fontawesome/free-solid-svg-icons'

type VariantType = 'primary' | 'secondary' | 'ghost'
type fontAwesomeType = 'faStar' | 'faCheck' | 'faChevronDown'

const fontAwesomeSources = {
    faStar,
    faCheck,
    faChevronDown,
}

const spinnerColors = {
    primary: '#FFFFFF',
    secondary: '#2E5BFF',
    ghost: '#2F384C',
}

@Component({
    components: {
        SimpleSpinner,
        FontAwesomeIcon,
    },
    emits: ['click'],
})
export default class BaseButton extends Vue {
    @Prop({ type: Boolean, required: false, default: false })
    public readonly full!: boolean
    @Prop({ type: Boolean, required: false, default: false })
    public readonly disabled!: boolean
    @Prop({ type: Boolean, required: false, default: false })
    public readonly loading!: boolean
    @Prop({ type: String, required: false, default: 'primary' })
    public readonly variant!: VariantType
    @Prop({ type: String, required: false, default: '' })
    public readonly fontAwesome!: fontAwesomeType

    public fontAwesomeSources = fontAwesomeSources
    public spinnerColors = spinnerColors

    public handleClick(event: Event) {
        this.$emit('click', event)
    }
}
</script>

<style scoped lang="less">
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';

.button {
    position: relative;
    box-sizing: border-box;
    padding: @gutterSpacing-base @gutterSpacing-lg;
    font-size: @fontSize-base;
    font-family: @fontFamily;
    font-style: normal;
    line-height: @lineHeight-base;
    letter-spacing: @letterSpacing-2xl;
    text-transform: capitalize;
    cursor: pointer;
    border: none;
    border-radius: @borderRadius-base;
    transition: all @duration @easing;

    &.loading {
        cursor: wait;
    }

    &.font-awesome {
        padding: @gutterSpacing-base @gutterSpacing-md;
    }

    .loading-content {
        visibility: hidden;
    }

    .loading-spinner {
        position: absolute;
        left: 0;
        right: 0;
        top: calc(50% - 10px);
    }

    .font-awesome {
        height: 17px;
        width: 16px;
        margin-left: 7px;
        display: inline-block;
        position: relative;
        right: 5px;
        top: 1px;
    }

    &.full {
        width: 100%;
    }

    &.primary {
        color: @white;
        background-color: @primaryBlue;

        &:hover {
            background-color: @hoverBlue;
        }

        &.disabled {
            background-color: @disabledPrimaryGrey;
            color: @grey;
            cursor: not-allowed;
        }
    }

    &.secondary {
        color: @primaryBlue;
        background-color: @secondaryLightBlue;

        &:hover {
            background-color: @hoverSecondaryLightBlue;
        }

        &.disabled {
            background-color: @disabledSecondaryGrey;
            color: @grey;
            cursor: not-allowed;
        }
    }

    &.ghost {
        color: @offBlack;
        background-color: transparent;

        &:hover {
            background-color: @lightGrey;
        }

        &.disabled {
            color: @grey;
            cursor: not-allowed;
        }
    }
}
</style>
