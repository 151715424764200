<template>
    <WebViewScreen
        class="pick-notification-value-container"
        no-pad
        :title="title"
    >
        <template #left-button>
            <BackButton />
        </template>
        <div class="pick-value-content">
            <div class="search">
                <input v-model="searchText" type="text" placeholder="search" />
            </div>
            <div v-if="currentFilter" class="values">
                <div
                    v-for="(value, idx) in allOptions"
                    :key="idx"
                    :class="[
                        'value',
                        { active: selectedValue === value[name] },
                    ]"
                    @click="onSelectOption(value[name])"
                >
                    <div class="tick">
                        <img
                            src="@/assets/img/Icons/Purple/Tick.svg"
                            alt="tick"
                        />
                    </div>
                    <div class="text">
                        {{
                            getFormattedText(
                                value[name] === undefined ? 'All' : value[name]
                            )
                        }}
                    </div>
                </div>
            </div>
            <Loading :loading="loading" />
        </div>
    </WebViewScreen>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import WebViewScreen from '@/components/WebView/WebViewScreen.vue'
import { Action, Getter, Mutation } from 'vuex-facing-decorator'
import { SettingFilter } from '@/mobile/src/store/modules/notificationSettings'
import { LooseObject } from '@/pages/appstore/entities/app'
import { ucfirst } from '@/utils/string'
import BackButton from '@/mobile/src/components/BackButton.vue'
import Loading from '@/components/Loading.vue'
import { sleep } from '@/utils/async'

@Component({
    components: {
        Loading,
        BackButton,
        WebViewScreen,
    },
})
export default class PickNotificationSettingValue extends Vue {
    @Prop({ type: String }) name!: string // the field to add

    @Getter
    private readonly generalFilters!: SettingFilter[]

    @Getter
    private readonly newFilterValues!: LooseObject

    @Mutation
    private setNewFilterValue

    @Getter
    private readonly customFieldValues!: SettingFilter[]

    @Action
    loadFieldValues

    private searchText = ''

    loading = true

    async mounted() {
        if (!this.currentFilter?.content) {
            this.loading = true
            await this.loadFieldValues(this.currentFilter!.value)
        }
        this.loading = false
    }

    private getFormattedText(text: string) {
        return ucfirst(text)
    }

    private get allFilters() {
        return [...this.generalFilters, ...this.customFieldValues]
    }

    private get currentFilter() {
        return this.allFilters.find((filter) => filter.value === this.name)
    }

    private get title() {
        return this.currentFilter ? this.currentFilter.label : ''
    }

    private get selectedValue() {
        return this.newFilterValues[this.name]
    }

    private get allOptions() {
        const re = new RegExp(this.searchText, 'i')
        let options = [{ [this.name]: undefined }]
        if (this.currentFilter?.content) {
            options = [...options, ...this.currentFilter.content]
        }
        return options.filter((value) => {
            if (!this.searchText) {
                return true
            }

            const fieldValue = value[this.name] as unknown
            return fieldValue && re.test(fieldValue as string)
        })
    }

    private onSelectOption(value) {
        this.setNewFilterValue({ [this.name]: value })
    }
}
</script>

<style lang="less" scoped>
@import '../../../../styles/palette.less';

.pick-value-content {
    height: 100%;
    color: @grey90;
    background: @greyLight;
    position: relative;

    .search {
        padding: 10px 15px 5px 15px;

        input {
            width: 100%;
            box-sizing: border-box;
            background: transparent;
            height: 34px;
            font-size: 16px;
            border-radius: 5px;
            border: none;
            background: @greyLight2;
            padding: 0 10px;
        }
    }

    .values {
        padding: 10px 0;

        .value {
            border-bottom: 1px solid @greyLight2;
            background: white;
            padding: 10px 15px;
            display: flex;
            align-items: center;
            height: 25px;

            .tick {
                width: 30px;
                display: flex;
                align-items: center;

                img {
                    display: none;
                }
            }

            &.active {
                img {
                    display: initial;
                }
            }
        }
    }
}
</style>
