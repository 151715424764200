<template>
    <button
        :class="[
            'button',
            {
                small,
                large,
                primary,
                newPrimary,
                caution,
                secondary,
                newSecondary,
                grey,
                red,
                green,
                white,
                transparent,
                muted,
                disabled,
                full,
                centred,
                bordered,
                outline,
                round,
            },
        ]"
        :disabled="disabled"
        :type="type"
        :data-id="dataIdAttribute"
        @click="handleClick"
    >
        <span :class="[{ executing: getShowSpinner }, 'btn-content-container']">
            <span><slot></slot></span>
            <img
                v-if="icon === 'plus'"
                src="@/assets/img/Icons/Grey/Plus.svg"
            />
            <img
                v-if="icon === 'chevron-down'"
                src="@/assets/img/Icons/Grey/Chevron Down.svg"
            />
        </span>
        <SimpleSpinner
            v-if="getShowSpinner"
            class="executing-spinner"
            size="small"
            line-bg-color="transparent"
            line-fg-color="#fff"
        />
    </button>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import SimpleSpinner from '@/components/SimpleSpinner.vue'

type Type = 'button' | 'reset' | 'submit'
/*
  Primary buttons have a colourful solid background
  Secondary have a white background and colourful text
  Both can be muted or disabled.
  The icon name, if any, is passed in.
*/

@Component({
    components: {
        SimpleSpinner,
    },
})
export default class BaseButton extends Vue {
    @Prop({ type: Function, required: false }) public click?: () => void
    @Prop({ type: Boolean, required: false }) public primary?: boolean
    @Prop({ type: Boolean, required: false }) public newPrimary?: boolean
    @Prop({ type: Boolean, required: false }) public grey?: boolean
    @Prop({ type: Boolean, required: false }) public white?: boolean
    @Prop({ type: Boolean, required: false }) public transparent?: boolean
    @Prop({ type: Boolean, required: false }) public red?: boolean
    @Prop({ type: Boolean, required: false }) public green?: boolean
    @Prop({ type: Boolean, required: false }) public secondary?: boolean
    @Prop({ type: Boolean, required: false }) public newSecondary?: boolean
    @Prop({ type: Boolean, required: false }) public caution?: boolean
    @Prop({ type: Boolean, required: false }) public muted?: boolean
    @Prop({ type: Boolean, required: false }) public disabled?: boolean
    @Prop({ type: Boolean, required: false }) public large?: boolean
    @Prop({ type: Boolean, required: false }) public small?: boolean
    @Prop({ type: Boolean, required: false }) public full?: boolean
    @Prop({ type: Boolean, required: false }) public centred?: boolean
    @Prop({ type: Boolean, required: false }) public bordered?: boolean
    @Prop({ type: Boolean, required: false }) public outline?: boolean
    @Prop({ type: Boolean, required: false }) public round?: boolean
    @Prop({ type: String, required: false }) public icon?: string
    @Prop({ type: String, required: false }) public dataIdAttribute?: string
    @Prop({ type: String, default: 'button' }) public type!: Type
    @Prop({ type: Boolean, default: true })
    public readonly showSpinner!: boolean // show spinner when executing ajax request
    @Prop({ type: Boolean, default: false })
    public readonly forceToShowSpinner!: boolean // show spinner for some ajax request via SQS worker

    public executing = false

    public get getShowSpinner() {
        return this.forceToShowSpinner || (this.showSpinner && this.executing)
    }

    public async handleClick() {
        if (this.executing) {
            return
        }
        if (this.click) {
            this.executing = true
            try {
                await this.click()
            } finally {
                this.executing = false
            }
        }
    }
}
</script>

<style scoped lang="less">
@import '../styles/palette';

.button {
    position: relative;
    box-sizing: border-box;
    padding: 8px 24px;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    line-height: 20px;
    display: inline-block;
    cursor: pointer;
    border: none;
    border-radius: 4px;

    &:focus,
    &:active {
        outline: 0;
    }
    &.round {
        border-radius: 20px;
        padding: 6px 16px;
        font-weight: 500;
    }
    &.small {
        font-size: 13px;
        line-height: 18px;
    }
    &.large {
        height: 48px;
        font-size: 16px;
        padding: 14px 20px;
    }
    &:not(:last-child) {
        margin-right: 10px;
    }
    .executing {
        visibility: hidden;
    }
    .executing-spinner {
        position: absolute;
        left: 0;
        right: 0;
        top: calc(50% - 10px);
    }

    .btn-content-container {
        display: flex;
        align-items: center;
    }
}
.centred {
    flex: 1;
    text-align: center;
    height: auto;
    padding: 12px 20px;
}
.full {
    width: 100%;
    text-align: center;
    margin-right: 0;
}
.centred.full {
    span {
        span {
            width: 100%;
        }
    }
}
.primary {
    background: @ctaColor;
    color: @white;
    &.outline {
        background: @white;
        color: @ctaColor;
        border: 0.5px solid @ctaColor;
    }
    &.caution {
        background: @cautionColor;
    }
    &.muted {
        background: @disabledColor;
        color: @textColor;
    }
    &.disabled {
        background: @disabledColor;
        color: @greyLight4;
        cursor: not-allowed;
    }
}
.newPrimary {
    background: @black80;
    color: @white;
    &.caution {
        background: @cautionColor;
    }
    &.muted {
        background: @disabledColor;
        color: @textColor;
    }
    &.disabled {
        background: @disabledColor;
        color: @greyLight4;
    }
}

.newSecondary {
    background: @blue10;
    color: @blue;
    font-weight: 500;
    &.disabled {
        background: @disabledColor;
        color: @greyLight4;
    }
}

.red {
    background: @orange;
    color: @white;
    &.outline {
        background: @white;
        color: @orange;
        border: 0.5px solid @orange;
    }
}
.green {
    background-color: @greenActive;
    color: @white;
    &.outline {
        background-color: @greenActive;
        color: @greenActive;
        border: 0.5px solid @greenActive;
    }
}

.grey {
    background: @greyMid3;
    color: @white;

    &.outline {
        background: @white;
        color: @black80;
        border: 0.5px solid @grey40;
    }

    &.caution {
        background: @cautionColor;
    }
    &.muted {
        background: @disabledColor;
        color: @textColor;
    }
    &.disabled {
        background: @disabledColor;
        color: @greyLight4;
    }
}

.white {
    background: @white;
    color: @black70;
    &.bordered {
        border: 1px solid @greyLight3;
    }
}

.transparent {
    background: transparent;
}
.secondary {
    background: @white;
    color: @ctaColor;
    &.bordered {
        border: 1px solid @ctaColor;
    }
    &.caution {
        color: @cautionColor;
    }
    &.muted {
        color: @textColor;
    }
    &.disabled {
        color: @disabledColor;
    }
}
.opened .button.secondary {
    color: @ctaColor;
    img {
        filter: @ctaFilter;
    }
}
</style>
