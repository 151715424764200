<template>
    <div class="scorecard-profile">
        <ScorecardProfileImage
            :can-edit="true"
            :scorecard="scorecard"
            :height="72"
            :width="72"
        />
        <div class="name-container">
            <div class="name">
                {{ name }}
            </div>
            <div class="location">{{ locationText }}</div>
        </div>
    </div>
</template>

<script lang="ts">
import ScorecardProfileImage from '@/components/Scorecard/ScorecardProfileImage.vue'
import { ScorecardEntity } from '@/entities/scorecard'
import { Component, Vue } from 'vue-facing-decorator'
import { Getter } from 'vuex-facing-decorator'
import { decode } from 'he'

@Component({
    components: {
        ScorecardProfileImage,
    },
})
export default class ScorecardProfile extends Vue {
    @Getter
    scorecard?: ScorecardEntity

    @Getter
    locationText!: string

    get name() {
        return decode(this.scorecard?.user.name || '')
    }
}
</script>

<style scoped lang="less">
@import '~@/styles/palette';

.scorecard-profile {
    display: flex;
    align-items: center;
}

.name-container {
    padding-left: 20px;
}

.name {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: @grey90;
}

.location {
    padding-top: 8px;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: @greyMid4;
}
</style>
