<template>
    <div class="breakdown">
        <WebViewHeader>
            <template #left-button>
                <div
                    class="close"
                    data-test-tag="close-modal"
                    @click="handleClick"
                >
                    <img
                        src="../../components/WebView/icons/Close.svg"
                        alt="Close"
                    />
                </div>
            </template>
        </WebViewHeader>

        <div class="content">
            <BaseCard class="nps-summary">
                <TsNpsOverallV2 is-mobile />
                <div class="break" />
                <TsNpsScorecardV2 />
            </BaseCard>

            <DashboardResponses
                v-if="teamScoreboard"
                :is-side-bar="false"
                :show-badges="true"
                :external-filters="dashboardResponsesFilters"
                :mobile-question-type="mobileQuestionType"
                class="responses"
                apply-scorecard-template-filter
            />
        </div>
    </div>
</template>

<script lang="ts">
import { Getter } from 'vuex-facing-decorator'
import { Component, Vue } from 'vue-facing-decorator'
import WebViewHeader from '@/components/WebView/WebViewHeader.vue'
import DashboardResponses from '@/pages/dashboard/components/DashboardResponses.vue'
import TsNpsOverallV2 from '@/components/TeamScoreboard/TsNpsOverallV2.vue'
import TsNpsScorecardV2 from '@/components/TeamScoreboard/TsNpsScorecardV2.vue'
import BaseCard from '@/components/BaseCard.vue'

@Component({
    components: {
        BaseCard,
        TsNpsScorecardV2,
        TsNpsOverallV2,
        DashboardResponses,
        WebViewHeader,
    },
    emits: ['click-close'],
})
export default class BreakdownScreen extends Vue {
    @Getter public teamScoreboard
    @Getter public sideBarFilters
    @Getter public dashboardResponsesFilters
    @Getter public mobileQuestionType

    public handleClick() {
        this.$emit('click-close')
    }
}
</script>

<style lang="less" scoped>
@import '../../styles/palette.less';

.breakdown {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
}

.break {
    border-bottom: 0.5px solid @blueLight;
    height: 0.5px;
    width: 100%;
}

.content {
    flex: 1;
    overflow-y: scroll;
    background-color: @backgroundColor;
    padding: 0 15px;
}

.close {
    min-width: 44px;
    min-height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nps-summary,
.responses {
    margin: 12px 0;
}
</style>

<style lang="less">
.breakdown {
    .responses-container .body .responses .scrollable {
        // override the `calculateScrollHeight` from `DashboardResponses`
        // Possibly better to provide the ability to turn that off in the in
        // component or extract it to the calling sites
        max-height: none !important;
    }
}
</style>
