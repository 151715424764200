<template>
    <span :class="`label ${sentiment}`">
        {{ display }}
    </span>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { SuggestionAction } from '../../entities/suggestions'

@Component({})
export default class ActionLabel extends Vue {
    @Prop({ type: String, default: '' }) public action!: SuggestionAction

    public get sentiment() {
        return this.action === 'not-now' ? 'negative' : 'positive'
    }

    public get display() {
        return this.action === 'not-now' ? 'Not now' : "Let's Do It"
    }
}
</script>

<style scoped lang="less">
.label {
    font-size: 12px;
    text-transform: capitalize;
    display: inline-block;
    padding: 4px 8px;
    border-radius: 2px;
    font-weight: 900;
    font-family: 'Roboto Black', Arial, Helvetica, sans-serif;
}

.positive {
    background: #f2f5ff;
    color: #2e5bff;
}

.negative {
    background: #fff7f4;
    color: #fb7e42;
}
</style>
