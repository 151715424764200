<template>
    <div class="strip-input">
        <label>{{ label }}</label>
        <div class="options">
            <span
                v-for="(option, index) in options"
                :key="index"
                :class="['item', { active: option.selected }]"
                @click="!disabled && clickHandler(option, index)"
            >
                {{ option.key }}
            </span>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { StripInputOption } from '@/entities/survey'

@Component({
    emits: ['change'],
})
export default class StripInput extends Vue {
    // if (isMultiSelect===true), return value:string[]
    // else return value:string
    @Prop({ required: true, default: null }) public value!: string | string[]
    @Prop({ required: false, default: '' }) public label!: string
    @Prop({ required: true, default: null }) public options!: StripInputOption[]
    @Prop({ required: false, default: false }) public isMultiSelect!: boolean
    @Prop({ required: false, default: false }) public disabled!: boolean

    public clickHandler(option: StripInputOption, index: string) {
        if (!this.isMultiSelect) {
            this.options.forEach((e) => (e.selected = e.value === option.value))
            return this.$emit('change', option.value)
        }
        this.options[index].selected = !this.options[index].selected
        const value = this.options.filter((e) => e.selected).map((e) => e.value)
        this.$emit('change', value)
    }
}
</script>

<style lang="less" scoped>
@import '../styles/form';
@import '../styles/palette.less';

.strip-input {
    height: 50px;
    margin-bottom: 15px;
}

.options {
    display: flex;
    background: @greyLight;
    border: 1px solid @greyLight2;
    border-radius: 3px;
    overflow: hidden;
}
.item {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid @greyLight2;
    height: 30px;
    cursor: pointer;
    &:hover {
        background: @greyLight2;
    }
    &:last-child {
        border-right: none;
    }
    &.active {
        background: @ctaColor;
        color: @white;
    }
}
</style>
