<template>
    <button class="plus-button" @click="onClick">
        <FontAwesomeIcon class="circle-plus-icon" :icon="faCirclePlus" />
    </button>
</template>

<script lang="ts">
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faCirclePlus } from 'fontawesome/free-solid-svg-icons'
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component({
    components: {
        FontAwesomeIcon,
    },
})
export default class PlusButton extends Vue {
    @Prop({ type: Function, required: true })
    public readonly onClick!: () => {}
    public faCirclePlus = faCirclePlus
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/variables.less';
@import '~@/styles/rain/colour.less';
@import '~@/styles/variables.less';

@iconSize: 44px;

.circle-plus-icon {
    color: @white;
    height: @iconSize;
    width: @iconSize;
}

.plus-button {
    background-color: unset;
    border: unset;
    padding: unset;

    position: fixed;
    right: @featureGutter;
    bottom: calc(@mobileNavHeightPadded + env(safe-area-inset-bottom));
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.4));
}
</style>
