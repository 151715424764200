export async function sleep(time: number) {
    return new Promise((res) => {
        // @ts-ignore
        setTimeout(() => res(), time)
    })
}

// call async function with retry back off could be exponentially 2^x seconds or linear
export const callWithRetry = async (
    fn,
    expenentialBackOff = true,
    depth = 0
) => {
    try {
        return await fn()
    } catch (e) {
        const maxDepth = expenentialBackOff ? 8 : 450
        if (depth > maxDepth) {
            throw e
        }
        if (expenentialBackOff) {
            await sleep(2 ** depth * 1000)
        } else {
            await sleep(2 * 1000)
        }

        return await callWithRetry(fn, expenentialBackOff, depth + 1)
    }
}
