import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5475a075"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "inactive"
}
const _hoisted_2 = { class: "closed badge" }
const _hoisted_3 = { class: "bold status-detail" }
const _hoisted_4 = { class: "open badge" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "row-container",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    (_ctx.caseStatus === 'default')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, "🔔 Open Case"))
      : _createCommentVNode("", true),
    (_ctx.caseStatus === 'closed')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.caseStatus), 1),
          _createTextVNode(" via "),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.closedVia), 1)
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.caseStatus === 'open')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.caseStatus), 1),
          _createElementVNode("div", {
            class: "status-detail",
            innerHTML: _ctx.timeAgo
          }, null, 8, _hoisted_5)
        ], 64))
      : _createCommentVNode("", true)
  ]))
}