import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChatMessage = _resolveComponent("ChatMessage")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "msgs",
    class: _normalizeClass(["chat-msgs", `${_ctx.noPad ? 'no-pad' : ''}`]),
    style: _normalizeStyle({ 'padding-bottom': _ctx.bottomPadding + 'px' })
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (message) => {
      return (_openBlock(), _createBlock(_component_ChatMessage, _mergeProps({ ref_for: true }, _ctx.$attrs, {
        key: _ctx.generateMessageKey(message),
        class: "chat-message",
        "question-text": _ctx.messages[0].comment_raw,
        "on-change-reply-review": _ctx.onChangeReplyReview,
        "chat-status": _ctx.chatStatus,
        "custom-data": _ctx.customData,
        "can-delete-note": _ctx.canDeleteNote,
        "is-user-basic": _ctx.isUserBasic,
        person: _ctx.person,
        "conv-questions": _ctx.convQuestions || {},
        message: message,
        "has-network-connection": _ctx.hasNetworkConnection
      }), null, 16, ["question-text", "on-change-reply-review", "chat-status", "custom-data", "can-delete-note", "is-user-basic", "person", "conv-questions", "message", "has-network-connection"]))
    }), 128))
  ], 6))
}