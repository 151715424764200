<template>
    <div class="text-input" :class="{ error }">
        <label v-if="label">{{ label }}</label>
        <input
            class="input"
            :type="type ? type : 'text'"
            :placeholder="placeholder ? placeholder : ''"
            :value="value"
            :disabled="disabled"
            @input="$emit('input', $event.target.value)"
            @blur="$emit('blur')"
        />
        <span v-if="error" class="error-message">{{ error }}</span>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component({
    emits: ['input', 'blur'],
})
export default class TextInput extends Vue {
    @Prop({ type: String }) public value!: string
    @Prop({ type: String, required: false }) public label!: string
    @Prop({ type: String }) public placeholder!: string
    @Prop({ type: String, required: false }) public readonly error!: string
    @Prop({ type: Boolean, required: false, default: false })
    public readonly disabled!: boolean
    @Prop({ type: String, required: false }) public readonly type!: string
}
</script>

<style lang="less" scoped>
@import '../styles/form';
@import '../styles/palette';

.text-input {
    .error-message {
        display: none;
        color: @red;
    }

    &.error {
        input {
            border: 2px solid @red;
        }

        .error-message {
            display: inline-block;
        }
    }
}
</style>
