import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-75deca16"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_SimpleSpinner = _resolveComponent("SimpleSpinner")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass([
            'button',
            _ctx.variant,
            {
                'font-awesome': _ctx.fontAwesome,
                full: _ctx.full,
                disabled: _ctx.disabled,
                loading: _ctx.loading,
            },
        ]),
    disabled: _ctx.disabled,
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([{ 'loading-content': _ctx.loading }])
    }, [
      (_ctx.fontAwesomeSources[_ctx.fontAwesome])
        ? (_openBlock(), _createBlock(_component_FontAwesomeIcon, {
            key: 0,
            icon: _ctx.fontAwesomeSources[_ctx.fontAwesome],
            class: "font-awesome"
          }, null, 8, ["icon"]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_SimpleSpinner, {
          key: 0,
          class: "loading-spinner",
          size: "small",
          "line-bg-color": "transparent",
          "line-fg-color": _ctx.spinnerColors[_ctx.variant]
        }, null, 8, ["line-fg-color"]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}