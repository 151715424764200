<template>
    <BaseTooltip>
        <template #trigger>
            <img
                class="info-icon"
                src="/img/icons/info.svg"
                width="16"
                height="16"
                alt="Information"
            />
        </template>
        <template #popover><slot /></template>
    </BaseTooltip>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import BaseTooltip from '@/components/BaseTooltip.vue'

@Component({
    components: {
        BaseTooltip,
        FontAwesomeIcon,
    },
})
export default class InfoTooltip extends Vue {}
</script>

<style lang="less" scoped>
.v-popover {
    display: inline;
}
.info-icon {
    position: relative;
    top: -2px;
}
</style>
