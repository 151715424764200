import { Component, Vue } from 'vue-facing-decorator'
import { Action, Getter } from 'vuex-facing-decorator'
import { faChevronDown } from 'fontawesome/free-solid-svg-icons'
import { IComposer, IRecipient } from '@/entities/notices'

declare const UIkit

@Component({})
export default class NoticesMixin extends Vue {
    protected contentLength = 0
    protected contentLengthLimit = 301
    protected scheduledTimeLabel = 'Now'
    protected selectedRecipients: IRecipient[] = []
    protected showSchedule = false
    protected faChevronDown = faChevronDown

    @Action protected setComposerMessage
    @Action protected setComposerMentions
    @Action protected setComposerScheduledAt
    @Action protected loadRecipients
    @Action protected setCanSendNotices
    @Action protected setErrorMessage
    @Action protected setComposerRecipients
    @Action protected setComposerNoticeType
    @Action protected setComposerQuestion
    @Action protected resetComposer
    @Action protected loadTimezone

    @Getter protected timezone!: string
    @Getter protected composer!: IComposer
    @Getter protected recipients!: IRecipient[]

    protected scrollToEditor(isClamped) {
        if (isClamped) {
            const el = this.$refs['notice-composer-editor'] as HTMLDivElement
            el.scrollTop = 0
        }
    }

    protected onMessageUpdate(text: string, length: number) {
        this.contentLength = length - 1 // If there is no text, length is still = 1
        this.setComposerMessage(text)
        // also update mentions in case one mention is deleted
        const allMentions = this.recipients.filter((mention) =>
            this.composer.message.includes(`@${mention.name} `)
        )
        this.setComposerMentions(allMentions)
    }

    protected onScheduleTime(timeStr, label) {
        this.setComposerScheduledAt(timeStr)
        this.scheduledTimeLabel = label
    }

    protected notifySuccess(data, customMessage?: string) {
        const sentType = this.composer.scheduled_at ? 'scheduled' : 'sent'
        UIkit.notify(customMessage || data.msg || `Notice ${sentType}`, {
            status: 'success',
            pos: 'top-right',
        })
    }

    protected notifyError(data) {
        UIkit.notify(data.msg, {
            status: 'warning',
            pos: 'top-right',
        })
    }

    protected sendButtonLabel(sendButtonTextOverride?: string): string {
        if (sendButtonTextOverride) {
            return sendButtonTextOverride
        }
        return this.composer.scheduled_at === '' ? 'Send' : 'Schedule'
    }

    protected get hasRecipients() {
        return this.selectedRecipients.length > 0
    }
}
