import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d7575d52"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "button-container" }
const _hoisted_2 = {
  key: 0,
  class: "word-limit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    show: "",
    "allow-click-out": "",
    "allow-escape": "",
    close: () => _ctx.$emit('close'),
    title: "Your Feedback:",
    bg: _ctx.showModalBg,
    size: _ctx.modalSize
  }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("textarea", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.feedback) = $event)),
        placeholder: "Write Feedback",
        rows: "5"
      }, "\n        ", 512), [
        [_vModelText, _ctx.feedback]
      ]),
      _createElementVNode("div", _hoisted_1, [
        (_ctx.wordLeft !== 300)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.wordLeft || ' '), 1))
          : _createCommentVNode("", true),
        _createVNode(_component_BaseButton, {
          disabled: _ctx.buttonDisabled,
          class: "btn-submit",
          primary: "",
          small: "",
          click: () => _ctx.$emit('submit', _ctx.feedback)
        }, {
          default: _withCtx(() => [
            _createTextVNode("Submit ")
          ], undefined, true),
          _: 1
        }, 8, ["disabled", "click"])
      ])
    ], undefined, true),
    _: 1
  }, 8, ["close", "bg", "size"]))
}