<template>
    <component
        v-bind="$attrs"
        :is="component"
        :class="['typography-base', variant]"
    >
        <slot />
    </component>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { ComponentType, VariantType } from '@/types/typography'

@Component({
    components: {},
})
export default class Typography extends Vue {
    @Prop({ type: String, required: false, default: 'body1' })
    public readonly variant!: VariantType
    @Prop({ type: String, required: false, default: 'p' })
    public readonly component!: ComponentType
}
</script>

<style scoped lang="less">
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';

.typography-base {
    font-family: @fontFamily;
    font-style: normal;
    color: @offBlack;
    margin: 0px;
}

.h1 {
    font-size: @fontSize-3xl;
    line-height: @fontSize-3xl*1.5;
    font-weight: @fontWeight-medium;
    text-transform: capitalize;
    letter-spacing: @letterSpacing-2xs;
}

.h2 {
    font-size: @fontSize-2xl;
    line-height: @fontSize-2xl*1.5;
    font-weight: @fontWeight-medium;
    text-transform: capitalize;
    letter-spacing: @letterSpacing-2xs;
}

.h3 {
    font-size: @fontSize-xl;
    line-height: @fontSize-xl*1.5;
    font-weight: @fontWeight-medium;
    text-transform: capitalize;
    letter-spacing: @letterSpacing-2xs;
}

.h4 {
    font-size: @fontSize-lg;
    line-height: @fontSize-lg*1.5;
    font-weight: @fontWeight-medium;
    letter-spacing: @letterSpacing-sm;

    &:first-letter {
        text-transform: uppercase;
    }
}

.subtitle1 {
    font-size: @fontSize-md;
    line-height: @fontSize-md*1.5;
    font-weight: @fontWeight-medium;
    text-transform: capitalize;
    letter-spacing: @letterSpacing-base;
}

.subtitle2 {
    font-size: @fontSize-base;
    line-height: @fontSize-base*1.5;
    font-weight: @fontWeight-medium;
    text-transform: capitalize;
    letter-spacing: @letterSpacing-xs;
}

.body1 {
    font-size: @fontSize-md;
    line-height: @fontSize-md*1.5;
    font-weight: @fontWeight-regular;
    letter-spacing: @letterSpacing-xl;

    &:first-letter {
        text-transform: uppercase;
    }
}

.body2 {
    font-size: @fontSize-base;
    line-height: @fontSize-base*1.5;
    font-weight: @fontWeight-regular;
    letter-spacing: @letterSpacing-md;

    &:first-letter {
        text-transform: uppercase;
    }
}

.button {
    font-size: @fontSize-base;
    line-height: @fontSize-base*1.5;
    font-weight: @fontWeight-medium;
    text-transform: capitalize;
    letter-spacing: @letterSpacing-2xl;
}

.tabs {
    font-size: @fontSize-base;
    line-height: @fontSize-base*1.5;
    font-weight: @fontWeight-medium;
    text-transform: uppercase;
    letter-spacing: @letterSpacing-2xl;
}

.caption {
    font-size: @fontSize-sm;
    line-height: @fontSize-sm*1.5;
    font-weight: @fontWeight-regular;
    letter-spacing: @letterSpacing-lg;
}

.overline {
    font-size: @fontSize-sm;
    line-height: @fontSize-sm*1.5;
    font-weight: @fontWeight-medium;
    text-transform: uppercase;
    letter-spacing: @letterSpacing-2xl;
}
</style>
