<template>
    <div id="wrapper">
        <div id="header-section">
            <Typography class="header" variant="overline">{{
                header
            }}</Typography>
            <button @click="() => onViewAllClick()">
                <Typography class="view-all" variant="overline">View All</Typography>
            </button>
        </div>

        <div class="feedback-base-card">
            <div v-if="hasScorecardBadges" class="card-header">
                <Typography variant="overline" class="title">
                    {{ props.feedback[currentSlideIndex].badges[0].label }}
                </Typography>
            </div>

            <Carousel class="feedback-carousel" @slidechanged="carouselSlide">
                <div
                    v-for="response in props.feedback"
                    :key="response.id"
                    class="card-content flicking-panel"
                    @click="() => onFeedbackClick(response.id)"
                >
                    <div class="first-line">
                        <div class="icon">
                            <ResponseCircle
                                :score="Number(response.answer)"
                                :variation="response.question_type"
                            />
                        </div>

                        <div class="card-main-content">
                            <div class="card-text">
                                <Typography
                                    variant="body2"
                                    :class="{ italics: !hasComment(response) }"
                                >
                                    <TextClamp
                                        is-html
                                        :text="getCommentText(response)"
                                        :max-lines="2"
                                    />
                                </Typography>
                                <div class="icons-container">
                                    <div
                                        v-if="getCommentCount(response) > 0"
                                        :class="['comment-number', 'positive']"
                                    >
                                        {{ getCommentCount(response) }}
                                    </div>
                                </div>
                            </div>

                            <div class="card-details">
                                <Typography
                                    variant="overline"
                                    class="card-author"
                                >
                                    {{ getPersonName(response) }}
                                </Typography>

                                <Typography
                                    variant="overline"
                                    class="timestamp"
                                >
                                    {{
                                        relativeDate(Number(response.responded))
                                    }}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </Carousel>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import type { Ref } from 'vue'
import { IResponse } from '@/entities'
import Typography from '@/components/Rain/Typography/Typography.vue'
import Carousel from '@/components/Rain/Layouts/Carousel/Carousel.vue'
import ResponseCircle from '@/components/ResponseCircle.vue'
import TextClamp from '@/components/TextClamp.vue'
import { getCommentCount } from '@/mobile/src/utils/feedback'
import { relativeDate } from '@/utils/time'
import { pendoTrackEvent } from '@/utils/pendo'

interface Props {
    header: string
    feedback?: IResponse[] | null
    onFeedbackClickCallback?: (id) => void
    viewAllCallback?: () => void
}

const props = withDefaults(defineProps<Props>(), {
    feedback: null,
    onFeedbackClickCallback: () => ({}),
    viewAllCallback: () => ({}),
})

const currentSlideIndex: Ref<number> = ref(0)

function carouselSlide(previous: number, newSlide: number): void {
    currentSlideIndex.value = newSlide
}

function onViewAllClick(): void {
    pendoTrackEvent('latest-feedback-view-all-clicked')
    props.viewAllCallback()
}

function onFeedbackClick(feedbackId: number): void {
    props.onFeedbackClickCallback(feedbackId)
}

function getPersonName(response: IResponse): string {
    return response.person?.name ?? 'Anonymous'
}

function getCommentText(response: IResponse): string {
    return hasComment(response) ? response.comment : 'No comment'
}

function hasComment(response: IResponse): boolean {
    return !!response.comment?.length
}

const hasScorecardBadges = computed(() => {
    return props.feedback
        ? props.feedback[currentSlideIndex.value]?.badges?.length
        : false
})
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';
@import '~@/mobile/src/styles/misc.less';
@import '~@/mobile/src/styles/feedback-card.less';

button {
    .remove-button-styling();
}

#header-section {
    display: flex;
    justify-content: space-between;
}

#wrapper {
    padding-bottom: 20px;
}

.header {
    color: @white80;
}

.view-all {
    color: @white;
}

.card-main-content {
    height: 100%;
}
.card-text {
    min-height: calc(@lineHeight-base * 2);

    .italics {
        font-style: italic;
    }
}
</style>

<style lang="less">
.feedback-carousel {
    position: relative;

    .an-carousel-dots {
        position: relative;
        bottom: -20px;
    }
}
</style>
