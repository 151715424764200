<template>
    <div class="mfa-page">
        <MFAValidation
            ref="mfaValidationRef"
            :formatted-time="formattedTime"
            :secret="secret"
            :is-verifying="loading"
            @verifyMFA="verifyMFA"
        />
    </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref, onUnmounted } from 'vue'
import { getMFACode, verifyMFACode } from '@/api/mfa'
import { getPlatform } from '@/mobile/src/utils/capacitor'
import store from '@/mobile/src/store'
import { showToast } from '@/mobile/src/utils/toastbus'
import { useRouter } from 'vue-router'
import useEmitter from '@/composables/useEmitter'
import { ToastColor, ToastIcon } from '@/mobile/src/types/toasts'
import { sleep } from '@/utils/async'
import MFAValidation from '@/mobile/src/views/appV4/mfa/MFAValidation.vue'

const router = useRouter()
const emitter = useEmitter()

const secret = ref('')
const verificationCode = ref('')
const loading = ref(false)
const copied = ref(false)
const platform = ref('')
const countdownTime = 5 * 60 // time is in seconds
const currentTime = ref(countdownTime)
const mfaValidationRef = ref()
let timerInterval: NodeJS.Timeout

const formattedTime = computed(() => {
    const minutes = Math.floor(currentTime.value / 60)
    const seconds = currentTime.value % 60
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
})

const updateTimer = () => {
    timerInterval = setInterval(async () => {
        if (currentTime.value > 0) {
            currentTime.value--
        } else {
            await reloadMFA()
        }
    }, 1000)
}

const userHasMfa = computed(() => store.getters['userHasMfa'])
const companyVars = computed(() => store.getters['$companyVars'])

onBeforeMount(async () => {
    await reloadMFA()
    updateTimer()
    platform.value = await getPlatform()
})

onUnmounted(() => {
    loading.value = false
    clearInterval(timerInterval)
})

function showFailureToast() {
    showToast(
        emitter,
        ToastIcon.Alert,
        ToastColor.Warning,
        `incorrect code`,
        2000
    )
}

async function verifyMFA(mfaCode: string) {
    loading.value = true
    let result = await verifyMFACode(mfaCode)
    if (result) {
        await store.dispatch('saveHasMFA', true)
        await router.replace({
            name: 'recovery-codes-setup',
            replace: true,
            params: {
                fromMfaSetup: 'true',
            },
        })
    } else {
        showFailureToast()
        await sleep(2000)
        mfaValidationRef.value?.resetMFAInput()
    }
    loading.value = false
}

async function reloadMFA() {
    loading.value = true
    let result = await getMFACode()
    secret.value = result.data.mfa_secret
    currentTime.value = countdownTime
    loading.value = false
}
</script>
