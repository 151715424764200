<template>
    <div class="brief-info" :class="{ 'has-rank': showRank }">
        <!-- avatar or title and ranking -->
        <div class="avatar-title">
            <div v-if="currentGroupAvatar" class="avatar">
                <img :src="avatarUrl" alt="avatar" class="avatar-img" />
            </div>
            <div>
                <div class="group-name">{{ name }}</div>

                <div v-if="npsInsightsSummary.rank" class="title-rank">
                    {{ npsInsightsSummary.rank
                    }}{{ getOrdinalRank(npsInsightsSummary.rank) }} within
                    {{ npsInsightsSummary.rankFieldLabel }}
                </div>
            </div>
        </div>
        <!-- response count and nps and change -->
        <div class="response-nps">
            <div class="response">
                <div class="label">TOTAL RESPONSES</div>
                <div class="number">
                    <FormattedNumber :number="npsInsightsSummary.total" />
                </div>
            </div>
            <div class="nps-block">
                <div class="nps">
                    {{ getNpsRounded(npsInsightsSummary.nps) }}
                </div>
                <div v-if="!npsInsightsSummary.isFiveScore" class="change">
                    <img
                        v-if="npsInsightsSummary.change >= 0"
                        src="@/assets/img/Icons/leaderboard-up.svg"
                        alt=""
                    />
                    <img
                        v-else
                        src="@/assets/img/Icons/leaderboard-down.svg"
                        alt=""
                    />
                    <span class="number">{{
                        getNpsRounded(npsInsightsSummary.change)
                    }}</span>
                </div>
            </div>
        </div>
        <Loading v-if="!!isLoadingInsightsData" loading />
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { Action, Getter } from 'vuex-facing-decorator'
import { getOrdinal } from '@/utils/number'

import { TsNpsSummaryEntity } from '@/entities/teamscoreboard'
import FormattedNumber from '@/components/FormattedNumber.vue'
import Loading from '@/components/Loading.vue'
import { getCDNUrl } from '@/utils/cdn'

@Component({
    components: { Loading, FormattedNumber },
})
export default class InsightsNPSInfo extends Vue {
    @Getter('npsInsightsSummary')
    insightsSummary!: TsNpsSummaryEntity

    @Getter
    currentGroupName

    @Getter
    companyProps

    @Getter
    currentGroupAvatar

    @Getter
    isLoadingInsightsData

    public getOrdinalRank(rank: string | number) {
        return getOrdinal(rank)
    }

    get npsInsightsSummary() {
        return (
            this.insightsSummary || {
                nps: 0,
                change: 0,
                total: 0,
                promoter: 0,
                passive: 0,
                detractor: 0,
                scorecardQuestionTotal: 0,
            }
        )
    }

    get name() {
        return this.currentGroupName || this.companyProps.name
    }

    public get avatarUrl() {
        if (/http/.test(this.currentGroupAvatar)) {
            return this.currentGroupAvatar
        }
        return getCDNUrl(this.currentGroupAvatar)
    }

    public lastValue = 0

    public getNpsRounded(val: number) {
        if (this.npsInsightsSummary && this.npsInsightsSummary.isFiveScore) {
            return val
        }

        // Round 100.0 to 100 and -100.0 to -100
        if (val === 100 || val === -100) {
            return val.toFixed(0)
        }
        return Number(val).toFixed(1)
    }

    public get showRank(): boolean {
        // If we have more than one sidebarFilter
        // if (this.tsFiltersHasGroup) {
        //     return false
        // }

        if (!this.npsInsightsSummary || !this.npsInsightsSummary.rank) {
            return false
        }

        return true
    }
}
</script>

<style scoped lang="less">
@import '~@/styles/palette.less';
@import '~@/styles/typography.less';

.brief-info {
    position: relative;
    padding: 15px;

    .avatar-title {
        display: flex;
    }

    .avatar-img {
        width: 24px;
        border-radius: 24px;
        margin-right: 8px;
    }

    .company-name {
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
    }

    .group-name {
        font-weight: 500;
        font-size: 18px;
    }

    .title-rank {
    }

    .response-nps {
        display: flex;
        justify-content: space-between;

        .response {
            padding-top: 20px;

            .total-response-tooltip-text {
                margin: 4px 0;
            }

            .missing-responses-link {
                text-decoration: underline !important;
            }

            .label {
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                text-transform: uppercase;

                color: @grey40;
            }

            .number {
                font-weight: bold;
                font-size: 14px;
                line-height: 16px;
                letter-spacing: 1px;
                margin-top: 5px;
            }
        }

        .nps-block {
            text-align: right;

            .nps {
                font-weight: 500;
                font-size: 38px;
                line-height: 38px;
                color: @blue;
                margin-top: 5px;
            }

            .change {
                color: @fontColor;

                .number {
                    font-size: 14px;
                    color: @fontColor;
                    margin-left: 8px;
                }
            }
        }
    }
}
</style>
