<template>
    <div class="nps-stats-container">
        <div class="total uk-width-4-10">
            <span class="stat">
                <span class="number">{{ tsNpsSummary.total }}</span>
                <span class="label">TOTAL RESPONSES</span>
                <br />
                <span class="sm-label">
                    incl. dropouts&nbsp;
                    <!-- TODO: Fix InfoToolTip so that it can wrap a larger area, and has a visible icon that doesn't rely
                     on Font Awesome-->
                    <InfoTooltip>Some survey respondents answered but did not select
                        feedback for the personal scorecard.</InfoTooltip>
                </span>
            </span>
        </div>
        <div class="positive uk-width-3-10">
            <span class="stat">
                <span class="number">{{ tsNpsSummary.promoter }}</span>
                <span class="label">POSITIVE</span>
            </span>
        </div>
        <div class="others uk-width-3-10">
            <span class="stat">
                <span class="number">{{ thingsToWorkOnCount }}</span>
                <span class="label">THINGS TO WORK ON</span>
            </span>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { Action, Getter } from 'vuex-facing-decorator'
import InfoTooltip from '@/components/InfoTooltip.vue'

import { TsNpsSummaryEntity } from '@/entities/teamscoreboard'

@Component({
    components: {
        InfoTooltip,
    },
})
export default class TsNpsStats extends Vue {
    @Getter public tsNpsSummary?: TsNpsSummaryEntity
    @Getter public tsScorecard

    public get positiveCount() {
        if (!this.tsScorecard || !this.tsScorecard.tsgroups) {
            return 0
        }

        return this.tsScorecard.tsgroups.reduce(
            (sum, current) => sum + current.respondedPromoter,
            0
        )
    }

    public get thingsToWorkOnCount() {
        if (!this.tsScorecard || !this.tsScorecard.tsgroups) {
            return 0
        }

        return this.tsScorecard.tsgroups.reduce(
            (sum, current) =>
                sum + (current.responded - current.respondedPromoter),
            0
        )
    }
}
</script>

<style scoped lang="less">
@import '../../styles/palette.less';
@import '../../styles/typography.less';

.nps-stats-container {
    display: flex;
    text-align: right;

    .total {
        flex: 3;
    }

    .positive,
    .others {
        flex: 2;
    }

    .stat {
        letter-spacing: 1px;
    }

    .label {
        color: @grey40;
        font-size: 12px;
        font-weight: 500;
        opacity: 0.75;
    }

    .sm-label {
        color: @grey40;
        color: #93a1be;
        font-size: 10px;
        opacity: 0.75;
    }

    .number {
        display: block;
        color: @darkGreyBlue;
        font-size: 14px;
        font-weight: 700;
    }
}
</style>
