import { ActionTree, MutationTree, GetterTree } from 'vuex'
import {
    getCustomData,
    getMessages,
    getChatResponses,
    getFeedbackDetail,
    getFeedbackDetailMessages,
} from '@/api/chat'
import { ChatMessage, FeedbackDetailMessage } from '@/entities/chat'
import { ChatReplyState } from '@/entities/chatresponse'
import fname from '@/utils/string'
import { toggleResponseStarred } from '@/api/scorecard'

export interface ChatResponseState {
    id: number
    note: string
    reply: ChatReplyState
    customData: any
    chatMessages: ChatMessage[] | FeedbackDetailMessage[]
    conversationalData: any
    userPermissions: string[]
    chatQuestionLoading: boolean
}

export const state: ChatResponseState = {
    id: 0,
    note: '',
    reply: new ChatReplyState('', '', ''),
    customData: {},
    chatMessages: [],
    conversationalData: [],
    userPermissions: [],
    chatQuestionLoading: false,
}

const getters: GetterTree<ChatResponseState, any> = {
    chatQuestionId({ id }): number {
        return id
    },

    chatMessages({ chatMessages }): ChatMessage[] | FeedbackDetailMessage[] {
        return chatMessages
    },

    chatNote({ note }): string {
        return note
    },

    chatReply({ reply }): ChatReplyState {
        return reply
    },

    chatCustomData({ customData }): any {
        return customData
    },

    chatConversationalData({ conversationalData }): any {
        return conversationalData
    },

    userPermissions({ userPermissions }): string[] {
        return userPermissions
    },

    chatQuestionLoading({ chatQuestionLoading }): boolean {
        return chatQuestionLoading
    },

    chatCustomFields({ customData }): any[] {
        if (!customData) {
            return []
        }

        const labels = customData
        // get friendly name for labels
        const customFieldLabels = labels.custom_field_labels
            ? labels.custom_field_labels
            : []
        const friendlyLabel = (k) =>
            fname(customFieldLabels[k] ? customFieldLabels[k] : k)

        // allow these custom fields which do not have the _c suffix
        const customDataAllowList = [
            'theme',
            'segment',
            'deliverymethod',
            'survey_template',
        ]
        return Object.keys(labels)
            .filter(
                (a) =>
                    (/_c$/.test(a) || customDataAllowList.includes(a)) &&
                    labels['custom_field_visibility'][a]
            )
            .sort()
            .map((a) => ({
                k: friendlyLabel(a),
                v: Array.isArray(labels[a])
                    ? (labels[a] as string[]).join(', ')
                    : labels[a],
            }))
            .filter((a) => a.v !== null)
    },
}
const actions: ActionTree<ChatResponseState, any> = {
    async getChatQuestion({ commit, rootGetters }, id: number) {
        // clear existing messages before loading new ones
        commit('setChatQuestionLoading', true)
        commit('setChatMessages', [] as any)

        let result
        if (rootGetters.userHasMlp) {
            result = await getFeedbackDetail(id)
        } else {
            result = await getChatResponses(id)
        }

        commit('setCustomData', result.data.customData)
        commit('setUserPermissions', result.data.userPermissions)
        commit('setChatMessages', result.data.chatMessages)
        commit('setChatConversationalData', result.data.conversationalData)
        commit('setChatQuestionLoading', false)
    },

    async setChatQuestion(
        { commit, state, dispatch },
        id: number,
        forceReload = false
    ) {
        if (state.id === id && !forceReload) {
            return
        }

        await dispatch('getChatQuestion', id)

        // prevent this action being executed concurrently
        commit('setChatQuestion', id)

        // commit status before getting custom data and user permissions to prevent concurrency issues
        commit('setChatNote', '')
        commit('setChatReply', new ChatReplyState('', '', ''))

        const newReply = new ChatReplyState(
            state.customData.from_name || '',
            state.customData.default_subject || '',
            state.reply.message
        )

        commit('setChatReply', newReply)
    },

    setChatNote({ commit }, note: string) {
        commit('setChatNote', note)
    },

    setChatReply({ commit, state }, { from, subject, message }) {
        const newReply = new ChatReplyState(
            from || state.reply.from,
            subject || state.reply.subject,
            message || state.reply.message
        )

        commit('setChatReply', newReply)
    },

    setChatCustomData({ commit }, customData) {
        commit('setCustomData', customData)
    },

    setChatQuestionLoading({ commit }, loading: boolean) {
        commit('setChatQuestionLoading', loading)
    },

    async reloadChatCustomData({ commit, state }) {
        const result = await getCustomData(state.id)

        const customData = result.data

        const newReply = new ChatReplyState(
            state.reply.from || customData.from_name || '',
            state.reply.subject || customData.default_subject || '',
            state.reply.message
        )

        commit('setChatReply', newReply)
        commit('setCustomData', customData)
    },

    async loadChat({ commit, state, rootGetters }) {
        if (state.id) {
            let resp
            if (rootGetters.userHasMlp) {
                resp = await getFeedbackDetailMessages(state.id)
            } else {
                resp = await getMessages(state.id)
            }

            commit('setChatMessages', resp.data)
        }
    },
    async toggleDetailResponseStar({ state }) {
        let matchedChat

        if (state.chatMessages) {
            state.chatMessages.forEach((chat) => {
                if (Number(chat.id) === Number(state.id)) {
                    chat.isStarred = !chat.isStarred
                    matchedChat = chat
                }
            })
        }

        await toggleResponseStarred(state.id).catch(() => {
            // Revert star if api call failed
            if (matchedChat) {
                matchedChat.isStarred = !matchedChat.isStarred
            }
        })
    },
}

const mutations: MutationTree<ChatResponseState> = {
    setChatQuestion(state, id: number) {
        state.id = id
    },

    setChatNote(state, note: string) {
        state.note = note
    },

    setChatMessages(state, chartMessages: ChatMessage[]) {
        state.chatMessages = chartMessages
        state.id = Number(chartMessages[0]?.id)
    },

    setChatReply(state, reply: ChatReplyState) {
        state.reply = reply
    },

    setCustomData(state, customData: any) {
        state.customData = customData
    },

    setChatQuestionLoading(state, loading: boolean) {
        state.chatQuestionLoading = loading
    },

    setChatConversationalData(state, conversationalData: any) {
        state.conversationalData = conversationalData
    },

    setUserPermissions(state, userPermissions: string[]) {
        state.userPermissions = userPermissions
    },
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations,
}
