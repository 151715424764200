<template>
    <div class="container">
        <img class="offline-img" src="@/assets/img/Icons/offline.svg" />
        <p class="title">You’re currently offline</p>
        <p class="description">You can access this when you’re back online.</p>
        <p class="description">In the meantime, you can</p>
        <div class="buttons">
            <BaseButton
                class="button"
                new-secondary
                :click="onClickViewScorecard"
            >
                View Scorecard
            </BaseButton>
            <BaseButton
                class="button"
                new-secondary
                :click="onClickViewFeedback"
            >
                View Feedback
            </BaseButton>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { useRouter } from 'vue-router'
import BaseButton from '@/components/BaseButton.vue'

@Component({
    components: { BaseButton },
})
export default class OfflineScreen extends Vue {
    private router = useRouter()

    onClickViewScorecard() {
        this.router.replace({ name: 'scorecardHome' })
    }

    onClickViewFeedback() {
        this.router.replace({ name: 'feedback' })
    }
}
</script>

<style scoped lang="less">
@import '~@/styles/variables';

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.title {
    font-size: 20px;
    color: @grey90;
    text-align: center;
    font-weight: 500;
}
.description {
    color: @grey90;
    text-align: center;
    width: 220px;
    margin: 24px auto;
}
.offline-img {
    width: 150px;
    margin: 40px auto 24px auto;
}

.buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.button {
    margin: 16px auto !important;
}
</style>
