import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-667c1e55"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "topic-row-content" }
const _hoisted_2 = { class: "label" }
const _hoisted_3 = { class: "mentions" }
const _hoisted_4 = {
  key: 0,
  class: "count"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.label), 1),
    _createElementVNode("div", _hoisted_3, [
      _createTextVNode(_toDisplayString(_ctx.totalResponses) + " mention" + _toDisplayString(_ctx.totalResponses > 1 ? 's' : '') + " ", 1),
      (_ctx.unseenResponses > 0)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, " +" + _toDisplayString(_ctx.unseenResponses), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}