import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HierarchyGoalTimeline = _resolveComponent("HierarchyGoalTimeline")!

  return (_openBlock(), _createBlock(_component_HierarchyGoalTimeline, {
    title: _ctx.title,
    loading: _ctx.loading,
    "hierarchy-items": _ctx.getHierarchyGoalByLocationLevel,
    "number-per-page": 7,
    pagination: true,
    "allow-drill-down": true,
    onDrillDown: _ctx.drillDown
  }, null, 8, ["title", "loading", "hierarchy-items", "onDrillDown"]))
}