import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-43facf6e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "trend-container" }
const _hoisted_2 = { class: "panel" }
const _hoisted_3 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScorecardGraph = _resolveComponent("ScorecardGraph")!
  const _component_Loading = _resolveComponent("Loading")!
  const _component_ScorecardSubpage = _resolveComponent("ScorecardSubpage")!

  return (_openBlock(), _createBlock(_component_ScorecardSubpage, {
    slide: "left",
    "show-header": !_ctx.embedded,
    title: _ctx.title
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.ratingLabel) + " last 30 days", 1),
          _createVNode(_component_ScorecardGraph, { "team-member-user-id": _ctx.teamMemberUserId }, null, 8, ["team-member-user-id"])
        ])
      ]),
      _createVNode(_component_Loading, { loading: _ctx.loading }, null, 8, ["loading"])
    ], undefined, true),
    _: 1
  }, 8, ["show-header", "title"]))
}