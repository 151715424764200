import client from '@/api/client'

export async function getHashtagWhitelist() {
    return client.get(`/hashtagwhitelist`)
}

export async function addHashtagToWhitelist(hashtag: string) {
    return client.post(`/hashtagwhitelist/${hashtag}`)
}

export async function removeHashtagFromWhitelist(hashtag: string) {
    return client.delete(`/hashtagwhitelist/${hashtag}`)
}
