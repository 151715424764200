<template>
    <BasePillButton class="pill-search-input" @click="showSearchInput">
        <div class="pill-search-input-content">
            <div class="search-icon">
                <FontAwesomeIcon :icon="faSearch" size="lg" />
            </div>
            <Transition name="slide-right">
                <div v-if="searchInputActive" class="search">
                    <div
                        ref="searchinput"
                        contenteditable="true"
                        class="search-input"
                        @input="onInput"
                    ></div>
                    <div class="close-icon" @click.stop="closeSearchInput">
                        <FontAwesomeIcon :icon="faClose" size="lg" />
                    </div>
                </div>
            </Transition>
        </div>
    </BasePillButton>
</template>

<script lang="ts">
import { nextTick } from 'vue'
import { Component, Prop, Vue } from 'vue-facing-decorator'
import BasePillButton from '@/components/BasePillButton.vue'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faSearch, faClose } from 'fontawesome/free-solid-svg-icons'

@Component({
    components: {
        BasePillButton,
        FontAwesomeIcon,
    },
    emits: ['toggle-input', 'input'],
})
export default class PillSearchInput extends Vue {
    @Prop({ type: Boolean, default: false }) private searchInputActive!: boolean
    private faSearch = faSearch
    private faClose = faClose

    $refs!: {
        searchinput: HTMLDivElement
    }

    public showSearchInput() {
        this.$emit('toggle-input', true)
        nextTick(() => {
            const searchInputEl = this.$refs.searchinput
            searchInputEl?.focus()
        })
    }

    public closeSearchInput() {
        this.$emit('toggle-input', false)
    }

    public onInput() {
        this.$emit('input', this.$refs.searchinput.innerText)
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/palette.less';
@import '~@/styles/rain/variables.less';

.pill-search-input {
    border: 1px solid #fff;
    background-color: rgba(0, 0, 0, 0);
    color: #fff;
    font-size: @fontSize-sm;
    font-weight: @fontWeight-medium;
    letter-spacing: @letterSpacing-xl;
    line-height: 12px;
    text-transform: uppercase;
    padding: 0;

    .pill-search-input-content {
        display: flex;
        align-items: center;

        .slide-right-enter-active,
        .slide-right-leave-active {
            max-width: 250px;
            opacity: 100;
            transition: all 1s cubic-bezier(0.5, 0.82, 0.165, 1);
        }
        .slide-right-enter-from,
        .slide-right-leave-to {
            max-width: 0;
            opacity: 0;
        }

        .search {
            display: flex;
            align-items: center;
        }

        .close-icon {
            // Increase clickable area of the icon
            padding: 5px;
            margin: -5px;
        }

        .search-input {
            min-width: 60px;
            text-align: justify;
            margin: 0 20px 0 5px;
            color: @white;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 1px;
            text-transform: uppercase;
            border: none;
            white-space: nowrap;
            overflow: hidden;

            &[contenteditable] {
                -webkit-user-select: text;
                user-select: text;
            }

            &:focus {
                outline: none;
            }
        }
    }
}
</style>

<style lang="less">
// Prevent multi line input in search input
.pill-search-input .pill-search-input-content .search-input {
    * {
        display: inline;
        white-space: nowrap;
    }

    br {
        display: none;
    }
}
</style>
