<template>
    <BaseCard class="suggestions-card">
        <slot />
    </BaseCard>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import BaseCard from '@/components/BaseCard.vue'

@Component({
    components: {
        BaseCard,
    },
})
export default class SuggestionsCard extends Vue {}
</script>

<style lang="less" scoped>
@import '../../styles/variables';

.suggestions-card {
    margin-bottom: 12px;
    padding: @gutterSpacing;
    position: relative;
}
</style>
