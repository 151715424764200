<template>
    <div class="transition-container">
        <transition :name="transitionName">
            <slot />
        </transition>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from 'vue-facing-decorator'
import { useRoute } from 'vue-router'
import { RouteLocationNormalized } from 'vue-router'
import { Getter } from 'vuex-facing-decorator'
import { TabData } from '@/mobile/src/types/tab'

@Component({ components: {} })
export default class PushPopTransitionContainer extends Vue {
    @Prop({ type: String, default: 'push-screen' })
    transition!: string

    @Getter
    mainNavTabs!: Array<TabData>

    transitionName = 'push-screen'
    toTime = 0
    fromTime = 0

    public route = useRoute()

    created() {
        this.transitionName = this.transition
    }

    @Watch('route.path')
    onRouteChanged(to: RouteLocationNormalized, from: RouteLocationNormalized) {
        // WEB-5018 on switching root tab, don't transit
        const fromTab = from?.meta?.tab
        const toTab = to?.meta?.tab
        if (
            !fromTab &&
            !toTab &&
            to?.meta?.transition !== 'modal' &&
            from?.meta?.transition !== 'modal'
        ) {
            this.transitionName = ''
            return
        }

        // if we are switching to or from a main tab then we shouldn't apply a transition
        if (
            fromTab !== toTab &&
            this.mainNavTabs.filter(
                (tab) => tab.name === fromTab || tab.name === toTab
            ).length > 0
        ) {
            this.transitionName = ''
            return
        }

        // only subpages on same tab would transit
        this.fromTime = this.toTime
        this.toTime = window.history.state
            ? Number.parseInt(window.history.state.key, 0) || 0
            : 0

        if (to?.meta?.transition) {
            this.transitionName = to.meta.transition + '-to'
            return
        }
        if (from?.meta?.transition) {
            this.transitionName = from.meta.transition + '-from'
            return
        }

        if (this.toTime < this.fromTime) {
            this.transitionName = 'pop-screen'
        } else if (this.toTime > this.fromTime) {
            this.transitionName = 'push-screen'
        } else {
            this.transitionName = 'switch-screen'
        }
    }
}
</script>

<style lang="less" scoped>
@duration: 200ms;
@vertical-duration: 200ms;

.transition-container {
    position: relative;
    height: 100%;
}

.push-screen-leave-active {
    transition-duration: @duration;
}

.push-screen-enter-from {
    transform: translateX(100%);
}

.push-screen-enter-active,
.pop-screen-leave-active,
.modal-to-enter-active,
.modal-from-leave-active {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-shadow: 1px 2px 6px 0 rgba(46, 91, 255, 0.08);
    z-index: 1000;
    background: white; // make sure bg is not transparent when transitioning
}

.push-screen-enter-active,
.pop-screen-leave-active {
    transition: transform @duration ease-in-out;
}

.pop-screen-leave-to {
    transform: translateX(100%);
}

.modal-to-enter-from,
.modal-from-leave-to {
    transform: translateY(100%);
}

.modal-to-enter-active,
.modal-from-leave-active {
    transition: transform @vertical-duration ease-in-out;
}

.modal-to-leave-to {
    transition-duration: @vertical-duration;
}
</style>
