import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4785c886"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "select-input" }
const _hoisted_2 = ["value"]
const _hoisted_3 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", null, _toDisplayString(_ctx.label), 1),
    _createElementVNode("select", {
      value: _ctx.value,
      class: "input",
      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('change', $event.target.value)))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (opt, idx) => {
        return (_openBlock(), _createElementBlock("option", {
          key: opt,
          value: opt
        }, _toDisplayString(_ctx.optionLabels[idx]), 9, _hoisted_3))
      }), 128))
    ], 40, _hoisted_2)
  ]))
}