import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7964ca89"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mentions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScorecardResponseTile = _resolveComponent("ScorecardResponseTile")!
  const _component_Loading = _resolveComponent("Loading")!
  const _component_ScorecardSubpage = _resolveComponent("ScorecardSubpage")!

  return (_openBlock(), _createBlock(_component_ScorecardSubpage, {
    slide: _ctx.slide,
    sticky: "",
    "show-header": !_ctx.embedded,
    title: _ctx.badgeLabel
  }, {
    default: _withCtx(() => [
      (!_ctx.loading)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.mentionCount) + " mention" + _toDisplayString(_ctx.mentionCount > 1 ? 's' : ''), 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.responses, (response) => {
              return (_openBlock(), _createElementBlock("div", {
                key: response.id,
                class: "response"
              }, [
                _createVNode(_component_ScorecardResponseTile, {
                  class: "bottom-margin-between-tiles",
                  response: response,
                  "show-badge": false,
                  scorecard: _ctx.scorecard,
                  onClick: ($event: any) => (_ctx.onClickResponse(response.id.toString()))
                }, null, 8, ["response", "scorecard", "onClick"])
              ]))
            }), 128))
          ], 64))
        : _createCommentVNode("", true),
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_Loading, {
            key: 1,
            loading: _ctx.loading
          }, null, 8, ["loading"]))
        : _createCommentVNode("", true)
    ], undefined, true),
    _: 1
  }, 8, ["slide", "show-header", "title"]))
}