<template>
    <div class="modal">
        <div
            :class="['container', { 'no-pad': noPad }]"
            :style="modalPositionStyle"
        >
            <div v-if="showHeader" class="header">
                <Typography variant="overline" class="info-title">{{
                    title
                }}</Typography>
                <FontAwesomeIcon
                    :icon="faTimes"
                    size="lg"
                    class="close"
                    @click="$emit('close')"
                />
            </div>

            <Typography variant="body2" class="content">
                <slot />
            </Typography>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import BaseButton from '@/components/Rain/Buttons/BaseButton.vue'
import Typography from '@/components/Rain/Typography/Typography.vue'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faTimes } from 'fontawesome/free-solid-svg-icons'
import { LooseObject } from '@/pages/appstore/entities/app'

@Component({
    components: {
        BaseButton,
        Typography,
        FontAwesomeIcon,
    },
    emits: ['close'],
})
export default class InfoModal extends Vue {
    @Prop({ type: Boolean, default: false }) public readonly show!: boolean
    @Prop({ type: Boolean, default: true }) public readonly showHeader!: boolean
    @Prop({ type: String, default: '' }) public readonly title!: string
    @Prop({ type: Number, default: 0 }) public xAdjust!: number
    @Prop({ type: Number, default: 0 }) public yAdjust!: number
    @Prop({ default: () => null }) public portraitTarget!: any
    @Prop({ default: () => null }) public landscapeTarget!: any

    @Prop({ type: Boolean, default: false })
    public readonly verticalOnly!: boolean
    @Prop({ type: Boolean, default: false }) public readonly noPad!: boolean

    private faTimes = faTimes
    private modalPosition: LooseObject = { top: 0, left: 0 }
    private modalTransform: LooseObject = { translateX: null, translateY: null }

    mounted() {
        window.addEventListener('resize', this.resizeModal)
        this.resizeModal()
    }

    destroyed() {
        window.removeEventListener('resize', this.resizeModal)
    }

    get modalPositionStyle() {
        let style = ''
        Object.entries(this.modalPosition).forEach(([key, value], index) => {
            if (Number.isFinite(value)) {
                style += `${key}: ${value}px; `
            }
        })
        let transform = ''
        Object.entries(this.modalTransform).forEach(([key, value], index) => {
            if (Number.isFinite(value)) {
                transform += `${key}(${value}%) `
            }
        })
        if (transform.length) {
            style += `transform: ${transform}; `
        }
        return style
    }

    public resizeModal() {
        let modalTarget =
            window.innerHeight > window.innerWidth
                ? (this.portraitTarget as Element)
                : (this.landscapeTarget as Element)
        if (!modalTarget) {
            return
        }
        let boundingRect = modalTarget.getBoundingClientRect()

        if (this.verticalOnly) {
            this.calculateVerticalOnly(boundingRect)
        } else if (window.innerHeight > window.innerWidth) {
            //portrait
            this.calculatePortraitModalLocation(boundingRect)
        } else {
            //landscape
            this.calculateLandscapeModalLocation(boundingRect)
        }

        this.modalPosition.left += this.xAdjust
        this.modalPosition.top += this.yAdjust
    }

    public calculateVerticalOnly(boundingRect) {
        this.modalPosition.left = boundingRect.left + boundingRect.width / 2
        this.modalTransform.translateX = -50

        if (boundingRect.top > window.innerHeight - boundingRect.bottom) {
            this.modalPosition.top = boundingRect.top - 5
            this.modalTransform.translateY = -100
        } else {
            this.modalPosition.top = boundingRect.bottom + 5
        }
    }

    public calculatePortraitModalLocation(boundingRect) {
        this.modalPosition.left = boundingRect.left
        this.modalPosition.top = boundingRect.bottom + 5
    }

    public calculateLandscapeModalLocation(boundingRect) {
        this.modalPosition.left = boundingRect.right + 5
        this.modalPosition.top = boundingRect.top
    }
}
</script>

<style scoped lang="less">
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';

@maxHeight: 600px;
@maxWidth: 200px;

.modal {
    position: fixed;
    z-index: 998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: left;

    .container {
        position: relative;
        align-self: self-start;
        padding: @featureGutter;
        margin-right: 10px;
        max-width: @maxWidth;
        max-height: @maxHeight;
        background-color: rgba(0, 0, 0, 0.6);
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
        backdrop-filter: blur(20px);
        border-radius: @borderRadius-md;

        &.no-pad {
            padding: 0;
        }

        .close {
            position: absolute;
            top: 13.6px;
            right: 10px;
            color: @lightGrey;
            cursor: pointer;
        }

        .header {
            height: 17px;
            color: @lightGrey;
            padding-bottom: 6px;

            .info-title {
                color: @lightGrey;
            }
        }

        .content {
            position: relative;
            box-sizing: border-box;
            color: @lightGrey;
            overflow-y: auto;
        }
    }
}

.modal-enter-from,
.modal-leave-active {
    opacity: 0;
}

.modal-enter-from .container,
.modal-leave-active .container {
    transform: scale(1.1);
}
</style>
