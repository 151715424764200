<template>
    <div class="graph-container">
        <canvas ref="graph" />
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { Action, Getter } from 'vuex-facing-decorator'
import { ScorecardEntity } from '@/entities/scorecard'
import { INPSTrendGraph } from '@/entities'
import { Chart, registerables } from 'chart.js'

@Component({
    components: {},
})
export default class ScorecardGraph extends Vue {
    @Getter public scorecard!: ScorecardEntity
    @Getter public scorecardNpsTrendGraph!: INPSTrendGraph & { datasets: [] }
    @Getter public scorecardOutdated!: boolean
    @Getter public scorecardLoading!: boolean

    @Action public loadScorecard
    @Action public loadNpsTrendGraph

    @Prop({ type: String, default: null })
    public teamMemberUserId!: string

    public chart?: Chart

    public mounted() {
        Chart.register(...registerables)
        this.load()
    }

    public async load() {
        if (this.scorecardOutdated && !this.scorecardLoading) {
            await this.loadScorecard(this.teamMemberUserId ?? null)
        }
        await this.loadNpsTrendGraph(this.teamMemberUserId ?? null)

        this.renderGraph()
    }

    public initialGraph() {
        return {
            labels: [],
            datasets: [],
        }
    }

    public async renderGraph() {
        const canvas = this.$refs.graph as HTMLCanvasElement
        if (!canvas) {
            return
        }
        const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
        // *** NOTE ***: deep copy object so chart js does'nt fuck up the data
        // by adding _meta k/v to the data and blow up vuex
        const data = JSON.parse(JSON.stringify(this.scorecardNpsTrendGraph))
        if (this.chart) await this.chart.destroy()
        this.chart = new Chart(ctx, {
            type: 'line',
            data: this.scorecardNpsTrendGraph ? data : this.initialGraph(),
            options: {
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false, // we have to render our own to match the design
                    },
                    tooltip: {
                        callbacks: {
                            //TODO: FIX THIS
                            // title(tooltipItem, data) {
                            //     let responses = '0'
                            //     if (
                            //         data.datasets[tooltipItem[0].datasetIndex].responded
                            //     ) {
                            //         responses =
                            //             data.datasets[tooltipItem[0].datasetIndex]
                            //                 .responded[tooltipItem[0].index]
                            //     }
                            //     return responses + ' | ' + tooltipItem[0].yLabel
                            // },
                            label: (context) => '',
                        },
                        backgroundColor: '#E0E7FF',
                        titleColor: '#2E5BFF',
                        titleFont: { weight: 'normal', size: 8 },
                        titleMarginBottom: 0,
                    },
                },
                scales: {
                    y: {
                        grid: {
                            color: '#F5F6F7',
                        },
                        border: {
                            display: true,
                        },
                        ticks: {
                            stepSize: 25,
                            font: { size: 11 },
                            color: '#B0BAC9',
                        },
                        beginAtZero: false,
                    },
                    x: {
                        grid: {
                            color: '#E4E8EB',
                        },
                        border: {
                            display: false,
                            dash: [3],
                        },
                        ticks: {
                            font: { size: 11 },
                            color: '#B0BAC9',
                        },
                    },
                },
            },
        })
    }
}
</script>

<style scoped lang="less">
.graph-container {
    margin-top: 10px;
}
</style>
<style lang="less">
.trend-caret {
    width: 8px;
    margin-left: 4px;
    position: relative;
    top: 1px;
}
</style>
