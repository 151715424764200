<template>
    <div class="select-container">
        <Typography v-if="label" variant="overline" class="select-label">
            {{ label }}:
        </Typography>
        <select @change="$emit('input', $event.target.value)">
            <option
                v-for="(option, optionIdx) in options"
                :key="optionIdx"
                :value="option.value"
                :selected="value === option.value"
            >
                {{ option.label }}
            </option>
        </select>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import Typography from '@/components/Rain/Typography/Typography.vue'

@Component({
    components: { Typography },
    emits: ['input'],
})
export default class BasicSelect extends Vue {
    @Prop({ type: Array, default: [] })
    options!: [{ label: string; value: string }]

    @Prop({ type: String, default: '' })
    value

    @Prop({ type: String, default: '' })
    label
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';

.select-container {
    display: flex;
    align-items: center;

    .select-label {
        color: @grey;
        margin-right: @gutterSpacing-base;
    }

    select {
        border: none;
        background: transparent;
        color: @primaryBlue;
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';
        outline: none;
        position: relative;
        top: -1px;
        cursor: pointer;
    }
}

select::-ms-expand {
    display: none;
}
</style>
