import UrlParse from 'url-parse'
import UrlPattern from 'url-pattern'
import queryString, { ParsedQuery } from 'query-string'
import { Capacitor } from '@capacitor/core'
import { PushNotifications } from '@capacitor/push-notifications'
import { DeepLinkType, patternForDomain } from '@/mobile/src/services/deeplink'
import {
    login,
    getGrantToken,
    openMagicInvitationLink,
    loginSupport,
    unRegisterPushDevice,
    getCsrfToken,
} from '@/mobile/src/api/auth'
import router from '../router'
import store from '../store'
import { LooseObject } from '@/pages/appstore/entities/app'
import { NoticeType } from '@/store/modules/notices'
import { getDeviceId } from '@/mobile/src/utils/capacitor'

export async function processMagicLink(url: string): Promise<LooseObject> {
    const { domain, token } = parseDeepLinkUrl(url)
    return authMagicLink(domain, token)
}

export async function authMagicLink(
    domain,
    token,
    mfaCode = null,
    mfaRememberDevice = false
) {
    const deviceId = await getDeviceId()
    const data = mfaCode
        ? await openMagicInvitationLink(domain, token, {
              mfaCode,
              mfaRememberDevice,
              deviceId,
          })
        : await openMagicInvitationLink(domain, token, {
              deviceId,
          })
    if (data && data.success && (data.data.auth_token || data.data.mfa)) {
        return data.data
    }
    if (data && data.raw && !data.success && data.raw.data.mfa) {
        return data.raw.data
    }
    return {}
}

interface MagicLink {
    domain: string
    token: string
    query?: ParsedQuery
}

interface DeepLink {
    domain: string
    token: string
    type: DeepLinkType
    query?: ParsedQuery
    id: string
}

interface Link {
    domain: string
    token: string
    query?: ParsedQuery
}

const patternForInvitationToken = new UrlPattern(
    /^\/(login\/invitation\/accept)\/(.*)$/,
    ['_path', 'token']
)

const patternForActivationToken = new UrlPattern(
    /^\/(password\/activate)\/(.*)$/,
    ['_path', 'token']
)

const patternForUserValidateToken = new UrlPattern(
    /^\/(user\/validate)\/(.*)$/,
    ['_path', 'token']
)

const patternForNoticePath = new UrlPattern(/^\/(notices\/message)\/?(.*)$/, [
    'path',
    'id',
])

const patternForNoticesPath = new UrlPattern(/^\/(dash\/notices)\/?(.*)$/, [
    'path',
    'id',
])

const patternForChat = new UrlPattern(/^\/(dash\/overview)\?chat=(.*)$/, [
    'path',
    'id',
])

const patternForFeedbackDetails = new UrlPattern(/^\/(chat\/view)\/?(.*)$/, [
    'path',
    'id',
])

export function parseDeepLinkUrl(url: string): DeepLink {
    // only need tenant and invitation token from link
    const parsedUrl = new UrlParse(url)
    const parseQueryString = queryString.parse(parsedUrl.query)

    const hostnameMatches = patternForDomain.match(parsedUrl.hostname)

    let type = 'no match' as DeepLinkType
    let token = '',
        id = ''
    if (patternForInvitationToken.match(parsedUrl.pathname)) {
        type = 'magic-login'
        token = patternForInvitationToken.match(parsedUrl.pathname).token
    } else if (patternForActivationToken.match(parsedUrl.pathname)) {
        token = patternForActivationToken.match(parsedUrl.pathname).token
        type = 'password-activation'
    } else if (patternForUserValidateToken.match(parsedUrl.pathname)) {
        token = patternForUserValidateToken.match(parsedUrl.pathname).token
        type = 'contact-method-validation'
    } else if (patternForNoticePath.match(parsedUrl.pathname)) {
        type = 'notices-view'
        id = patternForNoticePath.match(parsedUrl.pathname).id
    } else if (patternForNoticesPath.match(parsedUrl.pathname)) {
        type = 'notices-view'
        id = patternForNoticesPath.match(parsedUrl.pathname).id
    } else if (patternForChat.match(parsedUrl.pathname + parsedUrl.query)) {
        type = 'chat-view'
        id = parseQueryString.chat
    } else if (patternForFeedbackDetails.match(parsedUrl.pathname)) {
        type = 'chat-view'
        id = patternForFeedbackDetails?.match(parsedUrl?.pathname).id
    }

    return {
        domain: hostnameMatches ? hostnameMatches.domain : '',
        token,
        type,
        query: parseQueryString,
        id,
    }
}

export function buildMagicLink(magicLink: MagicLink): string {
    const { domain, token } = magicLink
    return `https://${domain}.asknice.ly/login/invitation/accept/${token}`
}

export async function validateMagicLinkAndRedirect(
    domain,
    invitationToken,
    verificationCode,
    mfaRememberDevice
) {
    const credentials = await authMagicLink(
        domain,
        invitationToken,
        verificationCode,
        mfaRememberDevice
    )

    if (credentials.auth_token) {
        await postLoginActions(
            domain,
            credentials.auth_token,
            !!credentials?.generate_recovery_codes
        )
        // register device for push notifications
        if (Capacitor.isPluginAvailable('PushNotifications')) {
            PushNotifications.register()
        }
    } else if (credentials.mfa) {
        alert('Verification failed, please try again')
    } else {
        alert(`Sorry, we couldn't log you in this time`)
        await router.replace({ name: 'home' })
    }
}

// login user and redirect to either a homepage or MFA authentication
export async function loginAndRedirect(
    domain,
    email,
    password,
    mfa?: string,
    mfaRememberDevice?: boolean,
    mfaSetupCode?: string,
    recoveryCode?: string
) {
    const result = await login(
        domain,
        email,
        password,
        mfa,
        mfaRememberDevice,
        mfaSetupCode,
        recoveryCode
    )

    if (result.success) {
        if (result.data?.mfa) {
            await router.replace({
                name: 'mfa-login',
                query: { domain },
            })
        } else if (result.data?.recoveryCode) {
            await router.replace({
                name: 'mfa-recovery-code-login',
                query: { domain },
            })
        } else if (result.data?.mfa_setup) {
            await router.replace({
                name: 'enforced-mfa-setup',
                query: {
                    domain: domain,
                    secret: result.data?.mfa_secret,
                },
            })
        } else {
            const { accesstoken } = result.data
            await postLoginActions(
                domain,
                accesstoken,
                !!result.data?.generate_recovery_codes
            )
            // register device for push notifications
            if (Capacitor.isPluginAvailable('PushNotifications')) {
                await PushNotifications.register()
            }
        }
    } else {
        if (mfa || mfaSetupCode) {
            alert('Verification failed, please try again')
        } else {
            alert('Login failed, please try again')
        }
    }
}

export async function loginSupportAndRedirectHome(
    domain: string,
    email: string,
    adminUserToken: string
) {
    const result = await getGrantToken(email, adminUserToken, domain)
    if (result.success) {
        const { grantToken } = result.data
        const supportResult = await loginSupport(
            adminUserToken,
            grantToken,
            email,
            domain
        )
        if (supportResult.success) {
            const { accesstoken } = supportResult.data
            await postLoginActions(domain, accesstoken)
            return null
        }
        return supportResult
    }
    return result
}

export async function postLoginActions(
    domain,
    accesstoken,
    generateRecoveryCodes = false
) {
    // set up new user domain and token
    await store.dispatch('setUserProp', { domain, accesstoken })

    // fetch permissions and features
    await store.dispatch('dispatchStoreLogin')

    if (store.getters.permissions.includes('ROLE_VIEW_NOTICES')) {
        if (store.getters.userHasMlp) {
            // App V4: Restrict to Message, Shoutout, and Coaching
            await store.dispatch('loadMessagesReceived', [
                NoticeType.General,
                NoticeType.Shoutout,
                NoticeType.Coaching,
            ])
        } else {
            await store.dispatch('loadMessagesReceived')
        }
    }
    // If user has no recovery codes, we need to show generate and show recovery codes to user
    if (generateRecoveryCodes) {
        await router.replace({
            name: 'recovery-codes-setup',
            replace: true,
            params: {
                fromMfaSetup: 'false',
            },
        })
    } else {
        // redirect to the first eligible tab
        router.replace({
            name: store.getters.mainNavTabs[0].name,
            replace: true,
        })
    }
}
