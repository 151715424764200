import { Emitter } from 'mitt'

export const showToastEvent = 'showToast'
export const hideToastEvent = 'hideToast'

export function showToast(
    emitter: Emitter<any> | null,
    icon,
    color,
    msg,
    wait = 0,
    dismissable = false
) {
    const time = Date.now()
    emitter?.emit(showToastEvent, {
        icon,
        color,
        msg,
        time,
        wait,
        dismissable,
    })
    return time
}

export function hideToast(emitter: Emitter<any> | null, time) {
    emitter?.emit(hideToastEvent, { time })
}
