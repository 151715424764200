<template>
    <div class="card-container">
        <div v-show="remainingNoticesCount" class="unread">
            <div>Your unread notices</div>
            <div>{{ remainingNoticesCount }} left</div>
        </div>
        <div v-if="writingFeedback" class="feedback-container">
            <div class="feedback">
                <div
                    class="close"
                    data-action="feedback"
                    @click="writingFeedback = false"
                >
                    &times;
                </div>
                <div class="feedback-title">Your Feedback:</div>
                <div>
                    <textarea
                        v-model="feedbackComment"
                        placeholder="Write Feedback"
                        rows="5"
                    />
                </div>
                <div class="feedback-submit">
                    <BaseButton
                        :disabled="feedbackComment.length < 1"
                        :click="onSubmitFeedback"
                        class="btn-submit"
                        primary
                        small
                        >Submit</BaseButton>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="close" @click="respond('close')">
                <img src="../WebView/icons/Close.svg" alt="Close" />
            </div>
            <div class="photos">
                <div class="notice-photo" :class="noticeClassName">
                    <img :src="noticeType.icon" />
                </div>
                <div class="sender-photo">
                    <AvatarOrInitials :user="notice.sender" :size="30" />
                </div>
            </div>
            <div class="header">
                <div class="sender-name">{{ notice.sender.name }}</div>
                <div class="meta-data">
                    <NoticeTypeBadge
                        class="notice-type"
                        :notice-type="notice.type"
                    />
                    <div>∙</div>
                    <div class="time">
                        {{ timeSinceNoticeCreated(notice.created) }}
                    </div>
                    <div>∙</div>
                    <div class="sent-to">
                        {{ sentTo }}
                    </div>
                </div>
            </div>
            <div
                class="body"
                :class="{ 'is-receiver': isReceiver && notice.seen }"
            >
                <Quote
                    v-if="notice.questioncomment"
                    class="quote-comment"
                    :text="notice.questioncomment"
                />
                <div
                    class="comment"
                    :style="'font-size: ' + commentFontSize + 'px'"
                >
                    <TextWithHighlight
                        class="subject"
                        :text="notice.message"
                        :highlights="highlights"
                    />
                </div>
            </div>
        </div>
        <template v-if="isReceiver && !notice.seen">
            <div v-if="allowedRespondAction" class="options">
                <div
                    v-for="(
                        emoji, reactionKey
                    ) in AVAILABLE_NOTICE_REACTION_TYPES"
                    :key="reactionKey"
                    class="respond"
                    :data-action="reactionKey"
                    @click="respond(reactionKey)"
                >
                    {{ emoji }}
                </div>
            </div>
            <div
                :style="{
                    visibility: showAddFeedback ? 'visible' : 'hidden',
                }"
                class="respond-feedback"
                data-action="feedback"
                @click="writingFeedback = true"
            >
                <span data-action="feedback">+ Add Feedback</span>
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { timeSince } from '@/utils/time'
import { Getter, Action } from 'vuex-facing-decorator'
import { respondToNotice } from '@/api/notices'
import { getJoinedRecipientNames } from '@/utils/notices'
import { sleep } from '@/utils/async'
import confetti from '@/utils/fancyConfetti'
import { IMessage, IRecipient, NoticeRespondAction } from '@/entities/notices'
import Loading from '@/components/Loading.vue'
import AvatarOrInitials from '@/components/WebView/AvatarOrInitials.vue'
import FeedbackModal from '@/components/FeedbackModal.vue'
import NoticeTypeBadge from './NoticeTypeBadge.vue'
import BaseButton from '@/components/BaseButton.vue'
import TextWithHighlight from '@/components/TextWithHighlight.vue'
import Quote from '@/components/Notices/Quote.vue'
import { PredefinedNoticeTypes } from '@/utils/notices'
import { AVAILABLE_NOTICE_REACTION_TYPES } from '@/entities/notices'

@Component({
    components: {
        BaseButton,
        TextWithHighlight,
        FeedbackModal,
        AvatarOrInitials,
        Loading,
        NoticeTypeBadge,
        Quote,
    },
    emits: ['respond', 'read'],
})
export default class NoticeTemplate extends Vue {
    @Prop({ type: Boolean, default: true }) private isReceiver!: boolean
    @Prop({ type: String, default: '' }) private response?: string
    @Prop({ type: Number, default: 0 }) private remainingNoticesCount?: number
    @Prop({ type: Object, default: {} }) private notice!: IMessage

    @Getter private recipients!: IRecipient[]

    @Action private loadRecipients

    public AVAILABLE_NOTICE_REACTION_TYPES = AVAILABLE_NOTICE_REACTION_TYPES
    private writingFeedback = false
    private feedbackComment = ''

    public mounted() {
        if (!this.recipients.length) {
            this.loadRecipients()
        }
    }

    private get noticeClassName() {
        return this.notice.type.replace(/\s/g, '')
    }

    private get noticeType() {
        return PredefinedNoticeTypes.find(
            (noticeType) => noticeType.name === this.notice.type
        )
    }

    private get allowedRespondAction() {
        const noticeType = this.noticeType
        return noticeType ? noticeType.allowedRespondActions : 0
    }

    private get showAddFeedback() {
        return this.allowedRespondAction & NoticeRespondAction.Feedback
    }

    private timeSinceNoticeCreated(created: string | number) {
        return timeSince(created)
    }

    private async respond(action, feedback = '') {
        respondToNotice(this.notice.noticesrecipient_id, action, feedback)

        if (action !== 'close') {
            await confetti.init('notice-stack-container', 1000)
            await confetti.start(1000, 1000, 1000)
            await sleep(3000)
        }

        this.$emit('respond', this.notice.id)
    }

    private onSubmitFeedback() {
        const feedback = this.feedbackComment
        this.$emit('read', { id: this.notice.id, feedback })
        this.respond('feedback', feedback)
    }

    public get sentTo() {
        return getJoinedRecipientNames(this.notice.recipients)
    }

    public get highlights() {
        return this.recipients.map((user) => `@${user.name}`)
    }

    get commentFontSize() {
        if (this.notice.message.length < 20) {
            return 52
        } else if (this.notice.message.length < 80) {
            return 24
        } else if (this.notice.message.length < 130) {
            return 20
        }
        return 13
    }
}
</script>

<style lang="less" scoped>
@import '../../styles/palette';
@import '../../styles/fade.less';

.ghost {
    visibility: hidden;
}

.card-container {
    height: 540px;
    width: 343px;

    @media screen and (max-width: 320px) {
        width: 296px;
    }

    position: absolute;
    background: white;
    border: 1px solid #e0e7ff;
    box-sizing: border-box;
    box-shadow: 0 0 10px rgba(30, 43, 90, 0.04);
    border-radius: 8px;
    text-align: center;

    .unread {
        display: flex;
        justify-content: space-between;
        padding: 8px 12px;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: @grey40;
    }

    .feedback-container {
        height: 100%;
        width: 100%;
        background: @white;
        position: absolute;
        z-index: 1;

        .feedback {
            .close {
                position: absolute;
                top: 0;
                right: 0;
                padding: 15px;
                font-size: 20px;
                color: @greyMid;
                cursor: pointer;
            }

            text-align: left;
            padding: 24px;

            .feedback-title {
                font-size: 22px;
                padding: 10px 0 30px 0;
            }

            textarea {
                width: 100%;
                border: 1px solid @greyMid;
                border-radius: 5px;
                font-size: 16px;
                outline: none;
                resize: vertical;
                box-sizing: border-box;
                font-family: inherit;
            }

            textarea::placeholder {
                color: @greyMid;
            }

            .feedback-submit {
                text-align: right;
            }
        }
    }

    .card {
        padding: 12px;
        height: 420px;

        .body {
            height: 248px;
            overflow-y: auto;

            &.is-receiver {
                height: 330px;
            }
        }

        .close {
            position: absolute;
            right: 14px;
            cursor: pointer;
        }

        .photos {
            height: 84px;
            padding: 10px 0;

            .notice-photo {
                margin: 0 auto;
                width: 80px;
                height: 80px;
                position: relative;

                img {
                    height: 80px;
                }

                &.coaching {
                    left: 5px;
                }

                &.notice {
                    left: 2px;
                }
            }

            .sender-photo {
                border: 2px solid #ffffff;
                border-radius: 50%;
                width: 30px;
                height: 30px;
                margin: -24px auto;
                position: relative;
            }
        }

        .header {
            margin-top: 20px;
            margin-bottom: 20px;

            .sender-name {
                color: @grey40;
                font-weight: 500;
                font-size: 10px;
                line-height: 12px;
                letter-spacing: 1px;
                text-transform: uppercase;
            }

            .meta-data {
                white-space: nowrap;
                align-content: space-between;
                display: flex;
                justify-content: center;
                padding: 6px 0;

                div {
                    font-size: 10px;
                    line-height: 12px;
                    color: @grey40;
                    padding: 2px;
                }

                .notice-type {
                    line-height: 12px;
                    font-size: 10px;
                }

                .sent-to {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        .comment {
            margin: 8px 6px;
            text-align: left;
            font-weight: normal;
            font-size: 13px;
            color: @grey90;

            span {
                display: inline-block;
                vertical-align: middle;
                line-height: 1.5;
            }
        }
        .respond-feedback {
            color: @grey40;
            cursor: pointer;
            text-align: left;
            padding: 16px 4px 6px;
        }
    }
}
.options {
    height: 60px;
    align-content: space-between;
    display: flex;
    justify-content: center;
    padding: 6px 0;
    border-top: 1px solid @blueLight;

    .neutral {
        font-size: 34px;
    }

    .acknowledge {
        font-size: 27px;
    }

    .neutral,
    .acknowledge {
        height: 60px;
        line-height: 60px;
        width: 60px;
        margin: 0 6px;
        border-radius: 50%;
        border: 1px solid @blueLight;
        box-sizing: border-box;
        cursor: pointer;
    }

    .respond {
        height: 60px;
        line-height: 60px;
        margin: 0;
        font-weight: 300;
        font-size: 28px;
        flex: 1;
        text-align: center;
        cursor: pointer;
    }
}

.quote-comment {
    margin-top: 16px;
}
</style>

<style>
.card .comment .subject > p {
    line-height: 1.5;
    display: inline-block;
    vertical-align: middle;
}
</style>
