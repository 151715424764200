<template>
    <PortalContainer v-if="props.show" @back="handleClose">
        <Modal
            :show="props.show"
            :size="ModalSize.Full"
            :has-close="true"
            :close="handleClose"
            :small-header="true"
            :padding="25"
        >
            <div class="container">
                <Loading
                    v-if="loading"
                    :loading="loading"
                    :contained="true"
                ></Loading>
                <template v-else>
                    <div class="close" @click="handleClose">&times;</div>
                    <div class="header">
                        <Typography variant="subtitle1">Company Goals</Typography>
                    </div>

                    <div class="content">
                        <Typography variant="body1">Any change affects the
                            <strong>company-wide</strong> goal.</Typography>
                        <Typography variant="body2">Make sure you have permission to change
                            this</Typography>

                        <div v-if="isNpsTargetEnabled" class="goal-input">
                            <Typography>NPS Goal</Typography>
                            <input
                                v-model="npsTarget.value.value"
                                type="number"
                                min="-100"
                                max="100"
                                step="1"
                            />
                            <Typography
                                v-if="npsTarget.errors.value.length > 0"
                                variant="body2"
                                class="error"
                                >Goal must be between -100 and 100</Typography>
                        </div>
                        <div v-if="isCsatTargetEnabled" class="goal-input">
                            <Typography>CSAT Goal</Typography>
                            <input
                                v-model="csatTarget.value.value"
                                type="number"
                                min="0"
                                max="5.0"
                                step="0.1"
                            />
                            <Typography
                                v-if="csatTarget.errors.value.length > 0"
                                variant="body2"
                                class="error"
                                >Goal must be between 0 and 5.0</Typography>
                        </div>
                        <div v-if="isFiveStarTargetEnabled" class="goal-input">
                            <Typography>Five Star Goal</Typography>
                            <input
                                v-model="fiveStarTarget.value.value"
                                type="number"
                                min="0"
                                max="5.0"
                                step="0.1"
                            />
                            <Typography
                                v-if="fiveStarTarget.errors.value.length > 0"
                                variant="body2"
                                class="error"
                                >Goal must be between 0 and 5.0</Typography>
                        </div>

                        <Typography
                            v-if="errorOnSave"
                            variant="body2"
                            class="error"
                            >Failed to save, please try again.</Typography>
                    </div>

                    <div class="footer">
                        <BaseButton
                            :full="true"
                            :centred="true"
                            class="purple outline"
                            :click="handleClose"
                        >
                            Cancel
                        </BaseButton>
                        <BaseButton
                            :disabled="hasValidationErrors"
                            :full="true"
                            :centred="true"
                            class="purple"
                            :click="saveTargets"
                        >
                            Update
                        </BaseButton>
                    </div>
                </template>
            </div>
        </Modal>
    </PortalContainer>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue'
import type { Ref } from 'vue'
import { defineRule, useField } from 'vee-validate'
import { between, required } from '@vee-validate/rules'
import { ModalSize } from '@/components/modal-size'
import Modal from '@/components/Modal.vue'
import Typography from '@/components/Rain/Typography/Typography.vue'
import PortalContainer from '@/mobile/src/components/containers/PortalContainer.vue'
import BaseButton from '@/components/BaseButton.vue'
import Loading from '@/components/Loading.vue'
import { getCompanyFields, setCompanyConfig } from '@/api/company'
import { CompanyAttributes, ICompanyConfig } from '@/entities'
import { countDecimals } from '@/utils/number'
import store from '@/mobile/src/store/index'

defineRule('between', between)
defineRule('required', required)
defineRule('maxDecimals', (value, [quantity]) => {
    return countDecimals(value) <= quantity
})

interface Props {
    show: boolean
}
const props = defineProps<Props>()
const emit = defineEmits(['close'])

const companyVars = computed(() => store.getters['$companyVars'])
const hasMultiMetric = computed(
    () => companyVars.value.has_lead_with_any_question
)
const isNpsTargetEnabled = computed(() => companyTargets.value?.target_enabled)
const isCsatTargetEnabled = computed(
    () => companyTargets.value?.target_csat_enabled && hasMultiMetric
)
const isFiveStarTargetEnabled = computed(
    () => companyTargets.value?.target_fivestar_enabled && hasMultiMetric
)

const companyTargets: Ref<ICompanyConfig> = ref({})
const loading = ref(false)
const errorOnSave = ref(false)
const hasValidationErrors = ref(true)

const npsTarget = useField<string>('NPS Goal', {
    between: [-100, 100],
    maxDecimals: 0,
    required: true,
})
const fiveStarTarget = useField<string>('Five Star Goal', {
    between: [0, 5],
    maxDecimals: 1,
    required: true,
})
const csatTarget = useField<string>('CSAT Goal', {
    between: [0, 5],
    maxDecimals: 1,
    required: true,
})

watch(npsTarget.errors, () => {
    if (hasMultiMetric.value) {
        hasValidationErrors.value =
            npsTarget.errors.value.length > 0 ||
            fiveStarTarget.errors.value.length > 0 ||
            csatTarget.errors.value.length > 0
    } else {
        hasValidationErrors.value = npsTarget.errors.value.length > 0
    }
})

watch(fiveStarTarget.errors, () => {
    if (hasMultiMetric.value) {
        hasValidationErrors.value =
            npsTarget.errors.value.length > 0 ||
            fiveStarTarget.errors.value.length > 0 ||
            csatTarget.errors.value.length > 0
    }
})

watch(csatTarget.errors, () => {
    if (hasMultiMetric.value) {
        hasValidationErrors.value =
            npsTarget.errors.value.length > 0 ||
            fiveStarTarget.errors.value.length > 0 ||
            csatTarget.errors.value.length > 0
    }
})

onMounted(async () => {
    let fieldsToFetch: CompanyAttributes[] = []
    const enabledTargets = hasMultiMetric.value
        ? ['target', 'target_csat', 'target_fivestar']
        : ['target']

    enabledTargets.forEach((targetType) => {
        fieldsToFetch.push(CompanyAttributes[targetType])
        fieldsToFetch.push(CompanyAttributes[targetType + '_enabled'])
    })

    loading.value = true
    const { data } = await getCompanyFields(fieldsToFetch)

    companyTargets.value = data
    npsTarget.setValue(data.target)
    fiveStarTarget.value.value = data.target_fivestar
    csatTarget.value.value = data.target_csat
    loading.value = false
})

const saveTargets = async () => {
    if (!hasValidationErrors.value) {
        const { data } = await setCompanyConfig({
            ...companyTargets.value,
            target: npsTarget.value.value,
            target_fivestar: fiveStarTarget.value.value,
            target_csat: csatTarget.value.value,
        })

        if (data.success) {
            emit('close')
        } else {
            errorOnSave.value = true
        }
    }
}

const handleClose = () => {
    emit('close')
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/palette.less';
@import '~@/styles/rain/variables.less';

.container {
    padding: 25px;
    background: white;
    border: 1px solid #e0e7ff;
    box-sizing: border-box;
    box-shadow: 0 0 10px rgba(30, 43, 90, 0.04);
    border-radius: 8px;
    text-align: left;

    .close {
        position: absolute;
        font-size: 25px;
        right: 25px;
        font-weight: 100;
        top: 15px;
        padding: 10px;
        color: #979797;
    }

    .header {
        padding-bottom: 12px;
    }

    .content {
        p {
            &.body1 {
                font-size: @fontSize-base;
                padding-bottom: 8px;
            }

            &.body2 {
                font-size: @fontSize-sm;
                color: @darkGrey;
                padding-bottom: 25px;
            }

            &.error {
                color: @errorRed;
                padding: 0;
            }
        }

        .goal-input {
            text-align: center;
            padding-bottom: 20px;
            p.body1 {
                padding-bottom: 0;
            }
        }
    }

    .footer {
        display: flex;
        justify-content: space-between;
        padding-top: @gutterSpacing-md;
        gap: @gutterSpacing-base;
    }
}

.button {
    padding: 6px 20px;
    border-radius: 8px;

    &.purple {
        background-color: @purple;
        color: @white;

        &.outline {
            background: @white;
            color: @black;
            border: 1px solid @purple;
        }

        &.disabled {
            background: @disabledColor;
            color: @greyLight4;
            cursor: not-allowed;
        }
    }
}

input {
    font-size: @fontSize-lg;
    color: @offBlack;
    font-weight: 450;
    width: 20%;
    box-sizing: border-box;
    border: 1px solid lightgrey;
    border-radius: 5px;
    font-family: @fontFamily;
    text-align: center;

    &:focus {
        outline: none;
    }
}
</style>
