import client, { formClient } from '@/api/client'
import { GlobalContactRules } from '@/pages/simple-survey/entities/contactRules'
import {
    SurveySettingsTab,
    SurveySettingsTabResponse,
    SurveyWhole,
} from '@/pages/simple-survey/entities/survey'
import {
    DatapipeFetch,
    DatapipeResponse,
    DatasourceResponse,
} from '@/pages/simple-survey/entities/datapipe'
import {
    Schedule,
    TouchBaseSchedule,
    AudienceTimingConfig,
} from '@/pages/simple-survey/entities/schedule'
import {
    SurveyColorAndLayoutType,
    SurveyEmailSetupType,
} from '@/pages/simple-survey/entities/design'

declare let ANAPI_TOKEN: string

export async function saveGlobalContactRules(data: GlobalContactRules) {
    return client.post(`/send/save/globals`, data)
}

export async function getDesign(surveyId: string) {
    return client.get(`/survey/design/${surveyId}`)
}

export async function getAudienceAndTiming(surveyId: string) {
    return client.get(`/survey/audience-and-timing/${surveyId}`)
}

export async function updateAudienceAndTiming(
    config: AudienceTimingConfig,
    surveyId: string
) {
    return client.post(`/survey/audience-and-timing/${surveyId}`, config)
}

export async function updateEmailSetup(
    config: SurveyEmailSetupType,
    surveyId: string
) {
    return client.post(`/survey/email-setup/${surveyId}`, config)
}

export async function updateColorAndLayout(
    config: SurveyColorAndLayoutType,
    surveyId: string
) {
    return client.post(`/survey/color-and-layout/${surveyId}`, config)
}

export async function getSurveys(surveys: string[]) {
    return client.get(`/surveys?${surveys.map((s) => `ids[]=${s}`).join('&')}`)
}

export async function validateAudienceAndTiming(surveyId: string) {
    return client.get(`/survey/validate-audience-and-timing/${surveyId}`)
}

export async function getEligibleCounts(surveyId: string) {
    const { data } = await client.get(
        `/survey/get-eligible-counts/${surveyId}`,
        {}
    )
    return data.counts
}

export async function getSurveyQuestions(surveyId: string) {
    const { data } = await client.get(
        `/survey/get-survey-questions/${surveyId}`
    )
    return data
}

export async function updateSurvey(id, data: SurveySettingsTab) {
    return client.post<SurveySettingsTabResponse>(`/survey/${id}`, data)
}

export async function loadDatapipes(id, data: DatapipeFetch) {
    return client.post<DatapipeResponse>(`/survey/datapipes/${id}`, data)
}

export async function loadDatasources(type) {
    return client.get<DatasourceResponse>(`/survey/datasources/${type}`)
}

export async function getSurvey(id: string) {
    const { data } = await client.get(`/survey/${id}`)
    return data
}

export async function getLang(id) {
    const response = await client.get(`/lang/${id}`)
    return response.data
}

export async function getVerifiedDomains() {
    const response = await client.get(`/verified-domains`)
    return response.data
}

export async function getMe() {
    const response = await client.get(`/api/v2/me`, { baseURL: '/' })
    return response.data
}

export async function saveLang(params, id) {
    return client.post(`/setting/email/save/${id}`, params, { baseURL: '/' })
}

export async function saveLogo(file, id, type = 'logo') {
    const formData = new FormData()
    formData.append('files[]', file)
    formData.append('_token', ANAPI_TOKEN)

    return formClient.post(`/setting/email/savelogo/${id}/${type}`, formData)
}

export async function setStatus(id, status) {
    return client.post(`/survey/status/${id}/${status}`)
}

export async function clearEligible(id) {
    return client.post(`/survey/clear-eligible/${id}`)
}

export async function duplicateSurvey(id) {
    const response = await client.post(`/survey/duplicate/${id}`)
    return response.data.survey
}

export async function deleteSurvey(id) {
    return client.delete(`/survey/${id}`)
}

export async function emailNow(personId, template) {
    const formData = new FormData()
    formData.append('template', template)
    formData.append('_token', ANAPI_TOKEN)

    return formClient.post(`/queue/emailnow/${personId}`, formData)
}
