// This class has 4 fields, the x0 and y0 are the start point.
// The x1 and y1 are the end point.
// The method calculates how much should it move from x0, y0 to x1, y1
// according to the ratio.
export default class Segment {
    public x0: number
    public y0: number
    public x1: number
    public y1: number
    constructor(x0 = 0, y0 = 0, x1 = 0, y1 = 0) {
        this.x0 = x0
        this.y0 = y0
        this.x1 = x1
        this.y1 = y1
    }
    public getNewX(ratio) {
        return this.x0 + (this.x1 - this.x0) * ratio
    }
    public getNewY(ratio) {
        return this.y0 + (this.y1 - this.y0) * ratio
    }
}
