<template>
    <div class="engagement-focus-question">
        <div class="top">
            <div class="icon">
                <img :src="icon" />
            </div>
            <div class="question">
                {{ question.question }}
            </div>
        </div>
        <div class="middle">
            <div
                v-for="opt in question.options"
                v-show="!question.answer || question.answer === opt.value"
                :key="opt.value"
                class="focus-question-option"
                :class="{ selected: question.answer === opt.value }"
                @click="onClickOption(opt)"
            >
                <img width="40" height="40" :src="getBadgeImg(opt)" />
                <div class="label">{{ opt.label }}</div>
            </div>
            <div v-if="question.answer !== null" class="comment">
                <FlexiMultilineTextInput
                    :value="question.comment ? question.comment : ''"
                    :blur-on-new-line="true"
                    placeholder="Want to share more…"
                    @input="$emit('update', { key: 'comment', value: $event })"
                />
            </div>
        </div>
        <div class="bottom">
            <BaseButton full :disabled="!question.answer" @click="submitAnswer">
                Done
            </BaseButton>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { EngagementQuestion } from '@/entities/engagement'
import FlexiMultilineTextInput from '@/components/FlexiMultilineTextInput.vue'
import { badgeSchemas } from '@/entities/scorecard'
import BaseButton from '@/components/Rain/Buttons/BaseButton.vue'
import { EngagementQuestionIcon } from '@/mobile/src/components/engagement/utils'

@Component({
    components: {
        BaseButton,
        FlexiMultilineTextInput,
    },
    emits: ['update', 'done'],
})
export default class EngagementScorecardQuestion extends Vue {
    @Prop({ type: Object, required: true })
    public question!: EngagementQuestion

    public getBadgeImg(opt) {
        const badgeSchema = badgeSchemas[opt.icon]
        return badgeSchema ? badgeSchema.img : ''
    }

    public onClickOption(opt) {
        this.$emit('update', {
            key: 'answer',
            value: this.question.answer === opt.value ? null : opt.value,
        })
    }

    public submitAnswer() {
        this.$emit('done', this.question)
    }

    get icon() {
        return EngagementQuestionIcon[this.question.theme]
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/variables';
@import '~@/styles/typography';

.engagement-focus-question {
    text-align: center;
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;

    .top {
        padding-top: 30px;
    }
    .middle {
        flex-grow: 1;
        overflow: scroll;
    }

    .bottom {
        flex: 0;
        padding: 16px 16px 32px 16px;
    }

    .icon img {
        height: 80px;
        margin-bottom: 20px;
    }

    .question {
        .heading();
        margin: 12px;
    }

    .comment {
        margin-top: 10px;
        padding: 0 16px;
        height: 20vh;
        max-height: 200px;
    }

    @media (min-height: 668px) {
        .comment {
            margin: 40px 0;
        }
    }

    @media (max-height: 630px) {
        .comment {
            height: 20vh;
        }
    }

    .focus-question-option {
        display: flex;
        margin: 16px 24px;
        padding: 10px;
        border: 1px solid @blueLight;
        border-radius: 8px;

        img {
            margin-right: 16px;
        }

        .label {
            display: flex;
            align-items: center;
            font-weight: 500;
            color: @black80;
        }

        &.selected {
            background: linear-gradient(90deg, #ffdaa3 0%, #f3b784 100%);
            border-color: transparent;

            .label {
                color: @white;
            }
        }
    }
}
</style>
