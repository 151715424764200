<template>
    <div class="team-profile-select-container">
        <HorizontalScrollContainer class="team-profile-scroll-container">
            <div
                v-for="profile in teamProfileUsers"
                :key="profile.user.id"
                class="team-member-profile"
                :class="{ active: activeUserId === profile.user.id }"
                @click="selectProfileUser(profile)"
            >
                <AvatarOrInitials
                    :size="40"
                    :user="{
                        id: profile.user.id,
                        name: profile.user.name,
                        avatar: profile.user.avatar,
                    }"
                />
                <FontAwesomeIcon
                    v-if="profile.hasComment"
                    :icon="faCheckCircle"
                    class="check-circle-icon"
                />
                <div class="name">
                    {{ profile.user.name.trim().split(' ')[0] }}
                </div>
            </div>
        </HorizontalScrollContainer>
    </div>
</template>

<script lang="ts">
import { Component, Watch, Vue } from 'vue-facing-decorator'
import { namespace } from 'vuex-facing-decorator'
import AvatarOrInitials from '@/components/WebView/AvatarOrInitials.vue'
import HorizontalScrollContainer from '@/components/HorizontalScrollContainer.vue'
import TextClamp from '@/components/TextClamp.vue'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faCheckCircle } from 'fontawesome/free-solid-svg-icons'
import { TeamProfileUser } from '@/entities/reportcard'

const ReportCardModule = namespace('reportcard')
const TopicFeedbackModule = namespace('topicfeedback')
const TeamFilterModule = namespace('teamFilter')

@Component({
    components: {
        TextClamp,
        AvatarOrInitials,
        HorizontalScrollContainer,
        FontAwesomeIcon,
    },
})
export default class TeamProfileSelect extends Vue {
    @ReportCardModule.Getter teamProfileUsers!: TeamProfileUser[]
    @ReportCardModule.Action setShowReportCard!: (show: boolean) => void
    @ReportCardModule.Action setDrillDownUser!: (payload: {
        name: string
    }) => void
    @ReportCardModule.Action loadReportCardData!: () => Promise<void>

    @TopicFeedbackModule.Action addTopicFilter
    @TopicFeedbackModule.Action clearTopicFilters
    @TopicFeedbackModule.Action getTopicFeedback

    @TeamFilterModule.Getter filterOptions

    faCheckCircle = faCheckCircle
    public activeUserId: number | null = null
    public reportMonth = new Date().getMonth() // Default to previous month

    @Watch('teamProfileUsers')
    public async onTeamProfileUsersLoaded(): Promise<void> {
        if (this.teamProfileUsers.length > 0) {
            const firstProfile = this.teamProfileUsers[0]

            this.setShowReportCard(true)
            await this.selectProfileUser(firstProfile)
        }
    }

    public getColumnField() {
        if (
            this.filterOptions.length > 0 &&
            this.filterOptions[this.filterOptions.length - 1]
        ) {
            return this.filterOptions[this.filterOptions.length - 1]
                .fieldMapping
        }

        return ''
    }

    public async selectProfileUser(profile: TeamProfileUser): Promise<void> {
        this.activeUserId = profile.user.id
        this.setDrillDownUser({ name: profile.userName })

        await this.clearTopicFilters()
        await this.addTopicFilter({
            column: this.getColumnField(),
            operator: 'in',
            value: [profile.userName],
            fromDrillDown: true,
        })

        await Promise.all([
            this.getTopicFeedback({ month: this.reportMonth }),
            this.loadReportCardData(),
        ])
    }
}
</script>

<style scoped lang="less">
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';

.team-profile-select-container {
    .team-profile-scroll-container {
        padding-left: @featureGutter;
        padding-top: calc(30px + env(safe-area-inset-top));
        display: flex;
        gap: 31px;

        .team-member-profile {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 5px;
            width: 40px;
            position: relative;
            opacity: 0.5;

            &.active {
                opacity: 1;
            }

            .check-circle-icon {
                position: absolute;
                left: 27px;
                opacity: 0.5;
                color: @white;
            }

            .name {
                color: @hoverSecondaryLightBlue;
                text-align: center;
                font-family: @fontFamily;
                font-size: @fontSize-sm;
                font-style: normal;
                font-weight: @fontWeight-regular;
                line-height: @lineHeight-sm;
                letter-spacing: @letterSpacing-2xl;
                text-transform: uppercase;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                width: 70px;
            }
        }
    }
}
</style>
