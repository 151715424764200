<template>
    <div>
        <LoadingCard v-show="props.isLoading" class="loading-container" />
        <div
            v-if="moments.length > 0 && !props.isLoading"
            class="report-card-messages-container"
        >
            <Typography class="topic-title" variant="h4" component="h4">{{
                props.title
            }}</Typography>
            <Carousel>
                <div
                    v-for="moment in moments"
                    :key="moment.id"
                    class="carousel-container"
                >
                    <MomentCard
                        :key="moment.id"
                        :moment="moment"
                        class="carousel-item"
                        @viewDetail="navigateToDetail(moment.id)"
                    />
                </div>
            </Carousel>
        </div>
        <hr v-if="moments.length > 0" />
    </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import type { Ref } from 'vue'
import store from '@/mobile/src/store'
import router from '@/mobile/src/router'
import Typography from '@/components/Rain/Typography/Typography.vue'
import Carousel from '@/components/Rain/Layouts/Carousel/Carousel.vue'
import LoadingCard from '@/mobile/src/components/appV4/LoadingCard.vue'
import { IMessage } from '@/entities/notices'
import { Moment } from '@/entities/moment'
import MomentCard from '@/mobile/src/components/appV4/MomentCard.vue'

interface Props {
    title: string
    isLoading: boolean
    messages?: any[]
    closePopup: () => void
}

const props = defineProps<Props>()

const moments: Ref<Moment[]> = ref([])

watch(
    () => props.messages,
    () => {
        parseMessagesIntoMoments(props.messages)
    }
)

const parseMessagesIntoMoments = (messages) => {
    moments.value = messages?.map((message) => {
        return {
            id: message.id,
            type: message.type,
            updated: message.created,
            created: message.created,
            message: message.message,
            repliesCount: 0,
            seen: message?.seen,
            user: message?.sender,
            recipientName: message?.recipients?.map(
                (recipient) => recipient.name
            ),
            recipients: message?.recipients,
        }
    })
}

parseMessagesIntoMoments(props.messages)

const navigateToDetail = (messageId: string) => {
    const selectedMessage = props.messages?.find(
        (message) => message.id === messageId
    )
    const selectedQuestionId = selectedMessage?.question_id

    if (selectedQuestionId) {
        props.closePopup()
        router.push(`/feedback/detail/${selectedQuestionId}`)
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/variables.less';
@import '~@/styles/rain/colour.less';

.loading-container {
    margin: @gutterSpacing-md;
}

.report-card-messages-container {
    padding: @gutterSpacing-md;
}

.topic-title {
    color: white;
    line-height: 22px;
}

.carousel-container {
    padding-bottom: @gutterSpacing-base;
}

.carousel-item {
    margin: 0 @gutterSpacing-sm;
}

hr {
    color: transparent;
    border-style: solid;
    border-bottom: 1px solid @thinFog;
}
</style>
