import { ref, computed, onMounted, watch, ComputedRef, Ref } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { getQuestionClass } from '@/domain/NPS'

export function chatResponseScreenComposable() {
    const route = useRoute()
    const store = useStore()

    const loading = ref(true)

    const setChatQuestion = (id: number) =>
        store.dispatch('setChatQuestion', id)

    onMounted(async () => {
        const chatMessages = store.getters['chatMessages']
        const chatReply = store.getters['chatReply']
        if (!chatMessages.value || !chatReply.value) {
            await reloadChat()
        } else {
            loading.value = false
        }
    })

    watch(route, async () => {
        const chatQuestionId = store.getters['chatQuestionId']
        if (
            route.params.id &&
            route.params.id !== chatQuestionId.value?.toString()
        ) {
            await reloadChat()
        }
    })

    const reloadChat = async () => {
        loading.value = true
        await setChatQuestion(Number(route.params.id))
        loading.value = false
    }

    const getPermission = (permissionName: string): boolean => {
        const permissions = store.getters['userPermissions']
        return permissions?.includes(permissionName)
    }
    const isAnonymous: Ref<boolean> = ref(
        !!Number(store.getters['chatCustomData']?.anom)
    )
    const dontContact: Ref<boolean> = ref(
        !!Number(store.getters['chatCustomData']?.dontcontact)
    )
    const isPhoneNumber: Ref<boolean> = ref(
        !!Number(store.getters['chatCustomData']?.is_phone_number)
    )
    const showWorkflow: Ref<boolean> = ref(
        getPermission('ROLE_USER_BASICS') &&
            !isAnonymous.value &&
            !dontContact.value
    )
    const chatForwardingLocked: Ref<boolean> = ref(
        Number(store.getters['chatCustomData']?.chat_forwarding_locked) !== 0
    )

    const name: Ref<string> = ref(store.getters['chatCustomData']?.person_name)
    const email: Ref<string> = ref(
        store.getters['chatCustomData']?.person_email
    )

    const abbrName: ComputedRef<string> = computed(() => {
        if (!name.value || name.value === '') {
            return ''
        }

        let abbrName = name.value
            .split(' ')
            .map((n) => n[0])
            .join('')
        const maxLength = 3

        if (abbrName.length > maxLength) {
            abbrName = abbrName.substring(0, maxLength)
        }

        return abbrName.toUpperCase()
    })

    const avatar: ComputedRef<string> = computed(() => {
        if (
            !store.getters['chatCustomData'] ||
            !store.getters['chatCustomData']?.person ||
            isAnonymous.value
        ) {
            return ''
        }

        return store.getters['chatCustomData']?.person.avatar
    })

    const caseStatus: ComputedRef<string> = computed(() => {
        // null | undefined => default ( you can open case)
        // 0 => open
        // 1 => closed
        if (
            !store.getters['chatCustomData'] ||
            !store.getters['chatCustomData']?.case_closed
        ) {
            return 'default'
        }

        return Number(store.getters['chatCustomData']?.case_closed) === 0
            ? 'open'
            : 'closed'
    })

    const circleClass: ComputedRef<string> = computed(() => {
        if (
            !store.getters['chatCustomData'] ||
            store.getters['chatCustomData']?.answer === undefined ||
            store.getters['chatCustomData']?.answer === null
        ) {
            return ''
        }

        const answer = store.getters['chatCustomData']?.answer
        return getQuestionClass(
            answer,
            store.getters['chatCustomData']?.question_type
        )
    })

    return {
        loading,
        setChatQuestion,
        reloadChat,
        circleClass,
        isAnonymous,
        dontContact,
        name,
        email,
        caseStatus,
        avatar,
        abbrName,
        chatForwardingLocked,
        showWorkflow,
        isPhoneNumber,
    }
}
