<template>
    <div class="report-container">
        <MonthPicker
            :month-picker="monthPicker"
            :close-popup="closePopup"
            @monthChanged="monthChanged"
        />
        <hr style="margin-bottom: 0" />
        <div class="scrollable-report-card">
            <ManagerComment
                v-if="
                    hasReportCardComment ||
                    (isFrontlineManager && reportCardUser !== userName)
                "
                :month="reportMonth"
            />
            <NPSDisplay
                v-if="mainScore && currentReportData"
                class="nps-display"
                :score="Number(mainScore)"
                :month="reportMonth"
                :score-type="
                    currentReportData ? currentReportData.questionType : 'nps'
                "
                trigger-from="report-card"
                :help-text="helpText"
                :disable-metric-picker="true"
            />
            <div v-show="!mainScore" class="nps-display-placeholder"></div>
            <GoalHistory :goal-history="reportCardData" />
            <hr />
            <ReportCardMessages
                title="Shoutouts"
                :is-loading="isLoadingReportCardMessages"
                :messages="reportCardShoutoutMessages"
                :close-popup="closePopup"
            />
            <ReportCardMessages
                title="Coaching"
                :is-loading="isLoadingReportCardMessages"
                :messages="reportCardCoachingMessages"
                :close-popup="closePopup"
            />
            <div class="report-card-inner">
                <AchievedGoal
                    v-if="reportMonth"
                    :month="reportMonth"
                    :user-name="reportCardUser"
                />
                <TopPerformer
                    v-if="showTopPerformer"
                    class="top-performer"
                    :month="reportMonth"
                    :user-name="reportCardUser"
                />
                <hr v-if="isFrontlineManager" />
                <TopicFeedbackCarousel
                    :month="reportMonth"
                    :is-report-card="true"
                    :show-empty-topics="false"
                    :show-favourites-first="true"
                />
                <div style="min-height: 100px" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import NPSDisplay from '@/mobile/src/components/NPSDisplay.vue'
import GoalHistory from '@/mobile/src/components/reportcard/GoalHistory.vue'
import AchievedGoal from '@/mobile/src/views/reportcard/AchievedGoal.vue'
import {
    IMonthPicker,
    IYearInGoalItem,
    ReportCardComment,
} from '@/entities/reportcard'
import MonthPicker from '@/mobile/src/views/reportcard/MonthPicker.vue'
import TopPerformer from '@/mobile/src/views/reportcard/TopPerformer.vue'
import ReportCardMessages from '@/mobile/src/views/reportcard/ReportCardMessages.vue'
import ManagerComment from '@/mobile/src/views/reportcard/ManagerComment.vue'
import { Getter, namespace } from 'vuex-facing-decorator'
import TopicFeedbackCarousel from '@/mobile/src/components/appV4/TopicFeedbackCarousel.vue'
import { getLastMonthUsingTimezone } from '@/utils/time'

const ReportCardModule = namespace('reportcard')
const AchievedGoalModule = namespace('achievedgoal')

@Component({
    components: {
        GoalHistory,
        TopicFeedbackCarousel,
        MonthPicker,
        NPSDisplay,
        AchievedGoal,
        TopPerformer,
        ReportCardMessages,
        ManagerComment,
    },
    emits: ['monthChanged'],
})
export default class ReportCard extends Vue {
    @Prop({ type: Number, default: null }) public month!: number
    @Prop({ type: String, default: '' }) public reportCardUser!: string
    @Prop({ type: Function, required: false }) private closePopup!: Function

    @Getter isFrontlineManager
    @Getter userName
    @Getter $companyVars

    @ReportCardModule.Getter mainScore
    @ReportCardModule.Getter reportCardData
    @ReportCardModule.Getter reportCardMessagesByUserMonth
    @ReportCardModule.Getter reportMonth
    @ReportCardModule.Getter drillDownUser
    @ReportCardModule.Getter reportCardComment!: ReportCardComment

    @ReportCardModule.Action setReportMonth
    @ReportCardModule.Action setReportUserName
    @ReportCardModule.Action loadReportCardData
    @ReportCardModule.Action loadReportCardMessages

    @AchievedGoalModule.Getter achievedGoalData

    private monthPicker: IMonthPicker = {
        months: [],
        selected: this.month,
    }

    private currentReportData: IYearInGoalItem | null = null

    public isLoadingReportCardMessages = false

    public beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.initialiseMonth(to.params.month ?? vm.defaultMonth())
        })
    }

    private initialiseMonth(month) {
        this.monthPicker.selected = Number(month)
        this.setReportMonth(Number(month))
    }

    public beforeMount(): void {
        this.initialiseMonth(this.month)
    }

    public defaultMonth(): number {
        return getLastMonthUsingTimezone(this.$companyVars.timezone)
    }

    public async mounted() {
        this.setReportUserName(this.reportCardUser)

        // Initialise monthPicker with previous month so it shows the initial month instead of blank
        const previousMonth = this.defaultMonth()
        this.monthPicker.months = [previousMonth]

        if (this.reportCardData.length === 0) {
            await this.loadData()
        }

        await this.loadMessagesData(this.reportMonth, this.reportCardUser)

        // Extract months that have data for the month picker
        this.monthPicker.months = this.reportCardData
            .filter((item) => item.hasData)
            .map((item) => item.month)

        this.currentReportData = this.reportCardData[this.month]
    }

    /**
     * Don't show the TopPerformers component for the drill-down user report card.
     */
    public get showTopPerformer(): boolean {
        return this.isFrontlineManager && !this.drillDownUser?.name
    }

    public async loadData() {
        await this.loadReportCardData()
    }

    public async loadMessagesData(month, userName) {
        this.isLoadingReportCardMessages = true
        await this.loadReportCardMessages({ month, userName })
        this.isLoadingReportCardMessages = false
    }

    public async monthChanged(month: string) {
        this.monthPicker.selected = Number(month)
        this.setReportMonth(Number(month))
        await this.loadMessagesData(Number(month), this.reportCardUser)
        await this.loadData()
        this.$emit('monthChanged', month)
    }

    get helpText(): string {
        return `This is your 30-day average customer rating, taken at the end of the month.
            This is used to determine if you have achieved the monthly goal.`
    }

    public get hasReportCardComment(): boolean {
        return !!this.reportCardComment.content
    }

    public get reportCardShoutoutMessages() {
        return this.reportCardMessagesByUserMonth?.['shoutout'] || []
    }

    public get reportCardCoachingMessages() {
        return this.reportCardMessagesByUserMonth?.['coaching'] || []
    }
}
</script>

<style scoped lang="less">
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';

.scrollable-report-card {
    width: 100%;
    height: 80vh;
    overflow-y: auto;

    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    -ms-overflow-style: none;

    /* Hide the scrollbar thumb */
    &::-webkit-scrollbar {
        display: none;
    }

    background-color: #240a3b;

    .report-card-inner {
        display: flex;
        flex-direction: column;
        padding: 0 20px;
    }
}

.report-container {
    height: 100%;
    width: 100%;
    background-color: #240a3b;
    border-radius: 20px;

    hr {
        color: transparent;
        border-style: solid;
        border-bottom: 1px solid @thinFog;
    }

    .nps-display {
        margin: 20px;
    }

    .nps-display-placeholder {
        height: 67px;
        margin: 20px;
    }
}

.top-performer {
    padding-top: @featureGutter;
}
</style>
