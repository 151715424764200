import { StepTip } from './step-tip'

export interface App {
    id: number | string
    title: string
    name: string
    description: string
    body?: string | null // html string
    app_link?: string
    help_link?: string
    only_show_for_logged_in: number | string
    search_terms?: string | null
    video_url: string | null
    image: string
    image_hero_url: string | null
    display: boolean
    is_new?: boolean
    status?: string
    type: string
    updates?: Update[]
    linked_learning_ids?: string[] | number[] // needed when update the curApp infomation
    linked_appstore_ids?: string[] | number[] // needed when update the curApp infomation
    linked_steps?: StepTip[]
}

export interface Update {
    image_hero_url: string | null
    date: string
    title: string
    body?: string | null // html string
    status: string | null
}

export interface LooseObject {
    [key: string]: any
}

export const InitApp: App = {
    id: 0,
    name: '',
    title: '',
    description: '',
    body: null,
    app_link: '',
    help_link: '',
    search_terms: null,
    only_show_for_logged_in: '',
    image: '',
    video_url: null,
    image_hero_url: '',
    display: true,
    status: '',
    type: '',
    updates: [],
}
