<template>
    <TwoColumnLayoutContainer>
        <template #left-column>
            <BaseBlock>
                <template #title>Profile</template>

                <ScorecardProfile />
            </BaseBlock>

            <BaseBlock>
                <template #title>{{ graphTitle }}</template>

                <ScorecardNPS />
                <ScorecardGraph />
            </BaseBlock>

            <BaseBlock v-if="showLeaderboard">
                <template #title>Leaderboard</template>

                <ScorecardLeaderboard />
            </BaseBlock>
        </template>

        <template #right-column>
            <BaseBlock>
                <template #title>Positive feedback</template>

                <RoutableBadge :team-member-user-id="teamMemberUserId" />
            </BaseBlock>

            <BaseBlock>
                <template #title>Things to work on</template>

                <ThingsToWorkOn />
            </BaseBlock>

            <BaseBlock v-if="showMyTeam" no-padding>
                <template #title>My Team</template>

                <HorizontalScrollContainer class="my-team">
                    <RouterLink
                        v-for="teamMember in teamMembers"
                        :key="teamMember.user_id"
                        :to="{
                            name: 'scorecardHomeUser',
                            params: { teamMemberUserId: teamMember.user_id },
                        }"
                        class="team-member-link"
                    >
                        <TeamMember :team-member="teamMember" />
                    </RouterLink>
                </HorizontalScrollContainer>
            </BaseBlock>

            <BaseBlock>
                <template #title>Achievements</template>

                <RoutableBadge
                    :team-member-user-id="teamMemberUserId"
                    show-achievements
                />
            </BaseBlock>
        </template>
    </TwoColumnLayoutContainer>
</template>

<script lang="ts">
import BaseBlock from '@/components/BaseBlock.vue'
import HorizontalScrollContainer from '@/components/HorizontalScrollContainer.vue'
import RoutableBadge from '@/mobile/src/components/scorecard/RoutableBadge.vue'
import ScorecardGraph from '@/components/Scorecard/ScorecardGraph.vue'
import ScorecardLeaderboard from '@/components/Scorecard/ScorecardLeaderboard.vue'
import ScorecardNPS from '@/components/Scorecard/ScorecardNPS.vue'
import ScorecardProfile from '@/components/Scorecard/ScorecardProfile.vue'
import ThingsToWorkOn from '@/mobile/src/components/scorecard/ThingsToWorkOn.vue'
import Scrollable from '@/components/Scrollable.vue'
import { IRecipient } from '@/entities/notices'
import { ScorecardEntity } from '@/entities/scorecard'
import TwoColumnLayoutContainer from '@/mobile/src/components/containers/TwoColumnLayoutContainer.vue'
import TeamMember from '@/mobile/src/components/scorecard/TeamMember.vue'
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { Action, Getter } from 'vuex-facing-decorator'

@Component({
    components: {
        BaseBlock,
        HorizontalScrollContainer,
        RoutableBadge,
        ScorecardGraph,
        ScorecardLeaderboard,
        ScorecardNPS,
        ScorecardProfile,
        Scrollable,
        TeamMember,
        ThingsToWorkOn,
        TwoColumnLayoutContainer,
    },
})
export default class ScorecardPageExpanded extends Vue {
    @Prop({ type: String, required: false })
    readonly teamMemberUserId?: string

    @Getter
    readonly $companyVars

    @Getter
    readonly scorecardLoading!: boolean

    @Getter
    readonly scorecard?: ScorecardEntity

    @Getter
    readonly myTeam!: IRecipient[]

    @Getter
    readonly userId!: string

    get graphTitle() {
        if (!this.scorecard) {
            return ''
        }

        return `${this.scorecard.questionType} last 30 days`
    }

    get teamMembers() {
        // Don't show the user in the list of team members
        return this.myTeam.filter(
            (user) => user.user_id !== Number(this.userId)
        )
    }

    get showMyTeam() {
        return !this.teamMemberUserId && this.teamMembers.length > 0
    }

    get showLeaderboard() {
        return Boolean(this.scorecard?.rankEnabled)
    }
}
</script>

<style scoped lang="less">
@import '~@/styles/variables';

.my-team {
    display: flex;
    gap: @gutterSpacing * 2;
    padding: @gutterSpacing;

    scroll-snap-type: x mandatory;
    scroll-padding: 0 @gutterSpacing;

    > .team-member-link {
        scroll-snap-align: start;
    }
}

.team-member-link {
    text-decoration: none;
}
</style>
