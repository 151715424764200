<template>
    <div @click="onClickDelete">
        <FontAwesomeIcon :icon="faTrashAlt" size="lg" />
    </div>
</template>

<script setup lang="ts">
import { faTrashAlt } from 'fontawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'

interface Props {
    deleteConfirmationMessage: string
}
const props = defineProps<Props>()
const emit = defineEmits(['delete'])
const onClickDelete = (): void => {
    if (confirm(props.deleteConfirmationMessage)) {
        emit('delete')
    }
}
</script>
