<template>
    <Modal
        v-if="show"
        :show="show"
        width="280px"
        vanilla
        has-close
        :top="10"
        :close="onClose"
        class="request-container"
    >
        <div class="modal-content">
            <div class="title">Push Notifications</div>
            <div class="description">
                AskNicely wants to send you Push Notifications to keep you
                updated about new Notices and Customer Feedback.
            </div>
            <div class="confirm-button">
                <BaseButton class="white" full :click="onClick">Turn on Notifications</BaseButton>
            </div>
        </div>
    </Modal>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import BaseButton from '@/components/BaseButton.vue'
import { Getter, Action } from 'vuex-facing-decorator'
import Modal from '@/components/Modal.vue'
import { PushNotifications } from '@capacitor/push-notifications'
import { Capacitor } from '@capacitor/core'

@Component({
    components: { Modal, BaseButton },
})
export default class RequestNotificationPermissionModal extends Vue {
    @Getter readonly showStaffPushNotificationModal

    @Action requestPushNotificationPermission!: () => Promise<void>
    @Action setUserProp

    get show() {
        return this.pushPermission && !!this.showStaffPushNotificationModal
    }

    pushPermission = false

    async mounted() {
        if (Capacitor.isPluginAvailable('PushNotifications')) {
            const result = await PushNotifications.checkPermissions()
            this.pushPermission = result.receive === 'prompt'
        }
    }

    async onClose() {
        // hide modal, only show panel
        await this.setUserProp({ hidePushNotificationModal: true })
    }

    async onClick() {
        if (process.env.CONFIG_KEY === 'mobile') {
            await this.requestPushNotificationPermission()
        }
        await this.onClose()
    }
}
</script>
<style lang="less" scoped>
@import '~@/styles/palette.less';

.request-container {
    .modal-content {
        background: @blue70;
        padding: 25px;
        color: white;
        text-align: center;

        .title {
            font-weight: bold;
            font-size: 18px;
        }

        .description {
            margin-top: 10px;
            font-size: 14px;
        }

        .button {
            margin-top: 10px;
            background: @blue90;
            border-radius: 8px;
        }

        .white {
            color: white;
        }
    }
}
</style>
