<template>
    <div>
        <FullScreenPopup v-if="shouldShowEngagementModal">
            <div class="close" :class="{ desktop: !isMobile }" @click="close">
                &times;
            </div>
            <div
                class="engagement-popup"
                :class="{ 'desktop-engagement-popup': !isMobile }"
            >
                <EngagementPositiveFeedback
                    v-if="
                        dailyEngagementQuestionToShow.question_type ===
                        engagementQuestionType.PositiveFeedback
                    "
                    :response="dailyEngagementQuestionToShow.response"
                    :see-more-responses="isLastQuestion"
                    @done="submitAnswer(dailyEngagementQuestionToShow)"
                />
                <EngagementFiveStarQuestion
                    v-else-if="
                        dailyEngagementQuestionToShow.question_type ===
                        engagementQuestionType.FiveStar
                    "
                    :key="dailyEngagementQuestionToShow.question_id"
                    :question="dailyEngagementQuestionToShow"
                    @update="
                        updateQuestion(dailyEngagementQuestionToShow, $event)
                    "
                    @done="submitAnswer"
                />
                <EngagementNPSQuestion
                    v-else-if="
                        dailyEngagementQuestionToShow.question_type ===
                        engagementQuestionType.NPS
                    "
                    :question="dailyEngagementQuestionToShow"
                    @update="
                        updateQuestion(dailyEngagementQuestionToShow, $event)
                    "
                    @done="submitAnswer"
                />
                <EngagementShoutoutQuestion
                    v-else-if="
                        dailyEngagementQuestionToShow.question_type ===
                        engagementQuestionType.Shoutout
                    "
                    :question="dailyEngagementQuestionToShow"
                    @update="
                        updateQuestion(dailyEngagementQuestionToShow, $event)
                    "
                    @done="submitAnswer"
                />
                <EngagementScorecardQuestion
                    v-else-if="
                        dailyEngagementQuestionToShow.question_type ===
                        engagementQuestionType.Scorecard
                    "
                    :question="dailyEngagementQuestionToShow"
                    @update="
                        updateQuestion(dailyEngagementQuestionToShow, $event)
                    "
                    @done="submitAnswer"
                />
            </div>
        </FullScreenPopup>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import FullScreenPopup from '../FullScreenPopup.vue'
import Modal from '@/components/Modal.vue'
import { Getter, Action } from 'vuex-facing-decorator'
import {
    EngagementQuestion,
    EngagementQuestionType,
} from '@/entities/engagement'
import BaseButton from '@/components/BaseButton.vue'
import EngagementFiveStarQuestion from '@/mobile/src/components/engagement/question-types/EngagementFiveStarQuestion.vue'
import EngagementNPSQuestion from '@/mobile/src/components/engagement/question-types/EngagementNPSQuestion.vue'
import EngagementShoutoutQuestion from '@/mobile/src/components/engagement/question-types/EngagementShoutoutQuestion.vue'
import EngagementScorecardQuestion from '@/mobile/src/components/engagement/question-types/EngagementScorecardQuestion.vue'
import EngagementPositiveFeedback from '@/mobile/src/components/engagement/EngagementPositiveFeedback.vue'

@Component({
    components: {
        EngagementPositiveFeedback,
        EngagementShoutoutQuestion,
        EngagementScorecardQuestion,
        EngagementNPSQuestion,
        BaseButton,
        EngagementFiveStarQuestion,
        Modal,
        FullScreenPopup,
    },
})
export default class EngagementPopup extends Vue {
    @Getter
    public dailyEngagementQuestions!: EngagementQuestion[]

    @Getter
    public dailyEngagementQuestionToShow!: EngagementQuestion

    @Getter
    public shouldShowEngagementModal!: boolean

    @Action
    public setEngagementModalOpened

    @Action
    public answerEngagementQuestion

    @Action
    public updateEngagementQuestionField

    @Action
    public loadDailyEngagementQuestions

    @Action
    public markEngagementQuestionsAsOpen

    engagementQuestionType = EngagementQuestionType

    async close() {
        this.setEngagementModalOpened(false)

        // when closing the popup on the last question for positive feedback let's mark this as answered
        if (
            this.dailyEngagementQuestionToShow &&
            this.dailyEngagementQuestionToShow.question_type ===
                'positive_feedback' &&
            this.isLastQuestion
        ) {
            await this.submitAnswer(this.dailyEngagementQuestionToShow)
            return
        }

        await this.markEngagementQuestionsAsOpen()
        // if the current question is positive feedback then mark as answered
        if (
            this.dailyEngagementQuestionToShow &&
            this.dailyEngagementQuestionToShow.question_type ===
                'positive_feedback'
        ) {
            await this.submitAnswer(this.dailyEngagementQuestionToShow)
        }
        await this.loadDailyEngagementQuestions()
    }

    public get isLastQuestion() {
        const lastQuestion =
            this.dailyEngagementQuestions[
                this.dailyEngagementQuestions.length - 1
            ]
        return (
            this.dailyEngagementQuestionToShow &&
            this.dailyEngagementQuestionToShow.answer_id ===
                lastQuestion.answer_id
        )
    }

    public get isMobile() {
        return process.env.CONFIG_KEY === 'mobile'
    }

    public async submitAnswer(question) {
        await this.answerEngagementQuestion(question)
    }

    public updateQuestion(question: EngagementQuestion, data) {
        this.updateEngagementQuestionField({
            answer_id: question.answer_id,
            key: data['key'],
            value: data['value'],
        })
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/variables';

.close {
    font-size: 28px;
    text-align: right;
    margin-right: 12px;
    color: @greyMid;
    position: fixed;
    right: 12px;
    z-index: 1;
    cursor: pointer;
    &.desktop {
        margin-top: 12px;
    }
}

.engagement-popup {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.desktop-engagement-popup {
    max-width: 400px;
    max-height: 600px;
    margin: 0 auto;
    position: relative;
    top: calc(50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
</style>
