<template>
    <div class="coaching-topic-review">
        <div
            class="coaching-topic-review-heading"
            @click="showTeamSortModal = true"
        >
            <div class="coaching-topic-review-title">{{ title }}</div>
            <div
                v-if="showMoreRouterLink"
                class="coaching-topic-review-more"
                @click="router.push(showMoreRouterLink)"
            >
                Show More
            </div>
        </div>
        <div class="coaching-topic-review-responses">
            <div v-if="responses === null" class="loading">
                <SimpleSpinner
                    size="small"
                    class="spinner"
                    line-fg-color="#B338A3"
                ></SimpleSpinner>
            </div>
            <RouterLink
                v-for="response in responses"
                v-else
                :key="response.id"
                :to="{ name: 'chatResponsesRoot', params: { id: response.id } }"
            >
                <div class="feedback-item">
                    <ScorecardResponseTile
                        :response="response"
                        :scorecard="scorecard"
                        class="coaching-topic-review-response"
                    />
                </div>
            </RouterLink>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { useRouter } from 'vue-router'
import ScorecardResponseTile from '@/components/ScorecardResponseTile.vue'
import { Getter } from 'vuex-facing-decorator'
import { ScorecardEntity } from '@/entities/scorecard'
import SimpleSpinner from '@/components/SimpleSpinner.vue'

@Component({
    components: {
        ScorecardResponseTile,
        SimpleSpinner,
    },
})
export default class InsightsCoachingTopicReviews extends Vue {
    @Prop({ type: String, default: null })
    private title!: string

    @Prop({ type: Array, default: null })
    private responses!: [] | null

    @Prop({ type: Object, default: null })
    private showMoreRouterLink!: {} | null

    @Getter
    public scorecard!: ScorecardEntity

    private router = useRouter()
}
</script>

<style lang="less" scoped>
@import '~@/styles/variables';
@import '~@/styles/typography';
@import '~@/styles/fade';

.coaching-topic-review {
    .coaching-topic-review-heading {
        display: flex;
        justify-content: space-between;
        margin-bottom: 6px;

        .coaching-topic-review-title {
            .title();
        }

        .coaching-topic-review-more {
            color: @blue;
            font-size: 12px;
            line-height: 25px;
        }
    }

    .coaching-topic-review-response {
        margin-bottom: 20px;
    }
}
</style>
