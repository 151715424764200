import { IRecipient, IMessage } from '@/entities/notices'
import { tomorrowMorning } from '@/utils/time'

export enum TriggerType {
    StartDate = 'start_date',
    Coaching = 'coaching',
    NewUser = 'new_user',
}

export enum ScheduleType {
    Daily = 'daily',
    Weekly = 'weekly',
}

export interface IPlaybook {
    id: number
    name: string
    description: string
    start_date: string
    disabled: number
    trigger: TriggerType
    schedule: ScheduleType
    recipients: IRecipient[]
    from: IRecipient | null
    notices: IMessage[]
    configuration?: any
    virtual_from?: number | null
}

export function emptyPlaybook(fromUserId = 0): IPlaybook {
    const defaultFrom: IRecipient = {
        type: 'user',
        name: '',
        description: '',
        user_id: fromUserId,
        field: '',
        value: '',
        role: '',
        avatar: '',
        usernames: [],
    }

    return {
        id: 0,
        name: '',
        description: '',
        disabled: 0,
        trigger: TriggerType.StartDate,
        start_date: tomorrowMorning().format('YYYY-MM-DD'), // default to tomorrow
        schedule: ScheduleType.Weekly,
        recipients: [],
        from: defaultFrom,
        notices: [],
        configuration: {},
    }
}

export interface IPlaybookTriggerGroup {
    name: string
    displayName: string
    description: string
    triggers: TriggerType[]
    backgroundColor: string
    color: string
}
