import gql from 'graphql-tag'
import {
    appstoreInput,
    appstoreInputData,
    learningInput,
    learningInputData,
} from './inputFields'
import {
    appstoreItem,
    appstoreItemResponse,
    learningItem,
    learningItemResponse,
} from './queryFields'

// Query
export function getAppstores() {
    return gql(`query {
        appstores {
            ${appstoreItem}
        }
    }`)
}

export function getAppstore(appId: string) {
    return gql(`query {
        appstore(id: ${appId}) {
            ${appstoreItemResponse}
        }
    }`)
}

export function getLearnings() {
    return gql(`query {
        learnings {
            ${learningItem}
        }
    }`)
}

export function getLearning(learningId: string) {
    return gql(`query {
        learning(id: ${learningId}) {
            ${learningItemResponse}
        }
    }`)
}
// Query end

// Mutation
export function updateAppstore() {
    return gql(`mutation (${appstoreInput}){
        updateAppstore(data: {
            ${appstoreInputData}
        }) {
            ${appstoreItemResponse}
        }
    }
    `)
}

export function createAppstore() {
    return gql(`mutation (${appstoreInput}){
        createAppstore(data: {
            ${appstoreInputData}
        }) {
            ${appstoreItemResponse}
        }
    }
    `)
}

export function reorderAppstoreData() {
    return gql(`mutation (
        $appstore_id: ID,
        $linked_learning_ids: [String],
        $linked_appstore_ids: [String]
    ){
        reorderAppstoreData(data: {
            appstore_id: $appstore_id,
            linked_learning_ids: $linked_learning_ids,
            linked_appstore_ids: $linked_appstore_ids
        }) {
            ${appstoreItemResponse}
        }
    }
    `)
}

export function createUpdateLearning() {
    return gql(`mutation (${learningInput}){
        createUpdateLearning(data: {
            ${learningInputData}
        }) {
            ${learningItemResponse}
        }
    }
    `)
}
// Mutation end
