import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7069e366"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "feedback-list" }
const _hoisted_2 = { class: "feedback-item" }
const _hoisted_3 = {
  key: 0,
  class: "loading"
}
const _hoisted_4 = {
  key: 1,
  class: "no-more-results"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EngagementBanner = _resolveComponent("EngagementBanner")!
  const _component_SummaryHeader = _resolveComponent("SummaryHeader")!
  const _component_FilterBarMobile = _resolveComponent("FilterBarMobile")!
  const _component_RequestNotificationPermissionPanel = _resolveComponent("RequestNotificationPermissionPanel")!
  const _component_MobileSelectMetric = _resolveComponent("MobileSelectMetric")!
  const _component_FeedbackScorecardResponseTile = _resolveComponent("FeedbackScorecardResponseTile")!
  const _component_ScorecardResponseTile = _resolveComponent("ScorecardResponseTile")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_SimpleSpinner = _resolveComponent("SimpleSpinner")!
  const _component_Scrollable = _resolveComponent("Scrollable")!
  const _component_WebViewScreen = _resolveComponent("WebViewScreen")!

  return (_openBlock(), _createBlock(_component_WebViewScreen, {
    title: "Feedback",
    "no-pad": ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Scrollable, {
        "pull-down": _ctx.refreshResponses,
        "on-scroll-end": _ctx.loadMoreResponses,
        class: "feedback-scroll",
        "scroll-container-top": _ctx.getScrollPosition('feedback')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_EngagementBanner),
          _createVNode(_component_SummaryHeader),
          _createVNode(_component_FilterBarMobile, {
            "is-floating": false,
            "no-pad": ""
          }),
          _createVNode(_component_RequestNotificationPermissionPanel, { class: "add-top-padding" }),
          _createVNode(_component_MobileSelectMetric, { class: "metric-selector" }),
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.responses, (response) => {
              return (_openBlock(), _createBlock(_component_RouterLink, {
                key: response.id,
                to: 
                        _ctx.hasNetworkConnection
                            ? {
                                  name: 'chatResponsesRoot',
                                  params: {
                                      id: response.id,
                                  },
                              }
                            : {}
                    
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    (_ctx.userHasMlp)
                      ? (_openBlock(), _createBlock(_component_FeedbackScorecardResponseTile, {
                          key: 0,
                          response: response,
                          scorecard: _ctx.scorecard,
                          class: "scorecard-response",
                          onClick: _ctx.handleLinkClick
                        }, null, 8, ["response", "scorecard", "onClick"]))
                      : (_openBlock(), _createBlock(_component_ScorecardResponseTile, {
                          key: 1,
                          response: response,
                          scorecard: _ctx.scorecard,
                          class: "scorecard-response"
                        }, null, 8, ["response", "scorecard"]))
                  ])
                ], undefined, true),
                _: 2
              }, 1032, ["to"]))
            }), 128)),
            (_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_SimpleSpinner, {
                    size: "small",
                    class: "spinner",
                    "line-fg-color": "#B43DC6"
                  })
                ]))
              : _createCommentVNode("", true),
            (!_ctx.loading && _ctx.resultsDepleted)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, " no more results "))
              : _createCommentVNode("", true)
          ])
        ], undefined, true),
        _: 1
      }, 8, ["pull-down", "on-scroll-end", "scroll-container-top"])
    ], undefined, true),
    _: 1
  }))
}