<template>
    <div v-if="show">
        <Modal
            small-header
            allow-click-out
            :top="20"
            :padding="14"
            :size="modalSize.Small"
            :show="showPredefinedDatePicker"
            :close="onClose"
        >
            <div
                v-for="item in pickerOptions"
                :key="item.name"
                class="schedule-datetime-picker-item"
                @click="onClickOption(item)"
            >
                <img :src="item.icon" />
                <p
                    class="schedule-datetime-picker-item-name"
                    v-html="item.name"
                />
                <p
                    class="schedule-datetime-picker-item-time"
                    v-html="item.displayTime"
                />
            </div>
            <div class="send-now-link">
                <a href="#" @click="onClickOption({ name: 'Now', time: '' })">Now</a>
            </div>
            <p class="shedule-datetime-picker-timezone-label">
                All times are in {{ timezone }} Time
            </p>
        </Modal>
        <Modal
            :top="20"
            :padding="24"
            :size="modalSize.Small"
            :show="showCustomDateTimePicker"
        >
            <p class="date-time-title">Select date and time</p>
            <div class="modal-group">
                <DatePicker
                    v-model.string="customDateTime"
                    mode="datetime"
                    color="blue"
                    :first-day-of-week="2"
                    :masks="datePickerMasks"
                    is-required
                />
                <p v-if="customDateTimePickerError" class="date-time-error">
                    &nbsp;&nbsp;&nbsp;&nbsp;{{ customDateTimePickerError }}
                </p>
                <p v-else class="date-time-timezone">
                    &nbsp;&nbsp;&nbsp;&nbsp;{{ timezone }} Time
                </p>
            </div>
            <div class="modal-footer">
                <Button class="cancel" no-border @click="onCancelSchedule">Cancel</Button>
                <Button class="continue" @click="onOkSchedule">Schedule Send</Button>
            </div>
        </Modal>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { ModalSize } from '@/components/modal-size'
import Modal from '@/components/Modal.vue'
import moment from 'moment'
import Button from '@/components/WebView/Button.vue'
import {
    tomorrowMorning,
    tomorrowAfternoon,
    nextMondayMorning,
} from '@/utils/time'
import { DatePicker } from 'v-calendar'
import 'v-calendar/style.css'

declare const Intl
@Component({
    components: { Modal, Button, DatePicker },
    emits: ['close', 'schedule'],
})
export default class NoticeSchedulerModal extends Vue {
    @Prop({ type: Boolean }) public show!: boolean
    @Prop({ type: String }) public timezone!: string

    private showPredefinedDatePicker = true
    private showCustomDateTimePicker = false

    private datePickerDateFormat = 'YYYY-MM-DDTHH:mm:ss'
    private datePickerMasks = {
        modelValue: this.datePickerDateFormat,
    }

    private customDateTime = moment()
        .add(1, 'hours')
        .add(5, 'minutes')
        .format(this.datePickerDateFormat)

    private dateFormat = 'DD MMM, h:mma'
    private pickerOptions = [
        {
            icon: require('@/components/WebView/icons/Sun.svg'),
            name: 'Tomorrow <br>Morning',
            time: tomorrowMorning().format('YYYY-MM-DD HH:mm:ss'),
            displayTime: tomorrowMorning().format(this.dateFormat),
        },
        {
            icon: require('@/components/WebView/icons/HalfSun.svg'),
            name: 'Tomorrow <br>Afternoon',
            time: tomorrowAfternoon().format('YYYY-MM-DD HH:mm:ss'),
            displayTime: tomorrowAfternoon().format(this.dateFormat),
        },
        {
            icon: require('@/components/WebView/icons/Briefcase.svg'),
            name: 'Monday <br>Morning',
            time: nextMondayMorning().format('YYYY-MM-DD HH:mm:ss'),
            displayTime: nextMondayMorning().format(this.dateFormat),
        },
        {
            icon: require('@/components/WebView/icons/Calendar.svg'),
            name: 'Pick <br>Date & Time',
            time: -1,
            displayTime: '<br>',
        },
    ]

    private onClickOption(option) {
        this.showPredefinedDatePicker = false
        if (option.time === -1) {
            this.toggleCustomDateTimePicker()
        } else {
            this.doSchedule(option.time, option.name.replace('<br>', ''))
        }
    }

    private onClose() {
        // to keep the animation, hide the whole component after 200ms
        this.showPredefinedDatePicker = false
        this.showCustomDateTimePicker = false

        setTimeout(() => {
            this.$emit('close')
            // make sure it shows picker next time open
            this.showPredefinedDatePicker = true
        }, 200)
    }

    private toggleCustomDateTimePicker() {
        this.showCustomDateTimePicker = !this.showCustomDateTimePicker
    }

    private onOkSchedule() {
        // return directly if validate fail
        if (this.customDateTimePickerError) {
            return
        }
        this.showCustomDateTimePicker = !this.showCustomDateTimePicker
        const label = moment(this.customDateTime).format(this.dateFormat)
        const scheduledAt = moment(this.customDateTime).format(
            'YYYY-MM-DD HH:mm:ss'
        )
        this.doSchedule(scheduledAt, label)
    }

    private doSchedule(scheduledAt: string, label: string) {
        this.$emit('schedule', scheduledAt, label)
        this.onClose()
    }

    private onCancelSchedule() {
        this.showPredefinedDatePicker = true
        this.showCustomDateTimePicker = false
    }

    private get modalSize() {
        return ModalSize
    }

    private get customDateTimePickerError() {
        return moment(this.customDateTime).unix() - moment().unix() < 60 * 60
            ? 'Pick a time at least one hour from now'
            : ''
    }
}
</script>

<style lang="less" scoped>
@import '../../styles/layout.less';
@import '../../styles/palette.less';

.schedule-datetime-picker-item {
    width: 45%;
    display: inline-block;
    text-align: center;
    text-transform: capitalize;
    line-height: 18px;
    padding: 20px 4px;
    cursor: pointer;

    .schedule-datetime-picker-item-name {
        font-size: 13px;
        color: @black80;
        margin: 10px 0 0;
    }

    .schedule-datetime-picker-item-time {
        font-size: 9px;
        color: @grey40;
        margin: 0;
    }
}

.send-now-link {
    a {
        color: @blue;
    }
    text-align: center;
}

.shedule-datetime-picker-timezone-label {
    text-align: center;
    color: @grey40;
    font-size: 9px;
    margin: 10px 0 0;
}

.date-time-title {
    text-align: center;
    color: @grey90;
    margin: 0 0 10px;
}

.date-time-timezone {
    text-align: left;
    color: @grey90;
    margin: 4px 0 0;
    font-size: 10px;
}

.date-time-error {
    text-align: left;
    color: @formMessageIconError;
    margin: 4px 0 0;
    font-size: 10px;
}

// hide the picker trigger
.cutomer-datetime-picker {
    position: absolute;
    height: 0;
    overflow: hidden;
}

.modal-group {
    display: flex;
    flex-direction: column;
    align-items: center;

    input {
        color: @black;
        border: 1px solid @blue10;
        line-height: 36px;
        min-height: 36px;
        padding-left: 12px;
        font-size: 14px;
        margin-top: 12px;
        flex: 1;
        border-radius: 4px;
    }
}

.modal-footer {
    display: flex;
    padding-top: 12px;
    float: right;
    padding-bottom: 10px;
    text-transform: uppercase;
    margin: 0 -20px;

    .button {
        background: white;
        border: white;
    }

    .continue {
        color: @blue;
    }

    .cancel {
        color: @grey40;
    }
}
</style>
