<template>
    <div class="base-block">
        <div class="title">
            <slot name="title"></slot>
        </div>
        <BaseCard class="card" :class="{ noPadding }">
            <slot name="default"></slot>
        </BaseCard>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import BaseCard from '@/components/BaseCard.vue'

@Component({
    components: {
        BaseCard,
    },
})
export default class BaseBlock extends Vue {
    @Prop({ type: Boolean, default: false })
    public noPadding!: boolean
}
</script>

<style lang="less" scoped>
@import '~@/styles/palette';
@import '~@/styles/typography';
@import '~@/styles/variables';

.base-block {
    // TODO: base this value on a variable
    margin: 12px 0;
}

.title {
    .title();

    // TODO: base this value on a variable
    margin-bottom: 8px;
}

.card {
    box-sizing: border-box;
    // TODO: base this value on a variable
    padding: 16px;

    &.noPadding {
        padding: 0;
    }
    width: 100%;

    // Hack to prevent items overflowing the box horizontally
    // Used to "hide" the grid-list blue hover bar
    overflow-x: hidden;

    @media screen and (min-width: 480px) {
        // TODO: base these values on a variable
        padding: 20px 24px;
    }
}
</style>
