<template>
    <div class="container">
        <div :class="['header', { mobile: $isMobile }]">
            <div class="search" :class="{ compact }">
                <SearchBar
                    :value="searchText"
                    label="Search recipients or groups"
                    @input="onInput"
                />
            </div>
            <div class="clear-all-button">
                <BaseButton variant="ghost" @click="clearSelectedRecipients">Clear all</BaseButton>
            </div>
        </div>

        <div :class="['recipient-pills', { mobile: $isMobile }]">
            <SelectedRecipientPills
                v-if="selectedRecipients.length > 0"
                :recipients="recipients"
                :selected-recipients="selectedRecipients"
                can-remove-single-tabs
                @removeRecipient="selectRecipient"
            />
        </div>
        <div class="recipient-picker">
            <div
                v-if="
                    virtualUsers && virtualUsers.length && canPickVirtualUsers
                "
                class="recipients"
            >
                <Recipient
                    v-for="recipient in filteredVirtualUsers"
                    v-show="recipient.selectableForSending"
                    :key="`${recipient.type}-${recipient.user_id}-${recipient.field}-${recipient.value}-${recipient.role}`"
                    :compact="compact"
                    :recipient="recipient"
                    :selected="isRecipientSelected(recipient)"
                    @pick="selectRecipient"
                />
            </div>
            <div v-if="recipients && recipients.length" class="recipients">
                <Recipient
                    v-for="recipient in filteredRecipients"
                    :key="`${recipient.type}-${recipient.user_id}-${recipient.field}-${recipient.value}-${recipient.role}`"
                    :compact="compact"
                    :recipient="recipient"
                    :selected="isRecipientSelected(recipient)"
                    @pick="selectRecipient"
                />
            </div>
            <SimpleSpinner
                v-else
                size="small"
                class="spinner"
                line-fg-color="#B43DC6"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import Recipient from './Recipient.vue'
import { IRecipient } from '@/entities/notices'
import SimpleSpinner from '@/components/SimpleSpinner.vue'
import Fuse from 'fuse.js'
import { Getter } from 'vuex-facing-decorator'
import SearchBar from '@/components/Rain/Inputs/SearchBar.vue'
import Tabs from '@/components/Tabs.vue'
import Tab from '@/components/Tab.vue'
import SelectedRecipientPills from './SelectedRecipientPills.vue'
import BaseButton from '@/components/Rain/Buttons/BaseButton.vue'

@Component({
    components: {
        BaseButton,
        SelectedRecipientPills,
        Tab,
        Tabs,
        Recipient,
        SimpleSpinner,
        SearchBar,
    },
    emits: ['clearSelectedRecipients', 'toggleRecipient'],
})
export default class RecipientPicker extends Vue {
    @Prop({ type: Array }) private recipients!: IRecipient[]
    @Prop({ type: Array }) private selectedRecipients!: IRecipient[]
    @Prop({ type: Boolean, default: false })
    public canPickVirtualUsers!: boolean
    // if compact, no border bottom, padding also smaller
    @Prop({ type: Boolean, default: false }) private compact!: boolean
    // Number of recipients to show initially without search
    @Prop({ type: Number, required: false }) private maxListLength!: number

    private searchText = ''

    public onInput(newText) {
        this.searchText = newText
    }

    @Getter private virtualUsers!: IRecipient[]

    private get filteredRecipients() {
        if (this.searchText.length > 2) {
            return this.fuse.search(this.searchText)
        }
        if (this.maxListLength && this.maxListLength < this.recipients.length) {
            return this.recipients.slice(0, this.maxListLength)
        }
        return this.recipients
    }

    private get filteredVirtualUsers() {
        if (this.searchText.length > 2) {
            return this.fuseVirtual.search(this.searchText)
        }
        if (
            this.maxListLength &&
            this.maxListLength < this.virtualUsers.length
        ) {
            return this.virtualUsers.slice(0, this.maxListLength)
        }
        return this.virtualUsers
    }

    private get fuse() {
        return new Fuse(this.recipients, {
            keys: ['name', 'id', 'type'],
            shouldSort: false,
            threshold: 0.0,
            location: 0,
            distance: 0,
            maxPatternLength: 32,
            minMatchCharLength: 3,
        })
    }

    private get fuseVirtual() {
        return new Fuse(this.virtualUsers, {
            keys: ['name', 'id', 'type'],
            shouldSort: false,
            threshold: 0.0,
            location: 0,
            distance: 0,
            maxPatternLength: 32,
            minMatchCharLength: 3,
        })
    }

    private clearSelectedRecipients() {
        this.searchText = ''
        this.$emit('clearSelectedRecipients')
    }

    private selectRecipient(recipient: IRecipient) {
        let updatedRecipients = [...this.selectedRecipients]
        const everyoneSelected = updatedRecipients.find((selectedRecipient) => {
            return selectedRecipient.name === 'Everyone'
        })

        if (this.isRecipientSelected(recipient)) {
            //remove selected
            updatedRecipients.splice(
                this.getSelectedRecipientIndex(recipient),
                1
            )
        } else {
            // Removes everyone off the list if 'Everyone' is selected
            // or removes 'Everyone' if an individual or group is selected.
            everyoneSelected || recipient.name === 'Everyone'
                ? (updatedRecipients = [recipient])
                : updatedRecipients.push(recipient)
        }
        this.$emit('toggleRecipient', updatedRecipients, recipient)
    }

    private isRecipientSelected(recipient: IRecipient) {
        return this.getSelectedRecipientIndex(recipient) >= 0
    }

    private getSelectedRecipientIndex(recipient: IRecipient) {
        let index = -1
        this.selectedRecipients.forEach((r, i) => {
            if (
                (r.type !== 'user' || r.user_id === recipient.user_id) &&
                r.field === recipient.field &&
                r.type === recipient.type &&
                r.role === recipient.role &&
                r.value === recipient.value
            ) {
                index = i
            }
        })
        return index
    }
}
</script>

<style lang="less" scoped>
@import '../../styles/layout.less';
@import '../../styles/palette.less';

.next {
    font-size: 16px;
    color: @blue;
    font-weight: bold;
}

.container {
    position: relative;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;

    .header {
        display: flex;
        align-items: center;
        padding: 0 0 12px 24px;

        .search {
            width: 336px;
            display: flex;
            position: relative;
            padding-right: 8px;
        }

        &.mobile {
            flex-direction: column;
            align-items: flex-end;
            padding: 0 0 8px 0;

            .search {
                width: calc(100% - 32px);
                padding: 16px 16px 8px 16px;
            }
        }

        .clear-all-button {
            margin-right: 16px;
        }
    }

    .recipient-pills {
        padding: 0 24px;
        margin-bottom: 12px;

        &.mobile {
            padding: 0 16px;
        }
    }
}

.recipient-picker {
    flex: 1;
    background: white;
    height: 100%;
    overflow-y: scroll;

    .spinner {
        margin-top: 20px;
    }
}
</style>
