<template>
    <div
        :class="{
            'best-topic-tag': positive,
            'worst-topic-tag': !positive,
            desktop: !$isMobile,
        }"
        class="topic-tag"
    >
        {{ title }}
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component({})
export default class BestWorstTopicTag extends Vue {
    @Prop({ type: Boolean, default: true })
    readonly positive!: boolean

    @Prop({ type: String, default: null })
    readonly title!: string
}
</script>

<style lang="less" scoped>
@import '~@/styles/palette';

.topic-tag {
    font-size: 12px;
    font-weight: 700;
    padding: 4px;

    &.desktop {
        padding: 0 8px;
    }
}

.best-topic-tag {
    color: @positive;
    border: 1px solid @positive;
    border-radius: 12px;
}

.worst-topic-tag {
    color: @neutral;
}
</style>
