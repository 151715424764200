import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5ccd5306"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_InfoModal = _resolveComponent("InfoModal")!
  const _component_Portal = _resolveComponent("Portal")!

  return (_openBlock(), _createElementBlock("span", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickDropdown && _ctx.onClickDropdown(...args)))
  }, [
    _createElementVNode("span", {
      ref: "modalDisplayTarget",
      class: _normalizeClass({ 'under-dots': _ctx.hasActiveMetrics })
    }, [
      _createTextVNode(_toDisplayString(_ctx.selectedMetric) + " ", 1),
      (_ctx.hasActiveMetrics)
        ? (_openBlock(), _createBlock(_component_FontAwesomeIcon, {
            key: 0,
            class: "chevron",
            icon: _ctx.faChevronDown
          }, null, 8, ["icon"]))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.showingSelect)
      ? (_openBlock(), _createBlock(_component_Portal, {
          key: 0,
          to: "shadow-overlay-destination",
          target: _ctx.$refs.modalDisplayTarget
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InfoModal, {
              class: "multi-metric-select",
              show: "",
              "vertical-only": "",
              "no-pad": "",
              "x-adjust": -5,
              "show-header": false,
              "portrait-target": _ctx.$refs.modalDisplayTarget,
              "landscape-target": _ctx.$refs.modalDisplayTarget
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.combinedActiveMetrics, (metric) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: metric,
                    class: "metric-option",
                    onClick: ($event: any) => (_ctx.onClickMetric(metric))
                  }, _toDisplayString(_ctx.friendlyMetricText(metric)), 9, _hoisted_1))
                }), 128))
              ], undefined, true),
              _: 1
            }, 8, ["portrait-target", "landscape-target"])
          ], undefined, true),
          _: 1
        }, 8, ["target"]))
      : _createCommentVNode("", true)
  ]))
}