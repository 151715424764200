<template>
    <div class="filter-bar-nps-type">
        <Dropdown
            ref="dropdown"
            :on-open="clearRuleSubOption"
            :modal="tsMobileView"
            :disabled="!hasNetworkConnection"
        >
            <template #trigger>
                <BaseButton
                    secondary
                    muted
                    :icon="hasNetworkConnection ? 'plus' : ''"
                    :disabled="!hasNetworkConnection"
                >
                    <template v-if="!tsMobileView">add </template>filter
                </BaseButton>
            </template>

            <template #content>
                <div class="options" :class="{ 'ts-modal-c': tsMobileView }">
                    <FilterModalMobileHeader
                        v-if="tsMobileView"
                        title="Add filter"
                        @close="closeModal"
                    />
                    <div
                        class="options-c"
                        :class="{
                            'mobile-view-modal-content-margin': tsMobileView,
                        }"
                    >
                        <div v-show="!showSubOptions">
                            <div
                                v-for="(
                                    optionSet, key
                                ) in checkFilterRuleOptions"
                                :key="key"
                                class="option-set"
                            >
                                <h5>{{ key }}</h5>
                                <div
                                    v-for="option in optionSet"
                                    :key="option.value"
                                    class="option"
                                    @click="
                                        (evt) =>
                                            initSubOptions(option.value, key)
                                    "
                                >
                                    <span>{{
                                        option.label
                                            .replace(/_c$/g, '')
                                            .replace(/_/g, ' ')
                                    }}</span>
                                    <i
                                        v-if="
                                            filterLoadingRuleSubOption ===
                                            option.value
                                        "
                                        class="asknicely-icon-loader_static icon-right"
                                    ></i>
                                    <img
                                        v-else
                                        src="@/assets/img/Icons/Grey/Chevron Right.svg"
                                        class="icon-right"
                                    />
                                </div>
                            </div>
                        </div>
                        <div v-show="showSubOptions" v-if="filterActive">
                            <div id="sub-options" class="option-set">
                                <div
                                    class="option option-back"
                                    @click="clearSelectedSubOptions"
                                >
                                    <img
                                        src="@/assets/img/Icons/Grey/Back.svg"
                                        class="icon-back"
                                    />
                                    <div>{{ activeRuleOptionLabel }}</div>
                                    <i
                                        v-if="filterPatching"
                                        class="asknicely-icon-loader_static icon-right"
                                    ></i>
                                </div>
                                <div
                                    v-if="filterIsNumeric"
                                    class="numeric-options"
                                >
                                    <a
                                        href="#"
                                        @click="handleFilterRuleOperatorOptions"
                                        ><div>
                                            {{ filterRuleOperator.name }}
                                            <i class="uk-icon-caret-down"></i>
                                        </div>
                                    </a>
                                    <div
                                        v-show="showFilterRuleOperatorOptions"
                                        id="filterRuleOperatorOptions"
                                    >
                                        <ul class="operator-options">
                                            <li
                                                v-for="operator in operators"
                                                v-show="
                                                    operator.name !==
                                                    filterRuleOperator.name
                                                "
                                                :key="operator.name"
                                                @click="
                                                    handleSelectOperator(
                                                        operator
                                                    )
                                                "
                                            >
                                                {{ operator.name }}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div
                                    v-else-if="
                                        !strOperatorExclude.includes(
                                            filterActiveRuleSubOption
                                        )
                                    "
                                    class="numeric-options"
                                >
                                    <a
                                        href="#"
                                        @click="handleFilterRuleOperatorOptions"
                                        ><div>
                                            {{ filterRuleOperator.name }}
                                            <i class="uk-icon-caret-down"></i>
                                        </div>
                                    </a>
                                    <div v-show="showFilterRuleOperatorOptions">
                                        <div>
                                            <ul class="operator-options">
                                                <li
                                                    v-for="operator in strOperators"
                                                    v-show="
                                                        operator.name !==
                                                        filterRuleOperator.name
                                                    "
                                                    :key="operator.name"
                                                    @click="
                                                        handleSelectOperator(
                                                            operator
                                                        )
                                                    "
                                                >
                                                    {{ operator.name }}
                                                </li>
                                                <template
                                                    v-if="subOption === 'tag'"
                                                >
                                                    <li
                                                        v-for="operator in hashtagOperators"
                                                        v-show="
                                                            operator.name !==
                                                            filterRuleOperator.name
                                                        "
                                                        :key="operator.name"
                                                        @click="
                                                            handleHashTagClickOption(
                                                                operator
                                                            )
                                                        "
                                                    >
                                                        {{ operator.name }}
                                                    </li>
                                                </template>

                                                <template
                                                    v-if="
                                                        subOption ===
                                                        'case_assigned_user_id'
                                                    "
                                                >
                                                    <li
                                                        v-for="operator in caseAssignedOperators"
                                                        v-show="
                                                            operator.name !==
                                                            filterRuleOperator.name
                                                        "
                                                        :key="operator.name"
                                                        @click="
                                                            handleCaseAssignedClickOption(
                                                                operator
                                                            )
                                                        "
                                                    >
                                                        {{ operator.name }}
                                                    </li>
                                                </template>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-if="mode === 'multi'"
                                    class="option-selected-container"
                                >
                                    <div class="option-selected-box">
                                        <div
                                            v-for="selectedSubOption in selectedSubOptions"
                                            v-show="
                                                selectedSubOption.value !==
                                                    'any' &&
                                                (filterRuleOperator.operator ===
                                                    'in' ||
                                                    filterRuleOperator.operator ===
                                                        'notin')
                                            "
                                            :key="selectedSubOption.value"
                                            class="option-selected"
                                            @click.stop="
                                                (evt) =>
                                                    removeFromSelectedSubOptions(
                                                        selectedSubOption
                                                    )
                                            "
                                        >
                                            {{
                                                getOptionValueLabel(
                                                    selectedSubOption
                                                )
                                            }}
                                            <span class="del">&times;</span>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-if="
                                        filterActiveRuleSubOptions.length ||
                                        filterSearchTerm
                                    "
                                    class="search-container"
                                >
                                    <SearchBox
                                        v-if="
                                            (filterSearchTerm ||
                                                filterActiveRuleSubOptions.length >
                                                    5) &&
                                            (filterRuleOperator.operator ===
                                                'in' ||
                                                filterRuleOperator.operator ===
                                                    'notin')
                                        "
                                        :value="filterSearchTerm"
                                        @input="onSearchInput"
                                    />
                                    <SearchBox
                                        v-if="
                                            filterRuleOperator.operator !==
                                                'in' &&
                                            filterRuleOperator.operator !==
                                                'notin'
                                        "
                                        :value="filterSubOptionValue"
                                        placeholder="Number"
                                        type="number"
                                        @input="onNumberInput"
                                    />
                                </div>
                                <InfiniteScroll
                                    scroll-container-classes="moment-infinite-scroll"
                                    :load-more="loadMoreContent"
                                    :is-loading-more="infiniteIsLoading"
                                    :has-reached-end="infiniteReachedTheEnd"
                                    scroll-end-class="scroll-end-infinite"
                                >
                                    <template
                                        v-if="
                                            filterRuleOperator.operator ===
                                                'in' ||
                                            filterRuleOperator.operator ===
                                                'notin'
                                        "
                                    >
                                        <div
                                            v-for="option in infiniteContent"
                                            :key="option.value"
                                            class="option"
                                            @click="
                                                (evt) =>
                                                    handleClickOption(option)
                                            "
                                        >
                                            <span
                                                data-uk-tooltip
                                                :title="option.label"
                                                >{{ option.label }}</span>
                                            <b>{{ option.count }}</b>
                                        </div>
                                    </template>

                                    <div
                                        v-if="
                                            !infiniteIsLoading &&
                                            showSubOptions &&
                                            infiniteContent.length > 1 &&
                                            (filterRuleOperator.operator ===
                                                'in' ||
                                                filterRuleOperator.operator ===
                                                    'notin')
                                        "
                                        class="sub-info"
                                    >
                                        {{ infiniteContent.length }}
                                        Results Found
                                    </div>
                                    <div
                                        v-if="
                                            !infiniteIsLoading &&
                                            showSubOptions &&
                                            (infiniteContent.length === 0 ||
                                                !searchedResults().length) &&
                                            (filterRuleOperator.operator ===
                                                'in' ||
                                                filterRuleOperator.operator ===
                                                    'notin')
                                        "
                                        class="sub-info"
                                    >
                                        no results found
                                    </div>

                                    <template #loading>
                                        <div class="loading-more">
                                            Please wait, loading filter
                                            values...
                                        </div>
                                    </template>
                                </InfiniteScroll>
                            </div>
                        </div>
                        <div
                            v-show="showSubOptions"
                            v-if="filterActive"
                            class="sticky-cta"
                        >
                            <div class="cta-container">
                                <div class="option-back">
                                    <BaseButton
                                        secondary
                                        :click="clearSelectedSubOptions"
                                        >Cancel</BaseButton>
                                    <BaseButton
                                        v-if="mode == 'multi'"
                                        primary
                                        :click="saveMultiFilter"
                                        :disabled="hasFilterChanged"
                                        >{{ applyingLabel }}</BaseButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </Dropdown>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { useStore } from 'vuex'
import { Action, Getter } from 'vuex-facing-decorator'
import Dropdown from './Dropdown.vue'
import BaseButton from './BaseButton.vue'
import {
    Option,
    FilterRuleOperator,
    getGeneralMultiSelectFilters,
    nonNumericFilters,
} from '@/entities'
import FilterModalMobileHeader from '@/components/FilterModalMobileHeader.vue'
import SearchBox from '@/components/SearchBox.vue'
import { Permissions } from '@/mobile/src/types/auth'
import { getRuleSubOptions } from '@/api/filter'
import InfiniteScroll from '@/mobile/src/components/appV4/InfiniteScroll.vue'
import LoadingCard from '@/mobile/src/components/appV4/LoadingCard.vue'
import { pendoTrackEvent } from '@/utils/pendo'

type OptionSetKey = 'general' | 'custom'

@Component({
    components: {
        FilterModalMobileHeader,
        BaseButton,
        Dropdown,
        SearchBox,
        InfiniteScroll,
        LoadingCard,
    },
})
export default class FilterBarNPSType extends Vue {
    @Prop({ type: Array, default: () => [] }) public permissions!: string[]

    @Getter public filterActive
    @Getter public filterRuleOptions
    @Getter public filterLoadingRuleSubOption
    @Getter public filterActiveRuleSubOption
    @Getter public filterActiveRuleSubOptions
    @Getter public filterSearchTerm
    @Getter public filterPatching
    @Getter public tsMobileView
    @Getter hasNetworkConnection!: boolean

    @Action public getRuleOptions
    @Action public loadRuleSubOptions
    @Action public reloadRuleSubOptions
    @Action public clearRuleSubOption
    @Action public setFilterRule
    @Action public removeFilterRule
    @Action public setSearchTerm
    @Action public setUsePagination

    public selectedSubOptions: Array<Option<string>> = []
    public mode: 'single' | 'multi' = 'single'
    public showFilterRuleOperatorOptions = false
    public subOption?: string = ''
    public optionSetKey?: OptionSetKey
    public filterSubOptionValue = ''
    public delayTimer // a delaytime to delay search ajax request
    public infiniteContent: any = []
    public infiniteIsLoading = false
    public infiniteOffset = 1
    public infinitePerPage = 1000
    public infiniteReachedTheEnd = false
    public infiniteSearchTerm = ''

    public hashtagOperators: FilterRuleOperator[] = [
        {
            name: 'has hashtags',
            operator: 'has_hashtags',
        },
        {
            name: 'none',
            operator: 'has_no_hashtags',
        },
    ]

    public caseAssignedOperators: FilterRuleOperator[] = [
        {
            name: 'Is Assigned',
            operator: 'is_assigned',
        },
        {
            name: 'Is Not Assigned',
            operator: 'is_not_assigned',
        },
    ]

    public store = useStore()
    public get specialHashtagValues() {
        return this.hashtagOperators.map((obj) => obj.operator)
    }

    public get specialCaseAssigneValues() {
        return this.caseAssignedOperators.map((obj) => obj.operator)
    }

    public strOperators: FilterRuleOperator[] = [
        {
            name: 'is',
            operator: 'in',
        },
        {
            name: 'is not',
            operator: 'notin',
        },
    ]

    public strOperatorExclude = [
        'comments',
        'notes',
        'messaged',
        'published',
        'case',
        'favourited',
    ]

    // numeric operators
    public operators: FilterRuleOperator[] = [
        {
            name: 'is  ',
            operator: 'in',
        },
        {
            name: 'is not  ',
            operator: 'notin',
        },
        {
            name: 'Greater than  ',
            operator: 'gt',
        },
        {
            name: 'Less than  ',
            operator: 'lt',
        },
    ]

    public filterRuleOperator: FilterRuleOperator = this.operators[0]

    public async loadMoreContent() {
        this.infiniteIsLoading = true

        if (this.filterActiveRuleSubOption) {
            const { data } = await getRuleSubOptions(
                this.filterActiveRuleSubOption,
                this.infiniteSearchTerm,
                true,
                this.infiniteOffset
            )
            this.updateInfiniteData(data)
        }

        this.infiniteIsLoading = false
        return true
    }

    public updateInfiniteData(data: any[], reset = false, fromSearch = false) {
        if (reset) {
            this.infiniteContent = []
            this.infiniteOffset = 1
            this.infiniteReachedTheEnd = false

            if (!fromSearch) {
                this.infiniteSearchTerm = ''
            }
        } else {
            this.infiniteContent = [...this.infiniteContent, ...data]
            this.infiniteOffset++
            if (
                data.length === 0 ||
                data.length < this.infinitePerPage ||
                data.length > this.infinitePerPage
            ) {
                this.infiniteReachedTheEnd = true
            }
        }
    }

    public get showSubOptions(): boolean {
        const { filterLoadingRuleSubOption, filterActiveRuleSubOption } = this
        return !filterLoadingRuleSubOption && filterActiveRuleSubOption
    }

    public get applyingLabel() {
        return this.filterPatching ? 'Applying...' : 'Apply'
    }

    public get filterIsNumeric() {
        if (nonNumericFilters.includes(this.subOption || '')) {
            return false
        }

        return (
            this.filterActiveRuleSubOptions &&
            this.filterActiveRuleSubOptions.some(
                (element) => !isNaN(element.value)
            )
        )
    }

    public get currentOperators(): FilterRuleOperator[] {
        return this.filterIsNumeric ? this.operators : this.strOperators
    }

    public get hasFilterChanged() {
        const activeRule = this.filterActive.filter_rules.find(
            (rule) => rule.column === this.subOption
        )
        const activeValues = activeRule?.value ?? []
        const selectedValues = this.selectedSubOptions.map(
            (option) => option.value
        )
        const valuesChanged =
            selectedValues.length !== activeValues.length ||
            selectedValues.filter((value) => !activeValues.includes(value))
                .length > 0 ||
            activeValues.filter((value) => !selectedValues.includes(value))
                .length > 0 ||
            (activeRule &&
                activeRule.operator !== this.filterRuleOperator.operator)

        return (
            this.filterPatching ||
            (this.filterRuleOperator.operator !== 'in' &&
                this.filterRuleOperator.operator !== 'notin' &&
                !this.filterSubOptionValue) ||
            (!valuesChanged &&
                (this.filterRuleOperator.operator === 'in' ||
                    this.filterRuleOperator.operator === 'notin'))
        )
    }

    public get activeRuleOptionLabel() {
        if (
            !this.filterActiveRuleSubOption ||
            !this.store.state.filter.ruleOptions ||
            this.store.state.filter.ruleOptions.length === 0
        ) {
            return ''
        }

        const activeRuleOption = this.store.state.filter.ruleOptions.find(
            (ruleOption) => ruleOption.value === this.filterActiveRuleSubOption
        )

        // Track event if user select Case Assignee filter.
        if (activeRuleOption?.value === 'case_assigned_user_id') {
            pendoTrackEvent('response_case_assignee_search_filter')
        }

        return activeRuleOption?.label ?? ''
    }

    public searchedResults(slice: Boolean = true) {
        const filtered =
            this.filterSearchTerm.length > 0
                ? this.filterActiveRuleSubOptions.filter(
                      (opt) =>
                          String(opt.label)
                              .toLowerCase()
                              .trim()
                              .indexOf(this.filterSearchTerm) > -1
                  )
                : this.filterActiveRuleSubOptions

        if (slice) {
            return filtered.slice(0, 100)
        }

        return filtered
    }

    public mounted() {
        this.setUsePagination(true)
        this.getRuleOptions()
        if (this.filterActiveRuleSubOption) {
            this.loadRuleSubOptions(this.filterActiveRuleSubOption)
        }
    }

    public async initSubOptions(subOption: string, optionSetKey: OptionSetKey) {
        this.updateInfiniteData([], true)
        this.subOption = subOption
        this.optionSetKey = optionSetKey
        // custom fields and some general fields (rating, tag & messaged) allow multiple values
        this.mode =
            optionSetKey === 'general' &&
            !getGeneralMultiSelectFilters().includes(subOption)
                ? 'single'
                : 'multi'

        await this.loadRuleSubOptions(subOption)
        this.updateInfiniteData(this.filterActiveRuleSubOptions)

        // Check for operator in existing rule if non found fallback to first operator
        this.filterRuleOperator =
            this.filterActive.filter_rules.find(
                (rule) => rule.column === this.subOption
            ) ?? this.currentOperators[0]

        await this.refreshSubOptions()
        const optionSet = document.getElementById('sub-options')
        if (optionSet !== null) {
            optionSet.scrollIntoView()
        }
    }

    public refreshSubOptions() {
        const activeRule = this.filterActive.filter_rules.find(
            (rule) =>
                rule.column === this.subOption &&
                rule.operator === this.filterRuleOperator.operator
        )

        if (activeRule) {
            this.filterRuleOperator =
                this.currentOperators.find(
                    (o: FilterRuleOperator) =>
                        o.operator === activeRule.operator
                ) || this.filterRuleOperator
        }

        if (activeRule && this.mode === 'multi') {
            this.selectedSubOptions = activeRule.value
                .map((value: string) => {
                    // Don't show the special hashtag operators as pill options
                    if (this.specialHashtagValues.includes(value)) {
                        return
                    }

                    if (this.specialCaseAssigneValues.includes(value)) {
                        return
                    }

                    return {
                        value,
                        label: value,
                    }
                })
                .filter((value?: string) => value !== undefined)
        }

        if (this.filterIsNumeric && !activeRule) {
            this.selectedSubOptions = []
        }

        this.filterSubOptionValue = this.getSelectedSubOptionValue()
    }

    public clearSelectedSubOptions(): void {
        this.selectedSubOptions = []
        this.clearRuleSubOption()
        this.showFilterRuleOperatorOptions = false
    }

    public getSelectedSubOptions() {
        return this.selectedSubOptions
    }

    public getOptionValueLabel(opt) {
        const found = this.filterActiveRuleSubOptions.find(
            (ruleOption) => ruleOption.value === opt.value
        )
        return found ? found.label : opt.label
    }

    public getSelectedSubOptionValue() {
        const option = this.getSelectedSubOptions()[0]
        return (option && option.value) || ''
    }

    public addToSelectedSubOptions(selectedSubOption: Option<string>): void {
        if (
            !this.selectedSubOptions.some(
                (element) => element.value === selectedSubOption.value
            )
        ) {
            this.selectedSubOptions.push(selectedSubOption)
        }
    }

    public removeFromSelectedSubOptions(
        subOptionToRemove: Option<string>
    ): void {
        this.selectedSubOptions = this.selectedSubOptions.filter(
            (element) => element.value !== subOptionToRemove.value
        )
    }

    public handleFilterRuleOperatorOptions(): void {
        this.showFilterRuleOperatorOptions = !this.showFilterRuleOperatorOptions
    }

    public async handleHashTagClickOption(option: FilterRuleOperator) {
        this.mode = 'single'
        let payload = {
            label: this.hashtagOperators[0].name,
            value: this.hashtagOperators[0].operator,
        }
        if (option.operator === this.hashtagOperators[1].operator) {
            // Has NO Hashtags
            payload = {
                label: this.hashtagOperators[1].name,
                value: this.hashtagOperators[1].operator,
            }
        }
        await this.handleClickOption(payload)
        this.showFilterRuleOperatorOptions = false
    }

    public async handleCaseAssignedClickOption(option: FilterRuleOperator) {
        this.mode = 'single'
        let payload = {
            label: this.caseAssignedOperators[0].name,
            value: this.caseAssignedOperators[0].operator,
        }
        if (option.operator === this.caseAssignedOperators[1].operator) {
            // Unassigned Case
            payload = {
                label: this.caseAssignedOperators[1].name,
                value: this.caseAssignedOperators[1].operator,
            }
        }
        await this.handleClickOption(payload)
        this.showFilterRuleOperatorOptions = false
    }

    public handleSelectOperator(operator: FilterRuleOperator): void {
        this.filterRuleOperator = operator
        this.showFilterRuleOperatorOptions = false
        this.refreshSubOptions()
    }

    public async handleClickOption(option: Option<string>) {
        if (
            !isNaN(option.value as any) &&
            this.filterRuleOperator.operator !== 'in' &&
            this.filterRuleOperator.operator !== 'notin'
        ) {
            this.mode = 'single'
        }
        if (this.mode === 'multi') {
            this.addToSelectedSubOptions({
                value: option.value,
                label: option.label,
            })
        } else {
            await this.setFilterRule({
                id: this.filterActive.id,
                rule: {
                    column: this.filterActiveRuleSubOption,
                    operator: this.filterRuleOperator.operator,
                    value: [option.value],
                },
            })
            setTimeout(
                () => ((this.$refs.dropdown as any).$data.opened = false),
                0
            )
        }
    }

    private closeModal() {
        (this.$refs.dropdown as any).$data.opened = false
    }

    public async saveMultiFilter() {
        if (
            this.filterRuleOperator.operator !== 'in' &&
            this.filterRuleOperator.operator !== 'notin' &&
            !this.filterSubOptionValue
        ) {
            return
        }

        const selectedValues = this.selectedSubOptions.map(({ value }) => value)
        if (
            selectedValues.length === 0 &&
            (this.filterRuleOperator.operator === 'in' ||
                this.filterRuleOperator.operator === 'notin')
        ) {
            // If all values are removed, filter rule need to be remove
            await this.removeFilterRule({
                id: this.filterActive.id,
                column: this.filterActiveRuleSubOption,
                operator: this.filterRuleOperator.operator,
            })
        } else {
            await this.setFilterRule({
                id: this.filterActive.id,
                rule: {
                    column: this.filterActiveRuleSubOption,
                    operator: this.filterRuleOperator.operator,
                    value:
                        this.filterRuleOperator.operator === 'in' ||
                        this.filterRuleOperator.operator === 'notin'
                            ? selectedValues
                            : [this.filterSubOptionValue],
                },
            })
        }
        setTimeout(() => ((this.$refs.dropdown as any).$data.opened = false), 0)

        this.clearSelectedSubOptions()
    }

    public async onSearchInput(value) {
        this.infiniteSearchTerm = value
        clearTimeout(this.delayTimer)
        this.delayTimer = setTimeout(async () => {
            this.updateInfiniteData([], true, true)
            await this.loadMoreContent()
        }, 800)
    }

    public async onNumberInput(value: string) {
        this.filterSubOptionValue = value
    }

    public get checkFilterRuleOptions() {
        const filterRuleOptions = JSON.parse(
            JSON.stringify(this.filterRuleOptions)
        )
        // Hide CUSTOM filters
        if (
            filterRuleOptions?.custom &&
            !this.permissions.includes(Permissions.RoleViewNameEmailCustomData)
        ) {
            delete filterRuleOptions?.custom
        }
        return filterRuleOptions
    }
}
</script>

<style scoped lang="less">
@import '../styles/palette';

.filter-bar-nps-type {
    display: inline-block;
    height: 42px;
    padding: 0px;
}
.options {
    width: 300px;
    background: @white;
}
.option-set {
    width: 100%;
    padding: 15px 0px;
}
h5 {
    padding: 5px 15px;
    margin: 0px;
    font-size: 16px;
    font-weight: bold;
    color: @greyMid2;
    text-transform: capitalize;
}
.option {
    padding: 5px 15px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    span {
        padding-right: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
.option.option-back {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.option:hover {
    background: @backgroundColor;
    color: @ctaColor;
    img {
        filter: @ctaFilter;
    }
}
.option-selected {
    display: inline-block;
    background: @greyLight4;
    border-radius: 20px;
    padding: 6px 16px;
    margin: 3px;
    font-weight: bold;
    cursor: pointer;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.cta-container {
    padding: 5px 15px;
    text-align: right;
    cursor: pointer;
    background: white;
    height: 45px;
}
.sticky-cta {
    position: sticky;
    bottom: 0;
}
.search-container {
    padding: 0 15px 10px 15px;
}
.option-selected-container {
    padding: 5px 15px 5px 15px;
}
.option-selected-box {
    padding: 3px;
    background: @backgroundColor;
    &:empty {
        padding: 0px;
        display: none;
    }
}
.search {
    display: block;
    background: @backgroundColor;
    padding: 10px 12px;
    border: none;
    font-size: 16px;
    font-family: 'Roboto';
    width: 100%;
    box-sizing: border-box;
    &::placeholder {
        color: @greyMid;
    }
}
.sub-info {
    margin-top: 5px;
    padding: 5px 15px;
    font-style: italic;
}
.icon-right {
    float: right;
    padding: 0px;
}
i.icon-right {
    top: 0px;
    left: 3px;
    position: relative;
}
.icon-back {
    margin-top: -3px;
    margin-left: -2px;
}
.del {
    padding-left: 3px;
    opacity: 0.7;
}
ul.operator-options {
    list-style-type: none;
    padding-left: 0px;
    margin-bottom: 0px;
}
ul.operator-options li {
    padding: 5px 15px 5px 15px;
    cursor: pointer;
}
ul.operator-options li:hover {
    background-color: @greyLight;
}
.numeric-options a div {
    padding: 5px 15px 5px 15px;
}
.numeric-options a div:hover {
    background-color: @greyLight;
}

.numeric-options {
    a {
        color: @blue;
    }
}

input.numeric-value {
    padding-top: 15px;
}
.filter-bar-nps-type .button {
    padding: 6px 17px;
}
.filter-bar-nps-type .trigger > .button {
    height: 42px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1.03px;
    display: flex;
    align-items: center;
}

@media (max-width: 1280px) {
    .filter-bar-nps-type {
        height: 38px;
    }

    .filter-bar-nps-type .trigger > .button {
        padding: 0 6px;
        height: 38px;
    }
}

.loading-more {
    text-align: center;
    padding: 10px 0;
}

#infinite-scroll-container.scroll-end-infinite {
    height: 25px;
}
</style>
