<template>
    <div>
        <Loading v-if="pageLoading" :loading="pageLoading" />
        <ScorecardSettings @changedSettings="onScorecardSettingsChanged" />
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import ScorecardSettings from '@/mobile/src/components/appV4/more/ScorecardSettings.vue'
import Loading from '@/components/Loading.vue'

@Component({
    components: {
        ScorecardSettings,
        Loading,
    },
})
export default class EvenMoreSettings extends Vue {
    public pageLoading = false

    public onScorecardSettingsChanged() {
        this.pageLoading = true
    }
}
</script>
