import { ActionTree, GetterTree, MutationTree } from 'vuex'
import {
    IScorecardSetting,
    IScorecardUserRole,
} from '@/pages/appstore/components/Scorecard/scorecard-settings-entity'
import {
    getScorecardSettingsList,
    getScorecardUserRoles,
    saveScorecardSetting,
    getScorecardTopicsInUse,
} from '@/api/appstore/scorecardSettings'

export interface ScorecardSettingsState {
    isLoadingList: boolean
    scorecardList: IScorecardSetting[]
    isAdding: boolean
    isEditing: boolean
    currentScorecard: IScorecardSetting
    topicsInUse: any // TODO: type this
    userRoles: IScorecardUserRole[]
    isLoadingUserRoles: boolean
    isLoadingTopicsInUse: boolean
    isSavingScorecard: boolean
}

const NEW_SCORECARD = {
    id: 0,
    name: '',
    field: '',
    deleted_at: 0,
    userRoleIds: [],
    scorecardTopics: [],
}

const INITIAL_STATE: ScorecardSettingsState = {
    isLoadingList: true, // initial loading state for scorecard list
    scorecardList: [], // list for all scorecards
    isAdding: false, // whether we are adding a new scorecard
    isEditing: false, // whether we are editing an existing scorecard
    currentScorecard: { ...NEW_SCORECARD }, // if editing/adding a scorecard, what's the id, 0 means adding new scorecard
    topicsInUse: [],
    userRoles: [], // the user roles to display when selecting roles for scorecard
    isLoadingUserRoles: false, // whether we are loading user roles list
    isLoadingTopicsInUse: false, // whether we are loading the currently in-use topics
    isSavingScorecard: false, // whether we are saving scorecard
}

const getters: GetterTree<ScorecardSettingsState, any> = {
    scorecardList: ({ scorecardList }) => scorecardList,
    isLoadingList: ({ isLoadingList }) => isLoadingList,
    isEditing: ({ isEditing }) => isEditing,
    isAdding: ({ isAdding }) => isAdding,
    currentScorecard: ({ currentScorecard }) => currentScorecard,
    topicsInUse: ({ topicsInUse }) => topicsInUse,
    userRoles: ({ userRoles }) => userRoles,
    isLoadingUserRoles: ({ isLoadingUserRoles }) => isLoadingUserRoles,
    isLoadingTopicsInUse: ({ isLoadingTopicsInUse }) => isLoadingTopicsInUse,
    isSavingScorecard: ({ isSavingScorecard }) => isSavingScorecard,
    getTopicNameByTopicId({ scorecardList }) {
        return (topicId: number) => {
            let name: any = topicId
            scorecardList.forEach((scorecard) => {
                scorecard.scorecardTopics.forEach((topic) => {
                    if (topic.id === topicId) {
                        name = topic.topic_name
                    }
                })
            })
            return name
        }
    },
}

const actions: ActionTree<ScorecardSettingsState, any> = {
    async loadScorecardList({ commit }) {
        commit('setState', { key: 'isLoadingList', value: true })
        const value = await getScorecardSettingsList()
        commit('setState', { key: 'scorecardList', value })
        commit('setState', { key: 'isLoadingList', value: false })
    },

    async startAddScorecard({ commit }) {
        commit('setState', { key: 'isAdding', value: true })
        commit('setState', { key: 'isEditing', value: false })
        commit('setState', {
            key: 'currentScorecard',
            value: { ...NEW_SCORECARD },
        })
    },

    async startEditScorecard({ commit, state }, id: number) {
        commit('setState', { key: 'isAdding', value: false })
        commit('setState', { key: 'isEditing', value: true })
        commit('setState', {
            key: 'currentScorecard',
            value: state.scorecardList.find((scorecard) => scorecard.id === id),
        })
    },

    async stopEditScorecard({ commit, state }) {
        commit('setState', { key: 'isAdding', value: false })
        commit('setState', { key: 'isEditing', value: false })
    },

    async loadScorecardUserRoles({ commit }) {
        commit('setState', { key: 'isLoadingUserRoles', value: true })
        const value = await getScorecardUserRoles()
        commit('setState', { key: 'userRoles', value })
        commit('setState', { key: 'isLoadingUserRoles', value: false })
    },

    async loadScorecardTopicsInUse({ commit, state }) {
        commit('setState', { key: 'isLoadingTopicsInUse', value: true })
        const { currentScorecard } = state
        const value = await getScorecardTopicsInUse(currentScorecard.id)
        commit('setState', { key: 'topicsInUse', value })
        commit('setState', { key: 'isLoadingTopicsInUse', value: false })
    },

    toggleUserRole({ commit, state }, userRoleId) {
        const { currentScorecard } = state
        if (currentScorecard.userRoleIds.includes(userRoleId)) {
            currentScorecard.userRoleIds = currentScorecard.userRoleIds.filter(
                (id) => id !== userRoleId
            )
        } else {
            currentScorecard.userRoleIds.push(userRoleId)
        }
        commit('setState', { key: 'currentScorecard', value: currentScorecard })
    },

    async saveScorecard({ commit, state, dispatch }) {
        const { currentScorecard, scorecardList } = state

        commit('setState', { key: 'isSavingScorecard', value: true })
        const updatedScorecard = await saveScorecardSetting(currentScorecard)
        commit('setState', { key: 'isSavingScorecard', value: false })
        commit('setState', { key: 'currentScorecard', value: updatedScorecard })

        const oldScorecard = scorecardList.find(
            (scorecard) => scorecard.id === updatedScorecard.id
        )
        if (oldScorecard) {
            scorecardList[scorecardList.indexOf(oldScorecard)] =
                updatedScorecard
        } else {
            scorecardList.push(updatedScorecard)
        }
        commit('setState', { key: 'scorecardList', value: scorecardList })
        // need to get user roles updated as well
        dispatch('loadScorecardUserRoles')
    },

    updateCurrentScorecard({ commit, state }, scorecard: IScorecardSetting) {
        commit('setState', { key: 'currentScorecard', value: scorecard })
    },
}

const mutations: MutationTree<ScorecardSettingsState> = {
    setState(state, { key, value }) {
        state[key] = value
    },
}

export default {
    namespaced: true,
    state: INITIAL_STATE,
    getters,
    mutations,
    actions,
}
