<template>
    <div v-if="showManagerComment" class="manager-comment-container">
        <div class="comment-container">
            <div class="comment-header">
                <div class="title">Manager's Overall Comment</div>
                <div class="icon">👏</div>
            </div>
            <div class="comment-input">
                <textarea
                    ref="commentTextArea"
                    placeholder="Enter your comment for this staff"
                    :value="comment"
                    :disabled="!canSave"
                    @input="onInput"
                />
            </div>
        </div>
        <div v-if="canEdit || canSave" class="button-container">
            <PillButton
                v-if="canSave"
                label="Save"
                class="button"
                :disabled="comment === '' || comment === null"
                @click="onSave"
            />
            <PillButton
                v-else-if="canEdit"
                class="button"
                label="Edit"
                @click="onEdit"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import { Getter, namespace } from 'vuex-facing-decorator'
import useEmitter from '@/composables/useEmitter'
import { Emitter } from 'mitt'
import PillButton from '@/components/PillButton.vue'
import { ReportCardComment } from '@/entities/reportcard'
import { UserRoleType } from '@/mobile/src/types/auth'

const ReportCardModule = namespace('reportcard')

@Component({
    components: {
        PillButton,
    },
})
export default class ManagerComment extends Vue {
    @Prop({ type: Number, default: null }) public month!: number

    @ReportCardModule.Getter reportCardComment!: ReportCardComment
    @ReportCardModule.Action saveComment!: (payload: {
        comment: ReportCardComment
        month: number
        isEdit: boolean
        emitter: Emitter<any> | null
    }) => Promise<void>

    @Getter userRoleType

    public comment: string | null = ''

    public inEditMode = false

    public emitter = useEmitter()

    public mounted() {
        this.comment = this.reportCardComment.content
    }

    public onInput(e) {
        this.comment = e.target.value
    }

    @Watch('reportCardComment')
    public updateComment() {
        this.comment = this.reportCardComment.content
    }

    public get canSave() {
        if (this.userRoleType !== UserRoleType.FrontlineManager) {
            return false
        }

        // Can only comment on the latest report card i.e. previous month
        const today = new Date()
        today.setMonth(today.getMonth() - 1)
        const previousMonth = today.getMonth() + 1 // +1 as return value is zero-indexed

        if (this.month !== previousMonth) {
            return false
        }

        if (this.inEditMode) {
            return true
        }

        return (
            this.reportCardComment.content === '' ||
            this.reportCardComment.content === null
        )
    }

    public get canEdit() {
        const now = Math.floor(Date.now() / 1000) // Get the current timestamp in seconds
        const timeLimit = 3600 // 1 hour = 3600 seconds

        const isWithinTimeLimit =
            this.reportCardComment.created !== null &&
            Number(this.reportCardComment.created) >= now - timeLimit &&
            Number(this.reportCardComment.created) <= now

        return (
            this.userRoleType === UserRoleType.FrontlineManager &&
            this.comment !== '' &&
            isWithinTimeLimit
        )
    }

    public async onSave() {
        await this.saveComment({
            comment: {
                content: this.comment,
                created: null,
            },
            month: this.month,
            isEdit: this.inEditMode,
            emitter: this.emitter,
        })

        this.inEditMode = false
    }

    public async onEdit() {
        this.inEditMode = true

        const commentTextArea = this.$refs
            .commentTextArea as HTMLTextAreaElement

        // workaround for .focus() not working bug
        setTimeout(function () {
            commentTextArea.focus()
        }, 0)
    }

    public get showManagerComment() {
        return (
            (this.comment !== '' && this.comment !== null) ||
            this.canSave ||
            this.canEdit
        )
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';

.manager-comment-container {
    margin: 25px @featureGutter @featureGutter @featureGutter;

    .comment-container {
        width: 100vw;
        border-bottom: 1px solid @thinFog;
        margin-left: 50%;
        transform: translateX(-50%);

        .comment-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: @featureGutter;
            padding-right: @featureGutter;

            .title {
                color: @white;
                font-family: Roboto, sans-serif;
                font-size: @fontSize-sm;
                font-style: normal;
                font-weight: @fontWeight-medium;
                line-height: @lineHeight-sm; /* 150% */
                letter-spacing: @letterSpacing-2xl;
                text-transform: uppercase;
            }

            .icon {
                font-size: 30px;
            }
        }

        .comment-input {
            margin-top: 10px;
            margin-bottom: @featureGutter;
            padding-left: @featureGutter;
            padding-right: @featureGutter;

            textarea {
                opacity: 1;
                padding: 0;
                color: @white;
                font-family: Roboto, sans-serif;
                font-size: @fontSize-base;
                font-style: normal;
                font-weight: @fontWeight-regular;
                line-height: @lineHeight-base;
                letter-spacing: @letterSpacing-md;
                height: 100px;
                overflow-y: scroll;
                width: 100%;
                border: none;
                background: none;

                &::placeholder {
                    color: @white;
                    opacity: 0.3;
                }
                &:focus {
                    outline: none;
                }
            }
        }
    }

    .button-container {
        width: 100vw;
        border-bottom: 1px solid @thinFog;
        margin-left: 50%;
        transform: translateX(-50%);

        .button {
            margin: @featureGutter;
            padding-left: @featureGutter;
            padding-right: @featureGutter;
            color: @brandPurple;
        }
    }
}
</style>
