import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3a2ff8ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "schedule-one" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InsightsEngagementThemeItem = _resolveComponent("InsightsEngagementThemeItem")!
  const _component_BaseCard = _resolveComponent("BaseCard")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_InsightsEngagementSpotlightList = _resolveComponent("InsightsEngagementSpotlightList")!
  const _component_EngagementEmptyTeamMembersAttentionState = _resolveComponent("EngagementEmptyTeamMembersAttentionState")!
  const _component_Tab = _resolveComponent("Tab")!
  const _component_Tabs = _resolveComponent("Tabs")!

  return (_ctx.engagementTheme)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["engagement-theme-trend-container", { 'desktop-no-margin': !_ctx.$isMobile }])
      }, [
        (_ctx.$isMobile)
          ? (_openBlock(), _createBlock(_component_BaseCard, {
              key: 0,
              class: "engagement-theme-trend-header"
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_component_InsightsEngagementThemeItem, {
                  key: _ctx.engagementTheme.id,
                  "theme-id": _ctx.engagementTheme.id,
                  title: _ctx.engagementTheme.name,
                  points: _ctx.engagementTheme.change_in_term_data.score,
                  "overall-score": _ctx.engagementTheme.current_term_data.score,
                  "previous-score": _ctx.engagementTheme.previous_term_data.score,
                  "delta-positive": _ctx.engagementTheme.change_in_term_data.score > 0,
                  history: _ctx.engagementTheme.history,
                  "show-info-icon": true
                }, null, 8, ["theme-id", "title", "points", "overall-score", "previous-score", "delta-positive", "history"]))
              ], undefined, true),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_BaseButton, {
            full: "",
            disabled: _ctx.scheduleMeetingUser === null,
            onClick: _ctx.onMessageUser
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Schedule 1:1"),
              (_ctx.scheduleMeetingUser)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(" with " + _toDisplayString(_ctx.scheduleMeetingUser.name), 1)
                  ], 64))
                : _createCommentVNode("", true)
            ], undefined, true),
            _: 1
          }, 8, ["disabled", "onClick"])
        ]),
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$isMobile ? 'BaseCard' : 'div'), null, {
          default: _withCtx(() => [
            _createVNode(_component_Tabs, {
              active: _ctx.activeTab,
              vsmall: "",
              "full-width": "",
              onChange: _ctx.setActiveTab
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userGroups, (group) => {
                  return (_openBlock(), _createBlock(_component_Tab, {
                    key: group.name,
                    name: group.name,
                    "is-active": _ctx.activeTab === group.name
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_InsightsEngagementSpotlightList, {
                        class: "insights-engagement-list",
                        "spotlight-team": group.users,
                        theme: _ctx.engagementTheme.name
                      }, null, 8, ["spotlight-team", "theme"]),
                      (group.users.length < 1)
                        ? (_openBlock(), _createBlock(_component_EngagementEmptyTeamMembersAttentionState, {
                            key: 0,
                            state: group.name,
                            class: "engagement-empty-state"
                          }, null, 8, ["state"]))
                        : _createCommentVNode("", true)
                    ], undefined, true),
                    _: 2
                  }, 1032, ["name", "is-active"]))
                }), 128))
              ], undefined, true),
              _: 1
            }, 8, ["active", "onChange"])
          ], undefined, true),
          _: 1
        }))
      ], 2))
    : _createCommentVNode("", true)
}