import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { getScoreData } from '@/api/nps'
import { pendoTrackEvent } from '@/utils/pendo'
import { IScoreData } from '@/entities/scorecard'

export interface ScoreDataState {
    scoredataLoading: boolean
    scoredataError: boolean
    emptyScoreData: boolean
    scoreGraph: any | null
    scoreType: string | null
    scoreTarget: Number
    scoreValue: Number
    showScoreGraph: boolean | null
    scoreData: Record<string, IScoreData[]>
    cacheKeyScoreData: string
}

function getDefaultState(): ScoreDataState {
    return {
        scoredataLoading: false,
        scoredataError: false,
        emptyScoreData: false,
        scoreGraph: null,
        scoreType: null,
        scoreTarget: 0,
        scoreValue: 0,
        showScoreGraph: null,
        scoreData: {},
        cacheKeyScoreData: '',
    }
}

export const state: ScoreDataState = getDefaultState()

const getters: GetterTree<ScoreDataState, any> = {
    scoredataLoading({ scoredataLoading }) {
        return scoredataLoading
    },
    scoredataError({ scoredataError }) {
        return scoredataError
    },
    emptyScoreData({ emptyScoreData }) {
        return emptyScoreData
    },
    scoreGraph({ scoreGraph }) {
        return scoreGraph
    },
    scoreType({ scoreType }) {
        return scoreType
    },
    scoreTarget({ scoreTarget }) {
        return scoreTarget
    },
    scoreValue({ scoreValue }) {
        return scoreValue
    },
    showScoreGraph({ showScoreGraph }) {
        return showScoreGraph
    },
}

const actions: ActionTree<ScoreDataState, any> = {
    async loadScoreData(
        { commit, state, rootGetters },
        payload: {
            days: string
            forceRefresh?: boolean
            extraFilter: null
            overwriteFilter?: boolean
        }
    ) {
        const {
            days,
            forceRefresh = false,
            extraFilter = null,
            overwriteFilter = false,
        } = payload
        commit('setScoredataLoading', true)

        try {
            const questionType = rootGetters.mobileQuestionType
            state.cacheKeyScoreData = btoa(
                `${days}${JSON.stringify(extraFilter)}${questionType}`
            )
            if (forceRefresh || !state.scoreData[state.cacheKeyScoreData]) {
                const { data } = await getScoreData(
                    days,
                    questionType,
                    extraFilter,
                    overwriteFilter
                )
                commit('setScoreData', { data })
            }

            const scoreData = state.scoreData[
                state.cacheKeyScoreData
            ] as unknown as IScoreData
            commit(
                'setScoreType',
                scoreData?.scoreType ?? rootGetters.activeMetrics[0]
            )
            if (
                (!scoreData.score && scoreData.score !== 0) ||
                scoreData.graph.datasets.every((v) => v.score === null)
            ) {
                commit('setEmptyScoreData', true)
            } else {
                commit('setScoreGraph', scoreData.graph ?? [])
                commit('setScoreValue', Number(scoreData.score ?? 0))
                commit('setScoreTarget', Number(scoreData.graph.target ?? 0))
                commit('setEmptyScoreData', false)
            }
        } catch (e) {
            commit('setScoredataError', true)
        }

        commit('setScoredataLoading', false)
    },
    setShowScoreGraph({ commit }, showScoreGraph: boolean) {
        commit('setShowScoreGraph', showScoreGraph)
    },
    async resetData({ commit }) {
        commit('resetState')
    },
    async loadOfflineDataForScoreData({ rootGetters }) {
        await getScoreData(
            '30', // Home screen only need 30 days data
            rootGetters.mobileQuestionType,
            null,
            false
        )
    },
}

const mutations: MutationTree<ScoreDataState> = {
    resetState(state) {
        Object.assign(state, getDefaultState())
    },
    setScoreData(state, { data }) {
        state.scoreData[state.cacheKeyScoreData] = data
    },
    setScoredataLoading(state, scoredataLoading: boolean) {
        state.scoredataLoading = scoredataLoading
    },
    setScoreGraph(state, scoreGraph: Array<any>) {
        state.scoreGraph = scoreGraph
    },
    setScoreType(state, scoreType) {
        state.scoreType = scoreType
    },
    setScoreValue(state, scoreValue) {
        state.scoreValue = scoreValue
    },
    setScoreTarget(state, scoreTarget) {
        state.scoreTarget = scoreTarget
    },
    setScoredataError(state, scoredataError: boolean) {
        pendoTrackEvent('mobile_scoredata_request_failed')
        state.scoredataError = scoredataError
    },
    setEmptyScoreData(state, emptyScoreData: boolean) {
        pendoTrackEvent('mobile_scoredata_empty')
        state.emptyScoreData = emptyScoreData
    },
    setShowScoreGraph(state, showScoreGraph: boolean) {
        state.showScoreGraph = showScoreGraph
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
