import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_ChatResponseScreen = _resolveComponent("ChatResponseScreen")!

  return (_openBlock(), _createBlock(_component_ChatResponseScreen, null, {
    default: _withCtx(() => [
      _createVNode(_component_RouterView, null, {
        default: _withCtx(({ Component }) => [
          _createVNode(_Transition, { name: _ctx.transitionName }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component), { class: "child-view" }))
            ], undefined, true),
            _: 2
          }, 1032, ["name"])
        ]),
        _: 1
      })
    ], undefined, true),
    _: 1
  }))
}