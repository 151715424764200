<template>
    <div class="insights-details-container">
        <WebViewHeader>
            <template #left-button>
                <div class="close" @click="close">
                    <img
                        src="@/components/WebView/icons/Close.svg"
                        alt="Close"
                    />
                </div>
            </template>
        </WebViewHeader>

        <div class="content">
            <BaseCard class="nps-summary">
                <InsightsNPSInfo />
                <div class="break" />
                <InsightsScorecardInfo />
            </BaseCard>

            <DashboardResponses
                :is-side-bar="false"
                :show-badges="true"
                :external-filters="hierarchyFilters"
                class="responses"
                apply-scorecard-template-filter
                :time-unit="timeRange.timeUnit"
                :time-value="timeRange.timeValue.toString(10)"
            />
        </div>
    </div>
</template>

<script lang="ts">
import BaseCard from '@/components/BaseCard.vue'
import WebViewHeader from '@/components/WebView/WebViewHeader.vue'
import { IFilterRule } from '@/entities'
import { ITimeRange } from '@/entities/insights'
import InsightsNPSInfo from '@/mobile/src/components/Insights/InsightsNPSInfo.vue'
import InsightsScorecardInfo from '@/mobile/src/components/Insights/InsightsScorecardInfo.vue'
import DashboardResponses from '@/pages/dashboard/components/DashboardResponses.vue'
import { Component, Vue } from 'vue-facing-decorator'
import { Action, Getter } from 'vuex-facing-decorator'
import { useRouter } from 'vue-router'

@Component({
    components: {
        BaseCard,
        DashboardResponses,
        InsightsNPSInfo,
        InsightsScorecardInfo,
        WebViewHeader,
    },
})
export default class InsightsDetailsModal extends Vue {
    @Getter
    readonly hierarchyFilters?: Array<IFilterRule>

    @Getter
    readonly filterActive?: IFilterRule

    @Getter
    readonly timeRange?: ITimeRange

    @Action
    getFilters

    private router = useRouter()

    mounted() {
        if (!this.filterActive) {
            this.getFilters()
        }
    }

    close() {
        this.router.back()
    }
}
</script>

<style scoped lang="less">
@import '~@/styles/palette.less';

.insights-details-container {
    z-index: 111;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: @white;
    display: flex;
    flex-direction: column;
    padding: env(safe-area-inset-top) env(safe-area-inset-right)
        env(safe-area-inset-bottom) env(safe-area-inset-left);

    .content {
        padding: 12px 10px;
        overflow-y: scroll;
        flex: 1;
        background: @greyLight;
    }

    .responses {
        margin-top: 10px;
    }
}

.break {
    border-bottom: 0.5px solid @blueLight;
    height: 0.5px;
    width: 100%;
}
</style>
