<template>
    <div class="ts-modal-header-c">
        <div class="ts-modal-header-title">{{ title }}</div>
        <div @click="$emit('close')">
            <img
                class="dropdown-modal-close"
                src="@/assets/img/Icons/Grey/X.svg"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component({
    emits: ['close'],
})
export default class FilterModalMobileHeader extends Vue {
    @Prop({ type: String }) public title!: string
}
</script>

<style scoped lang="less">
@import '~@/styles/palette.less';

.ts-modal-header-c {
    position: fixed;
    background: inherit;
    border-radius: 8px 8px 0 0;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    width: inherit;
}

.ts-modal-header-c img {
    margin: 10px 10px 0 0;
    pointer-events: auto;
}

.ts-modal-header-title {
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 12px;
    color: @grey40;
}
</style>
