import client from '@/api/client'
import {
    IScorecardSetting,
    IScorecardUserRole,
} from '@/pages/appstore/components/Scorecard/scorecard-settings-entity'

export async function getScorecardSettingsList() {
    const response = await client.get<IScorecardSetting[]>(
        `scorecard-settings/`
    )

    return response.data
}

export async function getScorecardUserRoles() {
    const response = await client.get<IScorecardUserRole[]>(
        `scorecard-settings/user-roles`
    )

    return response.data
}

export async function saveScorecardSetting(
    currentScorecard: IScorecardSetting
) {
    const response = await client.post<IScorecardSetting>(
        `scorecard-settings/`,
        currentScorecard
    )

    return response.data
}

export async function getScorecardTopicsInUse(scorecardId: number) {
    const response = await client.get<IScorecardSetting>(
        `scorecard-settings/topics/${scorecardId}/inuse`
    )

    return response.data
}
