<template>
    <div class="engagement-nps-question">
        <div class="top">
            <div class="icon">
                <img :src="icon" />
            </div>
            <div class="question">
                {{ question.question }}
            </div>
            <div class="nps-container">
                <div
                    v-for="(i, npsValue) in npsCountOptions"
                    :key="npsValue"
                    class="nps-option"
                >
                    <div
                        :class="{ selected: question.answer === npsValue }"
                        class="nps-option-value"
                        @click="
                            $emit('update', { key: 'answer', value: npsValue })
                        "
                    >
                        {{ npsValue }}
                    </div>
                </div>
            </div>
            <div class="nps-labels">
                <div>0 - Not likely</div>
                <div>10 - Very likely</div>
            </div>
            <div v-if="question.answer !== null" class="comment">
                <FlexiMultilineTextInput
                    :value="question.comment ? question.comment : ''"
                    :blur-on-new-line="true"
                    placeholder="Want to share more…"
                    @input="$emit('update', { key: 'comment', value: $event })"
                />
            </div>
        </div>
        <div class="bottom">
            <BaseButton full :disabled="!question.answer" @click="submitAnswer">
                Done
            </BaseButton>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { EngagementQuestion } from '@/entities/engagement'
import FlexiMultilineTextInput from '@/components/FlexiMultilineTextInput.vue'
import BaseButton from '@/components/Rain/Buttons/BaseButton.vue'
import { EngagementQuestionIcon } from '@/mobile/src/components/engagement/utils'

@Component({
    components: {
        BaseButton,
        FlexiMultilineTextInput,
    },
    emits: ['update', 'done'],
})
export default class EngagementNPSQuestion extends Vue {
    @Prop({ type: Object, required: true })
    public question!: EngagementQuestion

    public npsCountOptions = 11

    public submitAnswer() {
        this.$emit('done', this.question)
    }

    get icon() {
        return EngagementQuestionIcon[this.question.theme]
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/variables';
@import '~@/styles/typography';

.engagement-nps-question {
    text-align: center;
    flex-direction: column;
    flex: 1;
    display: flex;

    .top {
        flex: 1;
        margin-top: 30px;
    }

    .bottom {
        flex: 0;
        padding: 16px 16px 32px 16px;
    }

    .icon img {
        height: 80px;
        margin-bottom: 20px;
    }

    .question {
        .heading();
        margin: 0 12px;
    }

    .nps-container {
        margin-top: 22px;
        font-size: 40px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .nps-option {
            width: 15%;
            padding: 6px 0;
            max-width: 60px;

            .nps-option-value {
                background: @greyLight3;
                color: @grey40;
                text-align: center;
                font-size: 24px;
                font-weight: 500;
                border-radius: 50%;
                line-height: 40px;
                height: 40px;
                width: 40px;
                margin: auto;
            }

            .selected {
                background: @purple;
                color: @white;
            }
        }
    }

    .nps-labels {
        display: flex;
        justify-content: space-between;
        margin-top: 14px;
        .label();
        color: @grey40;
        font-weight: 400;

        div {
            min-width: 100px;
            padding: 0 6%;
        }
    }

    .comment {
        margin-top: 10px;
        padding: 0 16px;
        height: 20vh;
        max-height: 200px;
    }

    @media (min-height: 710px) {
        .comment {
            margin: 40px 0;
        }
    }

    @media (max-height: 630px) {
        .comment {
            height: 12vh;
        }
    }
}
</style>
