import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { createUploadLink } from 'apollo-upload-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { LooseObject } from '@/pages/appstore/entities/app'

let queryClient: LooseObject
export function getApolloQueryClient(url: string) {
    if (queryClient) {
        return queryClient
    }

    queryClient = new ApolloClient({
        link: createHttpLink({ uri: url }),
        cache: new InMemoryCache(),
        connectToDevTools: true,
    })
    return queryClient
}

let mutationClient: LooseObject
export function getApolloMutationClient(url: string) {
    if (mutationClient) {
        return mutationClient
    }

    mutationClient = new ApolloClient({
        link: createUploadLink({
            uri: url,
            credentials: 'include',
        }),
        cache: new InMemoryCache(),
        connectToDevTools: true,
    })
    return mutationClient
}
