import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a6777174"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "horizontal-scroll-container metrics-container" }
const _hoisted_2 = { class: "scrolling-item" }
const _hoisted_3 = { class: "scrolling-item" }
const _hoisted_4 = { class: "scrolling-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InsightsMetric = _resolveComponent("InsightsMetric")!
  const _component_InsightsGraph = _resolveComponent("InsightsGraph")!
  const _component_SkeletonDiv = _resolveComponent("SkeletonDiv")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _createVNode(_component_InsightsMetric, {
            active: _ctx.coachingMetricType === 'trending_topic',
            delta: _ctx.getScoreChange(_ctx.trendingTopic),
            title: _ctx.getInsightTitle(_ctx.trendingTopic),
            skeleton: _ctx.loading,
            subtitle: "Trending Topic",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click', 'trending_topic')))
          }, null, 8, ["active", "delta", "title", "skeleton"])
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createVNode(_component_InsightsMetric, {
            active: _ctx.coachingMetricType === 'top_performer',
            delta: _ctx.getScoreChange(_ctx.topPerformer),
            title: _ctx.getInsightTitle(_ctx.topPerformer),
            skeleton: _ctx.loading,
            subtitle: "Top Performer",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('click', 'top_performer')))
          }, null, 8, ["active", "delta", "title", "skeleton"])
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", null, [
          _createVNode(_component_InsightsMetric, {
            active: _ctx.coachingMetricType === 'biggest_change',
            delta: _ctx.getScoreChange(_ctx.biggestChange),
            title: _ctx.getInsightTitle(_ctx.biggestChange),
            subtitle: _ctx.getBiggestChangeSubtitle(_ctx.biggestChange),
            skeleton: _ctx.loading,
            "change-icon": "chevron",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('click', 'biggest_change')))
          }, null, 8, ["active", "delta", "title", "subtitle", "skeleton"])
        ])
      ])
    ]),
    (_ctx.withGraph && _ctx.graphData && _ctx.graphData.length)
      ? (_openBlock(), _createBlock(_component_InsightsGraph, {
          key: 0,
          title: _ctx.graphTitle,
          data: _ctx.graphData,
          color: _ctx.$palette.insightsCoaching,
          height: _ctx.graphHeight
        }, null, 8, ["title", "data", "color", "height"]))
      : (_ctx.withGraph && _ctx.loading)
        ? (_openBlock(), _createBlock(_component_SkeletonDiv, {
            key: 1,
            height: `${_ctx.graphHeight}px`
          }, null, 8, ["height"]))
        : _createCommentVNode("", true)
  ]))
}