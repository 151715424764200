import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-da4a7672"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "full-screen-popup-container" }
const _hoisted_2 = { class: "full-screen-popup" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DarkPurpleGradientBgContainer = _resolveComponent("DarkPurpleGradientBgContainer")!

  return (_openBlock(), _createBlock(_component_DarkPurpleGradientBgContainer, { class: "full-screen-popup-bg" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ])
      ])
    ], undefined, true),
    _: 3
  }))
}