export const toggleHeaderDropdownEvent = 'toggleHeaderDropdown'
export const updateFilterCountEvent = 'updateFilterCount'

export function toggleHeader(emitter) {
    emitter?.emit(toggleHeaderDropdownEvent)
}

export function updateFilterCount(emitter, count) {
    emitter?.emit(updateFilterCountEvent, count)
}
