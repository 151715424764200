<template>
    <div class="mfa-page">
        <FontAwesomeIcon class="circle-icon" :icon="faKey" />
        <Typography variant="h4" component="h4" class="title-container">Verify Recovery Code</Typography>
        <p>Please enter one of your recovery codes</p>
        <input
            v-model="recoveryCode"
            type="text"
            class="mfa-code-input"
            placeholder="Your 12 digit code"
            maxlength="13"
            @keyup.enter="() => $refs.verifyBtn.onClick()"
        />

        <BasePillButton
            ref="verifyBtn"
            class="verify-button"
            :disabled="loading || !enteredCode"
            @click="verifyRecoveryCode"
        >
            <SimpleSpinner
                v-if="loading"
                size="tiny"
                line-bg-color="transparent"
                :line-fg-color="$palette.brandPurple"
            />
            <template v-else>SUBMIT</template>
        </BasePillButton>
        <p class="white">
            Back to
            <RouterLink
                class="find-login white"
                :to="{
                    name: 'mfa-login',
                    params: {
                        domain: domain,
                        email: credentials.email,
                        password: credentials.password,
                    },
                }"
                replace
                >use MFA code
            </RouterLink>
        </p>
    </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref, onMounted, inject } from 'vue'
import { faKey } from 'fontawesome/free-solid-svg-icons'
import Typography from '@/components/Rain/Typography/Typography.vue'
import BasePillButton from '@/components/BasePillButton.vue'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { useRoute, useRouter } from 'vue-router'
import { loginAndRedirect } from '@/mobile/src/services/auth'
import SimpleSpinner from '@/components/SimpleSpinner.vue'
import { LoginMainPkey, LoginMainProvided } from '@/mobile/src/types/auth'

const recoveryCode = ref('')
const loading = ref(false)
const domain = ref('')

const route = useRoute()
const router = useRouter()

const enteredCode = computed(() => {
    return !!recoveryCode.value
})

const { credentials } = inject(LoginMainPkey) as LoginMainProvided

onBeforeMount(() => {
    domain.value = String(route.query.domain)
})

onMounted(async () => {
    if (
        !domain.value ||
        !credentials.value.email ||
        !credentials.value.password
    ) {
        await router.replace({
            name: 'findlogin',
            replace: true,
        })

        return
    }
})

async function verifyRecoveryCode() {
    loading.value = true
    await loginAndRedirect(
        domain.value,
        credentials.value.email,
        credentials.value.password,
        '',
        false,
        '',
        recoveryCode.value
    )
    loading.value = false
    recoveryCode.value = ''
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/variables.less';
@import '~@/mobile/src/styles/mfa.less';

.verify-button {
    margin-top: @gutterSpacing-xl;
}
</style>
