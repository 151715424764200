<template>
    <div class="container">
        <div class="login-form">
            <p class="white">Domain: {{ domain }}</p>
            <template v-if="!disableEmailLogin">
                <div class="inputs">
                    <Input
                        title="Email"
                        type="email"
                        placeholder="Email"
                        :regex="/.+@.+\..+/"
                        :required="true"
                        :err-msg="'Valid email is required'"
                        :model-value="email"
                        @update:model-value="onUpdateEmail"
                    />
                    <div @keyup.enter="() => $refs.btnLogin.onClick()">
                        <Input
                            title="Password"
                            placeholder="Password"
                            type="password"
                            :required="true"
                            :err-msg="'Invalid Password'"
                            :model-value="password"
                            @update:model-value="onUpdatePassword"
                        />
                    </div>
                </div>

                <TransparentButton
                    ref="btnLogin"
                    class="btn-login"
                    text="Login"
                    :click="onLogin"
                />
            </template>

            <p class="white">
                Back to
                <RouterLink
                    class="find-login white"
                    :to="{ name: 'findlogin' }"
                    replace
                    >request a magic link
                </RouterLink>
            </p>

            <div v-if="hasOtherOptions" class="or">
                <div class="line"></div>
                <div class="or-text">or</div>
                <div class="line"></div>
            </div>
            <TransparentButton
                v-if="samlSsoUrl"
                class="saml-login"
                :text="samlSsoName"
                :click="onSamlSSO"
            />

            <AppVersion class="app-v" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { inject, computed, ref } from 'vue'
import Input from '@/components/Input.vue'
import TransparentButton from '@/mobile/src/components/TransparentButton.vue'
import { loginAndRedirect, postLoginActions } from '@/mobile/src/services/auth'
import AppVersion from '@/mobile/src/components/AppVersion.vue'
import { samlLogin } from '@/mobile/src/utils/capacitor'
import { LoginMainPkey, LoginMainProvided } from '@/mobile/src/types/auth'

const email = ref('')
const password = ref('')

interface Props {
    domain: string
    disableEmailLogin: number
    samlSsoName?: string
    samlSsoUrl?: string
}

const props = withDefaults(defineProps<Props>(), {
    domain: '',
    disableEmailLogin: 0,
    samlSsoName: '',
    samlSsoUrl: '',
})

const hasOtherOptions = computed(() => {
    return !!props.samlSsoUrl
})

const { persistCredentials } = inject(LoginMainPkey) as LoginMainProvided

function onUpdateEmail(newEmail: string) {
    email.value = newEmail
}

function onUpdatePassword(newPass: string) {
    password.value = newPass
}

async function onLogin() {
    if (!/^\w+$/.test(props.domain)) {
        alert('Invalid domain')
        return
    }
    persistCredentials({ email: email.value, password: password.value })
    await loginAndRedirect(props.domain, email.value, password.value)
}

async function onSamlSSO() {
    if (!props.samlSsoUrl) {
        return
    }
    const response = await samlLogin(props.samlSsoUrl, props.domain)
    if (!response) {
        return
    }
    const urlParams = new URLSearchParams(response)
    await postLoginActions(props.domain, urlParams.get('token'))
}
</script>
<style lang="less" scoped>
@import '@/styles/palette';

.login-form {
    .white {
        color: white;
    }

    .btn-login {
        margin-top: 15px;
        width: 100%;
    }

    .or {
        display: flex;
        text-align: center;
        color: @white;
        font-weight: 400;
        padding-bottom: 14px;
        justify-content: space-between;

        .line {
            width: 40vw;
            border-bottom: 2px solid @white;
            position: relative;
            top: -8px;
        }

        .or-text {
            padding: 0 8px;
        }
    }

    .saml-login {
        width: 100%;
    }

    .app-v {
        margin-top: 10px;
    }
}
</style>
