import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a31f166a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "check-in-history-container" }
const _hoisted_2 = { class: "questions" }
const _hoisted_3 = { class: "date" }
const _hoisted_4 = { class: "question-text" }
const _hoisted_5 = { class: "answer" }
const _hoisted_6 = {
  key: 0,
  class: "answer-icons"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "answer-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasicSelect = _resolveComponent("BasicSelect")!
  const _component_Typography = _resolveComponent("Typography")!
  const _component_MessageBubble = _resolveComponent("MessageBubble")!
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BasicSelect, {
      value: _ctx.selectedTheme,
      options: _ctx.allThemes,
      label: "CHECK INS",
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectedTheme = $event))
    }, null, 8, ["value", "options"]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredEngagementQuestions, (engagementQuestion) => {
        return (_openBlock(), _createElementBlock("div", {
          key: engagementQuestion.answer_id,
          class: "question"
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Typography, { variant: "caption" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.formatDate(engagementQuestion.date)), 1)
              ], undefined, true),
              _: 2
            }, 1024)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_MessageBubble, { direction: "left" }, {
              default: _withCtx(() => [
                _createVNode(_component_Typography, { variant: "body2" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(engagementQuestion.question), 1)
                  ], undefined, true),
                  _: 2
                }, 1024)
              ], undefined, true),
              _: 2
            }, 1024)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_MessageBubble, {
              direction: "right",
              "bg-color": _ctx.getBackgroundColor(engagementQuestion)
            }, {
              default: _withCtx(() => [
                (
                                engagementQuestion.answer &&
                                _ctx.isFiveStarType(engagementQuestion.question_type)
                            )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      (engagementQuestion.variation === 'faces')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                            _createVNode(_component_FontAwesomeIcon, {
                              icon: 
                                        _ctx.variationFiveStarIcon(
                                            engagementQuestion.answer
                                        )
                                    ,
                              class: "font-awesome purple-icon"
                            }, null, 8, ["icon"])
                          ]))
                        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.getIconKeys(
                                    engagementQuestion
                                ), (i, idx) => {
                            return (_openBlock(), _createElementBlock("div", { key: idx }, [
                              (_openBlock(), _createBlock(_component_FontAwesomeIcon, {
                                key: i,
                                icon: _ctx.fontAwesomeSources['faStar'],
                                class: "font-awesome purple-icon"
                              }, null, 8, ["icon"]))
                            ]))
                          }), 128))
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_Typography, { variant: "body2" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.getAnswerText(engagementQuestion)), 1)
                    ], undefined, true),
                    _: 2
                  }, 1024)
                ])
              ], undefined, true),
              _: 2
            }, 1032, ["bg-color"])
          ])
        ]))
      }), 128))
    ])
  ]))
}