<template>
    <Modal :show="show" :flex="true" vanilla>
        <DesktopRecipientPicker
            :single="single"
            :allow-empty="allowEmpty"
            :selected="selected"
            :recipients="recipients"
            :title="title"
            :can-pick-virtual-users="canPickVirtualUsers"
            @close="onCancelRecipients"
            @confirm="onConfirmRecipients"
        />
    </Modal>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import Modal from '@/components/Modal.vue'
import { IRecipient } from '@/entities/notices'
import 'driver.js/dist/driver.min.css'
import RecipientPicker from '@/components/Notices/RecipientPicker.vue'
import DesktopRecipientPicker from './DesktopRecipientPicker.vue'

@Component({
    components: {
        DesktopRecipientPicker,
        Modal,
        RecipientPicker,
    },
    emits: ['close', 'confirm'],
})
export default class RecipientPickerModal extends Vue {
    @Prop({ type: Boolean }) public show!: boolean
    @Prop({ type: Boolean }) public single!: boolean // only allow single selection
    @Prop({ type: Boolean, default: true }) public allowEmpty!: boolean // allow empty selection
    @Prop({ type: Array }) public selected!: IRecipient[]
    @Prop({ type: Array }) public recipients!: IRecipient[]
    @Prop({ type: String }) public title!: string
    @Prop({ type: Boolean, default: false }) public canPickVirtualUsers!: string

    private onCancelRecipients() {
        this.$emit('close')
    }

    private onConfirmRecipients(selectedRecipients: IRecipient[]) {
        this.$emit('confirm', selectedRecipients)
    }
}
</script>

<style lang="less">
@import '~@/styles/palette.less';
@import '~@/styles/rain/variables.less';

.chat-input-container .chat-input.notice-editor .ql-editor {
    height: 100%;
}

.chat-input-container .chat-input.notice-editor .ql-editor.ql-blank::before {
    color: @neutral;
    font-style: normal;
    font-size: @fontSize-base;
    line-height: @lineHeight-base;
}
</style>
