import client, { baseClient } from '@/api/client'
import { IFilter, IFilterOption } from '@/entities'
import { timeOptions } from '@/utils/time'
import { format, addMonths } from 'date-fns'

export async function getFilters() {
    return await client.get<IFilter[]>(`/filter/`)
}

export async function getRuleOptions() {
    return await client.get<IFilterOption[]>(`/filter/options`)
}

export async function getRuleOptionsWithSuboptions() {
    return await client.get<IFilterOption[]>(`/filter/options/suboptions`)
}

export async function getRuleSubOptions(
    option: string,
    search = '',
    usePagination = false,
    offset = 1
) {
    // We didn't set pagination as default for this method because it is being called in four different places, and we want to avoid altering the current behavior in those contexts.
    return await client.get<IFilterOption[]>(
        `/filter/options/${option}?search=${search}&pagination=${usePagination}&page=${offset}`
    )
}

export async function getSchedulerruleFieldOptions(data: FormData) {
    return client.post(`/elastic/searchfield`, data)
}

export function getTimeOptions() {
    // Custom time option is initialized to the last month
    const today = format(new Date(), 'DD-MM-YYYY')
    const monthAgo = format(addMonths(new Date(), -1), 'DD-MM-YYYY')

    return timeOptions.slice().map((optionSet) =>
        optionSet.map((option) =>
            option.time_unit === 'custom'
                ? {
                      ...option,
                      time_value: `${monthAgo}_${today}`,
                  }
                : option
        )
    )
}

export async function createFilter(filter: IFilter) {
    return await client.post<{ id: number }>(`/filter/`, filter)
}

/**
 * To 'load' a filter, patch the default filter with the values of the filter you're loading
 */
export async function patchFilter(patch: Partial<IFilter>) {
    return await client.put(`/filter/${patch.id}`, patch)
}

export async function deleteFilter(filterId: number) {
    return await client.delete(`/filter/${filterId}`)
}

// get from `app.asknice.ly` / `app.asknicely.site
export async function getBenchmarkIndustriesList(baseUrl) {
    return await baseClient.get(baseUrl + '/benchmark/industries')
}
