<template>
    <div class="text-input">
        <form class="text-container" @submit="handleSubmit">
            <AtTa
                :members="members"
                :filter-match="memberSort"
                class="atwho-wrap"
                name-key="name"
                @insert="onInsertMention"
            >
                <template #item="scope">
                    <span class="name" v-text="scope.item.name"></span>
                    <span class="description" v-text="scope.item.email"></span>
                </template>
                <textarea
                    type="text"
                    class="text-field"
                    rows="1"
                    :placeholder="placeholder"
                    :value="value"
                    @input="setValue"
                    @keydown="onInputKeydown"
                />
            </AtTa>
            <button class="text-submit">{{ submitLabel }}</button>
        </form>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import AtTa from 'vue-at/dist/vue-at-textarea'
import { IAtMember } from '@/entities/chat'
import { pendoTrackEvent } from '@/utils/pendo'

/*
    This is supposed to sit fixed at the bottom of the screen,
    you'd place it in a WebViewScreen footer slot

    Emits:

    'submit' string
    'input' string
*/

@Component({
    components: {
        AtTa,
    },
    emits: ['mention', 'input', 'submit'],
})
export default class TextInput extends Vue {
    @Prop({ type: String, default: '' }) public placeholder!: string
    @Prop({ type: String, default: 'Send' }) public submitLabel!: string
    @Prop({ type: Array, default: [] }) public members!: IAtMember[]

    public value = ''
    public mentions: IAtMember[] = []

    //TODO: Clean this up entire file up, seems to be taken from MentionableEditor.
    public memberSort(name, chunk) {
        return name.toLowerCase().indexOf(chunk.toLowerCase()) === 0
    }

    public onInputKeydown(evt) {
        if (evt.key === '@') {
            pendoTrackEvent('mention_started')
        }
    }

    public onInsertMention(recipient: IAtMember) {
        const mentions = this.members.filter((mention) =>
            this.value.includes(`@${mention.name} `)
        )
        this.mentions = mentions
        pendoTrackEvent('mention_added')
        this.$emit('mention', mentions)
    }

    public setValue(event: Event) {
        const value = String((event.target as HTMLTextAreaElement).value)
        this.value = value
        this.$emit('input', value)

        // re-generate since some members could be removed
        this.mentions = this.members.filter((mention) =>
            this.value.includes(`@${mention.name} `)
        )
        this.$emit('mention', this.mentions)
    }

    public handleSubmit(event: Event) {
        event.preventDefault()
        this.$emit('submit', String(this.value), this.mentions)
        this.value = ''
    }
}
</script>

<style scoped lang="less">
.text-input {
    padding: 12px;
}
.text-container {
    box-sizing: border-box;
    border: 1px solid #e0e7ff;
    border-radius: 20px;
    display: flex;
    background: #fff;
}
.text-field {
    padding: 10px 18px;
    font-size: 16px;
    line-height: 19px;
    border-color: transparent;
    border-radius: 20px;
    flex: 1;
    color: #2f384c;
    &:focus {
        outline: none;
    }
    &::placeholder {
        color: #b7babf;
        font-size: 16px;
        line-height: 19px;
    }
}
.text-submit {
    width: 80px;
    color: #aebdfa;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    background: #fff;
    border-radius: 20px;
    border-color: transparent;
    outline: 0 none !important;
    cursor: pointer;
}
.atwho-wrap {
    flex: 1;
    display: flex;

    .description {
        font-size: 11px;
        color: #8097b1;
    }

    .name {
        flex: 1;
        padding-right: 6px;
    }
}

textarea,
textarea::placeholder {
    font-family: Roboto, sans-serif;
}
</style>
