import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0adddc28"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "name", "disabled", "checked"]
const _hoisted_2 = {
  key: 0,
  class: "content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", {
    class: "container",
    onMouseover: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hovered = true)),
    onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.hovered = false))
  }, [
    _createElementVNode("input", {
      id: _ctx.id,
      type: "checkbox",
      name: _ctx.name,
      disabled: _ctx.disabled,
      checked: _ctx.checked,
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args)))
    }, null, 40, _hoisted_1),
    _createElementVNode("span", {
      class: _normalizeClass(['checkmark', _ctx.colourVariant, { hovered: _ctx.hovered, circle: _ctx.circle }])
    }, null, 2),
    (_ctx.$slots.default)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ], 32))
}