<template>
    <div :class="['item', { square }]" @click="$emit('click', menuItem)">
        <div v-if="menuItem.icon" class="icon">
            <img :src="menuItem.icon" :alt="menuItem.name" />
        </div>
        <div class="name">
            <slot>{{ menuItem.name }}</slot>
        </div>
        <div class="arrow">
            <img src="@/assets/img/Icons/right-arrow.svg" alt="right" />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

export interface MenuItem {
    icon: string
    name: string
    id: string
}

@Component({
    emits: ['click'],
})
export default class MenuRow extends Vue {
    @Prop({ type: Object }) menuItem!: MenuItem
    // default show radius
    @Prop({ type: Boolean, default: false }) square!: boolean
}
</script>

<style lang="less" scoped>
@import '../../../styles/palette.less';

.item {
    display: flex;
    background: white;
    border-radius: 8px;
    align-items: center;
    padding: 15px;
    border: 1px solid @blueLight;

    &.square {
        border-radius: 0;
        border: none;
        border-bottom: 1px solid @blueLight;
    }

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
        width: 24px;
        height: 24px;
    }

    .name {
        flex: 1;
    }

    .arrow {
        display: flex;
        align-items: center;

        img {
            height: 15px;
        }
    }
}
</style>
