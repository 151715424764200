import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { requestFeature } from '@/api/beta'
import { LoadState } from '@/common/interfaces/loadstate'

const namespaced = true

export interface FeatureRequestState {
    visible: boolean
    feature: string | null
    loading: LoadState
}

const state: FeatureRequestState = {
    visible: false,
    feature: null,
    loading: LoadState.UNSET,
}

const actions: ActionTree<FeatureRequestState, any> = {
    showFeatureRequest({ commit }, feature: string) {
        commit('show', feature)
    },
    hideFeatureRequest({ commit }) {
        commit('hide')
    },
    async fulfilFeatureRequest({ commit, state: { loading, feature } }) {
        if (loading === LoadState.LOADING || !feature) {
            return
        }
        commit('setLoadState', LoadState.LOADING)
        try {
            await requestFeature(feature)

            commit('setLoadState', LoadState.LOADED)
        } catch (e) {
            commit('setLoadState', LoadState.ERROR)
        }
        commit('hide')
    },
}

const mutations: MutationTree<FeatureRequestState> = {
    show(state, feature: string) {
        state.visible = true
        state.feature = feature
    },
    hide(state) {
        state.visible = false
    },
    setLoadState(state, loading: LoadState) {
        state.loading = loading
    },
}

const getters: GetterTree<FeatureRequestState, any> = {
    visible: ({ visible }) => visible,
    feature: ({ feature }) => feature,
    loading: ({ loading }) => loading,
}
export default {
    namespaced,
    state,
    getters,
    mutations,
    actions,
}
