import { withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-862cae46"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = ["type", "autocomplete", "autocorrect", "autocapitalize", "spellcheck", "value", "disabled"]
const _hoisted_3 = {
  key: 0,
  class: "label label-spread"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      class: _normalizeClass([_ctx.variant, { value: _ctx.value, error: _ctx.error }]),
      type: _ctx.type,
      autocomplete: _ctx.autocomplete,
      autocorrect: _ctx.autocomplete,
      autocapitalize: _ctx.autocapitalize,
      spellcheck: _ctx.spellcheck,
      value: _ctx.value,
      disabled: !!_ctx.disabled,
      onFocus: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('focus', $event))),
      onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('blur', $event))),
      onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('input', $event.target.value))),
      onKeyup: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.$emit('enterpressed')), ["enter"])),
      onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('change', $event.target.value)))
    }, null, 42, _hoisted_2),
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          (_ctx.fontAwesomeSources[_ctx.fontAwesome])
            ? (_openBlock(), _createBlock(_component_FontAwesomeIcon, {
                key: 0,
                icon: _ctx.fontAwesomeSources[_ctx.fontAwesome],
                class: "font-awesome"
              }, null, 8, ["icon"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}