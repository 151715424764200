import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx, normalizeClass as _normalizeClass, TransitionGroup as _TransitionGroup, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-df8f2788"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "expand-graph-icons"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EmptyPageHome = _resolveComponent("EmptyPageHome")!
  const _component_ErrorPage = _resolveComponent("ErrorPage")!
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_NPSDisplay = _resolveComponent("NPSDisplay")!
  const _component_NPSTargetGraph = _resolveComponent("NPSTargetGraph")!
  const _component_PillButton = _resolveComponent("PillButton")!
  const _component_FeatureContainer = _resolveComponent("FeatureContainer")!
  const _component_LoadingCard = _resolveComponent("LoadingCard")!

  return (_openBlock(), _createBlock(_TransitionGroup, {
    name: "loading",
    tag: "div"
  }, {
    default: _withCtx(() => [
      (_ctx.emptyScoreData)
        ? (_openBlock(), _createBlock(_component_EmptyPageHome, {
            key: "empty-page",
            bordered: ""
          }))
        : (_ctx.scoredataError)
          ? (_openBlock(), _createBlock(_component_ErrorPage, {
              key: "error-page",
              bordered: ""
            }))
          : (!_ctx.scoredataLoading)
            ? (_openBlock(), _createBlock(_component_FeatureContainer, {
                key: "nps-features",
                class: _normalizeClass(['nps-features', { 'no-report': !_ctx.showReportCardButton }])
              }, {
                default: _withCtx(() => [
                  (!_ctx.showReportCardButton)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        (_ctx.showScoreGraph)
                          ? (_openBlock(), _createBlock(_component_FontAwesomeIcon, {
                              key: 0,
                              class: "chevron",
                              icon: _ctx.faChevronUp,
                              onClick: _ctx.expandGraph
                            }, null, 8, ["icon", "onClick"]))
                          : (_openBlock(), _createBlock(_component_FontAwesomeIcon, {
                              key: 1,
                              class: "chevron",
                              icon: _ctx.faChevronDown,
                              onClick: _ctx.expandGraph
                            }, null, 8, ["icon", "onClick"]))
                      ]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_NPSDisplay, {
                    score: _ctx.scoreValue,
                    "score-type": _ctx.scoreType,
                    "score-days": _ctx.scoreDays
                  }, null, 8, ["score", "score-type", "score-days"]),
                  _createVNode(_Transition, { name: "graph" }, {
                    default: _withCtx(() => [
                      (_ctx.showScoreGraph || _ctx.showReportCardButton)
                        ? (_openBlock(), _createBlock(_component_NPSTargetGraph, {
                            key: 0,
                            class: "home-target-graph",
                            target: _ctx.scoreTarget,
                            "score-data": _ctx.scoreGraph,
                            "score-type": _ctx.scoreType,
                            tall: !_ctx.showReportCardButton,
                            onGoalClicked: _ctx.onGoalClick
                          }, null, 8, ["target", "score-data", "score-type", "tall", "onGoalClicked"]))
                        : _createCommentVNode("", true)
                    ], undefined, true),
                    _: 1
                  }),
                  (_ctx.showReportCardButton && _ctx.hasNetworkConnection)
                    ? (_openBlock(), _createBlock(_component_PillButton, {
                        key: 1,
                        disabled: !_ctx.hasLastMonthsReportCard,
                        label: "Report Card",
                        class: "is-v4",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onReportCardClick()))
                      }, null, 8, ["disabled"]))
                    : _createCommentVNode("", true)
                ], undefined, true),
                _: 1
              }, 8, ["class"]))
            : (_openBlock(), _createBlock(_component_LoadingCard, {
                key: "graph",
                variant: "graph"
              }))
    ], undefined, true),
    _: 1
  }))
}