import { ConvUIQuestion } from '@/pages/dashboard/entities/dash'

export const NPS_TYPE: { [key: string]: Rating } = {
    DETRACTOR: 'detractor',
    PROMOTER: 'promoter',
    PASSIVE: 'passive',
    NONE: 'none',
}

export const FIVESCORE_TYPE: { [key: string]: string } = {
    DETRACTOR: 'negative',
    PROMOTER: 'positive',
    PASSIVE: 'neutral',
    NONE: 'none',
}

export type Rating = 'detractor' | 'passive' | 'promoter' | 'none'

export function answerToNPS(answer: number): Rating {
    if (answer < 0) {
        return NPS_TYPE.NONE
    }
    if (answer <= 6) {
        return NPS_TYPE.DETRACTOR
    }
    if (answer <= 8) {
        return NPS_TYPE.PASSIVE
    }
    return NPS_TYPE.PROMOTER
}

export function answerToFiveScore(answer: number): Rating {
    if (answer < 0) {
        return NPS_TYPE.NONE
    }
    if (answer <= 2) {
        return NPS_TYPE.DETRACTOR
    }
    if (answer <= 3) {
        return NPS_TYPE.PASSIVE
    }
    return NPS_TYPE.PROMOTER
}

export function getQuestionType(param: string, inputType: string): string {
    const regex = /(ces|csat|nps|5star|initial_score)(_c)?$/
    if (regex.test(param)) {
        const found = param.match(regex)
        return (found && found[1]) || 'nps'
    }

    if (inputType === 'ReceiveOption') {
        return 'multi'
    }

    return 'freetext'
}

export function getQuestionAnswerLabel(key: string, value): string {
    const type = getQuestionType(key, 'ReceiveOption')
    switch (type) {
        case 'ces':
            return (
                {
                    1: 'Very Hard',
                    2: 'Hard',
                    3: 'Neutral',
                    4: 'Easy',
                    5: 'Very Easy',
                }[value] || value
            )
        case 'csat':
            return (
                {
                    1: 'Very Unsatisfied',
                    2: 'Unsatisfied',
                    3: 'Neutral',
                    4: 'Satisfied',
                    5: 'Very Satisfied',
                }[value] || value
            )
        case '5star':
            return (
                {
                    1: '1 Star',
                    2: '2 Star',
                    3: '3 Star',
                    4: '4 Star',
                    5: '5 Star',
                }[value] || value
            )
        default:
            return value
    }
}

export function getQuestionClass(value, type, isFiveScore = 0): string {
    if (type === 'initial_score') {
        type = isFiveScore === 1 ? '5star' : 'nps'
    }
    const num = Number(value)
    if (
        (type === 'nps' && num > 8) ||
        (['csat', 'ces', '5star'].includes(type) && num > 3)
    ) {
        return 'success'
    } else if (
        (type === 'nps' && num > 6) ||
        (['csat', 'ces', '5star'].includes(type) && num > 2)
    ) {
        return 'warning'
    } else if (['csat', 'ces', '5star', 'nps'].includes(type)) {
        return 'danger'
    }

    return 'default'
}

// Replaces the reselect score box with the score
// e.g., "Thanks for <ReselectScore v-bind:score=4..." -> "Thanks for 4"
export function getQuestionText(text) {
    const temp = document.createElement('div')
    temp.innerHTML = text

    if (temp.getElementsByTagName('ReselectScore').length === 0) {
        return text
    }

    const scoreTags = temp.getElementsByTagName('ReselectScore')

    for (const tag of scoreTags) {
        const items = tag.attributes
        const scoreItem = items.getNamedItem('v-bind:score')

        if (!scoreItem) {
            return text
        }

        tag.innerHTML = scoreItem.value
    }

    return temp.innerText
}

export function getTextEllipsis(text, len) {
    if (!text) {
        return text
    }

    if (text.length < len) {
        return text
    }

    const parts = text.slice(0, len).split(' ')
    return parts.slice(0, parts.length - 1).join(' ') + '...'
}

export function calculateNPS(
    promoters: number,
    passives: number,
    detractors: number
): number {
    const total = promoters + passives + detractors
    if (!total) {
        return 0
    }

    return parseFloat((((promoters - detractors) / total) * 100).toFixed(1))
}
