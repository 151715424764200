import { TimeOption } from '@/entities/time'

export interface FilterRuleOperator {
    name: string
    operator: string
}

export interface IFilterTimeLabel {
    label: string
    from?: string
    to?: string
}

export interface IFilterRule {
    column: string
    operator: string
    value: string[]
    source?: string
}

export interface IFilterQuery {
    name?: string
    time_unit: TimeOption
    time_value: string
    filter_rules?: IFilterRule[]
}

export interface IFilterTime {
    label: string
    time_unit: string
    time_value: string
}

export interface IFilter extends IFilterQuery {
    id?: number // will be null for locked filters (which aren't stored as dashboardfilters)
    user_id?: number
    parent?: number
    is_shared?: boolean
    is_default?: boolean
    locked?: boolean
    question_type: string | null
}

export interface IFilterOption {
    label: string
    value: string
    common?: boolean
    count?: string | number
    options?: IFilterOption[] // any sub options
}

export interface ITrendLines {
    id: number
    user_id: number
    lines: IFilterRule[]
}

export interface IIndustry {
    id: number
    industry: string
    low?: number
    high?: number
    median?: number
    has_sufficient_data: boolean
}

export interface QuestionTypeOption {
    name: string
    label: string
}

// A single filter option in a set, e.g. "24 hours"
export interface FilterOptionV4 {
    label: string
    value: string // time_value for time filters.
}

export interface MultiFilterOptionV4 {
    label: string
    value: string[]
    filterType: string // filter type that current filter belongs to.  time_unit for time filters
    filterCategory: string // the category of the filter, eg: custom, general
}

// A group of filter options, e.g. "Time Filter"
export interface FilterSetV4 {
    filterOptions: FilterOptionV4[] | any[]
    selected: FilterOptionV4[]
}

export function getQuestionTypeOptions() {
    return [
        {
            name: 'nps',
            label: 'NPS',
        },
        {
            name: 'csat',
            label: 'CSAT',
        },
        {
            name: 'fivestar',
            label: 'Five Star',
        },
    ]
}

export function getQuestionTypeLabel(questionType) {
    const selected = getQuestionTypeOptions().find(
        (o) => o.name === questionType
    )
    return selected ? selected.label : 'NPS'
}

export function getGeneralMultiSelectFilters() {
    return ['rating', 'messaged', 'tag', 'case', 'case_assigned_user_id']
}

export const nonNumericFilters = ['tag', 'case_assigned_user_id']

export enum FilterDateFormat {
    dayMonthYear = 'dd-MM-yyyy',
}
