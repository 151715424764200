<template>
    <div>
        <TopicResponsesBreakdown
            v-if="topicData"
            class="topic-responses-breakdown"
            :question-type="insightsCoaching.question_type"
            :data="topicData"
            :total-topic-response-count="totalTopicResponseCount"
        />
        <div class="coaching-topic-team-member-container">
            <div
                class="coaching-topic-team-member-sort"
                @click="showTeamSortModal = true"
            >
                <span>Team</span>
                <span class="coaching-topic-team-member-selected-option">{{
                    selectedSortOption
                }}</span>
                <FontAwesomeIcon
                    class="coaching-topic-team-member-chevron"
                    :icon="faChevronDown"
                />
            </div>
            <div class="coaching-topic-team-member-tiles">
                <TeamMemberTileWeb
                    v-for="(teamMemberData, name) in teamMembers"
                    :key="name"
                    :name="name"
                    :data="teamMemberData"
                    :topic="topic"
                    :badge="topicData.badge"
                    class="coaching-topic-team-member-tile"
                />
                <div
                    v-if="Object.keys(teamMembers).length % 2"
                    class="coaching-topic-team-member-tile"
                ></div>
            </div>
        </div>
        <div class="coaching-topic-sort-modal">
            <Modal
                :top="25"
                :show="showTeamSortModal"
                allow-click-out
                allow-escape
                :has-close="false"
                title="View Results By:"
                size="small"
                :close="() => (showTeamSortModal = false)"
                visible-if-overflow
            >
                <div v-for="(sortOption, idx) in sortOptions" :key="idx">
                    <div
                        class="coaching-topic-sort-option"
                        @click="selectSortOption(sortOption)"
                    >
                        <div
                            :class="{
                                active: sortOption === selectedSortOption,
                            }"
                        >
                            {{ sortOption }}
                        </div>
                        <div>
                            <FontAwesomeIcon
                                v-if="sortOption === selectedSortOption"
                                :icon="faCheck"
                                class="active"
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import TopicResponsesBreakdown from '@/pages/insights/TopicResponsesBreakdown.vue'
import { CoachingInsightItem, CoachingInsights } from '@/entities/insights'
import TeamMemberTileWeb from '@/pages/insights/TeamMemberTileWeb.vue'
import { Action, Getter } from 'vuex-facing-decorator'
import Modal from '@/components/Modal.vue'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faCheck, faChevronDown } from 'fontawesome/free-solid-svg-icons'

@Component({
    components: {
        FontAwesomeIcon,
        TeamMemberTileWeb,
        TopicResponsesBreakdown,
        Modal,
    },
})
export default class TopicDetail extends Vue {
    private topicData: CoachingInsightItem | null = null
    private totalTopicResponseCount: number | null = null
    private showTeamSortModal = false
    private sortOptions = ['High Performers', 'Low Performers']
    private selectedSortOption: 'High Performers' | 'Low Performers' =
        'High Performers'

    readonly faCheck = faCheck
    readonly faChevronDown = faChevronDown

    @Prop({ type: String, default: null })
    private topic!: string

    @Getter
    readonly insightsCoaching!: CoachingInsights

    @Action
    loadInsightsCoaching

    //TODO: Cleanup, taken directly from CoachingTopic.vue
    get teamMembers() {
        let teamMembers = {}
        const sortedTeamMemberData = {}

        if (this.topicData) {
            for (const frontLineStaffColumnsKey in this.insightsCoaching
                .front_line_staff_columns) {
                const frontLineStaffColumn =
                    this.insightsCoaching.front_line_staff_columns[
                        frontLineStaffColumnsKey
                    ]
                teamMembers = {
                    ...teamMembers,
                    ...this.topicData[frontLineStaffColumn],
                }
            }

            // sort the data
            const teamMembersSortedKeys = Object.keys(teamMembers).sort(
                (a, b) => {
                    if (this.selectedSortOption === 'High Performers') {
                        return (
                            teamMembers[b]['score_current'] -
                            teamMembers[a]['score_current']
                        )
                    } else if (this.selectedSortOption === 'Low Performers') {
                        return (
                            teamMembers[a]['score_current'] -
                            teamMembers[b]['score_current']
                        )
                    } else {
                        return 1
                    }
                }
            )

            teamMembersSortedKeys.forEach((field) => {
                sortedTeamMemberData[field] = teamMembers[field]
            })
        }

        return sortedTeamMemberData
    }

    async mounted() {
        if (this.insightsCoaching === undefined) {
            await this.loadInsightsCoaching()
        }

        await this.setTopicData()
    }

    //Taken from CoachingTopic.vue
    public async setTopicData() {
        if (
            !this.insightsCoaching.data.scorecard ||
            this.insightsCoaching.data.scorecard[this.topic] === undefined
        ) {
            // TODO: Unsure what to do here
            return
        }

        this.topicData = this.insightsCoaching.data.scorecard[this.topic]

        let count = 0
        for (const coachingMetricDataKey in this.insightsCoaching.data
            .scorecard) {
            count +=
                this.insightsCoaching.data.scorecard[coachingMetricDataKey]
                    .total_count
        }
        this.totalTopicResponseCount = count
    }

    public selectSortOption(option) {
        this.selectedSortOption = option
        this.showTeamSortModal = false
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/variables';
@import '~@/styles/typography';
@import '~@/styles/rain/colour';

.coaching-topic-team-member-tiles {
    display: flex;
    flex-wrap: wrap;

    .coaching-topic-team-member-tile {
        flex: 1 0 42%;
        margin: 5px;
        padding: 16px;
        cursor: pointer;
    }
}

.coaching-topic-team-member-sort {
    .title();
}

.topic-responses-breakdown {
    padding-bottom: 10px;
}

.coaching-topic-team-member-selected-option,
.coaching-topic-team-member-chevron {
    color: @primaryBlue;
    cursor: pointer;
}

.coaching-topic-sort-option {
    display: flex;
    justify-content: space-between;
    margin-bottom: @gutterSpacing-md;

    .active {
        color: @primaryBlue;
    }
}
</style>
