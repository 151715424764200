<template>
    <PageHeader key="header" class="header" :go-back="logout">
        <template #back>
            <FontAwesomeIcon class="back" size="lg" :icon="faChevronLeft" />
        </template>
    </PageHeader>
</template>

<script setup lang="ts">
import PageHeader from '@/mobile/src/components/appV4/headers/PageHeader.vue'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faChevronLeft } from 'fontawesome/free-solid-svg-icons'
import router from '@/mobile/src/router'

async function logout() {
    router.replace({
        name: 'findlogin',
        replace: true,
    })
}
</script>
