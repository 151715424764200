import client from '@/api/client'
import {
    ChatTemplate,
    ChatMessage,
    ChatWorkflow,
    ChatReply,
    ChatTags,
    VISIBILITY_EVERYONE,
    VISIBILITY_PRIVATE,
    VISIBILITY_LOCATION,
} from '@/entities/chat'
import { Case } from '@/entities/case'
import { Dict } from '@/entities/object'
import { notifyError } from '@/utils/appstore'
import { OfflineCache } from '@/mobile/src/utils/offlinecache'

const offlineCache = new OfflineCache('chat')

export async function getChatResponses(questionId: number) {
    return await offlineCache.cachedGet<Dict<string>>(
        client,
        `/chat/responses/${questionId}`
    )
}

export async function getFeedbackDetail(questionId: number) {
    return await offlineCache.cachedGet<Dict<string>>(
        client,
        `/feedback/detail/${questionId}`
    )
}

export async function getTemplates(questionId: number) {
    return await client.get<Dict<ChatTemplate>>(
        `/ajax/chat/${questionId}/templates`,
        { baseURL: '/' }
    )
}

export async function getSavedTemplateLocations(chatTemplateId: number) {
    return await client.get<Array<number>>(
        `/ajax/chat/template/locations/${chatTemplateId}`,
        {
            baseURL: '/',
        }
    )
}

export async function getTags() {
    return await client.get<ChatTags>(`/api/v2/tags`, { baseURL: '/' })
}

export async function getWorkflows() {
    return await client.get<Dict<ChatWorkflow>>(`/ajax/chat/workflows`, {
        baseURL: '/',
    })
}

export async function getMessages(questionId: number) {
    return await client.get<ChatMessage[]>(
        `/ajax/chat/${questionId}/messages`,
        { baseURL: '/' }
    )
}

export async function getFeedbackDetailMessages(questionId: number) {
    return await client.get<ChatMessage[]>(
        `/ajax/feedback/detail/${questionId}/messages`,
        { baseURL: '/' }
    )
}

export async function getCustomData(questionId: number) {
    return await client.get<Dict<string>>(
        `/ajax/chat/${questionId}/custom-data`,
        { baseURL: '/' }
    )
}

export async function getCase(questionId: number) {
    return await client.get<Case>(`/chat/${questionId}/case`)
}

export async function getQuestionWatcher(questionId: number) {
    return await client.get<boolean>(`/chat/${questionId}/question-watcher`)
}

export async function addNote(
    questionId: number,
    note: string,
    markAsUrgentTask = false
) {
    const formData = new FormData()
    formData.append('message', note)

    if (markAsUrgentTask) {
        formData.append('urgent', JSON.stringify(markAsUrgentTask))
    }

    try {
        return await client.post(
            `/api/v2/response/${questionId}/note`,
            formData,
            { baseURL: '/' }
        )
    } catch (error: any) {
        if (error.response.status === 404) {
            notifyError(
                'You currently don’t have permission to carry out this action. Please see your AskNicely Administrator.'
            )
        } else {
            notifyError(
                `Something went wrong when saving your note: ${error.message}`
            )
        }
    }
}

export async function deleteNote(noteId: number) {
    return await client.delete(`/api/v2/note/${noteId}`, { baseURL: '/' })
}

export async function deleteReply(chatId: number) {
    return await client.post(
        `/api/v2/chat/cancel/${chatId}`,
        {},
        { baseURL: '/' }
    )
}

export async function addReply(questionId: number, reply: ChatReply) {
    const formData = new FormData()
    if (reply.caseClosedOption) {
        formData.append('caseClosedOption', reply.caseClosedOption)
    }
    if (reply.closecase) {
        formData.append('closecase', reply.closecase)
    }
    if (reply.template_id) {
        formData.append('template_id', reply.template_id)
    }
    formData.append('from_name', reply.from_name)
    formData.append('message', reply.message)
    formData.append('publishReview', reply.publishReview)
    formData.append('subject', reply.subject)
    return await client.post(`/api/v2/response/${questionId}/chat`, formData, {
        baseURL: '/',
    })
}

export async function updateEmoji(type, relid, emoji) {
    if (type !== 'note' && type !== 'question') {
        type = 'chat'
    }
    const formData = new FormData()
    formData.append('emoji', emoji)
    return await client.post(`/ajax/chat/reaction/${type}/${relid}`, formData, {
        baseURL: '/',
    })
}

export async function getMentionUsers() {
    return await client.get(`/api/v2/userlist`, { baseURL: '/' })
}

export async function getPermissions() {
    return await client.get(`/chat/permissions`, { baseURL: '/ajax' })
}

export async function getCaseOptions() {
    return await client.get(`/chat/case-options`, { baseURL: '/ajax' })
}

export async function toggleCaseClosed(
    questionId,
    boolCaseClosed,
    caseClosedOption
) {
    const formData = new FormData()
    if (caseClosedOption) {
        formData.append('caseClosedOption', caseClosedOption)
    }

    return await client.post(
        `/ajax/chat/togglecaseclosed/${questionId}/${boolCaseClosed}`,
        formData,
        { baseURL: '/' }
    )
}

export async function addWatcher(questionId, userId) {
    const formData = new FormData()
    formData.append('userId', userId)

    return await client.post(`/ajax/watcher/add/${questionId}`, formData, {
        baseURL: '/',
    })
}

export async function deleteWatcher(questionId) {
    return await client.post(`/ajax/watcher/delete/${questionId}`, null, {
        baseURL: '/',
    })
}

export async function runWorkflow(automationId, questionId, force) {
    return await client.post(
        `/workflows/${automationId}/run/${questionId}?force=${force ? 1 : 0}`,
        {},
        { baseURL: '/' }
    )
}

export async function requestLock(questionId) {
    return await client.get(`/chat/requestlock/${questionId}`, { baseURL: '/' })
}

export async function saveTemplate(questionId, template: ChatTemplate) {
    const formData = new FormData()
    formData.append('name', template.name)
    formData.append('subject', template.subject)
    formData.append('private', '' + template.private ?? VISIBILITY_EVERYONE)
    formData.append('message', template.text)

    if (template.visibilityLocations) {
        const visibilityLocationsString = JSON.stringify(
            template.visibilityLocations
        )
        formData.append('visibilityLocations', visibilityLocationsString)
    }

    if (template.calendly && template.calendly.link) {
        formData.append('calendly_link', template.calendly.link)
    }
    if (template.calendly && template.calendly.text) {
        formData.append('calendly_text', template.calendly.text)
    }

    return await client.post(
        `/chat/${questionId}/template/save/${template.id}?ajax=1`,
        formData,
        {
            baseURL: '/',
            maxRedirects: 0,
        }
    )
}

export async function addTemplate(questionId, template: ChatTemplate) {
    const formData = new FormData()
    formData.append('name', template.name)
    formData.append('subject', template.subject)
    formData.append('private', String(template.private) ?? '0')
    formData.append('text', template.text)
    if (template.calendly && template.calendly.link) {
        formData.append('calendly_link', template.calendly.link)
    }
    if (template.calendly && template.calendly.text) {
        formData.append('calendly_text', template.calendly.text)
    }
    if (template.visibilityLocations) {
        const visibilityLocationsString = JSON.stringify(
            template.visibilityLocations
        )
        formData.append('visibilityLocations', visibilityLocationsString)
    }
    return await client.post(
        `/chat/${questionId}/template/add?ajax=1`,
        formData,
        { baseURL: '/' }
    )
}

export async function deleteTemplate(questionId, templateId) {
    return await client.post(
        `/chat/${questionId}/template/delete/${templateId}?ajax=1`,
        {},
        { baseURL: '/' }
    )
}

export async function getConvQuestions(questionId) {
    return await client.get(`/ajax/conversation-spec/questions/${questionId}`, {
        baseURL: '/',
    })
}

export async function unlockChatForwarding(questionId: number) {
    return await client.get(`/ajax/chat/${questionId}/unlock-forwarding`, {
        baseURL: '/',
    })
}

export async function getTeamMembers(questionId: number) {
    return await client.get(`/chat/team-members-list/${questionId}`, {
        baseURL: '/ajax',
    })
}

export async function updateCaseAssignment(questionId, userId) {
    const formData = new FormData()
    formData.append('userId', userId == '0' ? 'unassigned' : userId)

    return await client.post(`/chat/case-assignment/${questionId}`, formData, {
        baseURL: '/ajax',
    })
}
