export function getCDNUrl(relativePath?: string): string {
    const baseURL =
        process.env.NODE_ENV === 'production'
            ? 'https://cdn.asknice.ly'
            : 'https://cdn.asknicely.site'

    if (relativePath?.startsWith('http')) {
        return relativePath
    }
    return baseURL + relativePath
}
