import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { pendoTrackEvent } from '@/utils/pendo'
import {
    getMomentDetail,
    getMoments,
    loadAvailableRecipients,
    pollMoments,
    pollUnseenMomentCount,
    sendMoment,
    uploadImage,
} from '@/api/moments'
import {
    Moment,
    MomentDetail,
    MomentMessageHashtag,
    MomentMessageRecipient,
    SendMomentRequest,
} from '@/entities/moment'
import { MessageTypeLabel, ModalSource } from '@/mobile/src/types/messageTypes'
import { getPhotoForMomentsComposer } from '@/mobile/src/utils/capacitor'
import {
    cleanMomentText,
    processNoteHashtags,
    processNoteMentions,
} from '@/utils/moments'
import { showToast } from '@/mobile/src/utils/toastbus'
import { ToastColor, ToastIcon } from '@/mobile/src/types/toasts'
import { ChatTemplate } from '@/entities'
import { getTags, getTemplates } from '@/api/chat'
import { getHashtagWhitelist } from '@/api/hashtagWhitelist'
import { Emitter } from 'mitt'

export interface MomentsState {
    moments: Moment[]
    polledMoments: Moment[]
    emptyMomentsData: boolean
    momentsDataError: boolean
    fetchTimestamp: number
    offset: number
    isLoading: boolean
    reachedEnd: boolean
    unseenCount: number
    unseenCountPollingTimer: number | null
    composer: {
        type: string
        availableTypes: string[] // If empty array, no restriction on available types
        source: string
        availableRecipients: MomentMessageRecipient[]
        selectedRecipients: MomentMessageRecipient[]
        selectedMentions: MomentMessageRecipient[]
        availableHashtags: MomentMessageHashtag[]
        selectedHashtags: MomentMessageHashtag[]
        typePickerModalOpen: boolean
        loadingRecipients: boolean
        recipientSearchInput: string
        mentionSearchInput: string
        mentionText: string
        hashtagSearchInput: string
        loadingHashtags: boolean
        showToField: boolean
        currentMentionedUserIds: Number[]
        uploadedImageFile: File | null
        uploadedImageRemoteFileName: string | null
        imageUploading: boolean
        subject: string
        customerReplyTemplates: ChatTemplate[]
        selectedReplyTemplate: ChatTemplate | null
        sendToContact: boolean
    }
    momentDetails: MomentDetail | null
    loadingMomentDetails: boolean
    errorMomentDetails: boolean
}

const defaultComposerType = 'message'

const defaultComposer = {
    type: defaultComposerType,
    availableTypes: [],
    source: ModalSource.MomentsScreen,
    availableRecipients: [],
    selectedRecipients: [],
    selectedMentions: [],
    availableHashtags: [],
    selectedHashtags: [],
    typePickerModalOpen: false,
    loadingRecipients: false,
    recipientSearchInput: '',
    mentionSearchInput: '',
    mentionText: '',
    hashtagSearchInput: '',
    loadingHashtags: false,
    showToField: true,
    currentMentionedUserIds: [],
    uploadedImageFile: null,
    uploadedImageRemoteFileName: null,
    imageUploading: false,
    subject: '',
    customerReplyTemplates: [],
    selectedReplyTemplate: null,
    sendToContact: false,
}

export const state: MomentsState = {
    moments: [],
    polledMoments: [],
    emptyMomentsData: false,
    momentsDataError: false,
    fetchTimestamp: 0,
    offset: 0,
    isLoading: true,
    reachedEnd: false,
    unseenCount: 0,
    unseenCountPollingTimer: null,
    composer: defaultComposer,
    momentDetails: null,
    loadingMomentDetails: true,
    errorMomentDetails: false,
}

const getters: GetterTree<MomentsState, any> = {
    getMoments(state) {
        return state.moments
    },
    getPolledMoments(state) {
        return state.polledMoments
    },
    momentsDataError({ momentsDataError }) {
        return momentsDataError
    },
    emptyMomentsData({ emptyMomentsData }) {
        return emptyMomentsData
    },
    hasMoments(state) {
        return state.fetchTimestamp > 0
    },
    isLoading: (state) => state.isLoading,

    // Composer related getters
    composerType({ composer }): string {
        return composer.type
    },
    typePickerModalOpen({ composer }): boolean {
        return composer.typePickerModalOpen
    },
    availableComposerTypes({ composer }): string[] {
        return composer.availableTypes
    },
    getComposerSource({ composer }): string {
        return composer.source
    },
    availableComposerRecipients({ composer }): MomentMessageRecipient[] {
        return composer.availableRecipients
    },
    availableComposerHashtags({ composer }): MomentMessageHashtag[] {
        return composer.availableHashtags
    },
    loadingRecipients({ composer }): boolean {
        return composer.loadingRecipients
    },
    selectedRecipients({ composer }): MomentMessageRecipient[] {
        return composer.selectedRecipients
    },
    selectedMentions({ composer }): MomentMessageRecipient[] {
        return composer.selectedMentions
    },
    selectedHashtags({ composer }): MomentMessageHashtag[] {
        return composer.selectedHashtags
    },
    recipientSearchInput({ composer }): string {
        return composer.recipientSearchInput
    },
    mentionSearchInput({ composer }): string {
        return composer.mentionSearchInput
    },
    hashtagSearchInput({ composer }): string {
        return composer.hashtagSearchInput
    },
    loadingHashtags({ composer }): boolean {
        return composer.loadingHashtags
    },
    mentionText({ composer }): string {
        return composer.mentionText
    },
    subject({ composer }): string {
        return composer.subject
    },
    showToField({ composer }): boolean {
        return composer.showToField
    },
    currentMentionedUserIds({ composer }): Number[] {
        return composer.currentMentionedUserIds
    },
    composerUploadedImageFile({ composer }): File | null {
        return composer.uploadedImageFile
    },
    composerUploadedImageRemoteFileName({ composer }): string | null {
        return composer.uploadedImageRemoteFileName
    },
    composerImageUploading({ composer }): boolean {
        return composer.imageUploading
    },
    customerReplyTemplates({ composer }): ChatTemplate[] {
        return composer.customerReplyTemplates
    },
    selectedReplyTemplate({ composer }): ChatTemplate | null {
        return composer.selectedReplyTemplate
    },
    composerSendToContact({ composer }): boolean {
        return composer.sendToContact
    },

    hasReachedEnd: (state) => state.reachedEnd,
    getUnseenMomentCount: (state) => state.unseenCount,
    momentDetails: (state) => state.momentDetails,
    loadingMomentDetails: (state) => state.loadingMomentDetails,
    errorMomentDetails: (state) => state.errorMomentDetails,
}

const actions: ActionTree<MomentsState, any> = {
    async fetchMoments({ commit, state }, reset = false) {
        commit('setLoading', true)

        try {
            if (reset) {
                commit('setOffset', 0)
                commit('setMomentsDataError', false)
                commit('resetPolledMoments')
                commit('resetMoments')
            }

            const { data: momentResponse } = await getMoments(state.offset)
            commit('setFetchTimestamp', momentResponse.timestamp)
            if (momentResponse.data && momentResponse.data.length > 0) {
                commit('addMoments', momentResponse.data)
                commit(
                    'setOffset',
                    Number(momentResponse.offset) +
                        (momentResponse.data ? momentResponse.data?.length : 0)
                )
            } else {
                commit('setReachedEnd', true)
            }
        } catch (e) {
            commit('setMomentsDataError', true)
        } finally {
            commit('setEmptyMomentsData', state.moments.length < 1)
            commit('setLoading', false)
        }
    },
    async pollMoments({ commit, dispatch, state, rootGetters }) {
        if (!rootGetters.hasNetworkConnection) {
            return
        }
        try {
            let resetPolledMoments = false
            if (state.moments.length <= 0) {
                resetPolledMoments = true
            }
            const { data: momentResponse } = await pollMoments(
                state.fetchTimestamp
            )

            if (momentResponse.data) {
                if ('moments' in momentResponse.data) {
                    commit('addPolledMoments', momentResponse.data.moments) // To get the count of polled moments
                    commit('addMoments', momentResponse.data.moments)
                }

                if ('unseenCount' in momentResponse.data) {
                    dispatch(
                        'updateUnseenMomentCount',
                        momentResponse.data.unseenCount
                    )
                }
            }

            commit('setFetchTimestamp', momentResponse.timestamp)

            if (state.moments.length >= 1) {
                commit('setEmptyMomentsData', false)
            }

            if (resetPolledMoments) {
                commit('resetPolledMoments')
            }
        } catch (error) {
            // do nothing
        }
    },
    async sendMoment(
        { commit, dispatch, state },
        { momentid, mentionText, chatMessages }
    ) {
        // Check if any mentions have been deleted and clean up the list
        const composer = state.composer
        let selectedMentions = composer.selectedMentions
        const desyncedSelectedMentions = selectedMentions.filter(
            (mention) =>
                !composer.currentMentionedUserIds.includes(mention.user_id)
        )
        if (desyncedSelectedMentions.length > 0) {
            selectedMentions = composer.selectedMentions.filter((mention) =>
                composer.currentMentionedUserIds.includes(mention.user_id)
            )
            await dispatch('setSelectedMentions', selectedMentions)
        }

        let message = mentionText
        if (composer.type === 'internalNote') {
            const cleanText = cleanMomentText(mentionText)
            message = processNoteMentions(
                cleanText,
                composer.availableRecipients
            )
            message = processNoteHashtags(message)
        }

        const requestData: SendMomentRequest = {
            recipients: composer.selectedRecipients,
            mentions: selectedMentions, // @todo: add mentions list from message
            type: MessageTypeLabel[composer.type],
            message: message,
            source: composer.source,
            image: composer.uploadedImageRemoteFileName,
        }

        if (composer.type === 'emailReply' && composer.subject) {
            requestData.subject = composer.subject
        }

        // Attach question only if sent from Feedback Detail Screen
        if (composer.source === ModalSource.FeedbackDetailScreen) {
            const chatQuestion = chatMessages[0]
            if (chatQuestion) {
                requestData.question = chatQuestion
            }
        }

        if (momentid) {
            requestData.moment = {
                id: Number(momentid),
            }
        }

        const { data } = await sendMoment(requestData)

        if (data.success && data.moment && !data.moment[0].question) {
            commit('addMoments', data.moment)
        }

        return data
    },
    resetPolledMoments({ commit }) {
        commit('resetPolledMoments')
    },
    async loadComposerRecipients({ commit }, userId) {
        commit('setLoadingRecipients', true)

        await loadAvailableRecipients()
            .then((request) => {
                // Remove current user from recipients list
                commit(
                    'setAvailableComposerRecipients',
                    request.data.filter(
                        (item) => item.user_id !== Number(userId)
                    )
                )
            })
            .catch(() => {
                commit('setAvailableComposerRecipients', [])
            })

        commit('setLoadingRecipients', false)
    },
    async loadComposerHashtags({ commit }, hasWhitelist) {
        commit('setLoadingHashtags', true)

        if (hasWhitelist) {
            await getHashtagWhitelist()
                .then((request) => {
                    const formattedTags = request.data.data.map((str) => {
                        return { name: str }
                    })
                    // Remove current user from recipients list
                    commit('setAvailableComposerHashtags', formattedTags)
                })
                .catch(() => {
                    commit('setAvailableComposerHashtags', [])
                })
        } else {
            await getTags()
                .then((request) => {
                    const formattedTags = request.data.tags.map((str) => {
                        return { name: str }
                    })
                    // Remove current user from recipients list
                    commit('setAvailableComposerHashtags', formattedTags)
                })
                .catch(() => {
                    commit('setAvailableComposerHashtags', [])
                })
        }
        commit('setLoadingHashtags', false)
    },
    async loadMomentDetails({ commit }, momentId) {
        commit('setLoadingMomentDetails', true)
        commit('setErrorMomentDetails', false)
        await getMomentDetail(momentId)
            .then((request) => {
                commit('setMomentDetails', request.data.data)
            })
            .catch(() => {
                commit('setErrorMomentDetails', true)
            })
        commit('setLoadingMomentDetails', false)
    },
    setComposerType({ commit }, type: string) {
        commit('setComposerType', type)
        pendoTrackEvent(`message_composer_type_selected`, { type: type })
    },
    prefillEmailReplyRecipient({ dispatch, rootGetters }) {
        let customerName, customerEmail
        const chatQuestion = rootGetters.chatMessages[0]
        if (chatQuestion) {
            customerName =
                chatQuestion.person?.name ?? chatQuestion.person?.email ?? ''
            customerEmail = chatQuestion.person?.email ?? ''
        }

        const customerRecipient: MomentMessageRecipient = {
            type: 'user',
            name: customerName,
            description: customerEmail,
            user_id: 9999,
            field: '',
            value: '',
            role: '',
            sender: false,
            avatar: '',
            usernames: ['defaultUser'],
            selectableForSending: true,
        }
        dispatch('setSelectedRecipients', [customerRecipient])
    },
    initAvailableComposerTypes({ commit, dispatch, rootGetters }, modalSource) {
        const canSendNotices =
            rootGetters.permissions.includes('ROLE_SEND_NOTICES')
        const canSendEmailReply =
            rootGetters.permissions.includes('ROLE_USER_BASICS') &&
            !Number(rootGetters.chatCustomData.anom)
        const canAddNote = rootGetters.permissions.includes('ROLE_ADD_NOTES')

        let availableTypes: string[] = []
        switch (modalSource) {
            case ModalSource.FeedbackDetailScreen:
                availableTypes = ['message']

                if (canSendEmailReply) {
                    availableTypes.push('emailReply')
                }
                if (canAddNote) {
                    availableTypes.push('internalNote')
                }
                if (canSendNotices) {
                    availableTypes.push('shoutout', 'coaching')
                }
                break

            case ModalSource.MomentsDetailScreen:
                availableTypes = ['message']

                if (canSendNotices) {
                    availableTypes.push('shoutout', 'coaching')
                }
                break

            case ModalSource.StaffProfileScreen:
            case ModalSource.MomentsScreen:
                availableTypes = ['message']

                if (canSendNotices) {
                    availableTypes.push('shoutout', 'coaching')
                }
                break
        }

        commit('setAvailableComposerTypes', availableTypes)

        // Initialise to the first available type if current type not available
        const firstAvailableType = availableTypes[0]
        if (
            !availableTypes.includes(state.composer.type) &&
            firstAvailableType
        ) {
            commit('setComposerType', firstAvailableType)
            if (firstAvailableType === 'emailReply') {
                dispatch('prefillEmailReplyRecipient')
            }
        }
    },
    setTypePickerModalOpen(
        { commit, state, dispatch },
        payload: { isOpen: boolean; modalSource?: string }
    ) {
        const { isOpen, modalSource } = payload
        dispatch('initAvailableComposerTypes', modalSource)

        commit('setTypePickerModalOpen', isOpen)
        pendoTrackEvent(
            `message_composer_type_picker_${isOpen ? 'opened' : 'closed'}`
        )
    },
    setAvailableComposerTypes({ commit }, availableTypes: string[]) {
        commit('setAvailableComposerTypes', availableTypes)
    },
    setComposerSource({ commit }, source: string) {
        commit('setComposerSource', source)
    },
    setSelectedRecipients({ commit }, recipients: MomentMessageRecipient[]) {
        commit('setSelectedRecipients', recipients)
    },
    setRecipientSearchInput({ commit }, searchInput: string) {
        commit('setRecipientSearchInput', searchInput)
    },
    selectNewRecipient({ commit }, recipient: MomentMessageRecipient) {
        pendoTrackEvent('message_composer_recipient_selected')
        commit('selectNewRecipient', recipient)
    },
    setMentionSearchInput({ commit }, searchInput: string) {
        commit('setMentionSearchInput', searchInput)
    },
    selectNewMention({ commit }, recipient: MomentMessageRecipient) {
        pendoTrackEvent('message_composer_mention_selected')
        commit('selectNewMention', recipient)
    },
    setHashtagSearchInput({ commit }, searchInput: string) {
        commit('setHashtagSearchInput', searchInput)
    },
    removeLastSelectedRecipient({ commit }) {
        commit('removeLastSelectedRecipient')
    },
    setMentionText({ commit }, mentionText: string) {
        commit('setMentionText', mentionText)
    },
    setSubject({ commit }, subject: string) {
        commit('setSubject', subject)
    },
    setShowToField({ commit }, showToField: boolean) {
        commit('setShowToField', showToField)
    },
    setSelectedMentions(
        { commit },
        newSelectedMentions: MomentMessageRecipient[]
    ) {
        commit('setSelectedMentions', newSelectedMentions)
    },
    setSelectedHashtags(
        { commit },
        newSelectedHashtags: MomentMessageHashtag[]
    ) {
        commit('setSelectedHashtags', newSelectedHashtags)
    },
    setCurrentMentionedUserIds({ commit }, userIds: Number[]) {
        commit('setCurrentMentionedUserIds', userIds)
    },
    resetComposer({ commit }) {
        commit('resetComposer')
    },
    updateUnseenMomentCount({ commit, dispatch }, unseenCount: number) {
        commit('setUnseenMomentCount', unseenCount)
        dispatch(
            'setTabUnreadCount',
            { name: 'moments', count: unseenCount },
            { root: true }
        )
    },
    addUnseenMomentCount({ dispatch, state }, addCount: number) {
        const unseenCount = state.unseenCount + addCount
        dispatch('updateUnseenMomentCount', unseenCount)
    },
    subUnseenMomentCount({ dispatch, state }, subCount: number) {
        const unseenCount = state.unseenCount - subCount
        dispatch('updateUnseenMomentCount', unseenCount)
    },
    stopUnseenCountPolling({ commit, state }) {
        if (state.unseenCountPollingTimer) {
            clearInterval(state.unseenCountPollingTimer)
            commit('setUnseenCountPollingTimer', null)
        }
    },
    async startUnseenCountPolling({ commit, dispatch, state, rootGetters }) {
        if (state.unseenCountPollingTimer) {
            clearInterval(state.unseenCountPollingTimer)
            commit('setUnseenCountPollingTimer', null)
        }

        const updateMomentCount = async () => {
            if (!rootGetters.hasNetworkConnection) {
                return
            }
            const { data: unseenResponse } = await pollUnseenMomentCount()
            dispatch('updateUnseenMomentCount', unseenResponse.count)
        }

        await updateMomentCount()
        const pollingTimer = setInterval(updateMomentCount, 30000)
        commit('setUnseenCountPollingTimer', pollingTimer)
    },
    setErrorMomentDetails({ commit }, error: boolean) {
        commit('setErrorMomentDetails', error)
    },
    setLoadingMomentDetails({ commit }, loading: boolean) {
        commit('setLoadingMomentDetails', loading)
    },
    setMomentDetails({ commit }, momentDetails: MomentDetail) {
        commit('setMomentDetails', momentDetails)
    },
    setMomentSeen({ commit, dispatch }, momentId: number) {
        commit('setMomentSeen', momentId)
        dispatch('subUnseenMomentCount', 1)
    },
    async uploadComposerImage({ commit }, emitter: Emitter<any> | null) {
        const upload = await getPhotoForMomentsComposer()

        commit('setImageUploading', true)
        if (upload.success) {
            pendoTrackEvent('moments-composer-image-added')

            const data = await uploadImage(upload.file)

            if (!data.success) {
                showToast(
                    emitter,
                    ToastIcon.Alert,
                    ToastColor.Warning,
                    data.error,
                    3000
                )
            } else {
                commit('setComposerUploadedImageFile', upload.file)
                commit('setComposerUploadedImageRemoteFileName', data.fileName)
            }
        } else if (!upload.success && upload.message) {
            showToast(
                emitter,
                ToastIcon.Alert,
                ToastColor.Warning,
                upload.message,
                3000
            )
        }
        commit('setImageUploading', false)
    },
    removeComposerImage({ commit }) {
        commit('setComposerUploadedImageFile', null)
    },
    setImageUploading({ commit }, loading: boolean) {
        commit('setImageUploading', loading)
    },
    async loadCustomerReplyTemplates({ commit }, feedbackId: number) {
        await getTemplates(feedbackId).then((templatesData) => {
            commit('setCustomerReplyTemplates', templatesData.data)
        })
    },
    setSelectedReplyTemplate({ commit }, replyTemplate: ChatTemplate) {
        commit('setSelectedReplyTemplate', replyTemplate)
    },
    setComposerSendToContact({ commit }, sendToContact: boolean) {
        commit('setComposerSendToContact', sendToContact)
    },
}

const mutations: MutationTree<MomentsState> = {
    resetMoments(state) {
        state.moments = []
        state.reachedEnd = false
    },
    addMoments(state, newMoments: Moment[]) {
        newMoments.forEach((newMoment) => {
            const index = state.moments.findIndex(
                (moment) => moment.id === newMoment.id
            )

            if (index !== -1) {
                state.moments[index] = newMoment
                return
            }

            state.moments.unshift(newMoment)
        })

        state.moments = state.moments.sort((a, b) => {
            return b.updated - a.updated
        })
    },
    resetPolledMoments(state) {
        state.polledMoments = []
    },
    addPolledMoments(state, newPolledMoments: Moment[]) {
        state.polledMoments = state.polledMoments.concat(newPolledMoments)
    },
    setLoading(state, isLoading: boolean) {
        state.isLoading = isLoading
    },
    setComposerType(state, type: string) {
        state.composer.type = type
    },
    setTypePickerModalOpen(state, isOpen: boolean) {
        state.composer.typePickerModalOpen = isOpen
        if (!isOpen) {
            state.composer.availableTypes = []
        }
    },
    setAvailableComposerTypes(state, availableTypes: string[]) {
        state.composer.availableTypes = availableTypes
    },
    setComposerSource(state, source: string) {
        state.composer.source = source
    },
    setLoadingRecipients(state, loading: boolean) {
        state.composer.loadingRecipients = loading
    },
    setLoadingHashtags(state, loading: boolean) {
        state.composer.loadingHashtags = loading
    },
    setSelectedRecipients(state, recipients: MomentMessageRecipient[]) {
        state.composer.selectedRecipients = recipients
    },
    setAvailableComposerRecipients(
        state,
        availableRecipients: MomentMessageRecipient[]
    ) {
        state.composer.availableRecipients = availableRecipients
    },
    setAvailableComposerHashtags(
        state,
        availableHashtags: MomentMessageHashtag[]
    ) {
        state.composer.availableHashtags = availableHashtags
    },
    removeLastSelectedRecipient(state) {
        if (state.composer.selectedRecipients.length > 0) {
            state.composer.selectedRecipients.pop()
        }
    },
    selectNewRecipient(state, recipient: MomentMessageRecipient) {
        if (!state.composer.selectedRecipients.includes(recipient)) {
            state.composer.selectedRecipients.push(recipient)
        }
    },
    setRecipientSearchInput(state, searchInput: string) {
        state.composer.recipientSearchInput = searchInput
    },
    setFetchTimestamp(state, timestamp: number) {
        state.fetchTimestamp = timestamp
    },
    setOffset(state, offset: number) {
        state.offset = offset
    },
    setReachedEnd(state, reachedEnd: boolean) {
        state.reachedEnd = reachedEnd
    },
    selectNewMention(state, recipient: MomentMessageRecipient) {
        if (!state.composer.selectedMentions.includes(recipient)) {
            state.composer.selectedMentions.push(recipient)
        }
    },
    setSelectedMentions(state, selectedMentions: MomentMessageRecipient[]) {
        state.composer.selectedMentions = selectedMentions
    },
    setSelectedHashtags(state, selectedHashtags: MomentMessageHashtag[]) {
        state.composer.selectedHashtags = selectedHashtags
    },
    setMentionSearchInput(state, searchInput: string) {
        state.composer.mentionSearchInput = searchInput
    },
    setHashtagSearchInput(state, searchInput: string) {
        state.composer.hashtagSearchInput = searchInput
    },
    setMentionText(state, mentionText: string) {
        state.composer.mentionText = mentionText
    },
    setSubject(state, subject: string) {
        state.composer.subject = subject
    },
    setShowToField(state, showToField: boolean) {
        state.composer.showToField = showToField
    },
    setCurrentMentionedUserIds(state, userIds: Number[]) {
        state.composer.currentMentionedUserIds = userIds
    },
    resetComposer(state) {
        // below we are doing deep clone, so the defaultComposer attribute are not modified by composer change.
        state.composer = JSON.parse(JSON.stringify(defaultComposer))
    },
    setMomentsDataError(state, momentsDataError: boolean) {
        pendoTrackEvent('mobile_moments_data_request_failed')
        state.momentsDataError = momentsDataError
    },
    setEmptyMomentsData(state, emptyMomentsData: boolean) {
        pendoTrackEvent('mobile_moments_data_empty')
        state.emptyMomentsData = emptyMomentsData
    },
    setUnseenMomentCount(state, unseenCount: number) {
        state.unseenCount = unseenCount
    },
    setUnseenCountPollingTimer(state, timer: number) {
        state.unseenCountPollingTimer = timer
    },
    setErrorMomentDetails(state, error: boolean) {
        state.errorMomentDetails = error
    },
    setLoadingMomentDetails(state, loading: boolean) {
        state.loadingMomentDetails = loading
    },
    setMomentDetails(state, momentDetails: MomentDetail) {
        state.momentDetails = momentDetails
    },
    setMomentSeen(state, momentId: number) {
        state.moments[
            state.moments.findIndex((moment) => moment.id === momentId)
        ].seen = true
    },
    setComposerUploadedImageFile(state, file: File | null) {
        state.composer.uploadedImageFile = file
    },
    setComposerUploadedImageRemoteFileName(state, fileName: string) {
        state.composer.uploadedImageRemoteFileName = fileName
    },

    setImageUploading(state, loading: boolean) {
        state.composer.imageUploading = loading
    },

    setCustomerReplyTemplates(state, replyTemplates: ChatTemplate[]) {
        state.composer.customerReplyTemplates = replyTemplates
    },
    setSelectedReplyTemplate(state, replyTemplate: ChatTemplate) {
        state.composer.selectedReplyTemplate = replyTemplate
    },
    setComposerSendToContact(state, sendToContact: boolean) {
        state.composer.sendToContact = sendToContact
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
