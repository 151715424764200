import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3a022d4a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ts-datatype-selector" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.datatypes, (type) => {
      return (_openBlock(), _createElementBlock("button", {
        key: type,
        class: _normalizeClass(['datatype-button', { selected: type === _ctx.current }]),
        onClick: ($event: any) => (_ctx.$emit('change', type))
      }, _toDisplayString(type), 11, _hoisted_2))
    }), 128))
  ]))
}