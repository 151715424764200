<template>
    <BasePillButton :is-active="isActive" :disabled="disabled" @click="onClick">
        <TextClamp :text="`${formattedLabel}`" :max-lines="maxLines" />
    </BasePillButton>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import BasePillButton from '@/components/BasePillButton.vue'
import TextClamp from '@/components/TextClamp.vue'

@Component({
    components: {
        TextClamp,
        BasePillButton,
    },
    emits: ['click'],
})
export default class PillButton extends Vue {
    @Prop({
        type: String,
        required: true,
    })
    readonly label!: string

    @Prop({
        type: Boolean,
        required: false,
        default: false,
    })
    readonly isActive!: boolean

    @Prop({
        type: Boolean,
        required: false,
        default: false,
    })
    readonly disabled!: boolean

    @Prop({
        type: Number,
        default: 0,
    })
    public readonly maxLines!: number // 0 = no clamping

    get formattedLabel() {
        return this.label.toLocaleUpperCase()
    }

    onClick() {
        if (this.disabled) {
            return
        }
        this.$emit('click')
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/palette';
@import '~@/styles/typography';
@import '~@/styles/rain/colour.less';

.pill-button {
    .label();
    line-height: 14px;

    background-color: @white;
    border-radius: 9999px;
    border: 1px solid @blueLight;
    outline: none;

    padding: 0.5rem 0.75rem;

    &.is-active {
        background-color: @blue;
        border-color: @blue;
        color: @white;
    }

    &.disabled {
        opacity: 0.6;
    }

    &.is-v4 {
        border-color: @disabledSecondaryGrey;
        color: @brandPurple;
    }

    &.disabled.is-v4 {
        opacity: 0.3;
    }

    &.is-v4-inverted {
        border-color: @white;
        color: @white;
        background-color: @thinFog;
    }
}
</style>
