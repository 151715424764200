import { StripInputOption } from '@/entities/survey'
import { LocalContactRules } from './contactRules'
import { LooseObject } from '@/pages/appstore/entities/app'
import { MultiCheckboxItem } from '@/entities/survey'

export interface AudienceTimingConfig {
    curSchedule: Schedule | TouchBaseSchedule
    localContactRules: LocalContactRules
    surveyExpiry: SurveyExpiryType
    surveyLimit: SurveyLimitType
    selectedSourceIds: string[]
    schedulerrules: Schedulerrule[]
}

export interface RuleSettings {
    defaultOperators: LooseObject
    timeOperators: LooseObject
    dateFields: string[]
    fields: string[] | MultiCheckboxItem[]
}

export interface Schedule {
    sendTime: string
    sendOption: string
    sendDays: string[]
    timeZone: string
}

export interface Schedulerrule {
    field: string
    id: string
    list: number
    operator: string
    priority: string
    repeat: string | null
    scheduler_id: string
    value_json: string
    value: string
    agoOrAway: string
}

export interface FieldOperatorOption {
    label: string
    value: string
    disabled: boolean
}

export interface TouchBaseSchedule extends Schedule {
    monthFrequency: string
    oneOffOption: string
    oneOffSendDay: Date
    oneOffSendTime: string
}

export const initSchedule: Schedule = {
    sendTime: '',
    sendOption: 'immediately',
    sendDays: [],
    timeZone: 'Pacific/Auckland',
}

export const initRuleSettings: RuleSettings = {
    defaultOperators: {},
    timeOperators: {},
    dateFields: [],
    fields: [],
}

export const initSchedulerrule: Schedulerrule = {
    field: '',
    id: '',
    list: 0,
    operator: 'is',
    priority: '0',
    repeat: null,
    scheduler_id: '',
    value_json: '',
    value: '',
    agoOrAway: '',
}

export const initTouchBaseSchedule: TouchBaseSchedule = {
    sendOption: 'regularly', // when set immediately mean one-off
    monthFrequency: '2',
    sendDays: ['1', '2', '3', '4', '5'],
    sendTime: '10:00',
    timeZone: 'Pacific/Auckland',
    oneOffOption: 'immediately',
    oneOffSendDay: new Date(),
    oneOffSendTime: '10:00',
}

export const scheduleInit = {
    touchbase: initTouchBaseSchedule,
    followup: initSchedule,
}

export enum sendOptions {
    specifically = 'specifically',
    immediately = 'immediately',
    regularly = 'regularly',
}

export const touchBaseStripInputSendOptions: StripInputOption[] = [
    {
        key: 'Regularly',
        value: 'regularly',
        selected: true,
        display: 'Regularly',
    },
    {
        key: 'One-off',
        value: 'immediately',
        selected: false,
        display: 'One-off',
    },
]

export const sendTimeOptions = {
    0: '10:00',
    1: '10:30',
    2: '11:00',
    3: '11:30',
    4: '12:00',
    5: '12:30',
    6: '13:00',
    7: '13:30',
    8: '14:00',
    9: '14:30',
    10: '15:00',
    11: '15:30',
    12: '16:00',
    13: '16:30',
    14: '17:00',
    15: '17:30',
    16: '18:00',
    17: '18:30',
    18: '19:00',
    19: '19:30',
    20: '20:00',
    21: '20:30',
    22: '21:00',
    23: '21:30',
    24: '22:00',
    25: '22:30',
    26: '23:00',
    27: '23:30',
    28: '00:00',
    29: '00:30',
    30: '01:00',
    31: '01:30',
    32: '02:00',
    33: '02:30',
    34: '03:00',
    35: '03:30',
    36: '04:00',
    37: '04:30',
    38: '05:00',
    39: '05:30',
    40: '06:00',
    41: '06:30',
    42: '07:00',
    43: '07:30',
    44: '08:00',
    45: '08:30',
    46: '09:00',
    47: '09:30',
}

export interface SurveyExpiryType {
    willExpire: boolean
    expiredDay: string
    expiredTitle: string
    expiredText: string
}

export const initSurveyExpiry: SurveyExpiryType = {
    willExpire: false,
    expiredDay: '30',
    expiredTitle: 'WHOOPS',
    expiredText: 'This survey has expired.',
}

export interface SurveyLimitType {
    hasLimit: boolean
    maxpeople: string
}

export const initSurveyLimit: SurveyLimitType = {
    hasLimit: false,
    maxpeople: '',
}
