<template>
    <div class="btn-container">
        <button class="btn-google-auth" @click="googleSignin">
            <img
                class="icon"
                :class="{ notloading: !loading }"
                src="@/assets/img/logo/google_logo.png"
                alt="Google"
            />
            <template v-if="!loading"> Login with Google </template>
            <template v-else>
                <div class="spinner-container">
                    <SimpleSpinner
                        size="small"
                        class="spinner"
                        line-fg-color="transparent"
                    />
                </div>
            </template>
        </button>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { googleSignIn } from '@/mobile/src/utils/capacitor'
import { loginWithGoogleIdToken } from '@/mobile/src/api/auth'
import SimpleSpinner from '@/components/SimpleSpinner.vue'

@Component({
    components: { SimpleSpinner },
    emits: ['signin'],
})
export default class GoogleAuthButton extends Vue {
    loading = false

    async googleSignin() {
        if (this.loading) {
            return
        }
        this.loading = true
        let data
        const result = await googleSignIn()
        if (result.success && result.data) {
            data = await loginWithGoogleIdToken(
                result.data.authentication.idToken
            )
        } else {
            data = result
        }
        this.$emit('signin', data)
        this.loading = false
    }
}
</script>

<style scoped lang="less">
.btn-container {
    display: inline-block;

    .btn-google-auth {
        background: white;
        border: none;
        line-height: 28px;
        display: flex;
        align-items: center;
        padding: 3px 10px;
        font-size: 14px;
        width: 170px;
        height: 40px;

        .icon {
            width: 15px;

            &.notloading {
                margin-right: 15px;
            }
        }

        .spinner-container {
            text-align: center;
            flex: 1;
        }
    }
}
</style>
