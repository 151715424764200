<template>
    <img
        src="@/components/WebView/icons/BackLeftAction.svg"
        class="back-button"
        @click="onClick"
    />
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { useRouter } from 'vue-router'

@Component({})
export default class BackButton extends Vue {
    private router = useRouter()

    onClick() {
        this.router.back()
    }
}
</script>
