import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Portal = _resolveComponent("Portal")!

  return (_openBlock(), _createBlock(_component_Portal, {
    to: _ctx.to,
    disabled: _ctx.portalDisabled || _ctx.disabled
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ], undefined, true),
    _: 3
  }, 8, ["to", "disabled"]))
}