import { ActionTree, GetterTree, MutationTree } from 'vuex'

export interface MobileNavigationState {
    tabHeight: number
    haxIosDisableContentScrolling: boolean // Tie this with Filters being open (true) / closed (false). Use this as a flag for a haxy fix for iOS/Webkit randomly letting touch events pierce filters menu.
    containerHasFocus: boolean // trigger Filter dropdown to collapse.
}

export const state: MobileNavigationState = {
    tabHeight: 0,
    haxIosDisableContentScrolling: false,
    containerHasFocus: false,
}

const getters: GetterTree<MobileNavigationState, any> = {
    tabHeight({ tabHeight }) {
        return tabHeight
    },

    haxIosDisableContentScrolling({ haxIosDisableContentScrolling }) {
        return haxIosDisableContentScrolling
    },
    containerHasFocus: (state) => state.containerHasFocus,
}

const actions: ActionTree<MobileNavigationState, any> = {
    setTabsHeight({ commit }, height: number) {
        commit('setTabsHeight', height)
    },

    setHaxIosDisableContentScrolling({ commit }, value: boolean) {
        commit('setHaxIosDisableContentScrolling', value)
    },
    setContainerHasFocus({ commit }, value: boolean) {
        commit('setContainerHasFocus', value)
    },
}

const mutations: MutationTree<MobileNavigationState> = {
    setTabsHeight(state, height: number) {
        state.tabHeight = height
    },

    setHaxIosDisableContentScrolling(state, value: boolean) {
        state.haxIosDisableContentScrolling = value
    },
    setContainerHasFocus(state, value: boolean) {
        state.containerHasFocus = value
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
