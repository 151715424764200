import {
    createDigest,
    patchDigest,
    deleteDigest,
    getDigests,
} from '@/api/digest'
import { IDigest } from '@/entities'
import { ActionTree, MutationTree, GetterTree } from 'vuex'
import { dedupBy } from '@/utils/array'

export interface DigestState {
    digests: IDigest[]
    editing: number // digest id
}

export const state: DigestState = {
    digests: [],
    editing: -1,
}

const getters: GetterTree<DigestState, any> = {
    digestSets({ digests }: DigestState) {
        return digests
    },
    digestEditing({ editing, digests }: DigestState) {
        return digests.find((d) => d.id === editing)
    },
}

const actions: ActionTree<DigestState, any> = {
    // @todo error handling

    async createDigest({ commit }, digest: IDigest): Promise<IDigest> {
        const { data } = await createDigest(digest)
        const { frequency, day, user_id, filter_id, userRoles } = digest
        const newDigest = {
            frequency,
            day,
            user_id,
            filter_id,
            id: data.id,
            userRoles,
        }
        commit('addDigest', newDigest)
        return newDigest
    },

    async patchDigest({ commit }, patch: Partial<IDigest>) {
        await patchDigest(patch)
        commit('patchDigest', patch)
    },

    async deleteDigest({ commit }, id: number) {
        await deleteDigest(id)
        commit('removeDigest', { id })
    },

    async getDigests({ commit }) {
        const { data } = await getDigests()
        commit('setDigests', data)
    },

    setDigestEditing({ commit }, digestId: number) {
        commit('setDigestEditing', digestId)
    },
}

const mutations: MutationTree<DigestState> = {
    setDigests(state, digests: IDigest[]) {
        state.digests = digests
    },

    patchDigest(state, patch: Partial<IDigest> & { id: number }) {
        state.digests = state.digests.map((digest: IDigest) =>
            digest.id === patch.id ? { ...digest, ...patch } : digest
        )
    },

    addDigest(state, digest: IDigest) {
        state.digests = dedupBy(state.digests.concat(digest), (f) =>
            String(f.id)
        )
    },

    removeDigest(state, { id }: { id: number }) {
        state.digests = state.digests.filter((f) => f.id !== id)
    },

    setDigestEditing(state, digestId: number) {
        state.editing = digestId
    },
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations,
}
