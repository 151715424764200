import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-023eccf6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "team-member-tile-avatar" }
const _hoisted_2 = { class: "team-member-tile-topic" }
const _hoisted_3 = { class: "team-member-tile-name" }
const _hoisted_4 = { class: "team-member-tile-score" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AvatarOrInitials = _resolveComponent("AvatarOrInitials")!
  const _component_TopicBadge = _resolveComponent("TopicBadge")!
  const _component_UpDownArrow = _resolveComponent("UpDownArrow")!
  const _component_BaseCard = _resolveComponent("BaseCard")!

  return (_openBlock(), _createBlock(_component_BaseCard, {
    class: _normalizeClass([
            'team-member-tile',
            { 'team-member-selected': _ctx.isTeamMemberSelected },
        ]),
    onClick: _ctx.onCardClick
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_AvatarOrInitials, {
          user: _ctx.data.user,
          size: 48
        }, null, 8, ["user"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.name), 1),
        _createVNode(_component_TopicBadge, {
          topic: _ctx.topic,
          badge: _ctx.badge,
          "font-size": 10
        }, null, 8, ["topic", "badge"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createTextVNode(_toDisplayString(_ctx.data.score_current.toFixed(1)) + " ", 1),
        (_ctx.data.score_change !== 0)
          ? (_openBlock(), _createBlock(_component_UpDownArrow, {
              key: 0,
              positive: _ctx.data.score_change > 0,
              class: "change"
            }, null, 8, ["positive"]))
          : _createCommentVNode("", true)
      ])
    ], undefined, true),
    _: 1
  }, 8, ["class", "onClick"]))
}