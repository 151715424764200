<template>
    <div
        v-if="nameAndRoleLoaded"
        :class="[
            'profile-wrapper',
            {
                'is-offline': !hasNetworkConnection,
                'from-drilldown': fromDrillDown,
            },
        ]"
    >
        <div class="profile-container">
            <ProfilePicture :user="user" :can-edit="!fromDrillDown" />
            <div
                class="profile-details"
                @touchstart="onProfileTouchStart"
                @touchend="onProfileTouchEnd"
            >
                <Typography
                    variant="tabs"
                    component="span"
                    class="profile-text"
                >
                    {{ selectedUserName ? selectedUserName : userName }}
                </Typography>
                <Typography
                    variant="caption"
                    component="span"
                    class="profile-text"
                >
                    {{ selectedUserType ? selectedUserType : userRole }}
                </Typography>
            </div>

            <!-- Show message button when viewing other users scorecards -->
            <PillButton
                v-if="fromDrillDown"
                label="New Message"
                class="is-v4-inverted send-message-button"
                @click="openMessageComposer"
            />
            <Portal v-if="showPopup" key="portal" :to="'over-card-destination'">
                <MomentModal
                    :close-popup="() => closeMessageComposer()"
                    :modal-source="modalSource"
                    :selected-recipient-name="selectedUserName"
                />
            </Portal>

            <!-- Show notifications menu when viewing your own scorecard -->
            <button
                v-if="
                    !fromDrillDown && hasNetworkConnection && showNotifications
                "
                id="notification-button"
                :class="{
                    open: notificationPanelOpen,
                    unread: unseenCount > 0,
                }"
                @click="onNotificationButtonClicked"
            >
                <img
                    id="bell-svg"
                    src="@/assets/img/Icons/notification-bell.svg"
                    alt="notification bell"
                />
                <UnreadCounter
                    v-if="unseenCount > 0"
                    :unread-count="unseenCount"
                />
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { useRouter } from 'vue-router'
import { Getter, Action, namespace } from 'vuex-facing-decorator'
import PillButton from '@/components/PillButton.vue'
import Typography from '@/components/Rain/Typography/Typography.vue'
import MomentModal from '@/mobile/src/components/appV4/MomentModal.vue'
import ProfilePicture from '@/mobile/src/components/appV4/ProfilePicture.vue'
import { Portal } from 'portal-vue'
import { pendoTrackEvent } from '@/utils/pendo'
import { UserDetails } from '@/mobile/src/types/auth'
import { ModalSource } from '@/mobile/src/types/messageTypes'
import { MomentMessageRecipient } from '@/entities/moment'
import UnreadCounter from '@/mobile/src/components/appV4/UnreadCounter.vue'

const MomentsModule = namespace('moments')
const NotificationsModule = namespace('notifications')

@Component({
    components: {
        UnreadCounter,
        ProfilePicture,
        MomentModal,
        Typography,
        PillButton,
        Portal,
    },
})
export default class Profile extends Vue {
    @Prop({ type: String, default: null }) public selectedUserName?: string
    @Prop({ type: String, default: null }) public selectedUserType?: string
    @Prop({ type: Boolean, default: false })
    public fromDrillDown?: boolean
    @Prop({ type: Boolean, default: true }) public showNotifications?: boolean

    @Getter userId
    @Getter userName
    @Getter userAvatar
    @Getter private details!: UserDetails
    @Action public toggleShowSeasonalBanner

    //Temporary while we hide Notifications feature
    @Getter domain

    @Getter hasNetworkConnection!: boolean

    private router = useRouter()

    private profileTouchStart = 0
    private profileTouchEnd = 0
    private profileTouchStartYPos = 0
    private profileTouchEndYPos = 0

    @MomentsModule.Getter getComposerSource!: string
    @MomentsModule.Getter availableComposerRecipients!: MomentMessageRecipient[]

    @MomentsModule.Action setTypePickerModalOpen
    @MomentsModule.Action selectNewRecipient
    @MomentsModule.Action resetComposer

    @NotificationsModule.Action setNotificationPanelOpen
    @NotificationsModule.Getter notificationPanelOpen
    @NotificationsModule.Getter unseenCount!: number

    public showPopup = false
    public modalSource = ModalSource.StaffProfileScreen

    public beforeDestroy() {
        // Close the notification panel if it is open
        if (this.notificationPanelOpen) {
            this.setNotificationPanelOpen(false)
        }
    }

    get userRole() {
        return this.details.userRole
    }

    get nameAndRoleLoaded() {
        return this.userRole && this.userName
    }
    get user() {
        // For Avatar displaying
        if (this.selectedUserName) {
            // drill-down user selected
            return {
                id: -1,
                name: this.selectedUserName ?? this.userName,
                avatar: null,
            }
        } else {
            // current sign-in user
            return {
                id: this.userId ?? -1,
                name: this.userName ?? '',
                avatar: this.userAvatar ?? null,
            }
        }
    }

    public openMessageComposer() {
        this.showPopup = true
        pendoTrackEvent(`message_composer_initialised`, {
            source: this.modalSource ?? 'unknown',
        })
    }

    public closeMessageComposer() {
        this.showPopup = false
        this.setTypePickerModalOpen(false)
    }

    // We want to show the user the 'Easter egg' seasonal header if they
    // press on the Profile name/caption section for more than 0.5s
    public onProfileTouchStart(event) {
        // Disable if drilldown home page
        if (this.fromDrillDown) {
            return
        }

        this.profileTouchStartYPos = event.changedTouches[0]?.screenY ?? 0
        this.profileTouchStart = event.timeStamp
    }

    public onProfileTouchEnd(event) {
        // Disable if drilldown home page
        if (this.fromDrillDown) {
            return
        }

        this.profileTouchEndYPos = event.changedTouches[0]?.screenY ?? 0
        this.profileTouchEnd = event.timeStamp

        // Conditions to qualify this as a long press
        const pressedForOverHalfSecond =
            this.profileTouchEnd - this.profileTouchStart > 500
        // If user is doing pull to refresh we don't want to detect that as a long press
        const didNotMoveTouchPressOverTenPixels =
            this.profileTouchEndYPos - this.profileTouchStartYPos <= 10

        if (pressedForOverHalfSecond && didNotMoveTouchPressOverTenPixels) {
            this.toggleShowSeasonalBanner()
        }
    }

    public onNotificationButtonClicked() {
        this.setNotificationPanelOpen(!this.notificationPanelOpen)
    }
}
</script>
<style scoped lang="less">
@import '~@/styles/rain/colour.less';
@import '~@/mobile/src/styles/misc.less';
@import '~@/styles/variables.less';
@import '~@/styles/rain/variables.less';

.profile-wrapper {
    padding-top: 44px;
    display: flex;
    flex-direction: column;
    margin-bottom: @featureGutter;
    align-items: center;

    &.is-offline,
    &.from-drilldown {
        padding-top: 22px;
    }
}

.profile-container {
    display: flex;
    width: 100%;
    position: relative;
    flex-grow: 2;
}

.profile-details {
    display: flex;
    flex-direction: column;
    padding-left: 12px;
}

.profile-text {
    opacity: 0.8;
    color: @white;
    text-transform: capitalize;
}

.send-message-button {
    margin-left: auto;
    height: 30px;
    transform: translateY(5px);
}
#notification-button {
    .remove-button-styling();
    margin-left: auto;
    height: 40px;
    width: 40px;
    border-radius: @borderRadius-6;

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    background: @brandPurple50;
    transition: background 0.3s ease-in-out;

    &.open {
        background: @brandPurple;
    }

    &.unread {
        // Shift the bell SVG down a few pixels when we are showing the unread count
        #bell-svg {
            padding-top: 4px;
        }
    }

    // Custom positioning of the unread count: .count class lives in UnreadCounter.vue
    .count {
        height: 15px;
        width: 15px;
        top: 4px;
        right: 5px;
    }
}

@media only screen and (min-width: @mobileMaxContentWidth) {
    .profile-wrapper {
        flex-direction: row;
        margin-bottom: 40px;
    }
}
</style>
