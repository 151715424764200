<template>
    <div class="footer-container">
        <MessagePillButton
            :is-active="true"
            :disabled="canSendMessage"
            :composer-type="composerType"
            label="Send"
            @click="send"
        />

        <div>
            <FontAwesomeIcon
                v-if="canAddImage"
                :icon="imageIcon"
                class="image-icon"
                size="lg"
                @click="onImageButtonClicked"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import useEmitter from '@/composables/useEmitter'
import MessagePillButton from '@/mobile/src/components/appV4/MessagePillButton.vue'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faImage } from 'fontawesome/free-solid-svg-icons'
import { namespace } from 'vuex-facing-decorator'

const MomentsModule = namespace('moments')

@Component({
    components: {
        MessagePillButton,
        FontAwesomeIcon,
    },
    emits: ['send'],
})
export default class MomentModalFooter extends Vue {
    @Prop({ type: Boolean, required: false, default: false })
    public readonly canSendMessage!: boolean
    @Prop({ type: Boolean, required: false, default: false })
    public readonly canAddImage!: boolean

    @MomentsModule.Action uploadComposerImage
    @MomentsModule.Getter composerType

    public readonly imageIcon = faImage
    public emitter = useEmitter()

    public send() {
        if (!this.canSendMessage) {
            this.$emit('send')
        }
    }

    public onImageButtonClicked() {
        this.uploadComposerImage(this.emitter)
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/variables.less';
@import '~@/styles/rain/colour.less';

.footer-container {
    padding: @featureGutter;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: @borderWidth solid @thinFog;
    align-items: center;
}

.image-icon {
    color: @white;
    width: 20px;
    transition: color 0.3s ease-in-out;
}
</style>
