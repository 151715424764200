import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { getTopicFeedback } from '@/api/scorecard'
import { getResponses } from '@/api/responses'
import { TopicFeedback } from '@/entities/topicfeedback'
import { TopicResponse } from '@/entities/topicresponse'
import { IFilterQuery, IFilterRule, IResponse } from '@/entities'
import { pendoTrackEvent } from '@/utils/pendo'

export interface TopicFeedbackState {
    topics: {
        [userKey: string]: {
            [month: number]: TopicFeedback[]
        }
    }
    lastFetchTime: number | null
    isLoading: boolean
    topicFeedbackError: boolean
    emptyTopicData: boolean
    emptyTopicMessage: string
    filters: IFilterRule[]
    latestFeedback: IResponse[] // Used for the alternative 'latest feedback' carousel when a tenant has no scorecard
    latestFeedbackLoading: boolean
}

export const state: TopicFeedbackState = {
    topics: {},
    lastFetchTime: null,
    isLoading: true,
    topicFeedbackError: false,
    emptyTopicData: false,
    emptyTopicMessage: '',
    filters: [],
    latestFeedback: [],
    latestFeedbackLoading: false,
}

const getters: GetterTree<TopicFeedbackState, any> = {
    topicFeedback: (state, getters) => (month: number) => {
        if (
            state.topics[getters.userKey] &&
            state.topics[getters.userKey][month]
        ) {
            return state.topics[getters.userKey][month]
        }
        return undefined
    },
    lastFetchTime: (state) => state.lastFetchTime,
    isLoading: (state) => state.isLoading,
    shouldFetchTopicFeedback: (state) => {
        return (
            state.lastFetchTime === null ||
            (Date.now() - state.lastFetchTime) / 1000 > 60
        ) // 1 minute
    },
    topicFeedbackError: (state) => state.topicFeedbackError,
    emptyTopicData: (state) => state.emptyTopicData,
    emptyTopicMessage: (state) => state.emptyTopicMessage,
    userKey: (state) => {
        if (state.filters.length > 0) {
            return JSON.stringify(state.filters)
        }

        return ''
    },
    latestFeedback: (state) => state.latestFeedback,
    latestFeedbackLoading: (state) => state.latestFeedbackLoading,
}

const actions: ActionTree<TopicFeedbackState, any> = {
    async getTopicFeedback(
        { commit, state, rootGetters, getters },
        payload: {
            month: number
            forceRefresh?: boolean
            sortFavouritesFirst?: boolean
        }
    ) {
        const { month, forceRefresh = false, sortFavouritesFirst } = payload
        if (
            state.topics[getters.userKey] &&
            state.topics[getters.userKey][month] &&
            !forceRefresh
        ) {
            return
        }

        commit('setLoading', true)

        try {
            const { data: feedbackData } = await getTopicFeedback(
                month,
                rootGetters.mobileQuestionType,
                state.filters,
                sortFavouritesFirst
            )

            if (!feedbackData.data) {
                commit('setEmptyTopicData', true)
                commit('setEmptyTopicMessage', feedbackData.message)
            }

            commit('setTopicFeedbackData', {
                data: feedbackData.data,
                month: month,
                userKey: getters.userKey,
            })
            commit('setLastFetchTime', Date.now())
        } catch (error) {
            commit('setTopicFeedbackError', true)
        } finally {
            commit('setLoading', false)
        }
    },

    async toggleTopicFeedbackStar(
        { commit, state, rootGetters, getters },
        payload: { questionId: number }
    ) {
        const { questionId } = payload

        Object.values(state.topics[getters.userKey])?.forEach(
            (topicFeedbacks) => {
                topicFeedbacks?.forEach((topicFeedback: TopicFeedback) => {
                    topicFeedback.responses?.forEach(
                        (response: TopicResponse) => {
                            if (Number(response.id) === Number(questionId)) {
                                response.isStarred = !response.isStarred
                            }
                        }
                    )
                })
            }
        )
    },

    async addTopicFilter({ commit, state }, filter: IFilterRule) {
        commit('addTopicFilter', filter)
    },

    async clearTopicFilters({ commit }) {
        commit('clearTopicFilters')
    },

    async getLatestFeedback({ commit }, metric: string) {
        commit('setLatestFeedbackLoading', true)
        const filterRules: IFilterQuery = {
            filter_rules: [
                {
                    column: 'question_type',
                    operator: 'in',
                    value: [metric],
                },
                {
                    column: 'comment',
                    operator: 'isnotempty',
                    value: [''],
                },
            ],
            time_unit: 'month',
            time_value: '1',
        }

        const { data } = await getResponses(filterRules, { pagesize: 4 })
        commit('setLatestFeedback', data)
        commit('setLatestFeedbackLoading', false)
    },
    async loadOfflineDataForTopicFeedback({ rootGetters }) {
        await getTopicFeedback(
            null,
            rootGetters.mobileQuestionType,
            state.filters,
            false
        )
    },
}

const mutations: MutationTree<TopicFeedbackState> = {
    setTopicFeedbackData(
        state,
        payload: { data: TopicFeedback[]; month: number; userKey: string }
    ) {
        if (!state.topics[payload.userKey]) {
            state.topics[payload.userKey] = {}
        }
        state.topics[payload.userKey][payload.month] = payload.data
    },
    setLastFetchTime(state, time: number) {
        state.lastFetchTime = time
    },
    setLoading(state, isLoading: boolean) {
        state.isLoading = isLoading
    },
    setTopicFeedbackError(state, topicFeedbackError: boolean) {
        pendoTrackEvent('mobile_topicfeedback_request_failed')
        state.topicFeedbackError = topicFeedbackError
    },
    setEmptyTopicData(state, emptyTopicData: boolean) {
        state.emptyTopicData = emptyTopicData
    },
    setEmptyTopicMessage(state, emptyTopicMessage: string) {
        pendoTrackEvent(
            'mobile_topicfeedback_empty_' +
                emptyTopicMessage.replace(/ /g, '_').toLowerCase()
        )
        state.emptyTopicMessage = emptyTopicMessage
    },
    addTopicFilter(state, filter) {
        state.filters.push(filter)
    },
    clearTopicFilters(state) {
        state.filters = []
    },
    setLatestFeedback(state, feedback: IResponse[]) {
        state.latestFeedback = feedback
    },
    setLatestFeedbackLoading(state, loading: boolean) {
        state.latestFeedbackLoading = loading
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
