<template>
    <div class="leaderboard-chart">
        <canvas ref="npsgrid" :class="{ hidden: hideCanvas }" />
        <Loading
            :loading="!chartRendered || !!tsLoadingTeamGroupSummary"
            :contained="true"
        ></Loading>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from 'vue-facing-decorator'
import { Getter, Mutation } from 'vuex-facing-decorator'
import {
    Chart,
    ChartEvent,
    LinearScale,
    PointElement,
    ScatterController,
    Tooltip,
} from 'chart.js'
import * as d3Color from 'd3-color'
import {
    ILeaderboardChartPoint,
    LeaderboardChartBoxesV2 as LeaderboardChartBoxes,
    LeaderboardChartColoursV2 as LeaderboardChartColours,
    LeaderboardChartGroupsV2 as LeaderboardChartGroups,
} from '@/entities'
import { clone } from '@/utils/object'
import Loading from '@/components/Loading.vue'
import { TsNpsSummaryEntity } from '@/entities/teamscoreboard'
import { formatNumber } from '@/utils/number'
import useEmitter from '@/composables/useEmitter'

@Component({
    components: {
        Loading,
    },
    emits: ['chart-click', 'label-hover'],
})
export default class LeaderboardChartV2 extends Vue {
    @Getter
    tsLoadingTeamGroupSummary
    private csatInPercentage = false

    public emitter = useEmitter()

    private static calculateChartParts(
        minNumber: number,
        maxNumber: number,
        minMin?: number,
        maxMax?: number
    ) {
        let max
        let min

        /*
         * Fivestar will have their minMin = 1 and maxMax = 5
         * The range of numbers available for fivestar is very small, so we want
         * to have smaller gaps between the highest number and the end of axes
         */
        if (minMin === 1 && maxMax === 5) {
            max = Number(maxNumber) + 0.5
            min = Number(minNumber) - 0.5
        } else {
            max = LeaderboardChartV2.spacedTenUp(maxNumber)
            min = LeaderboardChartV2.spacedTenDown(minNumber)
        }

        if (maxMax !== undefined) {
            max = max > maxMax ? maxMax : max
        }

        if (minMin !== undefined) {
            min = min < minMin ? minMin : min
        }

        // Note that we're using rounded numbers here - we're defining chart axes
        // so it doesn't matter how precise we are
        const third = (max - min) / 3
        const upperThird = Number((max - third).toFixed(1))
        const lowerThird = Number((min + third).toFixed(1))

        return { max, upperThird, lowerThird, min }
    }

    private static spacedTenUp(number: number) {
        let result = number

        // Get 5% of number, rounded up
        result = Math.ceil(Math.abs(result) * 0.05)

        // Make sure it's at least 1
        result = result <= 0 ? 1 : result

        // Round (base number + 5%) to the next (larger) 10
        return Math.ceil((Number(number) + result) / 10) * 10
    }

    private static spacedTenDown(number: number) {
        let result = number

        // Get 5% of number, rounded up
        result = Math.ceil(Math.abs(result) * 0.05)

        // Make sure it's at least 1
        result = result <= 0 ? 1 : result

        // Round (base number - 5%) to the previous (smaller) 10
        return Math.floor((Number(number) - result) / 10) * 10
    }

    @Prop({ type: Number, required: false, default: 1.33 }) public aspectRatio // Approximately 4:3 (default)
    @Prop({ type: Boolean, required: false }) public isTooltipsDisabled
    @Prop({ type: Boolean }) public readonly hidden?: boolean
    @Prop({ type: Boolean, required: false }) public isFiveScore
    @Prop({ type: Boolean, required: false }) public showLabels
    @Prop({ type: Boolean, required: false }) public showAverage
    @Prop({ type: Array, required: false })
    public chartData?: ILeaderboardChartPoint[]
    @Prop({ type: Boolean, required: false }) public mobileStyle

    @Getter public isZoomIn
    @Getter public selectedCategory
    @Getter public tsNpsSummary?: TsNpsSummaryEntity
    @Getter public tsGroupSummary
    @Getter public filterQuestionType
    @Getter public mobileQuestionType
    @Getter public $companyVars

    @Mutation public setZoomIn

    private isZoomInIconLoaded = false

    private chart?: Chart
    private chartRendered = false
    private chartGroups = clone(LeaderboardChartGroups)
    private allDatasets: any[] = [] //Should come up with a proper typing for this

    private averageNPS?: number

    private maxNPS?: number
    private upperThirdNPS?: number
    private lowerThirdNPS?: number
    private minNPS?: number

    private npsAxes?: number[]

    private maxResponded?: number
    private upperThirdResponded?: number
    private lowerThirdResponded?: number
    private minResponded?: number

    private responsesAxes?: number[]

    private zoomOutIcon = new Image()

    private textFontSize = '12px'

    private defaultTickStyle = {
        stepSize: 1, // Set a 1 step size to match individual numbers
        fontColor: LeaderboardChartColours.selectedGreys.text,
        fontFamily: 'Roboto',
        fontSize: 10,
        fontStyle: 'normal',
        maxRotation: 0,
    }

    private defaultAxesOptions = {
        type: 'linear' as any,
        position: 'left' as any,
        gridLines: {
            display: false,
            drawTicks: true,
            drawBorder: false,
        },
    }

    public get hideCanvas() {
        return (
            this.hidden ||
            !this.chartRendered ||
            !!this.tsLoadingTeamGroupSummary
        )
    }

    public get chartQuestionType() {
        if (this.mobileQuestionType) {
            return this.mobileQuestionType
        }
        return this.filterQuestionType
    }

    public mounted() {
        Chart.register(LinearScale, PointElement, ScatterController, Tooltip)
        this.onChartDataChanged()

        this.emitter?.on('loadChart', () => {
            this.onChartDataChanged()
        })
    }

    @Watch('chartData')
    public onChartDataChanged() {
        this.csatInPercentage =
            this.$companyVars.has_display_csat_as_percentage == '1' &&
            this.chartQuestionType === 'csat'
        if (this.chart) {
            this.chart.destroy()
            this.chartRendered = false
        }
        this.setZoomIn(false)
        this.loadChart()
    }

    private async loadChart() {
        if (!this.chartData) {
            return
        }

        const datasets = this.processDataset(this.chartData)
        const canvas = this.$refs.npsgrid as HTMLCanvasElement
        const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
        this.zoomOutIcon.onload = () => {
            this.isZoomInIconLoaded = true
            if (this.isZoomIn) {
                const chartArea = this.chart!.chartArea
                ctx.drawImage(
                    this.zoomOutIcon,
                    chartArea.right - 31,
                    chartArea.bottom - 31
                )
            }
        }
        this.zoomOutIcon.src = '/img/icons/zoom-out.svg'
        if (this.chart) await this.chart.destroy()
        this.chart = new Chart(ctx, {
            type: 'scatter',
            data: {
                datasets,
            },
            options: {
                animation: {
                    duration: 0,
                },
                aspectRatio: this.aspectRatio,
                elements: {
                    point: {
                        radius: 4,
                        hoverRadius: 6,
                    },
                },
                layout: {
                    padding: {
                        left: this.mobileStyle ? 15 : 35,
                        right: 50,
                        top: 50,
                        bottom: 25,
                    },
                },
                maintainAspectRatio: true,
                onClick: this.onChartClick,
                scales: {
                    x: {
                        ticks: {
                            ...this.defaultTickStyle,
                            callback: function (
                                this: TickCallbackThis,
                                value,
                                index,
                                values
                            ) {
                                return this.csatInPercentage
                                    ? value + '%'
                                    : formatNumber(value, 1)
                            }.bind({
                                csatInPercentage: this.csatInPercentage,
                            }),
                        },
                        max: this.maxNPS,
                        min: this.minNPS,
                        afterBuildTicks: (chart) => {
                            if (!this.npsAxes) {
                                return
                            }

                            chart.ticks = []
                            this.npsAxes.forEach((value) => {
                                const newTick = { value: value }
                                chart.ticks.push(newTick)
                            })
                        },
                        ...this.defaultAxesOptions,
                    },
                    y: {
                        ticks: {
                            ...this.defaultTickStyle,
                            callback: function (value, index, values) {
                                return formatNumber(value, 0)
                            },
                        },
                        max: this.maxResponded,
                        min: this.minResponded,
                        afterBuildTicks: (chart) => {
                            if (!this.responsesAxes) {
                                return
                            }

                            chart.ticks = []
                            this.responsesAxes.forEach((value) => {
                                const newTick = { value: value }
                                chart.ticks.push(newTick)
                            })
                        },
                        ...this.defaultAxesOptions,
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        backgroundColor: LeaderboardChartColours.white,
                        bodyColor: LeaderboardChartColours.averageNps.text,
                        titleColor: LeaderboardChartColours.dot.aggregate,
                        titleFont: { weight: 'normal' },
                        footerColor: LeaderboardChartColours.dot.aggregate,
                        footerFont: { weight: 'normal' },
                        displayColors: false,
                        caretPadding: 10,
                        enabled: !this.isTooltipsDisabled,
                        position: 'nearest',
                        callbacks: {
                            title: (tooltipItems) => {
                                if (
                                    !tooltipItems[0].dataset ||
                                    !tooltipItems.length ||
                                    typeof tooltipItems[0].datasetIndex ===
                                        'undefined'
                                ) {
                                    return []
                                }
                                const set = tooltipItems[0].dataset
                                if (set && set.label && set.label.length > 1) {
                                    return ' Not enough responses'
                                }
                                return []
                            },
                            label: (tooltipItem) => {
                                if (
                                    !tooltipItem.dataset ||
                                    typeof tooltipItem.datasetIndex ===
                                        'undefined'
                                ) {
                                    return []
                                }
                                const set = tooltipItem.dataset
                                if (!set) {
                                    return []
                                }
                                if (set && set.label && set.label.length > 10) {
                                    return set.label.slice(0, 10)
                                }
                                return set.label ?? []
                            },
                            footer: (tooltipItems) => {
                                if (
                                    !tooltipItems[0].dataset ||
                                    !tooltipItems.length ||
                                    typeof tooltipItems[0].datasetIndex ===
                                        'undefined'
                                ) {
                                    return []
                                }
                                const set = tooltipItems[0].dataset
                                if (set && set.label && set.label.length > 10) {
                                    const excess = set.label.length - 10
                                    return ` + ${excess} more`
                                }
                                return []
                            },
                        },
                        external: (context) => {
                            if (
                                context.tooltip.dataPoints &&
                                context.tooltip.dataPoints[0]
                            ) {
                                this.$emit(
                                    'label-hover',
                                    this.allDatasets[
                                        context.tooltip.dataPoints[0]
                                            .datasetIndex
                                    ].group
                                )
                            } else {
                                this.$emit('label-hover', '')
                                return
                            }
                        },
                    },
                },
                // TODO: Evaluate the need to re-add ChartZoomPlugin here
            },
            plugins: [
                {
                    id: 'custom-plugin-v2',
                    afterInit: (chart) => {
                        // @ts-ignore _mc is a Hammer.js Manager added by chartjs-zoom-plugin
                        const mc = chart._mc

                        if (mc) {
                            mc.set({ touchAction: 'pan-x pan-y' })
                        }
                    },
                    beforeDatasetsDraw: this.beforeDatasetsDraw,
                    afterRender: () => {
                        this.chartRendered = true
                    },
                },
            ],
        })
        this.chart.options.onHover = (e: any) => {
            if (this.chart) {
                const point = this.chart.getElementsAtEventForMode(
                    e,
                    'nearest',
                    { intersect: true },
                    false
                ) as any
                if (!e.target) {
                    return
                }

                if (point.length) {
                    e.target.style.cursor = 'pointer'
                } else {
                    e.target.style.cursor = 'default'
                }
            }
        }
    }

    /*
    This function processes the list of groups we have to fit them within
    a chart category (the 3 column grid). At the same time we assign colours,
    chart point design etc.
     */
    private processDataset(data: ILeaderboardChartPoint[]) {
        this.processAxesForDataset(data)

        this.allDatasets = []
        this.chartGroups = clone(LeaderboardChartGroups)

        data.forEach((point: ILeaderboardChartPoint) => {
            if (
                this.lowerThirdNPS === undefined ||
                this.upperThirdNPS === undefined ||
                this.lowerThirdResponded === undefined ||
                this.upperThirdResponded === undefined
            ) {
                return
            }

            const dataset = {
                x: point.NPS,
                y: point.responded,
            }

            let category = ''
            let backgroundColor = ''

            if (point.NPS <= this.lowerThirdNPS) {
                category = 'lowPerf'
                backgroundColor = LeaderboardChartColours.dot.lowPerf
            } else if (point.NPS > this.upperThirdNPS) {
                category = 'highPerf'
                backgroundColor = LeaderboardChartColours.dot.highPerf
            } else {
                category = 'medPerf'
                backgroundColor = LeaderboardChartColours.dot.medPerf
            }

            if (point.aggregate) {
                backgroundColor = LeaderboardChartColours.dot.aggregate
            }

            if (this.chartGroups.hasOwnProperty(category)) {
                this.chartGroups[category].groups.push(point.group)
            }

            const labeller = (point: ILeaderboardChartPoint) => {
                let label = ' ' + point.group || ''
                if (label) {
                    label += ' |'
                }

                let nps = point.NPS
                const responses = point.responded
                const npsLabel = this.isFiveScore ? 'Rating' : 'NPS'

                if (this.csatInPercentage) {
                    nps = Number(Number(nps).toFixed(1))
                }
                label += ` Responses ${responses} | ${npsLabel} ${nps}${
                    this.csatInPercentage ? '%' : ''
                } `

                return label
            }

            const labels =
                point.subgroups && point.subgroups.length
                    ? point.subgroups.map(labeller)
                    : [labeller(point)]

            this.allDatasets.push({
                group: point.group,
                label: labels,
                data: [dataset],
                backgroundColor,
                hoverBackgroundColor: backgroundColor,
                hoverBorderColor: backgroundColor,
                pointBorderColor: backgroundColor,
                pointBorderWidth: 0,
            })
        })

        // Only show the groups with items
        for (const key in this.chartGroups) {
            if (!this.chartGroups.hasOwnProperty(key)) {
                continue
            }

            this.chartGroups[key].hasElements =
                this.chartGroups[key].groups.length <= 0 ? false : true
        }

        return this.allDatasets
    }

    private processAxesForDataset(data: ILeaderboardChartPoint[]) {
        let sum = 0
        let maxNps: number | undefined | null
        let minNps: number | undefined | null
        let maxResponded: number | undefined
        let minResponded: number | undefined

        data.forEach((point: ILeaderboardChartPoint) => {
            const nps = point.NPS
            sum += Number(nps)

            const responded = point.responded

            maxNps =
                maxNps === undefined || Number(maxNps) < Number(nps)
                    ? nps
                    : maxNps
            minNps =
                minNps === undefined || Number(minNps) > Number(nps)
                    ? nps
                    : minNps

            maxResponded =
                maxResponded === undefined ||
                Number(maxResponded) < Number(responded)
                    ? responded
                    : maxResponded
            minResponded =
                minResponded === undefined ||
                Number(minResponded) > Number(responded)
                    ? responded
                    : minResponded
        })

        this.averageNPS = this.tsGroupSummary
            ? this.tsGroupSummary.aggregateScore
            : 0

        maxNps = maxNps ? maxNps : 0
        minNps = minNps ? minNps : 0
        maxResponded = maxResponded ? maxResponded : 0
        minResponded = minResponded ? minResponded : 0

        let minMin
        if (this.isFiveScore && !this.csatInPercentage) {
            minMin = 1
        } else if (this.isFiveScore && this.csatInPercentage) {
            minMin = 0
        } else {
            minMin = -100
        }

        const maxMax = this.isFiveScore && !this.csatInPercentage ? 5 : 100

        const {
            max: maximumNps,
            upperThird: upperThirdNps,
            lowerThird: lowerThirdNps,
            min: minimumNps,
        } = LeaderboardChartV2.calculateChartParts(
            minNps,
            maxNps,
            minMin,
            maxMax
        )

        this.maxNPS = maximumNps
        this.upperThirdNPS = upperThirdNps
        this.lowerThirdNPS = lowerThirdNps
        this.minNPS = minimumNps

        this.npsAxes = [maximumNps, upperThirdNps, lowerThirdNps, minimumNps]

        const {
            max: maximumResponded,
            upperThird: upperThirdResponded,
            lowerThird: lowerThirdResponded,
            min: minimumResponded,
        } = LeaderboardChartV2.calculateChartParts(
            minResponded,
            maxResponded,
            0
        )

        this.maxResponded = maximumResponded
        this.upperThirdResponded = upperThirdResponded
        this.lowerThirdResponded = lowerThirdResponded
        this.minResponded = minimumResponded

        this.responsesAxes = [
            maximumResponded,
            upperThirdResponded,
            lowerThirdResponded,
            minimumResponded,
        ]

        return {
            lowerThirdNps,
            upperThirdNps,
            lowerThirdResponded,
            upperThirdResponded,
        }
    }

    private onChartClick(event: ChartEvent) {
        this.$emit('chart-click', event, {
            chart: this.chart,
            chartGroups: this.chartGroups,
            allDatasets: this.allDatasets,
        })
    }

    private beforeDatasetsDraw(chart: Chart) {
        // Figure out the coordinates of the chart
        const chartArea = chart.chartArea
        const top = chartArea.top
        const left = chartArea.left
        const width = chartArea.right - chartArea.left
        const height = chartArea.bottom - chartArea.top

        /**
         * ChartArea numbers can be NaN if the chart is not shown on screen
         * e.g. when it is behind a modal
         */
        if (
            Number.isNaN(chartArea.bottom) ||
            Number.isNaN(chartArea.left) ||
            Number.isNaN(chartArea.right) ||
            Number.isNaN(chartArea.top)
        ) {
            return
        }

        const ctx = chart.ctx
        if (ctx === null) {
            return
        }

        let hasFiltered = false
        const selectedGroups: object[] = []
        for (const key in this.chartGroups) {
            if (
                this.chartGroups.hasOwnProperty(key) &&
                this.chartGroups[key].selected
            ) {
                hasFiltered = true
                selectedGroups.push({ key, ...this.chartGroups[key] })
                break
            }
        }

        const canvasWidth = ctx.canvas.width

        if (canvasWidth < 1000) {
            this.textFontSize = '10px'
        } else {
            this.textFontSize = '12px'
        }

        this.drawNpsRegionBoxes(ctx, selectedGroups, left, top, width, height)
        if (this.showAverage && !this.isZoomIn) {
            this.drawAverageBox(ctx, left, top, width, height)
        }
        this.drawSmallText(ctx, selectedGroups, left, top, width, height)

        if (this.showLabels) {
            this.drawLargeTopText(ctx, selectedGroups, left, top, width)
        }

        if (this.isZoomIn) {
            this.drawZoomOutButton(ctx, chartArea)
        }
    }

    private drawZoomOutButton(ctx, chartArea) {
        ctx.save()
        ctx.beginPath()
        ctx.arc(chartArea.right - 25, chartArea.bottom - 25, 16, 0, 2 * Math.PI)
        ctx.fillStyle = '#ffffff'
        this.setShadow(ctx, 'black', 1, 4, 15)
        ctx.fill()
        ctx.restore()
        if (this.isZoomInIconLoaded) {
            ctx.drawImage(
                this.zoomOutIcon,
                chartArea.right - 31,
                chartArea.bottom - 31
            )
        } else {
            ctx.save()
            ctx.font = '32px Roboto'
            ctx.fillStyle = '#000000'
            ctx.fillText('-', chartArea.right - 30, chartArea.bottom - 16)
            ctx.restore()
        }
    }

    private setShadow(ctx, color, ox, oy, blur) {
        ctx.shadowColor = color
        ctx.shadowOffsetX = ox
        ctx.shadowOffsetY = oy
        ctx.shadowBlur = blur
    }

    private drawNpsRegionBoxes(ctx, selectedGroups, left, top, width, height) {
        ctx.save()

        if (!this.isZoomIn || this.selectedCategory === '') {
            // Create a coloured box background for each NPS "type" defined in chartBoxes
            for (const key in LeaderboardChartBoxes) {
                if (!LeaderboardChartBoxes.hasOwnProperty(key)) {
                    continue
                }

                const box = LeaderboardChartBoxes[key]

                const boxleft = left + width * (box.x / 3)
                const boxtop = top
                const boxwidth = width * (1 / 3)
                const boxheight = height

                if (this.chartGroups.hasOwnProperty(key)) {
                    this.chartGroups[key].left = boxleft
                    this.chartGroups[key].top = boxtop
                    this.chartGroups[key].width = boxwidth
                    this.chartGroups[key].height = boxheight
                }

                if (selectedGroups.length <= 0 || this.mobileStyle) {
                    this.drawNormalNpsBoxes(
                        ctx,
                        box,
                        boxleft,
                        boxtop,
                        boxwidth,
                        boxheight
                    )
                } else {
                    if (
                        this.chartGroups.hasOwnProperty(key) &&
                        this.chartGroups[key].selected
                    ) {
                        this.drawSelectedNpsBox(
                            ctx,
                            box,
                            boxleft,
                            boxtop,
                            boxwidth,
                            boxheight
                        )
                    } else {
                        this.drawGreyedNpsBox(
                            ctx,
                            boxleft,
                            boxtop,
                            boxwidth,
                            boxheight
                        )
                    }
                }
            }
        } else {
            const key = this.selectedCategory
            if (
                this.chartGroups.hasOwnProperty(key) &&
                this.chartGroups[key].selected
            ) {
                const box = LeaderboardChartBoxes[key]
                this.drawNormalNpsBoxes(ctx, box, left, top, width, height)
            }
        }

        ctx.restore()
    }

    private drawNormalNpsBoxes(ctx, box, boxleft, boxtop, boxwidth, boxheight) {
        const backgroundFade = d3Color.color(box.colour)
        if (backgroundFade) {
            backgroundFade.opacity = 0.2
        }
        const gradient = ctx.createLinearGradient(0, 0, 0, boxheight)
        gradient.addColorStop(0, box.colour)
        gradient.addColorStop(0.5, box.colour)
        gradient.addColorStop(
            1,
            backgroundFade ? backgroundFade.rgb() + '' : 'white'
        )
        ctx.fillStyle = gradient
        ctx.fillRect(boxleft, boxtop, boxwidth, boxheight)
    }

    private drawSelectedNpsBox(ctx, box, boxleft, boxtop, boxwidth, boxheight) {
        ctx.fillStyle = box.colour
        ctx.fillRect(boxleft, boxtop, boxwidth, boxheight)

        ctx.strokeStyle = LeaderboardChartColours.averageNps.border
        ctx.lineWidth = 2.0
        ctx.strokeRect(boxleft + 1, boxtop + 1, boxwidth - 2, boxheight - 2)
    }

    private drawGreyedNpsBox(ctx, boxleft, boxtop, boxwidth, boxheight) {
        ctx.fillStyle = LeaderboardChartColours.selectedGreys.box
        ctx.fillRect(boxleft, boxtop, boxwidth, boxheight)
    }

    private drawAverageBox(ctx, left, top, width, height) {
        ctx.save()

        ctx.fillStyle = LeaderboardChartColours.white
        ctx.strokeStyle = LeaderboardChartColours.white

        // Shadow
        ctx.shadowColor = LeaderboardChartColours.white
        ctx.shadowBlur = 5

        // NPS Average Line
        const pixelsFromLeft = this.getPixelsByNPS(
            this.getDisplayedNps(),
            width
        )

        // Rectangle
        const boxWidth = 30
        const boxHeight = 26
        ctx.fillRect(
            left + pixelsFromLeft - boxWidth / 2,
            top + height + 20 - boxHeight / 2,
            boxWidth,
            boxHeight
        )
        ctx.strokeRect(
            left + pixelsFromLeft - boxWidth / 2,
            top + height + 20 - boxHeight / 2,
            boxWidth,
            boxHeight
        )

        // Line
        ctx.strokeStyle = LeaderboardChartColours.averageNps.line
        ctx.beginPath()
        ctx.setLineDash([4, 4])
        ctx.moveTo(left + pixelsFromLeft, top)
        ctx.lineTo(left + pixelsFromLeft, top + height)
        ctx.stroke()

        ctx.font = 'normal normal normal 10px sans-serif'
        ctx.textAlign = 'center'
        ctx.fillStyle = LeaderboardChartColours.averageNps.text
        ctx.fillText(
            this.getDisplayedNpsString(),
            left + pixelsFromLeft,
            top + height + 18
        )

        ctx.restore()
    }

    private getDisplayedNps() {
        return Number(this.averageNPS || 0)
    }

    private getDisplayedNpsString() {
        if (this.csatInPercentage && this.chartQuestionType == 'csat') {
            return this.getDisplayedNps().toFixed(1) + '%'
        }

        return this.getDisplayedNps().toFixed(1)
    }

    private drawSmallText(ctx, selectedGroups, left, top, width, height) {
        ctx.save()

        const textColour = 'black'

        ctx.font = 'normal normal bold 12px sans-serif'
        ctx.fillStyle = textColour

        // Number of Responses
        ctx.textAlign = 'right'
        const bias = 15
        ctx.fillText('Responses', left + bias, top - 20)

        // NPS
        ctx.textAlign = 'center'
        const npsLabel = this.isFiveScore ? 'Rating' : 'NPS'
        ctx.fillText(npsLabel, left + width + 32, top + height + 18)

        ctx.restore()
    }

    private drawLargeTopText(ctx, selectedGroups, left, top, width) {
        ctx.save()

        ctx.textAlign = 'center'

        let coachColour = LeaderboardChartColours.selectedGreys.titles
        let developColour = LeaderboardChartColours.selectedGreys.titles
        let recognizeColour = LeaderboardChartColours.selectedGreys.titles

        const normalFont =
            'normal normal normal ' + this.textFontSize + ' sans-serif'

        let coachFont = normalFont
        let developFont = normalFont
        let recognizeFont = normalFont

        let foundSelected = false
        let selectedFillStyle
        let selectedFont
        let selectedText
        selectedGroups.forEach((group) => {
            if (group.key.includes('LowPerf') || this.mobileStyle) {
                coachFont = normalFont
                coachColour = LeaderboardChartColours.averageNps.text
                foundSelected = true
                selectedFillStyle = coachColour
                selectedFont = coachFont
                selectedText = 'COACH'
            }

            if (group.key.includes('MedPerf') || this.mobileStyle) {
                developFont = normalFont
                developColour = LeaderboardChartColours.averageNps.text
                foundSelected = true
                selectedFillStyle = developColour
                selectedFont = developFont
                selectedText = 'DEVELOP'
            }

            if (group.key.includes('HighPerf') || this.mobileStyle) {
                recognizeFont = normalFont
                recognizeColour = LeaderboardChartColours.averageNps.text
                foundSelected = true
                selectedFillStyle = recognizeColour
                selectedFont = recognizeFont
                selectedText = 'RECOGNIZE'
            }
        })

        if (!foundSelected) {
            coachColour = LeaderboardChartColours.averageNps.text
            developColour = LeaderboardChartColours.averageNps.text
            recognizeColour = LeaderboardChartColours.averageNps.text

            coachFont = normalFont
            developFont = normalFont
            recognizeFont = normalFont
        }

        // Top row of text
        const firstThirdPixelsFromLeft = this.getPixelsByNPS(
            this.lowerThirdNPS,
            width
        )
        const centerFirstThirdPixels = firstThirdPixelsFromLeft / 2
        const secondThirdPixelsFromLeft = this.getPixelsByNPS(
            this.upperThirdNPS,
            width
        )
        const centerSecondThirdPixels =
            firstThirdPixelsFromLeft +
            (secondThirdPixelsFromLeft - firstThirdPixelsFromLeft) / 2
        const thirdThirdPixelsFromLeft = this.getPixelsByNPS(this.maxNPS, width)
        const centerThirdThirdPixels =
            secondThirdPixelsFromLeft +
            (thirdThirdPixelsFromLeft - secondThirdPixelsFromLeft) / 2

        if (
            this.isZoomIn &&
            selectedFillStyle &&
            selectedFont &&
            selectedText
        ) {
            ctx.fillStyle = selectedFillStyle
            ctx.font = selectedFont
            ctx.fillText(selectedText, left + centerSecondThirdPixels, top - 20)
        } else {
            ctx.fillStyle = coachColour
            ctx.font = coachFont
            ctx.fillText('COACH', left + centerFirstThirdPixels, top - 20)

            ctx.fillStyle = developColour
            ctx.font = developFont
            ctx.fillText('DEVELOP', left + centerSecondThirdPixels, top - 20)

            ctx.fillStyle = recognizeColour
            ctx.font = recognizeFont
            ctx.fillText('RECOGNIZE', left + centerThirdThirdPixels, top - 20)
        }

        ctx.restore()
    }

    private getPixelsByNPS(nps?: number, width?: number) {
        if (
            typeof this.maxNPS === 'undefined' ||
            typeof this.minNPS === 'undefined' ||
            typeof nps === 'undefined' ||
            typeof width === 'undefined'
        ) {
            return 99999999 // Draw it offscreen
        }

        const fractionFromLeft =
            (nps - this.minNPS) / (this.maxNPS - this.minNPS)

        return width * fractionFromLeft
    }

    private getPixelsByResponded(responded?: number, height?: number) {
        if (
            typeof this.maxResponded === 'undefined' ||
            typeof this.minResponded === 'undefined' ||
            typeof responded === 'undefined' ||
            typeof height === 'undefined'
        ) {
            return 99999999 // Draw it offscreen
        }

        const fractionFromTop =
            (responded - this.minResponded) /
            (this.maxResponded - this.minResponded)

        return height * fractionFromTop
    }
}

interface TickCallbackThis {
    csatInPercentage: boolean
}
</script>

<style lang="less" scoped>
.hidden {
    display: none !important; // without important, it's being overwritten
}
</style>
