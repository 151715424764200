import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-606ab285"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "recipient-bubble-list" }
const _hoisted_2 = ["value", "size", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Typography = _resolveComponent("Typography")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedRecipients, (recipient, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: _normalizeClass([
                'recipient-bubble',
                _ctx.composerType,
                { selected: _ctx.confirmDelete },
            ])
      }, [
        _createVNode(_component_Typography, {
          variant: "body2",
          class: _normalizeClass(['name-text', _ctx.composerType])
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(recipient.name), 1)
          ], undefined, true),
          _: 2
        }, 1032, ["class"])
      ], 2))
    }), 128)),
    _createElementVNode("input", {
      id: "recipient-search",
      ref: "recipientSearch",
      value: _ctx.recipientSearchInput,
      class: _normalizeClass([_ctx.composerType]),
      size: _ctx.searchInputWidth,
      disabled: _ctx.disableInput,
      type: "text",
      onInput: _cache[0] || (_cache[0] = (event) => _ctx.onSearchRecipient(event.target.value)),
      onKeydown: _cache[1] || (_cache[1] = (event) => _ctx.onDeleteEntered(event)),
      onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setRecipientSearchInput('')))
    }, null, 42, _hoisted_2)
  ]))
}