export enum CsvColumnTypes {
    SkipThisColumn = 'skip',
    UserEmail = 'email',
    MobileNumber = 'mobileasemail',
    UserNameFirstLast = 'name',
    UserNameLastFirst = 'lastnamefirst',
    FirstName = 'firstname',
    LastName = 'lastname',
    Segment = 'segment',
    CreatedDate = 'created',
    UserRole = 'role',
    Filter = 'filter',
}

export enum CsvColumnLabels {
    SkipThisColumn = 'Skip This Column',
    UserEmail = 'Email',
    MobileNumber = 'Mobile (Primary Survey Method)',
    UserNameFirstLast = 'Name (First Last)',
    UserNameLastFirst = 'Name (Last, First)',
    FirstName = 'First Name',
    LastName = 'Last Name',
    Segment = 'Segment',
    CreatedDate = 'Created Date (within your system)',
    UserRole = 'User Role',
    Filter = 'Visible Data',
}

export enum ProgressType {
    Upload = 'upload',
    Schedule = 'schedule',
}

export const Separator = '_'

export const CsvColumnOptions = [
    {
        value: CsvColumnTypes.SkipThisColumn,
        label: CsvColumnLabels.SkipThisColumn,
        disabled: false,
    },
    {
        value: CsvColumnTypes.UserEmail,
        label: CsvColumnLabels.UserEmail,
        disabled: false,
    },
    {
        value: CsvColumnTypes.UserNameFirstLast,
        label: CsvColumnLabels.UserNameFirstLast,
        disabled: false,
    },
    {
        value: CsvColumnTypes.UserNameLastFirst,
        label: CsvColumnLabels.UserNameLastFirst,
        disabled: false,
    },
    {
        value: CsvColumnTypes.UserRole,
        label: CsvColumnLabels.UserRole,
        disabled: false,
    },
    {
        value: CsvColumnTypes.Filter,
        label: CsvColumnLabels.Filter,
        disabled: false,
    },
]

export const HeadingForImporter = [
    'CSV HEADING',
    'FIRST ROW VALUE',
    'IMPORT AS',
]

export const oldCsvImporterOptions = [
    {
        value: 'Import Only',
        label: 'Import Only',
        isAdvanced: false,
        note: '',
    },
    {
        value: 'Import & Survey',
        label: 'Import & Survey',
        isAdvanced: false,
        note: 'This option will still follow the global contact rules.',
    },
    {
        value: 'Import & Queue',
        label: 'Import & Queue',
        isAdvanced: true,
        note: 'Contacts will be sent their default survey once any send scheduler starts. The queue will not look at the rules of the send schedulers but will still follow the global contact rules. You may also manually send them from the queue immediately.',
    },
    {
        value: 'Deactivate all contacts in the file',
        label: 'Deactivate all contacts in the file',
        isAdvanced: true,
        note: '',
    },
]

export const otherContactOptions = [
    {
        value: 'Do nothing',
        label: 'Do nothing',
    },
    {
        value: 'Deactivate them',
        label: 'Deactivate them',
    },
]

export const newCsvImporterOptions = [
    {
        value: '{"mailcsvblast":0,"mailcsvdeactivate":1}',
        label: 'A full update of this group of contacts who I want to <b>Touch Base</b> with.',
    },
    {
        value: '{"mailcsvblast":0,"mailcsvdeactivate":0}',
        label: 'New contacts that I want to <b>Touch Base</b> with and existing ones who have changed.',
    },
    {
        value: '{"mailcsvblast":0,"mailcsvdeactivate":2}',
        label: 'Contacts that I no longer want to <b>Touch base</b> with.',
    },
    {
        value: '{"mailcsvblast":null,"mailcsvdeactivate":null}',
        label: 'Contacts that have interacted with me, which I want to <b>Follow Up</b> with at the next survey send.',
    },
]

export const headers = [
    'first',
    'second',
    'third',
    'fourth',
    'fifth',
    'sixth',
    'seventh',
    'eighth',
    'ninth',
    'tenth',
]

export enum CsvImporterError {
    InvalidFileType = 'Invalid File Type',
    InvalidFile = 'Invalid File',
    InvalidCsvFile = 'Invalid CSV File',
    ImporterBusy = 'Importer Busy',
    Unknown = 'Unknown',
}
