import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SimpleSpinner = _resolveComponent("SimpleSpinner")!

  return (_openBlock(), _createBlock(_component_SimpleSpinner, {
    size: "small",
    class: "spinner",
    "line-fg-color": "transparent"
  }))
}