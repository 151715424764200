<template>
    <div class="engagement-empty-team-member-state">
        <div class="image">
            <img src="@/assets/img/insights/empty_state_team_members.svg" />
        </div>
        <Typography variant="h4" v-html="headerText"></Typography>
        <Typography variant="body2" v-html="bodyText"></Typography>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import Typography from '@/components/Rain/Typography/Typography.vue'

@Component({
    components: { Typography },
})
export default class EngagementEmptyTeamMembersAttentionState extends Vue {
    @Prop({ required: true, type: String })
    state!: 'Needs Attention' | 'Doing Well'

    public get headerText() {
        if (this.state === 'Needs Attention') {
            return 'No team members require your attention right now'
        }

        return 'Your team members may need some attention'
    }

    public get bodyText() {
        if (this.state === 'Needs Attention') {
            return 'Good news! It looks like your team are all doing well. Check the <span>Doing Well</span> tab to see your team members.'
        }

        return 'It looks like your team may need you. Check the <span>Needs Attention</span> tab to see your team members.'
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';

.engagement-empty-team-member-state {
    text-align: center;

    .image {
        margin-bottom: @gutterSpacing-base;
    }

    .h4 {
        margin-bottom: @gutterSpacing-base;
    }

    .body2 :deep(span) {
        font-weight: bold;
    }
}
</style>
