<template>
    <div :class="['circle', variation]">
        {{ count }}
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component({})
export default class CountCircle extends Vue {
    @Prop({ type: Number, required: true })
    public count!: number
    @Prop({ type: String, required: false })
    public variation?: string
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';

.circle {
    width: 18px;
    height: 18px;
    border: @borderWidth solid @white;
    color: @white;
    border-radius: 20px;
    text-align: center;
    line-height: 18px;
    font-size: 11px;
    text-transform: uppercase;
}
</style>
