import { getCurrentInstance } from 'vue'
import { Emitter } from 'mitt'

// App-level event bus using Mitt emitter
// To use this, please define app.config.globalProperties.emitter = mitt() in the Vue app your component belongs to
// For example: calculated-fields.ts

export default function useEmitter(): Emitter<any> | null {
    const appInstance = getCurrentInstance()
    return appInstance?.appContext?.config?.globalProperties?.emitter
}
