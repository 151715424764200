<template>
    <ScorecardSubpage
        v-if="scorecard"
        :slide="slideName"
        :black="false"
        :badge-id="total < 1 ? 'unknown-badge' : badgeId"
        title=""
    >
        <div class="achievement-detail-container">
            <div class="header">
                <div class="title">
                    <Typography variant="h4">{{ achievementLabel }}</Typography>
                </div>
                <div class="rules">
                    <Typography variant="body2">{{ rules }}</Typography>
                </div>
                <div class="stats">
                    <div v-if="value === 'allfiredup'" class="month">
                        <div class="number">
                            <Typography variant="h2">{{
                                totalCalendarMonth
                            }}</Typography>
                        </div>
                        <div class="subtitle">
                            <Typography variant="caption">{{ month }} Total</Typography>
                        </div>
                    </div>
                    <div class="all">
                        <div class="number">
                            <Typography variant="h2">{{ total }}</Typography>
                        </div>
                        <div class="subtitle">
                            <Typography variant="caption">All Time Total</Typography>
                        </div>
                    </div>
                </div>
                <div class="hr"></div>
                <div class="tokens">
                    <AchievementToken
                        v-for="(token, i) in tokens"
                        :key="`${i}-${token.title}-${token.description}`"
                        :type="value"
                        :title="token.title"
                        :description="token.description"
                        :data-id="'pendo-achievement-drilldown'"
                        @click="onClickToken"
                    />
                </div>
            </div>

            <AchievementSplash
                v-if="showSplash"
                :type="value"
                @close="showSplash = false"
            />
        </div>
    </ScorecardSubpage>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import ScorecardSubpage from '@/pages/scorecard/components/ScorecardSubpage.vue'
import { Action, Getter } from 'vuex-facing-decorator'
import { achievementSchemas, ScorecardEntity } from '@/entities/scorecard'
import { markAchievementSeen } from '@/api/achievements'
import Typography from '@/components/Rain/Typography/Typography.vue'
import AchievementToken from '@/components/Scorecard/AchievementToken.vue'
import AchievementSplash from '@/pages/scorecard/AchievementSplash.vue'

@Component({
    components: {
        AchievementSplash,
        AchievementToken,
        Typography,
        ScorecardSubpage,
    },
})
export default class AchievementBadgeDetail extends Vue {
    @Prop({ type: String })
    value!: string // achievement type

    @Prop({ type: String, default: null })
    teamMemberUserId!: string // view another users scorecard / my teams

    @Getter
    hasNetworkConnection!: boolean

    @Getter
    slide!: string

    @Getter
    scorecard!: ScorecardEntity

    @Getter
    scorecardOutdated!: boolean

    @Action
    setSlide

    @Action
    loadScorecard

    slideName = 'left'
    loading = true
    showSplash = false // whether to show achievement splash

    async beforeMount() {
        this.slideName = this.slide
    }

    mounted() {
        this.load()
    }

    async load() {
        this.loading = false

        // update the last seen timestamp of this badge
        if (
            !this.teamMemberUserId &&
            this.scorecard.achievements?.[this.value].countUnseen &&
            this.hasNetworkConnection
        ) {
            await markAchievementSeen(this.value)
            this.loadScorecard()
        }
    }

    get achievement() {
        return this.scorecard?.achievements?.[this.value] ?? undefined
    }

    get achievementLabel() {
        if (this.scorecard.achievements) {
            return this.achievement?.label ?? this.value
        }
        return ''
    }

    get badgeId() {
        if (this.scorecard.achievements) {
            return this.achievement?.badgeId ?? this.value
        }
        return ''
    }

    get rules() {
        return achievementSchemas?.[this.value]?.rules ?? ''
    }

    get total() {
        return this.achievement?.count ?? 0
    }

    get totalCalendarMonth() {
        return this.achievement?.countCalendarMonth ?? 0
    }

    get month() {
        return new Date().toLocaleString('default', { month: 'long' })
    }

    get tokens() {
        return this.achievement?.tokens ?? []
    }

    onClickToken() {
        this.showSplash = true
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/variables.less';
@import '~@/styles/rain/colour.less';

.achievement-detail-container {
    background: white;
    flex: 1;

    .header {
        text-align: center;
        padding: 20px 0;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        background: white;

        .title {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #2f384c;
            margin: @gutterSpacing-lg @gutterSpacing-lg @gutterSpacing-md
                @gutterSpacing-lg;
        }

        .rules {
            margin: @gutterSpacing-md @gutterSpacing-lg;

            p {
                color: @darkGrey;
            }
        }

        .hr {
            border-top: 1px solid @outline;
            margin: @gutterSpacing-lg @gutterSpacing-lg 0 @gutterSpacing-lg;
        }

        .stats {
            display: flex;
            align-items: center;
            justify-content: center;

            .month {
                margin-right: @gutterSpacing-lg;
            }

            .number {
            }

            .subtitle {
                p {
                    color: @darkGrey;
                }
            }
        }

        .tokens {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
}
</style>
