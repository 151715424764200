import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2e53848a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "scorecard-settings" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Typography = _resolveComponent("Typography")!
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([
                'accordion-option',
                { open: _ctx.openScorecardOptions, disabled: _ctx.loadingScorecards },
            ]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickScorecardDropdown && _ctx.onClickScorecardDropdown(...args)))
    }, [
      _createVNode(_component_Typography, {
        class: "accordion-label",
        variant: "body2"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.getAccordionLabel), 1)
        ], undefined, true),
        _: 1
      }),
      _createVNode(_component_FontAwesomeIcon, {
        class: _normalizeClass(['chevron-down icon', { open: _ctx.openScorecardOptions }]),
        icon: _ctx.chevronDown
      }, null, 8, ["class", "icon"])
    ], 2),
    (_ctx.showScorecardDropdown)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableScorecards, (scorecard) => {
            return (_openBlock(), _createElementBlock("div", {
              key: scorecard.id,
              class: "accordion-option",
              onClick: ($event: any) => (_ctx.onSelectNewScorecard(scorecard))
            }, [
              _createVNode(_component_Typography, {
                class: "accordion-label",
                variant: "body2"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(scorecard.name), 1)
                ], undefined, true),
                _: 2
              }, 1024),
              (_ctx.isSelectedScorecard(Number(scorecard.id)))
                ? (_openBlock(), _createBlock(_component_FontAwesomeIcon, {
                    key: 0,
                    class: "icon",
                    icon: _ctx.checkIcon
                  }, null, 8, ["icon"]))
                : _createCommentVNode("", true)
            ], 8, _hoisted_3))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}