<template>
    <WebViewScreen
        :title="suggestion ? suggestion.title : '...'"
        class="detail-container"
    >
        <AlertDialog
            :message="`You are about to delete this suggestion`"
            :show="showDeleteConfirmation"
            :ok-title="`Delete`"
            :on-cancel="cancelDelete"
            :on-ok="doDelete"
        />

        <template #left-button>
            <BackButton />
        </template>
        <template #right-button>
            <div v-if="canDelete" @click="askDelete">
                <img
                    src="../../components/WebView/icons/Trashcan.svg"
                    alt="Delete this suggestion"
                />
            </div>
        </template>

        <template v-if="suggestion">
            <div v-if="showActionButtons" class="action-buttons">
                <Button
                    variant="secondary"
                    @click="goToActionsPage(suggestion.id, 'not-now')"
                    >Not Now</Button>
                <Button
                    variant="primary"
                    @click="goToActionsPage(suggestion.id, 'good-idea')"
                    >Let's Do It</Button>
            </div>

            <SuggestionsCard>
                <SuggestionDialog
                    :title="suggestion ? suggestion.title : '...'"
                    :suggestion="suggestion"
                    :detail="true"
                    :in-dashboard="false"
                    :mention-users="suggestionMentionUsers"
                />

                <template v-if="actioningComment">
                    <hr />
                    <Dialog
                        :user="actioningComment.user"
                        :title="actioningComment.user.name"
                        :body="actioningComment.body"
                        :created="actioningComment.created"
                        :action="actioningComment.action"
                        :with-vertical-line="true"
                        type="comment"
                    />
                </template>
            </SuggestionsCard>

            <div class="suggestion-comments">
                <h5>COMMENTS</h5>

                <p v-if="displayComments.length === 0" class="no-comments">
                    No comments yet
                </p>

                <SuggestionsCard
                    v-for="comment in displayComments"
                    :key="comment.id"
                >
                    <Dialog
                        :user="comment.user"
                        :title="comment.user.name"
                        :body="comment.body"
                        :created="comment.created"
                        :action="comment.action"
                        :with-vertical-line="true"
                        type="comment"
                    />
                </SuggestionsCard>

                <div ref="commentsEnd" />
            </div>
        </template>
        <template v-if="!hasBeenActioned" #footer>
            <TextInput
                placeholder="Write a comment"
                submit-label="Post"
                :members="suggestionMentionUsers"
                @submit="handleCreateComment"
            />
        </template>
    </WebViewScreen>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { useRouter } from 'vue-router'
import { Action, Getter } from 'vuex-facing-decorator'
import WebViewScreen from '@/components/WebView/WebViewScreen.vue'
import TextInput from '@/components/WebView/TextInput.vue'
import Button from '@/components/WebView/Button.vue'
import SuggestionDialog from './SuggestionDialog.vue'
import SuggestionsCard from '@/pages/suggestions/SuggestionsCard.vue'
import AlertDialog from '@/components/AlertDialog.vue'
import Dialog from './Dialog.vue'
import BackButton from '@/mobile/src/components/BackButton.vue'
import {
    SuggestionComment,
    SuggestionCommentNew,
    SuggestionView,
} from '@/entities/suggestions'
import { IRecipient } from '@/entities/notices'

@Component({
    components: {
        BackButton,
        WebViewScreen,
        AlertDialog,
        SuggestionDialog,
        Dialog,
        TextInput,
        Button,
        SuggestionsCard,
    },
})
export default class SuggestionsScreen extends Vue {
    @Prop({ type: String }) public id!: string // from the route

    @Getter public getUserId!: number
    @Getter public getUserIsAdmin!: boolean
    @Getter public suggestionView?: SuggestionView
    @Getter public suggestionComments?: SuggestionComment[]
    @Getter public suggestionMentionUsers!: IRecipient[]

    @Action public setSuggestionComments
    @Action public createSuggestionComment!: (
        comment: SuggestionCommentNew
    ) => Promise<SuggestionComment>
    @Action public loadSuggestionMentionUsers
    @Action public setActiveSuggestion
    @Action public removeSuggestion
    @Action public cleanActiveSuggestion

    public showDeleteConfirmation = false

    private router = useRouter()

    public async mounted() {
        if (
            !this.suggestionMentionUsers ||
            !this.suggestionMentionUsers.length
        ) {
            await this.loadSuggestionMentionUsers()
        }

        await this.setActiveSuggestion(this.suggestionId)
        await this.setSuggestionComments(this.suggestionId)
    }

    public goBack() {
        this.cleanActiveSuggestion() // clean state to avoid displaying old data when enter the page next time
        this.router.back()
    }

    public async goToActionsPage(suggestionId: number, action: string) {
        await this.router.push({
            name: 'suggestionsAction',
            params: {
                id: suggestionId,
                action,
            },
        })
    }

    public cancelDelete() {
        this.showDeleteConfirmation = false
    }

    public doDelete() {
        this.showDeleteConfirmation = false
        if (this.removeSuggestion(this.suggestion)) {
            this.goBack()
        }
    }

    public askDelete() {
        this.showDeleteConfirmation = true
    }

    public get suggestion() {
        return this.suggestionView
    }

    public get suggestionId() {
        return parseInt(this.id)
    }

    public get canDelete() {
        return (
            this.suggestionView &&
            (this.suggestionView.user_id === this.getUserId ||
                this.getUserIsAdmin)
        )
    }

    public get hasBeenActioned() {
        return !!(
            this.suggestion && this.suggestion.actioning_suggestioncomment_id
        )
    }

    public get showActionButtons() {
        return this.getUserIsAdmin && this.suggestion && !this.hasBeenActioned
    }

    public get actioningComment(): SuggestionComment | false {
        if (!this.suggestionComments) {
            return false
        }
        return this.suggestionComments.find(({ action }) => !!action) || false
    }

    public get displayComments() {
        if (!this.suggestionComments) {
            return []
        }

        return this.suggestionComments
            .filter(({ action }) => !action)
            .slice()
            .sort((a, b) => a.created - b.created)
    }

    public async handleCreateComment(text: string, mentions: IRecipient[]) {
        const body = text.trim()
        if (body === '') {
            return
        }

        await this.createSuggestionComment({
            body,
            suggestion_id: this.suggestionId,
            mentions,
        })

        await this.setSuggestionComments(this.suggestionId)

        const anchor = this.$refs.commentsEnd as Element
        if (anchor) {
            anchor.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            })
        }
    }
}
</script>

<style scoped lang="less">
@import '../../styles/palette';

.detail-container {
    background: @greyLight;
}

hr {
    visibility: hidden;
}

.no-comments {
    color: #93a1be;
    font-size: 12px;
    margin-top: 4px;
}

.suggestion-comments {
    padding-bottom: 80px; /* to accommodate text input on footer */
    padding-left: 6px;
    padding-right: 6px;

    h5 {
        color: #93a1be;
        letter-spacing: 1px;
        font-size: 12px;
        line-height: 20px;
        font-family: 'Roboto', sans-serif;
        font-weight: normal;
        padding: 20px 0px 5px;
    }
}

.action-buttons {
    padding-bottom: 10px;
    display: flex;
    align-content: space-between;
    flex-shrink: 0;

    .button {
        display: block;
        border-radius: 4px;
        font-size: 12px;
        line-height: 14px;
        padding: 12px;
        margin: 0px 5px;
        text-align: center;
    }
}
</style>
