<template>
    <Scrollable
        :on-scroll-end="loadMore"
        :pull-down="refresh"
        class="suggestion-rows"
        bottom-padding
    >
        <Loading v-show="isRefreshing" :loading="isRefreshing" contained />
        <div v-show="!isRefreshing" class="suggestions-list">
            <SuggestionsCard
                v-for="suggestion in suggestionListView"
                :key="suggestion.id"
            >
                <SuggestionDialog
                    :suggestion="suggestion"
                    :in-dashboard="inDashboard"
                    :clip-length="suggestionClipLength"
                />
            </SuggestionsCard>
        </div>
    </Scrollable>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from 'vue-facing-decorator'
import { Action, Getter } from 'vuex-facing-decorator'
import { useRoute } from 'vue-router'
import Modal from '@/components/Modal.vue'
import SuggestionDialog from './SuggestionDialog.vue'
import { SuggestionView, SuggestionListQuery } from '../../entities/suggestions'
import Scrollable from '@/components/Scrollable.vue'
import SuggestionsCard from '@/pages/suggestions/SuggestionsCard.vue'
import Loading from '@/components/Loading.vue'

@Component({
    components: {
        Loading,
        SuggestionDialog,
        Modal,
        Scrollable,
        SuggestionsCard,
    },
})
export default class SuggestionsList extends Vue {
    @Prop({ type: Boolean, default: false }) public inDashboard?: boolean
    @Prop({ type: Number, default: 0 }) public suggestionClipLength!: number

    @Getter public suggestionListView!: SuggestionView[]
    @Getter public suggestionListQuery!: SuggestionListQuery

    @Action public loadSuggestionList
    @Action public loadSuggestionListMore

    private route = useRoute()

    public isRefreshing = false

    @Watch('route.path')
    public watchRoute() {
        this.refresh()
    }

    public mounted() {
        this.refresh()
    }

    public async refresh() {
        this.isRefreshing = true
        await this.loadSuggestionList({
            ...this.suggestionListQuery,
            mode: this.activeMode,
        })
        this.isRefreshing = false
    }

    public loadMore() {
        this.loadSuggestionListMore()
    }

    public get activeMode() {
        return this.route.params.mode || 'recent'
    }
}
</script>

<style scoped lang="less">
@import '../../styles/palette.less';

.suggestion-rows {
    flex: 1;
}

.suggestions-list {
    padding-top: 12px;
}

.not-seen {
    width: 8px;
    height: 8px;
    background: #ee2779;
    border: 2px solid #fff;
    border-radius: 10px;
    position: absolute;
    top: 10px;
    left: 37px;
}

.agreed-user-row {
    display: flex;
    height: 50px;
    .user-avatar {
        width: 32px;
        height: 32px;
        border-radius: 50%;
    }
    .user-name {
        line-height: 30px;
        font-size: 16px;
        margin-left: 12px;
        color: @grey90;
    }
}
</style>
