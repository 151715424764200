<template>
    <div id="recipient-bubble-list">
        <div
            v-for="(recipient, index) in selectedRecipients"
            :key="index"
            :class="[
                'recipient-bubble',
                composerType,
                { selected: confirmDelete },
            ]"
        >
            <Typography variant="body2" :class="['name-text', composerType]">
                {{ recipient.name }}
            </Typography>
        </div>

        <input
            id="recipient-search"
            ref="recipientSearch"
            :value="recipientSearchInput"
            :class="[composerType]"
            :size="searchInputWidth"
            :disabled="disableInput"
            type="text"
            @input="(event) => onSearchRecipient(event.target.value)"
            @keydown="(event) => onDeleteEntered(event)"
            @blur="setRecipientSearchInput('')"
        />
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { namespace } from 'vuex-facing-decorator'
import { MomentMessageRecipient } from '@/entities/moment'
import Typography from '@/components/Rain/Typography/Typography.vue'
import { recipientSearchInputFocusEvent } from '@/mobile/src/utils/messagecomposerbus'
import useEmitter from '@/composables/useEmitter'

const MomentsModule = namespace('moments')

@Component({
    components: {
        Typography,
    },
})
export default class SelectedRecipientsList extends Vue {
    @MomentsModule.Getter selectedRecipients!: MomentMessageRecipient[]
    @MomentsModule.Getter recipientSearchInput!: string
    @MomentsModule.Getter composerType

    @MomentsModule.Action removeLastSelectedRecipient
    @MomentsModule.Action setRecipientSearchInput

    public confirmDelete = false

    public emitter = useEmitter()

    public mounted() {
        this.emitter?.on(
            recipientSearchInputFocusEvent,
            this.focusRecipientInput
        )
    }

    public destroyed() {
        this.emitter?.off(
            recipientSearchInputFocusEvent,
            this.focusRecipientInput
        )
    }

    public onSearchRecipient(input) {
        this.resetDeleteState()
        this.setRecipientSearchInput(input)
    }

    public focusRecipientInput() {
        (this.$refs.recipientSearch as HTMLInputElement).focus()
    }

    public onDeleteEntered(event) {
        // Not optimal, but works with both Android & iOS
        const deleteKeyCode = 8
        if (event.keyCode === deleteKeyCode && !this.recipientSearchInput) {
            this.onDeleteDetected()
        }
    }

    public onDeleteDetected() {
        if (!this.recipientSearchInput) {
            if (!this.confirmDelete) {
                this.confirmDelete = true // Highlight the last recipient as confirmation
            } else {
                this.removeLastSelectedRecipient() // Reset highlight and delete last recipient
                this.resetDeleteState()
            }
        }
    }

    public resetDeleteState() {
        this.confirmDelete = false
    }

    // Responsive search input width based on input length
    public get searchInputWidth() {
        const buffer = 3
        if (this.recipientSearchInput.length > 0) {
            return this.recipientSearchInput.length + buffer
        }
        return buffer
    }

    public get disableInput() {
        return this.composerType === 'emailReply'
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/typography.less';
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';
@import '~@/styles/appV4/messageTypes.less';

#recipient-bubble-list {
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.name-text {
    padding: 4px 18px;
    border: 1px solid var(--type-colour);
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
    color: var(--type-colour);
}

.recipient-bubble {
    margin-right: 12px;
}

.recipient-bubble:last-of-type {
    &.selected {
        .name-text {
            background-color: @thinFog;
        }
    }
}

#recipient-search {
    .body2();
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
    color: var(--type-colour);
    height: 16px;
}
</style>
