<template>
    <div>
        <div class="horizontal-scroll-container metrics-container">
            <div class="scrolling-item">
                <div>
                    <InsightsMetric
                        :active="coachingMetricType === 'trending_topic'"
                        :delta="getScoreChange(trendingTopic)"
                        :title="getInsightTitle(trendingTopic)"
                        :skeleton="loading"
                        subtitle="Trending Topic"
                        @click="$emit('click', 'trending_topic')"
                    />
                </div>
            </div>
            <div class="scrolling-item">
                <div>
                    <InsightsMetric
                        :active="coachingMetricType === 'top_performer'"
                        :delta="getScoreChange(topPerformer)"
                        :title="getInsightTitle(topPerformer)"
                        :skeleton="loading"
                        subtitle="Top Performer"
                        @click="$emit('click', 'top_performer')"
                    />
                </div>
            </div>
            <div class="scrolling-item">
                <div>
                    <InsightsMetric
                        :active="coachingMetricType === 'biggest_change'"
                        :delta="getScoreChange(biggestChange)"
                        :title="getInsightTitle(biggestChange)"
                        :subtitle="getBiggestChangeSubtitle(biggestChange)"
                        :skeleton="loading"
                        change-icon="chevron"
                        @click="$emit('click', 'biggest_change')"
                    />
                </div>
            </div>
        </div>
        <InsightsGraph
            v-if="withGraph && graphData && graphData.length"
            :title="graphTitle"
            :data="graphData"
            :color="$palette.insightsCoaching"
            :height="graphHeight"
        />
        <SkeletonDiv
            v-else-if="withGraph && loading"
            :height="`${graphHeight}px`"
        />
    </div>
</template>

<script lang="ts">
import {
    CoachingInsightItem,
    CoachingInsights,
    CoachingMetricType,
} from '@/entities/insights'
import { Component, Prop, Watch, Vue } from 'vue-facing-decorator'
import { Getter } from 'vuex-facing-decorator'
import InsightsMetric from '@/mobile/src/components/Insights/InsightsMetric.vue'
import InsightsGraph from '@/mobile/src/components/Insights/InsightsGraph.vue'
import SkeletonDiv from '@/components/Skeletons/SkeletonDiv.vue'

@Component({
    components: {
        InsightsMetric,
        InsightsGraph,
        SkeletonDiv,
    },
    emits: ['click'],
})
export default class InsightsCoachingTiles extends Vue {
    private trendingTopic: CoachingInsightItem | any = {}
    private topPerformer: CoachingInsightItem | any = {}
    private biggestChange: CoachingInsightItem | any = {}

    @Prop({ type: String, default: null })
    private coachingMetricType!: CoachingMetricType

    @Prop({ type: Boolean, required: false, default: false })
    private withGraph!: boolean

    @Getter
    $isMobile

    public getScoreChange(insightItem: CoachingInsightItem): String | null {
        if (!insightItem) {
            return null
        }

        return insightItem?.score_change > 0 ? 'positive' : 'negative'
    }
    public getInsightTitle(insightItem: CoachingInsightItem): String | null {
        return insightItem?.title
    }

    public getBiggestChangeSubtitle(
        biggestChange: CoachingInsightItem
    ): String | null {
        return biggestChange?.score_change > 0
            ? 'Most Improved'
            : 'Biggest Decline'
    }

    private get graphHeight(): number {
        return this.$isMobile ? 80 : 240
    }

    @Getter
    readonly insightsCoaching!: CoachingInsights

    @Getter
    readonly friendlyShortTimeRange!: string

    @Getter
    readonly currentlyLoading!: string[]

    @Watch('insightsCoaching')
    public onInsightsCoachingChange() {
        this.setCoachingTiles()
    }

    async mounted() {
        this.setCoachingTiles()
    }

    get loading() {
        return this.currentlyLoading.includes('insightsCoaching')
    }

    get selectedMetricObj() {
        if (this.loading) {
            return {}
        }
        switch (this.coachingMetricType) {
            case 'biggest_change':
                return this.biggestChange ?? {}
            case 'top_performer':
                return this.topPerformer ?? {}
            case 'trending_topic':
                return this.trendingTopic ?? {}
            default:
                return {}
        }
    }

    get graphData() {
        return this.selectedMetricObj.graphData?.values ?? []
    }

    get graphTitle() {
        const suffix = ' NPS ' + this.friendlyShortTimeRange
        return this.selectedMetricObj.title
            ? this.selectedMetricObj.title + suffix
            : ''
    }

    public setCoachingTiles() {
        if (this.insightsCoaching?.tileData) {
            this.trendingTopic = this.insightsCoaching.tileData.trendingTopic
            this.biggestChange = this.insightsCoaching.tileData.biggestChange
            this.topPerformer = this.insightsCoaching.tileData.topPerformer
        }
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/variables';

.horizontal-scroll-container {
    margin-top: @gutterSpacing;
    margin-bottom: @gutterSpacing;
    overflow-x: auto;
    display: flex;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

.scrolling-item {
    flex: 0 0 auto;
    padding-right: @gutterSpacing;

    &:first-child {
        padding-left: @gutterSpacing;
    }
}
</style>
