<template>
    <OverCardModal :close-popup="onClosePopup">
        <template #header-center>
            <Typography
                variant="subtitle1"
                :class="['message-context-text', composerType]"
                >{{ messageType }}</Typography>
        </template>

        <template #default>
            <!-- Message Type Selector -->
            <div
                class="message-option"
                @click="
                    setTypePickerModalOpen({
                        isOpen: !typePickerModalOpen,
                        modalSource: modalSource,
                    })
                "
            >
                <Typography
                    variant="body2"
                    class="option-label"
                    :class="['message-context-text', composerType]"
                    >Type</Typography>
                <div class="option-content type">
                    <Typography
                        variant="body2"
                        :class="['message-context-text', composerType]"
                        >{{ messageType }}</Typography>
                    <FontAwesomeIcon
                        :icon="chevronDownIcon"
                        :class="['message-context-text', composerType]"
                    />
                </div>
            </div>
            <MessageTypePickerModal />

            <!-- Customer Reply Template Selector -->
            <div
                v-if="composerType === 'emailReply'"
                class="message-option"
                @click="() => (showReplyTemplateModal = true)"
            >
                <Typography
                    variant="body2"
                    class="option-label"
                    :class="['message-context-text', composerType]"
                >
                    Template
                </Typography>
                <div class="option-content type">
                    <Typography
                        variant="body2"
                        :class="['message-context-text', composerType]"
                    >
                        {{ templateName }}
                    </Typography>
                    <FontAwesomeIcon
                        :icon="chevronDownIcon"
                        :class="['message-context-text', composerType]"
                    />
                </div>
            </div>
            <ReplyTemplateSelectorModal
                :modal-open="showReplyTemplateModal"
                :loading-templates="loadingTemplates"
                @closeModal="() => (showReplyTemplateModal = false)"
            />

            <!-- Recipient Selector -->
            <div
                v-if="showToField"
                class="message-option to-field"
                @click="focusRecipientSearchInput"
            >
                <Typography
                    variant="body2"
                    class="option-label"
                    :class="['message-context-text', composerType]"
                >
                    To
                </Typography>
                <SelectedRecipientsList />
            </div>
            <div>
                <MessageRecipientPickerModal
                    pendo-name="recipient"
                    :search-input="recipientSearchInput"
                    :select-new="selectNewRecipient"
                    :set-search-input="setRecipientSearchInput"
                    @recipient-selected="focusRecipientSearchInput"
                />
            </div>

            <MomentTextBox
                :value="mentionText"
                placeholder="Write the message here"
                class="moment-modal-rich-text"
                @input="setMentionText"
            />
        </template>

        <template #footer>
            <MomentModalFooter
                :can-send-message="sendButtonDisabled"
                :can-add-image="canAddImage"
                @send="onSendMoment"
            />
        </template>
    </OverCardModal>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { namespace, Getter } from 'vuex-facing-decorator'
import useEmitter from '@/composables/useEmitter'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faChevronDown } from 'fontawesome/free-solid-svg-icons'
import MomentTextBox from '@/mobile/src/components/appV4/MomentTextBox.vue'
import { focusRecipientSearch } from '@/mobile/src/utils/messagecomposerbus'
import { MomentMessageRecipient } from '@/entities/moment'
import OverCardModal from '@/mobile/src/components/appV4/OverCardModal.vue'
import MomentModalFooter from '@/mobile/src/components/appV4/MomentModalFooter.vue'
import Typography from '@/components/Rain/Typography/Typography.vue'
import MessageTypePickerModal from '@/mobile/src/components/appV4/MessageTypePickerModal.vue'
import MessageRecipientPickerModal from '@/mobile/src/components/appV4/MessageRecipientPickerModal.vue'
import SelectedRecipientsList from '@/mobile/src/components/appV4/SelectedRecipientsList.vue'
import ReplyTemplateSelectorModal from '@/mobile/src/components/appV4/ReplyTemplateSelectorModal.vue'
import { MessageTypeLabel, ModalSource } from '@/mobile/src/types/messageTypes'
import { ChatTemplate } from '@/entities'
import { hideToast, showToast } from '@/mobile/src/utils/toastbus'
import { ToastColor, ToastIcon } from '@/mobile/src/types/toasts'
import { pendoTrackEvent } from '@/utils/pendo'
import { Dialog } from '@capacitor/dialog'
import { cleanMomentText } from '@/utils/moments'

const MomentsModule = namespace('moments')

@Component({
    components: {
        ReplyTemplateSelectorModal,
        MomentTextBox,
        SelectedRecipientsList,
        MessageRecipientPickerModal,
        MessageTypePickerModal,
        FontAwesomeIcon,
        Typography,
        MomentModalFooter,
        OverCardModal,
    },
    emits: ['message-sent'],
})
export default class MomentModal extends Vue {
    @Prop({ type: Function, required: false }) private closePopup!: Function
    @Prop({ type: String, required: true }) private modalSource!: string
    @Prop({ type: String, required: false, default: 'Everyone' })
    private selectedRecipientName!: string
    @Prop({ type: String, default: null }) momentid?: string

    @MomentsModule.Getter typePickerModalOpen!: boolean
    @MomentsModule.Getter recipientSearchInput!: string
    @MomentsModule.Getter composerImageUploading!: boolean

    @MomentsModule.Action setTypePickerModalOpen
    @MomentsModule.Action setRecipientSearchInput

    @Getter chatMessages
    @Getter chatReply
    @Getter userId!: number

    @MomentsModule.Getter composerType!: string
    @MomentsModule.Getter availableComposerRecipients!: MomentMessageRecipient[]
    @MomentsModule.Getter loadingRecipients!: boolean
    @MomentsModule.Getter selectedRecipients!: MomentMessageRecipient[]
    @MomentsModule.Getter mentionText!: string
    @MomentsModule.Getter subject!: string
    @MomentsModule.Getter showToField!: boolean
    @MomentsModule.Getter getComposerSource!: string
    @MomentsModule.Getter customerReplyTemplates!: ChatTemplate[]
    @MomentsModule.Getter selectedReplyTemplate!: ChatTemplate | null

    @MomentsModule.Action loadComposerRecipients
    @MomentsModule.Action selectNewRecipient
    @MomentsModule.Action setMentionText
    @MomentsModule.Action setSubject
    @MomentsModule.Action resetComposer
    @MomentsModule.Action setComposerSource
    @MomentsModule.Action initAvailableComposerTypes
    @MomentsModule.Action sendMoment

    private emitter = useEmitter()

    public chevronDownIcon = faChevronDown

    public baseMomentId: string | undefined = undefined
    public baseClosePopup!: Function
    public sendingMoment = false
    public showReplyTemplateModal = false
    public loadingTemplates = false
    public sendingToastTimeId = 0
    public baseModalSource = ModalSource.MomentsScreen.toString()

    public async created() {
        if (this.modalSource !== this.getComposerSource) {
            await this.resetComposer()
            await this.setComposerSource(this.modalSource)
        }

        if (
            !this.availableComposerRecipients.length &&
            !this.loadingRecipients
        ) {
            await this.loadComposerRecipients(this.userId)
        }

        if (this.selectedRecipientName && this.composerType !== 'emailReply') {
            this.selectNewRecipient(
                this.availableComposerRecipients.find(
                    (recipient) =>
                        recipient.name?.toLowerCase() ==
                        this.selectedRecipientName?.toLowerCase()
                )
            )
        }

        this.initAvailableComposerTypes(this.modalSource)

        // Prefill email reply for reply templates
        if (
            (this.chatReply.message || this.chatReply.subject) &&
            this.composerType === 'emailReply'
        ) {
            this.setMentionText(this.chatReply.message)
            this.setSubject(this.chatReply.subject)
        }

        this.baseMomentId = this.momentid
        this.baseClosePopup = this.closePopup
    }

    protected hasMessageText() {
        const div = document.createElement('div')
        div.innerHTML = this.mentionText
        return (div.textContent || div.innerText || '').trim().length > 0
    }

    public get messageType() {
        return MessageTypeLabel[this.composerType]
    }

    public get templateName() {
        return this.selectedReplyTemplate?.name ?? 'Load a reply template'
    }

    public async onSendMoment() {
        if (this.sendingMoment) {
            return
        }
        this.showSendingToast()

        try {
            const data = await this.sendMoment({
                momentid: this.baseMomentId,
                mentionText: this.mentionText,
                chatMessages: this.chatMessages,
            })

            this.hideSendingToast()
            if (data.success) {
                this.showSuccessToast()
                this.baseClosePopup()
                if (this.baseModalSource !== ModalSource.FeedbackDetailScreen) {
                    this.resetComposer()
                }
                this.$emit('message-sent')
                this.setMentionText('')

                return
            }
        } catch (error) {
            this.hideSendingToast()
        }
        this.showErrorToast()
    }

    public showErrorToast() {
        showToast(
            this.emitter,
            ToastIcon.Alert,
            ToastColor.Warning,
            `${this.messageType} failed to send`,
            2000
        )
        pendoTrackEvent(`message_composer_error_toast`, {
            type: this.messageType,
        })
    }

    public showSuccessToast() {
        showToast(
            this.emitter,
            ToastIcon.Success,
            this.getToastColor(),
            `${this.messageType} sent`,
            2000
        )
    }

    public showSendingToast() {
        this.sendingMoment = true
        this.sendingToastTimeId = showToast(
            this.emitter,
            ToastIcon.Waiting,
            this.getToastColor(),
            `Sending...`,
            0
        )
    }

    public get sendButtonDisabled() {
        return (
            this.typePickerModalOpen ||
            this.sendingMoment ||
            (this.composerType !== 'internalNote' &&
                this.selectedRecipients.length === 0) ||
            !this.hasMessageText() ||
            this.composerImageUploading
        )
    }

    public get canAddImage() {
        const supportedTypes = ['shoutout', 'coaching', 'message']
        const supportedSources = [
            ModalSource.MomentsScreen.toString(),
            ModalSource.StaffProfileScreen.toString(),
        ]
        return (
            supportedTypes.includes(this.composerType) &&
            supportedSources.includes(this.modalSource)
        )
    }

    public focusRecipientSearchInput() {
        focusRecipientSearch()
    }

    public hideSendingToast() {
        if (this.sendingToastTimeId) {
            hideToast(this.emitter, this.sendingToastTimeId)
            this.sendingToastTimeId = 0
        }
        this.sendingMoment = false
    }

    protected getToastColor() {
        switch (this.messageType) {
            case MessageTypeLabel.coaching:
                return ToastColor.Coaching
            case MessageTypeLabel.shoutout:
                return ToastColor.Shoutout
            case MessageTypeLabel.internalNote:
                return ToastColor.InternalNote
            case MessageTypeLabel.emailReply:
                return ToastColor.EmailReply
        }
        return ToastColor.Message
    }

    //TODO: Clean this up after we move to Vue 3 and we can more easily share logic again
    public async onClosePopup() {
        if (this.mentionText && cleanMomentText(this.mentionText)) {
            const { value } = await Dialog.confirm({
                title: 'Discard Message?',
                message: 'Are you sure you want to discard your message?',
            })
            if (!value) {
                return
            }
        }
        await this.resetComposer()
        if (this.closePopup) {
            this.closePopup()
        }
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';
@import '~@/styles/appV4/messageTypes.less';

.message-context-text {
    color: var(--type-colour);
}

.moment-modal-rich-text {
    flex-grow: 1;
    overflow: scroll;
}

.message-option {
    padding: 15px @featureGutter;
    border-bottom: @borderWidth solid @thinFog;
    display: flex;

    .option-label {
        min-width: 64px;
        margin-right: @featureGutter;
    }

    .option-content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .recipients {
            width: 100%;
        }

        .close-recipients-list-icon {
            width: 16px;
        }
    }
}

.to-field {
    overflow-x: auto;
    scroll-behavior: smooth;
    display: flex;
    align-items: center;
    flex-shrink: 0;
}

.to-field::-webkit-scrollbar {
    display: none;
}
</style>
