<template>
    <div class="feedback-filter">
        <hr class="divider" />
        <div class="header-content">
            <div class="filter-tabs-container">
                <div
                    :class="[
                        'filter-tab',
                        { selected: filterTab === 'general' },
                    ]"
                    @click="switchTab('general')"
                >
                    <Typography component="span" class="filter-tab-text">
                        General
                    </Typography>
                    <div class="count-container">
                        <span
                            :class="[
                                'filter-count',
                                { 'has-count': activeGeneralFilterCount > 0 },
                            ]"
                            >{{ activeGeneralFilterCount }}</span>
                    </div>
                </div>
                <div
                    v-if="canViewNameEmail"
                    :class="[
                        'filter-tab',
                        { selected: filterTab === 'custom' },
                    ]"
                    @click="switchTab('custom')"
                >
                    <Typography component="span" class="filter-tab-text">
                        Custom
                    </Typography>
                    <div class="count-container">
                        <span
                            :class="[
                                'filter-count',
                                { 'has-count': activeCustomFilterCount > 0 },
                            ]"
                            >{{ activeCustomFilterCount }}</span>
                    </div>
                </div>
            </div>
            <hr class="divider pixel-up" />
        </div>
        <div class="filter-content">
            <div v-if="loading" key="loading" class="loading-area">
                <LoadingFilters />
            </div>
            <transition v-else name="slide-tab-1">
                <div
                    v-if="filterTab === 'general'"
                    key="general"
                    class="filter-sets"
                >
                    <FilterSet
                        label="Time Filters"
                        :filter-set="timeFilterSet"
                        class="filter-set"
                        :class="[
                            'filter-set',
                            {
                                unfocus: focusedCustomFilter !== '',
                            },
                        ]"
                        @selectFilter="selectTimeFilter"
                    />
                    <FilterSet
                        v-for="rule in validGeneralRuleOptions"
                        :key="rule.value"
                        :label="rule.label"
                        :filter-type="rule.value"
                        :filter-set="ruleSubOptions[rule.value]"
                        :close-search="focusedCustomFilter === ''"
                        filter-category="general"
                        :select-mode="getGeneralFilterSelectMode(rule.value)"
                        :select-filter-loading="filterLoading"
                        :class="[
                            'filter-set',
                            {
                                unfocus:
                                    focusedCustomFilter !== '' &&
                                    focusedCustomFilter !== rule.value,
                            },
                        ]"
                        @addMultiSubOption="addMultiSubOption"
                        @selectFilter="handleClickOption"
                        @focus="onFocusMultiSelectFilter(rule.value, 'general')"
                        @unfocus="onUnfocusMultiSelectFilter"
                    />
                </div>
            </transition>
            <transition name="slide-tab-2">
                <div
                    v-if="filterTab === 'custom'"
                    key="custom"
                    class="filter-sets"
                >
                    <FilterSet
                        v-for="rule in validCustomRuleOptions"
                        :key="rule.value"
                        :label="rule.label"
                        :filter-type="rule.value"
                        :filter-set="ruleSubOptions[rule.value]"
                        :close-search="focusedCustomFilter === ''"
                        :class="[
                            'filter-set',
                            {
                                unfocus:
                                    focusedCustomFilter !== '' &&
                                    focusedCustomFilter !== rule.value,
                            },
                        ]"
                        :select-filter-loading="filterLoading"
                        filter-category="custom"
                        select-mode="multi"
                        @selectFilter="handleClickOption"
                        @addMultiSubOption="addMultiSubOption"
                        @focus="onFocusMultiSelectFilter(rule.value)"
                        @unfocus="onUnfocusMultiSelectFilter"
                    />
                </div>
            </transition>
        </div>
        <hr class="divider" />
        <div
            class="filter-footer"
            :class="{ unfocus: focusedCustomFilter !== '' }"
        >
            <Typography
                component="span"
                class="navigation-text"
                @click="resetFilters"
            >
                Reset Filters
            </Typography>
        </div>
        <hr class="divider" />
        <Modal
            :show="showCustomDatePicker"
            :close="closeDatePicker"
            :width="'90vw'"
            :shake="invalidDateRange"
            vanilla
            centered
            has-close
            allow-click-out
            allow-escape
        >
            <div class="date-picker-modal">
                <Typography component="span" class="date-title">
                    STARTING DATE
                </Typography>
                <span
                    class="date-input-trigger"
                    @click="openDatePicker($refs.startDateRef)"
                    >{{ customStartDate.format('ddd, MMM D, YYYY') }}</span>
                <input
                    ref="startDateRef"
                    type="date"
                    class="date-input"
                    :value="customStartDate.format('YYYY-MM-DD')"
                    @change="(e) => customDateStartDateChange(e)"
                />
                <Typography component="span" class="date-title">
                    END DATE
                </Typography>
                <span
                    class="date-input-trigger"
                    @click="openDatePicker($refs.endDateRef)"
                    >{{ customEndDate.format('ddd, MMM D, YYYY') }}</span>
                <input
                    ref="endDateRef"
                    type="date"
                    class="date-input"
                    :value="customEndDate.format('YYYY-MM-DD')"
                    @change="(e) => customDateEndDateChange(e)"
                />
                <Typography
                    v-show="invalidDateRange"
                    component="span"
                    class="date-title date-range-note"
                >
                    The start date must be before the end date
                </Typography>
                <PillButton
                    class="apply-button"
                    :label="'APPLY'"
                    @click="saveCustomDateFilter"
                />
            </div>
        </Modal>
    </div>
</template>

<script lang="ts">
import { nextTick } from 'vue'
import { Component, Watch, Vue } from 'vue-facing-decorator'
import useEmitter from '@/composables/useEmitter'
import { Action, Getter } from 'vuex-facing-decorator'
import Typography from '@/components/Rain/Typography/Typography.vue'
import Modal from '@/components/Modal.vue'
import FilterSet from '@/mobile/src/components/appV4/FilterSet.vue'
import LoadingFilters from '@/mobile/src/components/appV4/LoadingFilters.vue'
import PillButton from '@/components/PillButton.vue'
import moment from 'moment'
import {
    FilterOptionV4,
    FilterRuleOperator,
    FilterSetV4,
    getGeneralMultiSelectFilters,
    IFilterRule,
    IFilterTime,
    MultiFilterOptionV4,
} from '@/entities'
import { getTranslatedTimeFilterOption } from '@/mobile/src/utils/filter'
import { getPlatform } from '@/mobile/src/utils/capacitor'
import { updateFilterCount } from '@/mobile/src/utils/headerbus'
import { Permissions } from '@/mobile/src/types/auth'
import { scrollActiveFiltersIntoView } from '@/mobile/src/utils/misc'

@Component({
    components: {
        FilterSet,
        LoadingFilters,
        Modal,
        PillButton,
        Typography,
    },
    emits: ['resettingFilters'],
})

/**
 * Reference: FilterBarNPSType.vue.
 */
export default class FeedbackFilter extends Vue {
    // Reference: modules/filter.ts
    @Getter public mobileFilterActive
    @Getter public filterRuleOptions
    @Action public getRuleOptions
    @Action public getRuleOptionsWithSubOptions
    @Getter public filterActiveRuleSubOption
    @Getter public filterActiveRuleSubOptions
    @Getter public filterTimeLabel
    @Getter public filterTimeOptions
    @Getter public mobileQuestionType

    @Action public loadRuleSubOptions
    @Action public setFilterRule
    @Action public setFilterTime
    @Action public getFilters
    @Action public removeFilterRule
    @Action public removeAllFilters
    @Action public removeAllFiltersAndResetTime
    @Action public setFilterQuestionType
    @Action public patchFilter

    @Getter permissions

    private emitter = useEmitter()

    private ruleSubOptions: {
        [filterType: string]: FilterSetV4
    } = {}
    private ruleOptions: [] | any = []
    private allButtonValue = 'any'
    private optionAll = { label: 'All', value: this.allButtonValue }
    private defaultTimeValue = 'day:30'
    private filterClicked = Math.random()
    private activeGeneralFilterCount = 0
    private activeCustomFilterCount = 0
    private options: [] | any = []
    private timeFilterSet: FilterSetV4 = {
        selected: [],
        filterOptions: [],
    }
    private showCustomDatePicker = false
    private filterTab = 'general'
    private loading = true
    private filterLoading = false

    private customStartDate = moment(new Date())
    private customEndDate = moment(new Date())

    private invalidDateRange = false

    private previousTimeFilterSelection
    private platform = 'desktop'

    private focusedCustomFilter = ''

    // Filters in general tab that can be multi select
    private generalMultiSelectFilters = getGeneralMultiSelectFilters()

    //This is not manually set at the moment. In case we need this later.
    public operators: FilterRuleOperator[] = [
        {
            name: 'is  ',
            operator: 'in',
        },
        {
            name: 'is not  ',
            operator: 'notin',
        },
        {
            name: 'Greater than  ',
            operator: 'gt',
        },
        {
            name: 'Less than  ',
            operator: 'lt',
        },
    ]

    public hashtagOperators = [
        {
            label: 'Has Hashtags',
            value: 'has_hashtags',
        },
        {
            label: 'None',
            value: 'has_no_hashtags',
        },
    ]

    public filterRuleOperator: FilterRuleOperator = this.operators[0]

    async created() {
        await this.getFilters()
        await this.removeCustomFilters()
    }

    async mounted() {
        await this.getRuleOptionsWithSubOptions()
        this.ruleOptions = this.filterRuleOptions
        this.loadingRuleSubOptions()
        this.platform = await getPlatform()
        this.loading = false

        nextTick(() => scrollActiveFiltersIntoView())
    }

    private async removeCustomFilters() {
        if (
            !this.permissions.includes(Permissions.RoleViewNameEmailCustomData)
        ) {
            // Remove CUSTOM filter rules
            const newFilterRules = this.mobileFilterActive[
                'filter_rules'
            ].filter((filter) => filter.source !== 'custom')

            // Only if the filterRules are changed.
            if (
                newFilterRules.length !==
                this.mobileFilterActive['filter_rules'].length
            ) {
                // Update filter rules to server side.
                const patch = {
                    filter_rules: newFilterRules,
                    id: this.mobileFilterActive.id,
                }
                this.patchFilter(patch)
                // reload the filter rules
                await this.getFilters()
            }
        }
    }

    private get validCustomRuleOptions() {
        return this.ruleOptions.custom.filter(
            (rule) => rule.options.length >= 1
        )
    }

    private get validGeneralRuleOptions() {
        return this.ruleOptions.general.filter(
            (rule) => rule.options.length >= 1
        )
    }

    private onFocusMultiSelectFilter(key, category = 'custom') {
        // Only allow focus on custom or multi select filters
        if (
            category === 'general' &&
            this.getGeneralFilterSelectMode(key) === 'single'
        ) {
            return
        }
        this.focusedCustomFilter = key
    }

    private onUnfocusMultiSelectFilter() {
        this.focusedCustomFilter = ''
    }

    private switchTab(newTab = 'general') {
        this.filterTab = newTab
        this.loading = false
    }

    private loadingRuleSubOptions() {
        this.loadOptionsForGroup('general')
        this.loadOptionsForGroup('custom')

        this.generateTimeFilterSet()
        this.countFilters()
    }

    private loadOptionsForGroup(group: string): void {
        this.ruleOptions[group].map((option) => {
            if (option.value === 'tag') {
                // Filter out operators that already exist in option.options
                const newOperators = this.hashtagOperators.filter(
                    (op) =>
                        !option.options?.some(
                            (existingOp: { value: string }) =>
                                existingOp.value === op.value
                        )
                )

                //Only add the operator if it does not already exist.
                if (newOperators.length > 0) {
                    option.options?.unshift(...newOperators)
                }
            }

            let selectedFilters: FilterOptionV4[] = []
            this.mobileFilterActive.filter_rules.filter((rule) => {
                if (rule.column === option.value) {
                    rule.value.forEach((ruleValue) => {
                        let ruleFilterOption = option.options?.find(
                            (filterOpt) => filterOpt.value === ruleValue
                        )
                        if (ruleFilterOption) {
                            selectedFilters.push(ruleFilterOption)
                        }
                    })
                }
            })

            if (selectedFilters.length === 0) {
                selectedFilters.push(this.optionAll)
            }

            if (!option.options) {
                option.options = []
            }
            this.ruleSubOptions[option.value] = {
                selected: selectedFilters,
                filterOptions: [this.optionAll, ...option.options],
            }

            // Sorts custom filter sub options based on selected status
            if (group === 'custom') {
                this.sortRuleSubOptions(option.value)
            }
        })
    }

    private generateTimeFilterSet(): FilterSetV4 {
        if (this.timeFilterSet.filterOptions.length > 0) {
            // Return time filters as is, if it's already generated
            // return this.timeFilterSet
        }

        let newFilterSet: FilterSetV4 = {
            selected: [
                {
                    label: 'Last 30 Days',
                    value: 'day:30',
                },
            ],
            filterOptions: [],
        }

        for (const timeFilterOptionSuper of this.filterTimeOptions) {
            // Superset grouping of time filters.
            // Just 3: Relative Time (Custom, YTD, etc), Jan-Jun, Jul-Dec
            for (const timeFilterOptionSub of timeFilterOptionSuper as IFilterTime[]) {
                const translatedFilterOption =
                    getTranslatedTimeFilterOption(timeFilterOptionSub)
                newFilterSet.filterOptions.push({
                    label: translatedFilterOption.label,
                    value: translatedFilterOption.value,
                } as FilterOptionV4)
            }
        }

        if (this.mobileFilterActive?.time_unit === 'custom') {
            const dateRange = this.mobileFilterActive.time_value.split('_')
            this.customStartDate = moment(dateRange[0], 'DD-MM-YYYY')
            this.customEndDate = moment(dateRange[1], 'DD-MM-YYYY')
        }

        // Get selected filter
        if (this.mobileFilterActive) {
            newFilterSet.selected = [
                {
                    label: this.mobileFilterActive.time_unit,
                    value: this.getTranslatedMonthToDayValues(
                        this.mobileFilterActive.time_unit,
                        this.mobileFilterActive.time_value
                    ),
                },
            ]
        }
        this.timeFilterSet = newFilterSet

        return this.timeFilterSet
    }

    private async resetFilters() {
        // Collapse Filter dropdown immediately after click.
        this.filterClicked = Math.random()
        // Hold the data reloading until all Reset processes finished.
        this.$emit('resettingFilters', true)
        // This will remove all filters, including question_type
        // Also resets time filter to "Last 30 Days" (default)
        await this.removeAllFiltersAndResetTime({
            id: this.mobileFilterActive.id,
            time_unit: 'day',
            time_value: '30',
        })

        // update the data for Filters view.
        this.loadingRuleSubOptions()
        // Add question_type filter back.
        await this.setFilterQuestionType({
            id: this.mobileFilterActive.id,
            question_type: this.mobileQuestionType,
        })

        this.$emit('resettingFilters', false)
    }

    /**
     * Shim to translate month queries to day queries
     * @private
     */
    private getTranslatedMonthToDayValues(
        timeUnit: string,
        timeValue: string
    ): string {
        let result = timeUnit + ':' + timeValue

        if (timeUnit === 'custom') {
            result = 'custom'
        }

        if (timeUnit === 'month' && ['1', '2', '3', '6'].includes(timeValue)) {
            switch (timeValue) {
                case '1':
                    result = 'day:30'
                    break
                case '2':
                    result = 'day:60'
                    break
                case '3':
                    result = 'day:90'
                    break
                case '6':
                    result = 'day:180'
                    break
            }
        }

        return result
    }

    private async selectTimeFilter(filterOption: MultiFilterOptionV4) {
        // get the previous selection in case the user wants to cancel their selection
        this.previousTimeFilterSelection = this.timeFilterSet.selected

        const option: FilterOptionV4 = {
            label: filterOption.label,
            value: filterOption.value[0],
        }
        this.timeFilterSet.selected = [option]

        const split = this.timeFilterSet.selected[0].value.split(':')
        const timeUnit = split[0] ?? ''
        const timeValue = split[1] ?? ''

        if (timeUnit === 'custom') {
            this.showCustomDatePicker = true
        } else {
            this.filterClicked = Math.random()
            await this.setFilterTime({
                id: this.mobileFilterActive.id,
                time_unit: timeUnit,
                time_value: timeValue,
            })
        }
    }

    private openDatePicker(ref) {
        if (this.platform === 'ios') {
            ref.focus()
        } else {
            if ('showPicker' in HTMLInputElement.prototype) {
                ref.showPicker()
                return
            }
            ref.click()
        }
    }

    private closeDatePicker() {
        this.timeFilterSet.selected = this.previousTimeFilterSelection
        this.showCustomDatePicker = false
        this.invalidDateRange = false
    }

    private customDateStartDateChange(e) {
        this.customStartDate = moment(e.target.valueAsNumber)
    }

    private customDateEndDateChange(e) {
        this.customEndDate = moment(e.target.valueAsNumber)
    }

    private async saveCustomDateFilter() {
        if (
            this.customStartDate.unix() &&
            this.customEndDate.unix() &&
            this.customStartDate.unix() < this.customEndDate.unix()
        ) {
            this.invalidDateRange = false
            this.showCustomDatePicker = false
            this.filterClicked = Math.random()
            await this.setFilterTime({
                id: this.mobileFilterActive.id,
                time_unit: 'custom',
                time_value: `${this.customStartDate.format(
                    'DD-MM-YYYY'
                )}_${this.customEndDate.format('DD-MM-YYYY')}`,
            })
        } else {
            this.invalidDateRange = true
        }
    }

    public async addMultiSubOption(filterType: string, option: FilterOptionV4) {
        const hasAny = this.ruleSubOptions[filterType].selected.some(
            (element) => element.value === 'any'
        )

        if (!hasAny && option.value.includes('any')) {
            this.ruleSubOptions[filterType].selected = [this.optionAll]

            return
        }

        if (
            filterType === 'tag' &&
            this.hashtagOperators.some((opt) => opt.value === option.value)
        ) {
            const selectedOption =
                this.hashtagOperators.find(
                    (operator) => operator.value === option.value
                ) ?? this.optionAll

            this.ruleSubOptions[filterType].selected = [selectedOption]

            return
        }

        // If this is a newly selected option
        if (
            !this.ruleSubOptions[filterType].selected.some(
                (element) => element.value === option.value
            )
        ) {
            // Remove ALL if that was the previously selected option
            if (hasAny) {
                this.ruleSubOptions[filterType].selected = []
            }

            // Remove has_hashtags and has_no_hashtags from multiple options in the Hashtag filter
            if (filterType === 'tag') {
                this.ruleSubOptions[filterType].selected = this.ruleSubOptions[
                    filterType
                ].selected.filter((option) => {
                    return (
                        option.value !== 'has_hashtags' &&
                        option.value !== 'has_no_hashtags'
                    )
                })
            }

            this.ruleSubOptions[filterType].selected.push(option)
        } else {
            // This is already a selected option, deselect it
            this.ruleSubOptions[filterType].selected = this.ruleSubOptions[
                filterType
            ].selected.filter((element) => element.value !== option.value)

            // If all options have been deselected, reset to ALL
            if (this.ruleSubOptions[filterType].selected.length === 0) {
                this.ruleSubOptions[filterType].selected = [this.optionAll]
                this.sortRuleSubOptions(filterType, 'original')
                return
            }
        }

        // Sort the selected option to the front
        this.sortRuleSubOptions(filterType)
    }

    /**
     * Sorts custom filter options
     * 'original' will sort it back to the original order loaded from API
     * 'selected' will sort putting selected options first
     */
    public sortRuleSubOptions(
        filterType: string,
        order: 'original' | 'selected' = 'selected'
    ): void {
        // Don't sort general multiselect filters
        if (this.generalMultiSelectFilters.includes(filterType)) {
            return
        }

        let arrayToSortBy = this.ruleSubOptions[filterType].selected
        if (order === 'original') {
            const index = this.ruleOptions.custom.findIndex(
                (obj) => obj.value === filterType
            )
            arrayToSortBy = this.ruleOptions.custom[index].options
        }

        this.ruleSubOptions[filterType].filterOptions = this.ruleSubOptions[
            filterType
        ].filterOptions.sort((a, b) => {
            const indexA = arrayToSortBy.findIndex(
                (obj) => obj.value === a.value
            )
            const indexB = arrayToSortBy.findIndex(
                (obj) => obj.value === b.value
            )

            if (a.value === this.allButtonValue) {
                return -1 // Apply fixed sort order for ALL
            }

            if (b.value === this.allButtonValue) {
                return 1 // Apply fixed sort order for ALL
            }

            if (order === 'original') {
                return indexA - indexB
            }

            return indexB - indexA
        })
    }

    public async handleClickOption(
        option: MultiFilterOptionV4,
        selectMode: string
    ) {
        if (this.filterLoading) {
            return
        }

        // Set filterLoading to true so user cant click on another filter while the request has not finished
        this.filterLoading = true
        // highlight the selected Filter button for single options
        if (option.filterType && selectMode == 'single') {
            const singleOption: FilterOptionV4 = {
                label: option.label,
                value: option.value[0],
            }
            this.ruleSubOptions[option.filterType].selected = [singleOption]
        }
        // Put this before this.setFilterRule() to collapse Filter dropdown immediately once clicked.
        this.filterClicked = Math.random()

        // if we are setting a field to any, we can treat this as if we are resetting the filters to default for that field
        if (option.value.includes('any')) {
            await this.removeFilterRule({
                id: this.mobileFilterActive.id,
                column: option.filterType,
                operator: this.filterRuleOperator.operator,
            })
        } else {
            await this.setFilterRule({
                id: this.mobileFilterActive.id,
                rule: {
                    column: option.filterType,
                    operator: this.filterRuleOperator.operator,
                    source: option.filterCategory,
                    value: option.value,
                },
            })
        }

        this.filterLoading = false
    }

    public getGeneralFilterSelectMode(ruleValue) {
        return this.generalMultiSelectFilters.includes(ruleValue)
            ? 'multi'
            : 'single'
    }

    @Watch('mobileFilterActive', { deep: true })
    public countFilters() {
        if (this.mobileFilterActive) {
            // Count the non-time filters applied, except for question_type.
            const validFilters = this.mobileFilterActive.filter_rules.filter(
                (filter: IFilterRule) => {
                    return (
                        filter.column !== 'question_type' &&
                        filter.value[0] !== this.allButtonValue
                    )
                }
            )

            const generalFilters = validFilters.filter(
                (filter: IFilterRule) => {
                    // Source is not present for multi select filters
                    return (
                        filter.source !== 'custom' ||
                        this.generalMultiSelectFilters.includes(filter.column)
                    )
                }
            )

            // Include time filter in counting for the general filter, if null while loading don't count
            const countTimeFilter =
                this.timeFilterSet.selected.length === 0 ||
                this.timeFilterSet.selected[0].value === this.defaultTimeValue
                    ? 0
                    : 1
            this.activeGeneralFilterCount =
                generalFilters.length + countTimeFilter

            // count the total number of custom filters
            const customFilters = validFilters.filter((filter: IFilterRule) => {
                return filter.source === 'custom'
            })

            this.activeCustomFilterCount = customFilters.length

            //TODO:  Get rid of this after refactoring the above into vuex
            updateFilterCount(
                this.emitter,
                this.activeGeneralFilterCount + this.activeCustomFilterCount
            )
        }
    }

    private get canViewNameEmail() {
        return this.permissions.includes(
            Permissions.RoleViewNameEmailCustomData
        )
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';
@import '~@/styles/palette';

.date-picker-modal {
    display: flex;
    flex-direction: column;
    gap: 10px 0;
    padding: 20px;
    background: rgba(0, 0, 0, 0.9);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(20px);
    border-radius: 8px;

    .date-title {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 1px;
        text-transform: capitalize;
        color: @grey;

        &.date-range-note {
            text-transform: none;
        }
    }

    .date-input {
        opacity: 0;
        height: 0;
    }

    .date-input-trigger {
        padding: 10px;
        border: 1px solid @grey;
        border-radius: 4px;
        background-color: transparent;
        color: @white;
    }

    .apply-button {
        max-width: 40%;
    }
}

:deep(.divider) {
    margin: 0;
    width: 100%;
    opacity: 0.4;
    border: 0;
    border-top: 1px solid rgba(241, 243, 248, 0.3);

    &.pixel-up {
        position: relative;
        top: -1px;
    }
}

.feedback-filter {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-height: calc(
        80vh - env(safe-area-inset-bottom) - env(safe-area-inset-top)
    );
    overflow-x: hidden;
    overflow-y: auto;
    text-align: left;
    width: 100%;

    .filter-content {
        max-width: 100vw;
        overflow-x: auto;
        scrollbar-width: none; /* Firefox */
        position: relative;

        &::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
        }
    }

    .filter-sets {
        .filter-set {
            padding-bottom: 15px;

            &:first-of-type {
                padding-top: 20px;
            }
        }
    }

    .filter-tabs-container {
        display: flex;
        justify-content: center;
        gap: 20px;
        .filter-tab {
            width: 90px;
            height: 50px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            .filter-tab-text {
                flex: 2;
                align-items: center;
                color: @white;
                font-size: @fontSize-base;
                font-weight: @fontWeight-regular;
                letter-spacing: @letterSpacing-base;
                line-height: @lineHeight-md;
                text-align: center;
                text-transform: capitalize;
                transform: translateY(12px);
            }
            &.selected {
                border-bottom: 1px solid @white;
            }

            .count-container {
                transform: translateY(14px);
            }

            .filter-count {
                margin: 7px 5px;
                border: 1px solid @white;
                border-radius: 100px;
                color: @white;
                font-size: @fontSize-sm;
                font-weight: @fontWeight-medium;
                height: auto;
                padding: 2px 10px;
                text-transform: uppercase;
                &.has-count {
                    color: @indigo;
                    background-color: @white;
                }
            }
        }
    }

    .filter-footer {
        flex-grow: 0;
        max-height: 85px;
        padding-top: 15px;
        padding-bottom: 15px;
        text-align: center;
        text-transform: uppercase;
        color: @white;
    }

    .navigation-text {
        align-items: center;
        color: @white;
        font-size: @fontSize-sm;
        font-weight: @fontWeight-medium;
        line-height: @lineHeight-sm;
        letter-spacing: @letterSpacing-2xl;
        text-transform: uppercase;
        &.back {
            align-items: center;
            display: inline-flex;

            .chevron-left {
                margin-right: 5px;
            }
        }
    }

    .unfocus {
        opacity: 0.3;
        pointer-events: none;
    }
}

.slide-tab-1-enter-from,
.slide-tab-1-leave-to {
    transform: translateX(-100%);
}

.slide-tab-2-enter-from,
.slide-tab-2-leave-to {
    transform: translateX(100%);
}

.slide-tab-1-enter-active,
.slide-tab-1-leave-active,
.slide-tab-2-enter-active,
.slide-tab-2-leave-active {
    transition: 0.5s;
}

.slide-tab-1-leave-active {
    position: absolute;
}

.slide-tab-2-leave-active {
    position: absolute;
    top: 0;
}
</style>
