<template>
    <div class="login-page-container">
        <PageContainer no-nav-pad>
            <div class="login-page" :class="{ center: mergedMeta.center }">
                <div class="logo" :class="{ 'no-logo': !mergedMeta.hasLogo }">
                    <img :src="IconLogo" alt="AskNicely" />
                </div>
                <RouterView />
            </div>
        </PageContainer>
        <ToastOverlay />
    </div>
</template>

<script setup lang="ts">
import { ref, watch, provide, Ref } from 'vue'
import PageContainer from '@/mobile/src/components/appV4/PageContainer.vue'
import IconLogo from '@/assets/img/logo/asknicely_logo_white.svg'
import router from '@/mobile/src/router'
import { getMergedMeta } from '@/utils/routing'
import ToastOverlay from '@/mobile/src/components/appV4/ToastOverlay.vue'
import { LoginMainPkey, Credentials } from '@/mobile/src/types/auth'

const defaultCreds: Credentials = {
    email: '',
    password: '',
}

const credentials: Ref<Credentials> = ref(defaultCreds)
const routerRef = ref(router)
const mergedMeta = ref({})
watch(
    () => routerRef.value.currentRoute,
    (currentRoute) => {
        mergedMeta.value = getMergedMeta(currentRoute)
    },
    { immediate: true }
)
const persistCredentials = (creds: Credentials) => {
    credentials.value = creds
}
const resetCredentials = () => {
    credentials.value = defaultCreds
}
provide(LoginMainPkey, { credentials, persistCredentials, resetCredentials })
</script>

<style lang="less" scoped>
//This class and the div it lives on prevents an animation leak bug that occurs with PageContainer and top level pages.
//Do not remove either.
.login-page-container {
    height: 100%;
}

.login-page {
    text-align: center;
    color: white;
}

.center {
    box-sizing: border-box;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.logo {
    text-align: center;
    position: relative;

    &.no-logo {
        display: none;
    }
}
</style>
