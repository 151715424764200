import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasePillButton = _resolveComponent("BasePillButton")!

  return (_openBlock(), _createBlock(_component_BasePillButton, {
    class: "pill-button-with-icon",
    "is-active": _ctx.isActive,
    disabled: _ctx.disabled,
    onClick: _ctx.onClick
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.formattedLabel), 1)
    ], undefined, true),
    _: 1
  }, 8, ["is-active", "disabled", "onClick"]))
}