import { ActionTree, MutationTree, GetterTree } from 'vuex'
import {
    ChatMention,
    ChatMessage,
    ChatStatus,
    ChatTemplate,
    ChatWorkflow,
    TeamMembers,
} from '../../entities/chat'
import { Case } from '../../entities/case'
import { Dict } from '../../entities/object'
import {
    getTemplates,
    getMessages,
    getCustomData,
    getMentionUsers,
    getPermissions,
    getWorkflows,
    getCaseOptions,
    getConvQuestions,
    getTags,
    getTeamMembers,
} from '../../api/chat'
import { ConvUIQuestions } from '@/pages/dashboard/entities/dash'
import fname from '@/utils/string'
import { getHashtagWhitelist } from '@/api/hashtagWhitelist'

export interface ChatState {
    activeQuestionId: number
    loading: boolean
    error: string
    messages: {
        [questionId: string]: ChatMessage[]
    }
    customData: {
        [questionId: string]: {
            [column: string]: string | string[]
        }
    }
    caseOptions: []
    questionWatchers: {
        [questionId: string]: boolean
    }
    templates: {
        [questionId: string]: {
            [templateId: string]: ChatTemplate
        }
    }
    workflows: {
        [chatWorkflowId: string]: ChatWorkflow
    }
    mentionUsers: ChatMention[]
    teamMembersUsers: TeamMembers[]
    tags: string[]
    userPermissions: string[]
    status: {
        [questionId: string]: ChatStatus
    }
    convQuestions: { [questionId: number]: ConvUIQuestions }
}

export const state: ChatState = {
    activeQuestionId: 0,
    loading: false,
    error: '',
    messages: {},
    customData: {},
    caseOptions: [],
    questionWatchers: {},
    templates: {},
    workflows: {},
    mentionUsers: [],
    teamMembersUsers: [],
    tags: [],
    userPermissions: [],
    status: {},
    convQuestions: {},
}

const getters: GetterTree<ChatState, any> = {
    loading({ loading }) {
        return loading
    },

    activeQuestionId({ activeQuestionId }) {
        return activeQuestionId
    },
    chatMessages({ messages, activeQuestionId }) {
        return messages[activeQuestionId] || []
    },
    chatCustomData({ customData, activeQuestionId }) {
        return customData[activeQuestionId] || {}
    },
    chatStatus({ status, activeQuestionId }) {
        return (
            status[activeQuestionId] || {
                note: '',
                reply: '',
                editing: 0,
                reply_review: 0,
                editing_template: -1,
            }
        )
    },
    chatTemplates({ templates, activeQuestionId }) {
        return templates[activeQuestionId] || {}
    },
    chatWorkflows({ workflows }) {
        return workflows || {}
    },
    mentionUsers({ mentionUsers }) {
        return mentionUsers
    },
    teamMembersUsers({ teamMembersUsers }) {
        return teamMembersUsers
    },
    tags({ tags }) {
        return tags
    },
    userPermissions({ userPermissions }) {
        return userPermissions
    },
    caseOptions({ caseOptions }) {
        return caseOptions
    },
    questionWatchers({ questionWatchers, activeQuestionId }) {
        return questionWatchers[activeQuestionId]
    },
    chatConvQuestions({ convQuestions }) {
        return convQuestions
    },

    caseStatus({ customData, activeQuestionId }) {
        // null | undefined => default ( you can open case)
        // 0 => open
        // 1 => closed
        if (
            !customData[activeQuestionId] ||
            customData[activeQuestionId].case_closed === undefined ||
            customData[activeQuestionId].case_closed === null
        ) {
            return 'default'
        }
        return Number(customData[activeQuestionId].case_closed) === 0
            ? 'open'
            : 'closed'
    },
    customFields({ customData, activeQuestionId }) {
        const labels = customData[activeQuestionId]
        if (!labels) {
            return []
        }

        // get friendly name for labels
        const customFieldLabels = labels.custom_field_labels
            ? labels.custom_field_labels
            : []
        const friendlyLabel = (k) =>
            fname(customFieldLabels[k] ? customFieldLabels[k] : k)

        return Object.keys(labels)
            .filter((a) => /_c$/.test(a) || a === 'theme' || a === 'segment')
            .sort()
            .map((a) => ({
                k: friendlyLabel(a),
                v: Array.isArray(labels[a])
                    ? (labels[a] as string[]).join(', ')
                    : labels[a],
            }))
            .filter((a) => a.v !== null)
    },
    hasWorkflow({ customData, activeQuestionId, userPermissions }) {
        const chatCustomData = customData[activeQuestionId]
        if (!chatCustomData || !userPermissions) {
            return false
        }
        return (
            !Number(chatCustomData.anom) &&
            !Number(chatCustomData.dontcontact) &&
            userPermissions.includes('ROLE_USER_BASICS')
        )
    },
}

const actions: ActionTree<ChatState, any> = {
    async loadTemplates({ commit, state }) {
        const res = await getTemplates(state.activeQuestionId)
        commit('setTemplates', res.data)
    },

    async loadWorkflows({ commit }) {
        const res = await getWorkflows()
        commit('setWorkflows', res?.data ?? {})
    },

    async loadMentionUsers({ commit }) {
        const res = await getMentionUsers()
        commit('setMentionUsers', res.data.users)
    },

    async loadTeamMembersUsers({ commit }, questionId: number) {
        const res = await getTeamMembers(questionId)
        commit('setTeamMembersUsers', res.data.users)
    },

    async loadTags({ commit }, whitelist) {
        if (whitelist) {
            await getHashtagWhitelist().then(({ data }) => {
                commit('setTags', data.data)
            })
        } else {
            await getTags().then(({ data }) => {
                commit('setTags', data.tags)
            })
        }
    },

    async getUserPermissions({ commit }) {
        const res = await getPermissions()
        commit('setUserPermissions', res.data)
    },

    async loadCaseOptions({ commit }) {
        const res = await getCaseOptions()
        commit('setCaseOptions', res.data)
    },

    async loadChat({ commit, state }) {
        if (state.activeQuestionId) {
            state.loading = true
            const results = await Promise.all([
                // wait for all to complete. we could break this up if it's slow
                getMessages(state.activeQuestionId),
                getCustomData(state.activeQuestionId),
            ])

            const [messages, customData] = results.map(({ data }) => data)

            commit('setChatData', {
                messages,
                customData,
            })
            state.loading = false
        }
    },

    async loadChatCustomData({ commit, state }) {
        const res = await getCustomData(state.activeQuestionId)
        commit('setChatCustomData', res.data)
    },

    setActiveQuestionId({ commit }, id: number) {
        commit('setActiveQuestionId', id)
    },

    setChatCustomData({ commit }, data) {
        commit('setChatCustomData', data)
    },

    setChatStatus({ commit }, status: ChatStatus) {
        commit('setChatStatus', status)
    },

    async loadConvQuestions({ commit }, questionId) {
        const res = await getConvQuestions(questionId)
        commit('setConvQuestions', { questions: res.data, questionId })
    },
}

const mutations: MutationTree<ChatState> = {
    setTemplates(state, templates: Dict<ChatTemplate>) {
        state.templates = {
            ...state.templates,
            [state.activeQuestionId]: templates,
        }
    },

    setWorkflows(state, workflows: Dict<ChatWorkflow>) {
        state.workflows = workflows
    },

    setMentionUsers(state, mentionUsers: ChatMention[]) {
        state.mentionUsers = mentionUsers
    },

    setTeamMembersUsers(state, teamMembersUsers: TeamMembers[]) {
        state.teamMembersUsers = teamMembersUsers
    },

    setTags(state, tags: string[]) {
        state.tags = tags
    },

    setUserPermissions(state, userPermissions: string[]) {
        state.userPermissions = userPermissions
    },

    setCaseOptions(state, caseOptions: []) {
        state.caseOptions = caseOptions
    },

    setActiveQuestionId(state, id: number) {
        state.activeQuestionId = id
    },

    setChatStatus(state, status: ChatStatus) {
        state.status = { ...state.status, [state.activeQuestionId]: status }
    },

    setChatCustomData(state, customData) {
        state.customData = {
            ...state.customData,
            [state.activeQuestionId]: customData,
        }
    },

    setChatData(state, { messages, customData }: ChatData) {
        state.messages = {
            ...state.messages,
            [state.activeQuestionId]: messages,
        }
        state.customData = {
            ...state.customData,
            [state.activeQuestionId]: customData,
        }
    },

    setChatMessages(state, messages: []) {
        state.messages = {
            ...state.messages,
            [state.activeQuestionId]: messages,
        }
    },

    setConvQuestions(
        state,
        data: { questions: ConvUIQuestions; questionId: number }
    ) {
        state.convQuestions = {
            ...state.convQuestions,
            [data.questionId]: data.questions,
        }
    },
}

interface ChatData {
    questionId: number
    messages: ChatMessage[]
    customData: Dict<string>
    chatCase: Case
    questionWatcher: boolean
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations,
}
