<template>
    <div class="back-button" @click="onClick()">
        <FontAwesomeIcon :icon="faChevronLeft" class="arrow-icon-active" />
        <Typography variant="overline" class="back-text">Back</Typography>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import Typography from '@/components/Rain/Typography/Typography.vue'
import { faChevronLeft } from 'fontawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'

@Component({
    components: { FontAwesomeIcon, Typography },
})
export default class BackButton extends Vue {
    @Prop({ type: Function, required: true }) public onClick!: () => void

    private faChevronLeft = faChevronLeft
}
</script>

<style scoped lang="less">
@import '~@/styles/rain/variables.less';
.back-button {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-content: center;
    .arrow-icon-active {
        color: white;
    }

    .back-text {
        padding-left: 6px;
        color: white;
    }
}
</style>
