<template>
    <button
        :class="['pill-button', { 'is-active': isActive, disabled }]"
        :disabled="disabled"
        @click="onClick"
    >
        <slot />
    </button>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component({
    emits: ['click'],
})
export default class BasePillButton extends Vue {
    @Prop({
        type: Boolean,
        required: false,
        default: false,
    })
    readonly isActive!: boolean

    @Prop({
        type: Boolean,
        required: false,
        default: false,
    })
    readonly disabled!: boolean

    onClick() {
        if (this.disabled) {
            return
        }
        this.$emit('click')
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/palette';
@import '~@/styles/typography';

.pill-button {
    .label();
    line-height: 14px;

    background-color: @white;
    border-radius: 9999px;
    border: 1px solid @blueLight;
    outline: none;

    padding: 0.5rem 0.75rem;

    &.is-active {
        background-color: @blue;
        color: @white;
    }

    &.disabled {
        background-color: @greyLight3;
        border-color: @greyLight3;
        color: @greyBlue;
    }
}
</style>
