<template>
    <div class="carousel" @touchstart="onTouchStart" @touchend="onTouchEnd">
        <Flicking
            :options="{ circular: true }"
            :plugins="plugins"
            @willChange="beforeChange"
        >
            <slot />
            <template v-if="showPagination" #viewport>
                <div class="pagination"></div>
            </template>
        </Flicking>
    </div>
</template>

<script lang="ts" setup>
import { useSlots, ref, onMounted, Ref } from 'vue'
import Flicking from '@egjs/vue3-flicking'
import '@egjs/vue3-flicking/dist/flicking.css'
import { Pagination } from '@egjs/flicking-plugins'

const emit = defineEmits(['touchstart', 'touchend', 'slidechanged'])
const slots = useSlots()
const showPagination = ref(true)
const plugins: Ref<Pagination[]> = ref([])

onMounted(() => {
    // Hide pagination bullets if there is only one item in carousel
    if (slots.default && slots.default()[0]['children']) {
        const hasMultipleCarouselItems =
            Number(slots.default()[0]['children']?.length) > 1
        showPagination.value = hasMultipleCarouselItems

        if (hasMultipleCarouselItems) {
            plugins.value = [
                new Pagination({
                    type: 'bullet',
                    selector: '.pagination',
                    classPrefix: 'pagination',
                }),
            ]
        }
    }
})

const onTouchStart = () => {
    emit('touchstart')
}

const onTouchEnd = () => {
    emit('touchend')
}

const beforeChange = (event: { currentTarget: Flicking; index }) => {
    emit('slidechanged', event.currentTarget.index, event.index)
}
</script>

<style lang="less">
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';

.flicking-camera {
    z-index: 0;
}
.flicking-camera > * {
    width: 100%;
}

.pagination-bullets {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    gap: 7px;
    margin: 0;
}
.pagination-bullet {
    height: 4px;
    width: 16px;
    border-radius: 20px;
    background-color: @white;
    opacity: 0.2;
    transition: opacity 0.3s ease-in-out;
}

.pagination-bullet-active {
    background-color: white;
    opacity: 1;
}
</style>
