<template>
    <div :class="['wrapper', { 'show-info': showInfo }]">
        <div @click="onClickShowInfo">
            <Transition name="slide-down-from-top">
                <img
                    v-if="showSeasonalBanner"
                    class="seasonal-banner"
                    src="~@/assets/img/seasonal-banner.png"
                    alt="Seasonal banner"
                />
            </Transition>
        </div>

        <div v-if="showSeasonalBanner && showInfo" class="info-message-box">
            <FontAwesomeIcon
                class="close-icon"
                :icon="faXmark"
                @click="onClickHideInfo"
            />
            <Typography variant="overline" class="info-text title">{{
                title
            }}</Typography>
            <Typography variant="body2" class="info-text"><span v-html="infoText" /></Typography>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { Getter } from 'vuex-facing-decorator'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faXmark } from 'fontawesome/free-solid-svg-icons'
import Typography from '@/components/Rain/Typography/Typography.vue'
import { pendoTrackEvent } from '@/utils/pendo'

/**
 * Seasonal Banner - an Easter egg to show behind the Profile header in home page of App 4.0
 */
@Component({
    components: {
        Typography,
        FontAwesomeIcon,
    },
    emits: ['showSeasonalBanner'],
})
export default class SeasonalBanner extends Vue {
    @Getter public showSeasonalBanner

    public showInfo = false
    public faXmark = faXmark

    // TODO: Refactor so the copy is not hardcoded and can be updated easier when design/product want to change it

    public get title() {
        return "It's chocolate time!"
    }

    public get infoText() {
        return `Life is like chocolate: you should enjoy it piece for piece and let it slowly melt on your tongue. <br/> – Nina Sandmann`
    }

    public onClickShowInfo() {
        if (this.showSeasonalBanner && !this.showInfo) {
            this.showInfo = true

            this.$emit('showSeasonalBanner', this.showInfo)

            if (this.showInfo) {
                pendoTrackEvent('seasonal-banner-info-modal-shown')
            }
        }
    }

    public onClickHideInfo() {
        if (this.showSeasonalBanner) {
            this.showInfo = false
            this.$emit('showSeasonalBanner', this.showInfo)
        }
    }
}
</script>

<style lang="less" scoped>
@import '~@/mobile/src/styles/zindex.less';
@import '~@/mobile/src/styles/animations.less';
@import '~@/styles/rain/variables.less';
@import '~@/styles/rain/colour.less';

.seasonal-banner {
    width: 100%;
    pointer-events: none;
}
.wrapper {
    width: 100vw;
    position: fixed;
    left: 0;
    top: 0;
}

.show-info {
    height: 100%;
    z-index: @shadowOverlayIndex;
}

.info-message-box {
    box-shadow: 0 4px 20px @black15;
    width: fit-content;
    background: @offBlack;
    padding: @featureGutter;
    border-radius: @borderRadius-md;
    transform: translateX(-50%);
    position: relative;
    top: -40px;
    left: 50%;
    max-width: 210px;

    .info-text {
        color: @lightGrey;
    }

    .title {
        padding-bottom: 6px;
        padding-right: 12px;
    }

    .close-icon {
        color: @white;
        padding: 12px;
        position: absolute;
        top: 0;
        right: 0;
    }
}

.slide-down-from-top-animations();
</style>
