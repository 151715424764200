<template>
    <div>
        <div class="title">{{ title }}</div>
        <div class="graph-container">
            <canvas ref="graph" />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from 'vue-facing-decorator'
import { Chart, registerables } from 'chart.js'

@Component({})
export default class InsightsGraph extends Vue {
    @Prop({ type: String, default: null })
    private title!: string

    @Prop({ type: Array, default: {} })
    private data!: number[]

    @Prop({ type: String, default: '000' })
    private color!: string

    @Prop({ type: Number, default: 80 })
    private height!: number

    public chart?: Chart

    public mounted() {
        Chart.register(...registerables)
        this.updateGraph()
    }

    public beforeDestroy() {
        this.chart?.destroy()
    }

    get getSkeletonHeight() {
        return `height: ${this.height}px;`
    }

    public initialGraph() {
        return {
            labels: [],
            datasets: [],
        }
    }

    @Watch('data')
    public async renderGraph() {
        const canvas = this.$refs.graph as HTMLCanvasElement
        if (!canvas || !this.data.length) {
            // Prevents over-render if graph is empty; also prevents rotate issue
            return
        }
        const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
        ctx.canvas.height = this.height
        const gradient = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height)
        const triHex = this.color.length === 4 // Figure out if the supplied hex is three characters long, plus one for the #
        gradient.addColorStop(0, this.color + (triHex ? 'F' : 'FF'))
        gradient.addColorStop(1, this.color + (triHex ? '0' : '00'))
        let data = this.data ? this.data.map((v) => v) : []
        const minVal = data.length ? Math.min(...data) : 0
        if (minVal < 0) {
            data = data.map((v) => v - minVal)
        }
        if (this.chart) await this.chart.destroy()
        this.chart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: this.data ? this.data.map((v) => 'fill') : [], // It gets unhappy when we don't have 1:1 label to data
                datasets: [
                    {
                        data,
                        backgroundColor: gradient,
                        borderColor: this.color + '00',
                        borderWidth: 1,
                        fill: true,
                    },
                ],
            },
            options: {
                maintainAspectRatio: false,
                responsive: true,
                elements: {
                    line: {
                        tension: 0.3,
                    },
                    point: {
                        radius: 0,
                    },
                },
                scales: {
                    y: {
                        display: false,
                    },
                    x: {
                        display: false,
                    },
                },
                plugins: {
                    legend: {
                        display: false, // we have to render our own to match the design
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
            },
        })
    }

    public updateGraph() {
        if (this.chart) {
            this.chart.destroy()
        }
        this.renderGraph()
    }
}
</script>

<style scoped lang="less">
.title {
    color: #93a1be;
    padding-left: 18px;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-align: left;
    text-transform: uppercase;
}
</style>
