import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-081ab24e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "scorecard-profile" }
const _hoisted_2 = { class: "name-container" }
const _hoisted_3 = { class: "name" }
const _hoisted_4 = { class: "location" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScorecardProfileImage = _resolveComponent("ScorecardProfileImage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ScorecardProfileImage, {
      "can-edit": true,
      scorecard: _ctx.scorecard,
      height: 72,
      width: 72
    }, null, 8, ["scorecard"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.name), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.locationText), 1)
    ])
  ]))
}