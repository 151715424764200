<template>
    <div class="recipient-picker-modal-container">
        <div class="modal-pad recipient-title">
            <Typography variant="subtitle1">{{
                title ? title : 'Select one or more Recipient(s)'
            }}</Typography>
        </div>
        <RecipientPicker
            class="recipient-picker-content-container"
            :recipients="recipients"
            :selected-recipients="selectedRecipients"
            :can-pick-virtual-users="canPickVirtualUsers"
            :max-list-length="20"
            @toggleRecipient="onToggleRecipient"
            @clearSelectedRecipients="clearSelectedRecipients"
        />
        <div class="recipient-action">
            <BaseButton
                class="btn-back"
                variant="ghost"
                @click="onCancelRecipients"
            >
                Back
            </BaseButton>
            <BaseButton
                :disabled="disableSave"
                variant="secondary"
                @click="onConfirmRecipients"
            >
                Confirm
            </BaseButton>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { IRecipient } from '@/entities/notices'
import 'driver.js/dist/driver.min.css'
import ChatInput from '@/components/Chat/ChatInput.vue'
import RecipientPicker from '@/components/Notices/RecipientPicker.vue'
import BaseButton from '@/components/Rain/Buttons/BaseButton.vue'
import Typography from '@/components/Rain/Typography/Typography.vue'

@Component({
    components: {
        Typography,
        BaseButton,
        ChatInput,
        RecipientPicker,
    },
    emits: ['close', 'confirm'],
})
export default class DesktopRecipientPicker extends Vue {
    @Prop({ type: Boolean }) public single!: boolean // only allow single selection
    @Prop({ type: Boolean, default: true }) public allowEmpty!: boolean // allow empty selection
    @Prop({ type: Array }) public selected!: IRecipient[]
    @Prop({ type: Array }) public recipients!: IRecipient[]
    @Prop({ type: String }) public title!: string
    @Prop({ type: Boolean, default: false }) public canPickVirtualUsers!: string

    private selectedRecipients: IRecipient[] = []

    public async mounted() {
        this.selectedRecipients = [...this.selected]
        // Purge the unclean.  Remove any user not part of the recipients list to avoid errors.
        this.selectedRecipients = this.selectedRecipients.filter((s) =>
            this.recipients.map((r) => r.user_id).includes(s.user_id)
        )
    }

    private get disableSave(): boolean {
        return !(
            this.allowEmpty ||
            (!this.allowEmpty && this.selectedRecipients.length > 0)
        )
    }

    private onToggleRecipient(
        selectedRecipients: IRecipient[],
        changedRecipient: IRecipient
    ) {
        this.selectedRecipients = selectedRecipients
        if (this.single && this.selectedRecipients.length) {
            this.selectedRecipients = [changedRecipient]
        }
    }

    private clearSelectedRecipients() {
        this.selectedRecipients = []
    }

    private onCancelRecipients() {
        this.selectedRecipients = this.selected
        this.$emit('close')
    }

    private onConfirmRecipients() {
        this.$emit('confirm', this.selectedRecipients)
    }
}
</script>

<style lang="less">
.type-list {
    margin: -30px -15px 0 -15px;

    .row:last-of-type {
        border-bottom: none;
    }
}
</style>

<!-- overwrite any feature tour css -->
<style lang="less" scoped>
@import '../../styles/layout.less';
@import '../../styles/palette.less';

@padding: 20px;

.modal-pad {
    padding: 24px;
}

.recipient-picker-modal-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.recipient-picker-content-container {
    clear: both;
    flex: 1;
    display: flex;
    flex-direction: column;
    height: calc(100% - 140px);
    overflow: hidden;
}

.recipient-action {
    padding: 16px 24px;
    border-top: 1px solid @blueLight;
    text-align: right;

    .btn-back {
        margin-right: 16px;
    }
}

.uk-button:disabled {
    opacity: 0.3;
    cursor: not-allowed;
}

.chevron {
    font-size: 8px;
    margin-left: 2px;
}
</style>
