import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b983d82"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "feedback-custom-data-container" }
const _hoisted_2 = { class: "feedback-custom-data-content" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "row-label" }
const _hoisted_6 = { class: "row-value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.chatCustomFields.length === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, " No custom data available. "))
        : (_openBlock(), _createElementBlock("table", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chatCustomFields, (row, rowIdx) => {
              return (_openBlock(), _createElementBlock("tr", { key: rowIdx }, [
                _createElementVNode("td", _hoisted_5, _toDisplayString(row.k), 1),
                _createElementVNode("td", _hoisted_6, _toDisplayString(row.v), 1)
              ]))
            }), 128))
          ]))
    ])
  ]))
}