import { ActionTree, MutationTree, GetterTree } from 'vuex'

// refer to IPerformanceOverTimeConfig
export interface PerformanceOverTimeConfigState {
    questionType: string
    customField: string
    customValue: string
    timeRange: string
    timeUnit: string
}

/**
 * For conveying PerformanceOvertime filter change to DashboardResponses
 */
export const state: PerformanceOverTimeConfigState = {
    questionType: '',
    customField: '',
    customValue: '',
    timeRange: '',
    timeUnit: '',
}

const getters: GetterTree<PerformanceOverTimeConfigState, any> = {
    getPerformanceOverTimeConfig({
        questionType,
        customField,
        customValue,
        timeRange,
        timeUnit,
    }) {
        return { questionType, customField, customValue, timeRange, timeUnit }
    },
}

const actions: ActionTree<PerformanceOverTimeConfigState, any> = {
    setPerformanceOverTimeConfig(
        { commit },
        { questionType, customField, customValue, timeRange, timeUnit }
    ) {
        commit('setPerformanceOverTimeConfig', {
            questionType,
            customField,
            customValue,
            timeRange,
            timeUnit,
        })
    },
}

const mutations: MutationTree<PerformanceOverTimeConfigState> = {
    setPerformanceOverTimeConfig(
        state,
        { questionType, customField, customValue, timeRange, timeUnit }
    ) {
        state.questionType = questionType
        state.customField = customField
        state.customValue = customValue
        state.timeRange = timeRange
        state.timeUnit = timeUnit
    },
}

export default {
    namespaced: false,
    state,
    actions,
    mutations,
    getters,
}
