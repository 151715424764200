<template>
    <div>
        <p :style="style" v-html="html" />
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component({})
export default class TextWithHighlight extends Vue {
    @Prop({ type: String, default: '' }) public text!: string
    @Prop({ type: Array, default: () => [] }) public highlights!: string[]
    @Prop({ type: Number, default: 0 }) public height!: number

    public get html() {
        let coloredText = this.text
        this.highlights
            // !!NOTE: should sort by length at first to handle names with same prefix.
            // When having both `@Region:NZ` and `@Region:NZ(Staff)`. We only highlighted `@Region:NZ`
            // instead of `@Region:NZ(Staff)` without sort.
            .sort((a, b) => b.length - a.length)
            .forEach((word) => {
                coloredText = coloredText.replace(
                    word,
                    this.wrapHighlight(word)
                )
            })
        return coloredText
    }

    public get style() {
        const pStyle = {}
        if (this.height) {
            pStyle['height'] = this.height + 'px'
            pStyle['overflow'] = 'hidden'
        }
        return pStyle
    }

    public wrapHighlight(word) {
        return `<b class='default-highlight'>${word}</b>`
    }
}
</script>

<style scoped>
/* we use deep selector here, and it has not been supported when lang="less"
deep selector
https://vue-loader.vuejs.org/guide/scoped-css.html#deep-selectors */

p:deep(a) {
    text-decoration: underline;
    color: #0037ff;
}

p:deep(b) {
    background: #fff1c2;
    color: #2f384c;
    font-weight: 500;
    padding: 0 2px;
}

p {
    color: #2f384c;
    margin: 0;
    /*  suggestionsApp was doing an overwrite, but capacitor app isn't using that,
       and I don't like it. So manually setting the margin to 0*/
}
</style>
