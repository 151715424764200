import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RequestNotificationPermissionPanel = _resolveComponent("RequestNotificationPermissionPanel")!
  const _component_ScorecardPage = _resolveComponent("ScorecardPage")!
  const _component_ScorecardPageExpanded = _resolveComponent("ScorecardPageExpanded")!
  const _component_SwitchLayoutContainer = _resolveComponent("SwitchLayoutContainer")!
  const _component_Scrollable = _resolveComponent("Scrollable")!
  const _component_ScreenBottomButton = _resolveComponent("ScreenBottomButton")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_WebViewScreen = _resolveComponent("WebViewScreen")!

  return (_openBlock(), _createBlock(_component_WebViewScreen, {
    class: "webview-container",
    "no-pad": "",
    "show-go-back": ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Scrollable, {
        loading: _ctx.scorecardLoading,
        "pull-down": _ctx.load
      }, {
        default: _withCtx(() => [
          _createVNode(_component_RequestNotificationPermissionPanel),
          _createVNode(_component_SwitchLayoutContainer, null, {
            compact: _withCtx(() => [
              _createVNode(_component_ScorecardPage, { "team-member-user-id": _ctx.teamMemberUserId }, null, 8, ["team-member-user-id"])
            ]),
            expanded: _withCtx(() => [
              _createVNode(_component_ScorecardPageExpanded, { "team-member-user-id": _ctx.teamMemberUserId }, null, 8, ["team-member-user-id"])
            ]),
            _: 1
          })
        ], undefined, true),
        _: 1
      }, 8, ["loading", "pull-down"]),
      _createVNode(_component_RouterLink, {
        to: {
                name: 'notices-pick-type-user',
                params: { preselectedUserId: _ctx.teamMemberUserId },
            }
      }, {
        default: _withCtx(() => [
          (_ctx.canSendNotices)
            ? (_openBlock(), _createBlock(_component_ScreenBottomButton, {
                key: 0,
                title: _ctx.messageButtonText
              }, null, 8, ["title"]))
            : _createCommentVNode("", true)
        ], undefined, true),
        _: 1
      }, 8, ["to"])
    ], undefined, true),
    _: 1
  }))
}