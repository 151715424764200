<template>
    <div class="tab-option-container" @click="$emit('click', tab.name)">
        <div class="tab-option">
            <div class="icon">
                <div class="icon-container">
                    <img
                        :src="tab.icon"
                        alt="tab-icon"
                        :class="['icon-img', { selected }]"
                    />
                </div>
                <UnreadCounter
                    v-if="showDisplayCount"
                    :unread-count="unreadCount"
                    @click="onClickDisplayCount()"
                />
            </div>

            <div :class="['text', { selected }]">
                {{ tab.text }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { TabData } from '@/mobile/src/types/tab'
import { pendoTrackEvent } from '@/utils/pendo'
import UnreadCounter from '@/mobile/src/components/appV4/UnreadCounter.vue'

@Component({
    components: {
        UnreadCounter,
    },
    emits: ['click'],
})
export default class MobileNavigationTab extends Vue {
    @Prop({ type: Object, default: {} }) public tab!: TabData
    @Prop({ type: Boolean, default: false }) public selected!: boolean

    public get unreadCount() {
        return this.tab.count ?? 0
    }

    public get showDisplayCount() {
        return this.tab && 'count' in this.tab && this.tab?.count
    }

    public onClickDisplayCount() {
        pendoTrackEvent(`${this.tab.name}_tab_count`)
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/palette';

.tab-option-container {
    height: 100%;
    display: flex;
    align-items: center;

    .tab-option {
        margin: 0 auto;
    }

    .icon {
        height: 24px;
        display: flex;
        align-items: center;
        position: relative;
    }

    .icon-container {
        margin: 0 auto;
        position: relative;
        display: flex;
        align-items: center;
    }

    .icon-img {
        opacity: 0.6;
        transition: opacity 0.3s ease-in-out;

        &.selected {
            opacity: 1;
        }
    }

    .text {
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 12px;
        font-size: 10px;
        margin-top: 6px;
        color: rgba(@white, 0.6);
        transition: color 0.3s ease-in-out;

        &.selected {
            color: white;
        }
    }
}
</style>
