<template>
    <div class="container">
        <div class="login-form">
            <div class="inputs">
                <div class="white">
                    Please input your password to continue validation.
                </div>
                <Input
                    ref="password"
                    v-model="password"
                    title="Password"
                    placeholder="Password"
                    type="password"
                    :required="true"
                    :err-msg="'Invalid Password'"
                />
            </div>
            <TransparentButton
                class="btn-login"
                text="Validate"
                :click="validateContactMethod"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import Input from '@/components/Input.vue'
import { validateUser } from '@/mobile/src/api/auth'
import TransparentButton from '@/mobile/src/components/TransparentButton.vue'
import { loginAndRedirect, parseDeepLinkUrl } from '@/mobile/src/services/auth'
import { ParsedQuery } from 'query-string'

@Component({
    components: {
        TransparentButton,
        Input,
    },
})
export default class Login extends Vue {
    @Prop({ type: String, default: '' }) url!: string

    private domain = ''
    private token = ''
    private query?: ParsedQuery
    private password = ''
    private method = ''

    async mounted() {
        const { domain, token, query } = parseDeepLinkUrl(this.url)
        this.domain = domain
        this.token = token
        this.query = query
        this.method =
            this.query && this.query.method ? this.query.method.toString() : ''
    }

    private async validateContactMethod() {
        const { success, data, msg } = await validateUser(
            this.domain,
            this.token,
            this.password,
            this.method
        )
        if (success) {
            await loginAndRedirect(this.domain, data.email, this.password)
        } else {
            alert(msg)
        }
    }
}
</script>
<style lang="less" scoped>
@import '@/styles/palette';

.login-form {
    .white {
        color: white;
    }

    .btn-login {
        margin-top: 15px;
        width: 100%;
    }
}
</style>
