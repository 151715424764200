// Copied from 'https://github.com/stackjie/vue-pull-to/blob/master/src/utils.js'
// to replace 'vue-pull-down' package

// http://www.alloyteam.com/2012/11/javascript-throttle/

export function throttle(
    fn: (...args: any[]) => void,
    delay: number,
    mustRunDelay = 0
): (...args: any[]) => void {
    if (delay == null) return fn

    const timestampProvider: { now(): number } =
        typeof performance === 'object' ? performance : Date
    let timer: ReturnType<typeof setTimeout> | null = null
    let tStart: number | null = null

    return function (this: any, ...args: any[]): void {
        const tCurr: number = timestampProvider.now()

        if (timer != null) clearTimeout(timer)

        if (!tStart) {
            tStart = tCurr
        }

        if (mustRunDelay !== 0 && tCurr - tStart >= mustRunDelay) {
            fn.apply(this, args)
            tStart = tCurr
        } else {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const context = this
            // @ts-ignore
            timer = setTimeout(function (): void {
                timer = null
                fn.apply(context, args)
            }, delay)
        }
    }
}

export const PASSIVE_OPTS = (function () {
    let value = false
    try {
        window.addEventListener('test', () => ({}), {
            get passive() {
                value = true
                return true
            },
        })
        window.removeEventListener('test', () => ({}))
    } catch (e) {
        value = false
    }
    return value && { passive: true }
})()

export function create(prototype, properties) {
    const obj = Object.create(prototype)
    Object.assign(obj, properties)
    return obj
}

const TOP_DEFAULT_CONFIG = {
    pullText: 'Pull to refresh', // The text is displayed when you pull down
    triggerText: 'Release to refresh', // The text that appears when the trigger distance is pulled down
    loadingText: 'Loading...', // The text in the load
    doneText: 'Success!', // Load the finished text
    failText: 'Failure!', // Load failed text
    loadedStayTime: 400, // Time to stay after loading ms
    stayDistance: 50, // Trigger the distance after the refresh
    triggerDistance: 70, // Pull down the trigger to trigger the distance
}

const BOTTOM_DEFAULT_CONFIG = TOP_DEFAULT_CONFIG

export { TOP_DEFAULT_CONFIG, BOTTOM_DEFAULT_CONFIG }
