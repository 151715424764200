<template>
    <div class="scorecard-topics-container" data-test-tag="ts-nps-scorecard">
        <div v-if="scorecardGroups && scorecardGroups.tsgroups">
            <span class="topic-label">scorecard topics</span>
            <TsDatatypeSelector
                :current="viewSelected"
                :datatypes="viewOptions"
                @change="viewSelected = $event"
            />
        </div>
        <div class="sc-results">
            <table
                v-if="scorecardGroups && scorecardGroups.tsgroups"
                class="sc-table"
            >
                <thead>
                    <tr
                        v-if="scorecardGroups.tsgroups.length > 0"
                        class="sc-label-row"
                    >
                        <td :class="{ 'sc-group-comparison': !showCounts }">
                            <NPSBarLabel
                                v-if="!showCounts"
                                class="sc-bar-label"
                                :min="Number(scorecardGroups.minNPS)"
                                :max="Number(scorecardGroups.maxNPS)"
                                :average="
                                    Number(insightsDetails.aggregateScore)
                                "
                            >
                                {{
                                    scorecardGroups.isFiveScore
                                        ? 'Rating'
                                        : 'NPS'
                                }}
                                {{
                                    scorecardGroups.isFiveScore
                                        ? Number(
                                              insightsDetails.aggregateScore
                                          ).toFixed(4)
                                        : Number(
                                              insightsDetails.aggregateScore
                                          ).toFixed(1)
                                }}
                            </NPSBarLabel>
                        </td>
                        <td v-if="!showCounts">&nbsp;</td>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="scorecardGroup in displayScorecardGroups"
                        :key="scorecardGroup.group"
                    >
                        <td
                            :class="{
                                'sc-group-comparison': !showCounts,
                                'sc-counts-container': showCounts,
                            }"
                        >
                            <div class="topic-title">
                                {{ scorecardGroupName(scorecardGroup.group) }}
                            </div>
                            <TsNpsCounts
                                v-if="showCounts"
                                show-bg
                                :count-positive="
                                    Number(scorecardGroup.respondedPromoter)
                                "
                                :count-negative="
                                    getThingsToWorkOnCount(scorecardGroup)
                                "
                                :max-responded="
                                    Number(
                                        scorecardGroups.maxRespondedPromoterDetractor
                                    )
                                "
                            />
                            <NPSBar
                                v-else
                                :nps="Number(scorecardGroup.NPS)"
                                :min="Number(scorecardGroups.minNPS)"
                                :max="Number(scorecardGroups.maxNPS)"
                                :average="
                                    Number(insightsDetails.aggregateScore)
                                "
                            />
                        </td>
                        <td v-if="!showCounts" class="sc-group-nps">
                            <div class="sc-nps">
                                {{ scorecardGroup.NPS }}
                                <div
                                    v-if="scorecardGroup.previousNPS"
                                    :class="[
                                        'sc-delta',
                                        {
                                            up: scorecardGroup.scoreDelta > 0,
                                            down:
                                                scorecardGroup.scoreDelta <= 0,
                                        },
                                    ]"
                                ></div>
                            </div>
                        </td>
                    </tr>
                    <tr
                        v-if="scorecardGroups.tsgroups.length <= 0"
                        class="uk-text-center uk-text-muted sc-no-results"
                    >
                        <td colspan="99">No results found</td>
                    </tr>
                </tbody>
            </table>
            <div
                v-if="scorecardGroups && scorecardGroups.tsgroups"
                class="sc-legend"
            >
                <div class="legend-item">
                    <div class="key-circle positive"></div>
                    {{ legendPositive }}
                    <FormattedNumber
                        v-if="showCounts"
                        class="legend-number"
                        :number="totalPositiveCount"
                    />
                </div>
                <div class="legend-item">
                    <div class="key-circle negative"></div>
                    {{ legendNegative }}
                    <FormattedNumber
                        v-if="showCounts"
                        class="legend-number"
                        :number="totalNegativeCount"
                    />
                </div>
            </div>
        </div>
        <Loading :loading="!!isLoadingInsightsData" />
    </div>
</template>

<script lang="ts">
import { Action, Getter } from 'vuex-facing-decorator'
import { Component, Vue } from 'vue-facing-decorator'
import Loading from '@/components/Loading.vue'
import NPSBar from '@/components/NPSBar.vue'
import NPSBarLabel from '@/components/NPSBarLabel.vue'
import { formatGroupName } from '@/domain/TeamScoreboard'
import FormattedNumber from '@/components/FormattedNumber.vue'
import { TsGroupSummaryEntity } from '@/entities/teamscoreboard'
import TsDatatypeSelector from '@/components/TeamScoreboard/TsDatatypeSelector.vue'
import TsNpsCounts from '@/components/TeamScoreboard/TsNpsCounts.vue'

@Component({
    components: {
        TsNpsCounts,
        TsDatatypeSelector,
        FormattedNumber,
        Loading,
        NPSBar,
        NPSBarLabel,
    },
})
export default class InsightsScorecardInfo extends Vue {
    @Getter
    readonly isLoadingInsightsData

    @Getter
    readonly scorecardGroups

    @Getter
    readonly insightsDetails?: TsGroupSummaryEntity

    @Action
    loadScorecardGroups

    viewOptions = ['COUNT', 'NPS']
    viewSelected = 'NPS'

    get showCounts() {
        return this.viewSelected === 'COUNT'
    }

    get legendPositive() {
        if (this.showCounts) {
            return 'Positive feedback: '
        }
        return 'Above average'
    }

    public scorecardGroupName(name: string) {
        return formatGroupName(name)
    }

    get legendNegative() {
        if (this.showCounts) {
            return 'Things to work on: '
        }
        return 'Below average'
    }

    get displayScorecardGroups() {
        if (!this.scorecardGroups) {
            return []
        }

        return this.scorecardGroups.tsgroups.filter(
            ({ responded, group }) => responded > 0 && group !== 'None'
        )
    }

    getThingsToWorkOnCount(scorecardGroup) {
        return Number(
            scorecardGroup.responded - scorecardGroup.respondedPromoter
        )
    }

    get totalPositiveCount() {
        return this.displayScorecardGroups
            .map((group) => group.respondedPromoter)
            .reduce((prev, curr) => parseInt(prev) + parseInt(curr), 0)
    }

    get totalNegativeCount() {
        return (
            this.displayScorecardGroups
                .map((group) => group.responded)
                .reduce((prev, curr) => parseInt(prev) + parseInt(curr), 0) -
            this.totalPositiveCount
        )
    }

    mounted() {
        this.initData()
    }

    activated() {
        this.initData()
    }

    async initData() {
        if (this.scorecardGroups) {
            return
        }

        await this.loadScorecardGroups()
        if (this.scorecardGroups?.isFiveScore) {
            this.viewOptions = ['COUNT', 'RATING']
        }
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/palette.less';
@import '~@/styles/typography.less';

.scorecard-topics-container {
    position: relative;
    padding: 16px;

    .topic-label {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: @grey40;
    }

    .topic-title {
        position: relative;
        top: 15px;
    }
}

.legend-number {
    margin-left: 5px;
}

.sc-results {
    margin-top: -25px;
}

.sc-nps,
.sc-responses {
    line-height: 20px;
    font-style: normal;
    padding-right: 14px;
    text-align: right;
}

.sc-nps {
    position: relative;
    font-weight: bold;
    font-size: 14px;
    top: 10px;
    color: @grey90;
}

.sc-responses {
    font-size: 11px;
    color: @grey40;
}

.sc-delta {
    position: absolute;
    right: 2px;
    bottom: 50%;
    transform: translateY(50%);
    background-size: contain;

    &.up,
    &.down {
        height: 6px;
        width: 8px;
    }

    &.up {
        background-image: url('~@/assets/img/Icons/leaderboard-up.svg');
    }

    &.down {
        background-image: url('~@/assets/img/Icons/leaderboard-down.svg');
    }
}

.sc-table {
    width: 100%;
    text-align: left;
    table-layout: fixed;

    font-size: 13px;
    line-height: 20px;

    border-spacing: 0;

    @border-size: 2px;
    @row-height: 44px - @border-size; // 44px total height, -4px to account for border size (2px)

    tbody {
        tr {
            height: 50px;
        }
    }

    .sc-group-comparison {
        padding-right: 16px;
        height: 20px;
        width: 80%;
    }

    td.sc-counts-container {
        width: 100%;

        .topic-title {
            position: relative;
            top: 0px;
        }
    }

    .sc-label-row {
        height: 23px;
    }

    .sc-table-divider {
        margin: 18px 0;
        border-top: 1px solid @greyLight;
    }

    .sc-no-results {
        font-size: 16px;
    }

    .sc-group-nps {
        width: 50px;
        text-align: right;
    }
}

.sc-legend {
    font-size: 11px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    margin-top: 10px;

    .legend-item {
        display: flex;
        align-items: center;
        flex: 1;
    }
}

.key-circle {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    display: inline-block;
    margin-right: 3px;

    &.positive {
        background: @positiveLight;
    }

    &.negative {
        background: @negativeLight;
    }
}
</style>

<style lang="less">
.sc-table {
    tbody {
        tr:first-child {
            td {
                .nb-average {
                    top: 10px;
                }
            }
        }
    }
}

.sc-bar-label {
    .nbl-label {
        top: 10px;
    }
}
</style>
