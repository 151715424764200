export const palette = {
    greyLight: '#F4F6FC',
    greyLight1: '#F5F6F7',
    greyLight2: '#E7EBEE',
    greyLight3: '#E4E8EB',
    greyLight4: '#C8D2D9',
    greyLight5: '#F9FAFF',
    greyLight6: '#EBEBEB',
    greyMid: '#96A2AC',
    greyMid2: '#B6BAC0',
    greyMid3: '#B0BAC9',
    greyMid4: '#A9B4CB',
    grey40: '#93A1BE',
    grey60: '#586B93',
    grey90: '#2F384C',
    black: '#000',
    darkGreyBlue: '#3A4B59',
    midGreyBlue: '#587287',
    greyBlue: '#7082A9',
    blue: '#2E5BFF',
    blue60: '#0037FF',
    blue40: '#A6C1FF',
    blueLight: '#E0E7FF',
    blueLight1: '#F2F5FF',
    blue10: '#E0E7FF',
    gold: '#BE7F00',
    brown: '#DB8B00',
    brownLight: '#FFE59E',
    orange: '#FF4C3B',
    red: '#FD3A57',
    greenLightest: '#CEDCD8',
    greenLight: '#8CFAC7',
    green: '#1FDF8F',
    greenDark: '#00B887',
    greenDark1: '#007A51',
    indigo: '#8C54FF',
    purple: '#B43DC6',
    purpleDark: '#5925DC',
    purpleVivid: '#6B097A',
    purpleLight: '#F4F3FF',
    yellow: '#FBBD00',
    yellowLight: '#FEF9EB',
    yellowMid: '#FFF9E0',
    yellow10: '#FFEFD2',
    positive: '#00E083',
    neutral: '#93A1BE',
    negative: '#FF0000',
    insightsGrowth: '#39C087',
    insightsCoaching: '#B43DC6',
    chartPositive: '#39C087',
    chartNegative: '#FF0000',
    insightsDevelopmentGraph: '#61E898',
    insightsWellbeingGraph: '#F32763',
    insightsRecognitionGraph: '#9160F6',
    insightsCultureGraph: '#FBBD00',

    /* Colours from PrimeVue theming (primevuePreset.ts) */
    pGray25: '#FCFCFD',
    pGray50: '#F9FAFB',
    pGray100: '#F2F4F7',
    pGray200: '#EAECF0',
    pGray300: '#D0D5DD',
    pGray400: '#98A2B3',
    pGray500: '#667085',
    pGray600: '#475467',
    pGray700: '#344054',
    pGray800: '#182230',
    pGray900: '#101828',
    pGray950: '#0C111D',

    /* following are all rain colors */
    /* NOTE: when updating here, please also update colour.less used by LESS in components */
    brandPurple: '#B43DC6',
    brandPurple10: '#B43DC61A',
    primaryBlue: '#2E5BFF',
    hoverBlue: '#274BDB',
    disabledPrimaryGrey: '#E4E9F2',
    secondaryLightBlue: '#EFF3FF',
    hoverSecondaryLightBlue: '#DEE6FF',
    disabledSecondaryGrey: '#F1F3F8',
    successGreen: '#00D68F',
    warningOrange: '#FFAA00',
    errorRed: '#FF4C3B',
    white: '#FFFFFF',
    lightGrey: '#F4F6FC',
    outline: '#E0E7FF',
    offBlack: '#2F384C',
    grey: '#93A1BE',
    darkGrey: '#7082A9',
    red10: '#FEF2F7',
    lightgreen: '#F2FDF9',
}
