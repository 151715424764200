// Functions that copied from Chartjs zoom plugin
import { Chart } from 'chart.js'

const helpers = (Chart as any).helpers
export function storeOriginalOptions(chart) {
    const originalOptions = chart.$zoom._originalOptions
    helpers.each(chart.scales, (scale) => {
        if (!originalOptions[scale.id]) {
            originalOptions[scale.id] = helpers.clone(scale.options)
        }
    })
    helpers.each(originalOptions, (opt, key) => {
        if (!chart.scales[key]) {
            delete originalOptions[key]
        }
    })
}

export function rangeMaxLimiter(zoomPanOptions, newMax) {
    if (
        zoomPanOptions.scaleAxes &&
        zoomPanOptions.rangeMax &&
        !helpers.isNullOrUndef(
            zoomPanOptions.rangeMax[zoomPanOptions.scaleAxes]
        )
    ) {
        const rangeMax = zoomPanOptions.rangeMax[zoomPanOptions.scaleAxes]
        if (newMax > rangeMax) {
            newMax = rangeMax
        }
    }
    return newMax
}

export function rangeMinLimiter(zoomPanOptions, newMin) {
    if (
        zoomPanOptions.scaleAxes &&
        zoomPanOptions.rangeMin &&
        !helpers.isNullOrUndef(
            zoomPanOptions.rangeMin[zoomPanOptions.scaleAxes]
        )
    ) {
        const rangeMin = zoomPanOptions.rangeMin[zoomPanOptions.scaleAxes]
        if (newMin < rangeMin) {
            newMin = rangeMin
        }
    }
    return newMin
}
