<template>
    <div class="gradient-container">
        <slot />
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'

// just a container with purple gradient as background
@Component({ components: {} })
export default class DarkPurpleGradientBgContainer extends Vue {}
</script>
<style lang="less" scoped>
@import '../../../../styles/palette';

.gradient-container {
    height: 100%;
    display: flex;
    align-items: center;
    background: linear-gradient(
        270deg,
        @bgDarkGradientStart 0%,
        @bgDarkGradientStop 100%
    );
}
</style>
