<template>
    <div v-click-outside="clickOut">
        <div :class="{ hide: hideKebabOptions }" class="ts-kebab box">
            <div
                class="ts-kebab-item"
                @click="
                    toggleKebabOption(
                        'setTsFilterBarVisible',
                        !tsFilterBarVisible
                    )
                "
            >
                <div>Filter Bar</div>
                <div
                    :class="[
                        'ts-kebab-toggle',
                        { 'ts-kebab-off': !tsFilterBarVisible },
                    ]"
                >
                    {{ tsFilterBarVisible ? 'On' : 'Off' }}
                </div>
            </div>
            <div
                class="ts-kebab-item"
                @click="
                    toggleKebabOption(
                        'setTsScatterPlotVisible',
                        !tsScatterPlotVisible
                    )
                "
            >
                <div>Scatter Plot</div>
                <div
                    :class="[
                        'ts-kebab-toggle',
                        { 'ts-kebab-off': !tsScatterPlotVisible },
                    ]"
                >
                    {{ tsScatterPlotVisible ? 'On' : 'Off' }}
                </div>
            </div>
            <div
                v-if="tsMobileData.enabledExecutiveReporting && !tsMobileView"
                class="ts-kebab-item"
            >
                <FilterBarDownload
                    :show-download-button="false"
                    @toggle="hideKebabOptions = true"
                />
            </div>
        </div>
        <div id="actions" @click="hideKebabOptions = !hideKebabOptions">
            <img src="../components/WebView/icons/More.svg" alt="Actions" />
        </div>
    </div>
</template>

<script lang="ts">
import { Action, Getter, State } from 'vuex-facing-decorator'
import { Component, Vue } from 'vue-facing-decorator'
import FilterBarDownload from '@/components/FilterBarDownload.vue'
import vClickOutside from 'v-click-outside'

@Component({
    components: {
        FilterBarDownload,
    },
    directives: {
        clickOutside: vClickOutside.directive,
    },
})
export default class TeamScoreboardKebab extends Vue {
    @Action public setTsFilterBarVisible
    @Action public setTsScatterPlotVisible

    @Getter public tsFilterBarVisible
    @Getter public tsScatterPlotVisible
    @Getter public tsMobileData
    @Getter public tsMobileView

    public hideKebabOptions = true

    public toggleKebabOption(option, value) {
        this[option](value)
        this.hideKebabOptions = true
    }

    public clickOut() {
        this.hideKebabOptions = true
    }
}
</script>

<style scoped lang="less">
@import '../styles/palette';

.ts-kebab {
    position: absolute;
    margin-top: 12px;
    right: 30px;
    text-align: left;
    padding: 12px 14px;
    font-weight: 500;
    font-size: 16px;
    line-height: 40px;
    z-index: 11;

    .ts-kebab-item {
        display: flex;

        .ts-kebab-toggle {
            margin-left: 12px;
            color: @blue;
        }

        .ts-kebab-off {
            color: @greyMid;
        }
    }
}

.hide {
    visibility: hidden;
}

#actions {
    width: 18px;
}
</style>
