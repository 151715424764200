import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DesktopRecipientPicker = _resolveComponent("DesktopRecipientPicker")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    show: _ctx.show,
    flex: true,
    vanilla: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_DesktopRecipientPicker, {
        single: _ctx.single,
        "allow-empty": _ctx.allowEmpty,
        selected: _ctx.selected,
        recipients: _ctx.recipients,
        title: _ctx.title,
        "can-pick-virtual-users": _ctx.canPickVirtualUsers,
        onClose: _ctx.onCancelRecipients,
        onConfirm: _ctx.onConfirmRecipients
      }, null, 8, ["single", "allow-empty", "selected", "recipients", "title", "can-pick-virtual-users", "onClose", "onConfirm"])
    ], undefined, true),
    _: 1
  }, 8, ["show"]))
}