import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HierarchyGoalTimeline = _resolveComponent("HierarchyGoalTimeline")!

  return (_openBlock(), _createBlock(_component_HierarchyGoalTimeline, {
    title: 'TOP PERFORMERS',
    loading: _ctx.loading,
    month: _ctx.month,
    "user-name": _ctx.userName,
    "number-per-page": 3,
    "hierarchy-items": _ctx.getTopPerformersByUserMonth
  }, null, 8, ["loading", "month", "user-name", "hierarchy-items"]))
}