<template>
    <div class="page-wrapper">
        <div id="huddles-container">
            <Toast />
            <div class="column column--left">
                <template v-if="loadingEntirePage">
                    <HuddlesLoading type="left-column" />
                </template>
                <template v-else-if="!showError">
                    <Select
                        id="base-selector"
                        v-model="selectedBase"
                        :options="baseDropdownOptions"
                        option-label="name"
                        placeholder="Select a base"
                        @update:modelValue="onBaseSelect"
                    />
                    <img id="company-logo" :src="companyLogo" alt="logo" />
                    <div class="huddles-card">Overview stats</div>
                    <HuddlesPromotion
                        v-if="promotionMessage && promotionMessage.length"
                        :promotion-message="promotionMessage"
                    />
                    <div class="huddles-card">Highlights</div>
                </template>
                <template v-else>
                    <Select id="base-selector" placeholder="Select a base" />
                </template>
            </div>

            <div class="column column--right">
                <HuddlesLoading
                    v-if="loadingEntirePage"
                    type="date-paginator"
                />
                <div v-else class="huddles-card" @click="tempClickDate">
                    Date component
                </div>

                <template v-if="loadingEntirePage || loadingHuddleList">
                    <HuddlesLoading
                        v-for="card in 3"
                        :key="card"
                        type="huddle-card"
                    />
                </template>
                <template v-else-if="!showError">
                    <div
                        v-for="huddle in mockHuddleDetails"
                        :key="huddle.flightCode"
                        class="huddles-card huddles-card--details"
                    >
                        <HuddleDetails
                            :huddle="huddle"
                            :base-csat-goal="baseCsatGoal"
                            :summary-view="true"
                        />
                    </div>
                </template>
            </div>
        </div>
        <div v-if="showError" id="huddles-error-container">
            <HuddlesError type="page" @on-error-click="mockLoading(false)" />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted, type ComputedRef, type Ref } from 'vue'
import { useStore } from 'vuex'
import { pendoTrackEvent } from '@/utils/pendo'
import { getCDNUrl } from '@/utils/cdn'
import { useToast } from 'primevue/usetoast'
import { Huddle } from '@/entities/huddles'
import Select from 'primevue/select'
import Toast from 'primevue/toast'
import HuddlesLoading from '@/mobile/src/views/appV4/huddles/HuddlesLoading.vue'
import HuddlesError from '@/mobile/src/views/appV4/huddles/HuddlesError.vue'
import HuddlesPromotion from '@/mobile/src/views/appV4/huddles/HuddlesPromotion.vue'
import HuddleDetails from '@/mobile/src/views/appV4/huddles/HuddleDetails.vue'

const store = useStore()
const toast = useToast()

const loadingEntirePage = ref(true)
const loadingHuddleList = ref(false)
const showError = ref(false)

const promotionMessage = ref(
    'We’re offering 2 for 1 Pringles single serve for the month of October! Available on all domestic flights.'
)
const baseCsatGoal = ref(75)

onMounted(() => {
    mockLoading(false)
})

// TODO replace once API created - temporary show of loading state
const mockLoading = (showErrorOnLoad = true) => {
    loadingEntirePage.value = true

    setTimeout(() => {
        loadingEntirePage.value = false

        if (showErrorOnLoad) {
            toast.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Internal error',
                life: 2000,
            })
            showError.value = true
        } else {
            showError.value = false
        }
    }, 2000)
}

const tempClickDate = () => {
    // TODO - temporary show of loading state
    loadingHuddleList.value = true
    setTimeout(() => {
        loadingHuddleList.value = false
    }, 4000)
}

const companyLogo: ComputedRef<string> = computed(() => {
    if (store.getters['details'].company?.logo) {
        return getCDNUrl(store.getters['details'].company?.logo)
    }
    return ''
})

const baseDropdownOptions = ref([
    { name: 'Jetstar Sydney base', code: 'SYD' },
    { name: 'Jetstar Adelaide base', code: 'ADL' },
])
const selectedBase = ref(baseDropdownOptions.value[0])

const onBaseSelect = () => {
    pendoTrackEvent('huddles-base-changed')
}

const mockHuddleDetails: Ref<Huddle[]> = ref([
    {
        flightCode: 'JQ787',
        arrivalStation: 'MEL',
        departStation: 'HKT',
        csat: 76,
        revenueGoal: 400,
        passengers: 138,
        topics: [
            { topic: 'Clean & Tidy', csat: 98.5 },
            { topic: 'Communication', csat: 90.4 },
            { topic: 'Knowledge', csat: 65.9 },
            { topic: 'Patience', csat: 55.5 },
            { topic: 'Timeliness', csat: 45.4 },
        ],
        topSellingItems: [
            { item: 'Coffee', count: 15 },
            { item: 'Muffins', count: 14 },
            { item: 'Dr Bugs Gourmet Popcorn Feijoa Cider', count: 4 },
            { item: 'Crackers', count: 11 },
            { item: 'Sausage roll', count: 10 },
            { item: 'Tea', count: 9 },
            { item: 'Wine', count: 6 },
            { item: 'Heineken Beer', count: 5 },
            { item: 'Mrs Higgins Chocolate Chip Muffins', count: 12 },
            { item: 'Chocolate', count: 4 },
        ],
    },
])
</script>

<style lang="less" scoped>
@import '@/styles/prime-vue/variables.less';
@import '@/styles/rain/variables.less';
@import '@/styles/huddles.less';
@import '~@/styles/variables.less';

@gap: 32px;
@sidePadding: 42px;

.page-wrapper {
    height: calc(100vh - @mobileNavHeightPadded);
    display: flex;
    flex-direction: column;
}

#huddles-container {
    padding: @gap @sidePadding;
    display: flex;
    gap: @gap;
    scroll-behavior: smooth;
}

#huddles-error-container {
    padding: 0 @sidePadding @gap @sidePadding;
    height: 100%;
    display: flex;
    flex: 1;
    align-items: center;
}

.column {
    display: flex;
    flex-direction: column;
    gap: @gap;

    &--left {
        width: 300px;
        height: fit-content;
    }

    &--right {
        flex: 1;
    }
}

#company-logo {
    max-width: 180px;
    margin: auto;
}

#base-selector {
    border: @borderWidth solid var(--p-gray-300);
    box-shadow: @huddlesBoxShadow;
    font-weight: @fontWeight-medium;
    color: var(--p-gray-700);
}

.huddles-card {
    &--details {
        padding: 0;
    }
}
</style>

<style lang="less">
@import '@/styles/prime-vue/colours.less';

#base-selector_list {
    gap: 2px;
    padding: 0.25rem;

    .p-select-option {
        color: @text-tertiary;
    }
    .p-select-option-selected {
        background-color: var(--p-gray-100);
        color: @text-primary;
    }
}
</style>
