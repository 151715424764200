import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/img/Icons/Grey/Plus.svg'
import _imports_1 from '@/assets/img/Icons/Grey/Chevron Down.svg'


const _withScopeId = n => (_pushScopeId("data-v-44635ae7"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled", "type", "data-id"]
const _hoisted_2 = {
  key: 0,
  src: _imports_0
}
const _hoisted_3 = {
  key: 1,
  src: _imports_1
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SimpleSpinner = _resolveComponent("SimpleSpinner")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass([
            'button',
            {
                small: _ctx.small,
                large: _ctx.large,
                primary: _ctx.primary,
                newPrimary: _ctx.newPrimary,
                caution: _ctx.caution,
                secondary: _ctx.secondary,
                newSecondary: _ctx.newSecondary,
                grey: _ctx.grey,
                red: _ctx.red,
                green: _ctx.green,
                white: _ctx.white,
                transparent: _ctx.transparent,
                muted: _ctx.muted,
                disabled: _ctx.disabled,
                full: _ctx.full,
                centred: _ctx.centred,
                bordered: _ctx.bordered,
                outline: _ctx.outline,
                round: _ctx.round,
            },
        ]),
    disabled: _ctx.disabled,
    type: _ctx.type,
    "data-id": _ctx.dataIdAttribute,
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
  }, [
    _createElementVNode("span", {
      class: _normalizeClass([{ executing: _ctx.getShowSpinner }, 'btn-content-container'])
    }, [
      _createElementVNode("span", null, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      (_ctx.icon === 'plus')
        ? (_openBlock(), _createElementBlock("img", _hoisted_2))
        : _createCommentVNode("", true),
      (_ctx.icon === 'chevron-down')
        ? (_openBlock(), _createElementBlock("img", _hoisted_3))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.getShowSpinner)
      ? (_openBlock(), _createBlock(_component_SimpleSpinner, {
          key: 0,
          class: "executing-spinner",
          size: "small",
          "line-bg-color": "transparent",
          "line-fg-color": "#fff"
        }))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}