import { ajaxClient as client } from '@/api/client'
import { TeamUpdatesCardAction, TeamUpdatesInsights } from '@/entities/insights'

export async function addTeamUpdatesCardAction(
    cardId: number,
    cardType: string,
    action: string,
    note?: string
) {
    const data = {
        card_id: cardId,
        card_type: cardType,
        action: action,
    }

    if (typeof note !== 'undefined') {
        data['note'] = note
    }

    return client.post<TeamUpdatesCardAction>(
        `/insights/teamupdates/card/` + action,
        data
    )
}

export async function getTeamUpdatesCardNote(cardId: number, cardType: string) {
    return client.get(`/insights/teamupdates/card/note`, {
        params: {
            card_id: cardId,
            card_type: cardType,
        },
    })
}
