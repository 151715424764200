import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { IFilterOption } from '@/entities/teamFilter'

export interface TimeFilterState {
    filters: IFilterOption[]
    selectedFilter: IFilterOption | null
}

export const state: TimeFilterState = {
    filters: [],
    selectedFilter: null,
}

const getters: GetterTree<TimeFilterState, any> = {
    timeFilterOptions: (state) => state.filters,
    timeFilterIsSelected: (state) => (filterOption: IFilterOption) =>
        state.selectedFilter === filterOption,
    timeFilterSelected: (state) => state.selectedFilter,
}

const actions: ActionTree<TimeFilterState, any> = {
    async initTimeFilterOptions({ commit, state, getters }) {
        commit('initTimeFilterOptions')
    },
    toggleTimeSelected(
        { commit, state, getters },
        filterOption: IFilterOption
    ) {
        commit('enableSelected', filterOption)
    },
    resetTimeFilter({ commit, state }) {
        state.filters = []
        commit('initTimeFilterOptions')
    },
}

const mutations: MutationTree<TimeFilterState> = {
    initTimeFilterOptions(state) {
        if (state.filters && state.filters.length > 0) {
            return
        }

        // All values are in DAYS!
        const hours24 = {
            label: 'Last 24 hours',
            value: '1',
        }
        const days7 = {
            label: 'Last 7 days',
            value: '7',
        }
        const days30 = {
            label: 'Last 30 days',
            value: '30',
        }

        state.filters = [hours24, days7, days30]
        // default filter selected.
        state.selectedFilter = days30
    },
    enableSelected(state, filterOption) {
        if (state.selectedFilter === filterOption) {
            return
        }
        state.selectedFilter = filterOption
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
