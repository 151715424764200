import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f7c49b7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "percentage-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "the-percentage",
      style: _normalizeStyle({ background: _ctx.bgColor })
    }, [
      _createElementVNode("div", {
        class: "percent",
        style: _normalizeStyle({
                    width: _ctx.deltaWidthPercent + '%',
                    background: _ctx.color,
                    left: _ctx.deltaPositionRelative + '%',
                })
      }, null, 4)
    ], 4),
    _createElementVNode("div", {
      style: _normalizeStyle(`left: calc(${_ctx.middlePercent}% - 2px)`),
      class: _normalizeClass(['average', { last: _ctx.last }])
    }, null, 6),
    (_ctx.showlabel)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: "average-label",
          style: _normalizeStyle(`left: calc(${_ctx.middlePercent}% - 26px)`)
        }, _toDisplayString(_ctx.averageText), 5))
      : _createCommentVNode("", true)
  ]))
}