<template>
    <div class="response" @click="onClickResponse">
        <!-- circle left -->
        <div v-if="type !== 'freetext'" class="circle-container">
            <div :class="getQuestionClass(response)" class="circle">
                {{ response.answer }}
            </div>
        </div>
        <!-- text etc right -->
        <div class="right">
            <!-- name and time -->
            <div class="user-info">
                <!-- name -->
                <span class="name">
                    {{ responseName }}
                </span>
                <!-- time since -->
                <span class="time-since">
                    {{ respondedTimeSince(response.responded) }}
                </span>
            </div>

            <!-- comment / answer -->
            <div ref="comment" class="comment">
                {{ response.comment }}
                <span v-if="!response.comment" style="font-style: italic">
                    No comment
                </span>
            </div>

            <!-- Hide this for now as not in designs atm -->
            <!--<template v-if="showBadge">-->
            <!--    <div v-for="badge in response.badges"-->
            <!--         class="badge"-->
            <!--         :style="badgeStyle(badge)"-->
            <!--    >-->
            <!--        {{ badgeLabel(badge) }}-->
            <!--    </div>-->
            <!--</template>-->

            <div class="buttons">
                <div v-if="canAddNote" class="button internal-note">
                    + Internal Note
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { badgeSchemas, ScorecardEntity } from '@/entities/scorecard'
import { IResponse } from '@/entities'
import { getQuestionClass, getQuestionType } from '@/domain/NPS'
import { timeSince } from '@/utils/time'
import { clamp } from '@/utils/clamp'
import { isFiveScoreQuestionType } from '@/utils/globalVariables'
import { embedded, postMessageToRNWebView } from '@/utils/react-native-webview'
import { Getter } from 'vuex-facing-decorator'
import { UserDetails } from '@/mobile/src/types/auth'

@Component({
    emits: ['click'],
})
export default class FeedbackScorecardResponseTile extends Vue {
    @Prop() public scorecard!: ScorecardEntity
    @Prop() public response!: IResponse
    @Prop({ type: Boolean, default: true }) public showBadge!: boolean

    @Getter public details!: UserDetails

    public get type() {
        if (!this.scorecard) {
            return 'nps'
        }
        return getQuestionType(this.scorecard.leaderboard, 'ReceiveOption')
    }

    public respondedTimeSince(responded: string) {
        return timeSince(responded)
    }

    public get responseName() {
        return this.response?.person_name ?? ''
    }

    public badgeLabel(badge) {
        return badge.label || badge.badge
    }

    public getQuestionClass(response: IResponse) {
        return getQuestionClass(
            response.answer,
            isFiveScoreQuestionType(response.question_type) ? '5star' : 'nps'
        )
    }

    public mounted() {
        clamp(this.$refs.comment, { clamp: 3 })
    }

    public badgeStyle(badge) {
        if (!badge) {
            return ''
        }
        if ((badge as any).type === 'positive' && badgeSchemas[badge.badge]) {
            const { foreColor, backColor } = badgeSchemas[badge.badge]
            return { backgroundColor: backColor, color: foreColor }
        } else {
            return { backgroundColor: '#F4F6FC', color: '#A9B4CB' }
        }
    }

    public get canAddNote() {
        return this.details.permissions.includes('ROLE_ADD_NOTES')
    }

    public onClickResponse(e) {
        if (embedded) {
            postMessageToRNWebView({
                action: 'Chat',
                params: {
                    responseId: this.response.id.toString(),
                },
            })
        } else {
            if (this.canAddNote) {
                let internalNote = e.target.classList.contains('internal-note')
                this.$emit('click', {
                    addNote: internalNote,
                })
            }
        }
    }
}
</script>

<style scoped lang="less">
@import '~@/styles/layout.less';
@import '~@/styles/palette.less';

.response {
    display: flex;
    padding: 12px;
    min-width: 250px;
    background: #ffffff;
    border: 1px solid #e0e7ff;
    box-sizing: border-box;
    border-radius: 8px;
    max-width: 350px;
    margin: 0 auto;
    cursor: pointer;

    .circle-container {
        display: flex;

        .circle {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            text-align: center;
            line-height: 40px;
            font-weight: 500;
            text-transform: uppercase;
            font-size: 16px;
            letter-spacing: 0;
        }

        .success {
            background-color: @positiveBg;
            color: @positive;
        }

        .warning {
            background-color: @neutralBg;
            color: @neutral;
        }

        .danger {
            background-color: @negativeBg;
            color: @negative;
        }
    }

    .right {
        flex-grow: 1;
        margin-left: 12px;
        min-height: 100px;
        display: flex;
        flex-direction: column;

        .comment {
            font-size: 13px;
            color: #2f384c;
            letter-spacing: 0;
            line-height: 20px;
            min-width: 0;
            flex: 1;
            margin-top: 10px;
        }

        .user-info {
            display: flex;

            .name {
                font-weight: 500;
                font-size: 10px;
                line-height: 12px;
                letter-spacing: 1px;
                text-transform: uppercase;
                color: #a9b4cb;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                min-width: 0;
                flex: 10 10 0;
                font-style: normal;
            }

            .time-since {
                font-size: 10px;
                line-height: 12px;
                color: #a9b4cb;
                text-align: right;
                padding-left: 5px;
                flex: 1 0 auto;
                letter-spacing: 0;
            }
        }

        .badge {
            border-radius: 4px;
            font-style: normal;
            font-weight: bold;
            font-size: 10px;
            line-height: 10px;
            display: inline-block;
            padding: 4px;
            position: relative;
            left: -4px;
            margin-right: 5px;
            text-transform: uppercase;
        }

        .buttons {
            margin-top: 10px;
            display: flex;
            justify-content: flex-end;

            .button {
                font-size: 13px;
                color: @blue;
                cursor: pointer;
            }
        }
    }
}
</style>
