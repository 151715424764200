<template>
    <div class="toast-container">
        <transition name="fade">
            <div v-if="toastList.length" class="toast-backdrop" />
        </transition>
        <transition-group name="slide-in-out">
            <div
                v-for="event in toastList"
                :key="event.time"
                class="toast-display"
                :class="[event.color, { dismissable: event.dismissable }]"
                @click="onClickToast(event)"
            >
                <FontAwesomeIcon
                    class="toast-icon"
                    :icon="getIcon(event.icon)"
                ></FontAwesomeIcon>
                <div class="toast-msg">{{ event.msg }}</div>
            </div>
        </transition-group>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import useEmitter from '@/composables/useEmitter'
import { showToastEvent, hideToastEvent } from '@/mobile/src/utils/toastbus'
import { ToastEvent, ToastIcon } from '@/mobile/src/types/toasts'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import {
    faCheckCircle,
    faExclamationCircle,
    faHourglassHalf,
} from 'fontawesome/free-solid-svg-icons'

@Component({
    components: {
        FontAwesomeIcon,
    },
})
export default class ToastOverlay extends Vue {
    public toastList: ToastEvent[] = []
    public emitter = useEmitter()

    mounted() {
        this.emitter?.on(showToastEvent, this.onShowToast)
        this.emitter?.on(hideToastEvent, this.onHideToast)
    }

    destroyed() {
        this.emitter?.off(showToastEvent, this.onShowToast)
        this.emitter?.off(hideToastEvent, this.onHideToast)
    }

    getIcon(icon) {
        switch (icon) {
            case ToastIcon.Success:
                return faCheckCircle
            case ToastIcon.Waiting:
                return faHourglassHalf
            case ToastIcon.Alert:
                return faExclamationCircle
            default:
                return faCheckCircle
        }
    }

    onClickToast(event: ToastEvent) {
        if (event.dismissable) {
            this.onHideToast(event)
        }
    }

    onShowToast(event: ToastEvent) {
        this.toastList.push(event)
        if (event.wait) {
            setTimeout(() => {
                const index = this.toastList.indexOf(event)
                if (index > -1) {
                    this.toastList.splice(index, 1)
                }
            }, event.wait)
        }
    }

    onHideToast(event) {
        const index = this.toastList.findIndex(
            (toast) => toast.time === event.time
        )
        if (index > -1) {
            this.toastList.splice(index, 1)
        }
    }
}
</script>

<style scoped lang="less">
@import '~@/styles/rain/colour.less';
@import '~@/mobile/src/styles/zindex.less';

.toast-container,
.toast-backdrop {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: @filterHeaderIndex;
}

.toast-backdrop {
    background-color: @deepFog;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.toast-display {
    width: max-content;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: center;

    position: fixed;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    max-width: 90%;
    padding: 10px 20px;
    border-radius: 4px;
    box-shadow: 0 4px 70px 0 rgba(0, 0, 0, 0.25);
    z-index: @filterHeaderIndex;

    &.dismissable {
        pointer-events: all;
    }

    &.message {
        background-color: @white;
    }
    &.shoutout {
        background-color: @successGreen;
    }
    &.coaching {
        background-color: @warningOrange;
    }
    &.email-reply {
        background-color: @softCyan;
    }
    &.internal-note {
        background-color: @softYellow;
    }
    &.warning {
        background-color: @errorRed;
    }
    &.workflow {
        background-color: @teal;
    }

    .toast-icon {
        padding-right: 7px;
    }

    .toast-msg {
        font-family: Roboto, serif;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 1px;
        text-align: left;
        text-transform: uppercase;
        vertical-align: center;
    }
}

.slide-in-out-enter-from,
.slide-in-out-leave-to {
    transform: translate(-50%, 100vh);
}

.slide-in-out-enter-active {
    animation: slide 0.5s backwards;
    transition: 0.5s;
}

.slide-in-out-leave-active {
    animation: slide 0.5s forwards;
    transition: 0.5s;
}
</style>
