<template>
    <PillButtonWithIcon
        :class="['status-pill-button', status]"
        :label="label"
        :is-active="isActive"
        :disabled="disabled"
        @click="$emit('click')"
    />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import PillButtonWithIcon from '@/components/PillButtonWithIcon.vue'
import { InsightsStatus } from '@/mobile/src/types/insights'

@Component({
    components: {
        PillButtonWithIcon,
    },
    emits: ['click'],
})
export default class StatusPillButton extends Vue {
    @Prop({
        type: String,
        required: true,
    })
    readonly status!: InsightsStatus

    @Prop({
        type: String,
        required: true,
    })
    readonly label!: string

    @Prop({
        type: Boolean,
        required: false,
        default: false,
    })
    readonly isActive!: boolean

    @Prop({
        type: Boolean,
        required: false,
        default: false,
    })
    readonly disabled!: boolean
}
</script>

<style lang="less" scoped>
.status-pill-button {
    &.gold::after {
        background-image: url('~@/assets/img/Icons/round_star_gold.svg');
    }
    &.silver::after {
        background-image: url('~@/assets/img/Icons/round_star_silver.svg');
    }
    &.bronze::after {
        background-image: url('~@/assets/img/Icons/round_star_bronze.svg');
    }
}
</style>
