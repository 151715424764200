import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { getHierarchyGoalByLocationLevel } from '@/api/reportcard'
import { IHierarchyGoal } from '@/entities/reportcard'

export interface HierarchyGoalState {
    hierarchyGoalByLocationLevel: Record<string, IHierarchyGoal[]>
    selectionLocationLevel: string
}

export interface LocationState {
    selectionLocationLevel: string
}

export const state: HierarchyGoalState & LocationState = {
    hierarchyGoalByLocationLevel: {},
    selectionLocationLevel: 'country_c',
}

const getters: GetterTree<HierarchyGoalState, any> = {
    getHierarchyGoalByLocationLevel: (state) => () =>
        state.hierarchyGoalByLocationLevel[state.selectionLocationLevel] || [],
    hierarchyData: (state) =>
        state.hierarchyGoalByLocationLevel[state.selectionLocationLevel] ?? [],
}

const actions: ActionTree<HierarchyGoalState, any> = {
    async loadHierarchyGoalByLocationLevel(
        { commit, state },
        {
            selectionLocationLevel,
            selection,
            dayRange = '30',
            questionType = 'nps',
            forceRefresh = false,
        }
    ) {
        // questionType is used to differentiate the cache data only but not sent through API call to server side.
        // Refer to this PR for the reason: https://github.com/asknicely/asknicelydo/pull/17750
        state.selectionLocationLevel = btoa(
            `${selectionLocationLevel}${selection}${dayRange}${questionType}`
        )
        if (
            state.hierarchyGoalByLocationLevel[state.selectionLocationLevel] &&
            !forceRefresh
        ) {
            return
        }

        try {
            const { data } = await getHierarchyGoalByLocationLevel(
                selectionLocationLevel,
                selection,
                dayRange
            )
            commit('setHierarchyGoalByLocationLevel', {
                data: data.hierarchyGoalByLocationLevel,
            })
        } catch (error) {
            // handle error
        }
    },
}

const mutations: MutationTree<HierarchyGoalState> = {
    setHierarchyGoalByLocationLevel(
        state,
        payload: { data: IHierarchyGoal[] }
    ) {
        state.hierarchyGoalByLocationLevel[state.selectionLocationLevel] =
            payload.data
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
