<template>
    <LeaderboardChartV2
        v-if="tsGroupSummary"
        :hidden="!tsScatterPlotVisible"
        :is-five-score="tsGroupSummary.isFiveScore"
        :chart-data="tsChartGroupsDataV2"
        show-labels
        show-average
        mobile-style
        @chart-click="handleChartClick"
        @label-hover="setTsGridHoverGroup"
    />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { Getter, Action, Mutation } from 'vuex-facing-decorator'
import {
    TsGroupSummaryEntity,
    TsFilterTypes,
    TsFilterOperators,
    TsFilterEntity,
} from '@/entities/teamscoreboard'
import { setZoom, resetZoom } from '@/utils/zoomChart'
import LeaderboardChartV2 from '@/components/LeaderboardChart/LeaderboardChartV2.vue'

@Component({
    components: {
        LeaderboardChartV2,
    },
})
export default class NPSResponsesPlot extends Vue {
    @Prop({ type: Object, required: false }) public teamScoreboard
    @Getter public tsGroupSummary?: TsGroupSummaryEntity
    @Getter public readonly tsScatterPlotVisible?: boolean
    @Getter public isZoomIn
    @Getter public isLeafGroup
    @Getter public selectedCategory
    @Getter public tsChartGroupsDataV2

    @Action public setTsGroup
    @Action public setTsGridHoverGroup
    @Action public setTsFilter
    @Action public loadTeamScoreboard
    @Action public clearTsFilterOfType
    @Action public popTsHierarchy

    @Mutation public setZoomIn
    @Mutation public setSelectedCategory
    @Mutation public setSelectedPoint

    public timer = 0.15
    public gridsDirection: object = {
        highRespLowPerf: { x: 0, y: 2 },
        highRespMedPerf: { x: 1, y: 2 },
        highRespHighPerf: { x: 2, y: 2 },
        medRespLowPerf: { x: 0, y: 1 },
        medRespMedPerf: { x: 1, y: 1 },
        medRespHighPerf: { x: 2, y: 1 },
        lowRespLowPerf: { x: 0, y: 0 },
        lowRespMedPerf: { x: 1, y: 0 },
        lowRespHighPerf: { x: 2, y: 0 },
    }
    public zoom: object = {
        x: 3,
        y: 3,
    }

    // Chart click handler
    public async handleChartClick(event: MouseEvent, context) {
        const chart = context.chart
        const chartGroups = context.chartGroups

        const handled = await this.handleClickOnPoints(event, chart)

        // Click on a point
        if (handled) {
            // a point was clicked and has been handled
            return
        }

        // Logic about switching zoom in and zoom out
        if (this.clickOnZoomOut(event, chart)) {
            resetZoom(chart, this.timer)
            this.setZoomIn(false)
            chartGroups[this.selectedCategory].selected = false
            this.setSelectedCategory('')
            this.clearTsFilterOfType(TsFilterTypes.grid)
            return
        }

        let exists = false
        const data = chart.data.datasets ? chart.data.datasets : []

        // We didn't click on a point - let's look at the chart regions
        if (this.isZoomIn) {
            return
        }
        for (const key in chartGroups) {
            if (!chartGroups.hasOwnProperty(key)) {
                continue
            }

            const currentGroup = chartGroups[key]

            const found = this.checkWithinBounds(
                event.offsetX,
                event.offsetY,
                currentGroup
            )

            if (
                found &&
                this.teamScoreboard &&
                !currentGroup.selected &&
                !this.clickOnZoomOut(event, chart)
            ) {
                // If there is no element in the group
                if (!chartGroups[key].hasElements) {
                    this.setSelectedCategory('')
                    continue
                }
                // Deal with the logic of zoom in and zoom out
                currentGroup.selected = !this.isZoomIn
                if (!this.isZoomIn) {
                    this.setZoomIn(true)
                    this.setSelectedCategory(key)
                    const timer = this.timer
                    setZoom(chart, this.zoom, this.gridsDirection[key], timer)
                }
                if (
                    this.teamScoreboard.field ===
                    this.teamScoreboard.parentField
                ) {
                    // If the current field is the same as the parent, we're at the bottom level
                    // We can't have something selected and use the tsfilter, so go back up one level
                    this.popTsHierarchy()
                }

                this.setTsFilter({
                    type: TsFilterTypes.grid,
                    operator: TsFilterOperators.equals,
                    filters: currentGroup.groups,
                } as TsFilterEntity)

                exists = true
            } else {
                currentGroup.selected = false
            }
        }

        for (const key in chartGroups) {
            if (!chartGroups.hasOwnProperty(key)) {
                continue
            }

            const currentGroup = chartGroups[key]

            data.forEach((point) => {
                if (!currentGroup.groups.includes(point.label)) {
                    // We're not looking at the right group/point, move on
                    return
                }
            })
        }

        if (!exists) {
            this.clearTsFilterOfType(TsFilterTypes.grid)
        }
        chart.update()
        chart.render()
    }

    // Handle the click event when clicking on a point
    private async handleClickOnPoints(event, chart) {
        const chartElement = chart.getElementsAtEventForMode(
            event,
            'nearest',
            { intersect: true },
            false
        )
        if (chartElement && chartElement[0] && chart.data.datasets) {
            if (this.isZoomIn) {
                chart.options.elements.point.radius = 4 * 2.5
                chart.options.elements.point.HoverRadius = 6 * 2.5
            } else {
                chart.options.elements.point.radius = 4
                chart.options.elements.point.HoverRadius = 6
            }

            const datasetIndex = chartElement[0].datasetIndex
            const group = chart.data.datasets[datasetIndex].group
            this.setSelectedPoint(group)

            if (this.teamScoreboard) {
                await this.loadTeamScoreboard({
                    parent: { field: this.teamScoreboard.field, group: group },
                })
            } else {
                this.setTsGroup(group)
            }

            return true
        }
    }

    // Returns whether users click on the zoom out button
    private clickOnZoomOut(event, chart) {
        if (!this.isZoomIn) {
            return false
        }
        const x = event.offsetX
        const y = event.offsetY
        const area = chart.chartArea
        const x0 = area.right - 25
        const y0 = area.bottom - 25
        const r = 16
        return (x0 - x) ** 2 + (y0 - y) ** 2 <= r ** 2
    }

    // Returns whether users click on one box of the nine boxes
    private clickOnNineBoxes(x: number, y: number, boxes) {
        const withinX =
            x > boxes.highRespLowPerf.left &&
            x <= boxes.lowRespHighPerf.left + boxes.lowRespHighPerf.width
        const withinY =
            y > boxes.highRespLowPerf.top &&
            y <= boxes.lowRespHighPerf.top + boxes.lowRespHighPerf.height

        return withinX && withinY
    }

    // Returns whether users click on one box of the nine boxes
    private checkWithinBoundsV2(x: number, y: number, boxes) {
        const withinX =
            x > boxes.lowPerf.left &&
            x <= boxes.highPerf.left + boxes.highPerf.width
        const withinY =
            y > boxes.highPerf.top &&
            y <= boxes.highPerf.top + boxes.highPerf.height

        return withinX && withinY
    }

    // Returns whether users click on any box of the nine boxes
    private checkWithinBounds(x: number, y: number, chartGroup) {
        if (!this.isZoomIn) {
            return false
        }
        const withinX =
            x > chartGroup.left && x <= chartGroup.left + chartGroup.width
        const withinY =
            y > chartGroup.top && y <= chartGroup.top + chartGroup.height

        return withinX && withinY
    }
}
</script>
