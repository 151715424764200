import { ScorecardEntity } from '@/entities/scorecard'
import ScorecardPage from '@/mobile/src/views/scorecard/ScorecardPage.vue'
import ScorecardPageExpanded from '@/mobile/src/views/scorecard/ScorecardPageExpanded.vue'
import ScreenBottomButton from '@/components/Notices/ScreenBottomButton.vue'
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { Action, Getter } from 'vuex-facing-decorator'

@Component({
    components: {
        ScorecardPage,
        ScorecardPageExpanded,
        ScreenBottomButton,
    },
})
export default class ScorecardHomeUser extends Vue {
    @Prop({ type: String, required: true })
    readonly teamMemberUserId!: string // view another users scorecard / my teams

    @Getter
    readonly scorecardLoading!: boolean

    @Getter
    readonly scorecardOutdated!: boolean

    @Getter
    readonly scorecard?: ScorecardEntity

    @Getter
    readonly canSendNotices!: number

    @Action
    loadScorecard

    get messageButtonText() {
        if (!this.scorecard) {
            return 'Message Team Member'
        }

        return `Message ${this.scorecard.user.name}`
    }

    mounted() {
        if (!this.scorecard) {
            this.load()
            return
        }

        const scorecardUserId = this.scorecard.user.id.toString()

        if (this.teamMemberUserId !== scorecardUserId) {
            this.load()
            return
        }

        if (this.scorecardOutdated) {
            this.load()
        }
    }

    async load() {
        if (!this.scorecardLoading) {
            await this.loadScorecard(this.teamMemberUserId)
        }
    }
}
