<template>
    <FontAwesomeIcon v-if="positive" :icon="faAngleDoubleUp" class="positive" />
    <FontAwesomeIcon v-else :icon="faAngleDoubleDown" class="negative" />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import {
    faAngleDoubleUp,
    faAngleDoubleDown,
} from 'fontawesome/free-solid-svg-icons'

@Component({ components: { FontAwesomeIcon } })
export default class UpDownDoubleChevron extends Vue {
    readonly faAngleDoubleUp = faAngleDoubleUp
    readonly faAngleDoubleDown = faAngleDoubleDown

    @Prop({ type: Boolean, default: true })
    readonly positive!: boolean
}
</script>

<style lang="less">
@import '~@/styles/palette';

.positive {
    color: @positive;
}

.negative {
    color: @negative;
}
</style>
