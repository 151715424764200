<template>
    <Modal
        small-header
        :padding="20"
        :size="modalSize.Small"
        :show="true"
        :title="agreedUsersModalTitle"
        :close="closeModal"
        :allow-click-out="true"
    >
        <div
            v-for="(user, index) in usersAgreed"
            :key="index"
            class="agreed-user-row"
            @click="closeModal"
        >
            <AvatarOrInitials :user="user" :size="32" />
            <p class="user-name">{{ user.name }}</p>
        </div>
    </Modal>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import Modal from '@/components/Modal.vue'
import { ModalSize } from '@/components/modal-size'
import AvatarOrInitials from '../../components/WebView/AvatarOrInitials.vue'
import { UserDetail } from '@/entities'

@Component({
    components: {
        Modal,
        AvatarOrInitials,
    },
    emits: ['close'],
})
export default class SuggestionAgreedUsersModal extends Vue {
    @Prop({ type: Array }) public usersAgreed!: UserDetail[]

    public get modalSize() {
        return ModalSize
    }

    public get agreedCount() {
        if (!this.usersAgreed) {
            return 0
        }

        return this.usersAgreed.length
    }

    public get agreedUsersModalTitle() {
        return `${this.agreedCount} users agree`
    }

    public closeModal() {
        this.$emit('close')
    }
}
</script>

<style scoped lang="less">
@import '../../styles/palette';

.agreed-user-row {
    display: flex;
    height: 50px;

    .user-name {
        line-height: 30px;
        font-size: 16px;
        margin: 0 0 0 12px;
        color: @grey90;
    }
}
</style>
