<template>
    <transition name="fade">
        <!-- id "notice-stack-container" is for confetti animation-->
        <div id="notice-stack-container" class="notices-container">
            <template v-if="message">
                <div class="notice-container">
                    <NoticeTemplate
                        :notice="message"
                        :is-receiver="mailbox === 'received'"
                        @respond="onClose"
                    />
                </div>
            </template>
        </div>
    </transition>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import Loading from '@/components/Loading.vue'
import { Action } from 'vuex-facing-decorator'
import { IMessage } from '@/entities/notices'
import { embedded, postMessageToRNWebView } from '@/utils/react-native-webview'
import NoticeTemplate from './NoticeTemplate.vue'

@Component({
    components: { NoticeTemplate, Loading },
    emits: ['close'],
})
export default class NoticeMessage extends Vue {
    @Prop() private id!: number | string
    @Prop({ type: String, default: 'received' }) private mailbox?: string // [received | sent |scheduled]
    @Prop({ type: String, default: 'message' }) private messageType?: string

    @Prop() private message!: IMessage

    @Action private loadMessage

    private async mounted() {
        if (!this.message) {
            await this.loadMessage(Number(this.id))
        }
    }

    private async onClose() {
        await this.$emit('close')
        if (embedded) {
            postMessageToRNWebView({
                action: 'back', // tell react native to go back
            })
        }
    }
}
</script>

<style lang="less" scoped>
@import '../../styles/palette';
@import '../../styles/fade.less';

.notices-container {
    height: 100vh;
    z-index: 9;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
}

.notice-container {
    z-index: 99;
    position: relative;
    top: calc(50%);
    transform: translateY(-50%);
    width: 343px;
    height: 540px;
    text-align: left;
    margin: auto;

    @media screen and (max-width: 320px) {
        height: 480px;
        width: 296px;
    }
}
</style>
<style lang="less">
.notices-container {
    .bold {
        font-weight: 600;
    }
}
</style>
