/**
 * Store data to sessionStorage for set hours
 * Caller function has responsibility to convert data using JSON.stringify(...)
 *
 * @param cacheKey - Key to store data by
 * @param data - Data to cache
 * @param cacheHours - Duration to cache for in hours
 * @param expiresAt - If passed, will set expiry to this time, ignoring cacheHours
 */
export function saveToSessionStorage(
    cacheKey: string,
    data: string,
    cacheHours = 12,
    expiresAt: string | null = null
): void {
    let cacheTill = new Date()
        .setHours(new Date().getHours() + cacheHours)
        .toString()

    if (expiresAt !== null) {
        cacheTill = expiresAt.toString()
    }

    if (data) {
        const cacheItem = {
            value: data,
            expiry: cacheTill,
        }
        sessionStorage.setItem(cacheKey, JSON.stringify(cacheItem))
    }
}

/**
 * Get cached data if expiry time is not passed.
 * Caller function has responsibility to convert data using JSON.parse(...) if not NULL returned
 */
export function getFromSessionStorage(cacheKey: string): string | null {
    const cachedData = sessionStorage.getItem(cacheKey)
    if (!cachedData) {
        return null
    }
    const cachedItem = JSON.parse(cachedData)
    if (cachedItem && parseInt(cachedItem.expiry) > Date.now()) {
        return cachedItem.value
    }
    return null
}

/**
 * Get the cached data expiry time if not expired, returns null otherwise
 */
export function getSessionStorageExpiry(cacheKey: string): string | null {
    const cachedData = sessionStorage.getItem(cacheKey)
    if (!cachedData) {
        return null
    }
    const cachedItem = JSON.parse(cachedData)
    if (cachedItem && parseInt(cachedItem.expiry) > Date.now()) {
        return cachedItem.expiry
    }
    return null
}

export function clearAllFromSessionStorage(): void {
    sessionStorage.clear()
    return
}
