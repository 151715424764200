import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c2391e84"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "score-container" }
const _hoisted_2 = { class: "off-black" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Typography = _resolveComponent("Typography")!
  const _component_UpDownArrow = _resolveComponent("UpDownArrow")!
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_InsightsDetailRow = _resolveComponent("InsightsDetailRow")!

  return (_openBlock(), _createBlock(_component_InsightsDetailRow, {
    "show-chevron": _ctx.showChevron,
    "wide-layout": _ctx.wideLayout,
    selected: _ctx.selected,
    "on-sidebar": _ctx.onSidebar
  }, {
    icon: _withCtx(() => [
      _renderSlot(_ctx.$slots, "icon", {}, undefined, true)
    ]),
    title: _withCtx(() => [
      _createVNode(_component_Typography, { variant: "subtitle1" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.title), 1)
        ], undefined, true),
        _: 1
      })
    ]),
    subtitle: _withCtx(() => [
      _createVNode(_component_Typography, {
        variant: "caption",
        class: "subtitle"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.description), 1)
        ], undefined, true),
        _: 1
      }),
      _renderSlot(_ctx.$slots, "badge", {}, undefined, true)
    ]),
    score: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Typography, { variant: "subtitle1" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(Math.round(_ctx.score)), 1)
          ], undefined, true),
          _: 1
        }),
        _createVNode(_component_Typography, { variant: "caption" }, {
          default: _withCtx(() => [
            _createVNode(_component_UpDownArrow, { positive: _ctx.deltaPositive }, null, 8, ["positive"])
          ], undefined, true),
          _: 1
        })
      ]),
      (_ctx.percentage)
        ? (_openBlock(), _createBlock(_component_Typography, {
            key: 0,
            variant: "caption",
            class: "percent"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.previousPercentage) + " ", 1),
              _createVNode(_component_FontAwesomeIcon, {
                icon: _ctx.faArrowRight,
                class: _normalizeClass({
                        red: !_ctx.isPercentageChangePositive,
                        green: _ctx.isPercentageChangePositive,
                    })
              }, null, 8, ["icon", "class"]),
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.percentage), 1)
            ], undefined, true),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["show-chevron", "wide-layout", "selected", "on-sidebar"]))
}