import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-417992e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["type", "placeholder", "value", "disabled"]
const _hoisted_3 = {
  key: 1,
  class: "error-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["text-input", { error: _ctx.error }])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      class: "input",
      type: _ctx.type ? _ctx.type : 'text',
      placeholder: _ctx.placeholder ? _ctx.placeholder : '',
      value: _ctx.value,
      disabled: _ctx.disabled,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('input', $event.target.value))),
      onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('blur')))
    }, null, 40, _hoisted_2),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.error), 1))
      : _createCommentVNode("", true)
  ], 2))
}