<template>
    <Modal
        :show="!!filterEditing"
        title="Save filter"
        :close="handleClose"
        class="filter-form"
    >
        <form>
            <TextInput
                :value="name"
                label="filter name"
                @input="name = $event"
            />
            <div v-if="filterEditMode === 'edit'" class="readable">
                {{ readable }}
            </div>
            <SelectInput
                :value="frequency"
                label="email digest"
                :options="['', 'daily', 'monthly', 'weekly']"
                :option-labels="['never', 'daily', 'monthly', 'weekly']"
                @change="frequency = $event"
            />
            <StripInput
                v-if="frequency === 'weekly'"
                :value="day"
                label="every"
                :options="stripDayOptions"
                @change="day = $event"
            />
            <div
                v-if="frequency !== '' && userRoles.length !== 0"
                class="user-role-digest"
            >
                <br />
                <MultiCheckBox
                    title="Which user roles should receive this digest"
                    header="User Roles"
                    :selected="selectedUserRoles"
                    :options="multiMetricUserRoles"
                    disable-highlighting-count
                    wider-selection-box
                    @toggle="updateSelectedUserRoles($event)"
                >
                </MultiCheckBox>
            </div>
            <div class="buttons-right">
                <BaseButton
                    v-if="filterEditMode === 'edit'"
                    secondary
                    caution
                    :click="handleDelete"
                >
                    Delete
                </BaseButton>
                <BaseButton primary muted :click="handleClose">
                    Cancel
                </BaseButton>
                <BaseButton primary :click="handleSave"> Save </BaseButton>
            </div>
        </form>
    </Modal>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from 'vue-facing-decorator'
import { Action, Getter } from 'vuex-facing-decorator'
import Modal from '@/components/Modal.vue'
import BaseButton from '@/components/BaseButton.vue'
import TextInput from '@/components/TextInput.vue'
import SelectInput from '@/components/SelectInput.vue'
import StripInput from '@/components/StripInput.vue'
import MultiCheckBox from '@/components/MultiCheckbox.vue'
import { IFilter, IDigest } from '@/entities'
import { IUserType } from '@/entities/usertype'
import { StripInputOption, initDayOptions } from '@/entities/survey'

@Component({
    components: {
        Modal,
        BaseButton,
        TextInput,
        SelectInput,
        StripInput,
        MultiCheckBox,
    },
})
export default class FilterAndDigestEditor extends Vue {
    public show = false
    public name = ''
    public is_shared = false
    public frequency = ''
    public day = '1'
    public dayOptions: StripInputOption[] = initDayOptions
    public selectedUserRoles: IUserType[] = []

    @Prop({ type: Array }) public userRoles

    @Getter public filterEditMode
    @Getter public filterEditing
    @Getter public digestEditing

    @Action public setFilterEditing
    @Action public setDigestEditing
    @Action public createFilter
    @Action public patchFilter
    @Action public deleteFilter
    @Action public createDigest
    @Action public patchDigest
    @Action public deleteDigest
    @Action public statusAddToast

    /**
     * When filterEditing and digestEditing changed, update the local form values here.
     * They should only be changed when the modal is being opened and closed (set to null)
     */
    @Watch('filterEditing') public onFilterEditingChanged(filter?: IFilter) {
        if (filter) {
            this.is_shared = !!filter.is_shared
            this.name = filter.name || ''
        } else {
            this.is_shared = false
            this.name = ''
        }
    }

    @Watch('digestEditing') public onDigestEditingChanged(digest?: IDigest) {
        if (digest) {
            this.frequency = digest.frequency || ''
            this.day = digest.day || '1'
            this.selectedUserRoles = digest.userRoles || []
        } else {
            this.frequency = ''
            this.day = '1'
            this.selectedUserRoles = []
        }
    }

    public get readable(): string {
        if (!this.filterEditing) {
            return ''
        }

        const { filter_rules, time_unit, time_value } = this.filterEditing

        const s = String(time_value) !== '1'

        const timeStr =
            time_unit === 'custom'
                ? `From ${time_value.replace('_', ' - ')}`
                : `For the last ${s ? time_value : ''} ${time_unit}${
                      s ? 's' : ''
                  }`

        const ruleStr = filter_rules
            .filter(({ value }) => value[0] !== 'any')
            .map(
                ({ column, value }) =>
                    `${column
                        .replace('_c', '')
                        .replace('_', ' ')} = ${value.join(', ')}`
            )
            .join(' and ')

        return ruleStr.length > 0 ? `${timeStr} who have ${ruleStr}` : timeStr
    }

    public get stripDayOptions() {
        return this.dayOptions.map((dayOption) => {
            if (dayOption.value === this.day) {
                dayOption.selected = true
            } else {
                dayOption.selected = false
            }

            return dayOption
        })
    }

    public get multiMetricUserRoles() {
        const userRoles = {}
        this.userRoles.map((userRole) => {
            return (userRoles[userRole.id] = userRole.name)
        })

        return userRoles
    }

    public handleClose() {
        this.setFilterEditing({ id: -1, mode: 'closed' })
        this.setDigestEditing(-1)
    }

    public updateSelectedUserRoles(selected) {
        this.selectedUserRoles = selected
    }

    public async handleSave() {
        const {
            name,
            is_shared,
            frequency,
            day,
            filterEditMode,
            filterEditing,
            digestEditing,
            selectedUserRoles,
        } = this

        if (!filterEditing) {
            return
        }

        if (filterEditMode === 'create') {
            const newFilter = await this.createFilter({
                ...filterEditing,
                name,
                is_shared,
            })

            if (frequency) {
                await this.createDigest({
                    filter_id: newFilter.id,
                    frequency,
                    day,
                    userRoles: selectedUserRoles,
                })
            }
        } else if (filterEditMode === 'edit') {
            await this.patchFilter({ ...filterEditing, name, is_shared })

            if (frequency && !digestEditing) {
                this.createDigest({
                    filter_id: filterEditing.id,
                    frequency,
                    day,
                    userRoles: selectedUserRoles,
                })
            } else if (frequency && digestEditing) {
                this.patchDigest({
                    id: digestEditing.id,
                    filter_id: filterEditing.id,
                    frequency,
                    day,
                    userRoles: selectedUserRoles,
                })
            } else if (!frequency && digestEditing) {
                this.deleteDigest(digestEditing.id)
            }
        }

        this.handleClose()
        this.statusAddToast(`'${name || 'filter'}' has been saved`)
    }

    public async handleDelete() {
        if (this.digestEditing) {
            await this.deleteDigest(this.digestEditing.id)
        }
        if (this.filterEditing) {
            await this.deleteFilter(this.filterEditing.id)
        }

        this.handleClose()
        this.statusAddToast(`'${this.name || 'filter'}' has been deleted`)
    }
}
</script>

<style scoped lang="less">
@import '../styles/palette.less';

.buttons-right {
    text-align: right;
    padding-top: 10px;
}

.readable {
    padding-bottom: 20px;
    font-weight: bold;
    text-transform: none;
}
.user-role-digest {
    text-transform: none;
}
</style>

<style>
.filter-form .select-input .input {
    padding: 0 6px;
}

.filter-form .text-input .input {
    height: auto;
}
</style>
