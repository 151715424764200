<template>
    <div
        v-click-outside="() => (opened = false)"
        class="time-range-container"
        @click="opened = !opened"
    >
        <img src="@/components/WebView/icons/More.svg" alt="more" />
        <div v-if="opened" class="time-options">
            <div class="title">Time Filter</div>
            <div
                v-for="option in options"
                :key="`${option.timeRange.timeUnit}-${option.timeRange.timeValue}`"
                :class="['option', { active: isOptionSelected(option) }]"
                @click="$emit('selectTime', option.timeRange)"
            >
                <div class="label">{{ option.label }}</div>
                <div v-if="isOptionSelected(option)" class="check">
                    <FontAwesomeIcon :icon="faCheck" />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import InsightsTimePicker from '@/pages/insights/InsightsTimePicker'

@Component({
    emits: ['selectTime'],
})
export default class InsightsTimePickerMobile extends InsightsTimePicker {
    // Under certain circumstances the super properties won't load without calling it explicitly.  Basically just
    // being safe.
    constructor(opts) {
        super(opts, Vue)
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/palette';
@import '~@/styles/variables';

.time-range-container {
    width: 22px;
    display: flex;
    justify-content: center;
    align-items: center;

    .time-options {
        position: absolute;
        top: 43px;
        right: 15px;
        z-index: 1000;
        width: 200px;

        background: white;
        border: 1px solid @blueLight;
        border-radius: @borderRadius;
        text-align: left;

        .title {
            font-size: 14px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: @grey40;
            margin: 10px;
        }

        .option {
            padding: 10px;
            display: flex;
            justify-content: space-between;

            &.active {
                color: @blue;
                font-weight: 500;
            }

            .check-mark {
                filter: @blueFilter;
            }
        }
    }
}
</style>
