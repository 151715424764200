<template>
    <WebViewScreen class="composer" no-pad avoid-keyboard :title="typeTitle">
        <template #left-button>
            <img
                src="@/components/WebView/icons/BackLeftAction.svg"
                alt="Back"
                @click="goPickNoticeType"
            />
        </template>
        <template #right-button>
            <span
                :class="{ active: canSend }"
                class="send"
                @click="onSendNotices"
            >
                {{ sendButtonText }}
            </span>
        </template>

        <div class="composer-container">
            <div class="composer-option compact" @click="goPickRecipients">
                <Loading
                    v-if="loadingPreselectedRecipient"
                    :loading="loadingPreselectedRecipient"
                    :contained="true"
                />
                <div v-else-if="!hasRecipients" class="placeholder">
                    Add Recipients
                </div>
                <div v-else class="names">
                    <SelectedRecipientPills
                        :recipients="recipients"
                        :selected-recipients="selectedRecipients"
                        :can-remove-tabs="false"
                    />
                </div>
            </div>
            <div class="editor">
                <ChatInput
                    html-mode
                    disable-auto-focus
                    :placeholder="chatInputPlaceholder"
                    custom-class="notice-editor"
                    class="notice-editor-wrapper"
                    :max-text-length="wordLimit"
                    :content="message"
                    :on-update="onMessageUpdate"
                    :mentions="recipients"
                />
            </div>
            <slot name="footer">
                <div class="footer-container">
                    <div class="notice-scheduler">
                        <span class="notice-scheduler-label">Publish Schedule:</span>
                        <span
                            class="notice-scheduler-value"
                            @click="toggleScheduleDateTimePicker"
                            >{{ scheduledTimeLabel }}
                            <span class="chevron">▼</span>
                        </span>
                    </div>
                </div>
            </slot>
        </div>
        <NoticeSchedulerModal
            :show="showSchedule"
            :timezone="timezone"
            @close="showSchedule = false"
            @schedule="onScheduleTime"
        />
    </WebViewScreen>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { Action, Getter } from 'vuex-facing-decorator'

import { sendMessage, sendMoment } from '@/api/notices'
import {
    convertNoticeComposerToMomentRequest,
    noticeTypeTitle,
} from '@/utils/notices'
import Loading from '@/components/Loading.vue'
import ChatInput from '@/components/Chat/ChatInput.vue'
import WebViewScreen from '@/components/WebView/WebViewScreen.vue'
import NoticeSchedulerModal from '@/components/Notices/NoticeSchedulerModal.vue'
import SelectedRecipientPills from '@/components/Notices/SelectedRecipientPills.vue'
import { IComposer, IRecipient } from '@/entities/notices'
import { clamp } from '@/utils/clamp'
import { useRouter, useRoute } from 'vue-router'

@Component({
    components: {
        SelectedRecipientPills,
        Loading,
        ChatInput,
        WebViewScreen,
        NoticeSchedulerModal,
    },
})
export default class MessageComposer extends Vue {
    @Prop({ type: String, default: null })
    public preselectedUserId!: string // auto-populate user for notice

    @Getter private composer!: IComposer
    @Getter private $companyVars
    @Getter public recipients!: IRecipient[]
    @Getter public timezone!: string

    @Action private setComposerMessage
    @Action private resetComposerRecipients
    @Action private setComposerMentions
    @Action private loadMessagesSent
    @Action private loadMessagesScheduled
    @Action private loadMessagesReceived
    @Action private loadRecipients
    @Action private setErrorMessage
    @Action private setComposerScheduledAt
    @Action private loadTimezone
    @Action private resetComposer

    private sending = false // if is sending msg
    public wordLimit = 301 // there is a `newline` character at the beginning, so set word limit to 300 + 1
    private contentLength = 0
    public showSchedule = false
    public scheduledTimeLabel = 'Now'
    public selectedRecipients: IRecipient[] = []
    public loadingPreselectedRecipient = false

    private router = useRouter()
    private route = useRoute()

    private async created() {
        if (this.preselectedUserId) {
            this.loadingPreselectedRecipient = true
            this.resetComposerRecipients()
            await this.loadRecipients(parseInt(this.preselectedUserId))
            this.loadingPreselectedRecipient = false
        }

        this.selectedRecipients = [...this.composer.recipients]
    }

    private async mounted() {
        if (!this.timezone) {
            this.loadTimezone()
        }
        if (this.$refs.recipients) {
            clamp(this.$refs.recipients, { clamp: 2 })
        }
        if (!this.recipients?.length) {
            await this.loadRecipients()
        }
    }

    public get message() {
        return this.composer.message
    }

    public get sendButtonText() {
        return this.composer.scheduled_at ? 'Schedule' : 'Send'
    }

    public get typeTitle() {
        return noticeTypeTitle(this.composer.type)
    }

    public get chatInputPlaceholder() {
        return 'Write Notice'
    }

    // whether has loaded all recipients list
    public get hasRecipients() {
        return this.selectedRecipients.length > 0
    }

    private get hasPickedRecipients() {
        return this.composer.recipients.length > 0
    }

    public get canSend() {
        return (
            this.message.length > 0 &&
            this.contentLength <= this.wordLimit &&
            this.hasPickedRecipients &&
            !this.sending
        )
    }

    private get mailbox() {
        return this.route.path.split('/')[1]
    }

    public onScheduleTime(timeStr, label) {
        this.setComposerScheduledAt(timeStr)
        this.scheduledTimeLabel = label
    }

    public toggleScheduleDateTimePicker() {
        this.showSchedule = !this.showSchedule
    }

    public onMessageUpdate(text: string, length: number) {
        this.setComposerMessage(text)
        this.contentLength = length
        // also update mentions in case one mention is deleted
        const allMentions = this.recipients.filter((mention) =>
            this.composer.message.includes(`@${mention.name} `)
        )
        this.setComposerMentions(allMentions)
    }

    public async goPickRecipients() {
        await this.router.push({
            name: 'notices-editor-compose-pick-recipients',
            params: { mailbox: this.mailbox },
        })
    }

    public goPickNoticeType() {
        this.router.back()
    }

    public async onSendNotices() {
        if (!this.canSend) {
            return
        }

        // Notices without scheduled_at will trigger moment creation.
        if (!this.composer.scheduled_at) {
            await this.sendMomentNoticeMessage()
            return
        }

        this.sending = true
        const { data } = await sendMessage(this.composer)
        this.sending = false
        this.setErrorMessage(data.msg)
        if (data.success) {
            if (this.composer.scheduled_at) {
                this.loadMessagesScheduled()
                await this.router.replace({
                    name: 'notices',
                    params: { mailbox: 'scheduled' },
                })
            } else {
                this.loadMessagesSent()
                this.loadMessagesReceived() // in case sending to self, refresh received as well
                await this.router.replace({
                    name: 'notices',
                    params: { mailbox: 'sent' },
                })
            }

            this.resetComposer()
        }
    }

    private async sendMomentNoticeMessage() {
        this.sending = true
        const { data } = await sendMoment(
            convertNoticeComposerToMomentRequest(this.composer)
        )
        this.sending = false

        if (data.success) {
            this.loadMessagesSent()
            this.loadMessagesReceived()
            await this.router.replace({
                name: 'notices',
                params: { mailbox: 'sent' },
            })
        }
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/layout.less';
@import '~@/styles/palette.less';
@import '~@/mobile/src/styles/message-composer.less';
</style>

<style lang="less">
@import '~@/styles/palette.less';

.chat-input-container .chat-input.notice-editor .ql-editor {
    font-size: 14px;
    line-height: 20px;
}

// placeholder
.chat-input-container .chat-input.notice-editor .ql-editor.ql-blank::before {
    color: @grey40;
}
</style>
