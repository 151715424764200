import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextBadge = _resolveComponent("TextBadge")!

  return (_openBlock(), _createBlock(_component_TextBadge, {
    text: _ctx.noticeTypeObj.name,
    "background-colour": _ctx.noticeTypeObj.backgroundColor,
    "text-colour": _ctx.noticeTypeObj.color
  }, null, 8, ["text", "background-colour", "text-colour"]))
}