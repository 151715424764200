<template>
    <div id="infinite-scroll-container" :class="scrollContainerClasses">
        <div id="infinite-scroll-content">
            <slot />
        </div>
        <div v-if="isLoadingMore">
            <slot name="loading"></slot>
        </div>
        <div
            id="infinite-scroll-end"
            ref="infiniteScrollEnd"
            :class="scrollEndClass"
        ></div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import Typography from '@/components/Rain/Typography/Typography.vue'
import { faArrowRotateRight } from 'fontawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'

@Component({
    components: {
        Typography,
        FontAwesomeIcon,
    },
})
export default class InfiniteScroll extends Vue {
    @Prop({ type: Function, required: false })
    public loadMore?: () => void
    @Prop({ type: String, required: false, default: '' })
    public readonly scrollContainerClasses!: string
    @Prop({ type: Boolean, required: false, default: false })
    public readonly isLoadingMore!: boolean
    @Prop({ type: String, required: false, default: 'Loading...' })
    public readonly loadingMoreText!: string
    @Prop({ type: Boolean, required: false, default: false })
    public readonly hasReachedEnd!: boolean

    @Prop({ type: String, required: false, default: 'infinite-scroll-end' })
    public readonly scrollEndClass!: string

    public intersectionObserver!: IntersectionObserver

    public readonly faArrowRotateRight = faArrowRotateRight

    public mounted() {
        this.intersectionObserver = new IntersectionObserver((entries) => {
            if (entries && entries.length > 0 && entries[0].isIntersecting) {
                this.loadMoreContent()
            }
        })

        if (this.$refs.infiniteScrollEnd) {
            this.intersectionObserver.observe(
                this.$refs.infiniteScrollEnd as HTMLElement
            )
        }
    }

    public destroy() {
        if (this.$refs.infiniteScrollEnd) {
            this.intersectionObserver.unobserve(
                this.$refs.infiniteScrollEnd as HTMLElement
            )
        }
    }

    public loadMoreContent() {
        if (!this.loadMore || this.hasReachedEnd || this.isLoadingMore) {
            // If load more is not given or list has reached end
            return
        }

        this.loadMore()
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/variables.less';
@import '~@/styles/rain/colour.less';

.loading-more-text,
.loading-more-icon {
    color: @white;
}

.loading-more-text {
    margin-left: @gutterSpacing-md;
}

.loading-more-icon {
    animation-name: spin;
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
}
.infinite-scroll-end {
    width: 100%;
    height: 50px;
    background: transparent;
}
</style>
