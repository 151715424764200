<template>
    <div v-if="isActive" class="tab">
        <slot></slot>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component({})
export default class Tab extends Vue {
    @Prop({ type: String }) public name!: string
    @Prop({ type: String, required: false }) public displayName!: string
    @Prop({ type: Boolean, default: false }) public disabled!: boolean
    @Prop({ type: Boolean, default: false }) public isActive?: boolean
}
</script>

<style scoped lang="less"></style>
