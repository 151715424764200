<template>
    <div v-if="show" class="request-container">
        <div class="title">Push Notifications</div>
        <div class="description">
            Be notified of new Notices and Feedback so you can make every
            customer experience awesome.
        </div>
        <div class="button">
            <BaseButton round primary :click="onClick">Turn on Notifications</BaseButton>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import BaseButton from '@/components/BaseButton.vue'
import { Getter, Action } from 'vuex-facing-decorator'
import { PushNotifications } from '@capacitor/push-notifications'
import { Capacitor } from '@capacitor/core'

@Component({
    components: { BaseButton },
})
export default class RequestNotificationPermissionPanel extends Vue {
    @Getter readonly showStaffPushNotificationPanel

    @Action requestPushNotificationPermission!: () => Promise<void>

    get show() {
        return this.pushPermission && !!this.showStaffPushNotificationPanel
    }

    loading = false
    pushPermission = false

    async mounted() {
        if (Capacitor.isPluginAvailable('PushNotifications')) {
            const result = await PushNotifications.checkPermissions()
            this.pushPermission = result.receive === 'prompt'
        }
    }

    async onClick() {
        if (this.loading) {
            return
        }
        this.loading = true

        if (process.env.CONFIG_KEY === 'mobile') {
            await this.requestPushNotificationPermission()
        }

        this.loading = false
    }
}
</script>
<style lang="less" scoped>
@import '~@/styles/palette.less';

.request-container {
    text-align: center;
    background: white;
    padding: 15px;

    .title {
        font-size: 18px;
        color: @grey90;
    }

    .description {
        font-size: 14px;
        color: @greyBlue;
        margin-top: 10px;
    }

    .button {
        margin-top: 10px;
    }
}
</style>
