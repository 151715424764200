import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8c5e951a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "controls" }
const _hoisted_2 = { class: "options" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Dropdown, { right: "" }, {
      trigger: _withCtx(() => [
        _createVNode(_component_BaseButton, {
          secondary: "",
          small: "",
          muted: ""
        }, {
          default: _withCtx(() => [
            _createElementVNode("b", null, _toDisplayString(_ctx.active ? _ctx.active.label : _ctx.placeholder), 1)
          ], undefined, true),
          _: 1
        })
      ]),
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "option",
              onClick: () => _ctx.onChange(option.value)
            }, _toDisplayString(option.label), 9, _hoisted_3))
          }), 128))
        ])
      ]),
      _: 1
    })
  ]))
}