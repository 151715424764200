import { TsTeamGroupEntity } from '@/entities/teamscoreboard'
import { IFilterOption } from '@/entities/teamFilter'
import { IFilterRule } from '@/entities'

export function getFilterKey(
    teamFilterSelected: TsTeamGroupEntity | null,
    timeFilterSelected: IFilterOption | null
): string {
    return [
        JSON.stringify(teamFilterSelected),
        JSON.stringify(timeFilterSelected),
    ].join()
}

// Used to get temporary filters when we show the topic specific feedback view
export function getFiltersForTopicFeedback(
    scorecardName: string,
    topicKey: string,
    filterCXType?: 'promoters' | 'detractors' | 'passives' | undefined
): IFilterRule[] {
    const filters: IFilterRule[] = [
        {
            column: scorecardName,
            operator: 'in',
            source: 'custom',
            value: [topicKey],
        },
    ]

    if (filterCXType) {
        filters.push({
            column: 'rating',
            operator: 'in',
            source: 'general',
            value: [filterCXType],
        })
    }

    return filters
}
