import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BackButton = _resolveComponent("BackButton")!
  const _component_NoticeTypePicker = _resolveComponent("NoticeTypePicker")!
  const _component_WebViewScreen = _resolveComponent("WebViewScreen")!

  return (_openBlock(), _createBlock(_component_WebViewScreen, {
    "no-pad": "",
    title: "Select Notice Type"
  }, {
    "left-button": _withCtx(() => [
      _createVNode(_component_BackButton)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_NoticeTypePicker, {
        selected: _ctx.composer.type,
        onSelect: _ctx.onSelectNoticeType
      }, null, 8, ["selected", "onSelect"])
    ], undefined, true),
    _: 1
  }))
}