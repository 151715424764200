import { ActionTree, MutationTree, GetterTree } from 'vuex'
import {
    Suggestion,
    SuggestionNew,
    SuggestionComment,
    SuggestionCommentNew,
    SuggestionListQuery,
    SuggestionView,
    SuggestionRelations,
    SuggestionCommentRelations,
    SuggestionCommentView,
} from '@/entities/suggestions'
import { UserDetail } from '@/entities/user'
import {
    createSuggestion,
    getSuggestion,
    getSuggestionList,
    createSuggestionComment,
    getSuggestionComments,
    createSuggestionVote,
    removeSuggestionVote,
    removeSuggestion,
} from '@/api/suggestions'
import { IRecipient } from '@/entities/notices'
import { getSuggestionMentionUsers } from '@/api/suggestions'

import { dedupBy } from '@/utils/array'

const LIST_PAGE_SIZE = 10

export interface SuggestionsState {
    userId: number
    userIsAdmin: boolean
    userDetail: UserDetail

    mentionUsers: IRecipient[] // for @ user when creating suggestion or comment

    listQuery: SuggestionListQuery
    lists: {
        actioned: SuggestionRelations | null
        popular: SuggestionRelations | null
        recent: SuggestionRelations | null
    }

    activeSuggestion: SuggestionRelations | null

    comments: {
        [suggestionId: number]: SuggestionCommentRelations
    }

    newSuggestion?: Suggestion
    newSuggestionComment?: SuggestionComment
    activeSuggestionId: number
    suggestionLoading: boolean
}

export const state: SuggestionsState = {
    userId: -1,
    userIsAdmin: false,
    userDetail: {
        id: -1,
        name: '',
        avatar: '',
    },

    mentionUsers: [],

    listQuery: {
        mode: 'recent',
        offset: 0,
    },
    lists: {
        actioned: null,
        popular: null,
        recent: null,
    },
    activeSuggestion: null,
    comments: {},
    activeSuggestionId: 0,
    suggestionLoading: false,
}

const getters: GetterTree<SuggestionsState, any> = {
    getUserId({ userId }) {
        return userId
    },

    getUserDetail({ userDetail }) {
        return userDetail
    },

    getUserIsAdmin({ userIsAdmin }) {
        return userIsAdmin
    },

    suggestionListView({ lists, listQuery }): SuggestionView[] {
        const relations = lists[listQuery.mode]
        if (!relations) {
            return []
        }
        return suggestionRelationsToViews(relations)
    },

    suggestionListQuery({ listQuery }) {
        return listQuery
    },

    suggestionView({ activeSuggestion }): SuggestionView | undefined {
        if (activeSuggestion) {
            return suggestionRelationsToViews(activeSuggestion)[0]
        }
    },

    suggestionComments({
        comments,
        activeSuggestion,
    }): SuggestionCommentView[] {
        const active = activeSuggestion && activeSuggestion.suggestions[0]

        return (
            (active &&
                comments[active.id] &&
                suggestionCommentRelationsToViews(comments[active.id])) ||
            []
        )
    },

    activeSuggestionId({ activeSuggestionId }) {
        return activeSuggestionId
    },

    suggestionLoading({ suggestionLoading }) {
        return suggestionLoading
    },

    suggestionMentionUsers({ mentionUsers }) {
        return mentionUsers
    },
}

// Join the relations to prepare the view model
function suggestionRelationsToViews({
    suggestions,
    votes,
    actioning_comments,
    users,
    seen,
}: SuggestionRelations): SuggestionView[] {
    return suggestions.map((suggestion): SuggestionView => {
        const agreed = votes.filter(
            (vote) => vote.suggestion_id === suggestion.id
        )

        const users_agreed = agreed
            .map((vote) => users.find((user) => user.id === vote.user_id))
            .filter((user) => !!user) as UserDetail[]

        const action = actioning_comments.find(
            (comment) =>
                comment.id === suggestion.actioning_suggestioncomment_id
        )

        return {
            ...suggestion,
            users_agreed,
            user: users.find(
                (user) => user.id === suggestion.user_id
            ) as UserDetail,
            seen: seen.indexOf(suggestion.id) > -1,
            action: action ? action.action : undefined,
        }
    })
}

function suggestionCommentRelationsToViews({
    comments,
    users,
}: SuggestionCommentRelations): SuggestionCommentView[] {
    return comments.map((comment): SuggestionCommentView => {
        const user = users.find(
            (user) => user.id === comment.user_id
        ) as UserDetail

        return {
            ...comment,
            user,
        }
    })
}

interface UserData {
    userId: number
    userName: string
    userAvatar: string
    userIsAdmin: boolean
}

const actions: ActionTree<SuggestionsState, any> = {
    setUser({ commit }, user: UserData) {
        commit('setUser', user)
    },

    cleanActiveSuggestion({ commit }) {
        commit('setActiveSuggestion', null)
        commit('setSuggestionComments', {})
    },

    async createSuggestion({ commit }, suggestion: SuggestionNew) {
        const res = await createSuggestion(suggestion)
        return res.data
    },

    async removeSuggestion({ commit }, suggestion: Suggestion) {
        const res = await removeSuggestion(suggestion.id)
        if (res.data.success) {
            commit('removeSuggestion', suggestion.id)
            return true
        }
        return false
    },

    showCreateSuggestionModal({ commit }) {
        commit('setActiveCreateSuggestionModal', true)
    },

    hideCreateSuggestionModal({ commit }) {
        commit('setActiveCreateSuggestionModal', false)
    },

    setActiveSuggestionId({ commit }, id: number) {
        commit('setActiveSuggestionId', id)
    },

    async loadSuggestionList({ commit }, query: SuggestionListQuery) {
        const nextQuery = { ...query, offset: 0 }
        commit('setSuggestionListQuery', nextQuery)
        const res = await getSuggestionList(nextQuery)
        commit('setSuggestionList', res.data)
    },

    async loadSuggestionListMore({ commit, state, getters }) {
        const query = state.listQuery
        const nextQuery = { ...query, offset: query.offset + LIST_PAGE_SIZE }

        // extend the list, don't replace it
        commit('setSuggestionListQuery', nextQuery)
        const res = await getSuggestionList(nextQuery)
        commit('extendSuggestionList', res.data)
    },

    async loadSuggestion({ commit, state }) {
        if (state.activeSuggestionId) {
            const suggestionId = state.activeSuggestionId

            commit('setSuggestionLoading', true)

            const results = await Promise.all([
                getSuggestion(suggestionId),
                getSuggestionComments(suggestionId),
            ])

            const [suggestion, comments] = results.map(({ data }) => data)

            commit('setActiveSuggestion', suggestion)
            commit('setSuggestionComments', { comments, suggestionId })

            commit('setSuggestionLoading', false)
        }
    },

    async setActiveSuggestion({ commit }, suggestionId: number) {
        // @todo - if it's already in the list recently fetched, just copy it
        const res = await getSuggestion(suggestionId)
        commit('setActiveSuggestion', res.data)
    },

    async setSuggestionComments({ commit }, suggestionId: number) {
        const res = await getSuggestionComments(suggestionId)
        commit('setSuggestionComments', { comments: res.data, suggestionId })
    },

    async createSuggestionComment({ commit }, comment: SuggestionCommentNew) {
        await createSuggestionComment(comment)
    },

    // optimistic
    async createSuggestionVote({ commit, state }, suggestionId: number) {
        commit('setSuggestionVote', { suggestionId, user: state.userDetail })
        try {
            await createSuggestionVote(suggestionId)
        } catch (err) {
            commit('removeSuggestionVote', {
                suggestionId,
                user: state.userDetail,
            }) // revert on fail
        }
    },

    // optimistic
    async removeSuggestionVote({ commit, state }, suggestionId: number) {
        commit('removeSuggestionVote', { suggestionId, user: state.userDetail })
        try {
            await removeSuggestionVote(suggestionId)
        } catch (err) {
            commit('setSuggestionVote', {
                suggestionId,
                user: state.userDetail,
            }) // revert on fail
        }
    },

    setSuggestionMentionUsers({ commit }, mentionUsers: IRecipient[]) {
        commit('setSuggestionMentionUsers', {
            mentionUsers,
        })
    },

    async loadSuggestionMentionUsers({ commit }) {
        const res = await getSuggestionMentionUsers()
        commit('setSuggestionMentionUsers', res.data)
    },
}

const mutations: MutationTree<SuggestionsState> = {
    setUser(state, { userId, userName, userAvatar, userIsAdmin }: UserData) {
        state.userId = Number(userId)
        state.userDetail = {
            id: Number(userId),
            name: userName,
            avatar: userAvatar,
        }
        state.userIsAdmin = !!userIsAdmin
    },

    setSuggestionListQuery(state, query: SuggestionListQuery) {
        state.listQuery = query
    },

    setSuggestionList(state, relations: SuggestionRelations) {
        state.lists[state.listQuery.mode] = relations
    },

    extendSuggestionList(state, relations: SuggestionRelations) {
        const mode = state.listQuery.mode
        const current = state.lists[mode]
        const { suggestions, votes, actioning_comments, users, seen } =
            relations

        if (!current) {
            state.lists[mode] = relations
            return
        }

        const extended: SuggestionRelations = {
            suggestions: dedupBy(
                [...current.suggestions, ...suggestions],
                byId
            ),
            votes: dedupBy([...current.votes, ...votes], byUserAndSuggestion),
            actioning_comments: dedupBy(
                [...current.actioning_comments, ...actioning_comments],
                byId
            ),
            users: dedupBy([...current.users, ...users], byId),
            seen: dedupBy([...current.seen, ...seen], String),
        }

        state.lists[mode] = extended
    },

    setActiveSuggestion(state, suggestionView: SuggestionRelations | null) {
        state['activeSuggestion'] = suggestionView
    },

    setSuggestionComments(
        state,
        {
            comments,
            suggestionId,
        }: { comments: SuggestionCommentRelations; suggestionId: number }
    ) {
        state.comments[suggestionId] = comments
    },

    setSuggestionVote(
        state,
        { suggestionId, user }: { suggestionId: number; user: UserDetail }
    ) {
        const vote = {
            score: 1,
            suggestion_id: suggestionId,
            user_id: user.id,
        }

        for (const relations of forSuggestionRelations(state)) {
            relations.votes = dedupBy(
                [...relations.votes, vote],
                byUserAndSuggestion
            )
            // once votes was added to votes array, user info also needs to be added to users array to
            // meet getter filter condition
            relations.users = dedupBy([...relations.users, user], byId)
            // once agree button is tapped, the suggestion shall be regarded as viewed
            relations.seen = dedupBy([...relations.seen, suggestionId], String)
        }
    },

    removeSuggestionVote(
        state,
        { suggestionId, user }: { suggestionId: number; user: UserDetail }
    ) {
        for (const relations of forSuggestionRelations(state)) {
            relations.votes = relations.votes.filter(
                (vote) =>
                    byUserAndSuggestion(vote) !==
                    byUserAndSuggestion({
                        user_id: user.id,
                        suggestion_id: suggestionId,
                    })
            )
        }
    },

    setSuggestionSeen(state, suggestionId: number) {
        for (const relations of forSuggestionRelations(state)) {
            relations.seen = dedupBy([...relations.seen, suggestionId], String)
        }
    },

    removeSuggestion(state, suggestionId: number) {
        for (const relations of forSuggestionRelations(state)) {
            relations.suggestions = relations.suggestions.filter(
                ({ id }) => id !== suggestionId
            )
        }
    },

    setActiveSuggestionId(state, id: number) {
        state.activeSuggestionId = id
    },

    setSuggestionLoading(state, loading: boolean) {
        state.suggestionLoading = loading
    },

    setSuggestionMentionUsers(state, mentionUsers: IRecipient[]) {
        state.mentionUsers = mentionUsers
    },
}

const byId = ({ id }: { id: number }) => String(id)
const byUserAndSuggestion = ({ user_id, suggestion_id }) =>
    `${user_id}/${suggestion_id}`

// walks over all relations in the state
function* forSuggestionRelations(state: SuggestionsState) {
    for (const mode in state.lists) {
        if (state.lists[mode] !== null) {
            yield state.lists[mode]
        }
    }
    if (state.activeSuggestion !== null) {
        yield state.activeSuggestion
    }
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations,
}
