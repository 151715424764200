<template>
    <div class="search-box">
        <input
            :class="['search-input', { rounded }]"
            :type="type"
            :value="value"
            :placeholder="placeholder"
            @input="$emit('input', $event.target.value)"
        />
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component({
    emits: ['input'],
})
export default class SearchBox extends Vue {
    @Prop({ type: String, required: true }) public readonly value!: string
    @Prop({ type: String, required: false, default: 'text' })
    public readonly type!: string
    @Prop({ type: String, required: false, default: 'Search' })
    public readonly placeholder!: string
    @Prop({
        type: Boolean,
        required: false,
        default: true,
    })
    rounded?: boolean
}
</script>

<style lang="less" scoped>
@import '~@/styles/palette';
@import '~@/styles/typography';
@import '~@/styles/variables';

.search-box {
    width: 100%;
    display: flex;
    height: 32px;
    position: relative;

    &:before {
        display: block;
        content: '';
        background-image: url('~@/assets/img/Icons/Grey/Search.svg');
        background-repeat: no-repeat;
        background-size: contain;
        height: 16px;
        left: 8px;
        position: absolute;
        top: 8px;
        width: 16px;
    }
}

.search-input {
    appearance: none;
    flex: 1 0 auto;
    background-clip: padding-box; // remove ios border top style
    border: 1px solid @blueLight;
    border-radius: @borderRadius;
    box-shadow: none;
    font-size: 14px;
    font-family: @fontFamily;
    margin: 0;
    outline: none;
    padding: 1px 0 1px 32px;

    &::placeholder {
        color: @grey40;
    }

    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &.rounded {
        border-radius: 20px;
    }
}
</style>
