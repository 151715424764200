import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_Typography = _resolveComponent("Typography")!

  return (_openBlock(), _createElementBlock("div", {
    class: "back-button",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClick()))
  }, [
    _createVNode(_component_FontAwesomeIcon, {
      icon: _ctx.faChevronLeft,
      class: "arrow-icon-active"
    }, null, 8, ["icon"]),
    _createVNode(_component_Typography, {
      variant: "overline",
      class: "back-text"
    }, {
      default: _withCtx(() => [
        _createTextVNode("Back")
      ], undefined, true),
      _: 1
    })
  ]))
}