<template>
    <div class="chat-input-container">
        <At
            :members="members"
            class="chat-at"
            name-key="name"
            :ats="['@', '#']"
            :filter-match="filterMatch"
            :delete-match="deleteMatch"
            @insert="onMentionAdded"
        >
            <template #item="scope">
                <span class="name" v-text="scope.item.name" />
                <span
                    class="email"
                    v-text="
                        scope.item.email !== undefined
                            ? scope.item.email
                            : scope.item.description
                    "
                />
            </template>
            <div
                id="quill-container"
                class="chat-input"
                :class="customClass"
                @keydown="onInputKeydown"
            />
        </At>

        <div :class="['tools-container', toolsCustomClass]">
            <div v-if="!disableEmoji" class="emoji-container">
                <div class="emoji" @click="onClickEmojiPicker" />
                <Picker
                    v-if="showEmojiPicker"
                    :data="emojiIndex"
                    class="emoji-picker"
                    :emoji-size="24"
                    :per-line="9"
                    :native="false"
                    set="apple"
                    title="pick an emoji"
                    emoji="smile"
                    @select="onSelectEmoji"
                />
            </div>
            <div
                v-if="htmlMode"
                class="link-container"
                @click="toggerHyperlinkModal"
            />
            <div v-if="isSendosoEnabled" class="sendoso-container">
                <div class="sendoso" @click="onClickSendoso">
                    <div v-show="showSendosoTool">
                        <iframe
                            class="sendoso-iframe"
                            src="https://app.sendoso.com/plugin/plugin_integrations"
                            width="435"
                            height="500"
                        />
                        <img
                            class="close-sendoso-x"
                            src="/img/icons/x.svg"
                            alt="x"
                        />
                    </div>
                </div>
            </div>
            <span
                v-if="maxTextLength"
                class="content-count"
                :class="{ 'over-limit': contentLength > maxTextLength }"
                >{{ maxTextLength - contentLength }}</span>
        </div>
        <Modal
            :top="20"
            :padding="24"
            :size="modalSize.Small"
            :show="showHyperlinkModal"
        >
            <div class="modal-group">
                <input
                    v-model="hyperlink"
                    type="text"
                    placeholder="Paste link like https://asknice.ly"
                />
            </div>
            <div class="modal-footer">
                <Button class="cancel" no-border @click="toggerHyperlinkModal">Cancel</Button>
                <Button class="continue" @click="onOkHyperlink">Link</Button>
            </div>
        </Modal>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from 'vue-facing-decorator'
import { Picker, EmojiIndex } from 'emoji-mart-vue-fast/src'
import 'emoji-mart-vue-fast/css/emoji-mart.css'
import data from 'emoji-mart-vue-fast/data/apple.json'
import At from 'vue-at'
import { ModalSize } from '@/components/modal-size'
import Modal from '@/components/Modal.vue'
import Button from '@/components/WebView/Button.vue'
import { pendoTrackEvent } from '@/utils/pendo'

declare let Quill
@Component({
    components: { Picker, At, Modal, Button },
    emits: ['save', 'mentionAdded', 'mentionDeleted'],
})
export default class ChatInput extends Vue {
    // combine mention and hashtags
    public get members() {
        return [
            ...this.mentions,
            ...this.tags.map((tag) => ({ name: tag, email: '' })),
        ]
            .filter((member) => member.name !== null) // filter out users without a name
            .sort((a, b) => a.name.localeCompare(b.name))
    }

    public showEmojiPicker = false
    public showSendosoTool = false
    public showHyperlinkModal = false
    public hyperlink = ''
    public quill
    public contentLength = 0
    public emojiIndex = new EmojiIndex(data)

    @Prop({ type: Array, default: () => [] }) public mentions!: Array<{ name }> // can be ChatMention or IRecipient
    @Prop({ type: Array, default: () => [] }) public tags!: string[]
    @Prop({ type: String, default: '' }) public content?: string
    @Prop({ type: String, default: '' }) public placeholder?: string
    @Prop({ type: Boolean, default: false }) public isSendosoEnabled?: boolean
    @Prop({ type: Number, default: 0 }) public maxTextLength?: number
    @Prop({ type: Boolean, default: false }) public htmlMode?: boolean
    @Prop({ type: Boolean, default: false }) public disableEmoji?: boolean
    @Prop({ type: Boolean, default: false }) public disableAutoFocus?: boolean
    @Prop({ type: Function }) public onUpdate

    // Sometimes we need to overwrite the style of input; in that condition, you can apply a `customClass` on
    // ChatInput in your page then define the style in your file. Refer to `ChatResponseEmailReplyScreen.vue`
    // for more detail
    @Prop({ type: String, default: '' }) public customClass?: string
    @Prop({ type: String, default: '' }) public toolsCustomClass?: string

    public get modalSize() {
        return ModalSize
    }

    public link(link: string) {
        this.quill.format('link', link)
    }

    public mounted() {
        this.quill = new Quill('#quill-container', {
            placeholder: this.placeholder,
            modules: {
                keyboard: {
                    bindings: {
                        enter: {
                            key: 13, // enter
                            shortKey: true, // cmd on mac, ctrl on windows
                            handler: () => {
                                this.$emit('save')
                            },
                        },
                    },
                },
            },
        })

        this.quill.on('text-change', (delta, oldDelta, source) => {
            const content = this.htmlMode
                ? this.quill.root.innerHTML
                : this.quill.getText()
            this.contentLength = this.quill.getText().length
            this.onUpdate(content, this.contentLength)
        })
        this.updateContent(this.content)
        this.contentLength = this.quill.getText().length
        // move the cursor to the end
        if (!this.disableAutoFocus) {
            this.quill.focus()
            this.quill.setSelection(this.contentLength)
        }
    }

    public filterMatch(name, chunk, at) {
        return (
            name !== null &&
            name.toLowerCase().indexOf(chunk.toLowerCase()) === 0 &&
            // is mention someone and name is in mentions list
            ((at === '@' &&
                this.mentions.filter((mention) => mention.name === name)
                    .length > 0) ||
                // or is tag and name is in tags list
                (at === '#' &&
                    this.tags.filter((tag) => tag === name).length > 0))
        )
    }

    public onInputKeydown(evt) {
        if (evt.key === '@') {
            pendoTrackEvent('mention_started')
        }
    }

    // clear content after saving
    @Watch('content')
    public onContentChange() {
        if (!this.content) {
            this.updateContent(this.content)
        }
        this.contentLength = this.quill.getText().length
    }

    @Watch('placeholder')
    public onPlaceholderChanged() {
        this.quill.root.dataset.placeholder = this.placeholder
    }

    public updateContent(html) {
        if (this.htmlMode) {
            const delta = this.quill.clipboard.convert(html)
            this.quill.setContents(delta, 'silent')
        } else {
            this.quill.setText(html)
        }
        this.contentLength = this.quill.getText().length
    }

    public onClickEmojiPicker() {
        this.showSendosoTool = false
        this.showEmojiPicker = !this.showEmojiPicker
    }

    public onClickSendoso() {
        this.showEmojiPicker = false
        this.showSendosoTool = !this.showSendosoTool
    }

    public onSelectEmoji(emoji) {
        this.showEmojiPicker = false
        this.quill.insertText(this.quill.getSelection(true).index, emoji.native)
    }

    public toggerHyperlinkModal() {
        this.showHyperlinkModal = !this.showHyperlinkModal
    }

    public onOkHyperlink() {
        this.showHyperlinkModal = false
        let link = this.hyperlink.trim()
        if (!link.startsWith('http://') && !link.startsWith('https://')) {
            link = 'http://' + link
        }
        this.quill.format('link', link)
        this.hyperlink = ''
    }

    public onMentionAdded(tag) {
        if (tag.name) {
            pendoTrackEvent('mention_added')
            this.$emit('mentionAdded', tag.name)
        }
    }

    public deleteMatch(name, chunk, suffix) {
        let isMatch = chunk === name + suffix

        if (isMatch) {
            this.$emit('mentionDeleted', name)
        }

        return isMatch
    }
}
</script>

<style>
/* for mobile, selection is disabled by default, so div isn't editable */
.chat-input-container * {
    -webkit-user-select: initial; /* enable selection/Copy of UIWebView */
    -webkit-touch-callout: initial; /* enable the IOS popup when long-press on a link */
}

.chat-at {
    height: 100%;
}
</style>

<style scoped lang="less">
@import '../../styles/layout.less';
@import '../../styles/palette.less';

.chat-input-container {
    position: relative;
    font-size: 14px;
    flex: 1;

    .email {
        font-size: 11px;
        color: #8097b1;
    }

    .name {
        flex: 1;
    }

    .chat-input {
        font-size: 14px;

        .ql-editor {
            max-height: calc(60vh - 75px);
            overflow: auto;
            outline: none;
            min-height: 40px;
            padding: 0;

            p {
                margin: 0;
            }
        }

        .ql-editor.ql-blank::before {
            color: @greyMid;
            left: 0;
        }

        .ql-clipboard {
            left: -100000px;
            height: 1px;
            overflow-y: hidden;
            position: absolute;
            top: 50%;
        }
    }

    .tools-container {
        position: absolute;
        top: calc(100% + 14px);
        display: flex;
        height: 24px;
        // justify-content: flex-start;
        // align-items: baseline;

        .link-container {
            margin-right: 14px;
            width: 24px;
            height: 24px;
            cursor: pointer;
            background-image: url('~@/assets/img/chat/hyperlink.svg');

            &:hover {
                background-image: url('~@/assets/img/chat/hyperlink_active.svg');
            }
        }

        .emoji-container {
            margin-right: 14px;
            .emoji {
                width: 24px;
                height: 24px;
                cursor: pointer;
                background-size: contain;
                background-image: url('~@/assets/img/chat/emoji@2x.png');
            }

            .emoji:hover {
                background-image: url('~@/assets/img/chat/emoji_active@2x.png');
            }

            .emoji-picker {
                position: absolute;
                bottom: 100%;
            }
        }

        .sendoso-container {
            margin-right: 14px;

            .sendoso {
                width: 24px;
                height: 24px;
                cursor: pointer;
                background-size: contain;
                background-position-y: center;
                background-repeat: no-repeat;
                background-image: url('~@/assets/img/chat/sendoso@2x.png');
            }

            .sendoso:hover {
                background-image: url('~@/assets/img/chat/sendoso_active@2x.png');
            }

            .sendoso-iframe {
                position: absolute;
                bottom: 100%;
                border: 1px solid #d9d9d9;
                border-radius: 5px;
                background: @white;
                z-index: 1100;
            }

            .close-sendoso-x {
                position: absolute;
                z-index: 1200;
                top: -502px;
                right: -413px;
                border-radius: 15px;
            }
        }

        .content-count {
            top: 3px;
            position: relative;
        }

        .over-limit {
            color: @red;
            font-weight: bold;
        }
    }
    .atwho-wrap {
        margin: -14px;
        max-height: 100%;
        // TODO: adding "auto" will make name selections hidden behind the response modal(CS2GO-557), but if we
        // remove 'auto', on small screen(iPhone SE) with keyboard displayed, there would be no scroll bar for the
        // editarea, see https://github.com/asknicely/asknicelydo/pull/10539. Currently comment it out, we may try
        // to find another solution for small screen later.
        // overflow-y: auto;
    }

    .atwho-panel {
        top: auto !important;
    }
}

.modal-group {
    display: flex;
    flex-direction: column;
    input {
        color: @black;
        border: 1px solid @blue10;
        line-height: 36px;
        min-height: 36px;
        padding-left: 12px;
        font-size: 14px;
        margin-top: 12px;
        flex: 1;
        border-radius: 4px;
    }
}

.modal-footer {
    display: flex;
    padding-top: 12px;
    float: right;
    padding-bottom: 10px;
    text-transform: uppercase;
    margin: 0 -20px;

    .button {
        background: white;
        border: white;
    }

    .continue {
        color: @blue;
    }

    .cancel {
        color: @grey40;
    }
}
</style>

<style lang="less">
.atwho-view {
    max-width: 350px !important;
    bottom: 0.6rem;
}
</style>
