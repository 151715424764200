<template>
    <div class="emoji-reaction-container" :class="{ mobile: isMobile }">
        <!-- single mode -->
        <div
            v-if="emojiKeysNum === 0 && singleMode"
            class="emoji-reaction-button"
            :class="{ 'emoji-reaction-button-hover': showEmojis }"
            @click="toggleEmojis"
        />

        <!-- non single mode-->
        <div v-if="emojiKeysNum > 0 && !singleMode" class="emojis-list">
            <span
                class="emoji-list-button"
                :class="{ 'emoji-list-button-hover': showEmojis }"
                @click="toggleEmojis"
            />
            <!-- current emojis -->
            <span class="emojis-current">
                <span
                    v-for="(names, emoji) in emojis"
                    :key="emoji"
                    class="emoji-current-single"
                >
                    <span
                        v-if="names.length"
                        class="emoji-single current-single-pic"
                        data-uk-tooltip="{delay: 500, animation: true, offset: 10}"
                        :title="getEmojiTooltip(names)"
                        :class="[
                            emoji2class[emoji],
                            emoji == lastEmoji ? 'emojianimate' : '',
                        ]"
                        :data-emoji="emoji2class[emoji]"
                        @click="processReaction"
                    />
                    <span
                        v-if="names.length > 1"
                        class="emoji-current-single-num"
                        >{{ names.length || 0 }}</span>
                </span>
            </span>
        </div>

        <transition name="slide">
            <div
                v-if="showEmojis"
                v-click-outside="hideEmojiPopup"
                class="emoji-popup-container"
            >
                <div
                    class="emojis-popup"
                    :class="{
                        single: emojiKeysNum === 0,
                        multiple: emojiKeysNum > 0,
                    }"
                >
                    <div class="emoji-title">Pick Your Reaction</div>
                    <div class="emojis">
                        <span
                            class="emoji-single"
                            data-emoji="thumsup"
                            @click="processReaction"
                        />
                        <span
                            class="emoji-single"
                            data-emoji="thumsdown"
                            @click="processReaction"
                        />
                        <span
                            class="emoji-single"
                            data-emoji="smile"
                            @click="processReaction"
                        />
                        <span
                            class="emoji-single"
                            data-emoji="tada"
                            @click="processReaction"
                        />
                        <span
                            class="emoji-single"
                            data-emoji="frown"
                            @click="processReaction"
                        />
                        <span
                            class="emoji-single"
                            data-emoji="heart"
                            @click="processReaction"
                        />
                        <span class="emoji-split" />
                        <span
                            class="emoji-single"
                            data-emoji="kiwi"
                            @click="processReaction"
                        />
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { EmojiReactions } from '../../entities/chat'
import vClickOutside from 'v-click-outside'
import { updateEmoji } from '@/api/chat'

@Component({
    directives: {
        clickOutside: vClickOutside.directive,
    },
})
export default class EmojiReaction extends Vue {
    @Prop({ type: Object }) public emojis!: EmojiReactions
    @Prop() public relid!: string | number
    @Prop({ type: String }) public type!: string
    // single mode is on left, while list mode is at bottom of msg
    // single mode only shows when there are no emojis at all
    @Prop({ type: Number }) public singleMode!: number
    @Prop({ type: String }) public firstname!: string
    @Prop({ type: Boolean }) public isMobile!: boolean

    public lastEmoji = false // emoji to animate: zoom when you click
    public showEmojis = false
    public emoji2class = {
        '👍': 'thumsup',
        '👎': 'thumsdown',
        '😄': 'smile',
        '🎉': 'tada',
        '🙁': 'frown',
        '❤️': 'heart',
        '🥝': 'kiwi',
    }

    public get emojiKeysNum() {
        return Object.keys(this.emojis || {}).length
    }

    public hideEmojiPopup() {
        this.showEmojis = false
    }

    public get class2emojis() {
        const ret = {}
        for (const key in this.emoji2class) {
            if (this.emoji2class.hasOwnProperty(key)) {
                ret[this.emoji2class[key]] = key
            }
        }
        return ret
    }

    public processReaction(e) {
        const reaction = this.class2emojis[e.target.dataset.emoji]
        this.hideEmojiPopup()

        if (
            this.emojis[reaction] &&
            this.emojis[reaction].length &&
            this.emojis[reaction].indexOf(this.firstname) !== -1
        ) {
            if (this.emojis[reaction].length === 1) {
                //  if only one emoji, when remove, remove whole array completely, not leaving empty array
                delete this.emojis[reaction]
            } else {
                delete this.emojis[reaction][
                    this.emojis[reaction].indexOf(this.firstname)
                ]
            }
            this.lastEmoji = false
        } else {
            if (!this.emojis[reaction]) {
                this.emojis[reaction] = []
            }
            this.emojis[reaction][this.emojis[reaction].length || 0] =
                this.firstname
            this.lastEmoji = reaction
        }

        // try best, don't wait for it to finish
        updateEmoji(this.type, this.relid, reaction)
    }

    // generate tooltip for emojis telling who reacted with this emoji
    public getEmojiTooltip(names) {
        return names.join(', ') + ' reacted'
    }

    public toggleEmojis() {
        this.showEmojis = !this.showEmojis
    }
}
</script>

<style scoped lang="less">
@import '../../styles/palette';
@import '../../styles/layout';

.left .emoji-reaction {
    left: -110px;
}

.right .emoji-reaction {
    right: -110px;
}

.emoji-reaction {
    margin-top: auto;
    margin-bottom: auto;
    display: inline-block;
    position: relative;
}

.emoji-reaction-button {
    display: inline-block;
    height: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    border: 1px solid @greyLight4;
    padding: 0 15px 0 15px;
    opacity: 0;
    cursor: pointer;
    background-image: url('~@/assets/img/chat/add_reaction.svg');
    background-repeat: no-repeat;
    background-position: center;
}

.emoji-reaction-button:hover,
.emoji-reaction-button-hover {
    border-color: @blue;
    background-image: url('~@/assets/img/chat/add_reaction_active.svg');
}

.chat-msg:hover .emoji-reaction-button,
.chat-msg .emoji-reaction-button-hover {
    opacity: 1;
}

.emoji-popup-container {
    position: relative;
}

.emojis-popup {
    text-align: left;
    position: absolute;
    padding: 15px;
    border: 1px solid @greyLight4;
    border-radius: 5px;
    width: 240px;
    background-color: @white;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
    animation-duration: 0.3s;
    cursor: default;
    z-index: 1;
}

.emoji-title {
    font-size: 14px;
    line-height: 17px;
}

.emojis {
    font-size: 20px;
    margin-top: 11px;
}

.emoji-single {
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;
    background: url('~@/assets/img/chat/emoji.png');
    background-size: 700% 100%;
    width: 22px;
    height: 22px;
}

.emoji-current-single {
    .emoji-single {
        margin-right: 5px;
    }
}

@keyframes zoom1_3 {
    0% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(1.2, 1.2);
    }
}

.emojis-popup .emoji-single:hover {
    transform: scale(1.2, 1.2);
    -webkit-animation-name: zoom1_3;
    -moz-animation-name: zoom1_3;
    -o-animation-name: zoom1_3;
    animation-name: zoom1_3;
    animation-duration: 0.3s;
}

.emoji-split {
    border-left: 1px solid @greyLight3;
    margin-right: 12px;
    position: relative;
    top: -2px;
}

.emojis-list {
    border: 1px solid @greyLight3;
    border-radius: 30px;
    display: inline-block;
    background-color: @white;
    height: 33px;
}

.emoji-list-button {
    display: inline-block;
    width: 34px;
    height: 30px;
    position: relative;
    top: 2px;
    cursor: pointer;
    border-right: 1px solid @greyLight3;
    background-image: url('~@/assets/img/chat/add_reaction.svg');
    background-repeat: no-repeat;
    background-position: center;
}

.emoji-list-button:hover,
.emoji-list-button-hover {
    background-image: url('~@/assets/img/chat/add_reaction_active.svg');
}

.emojis-current {
    padding-right: 5px;
}

.emoji-current-single {
    position: relative;
    top: -2px;
    margin-left: 5px;
}

.emoji-current-single-num {
    position: relative;
    top: -2px;
    margin-right: 10px;
}

.spacereaction {
    margin-bottom: 90px !important;
}

@keyframes emojizoom {
    0% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1.5, 1.5);
    }
    100% {
        transform: scale(1, 1);
    }
}

.emojianimate {
    -webkit-animation-name: emojizoom;
    -moz-animation-name: emojizoom;
    -o-animation-name: emojizoom;
    animation-name: emojizoom;
    animation-duration: 0.6s;
}

.emoji-single[data-emoji='thumsup'] {
    background-position: 132px 0;
}

.emoji-single[data-emoji='thumsdown'] {
    background-position: 154px 0;
}

.emoji-single[data-emoji='smile'] {
    background-position: 110px 0;
}

.emoji-single[data-emoji='tada'] {
    background-position: 242px 0;
}

.emoji-single[data-emoji='frown'] {
    background-position: 220px 0;
}

.emoji-single[data-emoji='heart'] {
    background-position: 198px 0;
}

.emoji-single[data-emoji='kiwi'] {
    background-position: 330px 0;
}
</style>
<style lang="less">
.top {
    .emojis-popup {
        bottom: calc(100% + 45px);
    }
}

.left {
    .emojis-popup {
        right: 0;
    }
}

// avoid emoji reaction pop overlap by screen when on mobile
.mobile.left {
    .emojis-popup {
        right: -64px;
    }
}
</style>
