<template>
    <div
        v-if="isImpersonating"
        class="impersonating"
        @click="router.push({ name: 'viewAs' })"
    >
        <Typography variant="overline" class="impersonating-text">Viewing as {{ userName }}</Typography>
        <FontAwesomeIcon
            class="impersonating-change-icon"
            :icon="faCircleRight"
        />
    </div>
</template>

<script setup lang="ts">
import Typography from '@/components/Rain/Typography/Typography.vue'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faCircleRight } from 'fontawesome/free-solid-svg-icons'
import { computed } from 'vue'
import store from '@/mobile/src/store'
import router from '@/mobile/src/router'

const userName = computed(() => store.getters['userName'])
const isImpersonating = computed(() => store.getters['isImpersonating'])
</script>

<style lang="less" scoped>
@import '~@/styles/rain/variables.less';
@import '~@/styles/variables.less';

.impersonating {
    box-sizing: border-box;
    padding: 10px 20px;
    border-radius: @borderRadius-md @borderRadius-md 0 0;
    background-color: @greyBlue;
    color: @white;
    position: absolute;
    bottom: 100%;
    width: 100vw;

    &-text {
        max-width: 90%;
        float: left;
        color: @white;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    &-change-icon {
        float: right;
        width: 12px;
        height: 12px;
        padding: 4px;
    }
}
</style>
