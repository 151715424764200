<template>
    <div class="skeleton" :style="style">&nbsp;</div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component({})
export default class SkeletonDiv extends Vue {
    @Prop({ type: String, required: false })
    readonly width!: string

    @Prop({ type: String, required: false })
    readonly height!: string

    get style() {
        return `width: ${this.width ? this.width : '100%'};
                height: ${this.height ? this.height : '90%'};`
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/skeleton';

.skeleton {
    border-radius: 2px;
}
</style>
