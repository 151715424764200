import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a470e07a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "feedback-list-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "loading"
}
const _hoisted_3 = {
  key: 1,
  class: "no-more-results"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FeedbackResponseCard = _resolveComponent("FeedbackResponseCard")!
  const _component_LoadingCard = _resolveComponent("LoadingCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.responses, (response) => {
      return (_openBlock(), _createBlock(_component_FeedbackResponseCard, {
        key: response.id,
        response: response,
        onToggleStarred: _ctx.onToggleStarred,
        onViewDetail: _ctx.viewDetail
      }, null, 8, ["response", "onToggleStarred", "onViewDetail"]))
    }), 128)),
    (_ctx.loadingResponses || _ctx.forceShowLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (i) => {
            return _createVNode(_component_LoadingCard, {
              key: i,
              borderless: true,
              variant: "single",
              class: "loading-single"
            })
          }), 64))
        ]))
      : _createCommentVNode("", true),
    (!_ctx.loadingResponses && _ctx.resultsDepleted)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, " no more results "))
      : _createCommentVNode("", true)
  ]))
}