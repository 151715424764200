<template>
    <div class="filter-bar-time">
        <div v-if="showDateInput" class="date-range">
            <DateRangeInput
                :from="displayDate.from"
                :to="displayDate.to"
                :on-change="setDateRange"
                :start-open="startOpen"
            />
        </div>
        <Dropdown
            v-if="!showDateInput || !tsMobileView"
            ref="dropdown"
            :modal="tsMobileView"
            :disabled="!hasNetworkConnection"
        >
            <template #trigger>
                <div
                    v-if="tsMobileView && theFilterTimeLabel == 'custom'"
                    class="ts-date-select"
                >
                    <div class="filter-bar-dates">
                        {{ formatDates() }}
                    </div>
                    <img src="@/assets/img/Icons/Grey/Chevron Down.svg" />
                </div>
                <BaseButton
                    v-else
                    secondary
                    muted
                    :disabled="!hasNetworkConnection"
                    :icon="hasNetworkConnection ? 'chevron-down' : ''"
                    class="button-trigger"
                    data-test-tag="filter-time-button"
                >
                    {{ theFilterTimeLabel }}
                </BaseButton>
            </template>

            <template #content>
                <div
                    class="columns options"
                    :class="{
                        'ts-modal-c': tsMobileView,
                        'new-options': !tsMobileView,
                    }"
                >
                    <FilterModalMobileHeader
                        v-if="tsMobileView"
                        title="Time Filter"
                        @close="closeModal"
                    />
                    <div
                        v-for="(optionSet, indexSet) in filterTimeOptions"
                        :key="indexSet"
                        :class="getOptionSetClass(indexSet)"
                    >
                        <div
                            v-for="(option, index) in optionSet"
                            :key="option.label"
                            :class="[
                                getIsGreyOption(indexSet, index)
                                    ? 'grey-option'
                                    : 'option',
                                'capitalized',
                            ]"
                            :data-test-tag="`filter-time-option-${option.time_unit}-${option.time_value}`"
                            @click="
                                () => setTimeOption(option, indexSet, index)
                            "
                        >
                            {{ option.label }}
                        </div>
                    </div>
                    <i
                        v-if="filterPatching"
                        class="asknicely-icon-loader_static"
                    ></i>
                </div>
            </template>
        </Dropdown>
    </div>
</template>

<script lang="ts">
import { Component, Watch, Vue } from 'vue-facing-decorator'
import { Action, Getter } from 'vuex-facing-decorator'
import { format, subMonths } from 'date-fns'
import Dropdown from './Dropdown.vue'
import BaseButton from './BaseButton.vue'
import DateRangeInput from './DateRangeInput.vue'
import FilterModalMobileHeader from '@/components/FilterModalMobileHeader.vue'
import { isGreyOut } from '@/utils/time'
import { DateTime } from 'luxon'
import { FilterDateFormat } from '@/entities/filter'

const DATE_FORMAT = 'DD-MM-YYYY'

@Component({
    components: {
        FilterModalMobileHeader,
        Dropdown,
        BaseButton,
        DateRangeInput,
    },
})
export default class FilterBarTime extends Vue {
    @Getter public $companyVars
    @Getter public filterTimeLabel
    @Getter public filterTimeOptions
    @Getter public filterActive
    @Getter public filterPatching
    @Getter public tsMobileView
    @Getter
    hasNetworkConnection!: boolean

    @Action public setFilterTime
    @Action public populateTimeOptions

    public label = ''
    public from = ''
    public to = ''
    public startOpen = false
    public showDateInput = false

    public getOptionSetClass(indexSet) {
        let classes = 'option-set'
        if (this.tsMobileView) {
            classes += !indexSet ? ' first-option-set' : ''
        } else {
            classes += ' column-1-3'
        }

        return classes
    }

    public getIsGreyOption(indexSet, index) {
        return isGreyOut(
            false, // WEB-7536 - don't grey-out for now
            this.$companyVars.created,
            Number(indexSet),
            Number(index)
        )
    }

    @Watch('filterTimeLabel')
    public watchFilterTimeLabel() {
        if (this.filterTimeLabel.label === 'custom') {
            this.showDateInput = true
            this.copyFromStore()
        } else {
            this.showDateInput = false
        }
    }

    public get displayDate() {
        return {
            from: this.parseDate(this.from),
            to: this.parseDate(this.to),
        }
    }

    public mounted() {
        this.populateTimeOptions()
        this.copyFromStore()
    }

    public copyFromStore() {
        const filterProps = this.filterTimeLabel
        this.label = filterProps?.label || ''
        this.from = filterProps?.from || ''
        this.to = filterProps?.to || ''
        if (this.tsMobileView) {
            this.showDateInput = false
        }
    }

    public parseDate(date: string): Date {
        if (date === '') {
            date = DateTime.now().toFormat(FilterDateFormat.dayMonthYear)
        }

        const [DD, MM, YYYY] = date.split('-').map((n) => parseInt(n, 10))

        const luxonDate = DateTime.fromObject({
            day: DD,
            month: MM,
            year: YYYY,
        })

        return luxonDate.toJSDate()
    }

    public async setDateRange({ from, to }) {
        if (this.setFilterTime) {
            await this.setFilterTime({
                id: this.filterActive.id,
                time_unit: 'custom',
                time_value: `${format(from, 'DD-MM-YYYY')}_${format(
                    to,
                    'DD-MM-YYYY'
                )}`,
            })
        }
    }

    public async setTimeOption(option, indexSet, index) {
        if (this.getIsGreyOption(indexSet, index)) {
            return
        }

        if (option.time_unit === 'custom') {
            this.startOpen = true
            this.label = 'custom'
            this.from =
                this.from ?? format(subMonths(new Date(), 1), DATE_FORMAT)
            this.to = this.to ?? format(new Date(), DATE_FORMAT)
            this.showDateInput = true
        } else if (this.filterActive) {
            await this.setFilterTime({
                id: this.filterActive.id,
                time_unit: option.time_unit,
                time_value: option.time_value,
            })
            this.startOpen = false
        }
        const dropdown = this.$refs.dropdown as any
        if (dropdown) {
            dropdown.$data.opened = false
        }
    }

    public formatDates() {
        return this.from + ' - ' + this.to
    }

    private closeModal() {
        (this.$refs.dropdown as any).$data.opened = false
    }

    public get theFilterTimeLabel() {
        if (this.filterTimeLabel?.label) {
            return this.filterTimeLabel.label
        }

        return ''
    }
}
</script>

<style scoped lang="less">
@import '../styles/palette';

.filter-bar-time {
    display: inline-block;
    height: 42px;
    padding: 0;
}
.options {
    width: 430px;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
    position: relative;
}
.option-set {
    margin: 15px 0;
    border-right: 1px solid @backgroundColor;
    &:last-child {
        border-right: none;
    }
}
.first-option-set {
    margin-top: 45px !important;
}
.option {
    padding: 5px 15px;
    cursor: pointer;
}
.capitalized {
    text-transform: capitalize;
}
.option:hover {
    background: @backgroundColor;
    color: @ctaColor;
}
.grey-option {
    padding: 5px 15px;
    color: @greyBlue;
    cursor: not-allowed;
}
.new-options {
    width: 513px !important;
}
label {
    display: inline-block;
    padding: 0 10px;
}
.date-range {
    display: inline-block;
}
.asknicely-icon-loader_static {
    right: 20px;
    top: 20px;
    position: absolute;
}
.filter-bar-time .button {
    height: 42px;
    padding: 8px 17px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1.03px;
    display: flex;
    align-items: center;
}

.button-trigger {
    display: flex;
    align-items: center;
}

.ts-date-select {
    display: flex;
    font-size: 12px;
    background: #f2f5ff;
    padding-left: 4px;
    font-weight: 500;
    margin-top: 8px;
}

.ts-date-select .filter-bar-dates {
    padding: 5px;
}

@media (max-width: 1280px) {
    .filter-bar-time {
        height: 38px;
    }

    .filter-bar-time .button {
        padding: 0 6px;
        height: 38px;
    }

    .date-range-input {
        padding: 0;
        margin-top: 4px;
    }
}
</style>
