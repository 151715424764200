import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-be9914da"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tab-option" }
const _hoisted_2 = { class: "icon" }
const _hoisted_3 = { class: "icon-container" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 0,
  class: "count"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tab-option-container", _ctx.containerClass]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click', _ctx.tab.name)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("img", {
            src: _ctx.selected ? _ctx.tab.activeIcon : _ctx.tab.icon
          }, null, 8, _hoisted_4)
        ]),
        (_ctx.tab.count > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(['text', { selected: _ctx.selected }])
      }, _toDisplayString(_ctx.tab.text), 3)
    ])
  ], 2))
}