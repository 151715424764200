<template>
    <div class="team-member">
        <AvatarOrInitials :user="user" :size="75" />
        <div class="team-member-name">{{ name }}</div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import AvatarOrInitials from '@/components/WebView/AvatarOrInitials.vue'
import { IRecipient } from '@/entities/notices'
import { decode } from 'he'
import { UserDetail } from '@/entities'

@Component({
    components: {
        AvatarOrInitials,
    },
})
export default class TeamMember extends Vue {
    @Prop({ type: Object, required: true })
    teamMember!: IRecipient

    get name() {
        return decode(this.teamMember.name)
    }

    get user(): UserDetail {
        return {
            id: Number(this.teamMember.user_id),
            name: this.teamMember.name,
            avatar: this.teamMember.avatar,
        }
    }
}
</script>

<style scoped lang="less">
@import '~@/styles/palette';
@import '~@/styles/typography';

.team-member {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
}

.team-member-name {
    color: @grey90;
    font-family: @fontFamily;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
}
</style>
