import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7dadd6e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "feedback-case-management-container"
}
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingCard = _resolveComponent("LoadingCard")!
  const _component_ActionButton = _resolveComponent("ActionButton")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.loadingCaseOptions)
      ? (_openBlock(), _createBlock(_component_LoadingCard, {
          key: 0,
          variant: "single",
          borderless: ""
        }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (!_ctx.isCaseClosed)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.caseOptions, (caseOption, index) => {
                  return (_openBlock(), _createBlock(_component_ActionButton, {
                    key: index,
                    label: 'Close via ' + caseOption,
                    "on-click": () => _ctx.openOrCloseCase(true, caseOption)
                  }, null, 8, ["label", "on-click"]))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (_ctx.isCaseClosed)
            ? (_openBlock(), _createBlock(_component_ActionButton, {
                key: 1,
                label: "🔔 Re-open case",
                "on-click": () => _ctx.openOrCloseCase(false)
              }, null, 8, ["on-click"]))
            : _createCommentVNode("", true)
        ]))
  ]))
}