<template>
    <div
        :class="[
            'suggestions-subnav',
            !inDashboard && 'suggestions-subnav-padded',
        ]"
    >
        <RouterLink
            v-for="{ mode, label } in navMap"
            :key="mode"
            :to="`${currentPath}/${mode}`"
            replace
        >
            <button
                class="btn filter-btn"
                :class="{
                    'filter-btn-active': mode === activeMode,
                    'filter-btn-inactive': mode !== activeMode,
                }"
            >
                {{ label }}
            </button>
        </RouterLink>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { useRoute } from 'vue-router'

@Component({
    components: {},
})
export default class SuggestionsSubNav extends Vue {
    @Prop({ type: Boolean, default: false }) public inDashboard?: boolean

    private route = useRoute()

    public get currentPath() {
        return this.route.path
            .replace(/\/(recent|popular|actioned)/gi, '')
            .replace(/[\/]+$/g, '')
    }

    public get activeMode() {
        return this.route.params.mode || 'recent'
    }

    public get navMap() {
        return [
            { label: 'Most Recent', mode: 'recent' },
            { label: 'Popular', mode: 'popular' },
            { label: 'Reviewed', mode: 'actioned' },
        ]
    }
}
</script>

<style scoped lang="less">
@import '../../styles/button.less';
@import '../../styles/palette.less';

.suggestions-subnav {
    .button {
        margin-right: 10px;
    }
}

.suggestions-subnav-padded {
    padding-bottom: 15px;
}
</style>
