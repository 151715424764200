export const scrollEvent = 'scrollToPosition'
export const scrollToBottomEvent = 'scrollToBottom'
export const scrollToTopEvent = 'scrollToTop'
export const cacheScrollEvent = 'cacheScroll'
export const restoreScrollEvent = 'restoreScroll'

export function scrollToPosition(emitter, scrollObject) {
    emitter?.emit(scrollEvent, scrollObject)
}
export function scrollToBottom(emitter, behavior) {
    emitter?.emit(scrollToBottomEvent, behavior)
}
export function scrollToTop(emitter, behavior) {
    emitter?.emit(scrollToTopEvent, behavior)
}
