<template>
    <WebViewScreen :title="displayTitle" no-pad>
        <template #left-button>
            <div @click="goBack">
                <img
                    src="../../components/WebView/icons/BackLeftAction.svg"
                    alt="AskNicely"
                />
            </div>
        </template>
        <template #right-button>
            <div class="submit" @click="submitAction">
                <button :disabled="!submittable">Post</button>
            </div>
        </template>

        <WebViewForm @submit="submitAction">
            <fieldset class="expand">
                <label>{{ textAreaLabel }}</label>
                <MentionableEditor
                    :members="suggestionMentionUsers"
                    :placeholder="textAreaPlaceholder"
                    :text="body"
                    :compacted="true"
                    @input="onInputChange"
                    @mention="onMention"
                />
            </fieldset>
        </WebViewForm>
    </WebViewScreen>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { useRouter } from 'vue-router'
import { Action, Getter } from 'vuex-facing-decorator'
import {
    SuggestionAction,
    SuggestionComment,
    SuggestionCommentNew,
} from '@/entities/suggestions'
import WebViewScreen from '@/components/WebView/WebViewScreen.vue'
import WebViewForm from '@/components/WebView/WebViewForm.vue'
import MentionableEditor from '@/components/MentionableEditor.vue'
import { IRecipient } from '@/entities/notices'

@Component({
    components: { WebViewScreen, WebViewForm, MentionableEditor },
})
export default class SuggestionActionScreen extends Vue {
    @Getter public suggestionMentionUsers!: IRecipient[]

    // from the route
    @Prop({ type: String }) public id!: string
    @Prop({ type: String }) public action!: SuggestionAction

    @Action public createSuggestionComment!: (
        suggestion: Partial<SuggestionCommentNew>
    ) => Promise<SuggestionComment>
    @Action public loadSuggestionMentionUsers!: () => Promise<IRecipient[]>

    public body = ''
    protected mentions: IRecipient[] = []

    private router = useRouter()

    public mounted() {
        if (
            !this.suggestionMentionUsers ||
            !this.suggestionMentionUsers.length
        ) {
            this.loadSuggestionMentionUsers()
        }
    }

    public get displayTitle() {
        return this.action === 'not-now' ? 'Not Now' : "Let's Do It"
    }

    public get textAreaPlaceholder() {
        return this.action === 'not-now' ? 'Write reason' : 'Write next steps'
    }

    public get textAreaLabel() {
        return this.action === 'not-now' ? 'Reason' : 'Next Steps'
    }

    public get submittable() {
        return this.body.trim() !== ''
    }

    public async submitAction(event: Event) {
        event.preventDefault()
        if (!this.submittable) {
            return
        }

        const res = await this.createSuggestionComment({
            suggestion_id: parseInt(this.id, 10),
            body: this.body.trim(),
            action: this.action,
            mentions: this.mentions,
        })

        this.goBack()
    }

    public onMention(mentions: IRecipient[]) {
        this.mentions = mentions
    }

    public onInputChange(text: string) {
        this.body = text
    }

    public goBack() {
        this.router.back()
    }
}
</script>

<style scoped lang="less">
label {
    color: #2f384c;
}

.submit {
    text-align: right;
    button {
        display: inline-block;
        color: #2e5bff;
        font-size: 18px;
        font-weight: bold;
        background: #fff;
        border: none;
        &:disabled {
            opacity: 0.4;
        }
    }
}
</style>
