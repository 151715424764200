import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2be8744e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "notifications-panel" }
const _hoisted_2 = { id: "notifications-header" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 0,
  class: "unread-dot"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "notification-text" }
const _hoisted_7 = { class: "footer" }
const _hoisted_8 = {
  key: 2,
  id: "empty-notifications"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Typography = _resolveComponent("Typography")!
  const _component_LoadingCard = _resolveComponent("LoadingCard")!
  const _component_AvatarOrInitials = _resolveComponent("AvatarOrInitials")!
  const _component_TextClamp = _resolveComponent("TextClamp")!
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_EmptyPageState = _resolveComponent("EmptyPageState")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Typography, {
        class: "header-text",
        variant: "sub1"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Notifications")
        ], undefined, true),
        _: 1
      }),
      (_ctx.notifications.length)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            id: "mark-as-read-button",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.markAllAsRead && _ctx.markAllAsRead(...args)))
          }, [
            _createVNode(_component_Typography, {
              class: "header-text",
              variant: "overline"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Mark all as read ")
              ], undefined, true),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.notificationsLoading && _ctx.showLoadingAnimation)
      ? (_openBlock(), _createBlock(_component_LoadingCard, {
          key: 0,
          borderless: ""
        }))
      : (_ctx.notifications.length > 0)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            id: "notifications-list",
            onScroll: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onListScroll && _ctx.onListScroll(...args)))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (notification) => {
              return (_openBlock(), _createElementBlock("div", {
                key: notification.id,
                class: "notification",
                onClick: ($event: any) => (_ctx.onNotificationClick(notification))
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass([
                        'notification-avatar',
                        { read: notification.read },
                    ])
                }, [
                  (!notification.read)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4))
                    : _createCommentVNode("", true),
                  (_ctx.hasSender(notification))
                    ? (_openBlock(), _createBlock(_component_AvatarOrInitials, {
                        key: 1,
                        user: _ctx.getSender(notification),
                        size: 38
                      }, null, 8, ["user"]))
                    : (_openBlock(), _createElementBlock("img", {
                        key: 2,
                        class: "an-purple-logo",
                        src: _ctx.ANPurpleLogo,
                        alt: "AskNicely Logo"
                      }, null, 8, _hoisted_5))
                ], 2),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_Typography, {
                    variant: "overline",
                    class: "title"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.parseTitle(notification.type)), 1)
                    ], undefined, true),
                    _: 2
                  }, 1024),
                  _createVNode(_component_Typography, {
                    variant: "body2",
                    class: "content"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TextClamp, {
                        autoresize: "",
                        "max-lines": 2,
                        text: _ctx.getMessage(notification)
                      }, null, 8, ["text"])
                    ], undefined, true),
                    _: 2
                  }, 1024),
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_Typography, {
                      variant: "overline",
                      class: "footer-text"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.getSender(notification).name), 1)
                      ], undefined, true),
                      _: 2
                    }, 1024),
                    _createVNode(_component_Typography, {
                      variant: "overline",
                      class: "footer-text"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.getTimeSince(notification.created)), 1)
                      ], undefined, true),
                      _: 2
                    }, 1024)
                  ])
                ])
              ], 8, _hoisted_3))
            }), 128))
          ], 32))
        : (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createVNode(_component_EmptyPageState, { colour: "white" }, {
              "icon-img": _withCtx(() => [
                _createVNode(_component_FontAwesomeIcon, {
                  icon: _ctx.faInbox,
                  class: "inbox-icon",
                  color: "white"
                }, null, 8, ["icon"])
              ]),
              "header-text": _withCtx(() => [
                _createTextVNode("You currently have no notifications")
              ]),
              "body-text": _withCtx(() => [
                _createTextVNode(" Check back here for updates about customer responses ")
              ]),
              _: 1
            })
          ]))
  ]))
}