import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, TransitionGroup as _TransitionGroup, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dab4fd4a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: "topic-feedback",
  class: "topic-feedback-wrapper"
}
const _hoisted_2 = {
  key: 1,
  class: "show-all"
}
const _hoisted_3 = {
  key: 3,
  class: "show-all"
}
const _hoisted_4 = {
  key: "loading",
  class: "topic-feedback-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EmptyPageHome = _resolveComponent("EmptyPageHome")!
  const _component_ErrorPage = _resolveComponent("ErrorPage")!
  const _component_TopicFeedbackItem = _resolveComponent("TopicFeedbackItem")!
  const _component_PillButton = _resolveComponent("PillButton")!
  const _component_LoadingCard = _resolveComponent("LoadingCard")!

  return (_openBlock(), _createBlock(_TransitionGroup, {
    name: "loading",
    tag: "div"
  }, {
    default: _withCtx(() => [
      (!_ctx.monthTopics && _ctx.showEmptyTopics && !_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_EmptyPageHome, {
            key: "empty-page",
            message: _ctx.emptyTopicMessage,
            "topic-carousel": "",
            class: "empty-state",
            bordered: ""
          }, null, 8, ["message"]))
        : (_ctx.topicFeedbackError)
          ? (_openBlock(), _createBlock(_component_ErrorPage, {
              key: "error-page",
              class: "empty-state",
              bordered: ""
            }))
          : (!_ctx.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_ctx.showFirstTopicItem)
                  ? (_openBlock(), _createBlock(_component_TopicFeedbackItem, {
                      key: 0,
                      "topic-item": _ctx.showFirstTopicItem,
                      "is-report-card": _ctx.isReportCard
                    }, null, 8, ["topic-item", "is-report-card"]))
                  : _createCommentVNode("", true),
                (!_ctx.showAllTopics && _ctx.hasMultipleTopics && _ctx.hasOtherTopics)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createVNode(_component_PillButton, {
                        class: "is-v4-inverted",
                        label: "Show All Topics",
                        onClick: _ctx.toggleShowAll
                      }, null, 8, ["onClick"])
                    ]))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.otherTopics, (feedback, index) => {
                  return _withDirectives((_openBlock(), _createBlock(_component_TopicFeedbackItem, {
                    key: index,
                    "topic-item": feedback,
                    "is-report-card": _ctx.isReportCard
                  }, null, 8, ["topic-item", "is-report-card"])), [
                    [_vShow, _ctx.showAllTopics && feedback]
                  ])
                }), 128)),
                (_ctx.showLastTopicItem && _ctx.lastTopicItem)
                  ? (_openBlock(), _createBlock(_component_TopicFeedbackItem, {
                      key: 2,
                      "topic-item": _ctx.lastTopicItem,
                      "is-report-card": _ctx.isReportCard
                    }, null, 8, ["topic-item", "is-report-card"]))
                  : _createCommentVNode("", true),
                (_ctx.showAllTopics && _ctx.hasOtherTopics)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createVNode(_component_PillButton, {
                        class: "is-v4-inverted",
                        label: "Show Fewer Topics",
                        onClick: _ctx.toggleShowAll
                      }, null, 8, ["onClick"])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_LoadingCard),
                _createVNode(_component_LoadingCard, { variant: "pill" }),
                _createVNode(_component_LoadingCard)
              ]))
    ], undefined, true),
    _: 1
  }))
}