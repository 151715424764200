import client from '@/api/client'
import { SaveCommentResponse } from '@/entities/reportcard'
import { AxiosResponse } from 'axios'

export async function getYearInGoals(month, userName = '') {
    return client.get(`/reportcard/getyearingoals/${month}`, {
        params: {
            userName,
        },
    })
}

export async function getAchievedGoal(month, userName) {
    return client.get(`/reportcard/achievedgoal/${month}`, {
        params: {
            userName,
        },
    })
}

export async function getTopPerformers(month, userName) {
    return client.get(`/reportcard/topperformers/${month}`, {
        params: {
            userName,
        },
    })
}

export async function getHierarchyGoalByLocationLevel(
    selectionLocationLevel,
    selection = null,
    dayRange = '30'
) {
    return client.get('/ajax/reportcard/hierarchygoalbylocationlevel', {
        baseURL: '/',
        params: {
            selectionLocationLevel,
            selection,
            dayRange,
        },
    })
}

export async function saveReportCardComment(
    userName: string,
    yearMonth: string,
    questionType: string,
    comment: string,
    isEdit: boolean
): Promise<AxiosResponse<SaveCommentResponse>> {
    return client.post(`/reportcard/comment`, {
        userName,
        yearMonth,
        questionType,
        comment,
        isEdit,
    })
}

export async function getTeamProfileUsers(questionType = 'nps') {
    return client.get(`/reportcard/teamprofileusers/${questionType}`)
}

export async function getReportCardMessages(month: number, userName = '') {
    return client.get(`/reportcard/messages/${month}?userName=${userName}`)
}
