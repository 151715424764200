import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0afdecb3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tab-option" }
const _hoisted_2 = { class: "icon" }
const _hoisted_3 = { class: "icon-container" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UnreadCounter = _resolveComponent("UnreadCounter")!

  return (_openBlock(), _createElementBlock("div", {
    class: "tab-option-container",
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('click', _ctx.tab.name)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("img", {
            src: _ctx.tab.icon,
            alt: "tab-icon",
            class: _normalizeClass(['icon-img', { selected: _ctx.selected }])
          }, null, 10, _hoisted_4)
        ]),
        (_ctx.showDisplayCount)
          ? (_openBlock(), _createBlock(_component_UnreadCounter, {
              key: 0,
              "unread-count": _ctx.unreadCount,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClickDisplayCount()))
            }, null, 8, ["unread-count"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(['text', { selected: _ctx.selected }])
      }, _toDisplayString(_ctx.tab.text), 3)
    ])
  ]))
}