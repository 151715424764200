import {
    EngagementInsightsItem,
    EngagementInsightsItemData,
} from '@/entities/insights'
import { getPercentageString } from '@/utils/number'

export function calculateTeamEngagementPercentage(
    itemData: EngagementInsightsItemData
): string {
    return getPercentageString(itemData.answered_count, itemData.response_count)
}

export function calculateTeamEngagementPercentageChange(
    currentItemData: EngagementInsightsItemData,
    previousItemData: EngagementInsightsItemData
): number {
    return (
        parseFloat(calculateTeamEngagementPercentage(currentItemData)) -
        parseFloat(calculateTeamEngagementPercentage(previousItemData))
    )
}

export function sortByTeamEngagementPercentageChange(
    insightsItem: [EngagementInsightsItem]
): [EngagementInsightsItem] {
    return insightsItem.sort(
        (a, b) =>
            Math.abs(
                calculateTeamEngagementPercentageChange(
                    b.current_term_data,
                    b.previous_term_data
                )
            ) -
            Math.abs(
                calculateTeamEngagementPercentageChange(
                    a.current_term_data,
                    a.previous_term_data
                )
            )
    )
}
