import { ActionTree, MutationTree, GetterTree } from 'vuex'
import { InAppNotification } from '@/mobile/src/types/notifications'
import {
    getMobileNotifications,
    markAllAsRead,
    markOneAsRead,
    pollForNotifications,
} from '@/mobile/src/api/notifications'
import { pendoTrackEvent } from '@/utils/pendo'

export interface NotificationsState {
    notificationPanelOpen: boolean
    notifications: InAppNotification[]
    notificationsLoading: boolean
    nextPage: number
    canLoadMore: boolean
    unseenCount: number | null
    unseenPollingTimer: number | null
}

export const state: NotificationsState = {
    notificationPanelOpen: false,
    notifications: [],
    notificationsLoading: false,
    nextPage: 1,
    canLoadMore: true,
    unseenCount: null,
    unseenPollingTimer: null,
}

const PAGE_LIMIT = 10

function throwError(error) {
    throw new Error(`Mobile in-app notifications error: ${error}`)
}

const getters: GetterTree<NotificationsState, any> = {
    notifications: (state) => state.notifications,
    notificationsLoading: (state) => state.notificationsLoading,
    notificationPanelOpen: (state) => state.notificationPanelOpen,
    canLoadMore: (state) => state.canLoadMore,
    unseenCount: (state) => state.unseenCount,
}

const actions: ActionTree<NotificationsState, any> = {
    async loadNextPageOfNotifications({ commit, state }) {
        if (state.notificationsLoading || !state.canLoadMore) {
            return
        }

        commit('setNotificationsLoading', true)

        await getMobileNotifications(state.nextPage, PAGE_LIMIT)
            .then((result) => {
                const notificationsData = result.data.data

                if (notificationsData.length > 0) {
                    commit('setNotifications', notificationsData)
                    commit('incrementNextPageNumber')
                }
                commit('canLoadMore', notificationsData.length === PAGE_LIMIT)
            })
            .catch((error) => {
                throwError(error)
            })

        commit('setNotificationsLoading', false)
    },

    setNotificationPanelOpen({ commit }, open = false) {
        commit('setNotificationPanelOpen', open)
    },

    markAllNotificationsAsRead({ commit }) {
        commit('setAllToRead')

        markAllAsRead()
            .then(() => {
                pendoTrackEvent('mobile-notifications-mark-all-as-read')
            })
            .catch((error) => {
                throwError(error)
            })
    },

    markNotificationAsRead({ commit }, notificationId: number) {
        commit('setNotificationToRead', notificationId)
        markOneAsRead(notificationId).catch((error) => {
            throwError(error)
        })
    },

    setUnseenCount({ commit }, count: number) {
        commit('setUnseenCount', count)
    },

    stopUnseenPolling({ commit, state }) {
        if (state.unseenPollingTimer) {
            clearInterval(state.unseenPollingTimer)
            commit('setUnseenPolling', null)
        }
    },
    async startUnseenPolling({ commit, dispatch, state, rootGetters }) {
        if (state.unseenPollingTimer) {
            clearInterval(state.unseenPollingTimer)
            commit('setUnseenPolling', null)
        }

        const updateUnseenCount = async () => {
            if (!rootGetters.hasNetworkConnection) {
                return
            }
            const { data: unseenResponse } = await pollForNotifications()
            if (
                state.unseenCount !== null &&
                unseenResponse.count > state.unseenCount
            ) {
                // reload notifications up to page
                dispatch('refetchNotifications')
            }
            dispatch('setUnseenCount', unseenResponse.count)
        }

        await updateUnseenCount()
        const pollingTimer = setInterval(updateUnseenCount, 15000)
        commit('setUnseenPolling', pollingTimer)
    },

    async refetchNotifications({ commit, state }) {
        const notificationsToAdd: InAppNotification[] = []

        const pagesLoaded = state.nextPage - 1 > 0 ? state.nextPage : 1
        // Load all previously loaded notifications from page 1
        await getMobileNotifications(1, pagesLoaded * PAGE_LIMIT).then(
            (result) => {
                notificationsToAdd.push(...result.data.data)
            }
        )

        await commit('resetNotifications')
        commit('setNotifications', notificationsToAdd)
    },
}

const mutations: MutationTree<NotificationsState> = {
    setNotifications(state: NotificationsState, notifications) {
        if (notifications.length) {
            for (const notification of notifications) {
                state.notifications.push({
                    id: Number(notification.id),
                    userId: Number(notification.user_id),
                    created: Number(notification.created),
                    deleted: Number(notification.deleted),
                    extra: notification.extra,
                    message: notification.message,
                    questionId: notification.question_id,
                    read: !!Number(notification.read),
                    senderAvatar: notification.sender_avatar,
                    senderId: Number(notification.sender_id),
                    senderName: notification.sender_name,
                    text: notification.text,
                    type: notification.type,
                })
            }
        }
    },
    setAllToRead(state: NotificationsState) {
        state.notifications.map((notification) => {
            notification.read = true
            state.unseenCount = 0
        })
    },
    setNotificationToRead(state: NotificationsState, notificationId: number) {
        state.notifications.map((notification) => {
            if (notification.id === notificationId) {
                notification.read = true

                if (state.unseenCount !== null && state.unseenCount > 0) {
                    state.unseenCount--
                }
            }
        })
    },
    resetNotifications: (state: NotificationsState) =>
        (state.notifications = []),
    setNotificationsLoading: (state: NotificationsState, loading: boolean) =>
        (state.notificationsLoading = loading),
    setNotificationPanelOpen: (state: NotificationsState, open: boolean) =>
        (state.notificationPanelOpen = open),
    incrementNextPageNumber: (state: NotificationsState) => state.nextPage++,
    canLoadMore: (state: NotificationsState, canLoadMore: boolean) =>
        (state.canLoadMore = canLoadMore),
    setUnseenCount: (state: NotificationsState, count: number) =>
        (state.unseenCount = count),
    setUnseenPolling: (
        state: NotificationsState,
        pollingTimer: number | null
    ) => (state.unseenPollingTimer = pollingTimer),
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
