import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-691e1a1b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "theme-details-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EngagementThemeTrend = _resolveComponent("EngagementThemeTrend")!

  return (_ctx.insightsEngagement)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_EngagementThemeTrend, { "engagement-theme": _ctx.theme }, null, 8, ["engagement-theme"])
      ]))
    : _createCommentVNode("", true)
}