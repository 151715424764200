import axios, { AxiosInstance } from 'axios'
import { setupCache } from 'axios-cache-adapter'
import baseConfig from './clientShared'

declare let ANAPI_TOKEN: string

const cache = setupCache({
    // Default 15min, but we'll be configuring this to reset on page reload
    maxAge: 15 * 60 * 1000,
    exclude: {
        query: false, // Don't exclude requests with query params
    },
})

const webCachedClient = axios.create({
    ...baseConfig,
    adapter: cache.adapter,
    baseURL: '/ajax/',
})

// @see: websrc/src/api/client.ts
export const cachedClient =
    process.env.CONFIG_KEY !== 'mobile'
        ? webCachedClient
        : (require('@/mobile/src/api/mobileclient').ajaxClient as AxiosInstance)
export const ajaxClient =
    process.env.CONFIG_KEY !== 'mobile'
        ? cachedClient
        : (require('@/mobile/src/api/mobileclient').ajaxClient as AxiosInstance)
export const publicClient =
    process.env.CONFIG_KEY !== 'mobile'
        ? cachedClient
        : (require('@/mobile/src/api/mobileclient').jsonClient as AxiosInstance)
export const formClient =
    process.env.CONFIG_KEY !== 'mobile'
        ? cachedClient
        : (require('@/mobile/src/api/mobileclient').formClient as AxiosInstance)

const client = ajaxClient
export default client
