import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-592a0368"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "leaderboard-chart" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("canvas", {
      ref: "npsgrid",
      class: _normalizeClass({ hidden: _ctx.hideCanvas })
    }, null, 2),
    _createVNode(_component_Loading, {
      loading: !_ctx.chartRendered || !!_ctx.tsLoadingTeamGroupSummary,
      contained: true
    }, null, 8, ["loading"])
  ]))
}