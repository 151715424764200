import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3b268b0a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "left-button" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 1,
  class: "custom-title-container"
}
const _hoisted_4 = { class: "right-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "web-view-header",
    style: _normalizeStyle(_ctx.style)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "left-button", {}, undefined, true)
    ]),
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "simple-title",
          innerHTML: _ctx.title
        }, null, 8, _hoisted_2))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "custom-title", {}, undefined, true)
        ])),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "right-button", {}, undefined, true)
    ])
  ], 4))
}