export interface IThemes {
    rows: { [s: string]: ITheme }
    total: number
    lastProcessed: string
    ai_themes_date_warning?: string
}

export interface ITheme {
    name: string
    total: number
    nps: number
    npsPercentage: number
    impact?: number
    id: number
    words: string
    loading?: number
    indexing?: number
    detractor: number
    passive: number
    previousNPS: number
    promoter: number
    delta: number
    source?: string
}

export interface IThemeSetting {
    name: string
    id: number
    words: string
    source?: string
}

export enum ThemeSource {
    AI_SUGGESTED = 'ai-suggested',
    AI = 'ai',
    LEGACY = 'legacy',
}
