<template>
    <div class="panel-container">
        <div>
            <svg
                width="201"
                height="192"
                viewBox="0 0 201 192"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#clip0)">
                    <path
                        d="M121.658 10.7724L16.9394 33.3169L47.9842 178.491L152.703 155.947L121.658 10.7724Z"
                        fill="#E0E0E0"
                    />
                    <g opacity="0.5">
                        <path
                            opacity="0.5"
                            d="M120.415 13.6076L19.2354 35.3901L49.2308 175.657L150.411 153.875L120.415 13.6076Z"
                            fill="url(#paint0_linear)"
                        />
                    </g>
                    <path
                        d="M119.346 14.9175L20.7426 36.1455L50.0752 173.314L148.679 152.086L119.346 14.9175Z"
                        fill="#FAFAFA"
                    />
                    <path
                        d="M93.3603 14.2918L44.4441 24.8228L46.9866 36.7118L95.9027 26.1809L93.3603 14.2918Z"
                        fill="url(#paint1_linear)"
                    />
                    <path
                        d="M66.4177 7.94525C62.6411 8.75918 60.2516 12.5632 61.0824 16.441C61.9132 20.3189 65.6457 22.8074 69.4149 21.9934C73.1842 21.1795 75.581 17.3755 74.7502 13.4976C73.9194 9.61982 70.1943 7.13133 66.4177 7.94525ZM68.7875 19.0353C67.9894 19.2263 67.1526 19.1745 66.3839 18.8865C65.6151 18.5986 64.9493 18.0875 64.4711 17.4184C63.9929 16.7493 63.7241 15.9526 63.699 15.1296C63.6738 14.3067 63.8935 13.4949 64.3298 12.7977C64.7662 12.1004 65.3996 11.5494 66.1493 11.2147C66.899 10.88 67.7311 10.7769 68.5394 10.9184C69.3477 11.06 70.0958 11.4398 70.6882 12.0095C71.2805 12.5792 71.6905 13.313 71.8657 14.1173C71.9867 14.6431 72.003 15.1877 71.9137 15.7198C71.8244 16.2519 71.6312 16.7611 71.3451 17.2181C71.0591 17.6751 70.6859 18.0709 70.247 18.3827C69.8081 18.6946 69.3121 18.9163 68.7875 19.0353Z"
                        fill="url(#paint2_linear)"
                    />
                    <path
                        d="M92.7362 13.8925L44.8504 24.2017L47.2808 35.5666L95.1665 25.2575L92.7362 13.8925Z"
                        fill="#B43DC6"
                    />
                    <path
                        d="M66.4174 7.94492C65.0938 8.22997 63.8841 8.90243 62.9414 9.87726C61.9987 10.8521 61.3652 12.0855 61.1211 13.4216C60.877 14.7576 61.0333 16.1363 61.5701 17.3833C62.1069 18.6302 63.0001 19.6895 64.1369 20.4271C65.2737 21.1647 66.6029 21.5475 67.9565 21.5271C69.3102 21.5067 70.6273 21.0841 71.7416 20.3126C72.8558 19.5411 73.717 18.4554 74.2162 17.1929C74.7155 15.9303 74.8304 14.5475 74.5465 13.2194C74.358 12.3375 73.9981 11.5015 73.4875 10.7591C72.977 10.0168 72.3256 9.38258 71.5708 8.89279C70.8159 8.403 69.9723 8.06719 69.0881 7.90456C68.2038 7.74192 67.2963 7.75564 66.4174 7.94492ZM68.6819 18.5431C67.9179 18.7076 67.1224 18.6411 66.3961 18.352C65.6698 18.0628 65.0453 17.5641 64.6015 16.9188C64.1577 16.2735 63.9146 15.5106 63.9029 14.7267C63.8912 13.9428 64.1115 13.173 64.5359 12.5147C64.9603 11.8564 65.5697 11.3391 66.2871 11.0283C67.0044 10.7175 67.7975 10.6272 68.5661 10.7687C69.3347 10.9102 70.0441 11.2772 70.6048 11.8233C71.1655 12.3694 71.5521 13.07 71.7159 13.8367C71.9355 14.8645 71.7392 15.9377 71.1702 16.8203C70.6013 17.7029 69.7062 18.3226 68.6819 18.5431Z"
                        fill="#B43DC6"
                    />
                    <path
                        d="M170.304 28.748H63.2021V177.226H170.304V28.748Z"
                        fill="#E0E0E0"
                    />
                    <g opacity="0.5">
                        <path
                            opacity="0.5"
                            d="M168.496 31.2583H65.0134V174.718H168.496V31.2583Z"
                            fill="url(#paint3_linear)"
                        />
                    </g>
                    <path
                        d="M167.177 32.3159H66.3294V172.606H167.177V32.3159Z"
                        fill="white"
                    />
                    <path
                        d="M141.9 26.2324H91.8708V38.3921H141.9V26.2324Z"
                        fill="url(#paint4_linear)"
                    />
                    <path
                        d="M116.886 14.3628C113.023 14.3628 109.891 17.5791 109.891 21.548C109.891 25.5168 113.023 28.7331 116.886 28.7331C120.748 28.7331 123.88 25.5168 123.88 21.548C123.88 17.5791 120.748 14.3628 116.886 14.3628ZM116.886 25.6938C116.065 25.7134 115.256 25.4869 114.564 25.0434C113.872 24.5999 113.327 23.9595 112.999 23.2038C112.671 22.4482 112.575 21.6117 112.724 20.8011C112.872 19.9905 113.257 19.2426 113.831 18.653C114.405 18.0633 115.141 17.6585 115.945 17.4904C116.749 17.3223 117.585 17.3985 118.345 17.7092C119.106 18.0199 119.757 18.551 120.216 19.2347C120.674 19.9185 120.919 20.7238 120.92 21.548C120.928 22.6311 120.508 23.6733 119.752 24.4465C118.996 25.2198 117.965 25.6612 116.886 25.6741V25.6938Z"
                        fill="url(#paint5_linear)"
                    />
                    <path
                        d="M141.373 25.7109H92.3978V37.3346H141.373V25.7109Z"
                        fill="#B43DC6"
                    />
                    <path
                        d="M116.886 14.3628C115.532 14.3628 114.209 14.7656 113.083 15.5203C111.957 16.2749 111.08 17.3475 110.562 18.6025C110.044 19.8574 109.908 21.2384 110.172 22.5706C110.437 23.9029 111.088 25.1266 112.046 26.0871C113.003 27.0476 114.223 27.7017 115.55 27.9667C116.878 28.2317 118.254 28.0957 119.505 27.5759C120.756 27.0561 121.825 26.1758 122.577 25.0464C123.329 23.9169 123.731 22.5891 123.731 21.2307C123.731 19.4092 123.01 17.6624 121.726 16.3744C120.442 15.0864 118.701 14.3628 116.886 14.3628ZM116.886 25.1946C116.105 25.1937 115.342 24.9606 114.693 24.5249C114.045 24.0892 113.539 23.4703 113.24 22.7465C112.942 22.0227 112.864 21.2263 113.016 20.458C113.168 19.6897 113.544 18.9838 114.095 18.4296C114.647 17.8753 115.35 17.4975 116.116 17.3439C116.881 17.1902 117.675 17.2676 118.397 17.5662C119.119 17.8649 119.736 18.3714 120.171 19.0219C120.606 19.6723 120.839 20.4376 120.841 21.2209C120.841 21.7417 120.738 22.2572 120.539 22.7382C120.34 23.2192 120.049 23.6561 119.682 24.0239C119.314 24.3918 118.878 24.6835 118.399 24.8822C117.919 25.081 117.405 25.183 116.886 25.1823V25.1946Z"
                        fill="#B43DC6"
                    />
                    <g opacity="0.5">
                        <path
                            opacity="0.5"
                            d="M4.66375 181.24H3.74227V186.485H4.66375V181.24Z"
                            fill="#47E6B1"
                        />
                        <path
                            opacity="0.5"
                            d="M6.8177 184.326V183.401H1.59028V184.326H6.8177Z"
                            fill="#47E6B1"
                        />
                    </g>
                    <g opacity="0.5">
                        <path
                            opacity="0.5"
                            d="M111.57 0H110.649V5.24501H111.57V0Z"
                            fill="#47E6B1"
                        />
                        <path
                            opacity="0.5"
                            d="M113.724 3.08374V2.15918L108.497 2.15918V3.08374L113.724 3.08374Z"
                            fill="#47E6B1"
                        />
                    </g>
                    <path
                        opacity="0.5"
                        d="M188.972 189.971C188.718 189.86 188.513 189.659 188.397 189.407C188.281 189.154 188.261 188.868 188.342 188.602C188.355 188.561 188.363 188.519 188.367 188.476C188.374 188.354 188.342 188.234 188.275 188.131C188.209 188.029 188.111 187.951 187.997 187.909C187.883 187.867 187.759 187.863 187.642 187.897C187.525 187.931 187.423 188.003 187.35 188.1C187.324 188.134 187.303 188.171 187.286 188.211C187.175 188.466 186.975 188.671 186.723 188.787C186.472 188.904 186.186 188.924 185.921 188.843C185.88 188.83 185.838 188.821 185.796 188.818C185.674 188.811 185.554 188.843 185.452 188.909C185.35 188.976 185.273 189.074 185.231 189.189C185.188 189.303 185.184 189.428 185.219 189.545C185.253 189.662 185.324 189.765 185.421 189.838C185.455 189.864 185.492 189.886 185.531 189.902C185.785 190.013 185.99 190.214 186.106 190.467C186.222 190.719 186.242 191.006 186.161 191.272C186.148 191.313 186.14 191.355 186.136 191.397C186.129 191.519 186.161 191.64 186.228 191.742C186.294 191.844 186.392 191.922 186.506 191.965C186.62 192.007 186.744 192.011 186.861 191.977C186.978 191.942 187.08 191.871 187.153 191.774C187.179 191.739 187.2 191.702 187.217 191.663C187.328 191.408 187.528 191.203 187.78 191.086C188.031 190.97 188.317 190.95 188.582 191.031C188.623 191.044 188.665 191.052 188.707 191.056C188.829 191.063 188.949 191.031 189.051 190.964C189.153 190.897 189.23 190.8 189.272 190.685C189.315 190.571 189.319 190.446 189.284 190.329C189.25 190.212 189.179 190.109 189.082 190.035C189.048 190.01 189.011 189.988 188.972 189.971Z"
                        fill="#4D8AF0"
                    />
                    <path
                        opacity="0.5"
                        d="M33.4942 139.547C33.24 139.436 33.0354 139.236 32.9192 138.983C32.8031 138.73 32.7835 138.444 32.8643 138.178C32.8773 138.137 32.8856 138.095 32.8888 138.052C32.8961 137.931 32.8642 137.81 32.7977 137.708C32.7312 137.605 32.6336 137.527 32.5196 137.485C32.4055 137.443 32.2809 137.439 32.1642 137.473C32.0475 137.508 31.945 137.579 31.8718 137.676C31.8463 137.71 31.8249 137.748 31.808 137.787C31.6974 138.042 31.4972 138.247 31.2455 138.364C30.9938 138.48 30.7082 138.5 30.443 138.419C30.4024 138.406 30.3605 138.397 30.318 138.394C30.1966 138.387 30.0761 138.419 29.9743 138.486C29.8725 138.552 29.7948 138.65 29.7528 138.765C29.7107 138.879 29.7065 139.004 29.7408 139.121C29.775 139.238 29.846 139.341 29.943 139.415C29.9772 139.44 30.0142 139.462 30.0533 139.479C30.3075 139.59 30.5121 139.791 30.6282 140.043C30.7444 140.296 30.7639 140.582 30.6832 140.848C30.6701 140.889 30.6619 140.931 30.6586 140.974C30.6513 141.095 30.6833 141.216 30.7498 141.319C30.8163 141.421 30.9138 141.499 31.0279 141.541C31.142 141.583 31.2666 141.587 31.3833 141.553C31.4999 141.518 31.6025 141.447 31.6757 141.35C31.7012 141.316 31.7226 141.279 31.7394 141.239C31.8501 140.984 32.0503 140.779 32.302 140.662C32.5537 140.546 32.8393 140.526 33.1045 140.607C33.145 140.62 33.187 140.629 33.2295 140.632C33.3509 140.639 33.4714 140.607 33.5732 140.54C33.675 140.474 33.7526 140.376 33.7947 140.261C33.8368 140.147 33.841 140.022 33.8067 139.905C33.7724 139.788 33.7015 139.685 33.6044 139.611C33.5703 139.586 33.5333 139.564 33.4942 139.547Z"
                        fill="#FDD835"
                    />
                    <path
                        opacity="0.5"
                        d="M162.994 16.7657C162.74 16.6547 162.535 16.4538 162.419 16.2012C162.303 15.9487 162.283 15.6621 162.364 15.3961C162.377 15.3554 162.385 15.3133 162.389 15.2706C162.396 15.1489 162.364 15.0279 162.298 14.9258C162.231 14.8237 162.134 14.7457 162.019 14.7035C161.905 14.6613 161.781 14.6571 161.664 14.6915C161.547 14.7259 161.445 14.797 161.372 14.8944C161.346 14.9287 161.325 14.9658 161.308 15.0051C161.197 15.2601 160.997 15.4654 160.745 15.5819C160.494 15.6985 160.208 15.7181 159.943 15.637C159.902 15.624 159.86 15.6157 159.818 15.6124C159.697 15.6051 159.576 15.6372 159.474 15.7039C159.372 15.7706 159.295 15.8685 159.253 15.9829C159.211 16.0974 159.206 16.2224 159.241 16.3395C159.275 16.4566 159.346 16.5594 159.443 16.6329C159.477 16.6585 159.514 16.6799 159.553 16.6969C159.807 16.8079 160.012 17.0088 160.128 17.2613C160.244 17.5139 160.264 17.8004 160.183 18.0665C160.17 18.1072 160.162 18.1493 160.159 18.1919C160.151 18.3137 160.183 18.4346 160.25 18.5368C160.316 18.6389 160.414 18.7168 160.528 18.759C160.642 18.8012 160.767 18.8055 160.883 18.7711C161 18.7367 161.102 18.6655 161.176 18.5681C161.201 18.5339 161.222 18.4967 161.239 18.4575C161.35 18.2025 161.55 17.9972 161.802 17.8806C162.054 17.7641 162.339 17.7445 162.604 17.8255C162.645 17.8386 162.687 17.8469 162.729 17.8501C162.851 17.8575 162.971 17.8254 163.073 17.7587C163.175 17.6919 163.253 17.5941 163.295 17.4796C163.337 17.3651 163.341 17.2401 163.307 17.1231C163.272 17.006 163.201 16.9031 163.104 16.8296C163.07 16.8041 163.033 16.7826 162.994 16.7657Z"
                        fill="#FDD835"
                    />
                    <path
                        opacity="0.5"
                        d="M199.157 79.1793C200.176 79.1793 201.003 78.3503 201.003 77.3277C201.003 76.3051 200.176 75.4761 199.157 75.4761C198.138 75.4761 197.312 76.3051 197.312 77.3277C197.312 78.3503 198.138 79.1793 199.157 79.1793Z"
                        fill="#F55F44"
                    />
                    <path
                        opacity="0.5"
                        d="M56.5458 185.452C57.565 185.452 58.3912 184.623 58.3912 183.6C58.3912 182.578 57.565 181.749 56.5458 181.749C55.5266 181.749 54.7004 182.578 54.7004 183.6C54.7004 184.623 55.5266 185.452 56.5458 185.452Z"
                        fill="#F55F44"
                    />
                    <path
                        opacity="0.5"
                        d="M180.205 119.202C181.224 119.202 182.051 118.373 182.051 117.35C182.051 116.328 181.224 115.499 180.205 115.499C179.186 115.499 178.36 116.328 178.36 117.35C178.36 118.373 179.186 119.202 180.205 119.202Z"
                        fill="#F55F44"
                    />
                    <path
                        opacity="0.5"
                        d="M21.3559 25.5441C22.3751 25.5441 23.2013 24.7151 23.2013 23.6924C23.2013 22.6698 22.3751 21.8408 21.3559 21.8408C20.3367 21.8408 19.5105 22.6698 19.5105 23.6924C19.5105 24.7151 20.3367 25.5441 21.3559 25.5441Z"
                        fill="#4D8AF0"
                    />
                    <path
                        opacity="0.5"
                        d="M1.84541 76.0538C2.8646 76.0538 3.69082 75.2248 3.69082 74.2022C3.69082 73.1796 2.8646 72.3506 1.84541 72.3506C0.826217 72.3506 0 73.1796 0 74.2022C0 75.2248 0.826217 76.0538 1.84541 76.0538Z"
                        fill="#47E6B1"
                    />
                    <path
                        d="M129.776 76.9604H84.579C84.2125 76.9604 83.8611 76.8149 83.602 76.5558C83.3429 76.2967 83.1974 75.9453 83.1974 75.5788C83.1974 75.2124 83.3429 74.861 83.602 74.6019C83.8611 74.3428 84.2125 74.1973 84.579 74.1973H129.776C130.143 74.1973 130.494 74.3428 130.753 74.6019C131.012 74.861 131.158 75.2124 131.158 75.5788C131.158 75.9453 131.012 76.2967 130.753 76.5558C130.494 76.8149 130.143 76.9604 129.776 76.9604Z"
                        fill="#F2F2F2"
                    />
                    <path
                        d="M129.776 91.1709H84.579C84.2125 91.1709 83.8611 91.0253 83.602 90.7662C83.3429 90.5071 83.1974 90.1557 83.1974 89.7893C83.1974 89.4229 83.3429 89.0715 83.602 88.8124C83.8611 88.5533 84.2125 88.4077 84.579 88.4077H129.776C130.143 88.4077 130.494 88.5533 130.753 88.8124C131.012 89.0715 131.158 89.4229 131.158 89.7893C131.158 90.1557 131.012 90.5071 130.753 90.7662C130.494 91.0253 130.143 91.1709 129.776 91.1709Z"
                        fill="#F2F2F2"
                    />
                    <path
                        d="M129.776 105.381H84.579C84.2125 105.381 83.8611 105.236 83.602 104.977C83.3429 104.718 83.1974 104.366 83.1974 104C83.1974 103.633 83.3429 103.282 83.602 103.023C83.8611 102.764 84.2125 102.618 84.579 102.618H129.776C130.143 102.618 130.494 102.764 130.753 103.023C131.012 103.282 131.158 103.633 131.158 104C131.158 104.366 131.012 104.718 130.753 104.977C130.494 105.236 130.143 105.381 129.776 105.381Z"
                        fill="#F2F2F2"
                    />
                    <path
                        d="M129.776 119.592H84.579C84.2125 119.592 83.8611 119.446 83.602 119.187C83.3429 118.928 83.1974 118.577 83.1974 118.21C83.1974 117.844 83.3429 117.492 83.602 117.233C83.8611 116.974 84.2125 116.829 84.579 116.829H129.776C130.143 116.829 130.494 116.974 130.753 117.233C131.012 117.492 131.158 117.844 131.158 118.21C131.158 118.577 131.012 118.928 130.753 119.187C130.494 119.446 130.143 119.592 129.776 119.592Z"
                        fill="#F2F2F2"
                    />
                    <path
                        d="M129.776 133.803H84.579C84.2125 133.803 83.8611 133.657 83.602 133.398C83.3429 133.139 83.1974 132.788 83.1974 132.421C83.1974 132.055 83.3429 131.703 83.602 131.444C83.8611 131.185 84.2125 131.04 84.579 131.04H129.776C130.143 131.04 130.494 131.185 130.753 131.444C131.012 131.703 131.158 132.055 131.158 132.421C131.158 132.788 131.012 133.139 130.753 133.398C130.494 133.657 130.143 133.803 129.776 133.803Z"
                        fill="#F2F2F2"
                    />
                    <path
                        d="M121.882 76.9604H84.3816C84.0152 76.9604 83.6638 76.8149 83.4047 76.5558C83.1456 76.2967 83 75.9453 83 75.5788C83 75.2124 83.1456 74.861 83.4047 74.6019C83.6638 74.3428 84.0152 74.1973 84.3816 74.1973H121.882C122.248 74.1973 122.599 74.3428 122.859 74.6019C123.118 74.861 123.263 75.2124 123.263 75.5788C123.263 75.9453 123.118 76.2967 122.859 76.5558C122.599 76.8149 122.248 76.9604 121.882 76.9604Z"
                        fill="#B43DC6"
                    />
                    <path
                        d="M110.237 91.1709H84.3816C84.0152 91.1709 83.6638 91.0253 83.4047 90.7662C83.1456 90.5071 83 90.1557 83 89.7893C83 89.4229 83.1456 89.0715 83.4047 88.8124C83.6638 88.5533 84.0152 88.4077 84.3816 88.4077H110.237C110.603 88.4077 110.955 88.5533 111.214 88.8124C111.473 89.0715 111.618 89.4229 111.618 89.7893C111.618 90.1557 111.473 90.5071 111.214 90.7662C110.955 91.0253 110.603 91.1709 110.237 91.1709Z"
                        fill="#B43DC6"
                    />
                    <path
                        d="M96.0263 105.381H84.3816C84.0152 105.381 83.6638 105.236 83.4047 104.977C83.1456 104.718 83 104.366 83 104C83 103.633 83.1456 103.282 83.4047 103.023C83.6638 102.764 84.0152 102.618 84.3816 102.618H96.0263C96.3927 102.618 96.7441 102.764 97.0032 103.023C97.2623 103.282 97.4079 103.633 97.4079 104C97.4079 104.366 97.2623 104.718 97.0032 104.977C96.7441 105.236 96.3927 105.381 96.0263 105.381Z"
                        fill="#B43DC6"
                    />
                    <path
                        d="M125.434 119.592H84.3816C84.0152 119.592 83.6638 119.446 83.4047 119.187C83.1456 118.928 83 118.577 83 118.21C83 117.844 83.1456 117.492 83.4047 117.233C83.6638 116.974 84.0152 116.829 84.3816 116.829H125.434C125.801 116.829 126.152 116.974 126.411 117.233C126.67 117.492 126.816 117.844 126.816 118.21C126.816 118.577 126.67 118.928 126.411 119.187C126.152 119.446 125.801 119.592 125.434 119.592Z"
                        fill="#B43DC6"
                    />
                    <path
                        d="M116.355 133.803H84.3816C84.0152 133.803 83.6638 133.657 83.4047 133.398C83.1456 133.139 83 132.788 83 132.421C83 132.055 83.1456 131.703 83.4047 131.444C83.6638 131.185 84.0152 131.04 84.3816 131.04H116.355C116.722 131.04 117.073 131.185 117.332 131.444C117.591 131.703 117.737 132.055 117.737 132.421C117.737 132.788 117.591 133.139 117.332 133.398C117.073 133.657 116.722 133.803 116.355 133.803Z"
                        fill="#B43DC6"
                    />
                    <path
                        d="M148.526 74.1973H148.132V76.9604H148.526V74.1973Z"
                        fill="#B43DC6"
                    />
                    <path
                        d="M151.487 77.1579C151.174 77.1579 150.869 77.0653 150.61 76.8918C150.35 76.7183 150.148 76.4717 150.028 76.1832C149.909 75.8947 149.877 75.5772 149.938 75.2709C149.999 74.9646 150.149 74.6833 150.37 74.4625C150.591 74.2416 150.872 74.0913 151.179 74.0303C151.485 73.9694 151.803 74.0007 152.091 74.1202C152.38 74.2397 152.626 74.4421 152.8 74.7017C152.973 74.9614 153.066 75.2667 153.066 75.5789C153.065 75.9976 152.899 76.3989 152.603 76.6949C152.307 76.9909 151.905 77.1574 151.487 77.1579ZM151.487 74.3947C151.253 74.3947 151.024 74.4642 150.829 74.5943C150.634 74.7244 150.482 74.9094 150.393 75.1258C150.303 75.3422 150.28 75.5803 150.325 75.81C150.371 76.0397 150.484 76.2507 150.649 76.4163C150.815 76.5819 151.026 76.6947 151.256 76.7404C151.485 76.7861 151.724 76.7627 151.94 76.673C152.156 76.5834 152.341 76.4316 152.471 76.2369C152.602 76.0421 152.671 75.8132 152.671 75.5789C152.671 75.265 152.546 74.964 152.324 74.742C152.102 74.52 151.801 74.3951 151.487 74.3947Z"
                        fill="#B43DC6"
                    />
                    <path
                        d="M148.526 88.4077H148.132V91.1709H148.526V88.4077Z"
                        fill="#B43DC6"
                    />
                    <path
                        d="M151.487 91.3683C151.174 91.3683 150.869 91.2757 150.61 91.1022C150.35 90.9287 150.148 90.6822 150.028 90.3936C149.909 90.1051 149.877 89.7876 149.938 89.4814C149.999 89.1751 150.149 88.8937 150.37 88.6729C150.591 88.4521 150.872 88.3017 151.179 88.2408C151.485 88.1799 151.803 88.2111 152.091 88.3306C152.38 88.4501 152.626 88.6525 152.8 88.9122C152.973 89.1718 153.066 89.4771 153.066 89.7894C153.065 90.208 152.899 90.6093 152.603 90.9054C152.307 91.2014 151.905 91.3679 151.487 91.3683ZM151.487 88.6052C151.253 88.6052 151.024 88.6746 150.829 88.8048C150.634 88.9349 150.482 89.1198 150.393 89.3362C150.303 89.5526 150.28 89.7907 150.325 90.0204C150.371 90.2501 150.484 90.4611 150.649 90.6268C150.815 90.7924 151.026 90.9052 151.256 90.9509C151.485 90.9966 151.724 90.9731 151.94 90.8835C152.156 90.7938 152.341 90.6421 152.471 90.4473C152.602 90.2526 152.671 90.0236 152.671 89.7894C152.671 89.4754 152.546 89.1744 152.324 88.9524C152.102 88.7304 151.801 88.6055 151.487 88.6052Z"
                        fill="#B43DC6"
                    />
                    <path
                        d="M148.526 102.618H148.132V105.381H148.526V102.618Z"
                        fill="#B43DC6"
                    />
                    <path
                        d="M151.487 105.579C151.174 105.579 150.869 105.487 150.61 105.313C150.35 105.14 150.148 104.893 150.028 104.605C149.909 104.316 149.877 103.999 149.938 103.692C149.999 103.386 150.149 103.105 150.37 102.884C150.591 102.663 150.872 102.513 151.179 102.452C151.485 102.391 151.803 102.422 152.091 102.542C152.38 102.661 152.626 102.863 152.8 103.123C152.973 103.383 153.066 103.688 153.066 104C153.065 104.419 152.899 104.82 152.603 105.116C152.307 105.412 151.905 105.579 151.487 105.579ZM151.487 102.816C151.253 102.816 151.024 102.886 150.829 103.016C150.634 103.146 150.482 103.331 150.393 103.547C150.303 103.764 150.28 104.002 150.325 104.231C150.371 104.461 150.484 104.672 150.649 104.838C150.815 105.003 151.026 105.116 151.256 105.162C151.485 105.207 151.724 105.184 151.94 105.094C152.156 105.005 152.341 104.853 152.471 104.658C152.602 104.464 152.671 104.235 152.671 104C152.671 103.686 152.546 103.385 152.324 103.163C152.102 102.941 151.801 102.816 151.487 102.816Z"
                        fill="#B43DC6"
                    />
                    <path
                        d="M148.526 116.829H148.132V119.592H148.526V116.829Z"
                        fill="#B43DC6"
                    />
                    <path
                        d="M151.487 119.79C151.174 119.79 150.869 119.697 150.61 119.524C150.35 119.35 150.148 119.104 150.028 118.815C149.909 118.527 149.877 118.209 149.938 117.903C149.999 117.596 150.149 117.315 150.37 117.094C150.591 116.873 150.872 116.723 151.179 116.662C151.485 116.601 151.803 116.633 152.091 116.752C152.38 116.872 152.626 117.074 152.8 117.334C152.973 117.593 153.066 117.898 153.066 118.211C153.065 118.629 152.899 119.031 152.603 119.327C152.307 119.623 151.905 119.789 151.487 119.79ZM151.487 117.027C151.253 117.027 151.024 117.096 150.829 117.226C150.634 117.356 150.482 117.541 150.393 117.758C150.303 117.974 150.28 118.212 150.325 118.442C150.371 118.672 150.484 118.883 150.649 119.048C150.815 119.214 151.026 119.327 151.256 119.372C151.485 119.418 151.724 119.394 151.94 119.305C152.156 119.215 152.341 119.063 152.471 118.869C152.602 118.674 152.671 118.445 152.671 118.211C152.671 117.897 152.546 117.596 152.324 117.374C152.102 117.152 151.801 117.027 151.487 117.027Z"
                        fill="#B43DC6"
                    />
                    <path
                        d="M148.526 131.04H148.132V133.803H148.526V131.04Z"
                        fill="#B43DC6"
                    />
                    <path
                        d="M151.487 134C151.174 134 150.869 133.908 150.61 133.734C150.35 133.561 150.148 133.314 150.028 133.025C149.909 132.737 149.877 132.419 149.938 132.113C149.999 131.807 150.149 131.526 150.37 131.305C150.591 131.084 150.872 130.934 151.179 130.873C151.485 130.812 151.803 130.843 152.091 130.962C152.38 131.082 152.626 131.284 152.8 131.544C152.973 131.804 153.066 132.109 153.066 132.421C153.065 132.84 152.899 133.241 152.603 133.537C152.307 133.833 151.905 134 151.487 134ZM151.487 131.237C151.253 131.237 151.024 131.306 150.829 131.437C150.634 131.567 150.482 131.752 150.393 131.968C150.303 132.184 150.28 132.423 150.325 132.652C150.371 132.882 150.484 133.093 150.649 133.259C150.815 133.424 151.026 133.537 151.256 133.583C151.485 133.628 151.724 133.605 151.94 133.515C152.156 133.426 152.341 133.274 152.471 133.079C152.602 132.884 152.671 132.655 152.671 132.421C152.671 132.107 152.546 131.806 152.324 131.584C152.102 131.362 151.801 131.237 151.487 131.237Z"
                        fill="#B43DC6"
                    />
                    <path
                        d="M151.487 76.3685C151.923 76.3685 152.276 76.015 152.276 75.579C152.276 75.143 151.923 74.7896 151.487 74.7896C151.051 74.7896 150.697 75.143 150.697 75.579C150.697 76.015 151.051 76.3685 151.487 76.3685Z"
                        fill="#B43DC6"
                    />
                    <path
                        d="M151.487 119C151.923 119 152.276 118.647 152.276 118.211C152.276 117.775 151.923 117.421 151.487 117.421C151.051 117.421 150.697 117.775 150.697 118.211C150.697 118.647 151.051 119 151.487 119Z"
                        fill="#B43DC6"
                    />
                    <path
                        d="M151.487 133.211C151.923 133.211 152.276 132.857 152.276 132.421C152.276 131.985 151.923 131.632 151.487 131.632C151.051 131.632 150.697 131.985 150.697 132.421C150.697 132.857 151.051 133.211 151.487 133.211Z"
                        fill="#B43DC6"
                    />
                </g>
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1="99.8195"
                        y1="164.766"
                        x2="69.6309"
                        y2="24.5407"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#808080" stop-opacity="0.25" />
                        <stop
                            offset="0.54"
                            stop-color="#808080"
                            stop-opacity="0.12"
                        />
                        <stop
                            offset="1"
                            stop-color="#808080"
                            stop-opacity="0.1"
                        />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear"
                        x1="26910.7"
                        y1="-3516.08"
                        x2="26784.2"
                        y2="-4103.82"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#808080" stop-opacity="0.25" />
                        <stop
                            offset="0.54"
                            stop-color="#808080"
                            stop-opacity="0.12"
                        />
                        <stop
                            offset="1"
                            stop-color="#808080"
                            stop-opacity="0.1"
                        />
                    </linearGradient>
                    <linearGradient
                        id="paint2_linear"
                        x1="7857.99"
                        y1="401.053"
                        x2="7681.47"
                        y2="-418.896"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#808080" stop-opacity="0.25" />
                        <stop
                            offset="0.54"
                            stop-color="#808080"
                            stop-opacity="0.12"
                        />
                        <stop
                            offset="1"
                            stop-color="#808080"
                            stop-opacity="0.1"
                        />
                    </linearGradient>
                    <linearGradient
                        id="paint3_linear"
                        x1="49364.1"
                        y1="101964"
                        x2="49364.1"
                        y2="18267.8"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#808080" stop-opacity="0.25" />
                        <stop
                            offset="0.54"
                            stop-color="#808080"
                            stop-opacity="0.12"
                        />
                        <stop
                            offset="1"
                            stop-color="#808080"
                            stop-opacity="0.1"
                        />
                    </linearGradient>
                    <linearGradient
                        id="paint4_linear"
                        x1="23952.9"
                        y1="1924.72"
                        x2="23952.9"
                        y2="1323.43"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#808080" stop-opacity="0.25" />
                        <stop
                            offset="0.54"
                            stop-color="#808080"
                            stop-opacity="0.12"
                        />
                        <stop
                            offset="1"
                            stop-color="#808080"
                            stop-opacity="0.1"
                        />
                    </linearGradient>
                    <linearGradient
                        id="paint5_linear"
                        x1="9438.49"
                        y1="2549.14"
                        x2="9438.49"
                        y2="1709.34"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#808080" stop-opacity="0.25" />
                        <stop
                            offset="0.54"
                            stop-color="#808080"
                            stop-opacity="0.12"
                        />
                        <stop
                            offset="1"
                            stop-color="#808080"
                            stop-opacity="0.1"
                        />
                    </linearGradient>
                    <clipPath id="clip0">
                        <rect width="201" height="192" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
        <div class="title">No Scorecard Yet!</div>
        <div class="description">
            Check again in a later time<br />or contact your Account Admin for
            support
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'

@Component({})
export default class NoScorecardFound extends Vue {}
</script>

<style scoped lang="less">
.panel-container {
    background: #fff;
    border-radius: 3px;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    padding: 100px 0;
    text-align: center;

    .title {
        margin: 10px;
        font-size: 16px;
        font-weight: bold;
    }

    .description {
    }
}
</style>
