import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f11a66bc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  key: 0,
  class: "new-moments-pill-container"
}
const _hoisted_3 = {
  key: 0,
  class: "empty-data"
}
const _hoisted_4 = {
  key: 0,
  class: "moments-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_BasePillButton = _resolveComponent("BasePillButton")!
  const _component_OfflinePage = _resolveComponent("OfflinePage")!
  const _component_ErrorPage = _resolveComponent("ErrorPage")!
  const _component_EmptyPageMoments = _resolveComponent("EmptyPageMoments")!
  const _component_MomentCard = _resolveComponent("MomentCard")!
  const _component_LoadingCard = _resolveComponent("LoadingCard")!
  const _component_InfiniteScroll = _resolveComponent("InfiniteScroll")!
  const _component_PullToRefreshWrapper = _resolveComponent("PullToRefreshWrapper")!
  const _component_PlusButton = _resolveComponent("PlusButton")!
  const _component_MomentModal = _resolveComponent("MomentModal")!
  const _component_Portal = _resolveComponent("Portal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.newMomentsCount > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_BasePillButton, { class: "new-moments-pill" }, {
            default: _withCtx(() => [
              _createElementVNode("span", {
                class: "view-new-moments",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showNew && _ctx.showNew(...args)))
              }, _toDisplayString(_ctx.newMomentsMessage), 1),
              _createElementVNode("span", {
                class: "close-new-moments-pill",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.rejectNew && _ctx.rejectNew(...args)))
              }, [
                _createVNode(_component_FontAwesomeIcon, {
                  class: "close-icon",
                  size: "lg",
                  icon: _ctx.closeIcon
                }, null, 8, ["icon"])
              ])
            ], undefined, true),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_PullToRefreshWrapper, {
      "ptr-refreshing": _ctx.isReloading,
      disable: !_ctx.hasNetworkConnection,
      "refresh-container-classes": "moment-pull-to-refresh",
      onRefreshAction: _ctx.reloadData
    }, {
      default: _withCtx(() => [
        (
                    _ctx.emptyMomentsData ||
                    _ctx.momentsDataError ||
                    !_ctx.hasNetworkConnection
                )
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (!_ctx.hasNetworkConnection)
                ? (_openBlock(), _createBlock(_component_OfflinePage, { key: 0 }))
                : (_ctx.momentsDataError)
                  ? (_openBlock(), _createBlock(_component_ErrorPage, { key: 1 }))
                  : (_ctx.emptyMomentsData)
                    ? (_openBlock(), _createBlock(_component_EmptyPageMoments, { key: 2 }))
                    : _createCommentVNode("", true)
            ]))
          : (_openBlock(), _createBlock(_component_InfiniteScroll, {
              key: 1,
              "scroll-container-classes": "moment-infinite-scroll",
              "load-more": _ctx.loadMore,
              "is-loading-more": _ctx.isLoading,
              "has-reached-end": _ctx.hasReachedEnd
            }, {
              loading: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getMoments.length > 0 ? 3 : 5, (i) => {
                  return (_openBlock(), _createBlock(_component_LoadingCard, {
                    key: i,
                    borderless: true,
                    variant: "single",
                    class: "loading-single"
                  }))
                }), 128))
              ]),
              default: _withCtx(() => [
                (_ctx.showMoments)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getMoments, (moment) => {
                        return (_openBlock(), _createBlock(_component_MomentCard, {
                          key: moment.id,
                          moment: moment,
                          onViewDetail: ($event: any) => (_ctx.navigateToDetail(moment))
                        }, null, 8, ["moment", "onViewDetail"]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ], undefined, true),
              _: 1
            }, 8, ["load-more", "is-loading-more", "has-reached-end"]))
      ], undefined, true),
      _: 1
    }, 8, ["ptr-refreshing", "disable", "onRefreshAction"]),
    (_ctx.showCreateNewMomentButton)
      ? (_openBlock(), _createBlock(_component_PlusButton, {
          key: 1,
          "on-click": () => _ctx.openMessageComposer()
        }, null, 8, ["on-click"]))
      : _createCommentVNode("", true),
    (_ctx.showPopup)
      ? (_openBlock(), _createBlock(_component_Portal, {
          key: 2,
          to: "over-card-destination"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_MomentModal, {
              "close-popup": () => _ctx.closeMessageComposer(),
              "modal-source": _ctx.ModalSource.MomentsScreen,
              onMessageSent: _ctx.onMessageSent
            }, null, 8, ["close-popup", "modal-source", "onMessageSent"])
          ], undefined, true),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}