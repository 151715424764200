import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createSlots as _createSlots, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-af7f517c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Typography = _resolveComponent("Typography")!
  const _component_FeedbackFilter = _resolveComponent("FeedbackFilter")!
  const _component_PageHeader = _resolveComponent("PageHeader")!

  return (_openBlock(), _createBlock(_component_PageHeader, {
    key: "header",
    class: "header",
    "go-action": _ctx.goAction
  }, _createSlots({
    dropdown: _withCtx(() => [
      _createVNode(_component_FeedbackFilter)
    ]),
    _: 2
  }, [
    (_ctx.hasNetworkConnection)
      ? {
          name: "action",
          fn: _withCtx(() => [
            _createVNode(_component_Typography, {
              component: "span",
              class: "navigation-text filter flex-1"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Filters "),
                (_ctx.filterCount)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_1, "(" + _toDisplayString(_ctx.filterCount) + ")", 1))
                  : _createCommentVNode("", true)
              ], undefined, true),
              _: 1
            })
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["go-action"]))
}