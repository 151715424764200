import { doRequest } from '@/mobile/src/api/mobileclient'

/**
 * Retrieves all notifications for a user, read and unread notifications
 * @param page - For pagination, page number
 * @param limit - Number of notification items to retrieve
 */
export async function getMobileNotifications(page: number, limit: number) {
    return doRequest(`/ajax/notification/${page}/${limit}`)
}

/**
 * Marks all unread notifications as read
 */
export function markAllAsRead() {
    return doRequest(`/ajax/notification/readAll`)
}

/**
 * Marks a single notification as read
 * @param notificationId - ID in notification table, to be set as read
 */
export function markOneAsRead(notificationId: number) {
    return doRequest(`/ajax/notification/read/${notificationId}`)
}

export function pollForNotifications() {
    return doRequest(`/ajax/notification/unseen/poll`)
}
