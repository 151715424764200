import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "uploaded-image"
}
const _hoisted_2 = {
  key: 0,
  id: "image-loading"
}
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_MessageRecipientPickerModal = _resolveComponent("MessageRecipientPickerModal")!
  const _component_HashtagPickerModal = _resolveComponent("HashtagPickerModal")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['chat-input-container', _ctx.composerType])
  }, [
    _createElementVNode("div", {
      ref: "editor",
      class: _normalizeClass(['quill-container', { 'fit-content-height': _ctx.showImage }]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickEditor && _ctx.onClickEditor(...args)))
    }, null, 2),
    (_ctx.showImage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_FontAwesomeIcon, {
            id: "remove-img-icon",
            icon: _ctx.removeImageIcon,
            size: "lg",
            onClick: _cache[1] || (_cache[1] = () => _ctx.removeComposerImage())
          }, null, 8, ["icon"]),
          (_ctx.composerImageUploading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_FontAwesomeIcon, {
                  icon: _ctx.imageIcon,
                  size: "lg"
                }, null, 8, ["icon"])
              ]))
            : (_openBlock(), _createElementBlock("img", {
                key: 1,
                src: _ctx.uploadedImgSrc,
                alt: "uploaded image"
              }, null, 8, _hoisted_3))
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_MessageRecipientPickerModal, {
      "pendo-name": "mention",
      "search-input": _ctx.mentionSearchInput,
      "select-new": _ctx.onSelectMention,
      "set-search-input": _ctx.setMentionSearchInput,
      top: _ctx.caretTop,
      bottom: _ctx.caretBottom,
      "clear-on-no-results": true
    }, null, 8, ["search-input", "select-new", "set-search-input", "top", "bottom"]),
    _createVNode(_component_HashtagPickerModal, {
      "pendo-name": "hashtag",
      "search-input": _ctx.hashtagSearchInput,
      "select-new": _ctx.onSelectHashtag,
      "set-search-input": _ctx.setHashtagSearchInput,
      top: _ctx.caretTop,
      bottom: _ctx.caretBottom,
      "clear-on-no-results": true,
      "show-hashtag-modal": _ctx.showHashtagModal
    }, null, 8, ["search-input", "select-new", "set-search-input", "top", "bottom", "show-hashtag-modal"])
  ], 2))
}