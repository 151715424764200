<template>
    <div class="overlay-capture" :class="{ active }">
        <div
            class="endarken shadow-overlay-content"
            :class="{ active }"
            :style="clipPath"
        />
        <PortalTarget
            name="shadow-overlay-destination"
            @change="handleUpdate"
        />
    </div>
</template>

<script lang="ts">
import { Component, Watch, Vue } from 'vue-facing-decorator'
import { PortalTarget } from 'portal-vue'
import useEmitter from '@/composables/useEmitter'
import { shadowTargetEvent } from '@/mobile/src/utils/shadowbus'

@Component({
    components: {
        PortalTarget,
    },
})
export default class ShadowPortalWrapper extends Vue {
    public active = false
    public target: any = null
    public clipPath = { clipPath: 'none' }

    public emitter = useEmitter()

    mounted() {
        this.emitter?.on(shadowTargetEvent, (target) => (this.target = target))
        window.addEventListener('resize', this.calcClipPath)
    }

    destroyed() {
        window.removeEventListener('resize', this.calcClipPath)
    }

    @Watch('target')
    private calcClipPath() {
        setTimeout(() => {
            let clipPath = 'none'
            if (this.active && this.target) {
                const rect = this.target.getBoundingClientRect()
                clipPath = `polygon(0% 0%, 0% 100%, ${rect.left}px 100%,
                 ${rect.left}px ${rect.top}px, ${rect.right}px ${rect.top}px, ${rect.right}px ${rect.bottom}px, ${rect.left}px ${rect.bottom}px,
                  ${rect.left}px 100%, 100% 100%, 100% 0%)`
            }
            this.clipPath = { clipPath }
        }, 1)
    }

    public handleUpdate({ hasContent }) {
        this.active = hasContent
        if (hasContent && this.target) {
            this.target.classList.add('endarken')
            this.calcClipPath()
        } else if (this.target) {
            this.target.classList.remove('endarken')
        }
    }
}
</script>

<style lang="less">
@import '~@/styles/rain/colour.less';

.endarken {
    background: @endarken;
}
</style>

<style lang="less" scoped>
@import '~@/styles/rain/variables.less';
@import '~@/styles/rain/colour.less';
@import '~@/mobile/src/styles/zindex.less';

.overlay-capture {
    z-index: @shadowOverlayIndex;
}

.shadow-overlay-content,
.overlay-capture {
    position: fixed;
    display: none;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    pointer-events: none;

    &.active {
        display: block;
        pointer-events: all;
    }
}

.shadow-overlay-content {
    backdrop-filter: blur(
        0
    ); //This is vital; it forces the correct rendering order we want
}

.shadow-overlay-destination {
    width: 100%;
    height: 100%;
}
</style>
