<template>
    <Modal
        show
        allow-click-out
        allow-escape
        :close="() => $emit('close')"
        title="Your Feedback:"
        :bg="showModalBg"
        :size="modalSize"
    >
        <textarea v-model="feedback" placeholder="Write Feedback" rows="5">
        </textarea>
        <div class="button-container">
            <span v-if="wordLeft !== 300" class="word-limit">{{
                wordLeft || '&nbsp;'
            }}</span>
            <BaseButton
                :disabled="buttonDisabled"
                class="btn-submit"
                primary
                small
                :click="() => $emit('submit', feedback)"
                >Submit
            </BaseButton>
        </div>
    </Modal>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import Modal from '@/components/Modal.vue'
import BaseButton from '@/components/BaseButton.vue'

@Component({
    components: { BaseButton, Modal },
    emits: ['close', 'submit'],
})
export default class FeedbackModal extends Vue {
    @Prop({ type: String, default: 'small' }) public modalSize?: string
    @Prop({ type: Boolean, default: true }) private showModalBg!: boolean

    private feedback = ''
    private wordLimit = 300

    private get wordLeft() {
        return this.wordLimit - this.feedback.length
    }

    private get buttonDisabled() {
        return this.wordLeft < 0
    }
}
</script>

<style scoped lang="less">
@import '~@/styles/layout.less';
@import '~@/styles/palette';

textarea {
    width: 100%;
    border: 1px solid @greyMid;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
    resize: vertical;
    box-sizing: border-box;
}

.word-limit,
textarea::placeholder {
    color: @greyMid;
}

.button-container {
    min-height: 20px;

    .btn-submit {
        float: right;
    }
}
</style>
