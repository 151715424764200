import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1d7d37e9"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['mask-container', { 'make-relative': _ctx.makeRelative }])
  }, [
    (!!_ctx.$slots.default)
      ? _renderSlot(_ctx.$slots, "default", { key: 0 }, undefined, true)
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "button",
          "data-test-tag": "open-modal",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
        }, [
          (_ctx.icon)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: "icon",
                src: _ctx.icon,
                width: "14",
                height: "14"
              }, null, 8, _hoisted_1))
            : _createCommentVNode("", true),
          _createElementVNode("span", null, _toDisplayString(_ctx.title), 1)
        ]))
  ], 2))
}