import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a0c54c32"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal" }
const _hoisted_2 = {
  key: 0,
  class: "header"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Typography = _resolveComponent("Typography")!
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(['container', { 'no-pad': _ctx.noPad }]),
      style: _normalizeStyle(_ctx.modalPositionStyle)
    }, [
      (_ctx.showHeader)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_Typography, {
              variant: "overline",
              class: "info-title"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.title), 1)
              ], undefined, true),
              _: 1
            }),
            _createVNode(_component_FontAwesomeIcon, {
              icon: _ctx.faTimes,
              size: "lg",
              class: "close",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
            }, null, 8, ["icon"])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_Typography, {
        variant: "body2",
        class: "content"
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], undefined, true),
        _: 3
      })
    ], 6)
  ]))
}