<template>
    <div class="topic-row-content">
        <div class="label">
            {{ label }}
        </div>
        <div class="mentions">
            {{ totalResponses }} mention{{ totalResponses > 1 ? 's' : '' }}
            <span v-if="unseenResponses > 0" class="count">
                +{{ unseenResponses }}
            </span>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component
export default class TopicRowContent extends Vue {
    @Prop({ type: String, required: true })
    label!: string

    @Prop({ type: Number, required: true })
    totalResponses!: number

    @Prop({ type: Number, required: true })
    unseenResponses!: number
}
</script>

<style lang="less" scoped>
@import '~@/styles/palette';

.label {
    color: @grey90;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;
}

.mentions {
    color: @grey40;
    font-size: 12px;
    line-height: 20px;
}

// TODO: These styles have been extracted from components/Scorecard/ThingsToWorkOn.vue
// but similar code also exists in mobile/src/views/scorecard/ScorecardPage,
// pages/scorecard/ScorecardHome and pages/scorecard/ScorecardRoomtoImproveList.
// We should attempt to extract what we can into a shared component or set of
// styles.
.count {
    border-radius: 10px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    background-color: @scorecardUnseenCount;
    color: white;
    display: inline-block;
    min-width: 28px;
    text-align: center;
    margin-left: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 2px 1px;
}
</style>
