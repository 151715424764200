<template>
    <div id="scorecard-settings">
        <div
            :class="[
                'accordion-option',
                { open: openScorecardOptions, disabled: loadingScorecards },
            ]"
            @click="onClickScorecardDropdown"
        >
            <Typography class="accordion-label" variant="body2">
                {{ getAccordionLabel }}
            </Typography>
            <FontAwesomeIcon
                :class="['chevron-down icon', { open: openScorecardOptions }]"
                :icon="chevronDown"
            />
        </div>

        <div v-if="showScorecardDropdown">
            <div
                v-for="scorecard in availableScorecards"
                :key="scorecard.id"
                class="accordion-option"
                @click="onSelectNewScorecard(scorecard)"
            >
                <Typography class="accordion-label" variant="body2">
                    {{ scorecard.name }}
                </Typography>
                <FontAwesomeIcon
                    v-if="isSelectedScorecard(Number(scorecard.id))"
                    class="icon"
                    :icon="checkIcon"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { Action, Getter } from 'vuex-facing-decorator'
import { faCheck, faChevronDown } from 'fontawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import Typography from '@/components/Rain/Typography/Typography.vue'
import { pendoTrackEvent } from '@/utils/pendo'
import { Scorecard } from '@/mobile/src/types/scorecard'

@Component({
    components: {
        Typography,
        FontAwesomeIcon,
    },
    emits: ['changedSettings'],
})
export default class ScorecardSettings extends Vue {
    @Getter selectedScorecard!: number
    @Getter availableScorecards!: Scorecard[]
    @Action selectNewScorecard
    @Action loadScorecards

    public chevronDown = faChevronDown
    public checkIcon = faCheck
    public openScorecardOptions = false
    public selectingNewScorecard = false
    public loadingScorecards = false

    public onClickScorecardDropdown() {
        if (!this.loadingScorecards) {
            this.openScorecardOptions = !this.openScorecardOptions
            pendoTrackEvent('mobile-selected-scorecard-options-shown')
        }
    }

    public async created() {
        this.loadingScorecards = true
        await this.loadScorecards()
        this.loadingScorecards = false
    }

    public get selectedScorecardName() {
        if (!this.selectedScorecard) {
            return this.availableScorecards[0]?.name
        }
        return this.availableScorecards.find(
            (scorecard) => scorecard.id == this.selectedScorecard
        )?.name
    }

    public get getAccordionLabel() {
        return !this.availableScorecards.length || this.loadingScorecards
            ? 'Select scorecard'
            : `Select scorecard (${this.selectedScorecardName})`
    }

    public get showScorecardDropdown() {
        return (
            this.openScorecardOptions &&
            !this.loadingScorecards &&
            this.availableScorecards.length > 0
        )
    }

    public isSelectedScorecard(id: number): boolean {
        if (!this.selectedScorecard) {
            // Default to first scorecard
            return (
                this.availableScorecards &&
                this.availableScorecards[0]?.id === id
            )
        }
        return id === Number(this.selectedScorecard)
    }

    public async onSelectNewScorecard(scorecard) {
        if (
            !this.isSelectedScorecard(scorecard?.id) &&
            !this.selectingNewScorecard
        ) {
            this.$emit('changedSettings')
            this.selectingNewScorecard = true
            await this.selectNewScorecard(scorecard)
            this.selectingNewScorecard = false
            window.location.reload()
            window.location.href = '/overview'
            pendoTrackEvent('mobile-selected-scorecard-changed')
        }
    }
}
</script>

<style lang="less" scoped>
@import '~@/mobile/src/styles/settings.less';
</style>
