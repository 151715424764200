<template>
    <label
        class="container"
        @mouseover="hovered = true"
        @mouseleave="hovered = false"
    >
        <input
            :id="id"
            type="checkbox"
            :name="name"
            :disabled="disabled"
            :checked="checked"
            @change="handleChange"
        />
        <span :class="['checkmark', colourVariant, { hovered, circle }]" />
        <div v-if="$slots.default" class="content">
            <slot />
        </div>
    </label>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component({
    emits: ['change'],
})
export default class Checkbox extends Vue {
    @Prop({ type: String, required: false })
    public readonly id?: string
    @Prop({ type: String, required: false })
    public readonly name?: string
    @Prop({ type: Boolean, required: false, default: false })
    public readonly checked!: boolean
    @Prop({ type: Boolean, required: false, default: false })
    public readonly disabled!: boolean
    @Prop({ type: Boolean, required: false, default: false })
    public readonly circle!: boolean
    @Prop({ type: String, required: false, default: '' })
    public readonly colourVariant!: boolean

    public hovered = false

    public handleChange() {
        if (this.disabled) {
            return
        }
        this.$emit('change', !this.checked)
    }
}
</script>

<style scoped lang="less">
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';

.container {
    position: relative;
    display: flex;

    .checkmark {
        position: relative;
        height: 16px;
        width: 16px;
        background-color: @lightGrey;
        border-radius: @borderRadius-sm;
        cursor: pointer;
        border: 1px solid @grey;
        transition:
            background-color @duration @easing,
            border @duration @easing;
        box-sizing: content-box;

        &.hovered {
            background-color: @disabledPrimaryGrey;
            border: 1px solid @darkGrey;
        }

        &:after {
            content: '';
            position: absolute;
            display: none;
            left: 5px;
            top: 1.4px;
            width: 3.6px;
            height: 8px;
            border: solid @white;
            border-width: 0 3px 3px 0;
            transform: rotate(40deg);
            box-sizing: content-box;
        }

        &.circle {
            border-radius: 50%;
        }
    }

    input[type='checkbox'] {
        display: none;

        &:checked ~ .checkmark {
            // Default colourVariant
            background-color: @primaryBlue;
            border: 1px solid @primaryBlue;

            &.hovered {
                background-color: @hoverBlue;
                border: 1px solid @primaryBlue;
            }

            &.success {
                background-color: @successGreen;
                border: 1px solid @successGreen;

                &.hovered {
                    background-color: @successGreen;
                    border: 1px solid @successGreen;
                }
            }

            &:after {
                display: block;
            }
        }

        &:disabled ~ .checkmark {
            background-color: @disabledPrimaryGrey;
            border: 1px solid @disabledPrimaryGrey;
            cursor: not-allowed;

            &.hovered {
                background-color: @disabledPrimaryGrey;
                border: 1px solid @disabledPrimaryGrey;
            }

            & ~ .content {
                color: @grey;
                cursor: not-allowed;
            }
        }
    }

    .content {
        display: inline-flex;
        padding-left: @gutterSpacing-base;
        font-family: @fontFamily;
        font-size: @fontSize-base;
        line-height: @lineHeight-base;
        letter-spacing: @letterSpacing-md;
        color: @offBlack;
        cursor: pointer;
    }
}
</style>
