import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-70d90ba6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "tabs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ViewAsNavBar = _resolveComponent("ViewAsNavBar")!
  const _component_TabOption = _resolveComponent("TabOption")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_3, [
      _createVNode(_component_ViewAsNavBar),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
        return (_openBlock(), _createBlock(_component_TabOption, {
          key: tab.text,
          class: "tab-option",
          tab: tab,
          selected: _ctx.selected === tab.name,
          onClick: _ctx.onClickTab
        }, null, 8, ["tab", "selected", "onClick"]))
      }), 128))
    ], 512), [
      [_vShow, !_ctx.bottomTabsHidden]
    ])
  ]))
}