import { doRequest } from '@/mobile/src/api/mobileclient'
import { LooseObject } from '@/pages/appstore/entities/app'
import client from '@/api/client'

export async function getNotificationSettings() {
    return doRequest(`/api/v2/pushnotification/subscriptions`)
}

export async function toggleNotificationSettings(id: number, enabled: boolean) {
    return doRequest(
        `/api/v2/pushnotification/enable/${id}`,
        { enabled },
        'post',
        'form'
    )
}

export async function addNotificationSetting(notification: LooseObject) {
    return doRequest(
        '/api/v2/pushnotification/subscribe',
        notification,
        'post',
        'form'
    )
}

export async function deleteNotificationSetting(id: number) {
    return doRequest(`/api/v2/pushnotification/unsubscribe/${id}`, {}, 'post')
}

export async function toggleUserNotificationSettings(
    key: string,
    enabled: boolean
) {
    return doRequest(
        `/api/v2/user/settings/mobile/${key}/${enabled}`,
        {},
        'post'
    )
}
