import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EngagementBanner = _resolveComponent("EngagementBanner")!
  const _component_RequestNotificationPermissionPanel = _resolveComponent("RequestNotificationPermissionPanel")!
  const _component_ScorecardPage = _resolveComponent("ScorecardPage")!
  const _component_ScorecardPageExpanded = _resolveComponent("ScorecardPageExpanded")!
  const _component_SwitchLayoutContainer = _resolveComponent("SwitchLayoutContainer")!
  const _component_Scrollable = _resolveComponent("Scrollable")!
  const _component_WebViewScreen = _resolveComponent("WebViewScreen")!

  return (_openBlock(), _createBlock(_component_WebViewScreen, {
    class: "webview-container",
    "no-pad": "",
    "hide-header": ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Scrollable, {
        loading: _ctx.scorecardLoading,
        "pull-down": _ctx.load
      }, {
        default: _withCtx(() => [
          _createVNode(_component_EngagementBanner),
          _createVNode(_component_RequestNotificationPermissionPanel),
          _createVNode(_component_SwitchLayoutContainer, null, {
            compact: _withCtx(() => [
              _createVNode(_component_ScorecardPage)
            ]),
            expanded: _withCtx(() => [
              _createVNode(_component_ScorecardPageExpanded)
            ]),
            _: 1
          })
        ], undefined, true),
        _: 1
      }, 8, ["loading", "pull-down"])
    ], undefined, true),
    _: 1
  }))
}