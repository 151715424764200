<template>
    <div v-if="userData" class="spotlight-details-container">
        <EngagementTeamSpotlightUser :user-data="userData" />
        <EngagementCheckInHistory
            :engagement-questions="questionHistory"
            :pre-selected-theme="selectedTheme"
        />
    </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from 'vue-facing-decorator'
import { useRoute, useRouter } from 'vue-router'
import { Action, Getter, namespace } from 'vuex-facing-decorator'
import Scrollable from '@/components/Scrollable.vue'
import { EngagementInsights, ITimeRange } from '@/entities/insights'
import EngagementCheckInHistory from '@/mobile/src/components/engagement/TeamSpotlight/EngagementCheckInHistory.vue'
import { getCheckInQuestionHistory } from '@/api/engagement'
import {
    EngagementQuestion,
    EngagementQuestionType,
} from '@/entities/engagement'
import EngagementTeamSpotlightUser from '@/mobile/src/components/engagement/TeamSpotlight/EngagementTeamSpotlightUser.vue'
import { IScorecardSetting } from '@/pages/appstore/components/Scorecard/scorecard-settings-entity'
import { isNumeric } from '@/utils/string'
import { RouteLocationNormalized } from 'vue-router'

const ScorecardSettingsModule = namespace('scorecardSettings')

@Component({
    components: {
        EngagementTeamSpotlightUser,
        EngagementCheckInHistory,
        Scrollable,
    },
})
export default class EngagementTeamSpotlightDetail extends Vue {
    @Prop({ type: String })
    public teamMemberUserId!: string // from the route

    @Prop({ required: false, default: 'ALL' })
    public selectedTheme!: string // from the route

    engagementQuestions: EngagementQuestion[] = []

    @Getter
    readonly insightsEngagement!: EngagementInsights

    @Getter
    readonly timeRange!: ITimeRange

    @ScorecardSettingsModule.Getter
    readonly scorecardList!: IScorecardSetting[]

    @ScorecardSettingsModule.Getter
    getTopicNameByTopicId

    @Action
    loadInsightsEngagement

    @ScorecardSettingsModule.Action
    loadScorecardList

    public loading = false

    public route = useRoute()
    private router = useRouter()

    @Watch('route.path')
    onUrlChange() {
        this.updateQuestionHistory()
    }

    async updateQuestionHistory() {
        if (this.loading) {
            return
        }

        this.loading = true

        const { data } = await getCheckInQuestionHistory(
            this.teamMemberUserId,
            this.timeRange.timeUnit,
            this.timeRange.timeValue
        )
        this.engagementQuestions = data

        this.loading = false
    }

    public async mounted() {
        if (!this.insightsEngagement) {
            await this.loadInsightsEngagement()
        }

        // if invalid user let's redirect back
        if (this.userData === null) {
            await this.router.replace({ name: 'InsightsEngagementMetrics' })
            return
        }

        await this.updateQuestionHistory()

        if (!this.scorecardList.length) {
            await this.loadScorecardList()
        }
    }

    public get userData() {
        return (
            this.insightsEngagement?.data.team.find(
                (user) => user.id === this.teamMemberUserId
            ) ?? null
        )
    }

    get questionHistory() {
        // replace scorecard topic id with topic name
        return this.engagementQuestions.map((question) => {
            if (
                question.answer &&
                question.question_type === EngagementQuestionType.Scorecard &&
                isNumeric(question.answer)
            ) {
                question.answer = this.getTopicNameByTopicId(
                    Number(question.answer)
                )
            }
            return question
        })
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/typography';
@import '~@/styles/variables';
@import '~@/styles/button';

.spotlight-details-container {
    background: @neutralBg;

    .insights-no-data {
        padding: 12px;
    }
}

.insights-engagement-list {
    margin: 12px;
}
</style>
