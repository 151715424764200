import {
    FrontlineUserSessionDetailRow,
    FrontlineUserVisitStats,
    UserBasic,
} from '@/entities/user'
import client from '@/api/client'
import qs from 'qs'

interface UsersBasicResponse {
    success: boolean
    data: UserBasic[]
}

export async function getUsersBasic() {
    const response = await client.get<UsersBasicResponse>(`/user/basic`)

    return response.data
}

export async function getFrontlineUserVisitStats(
    timeUnit: string,
    timeValue: string
) {
    const params = qs.stringify({ timeUnit, timeValue })

    const { data } = await client.get<{ data: FrontlineUserVisitStats }>(
        `/user/frontline-user-visit-stats?${params}`
    )

    return data
}

export async function getFrontlineUsageTableData(query) {
    const params = qs.stringify(query)

    const { data } = await client.get<{
        data: FrontlineUserSessionDetailRow[]
    }>(`/user/frontline-usage-table?${params}`)

    return data
}

export async function downloadFrontlineUsageTableDataAsync(query) {
    const params = qs.stringify(query)

    const { data } = await client.get<{ data: string }>(
        `/user/frontline-usage-download-async?${params}`
    )

    return data
}

export async function saveSelectedScorecard(scorecard) {
    return client.post(`/user/selected-scorecard`, scorecard)
}

export async function getSelectedScorecard(scorecard) {
    return client.get(`/user/selected-scorecard`, scorecard)
}
