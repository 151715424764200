import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f93ae48"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 0,
  class: "check-in-button"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_ctx.shouldShowBanner)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["daily-check-in-banner", { 'desktop-daily-check-in-banner': !_ctx.isMobile }]),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onClickBanner(true)))
        }, [
          _createElementVNode("img", { src: _ctx.icon }, null, 8, _hoisted_2),
          _createElementVNode("p", null, _toDisplayString(_ctx.dailyEngagementQuestionToShow.question), 1),
          (!_ctx.isMobile)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_BaseButton, {
                  variant: "primary",
                  full: "",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClickBanner(false)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Daily Check-In ")
                  ], undefined, true),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ], 2)
      ]))
    : _createCommentVNode("", true)
}