<template>
    <div id="home-content" ref="masonryGridRef" class="masonry-grid">
        <div class="masonry-column">
            <HomeCube
                :show-goal-tracking="showGoalTracking"
                @set-show-report-card="onSetShowReportCard"
                @toggle-show-goal-tracking="ontoggleShowGoalTracking"
            />

            <Topics
                v-if="isTwoColumnLayout"
                :filter-key="filterKey"
                :self-only-filter="true"
                title="Your Scorecard"
            />
            <template v-if="!isTwoColumnLayout">
                <div v-if="showLatestFeedback" class="latest-feedback">
                    <LoadingCard v-if="latestFeedbackLoading" />
                    <FeedbackCardCarousel
                        v-else
                        header="Latest Feedback"
                        :view-all-callback="() => navigateToFeedback()"
                        :feedback="latestFeedback"
                    />
                </div>
                <TopicFeedbackCarousel v-else :key="refreshTopic" />
            </template>
        </div>
        <div class="masonry-column">
            <Topics
                v-if="!isTwoColumnLayout"
                :filter-key="filterKey"
                :self-only-filter="true"
                title="Your Scorecard"
            />
            <template v-if="isTwoColumnLayout">
                <div v-if="showLatestFeedback" class="latest-feedback">
                    <LoadingCard v-if="latestFeedbackLoading" />
                    <FeedbackCardCarousel
                        v-else
                        header="Latest Feedback"
                        :view-all-callback="() => navigateToFeedback()"
                        :feedback="latestFeedback"
                    />
                </div>
                <TopicFeedbackCarousel v-else :key="refreshTopic" />
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, onBeforeMount, onMounted, onDeactivated } from 'vue'
import type { Ref } from 'vue'
import store from '@/mobile/src/store/index'
import router from '@/mobile/src/router/index'
import HomeCube from '@/mobile/src/views/appV4/HomeCube.vue'
import FeedbackCardCarousel from '@/mobile/src/components/appV4/FeedbackCardCarousel.vue'
import Topics from '@/mobile/src/components/appV4/teams/Topics.vue'
import TopicFeedbackCarousel from '@/mobile/src/components/appV4/TopicFeedbackCarousel.vue'
import LoadingCard from '@/mobile/src/components/appV4/LoadingCard.vue'

const mobileQuestionType = computed(() => store.getters['mobileQuestionType'])
const companyVars = computed(() => store.getters['$companyVars'])
const latestFeedback = computed(
    () => store.getters['topicfeedback/latestFeedback']
)
const latestFeedbackLoading = computed(
    () => store.getters['topicfeedback/latestFeedbackLoading']
)

const getLatestFeedback = (metricType: string) => {
    if (!latestFeedback.value.length) {
        store.dispatch('topicfeedback/getLatestFeedback', metricType)
    }
}

interface Props {
    filterKey: string
    refreshTopic: number
    showGoalTracking: boolean
}
const props = withDefaults(defineProps<Props>(), {
    filterKey: '',
    refreshTopic: 0,
    showGoalTracking: false,
})

const masonryGridRef: Ref<HTMLDivElement | null> = ref(null)

const isTwoColumnLayout = ref(true)

const showLatestFeedback = computed(() => {
    return !companyVars.value?.has_scorecard
})

onBeforeMount(() => {
    getLatestFeedback(mobileQuestionType.value)
})

onMounted(() => {
    calculateColumnLayout()
    window.addEventListener('resize', calculateColumnLayout)
})

onDeactivated(() => {
    window.removeEventListener('resize', calculateColumnLayout)
})

// We want a different template layout for 1 & 2 column, so it must be recalculated on mount and window resize
const calculateColumnLayout = () => {
    const masonryGrid = masonryGridRef.value
    if (masonryGrid) {
        const columnWidth: number =
            parseInt(window.getComputedStyle(masonryGrid)?.columnWidth) ?? 0
        const columnGap: number =
            parseInt(window.getComputedStyle(masonryGrid)?.columnGap) ?? 0

        isTwoColumnLayout.value =
            columnWidth * 2 + columnGap <= masonryGrid.offsetWidth
    }
}

const navigateToFeedback = () => {
    router.push('/myfeedback')
}

const emit = defineEmits(['set-show-report-card', 'toggle-show-goal-tracking'])

const onSetShowReportCard = (val) => {
    emit('set-show-report-card', val)
}

const ontoggleShowGoalTracking = (val) => {
    emit('toggle-show-goal-tracking', val)
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/variables.less';
@import '~@/mobile/src/styles/misc.less';

@columnWidth: 350px;
@columnGap: 36px;
@rowGap: 20px;

#home-content {
    .masonry-grid(@columnWidth, @columnGap, @rowGap);
}

.latest-feedback {
    padding-top: @featureGutter;
}
</style>
