import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_EmptyPageState = _resolveComponent("EmptyPageState")!

  return (_openBlock(), _createBlock(_component_EmptyPageState, {
    colour: "white",
    class: _normalizeClass([{ bordered: _ctx.bordered }])
  }, {
    "icon-img": _withCtx(() => [
      _createVNode(_component_FontAwesomeIcon, {
        icon: _ctx.warningIcon,
        class: "warning-icon"
      }, null, 8, ["icon"])
    ]),
    "header-text": _withCtx(() => [
      _createTextVNode("Houston, we have a problem!")
    ]),
    "body-text": _withCtx(() => [
      _createTextVNode(" Sorry, it looks like something went wrong with our app! Please check back later and contact our support team if the problem isn't resolved. ")
    ]),
    "action-button": _withCtx(() => [
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.contactSupport && _ctx.contactSupport(...args)))
      }, "Contact support team")
    ]),
    _: 1
  }, 8, ["class"]))
}