import { ActionTree, MutationTree, GetterTree } from 'vuex'

import { getSurveyTemplates } from '@/api/lang'

export interface ContactsState {
    surveyTemplates: []
}

export const state: ContactsState = {
    surveyTemplates: [],
}

const getters: GetterTree<ContactsState, any> = {
    surveyTemplates({ surveyTemplates }) {
        return surveyTemplates
    },
}

const actions: ActionTree<ContactsState, any> = {
    async loadSurveyTemplates({ commit }) {
        const { templates } = await getSurveyTemplates()
        commit('setSurveyTemplates', templates)
    },
}

const mutations: MutationTree<ContactsState> = {
    setSurveyTemplates(state, templates) {
        state.surveyTemplates = templates
    },
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations,
}
