import { ActionTree, MutationTree, GetterTree } from 'vuex'
import { LooseObject } from '@/pages/appstore/entities/app'

// store when is the feature last refreshed

export interface PageRefreshState {
    lastRefresh: LooseObject
}

export const state: PageRefreshState = {
    lastRefresh: {},
}

const getters: GetterTree<PageRefreshState, any> = {
    pagesLastRefreshed({ lastRefresh }) {
        return lastRefresh
    },

    scorecardLastRefreshed({ lastRefresh }) {
        return lastRefresh.scorecard || 0
    },

    notificationSettingsLastRefreshed({ lastRefresh }) {
        return lastRefresh.notificationSettings || 0
    },

    customFieldValuesLastRefreshed({ lastRefresh }) {
        return lastRefresh.customFieldValues || 0
    },
}

const actions: ActionTree<PageRefreshState, any> = {}

const mutations: MutationTree<PageRefreshState> = {
    refreshFeature(state, feature) {
        state.lastRefresh = {
            ...state.lastRefresh,
            [feature]: Math.floor(Date.now() / 1000),
        }
    },
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations,
}
