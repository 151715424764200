import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MomentFeedbackCard = _resolveComponent("MomentFeedbackCard")!
  const _component_FlexibleImageBox = _resolveComponent("FlexibleImageBox")!
  const _component_AvatarOrInitials = _resolveComponent("AvatarOrInitials")!
  const _component_MomentTypeTitle = _resolveComponent("MomentTypeTitle")!
  const _component_DeleteConfirmButton = _resolveComponent("DeleteConfirmButton")!
  const _component_TextClamp = _resolveComponent("TextClamp")!
  const _component_CountCircle = _resolveComponent("CountCircle")!
  const _component_FeedbackBaseCard = _resolveComponent("FeedbackBaseCard")!

  return (_openBlock(), _createElementBlock("div", {
    class: "moment-container",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.viewDetail && _ctx.viewDetail(...args)))
  }, [
    (_ctx.moment.question)
      ? (_openBlock(), _createBlock(_component_MomentFeedbackCard, {
          key: 0,
          moment: _ctx.moment,
          "moment-recipients": _ctx.recipients
        }, null, 8, ["moment", "moment-recipients"]))
      : (_openBlock(), _createBlock(_component_FeedbackBaseCard, {
          key: 1,
          timestamp: _ctx.timestamp,
          author: _ctx.moment.user.name,
          text: _ctx.message,
          highlight: !_ctx.disableHighlight && !_ctx.moment.seen,
          "detailed-view": _ctx.detailedView,
          "emoji-view": _ctx.emojiView,
          "emoji-reactions": _ctx.emojiReactions,
          "show-undo": _ctx.showUndo,
          "undo-time-left": _ctx.secondsToDelete,
          "card-type": "moment",
          onOnReact: _ctx.onReact,
          onUndoChat: _ctx.onUndoChat
        }, _createSlots({
          icon: _withCtx(() => [
            _createVNode(_component_AvatarOrInitials, {
              size: 40,
              user: _ctx.momentUser,
              class: "profile-image"
            }, null, 8, ["user"])
          ]),
          title: _withCtx(() => [
            _createVNode(_component_MomentTypeTitle, {
              seen: Boolean(_ctx.moment.seen),
              title: _ctx.moment.type,
              "type-class": _ctx.typeClass
            }, null, 8, ["seen", "title", "type-class"])
          ]),
          rightTitle: _withCtx(() => [
            (_ctx.secondsToDelete > 0 && _ctx.moment.type === 'Workflow')
              ? (_openBlock(), _createBlock(_component_DeleteConfirmButton, {
                  key: 0,
                  "delete-confirmation-message": "Are you sure you want to cancel this workflow run?",
                  onDelete: _ctx.onConfirmDelete
                }, null, 8, ["onDelete"]))
              : _createCommentVNode("", true),
            (_ctx.recipients)
              ? (_openBlock(), _createBlock(_component_TextClamp, {
                  key: 1,
                  autoresize: "",
                  "max-lines": 2,
                  text: `To ${_ctx.recipients}`
                }, null, 8, ["text"]))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, [
          (_ctx.moment.image)
            ? {
                name: "image",
                fn: _withCtx(() => [
                  _createVNode(_component_FlexibleImageBox, {
                    src: _ctx.getMessageImageURL(_ctx.moment.image),
                    alt: `Posted by ${_ctx.moment.user.name}`,
                    "extra-class": "moment-image"
                  }, null, 8, ["src", "alt"])
                ]),
                key: "0"
              }
            : undefined,
          (_ctx.hasCount && _ctx.moment.repliesCount > 0)
            ? {
                name: "extraIcon",
                fn: _withCtx(() => [
                  _createVNode(_component_CountCircle, {
                    count: _ctx.moment.repliesCount,
                    variation: _ctx.typeClass,
                    class: "replies-count"
                  }, null, 8, ["count", "variation"])
                ]),
                key: "1"
              }
            : undefined
        ]), 1032, ["timestamp", "author", "text", "highlight", "detailed-view", "emoji-view", "emoji-reactions", "show-undo", "undo-time-left", "onOnReact", "onUndoChat"]))
  ]))
}