import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-03319c4c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "react-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "emoji-reactions-container"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "emoji-display" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "react-button-panel" }
const _hoisted_8 = { class: "react-options" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "react-close" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Typography = _resolveComponent("Typography")!
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showReactions)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reactions, (emoji, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: _normalizeClass(['emoji-count', { self: emoji.self }]),
              onClick: _withModifiers(($event: any) => (_ctx.react(emoji.emoji)), ["stop"])
            }, [
              _createElementVNode("span", _hoisted_4, _toDisplayString(emoji.emoji), 1),
              _createVNode(_component_Typography, {
                variant: "overline",
                class: "like-count"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(emoji.count), 1)
                ], undefined, true),
                _: 2
              }, 1024)
            ], 10, _hoisted_3))
          }), 128)),
          _createElementVNode("button", {
            class: "add-reaction-button open-reacts",
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleReactButtons && _ctx.toggleReactButtons(...args)), ["stop"]))
          }, [
            _createElementVNode("img", {
              src: _ctx.addEmojiIcon,
              alt: "add emoji"
            }, null, 8, _hoisted_5)
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.emojiList, (emoji, emojiIdx) => {
                return (_openBlock(), _createElementBlock("button", {
                  key: emojiIdx,
                  class: "react-button",
                  onClick: ($event: any) => (_ctx.onReact(emoji))
                }, _toDisplayString(emoji), 9, _hoisted_9))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("button", {
                class: "x-close-button",
                onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleReactButtons && _ctx.toggleReactButtons(...args)), ["stop"]))
              }, [
                _createVNode(_component_FontAwesomeIcon, {
                  class: "xmark",
                  icon: _ctx.faCircleXmark
                }, null, 8, ["icon"])
              ])
            ])
          ])
        ]))
  ]))
}