import { ITheme, IThemes } from '@/entities'
import client from '@/api/client'

export async function getThemes(useElastic) {
    return await client.get<IThemes>(`/theme?elastic=${useElastic ? 1 : 0}`)
}

export async function getTheme(id, includeTotal) {
    return await client.get<ITheme>(
        `/theme/${id}?includeTotal=${includeTotal ? 1 : 0}`
    )
}

export async function saveThemes(params, type) {
    try {
        return await client.post(`/buckets/${type}`, params)
    } catch (e) {
        return {
            data: {
                success: false,
                msg: 'Something went wrong, please try again later',
            },
        }
    }
}

export async function addSuggestedTheme(id) {
    try {
        return await client.post(`/buckets/add-ai-suggested/${id}`)
    } catch (e) {
        return {
            data: {
                success: false,
                msg: 'Something went wrong, please try again later',
            },
        }
    }
}

export async function deleteThemes(params, id) {
    try {
        return await client.post(`/buckets/remove/${id}`, params)
    } catch (e) {
        return {
            data: {
                success: false,
                msg: 'Something went wrong, please try again later',
            },
        }
    }
}

export async function saveAiTheme(params) {
    try {
        return await client.post(`/theme`, params)
    } catch (e) {
        return {
            data: {
                success: false,
                msg: 'Something went wrong, please try again later',
            },
        }
    }
}
