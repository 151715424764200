<template>
    <span :class="['moment-type', typeClass]">
        <FontAwesomeIcon
            v-if="!seen"
            :icon="circleIcon"
            class="moment-highlight-dot"
        />
        {{ title }}
    </span>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faCircle } from 'fontawesome/free-solid-svg-icons'

@Component({
    components: {
        FontAwesomeIcon,
    },
})
export default class MomentTypeTitle extends Vue {
    @Prop({ type: Boolean, default: true })
    public readonly seen!: boolean

    @Prop({ type: String, required: true })
    public readonly title!: string

    @Prop({ type: String, default: 'message' })
    public readonly typeClass!: string

    public circleIcon = faCircle
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/appV4/momentTypes.less';

.moment-type {
    font-weight: 500;
    color: var(--type-colour);
    white-space: nowrap;

    .moment-highlight-dot {
        width: 0.5em;
    }
}
</style>
