import { Chart } from 'chart.js'
import Segment from '@/utils/Segment'
import {
    storeOriginalOptions,
    rangeMaxLimiter,
    rangeMinLimiter,
} from '@/utils/zoomLib'

const helpers = (Chart as any).helpers

// The entrance that do the zoom in
// zoom is "a" number that divides the original scale
// zoomDirection tells which area is focused on after zoom in
// timer is the duration of the animation (unit: second)
// Segment stores the start point the end point during the animation
// e.g. Zoom Segment memorized the start zoom which is 1 (no change)
// and the final zoom which is 3.
export function setZoom(chart, zoom, zoomDirection, timer = 0) {
    const states = {
        // We use minimum number to locate the zoom "window"
        MinPos: new Segment(0, 0, zoomDirection.x, zoomDirection.y),
        Zoom: new Segment(1, 1, zoom.x, zoom.y),
        timer: timer * 1000,
        currentTime: 0,
        // During the animation, we don't iteratively updated the new position,
        // but we store the original scale of max and min of the ticks so that we can
        // maintain our original changing region
        originalMinAndMax: new Segment(),
        interval: 10,
        firstTime: {
            x: true,
            y: true,
        },
        pointRadius: new Segment(
            chart.options.elements.point.radius,
            chart.options.elements.point.hoverRadius,
            chart.options.elements.point.radius * 2.5,
            chart.options.elements.point.hoverRadius * 2.5
        ),
        fn: zoomInFrame,
    }

    const zoomOptions = chart.$zoom._options.zoom

    if (zoomOptions.enabled) {
        storeOriginalOptions(chart)

        if (timer === 0) {
            doZoom(chart, zoom, zoomDirection, states)
        } else {
            animationZoom(chart, states)
        }
    }
    if (typeof zoomOptions.onZoom === 'function') {
        zoomOptions.onZoom({ chart })
    }
}

// Function that do the zoom (in) once (animation will call this function multiple times)
function doZoom(chart, zoom, zoomDirection, states) {
    const zoomOptions = chart.$zoom._options.zoom
    // Which axe should be modified when figers were used.
    const _whichAxes = 'xy'
    let scaleRange
    helpers.each(chart.scales, (scale) => {
        if (scale.isHorizontal()) {
            zoomOptions.scaleAxes = 'x'
            scaleRange = getAnimationRange(states, scale, zoomOptions.scaleAxes)

            zoomScale(
                states.originalMinAndMax.x0,
                scale,
                zoom.x,
                zoomDirection.x,
                zoomOptions,
                scaleRange
            )
        } else if (!scale.isHorizontal()) {
            zoomOptions.scaleAxes = 'y'
            scaleRange = getAnimationRange(states, scale, zoomOptions.scaleAxes)

            zoomScale(
                states.originalMinAndMax.y0,
                scale,
                zoom.y,
                zoomDirection.y,
                zoomOptions,
                scaleRange
            )
        }
    })
    chart.update(0)
}

// then entrance that do (reset to) the zoom out
export function resetZoom(chart, timer = 0) {
    storeOriginalOptions(chart)

    if (timer === 0) {
        doReset(chart, null, null)
    } else {
        const states = {
            timer: timer * 1000,
            currentTime: 0,
            interval: 10,
            fn: resetFrame,
            min: new Segment(
                chart.scales['x-axis-1'].min,
                chart.scales['y-axis-1'].min,
                chart.$zoom._originalOptions['x-axis-1'].ticks.min,
                chart.$zoom._originalOptions['y-axis-1'].ticks.min
            ),
            max: new Segment(
                chart.scales['x-axis-1'].max,
                chart.scales['y-axis-1'].max,
                chart.$zoom._originalOptions['x-axis-1'].ticks.max,
                chart.$zoom._originalOptions['y-axis-1'].ticks.max
            ),
            pointRadius: new Segment(
                chart.options.elements.point.radius,
                chart.options.elements.point.hoverRadius,
                4,
                6
            ),
        }

        animationZoom(chart, states)
    }
}

// Function that do the reset zoom (out) once (animation will call this function multiple times)
function doReset(chart, min, max) {
    helpers.each(chart.scales, (scale) => {
        const tickOptions = scale.options.ticks

        if (scale.isHorizontal()) {
            tickOptions.min =
                min === null ? chart.scales['x-axis-1'].min : min.x
            tickOptions.max =
                max === null ? chart.scales['x-axis-1'].max : max.x
        } else if (!scale.isHorizontal()) {
            tickOptions.min =
                min === null ? chart.scales['y-axis-1'].min : min.y
            tickOptions.max =
                max === null ? chart.scales['y-axis-1'].max : max.y
        }
    })
    chart.update(0)
}

// Returns the animation range via the stored original min and max
function getAnimationRange(states, scale, dim) {
    let scaleRange = 0

    if (states === null) {
        scaleRange = scale.max - scale.min
    } else {
        if (dim === 'x') {
            if (states.firstTime.x) {
                states.originalMinAndMax.x0 = scale.min
                states.originalMinAndMax.x1 = scale.max
                states.firstTime.x = false
            }
            scaleRange =
                states.originalMinAndMax.x1 - states.originalMinAndMax.x0
        } else {
            if (states.firstTime.y) {
                states.originalMinAndMax.y0 = scale.min
                states.originalMinAndMax.y1 = scale.max
                states.firstTime.y = false
            }
            scaleRange =
                states.originalMinAndMax.y1 - states.originalMinAndMax.y0
        }
    }
    return scaleRange
}

// The core calculation of the zoom in
function zoomScale(
    originalMin,
    scale,
    zoom,
    directFactor,
    zoomOptions,
    scaleRange
) {
    // in one dimension
    const newRange = scaleRange / zoom
    const minDelta = directFactor * newRange
    scale.options.ticks.min = rangeMinLimiter(
        zoomOptions,
        originalMin + minDelta
    )
    scale.options.ticks.max = rangeMaxLimiter(
        zoomOptions,
        originalMin + minDelta + newRange
    )
}

// Do the animation zoom via requestAnimationFrame
function animationZoom(chart, states) {
    const start = window.performance.now()
    function step(timestamp) {
        let progress = timestamp - start
        if (chart.ctx && states.fn) {
            if (progress >= states.timer) {
                progress = states.timer
            }
            states.fn(chart, states, progress)
        }
        if (progress < states.timer) {
            window.requestAnimationFrame(step)
        }
    }
    window.requestAnimationFrame(step)
}

// Pre-process of doing one frame of the zoom in animation, will be called in the setInterval
const zoomInFrame = (chart, states, progress) => {
    states.currentTime = progress
    const timeRatio = progress / states.timer
    const myZoom = {
        x: states.Zoom.getNewX(timeRatio),
        y: states.Zoom.getNewY(timeRatio),
    }
    const myZoomDirection = {
        x: states.MinPos.getNewX(timeRatio),
        y: states.MinPos.getNewY(timeRatio),
    }
    const PR = states.pointRadius
    chart.options.elements.point.radius = PR.getNewX(timeRatio)
    chart.options.elements.point.hoverRadius = PR.getNewY(timeRatio)

    doZoom(chart, myZoom, myZoomDirection, states)
}

// Pre-process of doing one frame of the zoom out (reset) animation, will be called in the setInterval
const resetFrame = (chart, states, progress) => {
    states.currentTime = progress
    const timeRatio = progress / states.timer
    const myMin = {
        x: states.min.getNewX(timeRatio),
        y: states.min.getNewY(timeRatio),
    }
    const myMax = {
        x: states.max.getNewX(timeRatio),
        y: states.max.getNewY(timeRatio),
    }

    const PR = states.pointRadius
    chart.options.elements.point.radius = PR.getNewX(timeRatio)
    chart.options.elements.point.hoverRadius = PR.getNewY(timeRatio)

    doReset(chart, myMin, myMax)
}

// Non-linear function for the ease in and out effect
function parameterized(x) {
    return x ** 2 / (x ** 2 + (1 - x) ** 2)
}
