<template>
    <div class="filter-bar-tv-menu" data-uk-tooltip title="AskNicely TV">
        <Dropdown right>
            <template #trigger>
                <a class="trigger" href="/tv" @click.prevent>
                    <img src="../assets/img/Linkicons/TV.svg" />
                </a>
            </template>
            <template #content>
                <div class="options">
                    <a class="option" href="/tv/slideshow">Launch Slideshow</a>
                    <a class="option" href="/tv/edit">Configure Slideshow</a>
                    <a
                        class="option"
                        target="setupguide"
                        href="https://asknicely.zendesk.com/hc/en-us/articles/4401989471380"
                    >
                        TV Setup Guide
                        <img src="/img/icons/external_link.svg" />
                    </a>
                </div>
            </template>
        </Dropdown>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import Dropdown from '@/components/Dropdown.vue'

@Component({
    components: { Dropdown },
})
export default class FilterBarTVMenu extends Vue {
    public show = false
}
</script>

<style scoped lang="less">
@import '../styles/palette.less';

a {
    display: block;
}

.trigger {
    width: 38px;
    height: 38px;
    padding: 3px;
}

@media (max-width: 1280px) {
    .filter-bar-tv-menu {
        display: inline-block;
    }

    .trigger {
        img {
            margin-top: 6px;
        }
    }
}

.options:extend(.box) {
    width: 200px;
    text-align: left;
    margin-bottom: 0px;
    padding: 15px 0px;
    background: @white;
}

.option {
    padding: 5px 15px;
    display: block;
    cursor: pointer;
    font-size: 16px;
    color: @black;
}

.option:hover {
    background: @backgroundColor;
    color: @ctaColor;
    text-decoration: none;
}
</style>
