<template>
    <div>
        <div class="horizontal-scroll-container metrics-container">
            <div class="scrolling-item">
                <div>
                    <InsightsMetric
                        :active="selectedEngagementMetric === 'trending_themes'"
                        :delta="metricDelta(getMetric(themes))"
                        :title="metricName(getMetric(themes))"
                        :skeleton="loading"
                        subtitle="Trending Themes"
                        @click="
                            changeSelectedEngagementMetric('trending_themes')
                        "
                    />
                </div>
            </div>
            <div class="scrolling-item">
                <div>
                    <InsightsMetric
                        :active="selectedEngagementMetric === 'team_spotlight'"
                        :delta="metricDelta(getMetric(team))"
                        :title="metricName(getMetric(team))"
                        :skeleton="loading"
                        subtitle="Team Spotlight"
                        @click="
                            changeSelectedEngagementMetric('team_spotlight')
                        "
                    />
                </div>
            </div>
        </div>
        <template v-if="hasGraph">
            <InsightsGraph
                v-if="chartData"
                :title="graphTitle"
                :color="getGraphColour(chartData, $palette)"
                :data="chartData"
                :height="graphHeight"
            />
            <SkeletonDiv v-else :height="graphHeight.toString() + 'px'" />
        </template>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import InsightsMetric from '@/mobile/src/components/Insights/InsightsMetric.vue'
import { Action, Getter } from 'vuex-facing-decorator'
import {
    EngagementInsights,
    EngagementInsightsItem,
    EngagementMetricType,
} from '@/entities/insights'
import InsightsGraph from '@/mobile/src/components/Insights/InsightsGraph.vue'
import SkeletonDiv from '@/components/Skeletons/SkeletonDiv.vue'

enum InsightsData {
    THEMES = 'themes',
    TEAM = 'team',
}

@Component({
    components: {
        InsightsGraph,
        InsightsMetric,
        SkeletonDiv,
    },
})
export default class InsightsEngagementTiles extends Vue {
    @Prop({ type: Boolean, default: false })
    readonly hasGraph!: boolean

    @Prop({ type: Number, default: 80 })
    readonly graphHeight!: number

    @Getter
    readonly friendlyShortTimeRange!: string

    @Getter
    readonly selectedEngagementMetric!: EngagementMetricType

    @Getter
    readonly insightsEngagement!: EngagementInsights

    @Getter
    readonly currentlyLoading!: string[]

    @Action
    setSelectedEngagementMetric

    private themes = InsightsData.THEMES
    private team = InsightsData.TEAM

    public getMetric(insightsData: InsightsData) {
        return this.insightsEngagement?.data[insightsData]?.reduce((a, b) =>
            Math.abs(a.change_in_term_data.score) >
            Math.abs(b.change_in_term_data.score)
                ? a
                : b
        )
    }

    public getGraphColour(chartData: number[], palette) {
        // If chartData is flat line ([1, 1]) then show neutral colored chart
        return JSON.stringify(chartData) === JSON.stringify([1, 1])
            ? palette.neutral
            : palette.yellow
    }

    public metricDelta(insightsItem: EngagementInsightsItem) {
        return insightsItem?.change_in_term_data.score >= 0
            ? 'positive'
            : 'negative'
    }

    public metricName(insightsItem: EngagementInsightsItem) {
        return insightsItem?.name
    }

    public get chartData() {
        let chartData: number[] = []

        switch (this.selectedEngagementMetric) {
            case 'trending_themes':
                chartData = this.getMetric(InsightsData.THEMES)?.history.map(
                    (item) => item.score
                )
                break
            case 'team_spotlight':
                chartData = this.getMetric(InsightsData.TEAM)?.history.map(
                    (item) => item.score
                )
                break
            default:
                return null
        }

        // if all points are 0 (no data) then return [1, 1] to effectively draw a neutral flat line graph
        if (chartData && chartData.every((point) => point === 0)) {
            return [1, 1]
        }

        return chartData
    }

    public get graphTitle() {
        switch (this.selectedEngagementMetric) {
            case 'team_spotlight':
                return (
                    this.getMetric(InsightsData.TEAM)?.name +
                    ' ' +
                    this.friendlyShortTimeRange
                )
            case 'trending_themes':
                return (
                    this.getMetric(InsightsData.THEMES)?.name +
                    ' ' +
                    this.friendlyShortTimeRange
                )
            default:
                return ''
        }
    }

    get loading() {
        return this.currentlyLoading.includes('insightsEngagement')
    }

    public changeSelectedEngagementMetric(engagementMetric) {
        if (!this.loading) {
            this.setSelectedEngagementMetric(engagementMetric)
        }
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/variables';

.horizontal-scroll-container {
    margin-top: @gutterSpacing;
    margin-bottom: @gutterSpacing;
    overflow-x: auto;
    display: flex;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

.scrolling-item {
    flex: 0 0 auto;
    padding-right: @gutterSpacing;

    &:first-child {
        padding-left: @gutterSpacing;
    }
}
</style>
