export interface NavigationAction {
    routeName?: string
    action?: string
    params?: {
        [key: string]: string | null
    }
}

export function postMessageToRNWebView(navigationAction: NavigationAction) {
    if (!window.ReactNativeWebView) {
        return
    }

    window.ReactNativeWebView.postMessage(JSON.stringify(navigationAction))
}

export const embedded = window.hasOwnProperty('ReactNativeWebView')
