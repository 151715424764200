import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/img/Icons/leaderboard-up.svg'
import _imports_1 from '@/assets/img/Icons/leaderboard-down.svg'


const _hoisted_1 = {
  key: 0,
  src: _imports_0,
  alt: ""
}
const _hoisted_2 = {
  key: 1,
  src: _imports_1,
  alt: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.positive)
    ? (_openBlock(), _createElementBlock("img", _hoisted_1))
    : (_openBlock(), _createElementBlock("img", _hoisted_2))
}