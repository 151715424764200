import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a1b13954"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "mention-warning-modal"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_Typography = _resolveComponent("Typography")!

  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      (_ctx.modalOpen)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_FontAwesomeIcon, {
              class: "close-icon",
              icon: _ctx.closeIcon,
              onClick: _ctx.closeModal
            }, null, 8, ["icon", "onClick"]),
            _createVNode(_component_Typography, {
              variant: "body1",
              class: "template-text"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" User @mentions can not be used when sending an external message to a customer. ")
              ], undefined, true),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ], undefined, true),
    _: 1
  }))
}