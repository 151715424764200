import appstore, { AppstoreState } from '@/store/modules/appstore'
import auth, { AuthState } from '@/store/modules/auth'
import chartStatus, { ChartState } from '@/store/modules/chartStates'
import chat, { ChatState } from '@/store/modules/chat'
import contacts, { ContactsState } from '@/store/modules/contacts'
import conversationBlockEditor, {
    ConversationSpecState,
} from '@/store/modules/conversation-block-editor'
import crosstab, { CrosstabState } from '@/store/modules/crosstab'
import dashboard, { DashboardState } from '@/store/modules/dashboard'
import digest, { DigestState } from '@/store/modules/digest'
import featureRequest, {
    FeatureRequestState,
} from '@/store/modules/feature-request'
import filter, { FilterState } from '@/store/modules/filter'
import goals, { GoalTrackingState } from '@/store/modules/goals'
import notices, { NoticesState } from '@/store/modules/notices'
import notifications, { NotificationState } from '@/store/modules/notifications'
import onboarding, { OnboardingState } from '@/store/modules/onboarding'
import playbook, { PlaybookState } from '@/store/modules/playbook'
import qualtrics, {
    State as QualtricsState,
} from '@/store/modules/appstore/qualtrics'
import reportcard, { ReportCardState } from '@/store/modules/reportcard'
import reviews, {
    State as ReviewsState,
} from '@/store/modules/appstore/reviews'
import scorecard, { ScorecardState } from '@/store/modules/scorecard'
import scorecardSettings, {
    ScorecardSettingsState,
} from '@/store/modules/appstore/scorecardSettings'
import scoredata, { ScoreDataState } from '@/store/modules/scoredata'
import status, { StatusState } from '@/store/modules/status'
import steps, { StepsState } from '@/store/modules/steps'
import suggestions, { SuggestionsState } from '@/store/modules/suggestions'
import survey, { SurveyState } from '@/store/modules/survey'
import teamscoreboard, {
    TeamScoreboardState,
} from '@/store/modules/teamscoreboard'
import toastNotifications, {
    ToastNotificationState,
} from '@/store/modules/toast-notifications'
import twilio, { TwilioState } from '@/store/modules/appstore/twilio'
import insights, { InsightsState } from '@/store/modules/insights'
import engagement, { EngagementState } from '@/store/modules/engagement'
import performanceOvertime, {
    PerformanceOverTimeConfigState,
} from '@/store/modules/performanceOvertime'
import workflows, { WorkflowsState } from '@/store/modules/workflows'
import restoreState from '@/utils/vuexPersist'
import { createStore, Store } from 'vuex'
import VuexPersistence from 'vuex-persist'

declare global {
    interface Window {
        __ASKNICELY__VUEX__STORE__: Store<State>
    }
}

const vuexLocalStore = new VuexPersistence<State>({
    storage: window.localStorage,
    supportCircular: true,
    modules: ['filter', 'toastNotifications'],
    restoreState,
})

export interface State {
    appstore: AppstoreState
    auth: AuthState
    chartStatus: ChartState
    chat: ChatState
    contacts: ContactsState
    conversationBlockEditor: ConversationSpecState
    crosstab: CrosstabState
    dashboard: DashboardState
    digest: DigestState
    engagement: EngagementState
    featureRequest: FeatureRequestState
    filter: FilterState
    goals: GoalTrackingState
    insights: InsightsState
    notices: NoticesState
    notifications: NotificationState
    onboarding: OnboardingState
    playbook: PlaybookState
    qualtrics: QualtricsState
    reportcard: ReportCardState
    reviews: ReviewsState
    scorecard: ScorecardState
    scorecardSettings: ScorecardSettingsState
    scoredata: ScoreDataState
    status: StatusState
    steps: StepsState
    suggestions: SuggestionsState
    survey: SurveyState
    teamscoreboard: TeamScoreboardState
    toastNotifications: ToastNotificationState
    twilio: TwilioState
    performanceOvertime: PerformanceOverTimeConfigState
    workflows: WorkflowsState
}

if (!window.__ASKNICELY__VUEX__STORE__) {
    window.__ASKNICELY__VUEX__STORE__ = createStore<State>({
        modules: {
            appstore,
            auth,
            chartStatus,
            chat,
            contacts,
            conversationBlockEditor,
            crosstab,
            dashboard,
            digest,
            featureRequest,
            filter,
            goals,
            notices,
            notifications,
            onboarding,
            playbook,
            qualtrics,
            reportcard,
            reviews,
            scorecard,
            scorecardSettings,
            scoredata,
            status,
            steps,
            suggestions,
            survey,
            teamscoreboard,
            toastNotifications,
            twilio,
            insights,
            engagement,
            performanceOvertime,
            workflows,
        },
        plugins: [vuexLocalStore.plugin],
    })
}

export function useStore() {
    return window.__ASKNICELY__VUEX__STORE__
}

export default window.__ASKNICELY__VUEX__STORE__
