import { DashData } from './entities/dash'
import { Nav, NavItem } from './entities/nav'

let data: DashData | null = null

const hasTitle = ({ title }: NavItem) => !!title

// remove empty
function cleanNav(nav: Nav) {
    nav.items = nav.items.filter(hasTitle)

    nav.items.forEach((item) => {
        if (item.items) {
            item.items = item.items.filter(hasTitle)
        }
    })

    return nav
}

export function setQuestionSetting(param, key, value) {
    if (!data) {
        return
    }

    data.convUiQuestions[param].settings[key] = value
}

export default function getData(): DashData | null {
    if (data) {
        return data
    }

    const dataEl = document.getElementById('dash-data')
    const dataJson = dataEl && dataEl.getAttribute('data-json')
    data = (dataJson && (JSON.parse(dataJson) as DashData)) || null

    if (data) {
        data.subMenu = cleanNav(data.subMenu)
        data.convUiQuestions = data.convUiQuestions || {}
    }

    return data
}
