<template>
    <div class="offline-container">
        <div>You are currently offline.</div>
        <div>Some features are unavailable.</div>
    </div>
</template>

<script>
export default {
    name: 'OfflineBanner',
}
</script>

<style lang="less" scoped>
@import '~@/styles/variables';

.offline-container {
    text-transform: uppercase;
    text-align: center;
    background: @greyLight3;
    color: @grey40;
    padding: 4px;
    font-size: 12px;
    line-height: 18px;
}
</style>
