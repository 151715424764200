<template>
    <transition name="fade" mode="out-in">
        <div v-if="loading" key="loading" class="loading">
            <LoadingCard variant="table" />
        </div>
        <div v-else key="content" class="hierarchy-goal-timeline-container">
            <div class="content carousel-container">
                <Carousel>
                    <div v-for="page in pages" :key="page">
                        <div
                            v-for="(hierarchyItem, row) in hierarchyItems(
                                month,
                                userName
                            )"
                            :key="row"
                            class="carousel-content"
                        >
                            <div
                                v-if="row === (page - 1) * numberPerPage"
                                class="row header"
                            >
                                <Typography
                                    variant="overline"
                                    class="name dim hierarchy-title"
                                    >{{ title }}</Typography>
                                <div
                                    v-for="(
                                        score, index
                                    ) in getLatestHierarchyScore(hierarchyItem)"
                                    :key="index"
                                    class="month"
                                >
                                    <Typography
                                        variant="overline"
                                        class="hierarchy-title"
                                    >
                                        {{ labelScoreType }}
                                        <img
                                            class="caret"
                                            src="@/assets/img/Icons/White/cavet-down.svg"
                                        />
                                    </Typography>
                                </div>
                            </div>
                            <div
                                v-if="showItem(page, row) && hierarchyItem.name"
                                class="row"
                                :class="[getColourClass(hierarchyItem)]"
                            >
                                <div
                                    class="name"
                                    @click="drillDown(hierarchyItem)"
                                >
                                    <FontAwesomeIcon
                                        v-if="hierarchyItem.allowDrilldown"
                                        :icon="faArrowCircleRight"
                                        :class="[
                                            getColourClass(hierarchyItem),
                                            'arrow-icon',
                                        ]"
                                        size="sm"
                                    />
                                    {{ hierarchyItem.name }}
                                </div>
                                <div
                                    v-for="(
                                        score, index
                                    ) in getLatestHierarchyScore(hierarchyItem)"
                                    :key="index"
                                    class="month"
                                    @click="toggleIconScore"
                                >
                                    <span
                                        class="score"
                                        :class="[getScoreClass(score)]"
                                        >{{
                                            formatScore(
                                                score.metricScore,
                                                isFiveScoreQuestionType(
                                                    scoreType
                                                ),
                                                $companyVars.has_display_csat_as_percentage
                                            )
                                        }}</span>
                                    <span class="trend">
                                        <img
                                            v-if="score.change >= 0"
                                            src="@/assets/img/Icons/chevron-up-solid.svg"
                                            class="up"
                                        />
                                        <img
                                            v-else
                                            src="@/assets/img/Icons/chevron-down-solid.svg"
                                            class="error-red"
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Carousel>
            </div>
        </div>
    </transition>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import LoadingCard from '@/mobile/src/components/appV4/LoadingCard.vue'
import Carousel from '@/components/Rain/Layouts/Carousel/Carousel.vue'
import { IHierarchyGoal, IMonthScore } from '@/entities/reportcard'
import { getFormattedScore } from '@/utils/scorecard'
import { Getter, namespace } from 'vuex-facing-decorator'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faArrowCircleRight } from 'fontawesome/free-solid-svg-icons'
import Typography from '@/components/Rain/Typography/Typography.vue'
import { formatScore } from '@/utils/number'
import { isFiveScoreQuestionType } from '@/utils/globalVariables'

const HierarchyLevelGoalsModule = namespace('hierarchylevelgoals')
const ScoredataModule = namespace('scoredata')
const TopPerformersModule = namespace('topperformers')

@Component({
    methods: { formatScore, isFiveScoreQuestionType, getFormattedScore },
    components: {
        FontAwesomeIcon,
        LoadingCard,
        Carousel,
        Typography,
    },
    emits: ['drillDown'],
})
export default class HierarchyGoalTimeline extends Vue {
    @Prop({ type: Boolean, required: true, default: true }) loading
    @Prop({ type: Boolean, default: false }) allowDrillDown
    @Prop({ type: Number }) public month!: number
    @Prop({ type: String }) public userName!: string
    @Prop({ type: String }) private title!: string
    @Prop({ type: Function }) private hierarchyItems!: (
        month,
        userName
    ) => IHierarchyGoal[]
    @Prop({ type: Number, default: 3 }) private numberPerPage!: number
    @Prop({ type: Boolean, default: false }) private pagination!: boolean
    @Getter public $companyVars

    @HierarchyLevelGoalsModule.Getter hierarchyData!: IHierarchyGoal[]
    @ScoredataModule.Getter scoreType
    @TopPerformersModule.Getter topPerformers!: IHierarchyGoal[]

    private pages = 1
    private iconView = true
    private textView = false

    public faArrowCircleRight = faArrowCircleRight

    @Watch('hierarchyData')
    public refreshData() {
        const items = this.hierarchyData
        if (!items) return

        this.pages = this.pagination
            ? Math.ceil(items.length / this.numberPerPage)
            : this.pages
    }

    @Watch('topPerformers')
    public refreshReportCardData() {
        const items = this.topPerformers
        this.pages =
            this.pagination && items
                ? Math.ceil(items?.length / this.numberPerPage)
                : this.pages
    }

    private showItem(page: number, itemCount: number) {
        return (
            itemCount >= (page - 1) * this.numberPerPage &&
            itemCount < page * this.numberPerPage
        )
    }

    drillDown(hierarchyItem: IHierarchyGoal) {
        if (this.allowDrillDown) {
            this.$emit('drillDown', hierarchyItem)
        }
    }

    private toggleIconScore() {
        // This is a hack of Vue <Transition> that does not work for transiting icon/text issue properly.
        // Use setTimeout() to delay the swap in content till the swap out content fade out completely.
        // this should match the score-leave-active{} transition time
        const transitionTime = 500
        if (this.iconView) {
            this.iconView = !this.iconView
            setTimeout(() => (this.textView = !this.iconView), transitionTime)
        } else {
            this.textView = !this.textView
            setTimeout(() => (this.iconView = !this.textView), transitionTime)
        }
    }

    private getColourClass(performer) {
        let latestScore = performer.score[performer.score.length - 1]
        return this.calculateScoreClass(latestScore)
    }

    private getScoreClass(score: IMonthScore) {
        return this.calculateScoreClass(score)
    }

    private calculateScoreClass(score: IMonthScore) {
        // Calculate the percentage difference between current score and goal
        const difference = Math.abs(score.goal - score.metricScore)
        const percentage =
            score.goal === 0 ? 0 : (difference / score.goal) * 100

        // If score.goal ===0, Goal is not enabled.
        if (score.metricScore > score.goal && score.goal > 0) {
            // Check if the score is above the goal
            return 'green'
        } else if (percentage > 25 && score.goal > 0) {
            // Check if the score is below the goal by more than 25%
            return 'orange'
        } else {
            return 'white'
        }
    }

    get labelScoreType() {
        return this.scoreType ?? 'nps'
    }

    public getLatestHierarchyScore(hierarchyItem) {
        return [hierarchyItem.score[hierarchyItem.score.length - 1]]
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';

.loading {
    margin-top: @featureGutter;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.hierarchy-goal-timeline-container {
    display: flex;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;

    // have to use this class name carousel-container!!! to adjust an-carousel-dots position.
    .carousel-container {
        .an-carousel-dots {
            padding-top: @featureGutter;
        }
    }

    .content {
        width: 100%;
        border-radius: 8px;
        background-color: rgba(255, 255, 255, 0.1);
        padding-top: 13px;
        padding-bottom: 13px;

        .header {
            margin-bottom: 2px;

            // force cavet on the same line with month name
            img {
                display: inline;
            }
            .month {
                text-transform: uppercase;
                white-space: nowrap;
                text-align: center;
            }
        }

        .carousel-content {
            margin: 0 13px;
        }

        .row {
            width: 98%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 5px;
            font-size: 14px;

            img {
                display: inline;
            }
            .caret {
                vertical-align: middle;
            }

            .arrow-icon {
                margin-right: 5px;
            }

            div {
                flex: 1;
                text-align: center;
            }
            .name {
                flex: 4;
                text-align: left;
            }
            .hierarchy-title {
                color: @white;
                padding-top: 3px;
                font-size: 12px;
            }
            .dim {
                opacity: 0.6;
                text-transform: uppercase;
                white-space: nowrap;
                font-size: 12px;
            }
            .active {
                white-space: nowrap;
            }

            &.green .score {
                color: @successGreen;
            }

            &.orange .score {
                color: @warningOrange;
            }

            .month {
                display: flex;
                justify-content: flex-end;

                .trend {
                    display: inline-block;
                    margin-left: 5px;
                    margin-right: 5px;

                    .up {
                        transform: translateY(-2px);
                    }
                }
            }

            .white {
                color: @white !important;
            }

            // Hierarchy data, icon/score transition styles.
            .score-enter-active,
            .score-leave-active {
                transition: opacity 0.5s ease-out;
            }
            .score-enter-from,
            .score-leave-to {
                opacity: 0;
            }
        }
    }
}
</style>
