<template>
    <WebViewScreen
        class="add-notification-container"
        no-pad
        title="New Notification"
    >
        <template #left-button>
            <BackButton />
        </template>
        <template #right-button>
            <div :class="{ canSave }" @click="onCreateNotificationSetting">
                Done
            </div>
        </template>

        <div class="notification-content">
            <div class="top">
                <div class="name-row">
                    <div class="name-row-inner">
                        <div class="name-text">Name</div>
                        <div class="name-input">
                            <input
                                type="text"
                                placeholder="New Notification"
                                :value="newFilterName"
                                @input="updateName"
                            />
                        </div>
                    </div>
                </div>
                <div class="description">
                    You will receive a push notification when a response meets
                    the below criteria
                </div>
            </div>
            <div class="filters-container">
                <div class="group-title">General Filters</div>
                <div class="filters">
                    <MenuRow
                        v-for="filterSetting in generalFilters"
                        :key="filterSetting.value"
                        square
                        :menu-item="{
                            icon: '',
                            name: filterSetting.label,
                            id: filterSetting.value,
                        }"
                        @click="onSelectLeaderboard"
                    >
                        <div class="row-content">
                            <div class="name-text">
                                {{ filterSetting.label }}
                            </div>
                            <div class="selected">
                                {{ newFilterValues[filterSetting.value] || '' }}
                            </div>
                        </div>
                    </MenuRow>
                </div>
                <div
                    v-if="
                        !customFieldValuesLastRefreshed ||
                        customFieldValues.length
                    "
                    class="group-title"
                >
                    Custom Filters
                </div>
                <div class="filters">
                    <template
                        v-if="
                            customFieldValuesLastRefreshed ||
                            customFieldValues.length
                        "
                    >
                        <MenuRow
                            v-for="filterSetting in customFieldValues"
                            :key="filterSetting.value"
                            square
                            :menu-item="{
                                icon: '',
                                name: filterSetting.label,
                                id: filterSetting.value,
                            }"
                            @click="onSelectLeaderboard"
                        >
                            <div class="row-content">
                                <div class="name-text">
                                    {{ filterSetting.label }}
                                </div>
                                <div class="selected">
                                    {{
                                        newFilterValues[filterSetting.value] ||
                                        ''
                                    }}
                                </div>
                            </div>
                        </MenuRow>
                    </template>
                    <SimpleSpinner
                        v-else
                        size="small"
                        class="spinner"
                        line-fg-color="#B43DC6"
                    />
                </div>
            </div>
        </div>
    </WebViewScreen>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { useRouter } from 'vue-router'
import BaseButton from '@/components/BaseButton.vue'
import WebViewScreen from '@/components/WebView/WebViewScreen.vue'
import MenuRow, { MenuItem } from '@/mobile/src/components/MenuRow.vue'
import Scrollable from '@/components/Scrollable.vue'
import { Action, Getter, Mutation } from 'vuex-facing-decorator'
import { addNotificationSetting } from '@/mobile/src/api/notificationSettings'
import { LooseObject } from '@/pages/appstore/entities/app'
import { SettingFilter } from '@/mobile/src/store/modules/notificationSettings'
import SimpleSpinner from '@/components/SimpleSpinner.vue'
import BackButton from '@/mobile/src/components/BackButton.vue'
import { getRuleOptions } from '@/api/filter'

@Component({
    components: {
        BackButton,
        Scrollable,
        MenuRow,
        WebViewScreen,
        BaseButton,
        SimpleSpinner,
    },
})
export default class AddNotificationSettings extends Vue {
    @Getter
    private readonly newFilterName!: string

    @Getter
    private readonly newFilterValues!: LooseObject

    @Getter
    private readonly generalFilters!: SettingFilter[]

    @Getter
    private readonly customFieldValues!: SettingFilter[]

    @Getter
    private readonly customFieldValuesLastRefreshed!: number

    @Mutation
    private setNotificationCustomFields

    @Mutation
    private refreshFeature

    @Action
    private loadNotificationSettings

    @Mutation
    private setNewFilterName

    @Mutation
    private resetNewFilter

    private router = useRouter()

    private async mounted() {
        if (!this.customFieldValuesLastRefreshed) {
            const { data } = await getRuleOptions()
            this.setNotificationCustomFields(data)
            this.refreshFeature('customFieldValues')
        }
    }

    private get canSave() {
        return !!this.newFilterName
    }

    private async onCreateNotificationSetting() {
        if (!this.canSave) {
            alert('Name is required')
            return
        }
        const { success, msg } = await addNotificationSetting({
            name: this.newFilterName,
            ...this.newFilterValues,
        })
        if (success) {
            this.resetNewFilter()
            await this.loadNotificationSettings()
            await this.router.back()
        } else {
            alert(msg)
        }
    }

    private updateName(e) {
        this.setNewFilterName(e.target.value)
    }

    private onSelectLeaderboard(item: MenuItem) {
        if (item.id) {
            this.router.push({
                name: 'pickNotificationSettingValue',
                params: { name: item.id },
            })
        }
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/palette.less';

.add-notification-container {
    color: @grey90;

    .notification-content {
        background: @greyLight;
        box-sizing: border-box;
        height: 100%;
        flex: 1;
        display: flex;
        flex-flow: column;

        .top {
            padding: 15px;

            flex-shrink: 0;

            .name-row {
                display: flex;
                align-items: center;
                height: 40px;
                padding-bottom: 15px;

                .name-row-inner {
                    display: flex;
                    width: 100%;

                    .name-text {
                        padding-right: 20px;
                    }

                    .name-input {
                        flex: 1;

                        input {
                            width: 100%;
                            border: none;
                            background: transparent;
                            outline: none;
                            font-size: 16px;
                        }
                    }
                }
            }

            .description {
                font-size: 11px;
            }
        }

        .filters-container {
            flex: 1;
            position: relative;
            overflow-y: auto;

            .group-title {
                position: sticky;
                top: 0;
                background: @greyLight2;
                z-index: 1;
                padding: 10px 15px;
                text-transform: uppercase;
                color: @greyMid;
            }

            .filters {
                .row-content {
                    display: flex;
                    align-items: center;

                    .name-text {
                        flex: 1;
                    }

                    .selected {
                        flex: 1;
                        text-align: right;
                        font-size: 12px;
                        padding-right: 10px;
                        color: @purple;
                    }
                }

                .spinner {
                    margin-top: 15px;
                }
            }
        }
    }
}
</style>
