<template>
    <textarea
        class="flexi-multiline-text-input"
        :value="value"
        :disabled="disabled"
        @input="onInput"
    />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component({
    emits: ['input'],
})
export default class FlexiMultilineTextInput extends Vue {
    @Prop({ type: String, required: true })
    public readonly value!: string

    @Prop({ type: String, required: false, default: '5' })
    public readonly rows!: string

    @Prop({ type: Boolean, required: false, default: false })
    public readonly disabled!: boolean

    @Prop({ type: Boolean, required: false, default: false })
    public readonly blurOnNewLine!: boolean

    public onInput(event) {
        if (this.blurOnNewLine) {
            if (event.inputType === 'insertLineBreak') {
                event.target.blur()
            }
        }
        this.$emit('input', event.target.value)
    }
}
</script>

<style lang="less" scoped>
@import '../styles/palette';
@import '../styles/typography';
@import '../styles/variables';

.flexi-multiline-text-input {
    border: 1px solid @borderGrey;
    border-radius: @borderRadius;
    box-sizing: border-box;
    color: @grey;
    font-family: @fontFamily;
    font-size: 16px;
    width: 100%;
    height: 100%;
    padding: 10px;

    &::placeholder {
        color: @grey40;
    }
}
</style>
