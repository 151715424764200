import { UserDetail } from './user'
import { IRecipient } from '@/entities/notices'

export interface Suggestion {
    id: number
    user_id: number
    actioning_suggestioncomment_id?: number
    title: string
    body: string
    users_agreed_count: number
    hot_score: number
    created: number
}

export interface SuggestionNew {
    title: string
    body: string
    mentions: IRecipient[]
}

export interface SuggestionComment {
    id: number
    user_id: number
    suggestion_id: number
    body: string
    action?: string
    created: number
}

export interface SuggestionCommentNew {
    suggestion_id: number
    body: string
    action?: string
    mentions: IRecipient[]
}

export interface SuggestionSeen {
    id: number
    user_id: number
    suggestion_id: number
}

export interface SuggestionVote {
    id: number
    user_id: number
    suggestion_id: number
    score: 1
}

export interface SuggestionRelations {
    suggestions: Suggestion[]
    votes: SuggestionVote[]
    actioning_comments: SuggestionComment[]
    users: UserDetail[]
    seen: number[] // suggestion ids
}

export interface SuggestionCommentRelations {
    comments: SuggestionComment[]
    users: UserDetail[]
}

export interface SuggestionListQuery {
    mode: 'recent' | 'popular' | 'actioned'
    offset: number
}

export type SuggestionAction = 'not-now' | 'good-idea'

export enum SuggestionLinkMode {
    None = 0,
    Path,
    Query,
}

export interface SuggestionView extends Suggestion {
    user: UserDetail
    users_agreed: UserDetail[]
    seen: boolean
    action?: string
}

export interface SuggestionCommentView extends SuggestionComment {
    user: UserDetail
}
