interface Dict<T> {
    [key: string]: T
}

type Mapper<A, B> = (obj: A) => B

export function map<A, B>(obj: Dict<A>, f: Mapper<A, B>): Dict<B> {
    const res: Dict<B> = {}
    for (const prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            res[prop] = f(obj[prop])
        }
    }
    return res
}

type Zipper<A, B, C> = (a: A, b: B) => C

export function zip<A, B, C>(
    objA: Dict<A>,
    objB: Dict<B>,
    f: Zipper<A, B, C>
): Dict<C> {
    const res: Dict<C> = {}
    for (const prop in objA) {
        if (objA.hasOwnProperty(prop) && objB.hasOwnProperty(prop)) {
            res[prop] = f(objA[prop], objB[prop])
        }
    }
    return res
}

type Predicate<A> = (a: A, prop?: string) => boolean

export function filter<A>(obj: Dict<A>, predicate: Predicate<A>): Dict<A> {
    const res: Dict<A> = {}
    for (const prop in obj) {
        if (obj.hasOwnProperty(prop) && predicate(obj[prop], prop)) {
            res[prop] = obj[prop]
        }
    }
    return res
}

// This doesn't actually break refences, so be careful
export function replaceKey<A>(
    obj: Dict<A>,
    fromKey: string,
    toKey: string
): Dict<A> {
    const res: Dict<A> = {}

    res[toKey] = obj[fromKey]

    for (const prop in obj) {
        if (obj.hasOwnProperty(prop) && prop !== fromKey) {
            res[prop] = obj[prop]
        }
    }

    return res
}

// mutate object by a specific field
export function mutateObject(obj: any, key: string, value: any) {
    return {
        ...obj,
        [key]: value,
    }
}

// needed to break references on Vue observables
export function clone<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj))
}

// new observer setter/getter name(key) will be named as: key+'Observed'
export function objPropertyObserver(
    obj = {},
    key: string,
    subscribe: Function
): void {
    Object.defineProperty(obj, `${key}Observer`, {
        get: () => {
            return obj[key]
        },
        set: (newValue) => {
            const oldValue = obj[key]
            if (oldValue === newValue) {
                return console.warn('NO value changed', newValue)
            }
            obj[key] = newValue
            subscribe(obj)
        },
        configurable: true,
    })
}

// Deep copy the array and object even with method property
export function deepClone<T>(obj): T {
    const newObj = Array.isArray(obj) ? [] : {}
    if (obj && typeof obj === 'object') {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                // The Date object is special that we do not need to deepClone.
                newObj[key] =
                    obj[key] &&
                    typeof obj[key] === 'object' &&
                    !(obj[key] instanceof Date)
                        ? deepClone<any>(obj[key])
                        : obj[key]
            }
        }
    }
    return newObj as T
}
