import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_ScorecardSettings = _resolveComponent("ScorecardSettings")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.pageLoading)
      ? (_openBlock(), _createBlock(_component_Loading, {
          key: 0,
          loading: _ctx.pageLoading
        }, null, 8, ["loading"]))
      : _createCommentVNode("", true),
    _createVNode(_component_ScorecardSettings, { onChangedSettings: _ctx.onScorecardSettingsChanged }, null, 8, ["onChangedSettings"])
  ]))
}