import client from '@/api/client'
import { IDigest } from '@/entities'

export async function getDigests() {
    return await client.get<IDigest[]>(`/digest`)
}

export async function createDigest(digest: IDigest) {
    return await client.post<{ id: number }>(`/digest`, digest)
}

export async function patchDigest(digest: IDigest) {
    return await client.put(`/digest/${digest.id}`, digest)
}

export async function deleteDigest(digestId: number) {
    return await client.delete(`/digest/${digestId}`)
}
