<template>
    <PageHeader key="header" class="header" :go-back="goBack">
        <template #back>
            <FontAwesomeIcon class="back" size="lg" :icon="faChevronLeft" />
        </template>
        <template #action> </template>
    </PageHeader>
</template>

<script setup lang="ts">
import router from '@/mobile/src/router'
import PageHeader from '@/mobile/src/components/appV4/headers/PageHeader.vue'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faChevronLeft } from 'fontawesome/free-solid-svg-icons'

function goBack() {
    router.back()
}
</script>
