<template>
    <div class="select-input">
        <label>{{ label }}</label>
        <select
            :value="value"
            class="input"
            @change="$emit('change', $event.target.value)"
        >
            <option v-for="(opt, idx) in options" :key="opt" :value="opt">
                {{ optionLabels[idx] }}
            </option>
        </select>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component({
    emits: ['change'],
})
export default class SelectInput extends Vue {
    @Prop({ type: String }) public value!: string
    @Prop({ type: String }) public label!: string
    @Prop({ type: Array }) public options!: string[]
    @Prop({ type: Array }) public optionLabels!: string[]
}
</script>

<style lang="less" scoped>
@import '../styles/form';

.select-input {
}
</style>
