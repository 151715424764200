import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-74234d0c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "header"
}
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = {
  key: 1,
  class: "header desktop"
}
const _hoisted_6 = { class: "title left" }
const _hoisted_7 = { class: "title middle" }
const _hoisted_8 = { class: "title right" }
const _hoisted_9 = {
  key: 0,
  class: "title chevron"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InsightsCoachingListItem = _resolveComponent("InsightsCoachingListItem")!
  const _component_InsightsCoachingListItemWeb = _resolveComponent("InsightsCoachingListItemWeb")!

  return (_ctx.coachingMetricData)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(['insight-coaching-list-card', { desktop: _ctx.$isMobile }])
        }, [
          (_ctx.$isMobile)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.leftHeader), 1),
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.rightHeader), 1)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.leftHeader), 1),
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.middleHeader), 1),
                _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.rightHeader), 1),
                (_ctx.coachingMetricType === 'trending_topic')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9))
                  : _createCommentVNode("", true)
              ])),
          _createElementVNode("div", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedMetricData, (coachingMetricDataItem, title, i) => {
              return (_openBlock(), _createElementBlock("div", { key: title }, [
                (_ctx.$isMobile)
                  ? (_openBlock(), _createBlock(_component_InsightsCoachingListItem, {
                      key: 'mobile' + title,
                      title: title,
                      data: coachingMetricDataItem,
                      "coaching-metric-type": _ctx.coachingMetricType,
                      "total-count": _ctx.totalCount,
                      "question-type": _ctx.questionType,
                      class: _normalizeClass([
                            i + 1 < Object.keys(_ctx.coachingMetricData).length
                                ? 'bottom-border'
                                : 'no-border',
                        ])
                    }, null, 8, ["title", "data", "coaching-metric-type", "total-count", "question-type", "class"]))
                  : (_openBlock(), _createBlock(_component_InsightsCoachingListItemWeb, {
                      key: title,
                      title: title,
                      data: coachingMetricDataItem,
                      "coaching-metric-type": _ctx.coachingMetricType,
                      "total-count": _ctx.totalCount,
                      "question-type": _ctx.questionType,
                      "active-detail": _ctx.activeDetail,
                      class: _normalizeClass([
                            i + 1 < Object.keys(_ctx.coachingMetricData).length
                                ? 'bottom-border'
                                : 'no-border',
                        ]),
                      onChangeDetail: _ctx.onSwitchActiveDetail
                    }, null, 8, ["title", "data", "coaching-metric-type", "total-count", "question-type", "active-detail", "class", "onChangeDetail"]))
              ]))
            }), 128))
          ])
        ], 2)
      ]))
    : _createCommentVNode("", true)
}