declare const UIkit

export const imageCdnBaseUrl =
    process.env.NODE_ENV !== 'production'
        ? 'https://cdn.asknicely.site'
        : 'https://cdn.asknice.ly'

export function getContent(tab: string, currSearchTerm: string) {
    if (currSearchTerm.length > 0) {
        return {
            title: 'Search Results',
            className: 'all-apps',
            subHeading: '',
        }
    }

    return {
        discover: {
            title: 'Discover',
            className: 'all-apps',
            subHeading:
                'Explore add-ons that help you do more with your survey responses',
        },
        integrate: {
            title: 'Integration',
            className: 'integrate-apps',
            subHeading:
                'Send surveys to your contacts, customers and clients by connecting AskNicely to your existing systems through an integration.',
        },
        installed: {
            title: 'Installed Apps',
            className: 'installed-apps',
        },
        updates: {
            title: 'Updates',
            className: 'update-apps',
        },
    }[tab]
}

export function getTileMaxHeight(
    containerClass: string,
    itemTitleClass: string,
    itemBodyClass: string
) {
    let maxTitleHeight = 0
    let maxBodyHeight = 0
    const availableAppContainer = document.querySelectorAll(
        `.${containerClass}`
    )

    if (availableAppContainer.length === 0) {
        return {
            maxTitleHeight: 'auto',
            maxBodyHeight: 'auto',
        }
    }
    availableAppContainer.forEach((node: any) => {
        const { height: titileHeight } = (
            node.querySelector(`.${itemTitleClass}`) as HTMLElement
        ).getBoundingClientRect()
        const { height: bodyHeight } = (
            node.querySelector(`.${itemBodyClass}`) as HTMLElement
        ).getBoundingClientRect()

        if (titileHeight > maxTitleHeight) {
            maxTitleHeight = titileHeight
        }
        if (bodyHeight > maxBodyHeight) {
            maxBodyHeight = bodyHeight
        }
    })

    return {
        maxTitleHeight: parseInt(`${maxTitleHeight}`, 10) + 'px',
        maxBodyHeight: parseInt(`${maxBodyHeight}`, 10) + 'px',
    }
}

export function checkSearchTerm(
    apps,
    currSearchTerm,
    callback = (app: any) => {
        return true
    }
) {
    apps.forEach((app: any) => {
        if (currSearchTerm === '') {
            app.display = callback(app)
            return
        }
        const searchTerm = currSearchTerm.toLowerCase()
        app.display = false

        if (app.search_terms) {
            const searchTerms = app.search_terms.toLowerCase()
            if (searchTerms.indexOf(searchTerm) !== -1) {
                app.display = true
            }
        }

        const searchTitle = app.title.toLowerCase()
        if (searchTitle.indexOf(searchTerm) !== -1) {
            app.display = true
        }
    })
}

export function searchResults(apps): boolean {
    const results = apps.filter((app: any) => {
        return app.display
    })
    return results.length === 0
}

export function notifySuccess(message: string) {
    UIkit.notify(message, {
        status: 'success',
        pos: 'top-center',
    })
}

export function notifyError(message: string) {
    UIkit.notify(message, {
        status: 'error',
        pos: 'top-center',
    })
}

export function scrollToTop() {
    window.scrollTo(0, 0)
}

export function setFormData(data: any) {
    const formData = new FormData()
    Object.keys(data).forEach((key: string) => formData.append(key, data[key]))
    return formData
}
