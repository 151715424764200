<template>
    <div class="cube-scene">
        <div :class="['cube', face, { resizing }]">
            <slot name="front" class="cube__face cube__face--front" />
            <slot name="back" class="cube__face cube__face--back" />
            <slot name="right" class="cube__face cube__face--right" />
            <slot name="left" class="cube__face cube__face--left" />
            <slot name="top" class="cube__face cube__face--top" />
            <slot name="bottom" class="cube__face cube__face--bottom" />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component({})
export default class CubeWrapper extends Vue {
    @Prop({ type: String, default: 'front' })
    public readonly face?:
        | 'front'
        | 'right'
        | 'back'
        | 'left'
        | 'top'
        | 'bottom'

    public resizing = false

    public mounted() {
        window.addEventListener('resize', this.onResize)
    }

    public beforeDestroy() {
        window.removeEventListener('resize', this.onResize)
    }

    private onResize() {
        this.resizing = true
        setTimeout(() => (this.resizing = false), 100)
    }
}
</script>

<style lang="less">
@import '~@/styles/rain/variables.less';

.cube-scene {
    perspective: 100vw;
    backdrop-filter: blur(@glassBlur);
}

.cube {
    position: relative;
    transform-style: preserve-3d;
    transform: translateZ(-50vw);
    transition: transform 1.5s;

    .cube__face {
        opacity: 0;
        transition: opacity 1s;
    }

    &.resizing {
        transition: none;
    }
}

.cube.front {
    transform: translateZ(-50vw) rotateY(0deg);
    .cube__face--front {
        opacity: 1;
    }
}
.cube.right {
    transform: translateZ(-50vw) rotateY(-90.01deg);
    .cube__face--right {
        opacity: 1;
    }
}
.cube.back {
    transform: translateZ(-50vw) rotateY(-180.01deg);
    .cube__face--back {
        opacity: 1;
    }
}
.cube.left {
    transform: translateZ(-50vw) rotateY(90.01deg);
    .cube__face--left {
        opacity: 1;
    }
}
.cube.top {
    transform: translateZ(-50vw) rotateX(-90deg);
    .cube__face--top {
        opacity: 1;
    }
}
.cube.bottom {
    transform: translateZ(-50vw) rotateX(90deg);
    .cube__face--bottom {
        opacity: 1;
    }
}

.cube__face {
    position: absolute;
    top: 0;
    width: 100%;
    box-sizing: border-box;
}

.cube__face--front {
    transform: rotateY(0deg) translateZ(50vw);
    position: relative;
} //Force the cube to take up room
.cube__face--right {
    transform: rotateY(90.01deg) translateZ(50vw);
}
.cube__face--back {
    transform: rotateY(180.01deg) translateZ(50vw);
}
.cube__face--left {
    transform: rotateY(-90.01deg) translateZ(50vw);
}
.cube__face--top {
    transform: rotateX(90deg) translateZ(50vw);
}
.cube__face--bottom {
    transform: rotateX(-90deg) translateZ(50vw);
}
</style>
