import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f1c2a63"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "loading-state" }
const _hoisted_2 = { class: "masonry-grid" }
const _hoisted_3 = { class: "masonry-column" }
const _hoisted_4 = { class: "masonry-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Profile = _resolveComponent("Profile")!
  const _component_LoadingCard = _resolveComponent("LoadingCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.hideProfile)
      ? (_openBlock(), _createBlock(_component_Profile, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.hideProfile)
          ? (_openBlock(), _createBlock(_component_LoadingCard, {
              key: 0,
              borderless: true,
              variant: 'single',
              class: "profile-loader"
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_LoadingCard, { class: "loading-card" }),
        _createVNode(_component_LoadingCard, {
          class: "loading-card",
          variant: "pill"
        }),
        _createVNode(_component_LoadingCard, { class: "loading-card" })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_LoadingCard, { class: "loading-card" })
      ])
    ])
  ]))
}