import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-50f2998b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "clear-all-button" }
const _hoisted_3 = { class: "recipient-picker" }
const _hoisted_4 = {
  key: 0,
  class: "recipients"
}
const _hoisted_5 = {
  key: 1,
  class: "recipients"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchBar = _resolveComponent("SearchBar")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_SelectedRecipientPills = _resolveComponent("SelectedRecipientPills")!
  const _component_Recipient = _resolveComponent("Recipient")!
  const _component_SimpleSpinner = _resolveComponent("SimpleSpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(['header', { mobile: _ctx.$isMobile }])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["search", { compact: _ctx.compact }])
      }, [
        _createVNode(_component_SearchBar, {
          value: _ctx.searchText,
          label: "Search recipients or groups",
          onInput: _ctx.onInput
        }, null, 8, ["value", "onInput"])
      ], 2),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_BaseButton, {
          variant: "ghost",
          onClick: _ctx.clearSelectedRecipients
        }, {
          default: _withCtx(() => [
            _createTextVNode("Clear all")
          ], undefined, true),
          _: 1
        }, 8, ["onClick"])
      ])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(['recipient-pills', { mobile: _ctx.$isMobile }])
    }, [
      (_ctx.selectedRecipients.length > 0)
        ? (_openBlock(), _createBlock(_component_SelectedRecipientPills, {
            key: 0,
            recipients: _ctx.recipients,
            "selected-recipients": _ctx.selectedRecipients,
            "can-remove-single-tabs": "",
            onRemoveRecipient: _ctx.selectRecipient
          }, null, 8, ["recipients", "selected-recipients", "onRemoveRecipient"]))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", _hoisted_3, [
      (
                    _ctx.virtualUsers && _ctx.virtualUsers.length && _ctx.canPickVirtualUsers
                )
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredVirtualUsers, (recipient) => {
              return _withDirectives((_openBlock(), _createBlock(_component_Recipient, {
                key: `${recipient.type}-${recipient.user_id}-${recipient.field}-${recipient.value}-${recipient.role}`,
                compact: _ctx.compact,
                recipient: recipient,
                selected: _ctx.isRecipientSelected(recipient),
                onPick: _ctx.selectRecipient
              }, null, 8, ["compact", "recipient", "selected", "onPick"])), [
                [_vShow, recipient.selectableForSending]
              ])
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (_ctx.recipients && _ctx.recipients.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredRecipients, (recipient) => {
              return (_openBlock(), _createBlock(_component_Recipient, {
                key: `${recipient.type}-${recipient.user_id}-${recipient.field}-${recipient.value}-${recipient.role}`,
                compact: _ctx.compact,
                recipient: recipient,
                selected: _ctx.isRecipientSelected(recipient),
                onPick: _ctx.selectRecipient
              }, null, 8, ["compact", "recipient", "selected", "onPick"]))
            }), 128))
          ]))
        : (_openBlock(), _createBlock(_component_SimpleSpinner, {
            key: 2,
            size: "small",
            class: "spinner",
            "line-fg-color": "#B43DC6"
          }))
    ])
  ]))
}