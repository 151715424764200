<template>
    <div class="container">
        <div class="findlogin-form">
            <!--            find login form         -->
            <template v-if="!found">
                <h2>Enter your email address</h2>
                <p>We'll send you a magic link to log in.</p>
                <div @keyup.enter="() => $refs.btnSend.onClick()">
                    <Input
                        ref="email"
                        v-model="email"
                        type="email"
                        title="Email"
                        placeholder="Email"
                        :regex="/.+@.+\..+/"
                        :required="true"
                        :err-msg="'Valid email is required'"
                    />
                </div>

                <TransparentButton
                    ref="btnSend"
                    text="Send"
                    class="btn-send"
                    :click="onSend"
                />
                <p>
                    Or login with
                    <RouterLink
                        class="login-with-email"
                        :to="{ name: 'check-domain' }"
                        replace
                        >email and password
                    </RouterLink>
                </p>

                <!-- google auth button            -->
                <GoogleAuthButton @signin="onGoogleSignin" />
            </template>
            <!--            login is found      -->
            <template v-else>
                <h2>Please check your email</h2>
                <p>We have sent you an email link to log in to the app</p>

                <TransparentButton
                    text="OPEN YOUR EMAIL"
                    class="btn-white"
                    :click="onClickOpenEmail"
                />
                <TransparentButton
                    text="RESEND EMAIL"
                    class="btn-resend"
                    :click="onClickResendEmail"
                />
                <p>
                    Or login with
                    <RouterLink
                        class="login-with-email"
                        :to="{ name: 'check-domain' }"
                        replace
                        >email and password</RouterLink>
                </p>
            </template>
            <AppVersion />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { useRouter } from 'vue-router'
import Input from '@/components/Input.vue'
import { findLogin } from '@/mobile/src/api/auth'
import IconLogo from '@/assets/img/logo/asknicely_logo_white.svg'
import TransparentButton from '@/mobile/src/components/TransparentButton.vue'
import { launchMailApp } from '@/mobile/src/utils/capacitor'
import AppVersion from '@/mobile/src/components/AppVersion.vue'
import GoogleAuthButton from '@/mobile/src/components/GoogleAuthButton.vue'
import { MultiLoginTokenResult } from '@/mobile/src/types/auth'
import { buildMagicLink } from '@/mobile/src/services/auth'
import { Action } from 'vuex-facing-decorator'

@Component({
    components: {
        AppVersion,
        TransparentButton,
        Input,
        GoogleAuthButton,
    },
})
export default class FindLogin extends Vue {
    @Action saveLoginTokens
    @Action saveAdminToken

    private email = ''
    public logoSrc = IconLogo
    public resending = false // is resending ajax request
    public found = false // if the login is found

    private router = useRouter()

    async onSend() {
        const result = await findLogin(this.email)
        if (result.success && result.data.success) {
            if (
                result.data.samlIntegrations &&
                result.data.samlIntegrations.length === 1
            ) {
                const { tenant, name, url } = result.data.samlIntegrations[0]
                const query = {
                    domain: tenant,
                    samlSsoName: name,
                    samlSsoUrl: url,
                    disableEmailLogin: '1',
                }
                this.router.push({ name: 'login', query })
            } else {
                this.found = true
            }
        } else {
            alert(`We can't find this email, try again later`)
        }
    }

    async onClickResendEmail() {
        await findLogin(this.email)
    }

    async onClickOpenEmail() {
        await launchMailApp()
    }

    async onGoogleSignin(result: MultiLoginTokenResult) {
        if (result.success) {
            const domains = Object.keys(result.activationLinks ?? {})
            const linkCount = domains.length
            let hasAdmin = false
            if (result.token && result.token.user) {
                hasAdmin = true
                this.saveAdminToken({
                    token: result.token.user,
                    email: result.email,
                })
            }
            if (hasAdmin || linkCount) {
                if (!hasAdmin && result.activationLinks && linkCount === 1) {
                    const loginToken = result.activationLinks[domains[0]]
                    const url = buildMagicLink({
                        domain: domains[0],
                        token: loginToken.token,
                    })
                    await this.router.replace({
                        name: 'magiclogin',
                        query: { url },
                    })
                } else {
                    this.saveLoginTokens(result.activationLinks)
                    await this.router.push({
                        name: 'choose-login',
                    })
                }
            } else {
                alert("Sorry we couldn't log you in this time")
            }
        } else {
            alert(result.msg)
        }
    }
}
</script>
<style lang="less" scoped>
@import '@/styles/palette';

.findlogin-form {
    .login-with-email {
        color: white;
    }

    .btn-white {
        background: white;
        color: @purple;
    }

    .btn-send {
        width: 100%;
    }

    .btn-white,
    .btn-send,
    .btn-resend {
        margin-top: 15px;
    }

    .btn-white,
    .btn-resend {
        width: 70%;
    }
}

.btn-white {
    color: @purple;
    background-color: white;
}
</style>
