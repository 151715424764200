<template>
    <div class="topic-card-breakdown-container">
        <div class="topic-card-breakdown-header">
            <div>Responses Breakdown</div>
        </div>
        <div>
            <div class="topic-card-all-responses">
                {{
                    ((topicResponseCount / totalResponseCount) * 100).toFixed(
                        0
                    )
                }}% of all responses ({{ totalResponseCount }})
            </div>
            <div
                :style="getBreakdownLineBg"
                class="topic-card-breakdown-line"
            ></div>
            <div class="topic-card-breakdown">
                <div>
                    <div>
                        <div class="topic-card-circle positive">⬤</div>
                        {{ positiveLabel }}
                    </div>
                    <div class="topic-card-breakdown-count-container">
                        <div class="topic-card-breakdown-count">
                            {{ positiveCount }}
                        </div>
                        <div>{{ positivePercent }}%</div>
                    </div>
                </div>
                <div>
                    <div>
                        <div class="topic-card-circle neutral">⬤</div>
                        {{ neutralLabel }}
                    </div>
                    <div class="topic-card-breakdown-count-container">
                        <div class="topic-card-breakdown-count">
                            {{ neutralCount }}
                        </div>
                        <div>{{ neutralPercent }}%</div>
                    </div>
                </div>
                <div>
                    <div>
                        <div class="topic-card-circle negative">⬤</div>
                        {{ negativeLabel }}
                    </div>
                    <div class="topic-card-breakdown-count-container">
                        <div class="topic-card-breakdown-count">
                            {{ negativeCount }}
                        </div>
                        <div>{{ negativePercent }}%</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { CoachingInsightItem } from '@/entities/insights'
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { QuestionType } from '@/entities'
import { palette } from '@/utils/palette'

@Component({
    components: {},
})
export default class TopicResponsesBreakdown extends Vue {
    // TODO: Majority of this comes from CoachingTopic, we should refactor
    showBreakdown = false

    @Prop({ type: String, default: null })
    private questionType!: QuestionType

    @Prop({ type: Object, default: null })
    private data!: CoachingInsightItem

    @Prop({ type: Number, default: null })
    private totalTopicResponseCount!: number

    get score() {
        return this.data.score_current.toFixed(1)
    }

    get scoreChange() {
        return this.data.score_change
    }

    get topicResponseCount() {
        return this.data.total_count
    }

    get totalResponseCount() {
        return this.totalTopicResponseCount
    }

    get positiveCount() {
        if (this.questionType === 'nps') {
            return this.data.promoter ?? 0
        }
        return this.data.satisfied ?? 0
    }

    get neutralCount() {
        if (this.questionType === 'nps') {
            return this.data.passive ?? 0
        }
        return this.data.neutral ?? 0
    }

    get negativeCount() {
        if (this.questionType === 'nps') {
            return this.data.detractor ?? 0
        }
        return this.data.unsatisfied ?? 0
    }

    get positivePercent() {
        return Math.round((this.positiveCount / this.topicResponseCount) * 100)
    }

    get neutralPercent() {
        return Math.round((this.neutralCount / this.topicResponseCount) * 100)
    }

    get negativePercent() {
        return Math.round((this.negativeCount / this.topicResponseCount) * 100)
    }

    get positiveLabel() {
        if (this.questionType === 'nps') {
            return 'Promoters'
        }
        return 'Satisfied'
    }

    get neutralLabel() {
        if (this.questionType === 'nps') {
            return 'Passives'
        }
        return 'Neutral'
    }

    get negativeLabel() {
        if (this.questionType === 'nps') {
            return 'Detractors'
        }
        return 'Unsatisfied'
    }

    get getBreakdownLineBg() {
        return (
            'background: linear-gradient(90deg, ' +
            palette.positive +
            ' 0%, ' +
            palette.positive +
            ' ' +
            this.positivePercent.toString() +
            '%, ' +
            palette.neutral +
            ' ' +
            this.positivePercent +
            '%, ' +
            palette.neutral +
            ' ' +
            (this.neutralPercent + this.positivePercent) +
            '%, ' +
            palette.negative +
            ' ' +
            (this.neutralPercent + this.positivePercent) +
            '%, ' +
            palette.negative +
            ' 100%);'
        )
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/variables';
@import '~@/styles/typography';
@import '~@/styles/fade';
@import '~@/styles/rain/colour';

.topic-card-breakdown-container {
    width: 50%;
}

.topic-card-breakdown-header {
    display: flex;
    justify-content: space-between;
    margin-top: @gutterSpacing-md;
    .title();
}

.topic-card-breakdown {
    display: flex;
    justify-content: space-between;
    .title();
    letter-spacing: 1px;

    .topic-card-breakdown-count-container {
        margin-left: @gutterSpacing-md;
        .topic-card-breakdown-count {
            font-size: 16px;
            color: @offBlack;
        }
    }

    .topic-card-circle {
        font-size: 8px;
        float: left;
        line-height: 24px;
        padding-right: 2px;
    }
}

.topic-card-all-responses {
    font-size: 14px;
    text-transform: uppercase;
    color: @offBlack;
    font-weight: 500;
    margin-top: @gutterSpacing-md;
    letter-spacing: 1px;
    margin-bottom: @gutterSpacing-md;
}

.topic-card-breakdown-line {
    height: 10px;
    border-radius: 4px;
    margin-bottom: @gutterSpacing-md;
}

.positive {
    color: @positive;
}

.neutral {
    color: @neutral;
}

.negative {
    color: @negative;
}
</style>
