<template>
    <div v-if="shouldShowBanner">
        <div
            class="daily-check-in-banner"
            :class="{ 'desktop-daily-check-in-banner': !isMobile }"
            @click="onClickBanner(true)"
        >
            <img :src="icon" />
            <p>{{ dailyEngagementQuestionToShow.question }}</p>
            <div v-if="!isMobile" class="check-in-button">
                <BaseButton variant="primary" full @click="onClickBanner(false)">Daily Check-In
                </BaseButton>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import BaseButton from '@/components/Rain/Buttons/BaseButton.vue'
import { Action, Getter } from 'vuex-facing-decorator'
import { EngagementQuestionIcon } from '@/mobile/src/components/engagement/utils'

@Component({
    components: { BaseButton },
})
export default class EngagementBanner extends Vue {
    @Getter private hasUnopenedEngagementQuestions!: boolean
    @Getter private hasUnansweredQuestions!: boolean

    @Getter
    public dailyEngagementQuestionToShow

    @Action
    public setEngagementModalOpened

    get shouldShowBanner() {
        // Display banner only when the user has opened the question but hasn't answered
        return (
            !this.hasUnopenedEngagementQuestions && this.hasUnansweredQuestions
        )
    }

    get icon() {
        return EngagementQuestionIcon[this.dailyEngagementQuestionToShow.theme]
    }

    get isMobile() {
        return process.env.CONFIG_KEY === 'mobile'
    }

    onClickBanner(mobileOnly: boolean) {
        if (mobileOnly && !this.isMobile) {
            return
        }
        this.setEngagementModalOpened(true)
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';

.daily-check-in-banner {
    display: flex;
    background: @brandPurple10;
    padding: 16px;
    font-size: 14px;
    line-height: 20px;
    img {
        width: 40px;
    }

    > p {
        font-weight: 500;
        color: @offBlack;
        margin: auto 16px;
    }

    .check-in-button {
        margin-left: auto;
    }
}
.desktop-daily-check-in-banner {
    background: @white;
    border-radius: 4px;
}
</style>
