<template>
    <BaseButton :loading="loading" class="btn-transparent" @click="onClick">{{ text }}
    </BaseButton>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import Input from '@/components/Input.vue'
import BaseButton from '@/components/Rain/Buttons/BaseButton.vue'

@Component({
    components: { BaseButton, Input },
})
export default class TransparentButton extends Vue {
    loading = false

    @Prop({ type: String, default: 'Submit' }) text!: string
    @Prop({ type: Function, default: () => 0 }) click!: () => void

    async onClick() {
        if (this.loading) {
            return
        }
        this.loading = true
        await this.click()
        this.loading = false
    }
}
</script>
<style lang="less" scoped>
@import '../../../styles/palette';

.btn-transparent {
    border-radius: 5px;
    box-sizing: border-box;
    line-height: 20px;
    font-size: 16px;
    background-color: transparent !important;
    border: 2px solid #fff;
}
</style>
