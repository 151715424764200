<template>
    <DarkPurpleGradientBgContainer class="full-screen-popup-bg">
        <div class="full-screen-popup-container">
            <div class="full-screen-popup">
                <slot></slot>
            </div>
        </div>
    </DarkPurpleGradientBgContainer>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import DarkPurpleGradientBgContainer from '@/mobile/src/components/containers/DarkPurpleGradientBgContainer.vue'

@Component({
    components: {
        DarkPurpleGradientBgContainer,
    },
})
export default class FullScreenPopup extends Vue {}
</script>

<style lang="less" scoped>
@import '~@/styles/variables';
.full-screen-popup-bg {
    position: fixed;
    top: 0;
    width: 100%;
    /* unfortunately for desktop we have some crazy z-index values */
    z-index: 1000;
}
.full-screen-popup-container {
    height: 100vh;
    width: 100%;
    margin: env(safe-area-inset-top) env(safe-area-inset-right)
        env(safe-area-inset-bottom) env(safe-area-inset-left);
    display: flex;
    align-items: center;

    .full-screen-popup {
        width: 100%;
        background: #fff;
        box-sizing: border-box;
        box-shadow: 0 0 10px rgba(30, 43, 90, 0.04);
        border-radius: 8px;
        height: calc(
            98% - env(safe-area-inset-bottom) - env(safe-area-inset-top)
        );
        margin: 8px 12px;
    }
}
</style>
