<template>
    <div class="view-as-container" :class="{ 'no-mlp': !userHasMlp }">
        <Loading v-if="pageLoading" :loading="pageLoading" />
        <div class="vau user-search" @click="focusInput">
            <FontAwesomeIcon :icon="faMagnifyingGlass" />
            <span class="user-search-input-container">
                <input
                    ref="usersearch"
                    :value="searchText"
                    class="user-search-input"
                    type="text"
                    @input="(e) => (searchText = e.target.value)"
                />
                <Typography
                    v-show="!searchText"
                    class="user-search-placeholder"
                    variant="overline"
                >
                    Search...
                </Typography>
            </span>
        </div>
        <template v-for="user in filteredUsers" :key="user.id">
            <div class="vau" @click="viewAsSomeoneElse(user.id)">
                <div class="vau-content">
                    <Typography class="vau-text user-name" variant="overline">{{
                        user.name
                    }}</Typography>
                    <Typography class="vau-text user-type" variant="body2">{{
                        user.usertypeName
                    }}</Typography>
                    <div class="vau-icon">
                        <FontAwesomeIcon
                            v-if="user.id === userId"
                            :icon="faCircleCheck"
                        />
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script setup lang="ts">
import Loading from '@/components/Loading.vue'
import { Ref, computed, onMounted, ref } from 'vue'
import { listSwitchUsers, switchUser } from '@/mobile/src/api/auth'
import Typography from '@/components/Rain/Typography/Typography.vue'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import {
    faCircleCheck,
    faMagnifyingGlass,
} from 'fontawesome/free-solid-svg-icons'
import store from '@/mobile/src/store'
import router from '@/mobile/src/router'

const pageLoading = ref(false)
const viewAsUsers: Ref<any[]> = ref([])
const searchText = ref('')
const usersearch: Ref<HTMLElement | null> = ref(null)

onMounted(async () => {
    pageLoading.value = true
    const result = await listSwitchUsers()
    viewAsUsers.value.push(...result.data.users)
    pageLoading.value = false
})

const userId = computed(() => store.getters['userId'])
const userHasMlp = computed(() => store.getters['userHasMlp'])
const filteredUsers = computed(() => {
    if (!searchText.value) {
        return viewAsUsers.value
    }
    return viewAsUsers.value.filter((user) => {
        const lowerName = user.name.toLowerCase()
        const lowerSearch = searchText.value.toLowerCase()
        const lowerIndex = lowerName.indexOf(lowerSearch)
        return lowerIndex === 0
    })
})

function focusInput() {
    usersearch.value?.focus()
}

async function viewAsSomeoneElse(toUserId: string | number) {
    pageLoading.value = true
    await switchUser(userId.value === toUserId ? '_exit' : toUserId)
    await router.push({ name: userHasMlp.value ? 'evenmore' : 'more' })
    window.location.reload()
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/variables.less';
@import '~@/styles/rain/colour.less';
@import '~@/styles/variables.less';

.view-as-container {
    --text-color: @white;
    --view-gutter: calc(@featureGutter * -1);
    --thin-border: @thinFog;

    &.no-mlp {
        --text-color: @black;
        --view-gutter: none;
        --thin-border: @black;
    }

    margin-left: var(--view-gutter, @featureGutter);
    .user-search {
        display: flex;
        position: relative;
        background-color: rgba(217, 217, 217, 0.1);
        color: var(--text-color, @white);

        &-input-container {
            padding-left: 5px;
            position: relative;
            width: 100%;
        }

        &-input {
            background: transparent;
            border: none;
            caret-color: var(--text-color, @white);
            color: var(--text-color, @white);
            width: 100%;

            &:focus {
                outline: none;
            }
        }

        &-placeholder {
            padding-left: 10px;
            pointer-events: none;
            position: absolute;
            color: var(--text-color, @white);
            opacity: 0.6;
            left: 0;
            transform: translateY(-100%);
        }
    }

    .vau {
        border-bottom: 1px solid var(--thin-border, @thinFog);
        box-sizing: border-box;
        width: 100vw;
        padding: 20px 20px;

        &-content {
            display: flex;
            gap: @gutterSpacing-md;
            flex-wrap: nowrap;
        }

        &-text {
            flex: 1;
            color: var(--text-color, @white);
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        &-icon {
            color: var(--text-color, @white);
            text-align: center;
            flex-basis: 5%;
        }

        .user-type {
            text-align: right;
            opacity: 0.6;
        }
    }
}
</style>
