<template>
    <div class="uk-margin-top">
        <!-- header -->
        <div class="uk-margin-top">
            <span v-if="title">{{ title }}</span>
            <span
                v-if="disableHighlightingCount && !selectedCount"
                class="uk-float-right"
                >{{ selectedCount }} selected</span>
            <b v-else-if="selectedCount" class="uk-float-right">{{ selectedCount }} selected</b>
            <span v-else class="uk-float-right uk-text-danger">{{ selectedCount }} selected</span>
        </div>
        <div class="uk-margin-top check-container">
            <!-- list title -->
            <div
                class="head"
                :class="{ 'header-cursor': widerSelectionBox }"
                v-on="
                    widerSelectionBox ? { click: () => (opened = !opened) } : {}
                "
            >
                <Checkbox
                    v-if="selectAll"
                    :click="toggleCheckAll"
                    :label="
                        header + ' (' + selectedCount + '/' + totalCount + ')'
                    "
                    :partial="!!partialChecked"
                    :checked="partialChecked || allChecked"
                >
                </Checkbox>
                <div v-else>
                    <span>{{ header }}&nbsp;({{ selectedCount }}/{{
                            totalCount
                        }})</span>
                    <i
                        class="uk-float-right toggle-opened"
                        :class="{
                            'uk-icon-caret-right': !opened,
                            'uk-icon-caret-down': opened,
                        }"
                        v-on="
                            !widerSelectionBox
                                ? { click: () => (opened = !opened) }
                                : {}
                        "
                    ></i>
                </div>
            </div>
            <div v-if="opened && hasSearchBar" class="uk-form">
                <div class="uk-form-icon search-container">
                    <input
                        v-model="searchText"
                        type="text"
                        placeholder="Search"
                        class="search-input"
                    />
                    <i class="uk-icon-search"></i>
                </div>
            </div>
            <!-- list -->
            <div v-if="opened" class="items">
                <div
                    v-for="(name, id) in getSortedOptions"
                    :key="id"
                    class="item"
                >
                    <Checkbox
                        :id="id"
                        :label="getOptionLabel(name)"
                        :checked="shouldCheck(id)"
                        :click="toggleCheck"
                    >
                    </Checkbox>
                </div>
            </div>
        </div>
        <!-- add new data -->
        <div v-if="add">
            <div v-if="!adding" class="uk-margin-small-top">
                <a v-if="!newDataAdded" @click="adding = true">
                    <small>Add New Data</small>
                </a>
                <small v-else class="success-info">New Data Added Successfully!</small>
            </div>
            <div v-else class="uk-grid uk-margin-small-top">
                <div class="uk-width-3-4">
                    <Input
                        ref="newDataInput"
                        v-model="newData"
                        :title="'New ' + header + ' Name'"
                        :placeholder="'New ' + header + ' Name'"
                        :required="true"
                        :regex="regex"
                        :err-msg="'Invalid New ' + header + ' Name'"
                    />
                </div>
                <div class="uk-width-1-4 uk-padding-remove-left">
                    <button class="uk-button btn-add" @click="onAddData">
                        Add
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import Checkbox from './Checkbox.vue'
import Input from '@/components/Input.vue'

type Input = typeof Vue & { hasError: boolean; hasFocused: boolean }

@Component({
    components: {
        Checkbox,
        Input,
    },
    emits: ['toggle', 'add'],
})
export default class MultiCheckBox extends Vue {
    @Prop({ type: Array, default: () => [] }) public selected!: any[]
    @Prop({ type: Boolean, default: false }) public selectAll!: boolean
    @Prop({ type: Object, default: () => ({}) }) public options!: any
    @Prop({ type: Object, default: () => ({}) }) public optionsSuffix!: any
    @Prop({ type: Boolean, default: false }) public hasSearchBar!: boolean
    @Prop({ type: String, default: '' }) public title!: string
    @Prop({ type: String, default: '' }) public header!: string
    @Prop({ type: Boolean, default: false }) public add!: boolean
    @Prop({ type: Boolean, default: false }) public open!: boolean
    @Prop({ type: Boolean, default: false })
    public disableHighlightingCount!: boolean
    @Prop({ type: Boolean, default: false }) public widerSelectionBox!: boolean

    public adding = false
    public newData?: any = null
    public newDataAdded = false
    public opened = false
    public searchText = ''

    public mounted() {
        this.opened = this.open
    }

    public get optionsObj() {
        if (Array.isArray(this.options)) {
            const opt = {}
            this.options.forEach((v) => {
                opt[v] = v
            })
            return opt
        } else {
            return this.options
        }
    }

    public get getSortedOptions() {
        const options = {}

        Object.keys(this.optionsObj)
            .map((key) => ({ k: key, v: this.optionsObj[key] }))
            // handle search
            .filter(
                (item) =>
                    this.searchText.length === 0 ||
                    item.k
                        .toLocaleLowerCase()
                        .indexOf(this.searchText.toLowerCase()) >= 0
            )
            .sort((option1, option2) => (option1.v > option2.v ? 1 : -1))
            .forEach((option) => (options[option.k] = option.v))
        return options
    }

    public get selectedCount(): number {
        if (this.selected) {
            return this.selected.length
        } else {
            return 0
        }
    }

    public get ids() {
        return Object.keys(this.optionsObj)
    }

    public get totalCount(): number {
        return this.ids.length
    }

    public get partialChecked(): boolean {
        return this.selectedCount > 0 && this.selectedCount < this.totalCount
    }

    public get allChecked(): boolean {
        return this.totalCount > 0 && this.selectedCount === this.totalCount
    }

    // new regex to validate data
    public get regex() {
        if (!this.totalCount) {
            return null
        } else {
            if (
                this.newData &&
                Object.values(this.optionsObj).indexOf(this.newData) > -1
            ) {
                return new RegExp('^$') // make it invalid
            } else {
                return null
            }
        }
    }

    public getOptionLabel(k) {
        return `${k} ${
            this.optionsSuffix && this.optionsSuffix[k]
                ? this.optionsSuffix[k]
                : ''
        }`
    }

    public toggleCheckAll() {
        if (this.allChecked) {
            this.resetCheckboxes()
        } else {
            this.setCheckboxes()
        }
    }

    public resetCheckboxes() {
        const newSelected = []
        if (newSelected.length !== this.selectedCount) {
            this.$emit('toggle', newSelected)
        }
    }

    public setCheckboxes() {
        const newSelected = this.ids
        if (newSelected.length !== this.selectedCount) {
            this.$emit('toggle', newSelected)
        }
    }

    public toggleCheck(id) {
        const newSelected = this.selected as any[]
        if (newSelected.includes(id)) {
            const i = newSelected.indexOf(id)
            newSelected.splice(i, 1)
            this.$emit('toggle', newSelected)
        } else {
            newSelected.push(id)
            this.$emit('toggle', newSelected)
        }
    }

    public shouldCheck(id): boolean {
        if (!this.selectedCount) {
            return false
        } else {
            const curr = this.selected as any[]
            return curr.includes(id)
        }
    }

    public onAddData() {
        const inputEl = this.$refs.newDataInput as Input
        if (this.newData && !inputEl.hasError) {
            this.$emit('add', this.newData)
            // select new data by default
            this.toggleCheck(this.newData)
            this.newData = null
            this.adding = false
            this.newDataAdded = true

            setTimeout(() => {
                this.newDataAdded = false
            }, 1000)
        }
    }
}
</script>

<style scoped lang="less">
@import '../styles/palette';

.check-container {
    border: 1px solid @borderGrey;
    border-radius: 3px;

    .head {
        padding: 8px 12px;
        border-bottom: 1px solid @borderGrey;
        background: #fafafa;
        position: relative;
    }

    .items {
        max-height: 110px;
        overflow-y: scroll;

        .item {
            padding: 8px 12px;
        }
    }
}

.btn-add {
    height: 48px;
    margin-top: 15px;
}

.success-info {
    color: @green;
}

.toggle-opened {
    padding: 10px;
    position: absolute;
    top: 0;
    right: 5px;
    cursor: pointer;
}

.items::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}
.items::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.search-container {
    width: calc(100% - 50px);
    margin: 8px 12px;

    .search-input {
        width: 100%;
    }
}
.header-cursor {
    cursor: pointer;
}
</style>
