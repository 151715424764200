import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4e674933"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vanilla-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "modal" }, {
    default: _withCtx(() => [
      (_ctx.show)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(['modal', { bg: _ctx.bg }])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["modal-wrapper", { shake: _ctx.shake }]),
              style: _normalizeStyle(`top: ${_ctx.top}%`),
              onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.clickOut && _ctx.clickOut(...args)), ["self"]))
            }, [
              _createElementVNode("div", {
                class: _normalizeClass([
                        _ctx.size,
                        {
                            'overflow-visible': _ctx.visibleIfOverflow,
                            'modal-container': !_ctx.isFull,
                            flex: _ctx.flex,
                        },
                    ]),
                style: _normalizeStyle(_ctx.styles)
              }, [
                (_ctx.vanilla)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createElementVNode("div", _hoisted_1, [
                        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                      ]),
                      (_ctx.hasClose)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: "close",
                            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
                          }, " × "))
                        : _createCommentVNode("", true)
                    ], 64))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      (_ctx.hasHeader && _ctx.title.length > 0)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(["header", { 'small-header': _ctx.smallHeader }])
                          }, [
                            (_ctx.hasClose)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 0,
                                  class: "close",
                                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
                                }, " × "))
                              : _createCommentVNode("", true),
                            _createElementVNode("h2", null, _toDisplayString(_ctx.title), 1)
                          ], 2))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", {
                        class: "content",
                        style: _normalizeStyle(_ctx.getContentStyle)
                      }, [
                        _renderSlot(_ctx.$slots, "default", {}, undefined, true),
                        (_ctx.isLarge && !_ctx.hasClose)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: "close",
                              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
                            }, " × "))
                          : _createCommentVNode("", true)
                      ], 4)
                    ], 64))
              ], 6)
            ], 6)
          ], 2))
        : _createCommentVNode("", true)
    ], undefined, true),
    _: 3
  }))
}