import { ActionTree, MutationTree, GetterTree } from 'vuex'

import {
    getActionsList,
    getWorkflowsList,
    addTagsToWorkflow,
    updateWorkflowListOrder,
    removeTagsToWorkflow,
} from '@/api/workflow'
import {
    Workflow,
    Action,
    WorkflowTagAction,
    WorkflowTagsCount,
} from '@/entities/workflow'

export interface WorkflowsState {
    items: Workflow[]
    actions: Action[]
    workflowsLoading: boolean
    actionsLoading: boolean
    tagCounts: WorkflowTagsCount
    activeTag: string
    searchInput: string
}

export const state: WorkflowsState = {
    items: [],
    actions: [],
    workflowsLoading: false,
    actionsLoading: false,
    tagCounts: {
        all: 0,
    },
    activeTag: 'all',
    searchInput: '',
}

export const readOnlyTags: string[] = ['all', 'active', 'inactive']

const getters: GetterTree<WorkflowsState, any> = {
    items({ items }) {
        return items
    },
    actions({ actions }) {
        return actions
    },
    workflowsLoading({ workflowsLoading }) {
        return workflowsLoading
    },
    actionsLoading({ actionsLoading }) {
        return actionsLoading
    },
    tagCounts({ tagCounts }) {
        return tagCounts
    },
    activeTag({ activeTag }) {
        return activeTag
    },
    readOnlyTags() {
        return readOnlyTags
    },
    searchInput({ searchInput }) {
        return searchInput
    },
}

const actions: ActionTree<WorkflowsState, any> = {
    async loadItems({ commit, dispatch }) {
        commit('setWorkflowsLoading', true)
        const { data: workflows } = await getWorkflowsList()
        commit('setItems', workflows.data)
        dispatch('updateTagCount', workflows.data)
        commit('setWorkflowsLoading', false)
    },
    async addTagsToWorkflow({ dispatch }, tagAction: WorkflowTagAction) {
        const { data } = await addTagsToWorkflow(
            tagAction.workflowId,
            tagAction.tags
        )
        tagAction.tags = data.data
        dispatch('updateWorkflowTags', tagAction)
    },
    async removeTagsToWorkflow({ dispatch }, tagAction: WorkflowTagAction) {
        const { data } = await removeTagsToWorkflow(
            tagAction.workflowId,
            tagAction.tags
        )
        tagAction.tags = data.data
        dispatch('updateWorkflowTags', tagAction)
    },
    updateWorkflowTags(
        { commit, state, dispatch },
        tagAction: WorkflowTagAction
    ) {
        const workflows = [...state.items]
        const workflowIndex = workflows.findIndex(
            (workflow) => workflow.id == tagAction.workflowId
        )
        if (workflowIndex !== -1) {
            workflows[workflowIndex].tags = tagAction.tags
            commit('setItems', workflows)
            dispatch('updateTagCount', workflows)
        }
    },
    updateSearchInput({ commit }, searchInput) {
        commit('setSearchInput', searchInput)
    },
    updateTagCount({ commit, getters }, workflows: Workflow[]) {
        // Only show 'All' number based on search input filter
        let allCount = getters.items?.length
        if (getters.searchInput?.length) {
            allCount = getters.items.filter((workflow) => {
                return workflow.name
                    .toLowerCase()
                    .includes(getters.searchInput.toLowerCase())
            })?.length
        }

        const tagCounts: WorkflowTagsCount = {
            all: allCount,
            active: 0, // so active will always show after all in FE
            inactive: 0,
        }
        for (const workflow of workflows) {
            for (const tag of workflow.tags) {
                if (!(tag in tagCounts)) {
                    tagCounts[tag] = 0
                }
                tagCounts[tag]++
            }
        }

        if (tagCounts.active === 0) {
            // No active workflows so remove active tag
            delete tagCounts.active
        }
        if (tagCounts.inactive === 0) {
            // No inactive workflows so remove active tag
            delete tagCounts.inactive
        }

        commit('setTagCounts', tagCounts)
    },
    async loadActions({ commit }) {
        commit('setActionsLoading', true)
        const { data: actions } = await getActionsList()
        commit('setActions', actions.data)
        commit('setActionsLoading', false)
    },
    async setItems({ commit }, items) {
        commit('setItems', items)
        await updateWorkflowListOrder(items.map((item) => item.id))
    },
    setActiveTag({ commit }, tag: string) {
        commit('setActiveTag', tag)
    },
}

const mutations: MutationTree<WorkflowsState> = {
    setItems(state, items) {
        state.items = items
    },
    setActions(state, actions) {
        state.actions = actions
    },
    setWorkflowsLoading(state, loaded) {
        state.workflowsLoading = loaded
    },
    setActionsLoading(state, loaded) {
        state.actionsLoading = loaded
    },
    setTagCounts(state, tagCounts: WorkflowTagsCount) {
        state.tagCounts = tagCounts
    },
    setActiveTag(state, activeTag) {
        state.activeTag = activeTag
    },
    setSearchInput(state, searchInput) {
        state.searchInput = searchInput
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
