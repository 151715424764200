<template>
    <TextBadge
        :text="noticeTypeObj.name"
        :background-colour="noticeTypeObj.backgroundColor"
        :text-colour="noticeTypeObj.color"
    />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { palette } from '@/utils/palette'
import { PredefinedNoticeTypes } from '@/utils/notices'
import TextBadge from '@/components/TextBadge.vue'

@Component({
    components: { TextBadge },
})
export default class NoticeTypeBadge extends Vue {
    @Prop({ type: String, required: false }) private noticeType!: string

    get noticeTypeObj() {
        const noticeTypeObj = PredefinedNoticeTypes.find(
            (type) => type.name === this.noticeType
        )
        if (noticeTypeObj) {
            return noticeTypeObj
        }
        return {
            name: 'Unknown notice type',
            color: palette.black,
            backgroundColor: palette.white,
        }
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/variables.less';

.notice-type {
    padding: 4px 8px;
    border-radius: @borderRadius-base;
    text-transform: capitalize;
}
</style>
