import UrlPattern from 'url-pattern'
import { parseDeepLinkUrl } from '@/mobile/src/services/auth'
import router from '@/mobile/src/router'
import { isLocal } from '@/mobile/src/utils/misc'
import { me } from '@/mobile/src/api/auth'

export type DeepLinkType =
    | 'chat-view'
    | 'dash-view'
    | 'magic-login'
    | 'password-activation'
    | 'contact-method-validation'
    | 'notices-view'
    | 'no match'

export const patternForDomain = new UrlPattern(
    /^(.*)\.(asknice\.ly|asknicely\.site)$/,
    ['domain', '_host']
)

export async function processDeepLink(url: string) {
    // We need to have different deeplink routing as there are some users still on the old version of our app
    let hasAppV4 = false
    await me().then((data) => {
        if (data?.data?.userHasMlp) {
            hasAppV4 = true
        }
    })

    const deepLinkType = findDeepLinkType(url)

    const routeNameMap = {
        'magic-login': 'magiclogin',
        'password-activation': 'activation',
        'contact-method-validation': 'validation',
        'notices-view': 'notices',
        'chat-view': 'chatResponsesRoot',
    }
    const targetRouteName = routeNameMap[deepLinkType]

    switch (deepLinkType) {
        case 'magic-login':
        case 'password-activation':
        case 'contact-method-validation':
            // ignore if url is duplicated
            if (
                targetRouteName === router.currentRoute.value.name &&
                url === router.currentRoute.value.query.url
            ) {
                break
            }

            await router.replace({ name: targetRouteName, query: { url } })
            break
        case 'notices-view': {
            // ignore if url is duplicated
            const parsed = parseDeepLinkUrl(url)
            const messageId = parsed.id ? parsed.id : parsed.query.noticeId
            if (
                targetRouteName === router.currentRoute.value.name &&
                messageId === router.currentRoute.value.params.messageId
            ) {
                break
            }

            if (hasAppV4 && messageId) {
                await router.replace({
                    name: 'momentdetails',
                    params: { momentid: messageId },
                })
            } else if (hasAppV4) {
                await router.replace({
                    name: 'inbox',
                })
            } else {
                await router.replace({
                    name: targetRouteName,
                    params: { messageId },
                })
            }

            break
        }
        case 'chat-view': {
            // ignore if url is duplicated
            const parsed = parseDeepLinkUrl(url)
            const id = parsed.id ? parsed.id : parsed.query.chat
            if (
                targetRouteName === router.currentRoute.value.name &&
                id === router.currentRoute.value.params.id
            ) {
                break
            }

            if (hasAppV4) {
                await router.replace({
                    name: 'feedbackdetail',
                    params: { feedbackid: id },
                })
            } else {
                await router.replace({
                    name: targetRouteName,
                    params: { id },
                })
            }

            break
        }
    }
}

function findDeepLinkType(url: string): DeepLinkType {
    return parseDeepLinkUrl(url).type
}

if (isLocal) {
    // expose this in local, so cypress test can simulate app link click from email/sms, etc
    window.cypressTest = { ...window.cypressTest, processDeepLink }
}
