import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fe943dfc"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["disabled", "checked", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InfoTooltip = _resolveComponent("InfoTooltip")!

  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(['container', { disabled: _ctx.disabled }])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: "label",
          innerHTML: _ctx.label
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      type: "checkbox",
      disabled: _ctx.disabled,
      checked: _ctx.checked,
      value: _ctx.value,
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
    }, null, 8, _hoisted_2),
    _createElementVNode("span", {
      class: _normalizeClass(["checkmark", { partial: _ctx.partial }])
    }, null, 2),
    (_ctx.tooltip)
      ? (_openBlock(), _createBlock(_component_InfoTooltip, {
          key: 1,
          class: "tooltip"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.tooltip), 1)
          ], undefined, true),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 2))
}