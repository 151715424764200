<template>
    <TransitionGroup name="loading" tag="div">
        <EmptyPageHome
            v-if="!monthTopics && showEmptyTopics && !isLoading"
            key="empty-page"
            :message="emptyTopicMessage"
            topic-carousel
            class="empty-state"
            bordered
        />
        <ErrorPage
            v-else-if="topicFeedbackError"
            key="error-page"
            class="empty-state"
            bordered
        />

        <div
            v-else-if="!isLoading"
            key="topic-feedback"
            class="topic-feedback-wrapper"
        >
            <TopicFeedbackItem
                v-if="showFirstTopicItem"
                :topic-item="showFirstTopicItem"
                :is-report-card="isReportCard"
            />
            <div
                v-if="!showAllTopics && hasMultipleTopics && hasOtherTopics"
                class="show-all"
            >
                <PillButton
                    class="is-v4-inverted"
                    label="Show All Topics"
                    @click="toggleShowAll"
                />
            </div>
            <TopicFeedbackItem
                v-for="(feedback, index) in otherTopics"
                v-show="showAllTopics && feedback"
                :key="index"
                :topic-item="feedback"
                :is-report-card="isReportCard"
            />
            <TopicFeedbackItem
                v-if="showLastTopicItem && lastTopicItem"
                :topic-item="lastTopicItem"
                :is-report-card="isReportCard"
            />
            <div v-if="showAllTopics && hasOtherTopics" class="show-all">
                <PillButton
                    class="is-v4-inverted"
                    label="Show Fewer Topics"
                    @click="toggleShowAll"
                />
            </div>
        </div>

        <div v-else key="loading" class="topic-feedback-wrapper">
            <LoadingCard />
            <LoadingCard variant="pill" />
            <LoadingCard />
        </div>
    </TransitionGroup>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from 'vue-facing-decorator'
import { namespace } from 'vuex-facing-decorator'
import { pendoTrackEvent } from '@/utils/pendo'
import { TopicFeedback } from '@/entities/topicfeedback'
import TopicFeedbackItem from '@/mobile/src/components/appV4/TopicFeedbackItem.vue'
import PillButton from '@/components/PillButton.vue'
import LoadingCard from '@/mobile/src/components/appV4/LoadingCard.vue'
import EmptyPageHome from '@/mobile/src/components/appV4/EmptyPageHome.vue'
import ErrorPage from '@/mobile/src/components/appV4/ErrorPage.vue'

const topicfeedbackModule = namespace('topicfeedback')

@Component({
    components: {
        ErrorPage,
        EmptyPageHome,
        LoadingCard,
        PillButton,
        TopicFeedbackItem,
    },
})
export default class TopicFeedbackCarousel extends Vue {
    @Prop({ type: Number, required: false }) public month!: number
    @Prop({ type: Boolean, required: false, default: false }) isReportCard
    @Prop({ type: Boolean, default: true }) public showEmptyTopics
    @Prop({ type: Boolean, default: false }) public readonly showFavouritesFirst

    @topicfeedbackModule.Getter topicFeedback
    @topicfeedbackModule.Getter topicFeedbackError!: boolean
    @topicfeedbackModule.Getter emptyTopicData!: boolean
    @topicfeedbackModule.Getter emptyTopicMessage
    @topicfeedbackModule.Getter isLoading!: boolean

    @topicfeedbackModule.Action
    getTopicFeedback!: ({ month, sortFavouritesFirst }) => Promise<void>

    showAllTopics = false

    get showFirstTopicItem() {
        return !this.monthTopics || !this.monthTopics[0]
            ? null
            : this.monthTopics[0]
    }

    get showLastTopicItem() {
        if (!this.monthTopics || this.monthTopics.length <= 1) {
            return false
        }

        return !!this.lastTopicItem
    }

    get lastTopicItem() {
        return this.monthTopics[this.monthTopics.length - 1]
    }

    get otherTopics() {
        if (this.monthTopics && this.monthTopics.length > 2) {
            return this.monthTopics.slice(1, this.monthTopics.length - 1)
        } else {
            return []
        }
    }

    async created() {
        await this.loadData()
    }

    @Watch('month')
    public async onMonthChanged() {
        await this.loadData()
    }

    private async loadData() {
        await this.getTopicFeedback({
            month: this.month,
            sortFavouritesFirst: this.showFavouritesFirst,
        })
    }

    public toggleShowAll() {
        const pendoEvent = !this.showAllTopics
            ? 'topic_feedback_show_all'
            : 'topic_feedback_show_less'
        pendoTrackEvent(pendoEvent)
        this.showAllTopics = !this.showAllTopics
    }

    get monthTopics(): TopicFeedback[] {
        return this.topicFeedback(this.month)
    }

    get hasMultipleTopics() {
        return this.monthTopics && this.monthTopics.length > 1
    }

    get hasOtherTopics() {
        return this.otherTopics && this.otherTopics.length >= 1
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/variables.less';
@import '~@/mobile/src/styles/animations.less';

.topic-feedback-wrapper {
    display: flex;
    flex-direction: column;
    gap: @featureGutter;
}

.show-all {
    width: 100%;
    text-align: center;
}

.empty-state {
    margin-top: @featureGutter;
}

.loading-card-animations();
</style>
