import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PushPopTransitionContainer = _resolveComponent("PushPopTransitionContainer")!
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createBlock(_component_RouterView, null, {
    default: _withCtx(({ Component }) => [
      _createVNode(_component_PushPopTransitionContainer, null, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_KeepAlive, null, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
          ], 1024))
        ], undefined, true),
        _: 2
      }, 1024)
    ]),
    _: 1
  }))
}