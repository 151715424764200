<template>
    <div class="nps-bar">
        <div class="nb-bar nb-background"></div>
        <div
            class="nb-bar nb-nps"
            :style="{
                left: deltaPositionRelative + '%',
                width: deltaWidthPercent + '%',
                background: npsColor,
            }"
        ></div>
        <div class="nb-average" :style="{ left: middlePercent + '%' }"></div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component({})
export default class NPSBar extends Vue {
    @Prop({ type: Number, required: true }) public nps!: number
    @Prop({ type: Number, required: true }) public min!: number
    @Prop({ type: Number, required: true }) public max!: number
    @Prop({ type: Number, required: true }) public average!: number

    public get range(): number {
        return this.max - this.min
    }

    public get middleRelative() {
        return this.average - this.min
    }

    public get middlePercent(): number {
        const value =
            this.range > 0 ? (this.middleRelative / this.range) * 100 : 50
        return Math.max(0, Math.min(100, value))
    }

    public get delta() {
        return this.nps - this.average
    }

    public get deltaPercent() {
        return this.range > 0 ? (this.delta / this.range) * 100 : 0
    }

    public get deltaWidthPercent() {
        const value = Math.abs(this.deltaPercent)
        return Math.max(0, Math.min(value, 100))
    }

    public get deltaPositionRelative() {
        let value
        if (this.deltaPercent < 0) {
            value = this.middlePercent + this.deltaPercent
        } else {
            value = this.middlePercent
        }
        return Math.max(0, Math.min(100, value))
    }

    public get npsColor() {
        if (this.nps > this.average) {
            return '#D5DEFF'
        } else {
            return '#FCC3A8'
        }
    }
}
</script>

<style lang="less" scoped>
@import '../styles/palette';

.nps-bar {
    height: calc(
        100% + 4px
    ); // Height to allow for NPS average bar + 4px to accomodate table borders
    width: 100%;
    position: relative;

    .nb-bar {
        width: 100%;
        height: 6px;
        border-radius: 2px;

        position: absolute;
        top: 50%; // Vertically center the NPS bar
        transform: translateY(-50%);

        background: @greyLight;
    }

    .nb-average {
        background: #e7ecff;
        width: 2px;
        height: calc(100% + 4px);

        position: absolute;
    }

    @media print {
        -webkit-print-color-adjust: exact !important;
    }
}
</style>
