<template>
    <BasePillButton
        class="pill-button-with-icon"
        :is-active="isActive"
        :disabled="disabled"
        @click="onClick"
    >
        {{ formattedLabel }}
    </BasePillButton>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import BasePillButton from '@/components/BasePillButton.vue'

@Component({
    components: {
        BasePillButton,
    },
    emits: ['click'],
})
export default class PillButtonWithIcon extends Vue {
    @Prop({
        type: String,
        required: true,
    })
    readonly label!: string

    @Prop({
        type: Boolean,
        required: false,
        default: false,
    })
    readonly isActive!: boolean

    @Prop({
        type: Boolean,
        required: false,
        default: false,
    })
    readonly disabled!: boolean

    get formattedLabel() {
        return this.label.toLocaleUpperCase()
    }

    onClick() {
        if (this.disabled) {
            return
        }
        this.$emit('click')
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/palette';
@import '~@/styles/typography';

.pill-button-with-icon {
    position: relative;
    padding-right: 1.825rem;

    &::after {
        content: '';
        height: 14px;
        width: 14px;
        background-size: contain;
        right: 8px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}
</style>
