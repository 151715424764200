import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2daaba94"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }
const _hoisted_2 = { class: "time-range" }
const _hoisted_3 = { class: "coaching-topic-feedback-responses" }
const _hoisted_4 = { class: "feedback-item" }
const _hoisted_5 = {
  key: 0,
  class: "loading"
}
const _hoisted_6 = {
  key: 1,
  class: "no-more-results"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InsightsTimePickerMobile = _resolveComponent("InsightsTimePickerMobile")!
  const _component_ScorecardResponseTile = _resolveComponent("ScorecardResponseTile")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_SimpleSpinner = _resolveComponent("SimpleSpinner")!
  const _component_Scrollable = _resolveComponent("Scrollable")!
  const _component_WebViewScreen = _resolveComponent("WebViewScreen")!

  return (_openBlock(), _createBlock(_component_WebViewScreen, {
    "no-pad": "",
    "disable-when-offline": "",
    "show-go-back": ""
  }, {
    "custom-title": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.getFeedbackLabel), 1),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.friendlyTimeRange), 1)
    ]),
    "right-button": _withCtx(() => [
      _createVNode(_component_InsightsTimePickerMobile, {
        "time-range": _ctx.timeRange,
        onSelectTime: _ctx.updateTimeRange
      }, null, 8, ["time-range", "onSelectTime"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Scrollable, { "on-scroll-end": _ctx.loadMoreResponses }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.responses, (response) => {
              return (_openBlock(), _createBlock(_component_RouterLink, {
                key: response.id,
                to: {
                        name: 'chatResponsesRoot',
                        params: { id: response.id },
                    }
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_ScorecardResponseTile, {
                      response: response,
                      scorecard: _ctx.scorecard,
                      class: "coaching-topic-review-response"
                    }, null, 8, ["response", "scorecard"])
                  ])
                ], undefined, true),
                _: 2
              }, 1032, ["to"]))
            }), 128)),
            (_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_SimpleSpinner, {
                    size: "small",
                    class: "spinner",
                    "line-fg-color": "#B338A3"
                  })
                ]))
              : _createCommentVNode("", true),
            (!_ctx.loading && _ctx.resultsDepleted[_ctx.key])
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, " no more results "))
              : _createCommentVNode("", true)
          ])
        ], undefined, true),
        _: 1
      }, 8, ["on-scroll-end"])
    ], undefined, true),
    _: 1
  }))
}