import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingCard = _resolveComponent("LoadingCard")!
  const _component_AvatarOrInitials = _resolveComponent("AvatarOrInitials")!

  return (_openBlock(), _createElementBlock("div", {
    class: "profile-picture",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateProfilePicture && _ctx.updateProfilePicture(...args)))
  }, [
    (_ctx.isUploading)
      ? (_openBlock(), _createBlock(_component_LoadingCard, {
          key: 0,
          variant: "circle"
        }))
      : (_openBlock(), _createBlock(_component_AvatarOrInitials, {
          key: 1,
          size: _ctx.avatarSize,
          user: _ctx.avatarUser
        }, null, 8, ["size", "user"]))
  ]))
}