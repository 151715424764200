<template>
    <ScorecardSubpage
        :slide="slideName"
        sticky
        :show-header="!embedded"
        title="Your Customers Suggest"
    >
        <div v-if="scorecard" class="improve-item-container">
            <div
                v-for="(entry, idx) in sortedBadges"
                :key="idx"
                class="improve-item"
                @click="goRoomToImproveDetail(entry)"
            >
                <div class="content">
                    <div class="title">{{ entry[1].label || entry[0] }}</div>
                    <div class="mentions">
                        {{ entry[1].count }} mention{{
                            entry[1].count > 1 ? 's' : ''
                        }}
                        <span v-if="entry[1].countUnseen" class="count">
                            +{{ entry[1].countUnseen }}
                        </span>
                        <div
                            v-if="entry[1].percentage"
                            :class="{ 'mentions-label': idx === 0 }"
                        >
                            {{ entry[1].percentage }}% of detractor & passive
                            feedback
                        </div>
                    </div>
                </div>
                <div class="arrow">
                    <svg
                        width="8"
                        height="14"
                        viewBox="0 0 8 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                        <rect
                            opacity="0.2"
                            width="8"
                            height="14"
                            transform="matrix(-1 0 0 1 8 0)"
                            fill="url(#pattern0)"
                        />
                        <defs>
                            <pattern
                                id="pattern0"
                                patternContentUnits="objectBoundingBox"
                                width="1"
                                height="1"
                            >
                                <use
                                    xlink:href="#image0"
                                    transform="scale(0.125 0.0714286)"
                                />
                            </pattern>
                            <image
                                id="image0"
                                width="8"
                                height="14"
                                xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAOCAYAAAASVl2WAAAAmElEQVQoFWNkwAJUeGxFWTg5tv9n+P+dCV0eLMnFsZ+BkcGYgYGBgxFZAVySgUGbgYHh6p9vPxzhCrBJ3vly+DVYAS5JkOmM+CRBCphArmVAshNkLLK7MHyBLAk24c/3H54gF4NMYeHi2A+yElkRYUfCVONyLDwcQAqxKUJRAFKkzGMlxsrFvQ/ss/8MZzEUwBVxcm8DRRYAiUZTfr5p+UMAAAAASUVORK5CYII="
                            />
                        </defs>
                    </svg>
                </div>
            </div>
        </div>
        <Loading :loading="loading" />
    </ScorecardSubpage>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { useRouter } from 'vue-router'
import Loading from '@/components/Loading.vue'
import ScorecardSubpage from '@/pages/scorecard/components/ScorecardSubpage.vue'
import { Action, Getter } from 'vuex-facing-decorator'
import { ScorecardEntity } from '@/entities/scorecard'
import { sleep } from '@/utils/async'
import { embedded, postMessageToRNWebView } from '@/utils/react-native-webview'

@Component({
    components: { ScorecardSubpage, Loading },
})
export default class ScorecardRoomToImproveList extends Vue {
    @Prop({ type: String, default: null })
    public teamMemberUserId!: string

    @Getter public slide!: string
    @Getter public scorecard!: ScorecardEntity
    @Getter public scorecardOutdated!: boolean
    @Getter public scorecardLoading!: boolean

    @Action public loadScorecard
    @Action public setSlide

    private router = useRouter()

    public slideName = 'left'

    public beforeMount() {
        this.slideName = this.slide
    }

    public async mounted() {
        if (this.scorecardOutdated) {
            await this.loadScorecard(this.teamMemberUserId ?? null)
        }
    }

    // listen to willFocus event from webview if any
    public created() {
        window.addEventListener('willFocus', this.onReloadData)
    }

    public destroyed() {
        window.removeEventListener('willFocus', this.onReloadData)
    }

    public async onReloadData() {
        await this.loadScorecard(this.teamMemberUserId ?? null)
    }

    public get loading() {
        return this.scorecardLoading
    }

    public get sortedBadges() {
        return Object.entries(this.scorecard.badges.negative).sort(
            ([badge1, info1], [badge2, info2]) => info2.count - info1.count
        )
    }

    public get embedded() {
        return embedded
    }

    public async goRoomToImproveDetail(entry) {
        this.setSlide('left')
        this.slideName = 'right'
        await sleep(10)

        if (embedded) {
            postMessageToRNWebView({
                routeName: 'RoomToImproveDetail',
                params: {
                    badge: entry[0],
                    label: entry[1].label,
                },
            })
        } else {
            if (this.teamMemberUserId) {
                await this.router.push({
                    name: 'scorecardRoomToImproveDetailUser',
                    params: {
                        value: entry[0],
                        teamMemberUserId: this.teamMemberUserId,
                    },
                })
            } else {
                await this.router.push({
                    name: 'scorecardRoomToImproveDetail',
                    params: { value: entry[0] },
                })
            }
        }
    }
}
</script>

<style scoped lang="less">
@import '~@/styles/layout.less';
@import '~@/styles/palette.less';
@import '~@/styles/rain/variables.less';

.improve-item-container {
    padding-top: @gutterSpacing-md;

    .improve-item {
        margin: 0 @gutterSpacing-md;
        padding: 20px;
        display: flex;
        align-items: center;
        background: white;
        cursor: pointer;
        .box();
        border-radius: @borderRadius-md;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.12);

        &:not(:first-child) {
            border-top: 1px solid #e0e7ff;
        }

        .content {
            flex: 1;

            .title {
                font-weight: bold;
                font-size: 16px;
                line-height: 20px;
                color: #2f384c;
            }

            .mentions {
                font-size: 13px;
                line-height: 20px;
                color: #93a1be;

                .mentions-label {
                    color: @orange;
                }
            }
        }

        .arrow {
        }

        .count {
            border-radius: 10px;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            background-color: #e74c3c;
            color: white;
            display: inline-block;
            min-width: 28px;
            text-align: center;
            margin-left: 5px;
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
            padding: 2px 1px;
        }
    }
}
</style>
