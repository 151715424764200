import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createBlock as _createBlock, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dcf7b514"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "container",
  class: "dialog"
}
const _hoisted_2 = { class: "avatar" }
const _hoisted_3 = {
  key: 0,
  class: "alert"
}
const _hoisted_4 = { class: "body" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "suggestion-link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AvatarOrInitials = _resolveComponent("AvatarOrInitials")!
  const _component_DialogBody = _resolveComponent("DialogBody")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AvatarOrInitials, {
        user: _ctx.user,
        size: 32
      }, null, 8, ["user"]),
      (_ctx.alert)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(['vertical-line', _ctx.getVerticalLineClass])
      }, null, 2)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.suggestionLink)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_DialogBody, {
                title: _ctx.title,
                subtitle: _ctx.subtitle,
                body: _ctx.body,
                action: _ctx.action,
                type: _ctx.type,
                created: _ctx.created,
                "can-delete": _ctx.canDelete,
                onDelete: _ctx.askDelete,
                onOpenDetails: _cache[0] || (_cache[0] = ($event: any) => (_ctx.router.push(_ctx.suggestionLink)))
              }, null, 8, ["title", "subtitle", "body", "action", "type", "created", "can-delete", "onDelete"])
            ])
          ]))
        : (_openBlock(), _createBlock(_component_DialogBody, {
            key: 1,
            title: _ctx.title,
            subtitle: _ctx.subtitle,
            body: _ctx.body,
            action: _ctx.action,
            type: _ctx.type,
            created: _ctx.created,
            "can-delete": _ctx.canDelete,
            onDelete: _ctx.askDelete
          }, null, 8, ["title", "subtitle", "body", "action", "type", "created", "can-delete", "onDelete"])),
      _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
    ])
  ], 512))
}