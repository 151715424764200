<template>
    <div>
        <div class="filter-bar">
            <div class="filter-mob-view">
                <FilterBarTime />
                <FilterBarNPSType :permissions="permissions" />
                <FilterBarQuestionType
                    v-if="enabledLeadWithAnyQuestion && !questionType"
                />
                <FilterBarLoader v-if="showButtons && !isInMobile" />
                <slot name="additionalFilter" />
            </div>
            <FilterBarActive />
            <FilterBarSecondary />
            <GeneralNPSReportDownload v-if="enabledGeneralReporting" />
            <FilterBarTVMenu v-if="showTV && !forceHideTv && showButtons" />
            <FilterAndDigestEditor :user-roles="userRoles" />
            <Toasts
                :toasts="statusToasts"
                @removeToast="statusRemoveToast($event)"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Watch, Prop, Vue } from 'vue-facing-decorator'
import { Action, Getter } from 'vuex-facing-decorator'
import FilterBarNPSType from './FilterBarNPSType.vue'
import FilterBarQuestionType from './FilterBarQuestionType.vue'
import FilterBarTime from './FilterBarTime.vue'
import FilterBarActive from './FilterBarActive.vue'
import FilterBarSecondary from './FilterBarSecondary.vue'
import FilterBarLoader from './FilterBarLoader.vue'
import FilterBarDownload from './FilterBarDownload.vue'
import FilterBarTVMenu from './FilterBarTVMenu.vue'
import FilterAndDigestEditor from './FilterAndDigestEditor.vue'
import GeneralNPSReportDownload from './GeneralNPSReportDownload.vue'
import Toasts from './Toasts.vue'
import { getBaseUserRoles } from '@/api/userRoles'
import { IUserType } from '@/entities/usertype'

@Component({
    components: {
        FilterBarTime,
        FilterBarNPSType,
        FilterBarQuestionType,
        FilterBarActive,
        FilterBarSecondary,
        FilterBarLoader,
        FilterBarDownload,
        FilterBarTVMenu,
        FilterAndDigestEditor,
        GeneralNPSReportDownload,
        Toasts,
    },
    emits: ['filterChanged'],
})
export default class FilterBar extends Vue {
    public showTV = false
    public showButtons = true
    public enabledLeadWithAnyQuestion = false
    public userRoles: IUserType[] = []

    @Prop({ type: Boolean, default: true })
    public shouldReloadOnChange!: boolean
    @Prop({ type: Boolean, default: false })
    public enabledExecutiveReporting!: boolean
    @Prop({ type: Boolean, default: false })
    public enabledGeneralReporting!: boolean
    @Prop({ type: Boolean, default: false }) public isInMobile!: boolean
    @Prop({ type: String, default: null }) public questionType!: string
    @Prop({ type: Array, default: () => [] }) public permissions!: string[]
    @Prop({ type: Boolean, default: false }) public forceHideTv!: boolean

    @Action public getFilters
    @Action public getScorecardTopics
    @Action public getDigests
    @Action public statusClearToasts
    @Action public setAppBaseUrl
    @Action public statusRemoveToast

    @Getter public statusToasts
    @Getter public filterLastPatch
    @Getter public details

    /**
     * This just reloads the page after applying a patch, it's a short-term solution
     * until the entire dashboard and responses pages are in Vue.
     * Note that we remove the url query, relying only on the filter stored in the db.
     */
    @Watch('filterLastPatch')
    public onFilterLastPatchChanged(time: number) {
        this.$emit('filterChanged')
        // for new dashboard, we don't need to reload, it's listening to the events and updating the data on page
        if (!this.shouldReloadOnChange) {
            return
        }
        // otherwise support legacy
        const currentUrl = [
            location.protocol,
            '//',
            location.host,
            '/',
            location.pathname.split('/').filter((n) => n !== '')[0],
            // Only keep first part of path, e.g: /dash or /responses to clear out legacy setters
        ].join('')
        window.location.href = currentUrl
    }

    public async mounted() {
        this.getFilters()
        this.getScorecardTopics()
        this.getDigests()
        this.statusClearToasts()

        const response = await getBaseUserRoles()

        if (response.success) {
            this.userRoles = response.data
        }

        /**
         * Any template data that is directly in the html
         * This can be removed later if this data is provided by an endpoint
         */
        const filterDataFromHtml = document.getElementById('filter-bar-v3-data')

        if (filterDataFromHtml) {
            this.showTV =
                filterDataFromHtml.getAttribute('data-show-tv') === '1'
            this.showButtons =
                filterDataFromHtml.getAttribute('data-show-buttons') === '1'
            this.enabledLeadWithAnyQuestion =
                filterDataFromHtml.getAttribute(
                    'data-has-lead-with-any-question'
                ) === '1'
            this.setAppBaseUrl(
                filterDataFromHtml.getAttribute('data-app-base-url')
            )
        }
    }
}
</script>

<style lang="less">
.ts-modal-c {
    margin: 0 auto;
    border-radius: 8px;
    max-height: 320px;
    overflow-y: scroll;

    // on mobile, adding more padding so it's easier to click by thumb
    .option-set .option {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.content .ts-modal-c {
    width: 95%;
}

.mobile-view-modal-content-margin {
    margin-top: 43px;
}
</style>

<style scoped lang="less">
@import '../styles/layout';
@import '../styles/palette';

.filter-bar:extend(.box) {
    color: @textColor;
    font-size: 14px;
    min-height: 50px;
    padding: 6px;
    justify-content: space-between;
    align-items: center;
    text-transform: capitalize;
    margin-bottom: 0; // fix wrong margin bottom inheritted
}

@media (min-width: 1281px) {
    .filter-bar:extend(.box) {
        display: flex;
    }
}

@media (max-width: 1280px) {
    .filter-mob-view {
        display: flex;
        justify-content: space-between;
    }
}
</style>

<style lang="less">
// These global styles keep consistency from a previous version of the code
// They apparently resolve some issues with styles leaking from the datepicker
// They just set box sizing to border-box on this element and every child
// element of this element
// Global styles are still necessary to reach further than direct children of
// this component
.filter-bar {
    box-sizing: border-box;

    &:before,
    &:after {
        box-sizing: border-box;
    }

    * {
        box-sizing: border-box;

        &:before,
        &:after {
            box-sizing: border-box;
        }
    }
}
</style>
