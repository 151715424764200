import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-20c24f5c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "action-button"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Typography = _resolveComponent("Typography")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['empty-state-container', _ctx.colour])
  }, [
    _renderSlot(_ctx.$slots, "icon-img", {}, undefined, true),
    _createVNode(_component_Typography, {
      variant: "h4",
      component: "h4"
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "header-text", {}, undefined, true)
      ], undefined, true),
      _: 3
    }),
    _createVNode(_component_Typography, {
      variant: "body2",
      class: "empty-page-text"
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "body-text", {}, undefined, true)
      ], undefined, true),
      _: 3
    }),
    (_ctx.hasButtonSlot)
      ? (_openBlock(), _createElementBlock("button", _hoisted_1, [
          _createVNode(_component_Typography, {
            variant: "overline",
            class: "action-button-text"
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "action-button", {}, undefined, true)
            ], undefined, true),
            _: 3
          })
        ]))
      : _createCommentVNode("", true)
  ], 2))
}