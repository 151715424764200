import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Typography = _resolveComponent("Typography")!
  const _component_PillButton = _resolveComponent("PillButton")!
  const _component_FeedbackMessageModal = _resolveComponent("FeedbackMessageModal")!
  const _component_MomentModal = _resolveComponent("MomentModal")!
  const _component_Portal = _resolveComponent("Portal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      key: "detail-content",
      class: "detail-content",
      style: _normalizeStyle({ 'margin-bottom': _ctx.tabHeight + 'px' })
    }, [
      _renderSlot(_ctx.$slots, "detailContent", { undoChat: _ctx.undoChat }, undefined, true)
    ], 4),
    (_ctx.hasNetworkConnection)
      ? (_openBlock(), _createElementBlock("div", {
          key: "send-button",
          class: "bottom",
          style: _normalizeStyle({ bottom: _ctx.tabHeight + 'px' })
        }, [
          (_ctx.modalSource === _ctx.feedbackDetail)
            ? (_openBlock(), _createBlock(_component_Typography, {
                key: 0,
                variant: "body2",
                class: "reply-text",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openMessageComposer()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Add a reply... ")
                ], undefined, true),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_PillButton, {
                key: 1,
                label: "New Message",
                class: "message-button",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openMessageComposer()))
              }))
        ], 4))
      : _createCommentVNode("", true),
    (_ctx.showPopup)
      ? (_openBlock(), _createBlock(_component_Portal, {
          key: "portal",
          to: "over-card-destination"
        }, {
          default: _withCtx(() => [
            (_ctx.modalSource === _ctx.feedbackDetail)
              ? (_openBlock(), _createBlock(_component_FeedbackMessageModal, {
                  key: 0,
                  "close-popup": () => _ctx.closeMessageComposer(),
                  "modal-source": _ctx.modalSource,
                  feedbackid: _ctx.feedbackid,
                  momentid: _ctx.momentid,
                  "init-as-reply": _ctx.forRestore,
                  "init-with-text": _ctx.forRestore ? _ctx.textForRestore : ''
                }, null, 8, ["close-popup", "modal-source", "feedbackid", "momentid", "init-as-reply", "init-with-text"]))
              : (_openBlock(), _createBlock(_component_MomentModal, {
                  key: 1,
                  "close-popup": () => _ctx.closeMessageComposer(),
                  "modal-source": _ctx.modalSource,
                  feedbackid: _ctx.feedbackid,
                  momentid: _ctx.momentid
                }, null, 8, ["close-popup", "modal-source", "feedbackid", "momentid"]))
          ], undefined, true),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}