<template>
    <div v-click-outside="clickOutside" :class="['dropdown', { opened }]">
        <div v-if="mode === 'click'" class="trigger" @click="toggle">
            <slot name="trigger"></slot>
        </div>
        <div v-if="mode === 'mouseover'" class="trigger" @mouseover="open">
            <slot name="trigger"></slot>
        </div>
        <transition name="fade">
            <div
                v-if="opened"
                ref="content"
                :class="[
                    'content',
                    { left, right: rightOverride || right },
                    modal ? 'modal-dd' : '',
                ]"
            >
                <slot name="content"></slot>
            </div>
        </transition>
    </div>
</template>

<script lang="ts">
import { nextTick } from 'vue'
import { Component, Prop, Watch, Vue } from 'vue-facing-decorator'
import clickOutside from '@/directives/clickOutside'

type Mode = 'click' | 'mouseover'

@Component({
    directives: {
        clickOutside,
    },
})
export default class Dropdown extends Vue {
    public opened = false

    @Prop({ type: Boolean, default: false }) public right?: boolean
    @Prop({ type: Boolean, default: false }) public left?: boolean
    @Prop({ type: Boolean, default: false }) public modal?: boolean
    @Prop({ type: Boolean, default: false }) public disabled?: boolean
    @Prop({ type: String, default: 'click' }) public mode?: Mode
    @Prop({ type: Function }) public onOpen!: () => any
    @Prop({ type: Boolean, default: false }) public closed!: boolean

    protected rightOverride = false

    @Watch('closed')
    public watchClosed() {
        this.opened = !this.closed
    }

    public open() {
        if (this.disabled) {
            return
        }
        this.opened = true
        if (this.onOpen) {
            this.onOpen()

            // required to get $refs content element within v-if
            // runs checkAlignment in the next render cycle
            nextTick(() => {
                this.checkAlignment()
            })
        }
    }

    public toggle() {
        if (this.opened) {
            this.opened = false
        } else {
            this.open()
        }
    }

    public checkAlignment() {
        const content = this.$refs.content as Element

        if (!content || !content.parentElement) {
            return
        }

        this.rightOverride =
            !this.left &&
            content.parentElement.offsetLeft + content.clientWidth >
                window.innerWidth
    }

    private clickOutside(e) {
        this.opened = false
    }
}
</script>

<style scoped lang="less">
.dropdown {
    position: relative;
    display: inline-block;
}
.trigger {
    cursor: pointer;
}
.dropdown-position() {
    position: absolute;
    z-index: 150;
}
.content {
    .dropdown-position;
    max-height: 450px;
    overflow-y: auto;
    border-radius: 3px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    background: white;

    .filter-bar & {
        margin-top: 10px;
    }
    .filter-bar-tv-menu {
        margin-top: 9px;
    }
    &.left {
        left: 0px;
    }
    &.right {
        right: 0px;
    }
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s;
    .dropdown-position;
}
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
    .dropdown-position;
}
.modal-dd {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    background: rgba(0, 0, 0, 0.5);
    min-height: 100vh;
    display: flex;
    align-items: center;
}
</style>
