import { IResponse } from '@/entities/response'

export interface EngagementQuestions {
    questions: EngagementQuestion[]
    unseenQuestions: boolean
    unansweredQuestions: boolean
}

export interface EngagementOption {
    label: string
    value: string
    icon: string
}

export enum EngagementQuestionTheme {
    Wellbeing = 'Wellbeing',
    Development = 'Development',
    Recognition = 'Recognition',
    Culture = 'Culture',
}

export enum EngagementQuestionThemeDescription {
    Wellbeing = 'How is your team feeling?',
    Development = 'How can you help your team grow?',
    Recognition = 'How motivated is your team?',
    Culture = 'How connected is your team?',
}

export enum EngagementQuestionType {
    FiveStar = 'fivestar',
    NPS = 'nps',
    YesNo = 'yes_no',
    Shoutout = 'shoutout',
    PositiveFeedback = 'positive_feedback',
    Multi = 'multi',
    Scorecard = 'scorecard',
}

export interface EngagementQuestion {
    question_id: number
    question: string
    question_type: EngagementQuestionType
    theme: EngagementQuestionTheme
    date: string
    answer_id: number
    answer?: string
    comment?: string
    opened_at?: string
    answered_at?: string
    // response field used for positive_feedback question_type
    response?: IResponse
    options?: EngagementOption[]
    variation?: 'stars' | 'faces'
}
