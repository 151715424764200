<template>
    <div class="container">
        <input
            :class="[variant, { value, error }]"
            :type="type"
            :autocomplete="autocomplete"
            :autocorrect="autocomplete"
            :autocapitalize="autocapitalize"
            :spellcheck="spellcheck"
            :value="value"
            :disabled="!!disabled"
            @focus="$emit('focus', $event)"
            @blur="$emit('blur', $event)"
            @input="$emit('input', $event.target.value)"
            @keyup.enter="$emit('enterpressed')"
            @change="$emit('change', $event.target.value)"
        />
        <span v-if="label" class="label label-spread">
            {{ label }}
            <FontAwesomeIcon
                v-if="fontAwesomeSources[fontAwesome]"
                :icon="fontAwesomeSources[fontAwesome]"
                class="font-awesome"
            />
        </span>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import {
    AllowedInputTypes,
    AutocompleteTypes,
    FontAwesomeTypes,
    VariantTypes,
} from '@/entities/rain/input-single-line'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import {
    faCheck,
    faChevronDown,
    faStar,
    faSearch,
} from 'fontawesome/free-solid-svg-icons'

const fontAwesomeSources = {
    faStar,
    faCheck,
    faChevronDown,
    faSearch,
}

@Component({
    components: {
        FontAwesomeIcon,
    },
    emits: ['focus', 'blur', 'input', 'enterpressed', 'change'],
})
export default class InputSingleLine extends Vue {
    @Prop({ type: String, required: false, default: 'text' })
    public readonly type!: AllowedInputTypes
    @Prop({ type: String, required: false, default: 'off' })
    public readonly autocomplete!: AutocompleteTypes
    @Prop({ type: String, required: false, default: '' })
    public readonly value!: string
    @Prop({ type: Boolean, required: false, default: false })
    public readonly disabled!: boolean
    @Prop({ type: Boolean, required: false, default: false })
    public readonly error!: boolean
    @Prop({ type: Boolean, required: false, default: true })
    public readonly spellcheck!: boolean
    @Prop({ type: String, required: false, default: 'on' })
    public readonly autocapitalize!: AutocompleteTypes
    @Prop({ type: String, required: false, default: '' })
    public readonly label!: string
    @Prop({ type: String, required: false, default: '' })
    public readonly fontAwesome!: FontAwesomeTypes
    @Prop({ type: String, required: false, default: '' })
    public readonly variant!: VariantTypes

    public fontAwesomeSources = fontAwesomeSources
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/inputSingleLine.less';

.label-spread {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
}
</style>
