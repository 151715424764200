<template>
    <div class="two-line-header">
        <div class="title">{{ title }}</div>
        <div class="sub-title">{{ subtitle }}</div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component
export default class TwoLineHeader extends Vue {
    @Prop({ type: String, required: true })
    title!: string

    @Prop({ type: String, required: true })
    subtitle!: string
}
</script>

<style scoped lang="less">
@import '~@/styles/typography';
@import '~@/styles/variables';

.two-line-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin: @gutterSpacing 0;
}

.title {
    .nav-bar-title();
}

.sub-title {
    .nav-bar-subtitle();
}
</style>
