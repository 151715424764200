<template>
    <span
        class="type-badge"
        :style="{ background: backgroundColour, color: textColour }"
    >
        {{ text }}
        <slot />
    </span>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { palette } from '@/utils/palette'

@Component({})
export default class TextBadge extends Vue {
    @Prop({ type: String, required: false }) private text!: string // Can use text or <slot />
    @Prop({ type: String, default: palette.purpleLight })
    private backgroundColour!: string // CSS colour string
    @Prop({ type: String, default: palette.black }) private textColour!: string // CSS colour string
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/variables.less';

.type-badge {
    padding: 4px 8px;
    border-radius: @borderRadius-base;
    text-transform: capitalize;
}
</style>
