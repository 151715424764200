<template>
    <EmptyPageState colour="white" :class="[bordered]">
        <template #icon-img>
            <FontAwesomeIcon :icon="inboxIcon" class="inbox-icon" />
        </template>
        <template #header-text>Inbox Empty</template>
        <template #body-text>
            You haven't received any messages yet. Please check back later.
        </template>
    </EmptyPageState>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faInbox } from 'fontawesome/free-solid-svg-icons'
import EmptyPageState from '@/mobile/src/components/EmptyPageState.vue'
import MultiMetricSelect from '@/mobile/src/components/appV4/MultiMetricSelect.vue'

@Component({
    components: {
        MultiMetricSelect,
        EmptyPageState,
        FontAwesomeIcon,
    },
})
export default class EmptyPageMoments extends Vue {
    @Prop({ type: Boolean, default: false })
    public bordered?: boolean

    private inboxIcon = faInbox
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';

.bordered {
    border: 1px dashed @white50;
    padding: @featureGutter;
    border-radius: @borderRadius-md;
}
.inbox-icon {
    font-size: 48px;
    color: white;
}
</style>
