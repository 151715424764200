import { ActionTree, MutationTree, GetterTree } from 'vuex'

import { getNotifications } from '@/api/notifications'

export interface NotificationState {
    notices: []
    notifications: string[]
    last_polled: number
}

export const state: NotificationState = {
    notices: [],
    notifications: [],
    last_polled: 0,
}

const actions: ActionTree<NotificationState, any> = {
    async loadNotifications(
        { commit },
        { last_polled, first_notification_id = null }
    ) {
        const { data } = await getNotifications(
            last_polled,
            first_notification_id
        )
        commit('setNotifications', { data })
    },

    removeNotification({ commit }, index: number) {
        commit('removeNotification', { index })
    },
    removeNotifications({ commit }) {
        commit('removeNotifications')
    },
    removeNotice({ commit }, index: number) {
        commit('removeNotice', { index })
    },
}

const mutations: MutationTree<NotificationState> = {
    setNotifications(state, { data }) {
        state.notices = data.notices
        state.notifications = state.notifications.concat(data.notifications)
        state.last_polled = data.last_polled
    },

    removeNotification(state, { index }: { index: number }) {
        state.notifications.splice(index, 1)
    },

    removeNotifications(state) {
        state.notifications = []
    },

    removeNotice(state, { index }: { index: number }) {
        state.notifications.splice(index, 1)
    },
}

const getters: GetterTree<NotificationState, any> = {
    getNotifications({ notifications }) {
        return notifications
    },
    getNotices({ notices }) {
        return notices
    },
}

export default {
    namespaced: false,
    state,
    actions,
    mutations,
    getters,
}
