<template>
    <WebViewScreen no-pad title="Add Recipients">
        <template #left-button>
            <img
                src="@/components/WebView/icons/BackLeftAction.svg"
                alt="Back"
                @click="goBack"
            />
        </template>
        <template #right-button>
            <span class="next" @click="goNext">Confirm</span>
        </template>

        <Loading v-if="loading" />
        <RecipientPicker
            v-else
            :recipients="recipients"
            :selected-recipients="selectedRecipients"
            :max-list-length="20"
            @toggleRecipient="onToggleRecipient"
            @clearSelectedRecipients="clearSelectedRecipients"
        />
    </WebViewScreen>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { Action, Getter } from 'vuex-facing-decorator'
import WebViewScreen from '@/components/WebView/WebViewScreen.vue'
import RecipientPicker from '@/components/Notices/RecipientPicker.vue'
import { IComposer, IRecipient } from '@/entities/notices'
import Loading from '@/components/Loading.vue'
import { useRouter } from 'vue-router'

@Component({
    components: { Loading, WebViewScreen, RecipientPicker },
})
export default class RecipientPickerScreen extends Vue {
    @Getter private recipients!: IRecipient[]
    @Getter private composer!: IComposer

    @Action private loadRecipients
    @Action private setComposerRecipients

    private router = useRouter()

    private selectedRecipients: IRecipient[] = []

    private get loading() {
        return !this.recipients
    }

    private mounted() {
        this.selectedRecipients = [...this.composer.recipients]
        if (!this.recipients) {
            this.loadRecipients()
        }
    }

    private goBack() {
        this.router.back()
    }

    private goNext() {
        this.setComposerRecipients(this.selectedRecipients)
        this.goBack()
    }

    private onToggleRecipient(selectedRecipients: IRecipient[]) {
        this.selectedRecipients = selectedRecipients
    }

    private clearSelectedRecipients() {
        this.selectedRecipients = []
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/palette.less';

.next {
    font-size: 16px;
    color: @blue;
    font-weight: bold;
}
</style>
