import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"
import _imports_0 from '@/components/WebView/icons/BackLeftAction.svg'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_RecipientPicker = _resolveComponent("RecipientPicker")!
  const _component_WebViewScreen = _resolveComponent("WebViewScreen")!

  return (_openBlock(), _createBlock(_component_WebViewScreen, {
    "no-pad": "",
    title: "Add Recipients"
  }, {
    "left-button": _withCtx(() => [
      _createElementVNode("img", {
        src: _imports_0,
        alt: "Back",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goBack && _ctx.goBack(...args)))
      })
    ]),
    "right-button": _withCtx(() => [
      _createElementVNode("span", {
        class: "next",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.goNext && _ctx.goNext(...args)))
      }, "Confirm")
    ]),
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
        : (_openBlock(), _createBlock(_component_RecipientPicker, {
            key: 1,
            recipients: _ctx.recipients,
            "selected-recipients": _ctx.selectedRecipients,
            "max-list-length": 20,
            onToggleRecipient: _ctx.onToggleRecipient,
            onClearSelectedRecipients: _ctx.clearSelectedRecipients
          }, null, 8, ["recipients", "selected-recipients", "onToggleRecipient", "onClearSelectedRecipients"]))
    ], undefined, true),
    _: 1
  }))
}