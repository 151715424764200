<template>
    <EngagementPopup v-if="shouldShowEngagementModal" />
    <div
        v-else-if="dataLoaded && !userHasMlp"
        :class="['home', { offline: !hasNetworkConnection }]"
    >
        <TabbedContainer
            :tabs="mainNavTabs"
            :selected="selectedTab"
            @click="onSwitchTab"
        >
            <RouterView v-slot="{ Component }">
                <PushPopTransitionContainer>
                    <component :is="Component" />
                </PushPopTransitionContainer>
            </RouterView>
        </TabbedContainer>

        <!-- ATM, mobile filter bar content in Dropdown.vue will mount here
            instead of in their originial place deep down the tree
            this is to fix that safari (Webkit) doesn't support 100vh very well -->
        <PortalTarget name="portal-destination">
            <!--
                This component can be located anywhere in your App.
                The slot content of the above portal component will be rendered here.
                -->
        </PortalTarget>
        <AchievementSplash
            v-if="showAchievementSplash"
            :type="unseenAchievementType"
            @close="onCloseAchievementSplash"
        />
    </div>
    <div v-else-if="dataLoaded" class="homeV4">
        <MobileNavigation
            :tabs="mainNavTabs"
            :selected="selectedTab"
            @click="onSwitchTab"
        >
            <PageContainer class="overview">
                <div :key="contentKey">
                    <RouterView
                        v-slot="{ Component }"
                        class="header-router"
                        name="header"
                    >
                        <transition
                            class="transition-container"
                            :name="getRouteTransition"
                            @after-enter="scrollToPagePosition"
                        >
                            <component :is="Component" />
                        </transition>
                    </RouterView>
                    <RouterView
                        v-slot="{ Component }"
                        :class="[
                            'default-router',
                            { offline: !hasNetworkConnection },
                        ]"
                    >
                        <transition
                            class="transition-container"
                            :name="getRouteTransition"
                            @after-enter="scrollToPagePosition"
                        >
                            <component :is="Component" />
                        </transition>
                    </RouterView>
                    <OfflineBanner :selected="selectedTab" />
                </div>
            </PageContainer>
        </MobileNavigation>
        <TeamProfileSelectPopup />
        <ReportCardPopup />
        <OverCardPortalWrapper />
        <ShadowPortalWrapper />
        <ToastOverlay />
    </div>
    <LoadingWithGradientBg v-else />
</template>

<script lang="ts">
import PushPopTransitionContainer from '@/mobile/src/components/containers/PushPopTransitionContainer.vue'
import TabbedContainer from '@/mobile/src/components/containers/TabbedContainer/TabbedContainer.vue'
import { PortalTarget } from 'portal-vue'
import useEmitter from '@/composables/useEmitter'
import { Component, Watch, Vue } from 'vue-facing-decorator'
import { Action, Getter } from 'vuex-facing-decorator'
import { useRoute, useRouter } from 'vue-router'
import { TabData } from '../types/tab'
import FullScreenPopup from '@/mobile/src/components/FullScreenPopup.vue'
import EngagementPopup from '@/mobile/src/components/engagement/EngagementPopup.vue'
import { Badge } from '@/entities/scorecard'
import AchievementSplash from '@/pages/scorecard/AchievementSplash.vue'
import { markAchievementSeen } from '@/api/achievements'
import MobileNavigation from '@/mobile/src/components/appV4/MobileNavigation.vue'
import PageContainer from '@/mobile/src/components/appV4/PageContainer.vue'
import LoadingWithGradientBg from '@/mobile/src/components/LoadingWithGradientBg.vue'
import ShadowPortalWrapper from '@/mobile/src/components/appV4/ShadowPortalWrapper.vue'
import OverCardPortalWrapper from '@/mobile/src/components/appV4/OverCardPortalWrapper.vue'
import ToastOverlay from '@/mobile/src/components/appV4/ToastOverlay.vue'
import { Capacitor } from '@capacitor/core'
import { PushNotifications } from '@capacitor/push-notifications'
import requestPushNotificationPermission from '@/mobile/src/utils/pushnotification'
import { scrollToPosition, scrollToTop } from '@/mobile/src/utils/scrollbus'
import ReportCardPopup from '@/mobile/src/components/ReportCardPopup.vue'
import TeamProfileSelectPopup from '@/mobile/src/components/appV4/TeamProfileSelectPopup.vue'
import OfflineBanner from '@/mobile/src/components/appV4/OfflineBanner.vue'

@Component({
    components: {
        OfflineBanner,
        ReportCardPopup,
        ToastOverlay,
        OverCardPortalWrapper,
        PageContainer,
        MobileNavigation,
        AchievementSplash,
        EngagementPopup,
        FullScreenPopup,
        PortalTarget,
        PushPopTransitionContainer,
        TabbedContainer,
        LoadingWithGradientBg,
        ShadowPortalWrapper,
        TeamProfileSelectPopup,
    },
})
export default class Home extends Vue {
    @Getter
    mainNavTabs!: Array<TabData>
    @Getter
    public isFrontlineEngagementUser
    @Getter
    readonly shouldShowEngagementModal!: boolean

    @Getter
    readonly $companyVars
    @Getter
    readonly details

    @Getter
    unseenAchievements!: Badge[]

    @Getter public getScrollPosition!: (page: string | undefined) => number
    @Getter public hasScrollPosition!: (page: string | undefined) => boolean
    @Getter hasNetworkConnection!: boolean

    @Action
    public loadDailyEngagementQuestions

    @Action
    setUserProp

    @Getter
    private readonly showAdminPushNotificationNative
    @Getter isImpersonating

    private transitionName = ''
    private previousRouteGroup = ''

    private route = useRoute()
    private router = useRouter()
    private emitter = useEmitter()

    @Watch('route', { immediate: true, deep: true })
    onRouteChange(to, from) {
        this.previousRouteGroup = from?.meta?.group ?? ''

        const toDepth = to?.meta?.transitionDepth ?? 0
        const fromDepth = from?.meta?.transitionDepth ?? 0

        this.transitionName =
            toDepth >= fromDepth ? 'push-screen' : 'pop-screen'
    }

    get contentKey() {
        return 'page-content' + this.route.path
    }

    get getRouteTransition() {
        if (this.route?.meta?.group == this.previousRouteGroup) {
            return this.transitionName
        }
        return '' // opportunity for screen transitions to go here
    }

    get selectedTab(): string {
        const selectedTab = this?.route?.meta?.tab || this.route.name
        /*
            every user can have a different assortment of main navigation tabs.
            we should check if the current route selected is a main tab
            if it is not a main tab we will fall back to highlighting the more tab
         */
        if (
            this.mainNavTabs.filter((tab) => tab.name === selectedTab).length >
            0
        ) {
            return String(selectedTab ?? '')
        }
        return 'more'
    }

    async mounted() {
        this.loadDailyEngagementQuestions()

        // Request notification permission after logging in
        if (Capacitor.isPluginAvailable('PushNotifications')) {
            const result = await PushNotifications.checkPermissions()
            // request push notification permission
            if (
                result.receive === 'prompt' &&
                this.showAdminPushNotificationNative
            ) {
                await requestPushNotificationPermission()
            }
        }
    }

    public get userHasMlp() {
        return this.details.userHasMlp
    }

    public get dataLoaded() {
        return this.details?.company?.domain
    }

    onSwitchTab(name: string) {
        // use push rather than replace, since android back button would cause visual `back one more step` bug.
        this.router.push({ name })
        this.loadDailyEngagementQuestions()
    }

    get showAchievementSplash() {
        return this.unseenAchievements?.length
    }

    get unseenAchievementType() {
        return this.unseenAchievements[0]?.type
    }

    onCloseAchievementSplash(type) {
        // mark current achievement seen
        markAchievementSeen(type)

        const index = this.unseenAchievements.findIndex((a) => a.type === type)
        if (index > -1) {
            this.unseenAchievements.splice(index, 1)
            this.setUserProp({ unseenAchievements: this.unseenAchievements })
        }
    }

    scrollToPagePosition() {
        // page has scroll position then trigger scrolling
        if (
            !this.hasScrollPosition(this?.route?.name?.toString() || undefined)
        ) {
            scrollToTop(this.emitter, 'auto')
        }
        scrollToPosition(this.emitter, {
            top: this.getScrollPosition(
                this?.route?.name?.toString() || undefined
            ),
            behavior: 'smooth',
        })
    }
}
</script>

<style lang="less" scoped>
@import '~@/mobile/src/styles/animations.less';

.home {
    /* take the whole height so children can apply flex*/
    height: 100%;
    padding: env(safe-area-inset-top) env(safe-area-inset-right)
        env(safe-area-inset-bottom) env(safe-area-inset-left);

    &.offline {
        padding: 0;
    }

    /* so inner div doesn't need to care about height issue when above padding applied */
    box-sizing: border-box;
}

.homeV4 {
    height: 100%;
    width: 100%;
}

// Home page for App v4
.overview {
    height: 100%;
    /* so inner div doesn't need to care about height issue when above padding applied */
    box-sizing: border-box;
}

.screen-cover {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
}

.default-router {
    // Padding to avoid any "safe areas" like iPhone X notch status bar
    padding: env(safe-area-inset-top) env(safe-area-inset-right)
        env(safe-area-inset-bottom) env(safe-area-inset-left);
    &.offline {
        padding: 0;
    }
}

.slide-fade-enter-from {
    transform: translateY(500px);
}

.slide-fade-enter-active {
    animation: slide 0.3s backwards;
    transition: 0.3s;
}

.slide-fade-leave-active {
    animation: slide 0.5s forwards;
    transition: 0.5s;
}

.slide-fade-leave-to {
    transform: translateY(500px);
}

.cover-enter-active {
    transition: 0.3s ease;
}
.cover-leave-active {
    transition: 0.5s ease;
}
.cover-enter-from,
.cover-leave-to {
    opacity: 0;
}

.page-slide-animation();
</style>
