export enum ToastIcon {
    Success = 'success',
    Waiting = 'waiting',
    Alert = 'alert',
}

export enum ToastColor {
    Message = 'message',
    Shoutout = 'shoutout',
    Coaching = 'coaching',
    EmailReply = 'email-reply',
    InternalNote = 'internal-note',
    Warning = 'warning',
    Workflow = 'workflow',
}

export enum ToastDuration {
    Standard = 2000,
}

export interface ToastEvent {
    icon: ToastIcon
    color: ToastColor
    msg: string
    time: number
    wait: number
    dismissable: boolean
}
