import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScorecardProfile = _resolveComponent("ScorecardProfile")!
  const _component_BaseBlock = _resolveComponent("BaseBlock")!
  const _component_ScorecardNPS = _resolveComponent("ScorecardNPS")!
  const _component_ScorecardGraph = _resolveComponent("ScorecardGraph")!
  const _component_ScorecardLeaderboard = _resolveComponent("ScorecardLeaderboard")!
  const _component_RoutableBadge = _resolveComponent("RoutableBadge")!
  const _component_ThingsToWorkOn = _resolveComponent("ThingsToWorkOn")!
  const _component_TeamMember = _resolveComponent("TeamMember")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_HorizontalScrollContainer = _resolveComponent("HorizontalScrollContainer")!
  const _component_TwoColumnLayoutContainer = _resolveComponent("TwoColumnLayoutContainer")!

  return (_openBlock(), _createBlock(_component_TwoColumnLayoutContainer, null, {
    "left-column": _withCtx(() => [
      _createVNode(_component_BaseBlock, null, {
        title: _withCtx(() => [
          _createTextVNode("Profile")
        ]),
        default: _withCtx(() => [
          _createVNode(_component_ScorecardProfile)
        ], undefined, true),
        _: 1
      }),
      _createVNode(_component_BaseBlock, null, {
        title: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.graphTitle), 1)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_ScorecardNPS),
          _createVNode(_component_ScorecardGraph)
        ], undefined, true),
        _: 1
      }),
      (_ctx.showLeaderboard)
        ? (_openBlock(), _createBlock(_component_BaseBlock, { key: 0 }, {
            title: _withCtx(() => [
              _createTextVNode("Leaderboard")
            ]),
            default: _withCtx(() => [
              _createVNode(_component_ScorecardLeaderboard)
            ], undefined, true),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    "right-column": _withCtx(() => [
      _createVNode(_component_BaseBlock, null, {
        title: _withCtx(() => [
          _createTextVNode("Positive feedback")
        ]),
        default: _withCtx(() => [
          _createVNode(_component_RoutableBadge, { "team-member-user-id": _ctx.teamMemberUserId }, null, 8, ["team-member-user-id"])
        ], undefined, true),
        _: 1
      }),
      _createVNode(_component_BaseBlock, null, {
        title: _withCtx(() => [
          _createTextVNode("Things to work on")
        ]),
        default: _withCtx(() => [
          _createVNode(_component_ThingsToWorkOn)
        ], undefined, true),
        _: 1
      }),
      (_ctx.showMyTeam)
        ? (_openBlock(), _createBlock(_component_BaseBlock, {
            key: 0,
            "no-padding": ""
          }, {
            title: _withCtx(() => [
              _createTextVNode("My Team")
            ]),
            default: _withCtx(() => [
              _createVNode(_component_HorizontalScrollContainer, { class: "my-team" }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.teamMembers, (teamMember) => {
                    return (_openBlock(), _createBlock(_component_RouterLink, {
                      key: teamMember.user_id,
                      to: {
                            name: 'scorecardHomeUser',
                            params: { teamMemberUserId: teamMember.user_id },
                        },
                      class: "team-member-link"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_TeamMember, { "team-member": teamMember }, null, 8, ["team-member"])
                      ], undefined, true),
                      _: 2
                    }, 1032, ["to"]))
                  }), 128))
                ], undefined, true),
                _: 1
              })
            ], undefined, true),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_BaseBlock, null, {
        title: _withCtx(() => [
          _createTextVNode("Achievements")
        ]),
        default: _withCtx(() => [
          _createVNode(_component_RoutableBadge, {
            "team-member-user-id": _ctx.teamMemberUserId,
            "show-achievements": ""
          }, null, 8, ["team-member-user-id"])
        ], undefined, true),
        _: 1
      })
    ]),
    _: 1
  }))
}