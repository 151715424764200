<template>
    <WebViewScreen class="webview-container" no-pad title="Settings">
        <template #left-button>
            <BackButton />
        </template>
        <div class="container">
            <MenuRow
                v-for="menuItem in menuItems"
                :key="menuItem.id"
                :menu-item="menuItem"
                class="item"
                @click="onClickItem"
            />
        </div>
    </WebViewScreen>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { useRouter } from 'vue-router'
import BaseButton from '@/components/BaseButton.vue'
import WebViewScreen from '@/components/WebView/WebViewScreen.vue'
import IconBell from '@/assets/img/Icons/bell-active.svg'
import MenuRow, { MenuItem } from '@/mobile/src/components/MenuRow.vue'
import BackButton from '@/mobile/src/components/BackButton.vue'

@Component({
    components: { BackButton, MenuRow, WebViewScreen, BaseButton },
})
export default class Settings extends Vue {
    private router = useRouter()

    private menuItems: MenuItem[] = [
        {
            icon: IconBell,
            name: 'Notifications',
            id: 'notificationSettings',
        },
    ]

    private onClickItem(menuItem: MenuItem) {
        switch (menuItem.id) {
            case 'notificationSettings':
                this.router.push({ name: 'notificationSettings' })
                break
        }
    }
}
</script>

<style lang="less" scoped>
@import '../../../../styles/palette.less';

.container {
    background: @greyLight;
    flex: 1;

    .item {
        margin: 12px;
    }
}
</style>
