import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { getTsTeamGroups, getTsTeamParent } from '@/api/teamscoreboard'
import { TsTeamGroupEntity } from '@/entities/teamscoreboard'

export interface TeamFilterState {
    filters: TsTeamGroupEntity[]
    selectedFilter: TsTeamGroupEntity | null
    selectedParentFilter: TsTeamGroupEntity | null
    backHistories: TsTeamGroupEntity[] // for Back button history
    backToPrevious: TsTeamGroupEntity | null
    teamFilterLoading: boolean
    teamParentLoading: boolean // delay the Topics and LocationGoalTimeline components loading in Teams.vue
}

export const state: TeamFilterState = {
    filters: [],
    selectedFilter: null,
    selectedParentFilter: null,
    backHistories: [],
    backToPrevious: null,
    teamFilterLoading: false,
    teamParentLoading: false,
}

const getters: GetterTree<TeamFilterState, any> = {
    filterOptions: (state) => state.filters,
    filterIsSelected: (state) => (filterOption: TsTeamGroupEntity) =>
        state.selectedFilter === filterOption,
    backToPrevious: (state) =>
        state.backHistories[state.backHistories.length - 1],
    teamFilterSelected: (state) => state.selectedFilter,
    teamParentFilterSelected: (state) => state.selectedParentFilter,
    teamFilterLoading: (state) => state.teamFilterLoading,
    teamParentLoading: (state) => state.teamParentLoading,
    getHistory: (state) => state.backHistories,
}

const actions: ActionTree<TeamFilterState, any> = {
    async loadFilterOptions(
        { commit, state, getters },
        excludeHigherHierarchy: boolean
    ) {
        if (state.filters && state.filters.length > 0) {
            return
        }

        try {
            commit('setTeamFilterLoading', true)
            const { data } = await getTsTeamGroups(excludeHigherHierarchy)
            commit('setFilterOptions', { data })
            commit('setTeamFilterLoading', false)
        } catch (error) {
            // handle error
        }
    },

    async loadTeamParent({ commit, state, getters }) {
        try {
            if (!state.selectedParentFilter && !state.teamParentLoading) {
                commit('setTeamParentLoading', true)
                const { data } = await getTsTeamParent()
                commit('setSelectedParentFilter', data)
                commit('setTeamParentLoading', false)
            }
        } catch (error) {
            // handle error
        }
    },
    toggleSelected({ commit, state }, filterOption: TsTeamGroupEntity) {
        commit('setSelected', filterOption)
    },
    addHistory(
        { commit, state },
        payload: {
            filterOption: TsTeamGroupEntity
            parentFilterOption: TsTeamGroupEntity
        }
    ) {
        commit('addHistory', payload)
    },
    removeHistory({ commit, state }) {
        commit('removeHistory')
    },
    resetTeamFilters({ commit, state }) {
        commit('resetFilters')
    },
    setSelectedParentFilter({ commit }, filterOption: TsTeamGroupEntity) {
        commit('setSelectedParentFilter', filterOption)
    },
}

const mutations: MutationTree<TeamFilterState> = {
    setTeamFilterLoading(state, loading) {
        state.teamFilterLoading = loading
    },
    setTeamParentLoading(state, loading) {
        state.teamParentLoading = loading
    },
    setFilterOptions(state, payload: { data: any }) {
        state.filters = payload.data
        // default filter selected.
        state.selectedFilter = state.filters[0]
        // add first History item, avoid duplication
        if (state.backHistories.length === 0) {
            state.backHistories.push(state.selectedFilter)
        }
    },
    setSelected(state, filterOption: TsTeamGroupEntity) {
        state.selectedFilter = filterOption
    },
    setSelectedParentFilter(state, filterOption: TsTeamGroupEntity) {
        state.selectedParentFilter = filterOption
    },
    addHistory(
        state,
        payload: {
            filterOption: TsTeamGroupEntity
            parentFilterOption: TsTeamGroupEntity
        }
    ) {
        if (payload.parentFilterOption) {
            //Loop through the Back histories data to check if the parentFilterOption is there.
            const existItem = state.backHistories.filter((history) => {
                if (
                    history?.fieldMapping ===
                        payload.parentFilterOption?.fieldMapping &&
                    history?.groupName ===
                        payload.parentFilterOption?.groupName &&
                    history?.customSelectionName ===
                        payload.parentFilterOption?.customSelectionName
                ) {
                    return true
                }
            })
            if (existItem.length === 0) {
                // If the parentFilter is not in the history, add it before the current filter.
                // This will ensure the proper history data is built.
                // The parentFilter is removed when user click the Back button.
                state.backHistories.push(payload.parentFilterOption)
            }
        }

        // Avoid duplicated item adding again.
        const existInit = state.backHistories.filter((history) => {
            if (
                history?.fieldMapping === payload.filterOption?.fieldMapping &&
                history?.groupName === payload.filterOption?.groupName &&
                history?.customSelectionName ===
                    payload.filterOption?.customSelectionName
            ) {
                return true
            }
        })
        if (existInit.length === 0) {
            state.backHistories.push(payload.filterOption)
        }
    },
    removeHistory(state) {
        // remove last history item, always leave the top item in history
        if (state.backHistories.length > 1) {
            state.backHistories.pop()
        }
    },
    resetFilters(state) {
        // default filter selected.
        state.selectedFilter = state.filters[0]
        // add first History item
        state.backHistories = []
        state.backHistories.push(state.selectedFilter)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
