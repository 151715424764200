import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-930720cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "option-content type" }
const _hoisted_2 = { class: "option-content type" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Typography = _resolveComponent("Typography")!
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_MessageTypePickerModal = _resolveComponent("MessageTypePickerModal")!
  const _component_ReplyTemplateSelectorModal = _resolveComponent("ReplyTemplateSelectorModal")!
  const _component_SelectedRecipientsList = _resolveComponent("SelectedRecipientsList")!
  const _component_MessageRecipientPickerModal = _resolveComponent("MessageRecipientPickerModal")!
  const _component_MomentTextBox = _resolveComponent("MomentTextBox")!
  const _component_MomentModalFooter = _resolveComponent("MomentModalFooter")!
  const _component_OverCardModal = _resolveComponent("OverCardModal")!

  return (_openBlock(), _createBlock(_component_OverCardModal, { "close-popup": _ctx.onClosePopup }, {
    "header-center": _withCtx(() => [
      _createVNode(_component_Typography, {
        variant: "subtitle1",
        class: _normalizeClass(['message-context-text', _ctx.composerType])
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.messageType), 1)
        ], undefined, true),
        _: 1
      }, 8, ["class"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "message-option",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (
                    _ctx.setTypePickerModalOpen({
                        isOpen: !_ctx.typePickerModalOpen,
                        modalSource: _ctx.modalSource,
                    })
                ))
      }, [
        _createVNode(_component_Typography, {
          variant: "body2",
          class: _normalizeClass(["option-label", ['message-context-text', _ctx.composerType]])
        }, {
          default: _withCtx(() => [
            _createTextVNode("Type")
          ], undefined, true),
          _: 1
        }, 8, ["class"]),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_Typography, {
            variant: "body2",
            class: _normalizeClass(['message-context-text', _ctx.composerType])
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.messageType), 1)
            ], undefined, true),
            _: 1
          }, 8, ["class"]),
          _createVNode(_component_FontAwesomeIcon, {
            icon: _ctx.chevronDownIcon,
            class: _normalizeClass(['message-context-text', _ctx.composerType])
          }, null, 8, ["icon", "class"])
        ])
      ]),
      _createVNode(_component_MessageTypePickerModal),
      (_ctx.composerType === 'emailReply')
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "message-option",
            onClick: _cache[1] || (_cache[1] = () => (_ctx.showReplyTemplateModal = true))
          }, [
            _createVNode(_component_Typography, {
              variant: "body2",
              class: _normalizeClass(["option-label", ['message-context-text', _ctx.composerType]])
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Template ")
              ], undefined, true),
              _: 1
            }, 8, ["class"]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_Typography, {
                variant: "body2",
                class: _normalizeClass(['message-context-text', _ctx.composerType])
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.templateName), 1)
                ], undefined, true),
                _: 1
              }, 8, ["class"]),
              _createVNode(_component_FontAwesomeIcon, {
                icon: _ctx.chevronDownIcon,
                class: _normalizeClass(['message-context-text', _ctx.composerType])
              }, null, 8, ["icon", "class"])
            ])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_ReplyTemplateSelectorModal, {
        "modal-open": _ctx.showReplyTemplateModal,
        "loading-templates": _ctx.loadingTemplates,
        onCloseModal: _cache[2] || (_cache[2] = () => (_ctx.showReplyTemplateModal = false))
      }, null, 8, ["modal-open", "loading-templates"]),
      (_ctx.showToField)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "message-option to-field",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.focusRecipientSearchInput && _ctx.focusRecipientSearchInput(...args)))
          }, [
            _createVNode(_component_Typography, {
              variant: "body2",
              class: _normalizeClass(["option-label", ['message-context-text', _ctx.composerType]])
            }, {
              default: _withCtx(() => [
                _createTextVNode(" To ")
              ], undefined, true),
              _: 1
            }, 8, ["class"]),
            _createVNode(_component_SelectedRecipientsList)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", null, [
        _createVNode(_component_MessageRecipientPickerModal, {
          "pendo-name": "recipient",
          "search-input": _ctx.recipientSearchInput,
          "select-new": _ctx.selectNewRecipient,
          "set-search-input": _ctx.setRecipientSearchInput,
          onRecipientSelected: _ctx.focusRecipientSearchInput
        }, null, 8, ["search-input", "select-new", "set-search-input", "onRecipientSelected"])
      ]),
      _createVNode(_component_MomentTextBox, {
        value: _ctx.mentionText,
        placeholder: "Write the message here",
        class: "moment-modal-rich-text",
        onInput: _ctx.setMentionText
      }, null, 8, ["value", "onInput"])
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_MomentModalFooter, {
        "can-send-message": _ctx.sendButtonDisabled,
        "can-add-image": _ctx.canAddImage,
        onSend: _ctx.onSendMoment
      }, null, 8, ["can-send-message", "can-add-image", "onSend"])
    ]),
    _: 1
  }, 8, ["close-popup"]))
}