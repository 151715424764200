import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, Fragment as _Fragment, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c8be41d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  ref: "tile",
  class: "metric-container"
}
const _hoisted_2 = { class: "title-container" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "subtitle" }
const _hoisted_5 = {
  key: 1,
  ref: "tile",
  class: "metric-container"
}
const _hoisted_6 = { class: "subtitle" }
const _hoisted_7 = { class: "title-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SkeletonDiv = _resolveComponent("SkeletonDiv")!
  const _component_UpDownArrow = _resolveComponent("UpDownArrow")!
  const _component_UpDownDoubleChevron = _resolveComponent("UpDownDoubleChevron")!
  const _component_BaseCard = _resolveComponent("BaseCard")!

  return (_openBlock(), _createBlock(_component_BaseCard, {
    "is-highlighted": _ctx.active,
    onClick: _ctx.onClick
  }, {
    default: _withCtx(() => [
      (_ctx.skeleton)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_SkeletonDiv, { width: _ctx.skeleWidth }, null, 8, ["width"])
              ])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_SkeletonDiv, { width: _ctx.skeleWidth }, null, 8, ["width"])
            ])
          ], 512))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.subtitle), 1),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", {
                class: _normalizeClass(["title", { 'title-web': !_ctx.$isMobile }])
              }, [
                _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
                (_ctx.positive !== null)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      (_ctx.changeIcon === 'arrow')
                        ? (_openBlock(), _createBlock(_component_UpDownArrow, {
                            key: 0,
                            positive: _ctx.positive,
                            class: "change"
                          }, null, 8, ["positive"]))
                        : (_ctx.changeIcon === 'chevron')
                          ? (_openBlock(), _createBlock(_component_UpDownDoubleChevron, {
                              key: 1,
                              positive: _ctx.positive,
                              class: "change"
                            }, null, 8, ["positive"]))
                          : _createCommentVNode("", true)
                    ], 64))
                  : _createCommentVNode("", true)
              ], 2)
            ])
          ], 512))
    ], undefined, true),
    _: 1
  }, 8, ["is-highlighted", "onClick"]))
}