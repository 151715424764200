<template>
    <div class="multi-option-picker">
        <div class="search-container">
            <SearchBox :value="searchText" @input="searchText = $event" />
        </div>
        <div class="option-list">
            <div
                v-for="(opt, optIdx) in filteredOptions"
                :key="optIdx"
                class="option"
                @click="onClickOption(opt)"
            >
                <AvatarOrInitials
                    v-if="withIcon"
                    class="icon"
                    :size="40"
                    :user="mockUser(opt)"
                />
                <div class="option-label">{{ opt.label }}</div>
                <div
                    :class="{ checkbox: true, selected: isOptionSelected(opt) }"
                >
                    <div v-if="!isOptionSelected(opt)" class="not-checked" />
                    <div v-else>
                        <svg
                            width="14"
                            height="10"
                            viewBox="0 0 14 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1 5.49943L4.65259 9L13 1"
                                stroke="#2E5BFF"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { getCDNUrl } from '@/utils/cdn'
import AvatarOrInitials from '@/components/WebView/AvatarOrInitials.vue'
import SearchBox from '@/components/SearchBox.vue'
import Fuse from 'fuse.js'

@Component({
    components: { SearchBox, AvatarOrInitials },
    emits: ['select'],
})
export default class MultipleOptionPicker extends Vue {
    @Prop({ type: Array, required: true })
    options!: { icon?: string; label: string; value: any }[]

    @Prop({ type: Array, required: true })
    selected!: any[]

    @Prop({ type: Boolean, required: false })
    withIcon!: boolean

    @Prop({ type: Boolean, required: false })
    withSearch!: boolean

    searchText = ''

    get fuse() {
        return new Fuse(this.options, {
            keys: ['label', 'value'],
            shouldSort: true,
            threshold: 0.4,
            location: 0,
            distance: 100,
            maxPatternLength: 32,
            minMatchCharLength: 3,
        })
    }

    get filteredOptions() {
        if (this.searchText) {
            return this.fuse.search(this.searchText)
        }
        return this.options
    }

    isOptionSelected(opt) {
        return this.selected.indexOf(opt.value) >= 0
    }

    mockUser(opt) {
        return {
            id: -1,
            name: opt.label,
            avatar: opt.icon,
        }
    }

    onClickOption(opt) {
        let updatedSelected = [...this.selected]
        if (this.isOptionSelected(opt)) {
            updatedSelected = updatedSelected.filter((v) => v !== opt.value)
        } else {
            updatedSelected.push(opt.value)
        }
        this.$emit('select', updatedSelected)
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';

.search-container {
    display: flex;
    padding: 20px;
    position: sticky;
    top: 0;
    background: @white;
}

.option {
    display: flex;
    padding: @gutterSpacing-md;
    align-items: center;

    .icon {
        margin-right: @gutterSpacing-md;
    }

    .option-label {
        flex: 1;
        line-height: 24px;
        text-align: left;
    }
    .checkbox {
        .not-checked {
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: #f4f6fc;
        }
    }
}
</style>
