<template>
    <div
        :class="{
            'desktop-insights-engagement-theme-item': !$isMobile,
            select: desktopEngagementThemeSelected === themeId,
        }"
        @click="updateSelectedTheme(themeId)"
    >
        <InsightsDetailRow
            :class="['insights-detail-row', { mobile: $isMobile }]"
        >
            <template #icon>
                <img :src="engagementIcon(themeId)" class="icon" alt="icon" />
            </template>
            <template #title>
                <Typography
                    variant="subtitle1"
                    class="insights-engagement-theme-item-title"
                >
                    <div>{{ title }}</div>
                    <div
                        v-if="showInfoIcon"
                        class="insights-engagement-theme-item-info-icon"
                    >
                        <img src="@/assets/img/Icons/info.svg" alt="info" />
                    </div>
                </Typography>
            </template>
            <template #subtitle>
                <Typography variant="caption" class="subtitle"><span>{{ tileDescription(themeId) }}</span></Typography>
            </template>
        </InsightsDetailRow>
        <div class="insights-engagement-theme-info">
            <div class="left">
                <div class="points">
                    <div class="delta-div">
                        <UpDownArrow :positive="deltaPositive" />
                    </div>
                    <Typography variant="h4">{{
                        Math.abs(Math.round(points))
                    }}</Typography>
                </div>
                <Typography variant="caption"><span>Overall Score:</span>
                    {{ Math.round(overallScore) }}%</Typography>
                <Typography variant="caption"><span>vs previous:</span>
                    {{ Math.round(previousScore) }}%</Typography>
            </div>
            <div class="right">
                <InsightsGraph
                    v-if="chartData"
                    :color="getGraphColour(themeId, $palette)"
                    :data="chartData"
                    :height="60"
                />
                <InsightsGraph
                    v-else
                    :color="$palette.neutral"
                    :data="[1, 1]"
                    :height="60"
                />
            </div>
        </div>
        <div
            v-if="!$isMobile"
            :class="`desktop-chevron ${
                desktopEngagementThemeSelected === themeId && 'selected'
            }`"
        >
            <FontAwesomeIcon
                class="right-chevron-icon"
                :icon="faChevronRight"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import InsightsDetailRow from '@/mobile/src/components/Insights/InsightsDetailRow.vue'
import Typography from '@/components/Rain/Typography/Typography.vue'
import UpDownArrow from '@/components/UpDownArrow.vue'
import {
    engagementIcon,
    tileDescription,
} from '@/mobile/src/components/engagement/utils'
import InsightsGraph from '@/mobile/src/components/Insights/InsightsGraph.vue'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faChevronRight } from 'fontawesome/free-solid-svg-icons'
import { Action, Getter } from 'vuex-facing-decorator'
@Component({
    components: {
        InsightsGraph,
        UpDownArrow,
        Typography,
        InsightsDetailRow,
        FontAwesomeIcon,
    },
    methods: {
        engagementIcon,
        tileDescription,
    },
})
export default class InsightsEngagementThemeItem extends Vue {
    faChevronRight = faChevronRight
    @Prop({ type: String, required: true })
    public readonly themeId!: string
    @Prop({ type: String, required: true })
    public readonly title!: string
    @Prop({ type: Number, required: true })
    public readonly points!: number
    @Prop({ type: Array, required: true })
    public readonly history!: { date: string; score: number }[]
    @Prop({ type: Number, required: true })
    public readonly overallScore!: number
    @Prop({ type: Number, required: true })
    public readonly previousScore!: number
    @Prop({ type: Boolean, required: true })
    public readonly deltaPositive!: boolean
    @Prop({ type: Boolean, required: false, default: false })
    public readonly showInfoIcon!: boolean
    @Getter
    readonly desktopEngagementThemeSelected!: string
    @Action
    setDesktopEngagementThemeSelected
    get chartData() {
        const chartData = this.history.map((item) => item.score)
        // if all points are 0 (no data) then return null to effectively draw a neutral flat line graph
        if (chartData.every((point) => point === 0)) {
            return null
        }
        return chartData
    }
    updateSelectedTheme(themeId) {
        if (process.env.CONFIG_KEY !== 'mobile') {
            this.setDesktopEngagementThemeSelected(themeId)
        }
    }
    getGraphColour(themeId, $palette) {
        switch (themeId) {
            case 'Development':
                return $palette.insightsDevelopmentGraph
            case 'Culture':
                return $palette.insightsCultureGraph
            case 'Recognition':
                return $palette.insightsRecognitionGraph
            case 'Wellbeing':
                return $palette.insightsWellbeingGraph
            default:
                return $palette.insightsDevelopmentGraph
        }
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';
@import '~@/styles/insights.less';
@import '~@/styles/variables';

.desktop-insights-engagement-theme-item {
    padding: 12px 14px 14px;
    display: flex;
    height: 65px;
    cursor: pointer;

    .insights-detail-row {
        width: @desktopInsightsEngagementThemesWidth;
        span {
            display: flex;
            color: @grey;
            padding-top: 10px;
        }
    }
    .insights-engagement-theme-info {
        flex-grow: 1;
        .left {
            width: 160px;
        }
        .right {
            flex-grow: 1;
        }
    }
    .desktop-chevron {
        width: 32px;
        margin-top: @gutterSpacing-base;
        text-align: center;
        line-height: 50px;
        color: @grey;
        &.selected {
            color: @primaryBlue;
        }
    }
}
.subtitle {
    color: @grey;
}
.icon {
    width: 44px;
    height: 44px;
    line-height: 44px;
}
.insights-engagement-theme-item-title {
    display: flex;
    justify-content: space-between;
    .insights-engagement-theme-item-info-icon {
        position: relative;
        img {
            height: 20px;
            position: absolute;
            right: 0;
        }
    }
}
.insights-engagement-theme-info {
    display: flex;
    .left {
        padding-bottom: @gutterSpacing-xl;
        width: 50%;
        .points {
            display: flex;
            column-gap: @gutterSpacing-base;
            .delta-div {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .points-text {
                display: flex;
                p {
                    margin-top: 10px;
                    text-transform: lowercase;
                }
            }
        }
        span {
            color: @grey;
        }
    }
    .right {
        width: 50%;
        padding: 0 @gutterSpacing-base @gutterSpacing-md @gutterSpacing-base;
    }
    .percent {
        margin-top: 2px;
        text-align: right;
        color: @grey;
    }
}
.select {
    outline: 2px solid @primaryBlue;
    border-radius: 45px;
    color: @primaryBlue;
    padding: 12px 14px 14px !important;
}
.insights-detail-row {
    &.mobile {
        padding-top: 16px;
    }
}
</style>
