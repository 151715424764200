import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { TwilioCredentials } from '@/entities/appstore/twilio'
import { getConfig, postConfig, deleteConfig } from '@/api/appstore/twilio'
import { notifyError } from '@/utils/appstore'

export interface TwilioState {
    config: TwilioCredentials | null
    configUpdated: boolean
}

const INITIAL_STATE: TwilioState = {
    config: {
        accountSid: '',
        authToken: '',
        messagingServiceSid: '',
    },
    configUpdated: false,
}

const actions: ActionTree<TwilioState, any> = {
    setConfig({ commit }, config: TwilioCredentials | null): void {
        commit('setConfig', config)
    },
    setConfigUpdated({ commit }, updated: boolean): void {
        commit('setConfigUpdated', updated)
    },
    async loadConfig({ commit }): Promise<void> {
        const config = await getConfig()

        commit('setConfigUpdated', false)
        commit('setConfig', config)
    },
    async saveConfig({ commit, state }): Promise<boolean> {
        const response = await postConfig(state.config)

        if (!response.success) {
            notifyError(response.msg)
            return false
        }

        commit('setConfigUpdated', false)

        return response.success
    },
    async deleteConfig({ commit }): Promise<boolean> {
        const response = await deleteConfig()

        if (!response.success) {
            notifyError(response.msg)
            return false
        }

        return response.success
    },
}

const mutations: MutationTree<TwilioState> = {
    setConfig(state, config: TwilioCredentials) {
        state.config = config
    },
    setConfigUpdated(state, updated: boolean) {
        state.configUpdated = updated
    },
}

const getters: GetterTree<TwilioState, any> = {
    config: ({ config }) => config,
    configUpdated: ({ configUpdated }) => configUpdated,
}

export default {
    namespaced: true,
    state: INITIAL_STATE,
    getters,
    mutations,
    actions,
}
