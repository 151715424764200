<template>
    <div :class="{ 'at-mentions-below': atMentionsBelow }">
        <AtTa
            :members="members"
            :filter-match="memberSort"
            :class="[
                'atwho-wrap',
                compacted && 'compacted',
                inModal && 'in-modal',
            ]"
            name-key="name"
            @insert="onInsertMention"
        >
            <template #item="scope">
                <span class="name" v-text="scope.item.name"></span>
                <span
                    class="description"
                    v-text="scope.item.description"
                ></span>
            </template>
            <textarea
                :value="text"
                :placeholder="placeholder"
                @change="onMessageUpdate"
                @input="onMessageUpdate"
                @keydown="onInputKeydown"
            ></textarea>
        </AtTa>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import AtTa from 'vue-at/dist/vue-at-textarea'
import { IRecipient } from '@/entities/notices'
import { pendoTrackEvent } from '@/utils/pendo'

@Component({
    components: {
        AtTa,
    },
    emits: ['mention', 'input'],
})
export default class MentionableEditor extends Vue {
    @Prop({ type: Array, default: [] }) public members!: IRecipient[]
    @Prop({ type: String, default: '' }) public placeholder!: string
    @Prop({ type: String, default: '' }) public text!: string
    @Prop({ type: Boolean, default: false }) public compacted!: boolean
    @Prop({ type: Boolean, default: false }) public inModal!: boolean
    @Prop({ type: Boolean, default: false }) public atMentionsBelow?: boolean

    //TODO: Clean this up entire file up, seems to be taken from WebView/TextInput.
    public memberSort(name, chunk) {
        return name.toLowerCase().indexOf(chunk.toLowerCase()) === 0
    }

    public onInputKeydown(evt) {
        if (evt.key === '@') {
            pendoTrackEvent('mention_started')
        }
    }

    public onInsertMention(recipient: IRecipient) {
        const mentions = this.members.filter((mention) =>
            this.text.includes(`@${mention.name} `)
        )
        // when user select a recipient, the function is called, but the name of new
        // receipient has not been added in to text, therefore we should push the new
        // receipient manually
        mentions.push(recipient)

        pendoTrackEvent('mention_added')
        this.$emit('mention', mentions)
    }

    public onMessageUpdate(e: Event) {
        this.$emit('input', (e.currentTarget as any).value)
        // when DELETE any charactor, we need to walk through the mentions array to
        // confirm whether any mentions have been removed
        const mentions = this.members.filter((mention) =>
            this.text.includes(`@${mention.name} `)
        )
        this.$emit('mention', mentions)
    }
}
</script>

<style scoped lang="less">
@import '../styles/layout.less';
@import '../styles/palette.less';

input::placeholder,
textarea::placeholder {
    color: #a9a9a9;
    font-weight: normal !important;
    font-size: 16px;
}

.atwho-wrap {
    flex: 1;
    display: flex;
    height: 80%;

    .description {
        font-size: 11px;
        color: #8097b1;
    }

    .name {
        flex: 1;
        padding-right: 6px;
    }

    textarea {
        flex: 1;
        outline: none;
        border: none;
        padding: 10px 20px;
        font-size: 16px;
        resize: none;
        color: #2f384c;
        line-height: 24px;
        font-family: Roboto, sans-serif;
    }
}

.in-modal {
    textarea {
        margin: 8px 0;
        padding: 0 !important;
        font-size: 14px !important;
        line-height: 22px !important;
    }

    input::placeholder,
    textarea::placeholder {
        color: #b7bbc0;
        font-size: 14px;
    }
}
</style>

<style lang="less">
.atwho-panel {
    .atwho-inner {
        position: fixed; // required to avoid .atwho-view from being cropped when in modal
        z-index: 998;
    }

    .atwho-view {
        max-width: 350px !important;

        .atwho-li {
            font-weight: normal !important;
        }
    }
}

.compacted.atwho-wrap {
    .atwho-ul {
        max-height: 98px !important; // Need to reduce to 98 to avoid top edge overlap in SuggestionAction page
    }
}

.at-mentions-below {
    .atwho-view {
        bottom: inherit !important;
        top: 24px !important;
    }
}
</style>
