<template>
    <OverCardModal :close-popup="onClosePopup">
        <template #header-center>
            <Typography
                variant="subtitle1"
                :class="['message-context-text', composerType]"
                >{{ messageType }}
            </Typography>
        </template>

        <template #default>
            <!-- Customer Reply Template Selector -->
            <div
                v-if="showTemplateDropdown && sendReplyToContact"
                class="message-option"
                @click="() => (showReplyTemplateModal = true)"
            >
                <Typography
                    variant="body2"
                    class="option-label"
                    :class="['message-context-text', composerType]"
                >
                    Template
                </Typography>
                <div class="option-content type">
                    <Typography
                        variant="body2"
                        :class="['message-context-text', composerType]"
                    >
                        {{ templateName }}
                    </Typography>
                    <FontAwesomeIcon
                        :icon="chevronDownIcon"
                        :class="['message-context-text', composerType]"
                    />
                </div>
            </div>
            <ReplyTemplateSelectorModal
                :modal-open="showReplyTemplateModal"
                :loading-templates="loadingTemplates"
                @closeModal="() => (showReplyTemplateModal = false)"
            />

            <MentionWarningModal
                :modal-open="showMentionWarningModal"
                @closeModal="() => (showMentionWarningModal = false)"
            />

            <MomentTextBox
                :value="mentionText"
                :placeholder="inputPlaceHolder"
                class="moment-modal-rich-text"
                @input="catchInput"
            />
        </template>

        <template #footer>
            <div
                v-if="!showTemplateDropdown && $companyVars.has_notices"
                class="footer-pill-buttons"
            >
                <div class="shoutout-coaching-label">
                    SEND TO ALL MENTIONED USERS AS A
                </div>
                <PillButton
                    label="SHOUTOUT"
                    :class="['shoutout', shoutoutSelected]"
                    @click="toggleButton(messageTypeValues.shoutout.toString())"
                />
                <PillButton
                    label="COACHING TIP"
                    :class="['coaching', coachingSelected]"
                    @click="toggleButton(messageTypeValues.coaching.toString())"
                />
            </div>
            <div
                v-if="showTemplateDropdown && hasPermission"
                class="footer-checkbox"
                @click.stop="sendReplyToggle()"
            >
                <FontAwesomeIcon
                    v-if="sendReplyToContact"
                    class="checkbox"
                    :icon="faCheckSquare"
                    size="lg"
                />
                <FontAwesomeIcon
                    v-else
                    class="checkbox"
                    :icon="faSquare"
                    size="lg"
                />
                <label class="send-reply-label">SEND REPLY TO CONTACT</label>
            </div>

            <MomentModalFooter
                :can-send-message="sendButtonDisabled"
                :can-add-image="canAddImage"
                @send="onSendMoment"
            />
        </template>
    </OverCardModal>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import useEmitter from '@/composables/useEmitter'
import { Getter, namespace } from 'vuex-facing-decorator'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faChevronDown } from 'fontawesome/free-solid-svg-icons'
import { faCheckSquare, faSquare } from 'fontawesome/free-regular-svg-icons'
import MomentTextBox from '@/mobile/src/components/appV4/MomentTextBox.vue'
import {
    MessageTypeLabel,
    MessageTypeValues,
    ModalSource,
} from '@/mobile/src/types/messageTypes'
import OverCardModal from '@/mobile/src/components/appV4/OverCardModal.vue'
import MomentModalFooter from '@/mobile/src/components/appV4/MomentModalFooter.vue'
import Typography from '@/components/Rain/Typography/Typography.vue'
import MessageTypePickerModal from '@/mobile/src/components/appV4/MessageTypePickerModal.vue'
import MessageRecipientPickerModal from '@/mobile/src/components/appV4/MessageRecipientPickerModal.vue'
import ReplyTemplateSelectorModal from '@/mobile/src/components/appV4/ReplyTemplateSelectorModal.vue'
import MentionWarningModal from '@/mobile/src/components/appV4/feedback/MentionWarningModal.vue'
import PillButton from '@/components/PillButton.vue'
import { Permissions } from '@/mobile/src/types/auth'
import { MomentMessageRecipient } from '@/entities/moment'
import { ChatTemplate } from '@/entities'
import { ToastColor, ToastIcon } from '@/mobile/src/types/toasts'
import { hideToast, showToast } from '@/mobile/src/utils/toastbus'
import { pendoTrackEvent } from '@/utils/pendo'
import { cleanMomentText } from '@/utils/moments'
import { Dialog } from '@capacitor/dialog'

const MomentsModule = namespace('moments')

@Component({
    components: {
        ReplyTemplateSelectorModal,
        MomentTextBox,
        MessageRecipientPickerModal,
        MessageTypePickerModal,
        FontAwesomeIcon,
        Typography,
        MomentModalFooter,
        OverCardModal,
        PillButton,
        MentionWarningModal,
    },
    emits: ['message-sent'],
})
export default class FeedbackMessageModal extends Vue {
    @Prop({ type: Function, required: false }) public closePopup!: Function
    @Prop({ type: String, required: true }) private modalSource!: string
    @Prop({ type: String, required: false, default: 'Everyone' })
    private selectedRecipientName!: string
    @Prop({ type: String, default: null }) momentid?: string
    @Prop({ type: String, default: null }) feedbackid?: string
    @Prop({ type: Boolean, default: false }) initAsReply?: boolean
    @Prop({ type: String, default: '' }) initWithText?: string
    @Getter public $companyVars

    @Getter chatMessages
    @Getter chatReply
    @Getter userId!: number

    public sendingToastTimeId = 0
    public sendingMoment = false

    public showReplyTemplateModal = false
    public showMentionWarningModal = false
    public loadingTemplates = false
    public messageTypeValues = MessageTypeValues
    public baseMomentId: string | undefined = undefined
    public baseClosePopup!: Function
    public baseModalSource = ModalSource.MomentsScreen.toString()

    private emitter = useEmitter()

    @MomentsModule.Getter composerType!: string
    @MomentsModule.Getter loadingRecipients!: boolean
    @MomentsModule.Getter selectedRecipients!: MomentMessageRecipient[]
    @MomentsModule.Getter mentionText!: string
    @MomentsModule.Getter subject!: string
    @MomentsModule.Getter showToField!: boolean
    @MomentsModule.Getter customerReplyTemplates!: ChatTemplate[]
    @MomentsModule.Getter selectedReplyTemplate!: ChatTemplate | null
    @MomentsModule.Getter composerSendToContact!: boolean
    @MomentsModule.Getter getComposerSource!: string
    @MomentsModule.Getter availableComposerRecipients!: MomentMessageRecipient[]
    @MomentsModule.Getter availableComposerHashtags
    @MomentsModule.Getter loadingHashtags
    @Getter protected userPermissions!: string[]
    @MomentsModule.Getter currentMentionedUserIds!: Number[]

    @MomentsModule.Action loadComposerRecipients
    @MomentsModule.Action selectNewRecipient
    @MomentsModule.Action setMentionText
    @MomentsModule.Action setSubject
    @MomentsModule.Action initAvailableComposerTypes
    @MomentsModule.Action sendMoment
    @MomentsModule.Action setComposerSendToContact
    @MomentsModule.Action resetComposer
    @MomentsModule.Action setComposerSource
    @MomentsModule.Action loadComposerHashtags
    @MomentsModule.Action setSelectedReplyTemplate
    @MomentsModule.Action setComposerType
    @MomentsModule.Action setSelectedRecipients
    @MomentsModule.Action prefillEmailReplyRecipient
    @MomentsModule.Action loadCustomerReplyTemplates

    public chevronDownIcon = faChevronDown
    private inputPlaceHolderText = 'Add a reply...'
    private inputPlaceHolder = this.inputPlaceHolderText
    public sendReplyToContact = false
    public showTemplateDropdown = true
    public hasPermission = true // user has permission to send EmailReply
    readonly faCheckSquare = faCheckSquare
    readonly faSquare = faSquare

    public currentComposerType = this.composerType

    private rollbackComposerType = ''
    private rollbackTime = 0
    private shoutoutCoaching = [
        MessageTypeValues.shoutout.toString(),
        MessageTypeValues.coaching.toString(),
    ]

    public async created() {
        // Default composer
        if (this.modalSource !== this.getComposerSource) {
            await this.resetComposer()
            await this.setComposerSource(this.modalSource)
        }

        this.sendReplyToContact = this.composerSendToContact

        if (
            !this.availableComposerRecipients.length &&
            !this.loadingRecipients
        ) {
            await this.loadComposerRecipients(this.userId)
        }

        if (!this.availableComposerHashtags.length && !this.loadingHashtags) {
            await this.loadComposerHashtags(
                this.$companyVars.has_hashtag_whitelist
            )
        }

        // Set recipient as EVERYONE
        if (
            this.selectedRecipientName &&
            this.composerType !== MessageTypeValues.emailReply.toString()
        ) {
            this.setEveryoneRecipients()
        }

        if (this.initAsReply) {
            this.sendReplyToContact = this.initAsReply
        }
        if (this.initWithText) {
            this.setMentionText(this.initWithText)
        }

        this.initAvailableComposerTypes(this.modalSource)
        this.setSelectedReplyTemplate(null)
        await this.showEmailReply()

        // Prefill email reply for reply templates
        if (
            (this.chatReply.message || this.chatReply.subject) &&
            this.composerType === MessageTypeValues.emailReply.toString()
        ) {
            if (!this.mentionText) {
                this.setMentionText(this.chatReply.message)
            }
            this.setSubject(this.chatReply.subject)
        }

        this.baseMomentId = this.momentid
        this.baseClosePopup = this.closePopup
        this.baseModalSource = this.modalSource.toString()
    }

    public catchInput(mentionText: string) {
        this.rollbackComposerTypeWhenMention(mentionText)

        if (mentionText.includes('@')) {
            if (
                !this.sendReplyToContact &&
                MessageTypeValues.internalNote.toString() === this.composerType
            ) {
                this.showTemplateDropdown = false
                this.internalNoteEntry()
            } else if (this.sendReplyToContact) {
                // email-reply.
                this.showMentionWarningModal = true
            }
        } else if (!mentionText.includes('@') && !this.sendReplyToContact) {
            this.showTemplateDropdown = true
            this.internalNoteEntry()
        }
        this.setMentionText(mentionText)
    }

    public get templateName() {
        return this.selectedReplyTemplate?.name ?? 'Load a reply template'
    }

    /*
     * For the workaround of the below behavior:
     *   Mention user selection will trigger multiple mentionText updates
     *   with the first empty content, then full text with mentioned.
     *   That will cause Shoutout/Coaching selections change back to InternalNote.
     */
    private rollbackComposerTypeWhenMention(mentionText: string) {
        let changeWithin = Date.now() - this.rollbackTime
        // if text is updated within 100 milliseconds plus ...
        if (
            changeWithin < 100 &&
            this.shoutoutCoaching.includes(this.rollbackComposerType) &&
            !this.shoutoutCoaching.includes(this.composerType)
        ) {
            this.setComposerType(this.rollbackComposerType)
            this.showTemplateDropdown = false
            this.setMentionText(mentionText)
        } else {
            this.rollbackComposerType = this.composerType
            this.rollbackTime = Date.now()
        }
    }

    private internalNoteEntry() {
        this.setComposerType(MessageTypeValues.internalNote.toString())
        this.setSelectedRecipients([])
        this.inputPlaceHolder = `Add an ${MessageTypeLabel.internalNote.toLowerCase()}...`
    }

    public async sendReplyToggle() {
        this.sendReplyToContact = !this.sendReplyToContact
        this.setComposerSendToContact(this.sendReplyToContact)
        if (!this.sendReplyToContact) {
            this.internalNoteEntry()
        } else {
            await this.showEmailReply()
        }
    }

    public toggleButton(composerTyper: string) {
        if (this.composerType !== composerTyper) {
            // switch between shoutout/coaching tip
            this.setComposerType(composerTyper)
            this.setSelectedRecipients([])
            this.inputPlaceHolder = `Add a ${composerTyper.toLowerCase()}...`
        } else {
            this.internalNoteEntry()
        }
    }

    private setEveryoneRecipients() {
        this.selectNewRecipient(
            this.availableComposerRecipients.find(
                (recipient) =>
                    recipient.name?.toLowerCase() ==
                    this.selectedRecipientName?.toLowerCase()
            )
        )
    }

    public async showEmailReply() {
        if (
            this.userPermissions.includes(Permissions.RoleUserBasics) &&
            this.sendReplyToContact
        ) {
            // emailReply
            if (this.feedbackid) {
                this.loadCustomerReplyTemplates(this.feedbackid)
            }
            this.setComposerType(MessageTypeValues.emailReply.toString())
            this.prefillEmailReplyRecipient()
            this.inputPlaceHolder = this.inputPlaceHolderText
        } else {
            // No permission to send Email Reply OR sendReplyToContact is un-checked.
            this.sendReplyToContact = false
            this.internalNoteEntry()
        }

        if (!this.userPermissions.includes(Permissions.RoleUserBasics)) {
            this.hasPermission = false
        }
    }

    public get sendButtonDisabled() {
        return (
            (this.composerType === MessageTypeValues.emailReply.toString() &&
                this.selectedRecipients.length === 0) ||
            !this.hasMessageText() ||
            ((this.composerType === MessageTypeValues.coaching.toString() ||
                this.composerType === MessageTypeValues.shoutout.toString()) &&
                this.currentMentionedUserIds.length === 0)
        )
    }

    protected hasMessageText() {
        const div = document.createElement('div')
        div.innerHTML = this.mentionText
        return (div.textContent || div.innerText || '').trim().length > 0
    }

    public get shoutoutSelected() {
        return this.composerType === MessageTypeValues.shoutout.toString()
            ? 'shoutout-selected'
            : ''
    }

    public get coachingSelected() {
        return this.composerType === MessageTypeValues.coaching.toString()
            ? 'coaching-selected'
            : ''
    }

    public get canAddImage() {
        return false
    }

    public async onSendMoment() {
        if (this.sendingMoment) {
            return
        }
        this.showSendingToast()

        try {
            const data = await this.sendMoment({
                momentid: this.baseMomentId,
                mentionText: this.mentionText,
                chatMessages: this.chatMessages,
            })

            this.hideSendingToast()
            if (data.success) {
                this.showSuccessToast()
                this.baseClosePopup()
                if (this.baseModalSource !== ModalSource.FeedbackDetailScreen) {
                    this.resetComposer()
                }
                this.$emit('message-sent')
                this.setMentionText('')

                return
            }
        } catch (error) {
            this.hideSendingToast()
        }
        this.showErrorToast()
    }

    protected getToastColor() {
        switch (this.messageType) {
            case MessageTypeLabel.coaching:
                return ToastColor.Coaching
            case MessageTypeLabel.shoutout:
                return ToastColor.Shoutout
            case MessageTypeLabel.internalNote:
                return ToastColor.InternalNote
            case MessageTypeLabel.emailReply:
                return ToastColor.EmailReply
        }
        return ToastColor.Message
    }

    public showSuccessToast() {
        showToast(
            this.emitter,
            ToastIcon.Success,
            this.getToastColor(),
            `${this.messageType} sent`,
            2000
        )
    }

    public get messageType() {
        return MessageTypeLabel[this.composerType]
    }

    public showErrorToast() {
        showToast(
            this.emitter,
            ToastIcon.Alert,
            ToastColor.Warning,
            `${this.messageType} failed to send`,
            2000
        )
        pendoTrackEvent(`message_composer_error_toast`, {
            type: this.messageType,
        })
    }

    public showSendingToast() {
        this.sendingMoment = true
        this.sendingToastTimeId = showToast(
            this.emitter,
            ToastIcon.Waiting,
            this.getToastColor(),
            `Sending...`,
            0
        )
    }

    public hideSendingToast() {
        if (this.sendingToastTimeId) {
            hideToast(this.emitter, this.sendingToastTimeId)
            this.sendingToastTimeId = 0
        }
        this.sendingMoment = false
    }

    //TODO: Clean this up after we move to Vue 3 and we can more easily share logic again
    public async onClosePopup() {
        if (this.mentionText && cleanMomentText(this.mentionText)) {
            const { value } = await Dialog.confirm({
                title: 'Discard Message?',
                message: 'Are you sure you want to discard your message?',
            })
            if (!value) {
                return
            }
        }
        await this.resetComposer()
        if (this.closePopup) {
            this.closePopup()
        }
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';
@import '~@/styles/appV4/messageTypes.less';

.message-context-text {
    color: var(--type-colour);
}

.moment-modal-rich-text {
    flex-grow: 1;
    overflow: scroll;
}

.message-option {
    padding: 15px @featureGutter;
    border-bottom: @borderWidth solid @thinFog;
    display: flex;

    .option-label {
        min-width: 64px;
        margin-right: @featureGutter;
    }

    .option-content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .recipients {
            width: 100%;
        }

        .close-recipients-list-icon {
            width: 16px;
        }
    }
}

.footer-checkbox,
.footer-pill-buttons {
    padding: @featureGutter;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-top: @borderWidth solid @thinFog;
    align-items: center;
    color: @white;

    .send-reply-label,
    .shoutout-coaching-label {
        font-family: @fontFamily;
        font-size: @fontSize-sm;
        font-style: normal;
        font-weight: @fontWeight-medium;
        line-height: @lineHeight-sm;
        letter-spacing: @letterSpacing-2xl;
        text-transform: uppercase;
    }

    .send-reply-label {
        padding-left: 10px;
    }

    .shoutout-coaching-label {
        width: 100%;
        margin-bottom: 10px;
    }

    button {
        margin-right: 10px;
    }

    .shoutout {
        background-color: @offBlack;
        border-color: @successGreen;
        color: @successGreen;
    }

    .shoutout-selected {
        background-color: @successGreen;
        color: @offBlack;
    }

    .coaching {
        background-color: @offBlack;
        border-color: @warningOrange;
        color: @warningOrange;
    }

    .coaching-selected {
        background-color: @warningOrange;
        color: @offBlack;
    }
}
</style>
