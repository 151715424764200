import client from '@/api/client'
import { ICrossTabResult, IMasterCrossTabResult } from '@/entities'

export async function getMasterCrossTabResult(
    tabName: string,
    groupBy: string,
    thenBy: string,
    tenants: string
) {
    return await client.get<IMasterCrossTabResult>(
        `/crosstab/masterview/${groupBy}/${thenBy}?tenants=${tenants}`
    )
}

export async function getCrossTabResult(groupBy: string, thenBy: string) {
    return await client.get<ICrossTabResult>(
        `/crosstab/nps/${groupBy}/${thenBy}`
    )
}

export async function saveCrossTab(params) {
    return await client.post<ICrossTabResult>(`/crosstab/nps/save`, params)
}

export async function deleteCrossTab(params) {
    return await client.post<ICrossTabResult>(`/crosstab/nps/delete`, params)
}
