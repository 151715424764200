import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, Transition as _Transition, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-463b125b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "type-picker-modal"
}
const _hoisted_2 = { class: "message-type-list" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "type-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_Typography = _resolveComponent("Typography")!

  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      (_ctx.typePickerModalOpen)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_FontAwesomeIcon, {
              class: "close-icon",
              icon: _ctx.closeIcon,
              onClick: _ctx.closeTypePickerModal
            }, null, 8, ["icon", "onClick"]),
            _createElementVNode("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messageTypes, (type) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: type.type,
                  class: "type",
                  onClick: ($event: any) => (_ctx.switchMessageType(type))
                }, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_FontAwesomeIcon, {
                      class: "type-label-icon",
                      icon: _ctx.circleRightIcon,
                      style: _normalizeStyle(_ctx.typeColourStyles(type))
                    }, null, 8, ["icon", "style"]),
                    _createVNode(_component_Typography, {
                      variant: "tabs",
                      style: _normalizeStyle(_ctx.typeColourStyles(type))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(type.label), 1)
                      ], undefined, true),
                      _: 2
                    }, 1032, ["style"])
                  ]),
                  _createVNode(_component_Typography, {
                    variant: "body2",
                    style: _normalizeStyle(_ctx.typeColourStyles(type))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(type.description), 1)
                    ], undefined, true),
                    _: 2
                  }, 1032, ["style"])
                ], 8, _hoisted_3))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true)
    ], undefined, true),
    _: 1
  }))
}