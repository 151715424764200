import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TwoLineHeader = _resolveComponent("TwoLineHeader")!
  const _component_InsightsTimePickerMobile = _resolveComponent("InsightsTimePickerMobile")!
  const _component_Scrollable = _resolveComponent("Scrollable")!
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_WebViewScreen = _resolveComponent("WebViewScreen")!

  return (_openBlock(), _createBlock(_component_WebViewScreen, {
    "no-pad": "",
    class: "insights",
    "show-go-back": "",
    "disable-when-offline": ""
  }, {
    "custom-title": _withCtx(() => [
      _createVNode(_component_TwoLineHeader, {
        title: _ctx.headerTitle,
        subtitle: _ctx.friendlyTimeRange
      }, null, 8, ["title", "subtitle"])
    ]),
    "right-button": _withCtx(() => [
      _createVNode(_component_InsightsTimePickerMobile, {
        "time-range": _ctx.timeRange,
        onSelectTime: _ctx.updateTimeRange
      }, null, 8, ["time-range", "onSelectTime"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_RouterView, null, {
        default: _withCtx(({ Component }) => [
          _createVNode(_component_Scrollable, null, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
            ], undefined, true),
            _: 2
          }, 1024)
        ]),
        _: 1
      })
    ], undefined, true),
    _: 1
  }))
}