<template>
    <div>
        <LogoutHeader key="header" class="header" />
        <MFAValidation
            ref="MFAValidationRef"
            :formatted-time="formattedTime"
            :secret="secret"
            :is-verifying="loading"
            @verifyMFA="verifyMFA"
        />
    </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref, onUnmounted, inject } from 'vue'
import { getPlatform } from '@/mobile/src/utils/capacitor'
import MFAValidation from '@/mobile/src/views/appV4/mfa/MFAValidation.vue'
import LogoutHeader from '@/mobile/src/components/appV4/headers/LogoutHeader.vue'
import { loginAndRedirect } from '@/mobile/src/services/auth'
import { login } from '@/mobile/src/api/auth'
import { useRoute } from 'vue-router'
import { formatMinutesSecondsTime } from '@/utils/time'
import { LoginMainPkey, LoginMainProvided } from '@/mobile/src/types/auth'

let timerInterval: NodeJS.Timeout

const route = useRoute()

const secret = ref('')
const loading = ref(false)
const copied = ref(false)
const platform = ref('')
const MFAValidationRef = ref()
const initialTimeInSeconds = 5 * 60 // 5 minutes
const currentTimeInSeconds = ref(initialTimeInSeconds)

const { credentials } = inject(LoginMainPkey) as LoginMainProvided
const domain = route.query.domain

const formattedTime = computed(() => {
    return formatMinutesSecondsTime(currentTimeInSeconds.value)
})

const updateTimer = () => {
    timerInterval = setInterval(async () => {
        if (currentTimeInSeconds.value > 0) {
            currentTimeInSeconds.value--
        } else {
            await reloadMFA()
        }
    }, 1000)
}

onBeforeMount(async () => {
    const params = route.query
    secret.value = String(params.secret)
    updateTimer()
    platform.value = await getPlatform()
})

onUnmounted(() => {
    clearInterval(timerInterval)
})

async function verifyMFA(mfaCode: string) {
    loading.value = true
    await loginAndRedirect(
        domain,
        credentials.value.email,
        credentials.value.password,
        undefined,
        undefined,
        mfaCode
    )
    if (MFAValidationRef?.value) {
        MFAValidationRef.value.resetMFAInput()
        loading.value = false
    }
}

async function reloadMFA() {
    loading.value = true
    let result = await login(
        domain,
        credentials.value.email,
        credentials.value.password
    )
    secret.value = result.data.mfa_secret
    currentTimeInSeconds.value = initialTimeInSeconds
    loading.value = false
}
</script>
