import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-772a6877"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "month-label" }
const _hoisted_2 = { class: "goal-placeholder" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Typography = _resolveComponent("Typography")!
  const _component_HorizontalScrollContainer = _resolveComponent("HorizontalScrollContainer")!

  return (_openBlock(), _createBlock(_component_HorizontalScrollContainer, {
    class: _normalizeClass([{ 'goal-list-centered': _ctx.centered }, "goal-list"])
  }, {
    default: _withCtx(() => [
      (_ctx.goalHistory.length > 0)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.goalHistory, (item, itemIdx) => {
            return (_openBlock(), _createElementBlock("div", {
              key: itemIdx,
              class: _normalizeClass(["goal-item", { reverse: _ctx.textAboveDot }])
            }, [
              _createElementVNode("span", _hoisted_1, [
                _createVNode(_component_Typography, {
                  variant: "subtitle2",
                  class: "month-text"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.title), 1)
                  ], undefined, true),
                  _: 2
                }, 1024)
              ]),
              _createElementVNode("div", {
                class: _normalizeClass(["circle", item.achieved ? 'filled' : ''])
              }, null, 2)
            ], 2))
          }), 128))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("div", _hoisted_2, null, 512), [
        [_vShow, !_ctx.goalHistory || _ctx.goalHistory.length === 0]
      ])
    ], undefined, true),
    _: 1
  }, 8, ["class"]))
}