import { baseClient } from '@/api/client'
import { Nav } from '@/pages/dashboard/entities/nav'
import {
    ConvUIQuestion,
    ScorecardTopicSummary,
} from '@/pages/dashboard/entities/dash'

export async function getNavMenu() {
    return baseClient.get<Nav>(`/ajax/dash/menu`)
}

export async function getQuestions() {
    return baseClient.get<{ questions: ConvUIQuestion[]; nav: Nav }>(
        `/ajax/dash/questions`
    )
}

export async function getScorecardTopicSummary() {
    return baseClient.get<ScorecardTopicSummary>(
        `/ajax/dash/scorecardtopicsummary`
    )
}
