<template>
    <div v-if="achievedGoal">
        <div class="achieved-goal-container">
            <div class="goal-content">
                <div class="title">
                    {{ achievedGoal.title }}
                </div>
                <div class="description">
                    {{ achievedGoal.description }}
                </div>
                <div class="reward">
                    <div class="reward-title">
                        {{ achievedGoal.reward.title }}
                    </div>
                    <div class="reward-bonus">
                        {{ achievedGoal.reward.bonus }}
                    </div>
                </div>
            </div>
            <div class="icon">{{ achievedGoal.icon }}</div>
        </div>
        <hr />
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import { IAchievedGoal } from '@/entities/reportcard'
import { namespace } from 'vuex-facing-decorator'

const AchievedGoalModule = namespace('achievedgoal')

@Component({})
export default class AchievedGoal extends Vue {
    @Prop({ type: Number }) public month!: number
    @Prop({ type: String }) public userName!: string

    @AchievedGoalModule.Getter getAchievedGoalByUserMonth
    @AchievedGoalModule.Action loadAchievedGoalData

    public get achievedGoal(): IAchievedGoal | null {
        return this.getAchievedGoalByUserMonth(this.month, this.userName)
    }

    public mounted() {
        this.loadData()
    }

    public async loadData() {
        await this.loadAchievedGoalData({
            month: this.month,
            userName: this.userName,
        })
    }

    @Watch('month')
    @Watch('userName')
    public async onMonthChanged() {
        await this.loadData()
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/variables.less';

.achieved-goal-container {
    display: flex;
    margin-top: @featureGutter;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;

    .goal-content {
        padding-right: 26px;

        .title {
            font-size: 20px;
            line-height: 30px;
            letter-spacing: 0.15px;
        }

        .description {
            padding-top: 10px;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.2px;
            text-transform: capitalize;
        }

        .reward {
            padding-top: 10px;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 1px;
            text-transform: uppercase;

            .reward-title {
                opacity: 0.4;
            }
        }
    }

    .icon {
        padding-top: 14px;
        font-size: 40px;
        line-height: 18px;
        letter-spacing: 1px;
    }
}
</style>
