import { ActionTree, MutationTree, GetterTree } from 'vuex'

/**
 * This Vuex module is for retaining scroll positions (scrollTop) for mobile pages
 *
 * Importing the getter & action:
 * @Getter public getScrollPosition!: (page: string) => number
 * @Action public saveScrollPosition
 *
 * Using the get & set of scroll positions (e.g. Feedback.vue):
 * Set a position to 'feedback':   this.saveScrollPosition({page: 'feedback', scrollTop: scrollContainer.scrollTop})
 * Get the 'feedback' position:    this.getScrollPosition('feedback')
 */

export interface ScrollPositionsState {
    scrollPositions: {}
}

export const state: ScrollPositionsState = {
    scrollPositions: {},
}

const getters: GetterTree<ScrollPositionsState, any> = {
    getScrollPosition:
        ({ scrollPositions }) =>
        (page: string | undefined): number => {
            if (page === undefined) {
                return 0
            }
            return scrollPositions[page] ?? 0
        },
    hasScrollPosition:
        ({ scrollPositions }) =>
        (page: string | undefined): boolean => {
            return (
                page !== undefined &&
                page in scrollPositions &&
                scrollPositions[page] > 0
            )
        },
}

const actions: ActionTree<ScrollPositionsState, any> = {
    saveScrollPosition({ commit }, { page, scrollTop }) {
        commit('setScrollPosition', { page, scrollTop })
    },
    resetScrollPositions({ commit }) {
        commit('resetScrollPositions')
    },
}

const mutations: MutationTree<ScrollPositionsState> = {
    setScrollPosition(state, { page, scrollTop }) {
        state.scrollPositions[page] = scrollTop
    },
    resetScrollPositions(state) {
        state.scrollPositions = {}
    },
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations,
}
