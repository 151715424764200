import { ajaxClient as client } from '@/api/client'
import { EngagementQuestion, EngagementQuestions } from '@/entities/engagement'
import { EngagementInsights } from '@/entities/insights'

export async function getDailyEngagementQuestions() {
    return await client.get<EngagementQuestions>(
        `/insights/engagement/questions`
    )
}

export async function getCheckInQuestionHistory(userId, timeUnit, timeValue) {
    return client.get<EngagementQuestion[]>(`/insights/engagement/history`, {
        params: {
            user_id: userId,
            time_unit: timeUnit,
            time_value: timeValue,
        },
    })
}

export async function markEngagementQuestionsAsOpen(answerIds) {
    return await client.post<EngagementQuestions>(
        `/insights/engagement/questions/opened`,
        { answerIds }
    )
}

export async function answerEngagementQuestion(question: EngagementQuestion) {
    return await client.post<EngagementQuestion>(
        `/insights/engagement/questions/answer`,
        { question }
    )
}

export async function getEngagementInsights(timeUnit = 'month', timeValue = 1) {
    return client.get<EngagementInsights>(`/ajax/engagement/insights`, {
        baseURL: '/',
        params: {
            time_unit: timeUnit,
            time_value: timeValue,
        },
    })
}
