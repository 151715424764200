import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-15ab22d2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "avatar-or-initials" }
const _hoisted_2 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.user && _ctx.user.avatar)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "avatar",
          style: _normalizeStyle(_ctx.sizeStyle)
        }, [
          _createElementVNode("img", {
            src: _ctx.userAvatar,
            alt: _ctx.user.name || _ctx.user.id
          }, null, 8, _hoisted_2)
        ], 4))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "initials",
          style: _normalizeStyle(_ctx.sizeStyle)
        }, _toDisplayString(_ctx.initials), 5))
  ]))
}