<template>
    <span v-if="npsOnly" class="nps-only">
        NPS
        <i
            class="uk-icon-info-circle"
            data-uk-tooltip
            title="Currently only available for NPS survey results"
        ></i>
    </span>
    <div v-else class="filter-bar-question-type">
        <Dropdown ref="dropdown">
            <template #trigger>
                <BaseButton
                    secondary
                    muted
                    icon="chevron-down"
                    class="button-trigger"
                    data-test-tag="filter-time-button"
                >
                    {{ selectedOptionLabel }}
                </BaseButton>
            </template>

            <template #content>
                <div class="columns options">
                    <div class="option-set">
                        <div
                            v-for="option in metricOptions"
                            :key="option.label"
                            class="option"
                            @click="() => setMetricOption(option)"
                        >
                            {{ option.label }}
                        </div>
                    </div>
                </div>
            </template>
        </Dropdown>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { Action, Getter } from 'vuex-facing-decorator'
import Dropdown from './Dropdown.vue'
import BaseButton from './BaseButton.vue'
import { getQuestionTypeOptions } from '@/entities'

@Component({
    components: { Dropdown, BaseButton },
})
export default class FilterBarQuestionType extends Vue {
    public npsOnly: boolean = /dash\/crosstab\//.test(window.location.pathname)

    @Getter public filterActive
    @Getter public filterQuestionType

    @Action public setFilterQuestionType

    public get metricOptions() {
        return getQuestionTypeOptions()
    }

    public get selectedOptionLabel(): string {
        const selected = this.metricOptions.find(
            (o) => o.name === this.filterQuestionType
        )
        return selected ? selected.label : 'NPS'
    }

    public setMetricOption(option) {
        this.setFilterQuestionType({
            id: this.filterActive.id,
            question_type: option.name,
        })

        const dropdown = this.$refs.dropdown as Dropdown
        dropdown.$data['opened'] = false
    }
}
</script>

<style scoped lang="less">
@import '../styles/palette';

.nps-only {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1.03px;

    i {
        margin-left: 5px;
        font-size: 10px;
        vertical-align: super;
        color: #9399a2;
    }
}

.filter-bar-question-type {
    display: inline-block;
    height: 42px;
    padding: 0px;
}

.options {
    background: #fff;
    border-radius: 3px;
    position: relative;
}

.option-set {
    margin: 15px 0px;
    border-right: 1px solid @backgroundColor;

    &:last-child {
        border-right: none;
    }
}

.option {
    padding: 5px 15px;
    cursor: pointer;
}

.option:hover {
    background: @backgroundColor;
    color: @ctaColor;
}

label {
    display: inline-block;
    padding: 0px 10px;
}

.filter-bar-question-type .button {
    height: 42px;
    padding: 8px 17px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1.03px;
    display: flex;
    align-items: center;
}

.button-trigger {
    display: flex;
    align-items: center;
}
</style>
