<template>
    <ScorecardSubpage
        v-if="scorecard"
        :slide="slideName"
        :black="false"
        :badge-id="badgeId"
        :show-header="!embedded"
        title=""
    >
        <div class="badge-detail-container">
            <div class="header">
                <div class="title">{{ badgeLabel }}</div>
                <div class="stats">
                    <div class="month">
                        <div class="number">
                            {{ loading ? '-' : calendarMonthCount }}
                        </div>
                        <div class="label">Last 30 Days</div>
                    </div>
                    <div class="total">
                        <div class="number">
                            {{ loading ? '-' : totalCount }}
                        </div>
                        <div class="label">All Time Total</div>
                    </div>
                </div>
            </div>
            <div v-if="!loading" class="responses">
                <div
                    v-for="response in responses"
                    :key="response.id"
                    class="response"
                >
                    <ScorecardResponseTile
                        class="bottom-margin-between-tiles"
                        :response="response"
                        :show-badge="false"
                        :scorecard="scorecard"
                        @click="onClickResponse(response.id.toString())"
                    />
                </div>
            </div>
        </div>
    </ScorecardSubpage>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { useRoute, useRouter } from 'vue-router'
import ScorecardSubpage from '@/pages/scorecard/components/ScorecardSubpage.vue'
import { Action, Getter } from 'vuex-facing-decorator'
import { ScorecardEntity } from '@/entities/scorecard'
import { IResponse } from '@/entities'
import { seeBadge } from '@/api/scorecard'
import { embedded } from '@/utils/react-native-webview'
import ScorecardResponseTile from '@/components/ScorecardResponseTile.vue'

@Component({
    components: { ScorecardResponseTile, ScorecardSubpage },
})
export default class ScorecardBadgeDetail extends Vue {
    @Prop({ type: String })
    public value!: string // the room to improve badge

    @Prop({ type: String, default: null })
    public teamMemberUserId!: string // view another users scorecard / my teams

    @Getter public slide!: string
    @Getter public scorecard!: ScorecardEntity
    @Getter public scorecardResponsesMap!: { [value: string]: IResponse[] }
    @Getter public badgeTotalMap!: { [value: string]: { pastMonth; total } }
    @Getter public scorecardOutdated!: boolean
    @Getter
    hasNetworkConnection!: boolean

    @Getter
    $isMobile

    @Action public loadScorecard
    @Action public loadResponsesForBadge
    @Action public loadTotalCountForBadge
    @Action public setSlide

    public slideName = 'left'
    public loading = true

    private route = useRoute()
    private router = useRouter()

    public async beforeMount() {
        if (this.scorecardOutdated) {
            await this.loadScorecard(this.teamMemberUserId ?? null)
        }

        this.slideName = this.slide
    }

    public mounted() {
        this.load()
    }

    public async load() {
        this.loadTotalCountForBadge({
            type: 'positive',
            value: this.value,
            userId: this.teamMemberUserId ?? null,
        })

        await this.loadResponsesForBadge({
            type: 'positive',
            value: this.value,
        })
        this.loading = false

        // update the last seen timestamp of this badge
        if (
            !this.teamMemberUserId &&
            this.scorecard.badges.positive[this.value].countUnseen &&
            this.hasNetworkConnection
        ) {
            await seeBadge(this.value, 'positive')
            this.loadScorecard()
        }
    }

    public get mapKey() {
        return `${this.value}_positive`
    }

    public get badgeLabel() {
        return this.scorecard.badges.positive[this.value].label
    }

    public get badgeId() {
        return this.scorecard.badges.positive[this.value].badgeId
    }

    public get responses() {
        return (
            (this.scorecardResponsesMap &&
                this.scorecardResponsesMap[this.mapKey]) ||
            []
        )
    }

    public get totalCount() {
        return (
            (this.badgeTotalMap &&
                this.badgeTotalMap[this.mapKey] &&
                this.badgeTotalMap[this.mapKey].total) ||
            0
        )
    }

    public get calendarMonthCount() {
        return (
            (this.badgeTotalMap &&
                this.badgeTotalMap[this.mapKey] &&
                this.badgeTotalMap[this.mapKey].pastMonth) ||
            0
        )
    }

    public get embedded() {
        return embedded
    }

    public onClickResponse(id) {
        if (this.$isMobile) {
            this.router.push({ name: 'chatResponsesRoot', params: { id: id } })
        } else {
            const query = { ...this.route.query, chat: id }
            this.router.push({ path: this.route.path, query })
        }
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/layout.less';
@import '~@/styles/palette.less';

.badge-detail-container {
    background: white;
    flex: 1;

    .header {
        text-align: center;
        padding: 20px 0;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        background: white;

        .title {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #2f384c;
        }

        .stats {
            max-width: 350px;
            margin: 20px auto 0 auto;
            display: flex;
            justify-content: space-evenly;

            .number {
                font-weight: 500;
                font-size: 30px;
                line-height: 35px;
                text-align: center;

                color: #1d2330;
            }

            .label {
                font-size: 10px;
                line-height: 12px;
                text-align: center;

                color: #8798ad;
            }
        }
    }

    .responses {
        max-width: 350px;
        margin: 5px auto 20px auto;

        .response {
            padding-right: 8px;
            padding-left: 8px;
            cursor: pointer;

            .bottom-margin-between-tiles {
                margin-bottom: 12px;
            }
        }
    }
}
</style>
