<template>
    <transition name="fade">
        <div
            v-if="showModal()"
            class="hashtag-picker-modal"
            :style="positionStyle"
        >
            <Loading
                v-if="loadingHashtags"
                :loading="loadingHashtags"
                transparent
            />

            <Typography
                v-if="!loadingHashtags && !filteredHashtags.length"
                class="no-results"
                variant="body2"
            >
                Could not find results for: <strong>#{{ searchInput }}</strong>
            </Typography>

            <div
                v-for="(hashtag, index) in filteredHashtags"
                :key="index"
                class="hashtag-item"
                @mousedown="onHashTagSelect(hashtag)"
            >
                <Typography class="hashtag-name" variant="body2">
                    <span v-html="highlightSearchInName(hashtag.name)" />
                </Typography>
            </div>
        </div>
    </transition>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import Fuse from 'fuse.js'
import { htmlSanitizer } from '@/utils/string'
import { MomentMessageHashtag } from '@/entities/moment'
import Typography from '@/components/Rain/Typography/Typography.vue'
import Loading from '@/components/Loading.vue'
import { pendoTrackEvent } from '@/utils/pendo'
import store from '@/mobile/src/store'

interface Props {
    pendoName: string
    minimumSearchLength?: number
    searchInput: string
    setSearchInput?: (searchString: string) => void
    selectNew?: (hashtag: MomentMessageHashtag) => void
    top: number
    bottom: number
    clearOnNoResults: boolean
    showHashtagModal: boolean
}
const props = withDefaults(defineProps<Props>(), {
    pendoName: '',
    minimumSearchLength: 1,
    searchInput: '',
    setSearchInput: () => ({}),
    selectNew: () => ({}),
    top: 0,
    bottom: 0,
    clearOnNoResults: false,
    showHashtagModal: false,
})

const emit = defineEmits(['hashtag-selected'])

const availableComposerHashtags = computed(
    () => store.getters['moments/availableComposerHashtags']
)
const loadingHashtags = computed(() => store.getters['moments/loadingHashtags'])

const modalOpened = ref(false)

// Dynamically determines whether to position the modal using top/bottom, whichever gives the most screen height
// If both bottom and top are set, check if it'd make more sense to use top
// Otherwise, if bottom is set, use that
// Otherwise, if top is set, use that
// Otherwise just let it hang out where-ever the absolute positioning allows it
const positionStyle = computed(() => {
    if (props.bottom && props.top) {
        const middle = window.innerHeight / 2
        if (props.top < middle) {
            return { top: props.top + 'px' }
        }
    }
    if (props.bottom) {
        return { bottom: props.bottom + 'px' }
    }
    if (props.top) {
        return { top: props.top + 'px' }
    }
    return {}
})

const onHashTagSelect = (hashtag: MomentMessageHashtag) => {
    props.selectNew(hashtag)
    // Reset search state
    props.setSearchInput('')
    emit('hashtag-selected')
}

const showModal = () => {
    let isOpen = props.searchInput?.length >= props.minimumSearchLength

    if (props.showHashtagModal) {
        isOpen = true
    }

    if (isOpen !== modalOpened.value) {
        pendoTrackEvent(
            `moments-${props.pendoName}-picker-${isOpen ? 'opened' : 'closed'}`
        )
        modalOpened.value = isOpen
    }

    return isOpen
}

const highlightSearchInName = (name: string): string => {
    const searchString = htmlSanitizer(props.searchInput)
    if (searchString === '') {
        return '<strong>#</strong>' + name
    }
    const regex = new RegExp(searchString, 'ygi')
    return name.replace(regex, `<strong>#${searchString}</strong>`)
}

const filteredHashtags = computed(() => {
    if (props.searchInput === '') {
        return availableComposerHashtags.value
    }
    const results = fuse.value.search(props.searchInput)
    if (props.clearOnNoResults && !results.length && showModal()) {
        props.setSearchInput('')
    }
    return results
})

const fuse = computed(() => {
    return new Fuse(availableComposerHashtags.value, {
        keys: ['name'],
        shouldSort: true,
        threshold: 0,
        location: 0,
        distance: 0,
        maxPatternLength: 32,
        minMatchCharLength: props.minimumSearchLength,
    })
})
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';
@import '~@/mobile/src/styles/animations.less';
@import '~@/mobile/src/styles/zindex.less';
@import '~@/mobile/src/styles/message-composer.less';

.fade-transition-animation(0.2s);

.hashtag-picker-modal {
    @sidePadding: 16px;
    .modal-container();

    max-height: 220px;
    height: auto;
    padding: 18px @sidePadding;
    width: calc(
        100vw - ((@featureGutter * 2) + (@sidePadding * 2))
    ); // Account for inner and outer padding/margin
    overflow-y: scroll;

    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: @gutterSpacing-md;
}

.hashtag-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.no-results,
.hashtag-name {
    color: @hoverSecondaryLightBlue;
}

.hashtag-name {
    width: 100%;

    &:first-letter {
        text-transform: none;
    }
}
</style>
