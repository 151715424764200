<template>
    <WebViewScreen no-pad title="Select Notice Type">
        <template #left-button>
            <BackButton />
        </template>
        <NoticeTypePicker
            :selected="composer.type"
            @select="onSelectNoticeType"
        />
    </WebViewScreen>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { useRoute, useRouter } from 'vue-router'
import { Action, Getter } from 'vuex-facing-decorator'
import { IComposer, IRecipient } from '@/entities/notices'
import WebViewScreen from '@/components/WebView/WebViewScreen.vue'
import NoticeTypePicker from '@/components/Notices/NoticeTypePicker.vue'
import BackButton from '@/mobile/src/components/BackButton.vue'

@Component({
    components: {
        BackButton,
        WebViewScreen,
        NoticeTypePicker,
    },
})
export default class NoticeTypePickerScreen extends Vue {
    @Prop({ type: String, default: null })
    public preselectedUserId!: string // auto-populate user for notice
    @Prop({ type: String, default: null })
    public customBackRouteName!: string // Optionally redirect back to a specific page

    @Getter private composer!: IComposer

    @Action private setComposerNoticeType
    @Action private resetComposer
    @Action private loadRecipients

    private router = useRouter()
    private route = useRoute()

    private get mailbox() {
        return this.route.path.split('/')[1]
    }

    private async onSelectNoticeType(noticeType) {
        await this.setComposerNoticeType(noticeType)
        // different slide animation directions from mailbox/composer
        if (this.route.path.indexOf('editor') > 0) {
            // /notices/scheduled/pick-type
            await this.router.back()
        } else {
            // /notices/scheduled/editor/compose/pick-type
            await this.router.push({
                name: 'notices-editor-compose',
                params: {
                    mailbox: this.mailbox,
                    preselectedUserId: this.preselectedUserId,
                },
            })
        }
    }

    private onBackClick() {
        this.router.back()
    }

    private goToMailbox() {
        this.resetComposer()
        this.router.push({ name: 'notices' })
    }
}
</script>
