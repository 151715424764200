import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1e50c920"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "select-container" }
const _hoisted_2 = ["value", "selected"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Typography = _resolveComponent("Typography")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createBlock(_component_Typography, {
          key: 0,
          variant: "overline",
          class: "select-label"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.label) + ": ", 1)
          ], undefined, true),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createElementVNode("select", {
      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('input', $event.target.value)))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, optionIdx) => {
        return (_openBlock(), _createElementBlock("option", {
          key: optionIdx,
          value: option.value,
          selected: _ctx.value === option.value
        }, _toDisplayString(option.label), 9, _hoisted_2))
      }), 128))
    ], 32)
  ]))
}