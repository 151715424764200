import { setBetaAppAccess } from '@/api/settings/account/users'

/**
 * Allows user to change their own user-level beta app access from 'More' pages
 *
 * @param canAccess
 */
export async function toggleBetaAppAccess(canAccess: boolean) {
    setBetaAppAccess(canAccess).then(() => {
        // Make the app refresh (no capacitor solution unfortunately! but this works okay)
        window.location.reload()
        window.location.href = canAccess ? '/evenmore' : '/more'
    })
}
