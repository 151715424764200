<template>
    <FilterBarDownload
        :download-file-async="downloadFileAsync"
        @toggle="toggleModal"
    >
        <Typography variant="body1">We've summarized your key response data in a presentation ready for
            download.</Typography>
        <br />
        <Typography variant="body1">Your user role and current dashboard filters will both be applied
            to the data in the presentation.</Typography>
        <DropdownMultiCheckbox
            class="leaderboard-select"
            :options="leaderboardList"
            :values="selectedLeaderboards"
            label="Leaderboards"
            @change="onLeaderboardSelect"
        />
    </FilterBarDownload>
</template>

<script lang="ts">
import { Component, Watch, Vue } from 'vue-facing-decorator'
import { Action, Getter } from 'vuex-facing-decorator'
import FilterBarDownload from '@/components/FilterBarDownload.vue'
import { getGeneralNPSReportAsync } from '@/api/teamscoreboard'
import { IFilter } from '@/entities'
import DropdownMultiCheckbox from '@/components/Rain/Inputs/DropdownMultiCheckbox.vue'
import Typography from '@/components/Rain/Typography/Typography.vue'
import { Option } from '@/entities/rain/dropdown'

@Component({
    components: {
        DropdownMultiCheckbox,
        FilterBarDownload,
        Typography,
    },
})
export default class GeneralNPSReportDownload extends Vue {
    @Getter public filterActive!: IFilter | undefined
    @Getter public reportLeaderboardList!: Option[]
    @Action public loadLeaderboardList!: () => Promise<void>

    private forceNewDownload = Math.random()
    private leaderboardsLoaded = false

    public allOption: Option = { label: 'All leaderboards', value: 'ALL' }
    public selectedLeaderboards: Option[] = [this.allOption]

    @Watch('filterActive')
    public refreshForceNewDownload() {
        this.forceNewDownload = Math.random()
    }

    public onLeaderboardSelect(option: Option, checked: boolean) {
        if (checked) {
            // If ALL gets checked, reset all others
            if (option === this.allOption) {
                this.selectedLeaderboards = [this.allOption]
                return
            }

            // Unselect ALL option if individual options selected
            this.selectedLeaderboards = this.selectedLeaderboards.filter(
                (obj: Option) => obj.value !== this.allOption.value
            )

            const isDuplicate = this.selectedLeaderboards.some(
                (obj: Option) => obj.value === option.value
            )
            if (!isDuplicate) {
                this.selectedLeaderboards.push(option)
            }
        } else {
            // Remove unchecked option
            this.selectedLeaderboards = this.selectedLeaderboards.filter(
                (obj: Option) => obj.value !== option.value
            )
        }

        this.refreshForceNewDownload()
    }

    public get leaderboardList() {
        return [this.allOption, ...this.reportLeaderboardList]
    }

    public toggleModal() {
        // load leaderboards if it hasn't been loaded when displaying the modal
        if (
            !this.leaderboardsLoaded ||
            !this.reportLeaderboardList ||
            !this.reportLeaderboardList.length
        ) {
            this.loadLeaderboardList()
            this.leaderboardsLoaded = true
        }
    }

    public async downloadFileAsync() {
        return getGeneralNPSReportAsync({
            refreshDownload: this.forceNewDownload,
            leaderboards: this.selectedLeaderboards.map((obj) => obj.value),
        })
    }
}
</script>

<style scoped lang="less">
@import '../styles/palette';
.leaderboard-select {
    margin-top: 21px;
}
</style>
