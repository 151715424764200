<template>
    <div
        id="refresh-container"
        ref="ptrContainer"
        :style="refreshContainerStyles"
        :class="refreshContainerClasses"
    >
        <div v-if="!disable" id="refresh-indicator">
            <FontAwesomeIcon
                v-if="showRefreshingIcon"
                :class="['refresh-icon', { loading: isRefreshing }]"
                :icon="faArrowRotateRight"
                :style="iconStyles"
            />
            <Typography variant="body1" class="refresh-text">{{
                refreshText
            }}</Typography>
        </div>

        <div id="refresh-content">
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from 'vue-facing-decorator'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faArrowRotateRight } from 'fontawesome/free-solid-svg-icons'
import Typography from '@/components/Rain/Typography/Typography.vue'
import { easeOutExpo } from '@/utils/easing'

@Component({
    components: {
        Typography,
        FontAwesomeIcon,
    },
    emits: ['refresh-action'],
})
export default class PullToRefreshWrapper extends Vue {
    @Prop({ type: Boolean, required: false })
    public readonly disable!: boolean
    @Prop({ type: Boolean, required: true })
    public readonly ptrRefreshing!: boolean
    @Prop({ type: String, required: false, default: '' })
    public readonly refreshContainerClasses!: string

    public scrollTop = 0
    public refreshStarted = false
    public isRefreshing = false
    public refreshText = ''
    public currentY = 0
    public startY = 0
    public loadingRotation = 0
    public showRefreshingIcon = false
    public readonly pullToRefreshHeight = 60
    public readonly faArrowRotateRight = faArrowRotateRight

    public refresh!: HTMLDivElement
    public pageContent!: HTMLDivElement

    public mounted() {
        this.refresh = this.$refs.ptrContainer as HTMLDivElement
        this.pageContent = document.getElementById(
            'page-content'
        ) as HTMLDivElement

        this.refresh?.addEventListener('touchmove', (event) =>
            this.touchMove(event)
        )
        this.refresh?.addEventListener('touchend', () => this.touchEnd())
        this.pageContent?.addEventListener('scroll', (event) =>
            this.onScroll(event)
        )
    }

    public destroy() {
        this.refresh?.removeEventListener('touchmove', (event) =>
            this.touchMove(event)
        )
        this.refresh?.removeEventListener('touchend', () => this.touchEnd())
        this.pageContent?.removeEventListener('scroll', (event) =>
            this.onScroll(event)
        )
    }

    public get refreshContainerStyles() {
        if (this.isRefreshing) {
            return {
                transform: `translate(0px, ${this.pullToRefreshHeight}px)`,
            }
        }

        // Avoid applying styles until the user has pulled down at least 1px - this avoids applying the styles on initial touch event
        if (this.refreshStarted && this.currentY > this.startY) {
            return {
                transform: `translate(0px, ${this.scrollResistance}px)`,
                transitionDuration: '0ms',
                transitionDelay: '0ms',
            }
        }

        return {}
    }

    public get scrollResistance(): number {
        if (this.currentY - this.startY <= this.pullToRefreshHeight) {
            return this.currentY - this.startY
        } else {
            // Ease out pull distance the more the user pulls down to limit an infinite pull down
            return (
                this.pullToRefreshHeight +
                easeOutExpo(
                    this.currentY - this.startY,
                    this.pullToRefreshHeight,
                    this.pullToRefreshHeight * 10
                ) *
                    50
            )
        }
    }

    public get iconStyles() {
        // Spin the loading icon based on how far the user has pulled down the screen
        this.loadingRotation =
            this.loadingRotation > 360 ? 360 : (this.currentY - this.startY) * 4

        return {
            transform: `rotate(${this.loadingRotation}deg)`,
        }
    }

    public async touchEnd() {
        if (this.isRefreshing) {
            return
        }

        if (
            this.currentY - this.startY > this.pullToRefreshHeight &&
            !this.ptrRefreshing &&
            !this.disable
        ) {
            this.isRefreshing = true
            this.refreshText = 'Loading...'
            this.$emit('refresh-action')
        } else {
            this.resetPullToRefresh()
        }
    }

    @Watch('ptrRefreshing')
    public async refreshChanged() {
        if (!this.ptrRefreshing) {
            this.showRefreshingIcon = false
            this.resetPullToRefresh()
        }
    }

    private resetPullToRefresh() {
        this.isRefreshing = false
        this.refreshStarted = false
        this.showRefreshingIcon = false
        this.currentY = 0
        this.startY = 0
        this.loadingRotation = 0
    }

    public async touchMove(event) {
        if (this.isRefreshing || this.disable) {
            return
        }

        if (this.scrollTop === 0 && !this.isRefreshing && !this.disable) {
            this.refreshText = 'Pull to refresh'
            this.currentY = event.touches[0].clientY

            if (!this.refreshStarted) {
                this.refreshStarted = true
                this.showRefreshingIcon = true
                this.startY = this.currentY
            }
            if (this.currentY - this.startY > this.pullToRefreshHeight) {
                this.refreshText = 'Release to refresh'
            }
        }
    }

    public onScroll(event) {
        this.scrollTop =
            event.target?.scrollTop < 0 ? 0 : event.target?.scrollTop
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/variables.less';
@import '~@/styles/rain/colour.less';

#refresh-container {
    transition: transform 0.3s ease;
    &.full-height {
        height: 100%;
        #refresh-content {
            height: 100%;
        }
    }
}

#refresh-indicator {
    background: linear-gradient(@transparent, @thinFog);
    width: 100vw;
    height: @mobilePullToRefreshHeight;
    position: fixed;
    left: -@featureGutter;
    top: -@mobilePullToRefreshHeight;

    display: flex;
    justify-content: center;
    align-items: center;
}

.refresh-text,
.refresh-icon {
    color: @white;
}

.refresh-text {
    margin-left: @gutterSpacing-md;
}

.refresh-icon {
    &.loading {
        animation-name: spin;
        animation-duration: 1500ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;

        @keyframes spin {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(360deg);
            }
        }
    }
}
</style>
