<template>
    <div :class="{ 'engagement-team-members-list': onSidebar }">
        <div
            :class="[
                'list-item',
                {
                    selected,
                    wide: wideLayout,
                    narrow: !wideLayout,
                    mobile: $isMobile,
                },
            ]"
        >
            <div class="avatar">
                <slot name="icon"></slot>
            </div>
            <div :class="['details', { desktop: wideLayout }]">
                <slot name="title"></slot>
                <slot v-if="!wideLayout" name="subtitle"></slot>
            </div>
            <div v-if="wideLayout" class="desktop-subtitle">
                <slot name="subtitle"></slot>
            </div>
            <div :class="['number', { 'desktop-number': wideLayout }]">
                <slot name="score"></slot>
            </div>
            <div
                v-if="showChevron"
                :class="['desktop-chevron', { selected, wide: wideLayout }]"
            >
                <FontAwesomeIcon
                    class="right-chevron-icon"
                    :icon="faChevronRight"
                />
            </div>
        </div>
        <div v-if="showFooter" class="footer">
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { faChevronRight } from 'fontawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'

@Component({
    components: { FontAwesomeIcon },
})
export default class InsightsDetailRow extends Vue {
    faChevronRight = faChevronRight

    @Prop({ required: false, default: false })
    showFooter

    @Prop({ required: false, default: false })
    wideLayout

    @Prop({ required: false, default: false })
    showChevron

    @Prop({ required: false, default: false })
    selected

    @Prop({ type: Boolean, required: false, default: true })
    public readonly onSidebar!: boolean
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/variables';
@import '~@/styles/typography';
@import '~@/styles/palette';
@import '~@/styles/rain/colour';

.list-item {
    display: flex;
    padding: 14px 0;
    cursor: pointer;

    &.mobile {
        padding-top: 14px;
    }

    &.wide {
        padding: 16px;
    }

    .avatar {
        width: 44px;
    }

    .header {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 500;
        padding-top: 2px;
        margin-bottom: 8px;
    }

    .details {
        margin-left: 14px;
        flex-grow: 1;
        flex-basis: 100px;

        &.desktop {
            display: flex;
            align-items: center;
        }
    }

    .desktop-subtitle {
        flex-grow: 1;
        flex-basis: 100px;
    }

    .desktop-number {
        flex-grow: 1;
        flex-basis: 100px;
        display: flex;
    }

    .desktop-chevron {
        width: 32px;
        margin-top: @gutterSpacing-base;
        text-align: center;
        color: @grey;
        &.selected {
            color: @primaryBlue;
        }
    }

    &.selected {
        border: 2px solid @primaryBlue;
        border-radius: 36px;
        color: @primaryBlue;
        padding: 14px !important;
    }

    .score-container {
    }
}

.engagement-team-members-list {
    .list-item {
        &.selected {
            border-bottom: 2px solid @primaryBlue;
        }
    }
}
</style>
