<template>
    <WebViewScreen title="Feedback" no-pad>
        <Scrollable
            :pull-down="refreshResponses"
            :on-scroll-end="loadMoreResponses"
            class="feedback-scroll"
            :scroll-container-top="getScrollPosition('feedback')"
        >
            <EngagementBanner />
            <SummaryHeader />
            <FilterBarMobile :is-floating="false" no-pad />
            <RequestNotificationPermissionPanel class="add-top-padding" />
            <MobileSelectMetric class="metric-selector" />
            <div class="feedback-list">
                <RouterLink
                    v-for="response in responses"
                    :key="response.id"
                    :to="
                        hasNetworkConnection
                            ? {
                                  name: 'chatResponsesRoot',
                                  params: {
                                      id: response.id,
                                  },
                              }
                            : {}
                    "
                >
                    <div class="feedback-item">
                        <FeedbackScorecardResponseTile
                            v-if="userHasMlp"
                            :response="response"
                            :scorecard="scorecard"
                            class="scorecard-response"
                            @click="handleLinkClick"
                        />
                        <ScorecardResponseTile
                            v-else
                            :response="response"
                            :scorecard="scorecard"
                            class="scorecard-response"
                        />
                    </div>
                </RouterLink>
                <div v-if="loading" class="loading">
                    <SimpleSpinner
                        size="small"
                        class="spinner"
                        line-fg-color="#B43DC6"
                    />
                </div>
                <div v-if="!loading && resultsDepleted" class="no-more-results">
                    no more results
                </div>
            </div>
        </Scrollable>
    </WebViewScreen>
</template>

<script lang="ts">
import { Component, Watch, Vue } from 'vue-facing-decorator'
import { Action, Getter } from 'vuex-facing-decorator'
import WebViewScreen from '@/components/WebView/WebViewScreen.vue'
import FilterBarMobile from '@/mobile/src/components/FilterBarMobile.vue'
import SummaryHeader from '@/mobile/src/components/SummaryHeader.vue'
import Scrollable from '@/components/Scrollable.vue'
import RequestNotificationPermissionPanel from '@/mobile/src/components/RequestNotificationPermissionPanel.vue'
import { ScorecardEntity } from '@/entities/scorecard'
import { dedupBy } from '@/utils/array'
import { Dict, IResponse } from '@/entities'
import { getResponses } from '@/api/responses'
import Loading from '@/components/Loading.vue'
import SimpleSpinner from '@/components/SimpleSpinner.vue'
import ScorecardResponseTile from '@/components/ScorecardResponseTile.vue'
import FeedbackScorecardResponseTile from '@/components/FeedbackScorecardResponseTile.vue'
import MobileSelectMetric from '@/components/MobileSelectMetric.vue'
import { OFFLINE_RESPONSE_COUNT } from '@/store/modules/scorecard'
import EngagementBanner from '@/mobile/src/components/engagement/EngagementBanner.vue'

@Component({
    components: {
        EngagementBanner,
        MobileSelectMetric,
        ScorecardResponseTile,
        FeedbackScorecardResponseTile,
        Loading,
        RequestNotificationPermissionPanel,
        WebViewScreen,
        FilterBarMobile,
        SummaryHeader,
        Scrollable,
        SimpleSpinner,
    },
})
export default class Feedback extends Vue {
    @Getter public scorecard!: ScorecardEntity
    @Getter public responsesMap!: Dict<IResponse[]>
    @Getter public mobileFilterActive
    @Getter public mobileQuestionType!: string
    @Getter public hasNetworkConnection!: boolean
    @Getter public offlineFilter
    @Getter public $companyVars
    @Getter public feedbackFilter
    @Getter public getScrollPosition!: (page: string) => number
    @Getter public userHasMlp

    @Action public setResponses
    @Action public saveScrollPosition
    @Action public setFeedbackFilter

    public resultsDepleted = false // whether we have more responses
    public loading = false
    public addNoteOnRedirect = false

    public get key() {
        // for scorecard locked admin, responses on dashboard and scorecard page are different
        return JSON.stringify(this.mobileFilterActive)
    }

    private get responses() {
        const responses = this.responsesMap[this.key]
        return responses && Array.isArray(responses)
            ? dedupBy(responses, ({ id }) => id.toString())
            : []
    }

    public mounted() {
        if (!this.responsesMap[this.key]) {
            this.loadMoreResponses()
        }
    }

    public beforeDestroy() {
        // Retain scroll position in Vuex state
        const scrollContainer = document
            .getElementsByClassName('scroll-container')
            .item(0) as HTMLDivElement
        this.saveScrollPosition({
            page: 'feedback',
            scrollTop: scrollContainer?.scrollTop ?? 0,
        })
    }

    @Watch('mobileFilterActive')
    public onFilterChange() {
        const newFilterRules = JSON.stringify(this.mobileFilterActive)
        const filterRulesHaveChanged = newFilterRules !== this.feedbackFilter

        if (!this.loading && filterRulesHaveChanged) {
            this.setFeedbackFilter(newFilterRules)
            this.refreshResponses()
        }
    }

    public async loadMoreResponses() {
        if (!this.mobileFilterActive || this.resultsDepleted) {
            return
        }

        this.loading = true
        const { data } = await getResponses(this.mobileFilterActive, {
            pagesize: this.hasNetworkConnection ? 10 : OFFLINE_RESPONSE_COUNT,
            offset:
                this.responses && this.hasNetworkConnection
                    ? this.responses.length
                    : 0,
        })

        if (data.length > 0) {
            if (!this.responsesMap[this.key]) {
                this.setResponses({ key: this.key, data })
            } else {
                this.setResponses({
                    key: this.key,
                    data: dedupBy(
                        [...this.responsesMap[this.key], ...data],
                        ({ id }) => id.toString()
                    ),
                })
            }
        } else {
            this.resultsDepleted = true
        }
        this.loading = false
    }

    public async refreshResponses() {
        if (!this.mobileFilterActive) {
            return
        }

        this.loading = true
        this.resultsDepleted = false
        const { data } = await getResponses(
            this.hasNetworkConnection
                ? this.mobileFilterActive
                : this.offlineFilter(this.mobileQuestionType),
            {
                pagesize: this.hasNetworkConnection
                    ? 10
                    : OFFLINE_RESPONSE_COUNT,
                offset: 0,
            }
        )

        if (data.length > 0) {
            this.setResponses({ key: this.key, data })
        } else {
            this.resultsDepleted = true
        }
        this.loading = false
    }

    private handleLinkClick(e) {
        this.addNoteOnRedirect = e.addNote
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/variables';

.feedback-scroll {
    background: @greyLight;

    .metric-selector {
        top: 35px;
    }
}

.no-more-results {
    color: #999;
    text-align: center;
    padding: 10px;
    font-size: 12px;
    font-style: italic;
}

.feedback-item {
    margin: 12px;
}

.scorecard-response {
    // override the margins already set by ScorecardResponse
    // we should remove them in ScorecardResponse
    margin: 0 auto;
}

.feedback-list {
    padding: 30px 0 20px 0;
    // For use with the Feedback screen's snap to functionality
    scroll-snap-align: start;
    scroll-margin-top: 12px;

    a {
        text-decoration: none;
    }
}

.add-top-padding {
    padding-top: 40px;
}
</style>
