<template>
    <div :class="`reel-slide theme-${randomTheme}`">
        <div class="reel-slide-progress-bar-bkg" />
        <div class="reel-slide-progress-bar" />
        <div class="reel-slide-header">
            <p
                :class="`reel-slide-header-title ${randomTitleAnimation}`"
                v-html="props.title"
            />
            <button class="reel-slide-header-close-btn" @click="props.close">
                <FontAwesomeIcon :icon="faXmark" size="lg"></FontAwesomeIcon>
            </button>
        </div>
        <p
            :class="`reel-slide-message ${randomMsgAnimation}`"
            v-html="props.message"
        />
        <p :class="`reel-slide-score ${randomScoreAnimation}`">
            {{ props.score }}
        </p>
        <!-- ACTIONS ARE OUT OF SCOPE FOR NOW -->
        <!-- <div :class="`reel-slide-action-btn-container ${randomBtnAnimation}`">
            <button v-if="props.canShare" class="action-btn">
                <FontAwesomeIcon :icon="faHeart" size="2xl" />
            </button>
            <button v-if="props.canAddReportCard" class="action-btn">
                <FontAwesomeIcon :icon="faShareSquare" size="2xl" />
            </button>
            <button v-if="props.canAddReportCard" class="action-btn">
                <FontAwesomeIcon :icon="faComment" size="2xl" />
            </button>
        </div> -->
    </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faXmark } from 'fontawesome/free-solid-svg-icons'

interface Props {
    title: string
    message: string
    canShare: boolean
    score: string
    canAddReportCard: boolean
    slideTimeDuration: number
    close: () => void
}
const props = defineProps<Props>()

const animationDuration = `${props.slideTimeDuration / 1000}s`
const leftAdjustment = props.score?.length === 1 ? '0' : '-70px'

const themeOptions = ['purple', 'pink', 'yellow']
const randomTheme =
    themeOptions[Math.floor(Math.random() * themeOptions.length)]

const animationOptions = [
    'scale-up-anim',
    'tracking-in-anim',
    'focus-in-anim',
    'slide-in-anim',
    'bounce-in-anim',
]
const randomTitleAnimation =
    animationOptions[Math.floor(Math.random() * animationOptions.length)]
const randomMsgAnimation =
    animationOptions[Math.floor(Math.random() * animationOptions.length)]
const randomScoreAnimation =
    animationOptions[Math.floor(Math.random() * animationOptions.length)]
</script>

<style lang="less" scoped>
@import '~@/styles/rain/variables.less';
@import '~@/styles/rain/colour.less';
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

.reel-slide {
    height: 100vh;
    padding: calc(env(safe-area-inset-top) + @gutterSpacing-xl)
        @gutterSpacing-xl @gutterSpacing-xl @gutterSpacing-xl;

    &-progress-bar-bkg {
        position: fixed;
        top: calc(env(safe-area-inset-top) + 20px);
        left: 0;
        width: 100%;
        height: @gutterSpacing-sm;
        background-color: @white;
        opacity: 0.25;
    }

    &-progress-bar {
        position: fixed;
        top: calc(env(safe-area-inset-top) + 20px);
        left: 0;
        width: 100%;
        height: @gutterSpacing-sm;
        background-color: @white;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        animation: progressBar v-bind(animationDuration) linear;
    }

    @keyframes progressBar {
        from {
            width: 0%;
        }
        to {
            width: 100%;
        }
    }

    &-header {
        display: flex;
        justify-content: space-between;
        font-size: 20px;

        &-title {
            font-weight: bold;
            color: #9556ab;
        }

        &-close-btn {
            background: none;
            border: solid 2px @purplePopDark;
            border-radius: @borderRadius-base;
            color: @purplePopDark;
            padding: 0;
            height: 24px;
            width: 24px;
            margin-top: 10px;
            text-align: center;
            font-weight: bold;
        }
    }

    &-message {
        position: relative;
        z-index: 1;
        font-size: 30px;
        line-height: 38px;
        font-weight: 300;
        color: @purplePopDark;
        margin: 0;
    }

    &-action-btn-container {
        position: absolute;
        right: 34px;
        bottom: 40px;

        .action-btn {
            background: @white;
            border: none;
            font-weight: bold;
            color: #b43dc6;
            border-radius: 100%;
            height: 48px;
            width: 48px;
            margin-left: @gutterSpacing-md;
        }
    }

    &-score {
        position: fixed;
        z-index: 0;
        bottom: 0;
        left: v-bind(leftAdjustment);
        margin: 0;
        width: 100%;
        text-align: center;
        font-family: 'Bebas Neue', sans-serif;
        font-size: 700px;
        line-height: 350px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: -25px;
    }

    &.theme-purple {
        background: #6b097a;
        background: linear-gradient(170deg, #6b097a 0%, #3143cf 100%);

        .reel-slide-header {
            &-title {
                color: #a37fb7;
            }

            &-close-btn {
                color: @white;
                border-color: @white;
            }
        }

        .reel-slide-message {
            color: @white;
        }

        .reel-slide-score {
            color: rgba(180, 61, 198, 0.6);
        }
    }

    &.theme-pink {
        background: #f096e3;
        background: linear-gradient(170deg, #f096e3 0%, #f37be2 100%);

        .reel-slide-score {
            color: rgba(255, 255, 255, 0.3);
        }
    }

    &.theme-yellow {
        background: #ffc702;
        background: linear-gradient(170deg, #ffc702 0%, #ffde6c 100%);

        .reel-slide-score {
            color: rgba(255, 255, 255, 0.4);
        }
    }

    // SLIDE ANIMATIONS
    // Based on: https://animista.net/play/basic/scale-up
    @keyframes scaleUp {
        0% {
            transform: scale(0.25);
            opacity: 0.5;
        }
        100% {
            transform: scale(1);
            opacity: 1;
        }
    }
    .scale-up-anim {
        animation: scaleUp 0.75s cubic-bezier(0.215, 0.61, 0.355, 1) both;
        animation-delay: 0.2s;
    }

    @keyframes trackingIn {
        0% {
            letter-spacing: -0.5em;
            opacity: 0;
        }
        40% {
            opacity: 0.6;
        }
        100% {
            opacity: 1;
        }
    }
    .tracking-in-anim {
        animation: trackingIn 1s cubic-bezier(0.215, 0.61, 0.355, 1) both;
        animation-delay: 0.4s;
    }

    @keyframes focusIn {
        0% {
            filter: blur(12px);
            opacity: 0;
        }
        100% {
            filter: blur(0px);
            opacity: 1;
        }
    }
    .focus-in-anim {
        animation: focusIn 1s cubic-bezier(0.215, 0.61, 0.355, 1) both;
        animation-delay: 0.6s;
    }

    @keyframes slideIn {
        0% {
            transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
            transform-origin: 100% 50%;
            filter: blur(80px);
            opacity: 0;
        }
        100% {
            transform: translateX(0) scaleY(1) scaleX(1);
            transform-origin: 50% 50%;
            filter: blur(0);
            opacity: 1;
        }
    }
    .slide-in-anim {
        animation: slideIn 0.75s cubic-bezier(0.215, 0.61, 0.355, 1) both;
        animation-delay: 0.8s;
    }

    @keyframes bounceIn {
        0% {
            transform: translateY(100px);
            animation-timing-function: ease-in;
            opacity: 0;
        }
        50% {
            transform: translateY(-10px);
            animation-timing-function: ease-out;
            opacity: 1;
        }
        80% {
            transform: translateY(15px);
            animation-timing-function: ease-in;
        }
        100% {
            transform: translateY(0);
            animation-timing-function: ease-out;
        }
    }
    .bounce-in-anim {
        animation: bounceIn 1s cubic-bezier(0.215, 0.61, 0.355, 1) both;
    }
}
</style>
