<template>
    <div
        :class="[
            'filter-bar-container',
            {
                'no-x-pad': noXPad,
                'no-pad': noPad,
                'non-floating-filter-bar': !isFloating,
            },
        ]"
        @click="onClickWhenOffline"
    >
        <FilterBar
            v-if="tsFilterBarVisible"
            :should-reload-on-change="false"
            :enabled-executive-reporting="false"
            :is-in-mobile="tsMobileView"
            :class="{ 'float-shift-up': !isFloating }"
            :permissions="permissions"
        />
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { Getter } from 'vuex-facing-decorator'
import FilterBar from '@/components/FilterBar.vue'
import { Dialog } from '@capacitor/dialog'

@Component({
    components: {
        FilterBar,
    },
})
export default class FilterBarMobile extends Vue {
    @Prop({ type: Boolean, default: false })
    public readonly noXPad?: boolean
    @Prop({ type: Boolean, default: false })
    public readonly noPad?: boolean
    @Prop({ type: Boolean, default: true })
    public readonly isFloating?: boolean
    @Getter public tsFilterBarVisible
    @Getter public tsMobileView
    @Getter
    hasNetworkConnection!: boolean
    @Getter public permissions

    onClickWhenOffline() {
        if (!this.hasNetworkConnection) {
            Dialog.alert({
                title: 'Filter is Locked',
                message:
                    'You appear to be offline. You can access this when you’re back online.',
                buttonTitle: 'OK',
            })
        }
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/variables';

.filter-bar-container {
    position: sticky;
    top: 0;
    z-index: 1;
    padding: 15px 15px 0 15px;
    background: @greyLight;

    &.no-x-pad {
        padding: 15px 0 0 0;
    }

    &.no-pad {
        padding: 0;
    }

    &.non-floating-filter-bar {
        position: relative;
        margin: 0 12px;
    }
}

.float-shift-up {
    position: absolute;
    margin-top: -25px;
    width: 100%;
}
</style>
