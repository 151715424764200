<template>
    <div class="profile-picture" @click="updateProfilePicture">
        <LoadingCard v-if="isUploading" variant="circle" />
        <AvatarOrInitials v-else :size="avatarSize" :user="avatarUser" />
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { Action } from 'vuex-facing-decorator'
import { uploadUserAvatar } from '@/api/settings/account/users'
import { dataURItoBlob } from '@/utils/image'
import AvatarOrInitials from '@/components/WebView/AvatarOrInitials.vue'
import LoadingCard from '@/mobile/src/components/appV4/LoadingCard.vue'

@Component({
    components: {
        LoadingCard,
        AvatarOrInitials,
    },
})
export default class ProfilePicture extends Vue {
    @Prop({ type: Object, required: true }) public user!: {
        id: number
        avatar: string
        name: string
    }
    @Prop({ type: Boolean, default: true }) public canEdit?: boolean

    @Action public getPhoto
    @Action public setUserProp

    public avatarSize = 40
    public isUploading = false
    public newlyUploadedPicUrl: string | null = null

    public get avatarUser() {
        return {
            ...this.user,
            avatar: this.newlyUploadedPicUrl ?? this.user.avatar,
        }
    }

    public async updateProfilePicture() {
        if (!this.canEdit) {
            return
        }

        const photo = await this.getPhoto()
        if (!photo || !photo.dataUrl) {
            return
        }

        // Upload and get new pictures URL
        this.isUploading = true
        const blob = dataURItoBlob(photo.dataUrl)
        const file = new File([blob as any], `avatar.${photo.format}`, {
            type: `image/${photo.format}`,
        })
        const { data } = await uploadUserAvatar(file)
        this.newlyUploadedPicUrl = data.url
        this.setUserProp({ avatar: this.newlyUploadedPicUrl })
        this.isUploading = false
    }
}
</script>

<style lang="less" scoped>
.profile-picture {
    height: 40px;
    width: 40px;
}
</style>
