<template>
    <div class="skeleton" :style="style">&nbsp;</div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component({})
export default class SkeletonCircle extends Vue {
    @Prop({ type: String, default: '100px' })
    readonly width!: string

    get style() {
        return `width: ${this.width};
                height: ${this.width};
                border-radius: ${this.width};`
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/skeleton';
</style>
