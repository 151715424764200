<template>
    <div :class="[circleStyle, getCircleClass()]">
        {{ score }}
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component({})
export default class ResponseCircle extends Vue {
    @Prop({ type: Number, required: true })
    public score!: number
    @Prop({ type: String, required: false })
    public variation?: 'nps' | 'csat' | 'fivestar'
    @Prop({ type: String, default: 'circle' })
    public circleStyle?: 'circle' | 'circle30' | 'circle25'

    public getCircleClass() {
        switch (this.variation) {
            case 'csat':
            case 'fivestar':
                if (this.score > 3) {
                    return 'positive'
                } else if (this.score < 3) {
                    return 'negative'
                } else {
                    return 'neutral'
                }
            case 'nps':
                if (this.score > 8) {
                    return 'positive'
                } else if (this.score < 7) {
                    return 'negative'
                } else {
                    return 'neutral'
                }
        }
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';

.circle {
    width: 40px;
    height: 40px;
    background: @neutralLight;
    color: @white;
    border-radius: 20px;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    text-transform: uppercase;
}
.circle30 {
    width: 30px;
    height: 30px;
    background: @neutralLight;
    color: @white;
    border-radius: 20px;
    text-align: center;
    line-height: 30px;
    font-size: 12px;
    text-transform: uppercase;
}
.circle25 {
    width: 25px;
    height: 25px;
    background: @neutralLight;
    color: @white;
    border-radius: 20px;
    text-align: center;
    line-height: 25px;
    font-size: 10px;
    text-transform: uppercase;
}

.answer-change {
    margin-top: 4px;

    img {
        width: 6px;
        height: 5px;
    }

    .number {
        font-size: 10px;
        line-height: 16px;
    }
}

.positive {
    background-color: @successGreen;
}
.neutral {
    background-color: @neutralLight;
    color: @offBlack;
}
.negative {
    background-color: @errorRed;
}
</style>
