<template>
    <div :class="[borderless ? '' : 'loading-skeleton', variant]">
        <div class="content">
            <div class="header-box" />
            <div v-if="variant === 'table'" class="table-box">
                <div class="content-box" />
                <div class="content-box" />
                <div class="content-box" />
            </div>
            <div v-else-if="variant === 'columns'" class="loading-area">
                <div class="content-box" />
                <div class="content-box" />
            </div>
            <div v-else class="content-box" />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component({})
export default class LoadingCard extends Vue {
    @Prop({ type: String, default: 'default' })
    public readonly variant!:
        | 'default'
        | 'graph'
        | 'pill'
        | 'single'
        | 'table'
        | 'columns'
        | 'circle'
    @Prop({ type: Boolean, default: false })
    public readonly borderless!: boolean
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/mobile/src/styles/animations.less';
@import '~@/styles/rain/variables.less';

.loading-skeleton {
    border: 1px dashed @white50;
    border-radius: @borderRadius-md;
}

.content {
    padding: @featureGutter;

    .header-box,
    .content-box {
        width: 100%;
        border-radius: @borderRadius-md;
        .loading-wave();
    }

    .header-box {
        height: 60px;
        margin-bottom: @gutterSpacing-md;
    }

    .content-box {
        height: 115px;
    }
}

// Variant specific styling
.graph {
    .content {
        .header-box {
            height: 60px;
            width: 70%;
        }

        .content-box {
            height: 136px;
            width: 100%;
        }
    }
}

.pill {
    padding: 0;
    margin: auto;
    height: 32px;
    width: 140px;
    border-radius: 25px;

    .content {
        .header-box {
            height: 0;
        }

        .content-box {
            height: 0;
        }
    }
}

.single {
    padding: 0;
    .content {
        padding: 0;
        .header-box {
            height: 0;
        }
    }
}

.table {
    padding: 0;
    .content {
        padding: 25px;
        .header-box {
            height: 50px;
        }

        .table-box {
            display: flex;
            flex-direction: row;
            gap: 20px;

            .content-box {
                flex: 1;

                &:first-of-type {
                    flex-grow: 2;
                }
            }
        }
    }
}

.columns {
    padding: 0;
    margin-top: 15px;
    .content {
        padding: 25px;
        .header-box {
            display: none;
        }

        .loading-area {
            display: flex;
            flex-direction: row;
            gap: 20px;

            .content-box {
                flex: 1;
            }
        }
    }
}

.circle {
    border-radius: 25px;
    width: inherit;
    height: inherit;

    .content {
        height: inherit;
        padding: 0;
    }

    .header-box {
        height: inherit;
        border-radius: 25px;
    }

    .content-box {
        display: none;
    }
}
</style>
