import client from '@/api/client'
import { notifyError } from '@/utils/appstore'

export async function getSurveyTemplates() {
    try {
        const { data } = await client.get('/lang/survey-templates')

        return data
    } catch (e: any) {
        notifyError(`Something went wrong: ${e.message}`)
    }
}
