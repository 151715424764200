<template>
    <div
        :class="['page-background', { gradient: secondaryBgColour }]"
        :style="bgColourStyles"
        @click.stop="containerClick"
    >
        <div
            id="page-content"
            ref="pagecontent"
            :class="{
                'stop-scroll': stopScroll || haxIosDisableContentScrolling,
                'no-nav-pad': noNavPad,
                'no-side-pad': route.name === 'huddles',
            }"
        >
            <slot></slot>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { palette } from '@/utils/palette'
import useEmitter from '@/composables/useEmitter'
import {
    scrollEvent,
    scrollToBottomEvent,
    scrollToTopEvent,
} from '@/mobile/src/utils/scrollbus'
import { namespace } from 'vuex-facing-decorator'
import { useRoute } from 'vue-router'

const MobileNavigationModule = namespace('mobilenavigation')

@Component({})
export default class PageContainer extends Vue {
    @MobileNavigationModule.Getter haxIosDisableContentScrolling
    @MobileNavigationModule.Action setContainerHasFocus

    @Prop({ type: Boolean, default: false }) public noNavPad?: boolean

    private primaryBgColour = palette.purpleVivid
    private secondaryBgColour = palette.hoverBlue
    private stopScroll = false

    public route = useRoute()
    public emitter = useEmitter()

    public mounted() {
        this.emitter?.on('stopScroll', (scroll) => {
            this.stopScroll = scroll
        })
        this.emitter?.on(scrollEvent, this.scrollToPosition)
        this.emitter?.on(scrollToTopEvent, this.scrollToTop)
        this.emitter?.on(scrollToBottomEvent, this.scrollToBottom)
    }

    public destroyed() {
        this.emitter?.off(scrollEvent, this.scrollToPosition)
        this.emitter?.off(scrollToTopEvent, this.scrollToTop)
        this.emitter?.off(scrollToBottomEvent, this.scrollToBottom)
    }

    scrollToPosition(event) {
        (this.$refs.pagecontent as HTMLElement)?.scrollTo(event)
    }

    scrollToTop(behavior) {
        const pagecontent = this.$refs.pagecontent as HTMLElement
        pagecontent?.scrollTo({ top: 0, behavior: behavior })
    }

    scrollToBottom(behavior) {
        const pagecontent = this.$refs.pagecontent as HTMLElement
        pagecontent?.scrollTo({
            top: pagecontent?.scrollHeight,
            behavior: behavior,
        })
    }

    public get bgColourStyles() {
        if (this.route.name === 'huddles') {
            return 'white'
        }

        if (this.secondaryBgColour) {
            return {
                backgroundImage: `linear-gradient(170deg,
                ${this.primaryBgColour},
                ${this.secondaryBgColour},
                ${this.primaryBgColour},
                ${this.secondaryBgColour}
                )`,
            }
        } else {
            return {
                backgroundColor: this.primaryBgColour,
            }
        }
    }

    /*
     * Use to trigger Filter Header dropdown to collapse
     */
    public containerClick() {
        this.setContainerHasFocus(true)
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/variables.less';
@import '~@/styles/rain/variables.less';

#page-content {
    padding: 0 @featureGutter @mobileNavHeightPadded; // Padding for bottom to avoid overlap with floating nav
    height: calc(100% - @mobileNavHeightPadded);
    overflow-y: scroll;
    box-sizing: initial;

    /* Set the width and height of the scrollbar to 0 */
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    -ms-overflow-style: none;

    /* Hide the scrollbar thumb */
    &::-webkit-scrollbar {
        display: none;
    }

    &.stop-scroll {
        overflow-y: hidden;
    }

    &.no-nav-pad {
        padding: 0 @featureGutter;
        height: 100%;
    }

    &.no-side-pad {
        padding: 0 0 @mobileNavHeightPadded;
    }
}

.page-background {
    height: 100%;

    &.gradient {
        // Zoom in on the gradient so background-position can be used to animate gradient movement
        background-size: 800% 500%;
        animation: gradient-animation 30s infinite;
        animation-timing-function: linear;
    }
}

// Starting positions lined up to stop and start at the same frame for a loop effect
@keyframes gradient-animation {
    0% {
        background-position: 90% 90%;
    }
    100% {
        background-position: 7% 7%;
    }
}
</style>
