<template>
    <div class="team-scoreboard-screen">
        <WebViewScreen title="Team Scoreboard" no-pad>
            <template #left-button>
                <BackButton />
            </template>
            <template #right-button>
                <TeamScoreboardKebab />
            </template>

            <div class="team-scoreboard-screen-container">
                <FilterBarMobile no-x-pad class="filter-bar" />
                <TheTeamPerformanceBlock />
            </div>
            <RouterLink :to="{ name: 'teamscoreboardDetail' }">
                <ScreenBottomButton
                    v-if="!tsLoadingTeamGroupSummary"
                    :title="'View Detail: ' + tsCurrentGroupTitle"
                />
            </RouterLink>
        </WebViewScreen>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { Action, Getter } from 'vuex-facing-decorator'
import WebViewScreen from '@/components/WebView/WebViewScreen.vue'
import FilterBarMobile from '@/mobile/src/components/FilterBarMobile.vue'
import TeamScoreboardKebab from '@/components/TeamScoreboardKebab.vue'
import TheTeamPerformanceBlock from '@/pages/team-scoreboard/components/TheTeamPerformanceBlock.vue'
import BackButton from '@/mobile/src/components/BackButton.vue'
import ScreenBottomButton from '@/components/Notices/ScreenBottomButton.vue'

@Component({
    components: {
        ScreenBottomButton,
        BackButton,
        WebViewScreen,
        TeamScoreboardKebab,
        FilterBarMobile,
        TheTeamPerformanceBlock,
    },
})
export default class TeamScoreboard extends Vue {
    @Action public setTsMobileData

    @Getter public companyProps
    @Getter public tsCurrentGroupTitle
    @Getter public tsLoadingTeamGroupSummary

    public async mounted() {
        this.setTsMobileData({
            enabledExecutiveReporting: this.companyProps.has_scorecard,
        })
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/palette';

@footerHeight: 140px;

.team-scoreboard-screen {
    width: 100%;
    height: 100%;
}

.team-scoreboard-screen-container {
    padding: 0 15px @footerHeight 15px;
    background: @greyLight;
    flex: 1;
}

.filter-bar {
    // TODO: base this value on a variable
    margin-bottom: 16px;
}
</style>
