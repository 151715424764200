<template>
    <div
        class="tab-option-container"
        :class="containerClass"
        @click="$emit('click', tab.name)"
    >
        <div class="tab-option">
            <div class="icon">
                <div class="icon-container">
                    <img :src="selected ? tab.activeIcon : tab.icon" />
                </div>
                <!-- the red dot with number showing updates -->
                <div v-if="tab.count > 0" class="count"></div>
            </div>
            <div :class="['text', { selected }]">
                {{ tab.text }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { TabData } from '@/mobile/src/types/tab'

@Component({ components: {}, emits: ['click'] })
export default class TabbedOption extends Vue {
    @Prop({ type: Object, default: {} }) public tab!: TabData
    @Prop({ type: Boolean, default: false }) public selected!: boolean

    private get containerClass() {
        return this.selected ? 'border-top-active' : 'border-top-inactive'
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/palette';

.tab-option-container {
    height: 100%;
    display: flex;
    align-items: center;

    &.border-top-active {
        border-top: 2px solid @purple;
    }

    &.border-top-inactive {
        border-top: 2px solid transparent;
    }

    .tab-option {
        margin: 0 auto;

        .icon {
            height: 24px;
            display: flex;
            align-items: center;
            position: relative;

            .icon-container {
                margin: 0 auto;
                position: relative;
                display: flex;
                align-items: center;
            }

            .count {
                position: absolute;
                height: 12px;
                width: 12px;
                top: -4px;
                right: 4px;
                background: red;
                border-radius: 50%;
            }
        }

        .text {
            text-transform: uppercase;
            font-weight: 700;
            letter-spacing: 1px;
            line-height: 12px;
            font-size: 10px;
            color: @grey40;
            margin-top: 6px;

            &.selected {
                color: @grey90;
            }
        }
    }
}
</style>
