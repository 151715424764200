import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!

  return (_openBlock(), _createElementBlock("button", {
    class: "plus-button",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    _createVNode(_component_FontAwesomeIcon, {
      class: "circle-plus-icon",
      icon: _ctx.faCirclePlus
    }, null, 8, ["icon"])
  ]))
}