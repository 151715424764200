<template>
    <div
        v-if="badgeId && badgeSchema !== undefined"
        class="scorecard-badge"
        :class="{
            empty: badgeType !== 'achievement' && count === '0',
            'no-space': noSpace,
        }"
    >
        <div class="circle-container">
            <img
                :src="badgeImage"
                width="75"
                :alt="label"
                class="badge-image"
            />
            <div
                v-if="showCount && countUnseen !== '0'"
                class="mention-count-new"
                :style="countStyle"
            >
                +{{ countUnseen }}
            </div>
            <div
                v-else-if="!showCount && countUnseen !== '0'"
                class="mention-dot"
            ></div>
            <div class="mention-count-total-container">
                <div class="mention-count-total">{{ count }}</div>
            </div>
        </div>
        <div class="badge-name">{{ label || badgeSchema.label }}</div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import {
    achievementSchemas,
    achievementUnknownBadgeImage,
    badgeSchemas,
} from '@/entities/scorecard'

@Component({})
export default class ScorecardBadge extends Vue {
    @Prop({ type: String }) public label!: string
    @Prop({ type: String }) public countUnseen!: string
    @Prop({ type: String }) public count!: string
    @Prop({ type: String }) public badgeId!: string
    @Prop({ type: String }) public badgeType!: string
    // whether show unread count number or just a red dot
    @Prop({ type: Boolean, default: true }) public showCount!: boolean

    @Prop({ type: Boolean, required: false })
    noSpace?: boolean

    public get badgeSchema() {
        return (
            badgeSchemas[this.badgeId] ?? achievementSchemas[this.badgeId] ?? {}
        )
    }

    public get countStyle() {
        const { foreColor, color } = this.badgeSchema
        return { backgroundColor: foreColor, color: color }
    }

    public get badgeImage() {
        if (this.badgeType === 'achievement' && Number(this.count) < 1) {
            return achievementUnknownBadgeImage
        } else {
            return this.badgeSchema.img
        }
    }
}
</script>

<style scoped lang="less">
@import '~@/styles/scorecard/badge.less';

.scorecard-badge {
    max-width: 90px;
    cursor: pointer;

    &.no-space {
        margin: 0;
        padding: 0;
    }
}

.desktop-badges {
    .badge-name {
        margin-top: 12px;
    }
}
</style>
