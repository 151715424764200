<template>
    <div ref="selected-recipient-pills">
        <Tabs
            :class="['selected-recipient-pills', { mobile: $isMobile }]"
            :vsmall="!$isMobile"
            pills
            :can-remove-tabs="canRemoveTabs"
            :can-remove-single-tabs="canRemoveSingleTabs"
            @remove="removeRecipient"
            @change="removeRecipient"
        >
            <Tab
                v-for="recipient in selectedRecipients"
                :key="recipient.name"
                :name="recipient.name"
                is-active
            />
        </Tabs>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import Tabs from '@/components/Tabs.vue'
import Tab from '@/components/Tab.vue'
import { IRecipient } from '@/entities/notices'

@Component({
    components: { Tab, Tabs },
    emits: ['removeRecipient'],
})
export default class SelectedRecipientPills extends Vue {
    @Prop({ type: Array, required: true }) private recipients!: IRecipient[]
    @Prop({ type: Array, required: true })
    private selectedRecipients!: IRecipient[]
    @Prop({ type: Boolean, default: true }) private canRemoveTabs!: boolean
    @Prop({ type: Boolean, default: false })
    private canRemoveSingleTabs!: boolean

    private findSelectedRecipientByName(recipientName: String) {
        return this.selectedRecipients.find((recipient: IRecipient) => {
            return recipient.name === recipientName
        })
    }

    private removeRecipient(recipientName: String) {
        const recipient = this.findSelectedRecipientByName(recipientName)
        if (recipient) {
            this.$emit('removeRecipient', recipient)
        }
    }
}
</script>

<style lang="less">
.selected-recipient-pills {
    max-height: 84px;
    overflow-y: auto;

    &.mobile {
        max-height: 103.5px;
        .vtabs .pills {
            margin-bottom: 12px;
        }
    }

    .vtabs-content {
        padding: 0 !important;
    }
}
</style>
