<template>
    <PortalContainer v-if="show" @back="$emit('close')">
        <transition name="modal">
            <div class="modal" @click.self="clickOut">
                <div class="container" :class="'width' + width">
                    <div class="header">
                        <FontAwesomeIcon
                            :icon="faTimes"
                            class="close"
                            @click="$emit('close')"
                        />
                        <Typography variant="h3" component="h3">{{
                            title
                        }}</Typography>
                    </div>

                    <div class="content">
                        <slot />
                    </div>
                    <div
                        :class="[
                            'footer',
                            { 'extra-top-padding': footerTopPadding },
                        ]"
                    >
                        <BaseButton
                            v-if="btnLeft"
                            :full="btnFull"
                            :variant="btnLeftType"
                            @click="$emit('click-left')"
                        >
                            {{ btnLeft }}
                        </BaseButton>
                        <BaseButton
                            :full="btnFull"
                            :disabled="isDisabled"
                            :type="btnRightType"
                            variant="primary"
                            @click="$emit('click-right')"
                        >
                            {{ btnRight }}
                        </BaseButton>
                    </div>
                </div>
            </div>
        </transition>
    </PortalContainer>
</template>

<script lang="ts">
import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'
import PortalContainer from '@/mobile/src/components/containers/PortalContainer.vue'
import BaseButton from '@/components/Rain/Buttons/BaseButton.vue'
import Typography from '@/components/Rain/Typography/Typography.vue'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faTimes } from 'fontawesome/free-solid-svg-icons'

@Component({
    components: {
        BaseButton,
        Typography,
        PortalContainer,
        FontAwesomeIcon,
    },
    emits: ['close', 'click-left', 'click-right'],
})
class Modal extends Vue {
    @Prop({ type: Boolean, default: false }) public readonly show!: boolean
    @Prop({ type: String, default: '' }) public readonly title!: string
    @Prop({ type: String, default: 'ghost' })
    public readonly btnLeftType!: string
    @Prop({ type: String, default: '' })
    public readonly btnLeft!: string
    @Prop({ type: String, default: 'confirm' })
    public readonly btnRight!: string
    @Prop({ type: String, default: '' })
    public readonly btnRightType!: string
    @Prop({ type: Boolean, default: true })
    public readonly btnFull!: boolean
    @Prop({ type: Boolean, default: false })
    public readonly allowClickOut!: boolean
    @Prop({ type: Boolean, default: true })
    public readonly footerTopPadding!: boolean
    @Prop({ type: Boolean, default: false })
    public readonly isDisabled!: boolean
    @Prop({ type: String, default: '' })
    public readonly width!: string

    @Prop({ type: Number, default: 600 })
    public readonly containerHeight!: number
    @Prop({ type: Number, default: 400 })
    public readonly containerWidth!: number
    public readonly containerContentHeight: number = this.containerHeight - 224

    public faTimes = faTimes

    public clickOut() {
        if (!this.allowClickOut) {
            return
        }

        this.$emit('close')
    }

    containerWidthPx = `${this.containerWidth}px`
    containerHeightPx = `${this.containerHeight}px`
    containerContentHeightPx = `${this.containerContentHeight}px`
}

export default toNative(Modal)
</script>

<style scoped lang="less">
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';

@maxWidth600: 600px;

.modal {
    position: fixed;
    z-index: 998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity @duration @easing;
    background-color: rgba(0, 0, 0, 0.5);
}

.container {
    position: relative;
    width: 80vw;
    max-width: v-bind(containerWidthPx);
    max-height: v-bind(containerHeightPx);
    background-color: @white;
    border-radius: @borderRadius-base;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    transition: transform @duration @easing;

    .close {
        position: absolute;
        top: @gutterSpacing-lg;
        right: @gutterSpacing-lg;
        color: @darkGrey;
        cursor: pointer;
    }

    .header {
        padding: @gutterSpacing-xl;
        padding-bottom: @gutterSpacing-lg;
    }

    .content {
        position: relative;
        padding: 0 @gutterSpacing-xl;
        max-height: v-bind(containerContentHeightPx);
        box-sizing: border-box;
        overflow-y: auto;
    }

    .footer {
        display: flex;
        justify-content: space-between;
        padding: @gutterSpacing-xl;
        gap: @gutterSpacing-xl;

        &.extra-top-padding {
            padding-top: @gutterSpacing-xl*2;
        }
    }

    &.width600 {
        max-width: @maxWidth600;
    }
}

.modal-enter-from,
.modal-leave-active {
    opacity: 0;
}

.modal-enter-from .container,
.modal-leave-active .container {
    transform: scale(1.1);
}
</style>
