import ChatResponseMessagesScreen from '@/pages/chat/responses/ChatResponseMessagesScreen.vue'

// NOTE: lazy load components. The first page should not be lazy-load, or an error will occur. Need to investigate.
const importAsyncComponent = (path) => {
    return () => import(`./${path}.vue`).then((m) => m.default)
}
const ChatResponseEmailReplyScreen = importAsyncComponent(
    'ChatResponseEmailReplyScreen'
)
const ChatResponseActionScreen = importAsyncComponent(
    'ChatResponseActionScreen'
)
const ChatResponseCaseOperationListScreen = importAsyncComponent(
    'ChatResponseCaseOperationListScreen'
)
const ChatResponseCustomDataScreen = importAsyncComponent(
    'ChatResponseCustomDataScreen'
)
const ChatResponseTemplateListScreen = importAsyncComponent(
    'ChatResponseTemplateListScreen'
)
const ChatResponseWorkflowScreen = importAsyncComponent(
    'ChatResponseWorkflowScreen'
)

export const routes = [
    {
        path: '/:id',
        component: ChatResponseMessagesScreen,
        meta: { backgroundColor: '#F4F6FC' }, // default background color is white
        name: 'chatResponsesRoot',
        props: true,
    },
    {
        path: '/:id/email-reply',
        component: ChatResponseEmailReplyScreen,
        name: 'chatResponsesEmailReply',
        props: true,
    },
    {
        path: '/:id/actions',
        component: ChatResponseActionScreen,
        name: 'chatResponsesActions',
        props: true,
    },
    {
        path: '/:id/actions/case',
        component: ChatResponseCaseOperationListScreen,
        name: 'chatResponsesCase',
        props: true,
    },
    {
        path: '/:id/actions/customdata',
        component: ChatResponseCustomDataScreen,
        name: 'chatResponsesCustomdata',
        props: true,
    },
    {
        path: '/:id/actions/template',
        component: ChatResponseTemplateListScreen,
        name: 'chatResponsesTemplate',
        props: true,
    },
    {
        path: '/:id/actions/template/email-reply',
        component: ChatResponseEmailReplyScreen,
        name: 'chatResponsesTemplateEmailReply',
        props: true,
    },
    {
        path: '/:id/actions/workflow',
        component: ChatResponseWorkflowScreen,
        name: 'chatResponsesWorkflow',
        props: true,
    },
]

// This is a big old cheat to temporarily get around the routing cheat that we were relying on before for this to work
// in a webpage.  Where before we could rely on the leading slash to redirect us to the base route given below, under
// the Capacitor app we can't.  And since Vue complains if there isn't a leading slash in non-nested routes, we have to
// temporarily rely on this little trick (we could also create a fake component to wrap everything else, but that seems
// wasteful and likely to blow up in our faces).  This can all be removed when we kill the current app.
export const chatResponsesRoutes = routes.map((route) => {
    const newRoute = Object.assign({}, route)
    newRoute.path = '/chat/responses' + newRoute.path
    newRoute.meta = { ...newRoute.meta, tab: 'feedback' } as any
    return newRoute
})
