<template>
    <WebViewScreen no-pad class="insights" show-go-back disable-when-offline>
        <template #custom-title>
            <TwoLineHeader title="Coaching" :subtitle="friendlyTimeRange" />
        </template>

        <template #right-button>
            <InsightsTimePickerMobile
                :time-range="timeRange"
                @selectTime="updateTimeRange"
            />
        </template>
        <Scrollable
            class="coaching-metrics-container"
            :loading="loading"
            :pull-down="onPullDown"
        >
            <div v-if="hasCoachingData" class="coaching-metrics">
                <InsightsCoachingTiles
                    :coaching-metric-type="selectedCoachingMetric"
                    @click="changeCoachingMetricType"
                />
                <GradientHighlightedCard
                    v-if="!loading"
                    class="insights-coaching-list"
                >
                    <InsightsCoachingList
                        :coaching-metric-type="selectedCoachingMetric"
                        :question-type="insightsCoaching.question_type"
                    />
                </GradientHighlightedCard>
            </div>
            <div v-else-if="insightsCoaching" class="insights-no-data">
                No data to show, please try another Time Filter period.
            </div>
        </Scrollable>
    </WebViewScreen>
</template>

<script lang="ts">
import Scrollable from '@/components/Scrollable.vue'
import WebViewScreen from '@/components/WebView/WebViewScreen.vue'
import {
    CoachingInsights,
    CoachingMetricType,
    ITimeRange,
} from '@/entities/insights'
import InsightsTimePickerMobile from '@/mobile/src/components/Insights/InsightsTimePickerMobile.vue'
import { Component, Vue } from 'vue-facing-decorator'
import { Action, Getter } from 'vuex-facing-decorator'
import InsightsCoachingTiles from '@/mobile/src/components/Insights/InsightsCoachingTiles.vue'
import InsightsCoachingList from '@/mobile/src/components/Insights/InsightsCoachingList.vue'
import TwoLineHeader from '@/mobile/src/components/TwoLineHeader.vue'
import GradientHighlightedCard from '@/components/GradientHighlightedCard.vue'

@Component({
    components: {
        GradientHighlightedCard,
        InsightsCoachingList,
        InsightsCoachingTiles,
        InsightsTimePickerMobile,
        Scrollable,
        WebViewScreen,
        TwoLineHeader,
    },
})
export default class CoachingMetrics extends Vue {
    @Getter
    readonly insightsCoaching!: CoachingInsights

    @Getter
    readonly friendlyTimeRange!: string

    @Getter
    readonly timeRange!: ITimeRange

    @Getter
    readonly selectedCoachingMetric!: CoachingMetricType

    @Getter
    readonly currentlyLoading!: string[]

    @Action
    loadInsightsCoaching

    @Action
    updateTimeRange

    @Action
    setSelectedCoachingMetric

    public coachingScorecardData: {} = {}
    public coachingFrontlineData: {} = {}

    async mounted() {
        if (this.insightsCoaching === undefined) {
            await this.loadInsightsCoaching()
        }
    }

    get loading() {
        return this.currentlyLoading.includes('insightsCoaching')
    }

    async onPullDown() {
        if (!this.loading) {
            await this.loadInsightsCoaching()
        }
    }

    public changeCoachingMetricType(metric) {
        this.setSelectedCoachingMetric(metric)
    }

    get hasCoachingData() {
        return !!(
            this.insightsCoaching &&
            this.insightsCoaching.data['scorecard'] &&
            this.insightsCoaching.data[
                this.insightsCoaching.front_line_staff_columns[0]
            ]
        )
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/typography';
@import '~@/styles/variables';
@import '~@/styles/button';

.insights {
    background-color: @lightGrey;
}

.time-range {
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: @grey40;
}

.coaching-metrics-container {
    background: @neutralBg;

    .insights-no-data {
        padding: 12px;
    }
}

.insights-coaching-list {
    margin: 12px;
}
</style>
