import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7f362802"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "coaching-topic-review" }
const _hoisted_2 = { class: "coaching-topic-review-title" }
const _hoisted_3 = { class: "coaching-topic-review-responses" }
const _hoisted_4 = {
  key: 0,
  class: "loading"
}
const _hoisted_5 = { class: "feedback-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SimpleSpinner = _resolveComponent("SimpleSpinner")!
  const _component_ScorecardResponseTile = _resolveComponent("ScorecardResponseTile")!
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "coaching-topic-review-heading",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showTeamSortModal = true))
    }, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
      (_ctx.showMoreRouterLink)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "coaching-topic-review-more",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.router.push(_ctx.showMoreRouterLink)))
          }, " Show More "))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.responses === null)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_SimpleSpinner, {
              size: "small",
              class: "spinner",
              "line-fg-color": "#B338A3"
            })
          ]))
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.responses, (response) => {
            return (_openBlock(), _createBlock(_component_RouterLink, {
              key: response.id,
              to: { name: 'chatResponsesRoot', params: { id: response.id } }
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_ScorecardResponseTile, {
                    response: response,
                    scorecard: _ctx.scorecard,
                    class: "coaching-topic-review-response"
                  }, null, 8, ["response", "scorecard"])
                ])
              ], undefined, true),
              _: 2
            }, 1032, ["to"]))
          }), 128))
    ])
  ]))
}