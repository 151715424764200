<template>
    <div class="container">
        <input
            :class="[{ value, error }]"
            :type="type"
            :autocomplete="autocomplete"
            :value="value"
            :disabled="!!disabled"
            :placeholder="label"
            @focus="$emit('focus', $event)"
            @blur="$emit('blur', $event)"
            @input="$emit('input', $event.target.value)"
        />
        <FontAwesomeIcon :icon="faSearch" class="search-icon" />
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import {
    faCheck,
    faChevronDown,
    faSearch,
    faStar,
} from 'fontawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import {
    AllowedInputTypes,
    AutocompleteTypes,
    FontAwesomeTypes,
    VariantTypes,
} from '@/entities/rain/input-single-line'

const fontAwesomeSources = {
    faStar,
    faCheck,
    faChevronDown,
    faSearch,
}

@Component({
    components: {
        FontAwesomeIcon,
    },
    emits: ['focus', 'blur', 'input'],
})
export default class SearchBar extends Vue {
    @Prop({ type: String, required: false, default: 'text' })
    public readonly type!: AllowedInputTypes
    @Prop({ type: String, required: false, default: 'off' })
    public readonly autocomplete!: AutocompleteTypes
    @Prop({ type: String, required: false, default: '' })
    public readonly value!: string
    @Prop({ type: Boolean, required: false, default: false })
    public readonly disabled!: boolean
    @Prop({ type: Boolean, required: false, default: false })
    public readonly error!: boolean
    @Prop({ type: Boolean, required: false, default: true })
    public readonly spellcheck!: boolean
    @Prop({ type: String, required: false, default: 'on' })
    public readonly autocapitalize!: AutocompleteTypes
    @Prop({ type: String, required: false, default: '' })
    public readonly label!: string
    @Prop({ type: String, required: false, default: '' })
    public readonly fontAwesome!: FontAwesomeTypes
    @Prop({ type: String, required: false, default: '' })
    public readonly variant!: VariantTypes

    public fontAwesomeSources = fontAwesomeSources
    private faSearch = faSearch
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/inputSingleLine.less';

.container {
    width: 100%;

    .search-icon {
        color: @grey;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 13.5px;
        right: 16.5px;
    }

    input {
        height: 44px;
        padding: 10px @gutterSpacing-md;
    }
}

::placeholder {
    .label();
    text-transform: unset;
    top: 10px;
    left: 16px;
}

.filled-label() {
    padding-top: @gutterSpacing-xl;

    ~ .label {
        transform: translateY(-11px);
        font-size: @fontSize-sm;
        line-height: @lineHeight-sm;
        letter-spacing: @letterSpacing-lg;
    }
}
</style>
