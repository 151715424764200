<template>
    <div
        v-if="showSelector"
        :class="['select-metric-container', { 'no-pad': noPad }]"
    >
        <div class="select-metric" @click="displayModal">
            View Results By
            <span class="selected">
                {{ selectedMetric }}
                <img src="@/assets/img/Icons/chevron-down.svg" alt="" />
            </span>
        </div>
        <div class="modal-container">
            <PortalContainer v-if="showModal" @back="showModal = false">
                <Modal
                    vanilla
                    :top="30"
                    show
                    :size="modalSize.Small"
                    :has-close="false"
                    :allow-click-out="true"
                    :close="() => (showModal = false)"
                >
                    <div class="metric-selector-modal-content">
                        <div class="title">View Results by:</div>
                        <div class="options">
                            <div
                                v-if="activeMetrics.includes('nps')"
                                :class="[
                                    'option',
                                    { active: mobileQuestionType === 'nps' },
                                ]"
                                @click="switchMetric('nps')"
                            >
                                NPS
                                <FontAwesomeIcon
                                    v-if="mobileQuestionType === 'nps'"
                                    :icon="faCheck"
                                />
                            </div>
                            <div
                                v-if="activeMetrics.includes('csat')"
                                :class="[
                                    'option',
                                    { active: mobileQuestionType === 'csat' },
                                ]"
                                @click="switchMetric('csat')"
                            >
                                CSAT
                                <FontAwesomeIcon
                                    v-if="mobileQuestionType === 'csat'"
                                    :icon="faCheck"
                                />
                            </div>
                            <div
                                v-if="activeMetrics.includes('fivestar')"
                                :class="[
                                    'option',
                                    {
                                        active:
                                            mobileQuestionType === 'fivestar',
                                    },
                                ]"
                                @click="switchMetric('fivestar')"
                            >
                                Five Star
                                <FontAwesomeIcon
                                    v-if="mobileQuestionType === 'fivestar'"
                                    :icon="faCheck"
                                />
                            </div>
                        </div>
                    </div>
                </Modal>
            </PortalContainer>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { Action, Getter } from 'vuex-facing-decorator'
import PortalContainer from '@/mobile/src/components/containers/PortalContainer.vue'
import Modal from '@/components/Modal.vue'
import { ModalSize } from '@/components/modal-size'
import { CompanyFeatures } from '@/entities'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faCheck } from 'fontawesome/free-solid-svg-icons'

@Component({
    components: { Modal, PortalContainer, FontAwesomeIcon },
})
export default class MobileSelectMetric extends Vue {
    showModal = false
    modalSize = ModalSize
    readonly faCheck = faCheck

    @Prop({ type: Boolean, default: false })
    public readonly noPad?: boolean

    @Getter
    readonly getFeatureValue!: (featureName: CompanyFeatures) => any

    @Getter
    readonly mobileQuestionType!: string

    @Getter
    readonly activeMetrics!: string[]

    @Action
    switchMetricType

    displayModal() {
        this.showModal = true
    }

    // if tenant doesn't have feature turned on, not show this at all
    get showSelector() {
        return this.getFeatureValue(CompanyFeatures.has_lead_with_any_question)
    }

    get selectedMetric() {
        return this.mobileQuestionType === 'fivestar'
            ? 'Five Star'
            : this.mobileQuestionType.toUpperCase()
    }

    showSelectMetricModal() {
        this.showModal = true
    }

    switchMetric(metric) {
        this.switchMetricType(metric)
        this.showModal = false
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/palette';
@import '~@/styles/variables';

.select-metric-container {
    position: relative;
    padding: 0 12px;

    &.no-pad {
        padding: 0;
    }

    .select-metric {
        font-weight: 500;
        font-size: 12px;
        display: flex;
        align-items: center;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: @grey40;

        .selected {
            color: @blue;
            margin-left: 8px;
            display: flex;
            align-items: center;

            img {
                filter: @blueFilter;
                margin-left: 3px;
            }
        }
    }

    .modal-container {
    }
}

.metric-selector-modal-content {
    padding: 20px;

    .title {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 10px;
    }

    .options {
        .option {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.2px;
            color: @grey40;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px 0;

            img {
                display: none;
            }

            &.active {
                color: @blue;

                img {
                    display: initial;
                    filter: @blueFilter;
                }
            }
        }
    }
}
</style>
