<template>
    <div class="login-form">
        <div class="inputs">
            <Input
                title="Password"
                placeholder="Password"
                type="password"
                :required="true"
                :err-msg="'Invalid Password'"
                :model-value="password"
                @update:model-value="onUpdatePassword"
            />
            <Input
                v-if="method !== 'phone' && requirePhone"
                title="Phone"
                placeholder="Phone"
                type="text"
                :required="true"
                :err-msg="'Invalid Number'"
                :model-value="phone"
                @update:model-value="onUpdatePhone"
            />
        </div>
        <TransparentButton
            id="btnActivate"
            class="btn-login"
            text="Activate"
            :click="activateAccount"
        />
    </div>
</template>

<script setup lang="ts">
import { inject, ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import Input from '@/components/Input.vue'
import {
    activateLogin,
    updatePassword,
    getPasswordPolicy,
} from '@/mobile/src/api/auth'
import TransparentButton from '@/mobile/src/components/TransparentButton.vue'
import { loginAndRedirect, parseDeepLinkUrl } from '@/mobile/src/services/auth'
import { LoginMainPkey, LoginMainProvided } from '@/mobile/src/types/auth'

interface Props {
    url: string
}

const props = withDefaults(defineProps<Props>(), {
    url: '',
})

const domain = ref('')
const token = ref('')
const query = ref<any>({})
const password = ref('')
const phone = ref('')
const method = ref('')
const requirePhone = ref(false)

const router = useRouter()

const { persistCredentials } = inject(LoginMainPkey) as LoginMainProvided

onMounted(async () => {
    const parsedUrl = parseDeepLinkUrl(props.url)
    domain.value = parsedUrl.domain
    token.value = parsedUrl.token
    query.value = parsedUrl.query
    method.value =
        query.value && query.value.method ? query.value.method.toString() : ''

    const { success, data, msg } = await getPasswordPolicy(
        domain.value,
        token.value
    )
    if (success) {
        requirePhone.value = data.requirePhone
        phone.value = data.phone
    } else {
        alert(msg)
        await router.replace({ name: 'findlogin' })
    }
})

function onUpdatePassword(newPass: string) {
    password.value = newPass
}

function onUpdatePhone(newPhone: string) {
    phone.value = newPhone
}

async function activateAccount() {
    if (requirePhone.value) {
        if (!phone.value) {
            alert('Mobile number is required')
            return
        }
    }

    if (requirePhone.value) {
        await activate()
    } else {
        await setPassword()
    }
}

async function setPassword() {
    const { success, data, msg } = await updatePassword(
        domain.value,
        token.value,
        password.value
    )
    if (success) {
        persistCredentials({ email: data.email, password: password.value })
        await loginAndRedirect(domain.value, data.email, password.value)
    } else {
        alert(msg)
    }
}

async function activate() {
    const { success, data, msg } = await activateLogin(
        domain.value,
        token.value,
        password.value,
        phone.value,
        method.value
    )
    if (success) {
        persistCredentials({ email: data.email, password: password.value })
        await loginAndRedirect(domain.value, data.email, password.value)
    } else {
        alert(msg)
    }
}
</script>
<style lang="less" scoped>
@import '@/styles/palette';

.login-form {
    .white {
        color: white;
    }

    .btn-login {
        margin-top: 15px;
        width: 100%;
    }
}
</style>
