import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/img/logo/google_logo.png'


const _withScopeId = n => (_pushScopeId("data-v-0f259d3f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "btn-container" }
const _hoisted_2 = {
  key: 1,
  class: "spinner-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SimpleSpinner = _resolveComponent("SimpleSpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "btn-google-auth",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.googleSignin && _ctx.googleSignin(...args)))
    }, [
      _createElementVNode("img", {
        class: _normalizeClass(["icon", { notloading: !_ctx.loading }]),
        src: _imports_0,
        alt: "Google"
      }, null, 2),
      (!_ctx.loading)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createTextVNode(" Login with Google ")
          ], 64))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_SimpleSpinner, {
              size: "small",
              class: "spinner",
              "line-fg-color": "transparent"
            })
          ]))
    ])
  ]))
}