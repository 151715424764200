<template>
    <div :class="['chat-input-container', composerType]">
        <div
            ref="editor"
            :class="['quill-container', { 'fit-content-height': showImage }]"
            @click="onClickEditor"
        />

        <div v-if="showImage" class="uploaded-image">
            <FontAwesomeIcon
                id="remove-img-icon"
                :icon="removeImageIcon"
                size="lg"
                @click="() => removeComposerImage()"
            />
            <div v-if="composerImageUploading" id="image-loading">
                <FontAwesomeIcon :icon="imageIcon" size="lg" />
            </div>
            <img v-else :src="uploadedImgSrc" alt="uploaded image" />
        </div>

        <MessageRecipientPickerModal
            pendo-name="mention"
            :search-input="mentionSearchInput"
            :select-new="onSelectMention"
            :set-search-input="setMentionSearchInput"
            :top="caretTop"
            :bottom="caretBottom"
            :clear-on-no-results="true"
        />
        <HashtagPickerModal
            pendo-name="hashtag"
            :search-input="hashtagSearchInput"
            :select-new="onSelectHashtag"
            :set-search-input="setHashtagSearchInput"
            :top="caretTop"
            :bottom="caretBottom"
            :clear-on-no-results="true"
            :show-hashtag-modal="showHashtagModal"
        />
    </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from 'vue-facing-decorator'
import MessageRecipientPickerModal from '@/mobile/src/components/appV4/MessageRecipientPickerModal.vue'
import HashtagPickerModal from '@/mobile/src/components/appV4/HashtagPickerModal.vue'
import { MomentMessageRecipient, MomentMessageHashtag } from '@/entities/moment'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faCircleXmark } from 'fontawesome/free-regular-svg-icons'
import { faImage } from 'fontawesome/free-solid-svg-icons'
import { namespace } from 'vuex-facing-decorator'

import Quill from 'quill'
import 'quill-mention'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.bubble.css'
import { pendoTrackEvent } from '@/utils/pendo'

const MomentsModule = namespace('moments')

@Component({
    components: {
        MessageRecipientPickerModal,
        HashtagPickerModal,
        FontAwesomeIcon,
    },
    emits: ['save', 'input'],
})
export default class MomentTextBox extends Vue {
    public quill!: Quill

    @Prop({ type: Array, default: () => [] }) public mentions!: Array<{ name }> // can be ChatMention or IRecipient
    @Prop({ type: String, default: '' }) public value?: string
    @Prop({ type: String, default: '' }) public placeholder?: string

    @MomentsModule.Getter composerType!: string
    @MomentsModule.Getter mentionSearchInput!: string
    @MomentsModule.Getter hashtagSearchInput
    @MomentsModule.Getter composerUploadedImageFile!: File | null
    @MomentsModule.Getter composerImageUploading!: boolean
    @MomentsModule.Action setMentionSearchInput
    @MomentsModule.Action setHashtagSearchInput
    @MomentsModule.Action setSelectedHashtags
    @MomentsModule.Action selectNewMention
    @MomentsModule.Action setCurrentMentionedUserIds
    @MomentsModule.Action removeComposerImage

    public caretTop = 0
    public caretBottom = 0

    public removeImageIcon = faCircleXmark
    public imageIcon = faImage

    public showHashtagModal = false

    public mounted() {
        this.quill = new Quill(this.$refs.editor as Element, {
            placeholder: this.placeholder,
            modules: {
                keyboard: {
                    bindings: {
                        enter: {
                            key: 13,
                            shortKey: true,
                            handler: () => {
                                this.$emit('save')
                            },
                        },
                    },
                },
                toolbar: false,
                mention: {
                    allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
                    mentionDenotationChars: ['@', '#'],
                    source: (searchTerm, renderList, mentionChar) => {
                        //We don't actually use renderList, we just want to catch mentions

                        if (this.composerType !== 'emailReply') {
                            const range = this.quill.getSelection()
                            if (range !== null) {
                                const bounds = this.quill.getBounds(range.index)
                                const padding = 5

                                this.caretTop =
                                    (
                                        this.$refs.editor as Element
                                    ).getBoundingClientRect().top +
                                    bounds.bottom +
                                    padding

                                this.caretBottom =
                                    //Firstly zero out the location to the bottom of the editor
                                    window.innerHeight -
                                    (
                                        this.$refs.editor as Element
                                    ).getBoundingClientRect().bottom +
                                    //Then add the difference between the bottom and the top of the caret
                                    ((
                                        this.$refs.editor as Element
                                    ).getBoundingClientRect().height -
                                        bounds.top) +
                                    //Then padding
                                    padding
                            }
                            if (mentionChar === '@') {
                                this.setMentionSearchInput(searchTerm)
                            } else if (mentionChar === '#') {
                                this.setHashtagSearchInput(searchTerm)
                            }
                        }
                    },
                },
            },
            theme: 'bubble',
        })

        this.quill.on('text-change', () => {
            // Always keep current mentioned userIds updated to keep track of deleted mentions
            const currentMentionedUserIds = this.quill
                .getContents()
                .ops.filter(
                    (op) =>
                        // @ts-ignore
                        op.insert?.mention instanceof DOMStringMap &&
                        // @ts-ignore
                        op.insert?.mention?.denotationChar === '@'
                )
                .map((op) =>
                    typeof op.insert === 'string'
                        ? op.insert
                        : // @ts-ignore
                          Number(op?.insert?.mention.id)
                )
            this.setCurrentMentionedUserIds(currentMentionedUserIds)

            // Keep track of hashtags selected
            const currentSelectedHashtags = this.quill
                .getContents()
                .ops.filter(
                    (op) =>
                        // @ts-ignore
                        op.insert?.mention instanceof DOMStringMap &&
                        // @ts-ignore
                        op.insert?.mention?.denotationChar === '#'
                )
                .map((op): MomentMessageHashtag => {
                    return {
                        name:
                            typeof op.insert === 'string'
                                ? op.insert
                                : // @ts-ignore
                                  op?.insert?.mention.value,
                    }
                })
            this.setSelectedHashtags(currentSelectedHashtags)

            // Check whether last input char is a # to show hashtag picker modal when there is no search string
            const currentInput = this.quill.root.innerHTML.replace('</p>', '')
            this.showHashtagModal =
                currentInput.charAt(currentInput.length - 1) === '#'

            this.$emit('input', this.quill.root.innerHTML)
        })

        this.quill.root.addEventListener('blur', () => {
            this.setMentionSearchInput('')
            this.setHashtagSearchInput('')
        })

        this.updateContent(this.value)
    }

    public onSelectMention(recipient: MomentMessageRecipient) {
        this.selectNewMention(recipient)
        this.quill.getModule('mention').insertItem({
            id: recipient.user_id,
            value: recipient.name,
            denotationChar: '@',
        })
    }

    public onSelectHashtag(hashtag: MomentMessageHashtag) {
        pendoTrackEvent('message_composer_hashtag_selected')
        this.quill.getModule('mention').insertItem({
            id: hashtag.name,
            value: hashtag.name,
            denotationChar: '#',
        })
    }

    public onClickEditor(event) {
        const node = event.target
        const mention = node.closest('.mention')
        //Currently we don't do anything with this, but we'll need it for @ interactions in the future
    }

    @Watch('placeholder')
    public onPlaceholderChanged() {
        this.quill.root.dataset.placeholder = this.placeholder
    }

    @Watch('value')
    public onValueChanged() {
        // If the value has changed from outside the textbox, e.g. presetting for reply templates
        if (this.value !== this.quill.root.innerHTML) {
            this.updateContent(this.value)
        }
    }

    public updateContent(html) {
        const delta = this.quill.clipboard.convert(html)
        this.quill.setContents(delta)
    }

    public get uploadedImgSrc() {
        return this.composerUploadedImageFile
            ? URL.createObjectURL(this.composerUploadedImageFile)
            : ''
    }

    public get showImage() {
        return this.uploadedImgSrc || this.composerImageUploading
    }
}
</script>

<style lang="less">
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';
@import '~quill/dist/quill.bubble.css';
@import '~@/styles/appV4/messageTypes.less';

.chat-input-container {
    .ql-toolbar {
        border: none;
    }

    .ql-container {
        border: none;

        &.fit-content-height {
            height: fit-content;
        }
    }

    .ql-picker,
    .ql-editor,
    .ql-editor.ql-blank::before {
        color: var(--type-colour);
        left: @featureGutter;
        font-size: @fontSize-base;
        line-height: @lineHeight-base;
        font-style: normal;
    }

    .ql-editor.ql-blank::before {
        opacity: 0.5;
    }

    .ql-editor {
        caret-color: var(--type-colour);
        padding: @featureGutter;
    }

    .mention {
        background-color: @endarken;
        border-radius: @borderRadius-md;
        padding: 3px;
    }
}

.uploaded-image {
    width: calc(100% - @featureGutter * 2);
    margin: 0 @featureGutter @featureGutter;
    position: relative;

    img,
    #image-loading {
        border-radius: @borderRadius-md;
        width: 100%;
    }

    #remove-img-icon {
        color: white;
        filter: drop-shadow(5px 5px 5px #222);
        position: absolute;
        top: 0;
        right: 0;
        padding: 10px;
    }

    #image-loading {
        height: 200px;
        color: @white60;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #image-loading {
        background-image: linear-gradient(
            -90deg,
            #d9d9d9,
            @transparent,
            #d9d9d9
        );
        background-size: 2000% 100%;
        animation: gradient 4s infinite linear;
    }

    @keyframes gradient {
        0% {
            background-position: 0 0;
        }
        100% {
            background-position: 100% 0;
        }
    }
}
</style>
