import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
            'suggestions-subnav',
            !_ctx.inDashboard && 'suggestions-subnav-padded',
        ])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navMap, ({ mode, label }) => {
      return (_openBlock(), _createBlock(_component_RouterLink, {
        key: mode,
        to: `${_ctx.currentPath}/${mode}`,
        replace: ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("button", {
            class: _normalizeClass(["btn filter-btn", {
                    'filter-btn-active': mode === _ctx.activeMode,
                    'filter-btn-inactive': mode !== _ctx.activeMode,
                }])
          }, _toDisplayString(label), 3)
        ], undefined, true),
        _: 2
      }, 1032, ["to"]))
    }), 128))
  ], 2))
}