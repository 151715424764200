<template>
    <div class="percentage-container">
        <div class="the-percentage" :style="{ background: bgColor }">
            <div
                class="percent"
                :style="{
                    width: deltaWidthPercent + '%',
                    background: color,
                    left: deltaPositionRelative + '%',
                }"
            ></div>
        </div>
        <div
            :style="`left: calc(${middlePercent}% - 2px)`"
            :class="['average', { last: last }]"
        ></div>

        <span
            v-if="showlabel"
            class="average-label"
            :style="`left: calc(${middlePercent}% - 26px)`"
            >{{ averageText }}</span>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component({})
export default class ComparisonBar extends Vue {
    @Prop({ type: Number, default: 0 }) public value!: number
    @Prop({ type: Number, default: -100 }) public min!: number
    @Prop({ type: Number, default: 100 }) public max!: number
    @Prop({ type: Number, default: 0 }) public average!: number
    @Prop({ type: String, default: '' }) public averageText!: string
    @Prop({ type: String, default: '#D5DEFF' }) public positiveColor!: string
    @Prop({ type: String, default: '#FCC3A8' }) public negativeColor!: string
    @Prop({ type: String, default: '#ebebeb' }) public bgColor!: string
    @Prop({ type: Boolean, default: false }) public last!: boolean // show short vertical line for last row
    @Prop({ type: Boolean, default: false }) public showlabel!: boolean

    get range(): number {
        return this.max - this.min
    }

    get middleRelative() {
        return this.average - this.min
    }

    get middlePercent(): number {
        const value =
            this.range > 0 ? (this.middleRelative / this.range) * 100 : 50
        return Math.max(0, Math.min(100, value))
    }

    get valueRelative() {
        return this.value - this.min
    }

    get delta() {
        return this.value - this.average
    }

    get deltaPercent() {
        return this.range > 0 ? (this.delta / this.range) * 100 : 0
    }

    get deltaWidthPercent() {
        const value = Math.abs(this.deltaPercent)
        return Math.max(0, Math.min(value, 100))
    }

    get deltaPositionRelative() {
        let value
        if (this.deltaPercent < 0) {
            value = this.middlePercent + this.deltaPercent
        } else {
            value = this.middlePercent
        }
        return Math.max(0, Math.min(100, value))
    }

    get color() {
        if (this.deltaPercent > 0) {
            return this.positiveColor
        } else {
            return this.negativeColor
        }
    }
}
</script>

<style scoped lang="less">
.percentage-container {
    position: relative;
    height: calc(100% + 6px);
    display: flex;
    align-items: center;
    max-width: 140px;
    margin-top: -3px;
    margin-bottom: -3px;

    .the-percentage {
        position: relative;
        background: #f5f6f7;
        box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.08);
        height: 12px;
        width: 100%;
        min-width: 100px;
    }

    .percent {
        height: 12px;
        position: absolute;
    }

    .average {
        position: absolute;
        height: 100%;
        border: 1.5px solid #d5deff;
        background: #d5deff;

        &.last {
            height: 30px;
            top: 0;
        }
    }

    .average-label {
        text-transform: uppercase;
        display: inline-block;
        background-color: rgba(213, 223, 254, 1);
        color: #2e5bff;
        border-radius: 8.25px;
        font-size: 8.25px;
        font-weight: 500;
        line-height: 10px;
        position: absolute;
        top: 0;
        padding: 3px 5px;
        min-width: 45px;
        width: 45px;
        text-align: center;
    }
}
</style>
