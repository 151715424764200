import { EmojiReactionDetails, IEmojiReactionUpdateData } from '@/entities'

/**
 * Toggles the counter for the emoji reactions displayed.
 * This will add or delete from the list, depending on the counter being or becoming 0.
 */
export function updateEmojiReactionDetails(
    emojiSource: IEmojiReactionUpdateData
): void {
    if (!emojiSource.emojis) {
        // No emoji reaction at all.
        addEmojiReactionDetail(emojiSource)
    } else {
        // Has emoji list, but doesn't necessarily mean the user has a reaction.
        const index = emojiSource.emojis?.findIndex(
            (emojiDetail) => emojiDetail.emoji === emojiSource.emoji
        )
        if (emojiSource.emojis && index === -1) {
            // Emoji not yet displayed, or there are no emoji reactions yet.
            // User is the first one to react with the emoji; need to create list entry.
            addEmojiReactionDetail(emojiSource)
        } else {
            // Emoji is already displayed.
            if (emojiSource.emojis[index].self) {
                // If it was a self reaction, decrement it; optionally remove from list if it goes to 0.
                emojiSource.emojis[index].count--
                emojiSource.emojis[index].self = false

                if (emojiSource.emojis[index].count === 0) {
                    emojiSource.emojis.splice(index, 1)
                }
            } else {
                // If emoji is displayed due to reaction from other users, increment counter.
                emojiSource.emojis[index].count++
                emojiSource.emojis[index].self = true
            }
        }
    }
}

/**
 * Add an emoji reaction to the response emoji array.
 * Initialise the array if it's empty or doesn't exist.
 */
export function addEmojiReactionDetail(
    emojiSource: IEmojiReactionUpdateData
): void {
    if (!emojiSource.emojis) {
        emojiSource.emojis = []
    }
    emojiSource.emojis.push({
        emoji: emojiSource.emoji,
        count: 1,
        self: true,
    } as EmojiReactionDetails)
}
