<template>
    <div v-if="insightsEngagement" class="theme-details-container">
        <EngagementThemeTrend :engagement-theme="theme"></EngagementThemeTrend>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { Action, Getter } from 'vuex-facing-decorator'
import { EngagementInsights } from '@/entities/insights'
import EngagementThemeTrend from '@/mobile/src/components/engagement/TeamTheme/EngagementThemeTrend.vue'
import { useRouter } from 'vue-router'

@Component({
    components: {
        EngagementThemeTrend,
    },
})
export default class EngagementTeamThemeDetail extends Vue {
    @Prop({ type: String })
    public themeId!: string // from the route

    @Getter
    readonly insightsEngagement!: EngagementInsights

    @Action
    loadInsightsEngagement

    private router = useRouter()

    public async mounted() {
        if (!this.insightsEngagement) {
            await this.loadInsightsEngagement()
        }

        if (!this.theme) {
            await this.router.push({ name: 'InsightsEngagementMetrics' })
        }
    }

    public get theme() {
        return this.insightsEngagement.data.themes.find(
            (el) => el.id === this.themeId
        )
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/typography';
@import '~@/styles/variables';
@import '~@/styles/button';

.time-range {
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: @grey40;
}

.theme-details-container {
    background: @neutralBg;

    .insights-no-data {
        padding: 12px;
    }
}

.insights-engagement-list {
    margin: 12px;
}
</style>
