export const RECEIVE = 'receive'
export const SAY = 'say'
export const FOR = 'for'
export const JUMP = 'jump'
export const BLOCK = 'block'
export const LABEL_LEFT_NPS_DEFAULT = '0 - Not likely'
export const LABEL_RIGHT_NPS_DEFAULT = '10 - Very likely'
export const LABEL_LEFT_FIVESTAR_DEFAULT = '1 - Terrible'
export const LABEL_RIGHT_FIVESTAR_DEFAULT = '5 - Excellent'
export const TYPE_CONTACT_LOOKUP = 'contact_lookup'
export const TYPE_REVIEW_LINK_BUTTON = 'review_link_button'
export const TYPE_QUESTION = 'question'
export const LOOKUP_EMAIL = 'email'
export const LOOKUP_PHONE = 'phone'
export const REVIEW_LINK_INPUT = 'input'
export const REVIEW_LINK_CUSTOM_FIELD = 'custom_field'
export const QUESTION_KEY_AI = 'ai_question_1'

export const REVIEW_LINK_TITLE = 'review_link_title'
export const REVIEW_LINK_BTN_TITLE = 'review_link_btn_title'
export const SECTION_START = 'start'
export const SECTION_FINISH = 'finish'

export interface Option {
    key: string
    value: string
    badgeId?: string
    ogKey?: string
    // altho we store scorecardTopicId, we show name in UI
    scorecardTopicName?: string
    disabled?: boolean
    jump?: string
    isSelected?: boolean
}

export type Options = Option[]

export type Status = // this gets set clientside
    'new' | 'entering' | 'active' | 'leaving' | 'done'

export type ConversationSpec = Block[]

export type ConversationDisplayItem = ReceiveOption | ReceiveText | Say

export type ConversationItem = ConversationDisplayItem | For | Jump

export type ConversationDisplayItemType =
    | 'Say'
    | 'ReceiveText'
    | 'ReceiveOption'

export type ConversationItemType = ConversationDisplayItemType | 'Jump' | 'For'

export interface Block {
    type: 'Block'
    label: string
    items: ConversationItem[]
    preventFallthrough?: true
    // 'preventFallthrough' means the block is 'outside' the main sequence
    // it can only be jumped to and from,
    // you can't fall through to it, or from it
    // this is to allow for 'flows' in the UI builder
    // @todo - change interpreter to prevent this ^^
}

export interface Cases<T> {
    [value: string]: T
}

export interface For extends CardAnnotation {
    type: 'For'
    match: string
    cases: Cases<ConversationItem[]>
}

export interface ForCases<T> extends CardAnnotation {
    // generic version of For, where we specify the case branch types
    type: 'For'
    match: string
    cases: Cases<T>
    scorecardId?: number
    randomizeOrder?: boolean
    multiSelect?: boolean
}

export interface CardAnnotation {
    cardType?: CardType
    cardLength?: number
    isFirstInteractive?: boolean
    optionLabels?: OptionLabels
    extraOption?: string
}

export interface Jump extends CardAnnotation {
    type: 'Jump'
    label: string
}

export interface ReceiveOption extends CardAnnotation {
    type: 'ReceiveOption'
    param: string
    options: Option[]
    status?: Status
    link?: string
    randomizeOrder?: boolean
    multiSelect?: boolean
}
// if 'link' is present, the interpreter will treat it differently

export interface ReceiveText extends CardAnnotation {
    type: 'ReceiveText'
    param: string
    status?: Status
    link?: string
    title?: string
    btnTitle?: string
    confirmationMessage?: string
}

export interface Say extends CardAnnotation {
    type: 'Say'
    value: string
    status?: Status
    aiConversationalMode?: boolean
    aiQuestionCount?: number
}

export type CardType =
    | 'message'
    | 'question'
    | 'contact_lookup'
    | 'review_link_button'
    | 'list'
    | 'fivestar'
    | 'scorecardv3' // new refactored scorecard type
    | 'scorecardv3_promoter' // the promoter type question
    | 'scorecardv3_default' // the default type question
    // todo scorecard3 remove
    | 'scorecard' // the main scorecard type
    | 'scorecard_promoter' // the promoter type question
    | 'scorecard_default' // the default type question
    | 'csat'
    | 'ces'
    | 'nps'
    | 'jump'
    | 'external'
    | 'unknown'

export interface LinkConfig {
    say: string
    link: string
}

export interface Replies {
    [param: string]: string
}

// ---------- Sugared types for the Yaml editor ----------

export type Sugared = Array<{
    [label: string]: SugaredItem[]
}>

export type SugaredItem =
    | SugaredSay
    | SugaredReceiveText
    | SugaredReceiveOption
    | SugaredFor
    | SugaredJump

export type SugaredSpec = SugaredBlock[]

export interface SugaredBlock {
    [key: string]: SugaredItem[]
}

export interface SugaredSay {
    say: string
}

export interface SugaredReceiveText {
    [_receive: string]: undefined | null
}

export interface SugaredReceiveOption {
    [_receive: string]: Array<{
        [value: string]: string
    }>
}

export interface SugaredFor {
    [_for: string]: {
        [match: string]: SugaredItem[]
    }
}

export interface SugaredJump {
    jump: string
}

export function isSugaredSay(item: SugaredItem): item is SugaredSay {
    return Object.keys(item)[0] === SAY
}

export function isSugaredReceiveText(
    item: SugaredItem
): item is SugaredReceiveText {
    const key = Object.keys(item)[0]
    const body = item[key]
    return key.indexOf(RECEIVE) === 0 && !body
}

export function isSugaredReceiveOption(
    item: SugaredItem
): item is SugaredReceiveOption {
    const key = Object.keys(item)[0]
    const body = item[key]
    return key.indexOf(RECEIVE) === 0 && body instanceof Object
}

export function isSugaredFor(item: SugaredItem): item is SugaredFor {
    return Object.keys(item)[0].indexOf(FOR) === 0
}

export function isSugaredJump(item: SugaredItem): item is SugaredJump {
    return Object.keys(item)[0] === JUMP
}

export function isConversationDisplayItem(
    item: ConversationItem | Block
): item is ConversationDisplayItem {
    switch (item.type) {
        case 'Say':
        case 'ReceiveOption':
        case 'ReceiveText':
            return true
    }
    return false
}

export function isAiConversationDisplayItem(item: ConversationItem | Block) {
    if (item.type === 'Say' && item.aiConversationalMode) {
        return true
    }
    return false
}

// ---------- View model groupings for conversation spec items for the UI Builder -----------

export type MessagePattern = [Say]
export type QuestionPattern = [Say, ReceiveText]
export type ListPattern = [
    Say,
    ReceiveOption,
    ForCases<Jump[]>, // must have a 'For' to accommodate jumps, even if it is empty
]
export type JumpPattern = [Jump]
export type ExternalPattern = [Say, ReceiveOption]
export type BranchPattern<T> = [ForCases<T>]
export type BasePattern =
    | MessagePattern
    | QuestionPattern
    | ListPattern
    | ExternalPattern

export type Pattern = BasePattern | BranchPattern<BasePattern> | JumpPattern

export interface CardCase<T> {
    key: string
    card: T
}

export type OptionsType =
    | 'list'
    | 'csat'
    | 'ces'
    | 'nps'
    | 'scorecardv3'
    | 'scorecardv3_promoter'
    | 'scorecardv3_default'
    // todo scorecard3
    | 'scorecard'
    | 'scorecard_promoter'
    | 'scorecard_default'

export type CardCases<T> = Array<CardCase<T>>

export interface ReadOnlyCard {
    type: 'readonly'
    say: string
}
export interface MessageCard {
    type: 'message'
    say: string
    param?: string
    title?: string
    btnTitle?: string
    confirmationMessage?: string
}

export interface MessageCardBranched {
    type: 'message'
    param?: string
    match: string
    cases: CardCases<MessageCard>
}

export interface QuestionCard {
    type: 'question'
    say: string
    param: string
    title?: string
    linkType?: string
    btnTitle?: string
    confirmationMessage?: string
    aiConversationalMode?: boolean
    aiQuestionCount?: number
}

export interface ContactLookupCard {
    type: 'contact_lookup'
    say: string
    param: string
    contactType?: 'email' | 'phone'
}

export interface ReviewLinkButtonCard {
    type: 'review_link_button'
    say: string
    param: string
    linkType?: 'input' | 'custom_field'
    validateIt?: boolean // for local validation, no need to save to ConversationSpec
    title?: string
    btnTitle?: string
    confirmationMessage?: string
}

export interface QuestionCardBranched {
    type: 'question'
    param?: string
    match: string
    cases: CardCases<QuestionCard>
}

export interface ScorecardCard extends OptionsCardBranched {
    scorecardId: number
}

export interface OptionsCard {
    type: OptionsType
    say: string
    param: string
    options: Option[]
    optionLabels?: OptionLabels
    randomizeOrder?: boolean
    multiSelect?: boolean
    title?: string
    linkType?: string
    btnTitle?: string
    confirmationMessage?: string
}

// The survey embedded question's left and right labels.
export interface OptionLabels {
    labelLeft?: string
    labelRight?: string
}

export interface ListCard extends OptionsCard {
    type: 'list'
}

export interface OptionsCardBranched {
    type: OptionsType
    param?: string
    match: string
    cases: CardCases<OptionsCard>
    scorecardId?: number
    isScorecard?: boolean
}

export interface JumpCard {
    type: 'jump'
    param?: string
    jump: string
}

export interface JumpCardBranched {
    type: 'jump'
    param?: string
    match: string
    cases: CardCases<JumpCard>
}

export interface ExternalCard {
    type: 'external'
    param?: string
    say: string
    link: string
}

export interface ExternalCardBranched {
    type: 'external'
    param?: string
    match: string
    cases: CardCases<ExternalCard>
}

export interface UnknownCard {
    // for new cards, ignored when converting to spec
    type: 'unknown'
    param?: string
}

export type BaseCard =
    | ReadOnlyCard
    | MessageCard
    | QuestionCard
    | OptionsCard
    | JumpCard
    | ExternalCard
    | ContactLookupCard
    | ReviewLinkButtonCard

export type BranchCard =
    | MessageCardBranched
    | QuestionCardBranched
    | OptionsCardBranched
    | JumpCardBranched
    | ExternalCardBranched

export type Card = BaseCard | BranchCard | UnknownCard

export interface CardSection {
    name: string
    cards: Card[]
    flow?: boolean
}

export type BlockBuilderModel = CardSection[]

export interface CardSectionIndex {
    sectionIndex: number
}

export interface CardIndex extends CardSectionIndex {
    cardIndex: number
}

export interface CardCaseIndex extends CardIndex {
    caseIndex: number
}

export interface HasCard {
    card: Card
}

export interface HasSection {
    section: CardSection
}

export interface HasDirection {
    direction: 1 | -1
}

export interface HasParam {
    param: string
}

export function isBranchedCard(card: Card): card is BranchCard {
    return card.hasOwnProperty('match') && card.hasOwnProperty('cases')
}

export function isScorecardCard(card: Card): card is ScorecardCard {
    return isBranchedCard(card) && card.hasOwnProperty('scorecardId')
}

export function isOptionsCard(card: Card): card is OptionsCard {
    return ['list', 'csat', 'ces', 'nps'].indexOf(card.type) > -1
}

export function isListCard(card: Card): card is ListCard {
    return card.type === 'list'
}

export function isExternalCard(card: Card): card is ExternalCard {
    return card.type === 'external'
}

export function isParamCard(card: Card): card is QuestionCard | OptionsCard {
    return card.type === 'question' || isOptionsCard(card)
}

export function isUrlCard(card: Card): card is ReviewLinkButtonCard {
    return card.type === TYPE_REVIEW_LINK_BUTTON
}

export function isSayCard(
    card: Card
): card is MessageCard | QuestionCard | OptionsCard | ReviewLinkButtonCard {
    return card.type === 'message' || isParamCard(card) || isUrlCard(card)
}

export function isJumpCard(card: Card): card is JumpCard {
    return card.type === 'jump'
}

export function isCustomParameter(parameter) {
    return parameter === 'segment' || parameter.match(/_c$/)
}

export function isMultiSelectCard(card: Card): card is OptionsCard {
    return (
        isListCard(card) && 'multiSelect' in card && card.multiSelect === true
    )
}

export interface ConversationLog {
    sent_at: number
    index_at: number
    delivery_method: string
    triggered_by: string
    selected_answer: string
    metric: 'nps' | 'csat' | 'fivestar'
    responses: ConversationEvent[]
}
export interface ConversationEvent {
    score: number
    is_live_response: boolean
    is_bot: boolean
    bot_type: string
    response_time: number // Unix timestamp
    conversation_slug: string
    display_actions: ConversationEventDisplayAction[]
    actions: ConversationEventAction[]
    aws_tag: string | null
    reason: string | null
}
export interface ConversationEventDisplayAction {
    label: string
    value: string
}
export interface ConversationEventAction {
    time: number
    action: string
    value: string
    ip: string
}
