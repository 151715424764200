<template>
    <transition name="fade">
        <div
            v-if="showModal"
            class="recipient-picker-modal"
            :style="positionStyle"
        >
            <Loading
                v-if="loadingRecipients"
                :loading="loadingRecipients"
                transparent
            />

            <Typography
                v-if="!loadingRecipients && !filteredRecipients.length"
                class="no-results"
                variant="body2"
            >
                Could not find results for: <strong>{{ searchInput }}</strong>
            </Typography>

            <div
                v-for="(recipient, index) in filteredRecipients"
                :key="index"
                class="recipient-item"
                @mousedown="onRecipientSelect(recipient)"
            >
                <AvatarOrInitials
                    class="avatar"
                    :user="getRecipientAvatar(recipient)"
                    :size="30"
                />
                <Typography class="recipient-name" variant="body2">
                    <span v-html="highlightSearchInName(recipient.name)" />
                </Typography>
            </div>
        </div>
    </transition>
</template>

<script lang="ts">
import Fuse from 'fuse.js'
import { namespace } from 'vuex-facing-decorator'
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { htmlSanitizer } from '@/utils/string'
import { MomentMessageRecipient } from '@/entities/moment'
import AvatarOrInitials from '@/components/WebView/AvatarOrInitials.vue'
import Typography from '@/components/Rain/Typography/Typography.vue'
import Loading from '@/components/Loading.vue'
import { pendoTrackEvent } from '@/utils/pendo'

const MomentsModule = namespace('moments')

@Component({
    components: {
        Typography,
        AvatarOrInitials,
        Loading,
    },
    emits: ['recipient-selected'],
})
export default class MessageRecipientPickerModal extends Vue {
    //Name to identify whatever is triggering this particular modal ('recipient', 'mention', etc)
    @Prop({ type: String, default: '' })
    pendoName

    @Prop({ type: Number, default: 2 })
    minimumSearchLength!: number

    @Prop({ type: String, default: '' })
    searchInput

    @Prop({ type: Function, required: true })
    setSearchInput

    @Prop({ type: Function, required: true })
    selectNew

    @Prop({ type: Number, default: 0 })
    top!: number

    @Prop({ type: Number, default: 0 })
    bottom!: number

    @Prop({ type: Boolean, default: false })
    clearOnNoResults!: boolean

    @MomentsModule.Getter availableComposerRecipients!: MomentMessageRecipient[]
    @MomentsModule.Getter loadingRecipients!: boolean

    public modalOpened = false

    //Dynamically determines whether to position the modal using top/bottom, whichever gives the most screen height
    //If both bottom and top are set, check if it'd make more sense to use top
    //Otherwise, if bottom is set, use that
    //Otherwise, if top is set, use that
    //Otherwise just let it hang out where-ever the absolute positioning allows it
    public get positionStyle() {
        if (this.bottom && this.top) {
            const middle = window.innerHeight / 2
            if (this.top < middle) {
                return { top: this.top + 'px' }
            }
        }
        if (this.bottom) {
            return { bottom: this.bottom + 'px' }
        }
        if (this.top) {
            return { top: this.top + 'px' }
        }
        return {}
    }

    public onRecipientSelect(recipient: MomentMessageRecipient) {
        this.selectNew(recipient)
        // Reset search state
        this.setSearchInput('')
        this.$emit('recipient-selected')
    }

    public getRecipientAvatar(recipient: MomentMessageRecipient) {
        return {
            id: recipient.user_id,
            name: recipient.name,
            avatar: recipient.avatar,
        }
    }

    public get showModal() {
        const isOpen = this.searchInput?.length >= this.minimumSearchLength
        if (isOpen !== this.modalOpened) {
            pendoTrackEvent(
                `moments-${this.pendoName}-picker-${
                    isOpen ? 'opened' : 'closed'
                }`
            )
            this.modalOpened = isOpen
        }

        return isOpen
    }

    public highlightSearchInName(name: string): string {
        const searchString = htmlSanitizer(this.searchInput)
        const regex = new RegExp(searchString, 'ygi')
        return name.replace(regex, `<strong>${searchString}</strong>`)
    }

    public get filteredRecipients() {
        const results = this.fuse.search(this.searchInput)
        if (this.clearOnNoResults && !results.length && this.showModal) {
            this.setSearchInput('')
        }
        return results
    }

    private get fuse() {
        return new Fuse(this.availableComposerRecipients, {
            keys: ['name', 'user_id'],
            shouldSort: true,
            threshold: 0,
            location: 0,
            distance: 0,
            maxPatternLength: 32,
            minMatchCharLength: this.minimumSearchLength,
        })
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';
@import '~@/mobile/src/styles/animations.less';
@import '~@/mobile/src/styles/zindex.less';
@import '~@/mobile/src/styles/message-composer.less';

.fade-transition-animation(0.2s);

.recipient-picker-modal {
    @sidePadding: 16px;
    .modal-container();

    max-height: 220px;
    height: auto;
    padding: 18px @sidePadding;
    width: calc(
        100vw - ((@featureGutter * 2) + (@sidePadding * 2))
    ); // Account for inner and outer padding/margin
    overflow-y: scroll;

    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: @gutterSpacing-md;
}

.recipient-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.no-results,
.recipient-name {
    color: @hoverSecondaryLightBlue;
}

.recipient-name {
    margin-left: 28px;
    width: 100%;
}
</style>
