<template>
    <div class="width-restricted-columns">
        <div class="width-restricted-column">
            <slot name="left-column" />
        </div>
        <div class="width-restricted-column">
            <slot name="right-column" />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'

@Component
export default class TwoColumnLayoutContainer extends Vue {}
</script>

<style lang="less" scoped>
.width-restricted-columns {
    display: flex;
    justify-content: space-around;

    > .width-restricted-column {
        flex: 1;
    }
}

.width-restricted-column {
    max-width: 350px;
}
</style>
