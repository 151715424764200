<template>
    <img class="sort-direction" :src="imageSource" :alt="altText" />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import SortUp from '@/assets/img/Icons/sort-up.svg'
import SortDown from '@/assets/img/Icons/sort-down.svg'

@Component({})
export default class SortDirection extends Vue {
    @Prop({
        type: String,
        required: true,
        default: 'asc',
    })
    readonly direction!: 'asc' | 'desc'

    get imageSource() {
        return this.direction === 'asc' ? SortUp : SortDown
    }

    get altText() {
        return this.direction === 'asc' ? 'Ascending' : 'Descending'
    }
}
</script>

<style lang="less" scoped>
.sort-direction {
    vertical-align: middle;
}
</style>
